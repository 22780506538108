import { intersection } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiSpinner from '../SonifiSpinner';
import RcTree from './RcTree/RcTree'; // './RcTree/RcTree';


// import { orderBy } from 'lodash';
// import { compact, uniqBy } from 'lodash';
// import { loadSiteHierarchy } from '../../actions/globalActions';

class RoomSelector extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      selectedRooms: this.props.selectedRooms,
    };
  }

  componentDidMount() {
    // this.props.dispatch(loadSiteHierarchy());
  }

  onChange = (checked) => {
    // get the intersection of the checked rooms against the array of valid
    // rooms to filter out the keys from the buildings/floors/units
    const filteredRooms = intersection(checked, this.props.allRooms);
    this.props.onChange(filteredRooms);
  };

  render() {
    const {
      autoExpandKeys, loadingSite, siteHierarchy, disabled, errorMessage
    } = this.props;
    const { selectedRooms } = this.state;
    const expandedKeys = (!autoExpandKeys || autoExpandKeys.length === 0) ? ['Whole Site'] : autoExpandKeys;

    return (
      <Fragment>
        {loadingSite
          ? <SonifiSpinner />
          : <RcTree nodes={siteHierarchy} keys={selectedRooms ? selectedRooms : []}
            autoExpandKeys={expandedKeys} onChange={this.onChange} errorMessage={errorMessage}
            disabled={disabled} />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allRooms: state.global.selectableRooms,
  loadingSite: state.global.loadingSite,
  siteHierarchy: state.global.buildings
});

RoomSelector.propTypes = {
  allRooms: PropTypes.array.isRequired,
  autoExpandKeys: PropTypes.array,
  disabled: PropTypes.bool.isRequired,
  dispatch: PropTypes.func,
  errorMessage: PropTypes.string,
  loadingSite: PropTypes.bool,
  onChange: PropTypes.func,
  selectedRooms: PropTypes.array,
  siteHierarchy: PropTypes.array
};

export default connect(mapStateToProps)(RoomSelector);
