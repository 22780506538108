import { IPG_SOURCES, LOGOS, MAPS } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import { deleteIpgObjectPromise, getIpgPromise, putIpgImagePromise, putIpgPromise } from '../../../utils/ipg';
import * as types from './actionTypes';

export const fetchChannelLogosBegin = () => ({
  type: types.FETCH_CHANNEL_LOGOS_BEGIN
});

export const fetchChannelLogosSuccess = (logos) => ({
  type: types.FETCH_CHANNEL_LOGOS_SUCCESS,
  logos
});

export const fetchChannelLogosFailure = (error) => ({
  type: types.FETCH_CHANNEL_LOGOS_FAILURE,
  error
});

export function fetchChannelLogos(filter) {
  return (dispatch) => {
    dispatch(fetchChannelLogosBegin());
    return getIpgPromise(MAPS, null, filter).then((data) => {
      dispatch(fetchChannelLogosSuccess(data));
      return true;
    }).catch((error) => {
      dispatch(fetchChannelLogosFailure(error));
      return true;
    });
  };
}

export const startLogos = () => ({
  type: types.START_LOGOS
});

export const fetchLogoBegin = () => ({
  type: types.FETCH_LOGO_BEGIN
});

export const fetchLogoSuccess = (roleId, role) => ({
  type: types.FETCH_LOGO_SUCCESS,
  roleId,
  role
});

export const fetchLogoFailure = (error) => ({
  type: types.FETCH_LOGO_FAILURE,
  error
});

export const clearLogos = () => ({
  type: types.CLEAR_LOGOS
});

export const updateLogoLoading = () => ({
  type: types.UPDATE_LOGO_LOADING
});

export const updateLogo = (index, editType) => ({
  type: types.UPDATE_SELECTED_LOGO,
  index,
  editType
});

export function updateSelectedLogo(index, editType) {
  return (dispatch) => {
    dispatch(updateLogo(index, editType));
    setTimeout(() => {
      dispatch(updateLogoLoading());
    }, 500);
  };
}

export const updateDeleteRow = (logo) => ({
  type: types.DELETE_CHECK,
  logo
});

export const deleteLogoBegin = () => ({
  type: types.DELETE_LOGO_BEGIN
});

export const deleteLogoSuccess = (name) => ({
  type: types.DELETE_LOGO_SUCCESS,
  name
});

export const deleteLogoError = (name) => ({
  type: types.DELETE_LOGO_FAILURE,
  name
});

export function deleteLogoById(id, name, sort) {
  return (dispatch) => {
    dispatch(deleteLogoBegin());

    deleteIpgObjectPromise(MAPS, id)
      .then(() => {
        dispatch(clearLogos());

        setTimeout(() => {
          dispatch(fetchChannelLogos({ sort }))
            .then(() => {
              dispatch(deleteLogoSuccess(name));
            });
        }, 100);
      }).catch((error) => {
        dispatch(deleteLogoError(`${name} - (${error.status})`));
      });
  };
}

export const filteredChannelsBegin = () => ({
  type: types.FILTER_CHANNELS_BEGIN
});

export const filteredChannelsSuccess = (channels) => ({
  type: types.FILTER_CHANNELS_SUCCESS,
  channels
});

export const filteredChannelsError = (error) => ({
  type: types.FILTER_CHANNELS_FAILURE,
  error
});

export function filteredChannels(filter) {
  return (dispatch) => {
    dispatch(filteredChannelsBegin());
    getIpgPromise(IPG_SOURCES, null, filter)
      .then((data) => {
        dispatch(filteredChannelsSuccess(data));
      }).catch((error) => {
        dispatch(filteredChannelsError(error));
      });
  };
}

export const addLogo = () => ({
  type: types.ADD_NEW_LOGO
});

export const saveLogoBegin = () => ({
  type: types.SAVE_LOGO_BEGIN
});

export const saveLogoSuccess = (logoName) => ({
  type: types.SAVE_LOGO_SUCCESS,
  logoName
});

export const saveLogoError = (error) => ({
  type: types.SAVE_LOGO_FAILURE,
  error
});

export function saveLogo(logo, sort) {
  return (dispatch) => {
    dispatch(saveLogoBegin());

    // const logoPromises = [];
    const imageFile = logo.imageFiles[0];
    console.log(imageFile);

    const submitLogo = {};
    submitLogo.id = `${logo.id}`;
    if (imageFile.name !== undefined) {
      if (imageFile.type === 'image/png') {
        submitLogo.raster = imageFile.name;
      } else if (imageFile.type === 'image/svg') {
        submitLogo.vector = imageFile.name;
      }
    } else if (logo.raster) {
      submitLogo.raster = logo.raster;
    } else if (logo.vector) {
      submitLogo.vector = logo.vector;
    }

    // submitLogo.sources = map([...logo.sources], 'id');
    submitLogo.sources = [...logo.sources];

    putIpgPromise(MAPS, submitLogo.id, submitLogo)
      .then((response) => {
        if (!httpSuccess(response.status)) {
          return response.json()
            .then((errorObjDetails) => {
              dispatch(saveLogoError(errorObjDetails.detail));
            });
        }

        if (imageFile.name !== undefined) {
          putIpgImagePromise(LOGOS, (submitLogo.vector ? submitLogo.vector : submitLogo.raster), imageFile)
            .then((data) => {
              if (!httpSuccess(data.status)) {
                return data.json()
                  .then((errorObjDetails) => {
                    dispatch(saveLogoError(errorObjDetails.detail));
                  });
              }
              dispatch(clearLogos());

              dispatch(fetchChannelLogos({ sort }))
                .then(() => {
                  dispatch(saveLogoSuccess((submitLogo.vector ? submitLogo.vector : submitLogo.raster)));
                });
            }).catch((error) => error);
        } else {
          dispatch(clearLogos());

          dispatch(fetchChannelLogos({ sort }))
            .then(() => {
              dispatch(saveLogoSuccess((submitLogo.vector ? submitLogo.vector : submitLogo.raster)));
            });
        }
      }).catch((error) => {
        dispatch(saveLogoError(error));
      });
  };
}

export const resetSnackBar = () => ({
  type: types.RESET_SNACKBAR
});

