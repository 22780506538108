import { Add, Delete } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { connect } from 'react-redux';
import { KEYCODE_ENTER, KEYCODE_ESCAPE } from '../../../../constants/keyCodes';
import { SITE_EDIT, SITE_LIMITED } from '../../../../constants/roles';
import DragNDropIcon from '../../../../containers/DragNDrop/DragNDropIcon';
import SonifiConfirm from '../../../../containers/SonifiConfirm';
import SonifiError from '../../../../containers/SonifiError';
import SonifiIconButton from '../../../../containers/SonifiIconButton';
import SonifiLabel from '../../../../containers/SonifiLabel';
import SonifiTextInline from '../../../../containers/SonifiTextInline';
import { checkForAtLeastOneUserPermission } from '../../../../utils/rolesUtil';
import { updateDeleteRow } from '../../actions/siteManagementActions';
import {
  ROOMTYPE_ERROR_DUPLICATE, TERMLOC_ERROR_EMPTY
} from '../../constants/SiteContants';
import { getTerminalStyle, getTermLocItemStyle, reindexSequence, reorderSequence } from '../../utils';

class RoomTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editSequenceId: -1,
      addingSequenceNums: [],
      inputErrorMessage: '',
      origName: '',
      addingType: false,
      roomTypes: []
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.keyPressListener = this.keyPressListener.bind(this);
  }

  componentDidMount() {
    // TODO: Load room types and add to state when page initally rendered
    if (this.props.roomTypes) {
      this.setState({ roomTypes: [...this.props.roomTypes] });
    }
  }

  handleBlur = () => {
    if (!this.state.inputErrorMessage) {
      this.editRoomTypeName(this.state.editSequenceId);
      this.setState({
        editSequenceId: -1,
        origName: '',
      });
    }
  };

  cancelEdit() {
    const types = this.state.roomTypes;
    const pos = findIndex(types, (o) => o.sequence === this.state.editSequenceId);

    types[pos].name = this.state.origName;
    this.setState({
      roomTypes: types,
      editSequenceId: -1,
      origName: '',
      inputErrorMessage: '',
    });
  }


  // listen for either an enter or escape keypress to cancel edit
  // We can add a keypress event to SonifiTextInline, but for some reason
  // doesn't return when the ESC key is pressed.  find out why?
  keyPressListener(event) {
    if (event.target.type === 'text') {
      if (event.keyCode === KEYCODE_ESCAPE) {
        // on escape, set name back to original
        this.cancelEdit();
      } else if (event.keyCode === KEYCODE_ENTER) {
        if (this.checkForDuplicate(this.state.roomTypes, event.target.value)) {
          this.props.onRoomTypeUpdate(this.state.roomTypes, true);
          this.setState({
            inputErrorMessage: ROOMTYPE_ERROR_DUPLICATE
          });
        } else if (!this.state.inputErrorMessage) {
          const currentAddingIndexes = [...this.state.addingSequenceNums];
          const filteredIndexes = currentAddingIndexes.filter((idx) => idx !== this.state.editSequenceId);

          const avilableToEdit = filteredIndexes.length > 0;
          this.setState({
            editSequenceId: -1,
            addingSequenceNums: filteredIndexes,
            addingType: avilableToEdit
          });
        }
      }
    }
  }

  addRoomType = () => () => {
    const currentVisible = reindexSequence([...this.state.roomTypes]);
    const currentEditable = [...this.state.addingSequenceNums];

    const newSequenceId = currentVisible.length + 1;

    currentVisible.push({
      name: '',
      sequence: newSequenceId,
      inUse: false
    });
    currentEditable.push(newSequenceId);

    this.setState({
      roomTypes: currentVisible,
      addingType: true,
      addingSequenceNums: currentEditable
    });
    this.props.onRoomTypeUpdate(currentVisible, !!this.state.inputErrorMessage);
  };

  resetTerm() {
    this.setState({
      editSequenceId: -1,
      origName: ''
    });
  }

  editRoomTypeName(termSequenceIndex) {
    const inputErrorMessage = TERMLOC_ERROR_EMPTY;
    this.setState({
      inputErrorMessage
    });

    if (this.state.addingSequenceNums.indexOf(termSequenceIndex) > -1 &&
      (this.state.inputErrorMessage === TERMLOC_ERROR_EMPTY || !this.state.inputErrorMessage)
    ) {
      const origName = this.state.roomTypes.filter(((e) => e.sequence === termSequenceIndex))[0].name;
      this.setState({
        editSequenceId: termSequenceIndex,
        origName
      });
    }
  }

  confirmDialogConfirmFunc() {
    const { roomTypeIndex } = this.props;
    this.deleteRoomType(roomTypeIndex);
    this.props.dispatch(updateDeleteRow('roomTypes', null, null));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteRow('roomTypes', null, null));
  }

  deleteRoomType(sequence) {
    const currentAddingIndexes = [...this.state.addingSequenceNums];
    const filteredIndexes = currentAddingIndexes.filter((idx) => idx !== sequence);

    const types = reindexSequence(this.state.roomTypes.filter(((e) => e.sequence !== sequence)));
    this.props.onRoomTypeUpdate(types, !!this.state.inputErrorMessage);
    this.setState({
      roomTypes: types,
      editSequenceId: -1,
      origName: '',
      inputErrorMessage: '',
      addingSequenceNums: filteredIndexes
    });
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      // console.log("no-change");
      return;
    }

    let tempTypes = null;

    tempTypes =
      reorderSequence(
        this.state.roomTypes,
        result.source.index,
        result.destination.index
      );

    this.props.onRoomTypeUpdate(tempTypes, !!this.state.inputErrorMessage);
    if (!this.state.inputErrorMessage) {
      this.setState({
        editSequenceId: -1,
        roomTypes: tempTypes
      });
    }
  }

  handleRoomTypeChange = (typeIndex) => ({ target: { value } }) => {
    this.roomTypeChange(typeIndex, value);
  };

  checkForDuplicate(roomTypes, newValue) {
    const isDuplicate = (findIndex(roomTypes,
      (o) => (o.name.toUpperCase() === newValue.toUpperCase()) &&
        (o.sequence !== this.state.editSequenceId)) !== -1);
    return isDuplicate;
  }

  roomTypeChange(typeIndex, value) {
    let inputErrorMessage = '';
    const currentVisible = this.state.roomTypes;


    if (this.checkForDuplicate(currentVisible, value)) {
      inputErrorMessage = ROOMTYPE_ERROR_DUPLICATE;
    }

    if (value.length === 0 || !value.trim()) {
      inputErrorMessage = TERMLOC_ERROR_EMPTY;
    }

    currentVisible[typeIndex].name = value;

    this.props.onRoomTypeUpdate(currentVisible, !!inputErrorMessage);
    this.setState({
      roomTypes: currentVisible,
      inputErrorMessage
    });
  }

  render() {
    const {
      editSequenceId, inputErrorMessage, roomTypes, addingSequenceNums, addingType
    } = this.state;
    const {
      deleteType, generalTranslations, globalTranslations, itemName, termLocIndex, translations, userPermissions
    } = this.props;

    const allowEdit = checkForAtLeastOneUserPermission([SITE_EDIT], userPermissions);
    const allowReorder = checkForAtLeastOneUserPermission([SITE_EDIT, SITE_LIMITED], userPermissions);

    return (
      <Fragment>
        <Grid container justifyContent="space-between">
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            <SonifiLabel boldLabel={generalTranslations.title} />
          </Grid>
          <Grid item>
            {allowEdit && <SonifiIconButton label={generalTranslations.types}
              onClick={this.addRoomType()} icon={<Add />} />}
          </Grid>
        </Grid>
        <DragDropContext onDragEnd={this.onDragEnd} >
          <Droppable droppableId="droppableTermLoc" type="termLoc">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getTerminalStyle(snapshot.isDraggingOver)} >
                {roomTypes.map((type, index) => (
                  <Draggable
                    key={`roomTypes${index}`}
                    draggableId={`roomTypes${index}`}
                    isDragDisabled={!allowReorder || roomTypes.length < 2}
                    index={index} >
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        style={getTermLocItemStyle(
                          draggableSnapshot.isDragging,
                          draggableProvided.draggableProps.style,
                          index
                        )} >
                        <Grid container wrap="nowrap">
                          <Grid item xs={1}
                            {...draggableProvided.dragHandleProps}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                            {allowReorder && roomTypes.length > 1 &&
                              <DragNDropIcon style={{
                                cursor: 'move',
                                filter: `${draggableSnapshot.isDragging
                                  ? 'brightness(0) invert(1)'
                                  : 'brightness(1) invert(0)'}`,
                                verticalAlign: 'middle',
                                width: '20px'
                              }} />}
                          </Grid>
                          <Grid item xs={5} onClick={this.editRoomTypeName.bind(this, type.sequence)}>
                            {allowEdit &&
                              type.sequence === editSequenceId &&
                              addingSequenceNums.indexOf(type.sequence) > -1
                              ? <SonifiTextInline
                                defaultValue={type.name}
                                size={'sm'}
                                change={this.handleRoomTypeChange(index)}
                                handleKeyDown={this.keyPressListener}
                                disabled={!(addingType && addingSequenceNums.indexOf(type.sequence) > -1)} />
                              : <SonifiLabel label={type.name} />}
                          </Grid>
                          <Grid item xs={5} onClick={this.resetTerm.bind(this)}>
                            {allowEdit &&
                              type.sequence === editSequenceId &&
                              addingSequenceNums.indexOf(type.sequence) > -1
                              ? <SonifiError label={inputErrorMessage} />
                              : <Fragment></Fragment>
                            }
                          </Grid>
                          <Grid item xl={1}>
                            {allowEdit && roomTypes.length > 1 &&
                              <SonifiIconButton label=""
                                onClick={this.deleteRoomType.bind(this, type.sequence)}
                                icon={<Delete />}
                                useTertiary={draggableSnapshot.isDragging}
                                disabled={type.inUse ?? false} />
                            }
                          </Grid>
                        </Grid>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <SonifiConfirm
          dialogOpen={deleteType === 'roomTypes' && termLocIndex !== null && termLocIndex !== undefined}
          onConfirm={this.confirmDialogConfirmFunc.bind(this, termLocIndex)}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          confirmTitle={translations.deleteTerm}
          confirmText={`${translations.deleteText} ${(itemName
            ? `${itemName}?`
            : '')}`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteType: state.siteManagement.deleteType,
  generalTranslations: state.siteManagement.translations.roomTypes,
  globalTranslations: state.global.translations.defaults,
  itemName: state.siteManagement.itemName,
  roomTypes: state.siteManagement.roomTypes,
  translations: state.siteManagement.translations.site,
  userPermissions: state.global.permissions
});

RoomTypes.propTypes = {
  deleteType: PropTypes.string,
  dispatch: PropTypes.func,
  generalTranslations: PropTypes.object,
  globalTranslations: PropTypes.object,
  itemName: PropTypes.string,
  termLocIndex: PropTypes.number,
  onRoomTypeUpdate: PropTypes.func,
  userPermissions: PropTypes.array,
  roomTypes: PropTypes.array,
  roomTypeIndex: PropTypes.number,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(RoomTypes);
