import {
  Table, TableBody, TableCell, TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { withStyles } from 'tss-react/mui';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import UserRow from '../containers/UserRow';

const styles = () => ({
  centeredPagination: {
    flexDirection: 'column-reverse',
    alignItems: 'center'
  },
  removePaginationSpacer: {
    display: 'none'
  },
  paginationAction: {
    marginLeft: 0,
    paddingBottom: 0
  },
  pageTextStyling: {
    paddingTop: 0,
    paddingLeft: '1em'
  }
});

const DISTANCE_FROM_END = 5;

class UserGrid extends Component {
  render() {
    const {
      allowEditing, canEdit, users, handleRequestSort, translations, userState, pageChangeHandler, lastPage,
      loading, initDataLoad
    } = this.props;


    const tableHeader = [
      { id: 'given_name', sortable: true, numeric: false, label: `${translations.name}` },
      { id: 'group', sortable: true, numeric: false, label: `${translations.group}` },
      { id: 'email', sortable: true, numeric: false, label: `${translations.email}` },
      { id: 'role', sortable: true, numeric: false, label: `${translations.role}` },
      { id: 'last_login', sortable: true, numeric: false, label: `${translations.lastLogin}` },
      {
        id: 'active', sortable: false, numeric: false, label: `${translations.active}`, narrow: true
      }
    ];

    return (
      <Table stickyHeader={true}>
        <SonifiTableHead
          headColumns={tableHeader}
          order={userState.order}
          orderBy={userState.orderBy}
          onRequestSort={handleRequestSort}
        />
        {loading && initDataLoad
          ? <TableBody>
            <TableRow>
              <TableCell colSpan={6}><SonifiSpinner /></TableCell>
            </TableRow>
          </TableBody>
          : <TableBody>
            {users.length === 0 &&
              <TableRow>
                <TableCell colSpan={6}>
                  <SonifiLabel error label={(!allowEditing
                    ? translations.errors.gettingUsers
                    : translations.errors.filterUsers)} />
                </TableCell>
              </TableRow >
            }
            {users.map((option, index) => (
              <UserRow key={index} rowIndex={index} user={option} canEdit={canEdit}>
                {index === users.length - DISTANCE_FROM_END && !lastPage && (
                  <Waypoint onEnter={() => {
                    console.log('Waypoint reached: ', index);
                    if (!lastPage) {
                      pageChangeHandler();
                    }
                  }}
                  />)
                }
              </UserRow>
            ))}
          </TableBody>}
      </Table>
    );
  }
}

const mapStateToProps = (state) => ({
  allowEditing: state.users.allowEditing,
  lastPage: state.users.lastPage,
  loading: state.users.loading,
  initDataLoad: state.users.initDataLoad,
  sortUsers: state.users.sortUsers,
  translations: state.users.translations.grid,
  users: state.users.users,
});

UserGrid.propTypes = {
  allowEditing: PropTypes.bool,
  canEdit: PropTypes.bool,
  classes: PropTypes.object,
  currentPage: PropTypes.number,
  dispatch: PropTypes.func,
  handleRequestSort: PropTypes.func,
  lastPage: PropTypes.bool,
  loading: PropTypes.bool,
  initDataLoad: PropTypes.bool,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  pageChangeHandler: PropTypes.func,
  sortUsers: PropTypes.bool,
  translations: PropTypes.object,
  users: PropTypes.array,
  userState: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(UserGrid, styles, { withTheme: true }));
