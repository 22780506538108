import { CHANNELS, CHANNEL_SETS, LINEUPS, TERMINAL_RESOURCE } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import { deleteObjectPromise, getSiteObjectPromise, putObjectPromise } from '../../../utils/api';
import { getChannelsThatCanBeInLineup } from '../utils/validator';
import * as types from './actionTypes';

export function getAllLineupsForChannelSet(channelSetId, sort) {
  return (dispatch) => {
    // dispatch(setLineupLoading(true));
    dispatch(setChannelLineups([], true));

    getSiteObjectPromise(CHANNEL_SETS, `${encodeURIComponent(channelSetId)}/${LINEUPS}`, null, sort)
      .then((lineups) => {
        const lineupCalls = [];

        if (channelSetId === 'Draft' && lineups.lineups.length === 0) {
          dispatch(copyLineupsFromSetToSet('Default', 'Draft'));
        } else {
          for (let i = 0; i < lineups.lineups.length; i++) {
            const encodedId = encodeURIComponent(lineups.lineups[i].id);
            lineupCalls.push(getSiteObjectPromise(CHANNEL_SETS,
              `${encodeURIComponent(channelSetId)}/${LINEUPS}/${encodedId}`));
            lineupCalls.push(getSiteObjectPromise(CHANNEL_SETS,
              `${encodeURIComponent(channelSetId)}/${LINEUPS}/${encodedId}/${CHANNELS}`));
          }

          Promise.all(lineupCalls).then((data) => {
            const lineupsDetailed = [];

            // This works because Promise.all resolves all the promises into an array
            // that is in the same order as the array you gave it.
            for (let i = 0; i < data.length; i += 2) {
              lineupsDetailed.push({ ...data[i], ...data[i + 1] });
            }

            // lineupsDetailed = lineupsDetailed.sort((a, b) => {
            //   if (a.name.toLowerCase() < b.name.toLowerCase()) {
            //     return -1;
            //   }
            //   if (a.name.toLowerCase() > b.name.toLowerCase()) {
            //     return 1;
            //   }
            //   return 0;
            // });

            dispatch(setChannelLineups(lineupsDetailed, false));
            // dispatch(setLineupLoading(false));
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(setLineupLoading(false));
      });
  };
}

export const setChannelLineups = (channelLineups, loading) => ({
  type: types.SET_CHANNEL_LINEUPS,
  channelLineups,
  loading
});

export const setSelectedChannelLineups = (selectedChannelLineup) => ({
  type: types.SET_SELECTED_CHANNEL_LINEUP,
  selectedChannelLineup
});

export const setSavedSuccess = (successful) => ({
  type: types.SET_TV_LINEUP_SUCCESS,
  successful
});

export function saveSingleExistingLineup(channelSetId, lineup) {
  return (dispatch) => {
    dispatch(setLineupSaving(true));
    const lineupCopy = JSON.parse(JSON.stringify(lineup));
    const lineupChannels = lineup.channels;
    delete lineupCopy.channels;
    delete lineupCopy.tooltip;
    delete lineupCopy.isNew;
    lineupCopy.name = lineupCopy.name.trim();

    if (lineupCopy.power_on_ipg_source_id === '' || !lineupChannels.includes(lineupCopy.power_on_ipg_source_id)) {
      lineupCopy.power_on_ipg_source_id = lineupChannels[0];
    }

    return putObjectPromise(CHANNEL_SETS,
      `${encodeURIComponent(channelSetId)}/${LINEUPS}/${encodeURIComponent(lineup.id)}`,
      lineupCopy)
      .then((json) => {
        if (!httpSuccess(json.status)) {
          return dispatch(setLineupError(true, `Error while saving lineup (${json.status}).`));
        }

        putObjectPromise(CHANNEL_SETS,
          `${encodeURIComponent(channelSetId)}/${LINEUPS}/${encodeURIComponent(lineup.id)}/${CHANNELS}`,
          { channels: lineupChannels })
          .then((json1) => {
            if (!httpSuccess(json1.status)) {
              return dispatch(setLineupError(true, `Error while saving lineup (${json1.status}).`));
            }

            dispatch(setSavedSuccess(true));
            dispatch(setSelectedChannelLineups(null));
            dispatch(getAllLineupsForChannelSet(channelSetId));
            dispatch(setLineupSaving(false));
          }).catch((error) => dispatch(setLineupError(true, `Error while saving lineup (${error.status}).`)));
      }).catch((error) => dispatch(setLineupError(true, `Error while saving lineup (${error.status}).`)));
  };
}

export function saveBatchExistingLineups(channelSetId, lineups) {
  return (dispatch) => {
    const lineupDetailsSavePromises = [];

    for (let i = 0; i < lineups.length; i++) {
      const lineupCopy = JSON.parse(JSON.stringify(lineups[i]));
      delete lineupCopy.channels;
      const lineupDetails = lineupCopy;

      lineupDetailsSavePromises.push(putObjectPromise(CHANNEL_SETS,
        `${encodeURIComponent(channelSetId)}/${LINEUPS}/${encodeURIComponent(lineups[i].id)}`,
        lineupDetails));
    }

    Promise.all(lineupDetailsSavePromises)
      .then(() => {
        const lineupChannelsSavePromises = [];
        for (let i = 0; i < lineups.length; i++) {
          const lineupChannels = lineups[i].channels;

          lineupChannelsSavePromises.push(putObjectPromise(CHANNEL_SETS,
            `${encodeURIComponent(channelSetId)}/${LINEUPS}/${encodeURIComponent(lineups[i].id)}/${CHANNELS}`,
            { channels: lineupChannels }));
        }


        Promise.all(lineupChannelsSavePromises).then(() => {
          dispatch(getAllLineupsForChannelSet(channelSetId));
        });
      });
  };
}

export const setLineupLoading = (loading) => ({
  type: types.SET_LINEUPS_LOADING,
  loading
});

export const setLineupSaving = (saving) => ({
  type: types.SET_LINEUPS_SAVING,
  saving
});

export const setLineupError = (error, message) => ({
  type: types.SET_LINEUPS_ERROR,
  error,
  message
});

export function updateAllLineupsOnChannelEdit(lineups, channelSetList, channelSetId) {
  return (dispatch) => {
    for (let i = 0; i < lineups.length; i++) {
      const availableChannelsForLineup = getChannelsThatCanBeInLineup(lineups[i], channelSetList);
      const updatedLineupChannels = [];

      if (availableChannelsForLineup.length === 0) {
        lineups[i].channels = [];
        for (let j = 0; j < channelSetList.length; j++) {
          if (channelSetList[j] && channelSetList[j].ipg && channelSetList[j].ipg.source_id !== '') {
            lineups[i].power_on_ipg_source_id = channelSetList[j].ipg.source_id;
            break;
          }
        }
      } else {
        for (let j = 0; j < lineups[i].channels.length; j++) {
          if (availableChannelsForLineup.includes(lineups[i].channels[j])) {
            updatedLineupChannels.push(lineups[i].channels[j]);
          }
        }

        lineups[i].channels = updatedLineupChannels;

        if (!availableChannelsForLineup.includes(lineups[i].power_on_ipg_source_id)) {
          lineups[i].power_on_ipg_source_id = availableChannelsForLineup[0];
        }
      }
    }
    dispatch(saveBatchExistingLineups(channelSetId, lineups));
  };
}

export function copyLineupsFromSetToSet(channelSetFrom, channelSetTo) {
  return (dispatch) => {
    getSiteObjectPromise(CHANNEL_SETS, `${encodeURIComponent(channelSetFrom)}/${LINEUPS}`)
      .then((lineups) => {
        const lineupCalls = [];

        if (lineups.lineups.length === 0) {
          dispatch(setChannelLineups([], false));
          // dispatch(setLineupLoading(false));
          return;
        }

        for (let i = 0; i < lineups.lineups.length; i++) {
          const encodedLineupId = encodeURIComponent(lineups.lineups[i].id);
          lineupCalls.push(getSiteObjectPromise(CHANNEL_SETS,
            `${encodeURIComponent(channelSetFrom)}/${LINEUPS}/${encodedLineupId}`));
          lineupCalls.push(
            getSiteObjectPromise(CHANNEL_SETS,
              `${encodeURIComponent(channelSetFrom)}/${LINEUPS}/${encodedLineupId}/${CHANNELS}`)
          );
        }

        Promise.all(lineupCalls).then((data) => {
          const lineupsDetailed = [];

          // This works because Promise.all resolves all the promises into an array
          // that is in the same order as the array you gave it.
          for (let i = 0; i < data.length; i += 2) {
            lineupsDetailed.push({ ...data[i], ...data[i + 1] });
          }

          dispatch(saveBatchExistingLineups(channelSetTo, lineupsDetailed));
        });
      })
      .catch((error) => console.log(error));
  };
}

// export function deleteAChannelLineup(channelSetId, lineupId) {
//   return (dispatch) => {
//     dispatch(setLineupSaving(true));

//     deleteObjectPromise(CHANNEL_SETS,
//       `${encodeURIComponent(channelSetId)}/${LINEUPS}/${encodeURIComponent(lineupId)}`)
//       .then((json) => {
//         dispatch(closeLineupSaveSuccess());
//         dispatch(getAllLineupsForChannelSet(channelSetId));
//         dispatch(setLineupSaving(false));
//         return json;
//       }).catch((error) => {
//         dispatch(closeLineupSaveSuccess());
//         dispatch(getAllLineupsForChannelSet(channelSetId));
//         dispatch(setLineupSaving(false));
//         return error;
//       });
//   };
// }

// export function deleteAllChannelLineups(channelSetId, lineupList) {
//   return (dispatch) => {
//     dispatch(setLineupSaving(true));

//     const deleteCalls = lineupList.map((lineup) => deleteObjectPromise(CHANNEL_SETS,
//       `${encodeURIComponent(channelSetId)}/${LINEUPS}/${encodeURIComponent(lineup.id)}`));

//     Promise.all(deleteCalls).then(() => {
//       dispatch(setLineupSaving(true));
//     });
//   };
// }

// export function overwriteAllChannelLineups(channelSetId, channelSetList, lineupList) {
//   return (dispatch) => {
//     dispatch(setLineupLoading(true));
//     dispatch(setChannelLineups([]));
//     getSiteObjectPromise(CHANNEL_SETS, `${encodeURIComponent(channelSetId)}/${LINEUPS}`)
//       .then((data) => {
//         const deleteCalls = data.lineups.map((lineup) => deleteObjectPromise(CHANNEL_SETS,
//           `${encodeURIComponent(channelSetId)}/${LINEUPS}/${encodeURIComponent(lineup.id)}`));

//         Promise.all(deleteCalls).then(() => {
//           dispatch(updateAllLineupsOnChannelEdit(lineupList, channelSetList, channelSetId));
//           dispatch(setLineupLoading(false));
//         });
//       });
//   };
// }

export const closeLineupSaveSuccess = () => ({
  type: types.CLOSE_SUCCESS_LINEUP_SAVE
});

export const closeLineupSaveError = () => ({
  type: types.CLOSE_ERROR_LINEUP_SAVE
});

export const detailsEditSuccess = (editable) => ({
  type: types.DETAILS_EDIT_SUCCESS,
  editable: !editable
});

export const loadingLineup = (loading) => ({
  type: types.LOADING_LINEUP,
  loading
});

export function canDetailsBeEdited(lineupId) {
  return (dispatch) => {
    dispatch(loadingLineup(true));
    return getSiteObjectPromise(`${TERMINAL_RESOURCE}?lineup_id=${encodeURIComponent(lineupId)}`)
      .then((json) => {
        dispatch(detailsEditSuccess(json.terminals && json.terminals.length > 0));
        return;
      }).catch(() => {
        dispatch(detailsEditSuccess(false));
        return;
      });
  };
}

export const deleteLineupCheck = (lineup) => ({
  type: types.DELETE_LINEUP_CHECK,
  lineup
});

export const deleteSingleLineupBegin = () => ({
  type: types.DELETE_SINGLE_LINEUP_BEGIN
});

export const deleteSingleLineupSuccess = (lineupName) => ({
  type: types.DELETE_SINGLE_LINEUP_SUCCESS,
  lineupName
});

export const deleteSingleLineupFailure = (lineupName) => ({
  type: types.DELETE_SINGLE_LINEUP_FAILURE,
  lineupName
});

export const setLineupSnackBar = (snackBarMessage = '', snackBarType) => ({
  type: types.SET_LINEUP_SNACKBAR,
  snackBarMessage,
  snackBarType
});

export function deleteSingleLineup(lineup, channelSetId) {
  return (dispatch) => {
    dispatch(deleteSingleLineupBegin());
    deleteObjectPromise(CHANNEL_SETS,
      `${encodeURIComponent(channelSetId)}/${LINEUPS}/${encodeURIComponent(lineup.id)}`)
      .then((json) => {
        dispatch(getAllLineupsForChannelSet(channelSetId));
        dispatch(deleteSingleLineupSuccess(lineup.name));
        return json;
      }).catch((error) => {
        dispatch(getAllLineupsForChannelSet(channelSetId));
        dispatch(deleteSingleLineupFailure(lineup.name));
        return error;
      });
  };
}
