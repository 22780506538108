import { maxBy } from 'lodash';
import { BATCH_CHANGES, SOFTWARE_OPTIONS, SYSTEMS } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import { getSiteObjectPromise, postSitelessObjectPromise } from '../../../utils/api';
import { justSetUpgradeWindow } from '../../SystemManagement/actions/systemManagementActions';
import * as types from './actionTypes';

export const initSoftwareChanges = () => ({
  type: types.INIT_SOFTWARE_CHANGES
});

export const updateSoftwareTable = (tableInfo) => ({
  type: types.UPDATE_SOFTWARE_TABLE,
  tableInfo
});

export const updateSoftwareChangesSnackbar = (messsage, msgType) => ({
  type: types.UPDATE_SOFTWARE_CHANGES_SNACKBAR,
  messsage,
  msgType
});

export const updateTableListResponse = (index, success, details) => ({
  type: types.UPDATE_TABLE_RESPONSE,
  index,
  success,
  details
});

export const showPopup = (rowInfo) => ({
  type: types.SHOW_POPUP,
  rowInfo
});

export const parseSoftwareBegin = () => ({
  type: types.PARSE_SOFTWARE_BEGIN
});

export function checkSoftwareChanges(site, productParentObj) {
  return getSiteObjectPromise(SOFTWARE_OPTIONS, null, site.id)
    .then((response) => {
      if (!productParentObj || !productParentObj.id) {
        console.log('No Product Parent Matches here.');
        return { software: response, system: null };
      }
      return getSiteObjectPromise(SYSTEMS, null, site.id)
        .then((allSystemsResponse) => {
          if (allSystemsResponse && allSystemsResponse.length > 0) {
            const systemId = maxBy(allSystemsResponse, (o) => o.date).id;
            return getSiteObjectPromise(SYSTEMS, systemId, site.id)
              .then((systemResponse) => {
                if (systemResponse.hosts) {
                  const vmcs = Object.keys(systemResponse.hosts).map((key) => (key));
                  const configuredVersions = [];
                  const configuredHosts = [];
                  for (let i = 0; i < vmcs.length; i++) {
                    const ppExists = systemResponse.hosts[vmcs[i]]['product-parents']
                      .find((o) => o.id === productParentObj.id);

                    if (ppExists) {
                      configuredHosts.push(vmcs[i]);
                      configuredVersions.push(`${ppExists.version ? ppExists.version : '---'}`);
                    }
                  }
                  return {
                    software: response, system: { systemId, hosts: configuredHosts, versions: configuredVersions }
                  };
                }

                return { software: response, system: null };
              });
          }
          return { software: response, system: null };
        })
        .catch(() => (
          { software: response, system: null }
        ));
    })
    .catch(() => ({ software: null, system: null }));
}

export const saveSoftwareBegin = () => ({
  type: types.SAVE_SOFTWARE_CHANGES_BEGIN
});

export const saveSoftwareSuccess = (termId) => ({
  type: types.SAVE_SOFTWARE_CHANGES_SUCCESS,
  termId
});

export const saveSoftwareError = (error) => ({
  type: types.SAVE_SOFTWARE_CHANGES_FAILURE,
  error
});

export function saveSoftwareChanges(site) {
  const softwareOptions = getSoftwareObj(site);

  if (site.error?.id) {
    return Promise.resolve({ siteId: site.id, duplicate: true, error: site.error?.id });
  }

  if (softwareOptions === null) {
    return Promise.resolve({ siteId: site.id, software: true });
  }

  const promises = [];
  if (softwareOptions) {
    promises.push(justSetUpgradeWindow(softwareOptions, site.id));
  }

  return Promise.all(promises)
    .then((responses) => responses)
    .catch((error) => error);
}

export function getSoftwareObj(site) {
  const softwareObj = { ...site.response.software };
  let updated = false;
  if (site.automatic_updates !== '' && site.automatic_updates !== softwareObj['update-mode']) {
    softwareObj['update-mode'] = site.automatic_updates;
    updated = true;
  }

  if (site.software_channel !== '' && site.software_channel !== softwareObj.channel) {
    softwareObj.channel = site.software_channel;
    updated = true;
  }

  // if (site.virtos_version !== '' && parseInt(site.virtos_version, 10) !== parseInt(softwareObj.virtos, 10)) {
  //   softwareObj.virtos = parseInt(site.virtos_version, 10);
  //   updated = true;
  // }
  return updated ? softwareObj : null;
}

export function getSystemObj(site) {
  if (site.response.system && site.response.system.hosts.length > 0 &&
    site.release_version !== '---' && site.release_version !== null) {
    return { version: site.release_version };
  }
  return null;
}

export const saveBatchSoftwareFailure = (error) => ({
  type: types.SAVE_BATCH_SOFTWARE_CHANGES_FAILURE,
  error
});

export function saveBatchSoftware(batchTable) {
  return (dispatch) => {
    const batchArray = [];
    for (let i = 0; i < batchTable.length; i++) {
      if (batchTable[i].version) {
        batchArray.push({
          system_id: (batchTable[i].system_id !== batchTable[i].response.system.systemId
            ? batchTable[i].response.system.systemId
            : batchTable[i].system_id),
          site_id: batchTable[i].id,
          product_code: batchTable[i].product_code,
          version: batchTable[i].version
        });
      }
    }

    if (batchArray.length < 1) {
      dispatch(saveSoftwareSuccess());
      return Promise.resolve();
    }

    const promises = [];
    while (batchArray.length) {
      promises.push(postSitelessObjectPromise(BATCH_CHANGES, null, { updates: batchArray.splice(0, 10) }));
    }

    return Promise.all(promises)
      .then((responses) => {
        let errorMsgs = '';
        for (let i = 0; i < responses.length; i++) {
          if (responses[i] && !httpSuccess(responses[i].status)) {
            const errorMsg = `${responses[i].title}:${responses[i].detail}`;
            errorMsgs += `${errorMsg} \n `;
          }
        }

        if (errorMsgs !== '') {
          dispatch(saveBatchSoftwareFailure(errorMsgs));
        } else {
          dispatch(saveSoftwareSuccess());
        }
        return responses;
      })
      .catch((error) => {
        dispatch(saveBatchSoftwareFailure(error.status));
        return error;
      });
  };
}

