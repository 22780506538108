import { Cancel, Check } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ERROR } from '../../constants/constants';
import { httpSuccess } from '../../constants/http';
import { SONIFI_ADMIN } from '../../constants/roles';
import { SonifiIconButton } from '../../containers';
import SonifiLockoutModalSpinner from '../../containers/SonifiLockoutModalSpinner';
import SonifiSnackbar from '../../containers/SonifiSnackBar';
import SonifiTemplate from '../../containers/SonifiTemplate';
import { checkForSingleUserPermission } from '../../utils/rolesUtil';
import { getProductParents } from '../Deployments/actions/deploymentsActions';
import * as actions from './actions/softwareChangesActions';
import SoftwareDialog from './components/SoftwareDialog';
import SoftwareDrop from './components/SoftwareDrop';
import { findProductParentId, parseSoftware } from './utils/softwareImport';

class SoftwareChanges extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getProductParents(true));
    dispatch(actions.initSoftwareChanges());
  }

  saveSoftwareChanges() {
    const { dispatch, tableList } = this.props;

    dispatch(actions.saveSoftwareBegin());

    const softwareOpt = tableList.map((site) => actions.saveSoftwareChanges(site));

    Promise.all(softwareOpt)
      .then((responses) => {
        for (let i = 0; i < responses.length; i++) {
          if (responses[i].siteId && responses[i].duplicate) {
            dispatch(actions.updateTableListResponse(i, !responses[i].duplicate, responses[i].error));
          } else if (responses[i].siteId && responses[i].system === true && responses[i].software === true) {
            dispatch(actions.updateTableListResponse(i, true));
          } else {
            let isSuccessful = true,
              failureMsgs = '';
            for (let j = 0; j < responses[i].length; j++) {
              isSuccessful = isSuccessful && httpSuccess(responses[i][j].status);
              if (!httpSuccess(responses[i][j].status)) {
                failureMsgs = failureMsgs + responses[i][j].details;
              }
            }
            dispatch(actions.updateTableListResponse(i, isSuccessful, failureMsgs));
          }
        }
      }).finally(() => {
        const miscObj = JSON.parse(JSON.stringify(this.props.tableList));
        const softwareUpdates = filter(miscObj, (o) => {
          if (o.system_id === '' || o?.response?.system?.hosts.length < 1) {
            return false;
          }
          return !o.error?.id;
        });

        dispatch(actions.saveBatchSoftware(softwareUpdates));
      });
  }

  reset() {
    this.props.dispatch(actions.initSoftwareChanges());
  }

  closeSnackBar() {
    this.props.dispatch(actions.updateSoftwareChangesSnackbar(''));
  }

  parseImportFile(result) {
    const { dispatch, productParents, translations } = this.props;
    dispatch(actions.parseSoftwareBegin());

    const pChannels = parseSoftware(result, translations.headers);

    if (pChannels.sites.length === 0) {
      this.props.dispatch(actions.updateSoftwareChangesSnackbar(translations.errors.columnHeaders, ERROR));
    }

    const softwareOpt = pChannels.sites.map((site) => actions.checkSoftwareChanges(site,
      findProductParentId(site.product_code, productParents)));

    Promise.all(softwareOpt)
      .then((responses) => {
        for (let i = 0; i < responses.length; i++) {
          if (!responses[i].software) {
            pChannels.sites[i].allowSave = false;
            pChannels.sites[i].error = { no_software: translations.errors.noSoftware };
            pChannels.sites[i].toolTip = translations.errors.noSoftware;
          }
          if (!responses[i].system) {
            pChannels.sites[i].allowSave = false;
            pChannels.sites[i].error = { no_system: translations.errors.noSystems };
            pChannels.sites[i].toolTip = translations.errors.noSystems;
          } else if (responses[i]?.system?.systemId) {
            if (pChannels.sites[i].system_id === '') {
              pChannels.sites[i].system_id = responses[i].system.systemId;
            } else if (pChannels.sites[i].system_id !== `${responses[i].system.systemId}`) {
              pChannels.sites[i].error = { system_id: translations.errors.systemId };
              pChannels.sites[i].toolTip = translations.errors.systemId;
            }
          }

          if (!responses[i].software && !responses[i].system) {
            pChannels.sites[i].toolTip = translations.errors.marinaSite;
          }

          pChannels.sites[i].response = responses[i];
        }

        let siteId = '0';
        for (let i = 0; i < pChannels.sites.length; i++) {
          if (siteId === pChannels.sites[i].id) {
            if (pChannels.sites[i].error) {
              pChannels.sites[i].error.id = translations.errors.siteId;
            } else {
              pChannels.sites[i].error = { id: translations.errors.siteId };
            }
            pChannels.sites[i].toolTip = translations.errors.siteId;
            pChannels.sites[i].allowSave = false;
          }
          siteId = pChannels.sites[i].id;
        }

        dispatch(actions.updateSoftwareTable(
          {
            tableList: pChannels.sites,
            allowSave: pChannels.siteErrors,
            headers: pChannels.tableHeader,
            columnNames: pChannels.columnNames
          }
        ));
      });
  }

  getPageDetails() {
    return <Grid container style={{ height: '100%' }}>
      <Grid item xs={12}>
        <SoftwareDrop
          parseImportFile={this.parseImportFile.bind(this)}
        />
      </Grid>
    </Grid>;
  }

  render() {
    const {
      globalTranslations, popupInfo, saving, snackBarMessage, snackBarType, tableList, translations, userPermissions
    } = this.props;

    if (!checkForSingleUserPermission(SONIFI_ADMIN, userPermissions)) {
      return <Navigate replace to="/admin" />;
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={saving} />
        {popupInfo !== null && <SoftwareDialog />}
        <SonifiTemplate
          header={translations.title}
          showButton={false}
          middleExtra={
            <Grid container>
              <Grid item>
                <SonifiIconButton disabled={tableList && tableList.length < 1}
                  onClick={this.saveSoftwareChanges.bind(this)} icon={<Check />}
                  label={globalTranslations.defaults.save} />
              </Grid>
              <Grid item>
                <SonifiIconButton disabled={tableList && tableList.length < 1}
                  onClick={this.reset.bind(this)} icon={<Cancel />}
                  label={globalTranslations.defaults.cancel} />
              </Grid>
            </Grid>
          }
          pageDetails={this.getPageDetails()} />
        <SonifiSnackbar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations,
  popupInfo: state.softwareChanges.popupInfo,
  productParents: state.deployments.productParents,
  saving: state.softwareChanges.saving,
  snackBarMessage: state.softwareChanges.snackBarMessage,
  snackBarType: state.softwareChanges.snackBarType,
  tableList: state.softwareChanges.tableList,
  translations: state.softwareChanges.translations.grid,
  userPermissions: state.global.permissions
});

SoftwareChanges.propTypes = {
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  popupInfo: PropTypes.object,
  productParents: PropTypes.array,
  saving: PropTypes.bool,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  tableList: PropTypes.array,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(SoftwareChanges);
