import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import { getTranslationObject } from '../../../utils/index';
import * as actions from '../actions/actionTypes';
import en from '../i18n/en';
import es from '../i18n/es';

const langs = { en, es };

const initialState = {
  addingRange: false,
  addRoomLoading: false,
  addRoomStatus: null,
  change: true,
  deleteRange: false,
  deleteRooms: null,
  deleting: false,
  dialogError: null,
  dialogLoading: false,
  endRoom: null,
  error: null,
  filterObj: {},
  loading: false,
  occupiedRoomCount: null,
  open: false,
  retrievingRooms: false,
  roomIndex: null,
  rooms: [],
  selectedRoom: {},
  snackBarMessage: '',
  snackBarType: SUCCESS,
  startRoom: null,
  totalNumRooms: null,
  translations: null
};

/* eslint-disable max-lines-per-function */
export default function roomsReducer(state = initialState, action) {
  const updatedRooms = [...state.rooms];
  const editRoom = state.selectedRoom;
  let miscObject = null;
  switch (action.type) {
    case actions.CLOSE_ROOM_INFO:
      return {
        ...state,
        error: null,
        open: false,
        roomIndex: null,
        selectedRoom: {},
        terminals: [],
        dialogError: null,
        newRoomArr: [],
        addRoomLoading: false,
        numRoomsToAdd: 0
      };
    case actions.FETCH_ROOMS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
        open: false,
        snackBarMessage: ''
      };
    case actions.FETCH_ROOMS_SUCCESS:
      return {
        ...state,
        loading: false,
        totalNumRooms: action.products.total_items,
        ...action.products,
      };
    case actions.FETCH_ROOMS_FAILURE:
      action.error.message = state.translations.errors.noRooms;
      return {
        ...state,
        loading: false,
        error: action.error,
        rooms: []
      };
    case actions.FETCH_OCCUPIED_ROOM_COUNT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
        open: false
      };
    case actions.FETCH_OCCUPIED_ROOM_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        occupiedRoomCount: action.occupiedRoomCount,
        change: !state.change
      };
    case actions.FETCH_OCCUPIED_ROOM_COUNT_FAILURE:
      action.error.message = state.translations.errors.noRooms;
      return {
        ...state,
        loading: false,
        error: action.error,
        occupiedRoomCount: null
      };
    case actions.FETCH_ROOM_OPTIONS_SUCCESS:
      miscObject = action.products.buildings;
      if (!miscObject) {
        miscObject = [];
      }
      return {
        ...state,
        ...action.products,
        buildings: miscObject
      };
    case actions.FETCH_ROOM_OPTIONS_FAILURE:
      action.error.message = state.translations.errors.roomOpts;
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case actions.FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case actions.FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actions.GET_ROOM_INFO_BEGIN:
      return {
        ...state,
        error: null,
        open: true,
        roomIndex: action.roomIndex,
        dialogLoading: true
      };
    case actions.GET_ROOM_INFO_SUCCESS:
      updatedRooms[state.roomIndex] = action.products;
      if (!updatedRooms[state.roomIndex].is_occupied || updatedRooms[state.roomIndex].is_occupied === null) {
        updatedRooms[state.roomIndex].is_occupied = false;
      }

      if (!updatedRooms[state.roomIndex].building && state.buildings.length === 1) {
        updatedRooms[state.roomIndex].building = state.buildings[0].name;

        if (!updatedRooms[state.roomIndex].floor && state.buildings[0].floors.length === 1) {
          updatedRooms[state.roomIndex].floor = state.buildings[0].floors[0].name;
        }
      }

      return {
        ...state,
        rooms: updatedRooms,
        selectedRoom: updatedRooms[state.roomIndex],
        // selectedRoom: { ...updatedRooms[state.roomIndex], content_restriction_pin: 1515 },
        dialogLoading: false
      };
    case actions.GET_ROOM_INFO_FAILURE:
      action.error.message = state.translations.errors.roomInfo;
      return {
        ...state,
        error: action.error,
        dialogLoading: false
      };
    case actions.UPDATE_SELECTED_ROOM:
      editRoom[`${action.name}`] = action.value;
      return { ...state, selectedRoom: { ...editRoom } };
    case actions.FILTER_ROOMS_BEGIN:
      return { ...state, filterObj: action.products, loading: true };
    case actions.FILTER_ROOMS_FAILURE:
      return { ...state, rooms: [], loading: false };
    case actions.FILTER_ROOMS_SUCCESS:
      return { ...state, ...action.products, change: !state.change, loading: false };
    case actions.PUT_ROOM_BEGIN:
    case actions.PATCH_ROOM_BEGIN:
      return { ...state, dialogLoading: true };
    case actions.PUT_ROOM_SUCCESS:
    case actions.PATCH_ROOM_SUCCESS:
    case actions.PATCH_ROOM_FAILURE:
      return {
        ...state,
        dialogLoading: false,
        snackBarType: SUCCESS,
        snackBarMessage: state.translations.addDialog.saveSuccess
      };
    case actions.DELETE_CHECK:
      return {
        ...state,
        startRoom: action.start,
        endRoom: action.end,
        deleteRange: action.deleteRange
      };
    case actions.ADD_PUT_ROOM_BEGIN:
    case actions.ADD_PUT_ROOM_CLOSE:
    case actions.ADD_PUT_ROOM_FAILURE:
      return {
        ...state,
        addRoomSuccess: '',
        addingRoom: false,
        newRoomArr: null,
        deleting: false
      };
    case actions.DELETE_ROOM_FAILURE:
      return {
        ...state,
        addRoomSuccess: '',
        addingRoom: false,
        newRoomArr: null,
        dialogType: null,
        deleting: false,
        snackBarMessage: `${state.translations.addDialog.deleteRoomFailure} ${action.error}`,
        snackBarType: ERROR
      };
    case actions.ADD_PUT_ROOM_SUCCESS:
      miscObject = [];
      if (state.newRoomArr) {
        miscObject = [...state.newRoomArr];
      }
      miscObject.push(action.newRoomArr[0]);
      return {
        ...state,
        addRoomSuccess: action.type,
        addingRoom: (action.type === actions.ADD_PUT_ROOM_BEGIN),
        newRoomArr: miscObject,
        addRoomLoading: !(miscObject.length === state.numRoomsToAdd)
      };
    case actions.PUT_ROOM_FAILURE:
    case actions.SET_DIALOG_ERROR:
      return { ...state, dialogLoading: false, dialogError: action.error };
    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };
    case actions.DELETE_ROOM_BEGIN:
      return { ...state, deleting: true };
    case actions.DELETE_ROOM_SUCCESS:
      return {
        ...state,
        addingRoom: (action.type === actions.ADD_PUT_ROOM_BEGIN),
        addRoomLoading: false,
        addRoomSuccess: action.type,
        deleteRooms: null,
        dialogType: null,
        numRoomsToAdd: 0,
        retrievingRooms: false,
        deleting: false,
        snackBarMessage: `${state.translations.addDialog.deleteSuccessSingle} ${action.room}`,
        snackBarType: SUCCESS
      };
    case actions.RESET_ADD_ROOM:
      return {
        ...state,
        newRoomArr: [],
        addRoomLoading: true,
        retrievingRooms: false,
        snackBarMessage: '',
        numRoomsToAdd: 0
      };

    case actions.RESET_SNACKBAR:
      return { ...state, snackBarMessage: '' };

    case actions.START_DELETE_ROOM:
      return { ...state, retrievingRooms: true };

    case actions.GET_ROOMS_TO_DELETE_BEGIN:
      return { ...state, deleteRooms: null };

    case actions.GET_ROOMS_TO_DELETE_SUCCESS:
      return { ...state, retrievingRooms: false, deleteRooms: action.rooms };

    case actions.GET_ROOMS_TO_DELETE_FAILURE:
      return {
        ...state,
        retrievingRooms: false,
        snackBarMessage: `${state.translations.addDialog.getDeleteRoomError} ${action.error}`,
        snackBarType: ERROR
      };

    case actions.INVALID_FORM:
      return {
        ...state,
        retrievingRooms: false
      };

    case actions.DELETE_RANGE_BEGIN:
      return { ...state, retrievingRooms: false, addRoomLoading: false, deleting: true };

    case actions.DELETE_RANGE_SUCCESS:
      return {
        ...state,
        deleting: false,
        dialogType: null,
        snackBarMessage: `${state.translations.addDialog.deleteSuccess} ${action.rooms}`,
        snackBarType: SUCCESS
      };

    case actions.DELETE_RANGE_FAILURE:
      return {
        ...state,
        deleting: false,
        snackBarMessage: `${state.translations.addDialog.deleteRangeFailure} ${action.error}`,
        snackBarType: ERROR
      };

    case actions.DIALOG_TYPE:
      return { ...state, dialogType: action.dialogType, deleteRooms: null, snackBarMessage: '' };

    case actions.DONE_ADDING_ROOMS:
      return { ...state, numRoomsToAdd: action.numRooms, addRoomLoading: action.numRooms.length > 1 };

    case actions.START_ADD_RANGE:
      return {
        ...state, addingRange: true, addRoomLoading: false, retrievingRooms: false, addRoomStatus: null
      };

    case actions.ADD_RANGE:
      miscObject = { added: [], duplicates: [], errors: [] };
      for (let i = 0; i < action.duplicates.length; i++) {
        miscObject.duplicates.push(action.duplicates[i].id);
      }

      for (let i = 0; i < action.added.length; i++) {
        if (httpSuccess(action.added[i].status)) {
          miscObject.added.push(decodeURIComponent(action.added[i].url.split(/[/ ]+/).pop()));
        } else {
          miscObject.errors.push(action.added[i].url.split(/[/ ]+/).pop());
        }
      }

      return { ...state, addingRange: false, addRoomLoading: false, addRoomStatus: miscObject };

    case actions.RESET_ADD_RANGE:
      return {
        ...state,
        addingRange: false,
        addRoomLoading: false,
        retrievingRooms: false,
        addRoomStatus: null,
        deleteRooms: null
      };

    case actions.RESET_PIN_BEGIN:
      return { ...state, dialogLoading: true };

    case actions.RESET_PIN_COMPLETE:
      return {
        ...state,
        dialogLoading: false,
        selectedRoom:
        {
          ...state.selectedRoom,
          content_restriction_pin: (action.success ? null : state.selectedRoom.content_restriction_pin)
        }
      };

    default:
      return state;
  }
}
