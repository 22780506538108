import { filter, forEach, uniqBy } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ADD, ERROR, READ, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };
export const initialState = {
  channels: null,
  deleteLogo: null,
  editType: READ,
  getLogoLoading: false,
  loadingLogos: false,
  loadingLogo: false,
  maps: [],
  popupLoading: false,
  saving: false,
  selectedLogo: -1,
  translations: null,
  snackBarType: SUCCESS,
  snackBarMessage: '',
  logoError: false,
  maxPages: 0,
  pageSize: 0,
  sorting: false,
  totalItems: 0
};

/* eslint-disable max-lines-per-function */
export default function logoReducer(state = initialState, action) {
  const updatedLogos = [...state.maps];
  let miscObj = null;

  switch (action.type) {
    case types.START_LOGOS:
      return { ...state, loadingLogos: true, maps: [] };

    case types.FETCH_CHANNEL_LOGOS_BEGIN:
      return {
        ...state, selectedLogo: -1, snackBarMessage: '', logoError: false
      };

    case types.FETCH_CHANNEL_LOGOS_SUCCESS:
      miscObj = [...state.maps, ...action.logos.maps];

      // Filtering out all the source ids that look like (as they have no value):
      // {
      //   "id": null,
      //   "name": null,
      //   "affiliate": null,
      //   "call_letters": null,
      //   "official_call_sign": null,
      //   "timezone": null
      // },
      forEach(miscObj, (value) => {
        value.sources = filter(value.sources, (o) => {
          if (o.id === null && o.name === null &&
            o.affiliate === null && o.call_letters === null &&
            o.official_call_sign === null && o.timezone === null) {
            return;
          }
          return o;
        });
      });

      return {
        ...state,
        maps: uniqBy(miscObj, (e) => e.id),
        loadingLogos: false,
        logoError: false,
        maxPages: action.logos.page_count,
        pageSize: action.logos.page_size,
        totalItems: action.logos.total_items,
        sorting: false
      };

    case types.FETCH_CHANNEL_LOGOS_FAILURE:
      return {
        ...state,
        maps: [],
        loadingLogos: false,
        logoError: true,
        snackBarType: ERROR,
        snackBarMessage: `${state.translations.grid.errors.retrieving} - (${action.error})`,
        sorting: false
      };

    case types.DELETE_CHECK:
      return { ...state, deleteLogo: action.logo };

    case types.CLEAR_LOGOS:
      return {
        ...state, sorting: true, maps: [], maxPages: 0, pageSize: 0, totalItems: 0
      };

    case types.UPDATE_SELECTED_LOGO:
      if (action.index === -1) {
        miscObj = updatedLogos.filter((word) => word.id !== ADD);
      } else {
        miscObj = updatedLogos;
      }
      return {
        ...state,
        selectedLogo: action.index,
        editType: action.editType,
        maps: miscObj,
        channels: null,
        getLogoLoading: (action.index !== -1),
        snackBarMessage: ''
      };

    case types.UPDATE_LOGO_LOADING:
      return { ...state, getLogoLoading: false };

    case types.DELETE_LOGO_BEGIN:
      return { ...state, popupLoading: true };

    case types.DELETE_LOGO_SUCCESS:
      return {
        ...state,
        popupLoading: false,
        selectedLogo: -1,
        deleteLogo: null,
        snackBarType: SUCCESS,
        snackBarMessage: `Successfully deleted ${action.name}`
      };

    case types.DELETE_LOGO_FAILURE:
      return {
        ...state,
        popupLoading: false,
        selectedLogo: -1,
        deleteLogo: null,
        snackBarType: ERROR,
        snackBarMessage: `Error deleting ${action.name}`
      };


    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    case types.FILTER_CHANNELS_BEGIN:
      return { ...state, loadingChannels: true };

    case types.FILTER_CHANNELS_SUCCESS:
      if (action.channels === null) {
        miscObj = null;
      } else {
        miscObj = (action.channels.sources && action.channels.sources.length > 0 ? action.channels.sources : []);
      }
      return { ...state, loadingChannels: false, channels: miscObj };

    case types.FILTER_CHANNELS_FAILURE:
      return { ...state, loadingChannels: false, channels: [] };

    case types.ADD_NEW_LOGO:
      updatedLogos.push({
        id: ADD, raster: '', sources: [], vector: '', isNew: true
      });
      return {
        ...state,
        loadingLogo: false,
        maps: updatedLogos,
        channels: null,
        selectedLogo: state.maps.length,
        editType: 'edit',
        snackBarMessage: ''
      };

    case types.SAVE_LOGO_BEGIN:
      return { ...state, saving: true, snackBarMessage: '' };

    // vector = svg
    // raster = png
    case types.SAVE_LOGO_SUCCESS:
      return {
        ...state,
        saving: false,
        selectedLogo: -1,
        channels: [],
        snackBarType: SUCCESS,
        snackBarMessage: `Successfully saved ${(action.logoName)}`
      };

    case types.SAVE_LOGO_FAILURE:
      return {
        ...state,
        saving: false,
        snackBarType: ERROR,
        snackBarMessage: `Error Saving Channel Logo - ${action.error}`
      };

    case types.RESET_SNACKBAR:
      return { ...state, snackBarMessage: '' };

    default:
      return state;
  }
}
