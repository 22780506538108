import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { KEYBOARD_DELAY } from '../../constants/keyCodes';
import SonifiSnackbar from '../../containers/SonifiSnackBar';
import SonifiTemplate from '../../containers/SonifiTemplate';
import { buildSortString } from '../../utils';
import { removePipe } from '../../utils/textUtil';
import * as actions from './actions/auditActions';
import AuditDialog from './components/AuditDialog';
import AuditFilter from './containers/AuditFilter';
import AuditTable from './containers/AuditTable';

class AuditReport extends Component {
  state = {
    method: 'all',
    order: 'desc',
    orderBy: 'timestamp',
    page: 1,
    siteId: '',
    siteName: '',
    username: ''
  };

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  componentDidMount() {
    this.props.dispatch(actions.fetchAuditInfo(this.auditSortString(), true));
  }

  closeSnackBar() {
    this.props.dispatch(actions.updateAuditSnackbar());
  }

  handleRequestSort = (property) => {
    const { dispatch } = this.props;
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    }, () => {
      dispatch(actions.fetchAuditInfo(this.auditSortString(), true));
    });
  };

  auditSortString() {
    const sstring = buildSortString(this.state);
    let filterString = '';

    if (this.state.siteId !== '') {
      filterString += `site_id:${removePipe(this.state.siteId.trim())},`;
    }

    if (this.state.method !== 'all') {
      filterString += `method:${removePipe(this.state.method)},`;
    }

    if (this.state.username !== '') {
      sstring.search = this.state.username.trim();
    }

    if (filterString.length > 0) {
      sstring.filters = filterString.substring(0, filterString.length - 1);
    }

    return sstring;
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState({
        page: this.state.page + 1
      }, () => {
        this.props.dispatch(actions.fetchAuditInfo(this.auditSortString()));
      });
    }
  }

  filterData() {
    this.setState({
      order: 'desc',
      orderBy: 'timestamp',
      page: 1
    }, () => {
      this.props.dispatch(actions.fetchAuditInfo(this.auditSortString(), true));
    });
  }

  auditFilter = (name) => ({ target: { value } }) => {
    this.setState({ [name]: value }, () => {
      this.debouncedLoadMoreData();
    });
  };

  setSite(siteId, siteName) {
    this.setState({ siteId, siteName }, () => {
      this.debouncedLoadMoreData();
    });
  }

  getPageDetails() {
    return (
      <Grid container style={{ alignContent: 'start', width: '100%' }}>
        <Grid item xs={12}>
          <AuditFilter auditState={this.state} auditFilter={this.auditFilter.bind(this)}
            setSite={this.setSite.bind(this)} />
        </Grid>
        <Grid item xs={12} >
          <AuditTable auditState={this.state}
            handleRequestSort={this.handleRequestSort.bind(this)} getMoreData={this.getMoreData.bind(this)} />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { auditDialogIndex, snackbarMessage, snackbarType, translations } = this.props;
    return (
      <Fragment>
        <SonifiTemplate
          header={translations.header}
          pageDetails={this.getPageDetails()}
          showButton={false}
        />
        <SonifiSnackbar message={snackbarMessage} variant={snackbarType}
          open={snackbarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
        {auditDialogIndex !== -1 && <AuditDialog />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auditDialogIndex: state.auditReport.auditDialogIndex,
  maxPages: state.auditReport.maxPages,
  snackbarMessage: state.auditReport.snackbarMessage,
  snackbarType: state.auditReport.snackbarType,
  translations: state.auditReport.translations.grid,
  userPermissions: state.global.permissions
});

AuditReport.propTypes = {
  auditDialogIndex: PropTypes.number,
  dispatch: PropTypes.func,
  maxPages: PropTypes.number,
  snackbarMessage: PropTypes.string,
  snackbarType: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(AuditReport);
