import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { CHANNEL_EDIT } from '../../../constants/roles';
import { SonifiSubHeader } from '../../../containers';
import { checkSingleUserPermission } from '../../../utils/rolesUtil';
import { saveCheck } from '../actions/channelsActions';
import ChannelDetailsDialog from '../containers/ChannelDetailsDialog';
import Channels from '../containers/Channels';
import ChannelButtons from '../containers/Panels/ChannelButtons';
import { formatChannelSetName } from '../utils/helper';

class MasterChannelList extends Component {
  constructor(props) {
    super(props);

    this.saveCheck = this.saveCheck.bind(this);
  }

  saveCheck(currentChannels, chanInfo) {
    this.props.dispatch(saveCheck(currentChannels, chanInfo));
  }

  render() {
    const {
      channelSet, draftExists, selectedChannel, translations, userPermissions
    } = this.props;
    const canEdit = checkSingleUserPermission(CHANNEL_EDIT, userPermissions);
    return <Fragment>
      {selectedChannel !== null &&
        <ChannelDetailsDialog
          readOnly={!canEdit || draftExists}
          saveCheck={this.saveCheck}
        />}
      <SonifiSubHeader
        header={`${translations.title}: ${formatChannelSetName(channelSet.id)}`}
        middleExtra={<ChannelButtons draftExists={draftExists} />}
        smallerHeader
        showButton={false}
        topPadding
      />
      <Channels saveChannelList={this.saveCheck} draftExists={draftExists} />
    </Fragment>;
  }
}
const mapStateToProps = (state) => ({
  availableChannelSets: state.channels.availableChannelSets,
  channelSet: state.channels.channelSet,
  globalTranslations: state.global.translations.defaults,
  lineups: state.tvLineup.channelLineups,
  realChannels: state.channels.realChannels,
  selectedChannel: state.channels.selectedChannel,
  translations: state.channels.translations.channelHeaders,
  userPermissions: state.global.permissions
});

MasterChannelList.propTypes = {
  availableChannelSets: PropTypes.array,
  channelSet: PropTypes.object,
  dispatch: PropTypes.func,
  draftExists: PropTypes.bool,
  globalTranslations: PropTypes.object,
  lineups: PropTypes.array,
  realChannels: PropTypes.array,
  selectedChannel: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(MasterChannelList);
