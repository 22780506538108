/* eslint-disable max-lines-per-function */
import { orderBy, uniqBy } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { EDIT, ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  changed: false,
  createNewRelease: false,
  deleteTemplate: null,
  editType: EDIT,
  immutable: -1,
  loading: false,
  maxPages: 0,
  packagesOpt: [],
  pageSize: 0,
  pkgLoading: false,
  platforms: [],
  popupLoading: false,
  productName: '',
  releaseInfo: null,
  releaseTemplates: [],
  saving: false,
  selected: -1,
  selectedPkgRow: -1,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  templatePackages: [],
  total_items: 0,
  translations: null,
  virtOsVers: []
};

export default function releaseTemplatesReducer(state = initialState, action) {
  let miscObj = null;
  const sbMain = (state.translations && state.translations.main ? state.translations.main : { snackBar: {} });
  const sbTrans = sbMain.snackBar;

  switch (action.type) {
    case types.CLEAR_TABLE:
      return {
        ...state, releaseTemplates: [], maxPages: 0, pageSize: 0, totalItems: 0, selected: -1, snackBarMessage: ''
      };

    case types.DELETE_CHECK:
      return { ...state, deleteTemplate: action.template, productName: action.productName, snackBarMessage: '' };

    case types.DELETE_PACKAGE_ROW:
      miscObj = state.templatePackages;
      miscObj.splice(action.rowIndex, 1);
      return { ...state, templatePackages: miscObj, changed: !state.changed };

    case types.IMMUTABLE_CHECK:
      return { ...state, immutable: action.templateIndex, productName: action.productName, snackBarMessage: '' };

    case types.MAKE_IMMUTABLE_BEGIN:
      return {
        ...state, saving: true, immutable: -1, deleteTemplate: null, snackBarMessage: ''
      };

    case types.MAKE_IMMUTABLE_SUCCESS:
      return {
        ...state,
        immutable: -1,
        saving: false,
        snackBarType: SUCCESS,
        snackBarMessage: `${sbTrans.updateSuccess} ${action.productName} ${sbMain.version} ${action.versionNum}`
      };

    case types.MAKE_IMMUTABLE_FAILURE:
      return {
        ...state,
        saving: false,
        snackBarType: ERROR,
        snackBarMessage:
          `${sbTrans.updateFailure} ${action.productName} ${sbMain.version} ${action.versionNum} - ${action.error}`
      };

    case types.ADD_TEMPLATE_ROW_BEGIN:
      return { ...state, saving: true };

    case types.ADD_TEMPLATE_ROW_SUCCESS:
      return {
        ...state,
        saving: false,
        selected: -1,
        snackBarMessage: `${sbTrans.addSuccess} ${action.productName} ${sbMain.version} ${action.versionNum}`,
        snackBarType: SUCCESS
      };

    case types.ADD_TEMPLATE_ROW_FAILURE:
      return {
        ...state,
        saving: false,
        selected: -1,
        snackBarMessage: `${sbTrans.addFailure} - ${action.error}`,
        snackBarType: ERROR
      };
    case types.DELETE_TEMPLATE_ROW_BEGIN:
      return { ...state, popupLoading: true, saving: true };

    case types.DELETE_TEMPLATE_ROW_SUCCESS:
      return {
        ...state,
        deleteTemplate: null,
        popupLoading: false,
        saving: false,
        selected: -1,
        snackBarMessage: `${sbTrans.deleteSuccess} ${action.productName} ${sbMain.version} ${action.versionNum}`,
        snackBarType: SUCCESS
      };

    case types.DELETE_TEMPLATE_ROW_FAILURE:
      return {
        ...state,
        deleteTemplate: null,
        popupLoading: false,
        saving: false,
        selected: -1,
        snackBarMessage: `${sbTrans.deleteFailure} ${action.productName} ${sbMain.version} ${action.versionNum}`,
        snackBarType: ERROR
      };
    case types.EDIT_TEMPLATE_ROW_BEGIN:
      return { ...state, saving: true };

    case types.EDIT_TEMPLATE_ROW_SUCCESS:
      return {
        ...state,
        saving: false,
        selected: -1,
        snackBarType: SUCCESS,
        snackBarMessage: `${sbTrans.updateSuccess} ${action.productName} ${sbMain.version} ${action.versionNum}`
      };

    case types.EDIT_TEMPLATE_ROW_FAILURE:
      return {
        ...state,
        saving: false,
        selected: -1,
        snackBarType: ERROR,
        snackBarMessage: `${sbTrans.updateFailure} ${action.productName} ${sbMain.version} ${action.versionNum}`
      };

    case types.GET_RELEASE_TEMPLATES_BEGIN:
      return {
        ...state, loading: action.loading, immutable: -1
      };

    case types.GET_RELEASE_TEMPLATES_SUCCESS:
      miscObj = [...state.releaseTemplates, ...action.releaseTemplates];

      return {
        ...state,
        maxPages: action.pageCount,
        pageSize: action.pageSize,
        releaseTemplates: uniqBy(miscObj, (e) => e.id),
        totalItems: action.totalItems,
        loading: false,
        changed: !state.changed
      };

    case types.GET_RELEASE_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        maxPages: 0,
        pageSize: 0,
        releaseTemplates: [],
        snackBarMessage: `${sbMain.errors.noReleaseTemplates}`,
        snackBarType: ERROR,
        totalItems: -1,
      };

    case types.GET_SOFTWARE_OPTIONS_BEGIN:
      return {
        ...state, loading: true, platforms: [], snackBarMessage: '', virtOsVers: []
      };

    case types.GET_SOFTWARE_OPTIONS_SUCCESS:
      return {
        ...state,
        platforms: [...action.softwareOptions.platforms],
        virtOsVers: [...action.softwareOptions.versions.virtos],
        loading: false
      };

    case types.GET_SOFTWARE_OPTIONS_FAILURE:
      return {
        ...state,
        platforms: [],
        virtOsVers: [],
        loading: false
      };

    case types.GET_TEMPLATE_PACKAGES_BEGIN:
      return {
        ...state, packagesOpt: [], pkgLoading: true
      };

    case types.GET_TEMPLATE_PACKAGES_SUCCESS:
      miscObj = [...state.packagesOpt, ...action.packages];
      return {
        ...state,
        packagesOpt: orderBy(uniqBy(miscObj, 'id'), 'id'),
        pkgLoading: false
      };

    case types.GET_TEMPLATE_PACKAGES_FAILURE:
      return {
        ...state,
        pkgLoading: false,
        packagesOpt: [],
        snackBarType: ERROR,
        snackBarMessage: `${state.translations.main.errors.noPackages} - ${action.error}`
      };

    case types.SET_TEMPLATE_PACKAGES:
      miscObj = (state.selected === -2
        ? { packages: [] }
        : JSON.parse(JSON.stringify(state.releaseTemplates[state.selected])));
      return {
        ...state, templatePackages: [...miscObj.packages]
      };

    case types.UPDATE_SNACK_BAR:
      return {
        ...state,
        snackBarType: (action.snackBarType === null
          ? state.snackBarType
          : action.snackBarType),
        snackBarMessage: action.snackBarMessage
      };

    case types.UPDATE_SELECTED_PACKAGE_ROW:
      return {
        ...state, selectedPkgRow: action.index, editType: action.editType
      };

    case types.UPDATE_SELECTED_RELEASE_TEMPLATE:
      return {
        ...state,
        selected: action.index,
        editType: action.editType,
        templatePackages: []
      };

    case types.UPDATE_TEMPLATE_PACKAGES:
      return {
        ...state, templatePackages: action.packages
      };

    case types.CREATE_NEW_DEPLOYMENT:
      return {
        ...state, createNewRelease: action.showDialog, releaseInfo: action.releaseInfo
      };

    case types.RELEASE_SAVING:
      return {
        ...state, saving: action.saving
      };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
