import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiText from '../../../containers/SonifiText';
import SonifiTextAsync from '../../../containers/SonifiTextAsync';
import { AUDIT_METHODS } from '../constants/constants';

class AuditFilter extends Component {
  createFilterObj = (inputData) => ({
    search: inputData,
    limit: 20
  });

  createDisplayObj = (site) => ({
    value: site,
    label: `${site.id} ${(site.name ? `: ${site.name}` : '')}`,
  });

  handleChange = (option) => {
    if (option && option.value && option.value.id) {
      this.props.setSite(option.value.id, option.value.name);
    }
  };

  getFormattedSiteName() {
    const { auditState } = this.props;
    const name = (auditState.siteId && auditState.siteName) ? `${auditState.siteId} : ${auditState.siteName}` : null;
    return name;
  }

  render() {
    const {
      auditState, auditFilter, loading, translations
    } = this.props;

    return (
      <Grid container style={{ justifyContent: 'flex-end' }}>
        <Grid item xs={3}>
          <SonifiText
            change={auditFilter('method')}
            defaultValue={auditState.method}
            disabled={loading}
            items={AUDIT_METHODS}
            label={translations.method}
            select
            size="percent"
          />
        </Grid>
        <Grid item xs={3}>
          <SonifiText
            change={auditFilter('username')}
            defaultValue={auditState.username}
            icon={<SearchIcon />}
            iconPosition="start"
            label={`${translations.username}`}
            size="percent"
          />
        </Grid>
        <Grid item xs={3}>
          <SonifiTextAsync
            dataName="sites"
            defaultValue={auditState.siteId}
            disabled={loading}
            displayFunc={this.createDisplayObj}
            filterFunc={this.createFilterObj}
            label={translations.siteSearch}
            onSelect={this.handleChange}
            placeholder={this.getFormattedSiteName()}
            resource="sites"
            siteObj={false}
            type="site"
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.auditReport.loading,
  translations: state.auditReport.translations.filter
});

AuditFilter.propTypes = {
  auditState: PropTypes.object,
  auditFilter: PropTypes.func,
  loading: PropTypes.bool,
  setSite: PropTypes.func,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(AuditFilter);
