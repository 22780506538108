import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import SonifiCheckBox from '../../../containers/SonifiCheckbox';
import SonifiDivider from '../../../containers/SonifiDivider';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiText from '../../../containers/SonifiText';

const styles = () => ({
  displayContents: {
    display: 'contents'
  },
  show: {
    visibility: 'visible'
  },
  hide: {
    visibility: 'hidden'
  }
});

class LineupViewer extends Component {
  getSelectionBox(defValue, labl) {
    return <Grid item xs={6} sm={3}>
      <SonifiText
        label={labl}
        defaultValue={defValue}
        disabled={true}
        size="sm"
      />
    </Grid>;
  }

  /* eslint-disable camelcase*/
  render() {
    const { classes, lineupInfo, translations } = this.props;

    const {
      bat_warmup_seconds, cables, classification, encryption, is_bat_enabled, is_power_on_surfable,
      mpeg_format, name, tuner_type, is_power_on_enabled
    } = lineupInfo;

    return (
      <Grid container spacing={0}>
        {this.getSelectionBox(name, translations.lineupName)}
        {this.getSelectionBox(classification, translations.classification)}
        <Grid item xs={2}>
          <SonifiCheckBox label={translations.bat} value={is_bat_enabled} disabled />
          <div className={`${classes.displayContents} ${is_bat_enabled ? classes.show : classes.hide}`}>
            <SonifiText
              defaultValue={bat_warmup_seconds}
              disabled
              label={translations.seconds}
              size="xsNoPad"
              type="number"
            />
          </div>
        </Grid>
        <Grid item xs={3} style={{ border: '1px solid #d6d6d6', paddingLeft: '14px', paddingTop: '7px' }}>
          <Grid container>
            <Grid item xs={12}>
              <SonifiLabel size="xs" label={translations.powerOnCh} noPadding grid />
            </Grid>
            <SonifiCheckBox
              disabled
              label={translations.enabled}
              noPadding
              value={!!is_power_on_enabled}
            />
            {is_power_on_enabled && <SonifiCheckBox
              disabled
              label={translations.surfable}
              noPadding
              value={is_power_on_surfable && is_power_on_enabled}
            />}
          </Grid>
        </Grid>
        <SonifiDivider />

        {this.getSelectionBox(cables, translations.cables)}
        {this.getSelectionBox(tuner_type, translations.tunerType)}
        {this.getSelectionBox(mpeg_format, translations.mpegFormat)}
        {this.getSelectionBox(encryption, translations.encryption)}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.tvLineup.translations.editDialog
});

LineupViewer.propTypes = {
  classes: PropTypes.object.isRequired,
  lineupInfo: PropTypes.object,
  translations: PropTypes.object
};

// export default connect(mapStateToProps)(LineupViewer);
export default connect(mapStateToProps)(withStyles(LineupViewer, styles, { withTheme: true }));

