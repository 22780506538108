import { Block, CheckCircleOutline, Warning } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { resetLoadingSite, getSite } from '../../actions/globalActions';
import { SonifiLabel } from '../../containers';
import SonifiSpinner from '../../containers/SonifiSpinner';
import { getSiteNumFromURI } from '../../utils';
import { getTerminalOptions, getTerminalStatuses } from '../Terminals/actions/terminalActions';
import { setSiteId, clearSite } from '../../v2/GlobalSlices/siteSlice';
import { Navigate } from 'react-router-dom';

const styles = (theme) => ({
  align: {
    textAlign: 'center'
  },
  badStatus: {
    color: `${theme.palette.defaults.red} !important`
  },
  dashboard: {
    padding: '50px',
  },
  disabledStatus: {
    color: 'gray !important'
  },
  goodStatus: {
    color: 'green !important'
  },
  item: {
    backgroundColor: '#1592d9',
    color: 'white',
    margin: '10px',
  },
  itemPadding: {
    border: '2px solid gray',
    margin: '10px',
    padding: '50px'
  },
  infoPadding: {
    paddingTop: '10px'
  }
});

class SiteDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true
    };
  }

  componentDidMount() {
    const siteNum = getSiteNumFromURI();

    if (siteNum !== null && siteNum !== undefined) {
      this.getDashboardSiteInfo(siteNum);
      this.props.dispatch(setSiteId(siteNum));
      this.props.dispatch(getSite(siteNum));
      this.props.dispatch(getTerminalStatuses(siteNum));
      this.props.dispatch(getTerminalOptions(siteNum));
    } else {
      this.props.dispatch(clearSite());
    }

    // if (this.props.userGroup !== null) {
    //   let tempSiteNum = '0';
    //   getObjectPromise(USER_GROUPS, this.props.userGroup, { limit: 5 })
    //     .then((data) => {
    //       if (data.sites && data.sites.length === 1 && data.sites[0] !== '') {
    //         tempSiteNum = data.sites[0];
    //       } else if (this.props.userAssignedSites && this.props.userAssignedSites.length > 0) {
    //         tempSiteNum = this.props.userAssignedSites[0];
    //       }

    //       if ((siteNum === null || siteNum === undefined) && tempSiteNum !== '0' &&
    //         (
    //           (
    //             (data.sites && data.sites.length === 1 && data.sites[0] !== '') ||
    //             (this.props.userAssignedSites && this.props.userAssignedSites.length > 0 &&
    //               this.props.userAssignedSites[0] !== '*')
    //           ) &&
    //           this.props.userAssignedSites.length === 1
    //         )
    //       ) {
    //         this.props.dispatch(getSiteInfo(tempSiteNum));
    //       }
    //       this.getDashboardSiteInfo(tempSiteNum);
    //     }).catch((error) => {
    //       console.log(error);
    //       this.setState({ spinner: false });
    //     });
    // } else {
    this.setState({ spinner: false });
    // }
  }

  componentDidUpdate() {
    const prevSiteId = getSiteNumFromURI();
    if (prevSiteId !== this.props.siteId) {
      this.props.dispatch(getSite(this.props.siteId));
      this.props.dispatch(getTerminalStatuses(this.props.siteId));
    }
  }

  getDashboardSiteInfo(siteID) {
    if (siteID === null || siteID === '*' || siteID === 0 || siteID.length < 7) {
      this.setState({ spinner: false });
      this.props.dispatch(resetLoadingSite());
      // } else {
      //   this.props.dispatch(getSiteInfo(siteID));
      //   this.props.dispatch(getSite(siteID))
      //     .then(() => {
      //       this.setState({ spinner: false });
      //     })
      //     .catch(() => {
      //       this.setState({ spinner: false });
      //     });
    }
  }

  /* eslint-disable camelcase */
  // "status": "unknown | down | disabled | up",
  getPmsIcon() {
    const { classes, site } = this.props;
    const { pms_link } = site;
    if (pms_link.status === 'up') {
      return <CheckCircleOutline className={classes.goodStatus} />;
    } else if (pms_link.status === 'disabled') {
      return <Block className={classes.disabledStatus} />;
    }
    return <Warning className={classes.badStatus} />;
  }

  // "status": "unknown | down | disabled | up",
  getTerminalIcon() {
    const { classes, terminalStatuses } = this.props;

    if (terminalStatuses?.notCommunicating === 0) {
      return <CheckCircleOutline className={classes.goodStatus} />;
    }

    return <Warning className={classes.badStatus} />;
  }

  /* eslint-disable camelcase */
  render() {
    const siteNum = getSiteNumFromURI();
    if (!siteNum) {
      return <Navigate to="*" />;
    }
    const { spinner } = this.state;
    if (spinner || this.props.loadingSite) {
      return <SonifiSpinner />;
    }

    const {
      classes, marinaOnlySite, site, terminalStatuses
    } = this.props;
    const { id, location, name, owner } = site;
    const { labels } = this.props.translations.dashboard;

    return (
      <Grid container className={classes.dashboard}>
        {site.is_updating &&
          <Box width="97.5%"className={classes.item} >
            <SonifiLabel centered boldLabel={labels.updating} />
          </Box>}
          <Box width="48%" className={classes.itemPadding} >
            <SonifiLabel boldLabel={name} />
            <SonifiLabel noPadding boldLabel={labels.siteID} label={id} />
            {site?.external_id && <SonifiLabel noPadding boldLabel={labels.custID} label={site.external_id} />}
            {owner?.brand && <SonifiLabel noPadding boldLabel={labels.brand} label={owner.brand} />}
            {owner?.flag && <SonifiLabel noPadding boldLabel={labels.flag} label={owner.flag} />}
            {location?.timezone && <SonifiLabel boldLabel={labels.timezone} label={location.timezone} />}
            {site.platforms && site.platforms.length > 0 &&
              <SonifiLabel boldLabel={labels.platforms} label={site.platforms.toString()} />
            }
          </Box>
        {!marinaOnlySite &&
            <Box width="48%" className={classes.itemPadding} >
              <SonifiLabel boldLabel={labels.info} />
              {site && site.pms_link && site.pms_link.status &&
                <Grid container>
                  <Grid item xs={1} className={classes.align}>
                    {this.getPmsIcon()}
                  </Grid>
                  <Grid item>
                    <SonifiLabel noPadding label={labels.pms} />
                    <SonifiLabel noPadding size="xs" label={`${labels.status} ${site.pms_link.status}`} />
                    <SonifiLabel noPadding size="xs"
                      label={`${labels.reported} ${(site.pms_link.reported
                        ? moment(site.pms_link.reported).format('MM/DD/YYYY h:mm a')
                        : '')}`} />
                  </Grid>
                </Grid>}
              <Grid container className={classes.infoPadding}>
                <Grid item xs={1} className={classes.align}>
                  {this.getTerminalIcon()}
                </Grid>
                <Grid item>
                  <SonifiLabel noPadding label={labels.terminals} />
                  <SonifiLabel
                    noPadding
                    size="xs"
                    testId="siteDashboardNotCommunicatingLabel"
                    label={`${labels.notCommunicating}: ${terminalStatuses?.notCommunicating}`}
                  />
                </Grid>
              </Grid>
            </Box>}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingSite: state.global.loadingSite,
  marinaOnlySite: state.global.marinaOnlySite,
  site: state.global.site,
  terminalStatuses: state.termGrid.terminalStatuses,
  translations: state.global.translations,
  userAssignedSites: state.global.userAssignedSites,
  userGroup: state.global.group,
  siteId: state.v2.site.id
});

SiteDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  loadingSite: PropTypes.bool,
  marinaOnlySite: PropTypes.bool,
  site: PropTypes.object,
  terminalStatuses: PropTypes.object,
  translations: PropTypes.object,
  userAssignedSites: PropTypes.array,
  userGroup: PropTypes.string,
  siteId: PropTypes.string.isRequired
};

export { SiteDashboard };
export default connect(mapStateToProps)(withStyles(SiteDashboard, styles));
