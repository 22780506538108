export const CHANNEL_CLONE = 'Channel-Clone';
export const CHANNEL_EDIT = 'Channel-Edit';
export const CHANNEL_EDIT_LIMITED = 'Channel-Edit-Limited';
export const CHANNEL_READ = 'Channel-Read';
export const FIRMWARE_EDIT = 'Firmware-Write';
export const FIRMWARE_EDIT_LIMITED = 'Firmware-Write-Limited';
export const FIRMWARE_READ = 'Firmware-Read';
export const GROUP_EDIT = 'UserGroup-Edit';
export const GROUP_READ = 'UserGroup-Read';
export const GROUP_SERVICES_EDIT = 'GroupService-Edit';
export const GROUP_SERVICES_READ = 'GroupService-Read';
export const KEY_STROKE_ROUTER_EDIT = 'KeyStrokeRouter-Edit';
export const KEY_STROKE_ROUTER_READ = 'KeyStrokeRouter-Read';
export const LATE_CHECKOUT_EDIT = 'Late-Checkout-Edit';
export const LATE_CHECKOUT_READ = 'Late-Checkout-Read';
export const LINEUP_EDIT = 'Lineup-Edit';
export const LINEUP_LIMITED = 'Lineup-Limited';
export const LINEUP_READ = 'Lineup-Read';
export const MESSAGE_EDIT = 'Message-Edit';
export const MESSAGE_READ = 'Message-Read';
export const MESSAGEPUBLISH_EDIT = 'MessagePublish-Edit';
export const MESSAGEPUBLISH_READ = 'MessagePublish-Read';
export const ROLE_EDIT = 'Role-Edit';
export const ROLE_LIMITED = 'Role-Edit-Limited';
export const ROLE_READ = 'Role-Read';
export const ROOM_DETAIL_EDIT = 'RoomDetail-Edit';
export const ROOM_DETAIL_EDIT_LIMITED = 'RoomDetail-Edit-Limited';
export const ROOM_DETAIL_READ = 'RoomDetail-Read';
export const ROOM_EDIT = 'Room-Edit';
export const ROOM_READ = 'Room-Read';
export const SERVER_STREAM_EDIT = 'ServerStream-Source-Edit';
export const SERVER_STREAM_READ = 'ServerStream-Source-Read';
export const SITE_EDIT = 'Site-Edit';
export const SITE_LIMITED = 'Site-Edit-Limited';
export const SITE_PROVISION = 'Site-Provision';
export const SITE_READ = 'Site-Read';
export const SITE_TLX_READ = 'Site-Tlx-Read';
export const SITE_TLX_WRITE = 'Site-Tlx-Write';
export const SOFTWARE_READ = 'Software-Read';
export const SOFTWARE_WRITE = 'Software-Write';
export const SONIFI_ADMIN = 'SonifiAdmin';
export const SONIFI_TECH = 'SonifiTech';
export const SYSTEM_EDIT = 'System-Edit';
export const SYSTEM_LIMITED = 'System-Edit-Limited';
export const SYSTEM_READ = 'System-Read';
export const TERM_COMM_READ = 'TerminalCommunications-Read';
export const TERMINAL_DETAIL_EDIT = 'TerminalDetail-Edit';
export const TERMINAL_DETAIL_EDIT_LIMITED = 'TerminalDetail-Edit-Limited';
export const TERMINAL_DETAIL_READ = 'TerminalDetail-Read';
export const TERMINAL_MODELS_REPORT_READ = 'TerminalModelReport-Read';
export const TERMINAL_SETTINGS_EDIT = 'TerminalSettings-Edit';
export const TIE_ROLE = 'Tie';
export const USER_PROFILE_EDIT = 'UserProfile-Edit';
export const USER_PROFILE_READ = 'UserProfile-Read';
export const USER_TOKEN_READ = 'UserToken-Read';
export const USERACCOUNT_EDIT = 'UserAccount-Edit';
export const USERACCOUNT_READ = 'UserAccount-Read';
export const USERLOG_READ = 'UserLog-Read';
