import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel } from '../../../../containers';
import SonifiModalHeader from '../../../../containers/SonifiModalHeader';
import { dateToString } from '../../../../utils';
import { viewLogInfo } from '../actions/pmsLoggingAction';
import { pmsTypes } from '../constants';

class LogDialog extends Component {
  state = {
    messageLabel: ''
  };

  componentDidMount() {
    this.setState({
      messageLabel: this.props.selectedLog.message.guest_list.join(', ')
    }, () => {
      setTimeout(() => {
        this.getBlurredName();
      }, 3000);
    });
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(viewLogInfo(null));
    }
  }

  getBlurredName() {
    const guestList = [...this.props.selectedLog.message.guest_list];
    let displayMsg = '';
    if (this.state.messageLabel.length > 0) {
      for (let i = 0; i < guestList.length; i++) {
        const guestName = guestList[i].split(' ');
        for (let j = 0; j < guestName.length; j++) {
          displayMsg += `${guestName[j][0] + guestName[j].slice(1).replace(/./g, '*')} `;
        }
        displayMsg = `${displayMsg.trim()}, `;
      }
      this.setState({ messageLabel: displayMsg.trim().slice(0, -1) });
    }
  }

  render() {
    const { selectedLog, timezone, translations } = this.props;

    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={this.onCancel.bind(this)}
        open={true}>
        <SonifiModalHeader
          header={translations.title}
          onCancel={this.onCancel.bind(this)}
          onlyClose={true}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <SonifiLabel boldLabel={translations.timestamp}
                label={dateToString(selectedLog.timestamp, timezone)}
              />
            </Grid>
            <Grid item xs={12}>
              <SonifiLabel boldLabel={translations.room}
                label={selectedLog.room} />
            </Grid>
            <Grid item xs={12}>
              <SonifiLabel boldLabel={translations.type}
                label={find(pmsTypes, { value: selectedLog.type }).label} />
            </Grid>
            <Grid item xs={12}>
              {this.state.messageLabel.length > 0 &&
                <SonifiLabel boldLabel={translations.guest} label={this.state.messageLabel} />}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLog: state.pmsLogging.selectedLog,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
  translations: state.pmsLogging.translations.dialog
});

LogDialog.propTypes = {
  dispatch: PropTypes.func,
  selectedLog: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(LogDialog);
