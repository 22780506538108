import moment from 'moment';
import { HIGH_HTTP, LATE_CHECKOUTS, LOW_HTTP, OPTIONS_RESOURCE } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import { deleteObjectPromise, getSiteObjectPromise, patchObjectPromise, putObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

export const getLateCheckoutBegin = () => ({
  type: types.GET_OPTIONS_BEGIN
});

export const getLateCheckoutSuccess = (lateCheckouts, roomTypes) => ({
  type: types.GET_OPTIONS_SUCCESS,
  lateCheckouts,
  roomTypes
});

export const getLateCheckoutFailure = () => ({
  type: types.GET_OPTIONS_FAILURE
});

export function getLateCheckout() {
  return (dispatch) => {
    dispatch(getLateCheckoutBegin());
    return getSiteObjectPromise(LATE_CHECKOUTS)
      .then((json) => {
        dispatch(getLateCheckoutSuccess(json.late_checkouts, json.room_types));
        return json;
      })
      .catch(() => dispatch(getLateCheckoutFailure()));
  };
}

export const fetchAllRoomTypesBegin = () => ({
  type: types.FETCH_ROOM_TYPES_BEGIN
});

export const fetchAllRoomTypesSuccess = (roomTypes) => ({
  type: types.FETCH_ROOM_TYPES_SUCCESS,
  roomTypes
});

export const fetchAllRoomTypesFailure = () => ({
  type: types.FETCH_ROOM_TYPES_FAILURE
});

export function fetchAllRoomTypes() {
  return (dispatch) => {
    dispatch(fetchAllRoomTypesBegin());

    return getSiteObjectPromise(OPTIONS_RESOURCE)
      .then((json) => {
        dispatch(fetchAllRoomTypesSuccess(json.room_types));
        return json;
      })
      .catch(() => dispatch(fetchAllRoomTypesFailure()));
  };
}

export const removeSelectedRoomTypeBegin = () => ({
  type: types.REMOVE_SELECTED_ROOM_TYPE_BEGIN
});

export const removeSelectedRoomTypeSuccess = (item) => ({
  type: types.REMOVE_SELECTED_ROOM_TYPE_SUCCESS,
  item
});

export const removeSelectedRoomTypeFailure = (item) => ({
  type: types.REMOVE_SELECTED_ROOM_TYPE_FAILURE,
  item
});

export function removeSelectedRoomType(item, selectedRoomTypes) {
  return (dispatch) => {
    dispatch(removeSelectedRoomTypeBegin());

    const roomTypes = selectedRoomTypes.filter((e) => e !== item);
    return patchObjectPromise(LATE_CHECKOUTS, null, { room_types: roomTypes })
      .then((json) => {
        if (!httpSuccess(json.status)) {
          dispatch(removeSelectedRoomTypeFailure(`${item} - ${json.status}`));
        } else {
          dispatch(removeSelectedRoomTypeSuccess(item));
        }
        return json;
      }).catch((error) => {
        dispatch(removeSelectedRoomTypeFailure(`${item} - ${error.status}`));
      });
  };
}

export const saveRoomTypesBegin = () => ({
  type: types.SAVE_ROOM_TYPES_BEGIN
});

export const saveRoomTypesSuccess = () => ({
  type: types.SAVE_ROOM_TYPES_SUCCESS
});

export const saveRoomTypesFailure = (error) => ({
  type: types.SAVE_ROOM_TYPES_FAILURE,
  error
});

export function saveRoomTypes(roomTypes) {
  return (dispatch) => {
    dispatch(saveRoomTypesBegin());

    return patchObjectPromise(LATE_CHECKOUTS, null, { room_types: roomTypes })
      .then((json) => {
        if (!httpSuccess(json.status)) {
          dispatch(saveRoomTypesFailure(json.status));
        } else {
          dispatch(getLateCheckout());
          dispatch(saveRoomTypesSuccess());
        }
        return json;
      }).catch((error) => {
        dispatch(saveRoomTypesFailure(error.status));
      });
  };
}

export const showRoomTypesDialog = (show) => ({
  type: types.SHOW_ROOM_TYPES_DIALOG,
  show
});

export const resetSnackBar = (snackBarMessage, snackBarType) => ({
  type: types.RESET_SNACKBAR,
  snackBarType,
  snackBarMessage,
});

export const sortRoomTypes = () => ({
  type: types.SORT_ROOM_TYPES
});

export const deleteOptionCheck = (option) => ({
  type: types.DELETE_OPTION_CHECK,
  option
});

export const deleteOptionBegin = () => ({
  type: types.DELETE_OPTION_BEGIN
});

export const deleteOptionSuccess = (time) => ({
  type: types.DELETE_OPTION_SUCCESS,
  time
});

export const deleteOptionFailure = (time) => ({
  type: types.DELETE_OPTION_FAILURE,
  time
});

export function deleteOption(item) {
  return (dispatch) => {
    const snackBarTime = moment(item.time).format('h:mm A');
    dispatch(deleteOptionBegin());
    return deleteObjectPromise(LATE_CHECKOUTS, item.id)
      .then((json) => dispatch(getLateCheckout())
        .then(() => {
          dispatch(deleteOptionSuccess(snackBarTime));
          return json;
        })).catch((error) => {
          dispatch(deleteOptionFailure(snackBarTime));
          return error;
        });
  };
}

export const selectOption = (option, editType = 'read') => ({
  type: types.SELECT_OPTION,
  option,
  editType
});

export const saveOptionBegin = () => ({
  type: types.SAVE_OPTION_BEGIN
});

export const saveOptionSuccess = (time) => ({
  type: types.SAVE_OPTION_SUCCESS,
  time
});

export const saveOptionFailure = (time) => ({
  type: types.SAVE_OPTION_FAILURE,
  time
});

export function saveOption(time, item) {
  return (dispatch) => {
    dispatch(saveOptionBegin());
    const visibleTime = moment(time).format('HH:mm:ss');
    const snackBarTime = moment(time).format('h:mm A');
    return putObjectPromise(LATE_CHECKOUTS, `${visibleTime}`, item)
      .then((data) => {
        if (!httpSuccess(data.status)) {
          dispatch(saveOptionFailure(`${snackBarTime} - (${data.status})`));
          return data;
        }
        return dispatch(getLateCheckout())
          .then(() => {
            dispatch(saveOptionSuccess(snackBarTime));
            return data;
          });
      }).catch((error) => {
        dispatch(saveOptionFailure(snackBarTime));
        return error;
      });
  };
}

export const moveOption = (oldIndex, newIndex) => ({
  type: types.MOVE_OPTION,
  oldIndex,
  newIndex
});

export const reOrderSaveBegin = () => ({
  type: types.REORDER_SAVE_BEGIN
});

export const reOrderSaveSuccess = () => ({
  type: types.REORDER_SAVE_SUCCESS
});

export const reOrderSaveFailure = () => ({
  type: types.REORDER_SAVE_FAILURE
});

export function reOrderSave(options) {
  return (dispatch) => {
    dispatch(reOrderSaveBegin());

    const lineupCalls = [];
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const saveObject = {
        quantity: {
          default: option.quantity.default,
          today: option.quantity.today
        },
        price: option.price,
        sequence: option.sequence
      };
      const visibleTime = moment(option.time).format('HH:mm:ss');
      lineupCalls.push(putObjectPromise(LATE_CHECKOUTS, `${visibleTime}`, saveObject));
    }

    return Promise.all(lineupCalls)
      .then((responses) => {
        for (let i = 0; i < responses.length; i++) {
          if (responses[i] && (responses[i].status < LOW_HTTP || responses[i].status >= HIGH_HTTP)) {
            return dispatch(reOrderSaveFailure(responses[i].status));
          }
        }
        return dispatch(reOrderSaveSuccess());
      });
  };
}
