import { Warning } from '@mui/icons-material';
import { Dialog, Grid, Tooltip } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { compact, map, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { ADD, ERROR, SONIFI } from '../../../constants/constants';
import {
  BYTE, MAX_SIZE
} from '../../../constants/magic';
import SonifiDropZone from '../../../containers/SonifiDropZone/SonifiDropZone';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiText from '../../../containers/SonifiText';
import { dateToString, getErrorText, isError } from '../../../utils';
import { fetchModels, startModels } from '../../TermModels/actions/termModelActions';
import * as actions from '../actions/firmwareActions';
import ModelSelector from '../containers/ModelSelector';
import { allowUpload, getFileRegex, getFirmwareType, validFirmware } from '../utils/validator';

const styles = (theme) => ({
  editContent: {
    height: '100%',
    overflow: 'hidden'
  },
  header: {
    justifyContent: 'center'
  },
  uploadFileArea: {
    height: '38%'
  },
  siteSelectEdit: {
    height: '77%'
  },
  siteSelectNew: {
    height: '64%',
  },
  siteSelect: {
    borderBottom: `1px ${theme.palette.primary.border} solid`,
  },
  uploadButton: {
    width: '320px',
    height: '70%',
  },
  uploadHeight: {
    height: '91px',
    paddingRight: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  badStatus: {
    color: `${theme.palette.defaults.red} !important`
  },
  centered: {
    color: theme.palette.primary.contrastText,
    fontSize: '12pt',
    outline: 'none',
    height: '100%',
  },
  dropFile: {
    height: '100%',
    backgroundColor: theme.palette.secondary.main,
  },
  hoverCursor: {
    cursor: 'cell',
  },
  textCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  noPadding: {
    padding: 0,
    fontSize: '14pt',
    textAlign: 'center',
  },
  preview: {
    height: '98%',
    border: '1px solid #d6d6d6',
    width: '320px',
  },
  error: {
    backgroundColor: '#d32f2f',
  },
  ninetyEight: {
    height: '100%',
    backgroundColor: theme.palette.secondary.dark,
  },
});

export class FirmwareDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      fileBinary: undefined,
      fileSize: undefined,
      id: undefined,
      md5: '',
      uploadTimer: '0 seconds'
    };
  }

  componentDidMount() {
    const { dispatch, firmware, selectedFirmware } = this.props;
    const firmwareType = firmware[selectedFirmware].id === ADD ? SONIFI : firmware[selectedFirmware].make;

    dispatch(actions.fetchFirmwareFile(firmware[selectedFirmware].id));

    this.setState({
      id: firmware[selectedFirmware].id,
      SONIFI: [],
      LG: [],
      SAMSUNG: [],
      make: firmwareType,
      isNew: !!firmware[selectedFirmware].isNew,
    }, () => {
      const versDisplay = this.getVersionDisplay();
      this.setState({
        [firmwareType]: versDisplay.needsModels ? [...firmware[selectedFirmware].models] : ['*'],
        selectAllChecked: !versDisplay.needsModels,
        md5: firmware[selectedFirmware].md5
      });
    });
  }

  isModelSelected = (modelId) => this.state[this.state.make].includes(modelId);

  updateModels(models) {
    this.setState({ [this.state.make]: models });
  }

  abortUpload = () => {
    if (this.props.controller) {
      this.props.controller.abort();
    }
  };

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      if (this.props.uploadStarted) {
        this.props.dispatch(actions.resetSnackBar(ERROR, 'Abort current upload failed.'));
      } else {
        this.props.dispatch(actions.updateSelectedFirmware(-1));
      }
    }
  }

  componentWillUnmount() {
    this.abortUpload();
  }

  onSubmit() {
    const {
      dispatch, regex, reset, sort, translations
    } = this.props;
    dispatch(actions.saveFirmwareBegin());

    validFirmware(this.state, translations.errors, regex)
      .then((data) => {
        this.setState({ errors: data });
        if (Object.entries(data).length === 0) {
          setTimeout(this.step, 1000);
          dispatch(actions.saveFirmware(this.state, { page: 1, sort })).then((response) => {
            if (response) {
              reset();
            } else if (!response && this.state.isNew) {
              dispatch(actions.startFirmware());

              // dispatch(deleteFirmwareById(this.state.id.trim(), null, false)).then(() => {
              reset(this.state.id.trim());

              // });
            }
          });
        } else {
          dispatch(actions.resetSnackBar(ERROR, data.id ? data.id : data.models || data.md5));
        }
      })
      .catch((error) => {
        console.log('Valid Firmware Error', error);
        dispatch(actions.resetSnackBar(ERROR, translations.errors.error));
      });
  }

  fileSize = (size) => {
    const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(BYTE));
    return `${(size / Math.pow(BYTE, i)).toFixed(2) * 1} ${['B', 'kB', 'MB', 'GB'][i]}`;
  };

  onAcceptedDrop = (acceptedFiles) => {
    const { dispatch, regex, translations } = this.props;

    this.setState({ errors: {} });
    this.props.dispatch(actions.resetSnackBar('', ''));

    if (allowUpload(acceptedFiles[0].path, regex)) {
      this.setState({
        id: acceptedFiles[0].name,
        fileSize: this.fileSize(acceptedFiles[0].size),
        fileBinary: acceptedFiles[0],
      }, () => {
        const currFile = this.getVersionDisplay();
        this.setState({
          [currFile.make]: currFile.needsModels ? [] : ['*'],
          selectAllChecked: !currFile.needsModels
        });

        const firmwareType = getFirmwareType(acceptedFiles[0].name, regex);
        this.changeType(firmwareType);
      });
      return;
    }

    this.setState({
      errors: {
        uploadFile: translations.errors.fileType,
      },
    });
    dispatch(actions.resetSnackBar(ERROR, translations.errors.fileType));
  };

  getDropZoneContent(uploadTimer) {
    const { classes, translations, uploadStarted } = this.props;
    const uploadFile = uploadStarted
      ? uploadTimer
      : translations.uploadFile;

    return (
      <Grid container className={`${classes.textCenter}`}>
        <Grid item>
          <SonifiLabel additionalClasses={classes.noPadding} label={uploadFile} />
        </Grid>
      </Grid>
    );
  }

  changeType(value) {
    if (this.state.make !== value) {
      this.props.dispatch(startModels());
      const currFile = this.getVersionDisplay();
      this.setState(
        {
          make: value,
          selectAllChecked: !currFile.needsModels,
        },
        () => {
          this.props.dispatch(fetchModels({ make: this.state.make }));
        }
      );
    }
  }

  changeMd5 = (name) => (e) => {
    this.setState({
      [name]: e.target.value.toLowerCase()
    });
  };

  updateSelectAll(status) {
    this.setState({ selectAllChecked: status });
  }

  removeSelectAll() {
    this.setState({ selectAllChecked: false });
  }

  checkboxChange = () => () => {
    this.setState(
      {
        selectAllChecked: !this.state.selectAllChecked,
      },
      () => {
        let tempModels = [];
        if (this.state.selectAllChecked) {
          const searchableModels = this.props.models ? this.props.models : [];
          tempModels = compact([...this.props.models, ...searchableModels]);
          const uniqueLogos = uniqBy(tempModels, (e) => e.id);
          tempModels = uniqueLogos;
        }

        this.updateModels(map(tempModels, 'id'));
      }
    );
  };

  step = () => {
    const { uploadStarted } = this.props;
    if (uploadStarted) {
      const delta = Math.floor((Date.now() - uploadStarted) / 1000),
        minutes = Math.floor(delta / 60),
        seconds = delta % 60;
      this.setState({ uploadTimer: minutes ? `${minutes} minutes (${seconds} s)` : `${seconds} seconds` });
      setTimeout(this.step, 1000);
    }
  };

  getVersionDisplay() {
    const { firmware, regex, selectedFirmware } = this.props;
    const { id } = this.state;
    if (firmware[selectedFirmware].isNew && id !== ADD) {
      const fileRegex = getFileRegex(id, regex);
      const whatIsTheId = id.toLowerCase().match(fileRegex.regex);
      return {
        make: fileRegex.make,
        version: whatIsTheId && whatIsTheId.length > 0 ? whatIsTheId[whatIsTheId.length - 1] : '',
        type: fileRegex.type,
        needsModels: fileRegex.needs_models
      };
    }
    return { ...firmware[selectedFirmware], needsModels: true };
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    const {
      canEdit, classes, corrupted, editType, firmware, globalTranslations, fetching, selectedFirmware,
      selectedChecksum, selectedUploaded, sorting, timezone, translations, uploadStarted
    } = this.props;

    const {
      errors, fileSize, id, make, md5, selectAllChecked, uploadTimer
    } = this.state;

    const title = firmware[selectedFirmware].isNew
      ? translations.addNew
      : editType === 'edit'
        ? translations.title
        : translations.viewFirmware;

    const readOnly = editType === 'read' || !canEdit;

    return (
      <Dialog
        open={true}
        onClose={this.onCancel.bind(this)}
        fullWidth
        maxWidth="lg">
        <SonifiModalHeader
          header={title}
          onCancel={this.onCancel.bind(this)}
          onSubmit={this.onSubmit.bind(this)}
          onlyClose={readOnly}
          label={globalTranslations.defaults.save}
          disabled={fetching || sorting || uploadStarted !== null}
        />
        <DialogContent>
          {fetching
            ? <Grid container className={classes.editContent}>
              <Grid item xs={12}>
                <SonifiSpinner />
              </Grid>
            </Grid>
            : <Grid container className={classes.editContent}>
              <Grid item xs={12} className={`${firmware[selectedFirmware].isNew ? classes.uploadFileArea : ''}`}>
                <Grid container className={classes.header}>
                  {firmware[selectedFirmware].isNew && (
                    <Grid item className={classes.uploadHeight} xs={12}>
                      <Grid container className={classes.uploadButton}>
                        <Grid item className={classes.preview}>
                          <SonifiDropZone
                            allowMultiple={false}
                            displayOnlyContent={this.getDropZoneContent.bind(this, uploadTimer)}
                            displayDropZoneContent={this.getDropZoneContent.bind(this, uploadTimer)}
                            onAcceptedDropHandler={this.onAcceptedDrop}
                            disabled={!!uploadStarted}
                            maxSize={MAX_SIZE}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {id && id.length > 0 && id !== ADD && (
                    <Fragment>
                      {corrupted &&
                        <Grid item xs={1} className={classes.uploadHeight}>
                          <Tooltip title={translations.corruption}>
                            <Warning className={classes.badStatus} />
                          </Tooltip>
                        </Grid>
                      }
                      <Grid item xs={4} className={classes.uploadHeight}>
                        <SonifiText
                          label={translations.name}
                          defaultValue={id}
                          helperText={fileSize}
                          disabled
                          size="percent"
                        />
                      </Grid>
                      {firmware[selectedFirmware].isNew &&
                        <Grid item xs={4} className={classes.uploadHeight}>
                          <SonifiText
                            label={translations.md5}
                            defaultValue={md5}
                            helperText={translations.md5Helper}
                            change={this.changeMd5('md5')}
                            error={isError('md5', errors)}
                            errorText={getErrorText('md5', errors)}
                            size="percent"
                          />
                        </Grid>}
                    </Fragment>
                  )}
                  {!firmware[selectedFirmware].isNew && (
                    <Fragment>
                      <Grid item xs={4} className={classes.uploadHeight}>
                        <SonifiText
                          key={'checksum'}
                          defaultValue={selectedChecksum}
                          label={translations.checksum}
                          error={!selectedChecksum}
                          helperText={selectedChecksum ? '' : translations.corruption}
                          size="percent"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={corrupted ? 3 : 4} className={classes.uploadHeight}>
                        <SonifiText
                          key={'timestamp'}
                          defaultValue={dateToString(selectedUploaded, timezone)}
                          label={translations.uploaded}
                          error={!selectedUploaded}
                          size="percent"
                          disabled
                        />
                      </Grid>
                    </Fragment>
                  )}
                  {id && id.length > 0 && id !== ADD &&
                    <Grid item xs={9} style={{ border: '1px solid #d6d6d6', paddingLeft: '14px', paddingTop: '7px' }}>
                      <Grid container>
                        <Grid item xs={4}>
                          <SonifiText label={translations.version}
                            defaultValue={this.getVersionDisplay().version} size="percent" disabled />
                        </Grid>
                        <Grid item xs={4}>
                          <SonifiText label={translations.make}
                            defaultValue={this.getVersionDisplay().make} size="percent" disabled />
                        </Grid>
                        <Grid item xs={4}>
                          <SonifiText label={translations.type}
                            defaultValue={this.getVersionDisplay().type} size="percent" disabled />
                        </Grid>
                      </Grid>
                    </Grid>}
                </Grid>
              </Grid>

              <Grid item xs={12} className={`${classes.siteSelect} ${firmware[selectedFirmware].isNew
                ? classes.siteSelectNew
                : classes.siteSelectEdit}`}>
                <ModelSelector
                  canEdit={!readOnly && id && id.length > 0 && id !== ADD}
                  checkboxChange={this.checkboxChange.bind(this)}
                  errors={!!errors.models}
                  isModelSelected={this.isModelSelected.bind(this)}
                  make={make}
                  removeSelectAll={this.removeSelectAll.bind(this)}
                  selectAllChecked={selectAllChecked}
                  selectedModels={this.state[make]}
                  updateModels={this.updateModels.bind(this)}
                  updateSelectAll={this.updateSelectAll.bind(this)}
                  uploadErrorMsg={!(id && id.length > 0 && id !== ADD)}
                />
              </Grid>
            </Grid>
          }
        </DialogContent>
      </Dialog >);
  }
}

const mapStateToProps = (state) => ({
  addingFirmware: state.firmware.addingFirmware,
  controller: state.firmware.controller,
  corrupted: state.firmware.corrupted,
  editType: state.firmware.editType,
  fetching: state.firmware.fetching,
  firmware: state.firmware.firmware,
  globalTranslations: state.global.translations,
  loading: state.termModel.loading,
  models: state.termModel.models,
  regex: state.firmware.regex,
  selectedChecksum: state.firmware.selectedChecksum,
  selectedFirmware: state.firmware.selectedFirmware,
  selectedUploaded: state.firmware.selectedUploaded,
  sorting: state.termModel.sorting,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
  translations: state.firmware.translations.editDialog,
  uploadStarted: state.firmware.uploadStarted
});

FirmwareDialog.propTypes = {
  addingFirmware: PropTypes.bool,
  canEdit: PropTypes.bool,
  classes: PropTypes.object,
  controller: PropTypes.object,
  corrupted: PropTypes.bool,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  fetching: PropTypes.bool,
  firmware: PropTypes.array,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  models: PropTypes.array,
  regex: PropTypes.array,
  reset: PropTypes.func,
  selectedChecksum: PropTypes.string,
  selectedFirmware: PropTypes.number,
  selectedUploaded: PropTypes.string,
  sort: PropTypes.string,
  sorting: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  translations: PropTypes.object,
  uploadStarted: PropTypes.number
};

export default connect(mapStateToProps)(withStyles(FirmwareDialog, styles, { withTheme: true }));
