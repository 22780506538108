import { USER_GROUPS } from '../../../constants/apiEndpoints';
import { getObjectPromise } from '../../../utils/api';
import { textHelper } from '../../../utils/textUtil';

export function validGroup(selectedGroup, translations, globalTranslation) {
  const errors = {};

  if (selectedGroup.description === null || selectedGroup.description === undefined) {
    errors.name = translations.needName;
  } else if (textHelper(selectedGroup.description)) {
    errors.name = globalTranslation.symbols;
  }

  if (selectedGroup.sites.length < 1) {
    errors.sites = translations.needSites;
  }

  return errors;
}

export function validId(id, translations) {
  return new Promise((resolve, reject) => {
    const promises = [];
    promises.push(getObjectPromise(USER_GROUPS, encodeURIComponent(id))
      .then(() => {
        resolve({ message: translations.inUse });
      }).catch((error) => { reject(error); }));
  });
}
