import { Add } from '@mui/icons-material';
import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { LINEUP_ROLES } from '../../../constants/roleGroups';
import { SonifiConfirm, SonifiSubHeader } from '../../../containers';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { setSnackBar } from '../../Channels2/actions/channelsActions';
import * as actions from '../actions/tvLineupActions';
import { newEmptyLineup } from '../constants/lineupConstants';
import LineupDialog from './LineupDialog';
import Lineups from './Lineups';

const styles = () => ({
  contents: {
    maxHeight: 250,
    width: '100%'
  },
  innerContents: {
    border: 'none',
    borderBottom: '1px solid #d6d6d6',
    flexGrow: 1,
    maxHeight: '85%',
    overflowX: 'auto',
    width: '100%',
  },
  outerLayer: {
    display: 'flex',
    width: '100%'
  }
});

class LineupsGrid extends Component {
  // Starts processes of making a new lineup
  // Sets the id of the new lineup to be one greater than the highest id
  addLineup() {
    const { dispatch, lineups } = this.props;
    let highestId = lineups.length !== 0 ? lineups[0].id : 0;

    for (let i = 1; i < lineups.length; i++) {
      if (lineups[i].id > highestId) {
        highestId = lineups[i].id;
      }
    }
    highestId += 1;
    dispatch(setSnackBar());
    dispatch(actions.closeLineupSaveSuccess());
    dispatch(actions.setSelectedChannelLineups(newEmptyLineup(highestId)));
  }

  deleteLineupFunc = () => {
    const { deleteLineup, dispatch, lineupName } = this.props;

    // const { availableChannelSets, canEditDetails } = this.props;
    // if (canEditDetails) {
    //   for (let i = 0; i < availableChannelSets.length; i++) {
    //     this.props.dispatch(deleteAChannelLineup(availableChannelSets[i].value.id, this.props.selectedLineup.id));
    //   }
    // }
    dispatch(actions.deleteSingleLineup(deleteLineup, (lineupName === 'Active' ? 'Default' : lineupName)));
  };

  cancel = () => {
    this.props.dispatch(actions.deleteLineupCheck(null));
  };

  render() {
    const {
      classes, deleteLineup, draftExists, globalTranslations, lineupName, lineupsLoading, translations, userPermissions
    } = this.props;
    const canEdit = checkForAtLeastOneUserPermission(LINEUP_ROLES, userPermissions);
    return (<Fragment>
      <SonifiConfirm
        dialogOpen={deleteLineup !== null}
        onConfirm={this.deleteLineupFunc.bind(this)}
        onCancel={this.cancel.bind(this)}
        confirmTitle={`${translations.confirmTitle}`}
        confirmText={`${translations.confirmMessage} ${deleteLineup && deleteLineup.name ? deleteLineup.name : ''}?`}
        buttonCancelText={globalTranslations.cancel}
        buttonConfirmText={globalTranslations.save}
      />
      {this.props.selectedChannelLineup !== null && <LineupDialog />}
      <div className={classes.outerLayer}>
        <div className={classes.contents}>
          <SonifiSubHeader
            disabled={!canEdit || draftExists || lineupsLoading}
            header={`${translations.title} ${lineupName}`}
            icon={<Add />}
            label={`${translations.lineup}`}
            onSubmit={this.addLineup.bind(this)}
            showButton={canEdit}
            smallerHeader
            toolTip={draftExists ? `${translations.draftExists}` : `${globalTranslations.add} ${translations.lineup}`}
          />
          <Paper className={classes.innerContents}>
            <Lineups canEdit={canEdit} />
          </Paper>
        </div>
      </div>
    </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteLineup: state.tvLineup.deleteLineup,
  globalTranslations: state.global.translations.defaults,
  lineups: state.tvLineup.channelLineups,
  lineupsLoading: state.tvLineup.lineupsLoading,
  selectedChannelLineup: state.tvLineup.selectedChannelLineup,
  selectedChannelSet: state.channels.selectedChannelSet,
  translations: state.tvLineup.translations.lineupGrid,
  userPermissions: state.global.permissions
});

LineupsGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteLineup: PropTypes.object,
  dispatch: PropTypes.func,
  draftExists: PropTypes.bool,
  globalTranslations: PropTypes.object,
  lineupName: PropTypes.string,
  lineups: PropTypes.array,
  lineupsLoading: PropTypes.bool,
  selectedChannelLineup: PropTypes.object,
  selectedChannelSet: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(LineupsGrid, styles));
