import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiConfirm, SonifiLabel } from '../../../../containers';
import SonifiPopover from '../../../../containers/SonifiPopover';
import { bumpModelVersion } from '../../actions/FirmwareActions';
import { setSelectedLgFirmware } from '../../actions/LgFirmwareActions';
import { validLGFirmware } from '../../utils/validator';

class LgFirmwareRow extends Component {
  constructor(props) {
    super(props);

    this.state = { versionBumpDialogOpen: false };

    this.bumpVersionClickHandler = this.bumpVersionClickHandler.bind(this);
    this.bumpVersionConfirmHandler = this.bumpVersionConfirmHandler.bind(this);
    this.bumpVersionCancelHandler = this.bumpVersionCancelHandler.bind(this);
  }

  handleEditDialog() {
    const { dispatch, rowIndex } = this.props;
    dispatch(setSelectedLgFirmware(rowIndex));
  }

  lgRow() {
    const { lgFirmwareRow, translations } = this.props;
    const { id, firmware, version } = lgFirmwareRow;

    return (
      <Fragment>
        <TableCell>{id}</TableCell>
        <TableCell>{(firmware && firmware.CPU && firmware.CPU !== ''
          ? firmware.CPU
          : <SonifiLabel error label={translations.noCPU} />)}</TableCell>
        <TableCell>{(firmware && firmware.PTC && firmware.PTC !== ''
          ? firmware.PTC
          : <SonifiLabel error label={translations.noPTC} />)}</TableCell>
        <TableCell>{(firmware && firmware.BROWSER && firmware.BROWSER !== ''
          ? firmware.BROWSER
          : <SonifiLabel error label={translations.noBrowser} />)}</TableCell>
        <TableCell>{(version && version !== undefined
          ? version
          : <SonifiLabel error label={translations.noVersion} />)}</TableCell>
      </Fragment >
    );
  }

  bumpVersionConfirmHandler() {
    const { translations, lgFirmwareRow, snackbarTranslations } = this.props;
    const defaultFirmwareObj = { BROWSER: null, CPU: null, PTC: null };
    const lgFirmwareObj = { id: lgFirmwareRow.id, firmware: { ...defaultFirmwareObj, ...lgFirmwareRow.firmware } };
    validLGFirmware(lgFirmwareObj, translations.errors)
      .then((isValidFirmware) => {
        if (Object.entries(isValidFirmware).length === 0) {
          this.props.dispatch(
            bumpModelVersion(
              lgFirmwareObj.id,
              lgFirmwareRow.version
            )(snackbarTranslations.bumpSuccess, snackbarTranslations.bumpFailure)
          );
          this.setState({ versionBumpDialogOpen: false });
        }
      })
      .catch((error) => {
        console.log('Valid Firmware Error', error);
        this.setState({ errors: { cpu: translations.errors.general } });
      });
  }

  bumpVersionCancelHandler() {
    this.setState({ versionBumpDialogOpen: false });
  }

  bumpVersionClickHandler() {
    this.setState({ versionBumpDialogOpen: true });
  }

  render() {
    const {
      canEdit, globalTranslations, rowIndex, translations, lgFirmwareRow, dialogTranslations
    } = this.props;

    const { versionBumpDialogOpen } = this.state;

    return (
      canEdit
        ? <Fragment>
          <SonifiPopover
            editDisabled={!canEdit}
            editFunc={this.handleEditDialog.bind(this, rowIndex)}
            editTitle={globalTranslations.edit}
            hideDelete={true}
            functionOneIcon={<TrendingUpIcon />}
            functionOneTitle={translations.bumpVersion}
            functionOneDisabled={false}
            showFunctionOne={true}
            functionOneFunc={this.bumpVersionClickHandler}
            key={rowIndex}
            tableRow={this.lgRow()}
          />
          <SonifiConfirm
            dialogOpen={versionBumpDialogOpen}
            onConfirm={this.bumpVersionConfirmHandler}
            onCancel={this.bumpVersionCancelHandler}
            confirmTitle={dialogTranslations.versionBump.title}
            confirmText={`Are your sure you want to increment the ${lgFirmwareRow.id} version?`}
            buttonCancelText={dialogTranslations.versionBump.cancel}
            buttonConfirmText={dialogTranslations.versionBump.confirm}
            testId={'firmware-version-bump-confirm-dialog'}
          />
        </Fragment>
        : <TableRow> {this.lgRow()} </TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  translations: state.termFile.translations.popOver.lg,
  dialogTranslations: state.termFile.translations.dialog,
  snackbarTranslations: state.termFile.translations.snackbar
});

LgFirmwareRow.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  lgFirmwareRow: PropTypes.object,
  rowIndex: PropTypes.number,
  translations: PropTypes.object,
  dialogTranslations: PropTypes.object,
  snackbarTranslations: PropTypes.object
};

export default connect(mapStateToProps)(LgFirmwareRow);
