import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Navigation from '../../containers/Navigation';
import Profile from '../Profile';
import ProvisionTerms from '../ProvisionTerminals/components/ProvisionTerms';

function createLink(value, comp) {
  return {
    key: `${value}`,
    value: `${value}`,
    component: comp
  };
}

class User extends Component {
  usr() {
    const title = '';
    const items = [];
    items.push(createLink('profile', Profile));
    items.push(createLink('provision', ProvisionTerms));
    return { title, items };
  }

  render() {
    const links = [
      ...this.usr().items
    ];

    return (
      <Navigation hideNav={true} links={links} stackedNav={[this.usr()]} />
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  translations: state.global.translations
});

User.propTypes = {
  change: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(User);

