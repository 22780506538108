import { Dialog, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EDIT } from '../../../constants/constants';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import {
  clearPublicKey, deleteTerm, putTerm,
  rebootTerminal, setTermID, updateLog, updateTerminalFirmware
} from '../actions/terminalActions';
import {
  CLEAR, DELETE, FIRMWARE, LOGGING, REBOOT, VIEW
} from '../constants/Constants';

class PublicKeyDialog extends Component {
  componentDidMount() {
    const { dispatch, termID } = this.props;
    dispatch(setTermID(termID));
  }

  getTerminalInfo() {
    const {
      dispatch, selectedTerm, sort, termID, type
    } = this.props;
    if (type === REBOOT) {
      dispatch(rebootTerminal(termID));
    } else if (type === CLEAR) {
      dispatch(clearPublicKey(termID));
    } else if (type === DELETE) {
      dispatch(deleteTerm(termID));
    } else if (type === EDIT) {
      if (selectedTerm.changed) {
        dispatch(putTerm({ ...selectedTerm }));
      }
    } else if (type === FIRMWARE) {
      dispatch(
        updateTerminalFirmware({ ...this.props.selectedTerm }, sort)
      ).then(() => {
        this.props.onCancel(true);
      });
      return;
    } else if (type === LOGGING) {
      dispatch(updateLog(selectedTerm.id, !selectedTerm.log_forwarding));
    } else {
      return;
    }
    this.props.onCancel();
  }

  getInstructText() {
    console.log('getInstructText', this.props.type);
    const { selectedTerm, translations, type } = this.props;
    const { instruct } = translations;

    if (type === CLEAR) {
      return instruct.clearKey;
    } else if (type === DELETE) {
      return instruct.delete;
    } else if (type === FIRMWARE) {
      return instruct.firmware;
    } else if (type === LOGGING) {
      return `${selectedTerm.log_forwarding ? instruct.logOff : instruct.logOn}`;
    }
    return instruct.reboot;
  }

  getTitle() {
    if (this.props.type === CLEAR) {
      return this.props.translations.titles.clearKey;
    } else if (this.props.type === DELETE) {
      return this.props.translations.titles.delete;
    } else if (this.props.type === EDIT) {
      return this.props.translations.titles.edit;
    } else if (this.props.type === VIEW) {
      return this.props.translations.titles.view;
    } else if (this.props.type === FIRMWARE) {
      return this.props.translations.titles.firmware;
    } else if (this.props.type === LOGGING) {
      return this.props.translations.titles.log;
    }
    return this.props.translations.titles.reboot;
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  render() {
    const {
      dialogLoading, globalTranslations, popup, termID, type
    } = this.props;

    return (
      <Dialog
        open={true}
        maxWidth={type === EDIT || type === VIEW ? 'lg' : 'sm'}
        onClose={this.onClose.bind(this)}
        fullWidth>
        <SonifiModalHeader
          header={this.getTitle()}
          onlyClose={type === VIEW}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.getTerminalInfo.bind(this)}
          disabled={dialogLoading}
          label={type === EDIT
            ? globalTranslations.save
            : (type === FIRMWARE || type === LOGGING ? globalTranslations.save : type)}
        />
        <DialogContent>
          {type === VIEW || type === EDIT || type === FIRMWARE
            ? popup
            : <Typography><br />
              {this.getInstructText()} {termID}
            </Typography>
          }
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  dialogLoading: state.termGrid.dialogLoading,
  globalTranslations: state.global.translations.defaults,
  selectedTerm: state.termGrid.selectedTerm,
  translations: state.termGrid.translations
});

PublicKeyDialog.propTypes = {
  dialogLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  popup: PropTypes.object,
  selectedTerm: PropTypes.object,
  sort: PropTypes.object,
  termID: PropTypes.string.isRequired,
  translations: PropTypes.object,
  type: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(PublicKeyDialog);
