export const TYPES_OF_LOCATIONS = [
  { id: 'all', label: 'All', value: 'all' },
  { id: 'terminals', label: 'Terminals', value: 'terminals' },
  { id: 'rooms', label: 'Rooms', value: 'rooms' },
  { id: 'logo', label: 'Logo', value: 'logo' }
];

export const AUDIT_METHODS = [
  { id: 'all', label: 'All', value: 'all' },
  { id: 'DELETE', label: 'DELETE', value: 'DELETE' },

  // { id: 'GET', label: 'GET', value: 'GET' },
  { id: 'PATCH', label: 'PATCH', value: 'PATCH' },
  { id: 'POST', label: 'POST', value: 'POST' },
  { id: 'PUT', label: 'PUT', value: 'PUT' },
];
