import { Check } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getSite, patchSite } from '../../../actions/globalActions';
import { MARINA } from '../../../constants/constants';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { SITE_ROLES, SONIFI_ROLES } from '../../../constants/roleGroups';
import { SITE_EDIT, SONIFI_ADMIN } from '../../../constants/roles';
import { SonifiDivider, SonifiLabel, SonifiSwitch } from '../../../containers';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiSubHeader from '../../../containers/SonifiSubHeader';
import { checkForAtLeastOneUserPermission, checkSingleUserPermission } from '../../../utils/rolesUtil';
import {
  getIntegrations
} from '../../Integrations/actions/integrationActions';
import { fetchTerminalOptions } from '../../Rooms/actions/terminalActions'; // '../actions/terminalActions';
import {
  closeSnackBar, fetchChannelOptions, fetchRoomOptions, fetchSiteConfigs, getMarinaMobileInfo, resetGlobalSnackBar,
  updateChangeVariable, updateChannelOptions, updateChromecastPort, updateMarinaMobileInfo, updateRoomTypeError,
  updateRoomTypes, updateSiteClientConfig
} from '../actions/siteManagementActions';
import ChannelDelivery from '../containers/General/ChannelDelivery';
import ChromecastPort from '../containers/General/ChromecastPort';
import RoomTypes from '../containers/RoomTypes/RoomTypes';

class General extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempLabMode: false,
      enableSonifiClients: false,
      enableThirdPartyClients: false,
      autoImportRooms: false,
      marinaIpg: false
    };

    this.updateSonifiClientConfig = this.updateSonifiClientConfig.bind(this);
    this.updateThirdPartyClientConfig = this.updateThirdPartyClientConfig.bind(this);
    this.updateAutoImportRooms = this.updateAutoImportRooms.bind(this);
    this.updateMobile = this.updateMobile.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onRoomTypeUpdate = this.onRoomTypeUpdate.bind(this);
  }

  componentDidMount() {
    const { dispatch, site, userPermissions } = this.props;
    dispatch(resetGlobalSnackBar());
    dispatch(closeSnackBar());
    dispatch(getSite());
    dispatch(fetchRoomOptions()).then(() => {
      this.setState({ roomTypes: this.props.roomTypes });
    });
    dispatch(fetchChannelOptions());
    dispatch(fetchTerminalOptions());
    dispatch(updateChangeVariable(true));
    if (checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)) {
      dispatch(getIntegrations());

      dispatch(fetchSiteConfigs())
        .then((data) => {
          this.setState({
            enableThirdPartyClients: data.enable_third_party_clients ?? false,
            enableSonifiClients: data.enable_sonifi_clients ?? false,
            autoImportRooms: data.auto_import_rooms ?? false
          });
        });
    }

    if (site.platforms && site.platforms.length > 0 &&
      site.platforms.includes(MARINA.charAt(0).toUpperCase() + MARINA.slice(1).toLowerCase())) {
      dispatch(getMarinaMobileInfo())
        .then((data) => {
          this.setState({
            marinaIpg: data.mobile ?? false
          });
        });
    }
    this.setState({ tempLabMode: this.props.labMode });
  }

  debouncedAutoUpdate = debounce(this.autoUpdate, KEYBOARD_DELAY);

  debouncedMarinaIpg = debounce(this.marinaIpgUpdate, KEYBOARD_DELAY);

  debounceAutoImport = debounce(this.siteCFG, KEYBOARD_DELAY);

  debounceSonifiClient = debounce(this.siteCFG, KEYBOARD_DELAY);

  debounceThirdParty = debounce(this.siteCFG, KEYBOARD_DELAY);

  handleCheckChange = (name) => ({ target: { checked } }) => {
    this.setState({ [name]: checked }, () => {
      this.debouncedAutoUpdate();
    });
  };

  updateSonifiClientConfig({ target: { checked: enabled } }) {
    this.setState({ enableSonifiClients: enabled }, () => {
      this.debounceSonifiClient('enable_sonifi_clients', enabled);
    });
  }

  updateThirdPartyClientConfig({ target: { checked: enabled } }) {
    this.setState({ enableThirdPartyClients: enabled }, () => {
      this.debounceThirdParty('enable_third_party_clients', enabled);
    });
  }

  updateAutoImportRooms({ target: { checked: enabled } }) {
    this.setState({ autoImportRooms: enabled }, () => {
      this.debounceAutoImport('auto_import_rooms', enabled);
    });
  }

  autoUpdate() {
    this.closeSnackBar();
    this.props.dispatch(patchSite('is_lab', this.state.tempLabMode));
  }

  marinaIpgUpdate() {
    this.closeSnackBar();
    this.props.dispatch(updateMarinaMobileInfo({ mobile: this.state.marinaIpg }));
  }

  siteCFG(name, value) {
    this.closeSnackBar();
    this.props.dispatch(updateSiteClientConfig(name, value));
  }

  closeSnackBar() {
    this.props.dispatch(resetGlobalSnackBar());
    this.props.dispatch(closeSnackBar());
  }

  onSave = () => {
    const { channelDelivery, chromecastPort, dispatch, ipg } = this.props;

    // Validate room types
    const emptyTypeArray = this.state.roomTypes.reduce((total, type) => total + Number(type.name.length === 0), 0);
    const duplicateArray = this.state.roomTypes.filter(
      (type, idx) => !this.state.roomTypes.slice(idx + 1).every(
        (obj) => type.name.toUpperCase() !== obj.name.toUpperCase()
      )
    ).length;

    if (emptyTypeArray > 0) {
      const error = { message: 'Room Types may NOT be empty' };
      dispatch(updateRoomTypeError(error));
    } else if (duplicateArray > 0) {
      const error = { message: 'Room Types may NOT be duplicated' };
      dispatch(updateRoomTypeError(error));
    } else {
      dispatch(closeSnackBar());
      dispatch(updateChannelOptions(channelDelivery, ipg));
      dispatch(updateChromecastPort(chromecastPort));
      dispatch(updateChangeVariable(true));
      this.saveRoomTypes();
    }
  };

  saveRoomTypes = () => {
    this.props.dispatch(updateRoomTypes(this.state.roomTypes));
  };

  onRoomTypeUpdate = (types, tabChange = false) => {
    this.props.dispatch(updateChangeVariable(tabChange));
    this.setState({
      roomTypes: types
    });
  };

  updateMobile({ target: { checked: enabled } }) {
    this.setState({ marinaIpg: enabled }, () => {
      this.debouncedMarinaIpg();
    });
  }

  render() {
    const {
      channelOptionsLoading, globalTranslations, marinaTranslations, roomOptionsLoading,
      site, siteConfigLoading, translations, userPermissions
    } = this.props;
    const {
      tempLabMode, enableSonifiClients, enableThirdPartyClients, autoImportRooms, marinaIpg
    } = this.state;

    if (!checkForAtLeastOneUserPermission(SITE_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    if (channelOptionsLoading || roomOptionsLoading || siteConfigLoading) {
      return <SonifiSpinner />;
    }

    const readOnly = !checkSingleUserPermission(SITE_EDIT, userPermissions);
    return (
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={12}>
          {!readOnly && <SonifiSubHeader
            disabled={readOnly}
            header=""
            icon={<Check />}
            label={globalTranslations.save}
            onSubmit={this.onSave}
            showButton={true}
          />}
        </Grid>
        <Grid item xs={12}>
          <SonifiDivider />
          {/* <Divider style={{ marginTop: '5px', marginBottom: '5px' }} /> */}
        </Grid>
        <Grid item xs={12} style={{ overflow: 'auto', height: '91%' }}>
          <Grid container>
            <Grid item xs={6}>
              <ChannelDelivery readOnly={readOnly} />
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <ChromecastPort />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={11}>
                  <SonifiSwitch
                    disabled={!(checkSingleUserPermission(SONIFI_ADMIN, userPermissions) &&
                      checkSingleUserPermission(SITE_EDIT, userPermissions))}
                    label={translations.labMode}
                    onChange={this.handleCheckChange('tempLabMode')}
                    checked={tempLabMode}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions) &&
                <Grid container>
                  <Grid item xs={12} >
                    <SonifiLabel boldLabel={translations.ircClientConfig} />
                  </Grid>
                  <Grid item xs={12}>
                    <SonifiSwitch
                      disabled={!checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)}
                      label={translations.enableSonifiClients}
                      onChange={this.updateSonifiClientConfig}
                      checked={enableSonifiClients}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SonifiSwitch
                      disabled={!checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)}
                      label={translations.enableThirdPartyClients}
                      onChange={this.updateThirdPartyClientConfig}
                      checked={enableThirdPartyClients}
                    />
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid item xs={6}>
              {checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions) &&
                <Grid container>
                  <Grid item xs={1} />
                  <Grid item xs={11} >
                    <SonifiLabel boldLabel={translations.PMSConfig} />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={11} >
                    <SonifiSwitch
                      checked={autoImportRooms}
                      disabled={!checkSingleUserPermission(SITE_EDIT, userPermissions)}
                      label={translations.autoImportRooms}
                      onChange={this.updateAutoImportRooms}
                    />
                  </Grid>
                </Grid>
              }
            </Grid>
            <Grid item xs={12}>
              <SonifiDivider />
            </Grid>
            {
              !this.props.configurePmsRoomTypes &&
              <Fragment>
                <Grid item xs={6}>
                  <RoomTypes onRoomTypeUpdate={this.onRoomTypeUpdate} />
                </Grid>
              </Fragment>
            }
            {site.platforms && site.platforms.length > 0 &&
              site.platforms.includes(MARINA.charAt(0).toUpperCase() + MARINA.slice(1).toLowerCase()) &&
              <Grid item xs={6}>
                <Grid container>
                  <Grid item xs={1} />
                  <Grid item xs={11} >
                    <SonifiLabel boldLabel={marinaTranslations.title} />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={11} >
                    <SonifiSwitch
                      checked={marinaIpg}
                      disabled={!checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)}
                      label={marinaTranslations.marinaIpg}
                      onChange={this.updateMobile}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.siteManagement.changed,
  channelDelivery: state.siteManagement.channelDelivery,
  channelOptionsLoading: state.siteManagement.channelOptionsLoading,
  chromecastPort: state.terminals.chromecast_port,
  configurePmsRoomTypes: state.integrations.configurePmsRoomTypes,
  globalTranslations: state.global.translations.defaults,
  ipg: state.siteManagement.ipg,
  labMode: state.global.labMode,
  marinaTranslations: state.siteManagement.translations.marina,
  roomOptionsLoading: state.siteManagement.roomOptionsLoading,
  roomTypes: state.siteManagement.roomTypes,
  site: state.global.site,
  siteConfigLoading: state.siteManagement.siteConfigLoading,
  translations: state.siteManagement.translations.general,
  userPermissions: state.global.permissions
});

General.propTypes = {
  channelDelivery: PropTypes.array.isRequired,
  channelOptionsLoading: PropTypes.bool.isRequired,
  chromecastPort: PropTypes.string,
  configurePmsRoomTypes: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  ipg: PropTypes.string,
  labMode: PropTypes.bool,
  marinaTranslations: PropTypes.object,
  roomOptionsLoading: PropTypes.bool.isRequired,
  roomTypes: PropTypes.array,
  site: PropTypes.object,
  siteConfigLoading: PropTypes.bool,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(General);
