import { textHelper } from '../../../utils/textUtil';
import { getTieClientObjectPromise } from '../../../utils/tieApi';
import { checkSubnet } from '../../SiteManagement/utils/validator';
import { CLIENT_CREDENTIALS } from '../constants/TieApiClientConstants';
import { Cidr } from 'cidr-lib';
import { RESTRICTED_SUBNETS } from '../../SiteManagement/constants/SiteContants';

export function validClient(clientObj, translations, globalTranslation) {
  const errors = {};

  const clientNameError = checkName('id', clientObj, translations, globalTranslation);
  if (clientNameError !== null) {
    errors.id = clientNameError;
  }

  const authTypeError = checkName('type', clientObj, translations, globalTranslation);
  if (authTypeError !== null) {
    errors.type = authTypeError;
  }

  if (clientObj.type === CLIENT_CREDENTIALS) {
    const ipString = clientObj.config.ip.replace(/\n+/g, ',');
    const ipWhitespaceArray = ipString.replace(/\s+/g, '');
    const ipArray = ipWhitespaceArray.split(',');
    const commaArray = ipArray.filter((val) => val !== '');

    let invalidIps = '',
      subnet = false;
    for (const i of commaArray) {
      if (i.includes('/')) {
        const subnetCheck = checkIP(i);
        if (subnetCheck.length > 1) {
          invalidIps = subnetCheck;
          subnet = true;
          break;
        }
        // eslint-disable-next-line max-len
      } else if (!(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(i)) {
        invalidIps += `${i}, `;
      }
    }

    if (invalidIps.length > 0) {
      errors.ip = subnet ? `${invalidIps}` : `${translations.ip} ${invalidIps.slice(0, -2)}`;
    }

    if (!clientObj.config.scopes || clientObj.config.scopes.length < 1) {
      errors.scopes = translations.needScope;
    }

    if (!clientObj.config.sites || clientObj.config.sites.length < 1) {
      errors.sites = translations.needSites;
    }
  }
  return errors;
}

export function checkIP(ipAddr) {
  const cidr = new Cidr();
  try {
    if (!checkSubnet(ipAddr)) {
      return `${ipAddr} must use the CIDR-format (a.b.c.d/n)`;
    } else if (RESTRICTED_SUBNETS.some((sub) => cidr.doSubnetsOverlap(sub, ipAddr))) {
      return 'Restricted Subnet';
    }
  } catch(err) {
    return 'Invalid bytes';
  }
  return '';
}

function checkName(fieldName, clientObj, translations, globalTranslation) {
  if (clientObj[fieldName] === null || clientObj[fieldName] === undefined || clientObj[fieldName].trim() === '') {
    return translations[fieldName];
  } else if (textHelper(clientObj[fieldName].trim())) {
    return globalTranslation.symbols;
  }
  return null;
}

export function validId(clientName, translations) {
  return new Promise((resolve, reject) => {
    const promises = [];
    promises.push(getTieClientObjectPromise(clientName)
      .then(() => {
        resolve({ message: translations.inUse });
      }).catch((error) => {
        reject(error);
      }));
  });
}
