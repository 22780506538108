import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { SONIFI } from '../../../../constants/constants';
import { SonifiLabel } from '../../../../containers';
import BaseFirmwareGrid from '../BaseFirmwareGrid';
import EditSonifiFirmware from './EditSonifiFirmware';
import SonifiRow from './SonifiRow';

class SonifiFirmwareGrid extends BaseFirmwareGrid {
  getMake() {
    return SONIFI;
  }

  handleCheckChange = () => ({ target: { checked } }) => {
    this.updateMaintWindow('sonifiEnabled', checked);
  };

  getHeaderInfo() {
    const { sonifiFirmware, translations } = this.props;
    return [
      { id: 'id', sortable: sonifiFirmware.length > 1, numeric: false, label: `${translations.id}` },
      { id: 'firmware', sortable: sonifiFirmware.length > 1, numeric: false, label: `${translations.sonifi.firmware}` },
      { id: 'version', sortable: sonifiFirmware.length > 1, numeric: true, label: `${translations.version}` }
    ];
  }

  getChecked() {
    return this.props.sonifiEnabled;
  }

  getPopupBox() {
    const { canEdit, sonifiIndex } = this.props;
    return sonifiIndex !== -1 && <EditSonifiFirmware canEdit={canEdit} />;
  }

  getTableBody() {
    const { canEdit, sonifiFirmware, translations } = this.props;
    return <Fragment>
      {!sonifiFirmware || sonifiFirmware.length === 0
        ? <TableRow>
          <TableCell colSpan={2}>
            <SonifiLabel error label={translations.sonifi.errors.noTerminals} />
          </TableCell>
        </TableRow >
        : sonifiFirmware.map((option, i) => (
          <SonifiRow key={i} rowIndex={i} canEdit={canEdit} />
        ))}
    </Fragment>;
  }
}

const mapStateToProps = (state) => ({
  changed: state.termFile.changed,
  lgEnabled: state.termFile.lgEnabled,
  samsungEnabled: state.termFile.samsungEnabled,
  sonifiEnabled: state.termFile.sonifiEnabled,
  sonifiFirmware: state.termFile.sonifiFirmware,
  sonifiIndex: state.termFile.sonifiIndex,
  sonifiLoading: state.termFile.sonifiLoading,
  termOptLoading: state.termFile.termOptLoading,
  translations: state.termFile.translations.grid
});

SonifiFirmwareGrid.propTypes = {
  canEdit: PropTypes.bool,
  changed: PropTypes.bool,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  lgEnabled: PropTypes.bool,
  lgFirmware: PropTypes.array,
  lgIndex: PropTypes.number,
  lgLoading: PropTypes.bool,
  samsungEnabled: PropTypes.bool,
  samsungLoading: PropTypes.bool,
  sonifiEnabled: PropTypes.bool,
  sonifiLoading: PropTypes.bool,
  tabValue: PropTypes.string,
  termOptLoading: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(SonifiFirmwareGrid);
