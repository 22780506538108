import { CLIENT_CREDENTIALS, CLIENT_TYPE_SONIFI } from '../constants/TieApiClientConstants';

export function getSubmitObject(client) {
  const {
    active, config, id, password, type
  } = client;
  
  const clientType = client.client_type;
  const currClient = {};
  currClient.active = (active ? active : false);
  currClient.id = (id && id.length > 0 ? id.trim() : '');
  currClient.type = (type && type.length > 0 ? type.trim() : '');
  currClient.client_type = (clientType && clientType.length > 0 ? clientType.trim() : '');

  if (type === CLIENT_CREDENTIALS) {
    currClient.password = (password && password.length > 0 ? password.trim() : '');
    currClient.config = {
      ...config,
      ip: config.ip && config.ip.length > 0 ? config.ip.trim() : ''
    };
    if (clientType === CLIENT_TYPE_SONIFI) {
      currClient.config.sites = ['*'];
    }
  }


  return currClient;
}
