import { Add } from '@mui/icons-material';
import { Chip, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { LATE_CHECKOUT_EDIT } from '../../../constants/roles';
import { SonifiLabel, SonifiSubHeader } from '../../../containers';
import { checkSingleUserPermission } from '../../../utils/rolesUtil';
import { removeSelectedRoomType, showRoomTypesDialog } from '../actions/lateCheckoutActions';

const styles = (theme) => ({
  border: {
    border: `1px solid ${theme.palette.secondary.light}`
  },
  topPadding: {
    paddingTop: 5,
    paddingLeft: 9,
    paddingBottom: 5
  }
});
class RoomTypes extends Component {
  handleDeleteDialog(item) {
    this.props.dispatch(removeSelectedRoomType(item, this.props.selectedRoomTypes));
  }

  handleAddDialog() {
    this.props.dispatch(showRoomTypesDialog(true));
  }

  render() {
    const {
      classes, removingRoomType, selectedRoomTypes, translations, userPermissions
    } = this.props;

    const canEdit = checkSingleUserPermission(LATE_CHECKOUT_EDIT, userPermissions);

    return (
      <Fragment>
        <SonifiSubHeader
          smallerHeader
          header={translations.title}
          onSubmit={this.handleAddDialog.bind(this)}
          label={translations.addBtn}
          icon={<Add />}
          disabled={selectedRoomTypes === null || removingRoomType}
          showButton={canEdit}
        />
        {selectedRoomTypes === null
          ? <Grid container className={classes.border}>
            <SonifiLabel error label={translations.errors.roomTypeError} paddingLeft />
          </Grid>
          : <Grid container className={classes.border}>
            {selectedRoomTypes.length === 0
              ? <SonifiLabel error label={translations.errors.noSelectedRoomTypes} />
              : selectedRoomTypes.map((item, index) => (
                <Grid item key={index} className={classes.topPadding}>
                  {canEdit
                    ? <Chip label={item} onDelete={this.handleDeleteDialog.bind(this, item)} color="primary"
                      disabled={removingRoomType} />
                    : <Chip label={item} color="primary" />}
                </Grid>))}
          </Grid>}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  removingRoomType: state.lateCheckout.removingRoomType,
  selectedRoomTypes: state.lateCheckout.selectedRoomTypes,
  translations: state.lateCheckout.translations.roomType,
  userPermissions: state.global.permissions
});

RoomTypes.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  removingRoomType: PropTypes.bool,
  selectedRoomTypes: PropTypes.array,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(RoomTypes, styles, { withTheme: true }));

