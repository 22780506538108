import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { LATE_CHECKOUT_ROLES } from '../../../constants/roleGroups';
import { SonifiLockoutModalSpinner, SonifiSpinner } from '../../../containers';
import SonifiSnackBar from '../../../containers/SonifiSnackBar';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { getLateCheckout, resetSnackBar } from '../actions/lateCheckoutActions';
import CheckoutOptions from '../containers/CheckoutOptions';
import OptionDialog from '../containers/OptionDialog';
import RoomTypeDialog from '../containers/RoomTypeDialog';
import RoomTypes from '../containers/RoomTypes';

const styles = () => ({
  padding: {
    paddingBottom: '15px'
  },
  parentContainer: {
    alignContent: 'start',
    height: '100%'
  }
});

export class LateCheckoutGrid extends Component {
  componentDidMount() {
    this.props.dispatch(getLateCheckout());
  }

  reset() {
    this.setState({ page: 1, filter: '' });
  }

  closeSnackBar() {
    this.props.dispatch(resetSnackBar(''));
  }

  getPageDetails() {
    const { classes, fetchingOptions } = this.props;

    if (fetchingOptions) {
      return <Grid item xs={12} className={classes.topPadding}>
        <SonifiSpinner />
      </Grid>;
    }

    return (<Grid container className={classes.parentContainer}>
      <Grid item xs={12} className={classes.padding}>
        <RoomTypes />
      </Grid>
      <Grid item xs={12}>
        <CheckoutOptions />
      </Grid>
    </Grid>);
  }

  render() {
    const {
      deleting, saving, savingOptions, savingRoomTypes, selectedOption, showRoomTypesDialog, snackBarMessage,
      snackBarType, translations, userPermissions
    } = this.props;

    if (!checkForAtLeastOneUserPermission(LATE_CHECKOUT_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={deleting || savingRoomTypes || saving || savingOptions} />
        <SonifiTemplate
          header={translations.title}
          pageDetails={this.getPageDetails()}
        />
        {showRoomTypesDialog && <RoomTypeDialog />}
        {selectedOption && <OptionDialog />}
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deleting: state.lateCheckout.deleting,
  fetchingOptions: state.lateCheckout.fetchingOptions,
  saving: state.lateCheckout.saving,
  savingOptions: state.lateCheckout.savingOptions,
  savingRoomTypes: state.lateCheckout.savingRoomTypes,
  selectedOption: state.lateCheckout.selectedOption,
  showRoomTypesDialog: state.lateCheckout.showRoomTypesDialog,
  snackBarMessage: state.lateCheckout.snackBarMessage,
  snackBarType: state.lateCheckout.snackBarType,
  translations: state.lateCheckout.translations.main,
  userPermissions: state.global.permissions
});

LateCheckoutGrid.propTypes = {
  classes: PropTypes.object.isRequired,
  deleting: PropTypes.bool,
  dispatch: PropTypes.func,
  fetchingOptions: PropTypes.bool,
  saving: PropTypes.bool,
  savingOptions: PropTypes.bool,
  savingRoomTypes: PropTypes.bool,
  selectedOption: PropTypes.object,
  showRoomTypesDialog: PropTypes.bool,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(LateCheckoutGrid, styles, { withTheme: true }));
