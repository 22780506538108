import { displaySnackBar } from '../../../actions/globalActions';
import { HIGH_HTTP, LOW_HTTP, TERM_MODELS } from '../../../constants/apiEndpoints';
import { ERROR, SONIFI, SUCCESS } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import { getSiteObjectPromise, patchObjectPromise, postObjectPromise } from '../../../utils/api';
import { patchTerminalOptions } from '../../Terminals/actions/terminalActions';
import { clearModels, fetchModels } from '../../TermModels/actions/termModelActions';
import { LG, SAMSUNG } from '../constants/TermFileConstants';
import * as types from './actionTypes';
import { clearLgFirmware } from './LgFirmwareActions';
import { clearSamsungFirmware } from './SamsungFirmwareActions';
import { clearSonifiFirmware } from './SonifiFirmwareActions';

export const updateMaintenanceWindow = (name, value) => ({
  type: types.UPDATE_MAINTENANCE_WINDOW,
  name,
  value
});

export const resetSnackBar = (snackBarType = SUCCESS, snackBarMessage = '') => ({
  type: types.RESET_SNACK_BAR,
  snackBarType,
  snackBarMessage
});

export const fetchTermFirmwareBegin = (make) => ({
  type: types.FETCH_TERM_FIRMWARE_BEGIN,
  make
});

export const fetchTermFirmwareSuccess = (make, firmwares, clients) => ({
  type: types.FETCH_TERM_FIRMWARE_SUCCESS,
  make,
  clients,
  firmwares
});

export const fetchTermFirmwareFailure = (make, error) => ({
  type: types.FETCH_TERM_FIRMWARE_FAILURE,
  error,
  make
});

export function fetchTermFirmware(termFileObj) {
  return (dispatch) => {
    dispatch(fetchTermFirmwareBegin(termFileObj.make));
    return getSiteObjectPromise(TERM_MODELS, null, null, termFileObj)
      .then((termFiles) => {
        let clientFile = null;
        if (termFileObj.make === LG) {
          clientFile = termFiles.default_site_firmware.lg_client;
        } else if (termFileObj.make === SAMSUNG) {
          clientFile = termFiles.default_site_firmware.samsung_client;
        }
        return dispatch(fetchTermFirmwareSuccess(
          termFileObj.make,
          termFiles.models,
          clientFile
        ));
      })
      .catch((error) => {
        dispatch(fetchTermFirmwareFailure(termFileObj.make, `${error.status}`));
        return false;
      });
  };
}

export function saveTerminalOptions(termOptionObj, translations) {
  return (dispatch) => {
    dispatch(resetSnackBar(null, ''));
    return dispatch(patchTerminalOptions(termOptionObj))
      .then((data) => {
        const snackBarType = data.status === 204 ? SUCCESS : ERROR;
        const snackBarMessage = data.status === 204 ? translations.updateSuccess : translations.updateError;
        dispatch(resetSnackBar(snackBarType, snackBarMessage));
        return data;
      });
  };
}

export const saveSiteFirmwareBegin = () => ({
  type: types.SAVE_SITE_FIRMWARE_BEGIN
});

export const saveSiteFirmwareSuccess = (termType) => ({
  type: types.SAVE_SITE_FIRMWARE_SUCCESS,
  termType
});

export const saveSiteFirmwareFailure = (make, error) => ({
  type: types.SAVE_SITE_FIRMWARE_FAILURE,
  error,
  make
});

export const modelVersionBumpSuccess = () => (dispatch) => {
  dispatch(displaySnackBar());
 };

export const modelVersionBumpFailure = (error) => ({
  type: types.MODEL_VERSION_BUMP_FAILURE,
  error,
});

export function bumpModelVersion(model, currentVersion) {
  const version = currentVersion + 1;
  return (messageSuccess, messageFailure) => (dispatch) => patchObjectPromise(TERM_MODELS, `${model}`, { version })
        .then((json) => {
          dispatch(clearLgFirmware());

          return dispatch(fetchTermFirmware({ make: 'LG' }))
            .then(() => {
              dispatch(displaySnackBar(SUCCESS, messageSuccess));
              return json;
            });
        }).catch((error) => {
          dispatch(displaySnackBar(ERROR, `${messageFailure}: ${error.status}`));
          return false;
        });
}

export function saveSiteFirmware(saveFirmwareObj, termType, siteId = null, getUpdatedInfo = true) {
  saveFirmwareObj.make = termType;
  const model = saveFirmwareObj.id;
  delete saveFirmwareObj.id;

  return (dispatch) => {
    dispatch(saveSiteFirmwareBegin());
    return patchObjectPromise(TERM_MODELS, model, saveFirmwareObj, siteId)
      .then((json) => {
        if (!getUpdatedInfo) {
          if (json.status < LOW_HTTP || json.status >= HIGH_HTTP) {
            return json.json()
              .then((errorObjDetails) => ({
                id: model,
                siteNum: siteId,
                detail: errorObjDetails.detail,
                status: json.status,
                title: errorObjDetails.title
              }));
          }
          return json;
        }

        if (!httpSuccess(json.status)) {
          dispatch(saveSiteFirmwareFailure(termType, `${json.status}`));
          return json;
        }

        switch (termType) {
          case LG:
            dispatch(clearLgFirmware());
            break;
          case SAMSUNG:
            dispatch(clearSamsungFirmware());
            break;
          case SONIFI:
            dispatch(clearSonifiFirmware());
            break;
          default:
            break;
        }

        return dispatch(fetchTermFirmware({ make: termType }))
          .then(() => {
            dispatch(saveSiteFirmwareSuccess(termType));
            return json;
          });
      }).catch((error) => {
        dispatch(saveSiteFirmwareFailure(termType, `${error.status}`));
        return false;
      });
  };
}

export const sortTerminalModels = (sortObj) => ({
  type: types.SORT_TERMINAL_MODELS,
  sortObj
});

export const addNewModel = (modelType) => ({
  type: types.ADD_NEW_MODEL,
  modelType
});

export const getModelsForNewSiteModelBegin = () => ({
  type: types.GET_SITE_MODELS_BEGIN
});

export const getModelsForNewSiteModelSuccess = () => ({
  type: types.GET_SITE_MODELS_SUCCESS
});

export const getModelsForNewSiteModelFailure = (make, error) => ({
  type: types.GET_SITE_MODELS_FAILURE,
  error,
  make
});

export function getModelsForNewSiteModel(filter) {
  return (dispatch) => {
    dispatch(getModelsForNewSiteModelBegin());
    dispatch(clearModels());
    return dispatch(fetchModels(filter))
      .then((data) => {
        dispatch(getModelsForNewSiteModelSuccess());
        return data;
      }).catch((error) => {
        dispatch(getModelsForNewSiteModelFailure(filter.search, error));
        return false;
      });
  };
}

export const saveNewModelBegin = () => ({
  type: types.SAVE_NEW_MODEL_BEGIN
});

export const saveNewModelSuccess = (modelId, modelType) => ({
  type: types.SAVE_NEW_MODEL_SUCCESS,
  modelId,
  modelType
});

export const saveNewModelFailure = (modelId, modelType, error) => ({
  type: types.SAVE_NEW_MODEL_FAILURE,
  modelId,
  modelType,
  error
});

export function saveNewModel(modelId, modelType) {
  return (dispatch) => {
    dispatch(saveNewModelBegin());

    return postObjectPromise(`${TERM_MODELS}/${modelId}`, {})
      .then((data) => dispatch(fetchTermFirmware({ make: modelType }))
        .then(() => {
          dispatch(saveNewModelSuccess(modelId, modelType));

          return data;
        }))
      .catch((error) => {
        dispatch(saveNewModelFailure(modelId, modelType, error.status));
        return error;
      });
  };
}
