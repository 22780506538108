import { displaySnackBar } from '../../../actions/globalActions';
import { SERVER_STREAM } from '../../../constants/apiEndpoints';
import { SUCCESS } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import { getHasStreamChannelsFromSession } from '../../../utils';
import { getSitelessObjectPromise, postSitelessObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

export const cloudStreamDialog = (index, dialogType) => ({
  type: types.CLOUD_STREAM_DIALOG,
  index,
  dialogType
});

export const addStreamChannel = () => ({
  type: types.ADD_STREAM_CHANNEL
});

export const getCloudStreamChansBegin = () => ({
  type: types.GET_CLOUD_STREAM_CHANS_BEGIN,
});

export const getCloudStreamChansSuccess = (response) => ({
  type: types.GET_CLOUD_STREAM_CHANS_SUCCESS,
  response
});

export const getCloudStreamChansFailure = (error) => ({
  type: types.GET_CLOUD_STREAM_CHANS_FAILURE,
  error
});

export function getCloudStreamChans(global = true) {
  return (dispatch) => {
    if (!global && getHasStreamChannelsFromSession() !== 'true') {
      return new Promise((resolve) => {
        resolve();
        return;
      });
    }

    dispatch(getCloudStreamChansBegin());
    return getSitelessObjectPromise(SERVER_STREAM)
      .then((data) => {
        dispatch(getCloudStreamChansSuccess(data));
        return data;
      }).catch((error) => {
        dispatch(getCloudStreamChansFailure(error));
        return error;
      });
  };
}

export const saveStreamChannelBegin = () => ({
  type: types.SAVE_STREAM_CHAN_BEGIN
});

export const saveStreamChannelSuccess = () => ({
  type: types.SAVE_STREAM_CHAN_SUCCESS
});

export const saveStreamChannelFailure = (error) => ({
  type: types.SAVE_STREAM_CHAN_FAILURE,
  error
});

export function saveStreamChannel(channel) {
  return (dispatch) => {
    dispatch(saveStreamChannelBegin());
    return postSitelessObjectPromise(SERVER_STREAM, null, channel, false)
      .then((json) => {
        if (!httpSuccess(json.status)) {
          return json.json()
            .then((errorObjDetails) => {
              dispatch(saveStreamChannelFailure(errorObjDetails.detail));
            });
        }

        return dispatch(getCloudStreamChans())
          .then(() => {
            json.json()
              .then((details) => {
                dispatch(displaySnackBar(SUCCESS, `Successfully saved ${channel.name} - ${details.id}`));
              });
            dispatch(saveStreamChannelSuccess());
            return true;
          });
      }).catch((error) => {
        console.log(error);
        dispatch(saveStreamChannelFailure(`${error.detail} (${error.status})`));
        return false;
      });
  };
}
