import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';

export default class InternationalPhone extends Component {
  state = {
    country: {},
    phone_number: '',
    focused: false
  };

  componentDidMount() {
    if (this.props.phoneNumber) {
      this.setState({ phone_number: this.props.phoneNumber });
    }
  }

  updatePhoneView(value, countryInfo) {
    this.props.updateProfile(value, countryInfo);
  }

  onFocus() {
    this.setState({ focused: true });
  }

  onBlur() {
    this.setState({ focused: false });
  }

  /* eslint-disable max-lines-per-function */
  render() {
    const { errors, phoneNumber, translations } = this.props;
    const authDomain = process.env.REACT_APP_COGNITO_DOMAIN_PREFIX;
    if (!authDomain.includes('signin')) {
      return <div style={{ width: '100%', height: '100%', backgroundColor: 'red' }}></div>;
    }

    let phoneTitleColor = `${errors && errors.phone_number ? 'red' : '#333333'}`;
    if (phoneTitleColor !== 'red' && this.state.focused) {
      phoneTitleColor = '#1592d9';
    }

    return (
      <Grid item style={{ height: '80px' }}>
        <div className="special-label"
          style={{
            fontWeight: 400, /* Avenir 55 */
            background: 'transparent',
            color: `${phoneTitleColor}`,
            fontFamily: 'Open Sans',
            fontSize: '12px ',
            lineHeight: '1',
            padding: '6px 0 0 10px',
            position: 'absolute',
            zIndex: '100'
          }}
        >{translations.phoneNumber}</div>
        <PhoneInput
          autoFormat={true}
          country={'us'}
          enableSearch
          buttonStyle={{
            marginTop: '6px',
            border: 'none',
            background: 'transparent'
          }}
          dropdownStyle={{
            borderRadius: '0px',
            margin: '0px',
            backgroundColor: 'white'
          }}
          searchStyle={{ borderRadius: '0px' }}
          containerStyle={{
            border: 'none',
            left: '0',
            padding: '0',
            background: 'transparent'
          }}
          inputStyle={{
            fontWeight: 500, /* Avenir 65 */
            background: '#f6f6f6',
            border: 'none',
            borderBottom: `1px solid ${phoneTitleColor}`,
            borderRadius: '0px',
            color: `${errors && errors.phone_number ? 'red' : '#333333'}`,
            fontFamily: 'Open Sans',
            fontSize: '12pt',
            height: '56px',
            marginBottom: '0',
            marginRight: '16px',
            paddingTop: '25px',
            width: '320px'
          }}
          onFocus={() => this.onFocus()}
          onBlur={() => this.onBlur()}
          specialLabel={null}
          onChange={this.updatePhoneView.bind(this)}
          value={phoneNumber}
        />
        {errors && errors.phone_number &&
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
            style={{
              color: '#f44336',
              display: 'block',
              fontFamily: 'Open Sans',
              fontSize: '0.75rem',
              fontWeight: 400, /* Avenir 55 */
              lineHeight: '1.66',
              margin: '3px 14px 0px',
              padding: '0 5px',
              zIndex: '1'
            }}
          >
            {errors.phone_number}
          </p>}
      </Grid>
    );
  }
}

InternationalPhone.propTypes = {
  errors: PropTypes.object,
  phoneNumber: PropTypes.string,
  translations: PropTypes.object,
  updateProfile: PropTypes.func
};

