import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import decode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { updateCurrentUser } from '../../../actions/globalActions';
import { SonifiSpinner } from '../../../containers';
import SonifiButton from '../../../containers/SonifiButton';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiUnderlineButton from '../../../containers/SonifiUnderlineButton';
import Logo from '../../../img/SONIFI_Logo_NoTag_Header.png';
import { getTokenFromSession, logOut } from '../../../utils';
import { removePipe } from '../../../utils/textUtil';
import { validPhoneNumber } from '../../Profile/utils/validator';
import Background from '../components/Background';
import { confirmCode, phoneVerified, resendPhone, updatePhone } from '../utils/phone';
import { validCode } from '../utils/validator';
import InternationalPhoneSignin from './InternationalPhoneSignin';
import SonifiAuthInput from './SonifiAuthInput';

const styles = {
  root: {
    backgroundColor: 'transparent',
    borderRadius: 0
  },
  paper: {
    padding: '40px 10px 0px 10px',
    position: 'absolute !important',
    right: '4%',
    top: '6%',
    height: '37rem'
  }
};

class SonifiPhone extends Component {
  state = {
    loading: false,
    phoneNumber: '',
    resetCode: '',
    step: 0
  };

  componentDidMount() {
    if (this.props.phoneNum) {
      this.changePhone(this.props.phoneNum);
    }
  }

  setResetCode = ({ target: { value } }) => {
    this.setState({ resetCode: value });
  };

  updateLoading() {
    this.setState({ step: 1, loading: false });
  }

  changePhone(value, country) {
    this.setState({
      country,
      phoneNumber: removePipe(value),
    });
  }

  success() {
    phoneVerified(this.successContinue.bind(this));
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch({ type: 'USER_LOGGED_OUT' });
      logOut();
    }
  }

  successContinue() {
    setTimeout(() => {
      try {
        const token = decode(getTokenFromSession());
        this.props.dispatch(updateCurrentUser(token));
        setTimeout(() => {
          this.props.changeFunc();
        }, 200);
      } catch(error) {
        console.log(error);
      }
    }, 200);
  }

  failure(error) {
    if (typeof error === 'string') {
      this.setState({ errors: error, loading: false });
    } else {
      this.setState({ errors: error.message, loading: false });
    }
  }

  forgotPassSubmit() {
    if (this.state.step === 0) {
      const awsPhone =
        (this.state.phoneNumber !== undefined
          ? `${this.state.phoneNumber}`
          : undefined);
      const phoneError = validPhoneNumber(awsPhone, this.state.country);

      if (phoneError === null) {
        (this.props.phoneNum === awsPhone
          ? resendPhone(this.updateLoading.bind(this))
          : updatePhone(`+${awsPhone}`, this.updateLoading.bind(this), this.failure.bind(this))
        );
        this.setState({ errors: null, loading: true });
      } else {
        this.setState({ errors: phoneError, loading: false });
      }
    } else if (this.state.step === 1) {
      const codeError = validCode(this.state.resetCode);
      if (codeError === null) {
        this.setState({ loading: true });
        confirmCode(this.state.resetCode, this.success.bind(this), this.failure.bind(this));
      } else {
        this.setState({ errors: codeError, loading: false });
      }
    }
  }

  render() {
    const { classes } = this.props;
    const {
      phoneNumber, errors, resetCode, step, loading
    } = this.state;
    const authDomain = process.env.REACT_APP_COGNITO_DOMAIN_PREFIX;
    if (!authDomain.includes('signin')) {
      return <div style={{ width: '100%', height: '100%', backgroundColor: 'red' }}></div>;
    }

    return (
      <Fragment>
        <Background />
        <Dialog open={true}
          onClose={this.onClose.bind(this)}
          fullWidth
          BackdropProps={{
            classes: {
              root: classes.root
            }
          }}
          PaperProps={{
            classes: {
              root: classes.paper
            }
          }}
          maxWidth="xs" >
          <img src={Logo} alt="SONIFI logo" style={{ margin: 'auto' }} />
          <DialogContent >
            <form>
              <Grid container style={{ justifyContent: 'center' }}>
                {loading && <Grid style={{ height: '162px' }}>
                  <SonifiSpinner />
                </Grid>}
                {!loading &&
                  <Grid item xs={12}>
                    <SonifiLabel size="sm" centered
                      boldLabel={(step === 0
                        ? 'Enter your mobile phone number'
                        : 'Check your mobile phone')} />
                  </Grid>}
                {!loading &&
                  <Grid item xs={12}>
                    <SonifiLabel size="xs" centered
                      label={(step === 0
                        ? 'Required for security authentication'
                        : 'Enter the code that you have received')} />
                  </Grid>}
                {(!loading && step === 0) && <Grid item xs={11}>
                  <InternationalPhoneSignin updateProfile={this.changePhone.bind(this)} phoneNumber={phoneNumber}
                    translations={{ phoneNumber: 'Phone-ish' }} errors={{ phone_number: errors }} />
                </Grid>}
                {(!loading && step === 1) &&
                  <Grid item xs={11} style={{ height: '80px' }}>
                    <SonifiAuthInput
                      placeholder="Enter your mobile code"
                      onChange={this.setResetCode}
                      defaultValue={resetCode} />
                  </Grid>}
                <Grid item xs={11}>
                  <SonifiButton caption="Submit" onClick={this.forgotPassSubmit.bind(this)}
                    fullWidth disabled={loading} />
                </Grid>
                <Grid item xs={11} style={{ textAlign: 'center' }}>
                  <SonifiUnderlineButton label="Back to Sign In" onClick={this.onClose.bind(this)} disabled={loading} />
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <SonifiLabel
              size="xs"
              label={`Copyright© ${new Date().getFullYear()} SONIFI Solutions Inc.  All Rights Reserved`} />
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

SonifiPhone.propTypes = {
  changeFunc: PropTypes.func,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  phoneNum: PropTypes.string
};

export default connect()(withStyles(SonifiPhone, styles));
