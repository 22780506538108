import { updateSnackBar } from '../../../actions/globalActions';
import {
  DELETE_ROOM_RANGE, HIGH_HTTP, LOW_HTTP, ROOM_RESOURCE
} from '../../../constants/apiEndpoints';
import { ERROR, SUCCESS } from '../../../constants/constants';
import {
  deleteObjectPromise, getSiteCsvObjectPromise, getSiteObjectPromise, patchObjectPromise, putObjectPromise
} from '../../../utils/api';
import { addNewTerminal, swapTerminals, unassociateTerminal, updateTerminal } from '../../../utils/terminal';
import * as types from './actionTypes';
import { fetchRooms, getTerminals } from './roomActions';
import { fetchFreeTerminals } from './terminalActions';

export const updateSelectedRoom = (name, value) => ({
  type: types.UPDATE_SELECTED_ROOM,
  name,
  value
});

export function editSelectedRoom(name, value) {
  return (dispatch) => {
    dispatch(updateSelectedRoom(name, value));
  };
}
export function checkForDuplicateRoom(value) {
  return (dispatch) => {
    getSiteObjectPromise(ROOM_RESOURCE, `${encodeURIComponent(value)}`)
      .then(() => {
        dispatch(setDialogError('Duplicate room'));
      }).catch(() => {
        dispatch(setDialogError(null));
      });
  };
}

export function updateRoomGroups(groups, roomId) {
  return (dispatch) => patchObjectPromise(ROOM_RESOURCE, roomId, groups)
    .then((response) => {
      dispatch(patchRoomSuccess());
      return response;
    })
    .catch((error) => {
      dispatch(patchRoomFailure(error));
      return error;
    });
}

export const putRoomBegin = () => ({
  type: types.PUT_ROOM_BEGIN
});

export const addPutRoomBegin = () => ({
  type: types.ADD_PUT_ROOM_BEGIN
});

export const addPutRoomSuccess = (newRoomArr) => ({
  type: types.ADD_PUT_ROOM_SUCCESS,
  newRoomArr
});

export const invalidForm = () => ({
  type: types.INVALID_FORM,
});

export const startAddRoom = () => ({
  type: types.RESET_ADD_ROOM
});

export const startDeleteRoom = () => ({
  type: types.START_DELETE_ROOM
});

export const doneAddingRoom = (numRooms) => ({
  type: types.DONE_ADDING_ROOMS,
  numRooms
});

export const addPutRoomFailure = () => ({
  type: types.ADD_PUT_ROOM_FAILURE
});

export const addPutRoomClose = () => ({
  type: types.ADD_PUT_ROOM_CLOSE
});

export const deleteConfirm = (start, end, deleteRange) => ({
  type: types.DELETE_CHECK,
  start,
  end,
  deleteRange
});

export const getRoomsToDeleteBegin = () => ({
  type: types.GET_ROOMS_TO_DELETE_BEGIN
});

export const getRoomsToDeleteSuccess = (rooms) => ({
  type: types.GET_ROOMS_TO_DELETE_SUCCESS,
  rooms
});

export const getRoomsToDeleteFailure = (error) => ({
  type: types.GET_ROOMS_TO_DELETE_FAILURE,
  error
});

export function getRoomsToDelete(start, end) {
  return (dispatch) => {
    dispatch(getRoomsToDeleteBegin());
    const errorRooms = start === end ? `${start}` : `${start}-${end}`;
    const apiUrl = start === end ? `/${start}` : `?room_start=${start}&room_end=${end}`;
    return getSiteObjectPromise(`${DELETE_ROOM_RANGE}${apiUrl}`)
      .then((response) => {
        dispatch(getRoomsToDeleteSuccess(start === end ? [start] : response.rooms));
        return response;
      })
      .catch(() => dispatch(getRoomsToDeleteFailure(`${errorRooms}`)));
  };
}

export const updateDialogType = (dialogType) => ({
  type: types.DIALOG_TYPE,
  dialogType
});

export const deleteRangeOfRoomsBegin = () => ({
  type: types.DELETE_RANGE_BEGIN
});

export const deleteRangeOfRoomsSuccess = (rooms) => ({
  type: types.DELETE_RANGE_SUCCESS,
  rooms
});

export const deleteRangeOfRoomsFailure = (error) => ({
  type: types.DELETE_RANGE_FAILURE,
  error
});

export function deleteRangeOfRooms(start, end, exclude) {
  return (dispatch) => {
    dispatch(deleteRangeOfRoomsBegin());

    const errorRooms = start === end ? `${start}` : `${start}-${end}`;
    const apiUrl = start === end ? `/${start}` : `?room_start=${start}&room_end=${end}`;
    return deleteObjectPromise(`${DELETE_ROOM_RANGE}${apiUrl}`, null,
      { exclude: [...exclude] })
      .then((response) => {
        dispatch(fetchRooms())
          .then(() => {
            dispatch(deleteRangeOfRoomsSuccess(errorRooms));
            return response;
          });
      })
      .catch(() => dispatch(deleteRangeOfRoomsFailure(errorRooms)));
  };
}

export const resetSnackBar = () => ({
  type: types.RESET_SNACKBAR
});

export const deleteRoomBegin = () => ({
  type: types.DELETE_ROOM_BEGIN
});

export const deleteRoomSuccess = (room) => ({
  type: types.DELETE_ROOM_SUCCESS,
  room
});

export const deleteRoomFailure = (error) => ({
  type: types.DELETE_ROOM_FAILURE,
  error
});

export const patchRoomBegin = () => ({
  type: types.PATCH_ROOM_BEGIN
});

export const patchRoomSuccess = (products) => ({
  type: types.PATCH_ROOM_SUCCESS,
  payload: { products }
});

export const patchRoomFailure = (error) => ({
  type: types.PATCH_ROOM_FAILURE,
  error
});

export const putRoomSuccess = (products) => ({
  type: types.PUT_ROOM_SUCCESS,
  products
});

export const putRoomFailure = (error) => ({
  type: types.PUT_ROOM_FAILURE,
  error
});

export const setDialogError = (error) => ({
  type: types.SET_DIALOG_ERROR,
  error
});

export function submitRoomInfo(room, terminals) {
  return (dispatch) => {
    dispatch(putRoomBegin());

    return preparePutRoom(dispatch, room, terminals, false);
  };
}

// TODOD: Angie figure out how to get array of rooms here for success/fail add!
export function addRoomInfo(room, terminals) {
  return (dispatch) => {
    dispatch(addPutRoomBegin());
    delete room.rangeEnd;
    return putRoom(dispatch, room, terminals, true);
  };
}

// function parseAllRooms(dispatch, startRoom, endRoom, rooms) {
//   const roomsToParse = range(startRoom, endRoom);
//   console.log('roomsToParse');
//   console.log(roomsToParse);

//   const levels = roomsToParse.map((room) => {
//     console.log(room);
//     const newRoom = {
//       ...rooms,
//       id: room
//     };

//     console.log('New Room');
//     console.log(newRoom);
//     return putRoom(null, newRoom, null, true);
//   });


//   Promise.all(levels).then((responses) => {
//     // const newRoomArr = [];
//     // const currentRoom = null;
//     console.log('ALL DONE');
//     console.log(responses);

//     for(let i = 0; i < responses.length; i++) {
//       // currentRoom = responses[i].url.split('/');
//       // newRoomArr.push({
//       //  room: currentRoom[currentRoom.length - 1],
//       //  status: !(responses[i].status < LOW_HTTP || responses[i].status >= HIGH_HTTP)
//       // });

//       /* if(responses[i] && (responses[i].status < LOW_HTTP || responses[i].status >= HIGH_HTTP)) {
//         console.log('FAILURE');
//         return dispatch(addPutRoomFailure(responses[i].title));
//       }*/
//     }

//     // console.log('CHRISTINA  newRoomArr: ', newRoomArr);
//     return roomSuccessful(dispatch, startRoom, responses, true);
//   });
// }

export function resetAddRoom() {
  return (dispatch) => {
    dispatch(addPutRoomClose());
    dispatch(fetchRooms());
    return true;
  };
}

function preparePutRoom(dispatch, room, terminals, addRoom) {
  if (room.pms_id !== null) {
    room.pms_id = room.pms_id.trim();
    if (room.pms_id.length < 1) {
      room.pms_id = null;
    }
  }

  if (room.id !== room.editRoomNum.trim()) {
    const patchRoomObj = {};
    patchRoomObj.id = room.editRoomNum.trim();
    patchRoomObj.pms_id = room.pms_id.trim();
    patchRoomObj.origRoom = room.id;
    patchRoom(dispatch, room, terminals, addRoom, patchRoomObj);
  } else {
    putRoom(dispatch, room, terminals, addRoom);
  }
}
function patchRoom(dispatch, room, terminals, addRoom, patchRoomObj) {
  const origRoom = patchRoomObj.origRoom;
  delete patchRoomObj.origRoom;

  patchObjectPromise(ROOM_RESOURCE, encodeURIComponent(origRoom), patchRoomObj)
    .then(() => {
      dispatch(patchRoomSuccess());
    })
    .then(() => {
      console.log('patchObject promise SUCCESS');
      putRoom(dispatch, room, terminals, addRoom);
    })
    .then(() => {
      dispatch(fetchRooms());
    })
    .catch((error) => {
      console.log('patchObject: PROMISE failureCallback');
      console.log(error);
    });
}
function putRoom(dispatch, room, terminals, addRoom) {
  const roomID = (addRoom ? room.id : room.editRoomNum);
  delete room.id;
  delete room.editRoomNum;
  delete room.stay;
  delete room.rangeEnd;
  delete room.first_error;
  delete room.second_error;
  delete room.wing;

  return putObjectPromise(ROOM_RESOURCE, encodeURIComponent(roomID), room)
    .then((json) => {
      console.log('filterRoomsSuccess PROMISE SUCCESS');
      console.log(json);

      if (!dispatch) {
        return json;
      }
      const response = [];
      response.push(json);
      if (json.status < LOW_HTTP || json.status >= HIGH_HTTP) {
        addRoom
          ? roomSuccessful(dispatch, roomID, response, addRoom)
          : dispatch(putRoomFailure(`${json.status}-${json.title}`));
      }

      if (terminals) {
        console.log('DO THE TERMINALS NOW');
        return parseTerminals(dispatch, roomID, terminals);
      }

      console.log('NO TERMINALS');
      if (!(json.status < LOW_HTTP || json.status >= HIGH_HTTP)) {
        roomSuccessful(dispatch, roomID, response, addRoom);
      }
      return json;
    })
    .catch((error) => {
      console.log('EditDialog: PROMISE failureCallback');
      console.log(error);
      if (dispatch) {
        addRoom ? dispatch(putRoomFailure(error)) : dispatch(addPutRoomFailure(error));
      }
    });
}

function parseTerminals(dispatch, roomID, terminals) {
  const levels = terminals.map((terminal) => {
    console.log('Parse Terminals terminal');
    console.log(terminal);
    delete terminal.interactive_engine;
    if ((terminal.id === 'Add' && terminals.swapId === 'Add') || !terminal.edited) {
      console.log(`Terminal ${terminal.id} nothing needed.`);
      return `No updates needed for ${terminal.id}`;
    } else if (terminal.isNew) {
      console.log(`Terminal ${terminal.id}:${terminal.swapId} is new.`);
      terminal.id = terminal.swapId;
      delete terminal.swapId;
      return addNewTerminal(terminal, roomID);
    } else if (terminal.swapId) {
      console.log(`Terminal ${terminal.id} needs a swap.`);
      return swapTerminals(terminal);
    } else if (terminal.remove) {
      console.log(`Terminal ${terminal.id} REMOVE.`);
      return unassociateTerminal(terminal);
    }
    console.log(`Terminal ${terminal.id} needs an update.`);
    return updateTerminal(terminal);
  });


  Promise.all(levels).then((responses) => {
    roomSuccessful(dispatch, roomID, responses, false);
  });
}

function roomSuccessful(dispatch, roomID, json, addRoom) {
  if (addRoom) {
    const newRoomArr = [];
    let currentRoom = null;
    for (let i = 0; i < json.length; i++) {
      currentRoom = json[i].url.split('/');
      newRoomArr.push({
        room: decodeURIComponent(currentRoom[currentRoom.length - 1]),
        status: !(json[i].status < LOW_HTTP || json[i].status >= HIGH_HTTP)
      });
    }
    dispatch(addPutRoomSuccess(newRoomArr));
  }
  dispatch(putRoomSuccess(json));
  dispatch(fetchFreeTerminals());
  dispatch(getTerminals(roomID));
}

export function removeRoom(startRoom) {
  return (dispatch) => {
    dispatch(deleteRoomBegin());

    return deleteObjectPromise(ROOM_RESOURCE, encodeURIComponent(startRoom))
      .then((json) => {
        if (json.status < LOW_HTTP || json.status >= HIGH_HTTP) {
          dispatch(deleteRoomFailure(`${startRoom} - (${json.status})`));
          return json;
        }

        dispatch(fetchRooms())
          .then(() => {
            dispatch(deleteRoomSuccess(startRoom));
            return json;
          });
      }).catch((error) => {
        dispatch(deleteRoomFailure(`${startRoom} - (${error.status})`));
        return error;
      });
  };
}

export function getRoomsExport() {
  return () => getSiteCsvObjectPromise(ROOM_RESOURCE);
}

export const startAddedRange = () => ({
  type: types.START_ADD_RANGE
});

export const addedRange = (added, duplicates) => ({
  type: types.ADD_RANGE,
  added,
  duplicates
});

export const resetAddedRange = () => ({
  type: types.RESET_ADD_RANGE
});


export const resetContentPinBegin = () => ({
  type: types.RESET_PIN_BEGIN
});

export const resetContentPinComplete = (success) => ({
  type: types.RESET_PIN_COMPLETE,
  success
});

export function resetContentPin(roomId) {
  return (dispatch) => {
    dispatch(resetContentPinBegin());
    return patchObjectPromise(ROOM_RESOURCE, roomId, { content_restriction_pin: null })
      .then((response) => {
        dispatch(resetContentPinComplete(true));
        dispatch(updateSnackBar(SUCCESS, 'Successfully reset PIN'));
        return response;
      })
      .catch((error) => {
        dispatch(resetContentPinComplete(false));
        dispatch(updateSnackBar(ERROR, 'Error resetting PIN'));
        return error;
      });
  };
}
