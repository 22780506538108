import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiSpinnerWithMessage from '../../../containers/SonifiSpinnerWithMessage';
import { invalidForm, resetAddRoom, startDeleteRoom } from '../actions/editRoomActions';
import DeleteComponent from '../containers/Popup/DeleteComponent';
import { checkRoom } from '../utils/index';

class DeleteDialog extends Component {
  state = {
    errors: {},
    range: false,
    room: {
      excluded: [],
      id: '',
      rangeEnd: ''
    }
  };

  modalHeaderName() {
    const { deleteRooms, translations } = this.props;
    return (deleteRooms && deleteRooms.length > 1 ? translations.deleteRooms : translations.deleteRoom);
  }

  modalHeaderSubmitButton() {
    return this.props.globalTranslations.delete;
  }

  addRangeEnd() {
    const newRoom = this.state.room;
    if (this.state.room.id !== '') {
      newRoom.id = newRoom.id.replace(/([a-zA-Z| ])/g, '');
    }
    this.setState({ range: true, room: newRoom });
  }

  handleSelectChange(name, value) {
    const roomConst = { ...this.state.room };
    roomConst[name] = value;
    this.setState({ room: roomConst });
  }

  handleThisChange = (name, value) => {
    this.handleSelectChange(name, (typeof value === 'string' ? value.replace(/([|])/g, '') : value));
  };

  handleCloseAfterSubmit() {
    this.props.dispatch(resetAddRoom());
    this.props.onCancel();
  }

  handleSubmit() {
    const { range, room } = this.state;
    const { confirmFunc, dispatch, translations } = this.props;
    dispatch(startDeleteRoom());
    const validForm = checkRoom(room, translations.validation, range);
    if (Object.keys(validForm).length === 0) {
      confirmFunc(room.id, room.rangeEnd, room.excluded);
    } else {
      dispatch(invalidForm());
      this.setState({ errors: validForm });
      return;
    }
  }

  handleClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  render() {
    const {
      addRoomLoading, deleting, deleteRange, deleteRooms, retrievingRooms, translations
    } = this.props;
    const { range, room } = this.state;

    if (deleting) {
      return <SonifiSpinnerWithMessage show={deleting}
        message={(deleteRange ? translations.deletingRooms : translations.deletingRoom)} />;
    } else if (retrievingRooms || addRoomLoading) {
      return <SonifiSpinnerWithMessage show={addRoomLoading || retrievingRooms}
        message={addRoomLoading ? translations.deletingRooms : translations.retrievingRooms} />;
    }

    return (
      <Dialog
        open={true}
        onClose={this.handleClose.bind(this)}
        fullWidth
      >
        <SonifiModalHeader
          header={this.modalHeaderName()}
          onCancel={this.handleClose.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          label={this.modalHeaderSubmitButton()}
          disabled={deleteRooms && deleteRooms.length === room.excluded.length}
          onlyClose={deleteRooms !== null && deleteRooms.length === 0}

        />
        <DialogContent>
          <DeleteComponent addState={this.state.room}
            onChange={this.handleThisChange.bind(this)}
            addRangeEnd={this.addRangeEnd.bind(this)}
            range={range}
            errors={this.state.errors}
          />
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  addRoomLoading: state.rooms.addRoomLoading,
  deleteRange: state.rooms.deleteRange,
  deleteRooms: state.rooms.deleteRooms,
  deleting: state.rooms.deleting,
  globalTranslations: state.global.translations.defaults,
  retrievingRooms: state.rooms.retrievingRooms,
  translations: state.rooms.translations.addDialog
});

DeleteDialog.propTypes = {
  addRoomLoading: PropTypes.bool,
  confirmFunc: PropTypes.func,
  deleteRange: PropTypes.bool,
  deleteRooms: PropTypes.array,
  deleting: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  retrievingRooms: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(DeleteDialog);
