export const DOES_ROOM_EXIST_BEGIN = 'DOES_ROOM_EXIST_BEGIN';
export const GET_PROVISION_ROOM_TERMINALS_SUCCESS = 'GET_PROVISION_ROOM_TERMINALS_SUCCESS';
export const GET_PROVISION_TERM_INFO_BEGIN = 'GET_PROVISION_TERM_INFO_BEGIN';
export const GET_PROVISION_TERM_INFO_FAILURE = 'GET_PROVISION_TERM_INFO_FAILURE';
export const GET_PROVISION_TERM_INFO_SUCCESS = 'GET_PROVISION_TERM_INFO_SUCCESS';
export const GET_SITE_ROOMS_BEGIN = 'GET_SITE_ROOMS_BEGIN';
export const GET_SITE_ROOMS_FAILURE = 'GET_SITE_ROOMS_FAILURE';
export const GET_SITE_ROOMS_SUCCESS = 'GET_SITE_ROOMS_SUCCESS';
export const RESET_PROVISION_LINK = 'RESET_PROVISION_LINK';
export const UPDATE_PROVISION_LINK = 'UPDATE_PROVISION_LINK';
