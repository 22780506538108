import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { READ } from '../../../constants/constants';
import { compareValues, getTranslationObject } from '../../../utils/index';
import * as actions from '../actions/actionTypes';
import en from '../i18n/en';
import es from '../i18n/es';

const langs = { en, es };

const initialState = {
  deleteCheck: -1,
  editType: READ,
  loading: false,
  message: '',
  saving: false,
  selectedIcp: -1,
  success: false,
  translations: null
};

export default function icpReducer(state = initialState, action) {
  const updatedIcps = (state.icps ? [...state.icps] : []);
  let newIcps = null;

  switch (action.type) {
    case actions.RESET_ICP_SUCCESS:
      return { ...state, message: '' };

    case actions.DELETE_CHECK:
      return {
        ...state,
        deleteCheck: action.icpIndex
      };

    case actions.DELETE_ICP_BEGIN:
      return {
        ...state,
        deleteCheck: -1,
        saving: true
      };

    case actions.DELETE_ICP_SUCCESS:
      return {
        ...state,
        saving: false,
        success: true,
        message: `${state.translations.grid.deleteSuccess} ${action.icpID}`
      };

    case actions.DELETE_ICP_FAILURE:
      return {
        ...state,
        saving: false,
        success: false,
        message: `${state.translations.grid.deleteFailure} ${action.icpID} - (${action.error})`
      };

    case actions.FETCH_ICPS_BEGIN:
      return {
        ...state,
        loading: true
      };

    case actions.FETCH_ICPS_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload.products,
      };

    case actions.FETCH_ICPS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        icps: []
      };

    case actions.GET_ICP_INFO_SUCCESS:
      newIcps = updateIcpViaId(updatedIcps, action.payload.icpID, action.payload.products);
      return {
        ...state,
        icps: newIcps,
      };

    case actions.UPDATE_SELECTED_ICP:
      return {
        ...state,
        message: '',
        selectedIcp: action.index,
        editType: action.editType
      };

    case actions.EDIT_SELECTED_ICP:
      updatedIcps[state.selectedIcp] = {
        ...updatedIcps[state.selectedIcp],
        [action.payload.name]: action.payload.value
      };
      return { ...state, icps: updatedIcps };

    case actions.PUT_ICP_BEGIN:
      return {
        ...state,
        saving: true,
        success: false,
        failure: false,
        message: ''
      };

    case actions.PUT_ICP_SUCCESS:
      return {
        ...state,
        selectedIcp: -1,
        saving: false,
        success: true,
        failure: false,
        message: state.translations.messages.success
      };

    case actions.PUT_ICP_FAILURE:
      return {
        ...state,
        saving: false,
        success: false,
        failure: true,
        message: state.translations.messages.failure
      };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    case actions.SORT_ICPS:
      updatedIcps.sort(compareValues(action.state.orderBy, action.state.order));
      return { ...state, icps: updatedIcps };

    default:
      return state;
  }
}

function updateIcpViaId(icpArr, icpID, info) {
  for (let j = 0; j < icpArr.length; j++) {
    if (icpArr[j].id === icpID) {
      icpArr[j] = { ...icpArr[j], ...info };
      break;
    }
  }
  return icpArr;
}
