import { remove } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { EDIT, READ } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils';
import * as types from '../actions/actionTypes';
import { newStream } from '../constants/constants';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  loading: false,
  dialogType: READ,
  cloudStreamIndex: -1,
  cloudChans: [],
  saving: false,
  translations: null
};

export default function cloudStreamReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_STREAM_CHANNEL:
      return {
        ...state,
        cloudChans: [...state.cloudChans, newStream],
        cloudStreamIndex: state.cloudChans.length,
        dialogType: EDIT
      };

    case types.GET_CLOUD_STREAM_CHANS_BEGIN:
      return {
        ...state,
        cloudChans: [],
        loading: true
      };

    case types.GET_CLOUD_STREAM_CHANS_SUCCESS:
      return {
        ...state,
        cloudChans: action.response,
        loading: false
      };

    case types.GET_CLOUD_STREAM_CHANS_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case types.SAVE_STREAM_CHAN_BEGIN:
      return {
        ...state,
        saving: true
      };

    case types.SAVE_STREAM_CHAN_SUCCESS:
      return {
        ...state,
        cloudStreamIndex: -1,
        saving: false
      };

    case types.SAVE_STREAM_CHAN_FAILURE:
      return {
        ...state,
        saving: false,
      };

    case types.CLOUD_STREAM_DIALOG:
      return {
        ...state,
        cloudChans: (action.index === -1 ? remove(state.cloudChans, (chan) => !chan.isNew) : [...state.cloudChans]),
        cloudStreamIndex: action.index,
        dialogType: action.dialogType ? action.dialogType : state.dialogType
      };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
