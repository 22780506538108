import decode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from './actions/globalActions';
import NonSSO from './components/SignIn/NonSSO';
import SSO from './components/SignIn/SSO';
import { getTokenFromSession, isSessionValid } from './utils';

class AppWithProvider extends Component {
  componentDidMount() {
    const { currentLanguage, dispatch } = this.props;
    const authDomain = process.env.REACT_APP_COGNITO_DOMAIN_PREFIX;
    if (isSessionValid() && authDomain.includes('signin')) {
      const token = decode(getTokenFromSession());
      dispatch(actions.loadLiterals(currentLanguage));
      dispatch(actions.updateCurrentUser(token));
    } else {
      dispatch({ type: 'DESTROY_SESSION' });
      dispatch(actions.loadLiterals(currentLanguage));
      dispatch(actions.updateSite({ id: '' }));
      dispatch(actions.updateGroup(null));
    }
  }

  render() {
    const authDomain = process.env.REACT_APP_COGNITO_DOMAIN_PREFIX;
    return (
      authDomain.includes('signin')
        ? <NonSSO />
        : <SSO />
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: state.global.currentLanguage,
});

AppWithProvider.propTypes = {
  currentLanguage: PropTypes.string,
  dispatch: PropTypes.func
};

export default connect(mapStateToProps)(AppWithProvider);
