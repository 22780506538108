import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { getTranslationObject } from '../../../utils/index';
import {
  GET_SITE_ROOM_NUMBERS_BEGIN, GET_SITE_ROOM_NUMBERS_FAILURE,
  GET_SITE_ROOM_NUMBERS_SUCCESS
} from '../actions/siteLocationActionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  translations: null,
  siteLocations: []
};

export default function siteLocationReducer(state = initialState, action) {
  const universalArray = [];
  switch (action.type) {
    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    case GET_SITE_ROOM_NUMBERS_BEGIN:
      return { ...state, siteLocations: [] };

    case GET_SITE_ROOM_NUMBERS_SUCCESS:
      for (let i = 0; i < action.rooms.rooms.length; i++) {
        universalArray.push({ key: `/${action.rooms.rooms[i].id}`, title: `${action.rooms.rooms[i].id}` });
      }
      return { ...state, siteLocations: universalArray.sort() };

    case GET_SITE_ROOM_NUMBERS_FAILURE:
      return { ...state, error: action.error };

    default:
      return state;
  }
}
