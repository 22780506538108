import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiPopover from '../../../containers/SonifiPopover';
import { updateHostRow } from '../../SystemManagement/actions/systemManagementActions';
import { getShortName } from '../utils';

class HostRow extends Component {
  handleEditDialog() {
    const { dispatch, host } = this.props;
    dispatch(updateHostRow(host));
  }

  hostR() {
    const { host, productParents } = this.props;
    const { assigned, hosts, id, version } = host;

    return (
      <Fragment>
        <TableCell component="th" scope="row">{getShortName(id, productParents)}</TableCell>
        <TableCell component="th" scope="row">{hosts}</TableCell>
        <TableCell component="th" scope="row">{version}</TableCell>
        <TableCell component="th" scope="row">{assigned}</TableCell>
      </Fragment >
    );
  }

  render() {
    const { canEdit, globalTranslations, host } = this.props;
    return (
      canEdit
        ? <SonifiPopover
          editDisabled={!canEdit}
          editFunc={this.handleEditDialog.bind(this)}
          editTitle={globalTranslations.edit}
          hideDelete={true}
          key={host.id}
          tableRow={this.hostR()}
        />
        : <TableRow>{this.hostR()}</TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  productParents: state.deployments.productParents,
  productVersions: state.systemManagement.productVersions,
  globalTranslations: state.global.translations.defaults,
});

HostRow.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  host: PropTypes.object,
  productParents: PropTypes.array,
  productVersions: PropTypes.object
};

export default connect(mapStateToProps)(HostRow);
