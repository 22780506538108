import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiModalHeader from './SonifiModalHeader';

class SonifiModal extends Component {
  render() {
    const {
      children, display, withHeader, header, closeHandler, submitHandler, globalTranslations, size
    } = this.props;

    return (
      <Dialog
        fullWidth
        maxWidth={size}
        onClose={closeHandler}
        open={display}
      >
        {withHeader &&
          <SonifiModalHeader
            header={header}
            onSubmit={submitHandler}
            onCancel={closeHandler}
            label={globalTranslations.save}
          />
        }
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
});

SonifiModal.propTypes = {
  globalTranslations: PropTypes.object.isRequired,
  display: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  submitHandler: PropTypes.func,
  children: PropTypes.array,
  header: PropTypes.string,
  withHeader: PropTypes.bool,
  size: PropTypes.string
};

export default connect(mapStateToProps)(SonifiModal);
