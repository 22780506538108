import { Add, Check, CheckCircleOutline, Lock } from '@mui/icons-material';
import { TableCell, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import { findObjectByKey } from '../../../utils';
import {
  showCreateNewDeployment, updateDeleteRow, updateImmutableRow, updateSelectedReleaseTemplate
} from '../actions/releaseTemplateActions';

class ReleaseRow extends Component {
  getShortName(product) {
    const { productParents } = this.props;
    const shortName = findObjectByKey(productParents, 'id', product);
    return shortName ? shortName.name : product;
  }

  getTooltipText(immutable, active) {
    const { globalTranslations } = this.props;
    if (immutable && active) {
      return globalTranslations.deactivate;
    } else if (immutable && !active) {
      return globalTranslations.reactivate;
    } else {
      return globalTranslations.activate;
    }
  }

  handleEditDialog(type) {
    const { rowIndex } = this.props;
    this.props.dispatch(updateSelectedReleaseTemplate(rowIndex, type));
  }

  handleDeleteDialog() {
    const { dispatch, rowIndex, releaseTemplates } = this.props;
    const productName = this.getShortName(releaseTemplates[rowIndex].product);
    dispatch(updateDeleteRow(releaseTemplates[rowIndex], productName));
  }

  handleImmutableDialog() {
    const { dispatch, releaseTemplates, rowIndex } = this.props;
    const { product } = releaseTemplates[rowIndex];
    dispatch(updateImmutableRow(rowIndex, this.getShortName(product)));
  }

  handleAddRelease(releaseInfo) {
    this.props.dispatch(showCreateNewDeployment(true, releaseInfo));
  }

  render() {
    const {
      canEdit, deleteEnabled, globalTranslations, key, releaseTemplates, rowIndex, deploymentTranslations
    } = this.props;

    const {
      active, immutable, product, version, virtos, packages
    } = releaseTemplates[rowIndex];

    let packageNamesTemp = '';
    if (packages) {
      Object.values(packages).forEach((value) => (packageNamesTemp += `${value.name}   v.${value.version} | `));
    }
    const packageNames = packageNamesTemp.trim().slice(0, -1);

    return (
      <SonifiPopover
        deleteDisabled={!deleteEnabled || immutable}
        deleteFunc={this.handleDeleteDialog.bind(this)}
        deleteTitle={globalTranslations.delete}
        editDisabled={immutable}
        editFunc={this.handleEditDialog.bind(this, 'edit')}
        editTitle={globalTranslations.edit}
        functionOneFunc={this.handleEditDialog.bind(this, 'read')}
        functionOneTitle={globalTranslations.view}
        functionThreeDisabled={!active}
        functionThreeFunc={this.handleAddRelease.bind(this, releaseTemplates[rowIndex])}
        functionThreeIcon={<Add fontSize="small" />}
        functionThreeTitle={deploymentTranslations.manual}
        functionTwoFunc={this.handleImmutableDialog.bind(this, 'read')}
        functionTwoIcon={<Check fontSize="small" />}
        functionTwoTitle={this.getTooltipText(immutable, active)}
        hideDelete={!canEdit}
        hideEdit={!canEdit}
        key={key}
        showFunctionOne={true}
        showFunctionThree={canEdit}
        showFunctionTwo={canEdit}
        tableRow={
          <Fragment>
            <TableCell component="th" scope="row">
              {immutable
                ? <Lock style={{ color: '#1592d9' }} />
                : ''}
            </TableCell>
            <TableCell>
              {active && <Tooltip title={globalTranslations.active}>
                <CheckCircleOutline style={{ color: 'green' }} />
              </Tooltip>}
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={this.getShortName(product)} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={`${version}`} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={`${virtos}`} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={packageNames} />
            </TableCell>
          </Fragment >
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  deploymentTranslations: state.deployments.translations.editDialog,
  globalTranslations: state.global.translations.defaults,
  productParents: state.deployments.productParents,
  releaseTemplates: state.releaseTemplates.releaseTemplates,
  translations: state.releaseTemplates.translations.editDialog
});

ReleaseRow.propTypes = {
  canEdit: PropTypes.bool,
  deleteEnabled: PropTypes.bool,
  deploymentTranslations: PropTypes.object,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  key: PropTypes.number,
  productParents: PropTypes.array,
  releaseTemplates: PropTypes.array,
  rowIndex: PropTypes.number.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(ReleaseRow);

