export const GET_CHANNEL_CHANGES_BEGIN = 'GET_CHANNEL_CHANGES_BEGIN';
export const GET_CHANNEL_CHANGES_FAILURE = 'GET_CHANNEL_CHANGES_FAILURE';
export const GET_CHANNEL_CHANGES_SUCCESS = 'GET_CHANNEL_CHANGES_SUCCESS';
export const RESET_BATCH_CHANNELS = 'RESET_BATCH_CHANNELS';
export const SAVE_REPLACEMENT_BEGIN = 'SAVE_REPLACEMENT_BEGIN';
export const SAVE_REPLACEMENT_COMPLETE = 'SAVE_REPLACEMENT_COMPLETE';
export const SAVE_REPLACEMENT_SUCCESS = 'SAVE_REPLACEMENT_SUCCESS';
export const STOP_JOB_BEGIN = 'STOP_JOB_BEGIN';
export const STOP_JOB_CHECK = 'STOP_JOB_CHECK';
export const STOP_JOB_COMPLETE = 'STOP_JOB_COMPLETE';
export const VIEW_CHANNEL_BEGIN = 'VIEW_CHANNEL_BEGIN';
export const VIEW_CHANNEL_FAILURE = 'VIEW_CHANNEL_FAILURE';
export const VIEW_CHANNEL_SUCCESS = 'VIEW_CHANNEL_SUCCESS';
