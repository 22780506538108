import {
  Autorenew, CancelPresentation, Delete, Edit, GetApp, ImportExport, Receipt, Replay, Visibility
} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Grid, Popover, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { withStyles } from 'tss-react/mui';
import { displaySnackBar } from '../../actions/globalActions';
import { TERMINAL_RESOURCE } from '../../constants/apiEndpoints';
import { EDIT, ERROR, INFO, SUCCESS } from '../../constants/constants';
import { httpSuccess, isClientError, isServerError } from '../../constants/http';
import { DISTANCE_FROM_END } from '../../constants/magic';
import { SONIFI_ROLES, TERMINAL_DETAIL_ROLES } from '../../constants/roleGroups';
import {
  FIRMWARE_EDIT, TERMINAL_DETAIL_EDIT, TERMINAL_SETTINGS_EDIT
} from '../../constants/roles';
import { SonifiConfirm, SonifiLabel, SonifiLockoutModalSpinner, SonifiSpinner } from '../../containers';
import ImportDialog from '../../containers/Dialog/ImportDialog';
import SonifiIconButton from '../../containers/SonifiIconButton';
import SonifiSnackBar from '../../containers/SonifiSnackBar';
import SonifiTableHead from '../../containers/SonifiTableHead';
import SonifiTemplate from '../../containers/SonifiTemplate';
import SonifiTooltip from '../../containers/SonifiTooltip';
import { buildSortString, getSiteNumFromURI } from '../../utils';
import { deleteObjectPromise, isTesting, postObjectPromise } from '../../utils/api';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';
import {
  clearTable, closeSnack, getAllTerminals, getTerminalOptions, getTerminalsExport, getTerminalStatus,
  getTerminalStatuses, updateSelectedTerminal
} from './actions/terminalActions';
import PublicKeyDialog from './components/PublicKeyDialog';
import TerminalLayout from './components/TerminalLayout';
import TerminalsFilter from './components/TerminalsFilter';
import {
  CLEAR, DELETE, FIRMWARE, LOGGING, REBOOT, VIEW
} from './constants/Constants';
import EditRange from './containers/EditRange';
import FirmwareSelect from './containers/FirmwareSelect';
import TerminalRow from './containers/TerminalRow';

const styles = (theme) => ({
  popOverContainer: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff'
  }
});

function exampleCsv() {
  let csv = 'Here is where we can put some instructional text.\n';
  csv += 'Room column is required.\n';
  csv += 'Location is required. \n';
  csv += 'Terminal Address is required. \n';

  /* csv += 'Lineup is optional\n';
  csv += 'Menuset is optional \n';
  csv += 'Hardware is optional \n';
  csv += 'Firmware is optional \n';
  csv += 'Software is optional \n';
  */
  csv += 'Room,Location,Terminal Address\n';
  // csv += 'Room,Location,Terminal,Lineup,Menuset,Hardware,Firmware,Software\n';

  return csv;
}

export class Terminals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowDownload: true,
      columns: [
        { id: 'passive_status', sortable: true, numeric: false, label: this.props.translations.table.status },
        { id: 'room_id', sortable: true, numeric: false, label: this.props.translations.table.room },
        { id: 'id', sortable: true, numeric: false, label: this.props.translations.table.termID },
        { id: 'location_id', sortable: true, numeric: false, label: this.props.translations.table.location },
        { id: 'lineup_id', sortable: true, numeric: false, label: this.props.translations.table.lineup },
        { id: 'menuset_name', sortable: true, numeric: false, label: this.props.translations.table.menuset },
        { id: 'firmware', sortable: true, numeric: false, label: this.props.translations.table.firmware },
        { id: 'hardware', sortable: true, numeric: false, label: this.props.translations.table.hardware },
        { id: 'software', sortable: true, numeric: false, label: this.props.translations.table.software },
        { id: 'log', sortable: false, numeric: false, label: this.props.translations.table.log }
      ],
      count: null,
      index: -1,
      limit: 25,
      lineups: [],
      open: false,
      order: 'asc',
      orderBy: 'room_id',
      page: 1,
      popOverElement: null,
      popOverHeight: null,
      popOverId: -1,
      search: '',
      secondarySort: 'room_id:asc',
      selectedIndex: -1,
      snack: {},
      terminals: [],
      terminalStatuses: {
        assigned: '---',
        unassigned: '---',
        total: '---',
        notCommunicating: '---',
      },
      displayImportDialog: false,
      displayDeleteDialog: false
    };

    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.deleteUnassignedHandler = this.deleteUnassignedHandler.bind(this);
    this.getTableHeaders = this.getTableHeaders.bind(this);
    this.getTerminalRecords = this.getTerminalRecords.bind(this);
    this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
    this.handleDeleteDialogOpen = this.handleDeleteDialogOpen.bind(this);
    this.handleImportClick = this.handleImportClick.bind(this);
    this.handleImportClose = this.handleImportClose.bind(this);
    this.handleRequestDownload = this.handleRequestDownload.bind(this);
    this.importParsingHandler = this.importParsingHandler.bind(this);
    this.importSaveHandler = this.importSaveHandler.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
  }

  componentDidMount() {
    this.closeSnackBar();
    this.props.dispatch(clearTable());
    this.props.dispatch(getTerminalOptions()).then(() => {
      this.props.dispatch(getAllTerminals(buildSortString(this.state)));
      this.props.dispatch(getTerminalStatuses());
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(clearTable());
      this.props.dispatch(getAllTerminals(buildSortString(this.state)));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(getAllTerminals(buildSortString(this.state)));
    }
  }

  onCancel(resetPage) {
    (resetPage) ? this.setState({ index: -1, page: 1 }) : this.setState({ index: -1 });
    this.props.dispatch(updateSelectedTerminal({ type: '', term: -1 }));
  }

  handleWayPointReached() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  // click on table column headers
  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    });
  };

  handlePopover = (id, index) => (event) => {
    const idStr = id.toString();
    this.props.dispatch(getTerminalStatus(idStr));

    const { currentTarget } = event;
    this.setState((state) => ({
      popOverId: id,
      popOverElement: currentTarget,
      popOverHeight: currentTarget.clientHeight,
      open: !state.open,
      index
    }));
  };

  handlePopoverClose = (event) => {
    if (event && this.state.popOverElement) {
      if (this.state.popOverElement.contains(event.target)) {
        return;
      }
    }

    this.setState({ open: false, popOverId: -1, index: -1 });
  };

  handleButtonClick(name) {
    this.props.dispatch(updateSelectedTerminal({ type: name, term: null }));
  }

  handleImportClick() {
    this.setState({ displayImportDialog: true });
  }

  handleImportClose() {
    this.setState({ displayImportDialog: false });
  }

  handleDeleteDialogOpen() {
    this.setState({ displayDeleteDialog: true });
  }

  handleDeleteDialogClose() {
    this.setState({ displayDeleteDialog: false });
  }

  getTableHeaders() {
    const { importTableTranslations } = this.props;
    return [
      { id: '', sortable: false, numeric: false, label: '' },
      {
        id: 'room',
        sortable: false,
        numeric: false,
        label: importTableTranslations.headers.room
      },
      {
        id: 'location',
        sortable: false,
        numeric: false,
        label: importTableTranslations.headers.location,
      },
      {
        id: 'terminal_address',
        sortable: false,
        numeric: false,
        label: importTableTranslations.headers.terminal_address,
      },
    ];
  }

  getTerminalRecords(parseResults) {
    const { dispatch, translations } = this.props;
    const cleanResults = parseResults.map(
      (record) => {
        // eslint-disable-next-line no-useless-escape
        const cleanedRecord = record.map((entry) => entry.replaceAll('\"', ''));
        return cleanedRecord;
      }
    ).filter(
      (record) => record.every((x) => x !== '')
    );
    const headerIdx = cleanResults.findIndex(
      (record) => (
        record.includes('Room') &&
        record.includes('Location') &&
        record.includes('Terminal Address')
      )
    );

    if (headerIdx === -1) {
      dispatch(displaySnackBar(ERROR, translations.snackbars.bulkImportFileFailure));
      return;
    }

    const roomIdx = cleanResults[headerIdx].findIndex((header) => header === 'Room');
    const locationIdx = cleanResults[headerIdx].findIndex((header) => header === 'Location');
    const terminalIdx = cleanResults[headerIdx].findIndex((header) => header === 'Terminal Address');


    const records = cleanResults.slice(headerIdx + 1);
    return records.map(
      (record) => ({ room: record[roomIdx], location: record[locationIdx], terminal_address: record[terminalIdx] })
    );
  }

  importParsingHandler(parseResults) {
    const headers = this.getTableHeaders();
    const columnNames = headers.map((x) => ({ id: x.id, type: 'label' })).filter((x) => x.id !== '');

    const terminalRecords = this.getTerminalRecords(parseResults);

    return {
      tableList: terminalRecords,
      allowSave: false,
      headers,
      columnNames
    };
  }

  async importSaveHandler(data) {
    const { dispatch, translations } = this.props;
    try {
      const response = await postObjectPromise(TERMINAL_RESOURCE, { terminals: data });
      if (httpSuccess(response.status)) {
        dispatch(displaySnackBar(SUCCESS, translations.snackbars.bulkImportSuccess));
        dispatch(getAllTerminals(buildSortString(this.state)));
        this.setState({ displayImportDialog: false });
      }

      if (isServerError(response.status)) {
        dispatch(displaySnackBar(ERROR, translations.snackbars.bulkImportServerFailure));
        console.log(response);
      }

      if (isClientError(response.status)) {
        dispatch(displaySnackBar(ERROR, translations.snackbars.bulkImportClientFailure));
        console.log(response);
      }
      return response;
    } catch(error) {
      console.log(error);
    }
  }

  async deleteUnassignedHandler() {
    const { dispatch, translations } = this.props;
    try {
      const response = await deleteObjectPromise(`${TERMINAL_RESOURCE}?unassigned=true`);
      if (httpSuccess(response.status)) {
        const obj = await response.json();
        if (obj.deleted_count === 0) {
          dispatch(displaySnackBar(INFO, translations.snackbars.unassignedDeleteNone));
        } else {
          dispatch(displaySnackBar(SUCCESS, translations.snackbars.unassignedDeleteSuccess));
          dispatch(clearTable());
          dispatch(getAllTerminals(buildSortString(this.state)));
        }
      }

      if (isServerError(response.status)) {
        dispatch(displaySnackBar(ERROR, translations.snackbars.unassignedDeleteFailureServer));
        console.log(response);
      }

      if (isClientError(response.status)) {
        const obj = await response.json();
        dispatch(displaySnackBar(ERROR, `${translations.snackbars.unassignedDeleteFailureClient} ${obj.detail}`));
        console.log(obj);
      }

      this.setState({ displayDeleteDialog: false });
      return response;
    } catch(error) {
      console.log(error);
    }
  }

  handleRequestDownload() {
    this.setState({ allowDownload: false });
    this.props.dispatch(getTerminalsExport());

    setTimeout(() => {
      this.setState({ allowDownload: true });
    }, 2000);
  }

  updateDialog(name) {
    this.closeSnackBar();
    this.props.dispatch(updateSelectedTerminal({ type: name, term: this.props.terminals[this.state.index] }));

    setTimeout(() => {
      this.setState({
        selectedIndex: this.state.popOverId, open: false, popOverId: -1
      });
    }, 200);
  }

  closeSnackBar() {
    this.setState({ selectedIndex: -1 });
    this.props.dispatch(closeSnack());
  }

  getPopOverButtons() {
    const {
      classes, globalTranslations, languages, lineups, loading, menusets, terminals, translations, userPermissions
    } = this.props;
    const { popOverHeight, index, open } = this.state;

    if (loading || !open) {
      return <Fragment />;
    }

    const isIp = String(this.state.popOverId).split(':').length > 1;

    let isSonifiTerm = false;
    if (this.state.index !== -1) {
      isSonifiTerm = terminals[index].model_make && terminals[index].model_make === 'SONIFI';
    }

    const terminalRole = checkForSingleUserPermission(TERMINAL_DETAIL_EDIT, userPermissions);
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.popOverContainer}
        style={{ height: { popOverHeight } }}
      >
        {checkForAtLeastOneUserPermission(TERMINAL_DETAIL_ROLES, userPermissions) &&
          <SonifiTooltip
            onClick={this.updateDialog.bind(this, VIEW)}
            icon={<Visibility fontSize="small" />}
            title={globalTranslations.view}
          />
        }
        {terminalRole &&
          <Fragment>
            <SonifiTooltip
              onClick={this.updateDialog.bind(this, EDIT)}
              icon={<Edit fontSize="small" />}
              title={globalTranslations.edit}
              disabled={lineups.length < 2 && menusets.length < 2 && languages.length < 2}
            />
            <SonifiTooltip
              onClick={this.updateDialog.bind(this, REBOOT)}
              icon={<Replay fontSize="small" />}
              title={translations.tooltips.reboot}
            />
          </Fragment>

        }
        {isIp &&
          <SonifiTooltip
            onClick={this.updateDialog.bind(this, CLEAR)}
            icon={<CancelPresentation fontSize="small" />}
            title={translations.tooltips.clearKey}
          />
        }
        {isSonifiTerm && checkForSingleUserPermission(FIRMWARE_EDIT, userPermissions) &&
          <SonifiTooltip
            onClick={this.updateDialog.bind(this, FIRMWARE)}
            icon={<Autorenew fontSize="small" />}
            title={translations.tooltips.firmware}
          />
        }
        {checkForSingleUserPermission(TERMINAL_SETTINGS_EDIT, userPermissions) &&
          <SonifiTooltip
            onClick={this.updateDialog.bind(this, LOGGING)}
            icon={<Receipt fontSize="small" />}
            title={terminals[index].log_forwarding ? translations.tooltips.logOff : translations.tooltips.logOn}
          />
        }
        {terminalRole &&
          <SonifiTooltip
            onClick={this.updateDialog.bind(this, DELETE)}
            icon={<Delete fontSize="small" />}
            title={globalTranslations.delete}
          />
        }
      </Grid>
    );
  }

  resetFilter(search) {
    this.setState({
      order: 'asc',
      orderBy: 'room_id',
      page: 1,
      search
    });
  }

  getPageDetails() {
    const { loading, maxPages, terminals, translations } = this.props;
    const {
      columns, open, order, orderBy, page, popOverElement
    } = this.state;

    return (
      <Fragment>
        <TerminalsFilter resetFilter={this.resetFilter} />
        <Table>
          <SonifiTableHead
            headColumns={columns}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />
          <Popover
            open={open}
            anchorEl={popOverElement}
            PaperProps={{ style: { borderRadius: 0 } }}
            onClose={this.handlePopoverClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }} >
            {this.getPopOverButtons()}
          </Popover>
          <TableBody>
            {loading
              ? <TableRow>
                <TableCell colSpan={10}>
                  <SonifiSpinner />
                </TableCell>
              </TableRow>
              : <Fragment>
                {terminals.length < 1 || (terminals.length === 1 && terminals[0] === 'error')
                  ? <TableRow hover={false}>
                    <TableCell colSpan={10}>
                      <SonifiLabel error label={translations.errors.noTerms} />
                    </TableCell>
                  </TableRow>
                  : terminals.map((terminal, i) => (
                    <Fragment key={terminal.id}>
                      <TableRow
                        hover={true}
                        onClick={this.handlePopover(terminal.id, i)} >
                        <TerminalRow terminal={terminal} />
                      </TableRow>
                      {i === terminals.length - DISTANCE_FROM_END && page < maxPages && (
                        <TableRow>
                          <TableCell colSpan={10}>
                            <Waypoint onEnter={() => { this.handleWayPointReached(); }} />
                          </TableCell>
                        </TableRow>)
                      }
                    </Fragment>
                  ))}
              </Fragment>}
          </TableBody>
        </Table>
      </Fragment>
    );
  }

  render() {
    const {
      batchSaving, loading, savingFirmware, snack, translations, type, userPermissions, globalTranslations,
      importTranslations, importDialogTranslations, isProd
    } = this.props;
    const { allowDownload, selectedIndex, displayImportDialog, displayDeleteDialog } = this.state;

    if (getSiteNumFromURI() === null ||
      checkForSingleUserPermission(TERMINAL_DETAIL_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }


    const hasSonifiRole = checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions);

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={savingFirmware || batchSaving} />
        <SonifiTemplate
          header={translations.titles.terminals}
          pageDetails={this.getPageDetails()}
          middleExtra={
            <Grid container>
              {hasSonifiRole &&
                <Grid item>
                  <SonifiIconButton
                    disabled={!hasSonifiRole || loading}
                    onClick={this.handleDeleteDialogOpen}
                    icon={<DeleteIcon />}
                    label={translations.buttons.deleteUnassigned}
                    testLabel="delete-unassinged-terminals-button"
                  />
                </Grid>
              }
              {!isTesting(isProd) && <Grid item>
                <SonifiIconButton
                  disabled={!allowDownload || loading}
                  onClick={this.handleImportClick}
                  icon={<ImportExport />}
                  label={globalTranslations.import}
                  testLabel="bulk-import-terminals-button"
                />
              </Grid>}
              <Grid item>
                <SonifiIconButton
                  disabled={!allowDownload || loading}
                  onClick={this.handleButtonClick.bind(this, 'editRange')}
                  icon={<Edit />} label={translations.buttons.range} />
              </Grid>
              <SonifiIconButton disabled={!allowDownload || loading} onClick={this.handleRequestDownload}
                icon={<GetApp />}
                label={translations.buttons.downloadTerm} />
            </Grid>
          }
        />
        <SonifiConfirm
          dialogOpen={displayDeleteDialog}
          confirmTitle={translations.deleteDialog.title}
          confirmText={translations.deleteDialog.text}
          buttonConfirmText={translations.deleteDialog.buttons.confirmText}
          buttonCancelText={translations.deleteDialog.buttons.cancelText}
          onCancel={this.handleDeleteDialogClose}
          onConfirm={this.deleteUnassignedHandler}
          testId={'delete-unassigned-terminals-dialog'}
        />
        {displayImportDialog &&
          <ImportDialog
            csvText={exampleCsv()}
            onCancel={this.handleImportClose}
            globalTranslations={importTranslations}
            parseImportFile={this.importParsingHandler}
            saveListFunc={this.importSaveHandler}
            title={importDialogTranslations.title}
            serverValidation={true}
          />
        }
        {type !== '' && type !== 'editRange' && <PublicKeyDialog
          termID={`${selectedIndex}`}
          type={type}
          onCancel={this.onCancel}
          sort={buildSortString(this.state)}
          popup={type === EDIT || type === VIEW ? <TerminalLayout /> : <FirmwareSelect />}
        />}
        {type === 'editRange' &&
          <EditRange onCancel={this.onCancel} />}
        <SonifiSnackBar
          message={snack.message}
          variant={snack.variant}
          open={snack.open}
          onClose={this.closeSnackBar}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  batchSaving: state.termGrid.batchSaving,
  changed: state.termGrid.changed,
  globalTranslations: state.global.translations.defaults,
  importTranslations: state.global.translations.importDialog,
  importDialogTranslations: state.termGrid.translations.importDialog,
  importTableTranslations: state.termGrid.translations.importTable,
  isProd: state.global.isProd,
  languages: state.termGrid.languages,
  lineups: state.termGrid.lineups,
  loading: state.termGrid.loading,
  maxPages: state.termGrid.maxPages,
  menusets: state.termGrid.menusets,
  savingFirmware: state.termGrid.savingFirmware,
  snack: state.termGrid.snack,
  terminals: state.termGrid.terminals,
  translations: state.termGrid.translations,
  type: state.termGrid.type,
  userPermissions: state.global.permissions
});

Terminals.propTypes = {
  batchSaving: PropTypes.bool,
  changed: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  importTranslations: PropTypes.object,
  importDialogTranslations: PropTypes.object,
  importTableTranslations: PropTypes.object,
  isProd: PropTypes.bool,
  languages: PropTypes.array.isRequired,
  lineups: PropTypes.array,
  loading: PropTypes.bool,
  maxPages: PropTypes.number,
  menusets: PropTypes.array.isRequired,
  savingFirmware: PropTypes.bool,
  snack: PropTypes.object,
  terminals: PropTypes.array,
  translations: PropTypes.object,
  type: PropTypes.string,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(Terminals, styles, { withTheme: true }));
