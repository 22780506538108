import { Autocomplete, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const _RIGHTMARGINSPACING = 2;
const _HEIGHT = 10;

const styles = (theme) => ({
  textFieldDefault: {
    marginTop: 0,
    marginRight: 0,
    width: '200px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldXtraSmall: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '70px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldPercentWidth: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '95%',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldSmall: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '200px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldMedium: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '320px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldLarge: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '400px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  marginRightOnly: {
    marginTop: 0,
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '200px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textBox: {
    marginTop: 0,
    width: '649px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  }
});

export class SonifiAutoCompleteText extends Component {
  render() {
    const {
      classes, defaultValue, error, errorText, helperText, label, onChange, options, placeholder, popupIcon, size
    } = this.props;

    let classNames;

    if (classes && size) {
      if (size === 'sm') {
        classNames = classes.textFieldSmall;
      } else if (size === 'md') {
        classNames = classes.textFieldMedium;
      } else if (size === 'lg') {
        classNames = classes.textFieldLarge;
      } else if (size === 'xs') {
        classNames = classes.textFieldXtraSmall;
      } else if (size === 'percent') {
        classNames = classes.textFieldPercentWidth;
      } else if (size === 'marginRight') {
        classNames = classes.marginRightOnly;
      } else if (size === 'textBox') {
        classNames = classes.textBox;
      } else {
        classNames = classes.textFieldDefault;
      }
    }

    const showError = ((typeof error === 'undefined') || (error === null)) ? false : error;

    return (
      <Autocomplete
        autoHighlight
        defaultValue={defaultValue}
        getOptionLabel={(option) => (option.description || option.label)}
        // getOptionSelected={(option, value) => (option.value) === value.value}
        onChange={onChange}
        options={options}
        popupIcon={popupIcon}
        renderInput={(params) => (
          <TextField {...params}
            className={classNames}
            error={showError}
            helperText={(showError && errorText) ? errorText : helperText}
            label={label}
            margin="normal"
            placeholder={placeholder}
            variant="filled"
          />
        )}
      />
    );
  }
}
SonifiAutoCompleteText.propTypes = {
  classes: PropTypes.object.isRequired,
  defaultValue: PropTypes.object,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  popupIcon: PropTypes.object,
  size: PropTypes.string
};

export default withStyles(SonifiAutoCompleteText, styles, { withTheme: true });
