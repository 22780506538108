import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { SonifiSubHeader, SonifiText } from '../../../../../containers';
import { getErrorText, isError } from '../../../../../utils';
import {
  bandwidthValues, dvbcAndsModulationValues, DVB_S, encryptionValues, mpegFormatValues, polarizationValues,
  satelliteTypes
} from '../../../constants/constants';

export default class IntlDvbsForm extends Component {
  getSelectText(label, value, editVal, items, name) {
    const { errors, readOnly } = this.props;
    return (
      <SonifiText
        defaultValue={value}
        change={this.props.editFunction(DVB_S, editVal)}
        select={true}
        label={label}
        size="md"
        items={items}
        error={isError(name, errors)}
        errorText={getErrorText(name, errors)}
        disabled={readOnly} />
    );
  }

  getText(label, value, editVal, name) {
    const { errors, readOnly } = this.props;
    return (
      <SonifiText
        defaultValue={value}
        change={this.props.editFunction(DVB_S, editVal)}
        label={label}
        size="md"
        error={isError(name, errors)}
        errorText={getErrorText(name, errors)}
        disabled={readOnly} />
    );
  }

  render() {
    const { dvbsObj, translations } = this.props;
    return (
      <Fragment>
        <Grid item>
          <SonifiSubHeader
            header={translations.international}
            showButton={false}
          />
          {this.getSelectText(translations.carrier, dvbsObj.carrier,
            'carrier', satelliteTypes, 'dvbsCarrier')}
          {this.getText(translations.frequencyMhz, dvbsObj.frequency_mhz,
            'frequency_mhz', 'dvbsFrequency')}
        </Grid>
        <Grid item>
          {this.getSelectText(translations.dFormat, dvbsObj.video_format,
            'video_format', mpegFormatValues, 'dvbsFormat')}
          {this.getSelectText(translations.dEncryption, dvbsObj.encryption,
            'encryption', encryptionValues, 'dvbsEncryption')}
        </Grid>
        <Grid item>
          {this.getText(translations.dIntlStream, dvbsObj.stream,
            'stream', 'dvbsStream')}
          {this.getSelectText(translations.dModulation, dvbsObj.modulation,
            'modulation', dvbcAndsModulationValues, 'dvbsModulation')}
        </Grid>
        <Grid item>
          {this.getSelectText(translations.dBandwidth, dvbsObj.bandwidth,
            'bandwidth', bandwidthValues, 'dvbsBandwidth')}
          {this.getText(translations.dSymbolRate, dvbsObj.symbol_rate,
            'symbol_rate', 'dvbsSymbolRate')}
        </Grid>
        <Grid item>
          {this.getText(translations.satelliteId, dvbsObj.satellite_id,
            'satellite_id', 'satelliteId')}
          {this.getSelectText(translations.polarization, dvbsObj.polarization,
            'polarization', polarizationValues, 'polarization')}
        </Grid>
      </Fragment>
    );
  }
}

IntlDvbsForm.propTypes = {
  dvbsObj: PropTypes.object,
  editFunction: PropTypes.func.isRequired,
  editNonEventFunction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
  translations: PropTypes.object,
  tuning: PropTypes.string
};
