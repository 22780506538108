import { Grid, Menu, MenuItem } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { CHANNEL_EDIT } from '../../../constants/roles';
import { SonifiConfirm, SonifiSpinner } from '../../../containers';
import { checkSingleUserPermission } from '../../../utils/rolesUtil';
import * as actions from '../actions/channelsActions';
import { MAX_CHANNEL_NUMBER } from '../constants/constants';
import { emptyChannel } from '../dataObjects/channelObjects';
import ChannelRow from './ChannelRow';
import TableHeader from './TableHeader';

const styles = (theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden'
  },
  channelTable: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 1,
    overflow: 'hidden',
    border: `1px solid ${theme.palette.primary.light}`,
  },
  colorBlue: {
    backgroundColor: theme.palette.secondary.main,
  },
  channelTableContainer: {
    border: `1px solid ${theme.palette.primary.light}`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flexShrink: 1,
    marginTop: '5px'
  },
  channelTableSubContainer: {
    padding: '10px 5px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  droppableFlex: {
    overflow: 'auto'
  }
});

class Channels extends Component {
  state = {
    showMenu: false
  };

  componentDidMount() {

  }

  // Function called at the end of the channel drag
  // Checks to see if channel has changed positions
  // If it has then it calls the move function
  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const oldIndex = result.source.index;
    const newIndex = result.destination.index;

    // This is to trigger the blue fade to white transition on the moved row
    this.props.dispatch(actions.setDraggingRow(-1));

    // If channel moved, then call the move function
    if (oldIndex !== newIndex) {
      this.props.dispatch(actions.moveChannel(oldIndex, newIndex));
    }
  }

  // Function get call at the beginning of drag
  // Sets the dragging row to style the specific row differently
  startListDrag(result) {
    this.props.dispatch(actions.setDraggingRow(result.source.index));
  }

  openMenu = (channelIndex, channel) => (event) => {
    // this.props.dispatch(closeLineupSaveSuccess());
    this.props.dispatch(actions.setSnackBar());
    this.setState({
      menuAnchor: event.target,
      showMenu: true,
      channelIndex,
      deleteChanNum: channel.id,
      deleteChanName: channel.name
    });
    event.preventDefault();
  };

  deleteChannelCheck() {
    this.setState({ showConfirmDialog: true, showMenu: false });
  }

  // Delete a channel function, used in dropdown menu
  handleDeleteChannel = () => {
    this.setState({ showConfirmDialog: false });
    const currentChannels = [...this.props.realChannels];
    currentChannels.splice(this.state.channelIndex, 1);
    this.props.saveChannelList(currentChannels, { type: 'delete', chan: this.state.deleteChanName });
  };

  // Inserts a channel before the row the user clicks on
  // This means that it will take the channel number of the row
  // The user clicked on and push the clicked on row down the list
  insertNewBefore = () => {
    const { realChannels } = this.props;
    this.updateChan(realChannels[this.state.channelIndex].id);
  };

  // Inserts a channel after the row the user clicks on
  // This means it will insert a row whose number will be one greater
  // than the clicked on row
  insertNewAfter = () => {
    const { realChannels } = this.props;
    let newChannelNumber = 1;
    newChannelNumber = realChannels[this.state.channelIndex].id + 1;
    newChannelNumber = newChannelNumber > MAX_CHANNEL_NUMBER
      ? this.getNextChannel()
      : newChannelNumber;
    this.updateChan(newChannelNumber);
  };

  updateChan(newChannelNumber) {
    const { dispatch } = this.props;
    dispatch(actions.updateSelectedChannel({ ...emptyChannel, isNew: true, id: newChannelNumber }));
    this.setState({ showMenu: false });
  }

  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  // This a cancel for the confirm dialog
  cancel = () => {
    this.setState({ showConfirmDialog: false });
  };

  render() {
    const {
      channelHeaders, classes, draftExists, gettingChannelSet, globalTranslations, realChannels,
      translations, userPermissions
    } = this.props;
    const { deleteChanName, deleteChanNum, showConfirmDialog } = this.state;
    const canEdit = checkSingleUserPermission(CHANNEL_EDIT, userPermissions);

    return <Fragment>
      <SonifiConfirm
        buttonCancelText={globalTranslations.cancel}
        buttonConfirmText={globalTranslations.save}
        confirmText={`${translations.deleteChanCheck} ${deleteChanNum} ${deleteChanName}?`}
        confirmTitle={`${translations.deleteChannel}`}
        dialogOpen={!!showConfirmDialog}
        onCancel={this.cancel.bind(this)}
        onConfirm={this.handleDeleteChannel.bind(this)}
      />
      <div className={classes.channelTableContainer}>
        <div className={classes.channelTableSubContainer}>
          <TableHeader translations={channelHeaders} />
          <div className={`${classes.channelTable} ${!gettingChannelSet && classes.colorBlue}`}>
            {gettingChannelSet
              ? <Grid container>
                <Grid item xs={12}>
                  <SonifiSpinner />
                </Grid>
              </Grid>
              : <DragDropContext
                onDragEnd={this.onDragEnd.bind(this)}
                onDragStart={this.startListDrag.bind(this)}>
                <Droppable droppableId="droppable" >
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={classes.droppableFlex}>
                      {realChannels.map((channel, index) => (
                        <Draggable key={index} draggableId={`${index}`} index={index}
                          isDragDisabled={!canEdit || draftExists}>
                          {(providedOne) => (
                            <div
                              ref={providedOne.innerRef}
                              {...providedOne.draggableProps}>
                              <ChannelRow
                                channel={channel}
                                drag={providedOne}
                                menuEvent={!canEdit || draftExists
                                  ? () => { }
                                  : this.openMenu.bind(this, index, channel)}
                                readOnly={!canEdit || draftExists}
                                rowIndex={index}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>}
          </div>
        </div>
      </div>
      <div>
        <Menu open={this.state.showMenu} onClose={this.closeMenu} anchorEl={this.state.menuAnchor}>
          <MenuItem onClick={this.insertNewBefore}>{translations.insertBefore}</MenuItem>
          <MenuItem onClick={this.insertNewAfter}>{translations.insertAfter}</MenuItem>
          <MenuItem onClick={this.deleteChannelCheck.bind(this)}>{translations.deleteChannel}</MenuItem>
        </Menu>
      </div>
    </Fragment>;
  }
}
const mapStateToProps = (state) => ({
  channelHeaders: state.channels.translations.channelHeaders,
  gettingChannelSet: state.channels.gettingChannelSet,
  globalTranslations: state.global.translations.defaults,
  realChannels: state.channels.realChannels,
  translations: state.channels.translations.channels,
  userPermissions: state.global.permissions
});

Channels.propTypes = {
  channelHeaders: PropTypes.object,
  classes: PropTypes.object.isRequired,
  deleteCheck: PropTypes.func,
  dispatch: PropTypes.func,
  draftExists: PropTypes.bool,
  gettingChannelSet: PropTypes.bool,
  globalTranslations: PropTypes.object,
  realChannels: PropTypes.array,
  saveChannelList: PropTypes.func,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(Channels, styles, { withTheme: true }));
