import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiTabs from '../../../containers/SonifiTabs';
import { getCheckBox } from '../../../utils/ui';
import {
  DISCOUNT, FIAS_IP, FIAS_SERIAL, INTERFACE, IP, SERIAL, SUBTOTAL,
  TAX, USER, VENDORS
} from '../constants/constants';
import Discount from './pmsd/Discount';
import Interface from './pmsd/Interface';
import Ip from './pmsd/Ip';
import Serial from './pmsd/Serial';
import SubTotal from './pmsd/SubTotal';
import Tax from './pmsd/Tax';
import User from './pmsd/User';
import Vendors from './pmsd/Vendors';

function isInterfaceFias(interfaceType) {
  return [FIAS_IP, FIAS_SERIAL].indexOf(interfaceType) > -1;
}

function isInterfaceTaxSeparate(interfaceType, postTaxSeparate) {
  return isInterfaceFias(interfaceType) || postTaxSeparate;
}

class Pmsd extends Component {
  state = {
    selectedTab: INTERFACE
  };

  handleChange(event, newValue) {
    this.setState({ selectedTab: newValue });
  }

  handleCheckChange = (name) => ({ target }) => {
    this.props.updateTypeState(name, target.checked, 'interface');
  };

  getTabContainer() {
    const { notEditable, editState, updateTypeState } = this.props;
    switch (this.state.selectedTab) {
      case INTERFACE:
        return <Interface notEditable={notEditable} editState={editState}
          updateTypeState={updateTypeState} />;
      case SERIAL:
        return <Serial notEditable={notEditable} editState={editState} updateTypeState={updateTypeState} />;
      case IP:
        return <Ip notEditable={notEditable} editState={editState}
          updateTypeState={updateTypeState} />;
      case USER:
        return <User notEditable={notEditable} editState={editState}
          updateTypeState={updateTypeState} />;
      case TAX:
        return <Tax notEditable={notEditable} editState={editState}
          updateTypeState={updateTypeState} />;
      case DISCOUNT:
        return <Discount notEditable={notEditable} editState={editState}
          updateTypeState={updateTypeState} />;
      case SUBTOTAL:
        return <SubTotal notEditable={notEditable} editState={editState}
          updateTypeState={updateTypeState} />;
      case VENDORS:
        return <Vendors notEditable={notEditable} editState={editState}
          updateTypeState={updateTypeState} />;
      default:
        return <Fragment />;
    }
  }

  getTabsArray() {
    const { editState } = this.props;
    const isFias = isInterfaceFias(editState.interface.interface_type);
    const isTaxSeparate = isInterfaceTaxSeparate(
      editState.interface.interface_type,
      editState.interface.post_tax_separate
    );

    const tabsArray = [{ id: INTERFACE, title: INTERFACE }];
    tabsArray.push(editState.interface.interface_type !== FIAS_IP
      ? { id: SERIAL, title: SERIAL }
      : { id: IP, title: IP });

    if (isFias) {
      tabsArray.push({ id: USER, title: USER });
    }

    if (isTaxSeparate) {
      tabsArray.push({ id: TAX, title: TAX });
    }

    if (isFias) {
      tabsArray.push({ id: DISCOUNT, title: DISCOUNT });
    }

    if (isTaxSeparate) {
      tabsArray.push({ id: SUBTOTAL, title: SUBTOTAL });
    }

    tabsArray.push({ id: VENDORS, title: VENDORS });

    return tabsArray;
  }

  render() {
    const { notEditable, editState, translations } = this.props;
    const { selectedTab } = this.state;

    return (
      <Fragment>
        <div style={{ position: 'absolute', right: '10px' }}>
          {getCheckBox(translations.interfaceFlag, editState.interface.interface_flag,
            this.handleCheckChange('interface_flag'), !notEditable)}
        </div>
        <SonifiTabs
          tabs={this.getTabsArray()}
          handleChange={this.handleChange.bind(this)}
          tabValue={selectedTab}
          showingTab={this.getTabContainer()} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.integrations.translations.pmsd.interface
});

Pmsd.propTypes = {
  editState: PropTypes.object,
  notEditable: PropTypes.bool,
  translations: PropTypes.object,
  updateTypeState: PropTypes.func
};

export default connect(mapStateToProps)(Pmsd);
