import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SonifiText } from '../../../../containers';
import { durations } from '../../constants/constants';

export default class PropertyPopupSection extends Component {
  /* eslint-disable camelcase */
  render() {
    const {
      displayName, edit, errors, propertyInfo, readOnly, translations, type
    } = this.props;

    const {
      description, duration, entry, source_id
    } = propertyInfo;

    return (
      <Grid container>
        <Grid item xs={6}>
          <SonifiText
            defaultValue={displayName}
            change={edit(null, 'name')}
            label={translations.displayName}
            size="md"
            error={!!errors.displayName}
            errorText={(errors.displayName ? errors.displayName : '')}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <SonifiText
            defaultValue={source_id}
            label={translations.sourceId}
            size="md"
            disabled={true}
          />
        </Grid>
        <Grid item xs={6}>
          <SonifiText
            defaultValue={duration}
            change={edit(type, 'duration')}
            label={translations.duration}
            size="md"
            select={true}
            items={durations}
            error={!!errors.duration}
            errorText={(errors.duration ? errors.duration : '')}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={6}>
          <SonifiText
            defaultValue={entry}
            change={edit(type, 'entry')}
            label={translations.title}
            size="md"
            error={!!errors.entry}
            errorText={(errors.entry ? errors.entry : '')}
            disabled={readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <SonifiText
            defaultValue={description}
            change={edit(type, 'description')}
            label={translations.description}
            size="textBox"
            error={!!errors.description}
            errorText={(errors.description ? errors.description : '')}
            disabled={readOnly}
            rows={3}
            multiline={true}
          />
        </Grid>
      </Grid>
    );
  }
}

PropertyPopupSection.propTypes = {
  displayName: PropTypes.string,
  edit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  propertyInfo: PropTypes.object,
  readOnly: PropTypes.bool,
  translations: PropTypes.object,
  type: PropTypes.string
};
