import { GetApp } from '@mui/icons-material';
import {
  Grid, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { DISTANCE_FROM_END } from '../../../../constants/magic';
import {
  SonifiIconButton, SonifiLabel, SonifiSnackBar, SonifiSpinner, SonifiTableHead
} from '../../../../containers';
import SonifiTemplate from '../../../../containers/SonifiTemplate';
import { buildSortString } from '../../../../utils';
import * as actions from '../actions/purchaseAction';
import LogDialog from './LogDialog';
import LogRow from './LogRow';
import PurchaseFilter from './PurchaseFilter';

const STARTING_STATE = {
  allowDownload: true,
  limit: 10,
  nowDate: moment().format('MM/DD/YYYY hh:mm a'),
  order: 'desc',
  orderBy: 'timestamp',
  page: 1,
  search: '',
  start: null
};

const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

class PurchaseGrid extends Component {
  state = STARTING_STATE;

  componentDidMount() {
    this.props.dispatch(actions.resetPurchaseLogs());
  }

  searchFunc(search, startTime) {
    const { dispatch } = this.props;

    dispatch(actions.resetPurchaseLogs());

    this.setState({ search, page: 1, start: startTime }, () => {
      dispatch(actions.getPurchaseLogsData(this.getQueryParams()));
    });
  }

  getQueryParams() {
    const params = buildSortString(this.state);

    if (this.state.start) {
      params.start_timestamp = moment(this.state.start).toISOString(true);
    }

    if (this.state.search !== '') {
      params.search = this.state.search;
    }

    return params;
  }

  getPageDetails() {
    const { order, orderBy, page } = this.state;
    const {
      loading, logs, maxPages, selectedLog, translations
    } = this.props;

    const tableHeader = [
      {
        id: 'status', sortable: false, numeric: false, label: `${translations.status}`, narrow: true
      },
      { id: 'transaction_time', sortable: false, numeric: false, label: `${translations.timestamp}` },
      { id: 'room_number', sortable: false, numeric: false, label: `${translations.room}` },
      { id: 'purchase_time', sortable: false, numeric: false, label: `${translations.checkoutTime}` },
    ];

    return (<Fragment>
      <PurchaseFilter searchFunc={this.searchFunc.bind(this)} />
      <Table stickyHeader={true}>
        <SonifiTableHead
          headColumns={tableHeader}
          order={order}
          orderBy={orderBy}
        />
        {loading
          ? <TableBody>
            <TableRow>
              <TableCell colSpan={4}>
                <SonifiSpinner />
              </TableCell>
            </TableRow>
          </TableBody>
          : <TableBody>
            {(!logs || logs.length === 0 || logs === null)
              ? <TableRow>
                <TableCell colSpan={4}>
                  <SonifiLabel error label={`${translations.errors.noLogs} 
                  ${moment(this.state.start).format('MM/DD/YYYY hh:mm a')} 
                  ${translations.errors.and} ${this.state.nowDate}`} />
                </TableCell>
              </TableRow>
              : logs.map((option, index) => (
                <Fragment key={`w_${index}`}>
                  <LogRow key={index} rowIndex={index} />

                  {index === logs.length - DISTANCE_FROM_END && page < maxPages && (
                    <TableRow>
                      <TableCell>
                        <Waypoint onEnter={() => { this.getMoreData(); }} />
                      </TableCell>
                    </TableRow>)
                  }
                </Fragment>
              ))}
          </TableBody>}
      </Table>
      {selectedLog && <LogDialog />}
    </Fragment>);
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }), () => {
        this.props.dispatch(actions.getPurchaseLogsData(this.getQueryParams()));
      });
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  handleRequestDownload() {
    this.setState({ allowDownload: false });

    this.props.dispatch(
      actions.getPurchaseLogsExport(this.getQueryParams())
    ).finally(() => {
      this.setState({ allowDownload: true });
    });
  }

  closeSnackBar() {
    this.props.dispatch(actions.resetPurchaseSnack());
  }

  render() {
    const { snackBarMessage, snackBarType, translations } = this.props;
    const { allowDownload } = this.state;

    return (
      <Fragment>
        <SonifiTemplate
          header={translations.title}
          pageDetails={this.getPageDetails()}
          middleExtra={
            <Grid container>
              <SonifiIconButton disabled={!allowDownload} onClick={this.handleRequestDownload.bind(this)}
                icon={<GetApp />}
                label={translations.buttons.downloadTerm} />
            </Grid>
          }
        />
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.purchaseLogs.loading,
  logs: state.purchaseLogs.logs,
  maxPages: state.purchaseLogs.maxPages,
  selectedLog: state.purchaseLogs.selectedLog,
  snackBarMessage: state.purchaseLogs.snackBarMessage,
  snackBarType: state.purchaseLogs.snackBarType,
  translations: state.purchaseLogs.translations.grid
});

PurchaseGrid.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  logs: PropTypes.array,
  maxPages: PropTypes.number,
  selectedLog: PropTypes.object,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  translations: PropTypes.object
};

export { PurchaseGrid, STARTING_STATE, DATETIME_FORMAT };
export default connect(mapStateToProps)(PurchaseGrid);
