export const ACTIVE_CHECK = 'ACTIVE_CHECK';
export const ADD_CLIENT_BEGIN = 'ADD_CLIENT_BEGIN';
export const ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_VALIDATION_FAILURE = 'ADD_CLIENT_VALIDATION_FAILURE';
export const CLEAR_SITES_TABLE = 'CLEAR_SITES_TABLE';
export const GET_TIE_API_CLIENTS_BEGIN = 'GET_TIE_API_CLIENTS_BEGIN';
export const GET_TIE_API_CLIENTS_FAILURE = 'GET_TIE_API_CLIENTS_FAILURE';
export const GET_TIE_API_CLIENTS_SUCCESS = 'GET_TIE_API_CLIENTS_SUCCESS';
export const GET_TIE_SITES_BEGIN = 'GET_TIE_SITES_BEGIN';
export const GET_TIE_SITES_FAILURE = 'GET_TIE_SITES_FAILURE';
export const GET_TIE_SITES_SUCCESS = 'GET_TIE_SITES_SUCCESS';
export const UPDATE_SELECTED_CLIENT = 'UPDATE_SELECTED_CLIENT';
export const UPDATE_SNACK_BAR = 'TIE_UPDATE_SNACK_BAR';
