import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSiteNumFromURI } from '../../utils';
import IntegrationTable from './components/IntegrationTable';

export default function Integrations() {
  if (getSiteNumFromURI() === null) {
    return <Navigate replace to="/" />;
  }
  return (
    <IntegrationTable />
  );
}
