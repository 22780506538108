import { Home } from '@mui/icons-material';
import { Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { setTabSelected } from '../../actions/globalActions';
import {
  ALL_MESSAGE_ROLES, ALL_SYSTEM_ROLES, FIRMWARE_ROLES, GROUP_SERVICES_ROLES, LATE_CHECKOUT_ROLES, SITE_ROLES,
  SOFTWARE_ROLES, SONIFI_ROLES, USER_ACCOUNT_ROLES
} from '../../constants/roleGroups';
import { SONIFI_ADMIN, USERLOG_READ } from '../../constants/roles';
import { getSiteNumFromURI, hasITv, siteIsOnlyDWB } from '../../utils';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';

const styles = () => ({
  home: {
    width: '50px',
    height: '50px',
    color: '#BFBFBF',
  },
  homeHighlighted: {
    width: '50px',
    height: '50px',
    color: '#EEEEEE'
  },
  homeTab: {
    width: '100px',
    minWidth: '100px',
    maxWidth: '100px',
    height: '100px',
    minHeight: '100px',
    maxHeight: '100px',
  },
  hidden: {
    display: 'none'
  }
});

class CCATabs extends Component {
  // TODO: Pretty sure this is safe to remove value not being used
  componentDidMount() {
    const { dispatch } = this.props;
    const path = window.location.pathname;
    let match = undefined;
    if (path.startsWith('/global')) {
      match = path.match('^(/[a-zA-Z]+(/[a-zA-Z]+)?)');
    } else if (path.startsWith('/site')) {
      match = path.match('^(/[a-zA-Z]+/[0-9]{7}(/[a-zA-Z]+)?)');
    } else {
      match = path.match('^(/[a-zA-Z]+/[a-zA-Z]+)');
    }
    const pathSelected = match ? match[1] : '/';

    dispatch(setTabSelected(pathSelected));
  }

  componentDidUpdate() {
    const { dispatch, tabSelected } = this.props;
    const path = window.location.pathname;
    let match = undefined;
    if (path.startsWith('/global')) {
      match = path.match('^(/[a-zA-Z]+(/[a-zA-Z]+)?)');
    } else if (path.startsWith('/site')) {
      match = path.match('^(/[a-zA-Z]+/[0-9]{7}(/[a-zA-Z]+)?)');
    } else {
      match = path.match('^(/[a-zA-Z]+/[a-zA-Z]+)');
    }
    const pathSelected = match ? match[1] : '/';

    if (path !== '/site' && pathSelected !== tabSelected) {
      dispatch(setTabSelected(pathSelected));
    }
  }


  handleChange = (event, newValue) => {
    this.props.dispatch(setTabSelected(newValue));
  };

  render() {
    const {
      classes, marinaOnlySite, productParents, site, translations, userPermissions, siteId, tabSelected
    } = this.props;

    const isGlobal = window.location.pathname.startsWith('/global');
    const canManage = checkForAtLeastOneUserPermission(
      [...SOFTWARE_ROLES, ...USER_ACCOUNT_ROLES, SONIFI_ADMIN],
      userPermissions
    );
    const canConfigure = checkForAtLeastOneUserPermission([...FIRMWARE_ROLES, ...SONIFI_ROLES], userPermissions);
    const isSite = window.location.pathname.startsWith('/site');
    const showSiteTabs = isSite && getSiteNumFromURI() !== undefined;
    const canReport = checkForSingleUserPermission(USERLOG_READ, userPermissions);
    const canDownload = checkForAtLeastOneUserPermission([...SONIFI_ROLES], userPermissions);

    const showReporting = (
      (getSiteNumFromURI() !== null
        && site.has_late_checkout
        && checkForAtLeastOneUserPermission(LATE_CHECKOUT_ROLES, userPermissions))
      || checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)
    );

    const showCustomize = (
      checkForAtLeastOneUserPermission(ALL_MESSAGE_ROLES, userPermissions) ||
      (site.has_group_services && checkForAtLeastOneUserPermission(GROUP_SERVICES_ROLES, userPermissions)) ||
      site.has_hotel_directory
    );

    const showSystem = (
      (
        siteIsOnlyDWB(this.props.productParents) && (
          checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions) ||
          (checkForAtLeastOneUserPermission(SITE_ROLES, userPermissions) && hasITv(productParents))
        )
      ) ||
      (
        !siteIsOnlyDWB(this.props.productParents) && checkForAtLeastOneUserPermission(ALL_SYSTEM_ROLES, userPermissions)
      )
    );


    if (isGlobal && !canManage && !canConfigure) {
      return <Navigate to="/site" />;
    }

  //  const currSelected = window.location.pathname;
  //  let match = undefined;
  //  if (isGlobal) {
  //    match = currSelected.match('^(/[a-zA-Z]+(/[a-zA-Z]+)?)');
  //  } else if (isSite) {
  //    match = currSelected.match('^(/[a-zA-Z]+/[0-9]{7}/[a-zA-Z]+)');
  //  } else {
  //    match = currSelected.match('^(/[a-zA-Z]+/[a-zA-Z]+)');
  //  }
  //  const matchSelected = match ? match[1] : '/';

  //  console.log('MATCH');
  //  console.log(matchSelected);
  //  console.log('TAB');
  //  console.log(tabSelected);

    // if (isSite && !showSiteTabs) {
    //  currSelected = '/';
    // }

    return (
      <Tabs
        value={tabSelected}
        onChange={this.handleChange}
        variant="scrollable"
        scrollButtons={false}>
        <Tab value="/" className={classes.hidden} />
        <Tab value="/user/profile" className={classes.hidden} />
        <Tab value="/user/provision" className={classes.hidden} />
        <Tab icon={
          <Home className={tabSelected === '/global'
            ? classes.homeHighlighted
            : classes.home} />}
          data-testid="globalNavbarHomeTab"
          value="/global" component={Link} to="/global"
          className={`${!isGlobal && classes.hidden} ${classes.homeTab}`} />
        {canManage && <Tab label={translations.tabs.global.manage} value="/global/manage"
          disabled={tabSelected === '/global/manage'}
          component={Link} to="/global/manage" className={`${!isGlobal && classes.hidden}`} />}
        {canConfigure && <Tab label={translations.tabs.global.configure} value="/global/configure"
          disabled={tabSelected === '/global/configure'}
          component={Link} to="/global/configure" className={`${!isGlobal && classes.hidden}`} />}
        {canReport && <Tab label={translations.tabs.global.reports} value="/global/reports"
          disabled={tabSelected === '/global/reports'}
          component={Link} to="/global/reports" className={`${!isGlobal && classes.hidden}`} />}
        {canDownload && <Tab label={translations.tabs.global.downloads} value="/global/downloads"
          disabled={tabSelected === '/global/downloads'}
          component={Link} to="/global/downloads" className={`${!isGlobal && classes.hidden}`} />}
        <Tab icon={
          <Home className={tabSelected.match('/site/[0-9]{7}$')
            ? classes.homeHighlighted
            : classes.home}
          />}
          data-testid="siteNavbarHomeTab"
          value={`/site/${siteId}`} component={Link} to={`/site/${siteId}`}
          disabled={!showSiteTabs && window.location.pathname === '/site'}
          className={`${!isSite && classes.hidden} ${classes.homeTab}`} />
        {showSystem && <Tab label={translations.tabs.system.title}
          component={Link} to={`/site/${siteId}/system`}
          disabled={tabSelected === `/site/${siteId}/system`}
          value={`/site/${siteId}/system`}
          className={`${(!isSite || !showSiteTabs) && classes.hidden}`} />}
        {showCustomize && !siteIsOnlyDWB(productParents) && !marinaOnlySite &&
          <Tab label={translations.tabs.product.title}
            component={Link} to={`/site/${siteId}/product`}
            disabled={tabSelected === `/site/${siteId}/product`}
            value={`/site/${siteId}/product`}
            className={`${(!isSite || !showSiteTabs) && classes.hidden}`} />}
        {showReporting && !siteIsOnlyDWB(productParents) && !marinaOnlySite &&
          <Tab label={translations.tabs.report.title}
            component={Link} to={`/site/${siteId}/reporting`}
            disabled={tabSelected === `/site/${siteId}/reporting`}
            value={`/site/${siteId}/reporting`}
            className={`${(!isSite || !showSiteTabs) && classes.hidden}`} />
        }
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  tabSelected: state.global.tabSelected,
  marinaOnlySite: state.global.marinaOnlySite,
  productParents: state.global.site && state.global.site.product_parents ? state.global.site.product_parents : [],
  site: state.global.site,
  translations: state.global.translations,
  userPermissions: state.global.permissions,
  siteId: state.v2.site.id
});

CCATabs.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  handleChange: PropTypes.func,
  marinaOnlySite: PropTypes.bool,
  productParents: PropTypes.array,
  site: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array,
  siteId: PropTypes.string.isRequired,
  tabSelected: PropTypes.string
};

export default connect(mapStateToProps)(withStyles(CCATabs, styles));
