// import { httpSuccess } from '../../../constants/http';
import uniq from 'lodash.uniq';
import { CLIENTS, SITES } from '../../../constants/apiEndpoints';
import { EDIT } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import { getTieClientObjectPromise, getTieResource, putSitelessTieObjectPromise } from '../../../utils/tieApi';
import * as types from './actionTypes';

export const clearSitesTable = () => ({
  type: types.CLEAR_SITES_TABLE
});

export const getTieApiClientsBegin = (reset) => ({
  type: types.GET_TIE_API_CLIENTS_BEGIN,
  reset: !!reset
});

export const getTieApiClientsFailure = (error) => ({
  type: types.GET_TIE_API_CLIENTS_FAILURE,
  error
});

export const getTieApiClientsSuccess = (clients) => ({
  type: types.GET_TIE_API_CLIENTS_SUCCESS,
  clients
});

export function getTieApiClients(filter) {
  return (dispatch) => {
    dispatch(getTieApiClientsBegin(filter && filter.page && filter.page === 1));
    return getTieClientObjectPromise(null, filter)
      .then((data) => {
        dispatch(getTieApiClientsSuccess(data.clients));
        return true;
      }).catch((error) => {
        // console.log(error);
        dispatch(getTieApiClientsFailure(error.status));
        return false;
      });
  };
}

export const updateSelectedClient = (client, editType) => ({
  type: types.UPDATE_SELECTED_CLIENT,
  client,
  editType
});

export const updateSnackBar = (snackBarMessage, snackBarType = null) => ({
  type: types.UPDATE_SNACK_BAR,
  snackBarType,
  snackBarMessage
});

export const updateActiveRow = (activeClient, index) => ({
  type: types.ACTIVE_CHECK,
  activeClient,
  index
});

export function updateActiveStatus(client) {
  return (dispatch) => {
    const updatedClient = { ...client };
    updatedClient.active = !client.active;
    return dispatch(addClient(updatedClient, 'update', true));
  };
}

export const addClientBegin = () => ({
  type: types.ADD_CLIENT_BEGIN
});

export const addClientFailure = (client, update, error) => ({
  type: types.ADD_CLIENT_FAILURE,
  client,
  error,
  update
});

export const addClientValidationFailure = () => ({
  type: types.ADD_CLIENT_VALIDATION_FAILURE
});

export const addClientSuccess = (client, update) => ({
  type: types.ADD_CLIENT_SUCCESS,
  client,
  update
});

export function addClient(client, update = EDIT, updateActive = false) {
  return (dispatch) => {
    dispatch(addClientBegin());

    const submitObj = { ...client };
    if (submitObj.config && submitObj.config.brand === 'None') {
      submitObj.config.brand = '';
    }

    if (!updateActive) {
      if (submitObj.config.ip && submitObj.config.ip.length > 0) {
        const ipString = submitObj.config.ip.replace(/\n+/g, ',');
        const ipWhitespaceArray = ipString.replace(/\s+/g, '');
        const ipArray = ipWhitespaceArray.split(',');
        const commaArray = ipArray.filter((val) => val !== '');
        submitObj.config.ip = uniq(commaArray);
      }
    }

    delete (submitObj.id);

    return putSitelessTieObjectPromise(CLIENTS, client.id, submitObj)
      .then((data) => {
        if (!httpSuccess(data.status)) {
          dispatch(addClientFailure(client, update, data.status));
          return false;
        }
        return dispatch(getTieApiClients())
          .then(() => {
            dispatch(addClientSuccess(client, update));
            return true;
          });// .catch(() => true);
      }).catch((error) => {
        // console.log(error);
        dispatch(addClientFailure(client, update, error.status));
        return false;
      });
  };
}

export const getTieSitesBegin = () => ({
  type: types.GET_TIE_SITES_BEGIN
});

export const getTieSitesSuccess = (sites) => ({
  type: types.GET_TIE_SITES_SUCCESS,
  sites
});

export const getTieSitesFailure = (error) => ({
  type: types.GET_TIE_SITES_FAILURE,
  error
});

export function getTieSites(filterObj, clearTable = true) {
  return (dispatch) => {
    if (clearTable) {
      dispatch(clearSitesTable());
    }
    dispatch(getTieSitesBegin());

    return getTieResource(SITES, null, filterObj)
      .then((data) => {
        dispatch(getTieSitesSuccess(data));
        return data;
      }).catch((error) => {
        dispatch(getTieSitesFailure(error));
        return error;
      });
  };
}
