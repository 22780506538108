import { Grid, Paper } from '@mui/material';
import { Auth } from 'aws-amplify';
import decode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { setTabSelected, updateCurrentUser } from '../../../actions/globalActions';
import { SUCCESS } from '../../../constants/constants';
import { SONIFI_ROLES } from '../../../constants/roleGroups';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiLockoutModalSpinner from '../../../containers/SonifiLockoutModalSpinner';
import SonifiModal from '../../../containers/SonifiModal';
import SonifiSnackBar from '../../../containers/SonifiSnackBar';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiText from '../../../containers/SonifiText';
import {
  getErrorText, getTokenFromSession, isError, setItemInSession, setToken
} from '../../../utils/index';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { confirmCode, phoneVerified, resendPhone, updatePhone } from '../../SignInAuth/utils/phone';
import { validCode } from '../../SignInAuth/utils/validator';
import { addUser, updateSnackBar } from '../../UserManagement/actions/userActions';
import Admin from '../containers/Admin';
import ProfileInfo from '../containers/ProfileInfo';
import { validateProfile } from '../utils/validator';

const styles = (theme) => ({
  contents: {
    backgroundColor: theme.palette.defaults.disabledBackground,
    boxShadow: 'none',
    padding: '15px',
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  editContent: {
    height: '100%',
    overflow: 'hidden'
  },
  innerContents: {
    width: '100%',
    overflowX: 'auto',
    marginTop: 15,
    border: 'none',
    flexGrow: 1,
  },
  paperView: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  paddingBottom: {
    paddingBottom: '20px'
  },
  siteSelect: {
    height: '77%',
    minHeight: '77%'
  },
  siteSelectIndividual: {
    height: '92%',
    minHeight: '92%'
  }
});

class ProfileSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      mobileCode: null,
      profile: null,
      showCodePopup: false
    };

    this.handleUpdateUser = this.handleUpdateUser.bind(this);
    this.updateProfile = this.updateProfile.bind(this);
    this.changeText = this.changeText.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(setTabSelected('user'));
    this.props.dispatch(updateSnackBar(false));

    if (!this.props.currentUser || this.props.currentUser === null) {
      const session = getTokenFromSession();
      this.props.dispatch(updateCurrentUser(decode(session)));
    }
  }

  showPopup() {
    this.setState({ showCodePopup: true });
  }

  errorPopup(errorMsg) {
    this.setState({ errors: { phone_number: errorMsg } });
  }

  /* eslint-disable camelcase*/
  handleUpdateUser() {
    const prof = { ...this.state.profile };
    const { phone_number } = this.state.profile;

    prof.sites = (this.state.profile.sites === '*' ? ['*'] : this.state.profile.sites);

    delete (prof.email_verified);
    delete (prof.phone_number_verified);

    const valid = validateProfile(prof);
    this.setState({ errors: valid });

    if (Object.entries(valid).length === 0) {
      if (phone_number !== undefined) {
        const awsPhone = prof.phone_number.includes('+') ? prof.phone_number : `+${prof.phone_number}`;

        if (awsPhone !== this.props.currentUser.phone_number) {
          updatePhone(awsPhone, this.showPopup.bind(this), this.errorPopup.bind(this));
        } else if (!this.props.currentUser.phone_number_verified) {
          resendPhone(this.showPopup.bind(this));
        }
      }

      this.props.dispatch(
        addUser(prof, this.state.profile.group, false, true, !this.state.profile.email_verified, true)
      ).then(() => {
        this.getCurrentAuthUser();
      }).catch((error) => {
        console.log('Error happened with mobile number', error);
      });
    }
  }

  async getCurrentAuthUser() {
    try {
      const currSession = await Auth.currentSession();
      setToken(currSession.idToken.jwtToken);
      this.props.dispatch(updateCurrentUser(decode(currSession.idToken.jwtToken)));
      setItemInSession('mobilePhoneUpdated', true);
    } catch(err) {
      console.log('Something went wrong with your cell number', err);
    }
  }

  updateProfile(profileState) {
    this.setState({ profile: profileState });
  }

  closeSnackBar = () => {
    this.props.dispatch(updateSnackBar(false));
  };

  success() {
    this.setState({ errors: null, showCodePopup: false, loading: false });
    phoneVerified(this.successContinue.bind(this));
  }

  successContinue() {
    setTimeout(() => {
      try {
        const token = decode(getTokenFromSession());
        this.props.dispatch(updateCurrentUser(token));
        setTimeout(() => {
          this.props.dispatch(updateSnackBar(true, SUCCESS, 'Phone number verified'));
        }, 200);
      } catch(error) {
        console.log(error);
      }
    }, 200);
  }

  failure(error) {
    this.setState({ errors: { mobileCode: error.message }, loading: false });
  }

  confirmFunc = () => {
    const codeError = validCode(this.state.mobileCode);
    if (codeError === null) {
      this.setState({ errors: null, loading: true });
      confirmCode(this.state.mobileCode, this.success.bind(this), this.failure.bind(this));
    } else {
      this.setState({ errors: { mobileCode: codeError }, loading: false });
    }
  };

  cancelFunc = () => {
    this.setState({ showCodePopup: false });
  };

  changeText({ target: { value } }) {
    this.setState({ mobileCode: value });
  }

  getTextOption() {
    return <SonifiText
      defaultValue={this.state.mobileCode}
      change={this.changeText}
      label={this.props.translations.mobileCode}
      error={isError('mobileCode', this.state.errors)}
      errorText={getErrorText('mobileCode', this.state.errors)}
      size="md" />;
  }

  getPageDetails() {
    const { userPermissions } = this.props;
    const sonifiUser = checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions);
    return (
      <Grid container>
        {!sonifiUser
          ? <ProfileInfo
            showCodePopup={this.state.showCodePopup}
            updateProfile={this.updateProfile}
            errors={this.state.errors} />
          : <Admin />
        }
      </Grid>
    );
  }

  render() {
    const {
      classes, addingUser, globalTranslations, snackBar, snackBarMessage, snackbarOpen, translations,
      closeHandler, paddingBottom, multiple, display, userPermissions
    } = this.props;
    const { showCodePopup, loading } = this.state;
    const sonifiUser = checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions);

    return (
      <SonifiModal
        closeHandler={closeHandler}
        display={display}
        header={translations.header}
        size={sonifiUser ? 'sm' : 'md'}
        submitHandler={this.handleUpdateUser}
        withHeader >
        <SonifiLockoutModalSpinner show={addingUser} />
        <SonifiConfirm
          dialogOpen={showCodePopup}
          onConfirm={this.confirmFunc}
          onCancel={this.cancelFunc}
          confirmTitle={translations.mobileEnter}
          confirmText={translations.mobileConfirm}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.continue}
          extraInfo={(loading ? <SonifiSpinner /> : this.getTextOption())}
        />
        <Grid container className={`${classes.editContent} ${!!paddingBottom && classes.paddingBottom}`}>
          <Grid item xs={12} className={(multiple ? classes.siteSelect : classes.siteSelectIndividual)}>
            <div className={classes.paperView}>
              <Paper className={classes.contents}>
                <Paper className={classes.innerContents}>
                  {this.getPageDetails()}
                </Paper>
              </Paper>
            </div>
          </Grid>
        </Grid>

        <SonifiSnackBar message={snackBarMessage} variant={snackBar}
          open={snackbarOpen} onClose={this.closeSnackBar} />
      </SonifiModal >
    );
  }
}

const mapStateToProps = (state) => ({
  addingUser: state.users.addingUser,
  currentUser: state.global.user,
  globalTranslations: state.global.translations.defaults,
  snackBar: state.users.snackBar,
  snackBarMessage: state.users.snackBarMessage,
  snackbarOpen: state.users.snackbarOpen,
  translations: state.profile.translations.main,
  userPermissions: state.global.permissions
});

ProfileSettings.propTypes = {
  addingUser: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  closeHandler: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  dispatch: PropTypes.func,
  display: PropTypes.bool.isRequired,
  globalTranslations: PropTypes.object,
  multiple: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  snackBar: PropTypes.string,
  snackBarMessage: PropTypes.string,
  snackbarOpen: PropTypes.bool,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(
  withStyles(ProfileSettings, styles, { withTheme: true })
);
