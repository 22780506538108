import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { TV_TERMINAL_FILE_ROLES } from '../../constants/roleGroups';
import { SonifiConfirm, SonifiLockoutModalSpinner } from '../../containers';
import SonifiSnackBar from '../../containers/SonifiSnackBar';
import { checkForAtLeastOneUserPermission } from '../../utils/rolesUtil';
import { resetTermGrid } from '../Terminals/actions/terminalActions';
import { fetchTerminalOptions } from './actions/LgFirmwareActions';
import { resetTermFiles } from './actions/SonifiFirmwareActions';
import { deleteTlxFile, resetSnackBar, updateDeleteTlxRow } from './actions/tlxFileActions';
import TermTabs from './components/TermTabs';
import MaintenanceWindow from './containers/LG/MaintenanceWindow';

class TermFiles extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(resetTermFiles());
    dispatch(resetTermGrid());
    dispatch(fetchTerminalOptions());

    setTimeout(() => {
      this.setState({ loading: false });
    }, 200);
  }

  closeSnackBar = () => {
    this.props.dispatch(resetSnackBar(null, ''));
  };

  confirmDialogConfirmFunc() {
    this.props.dispatch(deleteTlxFile(this.props.deleteTlx));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteTlxRow(null));
  }

  render() {
    const {
      deleteTlx, globalTranslations, saving, savingFirmware, snackBarType, snackBarMessage, translations,
      userPermissions
    } = this.props;

    if (!checkForAtLeastOneUserPermission(TV_TERMINAL_FILE_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    if (this.state.loading) {
      return <Fragment />;
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={saving || savingFirmware} />
        <SonifiConfirm
          dialogOpen={!saving && deleteTlx !== null && deleteTlx !== undefined}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          confirmTitle={translations.deleteTitleTlx}
          confirmText={`${translations.deleteTextTlx} ${deleteTlx}?`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
        <Grid container>
          <Grid item xs={12}>
            <MaintenanceWindow />
          </Grid>
          <Grid item xs={12} style={{ height: '75%' }}>
            <TermTabs />
          </Grid>
        </Grid>
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteTlx: state.termFile.deleteTlx,
  globalTranslations: state.global.translations.defaults,
  saving: state.termFile.saving,
  savingFirmware: state.termGrid.savingFirmware,
  snackBarMessage: state.termFile.snackBarMessage,
  snackBarType: state.termFile.snackBarType,
  translations: state.termFile.translations.dialog.deleteBox,
  userPermissions: state.global.permissions
});

TermFiles.propTypes = {
  deleteTlx: PropTypes.string,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  saving: PropTypes.bool,
  savingFirmware: PropTypes.bool,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(TermFiles);
