import { DialogActions } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiButton from './SonifiButton';

export class CancelSubmit extends Component {
  render() {
    const {
      disabled, globalTranslations, label, onCancel, onSubmit, submitToolTip
    } = this.props;
    const onlyClose = (typeof this.props.onlyClose !== 'undefined') ? this.props.onlyClose : false;

    const displayButtons = (onlyClose)
      ? <SonifiButton
        caption={globalTranslations.close}
        onClick={onCancel} />
      : <Fragment>
        <SonifiButton
          caption={globalTranslations.cancel}
          onClick={onCancel}
          secondary />
        <SonifiButton
          caption={label}
          disabled={disabled}
          onClick={onSubmit}
          toolTip={submitToolTip}
        />
      </Fragment>;

    return (
      <DialogActions>
        {displayButtons}
      </DialogActions>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults
});

CancelSubmit.propTypes = {
  disabled: PropTypes.bool,
  globalTranslations: PropTypes.object,
  label: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onlyClose: PropTypes.bool,
  onSubmit: PropTypes.func,
  submitToolTip: PropTypes.string
};

export default connect(mapStateToProps)(CancelSubmit);
