import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { SONIFI_ROLES } from '../../../constants/roleGroups';
import { SonifiLabel, SonifiSwitch } from '../../../containers';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import {
  closeSnackBar, fetchSiteConfigs, getMarinaMobileInfo, resetGlobalSnackBar, updateMarinaMobileInfo,
  updateSiteClientConfig
} from '../actions/siteManagementActions';

class MarinaConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enableSonifiClients: false,
      enableThirdPartyClients: false,
      marinaIpg: false
    };

    this.updateSonifiClientConfig = this.updateSonifiClientConfig.bind(this);
    this.updateThirdPartyClientConfig = this.updateThirdPartyClientConfig.bind(this);
    this.updateMobile = this.updateMobile.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchSiteConfigs())
      .then((data) => {
        this.setState({
          enableThirdPartyClients: data.enable_third_party_clients ?? false,
          enableSonifiClients: data.enable_sonifi_clients ?? false
        });
      });

    dispatch(getMarinaMobileInfo())
      .then((data) => {
        this.setState({
          marinaIpg: data.mobile ?? false
        });
      });
  }

  debouncedMarinaIpg = debounce(this.marinaIpgUpdate, KEYBOARD_DELAY);

  debounceSonifiClient = debounce(this.siteCFG, KEYBOARD_DELAY);

  debounceThirdParty = debounce(this.siteCFG, KEYBOARD_DELAY);

  updateSonifiClientConfig({ target: { checked: enabled } }) {
    this.setState({ enableSonifiClients: enabled }, () => {
      this.debounceSonifiClient('enable_sonifi_clients', enabled);
    });
  }

  updateThirdPartyClientConfig({ target: { checked: enabled } }) {
    this.setState({ enableThirdPartyClients: enabled }, () => {
      this.debounceThirdParty('enable_third_party_clients', enabled);
    });
  }

  updateMobile({ target: { checked: enabled } }) {
    this.setState({ marinaIpg: enabled }, () => {
      this.debouncedMarinaIpg();
    });
  }

  marinaIpgUpdate() {
    this.closeSnackBar();
    this.props.dispatch(updateMarinaMobileInfo({ mobile: this.state.marinaIpg }));
  }

  siteCFG(name, value) {
    this.closeSnackBar();
    this.props.dispatch(updateSiteClientConfig(name, value));
  }

  closeSnackBar() {
    this.props.dispatch(resetGlobalSnackBar());
    this.props.dispatch(closeSnackBar());
  }

  render() {
    const { loadingSite, siteConfigLoading, translations, userPermissions } = this.props;
    const { enableSonifiClients, enableThirdPartyClients, marinaIpg } = this.state;

    if (loadingSite || siteConfigLoading) {
      return <SonifiSpinner />;
    }
    return (
      <Grid container>
        <Grid item xs={12} >
          <SonifiLabel boldLabel={translations.ircClientConfig} />
        </Grid>
        <Grid item xs={12}>
          <SonifiSwitch
            disabled={!checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)}
            label={translations.enableSonifiClients}
            onChange={this.updateSonifiClientConfig}
            checked={enableSonifiClients}
          />
        </Grid>
        <Grid item xs={12}>
          <SonifiSwitch
            disabled={!checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)}
            label={translations.enableThirdPartyClients}
            onChange={this.updateThirdPartyClientConfig}
            checked={enableThirdPartyClients}
          />
        </Grid>
        <Grid item xs={6}>
          <SonifiLabel boldLabel={translations.title} />
          <SonifiSwitch
            checked={marinaIpg}
            disabled={!checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)}
            label={translations.marinaIpg}
            onChange={this.updateMobile}
          />
        </Grid>
      </Grid>
    );
  }
}


const mapStateToProps = (state) => ({
  loadingSite: state.global.loadingSite,
  siteConfigLoading: state.siteManagement.siteConfigLoading,
  translations: state.siteManagement.translations.marina,
  userPermissions: state.global.permissions
});

MarinaConfig.propTypes = {
  loadingSite: PropTypes.bool,
  dispatch: PropTypes.func,
  siteConfigLoading: PropTypes.bool,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(MarinaConfig);
