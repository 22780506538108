import { CheckCircleOutline, ErrorOutline, Warning } from '@mui/icons-material';
import {
  Table, TableBody, TableCell, TableRow, Tooltip
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HIGH_HTTP, LOW_HTTP } from '../../../constants/apiEndpoints';
import { SonifiSpinner } from '../../../containers';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import { getTableHeaders } from '../utils/termFileImport';

class GlobalTermFileTable extends Component {
  getStatus(rowInfo) {
    return (
      rowInfo.response < LOW_HTTP || rowInfo.response >= HIGH_HTTP
        ? <Tooltip title={rowInfo.detail}><ErrorOutline style={{ color: 'red' }} /></Tooltip>
        : <CheckCircleOutline style={{ color: 'green' }} />
    );
  }

  getRow(columnNames, rowInfo, index) {
    return (
      <TableRow key={index}>
        <TableCell>
          {rowInfo.toolTip
            ? <Tooltip title={rowInfo.toolTip}><Warning style={{ color: '#1592d9' }} /></Tooltip>
            : rowInfo.response
              ? this.getStatus(rowInfo)
              : <SonifiSpinner />}
        </TableCell>
        {columnNames.map((name, indexOne) => (
          <TableCell key={indexOne}>
            {rowInfo[name.id] &&
              <SonifiLabel label={`${rowInfo[name.id]}`} />
            }
          </TableCell>
        ))}
      </TableRow>
    );
  }

  render() {
    const { termInfo, translations } = this.props;

    const columnNames = getTableHeaders(translations, termInfo);
    columnNames.shift();

    return (
      <Table stickyHeader={true}>
        <SonifiTableHead headColumns={getTableHeaders(translations, termInfo)} />
        <TableBody>
          {termInfo && termInfo.length === 0
            ? <TableRow>
              <TableCell colSpan={11}><SonifiLabel label={translations.noInfo} /></TableCell>
            </TableRow>
            : termInfo.map((channel, index) => (
              this.getRow(columnNames, channel, index)
            ))
          }
        </TableBody>
      </Table>
    );
  }
}

const mapStateToProps = (state) => ({
  termInfo: state.globalTermFiles.termInfo,
  translations: state.globalTermFiles.translations.csv
});

GlobalTermFileTable.propTypes = {
  termInfo: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(GlobalTermFileTable);
