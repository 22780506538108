import {
  buildUrl, deleteData, fetchData, fetchGzipData, getCurrentToken, putData
} from './index';

export function getIpgPromise(resource, info, obj) {
  return new Promise((resolve, reject) => {
    let url = buildUrl(`${process.env.REACT_APP_IPG_URL}/${resource}`, obj);
    if (info) {
      url = buildUrl(`${process.env.REACT_APP_IPG_URL}/${resource}/${info}`, obj);
    }

    fetchData(url)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function putIpgPromise(resource, info, submitObj) {
  return new Promise((resolve, reject) => {
    let url = buildUrl(`${process.env.REACT_APP_IPG_URL}/${resource}`);
    if (info) {
      url = buildUrl(`${process.env.REACT_APP_IPG_URL}/${resource}/${info}`);
    }

    putData(url, submitObj)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function putIpgImagePromise(resource, info, submitObj) {
  return new Promise((resolve, reject) => {
    let url = buildUrl(`${process.env.REACT_APP_IPG_URL}/${resource}`);
    if (info) {
      url = buildUrl(`${process.env.REACT_APP_IPG_URL}/${resource}/${info}`);
    }

    putImageData(url, submitObj.type, submitObj)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function putImageData(url, type, inputObject) {
  return new Promise((resolve, reject) => {
    getCurrentToken()
      .then((token) => {
        const bearer = `Bearer ${token}`;
        const object = {
          method: 'PUT',
          body: inputObject,
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*',
            'Accept': type
          },
        };
        fetch(`${url}`, object)
          .then((data) => resolve(data))
          .catch((reason) => reject(reason));
      }).catch((reason) => console.log(reason));
  });
}

export function deleteIpgObjectPromise(resource, info) {
  return new Promise((resolve, reject) => {
    const url = buildUrl(`${process.env.REACT_APP_IPG_URL}/${resource}/${info}`);

    deleteData(url)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function getIpgGzipObjectPromise(resource, info, obj) {
  return new Promise((resolve, reject) => {
    const url = buildUrl(`${process.env.REACT_APP_IPG_URL}/${resource}/${info}`, obj);

    fetchGzipData(url)
      .then((data) => {
        resolve(data);
      })
      .catch((reason) => reject(reason));
  });
}
