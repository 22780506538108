export const ADD_ROLE = 'ADD_ROLE';
export const DELETE_ROLE_BEGIN = 'DELETE_ROLE_BEGIN';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const FETCH_ROLE_BEGIN = 'FETCH_ROLE_BEGIN';
export const FETCH_ROLE_FAILURE = 'FETCH_ROLE_FAILURE';
export const FETCH_ROLE_SUCCESS = 'FETCH_ROLE_SUCCESS';
export const FETCH_ROLES_BEGIN = 'FETCH_ROLES_BEGIN';
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const REMOVE_ROLE = 'REMOVE_ROLE';
export const SORT_ROLES = 'SORT_ROLES';
export const UPDATE_ROLE_LOADING = 'UPDATE_ROLE_LOADING';
export const UPDATE_SELECTED_ROLE = 'UPDATE_SELECTED_ROLE';
