// GLOBAL ACTIONS
export const SHOW_MESSAGE_DIALOG = 'SHOW_MESSAGE_DIALOG';
export const CLOSE_MESSAGE_DIALOG = 'CLOSE_MESSAGE_DIALOG';
export const CLEAR_DIALOG_VARIABLES = 'CLEAR_DIALOG_VARIABLES';
export const CANCEL_DIALOG = 'CANCEL_DIALOG';
export const ADD_DIALOG_ERROR = 'ADD_DIALOG_ERROR';
export const UPDATE_SAVE_ATTEMPT = 'UPDATE_SAVE_ATTEMPT';
export const UPDATE_DELETE_ATTEMPT = 'UPDATE_DELETE_ATTEMPT';

// LIVE ACTIONS
export const CLEAR_LIVE = 'CLEAR_LIVE';
export const FETCH_LIVE_BEGIN = 'FETCH_LIVE_BEGIN';
export const FETCH_LIVE_SUCCESS = 'FETCH_LIVE_SUCCESS';
export const FETCH_LIVE_FAILURE = 'FETCH_LIVE_FAILURE';

// PUBLISH ACTIONS
export const CLEAR_PUBLISH = 'CLEAR_PUBLISH';
export const FETCH_PUBLISH_BEGIN = 'FETCH_PUBLISH_BEGIN';
export const FETCH_PUBLISH_SUCCESS = 'FETCH_PUBLISH_SUCCESS';
export const FETCH_PUBLISH_FAILURE = 'FETCH_PUBLISH_FAILURE';
export const SET_SELECTED_PUBLISH_ITEM = 'SET_SELECTED_PUBLISH_ITEM';
export const CLEAR_SELECTED_PUBLISH_ITEM = 'CLEAR_SELECTED_PUBLISH_ITEM';
export const GET_PUBLISH_ITEM_BEGIN = 'GET_PUBLISH_ITEM_BEGIN';
export const GET_PUBLISH_ITEM_SUCCESS = 'GET_PUBLISH_ITEM_SUCCESS';
export const GET_PUBLISH_ITEM_FAILURE = 'GET_PUBLISH_ITEM_FAILURE';

export const ADD_PUBLISH_ITEM_BEGIN = 'ADD_PUBLISH_ITEM_BEGIN';
export const ADD_PUBLISH_ITEM_ERROR = 'ADD_PUBLISH_ITEM_ERROR';
export const ADD_PUBLISH_ITEM_SUCCESS = 'ADD_PUBLISH_ITEM_SUCCESS';

// export const ADD_PUBLISH_ITEM_FAILURE = 'ADD_PUBLISH_ITEM_FAILURE';
export const EDIT_PUBLISH_ITEM_SUCCESS = 'EDIT_PUBLISH_ITEM_SUCCESS';

// export const EDIT_PUBLISH_ITEM_FAILURE = 'EDIT_PUBLISH_ITEM_FAILURE';


export const DELETE_PUBLISH_ITEM_BEGIN = 'DELETE_PUBLISH_ITEM_BEGIN';
export const DELETE_PUBLISH_ITEM_SUCCESS = 'DELETE_PUBLISH_ITEM_SUCCESS';
export const DELETE_PUBLISH_ITEM_FAILURE = 'DELETE_PUBLISH_ITEM_FAILURE';

export const STOP_PUBLISH_ITEM_BEGIN = 'STOP_PUBLISH_ITEM_BEGIN';
export const STOP_PUBLISH_ITEM_SUCCESS = 'STOP_PUBLISH_ITEM_SUCCESS';

// export const STOP_PUBLISH_ITEM_FAILURE = 'STOP_PUBLISH_ITEM_FAILURE';


// LIBRARY ACTIONS
export const CLEAR_LIBRARY = 'CLEAR_LIBRARY';

// export const SAVE_TRANSLATION_FAILURE = 'SAVE_TRANSLATION_FAILURE';

// export const DELETE_TRANSLATION_FAILURE = 'DELETE_TRANSLATION_FAILURE';

export const FETCH_LIBRARY_SUCCESS = 'FETCH_LIBRARY_SUCCESS';
export const FETCH_LIBRARY_ALL_SUCCESS = 'FETCH_LIBRARY_ALL_SUCCESS';
export const FETCH_LIBRARY_FAILURE = 'FETCH_LIBRARY_FAILURE';
export const FETCH_LIBRARY_BEGIN = 'FETCH_LIBRARY_BEGIN';

export const GET_LIBRARY_ITEM_BEGIN = 'GET_LIBRARY_ITEM_BEGIN';
export const GET_LIBRARY_ITEM_SUCCESS = 'GET_LIBRARY_ITEM_SUCCESS';
export const GET_LIBRARY_ITEM_FAILURE = 'GET_LIBRARY_ITEM_FAILURE';

export const ADD_LIBRARY_ITEM_BEGIN = 'ADD_LIBRARY_ITEM_BEGIN';
export const ADD_LIBRARY_ITEM_SUCCESS = 'ADD_LIBRARY_ITEM_SUCCESS';

// export const ADD_LIBRARY_ITEM_FAILURE = 'ADD_LIBRARY_ITEM_FAILURE';

export const EDIT_LIBRARY_ITEM_COMPLETE = 'EDIT_LIBRARY_ITEM_COMPLETE';

// export const EDIT_LIBRARY_ITEM_FAILURE = 'EDIT_LIBRARY_ITEM_FAILURE';

export const DELETE_LIBRARY_ITEM_BEGIN = 'DELETE_LIBRARY_ITEM_BEGIN';
export const DELETE_LIBRARY_ITEM_SUCCESS = 'DELETE_LIBRARY_ITEM_SUCCESS';
export const DELETE_LIBRARY_ITEM_FAILURE = 'DELETE_LIBRARY_ITEM_FAILURE';

export const SET_SELECTED_LIBRARY_ITEM = 'SET_SELECTED_LIBRARY_ITEM';
export const CLEAR_SELECTED_LIBRARY_ITEM = 'CLEAR_SELECTED_LIBRARY_ITEM';

