import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { TERMINAL_DETAIL_EDIT } from '../../../constants/roles';
import SonifiSnackBar from '../../../containers/SonifiSnackBar';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { checkSingleUserPermission } from '../../../utils/rolesUtil';
import { resetProvisionLink } from '../actions/provisionTermsActions';
import MobileProvision from '../containers/MobileProvision';
import QrCode from '../containers/QrCode';

class ProvisionTerms extends Component {
  state = {
    step: 0,
    terminalData: null
  };

  // state = {
  //   step: 1,
  //   terminalData: {
  //     id: '38:8c:50:1e:50:da',
  //     room_id: null,
  //     site_id: '0000056'
  //   }
  // }

  componentDidMount() {
    this.props.dispatch(resetProvisionLink());
  }

  continueToMobile(terminalData) {
    console.log('Go to the mobile website now to do what I need?', terminalData);
    this.setState({ step: this.state.step + 1, terminalData });
  }

  backFromMobile() {
    this.setState({ step: 0, terminalData: null });
  }

  closeSnackBar() {
    this.props.dispatch(resetProvisionLink());
  }

  getPageDetails() {
    const { step, terminalData } = this.state;
    if (step === 0) {
      return (
        <QrCode continue={this.continueToMobile.bind(this)} />
      );
    }
    return <MobileProvision terminalData={terminalData} onComplete={this.backFromMobile.bind(this)} />;
  }

  render() {
    const {
      globalTranslations, snackBarType, snackBarMessage, translations, userPermissions
    } = this.props;

    if (!checkSingleUserPermission(TERMINAL_DETAIL_EDIT, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    return (
      <Fragment>
        <SonifiTemplate
          header={translations.header}
          label={globalTranslations.save}
          pageDetails={this.getPageDetails()}
          showButton={false}
        />
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  snackBarMessage: state.provisionTerms.snackBarMessage,
  snackBarType: state.provisionTerms.snackBarType,
  translations: state.provisionTerms.translations.main,
  userPermissions: state.global.permissions
});

ProvisionTerms.propTypes = {
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(ProvisionTerms);
