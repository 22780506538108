import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { ModalSectionHeader, SonifiCheckbox, SonifiLabel } from '../../../../containers';
import SonifiTabs from '../../../../containers/SonifiTabs';
import {
  ANALOG, ANALOG_RF, AUX, DIGITAL, DIGITAL_RF, IP, IPTV
} from '../../constants/constants';
import {
  emptyAnalog, emptyDigital, emptyDvbC, emptyDvbS, emptyDvbT, emptyIntlAnalog
} from '../../dataObjects/channelObjects';
import AuxiliaryDigital from './channelFormComponents/AuxiliaryDigital';
import ChannelTableAnalogColumn from './channelFormComponents/ChannelTableAnalogColumn';
import ChannelTableDigitalColumn from './channelFormComponents/ChannelTableDigitalColumn';
import ChannelTableIpColumn from './channelFormComponents/ChannelTableIpColumn';

const styles = (theme) => ({
  // tabsBkg: {
  //   backgroundColor: theme.palette.primary.contrastText,
  //   color: theme.palette.primary.main
  // },
  // selectedTab: {
  //   backgroundColor: `${theme.palette.secondary.main} !important`
  // },
  // border: {
  //   border: `1px solid ${theme.palette.primary.light}`,
  //   minHeight: '262px',
  //   padding: '10px'
  // },
  channelTypes: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px'
  },
  channelTypeCheckboxes: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0px 10px',
    backgroundColor: theme.palette.defaults.defaultBackground,
    marginBottom: '12px'
  }
});

class ChannelForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    const allowedChannelTypes = this.getListOfChannelTypes(this.props.channel, this.props.channelTypes);
    const checkedChannelTypes = allowedChannelTypes.filter((type) => type.checked);

    this.state = {
      selectedTab: checkedChannelTypes.length > 0 ? checkedChannelTypes[0].dataName : '',
    };
  }

  // Checkbox edit function
  editToggleValue = (name) => ({ target: { checked } }) => {
    // if(name === this.state.selectedTab) {
    //   this.setState({ selectedTab: '' });
    // }
    this.props.changeDetailObjFunc(name, checked);
  };

  // Changing Tabs function
  // changeTabs = (event, newValue) => {
  //   this.setState({ selectedTab: newValue });
  // };

  // Boolean function for checkboxes
  hasDetailObject(channel, objName) {
    return channel[`${objName}Enabled`];
  }

  // Look at site management data to create a list of available channel types
  getListOfChannelTypes(channel, channelTypes) {
    const availableChannelTypes = [];

    if (this.props.channel.type === AUX) {
      availableChannelTypes.push({
        display: this.props.translations.digitalRF, dataName: DIGITAL, checked: !!channel.digitalEnabled
      });
    } else {
      for (let i = 0; i < channelTypes.length; i++) {
        if (channelTypes[i].checked) {
          if (channelTypes[i].name === ANALOG_RF) {
            availableChannelTypes.push({
              display: this.props.translations.analogRF, dataName: ANALOG, checked: !!channel.analogEnabled
            });
          } else if (channelTypes[i].name === DIGITAL_RF) {
            availableChannelTypes.push({
              display: this.props.translations.digitalRF, dataName: DIGITAL, checked: !!channel.digitalEnabled
            });
          } else if (channelTypes[i].name === IPTV) {
            availableChannelTypes.push({
              display: this.props.translations.iPTV, dataName: IP, checked: !!channel.ipEnabled
            });
          }
        }
      }
    }

    return availableChannelTypes;
  }

  componentDidUpdate(nextProps) {
    if (this.props.channel.type === AUX) {
      if (this.state.selectedTab !== DIGITAL) {
        this.setState({ selectedTab: DIGITAL });
      }
    } else {
      const allowedChannelTypes = this.getListOfChannelTypes(this.props.channel, nextProps.channelTypes);
      const checkedChannelTypes = allowedChannelTypes.filter((type) => type.checked);
      const selectedTabIndex = checkedChannelTypes.findIndex((type) => type.dataName === this.state.selectedTab);

      if (selectedTabIndex === -1) {
        if (checkedChannelTypes.length > 0) {
          this.setState({ selectedTab: checkedChannelTypes[0].dataName });
        } else if (this.state.selectedTab !== '') {
          this.setState({ selectedTab: '' });
        }
      }
    }
  }

  getTabsArray() {
    const allowedChannelTypes = this.getListOfChannelTypes(this.props.channel, this.props.channelTypes);
    const checkedChannelTypes = allowedChannelTypes.filter((typeOne) => typeOne.checked);

    const tabsArray = checkedChannelTypes.map((object) => (
      { id: object.dataName, title: object.display }
    ));
    return tabsArray;
  }

  getTabContainer() {
    const {
      channel, errors, readOnly, translations, type
    } = this.props;

    switch (this.state.selectedTab) {
      case ANALOG:
        if (channel.analogEnabled && type !== AUX) {
          return (<ChannelTableAnalogColumn
            readOnly={readOnly}
            editFunction={this.props.editFunction}
            editNonEventFunction={this.props.editNonEventFunction}
            analogObj={channel.analog ? channel.analog : emptyAnalog}
            intlAnalogObj={channel.intl_analog ? channel.intl_analog : emptyIntlAnalog}
            errors={errors}
            translations={translations} />);
        }
        break;
      case DIGITAL:
        if (channel.digitalEnabled && type !== AUX) {
          return (<ChannelTableDigitalColumn
            readOnly={readOnly}
            editFunction={this.props.editFunction}
            editNonEventFunction={this.props.editNonEventFunction}
            digitalObj={channel.digital ? channel.digital : emptyDigital}
            dvbcObj={channel.intl_dvb_c ? channel.intl_dvb_c : emptyDvbC}
            dvbsObj={channel.intl_dvb_s ? channel.intl_dvb_s : emptyDvbS}
            dvbtObj={channel.intl_dvb_t ? channel.intl_dvb_t : emptyDvbT}
            errors={errors}
            translations={translations} />);
        } else if (channel.digitalEnabled && type === AUX) {
          return (
            <AuxiliaryDigital
              readOnly={readOnly}
              editFunction={this.props.editFunction}
              editNonEventFunction={this.props.editNonEventFunction}
              digitalObj={channel.digital}
              errors={errors}
              translations={translations} />
          );
        }
        break;
      case IP:
        if (channel.ipEnabled && type !== AUX) {
          return (
            <ChannelTableIpColumn
              readOnly={readOnly}
              editFunction={this.props.editFunction}
              ipObj={channel.ip}
              errors={errors}
              translations={translations} />
          );
        }
        break;
    }
  }

  handleChange(event, newValue) {
    this.setState({ selectedTab: newValue });
  }

  render() {
    const {
      classes, channel, readOnly, translations
    } = this.props;
    const { selectedTab } = this.state;
    const allowedChannelTypes = this.getListOfChannelTypes(this.props.channel, this.props.channelTypes);

    if (allowedChannelTypes.length < 1) {
      return <SonifiLabel label={translations.noChannelsSelected} error />;
    }

    const tempTabs = this.getTabsArray();

    return (
      <div className={classes.channelTypes}>
        <Grid container >
          <Grid item xs={12}>
            <ModalSectionHeader label={translations.channelTypes} />
          </Grid>
          <Grid item xs={12}>
            <div className={classes.channelTypeCheckboxes}>
              {allowedChannelTypes.map((object, index) => (
                <div key={index}>
                  <SonifiCheckbox
                    value={this.hasDetailObject(channel, object.dataName)}
                    onChange={readOnly ? () => { } : this.editToggleValue(object.dataName)}
                    label={object.display}
                    removeTopPadding={true}
                    disabled={readOnly}
                    disableRipple={readOnly} />
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
        {selectedTab && selectedTab !== '' && tempTabs && tempTabs.length > 0 &&
          <SonifiTabs
            tabs={this.getTabsArray()}
            handleChange={this.handleChange.bind(this)}
            tabValue={selectedTab}
            showingTab={this.getTabContainer()} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  channelTypes: state.siteManagement.channelDelivery,
  translations: state.channels.translations.editDialog
});

ChannelForm.propTypes = {
  changeDetailObjFunc: PropTypes.func.isRequired,
  channelTypes: PropTypes.array.isRequired,
  channel: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  editFunction: PropTypes.func.isRequired,
  editNonEventFunction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
  translations: PropTypes.object,
  type: PropTypes.string
};

export default connect(mapStateToProps)(withStyles(ChannelForm, styles, { withTheme: true }));
