import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SonifiLabel } from '../../../containers';

export default class TableHeader extends Component {
  render() {
    const { translations } = this.props;
    return (<Grid container>
      <Grid item xs={1} />
      <Grid item xs={11}>
        <Grid container>
          <Grid item xs={3}>
            <SonifiLabel boldLabel={translations.time} />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <SonifiLabel boldLabel={translations.quantity} />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <SonifiLabel boldLabel={translations.today} />
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            <SonifiLabel boldLabel={translations.purchased} />
          </Grid>
          <Grid item xs={3}>
            <SonifiLabel boldLabel={translations.cost} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>);
  }
}

TableHeader.propTypes = {
  translations: PropTypes.object
};
