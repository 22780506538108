// NOTE:
// Use of the 'key' item in state is a bit of a hack.  As of implementation of this component, there
// was not a good programmatic way to clear the value in autocomplete automatically.
// (there was a manual x that would clear the item)
// So, in order to force an empty component, on select we set a new key,
// which essentially rerenders a new component.
// This was needed so that we could populate the component with a friendly placeholder
// after the selection was made.

// *https://css-tricks.com/debouncing-throttling-explained-examples/*
// *https://www.registers.service.gov.uk/registers/country/use-the-api*
// *https://material-ui.com/components/about-the-lab/*
import SearchIcon from '@mui/icons-material/Search';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../constants/keyCodes';
import { getObjectPromise, getSiteObjectPromise } from '../utils/api';
import { getIpgPromise } from '../utils/ipg';

const styles = (theme) => ({
  root: {
    // display: 'flex',
    width: '100%',
    marginBottom: theme.spacing(1),

    // backgroundColor: '#f6f6f6'
  },
});

export class SonifiTextAsync extends Component {
  state = {
    filter: '',
    key: new Date().toString(),
    loading: false,
    open: false,
    options: [{ value: null, label: 'Please start typing to begin filter.' }]
  };

  // There are other parameters/options that can be passed to debounce.
  // I believe this is the perfect situation to use this function, but we
  // may find that it needs to be tweaked a little more
  // *https://lodash.com/docs/4.17.15#debounce*
  debouncedLoadMoreData = debounce(this.loadMoreData, KEYBOARD_DELAY);

  loadMoreData() {
    if (this.state.filter && this.state.filter !== '') {
      this.getFilteredData(this.state.filter).then((data) => {
        this.setState({
          loading: false,
          options: ((!data || data.length < 1) ? [{ value: null, label: 'No Options Found.' }] : data)
        });
      });
    } else {
      this.setState({
        loading: false,
        options: [{ value: null, label: 'No Options Found.' }]
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filter !== prevState.filter) {
      if (this.state.filter.length === 0) {
        this.setState({
          loading: false,
          options: [{ value: null, label: 'Please start typing to begin filter.' }]
        });
      } else if (this.state.filter && this.state.filter.length) {
        this.setState({
          loading: true
        });
        this.debouncedLoadMoreData();
      }
    }
  }

  getFilteredData(filter) {
    const {
      dataName, displayFunc, filterFunc, info, isIpg, resource, siteId, siteObj
    } = this.props;

    return new Promise((resolve) => {
      if (isIpg) {
        getIpgPromise(resource, info, filterFunc(filter)).then((data) => {
          const options = data[dataName].map((d) => (displayFunc(d)));
          resolve(options);
        }).catch((error) => {
          console.log('Error-4: ', error);
          resolve([]);
        });
      } else if (siteObj) {
        getSiteObjectPromise(resource, info, siteId,
          filterFunc(filter)).then((data) => {
            const options = data[dataName].map((d) => (displayFunc(d)));
            resolve(options);
          }).catch((error) => {
            console.log('Error-3: ', error);
            resolve([]);
          });
      } else {
        getObjectPromise(resource, info, filterFunc(filter)).then((data) => {
          const options = data[dataName].map((d) => (displayFunc(d)));
          resolve(options);
        }).catch((error) => {
          console.log('Error-4: ', error);
          resolve([]);
        });
      }
    });
  }

  filterOptions = (options) => (options);

  setOpen(open) {
    this.setState({ open });
  }

  handleChange = (event) => {
    this.setState({ filter: event.target.value });
  };

  handleSelect = (e, item) => {
    if (item !== null) {
      this.props.onSelect(item);
    }

    // handle clear of component
    this.setState({
      filter: '',
      key: new Date().toString(),
      options: [{ value: null, label: 'Please start typing to begin filter.' }]
    });
  };

  render() {
    const { key, loading, open, options } = this.state;
    const {
      classes, disabled, error, errorText, helperText, label, dataTest
    } = this.props;

    const showError = ((typeof error === 'undefined') || (error === null)) ? false : error;

    return (
      <Autocomplete
        key={key}
        disabled={disabled}
        className={classes.root}
        open={open}
        onOpen={() => {
          this.setOpen(true);
        }}
        onClose={() => {
          this.setOpen(false);
        }}
        autoHighlight={true}
        autoSelect={true}
        freeSolo={true}
        filterOptions={this.filterOptions}
        getOptionLabel={(option) => (option.label || '')}
        options={options}
        onChange={this.handleSelect}
        loading={loading}
        data-testid={dataTest ?? ''}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            className={classes.root}
            variant="filled"
            onChange={this.handleChange}
            error={showError}
            helperText={(showError && errorText) ? errorText : helperText}
            placeholder={this.props.placeholder}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment: (
                !disabled && <SearchIcon />
              ),
            }}
          />
        )}
      />
    );
  }
}

SonifiTextAsync.propTypes = {
  classes: PropTypes.object.isRequired,
  dataName: PropTypes.string.isRequired,
  dataTest: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  displayFunc: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  filterFunc: PropTypes.func.isRequired,
  getRefFunc: PropTypes.func,
  helperText: PropTypes.string,
  info: PropTypes.string,
  isIpg: PropTypes.bool,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  resource: PropTypes.string.isRequired,
  siteId: PropTypes.string,
  siteObj: PropTypes.bool,
  type: PropTypes.string.isRequired
};

export default withStyles(SonifiTextAsync, styles, { withTheme: true });
