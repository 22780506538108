import { Delete, Edit, Visibility } from '@mui/icons-material';
import { Grid, Popover, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import SonifiTooltip from './SonifiTooltip';

const styles = (theme) => ({
  popOverContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

class SonifiPopover extends Component {
  state = {
    open: false,
    popOverElement: null,
    popOverHeight: null
  };

  rowClicked = () => (event) => {
    const { currentTarget } = event;
    this.setState((state) => ({
      popOverElement: currentTarget,
      popOverHeight: currentTarget.clientHeight,
      open: !state.open
    }));
  };

  handlePopoverClose = (event) => {
    if (event && this.state.popOverElement) {
      if (this.state.popOverElement.contains(event.target)) {
        return;
      }
    }
    this.setState({ open: false });
  };

  handleEditDialog() {
    this.setState({ open: false });
    this.props.editFunc();
  }

  handleDeleteDialog() {
    this.setState({ open: false });
    this.props.deleteFunc();
  }

  handleFuncOneDialog() {
    this.setState({ open: false });
    this.props.functionOneFunc();
  }

  handleFuncTwoDialog() {
    this.setState({ open: false });
    this.props.functionTwoFunc();
  }

  handleFuncThreeDialog() {
    this.setState({ open: false });
    this.props.functionThreeFunc();
  }

  render() {
    const { popOverElement, popOverHeight, open } = this.state;
    const {
      classes, deleteDisabled, deleteIcon, deleteTitle, editDisabled, editTitle,
      editIcon, functionOneIcon, functionOneTitle, functionOneDisabled,
      showFunctionTwo, functionTwoIcon, functionTwoTitle, functionTwoDisabled,
      showFunctionThree, functionThreeIcon, functionThreeTitle, functionThreeDisabled,
      hideDelete, hideEdit, key, showFunctionOne, tableRow
    } = this.props;

    return (
      <Fragment>
        <Popover
          open={open}
          anchorEl={popOverElement}
          PaperProps={{
            style: { borderRadius: 0 }
          }}
          onClose={this.handlePopoverClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.popOverContainer}
            style={{ height: { popOverHeight } }}
          >
            {showFunctionOne && <SonifiTooltip onClick={this.handleFuncOneDialog.bind(this)}
              icon={(functionOneIcon ? functionOneIcon : <Visibility fontSize="small" />)}
              title={functionOneTitle} disabled={!!functionOneDisabled} />}
            {!hideEdit && <SonifiTooltip onClick={this.handleEditDialog.bind(this)}
              icon={(editIcon ? editIcon : <Edit fontSize="small" />)}
              title={editTitle} disabled={editDisabled} />}
            {showFunctionTwo && <SonifiTooltip onClick={this.handleFuncTwoDialog.bind(this)}
              icon={(functionTwoIcon)} title={functionTwoTitle} disabled={!!functionTwoDisabled} />}
            {showFunctionThree && <SonifiTooltip onClick={this.handleFuncThreeDialog.bind(this)}
              icon={(functionThreeIcon)} title={functionThreeTitle} disabled={!!functionThreeDisabled} />}
            {!hideDelete && <SonifiTooltip onClick={this.handleDeleteDialog.bind(this)}
              icon={(deleteIcon ? deleteIcon : <Delete fontSize="small" />)}
              title={deleteTitle} disabled={deleteDisabled} />}
          </Grid>
        </Popover>
        <TableRow onClick={this.rowClicked()} key={key}>
          {tableRow}
        </TableRow >
      </Fragment>
    );
  }
}

SonifiPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteDisabled: PropTypes.bool,
  deleteFunc: PropTypes.func,
  deleteIcon: PropTypes.object,
  deleteTitle: PropTypes.string,
  editDisabled: PropTypes.bool,
  editFunc: PropTypes.func,
  editIcon: PropTypes.object,
  editTitle: PropTypes.string,
  functionOneDisabled: PropTypes.bool,
  functionOneFunc: PropTypes.func,
  functionOneIcon: PropTypes.object,
  functionOneTitle: PropTypes.string,
  functionThreeDisabled: PropTypes.bool,
  functionThreeFunc: PropTypes.func,
  functionThreeIcon: PropTypes.object,
  functionThreeTitle: PropTypes.string,
  functionTwoDisabled: PropTypes.bool,
  functionTwoFunc: PropTypes.func,
  functionTwoIcon: PropTypes.object,
  functionTwoTitle: PropTypes.string,
  hideDelete: PropTypes.bool,
  hideEdit: PropTypes.bool,
  key: PropTypes.string,
  showFunctionOne: PropTypes.bool,
  showFunctionThree: PropTypes.bool,
  showFunctionTwo: PropTypes.bool,
  tableRow: PropTypes.object
};

export default withStyles(SonifiPopover, styles, { withTheme: true });

