import { orderBy } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { EDIT, ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  channels: [],
  channelsLoading: false,
  deleteDeployment: -1,
  deployments: [],
  editType: EDIT,
  gettingNewChannels: false,
  gettingPackages: false,
  gettingVirtosPkgs: false,
  loading: false,
  maxPages: 0,
  packages: [],
  pageSize: 0,
  productParents: [],
  saving: false,
  selected: -1,
  selectedProductParent: '',
  snackBarMessage: '',
  snackBarType: SUCCESS,
  statusIndex: -1,
  subselected: -1,
  totalItems: 0,
  translations: null,
  virtosPkgs: {}
};

/* eslint-disable max-lines-per-function */
export default function deploymentsReducer(state = initialState, action) {
  let groupBy = null;
  let availPkgs = null;

  switch (action.type) {
    case types.DELETE_CHECK:
      return { ...state, deleteDeployment: action.deployment, snackBarMessage: '', statusIndex: action.statusIndex };

    case types.GET_PRODUCT_PARENTS_BEGIN:
      return {
        ...state,
        deployments: [],
        loading: true,
        snackBarMessage: '',
        selectedProductParent: '',
      };

    case types.GET_PRODUCT_PARENTS_SUCCESS:
      return {
        ...state,
        productParents: [...action.deployments['product-parents']],
        loading: false,
        selectedProductParent: `${(action.deployments['product-parents'].length > 0
          ? action.deployments['product-parents'][0].id
          : 0)}`,
      };

    case types.GET_PRODUCT_PARENTS_FAILURE:
      return {
        ...state,
        deployments: [],
        loading: false,
        maxPages: 0,
        pageSize: 0,
        snackBarMessage: state.translations.main.errors.fetchFailure,
        snackBarType: ERROR,
        totalItems: 0
      };

    case types.UPDATE_DEPLOYMENTS_BAR:
      return {
        ...state,
        snackBarType: action.snackBarType === null ? state.snackBarType : action.snackBarType,
        snackBarMessage: action.snackBarMessage,
      };

    case types.UPDATE_SELECTED_DEPLOYMENT:
      return {
        ...state,
        selected: action.index,
        subselected: action.subindex,
        editType: action.editType,
      };

    case types.UPDATE_PRODUCT_PARENTS_BEGIN:
      return { ...state, channelsLoading: true, selectedProductParent: action.value };

    case types.UPDATE_PRODUCT_PARENTS_SUCCESS:
      return {
        ...state,
        channels: action.channels.versions,
        channelsLoading: false,
        maxPages: action.channels.page_count,
        pageSize: action.channels.page_size,
        totalItems: action.channels.total_items,
      };

    case types.UPDATE_PRODUCT_PARENTS_FAILURE:
      return {
        ...state,
        channels: [],
        channelsLoading: false,
        maxPages: 0,
        pageSize: 0,
        totalItems: 0
      };

    case types.EDIT_SELECTED_DEPLOYMENT_BEGIN:
      return {
        ...state,
        deleteDeployment: -1,
        saving: true,
        snackBarMessage: '',
        statusIndex: -1
      };

    case types.EDIT_SELECTED_DEPLOYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        saving: false,
        selected: -1,
        snackBarMessage: state.translations.main.success.editSuccess,
        snackBarType: SUCCESS
      };

    case types.EDIT_SELECTED_DEPLOYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        saving: false,
        snackBarMessage: state.translations.main.errors.editFailure,
        snackBarType: ERROR
      };

    case types.GET_MORE_PP_VERSIONS_BEGIN:
      return {
        ...state,
        channels: action.reset ? [] : [...state.channels],
        gettingNewChannels: action.reset
      };

    case types.GET_MORE_PP_VERSIONS_SUCCESS:
      return {
        ...state,
        channels: [...state.channels, ...action.channels.versions],
        gettingNewChannels: false
      };

    case types.GET_MORE_PP_VERSIONS_FAILURE:
      return {
        ...state,
        snackBarMessage: state.translations.main.errors.moreFailure,
        snackBarType: ERROR,
        gettingNewChannels: false
      };

    case types.GET_RELEASE_VERSIONS_BEGIN:
      return { ...state, packages: [], gettingPackages: true };

    case types.GET_RELEASE_VERSIONS_SUCCESS:
      return { ...state, gettingPackages: false, packages: orderBy(action.packages, action.orderBy, action.order) };

    case types.GET_RELEASE_VERSIONS_FAILURE:
      return {
        ...state,
        gettingPackages: false,
        snackBarMessage: (action.error === 'fail'
          ? state.translations.main.errors.noPackages
          : state.translations.main.errors.versionFailure),
        snackBarType: ERROR
      };

    case types.SORT_RELEASE_VERSIONS:
      return { ...state, packages: orderBy(state.packages, action.orderBy, action.order) };

    case types.GET_VIRTOS_PKGS_BEGIN:
      return { ...state, virtosPkgs: {}, gettingVirtosPkgs: true };

    case types.GET_VIRTOS_PKGS_SUCCESS:
      groupBy = (array, key) => array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue.version
        );
        return result;
      }, {});

      availPkgs = groupBy(action.packages, 'name');
      Object.keys(availPkgs).forEach((key) => {
        availPkgs[key].sort((a, b) => {
          const nums1 = a.split('.');
          const nums2 = b.split('.');

          for (let i = 0; i < nums1.length; i++) {
            if (nums2[i]) {
              if (nums1[i] !== nums2[i]) {
                return nums1[i] - nums2[i];
              }
            } else {
              return 1;
            }
          }
        });
      });
      return { ...state, virtosPkgs: availPkgs, gettingVirtosPkgs: false };

    case types.ADD_MANUAL_VERSION_BEGIN:
      return { ...state, saving: true };

    case types.ADD_MANUAL_VERSION_COMPLETE:
      return { ...state, saving: false, selected: action.closeDialog ? -1 : state.selected };

    case types.GET_VIRTOS_PKGS_FAILURE:
      return { ...state, gettingVirtosPkgs: false };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
