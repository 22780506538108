import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiPopover from '../../../containers/SonifiPopover';
import { updateDeleteRow, updateSelectedIntegration } from '../actions/integrationActions';

class IntegrationRow extends Component {
  handleEditDialog() {
    const { dispatch, integration } = this.props;
    dispatch(updateSelectedIntegration(integration, 'edit'));
  }

  handleDeleteDialog() {
    const { dispatch, integration } = this.props;
    dispatch(updateDeleteRow(integration));
  }

  handleReadDialog() {
    const { dispatch, integration } = this.props;
    dispatch(updateSelectedIntegration(integration, 'read'));
  }

  render() {
    const { canEdit, globalTranslations, integration, key } = this.props;

    return (
      <SonifiPopover
        deleteDisabled={false}
        deleteFunc={this.handleDeleteDialog.bind(this)}
        deleteTitle={globalTranslations.delete}
        editFunc={this.handleEditDialog.bind(this)}
        editTitle={globalTranslations.edit}
        functionOneFunc={this.handleReadDialog.bind(this)}
        functionOneTitle={globalTranslations.view}
        hideDelete={!canEdit}
        hideEdit={!canEdit}
        key={key}
        showFunctionOne={true}
        tableRow={
          <Fragment>
            {/* <TableCell>{integration.category}</TableCell> */}
            <TableCell>{integration.type}</TableCell>
          </Fragment>

        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults
});

IntegrationRow.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  id: PropTypes.number,
  integration: PropTypes.object,
  key: PropTypes.number,
  globalTranslations: PropTypes.object
};

export default connect(mapStateToProps)(IntegrationRow);
