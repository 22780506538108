import { LOAD_LANGUAGE_LITERALS } from '../../../../actions/globalActionTypes';
import { getTranslationObject } from '../../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  gettingMore: false,
  lastPollingTime: null,
  loading: false,
  maxPages: 0,
  terminals: [],
  totalTerminals: 0,
  translations: null
};

export default function termCommReducer(state = initialState, action) {
  let miscObj = null;
  switch (action.type) {
    case types.GET_TERM_COMMS_BEGIN:
      return {
        ...state,
        gettingMore: !action.loading,
        lastPollingTime: null,
        loading: action.loading,
        terminals: (action.loading ? [] : state.terminals)
      };

    case types.GET_TERM_COMMS_SUCCESS:
      miscObj = state.terminals.concat(action.response.terminals);
      return {
        ...state,
        gettingMore: false,
        lastPollingTime: action.response.last_polling_period_update ||
          new Date(Date.now() - (20000 * 60)).toISOString(),
        loading: false,
        maxPages: action.response.page_count,
        terminals: miscObj,
        totalTerminals: action.response.total_items
      };

    case types.GET_TERM_COMMS_FAILURE:
      return {
        ...state,
        loading: false,
        gettingMore: false
      };

    case types.UPDATE_TERM_COMMS_FINISH:
      return {
        ...state,
        lastPollingTime: action.response.last_polling_period_update ||
          new Date(Date.now() - (20000 * 60)).toISOString(),
        terminals: action.response?.terminals ? action.response.terminals : state.terminals
      };

    case types.SET_ACTIVATE_TIME_NOW:
      return {
        ...state,
        lastPollingTime: new Date().toISOString()
      };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
