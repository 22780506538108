import { CheckCircleOutline } from '@mui/icons-material';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import { updateDeleteRow } from '../actions/termModelActions';

const styles = () => ({
  goodStatus: {
    color: 'green !important',
    width: '52px'
  }
});

class TermModelRow extends Component {
  handleDeleteDialog() {
    const { dispatch, rowIndex, models } = this.props;
    dispatch(updateDeleteRow(models[rowIndex]));
  }

  termRow() {
    const { classes, models, rowIndex, translations } = this.props;
    const { active, id, make } = models[rowIndex];
    return (<Fragment>
      <TableCell component="th" scope="row" >
        {active && <Tooltip title={translations.activeTerminal}>
          <CheckCircleOutline className={classes.goodStatus} />
        </Tooltip>}
      </TableCell>
      <TableCell component="th" scope="row">
        <SonifiLabel label={id} />
      </TableCell>
      <TableCell component="th" scope="row">
        <SonifiLabel label={make} />
      </TableCell>
    </Fragment >);
  }

  render() {
    const {
      canEdit, deleteEnabled, globalTranslations, key, models, rowIndex
    } = this.props;
    const { active, id } = models[rowIndex];

    if (id === 'add') {
      return (<Fragment />);
    }

    return (
      canEdit
        ? <SonifiPopover
          hideEdit={true}
          deleteTitle={globalTranslations.delete}
          deleteFunc={this.handleDeleteDialog.bind(this)}
          deleteDisabled={active || !deleteEnabled}
          hideDelete={!canEdit}
          key={key}
          tableRow={this.termRow()}
        />
        : <TableRow> {this.termRow()} </TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  models: state.termModel.models,
  globalTranslations: state.global.translations.defaults,
  translations: state.termModel.translations.row
});

TermModelRow.propTypes = {
  canEdit: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  deleteEnabled: PropTypes.bool,
  dispatch: PropTypes.func,
  key: PropTypes.number,
  globalTranslations: PropTypes.object,
  models: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(TermModelRow, styles, { withTheme: true }));

