import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { SonifiText } from '../../../containers';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import { getErrorText, isError } from '../../../utils/index';
import { removePipe } from '../../../utils/textUtil';
import {
    saveTermModel, saveTermModelBegin, saveTermModelReset, updateSelectedTermModel
} from '../actions/termModelActions';
import { makes } from '../constants';
import { isValidTermModel } from '../utils/validator';

const styles = () => ({
  editContent: {
    height: '100%',
    overflow: 'hidden'
  }
});

class TermModelDialog extends Component {
  state = {
    errors: {}
  };

  componentDidMount() {
    const { models, selectedTermModel } = this.props;
    this.setState({
      ...models[selectedTermModel],
      id: models[selectedTermModel].id === 'add' ? '' : models[selectedTermModel].id
    });
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(updateSelectedTermModel(-1));
    }
  }

  onSubmit() {
    const { globalTranslations, models, sort, translations } = this.props;

    this.props.dispatch(saveTermModelBegin());
    isValidTermModel(this.state, models, translations.errors, globalTranslations.errors)
      .then((data) => {
        this.setState({ errors: data });

        if (Object.entries(data).length === 0) {
          this.props.dispatch(saveTermModel(this.state, sort));
          this.props.reset();
        } else {
          this.props.dispatch(saveTermModelReset());
        }
      })
      .catch((error) => {
        console.log('Valid Term Model Error', error);
        this.props.dispatch(saveTermModelReset());
      });
  }

  processChange = (name) => ({ target: { value } }) => {
    this.setState({ [name]: value });
  };

  editId = (name) => ({ target: { value } }) => {
    this.setState({ [name]: removePipe(value) });
  };

  render() {
    const {
      canEdit, classes, globalTranslations, translations, editType
    } = this.props;
    const { errors } = this.state;

    const readOnly = editType === 'read' || !canEdit;

    return (
      <Dialog open={true}
        onClose={this.onCancel.bind(this)}
        fullWidth
        maxWidth="md" >
        <SonifiModalHeader
          header={translations.title}
          onCancel={this.onCancel.bind(this)}
          onSubmit={this.onSubmit.bind(this)}
          onlyClose={readOnly}
          label={globalTranslations.defaults.save}
        />
        <DialogContent >
          <Grid container className={classes.editContent}>
            <Grid item>
              <SonifiText
                defaultValue={this.state.id}
                change={this.editId('id')}
                label={translations.id}
                size="md"
                disabled={readOnly}
                error={isError('id', errors)}
                errorText={getErrorText('id', errors)}
              />
            </Grid>
            <Grid item>
              <SonifiText
                defaultValue={this.state.make}
                change={this.processChange('make')}
                label={translations.make}
                size="md"
                select={true}
                items={makes}
                disabled={readOnly}
                error={isError('make', errors)}
                errorText={getErrorText('make', errors)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  editType: state.termModel.editType,
  globalTranslations: state.global.translations,
  models: state.termModel.models,
  selectedTermModel: state.termModel.selectedTermModel,
  translations: state.termModel.translations.editDialog
});

TermModelDialog.propTypes = {
  canEdit: PropTypes.bool,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  globalTranslations: PropTypes.object,
  models: PropTypes.array,
  reset: PropTypes.func,
  selectedTermModel: PropTypes.number,
  sort: PropTypes.string,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(withStyles(TermModelDialog, styles, { withTheme: true }));
