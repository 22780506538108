import { LOAD_LANGUAGE_LITERALS } from '../../../../actions/globalActionTypes';
import { getTranslationObject } from '../../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  loading: false,
  logs: [],
  maxPages: 0,
  pageSize: 0,
  totalItems: 0,
  translations: null
};

export default function terminalLogsReducer(state = initialState, action) {
  let miscArray = null;

  switch (action.type) {
    case types.RESET_TERMINAL_LOGGING:
      return {
        ...state,
        loading: true,
        logs: [],
        maxPages: 0,
        pageSize: 0,
        totalItems: 0
      };

    case types.GET_TERMINAL_LOGGING_BEGIN:
      return { ...state, logs: action.reset ? [] : state.logs, loading: action.reset };

    case types.GET_TERMINAL_LOGGING_SUCCESS:
      miscArray = state.logs ? [...state.logs, ...action.logs.sites] : [...action.logs.sites];
      return {
        ...state,
        loading: false,
        logs: miscArray,
        maxPages: action.logs.page_count,
        pageSize: action.logs.page_size,
        totalItems: action.logs.total_items
      };

    case types.GET_TERMINAL_LOGGING_FAILURE:
      return {
        ...state, loading: false, maxPages: 0, pageSize: 0, totalItems: 0
      };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
