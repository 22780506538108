const hasValue = (value) => value !== null && value !== undefined;

const isNumber = (value) => typeof value === 'number';

const inRange = (value, low, high) => value >= low && value <= high;

const isNullOrEmptyString = (value) => value === null || value === undefined || value === '';


export { hasValue, isNumber, inRange, isNullOrEmptyString };
