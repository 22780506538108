import {
  ANALOG, ATSC, AUX, DVB_C, DVB_S, DVB_T, INTL_ANALOG, PROPERTY
} from '../constants/constants';
import {
  validateAnalogMod, validateAnalogStandard, validateAnalogTuning, validateCarrier, validateDescription,
  validateDigitalEncryption, validateDigitalMBPS, validateDigitalModulatorStream, validateDigitalTuning,
  validateDigitalVideoFormat, validateDisplayName, validateDuration, validateFrequencyKhz,
  validateFrequencyMhz, validateId, validateIntlBandwidth, validateIntlModulation, validateIntlPolarization,
  validateIntlSatelliteId, validateIntlStream, validateIpAddress, validateIpEncryption, validateIpMBPS,
  validateIpPort, validateIpStream, validateIpVideoFormat, validatePlpId, validateSourceId, validateSymbolRate,
  validateTitle, validateType
} from '../Validator/ValidationFunctions';

export function isFormValid(channel, channelList, globalTranslations) {
  const errors = {};
  let channelObjectToTest = channel[channel.type];
  if (channel.type === AUX) {
    channelObjectToTest = channel[PROPERTY];
  }
  errors.id = validateId(channel.id);
  errors.displayName = validateDisplayName(channel.name, globalTranslations);
  errors.type = validateType(channel.type);

  errors.sourceId = validateSourceId(channelObjectToTest.source_id, channel.id, channelList);
  errors.duration = validateDuration(channelObjectToTest.duration, channel.type);
  errors.entry = validateTitle(channelObjectToTest.entry, channel.type, globalTranslations);
  errors.description = validateDescription(channelObjectToTest.description, channel.type, globalTranslations);

  if (channel.analogEnabled) {
    if (channel.channelType === ANALOG) {
      errors.analogMod = validateAnalogMod(channel.analog.modulator, channel.id, channelList);
      errors.analogTuning = validateAnalogTuning(channel.analog.tuning);
    }
    if (channel.channelType === INTL_ANALOG) {
      errors.analogCarrier = validateCarrier(channel.intl_analog.carrier);
      errors.analogFrequency = validateFrequencyKhz(channel.intl_analog.frequency_khz);
      errors.analogStandard = validateAnalogStandard(channel.intl_analog.standard);
    }
  }
  if (channel.digitalEnabled) {
    if (channel.channelType === ATSC) {
      errors.digitalModStream = validateDigitalModulatorStream(
        channel.type, channel.digital.modulator, channel.digital.stream,
        channel.id, channelList
      );
      errors.digitalMbps = validateDigitalMBPS(channel.digital.mbps);
      errors.digitalFormat = validateDigitalVideoFormat(channel.digital.video_format);
      errors.digitalEncryption = validateDigitalEncryption(channel.digital.encryption);
      errors.digitalTuning = validateDigitalTuning(channel.digital.tuning);
    }

    if (channel.channelType === DVB_C) {
      errors.dvbcFormat = validateDigitalVideoFormat(channel.intl_dvb_c.video_format);
      errors.dvbcEncryption = validateDigitalEncryption(channel.intl_dvb_c.encryption);
      errors.dvbcBandwidth = validateIntlBandwidth(channel.intl_dvb_c.bandwidth);
      errors.dvbcSymbolRate = validateSymbolRate(channel.intl_dvb_c.symbol_rate);
      errors.dvbcStream = validateIntlStream(channel.intl_dvb_c.stream);
      errors.dvbcFrequency = validateFrequencyKhz(channel.intl_dvb_c.frequency_khz);
      errors.dvbcModulation = validateIntlModulation(channel.intl_dvb_c.modulation);
    }

    if (channel.channelType === DVB_S) {
      errors.dvbsCarrier = validateCarrier(channel.intl_dvb_s.carrier);
      errors.dvbsFormat = validateDigitalVideoFormat(channel.intl_dvb_s.video_format);
      errors.dvbsEncryption = validateDigitalEncryption(channel.intl_dvb_s.encryption);
      errors.dvbsBandwidth = validateIntlBandwidth(channel.intl_dvb_s.bandwidth);
      errors.polarization = validateIntlPolarization(channel.intl_dvb_s.polarization);
      errors.satelliteId = validateIntlSatelliteId(channel.intl_dvb_s.satellite_id);
      errors.dvbsSymbolRate = validateSymbolRate(channel.intl_dvb_s.symbol_rate);
      errors.dvbsStream = validateIntlStream(channel.intl_dvb_s.stream);
      errors.dvbsFrequency = validateFrequencyMhz(channel.intl_dvb_s.frequency_mhz);
      errors.dvbsModulation = validateIntlModulation(channel.intl_dvb_s.modulation);
    }

    if (channel.channelType === DVB_T) {
      errors.dvbtFormat = validateDigitalVideoFormat(channel.intl_dvb_t.video_format);
      errors.dvbtEncryption = validateDigitalEncryption(channel.intl_dvb_t.encryption);
      errors.dvbtBandwidth = validateIntlBandwidth(channel.intl_dvb_t.bandwidth);
      errors.plpID = validatePlpId(channel.intl_dvb_t.plp_id);
      errors.dvbtStream = validateIntlStream(channel.intl_dvb_t.stream);
      errors.dvbtFrequency = validateFrequencyKhz(channel.intl_dvb_t.frequency_khz);
      errors.dvbtModulation = validateIntlModulation(channel.intl_dvb_t.modulation);
    }
  }

  if (channel.ipEnabled) {
    errors.ipAddress = validateIpAddress(channel.ip.address, channel.ip.port, channel.id, channelList);
    errors.ipStream = validateIpStream(channel.ip.stream);
    errors.ipMbps = validateIpMBPS(channel.ip.mbps);
    errors.ipFormat = validateIpVideoFormat(channel.ip.video_format);
    errors.ipEncryption = validateIpEncryption(channel.ip.encryption);
    errors.ipPort = validateIpPort(channel.ip.port, channel.ip.address, channel.id, channelList);
  }

  for (const propName in errors) {
    if (errors[propName] === null || errors[propName] === undefined) {
      delete errors[propName];
    }
  }
  console.log('Error Filtered', errors);

  return errors;
}
