import {
  Add, Delete, GetApp, ImportExport
} from '@mui/icons-material';
import FilterListIcon from '@mui/icons-material/FilterList';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, Grid, Pagination } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { ROOM_EDIT } from '../../../constants/roles';
import { SonifiSnackBar } from '../../../containers';
import ImportDialog from '../../../containers/Dialog/ImportDialog';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiError from '../../../containers/SonifiError';
import SonifiFilterDropDown from '../../../containers/SonifiFilterDropDown';
import SonifiIconButton from '../../../containers/SonifiIconButton';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSpinnerWithMessage from '../../../containers/SonifiSpinnerWithMessage';
import SonifiText from '../../../containers/SonifiText';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import { fetchGroupServices } from '../../GroupServices/actions/groupServicesActions';
import { getIntegrations } from '../../Integrations/actions/integrationActions';
import { saveAvailableLocations } from '../../SiteManagement/actions/siteManagementActions';
import {
  deleteConfirm, deleteRangeOfRooms, getRoomsExport, getRoomsToDelete, removeRoom, resetAddedRange,
  resetSnackBar, updateDialogType
} from '../actions/editRoomActions';
import { filterRooms } from '../actions/filterActions';
import {
  fetchOccupiedRoomsCount, fetchRooms, fetchRoomsOptions, fetchRoomsWithoutTerminalsCount
} from '../actions/roomActions';
import Selects from '../containers/Selects';
import { addRangeOfRooms } from '../utils/addRooms';
import { exampleCsv, parseRooms } from '../utils/roomImport';
import AddDialog from './AddDialog';
import AddRoomSuccess from './AddRoomSuccess';
import DeleteDialog from './DeleteDialog';


const radioData = [
  { label: 'All Rooms', value: 'all-rooms' },
  { label: 'Occupied Rooms', value: 'occupied-rooms' },
  { label: 'Rooms without Terminals', value: 'rooms-without-terminals' }
];

class RoomsFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      building: this.props.translations.all,
      filter: '',
      floor: this.props.translations.all,
      limit: 100,
      newHierarchy: null,
      addedToHeirarchy: null,
      offset: 0,
      unit: this.props.translations.all,
      withoutTerminalsCount: null,
      radioChecked: radioData[0].value
    };

    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.filterObjectOnRadioSelect = this.filterObjectOnRadioSelect.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchRoomsOptions());
    this.props.dispatch(fetchOccupiedRoomsCount());
    this.props.dispatch(getIntegrations());
    this.props.dispatch(fetchGroupServices(null, true));
    this.props.dispatch(fetchRoomsWithoutTerminalsCount())
      .then((total) => this.setState({ withoutTerminalsCount: total }));
  }

  handleSelectChange(name, value) {
    this.setState({ [name]: value }, () => {
      const filterObj = { ...this.state };
      delete (filterObj.newHierarchy);
      delete (filterObj.addedToHeirarchy);
      delete (filterObj.radioChecked);
      delete (filterObj.withoutTerminalsCount);
      if (filterObj.building === this.props.translations.all) {
        delete filterObj.building;
      }
      if (filterObj.floor === this.props.translations.all) {
        delete filterObj.floor;
      }
      if (filterObj.unit === this.props.translations.all) {
        delete filterObj.unit;
      }
      if (filterObj.filter === '') {
        delete filterObj.filter;
      } else {
        filterObj.filter = filterObj.filter.trim();
      }
      this.filterObjectOnRadioSelect(filterObj);

      this.props.dispatch(filterRooms(filterObj));
    });
  }

  filterObjectOnRadioSelect(object) {
    const { radioChecked } = this.state;
    if (radioChecked === 'all-rooms') {
      delete object.occupied;
      delete object.has_terminals;
    } else if (radioChecked === 'occupied-rooms') {
      delete object.has_terminals;
    } else if (radioChecked === 'rooms-without-terminals') {
      delete object.occupied;
    }
  }

  handleGetRoom = ({ target: { value } }) => {
    this.setState({ page: 1, offset: 0 }, () => {
      this.handleSelectChange('filter', value);
    });
  };

  // handlePaginationClick(offset) {
  //   this.setState({ offset });

  //   this.handleSelectChange('page', (offset / this.state.limit) + 1);
  // }

  handlePaginationClick = (event, value) => {
    this.setState({ offset: value - 1 });
    this.handleSelectChange('page', value);
  };

  handleButtonClick = (name) => {
    this.props.dispatch(updateDialogType(name));
  };

  handleExportClick = () => {
    console.log('RoomsFilter:handleExportClick');
    this.props.dispatch(getRoomsExport());
  };

  handleDeleteConfirmDialog(start, end, exclude) {
    const { deleteRooms, dispatch } = this.props;

    let startOfRange = start,
      endOfRange = (end === null || end === '' ? start : end);
    if (deleteRooms && deleteRooms.length === 1) {
      startOfRange = endOfRange = deleteRooms[0];
    }
    deleteRooms !== null
      ? dispatch(deleteRangeOfRooms(startOfRange, endOfRange, exclude))
      : dispatch(getRoomsToDelete(startOfRange, endOfRange));

    // deleteRange
    //   ? (deleteRooms !== null
    //     ? dispatch(deleteRangeOfRooms(start, end, exclude))
    //     : dispatch(getRoomsToDelete(start, end)))
    //   : dispatch(deleteConfirm(start, end, deleteRange));
  }

  confirmDialogConfirmFunc() {
    const { startRoom } = this.props;
    this.props.dispatch(removeRoom(startRoom));
    this.props.dispatch(deleteConfirm(null, null));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(deleteConfirm(null, null));
    this.closeDialog();
  }

  closeDialog() {
    this.props.dispatch(resetAddedRange());
    this.props.dispatch(updateDialogType(null));
    this.setState({ newHierarchy: null, addedToHeirarchy: null });
    if (!isEmpty(this.props.filterObj)) {
      this.props.dispatch(filterRooms(this.props.filterObj));
    }
  }

  parseImportFile(result) {
    const { classifications, site, translations } = this.props;
    const pRooms = parseRooms(site, classifications, translations, result);

    this.setState({
      addedToHeirarchy: pRooms.addedToHeirarchy,
      newHierarchy: pRooms.siteHierarchy
    });

    return {
      tableList: pRooms.rooms,
      allowSave: pRooms.roomError,
      headers: pRooms.tableHeader,
      columnNames: pRooms.columnNames
    };
  }

  importList(roomData) {
    console.log('RoomFilter:importList', roomData);
    console.log('RoomsFilter:siteHierarchy', this.state.newHierarchy);
    const { classifications, dispatch } = this.props;
    const { addedToHeirarchy, newHierarchy } = this.state;

    this.props.dispatch(updateDialogType(null));

    if (addedToHeirarchy && addedToHeirarchy.length > 0) {
      dispatch(saveAvailableLocations(
        {
          buildings: newHierarchy,
          classifications,
        }
      ));
    }

    addRangeOfRooms(roomData, dispatch);
  }

  closeSnackBar() {
    this.props.dispatch(resetSnackBar());
  }

  handleCheckboxClick(event) {
    const value = event.target.value;
    const { dispatch } = this.props;

    this.setState({ radioChecked: value, page: 1, offset: 0 });

    if (value === 'all-rooms') {
      dispatch(fetchRooms(value));
      this.handleSelectChange('filter', '');
    } else if (value === 'occupied-rooms') {
      this.handleSelectChange('occupied', true);
    } else if (value === 'rooms-without-terminals') {
      this.handleSelectChange('has_terminals', false);
    }
  }

  render() {
    const {
      addingRange, addRoomStatus, dialogType, error, globalTranslations, limit, total, totalNumRooms,
      saving, site, startRoom, translations, userPermissions, snackBarMessage, snackBarType, occupiedRoomCount,
      loadingGroups
    } = this.props;
    const {
      building, floor, unit, withoutTerminalsCount, radioChecked
    } = this.state;

    if (error || loadingGroups) {
      return <Fragment />;
    }

    if (!site || site.length < 1) {
      return <SonifiError label={translations.siteHierarchyError} />;
    }

    const canEdit = checkForSingleUserPermission(ROOM_EDIT, userPermissions);
    return (
      <Fragment>
        <Grid container style={{ justifyContent: 'space-around' }}>
          <Grid item xs={2}>
            <SonifiLabel
              noPadding
              topMargin
              size="xs"
              boldLabel={translations.total}
              label={`${totalNumRooms !== null ? totalNumRooms : '---'}`}
            />
            <SonifiLabel
              noPadding
              size="xs"
              boldLabel={translations.occupied}
              label={`${occupiedRoomCount !== null ? occupiedRoomCount : '---'}`}
            />
            <SonifiLabel
              noPadding
              size="xs"
              boldLabel={translations.withoutTerminals}
              label={`${withoutTerminalsCount !== null ? withoutTerminalsCount : '---'}`}
            />
          </Grid>
          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
            <Selects
              onChange={this.handleSelectChange.bind(this)}
              building={building}
              unit={unit}
              floor={floor}
              includeAll={true}
            />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', marginTop: '1em' }}>
            <SonifiText
              label={translations.search}
              defaultValue={this.state.filter}
              change={this.handleGetRoom}
              icon={<SearchIcon />}
              iconPosition="end"
              size="percent"
            />
          </Grid>
          {canEdit && <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <SonifiIconButton onClick={this.handleButtonClick.bind(this, 'import')}
              icon={<ImportExport />} label={globalTranslations.defaults.import} />
            <SonifiIconButton onClick={this.handleExportClick.bind(this)}
              icon={<GetApp />} label={globalTranslations.defaults.export} />
            <SonifiIconButton onClick={this.handleButtonClick.bind(this, 'add')}
              icon={<Add />} label={globalTranslations.defaults.add} />
            <SonifiIconButton onClick={this.handleButtonClick.bind(this, 'delete')}
              icon={<Delete />} label={globalTranslations.defaults.delete} />
            <SonifiFilterDropDown
              buttonIcon={
                <FilterListIcon
                  color="secondary"
                />
              }
              containerStyles={{ display: 'flex', alignItems: 'center', position: 'relative' }}
              data={radioData}
              itemSelectHandler={this.handleCheckboxClick}
              checkedButton={radioChecked}
            />
          </Grid>}
        </Grid>

        <Divider style={{ marginTop: '5px' }} />
        {limit < total
          ? <Pagination
            count={this.props.pageCount}
            page={this.state.offset + 1}
            onChange={this.handlePaginationClick} />
          : <div style={{ height: '10px' }} />
        }

        {dialogType === 'add' &&
          <AddDialog range={false} onCancel={this.closeDialog.bind(this)}
            onClick={this.handleButtonClick.bind(this)} />}
        {dialogType === 'delete' &&
          <DeleteDialog confirmFunc={this.handleDeleteConfirmDialog.bind(this)}
            onCancel={this.closeDialog.bind(this)} />}
        {dialogType === 'import' &&
          <ImportDialog
            csvText={exampleCsv()}
            onCancel={this.closeDialog.bind(this)}
            globalTranslations={globalTranslations.importDialog}
            parseImportFile={this.parseImportFile.bind(this)}
            saveListFunc={this.importList.bind(this)}
            title={translations.importRooms}
          />
        }
        <SonifiSpinnerWithMessage show={saving || addingRange} message={translations.addingRooms} />
        {addRoomStatus && addRoomStatus !== null && <AddRoomSuccess onCancel={this.closeDialog.bind(this)} />}
        <SonifiConfirm
          dialogOpen={startRoom !== null && startRoom !== undefined}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          confirmTitle={translations.deleteRoom}
          confirmText={`${translations.deleteConfirmText} ${startRoom === null ? '' : startRoom}?`}
          buttonCancelText={globalTranslations.defaults.cancel}
          buttonConfirmText={globalTranslations.defaults.delete}
        />
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  addingRange: state.rooms.addingRange,
  addRoomStatus: state.rooms.addRoomStatus,
  classifications: state.rooms.classifications,
  deleteRange: state.rooms.deleteRange,
  deleteRooms: state.rooms.deleteRooms,
  dialogType: state.rooms.dialogType,
  endRoom: state.rooms.endRoom,
  error: state.rooms.error,
  filterObj: state.rooms.filterObj,
  globalTranslations: state.global.translations,
  limit: state.rooms.page_size,
  loading: state.rooms.loading,
  loadingGroups: state.groupServices.loadingGroups,
  occupiedRoomCount: state.rooms.occupiedRoomCount,
  pageCount: state.rooms.page_count,
  radioValue: state.rooms.radioValue,
  saving: state.siteManagement.saving,
  savingStatus: state.siteManagement.savingStatus,
  site: state.rooms.buildings,
  snackBarMessage: state.rooms.snackBarMessage,
  snackBarType: state.rooms.snackBarType,
  startRoom: state.rooms.startRoom,
  total: state.rooms.total_items,
  totalNumRooms: state.rooms.totalNumRooms,
  translations: state.rooms.translations.main,
  userPermissions: state.global.permissions
});

RoomsFilter.propTypes = {
  addingRange: PropTypes.bool,
  addRoomStatus: PropTypes.object,
  classifications: PropTypes.array,
  deleteRange: PropTypes.bool,
  deleteRooms: PropTypes.array,
  dialogType: PropTypes.string,
  dispatch: PropTypes.func,
  endRoom: PropTypes.string,
  error: PropTypes.object,
  filterObj: PropTypes.object,
  globalTranslations: PropTypes.object,
  limit: PropTypes.number,
  loading: PropTypes.bool,
  loadingGroups: PropTypes.bool,
  occupiedRoomCount: PropTypes.number,
  pageCount: PropTypes.number,
  saving: PropTypes.bool,
  savingStatus: PropTypes.number,
  site: PropTypes.array,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  startRoom: PropTypes.string,
  total: PropTypes.number,
  totalNumRooms: PropTypes.number,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(RoomsFilter);
