import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiText } from '../../../containers';
import { findObjectByKey } from '../../../utils/index';
import { editTerminalField } from '../actions/terminalActions';

class ConfigEdit extends Component {
  state = {
    initLang: '',
    initMenuset: ''
  };

  componentDidMount() {
    const { selectedTerm } = this.props;
    this.setState({
      initLang: selectedTerm.language,
      initMenuset: selectedTerm.menuset,
      initLineup: selectedTerm.lineup_id
    });
  }

  getItem(label, value, name, items, disabled) {
    return value !== null
      ? <Grid item xs={6}>
        <SonifiText
          label={label}
          size="percent"
          select={items !== null}
          items={items && items.map((suggestion) => ({
            id: suggestion.id,
            value: suggestion.id,
            label: suggestion.name,
          }))}
          defaultValue={value}
          change={this.handleChange(name)}
          disabled={!!disabled}
        />
      </Grid>
      : <Fragment />;
  }

  handleChange = (name) => ({ target: { value } }) => {
    const { dispatch, menusets, selectedTerm, termLocations } = this.props;
    const { initMenuset, initLang, initLineup } = this.state;

    if (name === 'location') {
      const locationName = findObjectByKey(termLocations, 'id', value) || { name: '' };
      value = locationName.name;
    } else if (name === 'menuset') {
      const menusetName = findObjectByKey(menusets, 'id', value) || { name: '' };
      dispatch(editTerminalField('interactive_engine', menusetName.description));
    }
    dispatch(editTerminalField(name, value));

    setTimeout(() => {
      const curLang = name === 'language' ? value : selectedTerm.language;
      const curMenuSet = name === 'menuset' ? value : selectedTerm.menuset;
      const curLineup = name === 'lineup_id' ? value : selectedTerm.lineup_id;
      dispatch(editTerminalField('changed',
        (initMenuset !== curMenuSet || initLang !== curLang || initLineup !== curLineup)));
    }, 200);
  };

  render() {
    const {
      helperClass, languages, lineups, menusets, selectedTerm, termLocations, translations
    } = this.props;

    const locationId = findObjectByKey(termLocations, 'name', selectedTerm.location) || { name: selectedTerm.location };
    const lineupObj = findObjectByKey(lineups, 'id', selectedTerm.lineup_id) || { name: selectedTerm.lineup_id };
    const languageObj = findObjectByKey(languages, 'id', selectedTerm.language) || { name: selectedTerm.language };
    const menusetObj = findObjectByKey(menusets, 'id', selectedTerm.menuset) || { name: selectedTerm.menuset };

    return (
      <Grid container justifyContent="space-between" className={helperClass.card} >
        {this.getItem(translations.location, locationId.id, 'location', termLocations, true)}
        {this.getItem(translations.lineup, selectedTerm.lineup_id, 'lineup_id', lineups,
          (lineups.length < 2 && lineupObj.id))}
        {this.getItem(translations.menuset, selectedTerm.menuset, 'menuset', menusets,
          (menusets.length < 2 && menusetObj.id))}
        {this.getItem(translations.interactiveEng, selectedTerm.interactive_engine || '', '', null, true)}
        {this.getItem(translations.language, selectedTerm.language, 'language', languages,
          (languages.length < 2 && languageObj.id))}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.termGrid.changed,
  languages: state.termGrid.languages,
  lineups: state.termGrid.lineups,
  menusets: state.termGrid.menusets,
  selectedTerm: state.termGrid.selectedTerm,
  termLocations: state.termGrid.locations,
  translations: state.termGrid.translations.terminalInfo
});

ConfigEdit.propTypes = {
  dispatch: PropTypes.func,
  helperClass: PropTypes.object,
  languages: PropTypes.array.isRequired,
  lineups: PropTypes.array.isRequired,
  menusets: PropTypes.array.isRequired,
  selectedTerm: PropTypes.object,
  termLocations: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(ConfigEdit);
