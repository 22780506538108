import { ArrowDropDown } from '@mui/icons-material';
import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import SonifiCheckbox from './SonifiCheckbox';
import SonifiLabel from './SonifiLabel';

const styles = () => ({
  tableHead: {
    backgroundColor: '#fff',
    borderTop: '1px solid #dedfdf',
    color: '#000',
    zIndex: '10'
  }
});

export class SonifiTableHead extends Component {
  checkboxChange = (col) => () => {
    col.onChange();
  };

  render() {
    const {
      classes, headColumns, order, orderBy, onRequestSort, hideSortIcon,
    } = this.props;

    const createSortHandler = (property) => (event) => {
      onRequestSort(property, event);
    };

    let hideSort = true;
    if (hideSortIcon === undefined || !hideSortIcon) {
      hideSort = false;
    }

    return (
      <TableHead>
        <TableRow>
          {headColumns.map((col) => (
            <TableCell
              key={col.id}
              variant={'head'}
              width={
                col.checkbox || col.narrow
                  ? `${col.width ? col.width : '8%'}`
                  : ''
              }
              className={classes.tableHead}
              align={col.centerHeader ? 'center' : col.rightHeader ? 'right' : 'left'}
              sortDirection={orderBy === col.id ? order : false}
            >
              {col.checkbox ?
                <SonifiCheckbox
                  disabled={col.disabled ? col.disabled : false}
                  error={col.error}
                  label=""
                  noLabel={col.noLabel}
                  onChange={this.checkboxChange(col)}
                  value={col.checkValue} />
                : (col.sortable
                  ? <TableSortLabel
                    active={col.sortable && orderBy === col.id}
                    direction={order}
                    hideSortIcon={hideSort ? hideSort : !col.sortable}
                    IconComponent={ArrowDropDown}
                    onClick={col.sortable ? createSortHandler(col.id) : null} >
                    <SonifiLabel boldLabel={col.label} error={col.error} hide={!!col.hide} />
                  </TableSortLabel>
                  : <SonifiLabel boldLabel={col.label} error={col.error} hide={!!col.hide} />)
              }
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

SonifiTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  headColumns: PropTypes.array.isRequired,
  hideSortIcon: PropTypes.bool,
  onRequestSort: PropTypes.func,
  order: PropTypes.string,
  orderBy: PropTypes.string
};

export default withStyles(SonifiTableHead, styles);
