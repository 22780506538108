import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiTemplate from '../../containers/SonifiTemplate';
import * as actions from './actions/downloadsActions';
import IpgFileTable from './containers/IpgFileTable';

class Downloads extends Component {
    state = {
        ipgDownloads: 'placeholder'
    };

    componentDidMount() {
        console.log(this.props.translations);
        this.props.dispatch(actions.fetchIpgFiles());
    }

    getPageDetails() {
        return (
          <Grid container style={{ alignContent: 'start', width: '100%' }}>
              <Grid item xs={12} >
                  <IpgFileTable downloadState={this.state} />
              </Grid>
          </Grid>
        );
    }

    render() {
        const { translations } = this.props;
        console.log(translations);

        return (
            <Fragment>
                <SonifiTemplate
                    header={translations.header}
                    pageDetails={this.getPageDetails()}
                />
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    userPermissions: state.global.permissions,
    translations: state.downloads.translations.table
});

Downloads.propTypes = {
    dispatch: PropTypes.func,
    translations: PropTypes.object,
    userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(Downloads);
