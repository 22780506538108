/*
[ used anywhere in the room number causes add button not to function
] used anywhere in the room number causes add button not to function
. used in the beginning of a room number causes the add button not to function
? used in the beginning of a room number causes the add button not to function
? used in the middle or end of a room number gets stripped from the room number that gets saved
/ used in the beginning of a room number causes the add button not to function
/ used in the middle or end of a room number gets stripped from the room number that gets saved
\ used in the beginning of a room number causes the add button not to function
\ used in the middle or end of a room number gets stripped from the room number that gets saved
*/
export function textHelper(txt) {
  const regex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/;
  return regex.test(txt);
}

export function removePipe(value) {
  if (value && value.length > 0) {
    return value.replace(/([|])/g, '');
  }
  return value;
}

export function alphaNumericLowercaseOnly(value) {
  if (value === null || value === undefined || value.length === 0) {
    return ' ';
  }
  return value.replace(/[^a-z0-9]/gi, '').toLowerCase();
}
