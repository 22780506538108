import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import Background from '../../img/HotelDirectory.jpg';

const styles = () => ({
  backgroundImageStyle: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    backgroundSize: 'contain',
    backgroundPosition: 'top center'
  }
});

class HotelDirectory extends Component {
  render() {
    const { classes } = this.props;
    window.open('http://portal.sonifi.com');
    return (
      <div style={{ position: 'relative', zIndex: '2' }} className={classes.backgroundImageStyle} />
    );
  }
}

HotelDirectory.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(HotelDirectory, styles);
