import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { READ } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  channels: [],
  dialogType: READ,
  gettingInfo: false,
  job: '',
  loading: false,
  maxPages: 0,
  pageSize: 0,
  saving: false,
  selectedChannel: null,
  totalItems: 0,
  translations: null
};

export default function batchChannelsReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_BATCH_CHANNELS:
      return { ...state, selectedChannel: null, loading: false, channels: [] };

    case types.GET_CHANNEL_CHANGES_BEGIN:
      return { ...state, channels: action.reset ? [] : state.channels, loading: true };

    case types.GET_CHANNEL_CHANGES_SUCCESS:
      return {
        ...state,
        channels: action.response.jobs,
        maxPages: action.response.page_count,
        pageSize: action.response.page_size,
        totalItems: action.response.total_items,
        loading: false
      };

    case types.GET_CHANNEL_CHANGES_FAILURE:
      return { ...state, loading: false };

    case types.SAVE_REPLACEMENT_BEGIN:
      return { ...state, saving: true };

    case types.SAVE_REPLACEMENT_COMPLETE:
      return { ...state, saving: false };

    case types.SAVE_REPLACEMENT_SUCCESS:
      return {
        ...state,
        channels:
          [
            ...state.channels,
            {
              ...action.saveObj,
              created: '2022-10-12T09:01:52.848323+00',
              ended: null,
              status: 'In Progress'
            }
          ]
      };

    case types.STOP_JOB_CHECK:
      return { ...state, job: action.channel };

    case types.STOP_JOB_BEGIN:
      return { ...state, job: '', saving: true };

    case types.STOP_JOB_COMPLETE:
      return { ...state, saving: false };

    case types.VIEW_CHANNEL_BEGIN:
      return { ...state, selectedChannel: action.channelInfo, gettingInfo: true };

    case types.VIEW_CHANNEL_SUCCESS:
      return {
        ...state,
        selectedChannel: action.response === null ? action.response : { ...state.selectedChannel, ...action.response },
        dialogType: action.dialogType,
        gettingInfo: false
      };

    case types.VIEW_CHANNEL_FAILURE:
      return { ...state, gettingInfo: false, selectedChannel: null };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
