import { CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = () => ({
  progress: {
    // eslint-disable-next-line no-magic-numbers
    // margin: theme.spacing(2),
    position: 'relative',
    marginLeft: '50%',
    left: '-20px'
  }
});

export class SonifiSpinner extends Component {
  render() {
    const spinnerSizeSmall = 20;
    let spinnerSize = 40; // This is the default size according to Material UI Docs

    if (this.props.size === 'sm') {
      spinnerSize = spinnerSizeSmall;
    }
    return (
      <CircularProgress
        className={this.props.classes.progress}
        color={this.props.usePrimary ? 'primary' : 'secondary'}
        size={spinnerSize} />
    );
  }
}

SonifiSpinner.propTypes = {
  classes: PropTypes.object.isRequired,
  size: PropTypes.string,
  usePrimary: PropTypes.bool
};

export default withStyles(SonifiSpinner, styles);
