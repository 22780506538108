import { orderBy } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { compareValues, findArrayIdByKey, getTranslationObject } from '../../../utils/index';
import {
  ADD_ROLE, FETCH_ROLES_BEGIN, FETCH_ROLES_FAILURE, FETCH_ROLES_SUCCESS, FETCH_ROLE_BEGIN,
  FETCH_ROLE_FAILURE, FETCH_ROLE_SUCCESS, REMOVE_ROLE, SORT_ROLES, UPDATE_ROLE_LOADING,
  UPDATE_SELECTED_ROLE
} from '../actions/actionTypes';
import en from '../i18n/en';
import es from '../i18n/es';

const langs = { en, es };

const initialState = {
  getRoleLoading: false,
  loading: false,
  roles: [],
  selectedRole: -1,
  translations: null
};

export default function roleReducer(state = initialState, action) {
  const updatedRoles = [...state.roles];
  let miscObj = null,

    updatedRoleId = -1,
    selectedRoles = null,
    roleIndex = -1;

  switch (action.type) {
    case FETCH_ROLES_BEGIN:
      return { ...state, selectedRole: -1, loading: true };

    case FETCH_ROLES_SUCCESS:
      miscObj = [...action.roles];
      miscObj = orderBy(miscObj, ['id'], ['asc']);
      return { ...state, roles: miscObj, loading: false };

    case FETCH_ROLES_FAILURE:
      return { ...state, roles: [], loading: false };

    case FETCH_ROLE_BEGIN:
      return {
        ...state,
        getRoleLoading: true
      };
    case FETCH_ROLE_FAILURE:
    case UPDATE_ROLE_LOADING:
      return { ...state, getRoleLoading: false };

    case FETCH_ROLE_SUCCESS:
      updatedRoleId = findArrayIdByKey(updatedRoles, 'id', action.roleId);
      updatedRoles[updatedRoleId].roles = action.role.roles || [];
      return { ...state, getRoleLoading: false, roles: updatedRoles };

    case SORT_ROLES:
      updatedRoles.sort(compareValues(action.state.orderBy, action.state.order));
      return { ...state, roles: updatedRoles };

    case UPDATE_SELECTED_ROLE:
      return { ...state, selectedRole: action.index };

    case REMOVE_ROLE:
      selectedRoles = [...state.roles[state.selectedRole].roles];
      roleIndex = selectedRoles.findIndex((role) => role === action.roleName);
      selectedRoles.splice(roleIndex, 1);
      updatedRoles[state.selectedRole].roles = selectedRoles;
      return { ...state, roles: updatedRoles };

    case ADD_ROLE:
      selectedRoles = [...state.roles[state.selectedRole].roles];
      selectedRoles.push(action.roleName);
      updatedRoles[state.selectedRole].roles = selectedRoles;
      return { ...state, roles: updatedRoles };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
