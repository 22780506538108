import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth, Hub, I18n } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import awsExports from '../../aws-exports';
import '../../AmplifyTheme.css';
import { isSessionValid } from '../../utils';
import SonifiApp from '../SignInAuth/SonifiApp';
import Login from './components/Login';
import Mfa from './components/MFA';
import NewPasswordRequired from './components/NewPasswordRequired';
import PasswordReset from './components/PasswordReset';

I18n.putVocabulariesForLanguage('en', {
  'Sign in': 'LOG IN', // Button label
  'Sign in to your account': 'Welcome Back!',
  'Email': 'Enter your email address', // Email label
  'Password': 'Enter your password', // Password label
  'Forgot your password?': 'Reset Password',
  'mfaCode': 'Submit Code',
  'passwordReset': 'Send Code',
  'passwordResetBack': 'Back to Log In',
  'passwordResetSubmit': 'Submit',
  'passwordResetResend': 'Resend Code'
});

// https://aws-amplify.github.io/amplify-js/api/classes/authclass.html
export const NonSSO = (props) => {
  const [authState, setAuthState] = useState('login');
  const [user, setUser] = useState();
  const [mfaError] = useState(undefined);
  const [resetError, setResetError] = useState(undefined);
  const [resetSubmitMsg, setResetSubmitMsg] = useState({ type: undefined, msg: undefined, heading: undefined });
  // const [resetSubmitError, setResetSubmitError] = useState(undefined);
  // const [signInError, setSignInError] = useState(undefined);
  const [signInMsg, setSignInMsg] = useState({ type: undefined, msg: undefined, heading: undefined });
  const [initPasswordChangeError, setInitPasswordChangeError] = useState(undefined);

  Auth.configure({ ...awsExports, storage: localStorage });

  useEffect(() => {
    if (user) {
      if (
        user.preferredMFA === 'NOMFA' &&
        user.attributes &&
        user.attributes.phone_number &&
        user.attributes.phone_number_verified
      ) {
        Auth.setPreferredMFA(user, 'SMS');
      }

      switch (user.challengeName) {
        case 'NEW_PASSWORD_REQUIRED':
          setAuthState('requireNewPassword');
          break;
      }
    }
  }, [user]);

  const listener = (data) => {
    switch (data.payload.event) {
      case 'configured':
        break;
      case 'signIn':
        // If the authState is changed to authenticated here, the Auth class doesn't see the user as authenticated
        // If the refresh happens, it appears that the user is authenticated.
        // location.reload(true);
        setAuthState('authenticated');
        break;
      case 'confirmSignIn':
        console.log('user confirmed signed in');
        break;
      case 'signUp':
        console.log('user signed up');
        break;
      case 'signOut':
        console.log('user signed out');
        break;
      case 'signIn_failure':
        // setSignInError(data.payload.data.message);
        setSignInMsg({ type: 'error', msg: data.payload.data.message, heading: undefined });
        break;
      case 'tokenRefresh':
        console.log('token refresh succeeded');
        break;
      case 'tokenRefresh_failure':
        console.log('token refresh failed');
        setAuthState('login');
        break;
      case 'forgotPassword':
        setResetError(undefined);
        break;
      case 'forgotPasswordSubmit':
        setSignInMsg({ type: 'success', heading: 'Password Changed!', msg: 'Please log in.' });
        setAuthState('login');
        break;
      case 'forgotPassword_failure':
        setResetError(data.payload.data.message);
        break;
      case 'forgotPasswordSubmit_failure':
        setResetSubmitMsg({ type: 'error', msg: data.payload.data.message });
        break;
      case 'completeNewPassword':
        console.log('complete new password');
        break;
      case 'completeNewPassword_failure':
        setInitPasswordChangeError(data.payload.data.message);
        break;
      default:
        console.log('event unknown:');
        console.log(data.payload);
        break;
    }
  };

  Hub.listen('auth', listener);
  console.log('NonSSO:authState:isSessionValid', authState, isSessionValid());

  return (
    <Authenticator.Provider>
      {(isSessionValid() || authState === 'authenticated')
        ? <SonifiApp />
        : <Fragment>
          {authState === 'confirmSignIn' &&
            <Mfa
              dispatch={props.dispatch}
              user={user}
              setUser={setUser}
              mfaError={mfaError}
            />
          }
          {authState === 'resetPassword' &&
            <PasswordReset
              setAuthState={setAuthState}
              resetError={resetError}
              resetSubmitMsg={resetSubmitMsg}
              setResetSubmitMsg={setResetSubmitMsg}
            />
          }
          {authState === 'requireNewPassword' &&
            <NewPasswordRequired
              setAuthState={setAuthState}
              initPasswordChangeError={initPasswordChangeError}
              user={user}
              setUser={setUser}
              dispatch={props.dispatch}
            />
          }
          {(authState === 'login' || authState === 'loggingIn') &&
            <Login
              authState={authState}
              dispatch={props.dispatch}
              setAuthState={setAuthState}
              setUser={setUser}
              signInMsg={signInMsg}
            // signInError={signInError}
            />
          }
        </Fragment>
      }
    </Authenticator.Provider>
  );
};

const mapStateToProps = (state) => ({
  currentLanguage: state.global.currentLanguage,
  user: state.global.user
});

NonSSO.propTypes = {
  currentLanguage: PropTypes.string,
  dispatch: PropTypes.func,
  user: PropTypes.object
};

export default connect(mapStateToProps)(NonSSO);
