import { map } from 'lodash';
import { displaySnackBar } from '../../../actions/globalActions';
import { CHANNEL_CHANGES } from '../../../constants/apiEndpoints';
import { ERROR, READ, SUCCESS } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import { getUserNameFromSession } from '../../../utils';
import { getSitelessObjectPromise, patchSitelessObjectPromise, postSitelessObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

export const resetBatchChannels = () => ({
  type: types.RESET_BATCH_CHANNELS
});

export const getChannelChangesBegin = (reset) => ({
  type: types.GET_CHANNEL_CHANGES_BEGIN,
  reset
});

export const getChannelChangesSuccess = (response) => ({
  type: types.GET_CHANNEL_CHANGES_SUCCESS,
  response
});

export const getChannelChangesFailure = (error) => ({
  type: types.GET_CHANNEL_CHANGES_FAILURE,
  error
});

export function getChannelChanges(ffilter) {
  return (dispatch) => {
    dispatch(getChannelChangesBegin(ffilter.page === 1));
    return getSitelessObjectPromise(CHANNEL_CHANGES, null, ffilter)
      .then((data) => {
        dispatch(getChannelChangesSuccess(data));
        return data;
      }).catch((error) => {
        dispatch(getChannelChangesFailure(error));
        dispatch(displaySnackBar(ERROR, `Unable to get replacement channels - ${error})`));
        return error;
      });
  };
}

export const viewChannelChangeBegin = (channelInfo) => ({
  type: types.VIEW_CHANNEL_BEGIN,
  channelInfo
});

export const viewChannelChangeSuccess = (response, dialogType = READ) => ({
  type: types.VIEW_CHANNEL_SUCCESS,
  response,
  dialogType
});

export const viewChannelChangeFailure = (error) => ({
  type: types.VIEW_CHANNEL_FAILURE,
  error
});

export function viewChannelChange(channelInfo, dialogType) {
  return (dispatch) => {
    dispatch(viewChannelChangeBegin(channelInfo));
    return getSitelessObjectPromise(CHANNEL_CHANGES, channelInfo.id)
      .then((data) => {
        dispatch(viewChannelChangeSuccess(data, dialogType));
        return data;
      }).catch((error) => {
        dispatch(viewChannelChangeFailure(error));
        dispatch(displaySnackBar(ERROR, `Unable to fetch more information for ${channelInfo.target.name} - ${error})`));
        return error;
      });
  };
}

export const saveReplacementBegin = () => ({
  type: types.SAVE_REPLACEMENT_BEGIN
});

export const saveReplacementComplete = () => ({
  type: types.SAVE_REPLACEMENT_COMPLETE
});

export const saveReplacementSuccess = (saveObj) => ({
  type: types.SAVE_REPLACEMENT_SUCCESS,
  saveObj
});

/* eslint-disable camelcase */
export function saveReplacementChannel(channel) {
  return (dispatch) => {
    dispatch(saveReplacementBegin());
    const copyReplacements = JSON.parse(JSON.stringify(channel.replacements));
    const partialStudentDetails = copyReplacements.map((
      {
        affiliate, name, number, source_id, timezone
      }
    ) => ({
      affiliate, name, number, source_id, timezone
    }));
    const saveObj = {
      sites: map(channel.sites.filter((site) => site.found && site.platforms.includes('Marina')), 'id'),
      target: {
        affiliate: channel.targetChannel.affiliate || '',
        name: channel.targetChannel.name,
        number: Number(channel.targetChannel.number),
        source_id: `${channel.targetChannel.id}`,
        timezone: channel.targetChannel.timezone
      },
      replacements: partialStudentDetails,
      username: getUserNameFromSession()
    };

    return postSitelessObjectPromise(CHANNEL_CHANGES, null, saveObj, false)
      .then((data) => {
        if (data.status && !httpSuccess(data.status)) {
          dispatch(saveReplacementComplete());
          dispatch(displaySnackBar(ERROR,
            `Unable to save new channel replacement - ${data.detail})`));
          return false;
        }
        dispatch(displaySnackBar(SUCCESS, `Successfully saved! - ${data}`));
        dispatch(saveReplacementSuccess(saveObj));
        dispatch(saveReplacementComplete());
        return true;
      }).catch((error) => {
        console.log(error);
        dispatch(saveReplacementComplete());
        dispatch(displaySnackBar(ERROR,
          `Unable to save new channel replacement - ${error.detail})`));
        return false;
      });
  };
}

export const stopJobCheck = (channel) => ({
  type: types.STOP_JOB_CHECK,
  channel
});

export const stopJobBegin = () => ({
  type: types.STOP_JOB_BEGIN
});

export const stopJobComplete = () => ({
  type: types.STOP_JOB_COMPLETE
});

export function stopJob(channel) {
  return (dispatch) => {
    dispatch(stopJobBegin());
    return patchSitelessObjectPromise(CHANNEL_CHANGES, channel)
      .then((data) => {
        dispatch(stopJobComplete());
        dispatch(displaySnackBar(SUCCESS, `Successfully stopped job - ${channel}`));
        return data;
      })
      .catch((error) => {
        dispatch(stopJobComplete());
        dispatch(displaySnackBar(ERROR, `Unable to stop job - (${error.detail})`));
        return error;
      });
  };
}
