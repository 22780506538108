// container for Publish Records.  Actual table is within PublishTableBody
import { Event } from '@mui/icons-material';
import { Paper, Table } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { getSystemSchedule, loadSiteHierarchy } from '../../../actions/globalActions';
import { FORM_MODE, FORM_TYPE, NO_PUBLISH_ITEM } from '../../../constants/messages';
import { MESSAGEPUBLISH_EDIT } from '../../../constants/roles';
import SonifiSubHeader from '../../../containers/SonifiSubHeader';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import { buildSortString } from '../../../utils';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import {
    clearPublish, fetchLibraryAll, fetchPublishedMessages, getPublishItem,
    showMessageDialog
} from '../actions/messagingActions';
import PublishTableBody from './PublishTableBody';

const styles = () => ({
  contents: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    border: '1px solid #c6c4c4',
    padding: '15px',
    width: '100%',
  },
  innerContents: {
    width: '100%',
    marginTop: 15,
    border: 'none',
    maxHeight: '85%',
  },
  table: {
    minWidth: 700,
    marginTop: 15,
  },
});

class PublishedMessages extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.props.dispatch(clearPublish());
    this.props.dispatch(fetchLibraryAll());

    this.state = {
      order: 'desc',
      orderBy: 'start_timestamp',
      page: 1
    };
    this.props.dispatch(getSystemSchedule());
    this.props.dispatch(fetchPublishedMessages(buildSortString(this.state), this.props.timezone));
    this.props.dispatch(loadSiteHierarchy());
  }

  handleAddDialog() {
    // on add, no item is selected, so pass in NO_PUBLISH_ITEM constant, so publish dialog knows that it is an add
    this.props.dispatch(getPublishItem(NO_PUBLISH_ITEM));
    this.props.dispatch(showMessageDialog(FORM_TYPE.PUBLISH, FORM_MODE.ADD));
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(clearPublish());
      this.props.dispatch(fetchPublishedMessages(buildSortString(this.state), this.props.timezone));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(fetchPublishedMessages(buildSortString(this.state), this.props.timezone));
    }
  }

  handleWayPointReached() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('Publish Records MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    });
  };

  // This component is just a wrapper for the actual publish messages table.  The
  // only state/events that this manages is the sorting of the columns for the datatable
  render() {
    const {
      classes, errorOccurred, publish, tableHeader, translations, userPermissions
    } = this.props;
    const { order, orderBy } = this.state;
    tableHeader.map((headerOption) => (
      headerOption.sortable = publish.length > 1
    ));

    return (
      <div style={{ display: 'flex' }}>
        <Paper className={classes.contents}>
          <SonifiSubHeader
            header={translations.scheduleTitle}
            onSubmit={this.handleAddDialog.bind(this)}
            showButton={checkForSingleUserPermission(MESSAGEPUBLISH_EDIT, userPermissions)}
            label={translations.scheduleBtn}
            icon={<Event />}
            disabled={errorOccurred}
          />
          <Paper className={classes.innerContents}>
            <Table className={classes.table}>
              <SonifiTableHead
                headColumns={tableHeader}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <PublishTableBody
                onWayPointReached={this.handleWayPointReached.bind(this)}
              />
            </Table>
          </Paper>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errorOccurred: !!state.messaging.publishError,
  library: state.messaging.library,
  libraryMaxPages: state.messaging.library_page_count,
  maxPages: state.messaging.publish_page_count,
  publish: state.messaging.publish,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
  translations: state.messaging.translations.messaging,
  userPermissions: state.global.permissions
});

PublishedMessages.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  errorOccurred: PropTypes.bool,
  library: PropTypes.array,
  maxPages: PropTypes.number,
  publish: PropTypes.array,
  tableHeader: PropTypes.array,
  timezone: PropTypes.string.isRequired,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(PublishedMessages, styles));

