import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { EDIT, ERROR, READ, SUCCESS } from '../../../constants/constants';
import { KEY_STROKE_ROUTER_ROLES } from '../../../constants/roleGroups';
import { KEY_STROKE_ROUTER_EDIT } from '../../../constants/roles';
import { SonifiConfirm, SonifiSpinner } from '../../../containers';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiLockoutModalSpinner from '../../../containers/SonifiLockoutModalSpinner';
import SonifiPopover from '../../../containers/SonifiPopover';
import SonifiSnackBar from '../../../containers/SonifiSnackBar';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../../utils/rolesUtil';
import * as actions from '../actions/icpActions';
import IcpDialog from '../components/IcpDialog';

class IcpGrid extends Component {
  state = {
    order: 'asc',
    orderBy: 'id'
  };

  closeSnack() {
    this.props.dispatch(actions.updateIcpSuccessStatus());
  }

  componentDidMount() {
    this.props.dispatch(actions.fetchIcps());
  }

  onCancel() {
    this.props.dispatch(actions.updateSelectedIcp(-1));
  }

  save() {
    this.props.dispatch(actions.putIcp(this.props.icps[this.props.selectedIcp]));
  }

  handleEditDialog(idx, type) {
    this.setState({ type: 'edit' });
    if (idx === this.props.selectedIcp) {
      this.props.dispatch(actions.updateSelectedIcp(-1));
    } else {
      this.props.dispatch(actions.updateSelectedIcp(idx, type));
    }
  }

  handleDeleteDialog(idx) {
    this.props.dispatch(actions.deleteIcpCheck(idx));
  }

  deleteKSR() {
    const { deleteCheck, dispatch, icps } = this.props;
    dispatch(actions.deleteIcp(icps[deleteCheck].id));
  }

  handleRequestSort = (property) => {
    this.props.dispatch(actions.updateSelectedIcp(-1));
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    }, () => {
      this.props.dispatch(actions.sortIcps(this.state));
    });
  };

  getTableRow(icp, index, canEdit) {
    const { globalTranslations } = this.props;
    return (
      <SonifiPopover
        deleteDisabled={!canEdit}
        deleteFunc={this.handleDeleteDialog.bind(this, index)}
        deleteTitle={globalTranslations.delete}
        editDisabled={!canEdit}
        editFunc={this.handleEditDialog.bind(this, index, EDIT)}
        editTitle={globalTranslations.edit}
        functionOneFunc={this.handleEditDialog.bind(this, index, READ)}
        functionOneTitle={globalTranslations.view}
        hideDelete={!canEdit}
        hideEdit={!canEdit}
        key={index}
        showFunctionOne={true}
        tableRow={
          <Fragment>
            <TableCell component="th" scope="row"> {icp.id} </TableCell>
            <TableCell component="th" scope="row"> {icp.host} </TableCell>
            <TableCell component="th" scope="row"> {icp.number_terminals} </TableCell>
          </Fragment >
        }
      />
    );
  }

  getPageDetails(canEdit) {
    const { order, orderBy } = this.state;
    const { icps, loading, translations } = this.props;

    const tableHeader = [
      { id: 'id', sortable: true, numeric: false, label: `${translations.icpID}` },
      { id: 'host', sortable: true, numeric: false, label: `${translations.host}` },
      { id: 'number_terminals', sortable: true, numeric: true, label: `${translations.numTerminals}` }
    ];

    return (<Table stickyHeader={true} >
      <SonifiTableHead
        headColumns={tableHeader}
        order={order}
        orderBy={orderBy}
        onRequestSort={this.handleRequestSort}
      />
      <TableBody>
        {loading || !icps || icps.length < 1
          ? <TableRow>
            <TableCell colSpan={3}>
              {loading
                ? <SonifiSpinner />
                : <SonifiLabel error label={translations.noIcps} />}
            </TableCell>
          </TableRow>
          : icps.map((icp, index) => (
            this.getTableRow(icp, index, canEdit)
          ))}
      </TableBody>
    </Table>);
  }

  render() {
    const {
      deleteCheck, globalTranslations, message, icps, icpSuccess, saving, translations, userPermissions
    } = this.props;

    if (!checkForAtLeastOneUserPermission(KEY_STROKE_ROUTER_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    const canEdit = checkForSingleUserPermission(KEY_STROKE_ROUTER_EDIT, userPermissions);
    let description = '';
    if (deleteCheck !== -1) {
      description = `${translations.deleteIcpCheck} ${icps[deleteCheck].id} (${icps[deleteCheck].mac_address})?`;
    }

    return (
      <Fragment>
        <SonifiTemplate
          header={translations.title}
          showButton={false}
          pageDetails={this.getPageDetails(canEdit)}
        />
        <IcpDialog
          onCancel={this.onCancel.bind(this)}
          onSubmit={this.save.bind(this)}
        />
        <SonifiLockoutModalSpinner show={saving} />
        <SonifiConfirm
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
          confirmText={description}
          confirmTitle={`${translations.deleteIcp}`}
          dialogOpen={deleteCheck !== -1 && canEdit}
          onCancel={this.handleDeleteDialog.bind(this, -1)}
          onConfirm={this.deleteKSR.bind(this)}
        />
        <SonifiSnackBar message={message} variant={(icpSuccess ? SUCCESS : ERROR)}
          open={message.length > 0} onClose={this.closeSnack.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteCheck: state.icps.deleteCheck,
  globalTranslations: state.global.translations.defaults,
  icps: state.icps.icps,
  icpSuccess: state.icps.success,
  loading: state.icps.loading,
  message: state.icps.message,
  saving: state.icps.saving,
  selectedIcp: state.icps.selectedIcp,
  translations: state.icps.translations.grid,
  userPermissions: state.global.permissions
});

IcpGrid.propTypes = {
  deleteCheck: PropTypes.number,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  icps: PropTypes.array,
  icpSuccess: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  rowIndex: PropTypes.number,
  saving: PropTypes.bool,
  selectedIcp: PropTypes.number,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(IcpGrid);
