import {
  NetworkWifi2Bar, Person, SignalWifi0Bar, SignalWifiStatusbar4Bar
} from '@mui/icons-material';
import {
  Box, Card, CardActionArea, CardContent, Grid, Tooltip, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { SonifiLabel } from '../../../../containers';
import { dateToString } from '../../../../utils';

const styles = (theme) => ({
  card: {
    height: '100%',
    color: 'white'
  },
  cardContent: {
    height: '100%',
    padding: 0
  },
  flexDisplay: {
    display: 'flex',
    paddingBottom: '7px'
  },
  icon: {
    position: 'absolute',
    right: '0',
    bottom: '0'
  },
  goodStatus: {
    border: `2px solid ${theme.palette.defaults.green}`,
    backgroundColor: '#f6f6f6'
  },
  badStatus: {
    border: '2px solid #993333',
    backgroundColor: '#f6f6f6'
  },
  typography: {
    wordBreak: 'break-all'
  }
});

/* eslint-disable camelcase */
class TermCard extends Component {
  wifiInfo() {
    const { classes, index, terminals, translations } = this.props;

    const intComms = Math.abs(parseInt(terminals[index].comms, 10));
    let tooltipIcon = <Tooltip title={translations.green}><SignalWifiStatusbar4Bar /></Tooltip>;
    if (intComms <= 100 && intComms >= 71) {
      tooltipIcon = <Tooltip title={translations.red}><SignalWifi0Bar /></Tooltip>;
    } else if (intComms <= 70 && intComms >= 66) {
      tooltipIcon = <Tooltip title={translations.yellow}><NetworkWifi2Bar /></Tooltip>;
    }
    return <Box className={classes.flexDisplay}>
      {tooltipIcon}<SonifiLabel noPadding label={`${terminals[index].comms} dBm`} />
    </Box>;
  }

  render() {
    const {
      classes, terminals, index, timezone, translations
    } = this.props;
    const {
      address, comms, last_comms, location, occupied, room, passive_status, terminal_type
    } = terminals[index];

    const commsClass = passive_status === 'good' ? classes.goodStatus : classes.badStatus;
    const textColor = passive_status === 'good' ? 'text.secondary' : 'white';

    return (
      <Card className={`${classes.card} ${commsClass}`} >
        <CardActionArea className={classes.cardContent}>
          <CardContent className={classes.cardContent}>
            <Grid container className={classes.typography}>
              <Grid item xs={12} backgroundColor={passive_status === 'good' ? '' : '#993333'}
                padding="16px 0px 5px 16px">
                <Typography sx={{ fontWeight: 'bold' }} color={textColor}>
                  {room ? room : translations.unassigned}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft="16px">
                <Typography color="text.secondary">{address}</Typography>
              </Grid>
              <Grid item xs={12} paddingLeft="16px">
                <Typography color="text.secondary">{location ? location : <span>&#8203;</span>}</Typography>
              </Grid>
              <Grid item xs={12} paddingLeft="16px">
                <Typography color="text.secondary">
                  {last_comms ? dateToString(last_comms, timezone) : <span>&#8203;</span>}
                </Typography>
              </Grid>
              <Grid item xs={12} paddingLeft="16px">
                <Typography color="text.secondary">{terminal_type.toUpperCase()}</Typography>
              </Grid>
              <Grid item xs={11} /* padding="0 0 30px 16px"*/ paddingLeft="16px">
                {terminal_type === 'rf' && <Typography color="text.secondary">{comms}</Typography>}
                {terminal_type === 'wifi' &&
                  <Typography color="text.secondary">{this.wifiInfo()}</Typography>}
                {terminal_type !== 'rf' && terminal_type !== 'wifi' && <span>&#8203;</span>}
              </Grid>
              <Grid item xs={1}>
                <Typography color="text.secondary" className={classes.icon}>
                  {occupied && <Tooltip title="Occupied"><Person /></Tooltip>}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  terminals: state.termComm.terminals,
  translations: state.termGrid.translations.terminalLabel,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
  userPermissions: state.global.permissions,
});

TermCard.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  terminals: PropTypes.array,
  index: PropTypes.number.isRequired,
  translations: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(TermCard, styles, { withTheme: true }));
