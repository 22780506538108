import { CheckCircleOutline, ErrorOutline, HelpOutline } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { ModalSectionHeader, SonifiError, SonifiSpinner, SonifiTooltip } from '../../../containers';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiText from '../../../containers/SonifiText';
import { getTerminal } from '../actions/terminalActions';
import { VIEW } from '../constants/Constants';
import ConfigCard from './ConfigCard';
import ConfigEdit from './ConfigEdit';
import DetailsCard from './DetailsCard';
import LocationCard from './LocationCard';
import OtherInfoCard from './OtherInfoCard';
import StatusCard from './StatusCard';

const styles = (theme) => ({
  card: {
    border: 'solid',
    borderWidth: 'thin',
    borderColor: theme.palette.primary.light,
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingBottom: 0,
    paddingRight: 0,
    marginBottom: theme.spacing(2),
  },

  statusCard: {
    backgroundColor: theme.palette.primary.light,
    paddingTop: 0,
    paddingLeft: 0,
    paddingBottom: 0,
    paddingRight: 0,
    marginBottom: theme.spacing(2),
  },
  smallCard: {
    borderRight: '1px solid',
    backgroundColor: theme.palette.primary.light,
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    paddingBottom: 0,
    paddingRight: theme.spacing(1),
  },
  noBorderCard: {
    backgroundColor: theme.palette.primary.light,
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    paddingBottom: 0,
    paddingRight: theme.spacing(1),
  },

  cardContent: {
    display: 'flex',
    flexGrow: 1,
    minHeight: '1vw',
  },
  goodStatus: {
    color: 'green !important',
    float: 'right',
  },
  badStatus: {
    color: 'red !important',
    float: 'right',
  },
  infoButton: {
    color: theme.palette.secondary.main
  },
  section: {
    width: '100%'
  }
});

class TerminalLayout extends Component {
  constructor(props) {
    super(props);
    this.getItem = this.getItem.bind(this);
    this.getStatusItem = this.getStatusItem.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getTerminal(this.props.selectedTerm.id));
  }

  getItem(label, value) {
    return value !== null
      ? <Grid item xs={6}>
        <SonifiText
          label={label}
          defaultValue={value}
          size="percent"
          disabled={true}
        />
      </Grid>
      : <Fragment />;
  }

  /* eslint-disable max-params */
  getStatusItem(label, value, status, actualValue, lastItem, tooltipTitle) {
    const { classes } = this.props;
    return <Grid item xs={2} className={lastItem ? classes.noBorderCard : classes.smallCard}>
      <Grid item xs={12} className={classes.cardContent} height="65%">
        <SonifiLabel boldLabel={label} />
        <SonifiTooltip title={tooltipTitle} icon={<HelpOutline fontSize="small" className={classes.infoButton}/>} />
      </Grid>
      <Grid container className={classes.cardContent}>
        <Grid item xs={10}>
          <SonifiLabel label={actualValue === null ? 'No Data' : `${value}`} />
        </Grid>
        {actualValue === null
          ? ''
          : <Grid item xs={2}>
            {status
              ? <Tooltip title="Good"><CheckCircleOutline className={classes.goodStatus} /></Tooltip>
              : <Tooltip title="Bad"><ErrorOutline className={classes.badStatus} /></Tooltip>}
          </Grid>}
      </Grid>
    </Grid>;
  }

  render() {
    const {
      dialogLoading, errorMsg, translations, classes, type
    } = this.props;
    if (dialogLoading) {
      return <SonifiSpinner />;
    }

    if (errorMsg) {
      return <SonifiError label={errorMsg} />;
    }

    return (
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item xs={12}>
          <ModalSectionHeader label={translations.title} />
          <StatusCard helperClass={classes} getStatusItem={this.getStatusItem} />
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item className={classes.section}>
              <ModalSectionHeader label={translations.location} />
              <LocationCard helperClass={classes} getItem={this.getItem} />
            </Grid>
            <Grid item className={classes.section}>
              <ModalSectionHeader label={translations.configuration} />
              {type === VIEW
                ? <ConfigCard helperClass={classes} getItem={this.getItem} />
                : <ConfigEdit helperClass={classes} />}
            </Grid>
            <Grid item className={classes.section}>
              <ModalSectionHeader label={translations.details} />
              <DetailsCard helperClass={classes} getItem={this.getItem} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.section}>
          <ModalSectionHeader label={translations.other} />
          <OtherInfoCard helperClass={classes} getItem={this.getItem} />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  dialogLoading: state.termGrid.dialogLoading,
  errorMsg: state.termGrid.errorMsg,
  selectedTerm: state.termGrid.selectedTerm,
  translations: state.termGrid.translations.terminalEdit,
  type: state.termGrid.type,
});

TerminalLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogLoading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func,
  errorMsg: PropTypes.string,
  selectedTerm: PropTypes.object,
  translations: PropTypes.object,
  type: PropTypes.string,
};

export default connect(mapStateToProps)(withStyles(TerminalLayout, styles, { withTheme: true }));
