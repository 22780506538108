import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { LINEUP_EDIT, LINEUP_LIMITED } from '../../../constants/roles';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../../utils/rolesUtil';
import Channels from './Channels';
import LineupSelector from './LineupSelector';
import LineupViewer from './LineupViewer';

export default class ChannelLineupForm extends Component {
  /* eslint-disable camelcase*/
  render() {
    const {
      errors, lineupInfo, userPermissions, handleChange
    } = this.props;

    const {
      power_on_ipg_source_id
    } = lineupInfo;

    return (
      <Grid container spacing={0} direction="row" style={{ height: '100%' }}>
        <Grid item xs={12}>
          {checkForSingleUserPermission(LINEUP_EDIT, userPermissions)
            ? <LineupSelector
              errors={errors}
              lineupInfo={lineupInfo}
              handleChange={handleChange}
              // TODO: This should NOT be rebound to this class here!!!!
              onCheckboxChange={this.props.onCheckboxChange.bind(this)} />
            : <LineupViewer lineupInfo={lineupInfo} />}
        </Grid>
        <Grid item xs={12} style={{ height: 'calc(100% - 194px)' }}>
          <Channels
            disabled={!checkForAtLeastOneUserPermission([LINEUP_EDIT, LINEUP_LIMITED], userPermissions)}
            errors={errors}
            lineupInfo={lineupInfo}
            handleRadioChange={this.props.handleRadioChange.bind(this)}
            power_on_ipg_source_id={power_on_ipg_source_id}
            handleChannelChange={this.props.handleChannelChange} />
        </Grid>
      </Grid>
    );
  }
}

ChannelLineupForm.propTypes = {
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleChannelChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  lineupInfo: PropTypes.object,
  onCheckboxChange: PropTypes.func,
  userPermissions: PropTypes.array
};
