import {
  Table, TableBody
} from '@mui/material';
import { findIndex } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SONIFI_ADMIN } from '../../../constants/roles';
import { SonifiSpinner, SonifiTableHead } from '../../../containers';
import SonifiSection from '../../../containers/SonifiSection';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import HostRow from './HostRow';

class ManualWindow extends Component {
  state = {
    currentProducts: {},
    hosts: null,
    loading: true,
    selected: {}
  };

  flattenProducts = (productParents) => {
    const flatten = (prev, curr) => ({ ...prev, [curr.id]: curr.version });
    return productParents.reduce(flatten, {});
  };

  componentDidMount() {
    const { productParents, schedHosts } = this.props;
    const currentProducts = this.flattenProducts(productParents);

    this.setState({
      hosts: schedHosts, currentProducts, loading: true
    }, () => {
      const softwareData = [];
      if (schedHosts) {
        const vmcs = Object.keys(schedHosts).map((key) => (key));
        let pp = null;
        for (let i = 0; i < vmcs.length; i++) {
          pp = schedHosts[vmcs[i]]['product-parents'];
          for (let j = 0; j < pp.length; j++) {
            const idx = findIndex(softwareData, ['id', pp[j].id]);
            if (idx === -1) {
              softwareData.push({ hosts: `${vmcs[i]}`, ...pp[j] });
            } else {
              softwareData[idx].hosts += `, ${vmcs[i]}`;
            }
          }
        }
      }
      this.setState({ data: softwareData, loading: false });
    });
  }

  renderModalSection() {
    const { data, hosts, loading } = this.state;
    const { translations, upgradeWindow, userPermissions } = this.props;

    if (!hosts) {
      return <div />;
    } else if (loading) {
      return <SonifiSpinner />;
    }

    const tableHeader = [
      { id: 'id', sortable: false, numeric: false, label: translations.labels.productLabel },
      { id: 'hosts', sortable: false, numeric: false, label: translations.labels.hostLabel },
      { id: 'version', sortable: false, numeric: false, label: translations.labels.currentLabel },
      { id: 'assigned', sortable: false, numeric: false, label: translations.labels.assignedLabel }
    ];

    const manualMode = (!loading && upgradeWindow && upgradeWindow['update-mode'] !== 'automatic' &&
      checkForSingleUserPermission(SONIFI_ADMIN, userPermissions));
    return (
      <Table id="ManualWindow" size="small">
        <SonifiTableHead headColumns={tableHeader} />
        <TableBody>
          {data.map((item, i) => (
            <HostRow key={i} canEdit={manualMode} host={item} />
          ))}
        </TableBody>
      </Table>);
  }

  render() {
    const { translations } = this.props;
    return (<SonifiSection title={translations.labels.sectionLabel} body={this.renderModalSection()} allPadding />
    );
  }
}

const mapStateToProps = (state) => ({
  productParents: state.deployments.productParents,
  translations: state.systemManagement.translations,
  upgradeWindow: state.systemManagement.upgradeWindow,
  userPermissions: state.global.permissions
});

ManualWindow.propTypes = {
  dispatch: PropTypes.func,
  productParents: PropTypes.array,
  schedHosts: PropTypes.object,
  translations: PropTypes.object,
  upgradeWindow: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(ManualWindow);
