// export const ADD_AVAILABLE_LOCATION = 'ADD_AVAILABLE_LOCATION';
// export const ADD_TERM_LOCATION = 'ADD_TERM_LOCATION';
export const CHANGE_VARIABLE = 'CHANGE_VARIABLE';
export const CLOSE_SNACK_BAR = 'CLOSE_SNACK_BAR';

// export const DELETE_AVAILABLE_LOCATION = 'DELETE_AVAILABLE_LOCATION';
export const DELETE_CHECK = 'DELETE_CHECK';

// export const DELETE_TERM_LOCATION = 'DELETE_TERM_LOCATION';
// export const EDIT_SELECTED_ITEM = 'EDIT_SELECTED_ITEM';
// export const EDIT_SELECTED_TERM_LOCATION = 'EDIT_SELECTED_TERM_LOCATION';
export const FETCH_CHANNEL_OPTIONS_BEGIN = 'FETCH_CHANNEL_OPTIONS_BEGIN';
export const FETCH_CHANNEL_OPTIONS_FAILURE = 'FETCH_CHANNEL_OPTIONS_FAILURE';
export const FETCH_CHANNEL_OPTIONS_SUCCESS = 'FETCH_CHANNEL_OPTIONS_SUCCESS';
export const FETCH_ROOM_OPTIONS_CONFIG_BEGIN = 'FETCH_ROOM_OPTIONS_CONFIG_BEGIN';
export const FETCH_ROOM_OPTIONS_CONFIG_FAILURE = 'FETCH_ROOM_OPTIONS_CONFIG_FAILURE';
export const FETCH_ROOM_OPTIONS_CONFIG_SUCCESS = 'FETCH_ROOM_OPTIONS_CONFIG_SUCCESS';
export const FETCH_SITE_CONFIG_BEGIN = 'FETCH_SITE_CONFIG_BEGIN';
export const FETCH_SITE_CONFIG_FAILURE = 'FETCH_SITE_CONFIG_FAILURE';
export const FETCH_SITE_CONFIG_SUCCESS = 'FETCH_SITE_CONFIG_SUCCESS';
export const RESET_GLOBAL_SNACKBAR = 'RESET_GLOBAL_SNACKBAR';
export const RESET_HIERARCHY_VARS = 'RESET_HIERARCHY_VARS';
export const SAVE_IP_NETWORK = 'SAVE_IP_NETWORK';
export const SAVE_IP_NETWORK_BEGIN = 'SAVE_IP_NETWORK_BEGIN';
export const SAVE_IP_NETWORK_FAILURE = 'SAVE_IP_NETWORK_FAILURE';
export const SAVE_IP_NETWORK_SUCCESS = 'SAVE_IP_NETWORK_SUCCESS';
export const SAVE_ROOM_OPTIONS = 'SAVE_ROOM_OPTIONS';
export const SAVE_ROOM_OPTIONS_FAILURE = 'SAVE_ROOM_OPTIONS_FAILURE';
export const SAVE_ROOM_OPTIONS_SUCCESS = 'SAVE_ROOM_OPTIONS_SUCCESS';

// export const SET_DROPPED_ITEM_ROW = 'SET_DROPPED_ITEM_ROW';
// export const SET_DROPPED_TERM_LOC_ROW = 'SET_DROPPED_TERM_LOC_ROW';

// export const UPDATE_AVAILABLE_LOCATION = 'UPDATE_AVAILABLE_LOCATION';
export const UPDATE_CHANNEL_OPTIONS = 'UPDATE_CHANNEL_OPTIONS';
export const UPDATE_CHANNEL_OPTIONS_BEGIN = 'UPDATE_CHANNEL_OPTIONS_BEGIN';
export const UPDATE_CHANNEL_OPTIONS_FAILURE = 'UPDATE_CHANNEL_OPTIONS_FAILURE';
export const UPDATE_CHANNEL_OPTIONS_SUCCESS = 'UPDATE_CHANNEL_OPTIONS_SUCCESS';
export const UPDATE_CHROMECAST_PORT_BEGIN = 'UPDATE_CHROMECAST_PORT_BEGIN';
export const UPDATE_CHROMECAST_PORT_FAILURE = 'UPDATE_CHROMECAST_PORT_FAILURE';
export const UPDATE_CHROMECAST_PORT_SUCCESS = 'UPDATE_CHROMECAST_PORT_SUCCESS';
export const UPDATE_IP_NETWORK = 'UPDATE_IP_NETWORK';

// export const UPDATE_ITEM_LOC_SEQUENCE = 'UPDATE_ITEM_LOCATION_SEQUENCE';

// export const UPDATE_NESTED_SITE_DATA = 'UPDATE_NESTED_SITE_DATA';
export const UPDATE_ROOM_TYPES = 'UPDATE_ROOM_TYPES';
export const UPDATE_ROOM_TYPES_BEGIN = 'UPDATE_ROOM_TYPES_BEGIN';
export const UPDATE_ROOM_TYPES_FAILURE = 'UPDATE_ROOM_TYPES_FAILURE';
export const UPDATE_ROOM_TYPES_ERROR = 'UPDATE_ROOM_TYPES_ERROR';
export const UPDATE_ROOM_TYPES_SUCCESS = 'UPDATE_ROOM_TYPES_SUCCESS';

// export const UPDATE_SELECTED_ITEM = 'UPDATE_SELECTED_ITEM';

// export const UPDATE_SELECTED_TERM_LOCATION = 'UPDATE_SELECTED_TERM_LOCATION';
export const UPDATE_SITE_CLIENT_CONFIG_SUCCESS = 'UPDATE_SITE_CLIENT_CONFIG_SUCCESS';
export const UPDATE_SITE_CLIENT_CONFIG_FAILURE = 'UPDATE_SITE_CLIENT_CONFIG_FAILURE';
export const UPDATE_SITE_DATA = 'UPDATE_SITE_DATA';
export const UPDATE_SITE_MANAGEMENT_GENERAL_BEGIN = 'UPDATE_SITE_MANAGEMENT_GENERAL_BEGIN';
export const UPDATE_SITE_MANAGEMENT_GENERAL_END = 'UPDATE_SITE_MANAGEMENT_GENERAL_END';
export const UPDATE_SITE_MANAGEMENT_GENERAL_STATUS = 'UPDATE_SITE_MANAGEMENT_GENERAL_STATUS';
export const UPDATE_SNACK_BAR = 'SITE_M_UPDATE_SNACK_BAR';
export const UPDATE_TERM_LOC_BEGIN = 'UPDATE_TERM_LOC_BEGIN';
export const UPDATE_TERM_LOC_FAILURE = 'UPDATE_TERM_LOC_FAILURE';

// export const UPDATE_TERM_LOC_SEQUENCE = 'UPDATE_TERMINAL_LOCATION_SEQUENCE';
export const UPDATE_TERM_LOC_SUCCESS = 'UPDATE_TERM_LOC_SUCCESS';

// export const UPDATE_TERM_LOCATION = 'UPDATE_TERM_LOCATION';

export const GET_MARINA_MOBILE_BEGIN = 'GET_MARINA_MOBILE_BEGIN';
export const GET_MARINA_MOBILE_SUCCESS = 'GET_MARINA_MOBILE_SUCCESS';
export const GET_MARINA_MOBILE_FAILURE = 'GET_MARINA_MOBILE_FAILURE';
export const UPDATE_MARINA_MOBILE_BEGIN = 'UPDATE_MARINA_MOBILE_BEGIN';
export const UPDATE_MARINA_MOBILE_SUCCESS = 'UPDATE_MARINA_MOBILE_SUCCESS';
export const UPDATE_MARINA_MOBILE_FAILURE = 'UPDATE_MARINA_MOBILE_FAILURE';

export const UPDATE_HL7_CONFIG_BEGIN = 'UPDATE_HL7_CONFIG_BEGIN';
export const UPDATE_HL7_CONFIG_COMPLETE = 'UPDATE_HL7_CONFIG_COMPLETE';
