import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import SonifiSpinner from './SonifiSpinner';

const styles = () => ({});

export class SonifiLockoutModalSpinner extends Component {
  render() {
    return (
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }}
        open={this.props.show}
      >
        <DialogContent style={{ overflow: 'hidden' }} >
          <SonifiSpinner />
        </DialogContent>
      </Dialog>
    );
  }
}

SonifiLockoutModalSpinner.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

export default withStyles(SonifiLockoutModalSpinner, styles);
