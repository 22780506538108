import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiText } from '../../../containers/';
import { disneySource } from '../constants/constants';

class Disney extends Component {
  componentDidMount() {
    // this.props.updateTypeState('timezone', this.props.timezone);
  }

  changeValue = (name) => ({ target: { value } }) => {
    this.props.updateTypeState(name, value);
  };

  render() {
    const { editState, notEditable, translations } = this.props;
    return (
      <Grid item xs={12}>
        <SonifiText
          size="lg"
          label={translations.source}
          defaultValue={editState.source}
          change={this.changeValue('source')}
          disabled={notEditable}
          select={true}
          items={disneySource}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.integrations.translations.disney
});

Disney.propTypes = {
  editState: PropTypes.object,
  notEditable: PropTypes.bool,
  translations: PropTypes.object,
  updateTypeState: PropTypes.func
};

export default connect(mapStateToProps)(Disney);
