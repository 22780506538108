import { TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import moment from 'moment';

const styles = (theme) => ({
  md: {
    marginRight: theme.spacing(2),
    width: '320px',
    height: theme.spacing(10),
    marginBottom: 0
  },
  percent: {
    marginRight: 0,
    width: '95%',
    height: theme.spacing(10),
    marginBottom: 0
  }
});
  

export class SonifiTimePicker extends Component {
  timeChange = (value) => {
    const date = new Date(value);
    // Converts timezone offset in minutes to milliseconds
    const getTimezoneOffset = (val) => val.getTimezoneOffset() * 60000;
    // Time value in milliseconds - timezone offset in milliseconds = utc datetime.
    const utcFromLocal = new Date(date.getTime() - getTimezoneOffset(date));

    const changeDate = moment.utc(utcFromLocal);
    if (value === null || changeDate === null) {
      return;
    }
    this.props.change(changeDate);
  };

  render() {
    const {
      classes, defaultValue, disabled, error, errorText, label, size, popperProps
    } = this.props;
    const showError = ((typeof error === 'undefined') || (error === null)) ? false : error;

    let classSize = '';
    if (size === 'md') {
      classSize = classes.md;
    } else if (size === 'percent') {
      classSize = classes.percent;
    }

    return (
      <TimePicker
        disabled={disabled}
        label={label}
        value={defaultValue}
        onChange={this.timeChange.bind(this)}
        PopperProps={popperProps ?? {}}
        renderInput={(params) => <TextField
          {...params} variant="filled" margin="normal" size={size}
          error={showError} helperText={errorText} className={classSize}
        />}
      />
    );
  }
}

SonifiTimePicker.propTypes = {
  change: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string.isRequired,
  popperProps: PropTypes.object,
  size: PropTypes.string
};

export default withStyles(SonifiTimePicker, styles, { withTheme: true });
