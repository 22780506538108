// DataTable rows of Libary items with popover and event handlers

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid, TableBody, TableCell, TableRow } from '@mui/material';
import Popover from '@mui/material/Popover';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { withStyles } from 'tss-react/mui';
import { FORM_MODE, FORM_TYPE, SOURCE_TYPE } from '../../../constants/messages';
import { MESSAGE_EDIT } from '../../../constants/roles';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiTooltip from '../../../containers/SonifiTooltip';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import {
    cancelDialog, clearSelectedLibraryItem, deleteLibraryItem, setSelectedLibraryItem, showMessageDialog,
    updateDeleteAttempt
} from '../actions/messagingActions';

const DISTANCE_FROM_END = 3; // insert waypoint object 3 items from the end of the list
const styles = (theme) => ({
  hover: {
    // Do not remove
  },
  popOverContainer: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
});

class MessageTableBody extends Component {
  state = {
    deleteDialogOpen: false,
    open: false,
    popOverElement: null,
    popOverHeight: null,
    popOverId: null,
    popOverIsIntegration: false
  };

  // event handlers to call redux methods
  handleViewDialog() {
    this.props.dispatch(showMessageDialog(FORM_TYPE.LIBRARY, FORM_MODE.VIEW));
  }

  handleEditDialog() {
    this.props.dispatch(showMessageDialog(FORM_TYPE.LIBRARY, FORM_MODE.EDIT));
  }

  handleCopyDialog() {
    this.props.dispatch(showMessageDialog(FORM_TYPE.LIBRARY, FORM_MODE.COPY));
  }

  handleDeleteDialog() {
    this.setState({ deleteDialogOpen: true });
  }

  getMoreData() {
    this.props.onWayPointReached();
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('COMPONENTDIDUPDATE MESSAGETABLEBODY');
    // console.log('prevProps', prevProps);
    // console.log('Props', this.props);
    // console.log('prevState', prevState);
    // console.log('State', this.state);

    // if delete or cancel was pressed, close the dialog
    if ((!prevProps.dialogDeleteAttemptComplete && this.props.dialogDeleteAttemptComplete) ||
      (!prevProps.dialogCancelSuccess && this.props.dialogCancelSuccess)) {
      this.props.dispatch(updateDeleteAttempt(false));
      this.setState({
        open: false,
        deleteDialogOpen: false
      });
    }

    // set selected item, or clear selected item depending on open status of popover
    if (prevState.open !== this.state.open) {
      if (this.state.open) {
        this.props.dispatch(setSelectedLibraryItem(this.state.popOverId));
      } else {
        this.props.dispatch(clearSelectedLibraryItem());
      }
    }
  }

  handleLibraryItemDelete = () => {
    console.log('MessageTableBody.js handleLibraryItemDelete', this.props.selectedLibraryItem);
    this.setState({ deleteDialogOpen: false });
    this.props.dispatch(deleteLibraryItem(this.props.selectedLibraryItem));
  };

  verifyCancelDialog = () => {
    this.setState({ deleteDialogOpen: false });
    this.props.dispatch(cancelDialog());
  };

  closeDeleteDialog = () => {
    console.log('MessageTableBody.js closeDeleteDialog');
    this.setState({ deleteDialogOpen: false });
    this.handlePopoverClose(null);
  };

  // check end_timestamp to determine if message has expired
  isIntegration = (item) => (item.source_type === SOURCE_TYPE.INTEGRATION);

  getLibraryItem = (id) => this.props.library.filter(((e) => e.id === id))[0];

  handlePopover = (id) => (event) => {
    const { currentTarget } = event;
    const item = this.getLibraryItem(id);

    this.setState((state) => ({
      popOverId: id,
      popOverElement: currentTarget,
      popOverHeight: currentTarget.clientHeight,
      popOverIsIntegration: this.isIntegration(item),
      open: !state.open
    }));
  };

  handlePopoverClose = (event) => {
    if (event) {
      if (this.state.popOverElement.contains(event.target)) {
        return;
      }
    }

    this.setState({ open: false });
  };

  getToolTip(title, icon, onClick, disabled = false) {
    return (<SonifiTooltip
      onClick={onClick}
      icon={icon}
      title={title}
      disabled={disabled}
    />);
  }

  /* eslint-disable camelcase */
  render() {
    const {
      classes, library, loading, translations, globalTranslations, userPermissions
    } = this.props;
    const {
      open, deleteDialogOpen, popOverElement, popOverHeight, popOverIsIntegration
    } = this.state;

    if (loading && library.length === 0) {
      return (<TableBody className={classes.tableBody}>
        <TableRow>
          <TableCell colSpan={3} >
            <SonifiSpinner />
          </TableCell>
        </TableRow>
      </TableBody>);
    }

    return (<Fragment>
      <SonifiConfirm
        dialogOpen={deleteDialogOpen}
        onConfirm={this.handleLibraryItemDelete}
        onCancel={this.verifyCancelDialog}
        confirmTitle={translations.libraryDelete}
        confirmText={translations.libraryConfirm}
        buttonCancelText={globalTranslations.cancel}
        buttonConfirmText={globalTranslations.delete}
      />
      <Popover
        open={open}
        anchorEl={popOverElement}
        PaperProps={{
          style: { borderRadius: 0 }
        }}
        onClose={this.handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.popOverContainer}
          style={{ height: { popOverHeight } }}
        >
          {this.getToolTip(globalTranslations.view,
            <VisibilityIcon fontSize="small" />, this.handleViewDialog.bind(this))}
          {checkForSingleUserPermission(MESSAGE_EDIT, userPermissions) &&
            <Fragment>
              {this.getToolTip(globalTranslations.edit,
                <EditIcon fontSize="small" />, this.handleEditDialog.bind(this), popOverIsIntegration)}
              {this.getToolTip(globalTranslations.copy,
                <FileCopyIcon fontSize="small" />, this.handleCopyDialog.bind(this))}
              {this.getToolTip(globalTranslations.delete,
                <DeleteIcon fontSize="small" />, this.handleDeleteDialog.bind(this), popOverIsIntegration)}
            </Fragment>
          }
        </Grid>
      </Popover>
      <TableBody className={classes.tableBody}>
        {library.length === 0 &&
          <TableRow>
            <TableCell colSpan={3}>
              <SonifiLabel error label={translations.errors.noLibrary} />
            </TableCell>
          </TableRow>
        }
        {library.map(({ id, source_type, user_name }, i) => (
          <TableRow
            key={id}
            hover={true}
            classes={{ hover: classes.hover }}
            className={classes.tableRow}
            onClick={this.handlePopover(id)}
          >
            <TableCell>{id}
              {i === library.length - DISTANCE_FROM_END && (
                <Waypoint onEnter={() => {
                  console.log('Waypoint reached: ', i);
                  console.log('Total Records: ', library.length);
                  this.getMoreData();
                }}
                />)
              }
            </TableCell>
            <TableCell>{source_type}</TableCell>
            <TableCell>{user_name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dialogCancelSuccess: state.messaging.dialogCancelSuccess,
  dialogDeleteAttemptComplete: state.messaging.dialogDeleteAttemptComplete,
  dialogOpen: state.messaging.dialogOpen,
  dialogSaveAttemptComplete: state.messaging.dialogSaveAttemptComplete,
  globalTranslations: state.global.translations.defaults,
  library: state.messaging.library,
  loading: state.messaging.loading,
  selectedLibraryItem: state.messaging.selectedLibraryItem,
  translations: state.messaging.translations.messaging,
  userPermissions: state.global.permissions
});

MessageTableBody.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogCancelSuccess: PropTypes.bool,
  dialogDeleteAttemptComplete: PropTypes.bool.isRequired,
  dialogOpen: PropTypes.bool,
  dialogSaveAttemptComplete: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.bool,
  globalTranslations: PropTypes.object,
  library: PropTypes.array,
  loading: PropTypes.bool,
  onWayPointReached: PropTypes.func,
  selectedLibraryItem: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(MessageTableBody, styles, { withTheme: true }));

