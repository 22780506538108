import { Add } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { DISTANCE_FROM_END } from '../../../constants/magic';
import { SONIFI_ADMIN } from '../../../constants/roles';
import {
  SonifiConfirm, SonifiLabel, SonifiLockoutModalSpinner, SonifiSnackBar,
  SonifiSpinner, SonifiTableHead
} from '../../../containers';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import {
  addLogo, clearLogos, deleteLogoById, fetchChannelLogos, resetSnackBar,
  startLogos, updateDeleteRow, updateSelectedLogo
} from '../actions/logoActions';
import LogoRow from '../containers/LogoRow';
import LogoDialog from './LogoDialog';

class LogoGrid extends Component {
  state = {
    order: 'asc',
    orderBy: 'raster',
    page: 1
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(startLogos());
    setTimeout(() => {
      dispatch(fetchChannelLogos(this.buildQueryStringObject()));
    }, 200);
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(fetchChannelLogos(this.buildQueryStringObject()));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(fetchChannelLogos(this.buildQueryStringObject()));
    }
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  buildQueryStringObject() {
    return {
      page: this.state.page,
      sort: this.state.order
    };
  }

  handleRequestSort = (property) => {
    const { dispatch } = this.props;
    dispatch(updateSelectedLogo(-1));
    dispatch(clearLogos());
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      page: 1
    });
  };

  confirmDialogConfirmFunc() {
    const { dispatch, deleteLogo } = this.props;
    dispatch(deleteLogoById(deleteLogo.id, (deleteLogo.vector
      ? deleteLogo.vector
      : deleteLogo.raster), this.state.order));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteRow(null));
  }

  addLogo() {
    this.props.dispatch(addLogo());
  }

  closeSnackBar() {
    this.props.dispatch(resetSnackBar());
  }

  getPageDetails() {
    const { order, orderBy, page } = this.state;
    const {
      loadingLogos, logoError, maps, maxPages, sorting, translations, userPermissions,
    } = this.props;

    if (loadingLogos) {
      return <SonifiSpinner />;
    }

    const canEdit = checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);
    const tableHeader = [
      { id: 'image', sortable: false, numeric: false, label: `${translations.image}` },
      { id: 'raster', sortable: !logoError, numeric: false, label: `${translations.name}` },
      { id: 'sources', sortable: false, numeric: false, label: `${translations.assignment}` }
    ];
    return <Table stickyHeader={true}>
      <SonifiTableHead
        headColumns={tableHeader}
        order={order}
        orderBy={orderBy}
        onRequestSort={this.handleRequestSort}
      />
      {sorting
        ? <TableBody>
          <TableRow>
            <TableCell colSpan={3}>
              <SonifiSpinner />
            </TableCell>
          </TableRow>
        </TableBody>
        : <TableBody>
          {(!maps || maps.length === 0)
            ? <TableRow>
              <TableCell colSpan={3}>
                <SonifiLabel error label={translations.errors.noLogos} />
              </TableCell>
            </TableRow>
            : maps.map((option, index) => (
              <Fragment key={`w_${index}`}>
                <LogoRow key={index} rowIndex={index} deleteEnabled={canEdit && !logoError && !loadingLogos} />

                {index === maps.length - DISTANCE_FROM_END && page < maxPages && (
                  <TableRow>
                    <TableCell>
                      <Waypoint onEnter={() => { this.getMoreData(); }} />
                    </TableCell>
                  </TableRow>)
                }
              </Fragment>
            ))}
        </TableBody>}
    </Table>;
  }

  render() {
    const { order } = this.state;
    const {
      deleteLogo, globalTranslations, logoError, popupLoading, loadingLogos,
      saving, selectedLogo, snackBarMessage, snackBarType, translations, userPermissions,
    } = this.props;

    const canEdit = checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);

    if (!canEdit) {
      return <Navigate replace to="/" />;
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={popupLoading || saving} />
        <SonifiConfirm
          dialogOpen={deleteLogo !== null && deleteLogo !== undefined && !popupLoading}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          confirmTitle={translations.deleteDialog.deleteTitle}
          confirmText={(deleteLogo
            ? `${translations.deleteDialog.deleteText} ${(deleteLogo.vector ? deleteLogo.vector : deleteLogo.raster)}?`
            : '')}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
        <SonifiTemplate
          header={translations.title}
          onSubmit={this.addLogo.bind(this)}
          showButton={canEdit && !logoError && !loadingLogos}
          label={globalTranslations.add}
          icon={<Add />}
          pageDetails={this.getPageDetails()}
        />
        {selectedLogo !== -1 && <LogoDialog canEdit={canEdit && !logoError} sort={order} />}
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteLogo: state.logo.deleteLogo,
  globalTranslations: state.global.translations.defaults,
  loadingLogos: state.logo.loadingLogos,
  logoError: state.logo.logoError,
  maps: state.logo.maps,
  maxPages: state.logo.maxPages,
  popupLoading: state.logo.popupLoading,
  saving: state.logo.saving,
  selectedLogo: state.logo.selectedLogo,
  snackBarMessage: state.logo.snackBarMessage,
  snackBarType: state.logo.snackBarType,
  sorting: state.logo.sorting,
  translations: state.logo.translations.grid,
  userPermissions: state.global.permissions
});

LogoGrid.propTypes = {
  deleteLogo: PropTypes.object,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  loadingLogos: PropTypes.bool,
  logoError: PropTypes.bool,
  maps: PropTypes.array,
  maxPages: PropTypes.number,
  popupLoading: PropTypes.bool,
  saving: PropTypes.bool,
  selectedLogo: PropTypes.number,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  sorting: PropTypes.bool,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(LogoGrid);
