export const BATTERY_KEY_THRESHOLD = 2000;
export const CHANNELS = 'CHANNELS';
export const CLEAR = 'Clear';
export const DELETE = 'Delete';
export const FIRMWARE = 'Firmware';
export const LOGGING = 'LOGGING';
export const PUBLIC_KEY = 'PUBLIC_KEY';
export const REBOOT = 'Reboot';
export const RF_COMM_THRESHOLD = 89;
export const RF_THROUGHPUT_THRESHOLD = 95;
export const RSSI_SIGNAL_THRESHOLD = 0;
export const TERMINAL_INFO = 'TERMINAL_INFO';
export const VIEW = 'View';
