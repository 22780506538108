import moment from 'moment';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { NOT_FOUND, NO_LIBRARY_ITEM, NO_PUBLISH_ITEM } from '../../../constants/messages';
import { getTranslationObject } from '../../../utils/index';
import * as actions from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  confirmLoading: false,
  dialogCancelSuccess: false,
  dialogDeleteAttemptComplete: false,
  dialogError: [],
  dialogLoading: false,
  dialogMode: '',
  dialogOpen: false,
  dialogSaveAttemptComplete: false,
  dialogSaving: false,
  library: [],
  libraryError: null,
  live: [],
  liveError: null,
  loading: false,
  publish: [],
  publishError: null,
  publishLoading: false,
  selectedLibraryItem: NO_LIBRARY_ITEM,
  selectedPublishItem: NO_PUBLISH_ITEM,
  translations: {
    reducerErrors: {}
  }
};

// eslint-disable-next-line max-lines-per-function
export default function messagingReducer(state = initialState, action) {
  const transErrors = state.translations.reducerErrors;
  let now = null,
    start = null,
    end = null,
    universalVar = null;

  switch (action.type) {
    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    // used in logic for displaying the spinner
    case actions.UPDATE_SAVE_ATTEMPT: {
      return {
        ...state,
        dialogSaveAttemptComplete: action.saveAttemptComplete
      };
    }

    // used in logic for displaying the spinner
    case actions.UPDATE_DELETE_ATTEMPT: {
      return {
        ...state,
        dialogDeleteAttemptComplete: action.deleteAttemptComplete
      };
    }

    // clearing some variables after a successful action
    case actions.CLEAR_DIALOG_VARIABLES: {
      return {
        ...state,
        dialogLoading: false,
        dialogSaveAttemptComplete: false,
        dialogCancelSuccess: false,
        dialogError: [],
        dialogMode: ''
      };
    }

    // sets dialogCancelSuccess to close the dialog
    case actions.CANCEL_DIALOG: {
      return {
        ...state,
        dialogCancelSuccess: false
      };
    }

    // LIBRARY ACTIONS

    // empty library state.  Called first when creating the library list, and called if
    // orderBy has changed as well
    case actions.CLEAR_LIBRARY: {
      return {
        ...state,
        library: [],
      };
    }

    // sets state for dialog to open
    case actions.SHOW_MESSAGE_DIALOG: {
      return {
        ...state,
        dialogOpen: true,
        dialogLoading: true,
        ...action.payload
      };
    }


    // sets state to close dialog
    case actions.CLOSE_MESSAGE_DIALOG: {
      return {
        ...state,
        dialogOpen: false,
        dialogLoading: false,
        dialogCancelSuccess: true,
        dialogSaving: false
      };
    }

    case actions.FETCH_LIBRARY_BEGIN:
      return {
        ...state,
        loading: true,
        libraryError: null,
      };

    // flag that fetch function is complete
    case actions.FETCH_LIBRARY_ALL_SUCCESS: {
      return {
        ...state,
        libraryError: null,
        loading: false,
        library: action.library,
      };
    }

    // add new messages to the library state.  Will grow with
    // each subsequent api call, unless clearLibary() is called.
    case actions.FETCH_LIBRARY_SUCCESS: {
      const { library } = state;
      return {
        ...state,
        libraryError: null,
        loading: false,
        library: [...library, ...action.payload.messages],
        library_page_count: action.payload.page_count,
        library_page_size: action.payload.page_size,
        library_total_items: action.payload.total_items,
      };
    }

    // error occurred while fetching library, store in libraryError
    case actions.FETCH_LIBRARY_FAILURE:
      return {
        ...state,
        loading: false,
        libraryError: { detail: state.translations.reducerErrors.FETCH_LIBRARY_FAILURE },
        library: []
      };

    case actions.ADD_LIBRARY_ITEM_BEGIN:
      return {
        ...state, dialogSaving: true
      };

    // put newly added item at top of the list.  We could put it where it belongs in the
    // list, but there needs to be more discussion.  We may only have a partial list currently, so
    // how do we determine if the new item would have even been "gotten" yet?  Do we create a new action
    // to sort the items after a get based on the sort order?  The api already does this, so this would be
    // somewhat redundant and unnecessary, as well as a performance hit.
    case actions.ADD_LIBRARY_ITEM_SUCCESS:
      return {
        ...state,
        library: [action.libraryItem, ...state.library],
        dialogSaveAttemptComplete: true
      };


    case actions.ADD_PUBLISH_ITEM_BEGIN:
      return {
        ...state,
        dialogSaving: true
      };

    case actions.ADD_PUBLISH_ITEM_ERROR:
      return {
        ...state,
        dialogSaving: false
      };

    // same logic as ADD_LIBRARY_ITEM_SUCCESS, only with a publish record
    case actions.ADD_PUBLISH_ITEM_SUCCESS:
      now = moment.utc().tz(action.timezone).toDate();
      start = moment.utc(action.publishItem.start_timestamp).tz(action.timezone).toDate();
      end = moment.utc(action.publishItem.end_timestamp).tz(action.timezone).toDate();

      if (!(start <= now && (action.publishItem.end_timestamp === null || now <= end))) {
        universalVar = [action.publishItem, ...state.publish];
      } else {
        universalVar = [...state.publish];
      }

      return {
        ...state,
        publish: universalVar,
        dialogSaveAttemptComplete: true,
        dialogSaving: false
      };

    // library item edit was successful, not replace the old item with the new item in state
    case actions.EDIT_LIBRARY_ITEM_COMPLETE: {
      const pos = state.library.map((e) => e.id).indexOf(action.payload.libraryItem.origMessageId);
      const { library } = state;
      const item = action.payload.libraryItem;
      library[pos] = item;

      return {
        ...state,
        library,
        dialogError: action.payload.dialogError,
        dialogSaveAttemptComplete: true,
      };
    }

    case actions.STOP_PUBLISH_ITEM_SUCCESS:
    case actions.EDIT_PUBLISH_ITEM_SUCCESS: {
      const pos = state.publish.map((e) => e.id).indexOf(action.publishItem.id);
      const { publish } = state;
      if (pos !== NOT_FOUND) {
        // item being stopped may not even exist in the list if it is
        // being stopped from the live messages section.  For example if you stop a live publish, but
        // the publish record was not gotten yet in the bottom table.  This could be solved if we create
        // new methods for only the live messages section, but it is really unneeded.  The live messages list
        // gets updated after an edit anyway, so modifying the state for that list is not necessary.
        publish[pos].start_timestamp = action.publishItem.start_timestamp;
        publish[pos].end_timestamp = action.publishItem.end_timestamp;
      }

      return {
        ...state,
        publish,
        dialogError: [],
        confirmLoading: false,
        dialogSaveAttemptComplete: true,
      };
    }

    // add the error to the dialogError array.
    case actions.ADD_DIALOG_ERROR:
      return {
        ...state,
        confirmLoading: false,
        dialogError: [...state.dialogError, action.error],
        dialogSaving: false
      };

    // removed deleted item from state
    case actions.DELETE_LIBRARY_ITEM_SUCCESS: {
      const library = state.library.filter(((e) => e.id !== action.id));
      return {
        ...state,
        loading: false,
        confirmLoading: false,
        dialogDeleteAttemptComplete: true,
        library,
      };
    }

    // removed deleted item from state
    case actions.DELETE_PUBLISH_ITEM_SUCCESS: {
      const publish = state.publish.filter(((e) => e.id !== action.id));
      return {
        ...state,
        loading: false,
        confirmLoading: false,
        dialogSaveAttemptComplete: true,
        publish,
      };
    }

    // library delete failed, store error
    case actions.DELETE_LIBRARY_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        confirmLoading: false,
        dialogSaveAttemptComplete: true,
        libraryError: { detail: `${transErrors.DELETE_LIBRARY_ITEM_FAILURE} ${action.error}` }
      };

    // publish delete failed, store error
    case actions.DELETE_PUBLISH_ITEM_FAILURE:
      return {
        ...state,
        loading: false,
        confirmLoading: false,
        publishError: { detail: `${state.translations.reducerErrors.DELETE_PUBLISH_ITEM_FAILURE}` }
      };

    // set loading state before deletion, for spinner.
    case actions.DELETE_PUBLISH_ITEM_BEGIN:
    case actions.DELETE_LIBRARY_ITEM_BEGIN:
    case actions.STOP_PUBLISH_ITEM_BEGIN:
      return {
        ...state,
        loading: true,
        confirmLoading: true,
        dialogSaveAttemptComplete: false
      };

    // set loading state before get, for spinner.
    case actions.GET_LIBRARY_ITEM_BEGIN:
      return {
        ...state,
        dialogLoading: true,
      };

    // set loading state after get, and return requested library item
    case actions.GET_LIBRARY_ITEM_SUCCESS:
      return {
        ...state,
        dialogLoading: false,
        libraryItem: action.libraryItem
      };

    // library get failed, return error
    case actions.GET_LIBRARY_ITEM_FAILURE:
      return {
        ...state,
        dialogLoading: false,
        dialogError: { detail: `${state.translations.reducerErrors.GET_LIBRARY_ITEM_FAILURE}` }
      };

    // when popover is shown, set selected item
    case actions.SET_SELECTED_LIBRARY_ITEM:
      return {
        ...state,
        dialogLoading: true,
        selectedLibraryItem: action.id
      };

    // when popover is closed, remove selected item
    case actions.CLEAR_SELECTED_LIBRARY_ITEM:
      return {
        ...state,
        dialogLoading: false,
        selectedLibraryItem: NO_LIBRARY_ITEM,
        libraryItem: null
      };

    // PUBLISH ACTIONS

    // remove publish items from state
    case actions.CLEAR_PUBLISH: {
      return {
        ...state,
        publish: [],
      };
    }

    // remove live message items from state
    case actions.CLEAR_LIVE: {
      return {
        ...state,
        live: [],
      };
    }

    // clear error before get
    case actions.FETCH_LIVE_BEGIN:
      return {
        ...state,
        liveError: null,
        loading: true
      };

    // clear error before get
    case actions.FETCH_PUBLISH_BEGIN:
      return {
        ...state,
        publishError: null,
        publishLoading: true
      };

    // add results of get to the end of the publish array
    case actions.FETCH_PUBLISH_SUCCESS: {
      const { publish } = state;
      const items = action.payload.published_messages;
      const notLiveMessages = [];
      now = moment.utc().tz(action.payload.timezone).toDate();

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        start = moment.utc(item.start_timestamp).tz(action.payload.timezone).toDate();
        end = moment.utc(item.end_timestamp).tz(action.payload.timezone).toDate();
        if (!(start <= now && (item.end_timestamp === null || now <= end))) {
          notLiveMessages.push(item);
        }
      }

      return {
        ...state,
        publishError: null,
        loading: false,
        publish: [...publish, ...notLiveMessages],
        publish_page_count: action.payload.page_count,
        publish_page_size: action.payload.page_size,
        publish_total_items: action.payload.total_items,
        publishLoading: false
      };
    }

    // add results of get to the end of the live messages array
    case actions.FETCH_LIVE_SUCCESS: {
      const { live } = state;
      return {
        ...state,
        liveError: null,
        loading: false,
        live: [...live, ...action.payload.published_messages],
        live_page_count: action.payload.page_count,
        live_page_size: action.payload.page_size,
        live_total_items: action.payload.total_items,
      };
    }

    // get failed, store error.  TODO: standardize error storage, make consistant?
    case actions.FETCH_LIVE_FAILURE:
      return {
        ...state,
        loading: false,
        liveError: { detail: state.translations.reducerErrors.FETCH_LIVE_FAILURE },
        live: []
      };

    // get failed, store error.  TODO: standardize error storage, make consistant?
    case actions.FETCH_PUBLISH_FAILURE:
      return {
        ...state,
        loading: false,
        publishError: { detail: state.translations.reducerErrors.FETCH_PUBLISH_FAILURE },
        publish: [],
        publishLoading: false
      };

    // when row is selected, store the selected item
    case actions.SET_SELECTED_PUBLISH_ITEM:
      return {
        ...state,
        dialogLoading: true,
        selectedPublishItem: action.id
      };

    // when popover closes, remove the selected item
    case actions.CLEAR_SELECTED_PUBLISH_ITEM:
      return {
        ...state,
        dialogLoading: false,
        selectedPublishItem: NO_PUBLISH_ITEM,
        publishItem: null
      };

    // set dialogLoading for spinner
    case actions.GET_PUBLISH_ITEM_BEGIN:
      return {
        ...state,
        dialogLoading: true,
      };

    // get was successful, return item
    case actions.GET_PUBLISH_ITEM_SUCCESS:
      return {
        ...state,
        dialogLoading: false,
        publishItem: action.publishItem
      };

    // get failed, return error
    case actions.GET_PUBLISH_ITEM_FAILURE:
      return {
        ...state,
        dialogLoading: false,
        dialogError: { detail: state.translations.reducerErrors.GET_PUBLISH_ITEM_FAILURE }
      };

    default:
      return state;
  }
}
