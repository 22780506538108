import SearchIcon from '@mui/icons-material/Search';
import {
    Grid, Paper, Table, TableBody,
    TableCell, TableFooter, TableRow
} from '@mui/material';
import {
    debounce, find, findIndex,
    orderBy, uniqBy
} from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import {
    SonifiCheckbox, SonifiLabel, SonifiSpinner,
    SonifiTableHead, SonifiText
} from '../../../containers/';
import { filterSites, getSites, updateSelectedGroup } from '../actions/groupActions';

const styles = () => ({
  table: {
    minWidth: 700,
    maxHeight: '90%',
  }
});

const DISTANCE_FROM_THE_END = 3;

class SiteSelector extends Component {
  state = {
    allowSave: true,
    filter: '',
    limit: 100,

    // order: 'asc',
    // order_by: 'id',
    page: 1,
    selectAllChecked: false
  };

  tableHeads = () => [
    {
      centerHeader: false,
      checkbox: true,
      checkValue: this.state.selectAllChecked,
      disabled: !this.props.canEdit,
      error: this.props.errors,
      id: '',
      label: '',
      noLabel: true,
      numeric: false,
      onChange: this.checkboxChange(),
      sortable: false
    },
    {
      centerHeader: false,
      id: 'id',
      label: this.props.translations.id,
      numeric: false,
      sortable: false
    },
    {
      centerHeader: false,
      id: 'name',
      label: this.props.translations.name,
      numeric: false,
      sortable: false
    }
  ];

  componentDidMount() {
    this.props.dispatch(getSites({ limit: this.state.limit }));
  }

  updateGroupSites(sites) {
    const updateSite = { ...this.props.selectedGroup };
    updateSite.sites = orderBy(sites, 'id', 'asc');
    this.props.dispatch(updateSelectedGroup(updateSite, 'edit'));
  }

  checkboxChange = () => () => {
    console.log(this.state.selectAllChecked);
    if (this.state.selectAllChecked) {
      this.updateGroupSites([]);
      this.setState({ selectAllChecked: false });
    } else {
      const siteIds = [...this.props.selectedGroup.sites];

      for (let i = 0; i < this.props.sites.sites.length; i++) {
        siteIds.push(this.props.sites.sites[i]);
      }

      const uniqueSites = uniqBy(siteIds, (e) => e.id);
      this.updateGroupSites(uniqueSites);
      this.setState({ selectAllChecked: true });
    }
  };

  isSiteInGroup = (siteId) => {
    const { selectedGroup } = this.props;
    if (!selectedGroup.sites || selectedGroup.sites.length === 0) {
      return false;
    }

    const selected = find(selectedGroup.sites, (o) => o.id === siteId);
    return selected !== undefined;
  };

  updateSiteCheck(site) {
    if (!this.props.canEdit) {
      return;
    }

    const tempSites = this.props.selectedGroup.sites;
    const channelIndex = findIndex(tempSites, (o) => o.id === site.id);

    if (channelIndex === -1) {
      tempSites.push(site);
    } else {
      tempSites.splice(channelIndex, 1);
      this.setState({ selectAllChecked: false });
    }

    this.updateGroupSites(tempSites);
  }

  // handleRequestSort = (property, event) => {
  //   const isDesc = this.state.order_by === property && this.state.order === 'desc';

  //   this.setState({
  //     order: isDesc ? 'asc' : 'desc',
  //     order_by: property,
  //     page: 1
  //   });
  // }

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  filterSite = ({ target: { value } }) => {
    this.setState({ filter: value, selectAllChecked: false }, () => {
      this.debouncedLoadMoreData();
    });
  };

  filterData() {
    this.props.dispatch(filterSites('',
      {
        search: encodeURIComponent(this.state.filter), limit: this.state.limit
      }, false));
  }

  handleWayPointReached() {
    if (this.state.page < this.props.maxPages) {
      this.setState({ page: this.state.page + 1, selectAllChecked: false }, () => {
        const filterObj = {
          limit: this.state.limit,
          page: this.state.page
        };

        if (this.state.filter.length > 0) {
          filterObj.filter = encodeURIComponent(this.state.filter);
        }

        this.props.dispatch(getSites(filterObj));
      });
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  render() {
    const {
      canEdit, classes, sites, globalTranslations, filterLoad, translations
    } = this.props;

    // const { order, order_by } = this.state;

    if (!(sites && sites.sites)) {
      return <SonifiSpinner />;
    }

    return (
      <Paper style={{ padding: '10px' }}>
        {(filterLoad)
          ? <SonifiSpinner />
          : <Grid container>
            <Grid container style={{ justifyContent: 'flex-end' }}>
              <Grid item>
                <SonifiText
                  label={globalTranslations.search}
                  defaultValue={this.state.filter}
                  change={this.filterSite}
                  icon={<SearchIcon />}
                  iconPosition={'end'}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Table className={classes.table}>
                <SonifiTableHead
                  headColumns={this.tableHeads()}

                // order={order}
                // orderBy={order_by}
                // onRequestSort={this.handleRequestSort}
                />
                {sites.sites.length === 0
                  ? <TableBody className={classes.tableBody}>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <SonifiLabel error label={translations.errors.noSites} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  : <TableBody className={classes.tableBody}>
                    {sites.sites.map((site, index) => (
                      <TableRow key={index} onClick={this.updateSiteCheck.bind(this, site)}>
                        <TableCell>
                          {index === sites.sites.length - DISTANCE_FROM_THE_END && (
                            <Waypoint onEnter={() => {
                              console.log('Waypoint reached: ', index);
                              console.log('Total Records: ', sites.sites.length);
                              this.handleWayPointReached();
                            }}
                            />)
                          }
                          <SonifiCheckbox
                            noLabel={true}
                            label=""
                            value={this.isSiteInGroup(site.id)}
                            disabled={!canEdit}
                          />
                        </TableCell>
                        <TableCell>{site.id}</TableCell>
                        <TableCell>{site.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>}
                <TableFooter>
                  <TableRow>

                  </TableRow>
                </TableFooter>
              </Table>
            </Grid>
          </Grid>}
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  filterLoad: state.groups.filterLoad,
  globalTranslations: state.global.translations.defaults,
  maxPages: state.groups.maxPages,
  selectedGroup: state.groups.selectedGroup,
  sites: state.groups.sites,
  translations: state.groups.translations.dialog,
});

SiteSelector.propTypes = {
  canEdit: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  errors: PropTypes.bool,
  filterLoad: PropTypes.bool,
  globalTranslations: PropTypes.object,
  maxPages: PropTypes.number,
  onCancel: PropTypes.func,
  selectedGroup: PropTypes.object,
  sites: PropTypes.object,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(withStyles(SiteSelector, styles, { withTheme: true }));
