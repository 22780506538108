import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getErrorText } from '../../../utils/index';
import { removePipe, textHelper } from '../../../utils/textUtil';
import { getCheckBox, getLgTextBox } from '../../../utils/ui';
import InternationalPhone from '../../SignInAuth/containers/InternationalPhone';

class ProfileSettings extends Component {
  state = {
    email: null, family_name: null, given_name: null, group: null, role: null
  };

  componentDidMount() {
    this.setState({
      ...this.props.currentUser
    }, () => {
      if (this.state.phone_number) {
        this.updatePhoneView(this.state.phone_number);
      } else {
        this.props.updateProfile(this.state);
      }
    });
  }

  updateProfile = (name) => ({ target: { value } }) => {
    if (!textHelper(value)) {
      this.setState({
        [name]: removePipe(value)
      }, () => {
        this.props.updateProfile(this.state);
      });
    }
  };

  updatePhoneView(value, countryInfo) {
    this.setState({
      country: countryInfo,
      phone_number: removePipe(value)
    }, () => {
      this.props.updateProfile(this.state);
    });
  }

  getText(label, value, disabled, name) {
    return (<Grid item>
      {getLgTextBox(
        label, value, name, (!disabled ? this.updateProfile(name) : null),
        getErrorText(name, this.props.errors),
        !disabled, 'string', 'md'
      )}
    </Grid>);
  }

  /* eslint-disable camelcase*/
  render() {
    const {
      email, family_name, given_name, group, phone_number, role
    } = this.state;
    const { currentUser, errors, translations } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            {this.getText(translations.givenName, given_name, false, 'given_name')}
            {this.getText(translations.familyName, family_name, false, 'family_name')}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {this.getText(translations.email, email, true)}
            <Grid item xs={3}>
              {getCheckBox(translations.emailVerified, currentUser.email_verified, null, false)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <InternationalPhone updateProfile={this.updatePhoneView.bind(this)} phoneNumber={phone_number}
              translations={translations} errors={errors} />
            <Grid item xs={3}>
              {getCheckBox(translations.phoneVerified, currentUser.phone_number_verified, null, false)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {this.getText(translations.role, role, true)}
            {this.getText(translations.group, group, true)}
          </Grid>
        </Grid>
      </Grid >
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.global.user,
  translations: state.profile.translations.main
});

ProfileSettings.propTypes = {
  currentUser: PropTypes.object,
  errors: PropTypes.object,
  translations: PropTypes.object,
  updateProfile: PropTypes.func
};

export default connect(mapStateToProps)(ProfileSettings);
