import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiText from '../../../containers/SonifiText';
import { updateSelectedGroup } from '../../GroupManagement/actions/groupActions';
import { deleteUser, updateDeleteRow, updateSelectedUser } from '../actions/userActions';
import EditUser from './EditUser';

class GroupFilter extends Component {
  handleButtonClick = () => {
    const { dispatch, userAssignedSites } = this.props;
    dispatch(updateSelectedUser({ id: null, description: null, sites: userAssignedSites, isNew: true }));
  };

  closeDialog() {
    this.props.dispatch(updateSelectedGroup(null, ''));
    this.props.dispatch(updateSelectedUser(null));
  }

  confirmDialogConfirmFunc() {
    const { dispatch, users, userToDelete, userState } = this.props;
    const otherUsers = users.filter((user) => user.username !== userToDelete.username);
    dispatch(deleteUser(otherUsers, userToDelete, userState));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteRow(null));
  }

  render() {
    const {
      userToDelete, canEdit, filterUser, userState, globalTranslations, selectedUser, translations
    } = this.props;

    return (
      <Fragment>
        <Grid container style={{ justifyContent: 'flex-end' }}>
          <Grid item>
            <SonifiText
              label={globalTranslations.search}
              defaultValue={userState.filter}
              change={filterUser}
              icon={<SearchIcon />}
              iconPosition="end"
              size="mdNoPad"
              helperText={translations.searchHelper}
            />
          </Grid>
        </Grid>

        {selectedUser !== null &&
          <EditUser onCancel={this.closeDialog.bind(this)} userState={userState} canEdit={canEdit} />}
        <SonifiConfirm
          dialogOpen={userToDelete !== null && userToDelete !== undefined}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          confirmTitle={translations.deleteTitle}
          confirmText={`${translations.deleteText} ${(userToDelete
            ? `${userToDelete.given_name} ${userToDelete.family_name}?`
            : '')}`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  selectedUser: state.users.selectedUser,
  translations: state.users.translations.grid,
  users: state.users.users,
  userToDelete: state.users.userToDelete,
  userAssignedSites: state.global.userAssignedSites
});

GroupFilter.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  filterUser: PropTypes.func,
  globalTranslations: PropTypes.object,
  selectedUser: PropTypes.object,
  translations: PropTypes.object,
  users: PropTypes.array,
  userState: PropTypes.object,
  userToDelete: PropTypes.object,
  userAssignedSites: PropTypes.array
};

export default connect(mapStateToProps)(GroupFilter);
