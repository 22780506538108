import { findIndex } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as actions from '../actions/actionTypes';
import { ANALOG_RF, DIGITAL_RF, IPTV } from '../constants/ChannelTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  allowTabChange: true,
  buildingIndex: null,
  changed: false,
  channelDelivery: [],
  channelLineup: 'Lineup 1',
  channelOptionsLoading: false,
  chromecastSaving: false,
  deleteType: '',
  floorIndex: null,
  ipg: null,
  ipNetwork: null,
  ipNetworkSaving: false,
  itemName: null,
  loading: false,
  roomOptionsLoading: false,
  roomTypes: [],
  saving: false,
  savingStatus: null,
  siteConfigLoading: false,
  siteManagementSavingStatus: [],
  snackBar: SUCCESS,
  snackBarMessage: '',
  snackBarMessageTwo: '',
  snackbarOpen: false,
  snackBarTwo: SUCCESS,
  translations: null
};

export default function roleReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CLOSE_SNACK_BAR:
      return {
        ...state,
        snackbarOpen: false,
        snackBarMessage: '',
        snackBarMessageTwo: ''
      };

    case actions.UPDATE_SNACK_BAR:
      return {
        ...state,
        snackbarOpen: !!(action.message && action.message.length > 0),
        snackBarMessage: action.message,
        snackBar: action.snackbarType
      };

    case actions.DELETE_CHECK:
      return {
        ...state,
        deleteType: action.deleteType,
        buildingIndex: action.buildingIndex,
        floorIndex: action.floorIndex,
        itemName: action.itemName,
        snackbarOpen: false
      };

    case actions.FETCH_CHANNEL_OPTIONS_BEGIN:
      return {
        ...state,
        channelDelivery: [],
        channelOptionsLoading: true
      };

    case actions.FETCH_CHANNEL_OPTIONS_SUCCESS: {
      const options = action.channelOptions,
        { inUse } = options;
      return {
        ...state,
        channelDelivery: [
          { name: ANALOG_RF, checked: options.rf.analog, inUse: inUse.rf.analog },
          { name: DIGITAL_RF, checked: options.rf.digital, inUse: inUse.rf.digital },
          { name: IPTV, checked: options.ip, inUse: inUse.ip }
        ],
        ipg: options.ipg,
        channelOptionsLoading: false,
        changed: !state.changed
      };
    }

    case actions.FETCH_CHANNEL_OPTIONS_FAILURE: {
      return {
        ...state,
        snackBar: ERROR,
        snackBarMessage: state.translations.snackbarMessages.channelOptionError,
        snackbarOpen: true
      };
    }

    case actions.FETCH_ROOM_OPTIONS_CONFIG_BEGIN:
      return {
        ...state,
        roomTypes: [],
        roomOptionsLoading: true
      };

    case actions.FETCH_ROOM_OPTIONS_CONFIG_SUCCESS: {
      const options = action.roomOptions;
      return {
        ...state,
        roomTypes: options.room_types,
        roomOptionsLoading: false
      };
    }

    case actions.FETCH_ROOM_OPTIONS_CONFIG_FAILURE: {
      return {
        ...state,
        roomOptionsLoading: false,
        snackBar: ERROR,
        snackBarMessage: `${state.translations.snackbarMessages.failedToLoadRoomOptions} (${action.error})`,
        snackbarOpen: true
      };
    }

    case actions.FETCH_SITE_CONFIG_BEGIN: {
      return {
        ...state,
        siteConfigLoading: true
      };
    }

    case actions.FETCH_SITE_CONFIG_FAILURE: {
      return {
        ...state,
        siteConfigLoading: false,
        snackBar: ERROR,
        snackBarMessage: state.translations.snackbarMessages.inRoomControlsError,
        snackbarOpen: true
      };
    }

    case actions.FETCH_SITE_CONFIG_SUCCESS: {
      return {
        ...state,
        siteConfigLoading: false
      };
    }

    case actions.UPDATE_CHANNEL_OPTIONS: {
      const delivery = [...state.channelDelivery];
      const idx = findIndex(delivery, (o) => o.name === action.name);
      delivery[idx].checked = action.value;

      return {
        ...state,
        channelDelivery: delivery,
        changed: !state.changed
      };
    }

    case actions.UPDATE_SITE_MANAGEMENT_GENERAL_BEGIN:
      return {
        ...state,
        siteManagementSavingStatus: [],
        saving: true
      };
    case actions.UPDATE_SITE_MANAGEMENT_GENERAL_END:
      return {
        ...state,
        changed: !state.changed,
        saving: false
      };

    case actions.UPDATE_SITE_MANAGEMENT_GENERAL_STATUS: {
      return {
        ...state,
        channelOptionsLoading: false,
        changed: !state.changed,
        siteManagementSavingStatus: action.status
      };
    }

    case actions.UPDATE_SITE_DATA:
      return {
        ...state,
        [action.name]: action.value
      };

    case actions.SAVE_ROOM_OPTIONS:
      return { ...state, saving: true, savingStatus: null };

    case actions.SAVE_ROOM_OPTIONS_SUCCESS:
      return {
        ...state,
        saving: false,
        savingStatus: 200,
        snackBar: SUCCESS,
        snackBarMessage: state.translations.snackbarMessages.heirarchySaved,
        snackbarOpen: true
      };

    case actions.SAVE_ROOM_OPTIONS_FAILURE:
      return {
        ...state,
        saving: false,
        savingStatus: action.error.status,
        snackBar: ERROR,
        snackBarMessage: state.translations.snackbarMessages.heirarchyError,
        snackbarOpen: true
      };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    case actions.RESET_HIERARCHY_VARS:
      return { ...state, savingStatus: null, saving: false };

    case actions.UPDATE_IP_NETWORK:
      return { ...state, ipNetwork: { ...action.value } };

    case actions.SAVE_IP_NETWORK_BEGIN:
      return { ...state, ipNetworkSaving: true, snackBarMessageTwo: '' };

    case actions.SAVE_IP_NETWORK_SUCCESS:
      return {
        ...state,
        ipNetworkSaving: false,
        snackbarOpen: true,
        snackBar: SUCCESS,
        snackBarMessage: state.translations.snackbarMessages.ipNetworkSaved,
      };
    case actions.SAVE_IP_NETWORK_FAILURE:
      return {
        ...state,
        ipNetworkSaving: false,
        snackBar: ERROR,
        snackBarMessage: state.translations.snackbarMessages.ipNetworkError,
        snackbarOpen: true
      };

    case actions.UPDATE_CHANNEL_OPTIONS_BEGIN:
      return {
        ...state,
        siteManagementSavingStatus: [],
        snackbarOpen: false,
        snackBarMessageTwo: '',
        saving: true
      };

    case actions.UPDATE_CHANNEL_OPTIONS_FAILURE:
      return {
        ...state,
        snackbarOpen: true,
        snackBarTwo: ERROR,
        snackBarMessageTwo: state.translations.snackbarMessages.channelDeliveryError,
        saving: false
      };

    case actions.UPDATE_CHANNEL_OPTIONS_SUCCESS:
      return {
        ...state,
        snackbarOpen: true,
        snackBarTwo: SUCCESS,
        snackBarMessageTwo: state.translations.snackbarMessages.channelDeliverySuccess,
        saving: false
      };

    case actions.UPDATE_CHROMECAST_PORT_BEGIN:
      return {
        ...state,
        snackbarOpen: false,
        snackBarMessage: '',
        chromecastSaving: true
      };

    case actions.UPDATE_CHROMECAST_PORT_SUCCESS:
      return {
        ...state,
        snackbarOpen: true,
        snackBar: SUCCESS,
        snackBarMessage: state.translations.snackbarMessages.chromecastSuccess,
        chromecastSaving: false
      };

    case actions.UPDATE_CHROMECAST_PORT_FAILURE:
      return {
        ...state,
        snackbarOpen: true,
        snackBar: ERROR,
        snackBarMessage: state.translations.snackbarMessages.chromecastFailure,
        chromecastSaving: false
      };

    case actions.UPDATE_ROOM_TYPES_BEGIN:
      return { ...state, loading: true, snackbarOpen: false, snackBarMessageTwo: '' };

    case actions.UPDATE_ROOM_TYPES_FAILURE:
      return {
        ...state,
        loading: false,
        savingStatus: action.error,
        snackbarOpen: true,
        snackBarTwo: ERROR,
        snackBarMessageTwo: state.translations.snackbarMessages.roomTypesError
      };

    case actions.UPDATE_ROOM_TYPES_ERROR:
      return {
        ...state,
        loading: false,
        snackbarOpen: true,
        snackBarMessage: action.error.message,
        snackBar: ERROR,
      };

    case actions.UPDATE_ROOM_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        snackbarOpen: true,
        snackBarTwo: SUCCESS,
        snackBarMessageTwo: state.translations.snackbarMessages.roomTypesSuccess
      };

    case actions.UPDATE_SITE_CLIENT_CONFIG_SUCCESS:
      return {
        ...state,
        snackbarOpen: true,
        snackBar: SUCCESS,
        snackBarMessage: state.translations.snackbarMessages.inRoomControlConfigSuccess
      };

    case actions.UPDATE_SITE_CLIENT_CONFIG_FAILURE:
      return {
        ...state,
        snackBar: ERROR,
        snackBarMessage: state.translations.snackbarMessages.inRoomControlConfigError,
        snackbarOpen: true
      };

    case actions.UPDATE_TERM_LOC_BEGIN:
      return { ...state, loading: true, snackBarMessageTwo: '' };

    case actions.UPDATE_TERM_LOC_FAILURE:
      return {
        ...state,
        loading: false,
        savingStatus: action.error.status,
        snackbarOpen: true,
        snackBarTwo: ERROR,
        snackBarMessageTwo: state.translations.snackbarMessages.termLocationsError
      };

    case actions.UPDATE_TERM_LOC_SUCCESS:
      return {
        ...state,
        loading: false,
        snackBarMessageTwo: state.translations.snackbarMessages.termLocationSuccess,
        snackbarOpen: true,
        snackBarTwo: SUCCESS
      };

    case actions.UPDATE_MARINA_MOBILE_BEGIN:
      return {
        ...state,
        loading: true
      };

    case actions.UPDATE_MARINA_MOBILE_SUCCESS:
      return {
        ...state,
        loading: false,
        snackBarMessage: state.translations.snackbarMessages.marinaMobileSuccess,
        snackbarOpen: true,
        snackBar: SUCCESS
      };

    case actions.UPDATE_MARINA_MOBILE_FAILURE:
      return {
        ...state,
        loading: false,
        snackBarMessage: state.translations.snackbarMessages.marinaMobileError,
        snackbarOpen: true,
        snackBar: ERROR
      };

    case actions.UPDATE_HL7_CONFIG_BEGIN:
      return {
        ...state,
        saving: true
      };

    case actions.UPDATE_HL7_CONFIG_COMPLETE:
      return {
        ...state,
        saving: false
      };

    case actions.CHANGE_VARIABLE:
      return {
        ...state, allowTabChange: action.changed
      };

    default:
      return state;
  }
}
