import { Dialog, DialogContent, Grid } from '@mui/material';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLockoutModalSpinner, SonifiModalHeader, SonifiSpinner, SonifiText } from '../../../containers/';
import { getErrorText, isError } from '../../../utils/index';
import { fetchSiteConfigs } from '../../SiteManagement/actions/siteManagementActions';
import { addIntegration, validateIntegrationFailure } from '../actions/integrationActions';
import {
  AGILYSYS, availableTypes, defaultAgilysys,
  defaultPmsd, DISNEY, GENERATOR, ip, PMSD, port
} from '../constants/constants';
import Agilysys from '../containers/Agilysys';
import Disney from '../containers/Disney';
import Generator from '../containers/Generator';
import None from '../containers/None';
import Pmsd from '../containers/Pmsd';
import { validIntegration } from '../validator/validator';

class EditIntegration extends Component {
  state = {
    errors: null
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchSiteConfigs())
      .then((data) => {
        this.setState({
          enableSonifiClients: data.enable_sonifi_clients ?? false,
          enableThirdPartyClients: data.enable_third_party_clients ?? false
        });
      });

    if (this.props.selectedIntegration.type === PMSD) {
      const interfaceObject = {
        ...cloneDeep(defaultPmsd), ...cloneDeep(this.props.selectedIntegration)
      };
      interfaceObject.interface = { ...defaultPmsd.interface, ...this.props.selectedIntegration.interface };
      this.setState({ ...interfaceObject });

      // } else if (this.props.selectedIntegration.type === AGILYSYS) {
      //   this.setState({ ...this.props.selectedIntegration, agilysys: { ...defaultAgilysys } });
    } else if (this.props.selectedIntegration.type === GENERATOR) {
      this.setState({ ...this.props.selectedIntegration });
      if (this.props.selectedIntegration.hour === undefined) {
        this.setState({ hour: 11 });
      }
    } else {
      this.setState({ ...this.props.selectedIntegration });
    }
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  onSave = () => {
    console.log('CHRISTINA EditIntegration::onSave');
    const { translations, selectedIntegration } = this.props;
    const tempIntegration = this.state;
    if (selectedIntegration.vendors !== undefined) {
      tempIntegration.vendors = selectedIntegration.vendors;
    }
    console.log('CHRISTINA tempIntegration is: ', tempIntegration);
    this.setState({ ...tempIntegration });
    const validationErrors = validIntegration(this.state, translations.errors);
    this.onSaveContinue(validationErrors);
  };

  onSaveContinue(validationErrors) {
    const { selectedIntegration } = this.props;
    if (Object.entries(validationErrors).length === 0) {
      this.setState({ errors: null });
      const tvControlConfigs = {
        enable_third_party_clients: this.state.enableSonifiClients,
        enable_sonifi_clients: this.state.enableSonifiClients
      };
      this.props.dispatch(addIntegration(selectedIntegration.isNew, this.state, tvControlConfigs));
    } else {
      this.props.dispatch(validateIntegrationFailure(
        `Invalid Configuration: ${Object.keys(validationErrors).join(', ')}`
      ));
      this.setState({ errors: validationErrors });
    }
  }

  getTitle() {
    const { editType, selectedIntegration, translations } = this.props;
    if (editType === 'read') {
      return translations.previewTitle;
    }
    return (selectedIntegration.isNew ? translations.addTitle : translations.editTitle);
  }

  updateIntegrationState = (name) => ({ target: { value } }) => {
    if (value === GENERATOR && !this.state.hour) {
      this.setState({ [name]: value, hour: 11 });
    } else if (value === PMSD) {
      let pmsIssueSolved = false;
      if (!this.state.interface) {
        pmsIssueSolved = true;
        this.setState({ [name]: value, ...cloneDeep(defaultPmsd) });
      }
      if (!this.state.port) {
        pmsIssueSolved = true;
        this.setState({ [name]: value, port: { ...port } });
      }
      if (!this.state.ip) {
        pmsIssueSolved = true;
        this.setState({ [name]: value, ip: { ...ip } });
      }

      if (!pmsIssueSolved) {
        this.setState({ [name]: value });
      }
    } else if (value === AGILYSYS && !this.state.request) {
      this.setState({ [name]: value, ...cloneDeep(defaultAgilysys) });
    } else {
      this.setState({ [name]: value });
    }
  };

  updateTypeState(name, value, extraValue) {
    if (extraValue) {
      const objCopy = { ...this.state[extraValue] };
      objCopy[name] = value;
      this.setState({ [extraValue]: objCopy });
    } else {
      this.setState({ [name]: value });
    }
  }

  getDropDown(label, name, defaultVal, items) {
    const { canEdit, editType } = this.props;
    const { errors } = this.state;

    return (
      <Grid item>
        <SonifiText
          size="lg"
          label={label}
          defaultValue={defaultVal}
          change={this.updateIntegrationState(name)}
          error={isError(name, errors)}
          errorText={getErrorText(name, errors)}
          disabled={!canEdit || editType === 'read'}
          select={true}
          items={items}
        />
      </Grid>
    );
  }

  render() {
    const {
      addingIntegration, canEdit, editType, globalTranslations, loadingIntegration,
      selectedIntegration, translations
    } = this.props;
    const { type } = this.state;
    const notEditable = (!canEdit || editType === 'read');

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={addingIntegration} />
        <Dialog open={true}
          onClose={this.onCancel.bind(this)}
          fullWidth
          maxWidth="lg" >
          <SonifiModalHeader
            header={this.getTitle()}
            onlyClose={notEditable}
            onCancel={this.onCancel.bind(this)}
            onSubmit={this.onSave.bind(this)}
            label={(selectedIntegration.isNew ? globalTranslations.defaults.add : globalTranslations.defaults.save)}
          />
          <DialogContent >
            {loadingIntegration
              ? <SonifiSpinner />
              : <Grid container>
                {/* {this.getDropDown(translations.categoryName, 'category', category, categories)} */}
                {this.getDropDown(translations.typeName, 'type', type, availableTypes)}

                {(type === 'none' || type === null) && <None />}
                {type === PMSD &&
                  <Pmsd
                    updateTypeState={this.updateTypeState.bind(this)}
                    editState={this.state}
                    notEditable={notEditable}
                  />
                }
                {type === AGILYSYS &&
                  <Agilysys
                    updateTypeState={this.updateTypeState.bind(this)}
                    editState={this.state}
                    notEditable={notEditable}
                  />
                }
                {type === DISNEY &&
                  <Disney
                    updateTypeState={this.updateTypeState.bind(this)}
                    editState={this.state}
                    notEditable={notEditable}
                  />
                }
                {type === GENERATOR &&
                  <Generator
                    updateTypeState={this.updateTypeState.bind(this)}
                    editState={this.state}
                    notEditable={notEditable}
                  />
                }
              </Grid>}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  addingIntegration: state.integrations.addingIntegration,
  loadingIntegration: state.integrations.loadingIntegration,
  editType: state.integrations.editType,
  globalTranslations: state.global.translations,
  selectedIntegration: state.integrations.selectedIntegration,
  translations: state.integrations.translations.dialog
});

EditIntegration.propTypes = {
  addingIntegration: PropTypes.bool,
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  globalTranslations: PropTypes.object,
  onCancel: PropTypes.func,
  loadingIntegration: PropTypes.bool,
  selectedIntegration: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(EditIntegration);
