import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SonifiLabel, SonifiTableHead } from '../../../../containers';
import SonifiTemplate from '../../../../containers/SonifiTemplate';
import { getIcon } from '../../utils';

export default class ResultsTable extends Component {
  getHeaderInfo() {
    const { translations } = this.props;
    return [
      {
        id: 'statusIcon', sortable: false, numeric: false, label: '', narrow: true
      },
      { id: 'siteId', sortable: false, numeric: false, label: `${translations.siteId}` },
      { id: 'name', sortable: false, numeric: false, label: `${translations.name}` },
      { id: 'sourceID', sortable: false, numeric: false, label: `${translations.sourceID}` },
      { id: 'notes', sortable: false, numeric: true, label: `${translations.notes}` }
    ];
  }

  getPageDetails() {
    return (
      <Table stickyHeader>
        <SonifiTableHead
          headColumns={this.getHeaderInfo()}
        />
        <TableBody>
          {this.props.results.map((info, index) => (
            <TableRow key={index} >
              <TableCell component="th" scope="row">
                {getIcon(info.status)}
              </TableCell>
              <TableCell component="th" scope="row">
                <SonifiLabel label={info.site} />
              </TableCell>
              <TableCell component="th" scope="row">
                {info.replacement_channel && <SonifiLabel label={`${info.replacement_channel.name}`} />}
              </TableCell>
              <TableCell component="th" scope="row">
                {info.replacement_channel && <SonifiLabel label={`${info.replacement_channel.source_id}`} />}
              </TableCell>
              <TableCell component="th" scope="row">
                <SonifiLabel label={info.error_message} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { translations } = this.props;
    return (
      <SonifiTemplate
        header={translations.title}
        multiple={true}
        paddingBottom={true}
        pageDetails={this.getPageDetails()}
      />
    );
  }
}

ResultsTable.propTypes = {
  results: PropTypes.array,
  translations: PropTypes.object
};
