import { LATE_CHECKOUT_PURCHASES } from '../../../../constants/apiEndpoints';
import { getSiteCsvObjectPromise, getSiteObjectPromise } from '../../../../utils/api';
import * as types from './actionTypes';

export const getPurchaseLogsDataBegin = () => ({
  type: types.GET_PURCHASE_LOGS_DATA_BEGIN
});

export const getPurchaseLogsDataSuccess = (purchases) => ({
  type: types.GET_PURCHASE_LOGS_DATA_SUCCESS,
  purchases
});

export const getPurchaseLogsDataFailure = (error) => ({
  type: types.GET_PURCHASE_LOGS_DATA_FAILURE,
  error
});

export function getPurchaseLogsData(filter) {
  return (dispatch) => {
    dispatch(getPurchaseLogsDataBegin());

    return getSiteObjectPromise(LATE_CHECKOUT_PURCHASES, null, null, filter)
      .then((data) => {
        dispatch(getPurchaseLogsDataSuccess(data));
        return data;
      }).catch((error) => {
        dispatch(getPurchaseLogsDataFailure(error));
        return error;
      });
  };
}

export const resetPurchaseSnack = () => ({
  type: types.RESET_PURCHASE_SNACK
});

export const resetPurchaseLogs = () => ({
  type: types.RESET_PURCHASE_LOGS
});

export const viewLogInfo = (log) => ({
  type: types.VIEW_PURCHASE_LOG,
  log
});

export const exportPurchLogsBegin = () => ({
  type: types.GET_PURCHASE_LOGS_BEGIN
});

export const exportPurchLogsSuccess = () => ({
  type: types.GET_PURCHASE_LOGS_SUCCESS
});

export const exportPurchLogsFailure = (error) => ({
  type: types.GET_PURCHASE_LOGS_FAILURE,
  error
});

export function getPurchaseLogsExport(filter) {
  return (dispatch) => {
    dispatch(exportPurchLogsBegin());
    const fileUrl = `${LATE_CHECKOUT_PURCHASES}`;

    return getSiteCsvObjectPromise(fileUrl, null, filter)
      .then((json) => {
        dispatch(exportPurchLogsSuccess());
        return json;
      })
      .catch((error) => {
        try {
          error.json().then((err) => {
            dispatch(exportPurchLogsFailure(`${err.detail} (${err.status})`));
          });
        } catch(err) {
          dispatch(exportPurchLogsFailure(`${err.detail} (${err.status})`));
        }
        return error;
      });
  };
}
