import { keys, pickBy, uniq } from 'lodash';
import { SONIFI } from '../../../constants/constants';
import { padZeros } from '../../../utils';
import { getFileRegex } from '../../Firmware/utils/validator';
import { LG, SAMSUNG } from '../../TermFiles/constants/TermFileConstants';

export function exampleCsv() {
  let csvText = 'Global TV & Terminal Files Mass Change \r\n';
  csvText += 'LG CPU/PTC/Browser files need a model number \n';
  csvText += '*LG Terminal Client file does not need a model number \n';
  csvText += 'SONIFI Firmware files need a model number \n';
  csvText += 'Samsung Firmware/Clone files need a model number \n';
  csvText += '*Samsung Terminal Client file does not need a model number \n';
  csvText += '\r\nsite Number,model number,LG CPU,LG PTC,LG BROWSER,LG TERMINAL CLIENT, SONIFI FIRMWARE, ';
  csvText += 'SAMSUNG FIRMWARE, SAMSUNG CLONE, SAMSUNG TERMINAL CLIENT';
  return csvText;
}

export function getTableHeaders(translations, termFiles) {
  const constKeyArray = ['siteNum', 'modelNum', 'lgCPU', 'lgPTC', 'lgBrowser', 'lgTermClient', 'sonifiFirmware'];
  constKeyArray.push('samsungFirmware', 'samsungClone', 'samsungTermClient');

  let keysArray = [];
  for (let i = 0; i < termFiles.length; i++) {
    const keyArray = keys(termFiles[i]);
    keysArray.push(...keyArray);
  }
  keysArray = uniq(keysArray.filter((typeOne) => constKeyArray.includes(typeOne)));

  if (keysArray.length < 2) {
    keysArray = constKeyArray;
  } else {
    keysArray.sort((a, b) => constKeyArray.indexOf(a) - constKeyArray.indexOf(b));
  }

  const tableHeader = [{
    id: '', sortable: false, numeric: false, label: '', type: 'label'
  }];
  for (let i = 0; i < keysArray.length; i++) {
    tableHeader.push({
      id: keysArray[i], sortable: false, numeric: false, label: `${translations[keysArray[i]]}`, type: 'label'
    });
  }
  return tableHeader;
}

function checkModelNumber(terminalUpdate, translations) {
  const firmwareNames = (
    terminalUpdate.lgBrowser || terminalUpdate.lgCPU || terminalUpdate.lgPTC ||
    terminalUpdate.samsungClone || terminalUpdate.samsungFirmware ||
    terminalUpdate.sonifiFirmware
  );

  const tempLGTermClient = terminalUpdate.lgTermClient;
  const tempSamsungClient = terminalUpdate.samsungTermClient;

  if (!terminalUpdate.siteNum) {
    terminalUpdate.error = { siteNum: translations.errors.needSiteNum };
    terminalUpdate.toolTip += `${translations.errors.needSiteNum} \n`;
  } else if (Object.keys(terminalUpdate).length < 3) {
    terminalUpdate.error = { siteNum: translations.errors.noEnoughArguments };
    terminalUpdate.toolTip += `${translations.errors.noEnoughArguments} \n`;
  } else if (terminalUpdate.modelNum && !firmwareNames) {
    terminalUpdate.error = { modelNum: translations.errors.firmwareFiles };
    terminalUpdate.toolTip += `${translations.errors.firmwareFiles} \n`;
  } else if (!terminalUpdate.modelNum && firmwareNames) {
    terminalUpdate.error = {
      lgBrowser: translations.errors.modelNum,
      lgCPU: translations.errors.modelNum,
      lgPTC: translations.errors.modelNum,
      samsungClone: translations.errors.modelNum,
      samsungFirmware: translations.errors.modelNum,
      sonifiFirmware: translations.errors.modelNum
    };
    terminalUpdate.toolTip += `${translations.errors.modelNum} \n`;
  } else if (terminalUpdate.modelNum && (tempLGTermClient || tempSamsungClient)) {
    terminalUpdate.error = { modelNum: translations.errors.noModelNeeded };
    terminalUpdate.toolTip += `${translations.errors.noModelNeeded} \n`;
  }
}

function checkFirmwareTypes(terminalUpdate, regex, translations) {
  Object.keys(terminalUpdate).forEach(function(key) {
    if (key === 'modelNum' || key === 'siteNum' || key === 'error' || key === 'toolTip') {
      return;
    }

    const regexCheck = getFileRegex(this[key], regex);
    if (!regexCheck) {
      terminalUpdate.error = {
        ...terminalUpdate.error,
        siteNum: `${translations.errors.noKnownRegex} ${this[key]}`
      };
      terminalUpdate.toolTip += `${key} ${translations.errors.noKnownRegex} ${this[key]} \n`;
    } else if (key.toLowerCase().includes('lg') && regexCheck.make !== LG) {
      terminalUpdate.error = {
        ...terminalUpdate.error,
        [this.key]: translations.errors.lgFileEnding
      };
      terminalUpdate.toolTip += `${key} ${translations.errors.lgFileEnding} \n`;
    } else if (key.toLowerCase().includes('samsung') && regexCheck.make !== SAMSUNG) {
      terminalUpdate.error = {
        ...terminalUpdate.error,
        [this.key]: translations.errors.samsungFileEnding
      };
      terminalUpdate.toolTip += `${key} ${translations.errors.samsungFileEnding} \n`;
    } else if (key.toLowerCase().includes('sonifi') && regexCheck.make !== SONIFI) {
      terminalUpdate.error = {
        ...terminalUpdate.error,
        [this.key]: translations.errors.sonifiFileEnding
      };
      terminalUpdate.toolTip += `${key} ${translations.errors.sonifiFileEnding} \n`;
    }
  }, terminalUpdate);
}

function checkFirmware(terminalUpdate, translations) {
  const tempLG = terminalUpdate.lgBrowser || terminalUpdate.lgCPU || terminalUpdate.lgPTC;
  const tempSamsung = terminalUpdate.samsungClone || terminalUpdate.samsungFirmware;
  const tempSONIFI = terminalUpdate.sonifiFirmware;

  const tempLGTermClient = terminalUpdate.lgTermClient;
  const tempSamsungClient = terminalUpdate.samsungTermClient;

  let SonifiError = '';
  let LgError = '';
  let SamsungError = '';

  if (tempLGTermClient && (tempLG || tempSamsung || tempSONIFI)) {
    LgError = translations.errors.onlyLGTermClient;
    terminalUpdate.toolTip += `${translations.errors.onlyLGTermClient} \n`;
  } else if (tempSamsungClient && (tempLG || tempSamsung || tempSONIFI)) {
    SamsungError = translations.errors.onlySamsungTermClient;
    terminalUpdate.toolTip += `${translations.errors.onlySamsungTermClient} \n`;
  } else if (tempSONIFI) {
    if (tempLG && tempSamsung) {
      LgError = SamsungError = SonifiError = translations.errors.cannotHaveSamsungAndLgAndSonifi;
      terminalUpdate.toolTip += `${translations.errors.cannotHaveSamsungAndLgAndSonifi} \n`;
    } else if (tempSamsung) {
      SamsungError = SonifiError = translations.errors.cannotHaveSamsungAndSonifi;
      terminalUpdate.toolTip += `${translations.errors.cannotHaveSamsungAndSonifi} \n`;
    } else if (tempLG) {
      LgError = SonifiError = translations.errors.cannotHaveLGAndSonifi;
      terminalUpdate.toolTip += `${translations.errors.cannotHaveLGAndSonifi} \n`;
    }
  } else if (tempLG) {
    if (tempSamsung) {
      LgError = SamsungError = translations.errors.cannotHaveSamsungAndLg;
      terminalUpdate.toolTip += `${translations.errors.cannotHaveSamsungAndLg} \n`;
    }
  }

  if (SonifiError !== '') {
    terminalUpdate.error = {
      ...terminalUpdate.error,
      sonifiFirmware: translations.errors.cannotHaveSamsungAndLgAndSonifi
    };
  }

  if (LgError !== '') {
    terminalUpdate.error = {
      ...terminalUpdate.error,
      lgBrowser: LgError,
      lgCPU: LgError,
      lgPTC: LgError,
      lgTermClient: LgError
    };
  }

  if (SamsungError !== '') {
    terminalUpdate.error = {
      ...terminalUpdate.error,
      samsungClone: SamsungError,
      samsungFirmware: SamsungError,
      samsungTermClient: SamsungError
    };
  }
}

export function parseTermInfo(translations, regex, termInfo) {
  const channelInfo = getTermInfoArray(termInfo);
  const termFiles = [];
  const titles = [];
  const configErrors = false;

  if (channelInfo.length < 1) {
    return { termFiles, channelNumberError: true, configErrors: true };
  }

  for (let i = 0; i < channelInfo[0].length; i++) {
    titles.push(channelInfo[0][i].trim().toUpperCase());
  }

  const siteNum = getPlaceInLine(titles, 'Site Number', 'Site Num');
  const modelNum = getPlaceInLine(titles, 'Model Number', 'Model');
  const lgCPU = getPlaceInLine(titles, 'LG CPU', 'CPU');
  const lgPTC = getPlaceInLine(titles, 'LG PTC', 'PTC');
  const lgBrowser = getPlaceInLine(titles, 'LG BROWSER', 'BROWSER');
  const lgTermClient = getPlaceInLine(titles, 'LG Terminal Client', 'LG Term');
  const sonifiFirmware = getPlaceInLine(titles, 'SONIFI Firmware', 'SONIFI');
  const samsungFirmware = getPlaceInLine(titles, 'Samsung Firmware', 'Samsung');
  const samsungClone = getPlaceInLine(titles, 'Samsung Clone', 'Clone');
  const samsungTermClient = getPlaceInLine(titles, 'Samsung Term Client', 'Samsung Terminal Client');

  for (let i = 1; i < channelInfo.length; i++) {
    const completelyEmpty = channelInfo[i].join('');
    if (completelyEmpty.length === 0) {
      continue;
    }

    const terminalUpdate = {
      lgBrowser: channelInfo[i][lgBrowser],
      lgCPU: channelInfo[i][lgCPU],
      lgPTC: channelInfo[i][lgPTC],
      lgTermClient: channelInfo[i][lgTermClient],
      modelNum: channelInfo[i][modelNum],
      samsungClone: channelInfo[i][samsungClone],
      samsungFirmware: channelInfo[i][samsungFirmware],
      samsungTermClient: channelInfo[i][samsungTermClient],
      siteNum: `${padZeros(channelInfo[i][siteNum])}`,
      sonifiFirmware: channelInfo[i][sonifiFirmware],
    };

    const cleanTermInfo = pickBy(terminalUpdate, (value) => !(value === undefined || value === null || value === ''));
    cleanTermInfo.toolTip = '';
    checkModelNumber(cleanTermInfo, translations);
    checkFirmwareTypes(cleanTermInfo, regex, translations);
    checkFirmware(cleanTermInfo, translations);

    if (cleanTermInfo.toolTip === '') {
      delete (cleanTermInfo.toolTip);
    }

    termFiles.push(cleanTermInfo);
  }

  const tHeaders = getTableHeaders(translations, termFiles);
  tHeaders.shift();

  const returnObj = {
    termFiles,
    configErrors,
    tableHeader: getTableHeaders(translations, termFiles),
    columnNames: tHeaders,
  };
  return returnObj;
}

function getTermInfoArray(termInfo) {
  const channelInfo = [...termInfo];
  let findStartOfChannelInfo = true;
  while (findStartOfChannelInfo && channelInfo.length > 0) {
    const chanRow = channelInfo[0].map((i) => i.trim().toLowerCase());
    if (chanRow.includes('site number') &&
      (
        chanRow.includes('model number') || chanRow.includes('model num') ||
        chanRow.includes('lg cpu') || chanRow.includes('lg ptc') ||
        chanRow.includes('lg browser') || chanRow.includes('lg terminal client') ||
        chanRow.includes('sonifi firmware') ||
        chanRow.includes('samsung firmware') || chanRow.includes('samsung clone') ||
        chanRow.includes('samsung terminal client')
      )
    ) {
      findStartOfChannelInfo = false;
    } else {
      channelInfo.shift();
    }
  }
  return channelInfo;
}

function getPlaceInLine(a, first, second) {
  let idx = a.indexOf(first.trim().toUpperCase());
  if (second && idx === -1) {
    idx = a.indexOf(second.trim().toUpperCase());
  }
  return idx;
}
