import {
  CHANNEL_CLONE, CHANNEL_EDIT, CHANNEL_EDIT_LIMITED, CHANNEL_READ, FIRMWARE_EDIT, FIRMWARE_EDIT_LIMITED, FIRMWARE_READ,
  GROUP_EDIT, GROUP_READ, GROUP_SERVICES_EDIT, GROUP_SERVICES_READ, KEY_STROKE_ROUTER_EDIT, KEY_STROKE_ROUTER_READ,
  LATE_CHECKOUT_EDIT, LATE_CHECKOUT_READ, LINEUP_EDIT, LINEUP_LIMITED, LINEUP_READ, MESSAGEPUBLISH_EDIT,
  MESSAGEPUBLISH_READ, MESSAGE_EDIT, MESSAGE_READ, ROLE_EDIT, ROLE_LIMITED, ROLE_READ, ROOM_DETAIL_EDIT,
  ROOM_DETAIL_EDIT_LIMITED, ROOM_DETAIL_READ, ROOM_EDIT, ROOM_READ, SERVER_STREAM_EDIT, SERVER_STREAM_READ, SITE_EDIT,
  SITE_LIMITED, SITE_PROVISION, SITE_READ, SITE_TLX_READ, SITE_TLX_WRITE, SOFTWARE_READ, SOFTWARE_WRITE, SONIFI_ADMIN,
  SONIFI_TECH, SYSTEM_EDIT, SYSTEM_LIMITED, SYSTEM_READ, TERMINAL_DETAIL_EDIT, TERMINAL_DETAIL_EDIT_LIMITED,
  TERMINAL_DETAIL_READ, USERACCOUNT_EDIT, USERACCOUNT_READ, USER_PROFILE_EDIT, USER_PROFILE_READ, USER_TOKEN_READ
} from './roles';

export const GROUP_ROLES = [GROUP_EDIT, GROUP_READ];
export const MESSAGE_ROLES = [MESSAGE_READ, MESSAGE_EDIT];
export const MESSAGEPUBLISH_ROLES = [MESSAGEPUBLISH_READ, MESSAGEPUBLISH_EDIT];
export const ROLES_ROLES = [ROLE_EDIT, ROLE_LIMITED, ROLE_READ];
export const SONIFI_ROLES = [SONIFI_ADMIN, SONIFI_TECH];
export const USER_MANAGEMENT_ROLES = [USERACCOUNT_EDIT, USERACCOUNT_READ];
export const FIRMWARE_ROLES = [FIRMWARE_READ, FIRMWARE_EDIT_LIMITED, FIRMWARE_EDIT];
export const SOFTWARE_ROLES = [SOFTWARE_READ, SOFTWARE_WRITE];
export const SITE_TLX_ROLES = [SITE_TLX_READ, SITE_TLX_WRITE];

export const ADMIN_ROLES = [...USER_MANAGEMENT_ROLES, ...GROUP_ROLES, SITE_PROVISION];
export const ALL_MESSAGE_ROLES = [...MESSAGE_ROLES, ...MESSAGEPUBLISH_ROLES];
export const CHANNEL_ROLES = [CHANNEL_EDIT, CHANNEL_READ, CHANNEL_EDIT_LIMITED, CHANNEL_CLONE];
export const KEY_STROKE_ROUTER_ROLES = [KEY_STROKE_ROUTER_READ, KEY_STROKE_ROUTER_EDIT];
export const LINEUP_ROLES = [LINEUP_EDIT, LINEUP_LIMITED, LINEUP_READ];
export const ROOM_DETAIL_ROLES = [ROOM_DETAIL_READ, ROOM_DETAIL_EDIT, ROOM_DETAIL_EDIT_LIMITED];
export const ROOM_ROLES = [ROOM_READ, ROOM_EDIT];
export const SITE_ROLES = [SITE_EDIT, SITE_READ, SITE_LIMITED];
export const SYSTEM_ROLES = [SYSTEM_READ, SYSTEM_LIMITED, SYSTEM_EDIT];
export const TERMINAL_DETAIL_ROLES = [TERMINAL_DETAIL_READ, TERMINAL_DETAIL_EDIT, TERMINAL_DETAIL_EDIT_LIMITED];
export const USER_ACCOUNT_ROLES = [USERACCOUNT_EDIT, USERACCOUNT_READ];
export const USER_PROFILE_ROLES = [USER_PROFILE_READ, USER_PROFILE_EDIT];
export const USER_TOKEN_ROLES = [USER_TOKEN_READ];
export const TV_TERMINAL_FILE_ROLES = [...FIRMWARE_ROLES, ...SITE_TLX_ROLES];
export const LATE_CHECKOUT_ROLES = [LATE_CHECKOUT_READ, LATE_CHECKOUT_EDIT];
export const FIRMWARE_EDIT_ROLES = [FIRMWARE_EDIT_LIMITED, FIRMWARE_EDIT];
export const GROUP_SERVICES_ROLES = [GROUP_SERVICES_READ, GROUP_SERVICES_EDIT];
export const STREAM_CHANNEL_ROLES = [SERVER_STREAM_EDIT, SERVER_STREAM_READ];

export const ALL_SYSTEM_ROLES = [
  ...SITE_ROLES,
  ...SYSTEM_ROLES,
  ...SONIFI_ROLES,
  ...CHANNEL_ROLES,
  ...KEY_STROKE_ROUTER_ROLES,
  ...ROOM_ROLES,
  ...TERMINAL_DETAIL_ROLES,
  ...TV_TERMINAL_FILE_ROLES,
  ...LATE_CHECKOUT_ROLES
];
