import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { EDIT, ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';
const langs = { en };

const initialState = {
  addingIntegration: false,
  configurePmsRoomTypes: false,
  deleteIntegration: null,
  deletingIntegration: false,
  editType: EDIT,
  hasPms: false,
  integrations: [],
  loading: false,
  loadingIntegration: false,
  selectedIntegration: null,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  translations: null,
};

export default function integrationReducer(state = initialState, action) {
  let tempIntegration = null;
  let tempBool = false;

  switch (action.type) {
    case types.GET_INTEGRATIONS_BEGIN:
      return {
        ...state,
        integrations: [],
        loading: true,
        snackBarMessage: '',
        hasPms: false,
      };

    case types.GET_INTEGRATIONS_SUCCESS:
      tempBool = false;
      if (action.pms && action.pms.type === 'pmsd') {
        if (action.pms.map) {
          const keys = Object.keys(action.pms.map);
          keys.forEach((key, index, arr) => {
            if (action.pms.map[key].name === 'room_type') {
              tempBool = true;
              arr.length = index + 1;
            }
          });
        }
      }

      return {
        ...state,
        configurePmsRoomTypes: tempBool,
        integrations: action.pms ? [action.pms] : [],
        loading: false,
        // TODO: The name hasPms feels a bit misleading.
        hasPms: hasGroupCodeSupport(action.pms),
      };

    case types.GET_INTEGRATIONS_FAILURE:
      return {
        ...state,
        snackBarType: ERROR,
        snackBarMessage: state.translations.errors.getFailure,
        loading: false,
      };

    case types.UPDATE_INTEGRATION_VENDORS:
      tempIntegration = state.selectedIntegration;
      tempIntegration.vendors = action.vendors;
      return {
        ...state,
        selectedIntegration: tempIntegration,
        snackBarMessage: '',
      };

    case types.UPDATE_SELECTED_INTEGRATION:
      return {
        ...state,
        editType: action.editType,
        selectedIntegration: action.integration,
        snackBarMessage: '',
      };

    case types.DELETE_CHECK:
      return {
        ...state,
        deleteIntegration: action.integration,
        snackBarMessage: '',
      };

    case types.ADD_INTEGRATION_BEGIN:
      return { ...state, addingIntegration: true };

    case types.ADD_INTEGRATION_SUCCESS:
      return {
        ...state,
        addingIntegration: false,
        selectedIntegration: null,
        snackBarMessage: action.isNew
          ? `${action.integration.type} ${state.translations.main.addSuccess}`
          : `${state.translations.main.saveSuccess} ${action.integration.type}`,
        snackBarType: SUCCESS,
      };

    case types.ADD_INTEGRATION_FAILURE: {
      return {
        ...state,
        addingIntegration: false,
        snackBarMessage: `${
          action.isNew
            ? state.translations.errors.errorAdding
            : state.translations.errors.errorSaving
        } ${action.error}`,
        snackBarType: ERROR,
      };
    }

    case types.CLEAR_SUCCESS_FAILURE:
      return { ...state, snackBarMessage: '' };

    case types.DELETE_INTEGRATION_BEGIN: {
      return { ...state, deletingIntegration: true };
    }

    case types.DELETE_INTEGRATION_SUCCESS:
      return {
        ...state,
        addingIntegration: false,
        deleteIntegration: null,
        deletingIntegration: false,
        snackBarMessage: `${state.translations.main.deleteSuccess} ${action.id.type}`,
        snackBarType: SUCCESS,
      };

    case types.DELETE_INTEGRATION_FAILURE: {
      return {
        ...state,
        deletingIntegration: false,
        snackBarMessage: `${state.translations.main.deleteFailure} ${action.error}`,
        snackBarType: ERROR,
      };
    }

    case types.VALIDATE_INTEGRATION_FAILURE: {
      return { ...state, snackBarMessage: action.error, snackBarType: ERROR };
    }

    case LOAD_LANGUAGE_LITERALS:
      return {
        ...state,
        translations: getTranslationObject(action.lang, langs),
      };

    default:
      return state;
  }
}

/**
 * Determine whether a PMS has group code support.
 *
 * @param {object} pmsConfig - A PMS configuration object from the API.
 * @param {string} pmsConfig.type - The integration type.
 * @param {object} pmsConfig.interface - An object of interface information.
 * @param {string} pmsConfig.interface.interface_type - The type of interface we have.
 * @param {boolean} pmsConfig.interface.enhanced_flag - Whether or not this is an enhanced PMS.
 *
 * @returns {boolean} True if the PMS has group code support. False otherwise.
 */
export function hasGroupCodeSupport(pmsConfig) {
  let groupCodeSupport = false;

  if (pmsConfig?.type === 'pmsd') {
    const pmsInterface = pmsConfig.interface;

    // HIS pms types may or may not have group code support,
    // depending on the enhanced flag.
    if (
      pmsInterface.interface_type === 'HIS/CLS/NSI'
      && pmsInterface.enhanced_flag
    ) {
      groupCodeSupport = pmsInterface.enhanced_flag;
    }

    // FIAS pms types always support group codes (fias_ip/fias_serial)
    if (pmsInterface.interface_type.startsWith('fias')) {
      groupCodeSupport = true;
    }
  }

  return groupCodeSupport;
}
