import { IPG_FILES } from '../../../constants/apiEndpoints';
import { getIpgGzipObjectPromise, getIpgPromise } from '../../../utils/ipg';
import * as types from './actionTypes';

export const fetchIpgFilesBegin = (resetDownloadInfo) => ({
    type: types.FETCH_IPG_FILES_BEGIN,
    resetDownloadInfo
});

export const fetchIpgFilesFailure = (error) => ({
    type: types.FETCH_IPG_FILES_FAILURE,
    error
});

export const fetchIpgFilesSuccess = (info) => ({
    type: types.FETCH_IPG_FILES_SUCCESS,
    info
});

export const showIpgFiles = (index = -1) => ({
    type: types.SHOW_IPG_FILES,
    index
});

export function fetchIpgFiles(resetDownloadInfo = true) {
  return (dispatch) => {
    dispatch(fetchIpgFilesBegin(resetDownloadInfo));
    return getIpgPromise(IPG_FILES)
      .then((json) => {
        dispatch(fetchIpgFilesSuccess(json.files));
        return json;
      })
      .catch((error) => dispatch(fetchIpgFilesFailure(error)));
  };
}

export const downloadIpgFileBegin = () => ({
  type: types.DOWNLOAD_IPG_FILE_BEGIN,
});

export const downloadIpgFileError = (error) => ({
  type: types.DOWNLOAD_IPG_FILE_ERROR,
  error
});

export function downloadIpgFile(directory, filepath) {
  return (dispatch) => {
    dispatch(downloadIpgFileBegin());

    const resourcePath = `${directory}/files/${filepath}`;

    return getIpgGzipObjectPromise(IPG_FILES, resourcePath)
    .then((json) => json).catch((error) => {
      dispatch(downloadIpgFileError(error));
    });
  };
}
