import { uniqBy } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ADD, ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  addingFirmware: false,
  corrupted: null,
  deleteFirmware: null,
  editType: '',
  fetching: false,
  firmware: [],
  firmwareError: false,
  loading: false,
  maxPages: 0,
  pageSize: 0,
  popupLoading: false,
  regex: null,
  saving: false,
  selectedFirmware: -1,
  selectedChecksum: null,
  selectedUploaded: null,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  totalItems: 0,
  translations: null,
  uploadStarted: null
};

/* eslint-disable max-lines-per-function */
export default function logoReducer(state = initialState, action) {
  const updatedFirmware = [...state.firmware];
  const newArray = [];
  let miscObj = null;

  switch (action.type) {
    case types.START_FIRMWARE:
      return {
        ...state,
        firmware: [],
        firmwareError: false,
        loading: true,
        models: [],
        saving: false,
        selectedFirmware: -1
      };

    case types.ADD_FIRMWARE_BEGIN:
      updatedFirmware.push({
        id: ADD,
        isNew: true,
        md5: '',
        models: [],
        name: '',
        type: 'SONIFI',
      });

      return {
        ...state,
        addingFirmware: true,
        firmware: updatedFirmware,
        selectedFirmware: state.firmware.length,
        snackBarMessage: '',
      };

    case types.ADD_FIRMWARE_SUCCESS:
    case types.ADD_FIRMWARE_FAILURE:
      return {
        ...state,
        addingFirmware: false
      };

    case types.CLEAR_FIRMWARE:
      return {
        ...state,
        firmware: [],
        maxPages: 0,
        models: [],
        pageSize: 0,
        sorting: true,
        totalItems: 0
      };

    case types.FETCH_FIRMWARE_BEGIN:
      return { ...state, selectedFirmware: -1, firmwareError: false };

    case types.FETCH_FIRMWARE_SUCCESS:
      miscObj = [...state.firmware, ...action.firmware.firmware];
      return {
        ...state,
        firmware: uniqBy(miscObj, (e) => e.id),
        firmwareError: false,
        loading: false,
        maxPages: action.firmware.page_count,
        pageSize: action.firmware.page_size,
        saving: false,
        sorting: false,
        totalItems: action.firmware.total_items
      };

    case types.FETCH_FIRMWARE_FAILURE:
      return {
        ...state,
        firmware: [],
        firmwareError: true,
        loading: false,
        saving: false,
        sorting: false
      };

    case types.FETCH_FIRMWARE_FILE_BEGIN:
      return {
        ...state, fetching: true, corrupted: null, selectedChecksum: null, selectedUploaded: null
      };

    case types.FETCH_FIRMWARE_FILE_SUCCESS:
      return {
        ...state,
        fetching: false,
        corrupted: (action.firmware.corrupted ? action.firmware.corrupted : false),
        selectedChecksum: action.firmware.md5,
        selectedUploaded: action.firmware.uploaded
      };

    case types.FETCH_FIRMWARE_FILE_FAILURE:
      return {
        ...state, fetching: false, corrupted: null, selectedChecksum: null, selectedUploaded: null
      };

    case types.UPDATE_SELECTED_FIRMWARE:
      if (action.index === -1) {
        miscObj = updatedFirmware.filter((word) => word.id !== ADD);
      } else {
        miscObj = updatedFirmware;
      }
      return {
        ...state,
        editType: action.editType,
        firmware: miscObj,
        loading: false,
        saving: false,
        selectedFirmware: action.index,
        snackBarMessage: '',
        sorting: false
      };

    case types.DELETE_CHECK:
      return { ...state, deleteFirmware: action.firmware, snackBarMessage: '' };

    case types.DELETE_FIRMWARE_BEGIN:
      return { ...state, saving: true };

    case types.DELETE_FIRMWARE_SUCCESS:
      return {
        ...state,
        deleteFirmware: null,
        saving: false,
        selectedFirmware: -1,
        snackBarMessage: action.showSnackBar
          ? `${state.translations.snackbar.deleteSuccess} ${action.id}`
          : state.snackBarMessage,
        snackBarType: action.showSnackBar ? SUCCESS : state.snackBarType
      };

    case types.DELETE_FIRMWARE_FAILURE:
      return {
        ...state,
        deleteFirmware: null,
        saving: false,
        loading: false,
        selectedFirmware: -1,
        snackBarMessage: `${state.translations.snackbar.deleteError} - ${action.error}`,
        snackBarType: ERROR
      };

    case types.SAVE_FIRMWARE_BEGIN:
      return {
        ...state,
        addingFirmware: true,
        controller: action.controller,
        loading: true,
        snackBarMessage: '',
        uploadStarted: Date.now()
      };

    case types.SAVE_FIRMWARE_SUCCESS:
      return {
        ...state,
        addingFirmware: false,
        controller: null,
        loading: false,
        models: [],
        saving: false,
        selectedFirmware: -1,
        snackBarMessage: `${state.translations.snackbar.saveSuccess} ${action.firmwareName}`,
        snackBarType: SUCCESS,
        uploadStarted: null
      };

    case types.SAVE_FIRMWARE_FAILURE:
      return {
        ...state,
        addingFirmware: false,
        controller: null,
        loading: false,
        saving: false,
        snackBarMessage: `${state.translations.snackbar.saveError} - ${action.error}`,
        snackBarType: ERROR,
        uploadStarted: null
      };

    case types.RESET_SNACKBAR:
      return {
        ...state,
        addingFirmware: false,
        controller: null,
        loading: false,
        saving: false,
        snackBarMessage: action.snackBarMessage,
        snackBarType: action.snackBarType ? action.snackBarType : state.snackBarType,
        uploadStarted: null
      };

    case types.UPDATE_CORRUPTED_FILE:
      for (let i = 0; i < action.data.length; i++) {
        miscObj = updatedFirmware.find((set) => set.id === action.data[i].id);
        if (!miscObj) {
          continue;
        }
        miscObj.corrupted = (!action.firmware[i] ||
          action.firmware[i].corrupted === undefined || action.firmware[i].corrupted === null
          ? true
          : action.firmware[i].corrupted);
        miscObj.uploaded = action.firmware[i].uploaded;
        miscObj.id = action.data[i].id;
        newArray.push(miscObj);
      }
      return {
        ...state,
        firmware: uniqBy([...state.firmware, ...newArray], (e) => e.id),
        fetching: false
      };

    case types.GET_REGEX_BEGIN:
      return { ...state, snackBarMessage: '' };

    case types.GET_REGEX_SUCCESS:
      return { ...state, regex: action.regex };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
