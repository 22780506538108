import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Navigation from '../../containers/Navigation';
import { getSiteNumFromURI } from '../../utils';
import { createLink } from '../../utils/links';
import GroupServices from '../GroupServices';
import HotelDirectory from '../HotelDirectory';
import Library from '../Messaging/library';
import { MessagingDashboard } from '../Messaging/manager';
import { GROUP_SERVICES_ROLES, MESSAGEPUBLISH_ROLES, MESSAGE_ROLES } from './../../constants/roleGroups';
import { checkForAtLeastOneUserPermission } from './../../utils/rolesUtil';

class Product extends Component {
  msg() {
    const { messages } = this.props.translations.product;
    const title = messages.title;
    const items = [];

    if (checkForAtLeastOneUserPermission(MESSAGE_ROLES, this.props.userPermissions)) {
      items.push(createLink(messages.library, 'library', Library));
    }
    if (checkForAtLeastOneUserPermission(MESSAGEPUBLISH_ROLES, this.props.userPermissions)) {
      items.push(createLink(messages.manager, 'manager', MessagingDashboard));
    }
    return { title, items };
  }

  manage() {
    const { site, translations, userPermissions } = this.props;
    const { manage } = translations.product;
    const title = manage.title;
    const items = [];

    if (checkForAtLeastOneUserPermission(GROUP_SERVICES_ROLES, userPermissions) &&
      site.has_group_services) {
      items.push(createLink(manage.groupServices, 'groupServices', GroupServices));
    }
    return { title, items };
  }

  externalLinks() {
    if (this.props.site.has_hotel_directory) {
      const { externalLinks } = this.props.translations.product;
      const title = externalLinks.title;
      const items = [];
      items.push(createLink(externalLinks.portal, 'portal', HotelDirectory));
      return { title, items };
    } else {
      const title = '';
      const items = [];
      return { title, items };
    }
  }

  render() {
    if (getSiteNumFromURI() === null) {
      return <Navigate to="/" />;
    }

    const messageLinks = this.msg();
    const manageLinks = this.manage();
    const externalLink = this.externalLinks();

    const links = [];
    const stackedLinks = [];
    if (messageLinks.items.length > 0) {
      links.push(...messageLinks.items);
      stackedLinks.push(messageLinks);
    }

    if (manageLinks.items.length > 0) {
      links.push(...manageLinks.items);
      stackedLinks.push(manageLinks);
    }

    if (externalLink.items.length > 0) {
      links.push(...externalLink.items);
      stackedLinks.push(externalLink);
    }

    return (
      <Navigation links={links} stackedNav={stackedLinks} />
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  loadingSite: state.global.loadingSite, // Need this to either show or not show group services once API call has been made
  site: state.global.site,
  translations: state.global.translations,
  userPermissions: state.global.permissions
});

Product.propTypes = {
  change: PropTypes.bool,
  site: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(Product);

