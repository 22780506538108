import { REQUESTS, TERM_COMMS_MONITOR } from '../../../../constants/apiEndpoints';
import { getSiteObjectPromise, postObjectPromise } from '../../../../utils/api';
import * as types from './actionTypes';

export const getTermCommsBegin = (loading) => ({
  type: types.GET_TERM_COMMS_BEGIN,
  loading
});

export const getTermCommsSuccess = (response) => ({
  type: types.GET_TERM_COMMS_SUCCESS,
  response
});

export const getTermCommsFailure = (error) => ({
  type: types.GET_TERM_COMMS_FAILURE,
  error
});

export function getTermComms(filter) {
  return (dispatch) => {
    dispatch(getTermCommsBegin(filter.page === 1));
    return getSiteObjectPromise(TERM_COMMS_MONITOR, null, null, filter)
      .then((data) => {
        dispatch(getTermCommsSuccess(data));
        return data;
      }).catch((error) => {
        dispatch(getTermCommsFailure(error));
        return error;
      });
  };
}

export const updateTermCommsBegin = () => ({
  type: types.UPDATE_TERM_COMMS_BEGIN
});

export const updateTermCommsFinish = (response) => ({
  type: types.UPDATE_TERM_COMMS_FINISH,
  response
});

export function updateTermComms(filter) {
  return (dispatch) => {
    dispatch(updateTermCommsBegin());
    return getSiteObjectPromise(TERM_COMMS_MONITOR, null, null,
      { ...filter, page: 1, limit: filter.page * filter.limit })
      .then((data) => {
        dispatch(updateTermCommsFinish(data));
        return data;
      }).catch((error) => {
        dispatch(updateTermCommsFinish(null));
        return error;
      });
  };
}

export const setActivateTimeNow = () => ({
  type: types.SET_ACTIVATE_TIME_NOW
});

export function loadTermCommsPage() {
  return (dispatch) => {
    const submitObj = {
      action: 'adjust-polling-period',
      keys: {
        polling_period: 60,
        duration: 15
      },
      resource: 'terminal-updates'
    };
    dispatch(setActivateTimeNow());
    return postObjectPromise(REQUESTS, submitObj)
      .then((data) => data)
      .catch((error) => error);
  };
}

export function refreshRequests() {
  return () => {
    const submitObj = {
      action: 'manual-refresh',
      resource: 'terminal-updates',
    };
    return postObjectPromise(REQUESTS, submitObj)
      .then((data) => data)
      .catch((error) => error);
  };
}

