import moment from 'moment';

export const MAX_DAYS_PAST_ARRIVAL = 14;

/**
 * Given a group arrival time, get their max departure time.
 *
 * @param {(string|moment.Moment)} arrival - A group arrival time.
 * @returns {moment.Moment} A Date object representing the max departure time
 *     for the given arrival time.
 */
export function getMaxDepartureTime(arrival) {
  return moment(arrival).add(MAX_DAYS_PAST_ARRIVAL, 'days');
}

export function convertGroupsToLocalTime(groups) {
  for (const group of groups) {
    group.arrival = parseIntoLocalTime(group.arrival);
    group.departure = parseIntoLocalTime(group.departure);
  }

  return groups;
}

export function parseIntoLocalTime(datetime) {
  return moment.parseZone(datetime).local().format('YYYY-MM-DDTHH:mm:ssZ');
}
