import { Grid } from '@mui/material';
import { without } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiText from '../../../containers/SonifiText';

export class MessageFormElements extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
      errors: this.props.errors
    };
  }

  componentDidMount() { }

  componentDidUpdate(prevProps) {
    // console.log('COMPONENTDIDUPDATE MESSAGEFORMELEMENTS');
    // console.log('prevProps', prevProps);
    // console.log('Props', this.props);
    // console.log('prevState', prevState);
    // console.log('State', this.state);

    // the only local state is for error handling so that the fields can report the error
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }
  }

  isError(fieldName) {
    if (!this.state.errors) {
      return false;
    }
    const error = this.state.errors.filter((e) => e.fieldName === fieldName);
    return !!error.length;
  }

  getErrorText(fieldName) {
    if (!this.state.errors) {
      return '';
    }
    const error = this.state.errors.filter((e) => e.fieldName === fieldName);
    return error.length ? error[0].message : '';
  }

  // pass changes to the onChange event that is passed into this component
  handleOnChange = (name) => (e) => this.props.onChange(name, e.target.value);

  // pass changes to the onTranslationChange event that is passed into this component
  handleOnTranslationChange = (name) => (e) => this.props.onTranslationChange(name, e.target.value);

  render() {
    const {
      availableLanguages, body, language, messageId, nameEditDisabled, nameEditHide, readOnly, translation, title
    } = this.props;

    const nameEdit = !nameEditHide && (
      <SonifiText
        label={translation.name}
        defaultValue={messageId}
        change={this.handleOnChange('messageId')}
        disabled={nameEditDisabled}
        size="md"
        error={this.isError('messageId')}
        errorText={this.getErrorText('messageId')}
      />
    );

    const removeUndefinedLangs = without(availableLanguages, undefined);

    return (
      <Fragment>
        {nameEdit}
        <Grid container>
          <Grid key="1" item>
            {removeUndefinedLangs.length === 1
              ? <SonifiText
                label={translation.language}
                defaultValue={removeUndefinedLangs[0].name}
                size="sm"
                disabled={true}
              />
              : <SonifiText
                label={translation.language}
                defaultValue={language}
                disabled={removeUndefinedLangs.length === 0}
                change={this.handleOnChange('language')}
                items={removeUndefinedLangs.map((suggestion) => ({
                  id: suggestion.sequence,
                  value: suggestion.iso_code_639_1,
                  label: suggestion.name
                }))}
                select={true}
                size="sm"
              />}
          </Grid>
          <Grid key="2" item xs={6}>
            <SonifiText
              label={translation.title}
              disabled={readOnly}
              defaultValue={title}
              change={this.handleOnTranslationChange('title')}
              size="md"
              error={this.isError('title')}
              errorText={this.getErrorText('title')}
            />
            <SonifiText
              label={translation.body}
              disabled={readOnly}
              defaultValue={body}
              change={this.handleOnTranslationChange('body')}
              multiline={true}
              size="md"
              error={this.isError('body')}
              errorText={this.getErrorText('body')}
            />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  translation: state.messaging.translations.form
});

MessageFormElements.propTypes = {
  availableLanguages: PropTypes.array.isRequired,
  body: PropTypes.string,
  errors: PropTypes.array.isRequired,
  language: PropTypes.string,
  messageId: PropTypes.string,
  nameEditDisabled: PropTypes.bool.isRequired,
  nameEditHide: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onTranslationChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  title: PropTypes.string,
  translation: PropTypes.object
};

export default connect(mapStateToProps)(MessageFormElements);
