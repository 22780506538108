import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { getTranslationObject } from '../../../utils/index';
import {
  FETCH_PROFILE_BEGIN, FETCH_PROFILE_FAILURE, FETCH_PROFILE_SUCCESS,
  UPDATE_LANGUAGE_SETTING
} from '../actions/actionTypes';
import en from '../i18n/en';
import es from '../i18n/es';

const langs = { en, es };

const initialState = {
  loading: false,
  success: false,
  message: '',
  translations: null,
  language: '',
  languages: [{ id: 'en', name: 'English' }, { id: 'es', name: 'Spanish' }],
};

export default function profileReducer(state = initialState, action) {
  // const updatedProfile = (state.profile ? [...state.profile] : []);

  switch (action.type) {
    case FETCH_PROFILE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload.products,
      };

    case FETCH_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        profile: []
      };

    case UPDATE_LANGUAGE_SETTING:
      return { ...state, language: action.value };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
