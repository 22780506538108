import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../../containers/SonifiLabel';

class TerminalRow extends Component {
  render() {
    const { logs, rowIndex } = this.props;

    return (
      <TableRow>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={`${logs[rowIndex].site_id}`} />
        </TableCell>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={logs[rowIndex].site_name} />
        </TableCell>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={logs[rowIndex].model} />
        </TableCell>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={logs[rowIndex].version_firmware} />
        </TableCell>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={logs[rowIndex].terminal_type.toUpperCase()} />
        </TableCell>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={`${logs[rowIndex].count}`} />
        </TableCell>
      </TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  logs: state.terminalLogs.logs
});

TerminalRow.propTypes = {
  dispatch: PropTypes.func,
  logs: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(TerminalRow);
