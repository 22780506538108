import { Update } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel } from '../../../../containers';
import SonifiPopover from '../../../../containers/SonifiPopover';
import { updateSonifiFirmware } from '../../actions/SonifiFirmwareActions';
import { MANUAL } from '../../constants/TermFileConstants';

class SonifiRow extends Component {
  handleEditDialog(type) {
    const { dispatch, rowIndex } = this.props;
    dispatch(updateSonifiFirmware(rowIndex, type));
  }

  firmwareRow() {
    const { rowIndex, sonifiFirmware, translations } = this.props;
    const { id, firmware, version } = sonifiFirmware[rowIndex];

    return (
      <Fragment>
        <TableCell>{id}</TableCell>
        <TableCell>{(firmware
          ? firmware[Object.keys(firmware)[0]]
          : <SonifiLabel error label={translations.noFirmware} />)}</TableCell>
        <TableCell>{(version && version !== undefined
          ? version
          : <SonifiLabel error label={translations.noVersion} />)}</TableCell>
      </Fragment >
    );
  }

  render() {
    const {
      canEdit, globalTranslations, rowIndex, sonifiFirmware, translations
    } = this.props;

    const { firmware } = sonifiFirmware[rowIndex];

    return (
      canEdit
        ? <SonifiPopover
          editDisabled={!canEdit}
          editFunc={this.handleEditDialog.bind(this, 'edit')}
          editTitle={globalTranslations.edit}
          functionTwoDisabled={!firmware}
          functionTwoFunc={this.handleEditDialog.bind(this, MANUAL)}
          functionTwoIcon={<Update fontSize="small" />}
          functionTwoTitle={translations.manual}
          hideDelete={true}
          key={rowIndex}
          showFunctionTwo={true}
          tableRow={this.firmwareRow()}
        />
        : <TableRow> {this.firmwareRow()} </TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  sonifiFirmware: state.termFile.sonifiFirmware,
  translations: state.termFile.translations.popOver.sonifi
});

SonifiRow.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  rowIndex: PropTypes.number,
  sonifiFirmware: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(SonifiRow);
