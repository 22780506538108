import { InputAdornment, MenuItem, TextField } from '@mui/material';
import { find, findIndex, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const _RIGHTMARGINSPACING = 2;
const _HEIGHT = 10;
const _DEFAULT_ROWS = 10;
const _END = 'end';
const _START = 'start';

/* eslint-disable max-lines-per-function */
const styles = (theme) => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  textFieldDefault: {
    marginTop: 0,
    marginRight: 0,
    width: '200px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldXtraSmall: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '70px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldXtraSmallNoPadding: {
    width: '70px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldPercentWidth: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '95%',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldPercentWidthNoPadding: {
    width: '100%',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldSmall: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '200px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldMedium: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '320px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldMediumNoPadding: {
    // marginTop: theme.spacing(1),
    marginRight: 0,
    width: '320px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textFieldLarge: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '400px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  marginRightOnly: {
    marginTop: 0,
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '200px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },
  textBox: {
    marginTop: 0,
    width: '649px',
    height: theme.spacing(_HEIGHT),
    marginBottom: 0
  },

  // multiline will need to use the following styles...
  // errorNoHeight: {
  //   color: 'red',
  //   margin: 0,
  //   fontSize: '10pt',
  //   paddingLeft: theme.spacing(1),
  //   paddingBottom: theme.spacing(1),
  //   paddingTop: theme.spacing(1),
  // },
  textFieldDefaultNoHeight: {
    marginTop: 0,
    marginRight: 0,
    width: '200px',
  },
  textFieldXtraSmallNoHeight: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '70px',
  },
  textFieldPercentWidthNoHeight: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '95%',
  },
  textFieldSmallNoHeight: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '200px',
  },
  textFieldMediumNoHeight: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '320px',
  },
  textFieldMediumNoHeightNoPadding: {
    // marginTop: theme.spacing(1),
    marginRight: 0,
    width: '320px',
  },
  textFieldLargeNoHeight: {
    // marginTop: theme.spacing(1),
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '400px',
  },
  marginRightOnlyNoHeight: {
    marginTop: 0,
    marginRight: theme.spacing(_RIGHTMARGINSPACING),
    width: '200px',
  },
  marginRightOnlyNoHeightSm: {
    marginTop: 0,
    marginRight: '2%',
    width: '48%',
  },
  textBoxNoHeight: {
    marginTop: 0,
    width: '649px',
  },
  hideMe: { visibility: 'hidden' }
});

export class SonifiText extends Component {
  buildAdornmentProps = () => {
    const { iconPosition, icon } = this.props;
    const props = {
      startAdornment: (iconPosition && iconPosition === _START)
        ? (
          <InputAdornment style={{ pointerEvents: 'none' }} position={_START}>
            {icon}
          </InputAdornment>
        )
        : null,
      endAdornment: (iconPosition && iconPosition === _END)
        ? (
          <InputAdornment style={{ pointerEvents: 'none' }} position={_END}>
            {icon}
          </InputAdornment>
        )
        : null,
    };

    return props;
  };

  handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  selectTextOnFocus = (event) => {
    event.target.select();
  };

  submitMe = (event) => {
    if (event.key === 'Enter' && this.props.onSubmit) {
      this.props.onSubmit();
    }
  };

  getClassName() {
    const { classes, size } = this.props;
    let className;

    if (!this.props.multiline) {
      if ((size) && (size === 'sm')) {
        className = classes.textFieldSmall;
      } else if ((size) && (size === 'md')) {
        className = classes.textFieldMedium;
      } else if ((size) && (size === 'mdNoPad')) {
        className = classes.textFieldMediumNoPadding;
      } else if ((size) && (size === 'lg')) {
        className = classes.textFieldLarge;
      } else if ((size) && (size === 'xs')) {
        className = classes.textFieldXtraSmall;
      } else if ((size) && (size === 'xsNoPad')) {
        className = classes.textFieldXtraSmallNoPadding;
      } else if ((size) && (size === 'percent')) {
        className = classes.textFieldPercentWidth;
      } else if ((size) && (size === 'percentNoPad')) {
        className = classes.textFieldPercentWidthNoPadding;
      } else if ((size) && (size === 'marginRight')) {
        className = classes.marginRightOnly;
      } else if (size && size === 'textBox') {
        className = classes.textBox;
      } else if ((size) && (size === 'marginRightSm')) {
        className = classes.marginRightOnlyNoHeightSm;
      } else {
        className = classes.textFieldDefault;
      }
    } else if (this.props.multiline) {
      if ((size) && (size === 'sm')) {
        className = classes.textFieldSmallNoHeight;
      } else if ((size) && (size === 'md')) {
        className = classes.textFieldMediumNoHeight;
      } else if ((size) && (size === 'mdNoPad')) {
        className = classes.textFieldMediumNoHeightNoPadding;
      } else if ((size) && (size === 'lg')) {
        className = classes.textFieldLargeNoHeight;
      } else if ((size) && (size === 'xs')) {
        className = classes.textFieldXtraSmallNoHeight;
      } else if ((size) && (size === 'percent')) {
        className = classes.textFieldPercentWidthNoHeight;
      } else if ((size) && (size === 'marginRight')) {
        className = classes.marginRightOnlyNoHeight;
      } else if (size && size === 'textBox') {
        className = classes.textBoxNoHeight;
      } else {
        className = classes.textFieldDefaultNoHeight;
      }
    }

    return className;
  }

  render() {
    const {
      classes, disabled, error, errorText, helperText, hide, highlightText, id, testLabel
    } = this.props;

    const adornmentProps = this.buildAdornmentProps();

    let value = ((typeof this.props.defaultValue === 'undefined') || (this.props.defaultValue === null))
      ? ''
      : this.props.defaultValue,

      textLabel = null;

    if (typeof this.props.defaultValue === 'object') {
      let myIndex = -1;
      if (this.props.defaultValue && this.props.defaultValue.pattern !== undefined &&
        this.props.defaultValue.order !== undefined) {
        myIndex = findIndex(this.props.items,
          (o) => (typeof o.value === 'object' &&
            (o.value ? o.value.pattern : o.value) === this.props.defaultValue.pattern) &&
            isEqual(o.value.order, this.props.defaultValue.order));
      } else {
        myIndex = findIndex(this.props.items,
          (o) => (typeof o.value === 'object' && o.value && o.value.name
            ? o.value.name
            : o.value) === this.props.defaultValue.name);
      }
      if (myIndex !== -1) {
        value = this.props.items[myIndex].value;
        textLabel = this.props.items[myIndex].label;
      }
    }

    const showError = ((typeof error === 'undefined') || (error === null)) ? false : error;

    if (this.props.select && disabled && this.props.items.length > 0) {
      let visibleValue = find(this.props.items, (o) => o.value === value);
      if (visibleValue === undefined) {
        visibleValue = find(this.props.items, (o) => o.label === value);
      }
      if (visibleValue === undefined) {
        visibleValue = value;
      }

      return (<TextField
        autoFocus={highlightText}
        autoComplete={id}
        className={`${this.getClassName()} ${hide && classes.hideMe}`}
        data-testid={testLabel}
        disabled={disabled}
        error={showError}
        helperText={(showError && errorText) ? errorText : helperText}
        id={id}
        InputLabelProps={{ classes: {} }}
        InputProps={adornmentProps}
        inputProps={{ ...this.props.inputProps, ...testLabel }}
        label={this.props.label}
        margin="normal"
        multiline={this.props.multiline}
        onBlur={this.handleBlur}
        onChange={this.props.change}
        onFocus={highlightText && this.selectTextOnFocus}
        onKeyUp={this.submitMe}
        rows={this.props.rows || _DEFAULT_ROWS}
        type={this.props.type}
        value={(typeof value === 'object' && value ? value.name : visibleValue.label)}
        variant="filled" >
      </TextField>);
    }

    return (
      <TextField
        autoFocus={highlightText}
        autoComplete={id}
        className={`${this.getClassName()} ${hide && classes.hideMe}`}
        data-testid={testLabel}
        disabled={disabled}
        error={showError}
        helperText={(showError && errorText) ? errorText : helperText}
        id={id}
        InputLabelProps={{ classes: {} }}
        InputProps={adornmentProps}
        inputProps={{ ...this.props.inputProps, ...testLabel }}
        label={this.props.label}
        margin="normal"
        multiline={this.props.multiline}
        onBlur={this.handleBlur}
        onChange={this.props.change}
        onFocus={highlightText && this.selectTextOnFocus}
        onKeyUp={this.submitMe}
        rows={this.props.rows || _DEFAULT_ROWS}
        select={!disabled && this.props.select}
        type={this.props.type}
        value={(disabled && typeof value === 'object' && value && textLabel ? textLabel : value)}
        variant="filled" >
        {!disabled && this.props.items && this.props.items.map((option, index) => (
          <MenuItem key={index} value={option.value} data-testid={`${testLabel}-${option.label ?? option.value}`} >
            {(typeof option.value === 'object' && option.value && !option.label ? option.value.name : option.label)}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

SonifiText.propTypes = {
  change: PropTypes.func,
  classes: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  float: PropTypes.string,
  helperText: PropTypes.string,
  hide: PropTypes.bool,
  highlightText: PropTypes.bool,
  icon: PropTypes.object,
  iconPosition: PropTypes.string,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  items: PropTypes.array,
  label: PropTypes.string,
  multiline: PropTypes.bool,
  onBlur: PropTypes.func,
  onSubmit: PropTypes.func,
  rows: PropTypes.number,
  select: PropTypes.bool,
  size: PropTypes.string,
  testLabel: PropTypes.string,
  type: PropTypes.string
};


export default React.memo(withStyles(SonifiText, styles, { withTheme: true }));
