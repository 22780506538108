// This is the actual publish form that is within the FormDialog when modifying or adding publish records

import { Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { difference, find, orderBy, without } from 'lodash';
import moment from 'moment';
import 'moment-timezone';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import {
  DISPLAY_TYPES, DROPDOWN_ADD_NEW, FORM_MODE, MESSAGE_TYPE, NOT_FOUND, requiredMessageFields, requiredPublishFields,
  TAKEOVER_TYPES
} from '../../../constants/messages';
import {
  RoomSelector, SonifiCheckbox, SonifiModalHeader, SonifiSpinner, SonifiText
} from '../../../containers';
import ModalSectionHeader from '../../../containers/ModalSectionHeader';
import SonifiDateTimePicker from '../../../containers/SonifiDateTimePicker';
import { getUserNameFromSession } from '../../../utils';
import { getLibraryItem } from '../actions/messagingActions';
import { getFormErrors } from '../utils/validator';
import MessageFormElements from './MessageFormElements';
const styles = (theme) => ({
  indent: {
    marginLeft: theme.spacing(2),
  },
  messageForm: {
    border: `1px solid ${theme.palette.primary.light}`,
    paddingBottom: theme.spacing(3),
  }
});

class PublishForm extends Component {
  state = {
    availableLanguages: [{
      iso_code_639_1: 'en',
      name: 'English',
      sequence: 1
    }],
    dialogLoading: false,
    end_timestamp: null,
    formErrors: [],
    language: 'en',
    libraryLoading: false,
    message_type: MESSAGE_TYPE.ROOM,
    neverExpires: true,
    open: false,
    organization: null,
    requester: null,
    rooms: [],
    start_timestamp: null,
    startNow: false,
    user_name: null
  };

  componentDidMount() {
    this.setState({
      dialogLoading: !(this.props.mode === FORM_MODE.ADD),
      language: this.props.defaultLanguage,
      libraryLoading: false,
      open: (this.props.mode === FORM_MODE.COPY)
    });
  }

  componentDidUpdate(prevProps) {
    // console.log('COMPONENTDIDUPDATE PUBLISHFORM');
    // console.log('prevProps', prevProps);
    // console.log('Props', this.props);
    // console.log('prevState', prevState);
    // console.log('State', this.state);

    if ((this.props.mode === FORM_MODE.ADD) && ((prevProps.libraryItem !== this.props.libraryItem))) {
      this.handleLibraryItemChange();
    } else if ((prevProps.dialogLoading === true) && (this.props.dialogLoading === false)) {
      if ((this.props.publishItem) && (this.props.dialogError.length === 0)) {
        const { publishItem } = this.props;
        const { body } = publishItem;
        let availableLanguages = body.translations.map(
          (item) => this.props.languages.filter(((e) => e.iso_code_639_1 === item.id))[0]
        );

        availableLanguages = orderBy(availableLanguages, ['sequence']);

        let setLang = this.state.language;
        if (find(availableLanguages, { iso_code_639_1: this.state.language }) === undefined) {
          setLang = availableLanguages[0].iso_code_639_1;
        }

        this.setState({
          dialogLoading: false,
          libraryLoading: false,
          language: setLang,
          nameDropdown: (this.props.mode === FORM_MODE.COPY ? body.name : DROPDOWN_ADD_NEW),
          messageId: (this.props.mode === FORM_MODE.COPY ? body.name += '-copy' : body.name),
          rooms: body.rooms,
          ...publishItem,
          ...body,
          availableLanguages: without(availableLanguages, undefined),
          start_timestamp: moment.utc(publishItem.start_timestamp, moment.ISO_8601, true).tz(
            this.props.timezone
          ).format('MM/DD/YYYY h:mm a'),
          end_timestamp: (publishItem.end_timestamp)
            ? moment.utc(publishItem.end_timestamp, moment.ISO_8601, true).tz(
              this.props.timezone
            ).format('MM/DD/YYYY h:mm a')
            : null,
          neverExpires: !(publishItem.end_timestamp),
        });
      }
    }
  }

  handleLibraryItemChange = () => {
    const libraryItem = this.props.libraryItem;
    libraryItem.messageId = libraryItem.id;
    delete libraryItem.id;

    let setLang = this.state.language;
    if (this.state.nameDropdown === DROPDOWN_ADD_NEW) {
      if (find(this.props.languages, { iso_code_639_1: this.state.language }) === undefined) {
        setLang = this.props.languages[0].iso_code_639_1;
      }

      this.setState({
        open: true,
        libraryLoading: false,
        language: setLang,
        ...libraryItem,
        availableLanguages: orderBy(this.props.languages, ['sequence'])
      });
    } else {
      const availableLanguages = libraryItem.translations.map(
        (item) => this.props.languages.filter(((e) => e.iso_code_639_1 === item.id))[0]
      );
      if (find(availableLanguages, { iso_code_639_1: this.state.language }) === undefined) {
        setLang = availableLanguages[0].iso_code_639_1;
      }
      this.setState({
        language: setLang,
        libraryLoading: false,
        ...libraryItem,
        availableLanguages: orderBy(availableLanguages, ['sequence'])
      });
    }
  };

  handleDateChange = (name) => (value) => {
    this.setState({
      [name]: (value === null) ? null : moment(value).format('MM/DD/YYYY h:mm a')
    });
  };

  handleCollapse = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  handleRotate = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  // This is an intermediate step.  The local components will be called
  // to this with the change event, then we pass it along to handleChange
  processChange = (name) => ({ target: { value } }) => {
    this.handleChange(name, value);
  };

  processRoomChange = (name) => (value) => {
    this.handleChange(name, value);
  };

  handleChange = (name, value) => {
    switch (name) {
      case 'nameDropdown':
        this.props.dispatch(getLibraryItem(value));
        this.setState({ libraryLoading: true });
        break;

      default:
        break;
    }
    this.setState({
      [name]: value
    });
  };

  handleCheckChange = (name) => ({ target }) => {
    let newDateTime;
    switch (name) {
      case 'message_type':
        this.setState({
          [name]: target.checked ? MESSAGE_TYPE.CONSUMER : MESSAGE_TYPE.ROOM
        });
        break;
      case 'neverExpires':
        this.setState({
          end_timestamp: null,
          [name]: target.checked
        });
        break;
      case 'startNow':
        newDateTime = moment.utc().tz(this.props.timezone).format('MM/DD/YYYY h:mm a');
        this.setState({
          start_timestamp: newDateTime,
          [name]: target.checked
        });
        break;
      default:
        this.setState({
          [name]: target.checked
        });
        break;
    }
  };

  handleTranslationChange = (name, value) => {
    const { language, translations } = this.state;
    const pos = translations.map((e) => e.id).indexOf(language);

    switch (name) {
      case 'title':
        if (pos === NOT_FOUND) {
          translations.push({
            id: language,
            title: value,
            body: '',
          });
        } else {
          translations[pos].title = value;
        }
        break;
      case 'body':
        if (pos === NOT_FOUND) {
          translations.push({
            id: language,
            title: '',
            body: value,
          });
        } else {
          translations[pos].body = value;
        }
        break;
      default:
        break;
    }

    this.setState({ translations });
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  handleSubmit = () => {
    const { fTranslation, globalTranslations, timezone } = this.props;
    let requiredFields = requiredPublishFields;
    if ((this.props.mode === FORM_MODE.ADD) && (this.state.nameDropdown) &&
      (this.state.nameDropdown === DROPDOWN_ADD_NEW)) {
      requiredFields = [...requiredPublishFields, ...requiredMessageFields];
    } else if (this.props.mode === FORM_MODE.COPY) {
      requiredFields = difference([...requiredPublishFields, ...requiredMessageFields], ['nameDropdown']);
    }

    getFormErrors(this.state, globalTranslations, fTranslation, requiredFields, timezone)
      .then((formErrors) => {
        if (formErrors.length) {
          this.setState({
            formErrors
          });
        } else {
          this.props.onSubmit({
            ...this.state,
            user_name: getUserNameFromSession()
          });
        }
      }).catch((error) => {
        console.log('handleSubmit failure:', error);
      });
  };

  createSelectList = (messageList) => {
    const addNew = {
      label: this.props.mTranslations.addNew,
      value: DROPDOWN_ADD_NEW,
    };

    let selectList = [];
    messageList.forEach((msg) => {
      selectList.push({
        label: msg.id,
        value: msg.id
      });
    });

    selectList = [addNew, ...orderBy(selectList, [(a) => a.label.toLowerCase()], ['asc'])];
    return selectList;
  };

  isError(fieldName) {
    if (!this.state.formErrors) {
      return false;
    }
    const error = this.state.formErrors.filter((e) => e.fieldName === fieldName);
    return !!(error.length);
  }

  getErrorText(fieldName) {
    if (!this.state.formErrors) {
      return '';
    }
    const error = this.state.formErrors.filter((e) => e.fieldName === fieldName);
    return (error.length) ? error[0].message : '';
  }

  // TODO: render function is too large
  /* eslint-disable max-lines-per-function */
  /* eslint-disable camelcase */
  render() {
    const {
      availableLanguages, dialogLoading, display_type, end_timestamp, formErrors, language, libraryLoading,
      messageId, name, nameDropdown, neverExpires, organization, requester, rooms, start_timestamp,
      startNow, takeover_type, translations
    } = this.state;
    const {
      classes, mode, header, library, globalTranslations, mTranslations
    } = this.props;

    // TODO REMOVE ALL OPEN CLOSE LOGIC AND OPEN STATE VARIABLE
    // ALSO CLEAN UP COMMENTED CODE

    if (dialogLoading) {
      return <SonifiSpinner />;
    }

    const messageSelectList = this.createSelectList(library);
    const submitText = globalTranslations.defaults.publish;

    const add = [FORM_MODE.ADD].includes(mode);
    const edit = [FORM_MODE.EDIT].includes(mode);
    const view = [FORM_MODE.VIEW].includes(mode);
    const copy = [FORM_MODE.COPY].includes(mode);
    const adHoc = (add && (nameDropdown === DROPDOWN_ADD_NEW)) || copy;

    const messageReadOnly = edit || !(adHoc);
    const editOrView = edit || view;
    const translation = (translations) ? translations.filter((ex) => ex.id === language)[0] : null;
    const title = (translation) ? translation.title : '';
    const body = (translation) ? translation.body : '';

    // display a spinner when the component is busy getting the selected library item
    const messageForm = (libraryLoading)
      ? <Grid container justifyContent="space-between">
        <Grid item xs={8} className={classes.messageForm}>
          <div className={classes.indent}>
            <SonifiSpinner />
          </div>
        </Grid>
      </Grid>
      : <Grid container justifyContent="space-between">
        <Grid item xs={8} className={classes.messageForm}>
          <div className={classes.indent}>
            <MessageFormElements
              messageId={messageId}
              nameEditDisabled={!adHoc}
              nameEditHide={!adHoc}
              language={language}
              availableLanguages={availableLanguages}
              title={title}
              body={body}
              onChange={this.handleChange}
              onTranslationChange={this.handleTranslationChange}
              readOnly={messageReadOnly}
              errors={formErrors}
            />
          </div>
        </Grid>
      </Grid>;

    // logic between allowing the user to choose an existing message, and
    // if it is in edit mode, only display the name
    const nameSelector = ([FORM_MODE.ADD, FORM_MODE.COPY].includes(mode))
      ? <SonifiText
        label={mTranslations.messageName}
        defaultValue={nameDropdown}
        change={this.processChange('nameDropdown')}
        items={messageSelectList}
        select={true}
        size="md"
        error={this.isError('nameDropdown')}
        errorText={this.getErrorText('nameDropdown')}
        disabled={mode === FORM_MODE.COPY}
      />
      : <SonifiText
        label={mTranslations.messageName}
        defaultValue={name}
        change={this.processChange('name')}
        size="md"
        disabled={true}
      />;

    return <Fragment>
      <SonifiModalHeader
        header={header}
        onlyClose={view}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit.bind(this)}
        label={submitText}
      />
      <DialogContent>
        {/* <SonifiErrorList errorList={dialogError} /> */}
        <Grid container>
          <Grid item xs={12}>
            {nameSelector}
          </Grid>
          <Grid item xs={12}>
            {messageForm}
          </Grid>
          <Grid item xs={12}>
            <ModalSectionHeader label={mTranslations.details} />
          </Grid>
          <Grid item xs={6}>
            <SonifiDateTimePicker
              change={this.handleDateChange('start_timestamp')}
              defaultValue={start_timestamp}
              disabled={!!(view || startNow)}
              disablePast={true}
              error={this.isError('start_timestamp')}
              errorText={this.getErrorText('start_timestamp')}
              label={mTranslations.startDate}
              size="percent"
            />
          </Grid>
          <Grid item xs={6}>
            <SonifiCheckbox
              label={mTranslations.startNow}
              disabled={view}
              value={startNow}
              onChange={this.handleCheckChange('startNow')}
            />
          </Grid>
          <Grid item xs={6}>
            <SonifiDateTimePicker
              change={this.handleDateChange('end_timestamp')}
              defaultValue={end_timestamp}
              disabled={!!(view || neverExpires)}
              disablePast={true}
              error={this.isError('end_timestamp')}
              errorText={this.getErrorText('end_timestamp')}
              label={neverExpires ? mTranslations.noEnd : mTranslations.endDate}
              size="percent"
            />
          </Grid>
          <Grid item xs={6}>
            <SonifiCheckbox
              label={mTranslations.noExpiration}
              disabled={view}
              value={neverExpires}
              onChange={this.handleCheckChange('neverExpires')}
            />
          </Grid>
          <Grid item xs={6}>
            <SonifiText
              label={mTranslations.display}
              disabled={editOrView}
              defaultValue={display_type}
              change={this.processChange('display_type')}
              items={DISPLAY_TYPES}
              select={true}
              size="percent"
              error={this.isError('display_type')}
              errorText={this.getErrorText('display_type')}
            />
          </Grid>
          <Grid item xs={6}>
            <SonifiText
              label={mTranslations.tvTurnOn}
              disabled={editOrView}
              defaultValue={takeover_type}
              change={this.processChange('takeover_type')}
              items={TAKEOVER_TYPES}
              select={true}
              size="percent"
              error={this.isError('takeover_type')}
              errorText={this.getErrorText('takeover_type')}
            />
          </Grid>
          <Grid item xs={6}>
            <SonifiText
              label={mTranslations.requestedBy}
              defaultValue={requester}
              change={this.processChange('requester')}
              disabled={editOrView}
              size="percent" />
          </Grid>
          <Grid item xs={6}>
            <SonifiText
              label={mTranslations.organization}
              defaultValue={organization}
              change={this.processChange('organization')}
              disabled={editOrView}
              size="percent" />
          </Grid>
          <Grid item xs={6}>
            <RoomSelector
              selectedRooms={rooms}
              disabled={editOrView}
              onChange={this.processRoomChange('rooms')}
              errorMessage={this.getErrorText('rooms')} />
          </Grid>
        </Grid>
      </DialogContent>
    </Fragment >;
  }
}

const mapStateToProps = (state) => ({
  defaultLanguage: state.global.defaultLanguage,
  dialogError: state.messaging.dialogError,
  dialogLoading: state.messaging.dialogLoading,
  fTranslation: state.messaging.translations.form,
  globalTranslations: state.global.translations,
  languages: state.global.languages,
  library: state.messaging.library,
  libraryItem: state.messaging.libraryItem,
  mTranslations: state.messaging.translations.publishForm,
  publishItem: state.messaging.publishItem,
  selectedItem: state.messaging.selectedPublishItem,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago')
});

PublishForm.propTypes = {
  classes: PropTypes.object.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  dialogError: PropTypes.array,
  dialogLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  fTranslation: PropTypes.object,
  globalTranslations: PropTypes.object,
  header: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  library: PropTypes.array,
  libraryItem: PropTypes.object,
  messageList: PropTypes.arrayOf(PropTypes.object),
  mode: PropTypes.string.isRequired,
  mTranslations: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  publishItem: PropTypes.object,
  publishMessage: PropTypes.object,
  selectedItem: PropTypes.number,
  timezone: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(withStyles(PublishForm, styles, { withTheme: true }));
