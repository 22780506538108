import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel } from '../../../../containers';
import SonifiPopover from '../../../../containers/SonifiPopover';
import { updateSamsungFirmware } from '../../actions/SamsungFirmwareActions';

class SamsungRow extends Component {
  handleEditDialog(type) {
    const { dispatch, rowIndex } = this.props;
    dispatch(updateSamsungFirmware(rowIndex, type));
  }

  firmwareRow() {
    const { rowIndex, samsungFirmware, translations } = this.props;
    const { id, firmware, version } = samsungFirmware[rowIndex];

    return (
      <Fragment>
        <TableCell>{id}</TableCell>
        <TableCell>{(firmware && firmware.BEM && firmware.BEM !== ''
          ? firmware.BEM
          : <SonifiLabel error label={translations.noBEM} />)}
        </TableCell>
        <TableCell>{(firmware && firmware.CLONE && firmware.CLONE !== ''
          ? firmware.CLONE
          : <SonifiLabel error label={translations.noCLONE} />)}
        </TableCell>
        <TableCell>{(version && version !== undefined
          ? version
          : <SonifiLabel error label={translations.noVersion} />)}</TableCell>
      </Fragment >
    );
  }

  render() {
    const { canEdit, globalTranslations, rowIndex } = this.props;

    return (
      canEdit
        ? <SonifiPopover
          editDisabled={!canEdit}
          editFunc={this.handleEditDialog.bind(this, 'edit')}
          editTitle={globalTranslations.edit}
          hideDelete={true}
          key={rowIndex}
          tableRow={this.firmwareRow()}
        />
        : <TableRow> {this.firmwareRow()} </TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  samsungFirmware: state.termFile.samsungFirmware,
  translations: state.termFile.translations.popOver.sonifi
});

SamsungRow.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  rowIndex: PropTypes.number,
  samsungFirmware: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(SamsungRow);
