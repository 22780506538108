import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getSite, getSystemSchedule } from '../../../actions/globalActions';
import { httpSuccess } from '../../../constants/http';
import {
  SonifiButton, SonifiLabel, SonifiSpinner, SonifiTextAsync
} from '../../../containers';
import { findObjectByKey, getErrorText, isError } from '../../../utils';
import { getDropDown } from '../../../utils/ui';
import { getTerminalOptions } from '../../Terminals/actions/terminalActions';
import {
  addTerminalToRoom, doesRoomExist, getProvRoomTerminals, getSiteRoomsBegin
} from '../actions/provisionTermsActions';

class MobileProvision extends Component {
  state = {
    activeStep: 0,
    error: '',
    id: null,
    language: 'en',
    room: '',
    siteId: '',
    siteLocation: '',
    siteName: '',
    spinner: false,
    termLineup: '',
    termLocation: '',
    termMenuSet: ''
  };

  componentDidMount() {
    const { dispatch, terminalData, translations } = this.props;
    this.setState({
      error: '',
      id: terminalData.id,
      spinner: true
    }, () => {
      dispatch(getTerminalOptions(terminalData.site_id));
      dispatch(getSystemSchedule(terminalData.site_id));
      dispatch(getSite(terminalData.site_id))
        .then((json) => {
          if (json.status && typeof json.status === 'number' && !httpSuccess(json.status)) {
            this.setState({ error: { access: `${translations.errors.access}` }, spinner: false });
          } else {
            this.setState({
              siteId: json.id,
              siteName: json.name,
              siteLocation: json.location.city,
              spinner: false
            });
          }
        })
        .catch((error) => {
          console.error('MobileProvisioning Error (100)', error);
          this.setState({ spinner: false });
        });
    });
  }

  buttonEnabled = () => {
    const {
      activeStep, room, termLineup, termLocation, termMenuSet
    } = this.state;
    if (activeStep === 0) {
      return (room === null || room === '');
    } else if (activeStep === 1) {
      return (
        termLocation === null || termLocation === '' ||
        termLineup === null || termLineup === '' ||
        termMenuSet === null || termMenuSet === ''
      );
    }
    return false;
  };

  handleBack = () => {
    if (this.state.activeStep === 0) {
      this.props.onComplete();
    } else {
      this.setState((state) => ({
        activeStep: state.activeStep - 1,
      }));
    }
  };

  handleChange = (name) => (value) => {
    if (value) {
      this.setState({ [name]: (value.label ? value.label.trim() : value.target.value) });
    }
    this.setState({
      [name]: (value instanceof Event ? value.target.value : value.label.trim())
    });
  };

  handleRoomChange = () => (value) => {
    if (value !== null && value.value !== null) {
      this.setState({
        room: (value.label ? value.label.trim() : value.target.value)
      });
    }
  };

  handleNext = () => {
    const { dispatch, terminalLocations } = this.props;
    const { activeStep, siteId, room } = this.state;

    let nextStep = activeStep + 1;

    this.setState({ spinner: true });

    if (activeStep === 0) {
      dispatch(doesRoomExist(siteId, room))
        .then((response) => {
          if (response) {
            dispatch(getSiteRoomsBegin());
            dispatch(getTerminalOptions(siteId))
              .then((json) => {
                dispatch(getProvRoomTerminals(siteId, room,
                  {
                    languages: json.languages,
                    locations: json.locations,
                    lineups: json.lineups,
                    menusets: json.menusets
                  }))
                  .then((data) => {
                    this.setState({
                      spinner: false,
                      termLocation: data.locations[0].id,
                      termLineup: data.lineups[0].id,
                      termMenuSet: (data.menusets && data.menusets.length > 0 ? data.menusets[0].id : 0),
                      language: (data.languages && data.languages.length > 0 ? data.languages[0].id : 'en')
                    });
                  })
                  .catch((error) => {
                    console.error('MobileProvisioning Error (200)', error);
                  });
              })
              .catch((error) => {
                console.error('MobileProvisioning Error (300)', error);
              });

            this.setState({
              activeStep: nextStep,
              spinner: false
            });
          } else {
            this.setState({ spinner: false, error: { room: 'Room does not exist' } });
          }
        })
        .catch((error) => {
          console.error('MobileProvisioning Error (400)', error);
          this.setState({ spinner: false });
        });
    } else if (activeStep === 1) {
      dispatch(addTerminalToRoom(this.state, terminalLocations))
        .then(() => {
          this.setState({
            activeStep: nextStep,
            spinner: false
          });
        })
        .catch((error) => {
          console.error('MobileProvisioning Error (500)', error);
          this.setState({
            activeStep: nextStep,
            spinner: false
          });
        });
    } else {
      nextStep = 0;
      this.props.onComplete();
    }
  };

  createDisplayObj = (site) => ({
    value: site,
    label: `${site.id} ${(site.name ? `: ${site.name}` : '')}`,
  });

  createFilterObj = (inputData) => ({
    filter: inputData,
    limit: 20
  });

  getStepContent() {
    const {
      activeStep, error, room, termLocation, siteId, termLineup, termMenuSet
    } = this.state;
    const { lineups, menusets, terminalLocations } = this.props;

    switch (activeStep) {
      case 0:
        return (
          <SonifiTextAsync
            dataName="rooms"
            defaultValue={room}
            displayFunc={this.createDisplayObj}
            filterFunc={this.createFilterObj}
            onSelect={this.handleRoomChange()}
            placeholder={room ? room : 'Please select a room'}
            resource="rooms"
            siteId={siteId}
            siteObj={true}
            type="room"
            error={isError('room', error)}
            errorText={getErrorText('room', error)}
          />
        );
      case 1:
        return (
          <Grid container>
            <Grid item xs={12}>
              {getDropDown('Location', `${termLocation}`, null,
                terminalLocations.map((suggestion) => ({
                  id: suggestion.id,
                  value: suggestion.id,
                  label: suggestion.name,
                })),
                this.handleChange('termLocation'), '', true, '')}
            </Grid>
            <Grid item xs={12}>
              {getDropDown('Channel Lineup', `${termLineup}`, null,
                lineups.map((suggestion) => ({
                  id: suggestion.id,
                  value: suggestion.id,
                  label: suggestion.name,
                })),
                this.handleChange('termLineup'), '', true, '')}
            </Grid>
            <Grid item xs={12}>
              {getDropDown('Menuset', `${termMenuSet}`, null,
                menusets.map((suggestion) => ({
                  id: suggestion.id,
                  value: suggestion.id,
                  label: suggestion.name,
                })),
                this.handleChange('termMenuSet'), '', true, '')}
            </Grid>
          </Grid >
        );
      case 2:
        return (
          <Grid container>
            <Grid item xs={12}>
              <SonifiLabel boldLabel="Location:"
                label={`${findObjectByKey(terminalLocations, 'id', termLocation).name}`} />
            </Grid>
            <Grid item xs={12}>
              {menusets && <SonifiLabel boldLabel="Menuset:"
                label={`${findObjectByKey(menusets, 'id', termMenuSet).name}`} />}
            </Grid>
            <Grid item xs={12}>
              <SonifiLabel boldLabel="Channel Lineup:"
                label={`${findObjectByKey(lineups, 'id', termLineup).name}`} />
            </Grid>
          </Grid>
        );
      default:
        return 'Unknown step';
    }
  }

  getNextButtonText() {
    const { activeStep } = this.state;
    const { globalTranslations } = this.props;
    if (activeStep === 0) {
      return globalTranslations.next;
    } else if (activeStep === 1) {
      return globalTranslations.done;
    }
    return globalTranslations.ok;
  }

  render() {
    const { globalTranslations, loading, terminalData } = this.props;
    const { activeStep, error, spinner } = this.state;

    if (spinner) {
      return <SonifiSpinner />;
    }

    // if (error && error.length > 0) {
    if (error && error.access) {
      return <SonifiLabel error boldLabel="Error" label={error} />;
    }

    return (
      <Fragment>
        <SonifiLabel boldLabel={`${this.state.siteId}, ${this.state.siteName}, ${this.state.siteLocation}`} />
        {activeStep > 0 && (
          <SonifiLabel boldLabel="Room:" label={this.state.room} />
        )}
        <SonifiLabel boldLabel="Terminal:" label={terminalData.id} />
        {loading
          ? <SonifiSpinner />
          : <Fragment>
            {this.getStepContent()}
            {activeStep < 2 &&
              <SonifiButton
                secondary
                onClick={this.handleBack}
                caption={globalTranslations.back}
              />
            }
            <SonifiButton
              disabled={this.buttonEnabled()}
              onClick={this.handleNext}
              caption={this.getNextButtonText()}
            />
          </Fragment>
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  lineups: state.provisionTerms.lineups,
  loading: state.provisionTerms.loading,
  menusets: state.termGrid.menusets,
  rooms: state.provisionTerms.rooms,
  terminalLocations: state.provisionTerms.terminalLocations,
  translations: state.provisionTerms.translations.mobile
});

MobileProvision.propTypes = {
  continue: PropTypes.func,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  lineups: PropTypes.array,
  loading: PropTypes.bool,
  menusets: PropTypes.array,
  onComplete: PropTypes.func,
  rooms: PropTypes.array,
  terminalData: PropTypes.object,
  terminalLocations: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(MobileProvision);
