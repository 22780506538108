import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import SonifiButton from './SonifiButton';

const styles = (theme) => ({
  alertTitle: {
    fontWeight: 900, /* Avenir 85 */
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Open Sans',
    fontSize: '14pt'
  },
  alertText: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Open Sans',
    fontSize: '14pt',
    fontWeight: 500, /* Avenir 65 */
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    whiteSpace: 'break-spaces'
  },
});

export class SonifiConfirm extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.state = {
    };
  }

  handleCancel = (event, reason) => {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  };

  handleConfirm = () => {
    this.props.onConfirm();
  };

  render() {
    const {
      buttonCancelText, buttonConfirmText, classes, confirmText,
      confirmTitle, dialogOpen, extraInfo, hideCancel, testId
    } = this.props;

    return (
      <Dialog
        open={dialogOpen}
        onClose={this.handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
        PaperProps={{
          'data-testid': testId ?? 'sonifi-confirm-dialog'
        }}
      >
        <DialogTitle>
          <Typography id="alert-dialog-title" className={classes.alertTitle} noWrap>
            {confirmTitle}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography id="alert-dialog-description" className={classes.alertText}>
            {confirmText}
          </Typography>
          {extraInfo}
        </DialogContent>
        <DialogActions>
          {!hideCancel && <SonifiButton
            secondary
            caption={buttonCancelText}
            onClick={this.handleCancel} />}
          <SonifiButton
            caption={buttonConfirmText}
            onClick={this.handleConfirm} />
        </DialogActions>
      </Dialog>
    );
  }
}
SonifiConfirm.propTypes = {
  buttonCancelText: PropTypes.string,
  buttonConfirmText: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  confirmText: PropTypes.string.isRequired,
  confirmTitle: PropTypes.string.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  extraInfo: PropTypes.object,
  hideCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  testId: PropTypes.string
};

export default withStyles(SonifiConfirm, styles, { withTheme: true });
