import { Button, Grid, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SonifiLabel from './SonifiLabel';

export class SonifiIconButton extends Component {
  getMiddleInfo() {
    const {
      disabled, extraStyles, icon, label, marginTop, onClick, usePrimary, testLabel, useTertiary
    } = this.props;

    let btnColor = usePrimary ? 'primary' : 'secondary';
    if (useTertiary) {
      btnColor = 'tertiary';
    }

    return (
      <div style={{ marginTop: `${marginTop || 0}px` }}>
        <Button
          color={btnColor}
          data-testid={testLabel ?? ''}
          disabled={disabled}
          onClick={onClick}
          style={extraStyles}
          variant="text"
        >
          {icon && <Fragment>
            {icon}&nbsp;
          </Fragment>}
          <div>
            {label}
          </div>
        </Button>
      </div>
    );
  }

  getToolTipHeader() {
    const { label, toolTipIcon, toolTipHeaderText, toolTipMessage } = this.props;
    return (
      <Grid container style={{ padding: 0 }}>
        <Grid item xs={12} style={{ backgroundColor: '#1592d9', color: 'white' }}>
          <Grid container style={{ padding: '10px 10px 0 10px' }}>
            <Grid item>
              {toolTipIcon}
            </Grid>
            <Grid item>&nbsp;</Grid>
            <Grid item>
              <SonifiLabel noPadding label={toolTipHeaderText} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ padding: '10px' }}>
          {toolTipMessage || label}
        </Grid>
      </Grid>
    );
  }

  render() {
    const { label, showToolTip, toolTipIcon, toolTipMessage } = this.props;
    if (toolTipIcon) {
      return (
        <Grid item>
          <Tooltip title={this.getToolTipHeader()}
            disableFocusListener={!showToolTip}
            disableHoverListener={!showToolTip}
            disableTouchListener={!showToolTip}
            PopperProps={{
              sx: {
                '& .MuiTooltip-tooltip': {
                  padding: '0'
                }
              }
            }}
          >
            {this.getMiddleInfo()}
          </Tooltip>
        </Grid>
      );
    }

    return (
      <Grid item>
        <Tooltip
          title={toolTipMessage || label}
          disableFocusListener={!showToolTip}
          disableHoverListener={!showToolTip}
          disableTouchListener={!showToolTip}>
          {this.getMiddleInfo()}
        </Tooltip>
      </Grid>
    );
  }
}
SonifiIconButton.propTypes = {
  disabled: PropTypes.bool,
  extraStyles: PropTypes.object,
  icon: PropTypes.object,
  label: PropTypes.string,
  marginTop: PropTypes.number,
  onClick: PropTypes.func,
  showToolTip: PropTypes.bool,
  testLabel: PropTypes.string,
  toolTipMessage: PropTypes.string,
  toolTipIcon: PropTypes.object,
  usePrimary: PropTypes.bool,
  toolTipHeaderText: PropTypes.string,
  useTertiary: PropTypes.bool
};

export default SonifiIconButton;
