import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
const styles = (theme) => ({
  disabledText: {
    color: 'red'
  },
  white: {
    fontWeight: 400, /* Avenir 55 */
    color: 'white',
    fontFamily: 'Open Sans',
    fontSize: '12pt'
  },
  error: {
    color: 'red',
    margin: 0,
    fontSize: '10pt',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
});

class ChannelNumberTextField extends Component {
  handleClickEnter = (event) => {
    if (event.key === 'Enter') {
      this.props.enterFunc();
    }
  };

  render() {
    const {
      disabled, redText, classes, value, readOnly
    } = this.props;

    return (
      <Fragment>
        <TextField
          variant="outlined"
          value={value ? value : ''}
          style={{ paddingLeft: 0, marginLeft: 0, backgroundColor: 'white', display: 'flex' }}
          type={'number'}
          disabled={disabled || readOnly}
          onKeyPress={this.handleClickEnter}
          onBlur={this.props.onBlur}
          onChange={this.props.editFunc}
          InputProps={{
            className: `${redText ? classes.disabledText : ''}`
          }}
        />
      </Fragment>
    );
  }
}

ChannelNumberTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  redText: PropTypes.bool,
  value: PropTypes.number,
  readOnly: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  editFunc: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  enterFunc: PropTypes.func
};

export default (withStyles(ChannelNumberTextField, styles, { withTheme: true }));
