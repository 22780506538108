import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { findObjectByKey } from '../../../utils/index';

class ConfigCard extends Component {
  render() {
    const {
      helperClass, lineups, translations, getItem, selectedTerm, languages, menusets
    } = this.props;

    const languageName = findObjectByKey(languages, 'id', selectedTerm.language) || { name: selectedTerm.language };
    const menusetName = findObjectByKey(menusets, 'id', selectedTerm.menuset) || { name: selectedTerm.menuset };
    const lineupName = findObjectByKey(lineups, 'id', selectedTerm.lineup_id) || { name: selectedTerm.lineup_id };
    return (
      <Grid container justifyContent="space-between" className={helperClass.card} >
        {getItem(translations.location, selectedTerm.location)}
        {getItem(translations.lineup, lineupName.name)}
        {getItem(translations.menuset, menusetName.name)}
        {getItem(translations.interactiveEng, selectedTerm.interactive_engine || '')}
        {getItem(translations.language, languageName.name)}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.termGrid.changed,
  languages: state.termGrid.languages,
  lineups: state.termGrid.lineups,
  menusets: state.termGrid.menusets,
  selectedTerm: state.termGrid.selectedTerm,
  translations: state.termGrid.translations.terminalInfo
});

ConfigCard.propTypes = {
  getItem: PropTypes.func,
  helperClass: PropTypes.object,
  languages: PropTypes.array.isRequired,
  lineups: PropTypes.array.isRequired,
  menusets: PropTypes.array.isRequired,
  selectedTerm: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(ConfigCard);
