import { CheckCircleOutline, Clear, ErrorOutline } from '@mui/icons-material';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { TERMINAL_DETAIL_EDIT, TERMINAL_DETAIL_EDIT_LIMITED } from '../../../constants/roles';
import SonifiPopover from '../../../containers/SonifiPopover';
import { getNameFromObjectArray } from '../../../utils';
import { checkForAtLeastOneUserPermission, checkSingleUserPermission } from '../../../utils/rolesUtil';
import { removeNewTerminal, updateSelectedTerminal, updateTerminal } from '../actions/terminalActions';

const styles = (theme) => ({
  goodStatus: {
    color: `${theme.palette.defaults.green} !important`
  },
  badStatus: {
    color: `${theme.palette.defaults.red} !important`
  }
});

class Row extends Component {
  handleEditDialog() {
    this.props.dispatch(updateSelectedTerminal(this.props.rowIndex));
  }

  handleDeleteDialog() {
    const { dispatch, rowIndex, terminals } = this.props;
    const rowTerm = terminals[rowIndex];
    if (rowTerm.id === 'Add') {
      dispatch(removeNewTerminal(rowIndex));
    } else {
      dispatch(updateTerminal(rowIndex, 'edited', true));
      dispatch(updateTerminal(rowIndex, 'remove', true));
    }
  }

  /* eslint-disable camelcase */
  getRowDetails() {
    const {
      classes, rowIndex, terminals, menusets, lineups, languages, translations
    } = this.props;

    const {
      id, interactive_engine, language, lineup_id, location, menuset, passive_status, swapId
    } = terminals[rowIndex];

    return (
      <Fragment>
        <TableCell component="th" scope="row">
          {passive_status !== 'bad'
            ? <Tooltip title={translations.goodTerm}><CheckCircleOutline className={classes.goodStatus} /></Tooltip>
            : <Tooltip title={translations.badTerm}><ErrorOutline className={classes.badStatus} /></Tooltip> || ''}
        </TableCell>
        <TableCell component="th" scope="row">{location}</TableCell>
        <TableCell component="th" scope="row" > {getNameFromObjectArray(languages, language)}</TableCell>
        <TableCell component="th" scope="row">{getNameFromObjectArray(lineups, lineup_id)}</TableCell>
        <TableCell component="th" scope="row">{getNameFromObjectArray(menusets, menuset)}
          {interactive_engine ? ' : '.concat(interactive_engine) : ''}</TableCell>
        <TableCell component="th" scope="row">{`${swapId || id}`}</TableCell>
      </Fragment >
    );
  }

  /* eslint-disable camelcase */
  render() {
    const {
      globalTranslations, rowIndex, terminals, userPermissions
    } = this.props;

    const { lineup_id, remove } = terminals[rowIndex];

    if (!lineup_id || remove) {
      return <Fragment />;
    }

    if (checkForAtLeastOneUserPermission([TERMINAL_DETAIL_EDIT, TERMINAL_DETAIL_EDIT_LIMITED], userPermissions)) {
      return (
        <SonifiPopover
          editTitle={globalTranslations.edit}
          editFunc={this.handleEditDialog.bind(this)}
          deleteTitle={globalTranslations.remove}
          deleteFunc={this.handleDeleteDialog.bind(this)}
          deleteIcon={<Clear fontSize="small" />}
          hideDelete={!checkSingleUserPermission(TERMINAL_DETAIL_EDIT, userPermissions)}
          key={rowIndex}
          tableRow={this.getRowDetails()}
        />
      );
    }
    return (
      <TableRow key={rowIndex}>
        {this.getRowDetails()}
      </TableRow >
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  languages: state.termGrid.languages,
  lineups: state.terminals.lineups,
  menusets: state.termGrid.menusets,
  selectedTerminal: state.terminals.selectedTerminal,
  terminals: state.terminals.terminals,
  translations: state.rooms.translations.editDialog,
  userPermissions: state.global.permissions
});

Row.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  languages: PropTypes.array.isRequired,
  lineups: PropTypes.array.isRequired,
  menusets: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired,
  selectedTerminal: PropTypes.number.isRequired,
  terminals: PropTypes.array.isRequired,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(Row, styles, { withTheme: true }));

