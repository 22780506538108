import { Add } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { STREAM_CHANNEL_ROLES } from '../../constants/roleGroups';
import { SERVER_STREAM_EDIT } from '../../constants/roles';
import { SonifiLabel, SonifiLockoutModalSpinner, SonifiSpinner, SonifiTableHead } from '../../containers';
import SonifiTemplate from '../../containers/SonifiTemplate';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';
import * as actions from './actions/cloudStreamAction';
import CloudStreamDialog from './components/CloudStreamDialog';
import CloudStreamRow from './components/cloudStreamRow';

class CloudStream extends Component {
  componentDidMount() {
    this.props.dispatch(actions.getCloudStreamChans());
  }

  addChannel() {
    this.props.dispatch(actions.addStreamChannel());
  }

  getPageDetails() {
    const { cloudChans, loading, translations, userPermissions } = this.props;
    const tableHeader = [
      { id: 'id', sortable: false, numeric: false, label: translations.id },
      { id: 'name', sortable: false, numeric: false, label: translations.name },
      { id: 'entry', sortable: false, numeric: false, label: translations.entry },
      { id: 'description', sortable: false, numeric: false, label: translations.desc }
    ];

    const canEdit = checkForSingleUserPermission(SERVER_STREAM_EDIT, userPermissions);

    return (
      <Table stickyHeader={true}>
        <SonifiTableHead headColumns={tableHeader} />
        <TableBody>
          {loading
            ? <TableRow><TableCell colSpan={4}><SonifiSpinner /></TableCell></TableRow>
            : <Fragment>
              {!cloudChans || cloudChans.length === 0
                ? <TableRow>
                  <TableCell colSpan={4}><SonifiLabel error label="No channels" /></TableCell>
                </TableRow>
                : cloudChans.map((info, index) => (
                  <CloudStreamRow key={index} index={index} canEdit={canEdit} />
                ))
              }
            </Fragment>
          }
        </TableBody>
      </Table>);
  }

  render() {
    const {
      cloudStreamIndex, globalTranslations, loading, saving, translations, userPermissions
    } = this.props;
    if (!checkForAtLeastOneUserPermission(STREAM_CHANNEL_ROLES, userPermissions)) {
      return <Navigate replace to="/global" />;
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={saving} />
        <SonifiTemplate
          header={translations.title}
          icon={<Add />}
          label={globalTranslations.add}
          onSubmit={this.addChannel.bind(this)}
          pageDetails={this.getPageDetails()}
          showButton={!loading && checkForSingleUserPermission(SERVER_STREAM_EDIT, userPermissions)}
        />
        {cloudStreamIndex !== -1 && <CloudStreamDialog />}
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  cloudChans: state.cloudStream.cloudChans,
  cloudStreamIndex: state.cloudStream.cloudStreamIndex,
  loading: state.cloudStream.loading,
  globalTranslations: state.global.translations.defaults,
  saving: state.cloudStream.saving,
  translations: state.cloudStream.translations.grid,
  userPermissions: state.global.permissions
});

CloudStream.propTypes = {
  dispatch: PropTypes.func,
  cloudChans: PropTypes.array,
  loading: PropTypes.bool,
  cloudStreamIndex: PropTypes.number,
  globalTranslations: PropTypes.object,
  translations: PropTypes.object,
  saving: PropTypes.bool,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(CloudStream);
