/* eslint-disable camelcase */
// import { USER_GROUPS } from '../../../constants/apiEndpoints';
import { find } from 'lodash';
import { MAX_IP_PORT } from '../../../constants/magic';
import {
  AGILYSYS, CMD_TIMEOUT_MAX, CMD_TIMEOUT_MIN, FIAS_IP, FIAS_SERIAL,
  GENERATOR, parity, PMSD
} from '../constants/constants';

export function validIntegration(selectedIntegration, translations) {
  let errors = {};

  // if (selectedIntegration.category === null || selectedIntegration.category === undefined) {
  //   errors.category = translations.needCategory;
  // }

  if (selectedIntegration.type === null || selectedIntegration.type === undefined) {
    errors.type = translations.needType;
  } else if (selectedIntegration.type === PMSD) {
    const pmsdErrors = validatePmsd(selectedIntegration, translations);
    errors = { ...errors, ...pmsdErrors };
  } else if (selectedIntegration.type === AGILYSYS) {
    const agilysysErrors = validateAgilysys(selectedIntegration, translations);
    errors = { ...errors, ...agilysysErrors };
  } else if (selectedIntegration.type === GENERATOR) {
    const generatorErrors = validateGenerator(selectedIntegration, translations);
    errors = { ...errors, ...generatorErrors };
  }

  return errors;
}

export function validatePmsd(selectedIntegration, translations) {
  let pmsdErrors = {};
  const pmsdInterface = selectedIntegration.interface;
  const pmsdSerial = selectedIntegration.port;
  const pmsdIp = selectedIntegration.ip;

  if (pmsdInterface.interface_type === null || pmsdInterface.interface_type === undefined) {
    pmsdErrors.interface_type = translations.interface_type;
  } else if (pmsdInterface.interface_type === FIAS_IP) {
    const ipErrors = validateIp(pmsdIp, translations);
    pmsdErrors = { ...pmsdErrors, ...ipErrors };
  } else {
    const serialErrors = validateSerial(pmsdSerial, translations);
    pmsdErrors = { ...pmsdErrors, ...serialErrors };
  }

  if (pmsdInterface.interface_level === null || pmsdInterface.interface_level === undefined) {
    pmsdErrors.interface_level = translations.interface_level;
  }

  if (pmsdInterface.post_tax_separate &&
    pmsdInterface.interface_type !== FIAS_IP && pmsdInterface.interface_type !== FIAS_SERIAL) {
    const postTaxSeparateErrors = validatePostTaxSeparate(selectedIntegration, translations);
    pmsdErrors = { ...pmsdErrors, ...postTaxSeparateErrors };
  }

  // if (pmsdInterface.timeout === null || pmsdInterface.interface_level === undefined ||
  //   pmsdInterface.timeout === '' || pmsdInterface.timeout.length === 0) {
  //   pmsdErrors.timeout = translations.timeout;
  // } else if (pmsdInterface.timeout < 0 || pmsdInterface.timeout > 3600) {
  //   pmsdErrors.timeout = translations.timeoutLimit;
  // }

  return pmsdErrors;
}

export function validateSerial(pmsdSerial, translations) {
  const serialErrors = {};
  const selected = find(parity, (o) => o.value === pmsdSerial.parity);
  if (selected === undefined) {
    serialErrors.parity = translations.parity;
  }
  return serialErrors;
}

export function validateIp(pmsdIp, translations) {
  const ipErrors = {};

  /* eslint-disable max-len */
  if (!(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(pmsdIp.address)) {
    ipErrors.address = translations.address;
  }

  const portNumber = parseInt(pmsdIp.port, 10);
  if (isNaN(portNumber)) {
    ipErrors.port = translations.portNumber;
  } else if (portNumber < 1 || portNumber > MAX_IP_PORT) {
    ipErrors.port = translations.port;
  }

  const command_timeout = parseInt(pmsdIp.command_timeout, 10);
  if (isNaN(command_timeout)) {
    ipErrors.command_timeout = translations.sendTimeoutNum;
  } else if (command_timeout < CMD_TIMEOUT_MIN || command_timeout > CMD_TIMEOUT_MAX) {
    ipErrors.command_timeout = translations.commandTimeout;
  }

  return ipErrors;
}

export function validateAgilysys(selectedIntegration, translations) {
  const agilysysErrors = {};
  console.log('validateAgilysys', selectedIntegration, translations);

  return agilysysErrors;
}

export function validateGenerator(selectedIntegration, translations) {
  const generatorErrors = {};
  console.log('validateGenerator', selectedIntegration, translations);

  return generatorErrors;
}

export function validatePostTaxSeparate(selectedIntegration, translations) {
  const postTaxSeparateErrors = {};
  const pmsdInterface = selectedIntegration.interface;
  if (!pmsdInterface.tax_description) {
    postTaxSeparateErrors.tax_description = translations.tax_description;
  }
  if (!pmsdInterface.revenue_code_tax) {
    postTaxSeparateErrors.revenue_code_tax = translations.revenue_code_tax;
  }

  if (!selectedIntegration.map.T1 || selectedIntegration.map.T1 === undefined) {
    postTaxSeparateErrors.T1 = translations.T1;
  }
  if (!selectedIntegration.map.S1 || selectedIntegration.map.S1 === undefined) {
    postTaxSeparateErrors.S1 = translations.S1;
  }

  return postTaxSeparateErrors;
}
