import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  CHANNEL_ROLES, KEY_STROKE_ROUTER_ROLES, LATE_CHECKOUT_ROLES, ROOM_ROLES, SITE_ROLES, SONIFI_ROLES, SYSTEM_ROLES,
  TERMINAL_DETAIL_ROLES, TV_TERMINAL_FILE_ROLES
} from '../../constants/roleGroups';
import { SONIFI_ADMIN } from '../../constants/roles';
import Navigation from '../../containers/Navigation';
import { getLateCheckoutFromSession, getSiteNumFromURI, hasITv, siteIsOnlyDWB } from '../../utils';
import { isProduction } from '../../utils/api';
import { createLink } from '../../utils/links';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';
import Channels from '../Channels2';
import Sandbox from '../ComponentSandbox/Sandbox';
import Icp from '../Icp';
import Integrations from '../Integrations';
import LateCheckout from '../LateCheckout';
import Rooms from '../Rooms';
import Site from '../SiteLocationSelection/Site';
import SiteManagement from '../SiteManagement';
import SoftwareManagement from '../SoftwareManagement';
import SystemManagement from '../SystemManagement';
import TermFiles from '../TermFiles/TermFiles';
import NewTerminals from '../Terminals/Terminals';

class System extends Component {
  configure() {
    const { marinaOnlySite, networksUsed, translations, userPermissions } = this.props;
    const { configure } = translations.system;
    const title = configure.title;
    const items = [];

    if (checkForAtLeastOneUserPermission(SITE_ROLES, userPermissions)) {
      items.push(createLink(configure.siteManagement, 'siteManagement', SiteManagement));
    }

    if (marinaOnlySite) {
      return { title, items };
    }

    if (checkForAtLeastOneUserPermission(SYSTEM_ROLES, userPermissions)) {
      items.push(createLink(configure.systemManagement, 'systemManagement', SystemManagement));
    }

    if (checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)) {
      items.push(createLink(configure.softwareManagement, 'softwareManagement', SoftwareManagement));

      // items.push(createLink(configure.activateSchedule, 'activateSchedule', ActivateSchedule));
    }

    if (checkForAtLeastOneUserPermission(CHANNEL_ROLES, userPermissions)) {
      items.push(createLink(configure.channelList, 'channels', Channels));
    }

    if (checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)) {
      items.push(createLink(configure.integrations, 'integrations', Integrations));
    }

    const hasAnalogRF = networksUsed && networksUsed.rf;
    if (hasAnalogRF && checkForAtLeastOneUserPermission(KEY_STROKE_ROUTER_ROLES, userPermissions)) {
      items.push(createLink(configure.ksr, 'icp', Icp));
    }

    return { title, items };
  }

  manage() {
    const { marinaOnlySite, translations, userPermissions } = this.props;
    const { manage } = translations.system;
    const title = manage.title;
    const items = [];

    if (marinaOnlySite) {
      return { title, items };
    }

    if (checkForAtLeastOneUserPermission(ROOM_ROLES, userPermissions)) {
      items.push(createLink(manage.rooms, 'rooms', Rooms));
    }

    if (checkForAtLeastOneUserPermission(TERMINAL_DETAIL_ROLES, userPermissions)) {
      items.push(createLink(manage.terminals, 'terminals', NewTerminals));
    }

    if (checkForAtLeastOneUserPermission(TV_TERMINAL_FILE_ROLES, userPermissions)) {
      items.push(createLink(manage.termFiles, 'termFiles', TermFiles));
    }

    const lateCheckout = getLateCheckoutFromSession();
    if (lateCheckout === 'true') {
      if (checkForAtLeastOneUserPermission(LATE_CHECKOUT_ROLES, this.props.userPermissions)) {
        items.push(createLink(manage.lateCheckout, 'lateCheckout', LateCheckout));
      }
    }

    return { title, items };
  }

  devel() {
    const { devel } = this.props.translations.system;
    const title = devel.title;
    const items = [];
    items.push(createLink(devel.selection, 'selection', Site));
    items.push(createLink(devel.componentsandbox, 'componentsandbox', Sandbox));
    return { title, items };
  }

  configureDwb() {
    const { productParents, translations, userPermissions } = this.props;
    const { configure } = translations.system;
    const title = configure.title;
    const items = [];

    if (checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)) {
      items.push(createLink(configure.softwareManagement, 'softwareManagement', SoftwareManagement));
    }

    if (checkForAtLeastOneUserPermission(SITE_ROLES, userPermissions) && hasITv(productParents)) {
      items.push(createLink(configure.siteManagement, 'siteManagement', SiteManagement));
    }

    return { title, items };
  }

  render() {
    if (getSiteNumFromURI() === undefined) {
      return <Navigate to="/" />;
    }

    if (siteIsOnlyDWB(this.props.productParents)) {
      const links = [...this.configureDwb().items];
      const stacked = [this.configureDwb()];

      return (
        <Navigation links={links} stackedNav={stacked} />
      );
    }

    const configureLinks = this.configure();
    const manageLinks = this.manage();

    const links = [];
    const stackedLinks = [];
    if (configureLinks.items.length > 0) {
      links.push(...configureLinks.items);
      stackedLinks.push(configureLinks);
    }
    if (manageLinks.items.length > 0) {
      links.push(...manageLinks.items);
      stackedLinks.push(manageLinks);
    }
    if (!isProduction(this.props.isProd) && checkForSingleUserPermission(SONIFI_ADMIN, this.props.userPermissions)) {
      const develLinks = this.devel();
      links.push(...develLinks.items);
      stackedLinks.push(develLinks);
    }

    return (
      <Navigation links={links} stackedNav={stackedLinks} />
    );
  }
}

const mapStateToProps = (state) => ({
  isProd: state.global.isProd,
  marinaOnlySite: state.global.marinaOnlySite,
  networksUsed: state.termGrid.networksUsed,
  productParents: state.global.site.product_parents,
  translations: state.global.translations,
  userPermissions: state.global.permissions
});

System.propTypes = {
  dispatch: PropTypes.func,
  isProd: PropTypes.bool,
  marinaOnlySite: PropTypes.bool,
  networksUsed: PropTypes.object,
  productParents: PropTypes.array,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export { System };
export default connect(mapStateToProps)(System);
