import { Delete } from '@mui/icons-material';
import { Grid, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import {
  cloneDeep, differenceWith, filter, findIndex, isEmpty, isEqual, sortBy, trim
} from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { connect } from 'react-redux';
import { ERROR } from '../../../../constants/constants';
import { KEYCODE_ENTER, KEYCODE_ESCAPE } from '../../../../constants/keyCodes';
import { SITE_EDIT, SITE_LIMITED } from '../../../../constants/roles';
import DragNDropIcon from '../../../../containers/DragNDrop/DragNDropIcon';
import SonifiConfirm from '../../../../containers/SonifiConfirm';
import SonifiError from '../../../../containers/SonifiError';
import SonifiIconButton from '../../../../containers/SonifiIconButton';
import SonifiLabel from '../../../../containers/SonifiLabel';
import SonifiTextInline from '../../../../containers/SonifiTextInline';
import { checkForAtLeastOneUserPermission } from '../../../../utils/rolesUtil';
import { updateDeleteRow, updateSnackBar } from '../../actions/siteManagementActions';
import {
  TERMLOC_ERROR_DUPLICATE, TERMLOC_ERROR_EMPTY, TERMLOC_NEW_ITEM
} from '../../constants/SiteContants';
import { getTerminalStyle, getTermLocItemStyle, reorder } from '../../utils';

function isArrayEqual(x, y) {
  return isEmpty(differenceWith(x, y, isEqual));
}

const filterOptions = createFilterOptions();

class TerminalLocations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editSequenceId: -1,
      editOriginalName: '',
      inputErrorMessage: '',
      createErrorMessage: '',
      newLocation: '',

      // This component should only modify terminalLocations
      // and then use setState. That will trigger the componentDidUpdate
      // hook to keep everything else in sync.
      terminalLocations: [],
      // Force update is for forcing a componentDidUpdate update.
      // If we're simply reordering the list, the arrays are still equal
      // so we force an update.
      forceUpdate: false,

      activeLocations: [],
      inactiveLocations: [],
      inactiveLocationNames: []
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    this.keyPressListener = this.keyPressListener.bind(this);
    // this.onLocationChangeHandler = this.onLocationChangeHandler.bind(this);
    // this.newLocationPressListener = this.newLocationPressListener.bind(this);
  }

  componentDidMount() {
    const sortedTerminalLocations = sortBy(
      this.props.terminalLocations, ['sequence']
    );
    const state = this._setupLocationsState(sortedTerminalLocations);

    this.props.setTerminalLocations(state.terminalLocations, false, false);
    this.setState(state);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      !isArrayEqual(this.state.terminalLocations, prevState.terminalLocations)
      || this.state.forceUpdate
    ) {
      const state = this._setupLocationsState(this.state.terminalLocations);
      this.props.setTerminalLocations(state.terminalLocations, !!this.state.inputErrorMessage);
      this.setState(state);
    }
  }

  _setupLocationsState(locations) {
    const terminalLocations = this._resequenceActiveLocations(locations);
    const activeLocations = this.state.terminalLocations.filter((location) => location.is_active);
    const inactiveLocations = this.state.terminalLocations.filter((location) => !location.is_active);
    const inactiveLocationNames = inactiveLocations.map((location) => location.name);
    inactiveLocationNames.sort();

    return {
      terminalLocations,
      activeLocations,
      inactiveLocations,
      inactiveLocationNames,
      forceUpdate: false
    };
  }

  _resequenceActiveLocations(locations) {
    let sequence = 0;

    return locations.map((location) => {
      if (!location?.is_active) {
        return location;
      }

      location.sequence = sequence;
      sequence++;
      return location;
    });
  }

  resetTerm() {
    this.setState({ editSequenceId: -1, editOriginalName: '' });
  }

  editTermName(termSequenceIndex) {
    if (!this.state.inputErrorMessage) {
      const editOriginalName = this.state.activeLocations.filter(((e) => e.sequence === termSequenceIndex))[0].name;
      this.setState({
        editSequenceId: termSequenceIndex,
        editOriginalName
      });
    }
  }

  handleDeleteDialog(termLocIndex, itemName) {
    const { dispatch } = this.props;
    dispatch(updateDeleteRow('activeLocations', termLocIndex, null, itemName));
  }

  confirmDialogConfirmFunc() {
    const { termLocIndex } = this.props;
    this.deleteTerminalLocation(termLocIndex);
    this.props.dispatch(updateDeleteRow('activeLocations', null, null));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteRow('activeLocations', null, null));
  }

  deleteTerminalLocation(sequenceId) {
    const terminalLocations = this._deactivateLocation(sequenceId);

    this.setState({
      terminalLocations,
      editSequenceId: -1,
      editOriginalName: '',
      inputErrorMessage: '',
    });
  }

  _deactivateLocation(sequenceId) {
    const terminalLocations = cloneDeep(this.state.terminalLocations);
    const deactivateIndex = terminalLocations.findIndex(
      (location) => location.sequence === sequenceId
    );
    const deactivateLocation = terminalLocations[deactivateIndex];
    terminalLocations[deactivateIndex] = {
      id: deactivateLocation.id,
      sequence: null,
      name: deactivateLocation.name,
      description: deactivateLocation.description,
      count: deactivateLocation.count,
      is_active: false,
    };

    return terminalLocations;
  }

  onDragEnd(result) {
    if (!result.destination) {
      // Dropped outside the list
      return;
    }

    let locations = cloneDeep(this.state.activeLocations);
    locations = reorder(
      locations,
      result.source.index,
      result.destination.index
    );
    for (const location of cloneDeep(this.state.inactiveLocations)) {
      locations.push(location);
    }

    this.setState({ terminalLocations: locations, editSequenceId: -1, forceUpdate: true });
  }

  handleChangeTerminalLocation(terminalIndex) {
    return ({ target: { value } }) => {
      this.changeTerminalLocation(terminalIndex, value);
    };
  }

  checkForDuplicate(terminalLocations, newValue) {
    const foundIndex = findIndex(
      terminalLocations,
      (location) => {
        const hasSameName = location.name.toUpperCase() === newValue.toUpperCase();
        const notEditSequenceId = location.sequence !== this.state.editSequenceId;
        return hasSameName && notEditSequenceId;
      }
    );

    return foundIndex !== -1;
  }

  changeTerminalLocation(activeIndex, newLocationName) {
    const terminalLocations = cloneDeep(this.state.terminalLocations);

    const activeLocations = cloneDeep(this.state.activeLocations);
    const currentLocation = activeLocations[activeIndex];
    const currentLocationIndex = terminalLocations.findIndex(
      (location) => location.name === currentLocation.name
    );

    const newLocationIndex = terminalLocations.findIndex(
      (location) => location.name === newLocationName
    );
    const newLocation = terminalLocations[newLocationIndex];

    newLocation.sequence = currentLocation.sequence;
    newLocation.is_active = true;
    newLocation.count = 0;
    currentLocation.sequence = null;
    currentLocation.is_active = false;

    terminalLocations[currentLocationIndex] = newLocation;
    terminalLocations[newLocationIndex] = currentLocation;

    let inputErrorMessage = '';
    if (newLocationName.length === 0 || !newLocationName.trim()) {
      inputErrorMessage = TERMLOC_ERROR_EMPTY;
    }

    this.setState({ terminalLocations, inputErrorMessage });
  }

  // listen for either an enter or escape keypress to cancel edit
  // We can add a keypress event to SonifiTextInline, but for some reason
  // doesn't return when the ESC key is pressed.  find out why?
  keyPressListener(event) {
    if (event.target.type === 'text') {
      if (event.keyCode === KEYCODE_ESCAPE) {
        // on escape, set name back to original
        this.cancelEdit();
      } else if (event.keyCode === KEYCODE_ENTER) {
        if (this.checkForDuplicate(this.state.inactiveLocations, trim(event.target.value))) {
          this.setState({
            inputErrorMessage: TERMLOC_ERROR_DUPLICATE,
            forceUpdate: true
          });
        } else if (!this.state.inputErrorMessage) {
          this.setState({
            editSequenceId: -1
          });
        }
      }
    }
  }

  cancelEdit() {
    const activeLocations = this.state.activeLocations;
    const position = findIndex(
      activeLocations,
      (location) => location.sequence === this.state.editSequenceId
    );
    activeLocations[position].name = this.state.editOriginalName;

    // if escape pressed on new item, remove it
    if (
      (activeLocations[position].id === null)
      && (activeLocations[position].name === TERMLOC_NEW_ITEM)
    ) {
      this.deleteTerminalLocation(activeLocations[position].sequence);
    } else {
      this.props.setTerminalLocations(activeLocations, false);
      this.setState({
        activeLocations,
        editSequenceId: -1,
        editOriginalName: '',
        inputErrorMessage: '',
      });
    }
  }

  checkLocationPressListener() {
    const { snackbarTranslations, dispatch } = this.props;

    if (this.state.newLocation === '') {
      this.setState({
        createErrorMessage: TERMLOC_ERROR_EMPTY
      });
      dispatch(updateSnackBar(ERROR, snackbarTranslations.locationEmpty));
      return;
    }

    if (this.state.inputErrorMessage) {
      return;
    }

    if (this.checkForDuplicate(this.state.activeLocations, trim(this.state.newLocation))) {
      this.setState({
        createErrorMessage: TERMLOC_ERROR_DUPLICATE,
        newLocation: ''
      });
      dispatch(updateSnackBar(ERROR, snackbarTranslations.locationExists));
      return;
    }

    let terminalLocations = cloneDeep(this.state.terminalLocations);
    const newSequenceId = this.state.activeLocations.length;

    if (this.checkForDuplicate(this.props.terminalLocations, trim(this.state.newLocation))) {
      const existingLocationIndex = terminalLocations.findIndex(
        (location) => location.name === this.state.newLocation
      );
      terminalLocations[existingLocationIndex].sequence = newSequenceId;
      terminalLocations[existingLocationIndex].is_active = true;
    } else {
      terminalLocations.push({
        id: null,
        name: trim(this.state.newLocation),
        description: null,
        is_active: true,
        sequence: newSequenceId,
        count: 0,
      });
    }

    terminalLocations = sortBy(terminalLocations, ['sequence']);
    this.setState({ terminalLocations, newLocation: '' });
  }

  showAvailableLocations(location) {
    const options = this.state.inactiveLocationNames.map((suggestion) => ({
      id: suggestion,
      value: suggestion,
      label: suggestion
    }));

    return [
      {
        id: location.name,
        value: location.name,
        label: location.name
      },
      ...options
    ];
  }

  handleOnChange = (event, value) => {
    this.setState({ newLocation: (value === null ? '' : value) });
  };

  render() {
    const {
      activeLocations, editSequenceId, inputErrorMessage, newLocation
    } = this.state;
    const {
      deleteType, generalTranslations, globalTranslations, itemName, termLocIndex, terminalLocations,
      translations, userPermissions,
    } = this.props;

    const allowEdit = checkForAtLeastOneUserPermission([SITE_EDIT], userPermissions);
    const allowReorder = checkForAtLeastOneUserPermission([SITE_EDIT, SITE_LIMITED], userPermissions);
    const autoCompleteOptions = filter(this.state.terminalLocations, (o) => !o.is_active);

    return (
      <Fragment>
        <Grid container justifyContent="space-between" style={{ height: 'calc(100% - 1px)' }}>
          <Grid item xs={12} style={{ alignItems: 'center', display: 'flex' }}>
            <SonifiLabel boldLabel={generalTranslations.termLocations} />
          </Grid>

          {allowEdit &&
            <Grid item xs={12}>
              <Autocomplete
                defaultValue={newLocation}
                sx={{ width: '100%' }}
                disabled={inputErrorMessage !== '' || (activeLocations.length >= terminalLocations.length)}
                value={newLocation}
                filterOptions={(options, params) => {
                  const filtered = filterOptions(options, params);
                  if (params.inputValue !== '') {
                    filtered.push({
                      name: `Add "${params.inputValue}"`,
                      value: params.inputValue,
                      isNew: true
                    });
                  }
                  return filtered;
                }}
                onChange={(event, newValue, reason) => {
                  if (reason === 'clear') {
                    this.setState({ newLocation: '' });
                    return;
                  }

                  this.setState({
                    newLocation: (typeof newValue === 'string'
                      ? newValue
                      : (
                        newValue.isNew ? newValue.value : newValue.name
                      ))
                  }, () => {
                    this.checkLocationPressListener();
                  });
                }}
                options={autoCompleteOptions}
                freeSolo
                selectOnFocus
                clearOnBlur
                getOptionLabel={(option) => option.name ?? option}
                renderInput={(params) => <TextField {...params} label={translations.addNewLocation} />}
              />
            </Grid>
          }

          <Grid item xs={12} style={{ height: allowEdit ? '88%' : '100%' }}>
            <DragDropContext onDragEnd={this.onDragEnd} >
              <Droppable droppableId="droppableTermLoc" type="termLoc">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getTerminalStyle(snapshot.isDraggingOver)}>
                    {activeLocations.map((location, index) => (
                      <Draggable
                        key={`activeLocations${index}`}
                        draggableId={`activeLocations${index}`}
                        isDragDisabled={!allowReorder || activeLocations.length < 2}
                        index={index} >
                        {(draggableProvided, draggableSnapshot) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            style={getTermLocItemStyle(
                              draggableSnapshot.isDragging,
                              draggableProvided.draggableProps.style,
                              index
                            )} >
                            <Grid container wrap="nowrap" style={{ padding: '3px' }}>
                              <Grid item xs={1}
                                {...draggableProvided.dragHandleProps}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}>
                                {allowReorder && activeLocations.length > 1 &&
                                  <DragNDropIcon style={{
                                    cursor: 'move',
                                    filter: `${draggableSnapshot.isDragging
                                      ? 'brightness(0) invert(1)'
                                      : 'brightness(1) invert(0)'}`,
                                    verticalAlign: 'middle',
                                    width: '20px'
                                  }} />}
                              </Grid>
                              <Grid item xs={5} onClick={this.editTermName.bind(this, location.sequence)}>
                                {allowEdit && location.sequence === editSequenceId
                                  ? <SonifiTextInline
                                    select
                                    items={this.showAvailableLocations(location)}
                                    defaultValue={location.name}
                                    change={this.handleChangeTerminalLocation(index)}
                                    handleKeyDown={this.keyPressListener}
                                    selectProps={{
                                      style: {
                                        maxHeight: '20%',
                                      },
                                    }}
                                  />
                                  : <SonifiLabel label={location.name} />}
                              </Grid>
                              <Grid item xs={5} onClick={this.resetTerm.bind(this)}>
                                {allowEdit && location.sequence === editSequenceId
                                  ? <SonifiError label={inputErrorMessage} />
                                  : <Fragment></Fragment>
                                }
                              </Grid>
                              <Grid item xl={1}>
                                {allowEdit && <SonifiIconButton label=""
                                  onClick={this.handleDeleteDialog.bind(this, location.sequence, location.name)}
                                  icon={<Delete />}
                                  useTertiary={draggableSnapshot.isDragging}
                                  disabled={location.count !== 0}
                                />}
                              </Grid>
                            </Grid>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Grid>
        </Grid>

        <SonifiConfirm
          dialogOpen={deleteType === 'activeLocations' && termLocIndex !== null && termLocIndex !== undefined}
          onConfirm={this.confirmDialogConfirmFunc.bind(this, termLocIndex)}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          confirmTitle={translations.deleteTerm}
          confirmText={`${translations.deleteText} ${(itemName
            ? `${itemName}?`
            : '')}`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  deleteType: state.siteManagement.deleteType,
  generalTranslations: state.siteManagement.translations.general,
  globalTranslations: state.global.translations.defaults,
  itemName: state.siteManagement.itemName,
  snackbarTranslations: state.siteManagement.translations.snackbarMessages,
  termLocIndex: state.siteManagement.buildingIndex,
  terminalLocations: state.terminals.locations,
  translations: state.siteManagement.translations.site,
  userPermissions: state.global.permissions
});

TerminalLocations.propTypes = {
  deleteType: PropTypes.string,
  dispatch: PropTypes.func,
  generalTranslations: PropTypes.object,
  globalTranslations: PropTypes.object,
  itemName: PropTypes.string,
  setTerminalLocations: PropTypes.func.isRequired,
  snackbarTranslations: PropTypes.object,
  termLocIndex: PropTypes.number,
  terminalLocations: PropTypes.array.isRequired,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(TerminalLocations);

