import {
  Authenticator, Flex, Grid, Image, View
} from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import '../../../AmplifyTheme.css';
import SonifiLabel from '../../../containers/SonifiLabel';
import LOGO from '../../../img/SONIFI_Logo_NoTag_Header.png';
import Background from '../../SignInAuth/components/Background';

const components = {
  header() {
    return (
      <View textAlign="center" paddingTop="23px"><Image alt="SONIFI" src={LOGO} /></View>
    );
  },

  footer() {
    return (
      <View textAlign="center" padding={10}>
        <SonifiLabel size="xs" label={
          `Copyright© ${new Date().getFullYear()} SONIFI Solutions Inc.  All Rights Reserved`} />
      </View>
    );
  },
  forceNewPassword() {
      return (
        <Fragment>
          <Authenticator.ForceNewPassword.FormFields />
          <Grid container style={{ justifyContent: 'space-evenly' }}>
            <Grid item>
              <SonifiLabel size="xs" label="Must be between 10-64 characters." />
            </Grid>
            <Grid item>
              <SonifiLabel size="xs" label="Must contain mix of letters and numbers" />
            </Grid>
            <Grid item>
              <SonifiLabel size="xs" label="Need to include a non-alphanumeric character" />
            </Grid>
          </Grid>
        </Fragment>
      );
  }
};

const FormFrame = (props) => (
    <Fragment>
      <div style={{ position: 'relative' }}>
        <Flex justifyContent={'flex-end'} >
          <View height="auto"
            style={{
              minWidth: '30vw',
              minHeight: '40vh',
              position: 'absolute',
              top: '5%',
              backgroundColor: 'white',
              padding: '1vh 5vw',
              margin: '5% 3%'
            }}
          >
            {components.header()}
            {props.children}
            {components.footer()}
          </View>
        </Flex>
        <Background />
      </div>
    </Fragment>
  );

FormFrame.propTypes = {
  children: PropTypes.node,
  user: PropTypes.object
};

export default FormFrame;
