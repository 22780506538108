import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  GROUP_ROLES, ROLES_ROLES, SOFTWARE_ROLES, STREAM_CHANNEL_ROLES, USER_ACCOUNT_ROLES
} from '../../constants/roleGroups';
import { SONIFI_ADMIN } from '../../constants/roles';
import Navigation from '../../containers/Navigation';
import { isProduction } from '../../utils/api';
import { createLink } from '../../utils/links';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';
import CloudStream from '../CloudStream';
import Deployments from '../Deployments';
import GroupManagement from '../GroupManagement';
import ReleaseTemplates from '../ReleaseTemplates';
import RoleManagement from '../RoleManagement';
import RolesDev from '../RolesDev/RolesDev';
import SoftwareChanges from '../SoftwareChanges';
import UserManagement from '../UserManagement';

class Manage extends Component {
  manage() {
    const { isProd, translations, userPermissions } = this.props;
    const title = translations.title;
    const items = [];

    if (!isProduction(isProd) && checkForAtLeastOneUserPermission(ROLES_ROLES, userPermissions)) {
      items.push(createLink(translations.role, 'roleManagement', RoleManagement));
      items.push(createLink(translations.dev, 'roleManagementDev', RolesDev));
    }

    if (checkForAtLeastOneUserPermission(SOFTWARE_ROLES, userPermissions)) {
      items.push(createLink(translations.releaseTemplates, 'releaseTemplates', ReleaseTemplates));
      items.push(createLink(translations.deployments, 'deployments', Deployments));
    }

    if (checkForSingleUserPermission(SONIFI_ADMIN, userPermissions)) {
      items.push(createLink(translations.software, 'software', SoftwareChanges));
    }

    if (checkForAtLeastOneUserPermission(USER_ACCOUNT_ROLES, userPermissions)) {
      items.push(createLink(translations.user, 'userManagement', UserManagement));
    }

    if (checkForAtLeastOneUserPermission(GROUP_ROLES, userPermissions)) {
      items.push(createLink(translations.group, 'groupManagement', GroupManagement));
    }

    if (checkForAtLeastOneUserPermission(STREAM_CHANNEL_ROLES, userPermissions)) {
      items.push(createLink(translations.cloudStream, 'cloudStream', CloudStream));
    }
    return { title, items };
  }

  render() {
    return (
      <Navigation links={this.manage().items} stackedNav={[this.manage()]} />
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  isProd: state.global.isProd,
  translations: state.global.translations.manage,
  userPermissions: state.global.permissions,
});

Manage.propTypes = {
  isProd: PropTypes.bool,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(Manage);
