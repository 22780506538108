import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiSpinner } from '../../containers';
import { checkLoginStatus, getTokenFromSession, redirectToLogin } from '../../utils';
import SonifiApp from '../SignInAuth/SonifiApp';

export class SSO extends Component {
  state = {
    isAuthenticated: false
  };

  componentDidMount() {
    let code = new URLSearchParams(window.location.search).get('code'),
      tokenCheck = getTokenFromSession();
    const authDomain = process.env.REACT_APP_COGNITO_DOMAIN_PREFIX;

    checkLoginStatus(code, tokenCheck, authDomain)
      .then((isLoggedIn) => {
        if (!isLoggedIn) {
          setTimeout(() => {
            code = new URLSearchParams(window.location.search).get('code');
            tokenCheck = getTokenFromSession();

            checkLoginStatus(code, tokenCheck, authDomain)
              .then(() => {
                redirectToLogin();
              }).catch((error1) => {
                console.log('Not logged in', error1);
                // this.setState({ isAuthenticated: false });
              });
          }, 500);
        } else {
          // dispatch(actions.loadLiterals(currentLanguage));
          // dispatch(actions.setProdStatus());

          // checkMobile();

          // try {
          //   const token = decode(getTokenFromSession());
          //   console.log('Angie:token', token);
          //   dispatch(actions.updateCurrentUser(token));

          //   if (token['custom:role']) {
          //     getObjectPromise(ROLES, token['custom:role']).then((data) => {
          //       data.permissions.push(token['custom:role']);
          //       dispatch(actions.updateRole(data));
          //       this.setState({ spinner: false });
          //     }).catch((error) => {
          //       console.log(error);
          //       this.setState({ spinner: false });
          //     });
          //   } else { // TODO: ASK WHAT TO DO HERE
          //     console.log('Could not find role for logged in user?');
          //   }

          //   if (token['custom:sites']) {
          //     dispatch(actions.updateSites((token['custom:sites'] && token['custom:sites'].length > 1)
          //       ? token['custom:sites'].split(' ')
          //       : ['*']));
          //     if (token['custom:group'] !== undefined) {
          //       dispatch(updateGroup(token['custom:group']));
          //       getObjectPromise(USER_GROUPS, token['custom:group'], { limit: 5 })
          //         .then((data) => {
          //           if (data.sites.length === 1) {
          //             if (data.sites[0] !== '') {
          //               this.setState({ showMenu: true });
          //             } else {
          //               dispatch(actions.updateGlobalError(
          //                 'There is an issue with your group.  Please contact your administrator.'
          //               ));
          //             }
          //           } else {
          //             this.setState({ showMenu: true });
          //           }
          //         }).catch((error) => {
          //           console.log('App:ERROR', error);
          //           dispatch(actions.updateGlobalError(
          //             'There is an issue with your group.  Please contact your administrator.'
          //           ));
          //         });
          //     }
          //   }
          // } catch(error) {
          //   console.log(error);
          // }
        }

        this.setState({
          isAuthenticated: isLoggedIn
        });
      }).catch((error) => {
        console.log('Not logged in', error);
        this.setState({ isAuthenticated: false });
      });
  }

  render() {
    return (
      this.state.isAuthenticated ? <SonifiApp /> : <SonifiSpinner />
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: state.global.currentLanguage,
});

SSO.propTypes = {
  currentLanguage: PropTypes.string,
  dispatch: PropTypes.func
};

export default connect(mapStateToProps)(SSO);
