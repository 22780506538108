// import { remove, uniqBy } from 'lodash';
import { TLX_RESOURCE } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import {
  deleteObjectPromise, getSiteCsvObjectPromise, getSiteObjectPromise, postObjectPromise
} from '../../../utils/api';
import { putFileNoAuthHeaderTlx } from '../../../utils/index';
import { LG } from '../constants/TermFileConstants';
import {
  CLEAR_TLX_FILE, DELETE_TLX, DELETE_TLX_FILE_BEGIN, DELETE_TLX_FILE_FAILURE, DELETE_TLX_FILE_SUCCESS, EDIT_TLX,
  EXPORT_TLX_FILE_BEGIN, EXPORT_TLX_FILE_FAILURE, EXPORT_TLX_FILE_SUCCESS, FETCH_TLX_FILE_BEGIN, FETCH_TLX_FILE_FAILURE,
  FETCH_TLX_FILE_SUCCESS, IMPORT_TLX_FILE_BEGIN, IMPORT_TLX_FILE_FAILURE, IMPORT_TLX_FILE_SUCCESS, RESET_SNACK_BAR
} from './actionTypes';
import { fetchTermFirmware } from './FirmwareActions';

export const updateDeleteTlxRow = (filename) => ({
  type: DELETE_TLX,
  filename
});

export const clearTLXFile = () => ({
  type: CLEAR_TLX_FILE,
});

export const fetchTLXFileBegin = () => ({
  type: FETCH_TLX_FILE_BEGIN,
});

export const fetchTLXFileSuccess = (tlxFile) => ({
  type: FETCH_TLX_FILE_SUCCESS,
  tlxFile,
});

export const fetchTLXFileFailure = (error) => ({
  type: FETCH_TLX_FILE_FAILURE,
  error,
});

export const importTLXFileBegin = () => ({
  type: IMPORT_TLX_FILE_BEGIN,
});

export const importTLXFileSuccess = (tlxFileLink) => ({
  type: IMPORT_TLX_FILE_SUCCESS,
  tlxFileLink,
});

export const importTLXFileFailure = (error) => ({
  type: IMPORT_TLX_FILE_FAILURE,
  error,
});

export const exportTLXFileBegin = () => ({
  type: EXPORT_TLX_FILE_BEGIN,
});

export const exportTLXFileSuccess = (tlxFileLink) => ({
  type: EXPORT_TLX_FILE_SUCCESS,
  tlxFileLink,
});

export const exportTLXFileFailure = (error) => ({
  type: EXPORT_TLX_FILE_FAILURE,
  error,
});

export function fetchTLXFile() {
  return (dispatch) => {
    dispatch(fetchTLXFileBegin());
    getSiteObjectPromise(TLX_RESOURCE, null, null, null)
      .then((tlxFile) => {
        dispatch(fetchTLXFileSuccess(tlxFile['tlx-files']));
      }).catch((error) => {
        try {
          error.json().then((err) => {
            dispatch(fetchTLXFileFailure(`${err.detail} (${err.status})`));
          });
        } catch(err) {
          dispatch(fetchTLXFileFailure(`${err.detail} (${err.status})`));
        }
      });
  };
}

export function exportTLXFile(tlxFile) {
  return (dispatch) => {
    dispatch(exportTLXFileBegin());
    const fileUrl = `${TLX_RESOURCE}/${tlxFile}`;
    return getSiteCsvObjectPromise(fileUrl)

      // getSiteObjectPromise(fileUrl, null, null, null)
      .then((json) => json).catch((error) => {
        try {
          error.json().then((err) => {
            dispatch(exportTLXFileFailure(`${err.detail} (${err.status})`));
          });
        } catch(err) {
          dispatch(exportTLXFileFailure(`${err.detail} (${err.status})`));
        }
      });
  };
}

export function importTLXFile({ tlxFile, oldFilename }) {
  return (dispatch) => {
    const resetTlx = () => {
      dispatch(clearTLXFile());
      dispatch(fetchTLXFile());
      dispatch(importTLXFileSuccess());

      dispatch(fetchTermFirmware({ order: 'asc', orderBy: 'id', page: 1, make: LG }));
    };
    dispatch(importTLXFileBegin());
    postObjectPromise(TLX_RESOURCE, { filename: tlxFile.name }, null, true).then((importUrl) => {
      putFileNoAuthHeaderTlx(importUrl, tlxFile, tlxFile.name).then((response) => {
        if (!httpSuccess(response.status)) {
          return response.json().then((errorObjDetails) => {
            dispatch(importTLXFileFailure(errorObjDetails.detail));
          });
        } else if (oldFilename && tlxFile.name !== oldFilename) {
          deleteObjectPromise(TLX_RESOURCE, oldFilename).then(() => resetTlx());
        } else {
          resetTlx();
        }
      });
    });
  };
}
export const resetSnackBar = (snackBarType, snackBarMessage) => ({
  type: RESET_SNACK_BAR,
  snackBarType,
  snackBarMessage,
});

export const editTLXFile = (editable) => ({
  type: EDIT_TLX,
  editable,
});


export const deleteTlxBegin = () => ({
  type: DELETE_TLX_FILE_BEGIN
});

export const deleteTlxSuccess = (filename) => ({
  type: DELETE_TLX_FILE_SUCCESS,
  filename
});

export const deleteTlxFailure = (filename, error) => ({
  type: DELETE_TLX_FILE_FAILURE,
  error,
  filename
});

export function deleteTlxFile(filename) {
  return (dispatch) => {
    dispatch(deleteTlxBegin());

    const resetTlx = (fName) => {
      dispatch(clearTLXFile());
      dispatch(fetchTLXFile());
      dispatch(deleteTlxSuccess(fName));
    };

    deleteObjectPromise(TLX_RESOURCE, filename)
      .then(() => resetTlx(filename))
      .catch((error) => {
        try {
          error.json().then((err) => {
            dispatch(deleteTlxFailure(filename, `${err.detail} (${err.status})`));
          });
        } catch(err) {
          dispatch(deleteTlxFailure(filename, `${err.detail} (${err.status})`));
        }
      });
  };
}
