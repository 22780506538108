// Check if user has a particular role. Returns bool.
export const checkForSingleUserPermission = (roleName, roleObj) => checkSingleUserPermission(roleName, roleObj);
export const checkSingleUserPermission = (roleName, roleObj) => {
  if (!roleObj || roleObj === null || roleObj === undefined || roleObj.length === 0) {
    return false;
  }
  const roleIndex = roleObj.findIndex((role) => role === roleName);
  return roleIndex !== -1;
};

// Check if user has at least one role in a list of roles. Returns bool
export const checkForAtLeastOneUserPermission = (roleNames, roleObj) => {
  if (!roleObj || roleObj === null || roleObj === undefined) {
    return false;
  }

  for (let i = 0; i < roleNames.length; i++) {
    const roleIndex = roleObj.findIndex((role) => role === roleNames[i]);
    if (roleIndex !== -1) {
      return true;
    }
  }
  return false;
};

// Check if user has at least one role in a list of roles. Returns bool
// export const checkForAllInclusiveUserPermissions = (roleNames, roleObj) => {
//   if (!roleObj || roleObj === null || roleObj === undefined) {
//     return false;
//   }
//   const resultSet = [];

//   for (let i = 0; i < roleNames.length; i++) {
//     if (!roleObj.roles.includes(roleNames[i])) {
//       resultSet.push(false);
//     }
//   }

//   return !resultSet.includes(false);
// };

// // Gets all matching user roles to roles provided. Returns an array of role names
// export const getAllMatchingUserPermissions = (roleNames, roleObj) => {
//   const matchingUserPermissions = [];
//   for (let i = 0; i < roleNames.length; i++) {
//     const matchedRole = roleObj.findIndex((role) => role === roleNames[i]);
//     if (matchedRole !== undefined) {
//       matchingUserPermissions.push(matchedRole);
//     }
//   }
//   return matchingUserPermissions;
// };
