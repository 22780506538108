import SearchIcon from '@mui/icons-material/Search';
import { debounce, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiText from '../../../containers/SonifiText';
import { searchTerminals } from '../actions/terminalActions';

const styles = () => ({
  spaceBetween: {
    justifyContent: 'space-between'
  }
});

class TerminalsFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: ''
    };
    this.debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);
  }

  handleSearchTerminals(name, value) {
    this.setState({ [name]: value }, () => {
      this.debouncedLoadMoreData();
    });
  }

  handleSearchChange = ({ target: { value } }) => {
    this.setState({ page: 1, offset: 0 }, () => {
      this.handleSearchTerminals('search', value);
    });
  };

  filterData() {
    const filterObj = { ...this.state };
    if (filterObj.search.length < 1) {
      delete filterObj.search;
    }

    this.props.resetFilter(this.state.search);
    this.props.dispatch(searchTerminals(filterObj));
  }

  render() {
    const {
      classes, globalTranslations, terminalStatuses, translations
    } = this.props;

    return (
      <Grid container className={classes.spaceBetween}>
        <Grid item>
          <SonifiLabel
            noPadding
            size="xs"
            boldLabel={`${translations.terminalLabel.assigned}:`}
            label={`${terminalStatuses.assigned}`}
          />
          <SonifiLabel
            noPadding
            size="xs"
            boldLabel={`${translations.terminalLabel.unassigned}:`}
            label={`${terminalStatuses.unassigned}`}
          />
          <SonifiLabel
            noPadding
            size="xs"
            boldLabel={`${translations.terminalLabel.notCommunicating}:`}
            label={
              `${terminalStatuses.notCommunicating} ${this.calculateNotCommunicatingPercentage(terminalStatuses)}`
            }
          />
        </Grid>
        <Grid item>
          <SonifiText
            change={this.handleSearchChange}
            defaultValue={this.state.search}
            icon={<SearchIcon />}
            iconPosition="end"
            label={globalTranslations.search}
            size="md"
          />
        </Grid>
      </Grid>
    );
  }

  /**
   * Calculate the text for the not communicating terminals percentage.
   *
   * @param {object} terminalStatuses - A GET /sites/{site_id}/terminal-statuses payload
   * @param {string|number} terminalStatuses.notCommunicating - Total not communicating terminals
   * @param {string|number} terminalStatuses.total - Total site terminals
   *
   * @returns {string} An empty string if the calculate is NaN. Otherwise, a percentage
   *     to one decimal place of the form `(1.0%)`.
   */
  calculateNotCommunicatingPercentage(terminalStatuses) {
    const percentage = (
      (terminalStatuses.notCommunicating / terminalStatuses.total) * 100
    ).toFixed(1);

    if (isNaN(percentage)) {
      return '';
    }

    return `(${percentage}%)`;
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  translations: state.termGrid.translations,
  terminalStatuses: state.termGrid.terminalStatuses,
});

TerminalsFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  terminalStatuses: PropTypes.object,
  resetFilter: PropTypes.func,
  translations: PropTypes.object,
};

export { TerminalsFilter };
export default connect(mapStateToProps)(withStyles(TerminalsFilter, styles, { withTheme: true }));
