import {
    Dialog, DialogContent, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import { CHANNEL_SETS } from '../../../constants/apiEndpoints';
import { SonifiError, SonifiModalHeader, SonifiTableHead, SonifiTextAsync } from '../../../containers';
import ModalSectionHeader from '../../../containers/ModalSectionHeader';
import { getSiteObjectPromise } from '../../../utils/api';

const styles = () => ({
  channelListContainer: {
    overflow: 'auto',
    height: 'calc(100% - 140px)',
    paddingBottom: '5px'
  }
});

class CloneAnotherSiteDialog extends Component {
  state = {
    selectedSite: null,
    channelList: null,
  };

  // removes selected channels
  // cancel = () => {
  //   this.setState({
  //     selectedSite: null,
  //     channelList: null
  //   });
  // }
  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.closeFunc();
    }
  }

  // function for filter async
  createDisplayObj = (site) => ({
    value: site,
    label: `${site.id} ${(site.name ? `: ${site.name}` : '')}`,
  });

  // function for filter async
  createFilterObj = (inputData) => ({
    search: inputData,
    limit: 20
  });

  // Change function for filter async
  handleChange = (option) => {
    if (option.value && option.value.id) {
      getSiteObjectPromise(CHANNEL_SETS, 'Default/channels', option.value.id)
        .then((data) => {
          this.setState({ channelList: data.channels });
          console.log(data);
        }).catch((error) => {
          console.log(error);
        });

      this.setState({ selectedSite: option.value });
    }
  };

  render() {
    const {
      channelHeaders, saveListFunc, classes, translations, globalTranslations
    } = this.props;
    const { channelList, selectedSite } = this.state;

    const tableHeader = [
      { id: 'channel', sortable: false, numeric: false, label: channelHeaders.channel },
      { id: 'type', sortable: false, numeric: false, label: channelHeaders.type },
      { id: 'name', sortable: false, numeric: false, label: channelHeaders.displayName },
      { id: 'enabled', sortable: false, numeric: false, label: channelHeaders.enabled },
    ];

    return (
      <Dialog open={true}
        onClose={this.onCancel.bind(this)}
        fullWidth
        maxWidth="lg" >
        <SonifiModalHeader
          header={translations.title}
          onlyClose={!channelList || channelList.length === 0}
          onCancel={this.onCancel.bind(this)}
          onSubmit={saveListFunc(channelList)}
          label={globalTranslations.import}
        />
        <DialogContent >
          <ModalSectionHeader label={translations.siteSearch} />
          <SonifiTextAsync
            placeholder={(channelList) ? `${selectedSite.id} : ${selectedSite.name}` : translations.placeholder}
            onSelect={this.handleChange}
            resource="sites"
            type="sites"
            dataName="sites"
            siteObj={false}
            filterFunc={this.createFilterObj}
            displayFunc={this.createDisplayObj}
            label={translations.siteId}
          />

          {channelList !== null &&
            <Fragment>
              <ModalSectionHeader label={`${translations.preview} ${selectedSite.id} ${selectedSite.name}`} />
              <div className={classes.channelListContainer}>
                {channelList.length === 0
                  ? <SonifiError
                    boldLabel={`${translations.site}: ${selectedSite.id}: ${selectedSite.name}`}
                    label={translations.noList}
                  />
                  : <Table>
                    <SonifiTableHead
                      headColumns={tableHeader}
                      hideSortIcon={true}
                    />
                    <TableBody>
                      {channelList.map((channel, index) => (
                        <TableRow key={index}>
                          <TableCell> {channel.id} </TableCell>
                          <TableCell> {channel.type} </TableCell>
                          <TableCell> {channel.name} </TableCell>
                          <TableCell> {channel.enabled ? channelHeaders.enabled : channelHeaders.disabled} </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                }
              </div>
            </Fragment>
          }
        </DialogContent>
      </Dialog>
    );
  }
}

CloneAnotherSiteDialog.propTypes = {
  channelHeaders: PropTypes.object,
  classes: PropTypes.object.isRequired,
  closeFunc: PropTypes.func.isRequired,
  globalTranslations: PropTypes.object,
  saveListFunc: PropTypes.func.isRequired,
  translations: PropTypes.object
};

export default (withStyles(CloneAnotherSiteDialog, styles));
