import { PMS_LOGS } from '../../../../constants/apiEndpoints';
import { getTieObjectPromise } from '../../../../utils/tieApi';
import * as types from '../actions/actionTypes';

export const getPmsLoggingDataBegin = () => ({
  type: types.GET_PMS_LOGGING_DATA_BEGIN
});

export const getPmsLoggingDataSuccess = (logs) => ({
  type: types.GET_PMS_LOGGING_DATA_SUCCESS,
  logs
});

export const getPmsLoggingDataFailure = (error) => ({
  type: types.GET_PMS_LOGGING_DATA_FAILURE,
  error
});

export function getPmsLoggingData(filter) {
  return (dispatch) => {
    dispatch(getPmsLoggingDataBegin());
    return getTieObjectPromise(PMS_LOGS, filter)
      .then((data) => {
        dispatch(getPmsLoggingDataSuccess(data));
        return data;
      }).catch((error) => {
        dispatch(getPmsLoggingDataFailure(error));
        return error;
      });
  };
}

export const resetPmsLogging = () => ({
  type: types.RESET_PMS_LOGGING
});

export const viewLogInfo = (log) => ({
  type: types.VIEW_PMS_LOG,
  log
});
