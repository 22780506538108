export const ADD_FIRMWARE_BEGIN = 'ADD_FIRMWARE_BEGIN';
export const ADD_FIRMWARE_FAILURE = 'ADD_FIRMWARE_FAILURE';
export const ADD_FIRMWARE_SUCCESS = 'ADD_FIRMWARE_SUCCESS';
export const CLEAR_FIRMWARE = 'CLEAR_FIRMWARE';
export const DELETE_CHECK = 'DELETE_CHECK';
export const DELETE_FIRMWARE_BEGIN = 'DELETE_FIRMWARE_BEGIN';
export const DELETE_FIRMWARE_FAILURE = 'DELETE_FIRMWARE_FAILURE';
export const DELETE_FIRMWARE_SUCCESS = 'DELETE_FIRMWARE_SUCCESS';
export const FETCH_FIRMWARE_BEGIN = 'FETCH_FIRMWARE_BEGIN';
export const FETCH_FIRMWARE_FAILURE = 'FETCH_FIRMWARE_FAILURE';
export const FETCH_FIRMWARE_FILE_BEGIN = 'FETCH_FIRMWARE_FILE_BEGIN';
export const FETCH_FIRMWARE_FILE_FAILURE = 'FETCH_FIRMWARE_FILE_FAILURE';
export const FETCH_FIRMWARE_FILE_SUCCESS = 'FETCH_FIRMWARE_FILE_SUCCESS';
export const FETCH_FIRMWARE_SUCCESS = 'FETCH_FIRMWARE_SUCCESS';
export const GET_REGEX_BEGIN = 'GET_REGEX_BEGIN';
export const GET_REGEX_FAILURE = 'GET_REGEX_FAILURE';
export const GET_REGEX_SUCCESS = 'GET_REGEX_SUCCESS';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';
export const SAVE_FIRMWARE_BEGIN = 'SAVE_FIRMWARE_BEGIN';
export const SAVE_FIRMWARE_FAILURE = 'SAVE_FIRMWARE_FAILURE';
export const SAVE_FIRMWARE_SUCCESS = 'SAVE_FIRMWARE_SUCCESS';
export const START_FIRMWARE = 'START_FIRMWARE';
export const UPDATE_CORRUPTED_FILE = 'UPDATE_CORRUPTED_FILE';
export const UPDATE_SELECTED_FIRMWARE = 'UPDATE_SELECTED_FIRMWARE';
