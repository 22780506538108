import { filter } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import {
  ADD_GROUP_BEGIN, ADD_GROUP_FAILURE, ADD_GROUP_SUCCESS, DELETE_CHECK, DELETE_GROUP_BEGIN,
  DELETE_GROUP_FAILURE, DELETE_GROUP_SUCCESS, FETCH_GROUPS_BEGIN, FETCH_GROUPS_FAILURE,
  FETCH_GROUPS_SUCCESS, FILTER_GROUP_SITES_FOR_USER, FILTER_SITES_BEGIN, FILTER_SITES_FAILURE,
  FILTER_SITES_SUCCESS, GET_ALL_SITES_FAILURE, GET_ALL_SITES_SUCCESS, GET_GROUP_SITES_BEGIN,
  GET_GROUP_SITES_FAILURE, GET_GROUP_SITES_SUCCESS, GET_SITES_FAILURE, GET_SITES_SUCCESS,
  RESET_GROUP, SORT_GROUPS_BEGIN, UPDATE_SELECTED_GROUP, UPDATE_SNACK_BAR
} from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  addingGroup: false,
  allSites: null,
  allowEditing: true,
  changed: false,
  deleteSite: null,
  filterLoad: false,
  filteredSitesForUser: [],
  gettingGroupSites: false,
  groups: [],
  loading: false,
  maxPages: 1,
  selectedGroup: null,
  siteFilterLoad: false,
  sites: null,
  snackBar: SUCCESS,
  snackBarMessage: '',
  snackbarOpen: false,
  sortGroups: false,
  translations: null,
  editType: ''
};

/* eslint-disable max-lines-per-function */
export default function groupsReducer(state = initialState, action) {
  let miscObj = {},
    miscArray = null;

  switch (action.type) {
    case RESET_GROUP:
      return {
        ...state,
        groups: [],
        selectedGroup: null,
        snackbarOpen: false
      };

    case ADD_GROUP_BEGIN:
      return {
        ...state,
        addingGroup: true,
        snackbarOpen: false,
        snackBarMessage: ''
      };

    case ADD_GROUP_SUCCESS:
      return {
        ...state,
        addingGroup: false,
        selectedGroup: null,
        snackbarOpen: true,
        snackBar: SUCCESS,
        snackBarMessage: (action.isNewGroup
          ? state.translations.grid.addSuccess
          : state.translations.grid.updateSuccess)
      };

    case ADD_GROUP_FAILURE:
      return {
        ...state,
        addingGroup: false,
        snackbarOpen: true,
        snackBar: ERROR,
        snackBarMessage: state.translations.grid.errors.addFailure
      };

    case DELETE_GROUP_SUCCESS:
      return {
        ...state,
        selectedGroup: null,
        sortGroups: false,
        snackbarOpen: true,
        snackBar: SUCCESS,
        snackBarMessage: `${state.translations.grid.deleteSuccess} ${action.groupName}`
      };

    case DELETE_GROUP_FAILURE:
      return {
        ...state,
        sortGroups: false,
        selectedGroup: null,
        snackbarOpen: true,
        snackBar: ERROR,
        snackBarMessage: `${state.translations.grid.deleteFailure} ${action.error}`
      };

    case FETCH_GROUPS_BEGIN:
      return {
        ...state,
        filterLoad: false,
        loading: true,
        siteFilterLoad: false
      };

    case DELETE_GROUP_BEGIN:
    case SORT_GROUPS_BEGIN:
      return {
        ...state,
        deleteSite: null,
        sortGroups: true
      };

    case FETCH_GROUPS_SUCCESS:
      return {
        ...state,
        filterLoad: false,
        groups: action.groups,
        loading: false,
        siteFilterLoad: false,
        sortGroups: false
      };

    case FETCH_GROUPS_FAILURE:
      return {
        ...state,
        allowEditing: action.allowEditing,
        groups: [],
        loading: false,
        snackBar: ERROR,
        snackBarMessage: state.translations.grid.errors.gettingGroups,
        snackbarOpen: true,
        sortGroups: false
      };

    case UPDATE_SELECTED_GROUP:
      if (action.group === null) {
        return {
          ...state, selectedGroup: action.group, sites: null, filteredSitesForUser: [], editType: ''
        };
      }
      return { ...state, selectedGroup: action.group, editType: action.editType };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    case FILTER_SITES_SUCCESS:
      return {
        ...state,
        sites: action.sites,
        siteFilterLoad: false,
        maxPages: action.sites.page_count
      };

    case GET_SITES_SUCCESS:
      miscArray = (state.sites === null ? [...action.sites.sites] : [...state.sites.sites, ...action.sites.sites]);
      return {
        ...state,
        sites: { sites: miscArray },
        siteFilterLoad: false,
        maxPages: action.sites.page_count,
        filterLoad: false
      };

    case GET_SITES_FAILURE:
      return {
        ...state,
        sites: {},
        filterLoad: false
      };

    case GET_GROUP_SITES_SUCCESS:
      miscObj = { ...state.selectedGroup };
      miscObj.sites = filter(state.allSites, (o) => action.sites.includes(o.id));

      return {
        ...state,
        selectedGroup: miscObj,
        gettingGroupSites: false,
        filteredSitesForUser: miscObj.sites,
        changed: !state.changed
      };

    case GET_GROUP_SITES_FAILURE:
      return {
        ...state,
        gettingGroupSites: false
      };

    case GET_GROUP_SITES_BEGIN:
      return {
        ...state,
        gettingGroupSites: true
      };

    case UPDATE_SNACK_BAR:
      return {
        ...state,
        snackbarOpen: action.open
      };

    case FILTER_SITES_BEGIN:
      return {
        ...state,
        siteFilterLoad: true
      };

    case FILTER_SITES_FAILURE:
      return {
        ...state
      };

    case DELETE_CHECK:
      return {
        ...state,
        deleteSite: action.site
      };

    case GET_ALL_SITES_SUCCESS:
      return {
        ...state,
        allSites: action.sites.sites
      };

    case GET_ALL_SITES_FAILURE:
      return {
        ...state
      };

    case FILTER_GROUP_SITES_FOR_USER:
      miscArray = filter(state.selectedGroup.sites, (o) => o.name.toLowerCase().includes(action.filter));
      return {
        ...state,
        filteredSitesForUser: miscArray
      };
    default:
      return state;
  }
}
