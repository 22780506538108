import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import ReactDOMClient from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import App from './App';
import { persistor, store } from './SonifiReduxStore.js';
import { theme } from './SonifiTheme.js';

export const GlobalStoreContextV2 = React.createContext();


// "start": "export GENERATE_SOURCEMAP=false $(cat .env | xargs) && react-scripts start --no-cache",

function AppWithCallbackAfterRender() {
  return (
    <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

 if (process.env.REACT_APP_GA_ID) {
  const head = document.head;
  const firstChild = head.childNodes[0];
  const script1 = document.createElement('script');
  script1.async = true;
  script1.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_ID}`;
  head.insertBefore(script1, firstChild);

  const script2 = document.createElement('script');
  const script2Text = document.createTextNode(`window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
  
    gtag('config', '${process.env.REACT_APP_GA_ID}');`);

  script2.appendChild(script2Text);
  head.insertBefore(script2, firstChild);
}

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);
root.render(<AppWithCallbackAfterRender />);
