import { loadLiterals } from '../../../actions/globalActions';
import * as types from './actionTypes';

export function editLanguageSetting(name, value) {
  return (dispatch) => {
    dispatch(updateLanguageSetting(name, value));
    dispatch(loadLanguage(value));
  };
}

export const updateLanguageSetting = (name, value) => ({
  type: types.UPDATE_LANGUAGE_SETTING,
  name,
  value
});

export function loadLanguage(lang) {
  return loadLiterals(lang);
}
