import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiModalHeader from '../../../../containers/SonifiModalHeader';
import SonifiText from '../../../../containers/SonifiText';
import { getErrorText, isError } from '../../../../utils/index';
import { checkSubnet } from '../../utils/validator';

class NetworkDialog extends Component {
  state = {
    addEditText: '',
    errors: null
  };

  componentDidMount() {
    if (this.props.id !== -1) {
      this.setState({ addEditText: this.props.layer3_netmasks[this.props.id] });
    }
  }

  changeText({ target: { value } }) {
    if (!(/^[0-9./]+$/).test(value)) {
      return;
    }
    this.setState({ addEditText: value });
  }

  addNetwork = () => {
    const currentNetwork = this.state.addEditText.trim();
    if (!checkSubnet(currentNetwork)) {
      this.setState({ errors: { addEditText: 'Must display the CIDR-format (a.b.c.d/n)' } });
      return;
    }

    const currentNetworks = (this.props.layer3_netmasks ? [...this.props.layer3_netmasks] : []);

    if (currentNetworks.includes(currentNetwork)) {
      this.setState({ errors: { addEditText: 'Network already exists' } });
      return;
    }

    if (this.props.id === -1) {
      currentNetworks.push(currentNetwork);
    } else {
      currentNetworks[this.props.id] = currentNetwork;
    }

    this.props.updateNetworks(currentNetworks);
  };

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  render() {
    const { globalTranslations, id, readOnly, translations } = this.props;
    const { errors } = this.state;

    return (
      <Dialog open={true}
        onClose={this.onClose.bind(this)}
        fullWidth
        maxWidth="sm" >
        <SonifiModalHeader
          header={id === -1 ? `${translations.popup.add}` : `${translations.popup.edit}`}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.addNetwork.bind(this)}
          onlyClose={readOnly}
          label={globalTranslations.save}
        />
        <DialogContent >
          <Grid container>
            <SonifiText
              disabled={readOnly}
              defaultValue={this.state.addEditText}
              change={this.changeText.bind(this)}
              label={translations.networkIp}
              error={isError('addEditText', errors)}
              errorText={getErrorText('addEditText', errors)}
              size="md" />
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  translations: state.siteManagement.translations.ipNetwork,
});

NetworkDialog.propTypes = {
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  id: PropTypes.number,
  layer3_netmasks: PropTypes.array,
  onCancel: PropTypes.func,
  readOnly: PropTypes.bool,
  translations: PropTypes.object,
  updateNetworks: PropTypes.func
};

export default connect(mapStateToProps)(NetworkDialog);
