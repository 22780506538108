import { Search } from '@mui/icons-material';
import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { differenceWith, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiText from '../../../containers/SonifiText';
import { fetchAllRoomTypes, resetSnackBar, saveRoomTypes, showRoomTypesDialog } from '../actions/lateCheckoutActions';
import RoomTypeTable from './RoomTypeTable';

export class RoomTypeDialog extends Component {
  state = {
    filter: '',
    selected: null
  };

  componentDidMount() {
    this.props.dispatch(resetSnackBar(''));
    this.props.dispatch(fetchAllRoomTypes());
  }

  filterRoom = ({ target: { value } }) => {
    this.setState({ filter: value });
  };

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.onCancel();
    }
  }

  onCancel() {
    this.props.dispatch(showRoomTypesDialog(false));
  }

  onSubmit() {
    const addedTypes = differenceWith(this.state.selected, this.props.selectedRoomTypes, isEqual);
    const removedTypes = differenceWith(this.props.selectedRoomTypes, this.state.selected, isEqual);
    if (this.state.selected !== null && (addedTypes.length > 0 || removedTypes.length > 0)) {
      this.props.dispatch(saveRoomTypes(this.state.selected));
    } else {
      this.onCancel();
    }
  }

  updateSelectedRoomTypes(selected) {
    this.setState({ selected });
  }

  render() {
    const { fetchingRoomTypes, globalTranslations, translations } = this.props;
    const { filter } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={this.onClose.bind(this)}
        open={true} >
        <SonifiModalHeader
          header={translations.title}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.onSubmit.bind(this)}
          label={globalTranslations.save}
        />
        <DialogContent>
          <Grid container style={{ alignContent: 'start', width: '100%' }}>
            <Grid item xs={12}>
              {fetchingRoomTypes
                ? <SonifiSpinner />
                : <Grid container style={{ justifyContent: 'flex-end' }}>
                  <Grid item>
                    <SonifiText
                      label={globalTranslations.search}
                      defaultValue={filter}
                      change={this.filterRoom.bind(this)}
                      icon={<Search />}
                      iconPosition="end"
                      size="mdNoPad"
                    />
                  </Grid>
                </Grid>}
            </Grid>
            {!fetchingRoomTypes &&
              <Grid item xs={12}>
                <RoomTypeTable filter={filter} updateSelectedRoomTypes={this.updateSelectedRoomTypes.bind(this)} />
              </Grid>
            }
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  fetchingRoomTypes: state.lateCheckout.fetchingRoomTypes,
  globalTranslations: state.global.translations.defaults,
  selectedRoomTypes: state.lateCheckout.selectedRoomTypes,
  translations: state.lateCheckout.translations.roomTypeDialog
});

RoomTypeDialog.propTypes = {
  fetchingRoomTypes: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  selectedRoomTypes: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(RoomTypeDialog);
