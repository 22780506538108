// Entry page to Message Manager
// This page contains the live messages and scheduled messages,
// with the ability to add new scheduled messages.

// Be careful with the styling.  Both tables are built with infinite scrolling,
// but the live messages table is confined to a defined space, whereas the
// scheduled message table will infinitely scroll to the bottom of the browser page.

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import SonifiSnackBar from '../../../containers/SonifiSnackBar';
import { getSiteNumFromURI } from '../../../utils';
import FormDialog from './FormDialog';
import LiveMessages from './LiveMessages';
import PublishedMessages from './PublishedMessages';

class MessagingDashboard extends Component {
  state = {
    snackBarOpen: true
  };

  closeSnackBar() {
    this.setState({ snackBarOpen: false });
  }

  getErrorMessages() {
    const { liveDetail, publishDetail, errorOccurred } = this.props;

    console.log('liveDetail', liveDetail);
    console.log('publishDetail', publishDetail);

    if (!errorOccurred) {
      return '';
    }

    let messageDetail = liveDetail || '';
    if (messageDetail.length > 0) {
      messageDetail += '\n';
    }
    messageDetail += publishDetail;

    console.log('messageDetail', messageDetail);
    return messageDetail;
  }

  render() {
    if (getSiteNumFromURI() === null) {
      return <Navigate replace to="/" />;
    }

    const { errorOccurred, translations } = this.props;
    const { snackBarOpen } = this.state;

    const tableHeader = [
      { id: 'name', sortable: false, numeric: false, label: translations.name },
      { id: 'format', sortable: false, numeric: false, label: translations.type },
      { id: 'requester', sortable: false, numeric: false, label: translations.requester },
      { id: 'organization', sortable: false, numeric: false, label: translations.organization },
      { id: 'start_timestamp', sortable: true, numeric: false, label: translations.startDate },
      { id: 'end_timestamp', sortable: true, numeric: false, label: translations.endDate },
      { id: 'user_name', sortable: false, numeric: false, label: translations.publisher },
    ];

    const msg = this.getErrorMessages();

    return (
      <div style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'column',

        overflow: 'auto',
        overflowX: 'auto'
      }}>
        <FormDialog />
        <LiveMessages tableHeader={tableHeader} />
        <br />
        <PublishedMessages tableHeader={tableHeader} />
        <SonifiSnackBar message={msg}
          variant="error" open={errorOccurred && snackBarOpen} onClose={this.closeSnackBar.bind(this)} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  errorOccurred: (!!state.messaging.liveError || !!state.messaging.publishError),
  liveDetail: state.messaging.liveError ? state.messaging.liveError.detail : '',
  publishDetail: state.messaging.publishError ? state.messaging.publishError.detail : '',
  translations: state.messaging.translations.tableHeaders
});

MessagingDashboard.propTypes = {
  errorOccurred: PropTypes.bool,
  liveDetail: PropTypes.string,
  publishDetail: PropTypes.string,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(MessagingDashboard);
