import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { DISTANCE_FROM_END } from '../../../../constants/magic';
import { SONIFI_ADMIN } from '../../../../constants/roles';
import { SonifiLabel, SonifiSpinner, SonifiTableHead } from '../../../../containers';
import SonifiTemplate from '../../../../containers/SonifiTemplate';
import { checkForSingleUserPermission } from '../../../../utils/rolesUtil';
import { getPmsLoggingData, resetPmsLogging } from '../actions/pmsLoggingAction';
import LogDialog from './LogDialog';
import LogRow from './LogRow';
import PmsFilter from './PmsFilter';

class PmsLoggingGrid extends Component {
  state = {
    filter: '',
    limit: 10,
    order: 'desc',
    orderBy: 'timestamp',
    page: 1
  };

  componentDidMount() {
    this.filterFunc();
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }), () => {
        this.props.dispatch(getPmsLoggingData(
          { page: this.state.page, limit: this.state.limit, ...this.state.filter }
        ));
      });
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  filterFunc(filterPayload) {
    const { dispatch } = this.props;
    dispatch(resetPmsLogging());
    this.setState({
      filter: { ...filterPayload }, page: 1
    }, () => {
      dispatch(getPmsLoggingData({ page: this.state.page, limit: this.state.limit, ...this.state.filter }));
    });
  }

  getPageDetails() {
    const { order, orderBy, page } = this.state;
    const {
      loading, logs, maxPages, selectedLog, translations, userPermissions,
    } = this.props;

    const canEdit = checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);
    const tableHeader = [
      { id: 'timestamp', sortable: false, numeric: false, label: `${translations.timestamp}` },
      { id: 'room', sortable: false, numeric: false, label: `${translations.room}` },
      { id: 'type', sortable: false, numeric: false, label: `${translations.type}` }
    ];

    return (<Fragment>
      <PmsFilter filterFunc={this.filterFunc.bind(this)} />
      <Table stickyHeader={true}>
        <SonifiTableHead
          headColumns={tableHeader}
          order={order}
          orderBy={orderBy}
        />
        {loading
          ? <TableBody>
            <TableRow>
              <TableCell colSpan={3}>
                <SonifiSpinner />
              </TableCell>
            </TableRow>
          </TableBody>
          : <TableBody>
            {(!logs || logs.length === 0 || logs === null)
              ? <TableRow>
                <TableCell colSpan={3}>
                  <SonifiLabel error label={translations.errors.noLogs} />
                </TableCell>
              </TableRow>
              : logs.map((option, index) => (
                <Fragment key={`w_${index}`}>
                  <LogRow key={index} rowIndex={index} deleteEnabled={canEdit && !loading} />

                  {index === logs.length - DISTANCE_FROM_END && page < maxPages && (
                    <TableRow>
                      <TableCell>
                        <Waypoint onEnter={() => { this.getMoreData(); }} />
                      </TableCell>
                    </TableRow>)
                  }
                </Fragment>
              ))}
          </TableBody>}
      </Table>
      {selectedLog && <LogDialog />}
    </Fragment>);
  }

  render() {
    const { translations } = this.props;

    return (
      <SonifiTemplate
        header={translations.title}
        pageDetails={this.getPageDetails()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.pmsLogging.loading,
  logs: state.pmsLogging.logs,
  maxPages: state.pmsLogging.maxPages,
  selectedLog: state.pmsLogging.selectedLog,
  translations: state.pmsLogging.translations.grid,
  userPermissions: state.global.permissions
});

PmsLoggingGrid.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  logs: PropTypes.array,
  maxPages: PropTypes.number,
  selectedLog: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(PmsLoggingGrid);
