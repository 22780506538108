import { Dialog, DialogContent, Grid } from '@mui/material';
import { differenceBy, filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SONIFI } from '../../../constants/constants';
import SonifiAutoCompleteText from '../../../containers/SonifiAutoCompleteText';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import { buildSortString } from '../../../utils';
import { addNewModel, getModelsForNewSiteModel, saveNewModel } from '../actions/FirmwareActions';
import { LG, SAMSUNG } from '../constants/TermFileConstants';

export class AddModelDialog extends Component {
  state = {
    limit: 1000,
    order: 'asc',
    orderBy: 'id',
    page: 1,
    search: '',
    selected: ''
  };

  componentDidMount() {
    this.setState({
      search: this.props.modelType
    }, () => {
      this.props.dispatch(getModelsForNewSiteModel(buildSortString(this.state)));
    });
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(addNewModel(''));
    }
  }

  onSave() {
    const { selected } = this.state;

    if (selected !== '') {
      console.log(`Add ${selected} to this site`);
      this.props.dispatch(saveNewModel(selected, this.props.modelType));
    }
    this.props.dispatch(addNewModel(''));
  }

  handleOnChange = (event, value) => {
    this.setState({ selected: (value === null ? '' : value.value) });
  };

  getModelArrayNotAssignedToSite() {
    const {
      lgFirmware, models, modelType, samsungFirmware, sonifiFirmware
    } = this.props;

    if (modelType === LG) {
      return differenceBy(models, lgFirmware, 'id');
    } else if (modelType === SONIFI) {
      return differenceBy(models, sonifiFirmware, 'id');
    } else if (modelType === SAMSUNG) {
      return differenceBy(models, samsungFirmware, 'id');
    }
    return [];
  }

  render() {
    const {
      gettingModels, globalTranslations, modelType, translations
    } = this.props;
    const { selected } = this.state;
    const newArray = this.getModelArrayNotAssignedToSite();

    return (
      <Dialog open={true} onClose={this.onCancel} fullWidth>
        <SonifiModalHeader
          disabled={gettingModels}
          header={translations.title.replace('#modelType', modelType)}
          label={globalTranslations.save}
          onCancel={this.onCancel.bind(this)}
          onlyClose={gettingModels}
          onSubmit={this.onSave.bind(this)}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              {gettingModels
                ? <SonifiSpinner />
                : <SonifiAutoCompleteText
                  label={`${modelType} ${translations.models}`}
                  size="md"
                  defaultValue={selected ? filter(newArray, (o) => (o.value || o) === selected)[0] : null}
                  options={newArray.map((suggestion) => ({
                    value: suggestion.id,
                    label: suggestion.id
                  }))}
                  onChange={this.handleOnChange.bind(this)}
                  helperText={translations.helperText}
                />}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  gettingModels: state.termFile.gettingModels,
  globalTranslations: state.global.translations.defaults,
  lgFirmware: state.termFile.lgFirmware,
  models: state.termModel.models,
  modelType: state.termFile.modelType,
  samsungFirmware: state.termFile.samsungFirmware,
  sonifiFirmware: state.termFile.sonifiFirmware,
  translations: state.termFile.translations.dialog.add
});

AddModelDialog.propTypes = {
  dispatch: PropTypes.func,
  gettingModels: PropTypes.bool,
  globalTranslations: PropTypes.object,
  lgFirmware: PropTypes.array,
  models: PropTypes.array,
  modelType: PropTypes.string,
  samsungFirmware: PropTypes.array,
  sonifiFirmware: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(AddModelDialog);
