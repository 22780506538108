import { getTerminalOptions } from '../../Terminals/actions/terminalActions';
import * as types from './actionTypes';

export const clearLgFirmware = () => ({
  type: types.CLEAR_LG_FIRMWARE
});

export const sortLgFirmwareBegin = () => ({
  type: types.SORT_LG_FIRMWARE_BEGIN
});

// export const fetchLgFirmwareBegin = () => ({
//   type: types.FETCH_LG_FIRMWARE_BEGIN
// });

// export const fetchLgFirmwareSuccess = (lgFirmware, lgClient) => ({
//   type: types.FETCH_LG_FIRMWARE_SUCCESS,
//   lgClient,
//   lgFirmware
// });

// export const fetchLgFirmwareFailure = (error) => ({
//   type: types.FETCH_LG_FIRMWARE_FAILURE,
//   error
// });

// export function fetchLgFirmware() {
//   return (dispatch) => {
//     dispatch(fetchLgFirmwareBegin());
//     const termFileObj = { make: LG };
//     return getSiteObjectPromise(TERM_MODELS, null, null, termFileObj)
//       .then((termFiles) => dispatch(fetchLgFirmwareSuccess(
//         termFiles.models, termFiles.default_site_firmware.lg_client
//       )))
//       .catch((error) => {
//         dispatch(fetchLgFirmwareFailure(`${error.status}`));
//         return false;
//       });
//   };
// }

export const setSelectedLgFirmware = (firmware) => ({
  type: types.SET_SELECTED_LG_FIRMWARE,
  firmware
});

export const fetchTerminalOptionsBegin = () => ({
  type: types.FETCH_TERMINAL_OPTIONS_BEGIN
});

export const fetchTerminalOptionsSuccess = (autoUpdates, maintWindow) => ({
  type: types.FETCH_TERMINAL_OPTIONS_SUCCESS,
  autoUpdates,
  maintWindow
});

export const fetchTerminalOptionsFailure = (error) => ({
  type: types.FETCH_TERMINAL_OPTIONS_FAILURE,
  error
});

export function fetchTerminalOptions() {
  return (dispatch) => {
    dispatch(fetchTerminalOptionsBegin());
    return dispatch(getTerminalOptions())
      .then((termOptions) => dispatch(
        fetchTerminalOptionsSuccess(termOptions.automatic_updates, termOptions.maintenance_window)
      ))
      .catch((error) => {
        dispatch(fetchTerminalOptionsFailure(`${error.status}`));
        return false;
      });
  };
}

export const editClientFile = (show) => ({
  type: types.UPDATE_CLIENT_FILE,
  show
});
