import { Check } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SITE_EDIT } from '../../../../constants/roles';
import {
 SonifiDivider, SonifiLabel, SonifiSubHeader, SonifiText, SonifiEditableList
} from '../../../../containers';
import { getErrorText, isError } from '../../../../utils';
import { checkSingleUserPermission } from '../../../../utils/rolesUtil';
import { updateChangeVariable, updateHL7Config } from '../../actions/siteManagementActions';
import { validHL7Network } from '../../utils/validator';

class HL7 extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      subnets: this.props.hl7.subnets,
      port: this.props.hl7.port,
      errors: {}
    };

    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(updateChangeVariable(true));
    this.setState({ ...this.props.hl7 });
  }

  updateConfigField = (name) => ({ target: { value } }) => {
    this.props.dispatch(updateChangeVariable(false));
    this.setState({ [name]: value });
  };

  onListChange(newList) {
    this.props.dispatch(updateChangeVariable(false));
    this.setState({
      subnets: newList
    });
  }

  onSave = () => {
    const validationErrors = validHL7Network(this.state, this.props.translations.errors);

    if (Object.entries(validationErrors).length === 0) {
      this.setState({ errors: null });
      this.props.dispatch(updateHL7Config({ ...this.state }))
        .then((json) => {
          if (typeof json === 'string') {
            this.setState({ errors: { subnets: json } });
          }
          return json;
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      this.setState({ errors: validationErrors });
    }
    this.props.dispatch(updateChangeVariable(true));
  };

  render() {
    const { errors } = this.state;
    const { globalTranslations, translations, userPermissions } = this.props;
    const readOnly = !checkSingleUserPermission(SITE_EDIT, userPermissions);
    return (
      <Grid container>
        <Grid item xs={12}>
          <SonifiSubHeader
            disabled={readOnly}
            header=""
            icon={<Check />}
            label={globalTranslations.save}
            onSubmit={this.onSave}
            showButton={!readOnly}
          />
        </Grid>
        <Grid item xs={12}>
          <SonifiDivider />
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            <SonifiLabel boldLabel={translations.ports} />
          </Grid>
          <Grid item xs={3}>
            <SonifiText
              change={this.updateConfigField('port')}
              defaultValue={this.state.port}
              disabled={readOnly}
              error={isError('port', errors)}
              errorText={getErrorText('port', errors)}
              label={translations.port}
              size="percent"
            />
          </Grid>
        </Grid>
        <Grid item xs={4} style={{ height: '100%', maxHeight: '100%' }}>
         <SonifiEditableList
              label={translations.subnets}
              list={this.state.subnets}
              onListChange={this.onListChange.bind(this)}
              newItemPlaceholder={translations.newSubnetPlaceholder}
              disabled={readOnly}
              error={isError('subnets', errors)}
              errorText={getErrorText('subnets', errors)}
            />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  hl7: state.global.site.hl7,
  translations: state.siteManagement.translations.hl7,
  userPermissions: state.global.permissions
});

HL7.propTypes = {
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  hl7: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(HL7);
