import { ip2long, Netmask } from 'netmask';
import { textHelper } from '../../../utils/textUtil';
import { RESTRICTED_SUBNETS } from '../constants/SiteContants';
import { Cidr } from 'cidr-lib';

export function isValidHierarchy(buildings, termLocations, globalTranslations) {
  let errors = '',
    floorsArr = null;

  if (!buildings || buildings.length === 0) {
    return { buildings: [], errors: 'No buildings configured' };
  }

  if (!termLocations || termLocations.length === 0) {
    errors += 'No terminal locations configured \n';
  }

  const currentVisible = [...buildings];

  const buildingsArr = [];
  for (let i = 0; i < currentVisible.length; i++) {
    currentVisible[i].hError = '';
    if (buildingsArr.indexOf(currentVisible[i].name) === -1) {
      buildingsArr.push(currentVisible[i].name);
    } else {
      currentVisible[i].hError += `${currentVisible[i].name} is a duplicate \n `;
      errors += `${currentVisible[i].name} is a duplicate \n `;
    }
    if (currentVisible[i].name.trim().length === 0) {
      currentVisible[i].hError += 'Building name contains only spaces \n ';
      errors += 'Building name contains only spaces \n ';
    }

    if (textHelper(currentVisible[i].name)) {
      currentVisible[i].hError += `${currentVisible[i].name} ${globalTranslations.symbols} \n `;
      errors += `${currentVisible[i].name} ${globalTranslations.symbols} \n `;
    }

    if (!currentVisible[i].floors || currentVisible[i].floors.length < 1) {
      currentVisible[i].hError += `${currentVisible[i].name} has no floors \n `;
      errors += `${currentVisible[i].name} has no floors \n `;
    } else {
      floorsArr = [];
      for (let j = 0; j < currentVisible[i].floors.length; j++) {
        currentVisible[i].floors[j].hError = '';
        if (floorsArr.indexOf(currentVisible[i].floors[j].name) === -1) {
          floorsArr.push(currentVisible[i].floors[j].name);
        } else {
          currentVisible[i].floors[j].hError += `${currentVisible[i].floors[j].name} is a duplicate.\n `;
          errors += `${currentVisible[i].floors[j].name} is a duplicate.\n `;
        }
        if (currentVisible[i].floors[j].name.trim().length === 0) {
          currentVisible[i].floors[j].hError += 'Floor name contains only spaces \n ';
          errors += 'Floor name contains only spaces \n ';
        }

        if (textHelper(currentVisible[i].floors[j].name)) {
          currentVisible[i].floors[j].hError += `${currentVisible[i].floors[j].name} ${globalTranslations.symbols} \n `;
          errors += `${currentVisible[i].floors[j].name} ${globalTranslations.symbols} \n `;
        }

        if (currentVisible[i].floors[j].hError.length === 0) {
          delete (currentVisible[i].floors[j].hError);
        }
      }
    }

    if (currentVisible[i].hError.length === 0) {
      delete (currentVisible[i].hError);
    }
  }

  return { buildings: currentVisible, errors };
}

export function isValidIP(networks) {
  const errors = {};

  if (networks.networksUsed.ip || networks.networksUsed.wifi) {
    const cidr = new Cidr();
    console.log(networks.iptvNetwork.subnet_mask);
    try {
      if (!checkSubnet(networks.iptvNetwork.subnet_mask)) {
        errors.subnet_mask = 'Must display the CIDR-format (a.b.c.d/n)';
      } else if (RESTRICTED_SUBNETS.some((sub) => cidr.doSubnetsOverlap(sub, networks.iptvNetwork.subnet_mask))) {
        errors.subnet_mask = 'Restricted Subnet';
      }
    } catch(err) {
      console.log(err);
      errors.subnet_mask = 'Invalid bytes';
    }

    if (networks.iptvNetwork.subnet_mask && !errors.subnet_mask) {
      const block = new Netmask(networks.iptvNetwork.subnet_mask);
      const subnetIp = networks.iptvNetwork.subnet_mask.split('/')[0];
      try {
        if (!checkIpFormat(networks.iptvNetwork.gateway_address)) {
          errors.gateway_address = 'Must display in format (a.b.c.d)';
        } else if (!block.contains(networks.iptvNetwork.gateway_address)) {
          errors.gateway_address = 'Gateway is not in netmask';
        }
      } catch(err) {
        errors.gateway_address = 'Invalid bytes';
      }

      if (!networks.iptvNetwork.layer3_enabled) {
        try {
          if (!networks.iptvNetwork.dhcp || !checkIpFormat(networks.iptvNetwork.dhcp.pool_start)) {
            errors.pool_start = 'Must display in format (a.b.c.d)';
          } else if (!block.contains(networks.iptvNetwork.dhcp.pool_start)) {
            errors.pool_start = 'Pool Start is not in netmask';
          } else if (subnetIp === networks.iptvNetwork.dhcp.pool_start) {
            errors.pool_start = 'Pool Start matches the Interface Address';
          }
        } catch(err) {
          errors.pool_start = 'Invalid bytes';
        }

        try {
          if (!networks.iptvNetwork.dhcp || !checkIpFormat(networks.iptvNetwork.dhcp.pool_end)) {
            errors.pool_end = 'Must display in format (a.b.c.d)';
          } else if (!block.contains(networks.iptvNetwork.dhcp.pool_end)) {
            errors.pool_end = 'Pool End is not in netmask';
          } else if (subnetIp === networks.iptvNetwork.dhcp.pool_end) {
            errors.pool_end = 'Pool End matches the Interface Address';
          }
        } catch(err) {
          errors.pool_end = 'Invalid bytes';
        }

        try {
          if (!networks.iptvNetwork.dhcp || !checkIpFormat(networks.iptvNetwork.dhcp.dns_server)) {
            errors.dns_server = 'Must display in format (a.b.c.d)';
          } else if (!block.contains(networks.iptvNetwork.dhcp.dns_server)) {
            errors.dns_server = 'DNS server is not in netmask';
          }
        } catch(err) {
          errors.dns_server = 'Invalid bytes';
        }

        if (!errors.pool_start && !errors.pool_end) {
          const ipStart = ip2long(networks.iptvNetwork.dhcp.pool_start);
          const ipEnd = ip2long(networks.iptvNetwork.dhcp.pool_end);

          if (ipStart > ipEnd) {
            errors.pool_start = 'Pool End must be greater than the Pool Start';
            errors.pool_end = 'Pool End must be greater than the Pool Start';
          } else {
            if (!errors.subnet_mask) {
              const ipInterface = ip2long(subnetIp);
              if (ipInterface >= ipStart && ipEnd >= ipInterface) {
                errors.subnet_mask = 'Interface address cannot be within pool range';
              }
            }

            if (!errors.gateway_address) {
              const ipGateway = ip2long(networks.iptvNetwork.gateway_address);
              if (ipGateway >= ipStart && ipEnd >= ipGateway) {
                errors.gateway_address = 'Gateway cannot be within pool range';
              }
            }

            if (!errors.dns_server) {
              const ipDns = ip2long(networks.iptvNetwork.dhcp.dns_server);
              if (ipDns >= ipStart && ipEnd >= ipDns) {
                errors.dns_server = 'DNS server cannot be within pool range';
              }
            }
          }
        }
      }
    }
  }
  return errors;
}

export function checkSubnet(value) {
  return (/^([0-9]{1,3}\.){3}[0-9]{1,3}(\/([0-9]|[1-2][0-9]|3[0-2]))$/).test(value);
}

export function checkIpFormat(value) {
  return (/^([0-9]{1,3}\.){3}[0-9]{1,3}$/).test(value);
}

export function getDhcp(subnetMask) {
  const block = new Netmask(subnetMask);
  const returnObj = {};
  returnObj.pool_start = block.first;
  returnObj.pool_end = block.last;
  returnObj.dns_server = block.first;
  returnObj.device_filtering = false;
  return returnObj;
}

export function validHL7Network(clientObj, translations) {
  const errors = {};
  const subnets = Array.isArray(clientObj.subnets) ? clientObj.subnets.toString() : clientObj.subnets;
  const ipString = subnets.replace(/\n+/g, ',');
  const ipWhitespaceArray = ipString.replace(/\s+/g, '');
  const ipArray = ipWhitespaceArray.split(',');
  const commaArray = ipArray.filter((val) => val !== '');

  let invalidIps = '';
  for (const i of commaArray) {
    if (i.includes('/')) {
      if (!checkSubnet(i)) {
        invalidIps += `${i}, `;
      }
    } else if (!checkIpFormat(i)) {
      invalidIps += `${i}, `;
    }
  }

  if (invalidIps.length > 0) {
    errors.subnets = `${translations.subnet} ${invalidIps.slice(0, -2)}`;
  }

  return errors;
}
