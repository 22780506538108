import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLockoutModalSpinner } from '../../containers';
import ImportDialog from '../../containers/Dialog/ImportDialog';
import SonifiSnackbar from '../../containers/SonifiSnackBar';
import SonifiTemplate from '../../containers/SonifiTemplate';
import { getFirmwareRegex } from '../Firmware/actions/firmwareActions';
import { closeTermSnackBar, updateGlobalTermFiles } from './actions/globalTermFilesActions';
import GlobalTermFileTable from './containers/GlobalTermFileTable';
import { exampleCsv, parseTermInfo } from './utils/termFileImport';

class GlobalTermFiles extends Component {
  state = {
    showImport: false
  };

  componentDidMount() {
    this.props.dispatch(getFirmwareRegex());
  }

  handleButtonClick = () => {
    this.setState({ showImport: true });
  };

  parseImportFile(result) {
    const { regex, translations } = this.props;
    const termInfo = parseTermInfo(translations.csv, regex, result);

    if (termInfo.termFiles.length === 0) {
      return {
        errors: translations.csv.columnHeaderError
      };
    }

    return {
      tableList: termInfo.termFiles,
      allowSave: termInfo.configErrors,
      headers: termInfo.tableHeader,
      columnNames: termInfo.columnNames
    };
  }

  importList(data) {
    this.props.dispatch(updateGlobalTermFiles(data));
    this.setState({ showImport: false });
  }

  closeImportDialog = () => {
    this.setState({ showImport: false });
  };

  closeSnackBar() {
    this.props.dispatch(closeTermSnackBar());
  }

  getPageDetails() {
    return (
      <Grid container style={{ alignContent: 'start', width: '100%' }}>
        <Grid item xs={12} >
          <GlobalTermFileTable />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      globalTranslations, saving, snackBarMessage, snackBarType, translations
    } = this.props;
    const { showImport } = this.state;
    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={saving} />
        <SonifiTemplate
          header={translations.grid.header}
          icon={<Add />}
          label={globalTranslations.defaults.add}
          onSubmit={this.handleButtonClick.bind(this)}
          pageDetails={this.getPageDetails()}
          showButton={true}
        />
        {showImport && <ImportDialog
          csvText={exampleCsv()}
          globalTranslations={globalTranslations.importDialog}
          onCancel={this.closeImportDialog.bind(this)}
          parseImportFile={this.parseImportFile.bind(this)}
          saveListFunc={this.importList.bind(this)}
          title={translations.grid.importTermFiles}
        />}
        <SonifiSnackbar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations,
  regex: state.firmware.regex,
  saving: state.globalTermFiles.saving,
  snackBarMessage: state.globalTermFiles.snackBarMessage,
  snackBarType: state.globalTermFiles.snackBarType,
  translations: state.globalTermFiles.translations,
  userPermissions: state.global.permissions
});

GlobalTermFiles.propTypes = {
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  regex: PropTypes.array,
  saving: PropTypes.bool,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(GlobalTermFiles);
