import { Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { userTypes } from '../../constants/constants';
import TieMap from './TieMap';

export default class User extends TieMap {
  render() {
    return (
      <Fragment>
        <Grid item xs={12}>
          {this.getDropDown('A0', userTypes)}
          {this.getDropDown('A1', userTypes)}
          {this.getDropDown('A2', userTypes)}
          {this.getDropDown('A3', userTypes)}
          {this.getDropDown('A4', userTypes)}
          {this.getDropDown('A5', userTypes)}
          {this.getDropDown('A6', userTypes)}
        </Grid>
      </Fragment>
    );
  }
}
