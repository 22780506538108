import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../../containers/SonifiLabel';
import SonifiModalHeader from '../../../../containers/SonifiModalHeader';
import SonifiSpinner from '../../../../containers/SonifiSpinner';
import SonifiText from '../../../../containers/SonifiText';
import { getErrorText, isError } from '../../../../utils';
import { fetchFirmware } from '../../../Firmware/actions/firmwareActions';
import { saveSiteFirmware } from '../../actions/FirmwareActions';
import { editClientFile } from '../../actions/LgFirmwareActions';
import { CLIENT, LG } from '../../constants/TermFileConstants';

class LgClientDialog extends Component {
  state = {
    origFile: null
  };

  componentDidMount() {
    const { dispatch, lgClient } = this.props;
    dispatch(fetchFirmware({
      limit: 100, sort: 'id:asc', make: LG, model: '*', type: CLIENT
    }));

    this.setState({ origFile: lgClient !== null ? lgClient : '' });
  }

  updateFirmware() {
    const { dispatch, translations } = this.props;
    const { origFile } = this.state;

    if (this.props.lgClient === origFile) {
      this.onCancel();
      return;
    }

    const lgFirmwareObj = {};
    lgFirmwareObj.id = '*';
    lgFirmwareObj.firmware = { [CLIENT]: '' };

    if (origFile && origFile !== translations.unconfigureClient && origFile.trim() !== '') {
      lgFirmwareObj.firmware.CLIENT = origFile;
    }

    dispatch(saveSiteFirmware(lgFirmwareObj, LG));
  }

  onCancel() {
    this.props.dispatch(editClientFile(false));
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.onCancel();
    }
  }

  handleClientChange = () => ({ target: { value } }) => {
    this.setState({ origFile: value });
  };

  getBrowserOptions() {
    let clientOptions = [];
    const { loading, translations, configuredFirmware } = this.props;
    if (!loading) {
      clientOptions = [...configuredFirmware];
      if (this.props.lgClient !== '' && this.props.lgClient !== null && this.props.lgClient !== undefined) {
        const selected = find(clientOptions, (o) => o.id === this.props.lgClient);
        if (selected === undefined) {
          clientOptions.unshift({ id: this.props.lgClient, type: CLIENT });
        }
        clientOptions.unshift({ id: translations.unconfigureClient, type: CLIENT });
      }
    }
    return clientOptions;
  }

  render() {
    const {
      canEdit, fetching, globalTranslations, loading, translations
    } = this.props;
    const { errors, origFile } = this.state;

    return (
      <Dialog
        open={true}
        maxWidth="sm"
        onClose={this.onClose.bind(this)}
        fullWidth >
        <SonifiModalHeader
          header={translations.clientTitle}
          onlyClose={!canEdit || loading || fetching}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.updateFirmware.bind(this)}
          label={globalTranslations.save}
        />
        <DialogContent>
          {loading || fetching
            ? <SonifiSpinner />
            : <Grid container>
              <Grid item xs={12}>
                <SonifiLabel boldLabel={translations.clientText} />
              </Grid>
              <Grid item xs={8}>
                <SonifiText
                  defaultValue={origFile}
                  change={this.handleClientChange()}
                  select={true}
                  label={translations.client}
                  size="percent"
                  error={isError('client', errors)}
                  errorText={getErrorText('client', errors)}
                  items={this.getBrowserOptions().filter(
                    (suggestion) => (suggestion.type === CLIENT && !suggestion.corrupted)
                  ).map((suggestion) => ({
                    id: suggestion.id,
                    value: suggestion.id,
                    label: suggestion.id,
                  }))}
                />
              </Grid>
            </Grid>}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  configuredFirmware: state.firmware.firmware,
  fetching: state.firmware.fetching,
  globalTranslations: state.global.translations.defaults,
  lgClient: state.termFile.lgClient,
  loading: state.firmware.loading,
  translations: state.termFile.translations.dialog.lg
});

LgClientDialog.propTypes = {
  canEdit: PropTypes.bool,
  configuredFirmware: PropTypes.array,
  dispatch: PropTypes.func,
  fetching: PropTypes.bool,
  globalTranslations: PropTypes.object,
  lgClient: PropTypes.string,
  loading: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(LgClientDialog);
