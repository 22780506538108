import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../containers/SonifiLabel';
import { getRoleInfo, updateSelectedRole } from '../actions/roleActions';

class RoleRow extends Component {
  handleEditDialog() {
    this.setState({ open: false });
    this.props.dispatch(updateSelectedRole(this.props.rowIndex));
    this.props.dispatch(getRoleInfo(this.props.roles[this.props.rowIndex].id));
  }

  render() {
    const { rowIndex, roles } = this.props;
    const { id, owner, description } = roles[rowIndex];

    if (!id) {
      return <Fragment />;
    }

    return (
      <Fragment>
        <TableRow onClick={this.handleEditDialog.bind(this)} >
          <TableCell component="th" scope="row">
            <SonifiLabel label={`${id}`} />
          </TableCell>
          <TableCell component="th" scope="row">
            <SonifiLabel label={owner} />
          </TableCell>
          <TableCell component="th" scope="row">
            <SonifiLabel label={description} />
          </TableCell>
        </TableRow >
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  roles: state.roles.roles,
});

RoleRow.propTypes = {
  dispatch: PropTypes.func,
  roles: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired,
};

export default connect(mapStateToProps)(RoleRow);

