import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SonifiSection from '../../../containers/SonifiSection';
import SonifiText from '../../../containers/SonifiText';

export default class NameValueData extends Component {
  state = { data: null };

  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  getBodyInfo() {
    const { data } = this.state;
    const readOnly = true;
    return (
      <Fragment>
        {data.map(({ name, value, enumeration }, i) => (
          <SonifiText
            key={i}
            label={name}
            defaultValue={value}
            select={!!(enumeration) && !!enumeration.length}
            items={(enumeration)
              ? enumeration.map((choice) => ({
                id: choice,
                value: choice,
                label: choice
              }))
              : null}
            disabled={readOnly}
            size="md"
          />
        ))}
      </Fragment>
    );
  }

  render() {
    const { data } = this.state;
    const { sectionLabel } = this.props;

    if (!data) {
      return <Fragment></Fragment>;
    }

    return (
      <SonifiSection title={sectionLabel} body={this.getBodyInfo()} />
    );
  }
}

NameValueData.propTypes = {
  sectionLabel: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
};
