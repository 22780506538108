import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../../containers/SonifiLabel';
import SonifiPopover from '../../../../containers/SonifiPopover';
import SonifiSpinner from '../../../../containers/SonifiSpinner';
import SonifiTableHead from '../../../../containers/SonifiTableHead';
import SonifiTemplate from '../../../../containers/SonifiTemplate';
import { clearFirmware, fetchFirmwareFileBegin } from '../../../Firmware/actions/firmwareActions';
import { editSamsungFile } from '../../actions/SamsungFirmwareActions';
import { CLIENT } from '../../constants/TermFileConstants';
import SamsungClientDialog from './SamsungClientDialog';

class SamsungClientGrid extends Component {
  handleEditDialog() {
    this.props.dispatch(fetchFirmwareFileBegin());
    this.props.dispatch(clearFirmware());

    setTimeout(() => {
      this.props.dispatch(editSamsungFile(CLIENT));
    }, 200);
  }

  getPageDetails() {
    const {
      canEdit, samsungClient, samsungLoading, translations, globalTranslations
    } = this.props;

    const tableHeader = [
      { id: 'client', sortable: false, numeric: false, label: `${translations.samsung.client}` }
    ];
    return (
      <Table>
        <SonifiTableHead headColumns={tableHeader} />
        {samsungLoading
          ? <TableBody>
            <TableRow>
              <TableCell>
                <SonifiSpinner />
              </TableCell>
            </TableRow >
          </TableBody>
          : <TableBody>
            <SonifiPopover
              hideEdit={!canEdit}
              editFunc={this.handleEditDialog.bind(this)}
              editTitle={globalTranslations.edit}
              hideDelete={true}
              tableRow={
                <TableCell>
                  {!samsungClient || samsungClient.length === 0
                    ? <SonifiLabel error label={translations.samsung.errors.noClientFirmware} />
                    : samsungClient}
                </TableCell>
              }
            />
          </TableBody>
        }
      </Table>
    );
  }

  render() {
    const { canEdit, samsungEditType, translations } = this.props;
    return (
      <Fragment>
        <SonifiTemplate
          header={translations.samsung.clientTitle}
          pageDetails={this.getPageDetails()}
          multiple={true}
        />
        {samsungEditType !== '' && <SamsungClientDialog canEdit={canEdit} />}
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.termFile.changed,
  globalTranslations: state.global.translations.defaults,
  samsungClient: state.termFile.samsungClient,
  samsungEditType: state.termFile.samsungEditType,
  editClient: state.termFile.editClient,
  samsungLoading: state.termFile.samsungLoading,
  translations: state.termFile.translations.grid
});

SamsungClientGrid.propTypes = {
  canEdit: PropTypes.bool,
  changed: PropTypes.bool,
  globalTranslations: PropTypes.object,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  samsungClient: PropTypes.string,
  samsungEditType: PropTypes.string,
  editClient: PropTypes.bool,
  samsungLoading: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(SamsungClientGrid);
