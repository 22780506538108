import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  root: {
    'background': 'transparent',
    'borderStyle': 'solid',
    'color': theme.palette.primary.light,
    'fontFamily': 'Open Sans',
    'fontSize': '14pt',
    'fontWeight': 400, /* Avenir 55 */
    'margin': '8px',
    'padding': theme.spacing(1),
    '&:focus': {
      outlineStyle: 'solid',
      outlineColor: theme.palette.primary.main,
      outlineWidth: '2px'
    }
  },
});

export class SonifiInput extends Component {
  onChange = (event) => {
    this.props.onChange(event);
  };

  render() {
    const { classes, placeholder, value } = this.props;
    return (
      <input type="text"
        className={classes.root}
        name="inputBox"
        placeholder={placeholder}
        value={value}
        onChange={this.onChange}
      />
    );
  }
}

SonifiInput.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default withStyles(SonifiInput, styles, { withTheme: true });
