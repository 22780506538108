export function changeBinaryToDecimal(binary) {
  if (!binary) {
    return '1';
  }

  return parseInt(binary.toString(), 2);
}

export function changeDecimalToBinary(dec) {
  if (!dec) {
    return '00000001';
  }

  // eslint-disable-next-line no-bitwise
  let decimalString = (dec >>> 0).toString(2);
  while (decimalString.length < 8) {
    decimalString = `0${decimalString}`;
  }

  return decimalString;
}
