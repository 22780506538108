import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  root: {
    // display: 'flex',
    fontSize: '12pt',
    fontWeight: 400, /* Avenir 65 */
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing()
  },
  bold: {
    display: 'inline-block',
    fontWeight: 900, /* Avenir 85 */
    paddingRight: '6px'
  },
  error: {
    color: 'red',
  },
  addLeftPadding: {
    paddingLeft: theme.spacing()
  },
  halfLeftPadding: {
    paddingLeft: '6px'
  },
  xs: {
    fontWeight: 500, /* Avenir 65 */
    fontSize: '9pt'
  },
  md: {
    fontWeight: 500, /* Avenir 65 */
    fontSize: '16px',
    paddingRight: '6px'
  },
  disabled: {
    color: theme.palette.primary.disabled
  },
  centered: {
    textAlign: 'center'
  },
  blue: {
    color: theme.palette.secondary.main
  },
  noPadding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  italic: {
    fontStyle: 'italic'
  },
  hide: {
    visibility: 'hidden'
  },
  grid: {
    display: 'grid'
  },
  flexDisplay: {
    display: 'flex'
  },
  topMargin: {
    marginTop: '1.5em'
  }
});

export class SonifiLabel extends Component {
  render() {
    const {
      additionalClasses, blue, boldLabel, centered, classes, disabled, error, flexDisplay, grid, halfLeftPadding, hide,
      italic, label, noPadding, paddingLeft, size, topMargin
    } = this.props;

    return (
      <div className={`${classes.root} 
      ${additionalClasses} 
      ${paddingLeft && classes.addLeftPadding}
      ${halfLeftPadding && classes.halfLeftPadding} 
      ${error && classes.error}
      ${(size && size === 'xs') && classes.xs}
      ${(size && size === 'md') && classes.md}
      ${centered && classes.centered}
      ${blue && classes.blue}
      ${disabled && classes.disabled}
      ${italic && classes.italic}
      ${hide && classes.hide}
      ${grid && classes.grid}
      ${flexDisplay && classes.flexDisplay}
      ${noPadding && classes.noPadding}
      ${topMargin && classes.topMargin}`
      }>
        <div className={boldLabel && classes.bold}>{boldLabel}</div> {label}
      </div >
    );
  }
}

SonifiLabel.propTypes = {
  additionalClasses: PropTypes.string,
  blue: PropTypes.bool,
  boldLabel: PropTypes.string,
  centered: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  flexDisplay: PropTypes.bool,
  grid: PropTypes.bool,
  halfLeftPadding: PropTypes.bool,
  hide: PropTypes.bool,
  italic: PropTypes.bool,
  label: PropTypes.string,
  noPadding: PropTypes.bool,
  paddingLeft: PropTypes.bool,
  size: PropTypes.string,
  topMargin: PropTypes.bool,
};

export default withStyles(SonifiLabel, styles, { withTheme: true });
