export const CHANGE = 'CHANGE';
export const GET_SITE_BEGIN = 'GET_SITE_BEGIN';
export const GET_SITE_FAILURE = 'GET_SITE_FAILURE';
export const GET_SITE_SUCCESS = 'GET_SITE_SUCCESS';
export const GET_SYSTEM_SCHEDULE_BEGIN = 'GET_SYSTEM_SCHEDULE_BEGIN';
export const GET_SYSTEM_SCHEDULE_ERROR = 'GET_SYSTEM_SCHEDULE_ERROR';
export const GET_SYSTEM_SCHEDULE_SUCCESS = 'GET_SYSTEM_SCHEDULE_SUCCESS';
export const HIERARCHY_LOADED = 'HIERARCHY_LOADED';
export const LOAD_HIERARCHY_ROOMS_SUCCESS = 'LOAD_HIERARCHY_ROOMS_SUCCESS';
export const LOAD_LANGUAGE_LITERALS = 'LOAD_LANGUAGE_LITERALS';
export const LOAD_START_HIERARCHY_BEGIN = 'LOAD_START_HIERARCHY_BEGIN';
export const LOAD_START_HIERARCHY_FAILURE = 'LOAD_START_HIERARCHY_FAILURE';
export const LOAD_START_HIERARCHY_SUCCESS = 'LOAD_START_HIERARCHY_SUCCESS';
export const LOAD_START_HIERARCHY_UPDATED = 'LOAD_START_HIERARCHY_UPDATED';
export const PATCH_SITE_BEGIN = 'PATCH_SITE_BEGIN';
export const PATCH_SITE_FAILURE = 'PATCH_SITE_FAILURE';
export const PATCH_SITE_SUCCESS = 'PATCH_SITE_SUCCESS';
export const RESET_LOADING_SITE = 'RESET_LOADING_SITE';
export const CLOSE_SNACK_BAR = 'CLOSE_SNACK_BAR';
export const UPDATE_SNACK_BAR = 'UPDATE_SNACK_BAR';
export const SET_PROD_STATUS = 'SET_PROD_STATUS';
export const SET_TAB_SELECTED = 'SET_TAB_SELECTED';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const UPDATE_GLOBAL_ERROR = 'UPDATE_GLOBAL_ERROR';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_LANGUAGES = 'UPDATE_LANGUAGES';
export const UPDATE_PRODUCTION_STATUS = 'UPDATE_PRODUCTION_STATUS';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_SITE = 'UPDATE_SITE';
export const UPDATE_SITES = 'UPDATE_SITES';
