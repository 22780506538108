import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiCheckbox from '../../../containers/SonifiCheckbox';
import SonifiLabel from '../../../containers/SonifiLabel';
import { SCOPE_OPTIONS } from '../constants/TieApiClientConstants';

class ScopeTable extends Component {
  state = {
    filterActive: false,
    selectAllChecked: false,
    selected: [],
    loaded: false
  };

  componentDidMount() {
    const { selectedScope } = this.props;
    if (selectedScope) {
      this.setState({ selected: [...selectedScope] });
    }
  }

  checkboxChange = () => () => {
    const tempArr = [];
    SCOPE_OPTIONS.map((item) => (
      tempArr.push(item)
    ));
    if (this.state.selectAllChecked) {
      this.setState({
        selected: [], selectAllChecked: false
      }, () => {
        this.props.updateSelectedScope(this.state.selected);
      });
    } else {
      this.setState({
        selected: [...tempArr], selectAllChecked: true
      }, () => {
        this.props.updateSelectedScope(this.state.selected);
      });
    }
  };

  isItemInList = (item) => {
    const { selected } = this.state;
    return selected.length === 0 ? false : selected.includes(item);
  };

  onItemChange = (item) => () => {
    const { selected } = this.state;
    const updateSelected = [...selected];
    const index = updateSelected.findIndex((channel) => channel === item);

    if (index === -1) {
      updateSelected.push(item);
    } else if (index !== -1) {
      updateSelected.splice(index, 1);
    }

    this.setState({ selected: updateSelected, selectAllChecked: false });
    this.props.updateSelectedScope(updateSelected);
  };

  render() {
    const { canEdit, editType, error, translations } = this.props;
    const { selected } = this.state;

    const tempSelected = [...selected];
    const readOnly = editType === 'read' || !canEdit;
    for (let i = 0; i < selected.length; i++) {
      const idx = SCOPE_OPTIONS.indexOf(selected[i]);
      if (idx === -1) {
        tempSelected.splice(i, 1);
        this.setState({ selected: tempSelected });
        this.props.updateSelectedScope(tempSelected);
      }
    }
    return <Grid container
      style={{ overflow: 'auto', border: '1px solid #d6d6d6', padding: '18px', marginBottom: '10px' }}>
      <Grid item xs={12}>
        <SonifiLabel label={translations.scope} noPadding />
      </Grid>
      {SCOPE_OPTIONS.map((item, index) => (
        <Grid item xs={4} key={index} >
          <SonifiCheckbox
            disabled={readOnly}
            label={item}
            noPadding
            error={error}
            onChange={this.onItemChange(item)}
            value={this.isItemInList(item)}
          />
        </Grid>
      ))}
    </Grid>;
  }
}

const mapStateToProps = (state) => ({
  editType: state.tieClients.editType,
  translations: state.tieClients.translations.dialog
});

ScopeTable.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  error: PropTypes.bool,
  filter: PropTypes.string,
  roomTypes: PropTypes.array,
  selectedScope: PropTypes.array,
  translations: PropTypes.object,
  updateSelectedScope: PropTypes.func
};

export default connect(mapStateToProps)(ScopeTable);
