import { orderBy, uniqBy } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { EDIT, ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  activeClient: null,
  clients: [],
  editType: EDIT,
  gettingSites: false,
  loading: false,
  maxPages: 1,
  saving: false,
  selectedClient: -1,
  sites: [],
  snackBarMessage: '',
  snackBarType: SUCCESS,
  translations: null
};

export default function tieClientsReducer(state = initialState, action) {
  let miscString = '',
    miscArray = null;

  switch (action.type) {
    case types.ACTIVE_CHECK:
      return { ...state, activeClient: action.activeClient, snackBarMessage: '' };

    case types.ADD_CLIENT_SUCCESS:
      miscString = `${action.update === EDIT
        ? state.translations.reducer.editClient
        : state.translations.reducer.updateClient}`;
      if (action.update === 'add') {
        miscString = state.translations.reducer.addClient;
      }

      return {
        ...state,
        saving: false,
        activeClient: null,
        selectedClient: -1,
        snackBarMessage: `${miscString} ${decodeURIComponent(action.client.id)}`,
        snackBarType: SUCCESS
      };

    case types.ADD_CLIENT_FAILURE:
      return {
        ...state,
        saving: false,
        snackBarMessage:
          `${state.translations.reducer.addClientError} ${decodeURIComponent(action.client.id)} - (${action.error})`,
        snackBarType: ERROR
      };

    case types.ADD_CLIENT_VALIDATION_FAILURE:
      return {
        ...state, saving: false
      };

    case types.CLEAR_SITES_TABLE:
      return {
        ...state,
        sites: []
      };

    case types.GET_TIE_API_CLIENTS_BEGIN:
      return {
        ...state,
        loading: true,
        selectedClient: -1,
        snackBarMessage: '',
        clients: (action.reset ? [] : [...state.clients])
      };

    case types.GET_TIE_API_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.clients,
        loading: false
      };

    case types.GET_TIE_API_CLIENTS_FAILURE:
      return {
        ...state,
        clients: [],
        loading: false,
        snackBarMessage: `${state.translations.reducer.retrievingError} - (${action.error})`,
        snackBarType: ERROR
      };

    case types.UPDATE_SELECTED_CLIENT:
      return {
        ...state,
        editType: action.editType,
        selectedClient: action.client,
        snackBarMessage: ''
      };

    case types.ADD_CLIENT_BEGIN:
      return { ...state, saving: true };

    case types.UPDATE_SNACK_BAR:
      return {
        ...state,
        snackBarType: (action.snackBarType === null
          ? state.snackBarType
          : action.snackBarType),
        snackBarMessage: action.snackBarMessage
      };

    case types.GET_TIE_SITES_BEGIN:
      return { ...state, gettingSites: true };

    case types.GET_TIE_SITES_SUCCESS:
      miscArray = uniqBy([...state.sites, ...action.sites.sites], 'id');
      return {
        ...state,
        gettingSites: false,
        maxPages: action.sites.page_count,
        sites: orderBy(miscArray, 'id')
      };

    case types.GET_TIE_SITES_FAILURE:
      return { ...state, gettingSites: false };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
