import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiText } from '../../../containers/';
import { HOURS } from '../constants/constants';

class Generator extends Component {
  componentDidMount() {
    this.props.updateTypeState('timezone', this.props.timezone);
  }

  changeValue = (name) => ({ target: { value } }) => {
    this.props.updateTypeState(name, value);
  };

  render() {
    const { editState, notEditable, timezone, translations } = this.props;
    return (
      <Grid item xs={12}>
        <SonifiText
          size="lg"
          label={translations.timezone}
          defaultValue={timezone}
          disabled={true}
        />
        <SonifiText
          size="lg"
          label={translations.hour}
          defaultValue={editState.hour}
          change={this.changeValue('hour')}
          disabled={notEditable}
          select={true}
          items={HOURS}
        />
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
  translations: state.integrations.translations.generator
});

Generator.propTypes = {
  editState: PropTypes.object,
  notEditable: PropTypes.bool,
  timezone: PropTypes.string,
  translations: PropTypes.object,
  updateTypeState: PropTypes.func
};

export default connect(mapStateToProps)(Generator);
