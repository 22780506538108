import { displaySnackBar } from '../../../actions/globalActions';
import { PRODUCT_PARENTS, RELEASE_TEMPLATES } from '../../../constants/apiEndpoints';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import { MAX_SIZE } from '../../../constants/magic';
import {
  getObjectPromise, getSitelessObjectPromise, patchSitelessObjectPromise, postSitelessObjectPromise
} from '../../../utils/api';
import * as types from './actionTypes';

export const updateSelectedProdParentBegin = (value) => ({
  type: types.UPDATE_PRODUCT_PARENTS_BEGIN,
  value,
});

export const updateSelectedProdParentSuccess = (channels) => ({
  type: types.UPDATE_PRODUCT_PARENTS_SUCCESS,
  channels,
});

export const updateSelectedProdParentFailure = (error) => ({
  type: types.UPDATE_PRODUCT_PARENTS_FAILURE,
  error,
});

export const updateDeleteDeployment = (deployment, statusIndex) => ({
  type: types.DELETE_CHECK,
  deployment,
  statusIndex
});

export function updateSelectedProdParent(value, query) {
  return (dispatch) => {
    dispatch(updateSelectedProdParentBegin(value));
    return getObjectPromise(PRODUCT_PARENTS, `${value}/versions`, query)
      .then((data) => {
        dispatch(updateSelectedProdParentSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(updateSelectedProdParentFailure(error));
        return error;
      });
  };
}

export const updateDeploymentBar = (snackBarMessage, snackBarType = null) => ({
  type: types.UPDATE_DEPLOYMENTS_BAR,
  snackBarType,
  snackBarMessage,
});

export const getProductParentsBegin = () => ({
  type: types.GET_PRODUCT_PARENTS_BEGIN,
});

export const getProductParentsSuccess = (deployments) => ({
  type: types.GET_PRODUCT_PARENTS_SUCCESS,
  deployments,
});

export const getProductParentsFailure = () => ({
  type: types.GET_PRODUCT_PARENTS_FAILURE
});

export function getProductParents(setInitProdParent = false) {
  return (dispatch) => {
    dispatch(getProductParentsBegin());
    return getObjectPromise(PRODUCT_PARENTS, null, { limit: 10000 })
      .then((data) => {
        dispatch(getProductParentsSuccess(data));
        if (setInitProdParent) {
          dispatch(updateSelectedProdParent(data['product-parents'][0].id, { page: 1, sort: 'id:desc' }));
        }
        return data;
      })
      .catch((error) => {
        dispatch(getProductParentsFailure());
        return error;
      });
  };
}

export const updateSelectedDeployment = (index, subindex, editType = '') => ({
  type: types.UPDATE_SELECTED_DEPLOYMENT,
  index,
  subindex,
  editType,
});

export const editSelectedDeploymentBegin = () => ({
  type: types.EDIT_SELECTED_DEPLOYMENT_BEGIN,
});

export const editSelectedDeploymentSuccess = () => ({
  type: types.EDIT_SELECTED_DEPLOYMENT_SUCCESS
});

export const editSelectedDeploymentFailure = (info) => ({
  type: types.EDIT_SELECTED_DEPLOYMENT_FAILURE,
  info,
});

export function editSelectedDeployment(productId, versionId, channels, virtos) {
  return (dispatch) => {
    dispatch(editSelectedDeploymentBegin());
    return patchSitelessObjectPromise(PRODUCT_PARENTS, `${productId}/versions/${versionId}`, { channels, virtos })
      .then((data) => {
        dispatch(editSelectedDeploymentSuccess(data));
        return data;
      })
      .then((data) => {
        dispatch(updateSelectedProdParent(productId));
        return data;
      })
      .catch((error) => {
        dispatch(editSelectedDeploymentFailure(`Error modifying deployments (${error.status})`));
        return error;
      });
  };
}

export const getMoreVersionsBegin = (reset) => ({
  type: types.GET_MORE_PP_VERSIONS_BEGIN,
  reset
});

export const getMoreVersionsSuccess = (channels) => ({
  type: types.GET_MORE_PP_VERSIONS_SUCCESS,
  channels
});

export const getMoreVersionsFailure = (error) => ({
  type: types.GET_MORE_PP_VERSIONS_FAILURE,
  error
});

export function getMoreVersions(value, pageLimits) {
  return (dispatch) => {
    dispatch(getMoreVersionsBegin(pageLimits.page === 1));
    return getObjectPromise(PRODUCT_PARENTS, `${value}/versions`, pageLimits)
      .then((data) => {
        dispatch(getMoreVersionsSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(getMoreVersionsFailure(error));
        return error;
      });
  };
}

export const getReleaseVersionsBegin = () => ({
  type: types.GET_RELEASE_VERSIONS_BEGIN
});

export const getReleaseVersionsSuccess = (packages, orderBy, order) => ({
  type: types.GET_RELEASE_VERSIONS_SUCCESS,
  packages,
  orderBy,
  order
});

export const getReleaseVersionsFailure = (error) => ({
  type: types.GET_RELEASE_VERSIONS_FAILURE,
  error
});

export function getReleaseVersions(selectedProductParent, id, orderBy, order, virtos) {
  return (dispatch) => {
    dispatch(getReleaseVersionsBegin());
    return getObjectPromise(PRODUCT_PARENTS, `${selectedProductParent}/versions/${id}`, { virtos })
      .then((data) => {
        if (!data.packages) {
          dispatch(getReleaseVersionsFailure('fail'));
          return false;
        }
        dispatch(getReleaseVersionsSuccess(data.packages, orderBy, order));
        return data;
      })
      .catch((error) => {
        dispatch(getReleaseVersionsFailure(error.status));
        return error;
      });
  };
}

export const sortReleaseVersions = (orderBy, order) => ({
  type: types.SORT_RELEASE_VERSIONS,
  orderBy,
  order
});


export const getVirtosPkgsBegin = () => ({
  type: types.GET_VIRTOS_PKGS_BEGIN
});

export const getVirtosPkgsSuccess = (packages) => ({
  type: types.GET_VIRTOS_PKGS_SUCCESS,
  packages
});

export const getVirtosPkgsFailure = (error) => ({
  type: types.GET_VIRTOS_PKGS_FAILURE,
  error
});

export function getVirtosPkgs(virtos) {
  // This is a paginated endpoint, so fetch all the pages before dispatching a "Success"
  return (dispatch) => {
    dispatch(getVirtosPkgsBegin());
    return getSitelessObjectPromise(`virtos/${virtos}/packages`, null, { order: 'asc', virtos, limit: MAX_SIZE })
      .then((results) => {
        dispatch(getVirtosPkgsSuccess(results.packages));
        return results.packages;
      })
      .catch((error) => {
        try {
          error.json().then((err) => {
            dispatch(getVirtosPkgsFailure(`${err.detail} (${err.status})`));
          });
        } catch(err) {
          dispatch(getVirtosPkgsFailure(`${error.detail} (${error.status})`));
        }
        return false;
      });
  };
}

export const addManualVersionBegin = () => ({
  type: types.ADD_MANUAL_VERSION_BEGIN,
});

export const addManualVersionComplete = (closeDialog) => ({
  type: types.ADD_MANUAL_VERSION_COMPLETE,
  closeDialog
});

export function addManualVersion(templateId, productId, releaseState, id) {
  return (dispatch) => {
    dispatch(addManualVersionBegin());

    const pkgObj = [];
    for (const [key, value] of Object.entries(releaseState)) {
      pkgObj.push({ name: `${key}`, version: `${value}` });
    }

    const submitObj = { packages: pkgObj };
    if (id && id !== null && id !== undefined) {
      submitObj.create_from_version = id;
    }

    return postSitelessObjectPromise(RELEASE_TEMPLATES, `${templateId}/versions`, submitObj)
      .then((data) => {
        if (data.status && !httpSuccess(data.status)) {
          dispatch(addManualVersionComplete(false));
          dispatch(displaySnackBar(ERROR, `${data.detail} - (${data.status})`));
        } else {
          dispatch(addManualVersionComplete(true));
          dispatch(displaySnackBar(SUCCESS, `Successfully created version ${data}`));
        }
        return data;
      })
      .then((data) => {
        dispatch(updateSelectedProdParent(productId));
        return data;
      })
      .catch((error) => {
        console.log('Error', error);
        dispatch(displaySnackBar(ERROR, `Error creating a new release version - (${error.status})`));
        return error;
      });
  };
}
