import { OPTIONS_RESOURCE, ROOM_RESOURCE, TERMINAL_RESOURCE } from '../../../constants/apiEndpoints';
import { getSiteObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

export const closeRoomDialog = () => ({
  type: types.CLOSE_ROOM_INFO
});

export const fetchRoomsBegin = () => ({
  type: types.FETCH_ROOMS_BEGIN
});

export const fetchRoomsSuccess = (products) => ({
  type: types.FETCH_ROOMS_SUCCESS,
  products
});

export const fetchRoomsFailure = (error) => ({
  type: types.FETCH_ROOMS_FAILURE,
  error
});

export const fetchOccupiedRoomCountBegin = () => ({
  type: types.FETCH_OCCUPIED_ROOM_COUNT_BEGIN
});

export const fetchOccupiedRoomCountSuccess = (occupiedRoomCount) => ({
  type: types.FETCH_OCCUPIED_ROOM_COUNT_SUCCESS,
  occupiedRoomCount
});

export const fetchOccupiedRoomCountFailure = (error) => ({
  type: types.FETCH_OCCUPIED_ROOM_COUNT_FAILURE,
  error
});

export const fetchRoomOptionsBegin = () => ({
  type: types.FETCH_ROOM_OPTIONS_BEGIN
});

export const fetchRoomOptionsSuccess = (products) => ({
  type: types.FETCH_ROOM_OPTIONS_SUCCESS,
  products
});

export const fetchRoomOptionsFailure = (error) => ({
  type: types.FETCH_ROOM_OPTIONS_FAILURE,
  error
});

export const getRoomBegin = (roomIndex) => ({
  type: types.GET_ROOM_INFO_BEGIN,
  roomIndex
});

export const getRoomSuccess = (products) => ({
  type: types.GET_ROOM_INFO_SUCCESS,
  products
});

export const getRoomFailure = (error) => ({
  type: types.GET_ROOM_INFO_FAILURE,
  error
});

export const getTerminalsBegin = (roomID) => ({
  type: types.GET_TERMINALS_BEGIN,
  roomID
});

export const getTerminalsSuccess = (terminals) => ({
  type: types.GET_TERMINALS_SUCCESS,
  terminals
});

export const getTerminalsFailure = (error) => ({
  type: types.GET_TERMINALS_FAILURE,
  error
});

export function fetchRooms() {
  return (dispatch) => {
    dispatch(fetchRoomsBegin());
    return getSiteObjectPromise(ROOM_RESOURCE, null, null, { limit: 100, page: 1 })
      .then((json) => {
        dispatch(fetchRoomsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchRoomsFailure(error)));
  };
}

export function fetchOccupiedRoomsCount() {
  return (dispatch) => {
    dispatch(fetchOccupiedRoomCountBegin());
    const filter = { limit: 1, page: 1, occupied: 'true' };
    return getSiteObjectPromise(ROOM_RESOURCE, null, null, filter)
      .then((json) => {
        dispatch(fetchOccupiedRoomCountSuccess(json.total_items));
        return json;
      })
      .catch((error) => dispatch(fetchOccupiedRoomCountFailure(error)));
  };
}

export function fetchRoomsWithoutTerminalsCount() {
  return (dispatch) => {
    dispatch(fetchRoomsWithoutTerminalsCountBegin());
    const filter = { limit: 1, page: 1, has_terminals: false };
    return getSiteObjectPromise(ROOM_RESOURCE, null, null, filter)
    .then((json) => {
      dispatch(fetchRoomsWithoutTerminalsCountSuccess());
      return json.total_items;
    })
    .catch((error) => dispatch(fetchRoomsWithoutTerminalsCountFailure(error)));
  };
}
export const fetchRoomsWithoutTerminalsCountBegin = () => ({
  type: types.FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_BEGIN
});

export const fetchRoomsWithoutTerminalsCountFailure = (error) => ({
  type: types.FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_FAILURE,
  error
});

export const fetchRoomsWithoutTerminalsCountSuccess = (json) => ({
  type: types.FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_SUCCESS,
  json
});

export function fetchRoomsOptions() {
  return (dispatch) => {
    dispatch(fetchRoomOptionsBegin());
    return getSiteObjectPromise(OPTIONS_RESOURCE)
      .then((json) => {
        dispatch(fetchRoomOptionsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchRoomOptionsFailure(error)));
  };
}

export function editRoom(roomIndex, roomID) {
  return (dispatch) => {
    dispatch(getRoomBegin(roomIndex));

    return getSiteObjectPromise(ROOM_RESOURCE, encodeURIComponent(roomID))
      .then((json) => {
        json.editRoomNum = roomID;
        dispatch(getRoomSuccess(json));
        return json;
      })
      .catch((error) => dispatch(getRoomFailure(error)));
  };
}


export function getTerminals(roomID) {
  return (dispatch) => {
    dispatch(getTerminalsBegin(roomID));
    return getSiteObjectPromise(ROOM_RESOURCE, `${encodeURIComponent(roomID)}/${TERMINAL_RESOURCE}`)
      .then((json) => {
        dispatch(getTerminalsSuccess(json));
        return json;
      }).catch((error) => dispatch(getTerminalsFailure(error)));
  };
}


export function closeDialog() {
  return (dispatch) => {
    dispatch(closeRoomDialog());
  };
}
