import { CheckCircleOutline, Replay, RestartAlt } from '@mui/icons-material';
import { TableCell, Tooltip } from '@mui/material';
import { filter } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { displaySnackBar } from '../../../actions/globalActions';
import { USERS } from '../../../constants/apiEndpoints';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { httpSuccess, isClientError } from '../../../constants/http';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiPopover from '../../../containers/SonifiPopover';
import { patchSitelessObjectPromise } from '../../../utils/api';
import { resendPassword, updateDeleteRow, updateSelectedUser } from '../actions/userActions';
import { CONFIRMED, FORCE_CHANGE_PASSWORD, RESET_REQUIRED } from '../constants/index';

const styles = () => ({
  goodStatus: {
    color: 'green !important',
    width: '100%'
  }
});

class UserRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mfaResetVerifyOpen: false,
      mfaResetVerified: false
    };

    this.setMfaResetVerification = this.setMfaResetVerification.bind(this);
    this.displayMfaResetVerification = this.displayMfaResetVerification.bind(this);
    this.openMfaResetVerifyDialog = this.openMfaResetVerifyDialog.bind(this);
    this.closeMfaResetVerifyDialog = this.closeMfaResetVerifyDialog.bind(this);
  }

  handleEditDialog() {
    const { dispatch, user } = this.props;
    dispatch(updateSelectedUser(user));
  }

  handleDeleteDialog() {
    const { dispatch, user } = this.props;
    dispatch(updateDeleteRow(user));
  }

  resendEmailPopup() {
    const { dispatch, user } = this.props;
    dispatch(resendPassword(user));
  }

  openMfaResetVerifyDialog() {
    this.displayMfaResetVerification(true);
  }

  closeMfaResetVerifyDialog() {
    this.displayMfaResetVerification(false);
  }

  setMfaResetVerification(isVerified) {
    this.setState({ mfaResetVerified: isVerified });
  }

  displayMfaResetVerification(isOpen) {
    this.setState({ mfaResetVerifyOpen: isOpen });
  }

  mfaResetHandler = async () => {
    const { user, dispatch, snackbarTranslations } = this.props;
    // eslint-disable-next-line no-undef
    try {
      const response = await patchSitelessObjectPromise(USERS, user.email, { phone_number: '' });
      console.log(response);
      if (httpSuccess(response.status)) {
        dispatch(displaySnackBar(SUCCESS, snackbarTranslations.mfaResetSuccess));
        this.closeMfaResetVerifyDialog();
        this.setMfaResetVerification(false);
      } else if (isClientError(response.status)) {
        dispatch(displaySnackBar(ERROR, `${snackbarTranslations.mfaResetFailure}`));
      }
    } catch(error) {
      console.log(error);
      dispatch(displaySnackBar(ERROR, `${snackbarTranslations.mfaResetFailure}`));
    }
  };

  render() {
    const {
      canEdit, classes, globalTranslations, groups, key, translations, user,
      confirmTranslations
    } = this.props;

    const { mfaResetVerifyOpen } = this.state;

    if (!user.email || !groups) {
      return <Fragment />;
    }

    const groupDesc = filter(groups, (o) => (o.id || o) === user.group);

    return (
      <Fragment>
        <SonifiPopover
          functionOneFunc={this.openMfaResetVerifyDialog}
          functionOneIcon={<RestartAlt fontSize="small" />}
          functionOneTitle={translations.mfaReset}
          showFunctionOne={user.status === CONFIRMED || user.status === RESET_REQUIRED}
          deleteFunc={this.handleDeleteDialog.bind(this)}
          deleteTitle={globalTranslations.delete}
          editFunc={this.handleEditDialog.bind(this)}
          editTitle={(canEdit ? globalTranslations.edit : globalTranslations.view)}
          functionTwoFunc={this.resendEmailPopup.bind(this)}
          functionTwoIcon={<Replay fontSize="small" />}
          functionTwoTitle={translations.resend}
          hideDelete={!canEdit}
          key={key}
          showFunctionTwo={user.status === FORCE_CHANGE_PASSWORD}
          tableRow={
            <Fragment>
              <TableCell>{user.given_name} {user.family_name}
                {this.props.children}
              </TableCell>
              <TableCell>{groupDesc.length > 0 ? groupDesc[0].description : ''}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.role}</TableCell>
              <TableCell>{user.last_login === null
                ? ''
                : moment(user.last_login, 'YYYYMMDDHHmmss').format('MM/DD/YYYY h:mm a')}
              </TableCell>
              <TableCell component="th" scope="row" >
                {user.status !== FORCE_CHANGE_PASSWORD &&
                  <Tooltip title={translations.validatedUser}>
                    <CheckCircleOutline className={classes.goodStatus} />
                  </Tooltip>}
              </TableCell>
            </Fragment >
          }
        />
        <SonifiConfirm
          dialogOpen={mfaResetVerifyOpen}
          onConfirm={this.mfaResetHandler}
          onCancel={this.closeMfaResetVerifyDialog}
          confirmTitle={confirmTranslations.mfaResetValidationTitle}
          confirmText={`Are your sure you want to reset ${user.given_name} ${user.family_name} MFA?`}
          buttonCancelText={confirmTranslations.mfaResetCancel}
          buttonConfirmText={confirmTranslations.mfaResetConfirm}
          testId={'mfa-reset-confirm-dialog'}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  groups: state.groups.groups,
  translations: state.users.translations.popOver,
  confirmTranslations: state.users.translations.confirmDialog,
  snackbarTranslations: state.users.translations.snackBar,
  userPermissions: state.global.permissions
});

UserRow.propTypes = {
  canEdit: PropTypes.bool,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  groups: PropTypes.array,
  key: PropTypes.number,
  translations: PropTypes.object,
  confirmTranslations: PropTypes.object,
  snackbarTranslations: PropTypes.object,
  user: PropTypes.object,
  userPermissions: PropTypes.array,
  displayMfaResetVerification: PropTypes.func,
  setMfaResetVerification: PropTypes.func
};

export default connect(mapStateToProps)(withStyles(UserRow, styles, { withTheme: true }));
