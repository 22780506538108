import { Button, Grid, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  normal: {
    color: theme.palette.primary.contrastText
  }
});

/* (const InfoTooltip = withStyles({
  tooltip: {
    color: '#1592d9',
    backgroundColor: 'white',
    border: '#1592d9 solid 1px'
  }
})(Tooltip);
*/

export class SonifiSecondaryButton extends Component {
  getMiddleInfo() {
    const {
      classes, disabled, icon, label, onClick
    } = this.props;
    return (
      <div>
        <Button
          disabled={disabled}
          variant="text"
          className={classes.normal}
          onClick={onClick}
        >
          {icon && <Fragment>
            {icon}&nbsp;
          </Fragment>}
          <div style={{ paddingTop: '5px' }}>
            {label}
          </div>
        </Button>
      </div>
    );
  }

  render() {
    const {
      label, showToolTip, toolTipMessage
    } = this.props;

    return (
      <Grid item>
        <Tooltip title={toolTipMessage || label}
          disableFocusListener={!showToolTip}
          disableHoverListener={!showToolTip}
          disableTouchListener={!showToolTip}>
          {this.getMiddleInfo()}
        </Tooltip>
        {/* {error
          ? <InfoTooltip title={toolTipMessage || label}
            disableFocusListener={!showToolTip}
            disableHoverListener={!showToolTip}
            disableTouchListener={!showToolTip}>
            {this.getMiddleInfo()}
          </InfoTooltip>
          : <Tooltip title={toolTipMessage || label}
            disableFocusListener={!showToolTip}
            disableHoverListener={!showToolTip}
            disableTouchListener={!showToolTip}>
            {this.getMiddleInfo()}
          </Tooltip>
        } */}
      </Grid>
    );
  }
}
SonifiSecondaryButton.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.object,
  label: PropTypes.string,
  toolTipMessage: PropTypes.string,
  showToolTip: PropTypes.bool,
};

export default withStyles(SonifiSecondaryButton, styles, { withTheme: true });
