import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLateCheckoutFromSession, getSiteNumFromURI } from '../../../utils';
import PurchaseGrid from './components/PurchaseGrid';

export default function PurchaseLog() {
  if (getSiteNumFromURI() === null || getLateCheckoutFromSession() !== 'true') {
    return <Navigate to="/" />;
  }

  return (
    <PurchaseGrid />
  );
}
