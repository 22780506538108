export const newEmptyLineup = (id) => ({
  bat_warmup_seconds: 6,
  cables: 'coax',
  channels: [],
  classification: 'consumer',
  encryption: 'none',
  id,
  isNew: true,
  is_bat_enabled: false,
  is_power_on_enabled: true,
  is_power_on_surfable: false,
  mpeg_format: 'mpeg2',
  name: '',
  power_on_ipg_source_id: '',
  tuner_type: 'digital'
});

export const propertyCables = ['coax', 'ip', 'ip/coax']; // terminals
export const tunerType = ['analog', 'digital']; //
export const mpegFormat = ['mpeg2', 'mpeg4'];
export const encryptionType = ['none', 'proidiom'];

export const TVCABLES_COAX = 'coax';
export const TVCABLES_IP = 'ip';
export const TVCABLES_IP_COAX = 'ip/coax';
export const TVMPEG2 = 'mpeg2';
export const TVMPEG4 = 'mpeg4';
export const TVTUNER_ANALOG = 'analog';
export const TVTUNER_DIGITAL = 'digital';
export const NONE = 'none';
export const PROIDIOM = 'proidiom';
