import { Delete } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Draggable, Droppable } from '@hello-pangea/dnd';
import DragNDropIcon from '../../../../containers/DragNDrop/DragNDropIcon';
import SonifiIconButton from '../../../../containers/SonifiIconButton';
import SonifiLabel from '../../../../containers/SonifiLabel';
import SonifiTextInline from '../../../../containers/SonifiTextInline';
import { getFloorListStyle, getItemStyle } from '../../utils';

export default class Floors extends Component {
  state = {
    error: ''
  };

  editFloorName(buildingIndx, floorIndex) {
    this.props.editName(buildingIndx, floorIndex);
  }

  handleChange = (buildingIndx, floorIndex) => ({ target: { value } }) => {
    if (!value || value === '' || value.length < 1) {
      this.setState({ error: 'Cannot be blank' });
      return;
    }
    this.setState({ error: '' });
    this.props.change(buildingIndx, floorIndex, value);
  };

  deleteThisFloor(f, floorName) {
    this.props.deleteFloor(this.props.building.index, f, floorName);
  }

  render() {
    const { allowEdit, allowReorder, building, buildingIdx } = this.props;
    if (!building.floors) {
      return <div></div>;
    }

    return (
      <Droppable droppableId={`${building.index}`} type={`${building.index}`}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getFloorListStyle(snapshot.isDraggingOver)}
          >
            {building.floors.map((floor, index) => (
              <Draggable
                key={`${building.index}-${index}`}
                draggableId={`${building.index}-${index}`}
                index={index}
                isDragDisabled={!allowReorder || building.floors.length < 2}
              >
                {(draggableProvided, draggableSnapshot) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    // {...draggableProvided.dragHandleProps}
                    style={getItemStyle(
                      draggableSnapshot.isDragging,
                      draggableProvided.draggableProps.style,
                      buildingIdx
                    )} >
                    <Grid container wrap="nowrap" style={{ padding: '3px' }}>
                      <Grid item xs={1}
                        {...draggableProvided.dragHandleProps}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        {allowReorder && building.floors.length > 1 &&
                          <DragNDropIcon style={{
                            cursor: 'move',
                            filter: `${draggableSnapshot.isDragging
                              ? 'brightness(0) invert(1)'
                              : 'brightness(1) invert(0)'}`,
                            verticalAlign: 'middle',
                            width: '20px'
                          }} />}
                      </Grid>
                      <Grid item xs={9} onClick={this.editFloorName.bind(this, building.index, index)}>
                        {floor.edit && allowEdit
                          ? <SonifiTextInline
                            defaultValue={floor.name}
                            change={this.handleChange(building.index, index)}
                            error={(floor.hError && floor.hError.length > 0)}
                            errorText={floor.hError ? `${floor.hError}` : ''}
                          />
                          : <SonifiLabel label={floor.name} error={(floor.hError && floor.hError.length > 0)} />}
                      </Grid>
                      <Grid item xs={1}>
                        {allowEdit && <SonifiIconButton label=""
                          onClick={this.deleteThisFloor.bind(this, index, floor.name)}
                          useTertiary={draggableSnapshot.isDragging}
                          icon={<Delete />} disabled={!floor.canEdit || building.floors.length <= 1} />}
                      </Grid>
                    </Grid>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

Floors.propTypes = {
  allowEdit: PropTypes.bool,
  allowReorder: PropTypes.bool,
  building: PropTypes.object.isRequired,
  buildingIdx: PropTypes.number,
  change: PropTypes.func,
  deleteFloor: PropTypes.func,
  editName: PropTypes.func
};
