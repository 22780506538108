import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { ADD, ERROR } from '../../../constants/constants';
import SonifiCurrency from '../../../containers/SonifiCurrency';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiText from '../../../containers/SonifiText';
import SonifiTimePicker from '../../../containers/SonifiTimePicker';
import {
  resetSnackBar, saveOption, saveOptionBegin, selectOption
} from '../actions/lateCheckoutActions';
import { ADD_OPTION } from '../constants';
import { getPriceToSend } from '../utils';
import { validateTime } from '../utils/validator';

const maxPriceValue = 21474836;

const styles = (theme) => ({
  section: {
    backgroundColor: '#fff',
    border: 'solid',
    borderColor: theme.palette.primary.light,
    borderWidth: 'thin',
    marginBottom: theme.spacing(2),
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
    paddingTop: theme.spacing(2)
  }
});

export class OptionDialog extends Component {
  state = {
    changed: false,
    errors: {},
    id: null,
    price: null,
    quantity: null
  };

  componentDidMount() {
    const { options, selectedOption } = this.props;

    if (selectedOption.id === ADD) {
      const miscObj2 = new Date();
      selectedOption.time = `${miscObj2.toDateString()} 12:00:00`;
      selectedOption.sequence = options.length;
    }

    this.setState({
      ...selectedOption,
      changed: selectedOption.id === ADD,
      price: selectedOption.price / 100,
      time: selectedOption.time
    });
  }

  onCancel() {
    this.props.dispatch(selectOption(null));
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.onCancel();
    }
  }

  onSubmit() {
    const { dispatch, translations } = this.props;
    const {
      changed, id, quantity, price, sequence, time
    } = this.state;

    if (!changed && id !== ADD) {
      this.onCancel();
      return;
    }

    dispatch(saveOptionBegin());
    validateTime(this.state, translations.errors)
      .then((data) => {
        this.setState({ errors: data });
        if (Object.entries(data).length === 0) {
          const saveObject = {
            quantity: {
              default: quantity.default,
              today: quantity.today
            },
            price: getPriceToSend(price),
            sequence
          };
          dispatch(saveOption(time, saveObject));
        } else {
          dispatch(resetSnackBar(translations.errors.timeUsed, ERROR));
        }
      });
  }

  updateQuantity = (stateName) => ({ target: { value } }) => {
    const tempQuantity = { ...this.state.quantity };
    tempQuantity[stateName] = (value === '' || value === null || value < 0) ? 0 : value;
    this.setState({ changed: true, quantity: tempQuantity });
  };

  handleDateChange = (value) => {
    if (value) {
      this.setState({ changed: true, time: moment(value).format('YYYY-MM-DD HH:mm:ss') });
    }
  };

  getTitle() {
    const { editType, translations } = this.props;
    const { id } = this.state;
    if (editType === 'read') {
      return translations.readTitle;
    } else if (id === ADD_OPTION.id) {
      return translations.addTitle;
    }
    return translations.editTitle;
  }

  render() {
    const {
      classes, editType, globalTranslations, translations
    } = this.props;
    const {
      errors, id, price, quantity, time
    } = this.state;
    const readOnly = editType === 'read';

    if (!quantity) {
      return <Fragment />;
    }

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={this.onClose.bind(this)}
        open={true}>
        <SonifiModalHeader
          header={this.getTitle()}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.onSubmit.bind(this)}
          onlyClose={readOnly}
          label={globalTranslations.save}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={5}>
              <SonifiTimePicker
                change={this.handleDateChange.bind(this)}
                defaultValue={time}
                disabled={readOnly || id !== ADD}
                error={!!errors.time}
                errorText={errors.time}
                label={translations.time}
                size="md"
              />
              <SonifiCurrency
                currencySymbol="$"
                decimalCharacter="."
                digitGroupSeparator=","
                disabled={readOnly || id !== ADD}
                label={translations.price}
                minimumValue="0"
                onBlur={(event, value) => {
                  this.setState({ price: `${value}` });
                }}
                onChange={(event, value) => {
                  this.setState({ changed: true, price: (value >= maxPriceValue) ? maxPriceValue : value });
                }}
                style={{ marginRight: '16px', width: '320px', height: '80px', marginBottom: '0' }}
                value={price}
                variant="filled"
              />
            </Grid>
            <Grid item xs={7} className={classes.section}>
              <SonifiLabel boldLabel={translations.quantityAvailable} />
              <SonifiText
                change={this.updateQuantity('default')}
                defaultValue={quantity.default}
                disabled={readOnly}
                label={translations.default}
                size="md"
                type="number" />
              <SonifiText
                change={this.updateQuantity('today')}
                defaultValue={quantity.today}
                disabled={readOnly}
                label={translations.today}
                size="md"
                type="number" />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  editType: state.lateCheckout.editType,
  globalTranslations: state.global.translations.defaults,
  options: state.lateCheckout.options,
  selectedOption: state.lateCheckout.selectedOption,
  translations: state.lateCheckout.translations.optionDialog
});

OptionDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  globalTranslations: PropTypes.object,
  options: PropTypes.array,
  selectedOption: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(OptionDialog, styles, { withTheme: true }));
