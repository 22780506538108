import { CLIENTS, SITES } from '../constants/apiEndpoints';
import {
 buildUrl, fetchData, getSiteNumFromURI, patchData, putData
} from './index';

export function getTieObjectPromise(resource, filter) {
  return new Promise((resolve, reject) => {
    const siteId = getSiteNumFromURI();
    let url = buildUrl(`${process.env.REACT_APP_TIE_URL}/${SITES}/${siteId}`, filter);
    if (resource) {
      url = buildUrl(`${process.env.REACT_APP_TIE_URL}/${SITES}/${siteId}/${resource}`, filter);
    }

    fetchData(url)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function getTieClientObjectPromise(clientId, filter) {
  return new Promise((resolve, reject) => {
    let tempUrl = '';
    if (clientId) {
      tempUrl = buildUrl(`${process.env.REACT_APP_TIE_URL}/${CLIENTS}/${clientId}`, filter);
    } else {
      tempUrl = buildUrl(`${process.env.REACT_APP_TIE_URL}/${CLIENTS}`, filter);
    }
    const url = tempUrl;

    fetchData(url)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function patchTieObjectPromise(submitObj) {
  return new Promise((resolve, reject) => {
    const siteId = getSiteNumFromURI();

    const url = buildUrl(`${process.env.REACT_APP_TIE_URL}/${SITES}/${siteId}`);

    patchData(url, submitObj)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function putTieObjectPromise(submitObj, tvControlConfigs) {
  return new Promise((resolve, reject) => {
    const siteId = getSiteNumFromURI();

    const url = buildUrl(`${process.env.REACT_APP_TIE_URL}/${SITES}/${siteId}`);
    let sObj = {
      id: siteId,
      site_id: siteId
    };

    if (submitObj) {
      sObj.pms = { ...submitObj };
    }

    if (tvControlConfigs) {
      sObj = { ...sObj, ...tvControlConfigs };
    }


    putData(url, sObj)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function putSitelessTieObjectPromise(resource, info, submitObj) {
  return new Promise((resolve, reject) => {
    const url = buildUrl(`${process.env.REACT_APP_TIE_URL}/${resource}/${info}`);
    putData(url, submitObj)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function getTieResource(resource, info, filterObj) {
  return new Promise((resolve, reject) => {
    let url = buildUrl(`${process.env.REACT_APP_TIE_URL}/${resource}`, filterObj);

    if (info) {
      url = buildUrl(`${process.env.REACT_APP_TIE_URL}/${resource}/${info}`, filterObj);
    }

    fetchData(url)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}
