import { map, orderBy, uniqBy } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, READ, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';
import { convertGroupsToLocalTime } from '../utils';

const langs = { en };

const initialState = {
  deleteGroup: null,
  editType: READ,
  errorCodes: [],
  gettingMessages: false,
  gettingRooms: false,
  groups: [],
  loadingGroups: false,
  maxPages: 1,
  saving: false,
  selectedGroup: null,
  sendingMessage: false,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  translations: null
};

/* eslint-disable max-lines-per-function */
export default function groupServicesReducer(state = initialState, action) {
  let miscObj = null,
    deepCopy = null;
  switch (action.type) {
    case types.DELETE_CHECK_SELECTED_GROUP:
      return { ...state, deleteGroup: action.group };

    case types.DELETE_GROUP_SERVICE_BEGIN:
      return { ...state, deleteGroup: null, saving: true };

    case types.DELETE_GROUP_SERVICE_SUCCESS:
      return {
        ...state,
        saving: false,
        snackBarMessage: `${state.translations.grid.success.delete} ${action.groupId}`,
        snackBarType: SUCCESS
      };

    case types.DELETE_GROUP_SERVICE_FAILURE:
      return {
        ...state,
        saving: false,
        snackBarMessage: `${state.translations.grid.errors.unableToDelete} ${action.groupId} - (${action.error})`,
        snackBarType: ERROR
      };

    case types.FETCH_GROUP_SERVICES_BEGIN:
      return { ...state, loadingGroups: action.resetGroups, groups: action.resetGroups ? [] : state.groups };

    case types.FETCH_GROUP_SERVICES_SUCCESS:
      miscObj = state.groups.concat(action.groups);
      return {
        ...state,
        groups: convertGroupsToLocalTime(uniqBy(miscObj, (e) => e.id)),
        loadingGroups: false,
        maxPages: action.maxPages
      };

    case types.FETCH_GROUP_SERVICES_FAILURE:
      return { ...state, loadingGroups: false };

    case types.SET_GROUP_SERVICES_SNACKBAR:
      return {
        ...state,
        snackBarMessage: action.msg,
        snackBarType: action.sbType ? action.sbType : state.snackBarType
      };

    case types.RESET_GROUP_DIALOG:
      return { ...state, errorCodes: [], snackBarMessage: '' };

    case types.UPDATE_SELECTED_GROUP:
      deepCopy = JSON.parse(JSON.stringify(action.group));

      return {
        ...state,
        editType: action.editType,
        selectedGroup: deepCopy,
        snackBarMessage: ''
      };

    case types.EDIT_SELECTED_GROUP: {
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          [action.name]: action.value
        }
      };
    }

    case types.GET_GROUP_ROOMS_BEGIN: {
      return { ...state, gettingRooms: true };
    }

    case types.GET_GROUP_ROOMS_SUCCESS: {
      return {
        ...state,
        gettingRooms: false,
        selectedGroup: {
          ...state.selectedGroup,
          rooms: action?.rooms
            ? orderBy(action.rooms, ['room_number'], ['asc'])
            : []
        }
      };
    }

    case types.GET_GROUP_ROOMS_FAILURE: {
      return {
        ...state,
        gettingRooms: false,
        selectedGroup: { ...state.selectedGroup, rooms: [] }
      };
    }

    case types.ORDER_GROUP_ROOMS: {
      return {
        ...state,
        selectedGroup: {
          ...state.selectedGroup,
          rooms: state.selectedGroup?.rooms
            ? orderBy(state.selectedGroup.rooms, [action.column], [action.direction])
            : []
        }
      };
    }

    case types.SAVE_GROUP_BEGIN: {
      return { ...state, errorCodes: [], saving: true };
    }

    case types.SAVE_GROUP_SUCCESS: {
      if (action.group && action.group.isNew) {
        miscObj = action.group;
        miscObj.id = action.groupId;
        miscObj.messages = [];
        delete (miscObj.isNew);
      }

      return {
        ...state,
        saving: false,
        selectedGroup: miscObj,
        snackBarMessage: action.group ? `${state.translations.grid.success.save} ${action.group.name}` : '',
        snackBarType: SUCCESS
      };
    }

    case types.SAVE_GROUP_FAILURE: {
      miscObj = `${state.translations.grid.errors.save} - (${action.error})`;
      if (action.errorMsg) {
        miscObj += `\n ${action.errorMsg}`;
      }
      return {
        ...state,
        errorCodes: action.errorCodes && action.errorCodes.length > 0 ? action.errorCodes : [],
        saving: false,
        snackBarMessage: miscObj,
        snackBarType: ERROR
      };
    }

    case types.GET_GROUP_MESSAGES_BEGIN: {
      return {
        ...state,
        gettingMessages: true,
        selectedGroup: { ...state.selectedGroup, messages: [] }
      };
    }

    case types.GET_GROUP_MESSAGES_SUCCESS: {
      return {
        ...state,
        gettingMessages: false,
        selectedGroup: {
          ...state.selectedGroup,
          messages: action.messages && action.messages.length > 0 ? uniqBy(action.messages, (e) => e.id) : []
        }
      };
    }

    case types.RESET_SEND_GROUP_MESSAGE: {
      return {
        ...state,
        sendingMessage: false,
        snackBarMessage: action.errors ? map(action.errors, 'message').toString() : '',
        snackBarType: action.errors ? ERROR : state.snackBarType
      };
    }

    case types.SEND_GROUP_MESSAGE_BEGIN: {
      return {
        ...state,
        sendingMessage: true,
        snackBarMessage: ''
      };
    }

    case types.SEND_GROUP_MESSAGE_SUCCESS: {
      return {
        ...state,
        sendingMessage: false,
        snackBarMessage: `${state.translations.grid.success.sendMessage} - ${action.messageTitle}`,
        snackBarType: SUCCESS
      };
    }

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
