import { findArrayIdByKey } from '../../../utils/index';
import { textHelper } from '../../../utils/textUtil';

export function exampleCsv() {
  let csv = 'Here is where we can put some instructional text.\n';
  csv += 'Room column is required.\n';
  csv += 'Building is required. \n';
  csv += 'Floor is required. \n';
  csv += 'Classification is optional (default = consumer)\n,(values = consumer, bar_restaurant, fitness, lobby)\n';
  csv += 'PMS is optional (default = room number) \n';
  csv += 'Room, Building, Floor, Classification, PMS\n';

  return csv;
}
export function getTableHeaders(translations) {
  return [
    { id: '', sortable: false, numeric: false, label: '' },
    { id: 'id', sortable: false, numeric: false, label: translations.room },
    { id: 'building', sortable: false, numeric: false, label: translations.building },
    { id: 'floor', sortable: false, numeric: false, label: translations.floor },
    { id: 'classification', sortable: false, numeric: false, label: translations.classification },
    { id: 'pms_id', sortable: false, numeric: false, label: translations.pmsId }
  ];
}

export function getColumnNames() {
  return [
    { id: 'id', type: 'label' },
    { id: 'building', type: 'label' },
    { id: 'floor', type: 'label' },
    { id: 'classification', type: 'label' },
    { id: 'pms_id', type: 'label' },
  ];
}

export function parseRooms(site, classifications, translations, rInfo) {
  // console.log('RoomsFilter:parseRooms', classifications, rInfo);
  console.log('ParseRooms:site', site);
  const roomInfo = getRoomArray(rInfo);
  const rooms = [];
  const titles = [];
  const roomNumbers = [];
  let areThereErrors = false,
    newSite = [...site];


  if (roomInfo.length < 1) {
    return { rooms, roomError: true };
  }

  for (let i = 0; i < roomInfo[0].length; i++) {
    titles.push(roomInfo[0][i].trim().toUpperCase());
  }

  const roomNumber = getPlaceInLine(titles, 'Room', 'Room Number');
  const building = getPlaceInLine(titles, 'Building');
  const floor = getPlaceInLine(titles, 'Floor');
  const classification = getPlaceInLine(titles, 'Classification');
  const pmsId = getPlaceInLine(titles, 'Pms', 'pmsId');
  const addedToHeirarchy = [];

  for (let i = 1; i < roomInfo.length; i++) {
    const completelyEmpty = roomInfo[i].join('');
    if (completelyEmpty.length === 0) {
      continue;
    }

    const clfcn = getClassification(classification, roomInfo[i], classifications);
    const rmn = getInfo(roomNumber, roomInfo[i]);
    const bldg = getInfo(building, roomInfo[i]);
    const flr = getInfo(floor, roomInfo[i]);
    const pms = getInfo(pmsId, roomInfo[i]);

    const room = {
      id: rmn,
      building: bldg,
      floor: flr,
      classification: clfcn.id,
      pms_id: (pms === -1 ? rmn : pms),
      is_occupied: false,
      unit: null,
      is_test: false,
      can_bill_to_room: true,
      error: {},
      toolTip: ''
    };

    if (rmn === -1) {
      room.error.id = `${translations.errors.roomNumber}` + '\n';
      room.toolTip += `${translations.errors.roomNumber}` + '\n';
    } else if (textHelper(room.id)) {
      room.error.id = `${translations.errors.poundPercentage}` + '\n';
      room.toolTip += `${translations.errors.poundPercentage}` + '\n';
    } else if (rmn.id !== -1 && !roomNumbers.includes(room.id)) {
      roomNumbers.push(room.id);
    } else {
      room.error.id = `${translations.errors.duplicate}` + '\n';
      room.toolTip += `${translations.errors.duplicate}` + '\n';
    }

    let buildingNum = -1;
    if (bldg === -1 || room.building === null || room.building === '') {
      room.error.building = `${translations.errors.building} \n`;
      room.toolTip += `${translations.errors.building} \n`;
    } else if (textHelper(room.building)) {
      room.error.building = `${translations.errors.buildingPoundPercentage} \n`;
      room.toolTip += `${translations.errors.buildingPoundPercentage} \n`;
    } else {
      buildingNum = findArrayIdByKey(newSite, 'name', room.building);
      console.log(`buildingNum ${room.id}`, buildingNum);
    }

    let floorNum = -1;
    if (flr === -1 || room.floor === null || room.floor === '') {
      room.error.floor = `${translations.errors.floor} \n`;
      room.toolTip += `${translations.errors.floor} \n`;
    } else if (textHelper(room.floor)) {
      room.error.floor = `${translations.errors.floorPoundPercentage} \n`;
      room.toolTip += `${translations.errors.floorPoundPercentage} \n`;
    } else if (buildingNum > -1 && site[buildingNum] && site[buildingNum].floors.length > 0) {
      floorNum = findArrayIdByKey(newSite[buildingNum].floors, 'name', room.floor);
      console.log(`floorNum ${room.id}`, floorNum);
    }

    if (clfcn.error) {
      room.error.classification = `${clfcn.error} \n`;
      room.toolTip += `${clfcn.error} \n`;
    }

    if (rmn === -1 && pms === -1) {
      room.error.pms_id = `${translations.errors.pms} \n`;
      room.toolTip += `${translations.errors.pms} \n`;
    } else if (textHelper(room.pms_id)) {
      room.error.pms_id = `${translations.errors.pmsPoundPercentage} \n`;
      room.toolTip += `${translations.errors.pmsPoundPercentage} \n`;
    }

    if (!room.error.building && !room.error.floor &&
      (bldg !== -1 && flr !== -1 && (buildingNum === -1 || buildingNum === null ||
        floorNum === -1 || floorNum === null))) {
      newSite = checkoutHierarchy(newSite, buildingNum, floorNum, room, addedToHeirarchy);
    }

    if (Object.keys(room.error).length === 0) {
      delete (room.error);
      delete (room.toolTip);
    } else {
      areThereErrors = true;
    }
    rooms.push(room);
  }

  return {
    rooms,
    roomError: areThereErrors,
    tableHeader: getTableHeaders(translations),
    columnNames: getColumnNames(),
    siteHierarchy: newSite,
    addedToHeirarchy
  };
}

function getInfo(roomNumber, roomInfo) {
  if (roomNumber === -1) {
    return -1;
  }

  if (roomInfo[roomNumber].trim().length > 0) {
    return roomInfo[roomNumber].trim();
  }

  return -1;
}

function getClassification(classification, roomInfo, classifications) {
  if (classification === -1) {
    return { id: classifications[0] };
  }

  const clfctn = roomInfo[classification].trim();
  if (clfctn === '') {
    return { id: classifications[0] };
  } else if (classifications.includes(clfctn)) {
    return { id: clfctn };
  }

  return { id: clfctn, error: `Classification needs to be ${classifications}` };
}

function getRoomArray(roomInfo) {
  const channelInfo = [...roomInfo];
  let findStartOfChannelInfo = true;

  while (findStartOfChannelInfo && channelInfo.length > 0) {
    const chanRow = channelInfo[0].map((i) => i.trim().toLowerCase());
    if (chanRow.includes('room') && chanRow.includes('building') && chanRow.includes('floor')) {
      findStartOfChannelInfo = false;
    } else {
      channelInfo.shift();
    }
  }
  return channelInfo;
}

function getPlaceInLine(a, first, second) {
  let idx = a.indexOf(first.trim().toUpperCase());
  if (second && idx === -1) {
    idx = a.indexOf(second.trim().toUpperCase());
  }
  return idx;
}

function checkoutHierarchy(newSite, building, floor, room, addingToSite) {
  const site = [...newSite];
  if (building !== null) {
    const floorNum = findArrayIdByKey(site[building].floors, 'name', room.floor);
    if (floorNum === -1 || floorNum === null) {
      site[building].floors.push({
        name: `${room.floor}`,
        description: null
      });
      addingToSite.push({ building: `${room.building}`, floor: `${room.floor}`, newF: true });
    }
  } else {
    const buildingNum = findArrayIdByKey(site, 'name', room.floor);
    if (buildingNum === -1 || buildingNum === null) {
      site.push({
        name: `${room.building}`,
        description: null,
        floors: [{
          name: `${room.floor}`,
          description: null
        }]
      });
      addingToSite.push({ building: `${room.building}`, floor: `${room.floor}`, newB: true });
    }
  }
  return site;
}
