export function validTemplate(releaseTemplate, translations) {
  const errors = {};

  if (releaseTemplate.packages.length === 0) {
    errors.packages = translations.noData;
  }

  // if (logo.imageFiles.length === 0) {
  //   errors.image = translations.needImage;
  // }

  return errors;
}
