import { Add } from '@mui/icons-material';
import {
  Table, TableBody, TableCell, TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SONIFI_ROLES } from '../../../constants/roleGroups';
import {
  SonifiConfirm, SonifiLabel, SonifiLockoutModalSpinner, SonifiSnackBar, SonifiSpinner, SonifiTableHead
} from '../../../containers';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { fetchSiteConfigs } from '../../SiteManagement/actions/siteManagementActions';
import {
  clearSuccessFailure, deleteAllIntegrations, getIntegrations, updateDeleteRow, updateSelectedIntegration
} from '../actions/integrationActions';
import { PMSD } from '../constants/constants';
import IntegrationRow from '../containers/IntegrationRow';
import EditIntegration from './EditIntegration';

class IntegrationTable extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchSiteConfigs())
      .then((data) => {
        this.setState({
          enableSonifiClients: data.enable_sonifi_clients ?? false,
          enableThirdPartyClients: data.enable_third_party_clients ?? false
        });
      });
    dispatch(getIntegrations());
  }

  closeDialog() {
    this.props.dispatch(updateSelectedIntegration(null, ''));
  }

  handleAddIntegration() {
    this.props.dispatch(updateSelectedIntegration({ isNew: true, type: PMSD }, 'edit'));
  }

  confirmDialogConfirmFunc() {
    const { dispatch, deleteIntegration } = this.props;
    const tvControlConfigs = {
      enable_third_party_clients: this.state.enableSonifiClients,
      enable_sonifi_clients: this.state.enableSonifiClients
    };
    dispatch(deleteAllIntegrations(deleteIntegration, tvControlConfigs));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteRow(null));
  }

  closeSnackBar = () => {
    this.props.dispatch(clearSuccessFailure());
  };

  getPageDetails() {
    const {
      integrations, translations, userPermissions
    } = this.props;

    const tableHeader = [
      { id: 'type', sortable: true, numeric: false, label: `${translations.main.type}` }
    ];
    const canEdit = checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions);

    return (

      <Table>
        <SonifiTableHead headColumns={tableHeader} />
        <TableBody>
          {integrations.length === 0 &&
            <TableRow>
              <TableCell colSpan={2}>
                <SonifiLabel error label={translations.errors.gettingIntegrations} />
              </TableCell>
            </TableRow >
          }
          {integrations.map((option, index) => (
            <IntegrationRow key={index} integration={option} canEdit={canEdit} id={index} />
          ))}
        </TableBody>
      </Table>
    );
  }

  render() {
    const {
      deleteIntegration, deletingIntegration, globalTranslations, integrations, loading, selectedIntegration,
      snackBarMessage, snackBarType, translations, userPermissions
    } = this.props;

    if (loading) {
      return <SonifiSpinner />;
    }

    // const canEdit = checkForSingleUserPermission(TIE_ROLE, userPermissions);
    const canEdit = checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions);

    return (
      <Fragment>
        {selectedIntegration !== null &&
          <EditIntegration onCancel={this.closeDialog.bind(this)} canEdit={canEdit} />
        }

        <SonifiLockoutModalSpinner show={deletingIntegration} />
        <SonifiConfirm
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
          confirmText={`${translations.main.deleteText} ${(deleteIntegration ? deleteIntegration.type : '')}?`}
          confirmTitle={translations.main.deleteTitle}
          dialogOpen={deleteIntegration !== null && deleteIntegration !== undefined}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)} />

        <SonifiTemplate
          header={translations.main.title}
          icon={<Add />}
          label={translations.main.add}
          onSubmit={this.handleAddIntegration.bind(this)}
          pageDetails={this.getPageDetails()}
          showButton={canEdit && integrations.length === 0} />

        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteIntegration: state.integrations.deleteIntegration,
  deletingIntegration: state.integrations.deletingIntegration,
  globalTranslations: state.global.translations.defaults,
  integrations: state.integrations.integrations,
  loading: state.integrations.loading,
  selectedIntegration: state.integrations.selectedIntegration,
  snackBarMessage: state.integrations.snackBarMessage,
  snackBarType: state.integrations.snackBarType,
  translations: state.integrations.translations,
  userPermissions: state.global.permissions
});

IntegrationTable.propTypes = {
  deleteIntegration: PropTypes.object,
  deletingIntegration: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  integrations: PropTypes.array,
  loading: PropTypes.bool,
  selectedIntegration: PropTypes.object,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(IntegrationTable);
