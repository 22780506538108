import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { SOFTWARE_ROLES } from '../../../constants/roleGroups';
import { SOFTWARE_WRITE } from '../../../constants/roles';
import { SonifiConfirm, SonifiLockoutModalSpinner, SonifiSpinner } from '../../../containers';
import SonifiSnackBar from '../../../containers/SonifiSnackBar';
import SonifiTabs from '../../../containers/SonifiTabs';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { checkForAtLeastOneUserPermission, checkSingleUserPermission } from '../../../utils/rolesUtil';
import {
  editSelectedDeployment, getProductParents, updateDeleteDeployment, updateDeploymentBar, updateSelectedProdParent
} from '../actions/deploymentsActions';
import { currentStatus } from '../constants/constants';
import ParentTable from '../containers/ParentTable';
import DeploymentDialog from './DeploymentDialog';

export class DeploymentGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ''
    };

    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.confirmDialogCancelFunc = this.confirmDialogCancelFunc.bind(this);
    this.confirmDialogConfirmFunc = this.confirmDialogConfirmFunc.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getProductParents(true));
  }

  closeSnackBar() {
    this.props.dispatch(updateDeploymentBar(''));
  }

  handleChange(event, newValue) {
    if (newValue !== this.state.value) {
      this.setState({ value: newValue });
      this.props.dispatch(updateSelectedProdParent(newValue, { page: 1, sort: 'id:desc' }));
    }
  }

  getTabContainers() {
    if (this.props.channelsLoading) {
      return <SonifiSpinner />;
    }
    return <ParentTable />;
  }

  getPageDetails() {
    const {
      loading, selectedProductParent, productParents
    } = this.props;

    if (loading) {
      return <SonifiSpinner />;
    }

    return <SonifiTabs
      tabs={productParents.map((suggestion) => ({
        id: suggestion.id,
        title: suggestion.name,
      }))}
      handleChange={this.handleChange}
      tabValue={selectedProductParent}
      showingTab={this.getTabContainers()} />;
  }

  confirmDialogConfirmFunc() {
    const {
      dispatch, channels, deleteDeployment, selectedProductParent, statusIndex
    } = this.props;
    channels[deleteDeployment].channels.splice(statusIndex, 1);
    dispatch(editSelectedDeployment(
      selectedProductParent,
      channels[deleteDeployment].id,
      channels[deleteDeployment].channels,
      channels[deleteDeployment].virtos
    ));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteDeployment(-1, -1));
  }

  render() {
    const {
      channels, deleteDeployment, globalTranslations, saving, selected,
      snackBarType, snackBarMessage, statusIndex, translations, userPermissions
    } = this.props;

    if (!checkForAtLeastOneUserPermission(SOFTWARE_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    const canEdit = checkSingleUserPermission(SOFTWARE_WRITE, userPermissions);

    let deleteText = '';
    if (deleteDeployment !== -1) {
      deleteText = `\n ${translations.channel}: ${currentStatus(channels[deleteDeployment].channels[statusIndex])}`;
      deleteText += `\n ${translations.virtOS}: ${channels[deleteDeployment].virtos}`;
      deleteText += `\n ${translations.version}: ${channels[deleteDeployment].id}`;
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={saving} />
        <SonifiTemplate
          header={translations.header}
          label={globalTranslations.save}
          pageDetails={this.getPageDetails()}
          showButton={false}
        />
        <SonifiConfirm
          dialogOpen={deleteDeployment !== -1}
          onConfirm={this.confirmDialogConfirmFunc}
          onCancel={this.confirmDialogCancelFunc}
          confirmTitle={translations.deleteDialog.deleteTitle}
          confirmText={`${translations.deleteDialog.deleteText} ${deleteText}?`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar} />
        {selected !== -1 && <DeploymentDialog canEdit={canEdit} />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  channels: state.deployments.channels,
  channelsLoading: state.deployments.channelsLoading,
  deleteDeployment: state.deployments.deleteDeployment,
  deployments: state.deployments.deployments,
  globalTranslations: state.global.translations.defaults,
  loading: state.deployments.loading,
  productParents: state.deployments.productParents,
  saving: state.deployments.saving,
  selected: state.deployments.selected,
  selectedProductParent: state.deployments.selectedProductParent,
  snackBarMessage: state.deployments.snackBarMessage,
  snackBarType: state.deployments.snackBarType,
  statusIndex: state.deployments.statusIndex,
  translations: state.deployments.translations.main,
  userPermissions: state.global.permissions
});

DeploymentGrid.propTypes = {
  channels: PropTypes.array,
  channelsLoading: PropTypes.bool,
  deleteDeployment: PropTypes.number,
  deployments: PropTypes.array,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  productParents: PropTypes.array,
  saving: PropTypes.bool,
  selected: PropTypes.number,
  selectedProductParent: PropTypes.string,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  statusIndex: PropTypes.number,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(DeploymentGrid);
