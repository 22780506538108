import {
  ANALOG, AUX, CORE, DIGITAL, DVB_C, DVB_S, PROPERTY, SERVER_STREAM
} from '../constants/constants';

export function formatChannelSetName(name) {
  return name === 'Default' ? 'Active' : name;
}

export function createSaveObj(saveObject) {
  saveObject.name = saveObject.name.trim();
  if (saveObject.type === PROPERTY || saveObject.type === AUX) {
    saveObject.ipg = { ...saveObject.property };
    saveObject.ipg.entry = saveObject.ipg.entry.trim();
    saveObject.ipg.description = (
      saveObject.ipg.description && saveObject.ipg.description.trim().length > 0
        ? saveObject.ipg.description.trim()
        : ' ');
  }

  if (saveObject.type === SERVER_STREAM) {
    saveObject.ipg = { ...saveObject[SERVER_STREAM] };
    delete (saveObject.ipg.id);
    delete (saveObject.ipg.name);
  }

  if (saveObject.type === CORE) {
    saveObject.ipg = {
      affiliate: '',
      call_letters: '',
      description: saveObject[CORE].description,
      duration: saveObject[CORE].duration,
      entry: saveObject[CORE].entry,
      source_id: saveObject[CORE].source_id
    };
    saveObject.select_access = saveObject[CORE].select_access;
  } else {
    delete (saveObject.ipg.select_access);
  }

  if (!saveObject.analogEnabled || saveObject.type === AUX) {
    delete (saveObject.analog);
    delete (saveObject.intl_analog);
    if (saveObject.channelType === DIGITAL) {
      delete (saveObject.intl_dvb_c);
      delete (saveObject.intl_dvb_s);
      delete (saveObject.intl_dvb_t);
    } else if (saveObject.channelType === DVB_C) {
      delete (saveObject.digital);
      delete (saveObject.intl_dvb_s);
      delete (saveObject.intl_dvb_t);
    } else if (saveObject.channelType === DVB_S) {
      delete (saveObject.digital);
      delete (saveObject.intl_dvb_c);
      delete (saveObject.intl_dvb_t);
    } else {
      delete (saveObject.digital);
      delete (saveObject.intl_dvb_s);
      delete (saveObject.intl_dvb_c);
    }
  }
  if (!saveObject.digitalEnabled) {
    delete (saveObject.digital);
    delete (saveObject.intl_dvb_c);
    delete (saveObject.intl_dvb_s);
    delete (saveObject.intl_dvb_t);
    if (saveObject.channelType === ANALOG) {
      delete (saveObject.intl_analog);
    } else {
      delete (saveObject.analog);
    }
  }
  if (!saveObject.ipEnabled || saveObject.type === AUX) {
    delete (saveObject.ip);
  }

  delete (saveObject.channelType);
  delete (saveObject.aux);
  delete (saveObject.property);
  delete (saveObject.analogEnabled);
  delete (saveObject.digitalEnabled);
  delete (saveObject.ipEnabled);
  delete (saveObject.errors);
  delete (saveObject.isNew);
  delete (saveObject[SERVER_STREAM]);
  delete (saveObject[CORE]);
  delete (saveObject.loading);
  delete (saveObject.unusedSS);

  return saveObject;
}

export function getTitle(type, translations) {
  if (type === 'delete') {
    return translations.deleteDraftTitle;
  } else if (type === 'tab') {
    return translations.unsaved;
  } else if (type === 'overwrite') {
    return 'OVERWRITE HERE';
  } else if (type === 'publish') {
    return translations.publish;
  }
  return '';
}

export function getMessage(type, translations, channelSetName) {
  if (type === 'delete') {
    return translations.deleteDraft;
  } else if (type === 'tab') {
    return translations.unsavedMessages.replace('#chanSet', channelSetName);
  } else if (type === 'overwrite') {
    return 'OVERWRITE HERE';
  } else if (type === 'publish') {
    return translations.publishDraft;
  }
  return '';
}
