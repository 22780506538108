import { Dialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { EDIT } from '../../../constants/constants';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import IcpForm from './IcpForm';

class IcpDialog extends Component {
  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  render() {
    const {
      editType, globalTranslations, onSubmit, selectedIcp, translations
    } = this.props;

    return (
      <Dialog
        open={selectedIcp > -1}
        maxWidth="md"
        onClose={this.onCancel.bind(this)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <SonifiModalHeader
          header={translations.form.title}
          label={globalTranslations.save}
          onCancel={this.onCancel.bind(this)}
          onlyClose={editType !== EDIT}
          onSubmit={onSubmit}
        />
        <DialogContent>
          <IcpForm />
        </DialogContent>
      </Dialog>
    );
  }
}
const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  selectedIcp: state.icps.selectedIcp,
  editType: state.icps.editType,
  translations: state.icps.translations
});

IcpDialog.propTypes = {
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  editType: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  selectedIcp: PropTypes.number,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(IcpDialog);
