import { MenuItem, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const _DEFAULT_ROWS = 10;

const styles = (theme) => ({
  error: {
    color: 'red',
    margin: 0,
    fontSize: '10pt',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1)
  },
  errorInline: {
    display: 'inline-block',
    float: 'middle',
    color: 'red',
    margin: 0,
    fontSize: '10pt',
    padding: theme.spacing(1),
    marginLeft: theme.spacing(8)
  },
  textFieldDefault: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    width: '200px',
  },
  textFieldDefaultInline: {
    display: 'inline-block',
    width: '200px'
  },
  textFieldDefaultBlock: {
    display: 'block',
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    width: '100%',
  },
  textFieldXtraSmall: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '60px',
  },
  textFieldPercentWidth: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '95%'
  },
  textFieldSmall: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '200px',
  },
  textFieldMedium: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '320px',
  },
  textFieldLarge: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '400px',
  },
  dateField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '250px',
  },
  description: {
    width: '99%'
  },
  right: {
    // float: 'right'
    position: 'absolute',
    bottom: '0',
    right: '0'
  },
  marginRightOnly: {
    marginTop: 0,
    marginBottom: 0,
    marginRight: theme.spacing(1),
    width: '200px',
  },
  textBox: {
    marginTop: 0,
    width: '649px'
  },
  errorOutline: {
    borderWidth: '1px',
    borderColor: 'red !important',
  },
  noPadding: {
    marginTop: '2px',
    marginBottom: '2px'
  }
});

export class SonifiTextInline extends Component {
  selectTextOnFocus = (event) => {
    event.target.select();
  };

  handleBlur() {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  }

  render() {
    const {
      change, classes, defaultValue, disabled, error, errorText, float, handleKeyDown, highlightText, items, label,
      noPadding, multiline, name, placeholder, onKeyUp, rows, select, showErrorOutline, size, type,
      errorInline, selectProps
    } = this.props;

    let className;
    let componentSize = 'small';

    if ((size) && (size === 'sm')) {
      className = classes.textFieldSmall;
    } else if ((size) && (size === 'md')) {
      className = classes.textFieldMedium;
      componentSize = 'medium';
    } else if ((size) && (size === 'lg')) {
      className = classes.textFieldLarge;
      componentSize = 'large';
    } else if ((size) && (size === 'xs')) {
      className = classes.textFieldXtraSmall;
    } else if ((size) && (size === 'percent')) {
      className = classes.textFieldPercentWidth;
    } else if ((size) && (size === 'marginRight')) {
      className = classes.marginRightOnly;
    } else if (size && size === 'textBox') {
      className = classes.textBox;
    } else {
      className = errorInline ? classes.textFieldDefaultBlock : classes.textFieldDefault;
    }

    if ((type) && (type === 'datetime-local')) {
      className = classes.dateField;
    } else if ((type) && (type === 'description')) {
      className = classes.description;
    }
    const value = ((typeof defaultValue === 'undefined') || (defaultValue === null))
      ? ''
      : defaultValue;

    const showError = ((typeof error === 'undefined') || (error === null)) ? false : error;

    if (float) {
      className += ` ${classes[float]}`;
    }

    return (
      <TextField
        disabled={disabled}
        label={label}
        select={select}
        value={value}
        autoFocus={highlightText}
        onFocus={highlightText && this.selectTextOnFocus}
        onBlur={this.handleBlur.bind(this)}
        onKeyDown={handleKeyDown}
        onKeyUp={onKeyUp}
        className={`${className} ${noPadding ? classes.noPadding : ''}`}
        onChange={change}
        multiline={multiline}
        type={type}
        variant="outlined"
        margin="normal"
        name={name}
        placeholder={placeholder}
        size={componentSize}
        error={showError}
        helperText={errorText}
        FormHelperTextProps={{
          classes: {
            root: errorInline ? classes.errorInline : classes.error
          }
        }}
        InputProps={{
          classes: {
            notchedOutline: (showError && showErrorOutline && classes.errorOutline),
            error: (showError && errorInline && classes.textFieldDefaultInline)
          }
        }}
        SelectProps={selectProps}
        rows={rows || _DEFAULT_ROWS}>
        {items && items.map((option, index) => (
          <MenuItem
            key={index}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }
}

SonifiTextInline.propTypes = {
  change: PropTypes.func,
  classes: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  errorInline: PropTypes.bool,
  errorText: PropTypes.string,
  float: PropTypes.string,
  handleKeyDown: PropTypes.func,
  highlightText: PropTypes.bool,
  items: PropTypes.array,
  label: PropTypes.string,
  noPadding: PropTypes.bool,
  multiline: PropTypes.bool,
  onBlur: PropTypes.func,
  rows: PropTypes.number,
  select: PropTypes.bool,
  showErrorOutline: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  selectProps: PropTypes.object,
  placeholder: PropTypes.string,
  onKeyUp: PropTypes.func,
  name: PropTypes.string
};

export default React.memo(withStyles(SonifiTextInline, styles, { withTheme: true }));
