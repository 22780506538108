export const ADD_NEW_MODEL = 'ADD_NEW_MODEL';
export const ADD_TLX_FILE_BEGIN = 'ADD_TLX_FILE_BEGIN';
export const ADD_TLX_FILE_FAILURE = 'ADD_TLX_FILE_FAILURE';
export const ADD_TLX_FILE_SUCCESS = 'ADD_TLX_FILE_SUCCESS';
export const CLEAR_LG_FIRMWARE = 'CLEAR_LG_FIRMWARE';
export const CLEAR_SAMSUNG_FIRMWARE = 'CLEAR_SAMSUNG_FIRMWARE';
export const CLEAR_SONIFI_FIRMWARE = 'CLEAR_SONIFI_FIRMWARE';
export const CLEAR_TLX_FILE = 'CLEAR_TLX_FILE';
export const DELETE_TLX = 'DELETE_TLX';
export const DELETE_TLX_FILE_BEGIN = 'DELETE_TLX_FILE_BEGIN';
export const DELETE_TLX_FILE_FAILURE = 'DELETE_TLX_FILE_FAILURE';
export const DELETE_TLX_FILE_SUCCESS = 'DELETE_TLX_FILE_SUCCESS';
export const EDIT_TLX = 'EDIT_TLX';
export const EDIT_TLX_FILE_BEGIN = 'EDIT_TLX_FILE_BEGIN';
export const EDIT_TLX_FILE_FAILURE = 'EDIT_TLX_FILE_FAILURE';
export const EDIT_TLX_FILE_SUCCESS = 'EDIT_TLX_FILE_SUCCESS';
export const EXPORT_TLX_FILE_BEGIN = 'EXPORT_TLX_FILE_BEGIN';
export const EXPORT_TLX_FILE_FAILURE = 'EXPORT_TLX_FILE_FAILURE';
export const EXPORT_TLX_FILE_SUCCESS = 'EXPORT_TLX_FILE_SUCCESS';
export const FETCH_TERM_FIRMWARE_BEGIN = 'FETCH_TERM_FIRMWARE_BEGIN';
export const FETCH_TERM_FIRMWARE_FAILURE = 'FETCH_TERM_FIRMWARE_FAILURE';
export const FETCH_TERM_FIRMWARE_SUCCESS = 'FETCH_TERM_FIRMWARE_SUCCESS';
export const FETCH_TERMINAL_OPTIONS_BEGIN = 'FETCH_TERMINAL_OPTIONS_BEGIN_TERM_FILES';
export const FETCH_TERMINAL_OPTIONS_FAILURE = 'FETCH_TERMINAL_OPTIONS_FAILURE_TERM_FILES';
export const FETCH_TERMINAL_OPTIONS_SUCCESS = 'FETCH_TERMINAL_OPTIONS_SUCCESS_TERM_FILES';
export const FETCH_TLX_FILE_BEGIN = 'FETCH_TLX_FILES_BEGIN';
export const FETCH_TLX_FILE_FAILURE = 'FETCH_TLX_FILES_FAILURE';
export const FETCH_TLX_FILE_SUCCESS = 'FETCH_TLX_FILES_SUCCESS';
export const GET_SITE_MODELS_BEGIN = 'GET_SITE_MODELS_BEGIN';
export const GET_SITE_MODELS_FAILURE = 'GET_SITE_MODELS_FAILURE';
export const GET_SITE_MODELS_SUCCESS = 'GET_SITE_MODELS_SUCCESS';
export const IMPORT_TLX_FILE_BEGIN = 'IMPORT_TLX_FILE_BEGIN';
export const IMPORT_TLX_FILE_FAILURE = 'IMPORT_TLX_FILE_FAILURE';
export const IMPORT_TLX_FILE_SUCCESS = 'IMPORT_TLX_FILE_SUCCESS';
export const MODEL_VERSION_BUMP_FAILURE = 'MODEL_VERSION_BUMP_FAILURE';
export const MODEL_VERSION_BUMP_SUCCESS = 'MODEL_VERSION_BUMP_SUCCESS';
export const RESET_SNACK_BAR = 'RESET_SNACK_BAR';
export const RESET_TERM_FILES = 'RESET_TERM_FILES';
export const SAVE_NEW_MODEL_BEGIN = 'SAVE_NEW_MODEL_BEGIN';
export const SAVE_NEW_MODEL_FAILURE = 'SAVE_NEW_MODEL_FAILURE';
export const SAVE_NEW_MODEL_SUCCESS = 'SAVE_NEW_MODEL_SUCCESS';
export const SAVE_SITE_FIRMWARE_BEGIN = 'SAVE_SITE_FIRMWARE_BEGIN';
export const SAVE_SITE_FIRMWARE_FAILURE = 'SAVE_SITE_FIRMWARE_FAILURE';
export const SAVE_SITE_FIRMWARE_SUCCESS = 'SAVE_SITE_FIRMWARE_SUCCESS';
export const SET_SELECTED_LG_FIRMWARE = 'SET_SELECTED_LG_FIRMWARE';
export const SET_SELECTED_TLX_FILE = 'SET_SELECTED_TLX_FILE';
export const SORT_LG_FIRMWARE_BEGIN = 'SORT_LG_FIRMWARE_BEGIN';
export const SORT_TERMINAL_MODELS = 'SORT_TERMINAL_MODELS';
export const UPDATE_CLIENT_FILE = 'UPDATE_CLIENT_FILE';
export const UPDATE_MAINTENANCE_WINDOW = 'UPDATE_MAINTENANCE_WINDOW';
export const UPDATE_SAMSUNG_FILE = 'UPDATE_SAMSUNG_FILE';
export const UPDATE_SELECTED_SAMSUNG_FIRMWARE = 'UPDATE_SELECTED_SAMSUNG_FIRMWARE';
export const UPDATE_SELECTED_SONIFI_FIRMWARE = 'UPDATE_SELECTED_SONIFI_FIRMWARE';
