import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FIRMWARE_EDIT_ROLES, FIRMWARE_ROLES, SONIFI_ROLES } from '../../constants/roleGroups';
import { SONIFI_ADMIN } from '../../constants/roles';
import Navigation from '../../containers/Navigation';
import { isTesting } from '../../utils/api';
import { createLink } from '../../utils/links';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';
import BatchChannels from '../BatchChannels';
import ChannelLogos from '../ChannelLogos';
import Firmware from '../Firmware';
import GlobalTermFiles from '../GlobalTermFiles';
import TermModels from '../TermModels';
import TieApiClients from '../TieApiClients';

class Configure extends Component {
  configure() {
    const { isProd, translations, userPermissions } = this.props;
    const title = translations.title;
    const items = [];

    if (checkForSingleUserPermission(SONIFI_ADMIN, userPermissions)) {
      items.push(createLink(translations.logo, 'logo', ChannelLogos));
    }

    if (checkForAtLeastOneUserPermission(FIRMWARE_ROLES, userPermissions)) {
      items.push(createLink(translations.termModels, 'termModels', TermModels));
      items.push(createLink(translations.firmware, 'firmware', Firmware));
    }

    if (checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)) {
      items.push(createLink(translations.tieClients, 'tieApiClients', TieApiClients));
    }

    if (checkForAtLeastOneUserPermission(FIRMWARE_EDIT_ROLES, userPermissions)) {
      items.push(createLink(translations.tvTermFiles, 'globalTermFiles', GlobalTermFiles));
    }

    if (!isTesting(isProd) && checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)) {
      items.push(createLink(translations.batch, 'batchChannels', BatchChannels));
    }

    return { title, items };
  }

  render() {
    return (
      <Navigation links={this.configure().items} stackedNav={[this.configure()]} />
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  isProd: state.global.isProd,
  translations: state.global.translations.configure,
  userPermissions: state.global.permissions,
});

Configure.propTypes = {
  isProd: PropTypes.bool.isRequired,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(Configure);
