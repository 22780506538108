import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiError from '../../../containers/SonifiError';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import { getScheduleData } from '../actions/systemManagementActions';
import NameValueData from './NameValueData';
import PropertyIdData from './PropertyIdData';
import ScheduleDetails from './ScheduleDetails';
import Taxes from './Taxes';

class System extends Component {
  state = {
    schedule: null,
    scheduleId: null,
    error: null
  };

  componentDidMount() {
    this.props.dispatch(getScheduleData(this.props.scheduleId));
  }

  componentDidUpdate() {
    if (this.props.scheduleId !== this.state.scheduleId) {
      this.props.dispatch(getScheduleData(this.props.scheduleId));
      this.setState({
        scheduleId: this.props.scheduleId,
        schedule: null
      });
    }
    if (this.props.error !== this.state.error) {
      this.setState({
        error: this.props.error
      });
    }
    if (this.props.schedule !== this.state.schedule) {
      this.setState({
        scheduleId: this.props.scheduleId,
        schedule: this.props.schedule
      });
    }
  }

  render() {
    const { error, schedule, scheduleId } = this.state;
    const { translations } = this.props;

    if (!error && !schedule) {
      return <SonifiSpinner />;
    }

    if ((error) || (!schedule.schedule)) {
      return <SonifiError boldLabel={translations.errors.noSystemData} />;
    }

    const scheduleData = schedule.schedule;
    if (!scheduleData || Object.keys(scheduleData).length === 0) {
      return <SonifiLabel error label={translations.errors.noSystemData} />;
    }

    return (
      <Fragment>
        <ScheduleDetails
          sectionLabel={translations.headers.cmsScheduleDetails}
          scheduleId={scheduleId}
          data={scheduleData}
        />
        <NameValueData
          sectionLabel={translations.headers.configurations}
          data={scheduleData.configs}
        />
        <NameValueData
          sectionLabel={translations.headers.contractData}
          data={scheduleData.contracts}
        />
        <PropertyIdData
          sectionLabel={translations.headers.propertyIDs}
          data={scheduleData.alias}
        />
        <Taxes data={scheduleData.taxes} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.systemManagement.error,
  schedule: state.systemManagement.schedule,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
  translations: state.systemManagement.translations
});

System.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  schedule: PropTypes.object,
  scheduleId: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(System);
