import { Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { FIAS_IP, FIAS_SERIAL, subTotalTypes } from '../../constants/constants';
import TieMap from './TieMap';

function isInterfaceFias(interfaceType) {
  return [FIAS_IP, FIAS_SERIAL].indexOf(interfaceType) > -1;
}

/* eslint-disable camelcase */
export default class SubTotal extends TieMap {
  render() {
    const { editState: { interface: { interface_type } } } = this.props;
    const isFias = isInterfaceFias(interface_type);

    return (
      <Fragment>
        <Grid item xs={12}>
          {/* {this.getDropDown('S0', subTotalTypes)} */}
          {this.getDropDown('S1', subTotalTypes)}
          {isFias && this.getDropDown('S2', subTotalTypes)}
          {isFias && this.getDropDown('S3', subTotalTypes)}
          {isFias && this.getDropDown('S4', subTotalTypes)}
          {isFias && this.getDropDown('S5', subTotalTypes)}
          {isFias && this.getDropDown('S6', subTotalTypes)}
          {isFias && this.getDropDown('S7', subTotalTypes)}
          {isFias && this.getDropDown('S8', subTotalTypes)}
          {isFias && this.getDropDown('S9', subTotalTypes)}
        </Grid>
      </Fragment>
    );
  }
}
