import { uniqBy } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ADD, EDIT, ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';
import {
  deepCopyJson, formatAllDisplayName, formatUpdateValue, handleChannelMove, resortChannels
} from '../utils/channelMoveAndSort';

const langs = { en };
const initialState = {
  availableChannelSets: [],
  channelSet: {},
  checkType: null,
  coreChannels: [],
  creatingDraft: false,
  loading: false,
  firstLoad: false,
  translations: null,
  gettingChannelSet: false,
  snackBarMessage: '',
  realChannels: [],
  draggingRow: -1,
  saving: false,
  selectedChannel: null,
  snackBarType: SUCCESS,
  undoStack: []
};

/* eslint-disable max-lines-per-function */
export default function reducer(state = initialState, action) {
  let moveResults,
    saveMsg = '',
    channelAtIndex,
    currentChannels = [...state.realChannels];
  const undoStack = [...state.undoStack];

  switch (action.type) {
    case types.SET_AVAILABLE_CHANNEL_SETS:
      return { ...state, availableChannelSets: action.channelSets };

    case types.PUBLISH_DRAFT_BEGIN:
      return { ...state, loading: true };

    case types.PUBLISH_DRAFT_SUCCESS:
      return {
        ...state,
        loading: false,
        snackBarMessage: `${state.translations.messages.publishSuccess}`,
        snackBarType: SUCCESS
      };

    case types.PUBLISH_DRAFT_FAILURE:
      return {
        ...state,
        loading: false,
        snackBarMessage: `${state.translations.messages.publishFailure}`,
        snackBarType: ERROR
      };

    case types.SET_LOADING:
      return { ...state, loading: action.loading };

    case types.CHANNEL_SET_CHECK:
      return { ...state, checkType: action.checkType };

    case types.DELETE_DRAFT_BEGIN:
      return { ...state, saving: true, snackBarMessage: '', checkType: null };

    case types.DELETE_DRAFT_SUCCESS:
      return {
        ...state,
        saving: false,
        snackBarMessage: `${state.translations.messages.delete} Draft`,
        snackBarType: SUCCESS
      };

    case types.DELETE_DRAFT_FAILURE:
      return {
        ...state,
        saving: false,
        snackBarMessage: `${state.translations.messages.deleteError} Draft`,
        snackBarType: ERROR
      };

    case types.SET_CHANNEL_SNACKBAR:
      return {
        ...state,
        snackBarMessage: action.snackBarMessage,
        snackBarType: (action.snackBarType ? action.snackBarType : state.snackBarType),
        loading: false,
        saving: false,
        firstLoad: false
      };

    case types.SET_FIRST_LOAD:
      return {
        ...state,
        firstLoad: action.loading,
        checkType: null
      };

    case types.MOVE_CHANNEL:
      undoStack.push(deepCopyJson(currentChannels));
      moveResults = handleChannelMove(currentChannels, action.oldIndex, action.newIndex);
      return {
        ...state,
        undoStack,

        // redoStack: [],
        undoFirst: true,
        realChannels: moveResults.finalList,
        droppedRow: moveResults.finalIndex,
        selectedChannel: null,
      };

    case types.SET_CREATING_DRAFT:
      return {
        ...state,
        creatingDraft: action.value,
        loading: false
      };

    case types.SET_CHANNEL_SET:
      return {
        ...state,
        channelSet: action.channelSet,
        undoStack: [],
        gettingChannelSet: true,
        checkType: null

        // redoStack: []
      };

    case types.SET_DRAGGING_ROW:
      return {
        ...state,
        draggingRow: action.rowIndex
      };

    case types.SET_DROPPED_ROW:
      return {
        ...state,
        droppedRow: action.rowIndex
      };

    case types.FETCH_CHANNELS_SUCCESS:
      return {
        ...state,
        realChannels: uniqBy(action.channels, (e) => e.id),
        firstLoad: false,
        loading: false,
        gettingChannelSet: false,
        saving: false
      };

    case types.FETCH_CHANNELS_FAILURE:
      return {
        ...state,
        realChannels: [],
        firstLoad: false,
        loading: false,
        gettingChannelSet: false
      };

    case types.SAVE_CHANNEL_LIST_BEGIN:
      return {
        ...state,
        snackBarMessage: '',
        firstLoad: false,
        loading: false,
        saving: true,
        gettingChannelSet: false,
        selectedChannel: null
      };

    case types.SAVE_CHANNEL_LIST_SUCCESS:
      if (action.saveInfo.type === ADD) {
        saveMsg = `${state.translations.messages.add}`;
      } else if (action.saveInfo.type === 'delete') {
        saveMsg = `${state.translations.messages.delete}`;
      } else if (action.saveInfo.type === EDIT) {
        saveMsg = `${state.translations.messages.edit}`;
      } else if (action.saveInfo.type === 'save') {
        saveMsg = `${state.translations.messages.save}`;
      }
      return {
        ...state,
        firstLoad: false,
        gettingChannelSet: false,
        loading: false,
        saving: false,
        selectedChannel: null,
        snackBarMessage: `${saveMsg} ${action.saveInfo.chan}`,
        snackBarType: SUCCESS
      };

    case types.SAVE_CHANNEL_LIST_FAILURE:
      return {
        ...state,
        firstLoad: false,
        gettingChannelSet: false,
        loading: false,
        saving: false,
        snackBarMessage: `${action.error}`,
        snackBarType: ERROR
      };

    case types.UPDATED_SELECTED_CHANNEL:
      return {
        ...state,
        selectedChannel: action.channel,
        snackBarMessage: ''
      };

    case types.RESORT_CHANNELS:
      return {
        ...state,
        changed: !state.changed,
        editChannelNumber: -1,
        realChannels: resortChannels(currentChannels, action.changedIndex)
      };

    case types.EDIT_CHANNEL_NUMBER:
      return {
        ...state, editChannelNumber: action.index
      };

    case types.UPDATE_CHANNEL_DETAILS:
      channelAtIndex = currentChannels[action.rowIndex];

      // Save state for undo redo
      if (action.valueName !== state.lastChangedField) {
        undoStack.push(deepCopyJson(currentChannels));
      }

      // Convert display_channel to an number
      action.value = formatUpdateValue(action.objName, action.valueName, action.value);

      if (!action.objName) {
        channelAtIndex[action.valueName] = action.value;
      } else {
        channelAtIndex[action.objName] = {
          ...channelAtIndex[action.objName],
          [action.valueName]: action.value
        };
      }

      // Set the new channel data
      currentChannels[action.rowIndex] = channelAtIndex;

      // Update all the display names
      currentChannels = formatAllDisplayName(currentChannels);

      return {
        ...state,
        changed: !state.changed,
        realChannels: currentChannels,

        // redoStack: [],
        undoFirst: true,
        undoStack
      };

    case types.FETCH_CORE_CHANNELS_BEGIN: {
      return { ...state, coreChannels: [] };
    }

    case types.FETCH_CORE_CHANNELS_SUCCESS: {
      return { ...state, coreChannels: action.channels };
    }

    case LOAD_LANGUAGE_LITERALS: {
      return {
        ...state,
        translations: getTranslationObject(action.lang, langs)
      };
    }

    default:
      return state;
  }
}
