import {
  Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { debounce, filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { SonifiCheckbox, SonifiSnackBar, SonifiSpinner, SonifiTableHead } from '../../../containers';
import SonifiLabel from '../../../containers/SonifiLabel';
import { setSnackBar } from '../../Channels2/actions/channelsActions';
import * as actions from '../actions/groupServicesActions';

class GroupChannels extends Component {
  state = {
    add: [],
    remove: [],
    powerOn: null
  };

  componentDidMount() {
    const { selectedGroup } = this.props;

    this.setState({
      add: selectedGroup.channels.add ? [...selectedGroup.channels.add] : [],
      remove: selectedGroup.channels.remove ? [...selectedGroup.channels.remove] : []
    });
  }

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  /* Put a default value for power_on here & in constants until functionality is added
    this was done to bypass the api validation.
    TODO: Update powerOn state in handler then put state value here
  */
  filterData() {
    this.props.dispatch(actions.editSelectedGroup('channels',
      { add: this.state.add, remove: this.state.remove, power_on: this.state.powerOn }));
  }

  editAddValue = (name) => () => {
    const { add } = this.state;
    let addArray = [...add];
    addArray.includes(name)
      ? addArray = filter(addArray, (o) => o !== name)
      : addArray.push(name);
    this.setState({
      add: addArray
    }, () => {
      this.debouncedLoadMoreData();
    });
  };

  editRemoveValue = (name) => () => {
    const { remove } = this.state;
    let removeArray = [...remove];
    removeArray.includes(name)
      ? removeArray = filter(removeArray, (o) => o !== name)
      : removeArray.push(name);
    this.setState({
      remove: removeArray
    }, () => {
      this.debouncedLoadMoreData();
    });
  };

  isChannelAdded(channelNumber) {
    return this.state.add.includes(channelNumber);
  }

  isChannelRemoved(channelNumber) {
    return this.state.remove.includes(channelNumber);
  }

  // handleRadioChange = (name, value) => () => {
  //   console.log('GroupChannels:handlePowerOnUpdate', name, value);
  // }

  closeSnackBar() {
    this.props.dispatch(setSnackBar());
  }

  // TODO: Power on maybe an AC, verification need. Commenting out for now
  render() {
    const {
      canEdit, errors, firstLoad, realChannels, snackBarMessage, snackBarType
    } = this.props;

    const tableHeader = [
      { id: 'id', sortable: false, numeric: false, label: 'Ch' },
      { id: 'name', sortable: false, numeric: false, label: 'TV Display' },

      //   { id: 'powerOn', sortable: false, numeric: false, label: 'Power On' },
      { id: 'add', sortable: false, numeric: false, label: 'Add' },
      { id: 'remove', sortable: false, numeric: false, label: 'Remove' },
    ];

    // const powerOn = 1;
    return (<Fragment>
      <div style={{
        width: '100%', border: 'none', flexGrow: 1, overflowX: 'hidden', height: '100%'
      }}>
        <Table id="scheduleList">
          <SonifiTableHead
            headColumns={tableHeader}
            order="asc"
            orderBy="id"
          />
          <TableBody>
            {realChannels && realChannels.length < 1
              ? <TableRow>
                <TableCell colSpan={5}>
                  {firstLoad
                    ? <SonifiSpinner />
                    : <SonifiLabel error label="No Channels Error" />}
                </TableCell>
              </TableRow>
              : realChannels.map(({ id, ipg, name }) => (
                <TableRow key={id}>
                  <TableCell>{id}</TableCell>
                  <TableCell>{name}</TableCell>
                  {/*  <TableCell>
                    <Radio
                      checked={id === this.state.powerOn}
                      disabled={!canEdit}
                      inputProps={{ 'aria-label': `${id}` }}
                      name="powerOn"
                      onChange={this.handleRadioChange('powerOn', id)}
                      value={id}
                    />
                  </TableCell>
                  */}
                  <TableCell>
                    <SonifiCheckbox
                      value={this.isChannelAdded(ipg.source_id)}
                      noLabel={true}
                      onChange={!canEdit ? () => { } : this.editAddValue(ipg.source_id)}
                      removeTopPadding={true}
                      error={!!(errors && errors.channels && errors.channels.includes(ipg.source_id))}
                      disabled={!canEdit}
                      disableRipple={!canEdit} />
                  </TableCell>
                  <TableCell>
                    <SonifiCheckbox
                      value={this.isChannelRemoved(ipg.source_id)}
                      noLabel={true}
                      onChange={!canEdit ? () => { } : this.editRemoveValue(ipg.source_id)}
                      removeTopPadding={true}
                      error={!!(errors && errors.channels && errors.channels.includes(ipg.source_id))}
                      disabled={!canEdit}
                      disableRipple={!canEdit} />
                  </TableCell>
                </TableRow>))}
          </TableBody>
        </Table>
      </div>
      <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
        open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
    </Fragment>);
  }
}

const mapStateToProps = (state) => ({
  firstLoad: state.channels.firstLoad,
  realChannels: state.channels.realChannels,
  selectedGroup: state.groupServices.selectedGroup,
  snackBarMessage: state.channels.snackBarMessage,
  snackBarType: state.channels.snackBarType
});

GroupChannels.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  errors: PropTypes.object,
  firstLoad: PropTypes.bool,
  realChannels: PropTypes.array,
  selectedGroup: PropTypes.object,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string
};

export default connect(mapStateToProps)(GroupChannels);

