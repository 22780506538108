import { textHelper } from '../../../utils/textUtil';
import { ANALOG } from '../../Channels2/constants/constants';
import {
  NONE, PROIDIOM, TVCABLES_COAX, TVCABLES_IP,
  TVMPEG2, TVMPEG4
} from '../constants/lineupConstants';

export function validLineup(lineupObj, channelsInLineup, translations, globalTranslations) {
  const validationErrors = [];
  const errors = {};

  if (!lineupObj.cables) {
    errors.cables = translations.cables;
  }

  if (!lineupObj.classification) {
    errors.classification = translations.classification;
  }

  if (!lineupObj.encryption) {
    errors.encryption = translations.encryption;
  }

  if (!lineupObj.mpeg_format) {
    errors.mpeg_format = translations.mpegFormat;
  }

  if (!lineupObj.name || lineupObj.name.trim() === '') {
    errors.name = translations.name;
  } else if (textHelper(lineupObj.name)) {
    errors.name = globalTranslations.symbols;
  } else if (lineupObj.name.length > 32) {
    errors.name = translations.nameCharRestriction;
  }

  if (lineupObj.is_power_on_enabled && !lineupObj.power_on_ipg_source_id) {
    errors.power_on_ipg_source_id = translations.powerOn;
  }

  if (!lineupObj.tuner_type) {
    errors.tuner_type = translations.tunerType;
  }

  if (!lineupObj.bat_warmup_seconds) {
    errors.bat_warmup_seconds = translations.bat;
  }

  if (lineupObj.is_power_on_enabled &&
    (!lineupObj.power_on_ipg_source_id || !channelsInLineup.includes(lineupObj.power_on_ipg_source_id))) {
    errors.power_on_ipg_source_id = true;
    validationErrors.push(translations.powerOn);
  }

  if (!channelsInLineup || channelsInLineup.length < 1) {
    validationErrors.push(translations.noChannels);
  } else if (!lineupObj.channels || lineupObj.channels.length < 1) {
    validationErrors.push(translations.oneChannel);
    errors.channels = true;
  } else {
    let found = false;
    for (let i = 0; i < lineupObj.channels.length; i++) {
      if (channelsInLineup.includes(lineupObj.channels[i])) {
        found = true;
        break;
      }
    }
    if (!found) {
      errors.channels = true;
      validationErrors.push(translations.oneChannel);
    }
  }

  if (validationErrors.length > 0) {
    errors.validationErrors = validationErrors;
  }

  // return validationErrors;
  return errors;
}

export function checkChannelInfo(channel, lineupInfo) {
  if (!channel.enabled) {
    return false;
  }

  if (lineupInfo.cables === TVCABLES_COAX) {
    return checkCoaxCables(lineupInfo, channel);
  } else if (lineupInfo.cables === TVCABLES_IP) {
    return checkIPCables(lineupInfo, channel);
  }
  return checkCoaxIpCables(lineupInfo, channel);
}

export function checkAllCables(channel, lineupInfo) {
  if (lineupInfo.cables === TVCABLES_COAX) {
    return checkCoaxCables(lineupInfo, channel);
  } else if (lineupInfo.cables === TVCABLES_IP) {
    return checkIPCables(lineupInfo, channel);
  }
  return checkCoaxIpCables(lineupInfo, channel);
}

export function checkCoaxCables(lineupInfo, channel) {
  if (!channel.analog && !channel.intl_analog && !channel.digital && !channel.intl_dvb_c &&
    !channel.intl_dvb_s && !channel.intl_dvb_t) {
    return false;
  }

  if (lineupInfo.tuner_type === ANALOG) {
    if (!channel.analog && !channel.intl_analog) {
      return false;
    } else {
      return true;
    }
  } else if (!channel.analog && !channel.digital && !channel.intl_dvb_c && !channel.intl_dvb_s && !channel.intl_dvb_t) {
    return false;
  }

  if (!checkDigitalEncryption(lineupInfo, channel)) {
    return false;
  }

  if (!checkDigitalMpegFormat(lineupInfo, channel)) {
    return false;
  }
  return true;
}

export function checkDigitalEncryption(lineupInfo, channel) {
  if (lineupInfo.encryption === PROIDIOM) {
    return true;
  }
  if (channel.digital) {
    if (lineupInfo.encryption !== channel.digital.encryption) {
      return false;
    }
  }
  if (channel.intl_dvb_c) {
    if (lineupInfo.encryption !== channel.intl_dvb_c.encryption) {
      return false;
    }
  }
  if (channel.intl_dvb_s) {
    if (lineupInfo.encryption !== channel.intl_dvb_s.encryption) {
      return false;
    }
  }
  if (channel.intl_dvb_t) {
    if (lineupInfo.encryption !== channel.intl_dvb_t.encryption) {
      return false;
    }
  }
  return true;
}


export function checkDigitalMpegFormat(lineupInfo, channel) {
  // Mpeg4 is backward compatible and we can allow mpeg2 and mpeg4
  if (lineupInfo.mpeg_format === TVMPEG4) {
    return true;
  }
  if (channel.digital) {
    if (lineupInfo.mpeg_format !== channel.digital.video_format) {
      return false;
    }
  }
  if (channel.intl_dvb_c) {
    if (lineupInfo.mpeg_format !== channel.intl_dvb_c.video_format) {
      return false;
    }
  }
  if (channel.intl_dvb_s) {
    if (lineupInfo.mpeg_format !== channel.intl_dvb_s.video_format) {
      return false;
    }
  }
  if (channel.intl_dvb_t) {
    if (lineupInfo.mpeg_format !== channel.intl_dvb_t.video_format) {
      return false;
    }
  }
  return true;
}

export function checkIPCables(lineupInfo, channel) {
  if (!channel.ip) {
    return false;
  }

  // check encryption
  if (lineupInfo.encryption === NONE) {
    if (channel.ip.encryption === PROIDIOM) {
      return false;
    }
  } else if (lineupInfo.encryption === PROIDIOM) {
    if (channel.ip.encryption !== PROIDIOM && channel.ip.encryption !== NONE) {
      return false;
    }
  }

  // check mpeg format
  if (lineupInfo.mpeg_format === TVMPEG2 && channel.ip.video_format === TVMPEG4) {
    return false;
  }

  return true;
}

export function checkCoaxIpCables(lineupInfo, channel) {
  if (!channel.analog && !channel.intl_analog && !channel.intl_dvb_c && !channel.intl_dvb_s &&
    !channel.intl_dvb_t && !channel.digital && !channel.ip) {
    return false;
  }

  return (checkIPCables(lineupInfo, channel) || checkCoaxCables(lineupInfo, channel));
}

export function getChannelsThatCanBeInLineup(lineup, channels) {
  const channelsInLineup = [];
  for (let i = 0; i < channels.length; i++) {
    if (checkChannelInfo(channels[i], lineup)) {
      channelsInLineup.push(channels[i].ipg.source_id);
    }
  }
  return channelsInLineup;
}
