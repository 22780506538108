import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel, SonifiSpinner, SonifiText } from '../../../../containers';
import SonifiModalHeader from '../../../../containers/SonifiModalHeader';
import { getErrorText, isError } from '../../../../utils';
import { fetchFirmware, startFirmware } from '../../../Firmware/actions/firmwareActions';
import { saveSiteFirmware } from '../../actions/FirmwareActions';
import { setSelectedLgFirmware } from '../../actions/LgFirmwareActions';
import { BROWSER, CPU, LG, PTC } from '../../constants/TermFileConstants';
import { validLGFirmware } from '../../utils/validator';

class LgFirmwareDialog extends Component {
  state = {
    cpuFirmware: null,
    id: null,
    model: null,
    origBrowserFirmware: null,
    origCpuFirmware: null,
    origPtcFirmware: null,
    ptcFirmware: null,
  };

  componentDidMount() {
    const { dispatch, lgFirmware, lgIndex } = this.props;
    const { id, model, firmware } = lgFirmware[lgIndex];

    this.setState({
      browserFirmware: firmware ? firmware.BROWSER : '',
      cpuFirmware: firmware ? firmware.CPU : '',
      id,
      model,
      origBrowserFirmware: firmware ? firmware.BROWSER : '',
      origCpuFirmware: firmware ? firmware.CPU : '',
      origPtcFirmware: firmware ? firmware.PTC : '',
      ptcFirmware: firmware ? firmware.PTC : '',
    });

    if (model) {
      dispatch(startFirmware());
      setTimeout(() => {
        dispatch(
          fetchFirmware({
            limit: 100,
            sort: 'id:asc',
            make: LG,
            model: id,
          })
        );
      }, 200);
    }
  }

  updateFirmware() {
    const { translations } = this.props;
    const {
      cpuFirmware,
      id,
      origCpuFirmware,
      ptcFirmware,
      origPtcFirmware,
      origBrowserFirmware,
      browserFirmware,
    } = this.state;

    const lgFirmwareObj = {};
    lgFirmwareObj.id = id;
    lgFirmwareObj.firmware = {};
    if (cpuFirmware && cpuFirmware.trim() !== '') {
      if (cpuFirmware === translations.unconfigureCPU) {
        lgFirmwareObj.firmware.CPU = null;
      } else {
        lgFirmwareObj.firmware.CPU = cpuFirmware;
      }
    }

    if (ptcFirmware && ptcFirmware.trim() !== '') {
      if (ptcFirmware === translations.unconfigurePTC) {
        lgFirmwareObj.firmware.PTC = null;
      } else {
        lgFirmwareObj.firmware.PTC = ptcFirmware;
      }
    }

    if (browserFirmware && browserFirmware.trim() !== '') {
      if (browserFirmware === translations.unconfigureBrowser) {
        lgFirmwareObj.firmware.BROWSER = null;
      } else {
        lgFirmwareObj.firmware.BROWSER = browserFirmware;
      }
    }

    validLGFirmware(lgFirmwareObj, translations.errors)
      .then((isValidFirmware) => {
        this.setState({ errors: isValidFirmware });

        if (Object.entries(isValidFirmware).length === 0) {
          if (origCpuFirmware === cpuFirmware && origPtcFirmware === ptcFirmware &&
            origBrowserFirmware === browserFirmware) {
            this.onCancel();
            return;
          }
          this.props.dispatch(saveSiteFirmware(lgFirmwareObj, LG));
        }
      })
      .catch((error) => {
        console.log('Valid Firmware Error', error);
        this.setState({ errors: { cpu: translations.errors.general } });
      });
  }

  onCancel() {
    this.props.dispatch(setSelectedLgFirmware(-1));
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.onCancel();
    }
  }

  handleCpuChange = () => ({ target: { value } }) => {
    this.setState({ cpuFirmware: value });
  };

  handlePtcChange = () => ({ target: { value } }) => {
    this.setState({ ptcFirmware: value });
  };

  handleBrowserChange = () => ({ target: { value } }) => {
    this.setState({ browserFirmware: value });
  };

  getBrowserOptions() {
    let cpuOptions = '',
      ptcOptions = '',
      browserOptions = '';

    const { loading, translations, configuredFirmware } = this.props;
    const { origCpuFirmware, origPtcFirmware, origBrowserFirmware } = this.state;

    if (!loading) {
      cpuOptions = [...configuredFirmware];
      ptcOptions = [...configuredFirmware];
      browserOptions = [...configuredFirmware];

      if (origCpuFirmware !== '' && origCpuFirmware !== null && origCpuFirmware !== undefined) {
        const selected = find(cpuOptions, (o) => o.id === origCpuFirmware);
        if (selected === undefined) {
          cpuOptions.unshift({ id: origCpuFirmware, type: CPU });
        }
        cpuOptions.unshift({ id: translations.unconfigureCPU, type: CPU });
      }

      if (origPtcFirmware !== '' && origPtcFirmware !== null && origPtcFirmware !== undefined) {
        const selected = find(ptcOptions, (o) => o.id === origPtcFirmware);
        if (selected === undefined) {
          ptcOptions.unshift({ id: origPtcFirmware, type: PTC });
        }
        ptcOptions.unshift({ id: translations.unconfigurePTC, type: PTC });
      }

      if (origBrowserFirmware !== '' && origBrowserFirmware !== null && origBrowserFirmware !== undefined) {
        const selected = find(browserOptions, (o) => o.id === origBrowserFirmware);
        if (selected === undefined) {
          browserOptions.unshift({ id: origBrowserFirmware, type: BROWSER });
        }
        browserOptions.unshift({ id: translations.unconfigureBrowser, type: BROWSER });
      }
    }
    return {
      browserOptions, cpuOptions, ptcOptions
    };
  }

  render() {
    const {
      canEdit, globalTranslations, loading, translations
    } = this.props;
    const {
      id, cpuFirmware, errors, browserFirmware, ptcFirmware
    } = this.state;

    const dropDownOptions = this.getBrowserOptions();


    // let dropDownOptions.cpuOptions = '',
    //   ptcOptions = '';

    // if (!loading) {
    //   dropDownOptions.cpuOptions = [...configuredFirmware];
    //   ptcOptions = [...configuredFirmware];
    //   browserOptions = [...configuredFirmware];

    //   if (origCpuFirmware !== '' && origCpuFirmware !== null && origCpuFirmware !== undefined) {
    //     const selected = find(dropDownOptions.cpuOptions, (o) => o.id === origCpuFirmware);
    //     if (selected === undefined) {
    //       dropDownOptions.cpuOptions.unshift({ id: origCpuFirmware, type: CPU });
    //     }
    //     dropDownOptions.cpuOptions.unshift({ id: translations.unconfigureCPU, type: CPU });
    //   }

    //   if (origPtcFirmware !== '' && origPtcFirmware !== null && origPtcFirmware !== undefined) {
    //     const selected = find(ptcOptions, (o) => o.id === origPtcFirmware);
    //     if (selected === undefined) {
    //       ptcOptions.unshift({ id: origPtcFirmware, type: PTC });
    //     }
    //     ptcOptions.unshift({ id: translations.unconfigurePTC, type: PTC });
    //   }

    //   if (origBrowserFirmware !== '' && origBrowserFirmware !== null && origBrowserFirmware !== undefined) {
    //     const selected = find(browserOptions, (o) => o.id === origBrowserFirmware);
    //     if (selected === undefined) {
    //       browserOptions.unshift({ id: origBrowserFirmware, type: BROWSER });
    //     }
    //     browserOptions.unshift({ id: translations.unconfigureBrowser, type: BROWSER });
    //   }
    // }

    return (
      <Dialog
        open={true}
        maxWidth="md"
        onClose={this.onClose.bind(this)}
        fullWidth>
        <SonifiModalHeader
          header={translations.dialogTitle}
          onlyClose={!canEdit || loading}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.updateFirmware.bind(this)}
          label={globalTranslations.save}
        />
        <DialogContent>
          {loading
            ? <SonifiSpinner />
            : <Grid container>
              <Grid item xs={12}>
                <SonifiLabel boldLabel={translations.text.replace('#model', id)} />
              </Grid>
              <Grid item xs={4}>
                <SonifiText
                  defaultValue={cpuFirmware}
                  change={this.handleCpuChange()}
                  select={true}
                  label={translations.cpu}
                  size="percent"
                  error={isError('cpu', errors)}
                  errorText={getErrorText('cpu', errors)}
                  items={dropDownOptions.cpuOptions.filter(
                    (suggestion) => (suggestion.type === CPU && !suggestion.corrupted)
                  ).map((suggestion) => ({
                    id: suggestion.id,
                    value: suggestion.id,
                    label: suggestion.id,
                  }))}
                />
              </Grid>
              <Grid item xs={4}>
                <SonifiText
                  defaultValue={ptcFirmware}
                  change={this.handlePtcChange()}
                  select={true}
                  label={translations.ptc}
                  size="percent"
                  error={isError('ptc', errors)}
                  errorText={getErrorText('ptc', errors)}
                  items={dropDownOptions.ptcOptions.filter(
                    (suggestion) => (suggestion.type === PTC && !suggestion.corrupted)
                  ).map((suggestion) => ({
                    id: suggestion.id,
                    value: suggestion.id,
                    label: suggestion.id,
                  }))}
                />
              </Grid>
              <Grid item xs={4}>
                <SonifiText
                  defaultValue={browserFirmware}
                  change={this.handleBrowserChange()}
                  select={true}
                  label={translations.browser}
                  size="percent"
                  error={isError('browser', errors)}
                  errorText={getErrorText('browser', errors)}
                  items={dropDownOptions.browserOptions.filter(
                    (suggestion) => (suggestion.type === BROWSER && !suggestion.corrupted)
                  ).map((suggestion) => ({
                    id: suggestion.id,
                    value: suggestion.id,
                    label: suggestion.id,
                  }))}
                />
              </Grid>
            </Grid>}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  configuredFirmware: state.firmware.firmware,
  globalTranslations: state.global.translations.defaults,
  lgFirmware: state.termFile.lgFirmware,
  lgIndex: state.termFile.lgIndex,
  loading: state.firmware.loading,
  translations: state.termFile.translations.dialog.lg
});

LgFirmwareDialog.propTypes = {
  canEdit: PropTypes.bool,
  configuredFirmware: PropTypes.array,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  lgFirmware: PropTypes.array,
  lgIndex: PropTypes.number,
  loading: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(LgFirmwareDialog);
    
