import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { intersectionWith, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { EDIT, ERROR } from '../../../constants/constants';
import { ALL_MESSAGE_ROLES } from '../../../constants/roleGroups';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiTabs from '../../../containers/SonifiTabs';
import { getSiteNumFromURI } from '../../../utils';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { getChannelSets, setFirstLoad, setSnackBar } from '../../Channels2/actions/channelsActions';
import { getTerminalOptions } from '../../Terminals/actions/terminalActions';
import * as actions from '../actions/groupServicesActions';
import {
  CHANNELS, CODES, MESSAGES, ROOMS
} from '../constants';
import GroupChannels from '../containers/GroupChannels';
import GroupCodes from '../containers/GroupCodes';
import GroupInfo from '../containers/GroupInfo';
import GroupMessages from '../containers/GroupMessages';
import GroupRooms from '../containers/GroupRooms';
import { isValidGroup } from '../utils/validator';

class GroupServicesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      haveMessageToSend: false,
      tabValue: this.props.hasPms ? CODES : CHANNELS
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.updateMessageState = this.updateMessageState.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { dispatch, hasPms, selectedGroup, userPermissions } = this.props;
    dispatch(setSnackBar());
    dispatch(setFirstLoad(true));
    dispatch(getChannelSets(true));
    dispatch(actions.resetGroupDialog());
    dispatch(getTerminalOptions(getSiteNumFromURI()));
    if (!selectedGroup.isNew) {
      dispatch(actions.getGroupRooms(selectedGroup.id));
    }

    if (!hasPms && selectedGroup.isNew) {
      dispatch(actions.editSelectedGroup('codes', [selectedGroup.id]));
    }

    if (checkForAtLeastOneUserPermission(ALL_MESSAGE_ROLES, userPermissions) && !selectedGroup.isNew) {
      dispatch(actions.getGroupMessages(selectedGroup.id));
    }

    this.setState({ tabValue: hasPms ? CODES : CHANNELS });
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(actions.updateSelectedGroup(null));
    }
  }

  onSubmit() {
    const {
      globalTranslations, hasPms, selectedGroup, translations
    } = this.props;
    this.setState({ errors: null });

    isValidGroup(selectedGroup, hasPms, translations.errors, globalTranslations.errors)
      .then((validationErrors) => this.onSaveContinue(validationErrors));
  }

  onSaveContinue(validationErrors) {
    const { dispatch, selectedGroup, refetchGroupsHandler, translations } = this.props;

    if (Object.entries(validationErrors).length === 0) {
      if (selectedGroup.isNew) {
        dispatch(actions.saveGroup(selectedGroup))
          .then(() => {
            refetchGroupsHandler();
          });
      } else {
        dispatch(actions.updateGroup(selectedGroup))
          .then(() => {
            refetchGroupsHandler();
          });
      }
    } else {
      this.setState({ errors: validationErrors });
      const validationKeys = Object.keys(validationErrors);

      const errorChannelString = validationKeys.map((key) => {
        if (key === 'channels') {
          const errorChannels = intersectionWith(map(this.props.realChannels, 'ipg'), validationErrors[key],
            (o, num) => o.source_id === num);
          const channelArray = map(errorChannels, 'call_letters');
          const channelNames = `Channels: ${channelArray.map((chan) => `\n - ${chan}`)}`;
          return `\n - ${channelNames.toString()}`;
        } else {
          return `\n - ${validationErrors[key]}`;
        }
      });

      dispatch(actions.setSnackBar(`${translations.errors.save} ${errorChannelString}`, ERROR));
    }
  }

  updateMessageState(value) {
    this.setState({ haveMessageToSend: value });
  }

  handleChange(event, newValue) {
    if (newValue !== this.state.tabValue) {
      this.setState({ tabValue: newValue, updateMessageState: false });
    }
  }

  handleError(errorObj) {
    const newErrors = { ...this.state.errors, ...errorObj };

    this.setState({ errors: newErrors });
  }

  getTabContainer() {
    const { canEdit, editType } = this.props;
    const ableToEditDialog = canEdit && editType === EDIT;

    switch (this.state.tabValue) {
      case CHANNELS:
        return <GroupChannels canEdit={ableToEditDialog} errors={this.state.errors} />;
      case MESSAGES:
        return <GroupMessages canEdit={ableToEditDialog} updateMessageState={this.updateMessageState} />;
      case CODES:
        return <GroupCodes
          canEdit={ableToEditDialog} errors={this.state.errors} errorHandler={this.handleError}
        />;
      case ROOMS:
        return <GroupRooms canEdit={ableToEditDialog} />;
      default:
        return <Fragment />;
    }
  }

  getTitle() {
    const { editType, selectedGroup, translations } = this.props;
    if (selectedGroup.isNew) {
      return translations.addTitle;
    }
    return `${editType === EDIT ? translations.editTitle : translations.viewTitle}: ${selectedGroup.name}`;
  }

  render() {
    const {
      canEdit, editType, globalTranslations, hasPms, selectedGroup, translations, userPermissions
    } = this.props;
    const { errors, haveMessageToSend, tabValue } = this.state;

    const tabsArray = [];

    if (hasPms) {
      tabsArray.push({ id: CODES, title: translations.codesTab.tabTitle });
    }

    tabsArray.push({ id: CHANNELS, title: translations.channels.tabTitle });

    if (checkForAtLeastOneUserPermission(ALL_MESSAGE_ROLES, userPermissions)) {
      tabsArray.push({ id: MESSAGES, title: translations.messages.tabTitle });
    }

    if (!hasPms || (hasPms && !selectedGroup.isNew)) {
      tabsArray.push({ id: ROOMS, title: translations.roomsTab.tabTitle });
    }

    const ableToEditDialog = canEdit && editType === EDIT;

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={this.onCancel}
        open={true}
      >
        <Fragment>
          <SonifiModalHeader
            disabled={haveMessageToSend}
            header={this.getTitle()}
            label={globalTranslations.defaults.save}
            onCancel={this.onCancel}
            onlyClose={!ableToEditDialog}
            onSubmit={this.onSubmit}
            submitToolTip={haveMessageToSend ? translations.messageToSend : ''} />
          <DialogContent style={{ height: '100vh' }}>
            <Grid container>
              <Grid item xs={12}>
                <GroupInfo canEdit={ableToEditDialog} errors={errors} />
              </Grid>
              <Grid item xs={12} style={{ height: '56vh' }}>
                <SonifiTabs
                  handleChange={this.handleChange}
                  showingTab={this.getTabContainer()}
                  tabs={tabsArray}
                  tabValue={tabValue} />
              </Grid>
            </Grid>
          </DialogContent>
        </Fragment>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  editType: state.groupServices.editType,
  globalTranslations: state.global.translations,
  realChannels: state.channels.realChannels,
  groups: state.groupServices.groups,
  hasPms: state.integrations.hasPms,
  selectedGroup: state.groupServices.selectedGroup,
  translations: state.groupServices.translations.editDialog,
  userPermissions: state.global.permissions
});

GroupServicesDialog.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  globalTranslations: PropTypes.object,
  realChannels: PropTypes.array,
  groups: PropTypes.array,
  hasPms: PropTypes.bool,
  refetchGroupsHandler: PropTypes.func,
  selectedGroup: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(GroupServicesDialog);
