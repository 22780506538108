import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { SonifiSubHeader, SonifiText } from '../../../../../containers';
import { analogStandardValues, analogTypeValues, INTL_ANALOG } from '../../../constants/constants';

export default class IntlAnalogForm extends Component {
  render() {
    const {
      intlAnalogObj, errors, readOnly, translations, editFunction
    } = this.props;

    return (
      <Fragment><Grid item>
        <SonifiSubHeader
          header={'International Settings'}
          showButton={false}
        />
        <SonifiText
          defaultValue={intlAnalogObj.carrier}
          change={editFunction(INTL_ANALOG, 'carrier')}
          select={true}
          label={translations.type}
          size="md"
          items={analogTypeValues}
          error={!!errors.analogCarrier}
          errorText={(errors.analogCarrier ? errors.analogCarrier : '')}
          disabled={readOnly} />
        <SonifiText
          defaultValue={intlAnalogObj.frequency_khz}
          label={translations.frequencyKhz}
          change={editFunction(INTL_ANALOG, 'frequency_khz', 'number')}
          size="md"
          error={!!errors.analogFrequency}
          errorText={(errors.analogFrequency ? errors.analogFrequency : '')}
          disabled={readOnly} />
      </Grid>
        <Grid item>
          <SonifiText
            defaultValue={intlAnalogObj.standard}
            change={editFunction(INTL_ANALOG, 'standard')}
            select={true}
            label={translations.standard}
            size="md"
            items={analogStandardValues}
            error={!!errors.analogStandard}
            errorText={(errors.analogStandard ? errors.analogStandard : '')}
            disabled={readOnly} />
        </Grid>
      </Fragment>
    );
  }
}

IntlAnalogForm.propTypes = {
  intlAnalogObj: PropTypes.object,
  editFunction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
  translations: PropTypes.object
};
