export const GET_PRODUCTS_BEGIN = 'GET_PRODUCTS_BEGIN';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_SCHEDULE_BEGIN = 'GET_SCHEDULE_BEGIN';
export const GET_SCHEDULE_ERROR = 'GET_SCHEDULE_ERROR';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const GET_SYSTEMS_BEGIN = 'GET_SYSTEMS_BEGIN';
export const GET_SYSTEMS_ERROR = 'GET_SYSTEMS_ERROR';
export const GET_SYSTEMS_SUCCESS = 'GET_SYSTEMS_SUCCESS';
export const GET_UPGRADE_WINDOW_BEGIN = 'GET_UPGRADE_WINDOW_BEGIN';
export const GET_UPGRADE_WINDOW_FAILURE = 'GET_UPGRADE_WINDOW_FAILURE';
export const GET_UPGRADE_WINDOW_SUCCESS = 'GET_UPGRADE_WINDOW_SUCCESS';
export const LOAD_LANGUAGE_LITERALS = 'LOAD_LANGUAGE_LITERALS';
export const SET_UPGRADE_WINDOW_BEGIN = 'SET_UPGRADE_WINDOW_BEGIN';
export const SET_UPGRADE_WINDOW_FAILURE = 'SET_UPGRADE_WINDOW_FAILURE';
export const SET_UPGRADE_WINDOW_SUCCESS = 'SET_UPGRADE_WINDOW_SUCCESS';
export const UPDATE_HOST_BEGIN = 'UPDATE_HOST_BEGIN';
export const UPDATE_HOST_FAILURE = 'UPDATE_HOST_FAILURE';
export const UPDATE_HOST_ROW = 'UPDATE_HOST_ROW';
export const UPDATE_HOST_SUCCESS = 'UPDATE_HOST_SUCCESS';
export const UPDATE_SNACK_BAR = 'SYSTEM_UPDATE_SNACK_BAR';

// export const UPGRADE_SYSTEM_BEGIN = 'UPGRADE_SYSTEM_BEGIN';
// export const UPGRADE_SYSTEM_ERROR = 'UPGRADE_SYSTEM_ERROR';
// export const UPGRADE_SYSTEM_SUCCESS = 'UPGRADE_SYSTEM_SUCCESS';
