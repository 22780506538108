import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { DISTANCE_FROM_END } from '../../../constants/magic';
import { SonifiSpinner } from '../../../containers';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import AuditRow from './AuditRow';

class AuditTable extends Component {
  render() {
    const {
      auditInfo, auditState, loading, maxPages, translations
    } = this.props;
    const { order, orderBy, page } = auditState;

    const tableHeader = [
      { id: 'username', sortable: auditInfo.length > 1, numeric: false, label: `${translations.username}` },
      { id: 'timestamp', sortable: auditInfo.length > 1, numeric: false, label: `${translations.timestamp}` },
      { id: 'method', sortable: auditInfo.length > 1, numeric: false, label: `${translations.method}` },
      { id: 'path', sortable: false, numeric: false, label: `${translations.path}` }
    ];

    return (
      <Table stickyHeader={true}>
        <SonifiTableHead
          headColumns={tableHeader}
          order={order}
          orderBy={orderBy}
          onRequestSort={this.props.handleRequestSort}
        />
        <TableBody>
          {loading
            ? <TableRow><TableCell colSpan={4}><SonifiSpinner /></TableCell></TableRow>
            : <Fragment>
              {!auditInfo || auditInfo.length === 0
                ? <TableRow>
                  <TableCell colSpan={4}><SonifiLabel error label={translations.errors.noAuditInfo} /></TableCell>
                </TableRow>
                : auditInfo.map((info, index) => (
                  <Fragment key={index}>
                    <AuditRow auditRow={info} rowIndex={index} />
                    {index === auditInfo.length - DISTANCE_FROM_END && page < maxPages && (
                      <TableRow>
                        <TableCell>
                          <Waypoint onEnter={() => { this.props.getMoreData(); }} />
                        </TableCell>
                      </TableRow>)
                    }
                  </Fragment>
                ))
              }
            </Fragment>
          }
        </TableBody>
      </Table>
    );
  }
}

const mapStateToProps = (state) => ({
  auditInfo: state.auditReport.auditInfo,
  loading: state.auditReport.loading,
  maxPages: state.auditReport.maxPages,
  translations: state.auditReport.translations.grid
});

AuditTable.propTypes = {
  auditInfo: PropTypes.array,
  auditState: PropTypes.object,
  getMoreData: PropTypes.func,
  handleRequestSort: PropTypes.func,
  loading: PropTypes.bool,
  maxPages: PropTypes.number,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(AuditTable);
