import { Grid, Table, TableBody } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import { sortTerminals, updateSelectedTerminal } from '../actions/terminalActions';
import TerminalRow from '../containers/TerminalRow';

class TerminalTable extends Component {
  state = {
    order: 'desc',
    orderBy: 'location'
  };

  handleRequestSort = (property) => {
    this.props.dispatch(updateSelectedTerminal(-1));
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    }, () => {
      this.props.dispatch(sortTerminals(this.state));
    });
  };

  render() {
    const { dialogLoading, terminals, translations } = this.props;


    const {
      order, orderBy
    } = this.state;

    if (dialogLoading) {
      return <SonifiSpinner />;
    }

    const sortableTable = terminals && terminals.length > 1;
    const tableHeader = [
      {
        id: 'passive_status', sortable: sortableTable, numeric: false, label: `${translations.status}`, narrow: true
      },
      { id: 'location', sortable: sortableTable, numeric: false, label: `${translations.location}` },
      { id: 'language', sortable: sortableTable, numeric: false, label: `${translations.language}` },
      { id: 'lineup_id', sortable: sortableTable, numeric: false, label: `${translations.channelLineup}` },
      { id: 'menuset', sortable: sortableTable, numeric: false, label: `${translations.menuset}` },
      { id: 'id', sortable: sortableTable, numeric: false, label: `${translations.termId}` },

      //  { id: 'censor_level', sortable: sortableTable, numeric: false, label: `${translations.censorship}` },
    ];
    return (
      <Grid container style={{
        minHeight: '250px',
        width: '99%',
        overflowX: 'auto'
      }}>
        <Grid item xs={12}>
          <Table>
            <SonifiTableHead
              headColumns={tableHeader}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {terminals.map((option, index) => (
                <TerminalRow key={index} rowIndex={index} />
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  terminals: state.terminals.terminals,
  dialogLoading: state.terminals.dialogLoading,
  translations: state.rooms.translations.main
});

TerminalTable.propTypes = {
  dispatch: PropTypes.func,
  terminals: PropTypes.array.isRequired,
  dialogLoading: PropTypes.bool.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(TerminalTable);
