export function isError(fieldName, errors) {
  if (errors === null || errors === undefined || Object.entries(errors).length === 0) {
    return false;
  }

  return !!(Object.prototype.hasOwnProperty.call(errors, fieldName));
}

export function getErrorText(fieldName, errors) {
  // if (!this.state.formErrors) { return ''; }
  if (errors === null || errors === undefined || Object.entries(errors).length === 0) {
    return '';
  }
  const error = Object.prototype.hasOwnProperty.call(errors, fieldName);
  return error ? errors[fieldName] : '';
}
