export const DELETE_OPTION_BEGIN = 'DELETE_OPTION_BEGIN';
export const DELETE_OPTION_CHECK = 'DELETE_OPTION_CHECK';
export const DELETE_OPTION_FAILURE = 'DELETE_OPTION_FAILURE';
export const DELETE_OPTION_SUCCESS = 'DELETE_OPTION_SUCCESS';
export const FETCH_ROOM_TYPES_BEGIN = 'FETCH_ROOM_TYPES_BEGIN';
export const FETCH_ROOM_TYPES_FAILURE = 'FETCH_ROOM_TYPES_FAILURE';
export const FETCH_ROOM_TYPES_SUCCESS = 'FETCH_ROOM_TYPES_SUCCESS';
export const GET_OPTIONS_BEGIN = 'GET_OPTIONS_BEGIN';
export const GET_OPTIONS_FAILURE = 'GET_OPTIONS_FAILURE';
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS';
export const MOVE_OPTION = 'MOVE_LATE_CHECKOUT_OPTION';
export const REMOVE_SELECTED_ROOM_TYPE_BEGIN = 'REMOVE_SELECTED_ROOM_TYPE_BEGIN';
export const REMOVE_SELECTED_ROOM_TYPE_FAILURE = 'REMOVE_SELECTED_ROOM_TYPE_FAILURE';
export const REMOVE_SELECTED_ROOM_TYPE_SUCCESS = 'REMOVE_SELECTED_ROOM_TYPE_SUCCESS';
export const REORDER_SAVE_BEGIN = 'REORDER_SAVE_BEGIN';
export const REORDER_SAVE_FAILURE = 'REORDER_SAVE_FAILURE';
export const REORDER_SAVE_SUCCESS = 'REORDER_SAVE_SUCCESS';
export const RESET_SNACKBAR = 'RESET_LATE_CHECKOUT_SNACKBAR';
export const SAVE_OPTION_BEGIN = 'SAVE_OPTION_BEGIN';
export const SAVE_OPTION_FAILURE = 'SAVE_OPTION_FAILURE';
export const SAVE_OPTION_SUCCESS = 'SAVE_OPTION_SUCCESS';
export const SAVE_ROOM_TYPES_BEGIN = 'SAVE_ROOM_TYPES_BEGIN';
export const SAVE_ROOM_TYPES_FAILURE = 'SAVE_ROOM_TYPES_FAILURE';
export const SAVE_ROOM_TYPES_SUCCESS = 'SAVE_ROOM_TYPES_SUCCESS';
export const SELECT_OPTION = 'SELECT_OPTION';
export const SET_DRAGGING_ROW = 'SET_OPTIONS_DRAGGING_ROW';
export const SET_DROPPED_ROW = 'SET_OPTIONS_DROPPED_ROW';
export const SHOW_ROOM_TYPES_DIALOG = 'SHOW_ROOM_TYPES_DIALOG';
export const SORT_ROOM_TYPES = 'SORT_ROOM_TYPES';
