import { Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class DetailsCard extends Component {
  render() {
    const { helperClass, getItem, translations, selectedTerm } = this.props;
    return (
      <Grid container justifyContent="space-between" className={helperClass.card} >
        {getItem((selectedTerm.type === 'ip'
          ? translations.terminalInfo.macAddress
          : translations.terminalInfo.address), selectedTerm.id)}
        {selectedTerm.type !== 'ip' &&
          getItem(translations.terminalInfo.icpIndex, selectedTerm.icpIndex)
        }
        {getItem(translations.terminalInfo.lastMod, selectedTerm.last_modified
          ? moment(selectedTerm.last_modified, 'YYYYMMDDHHmmss').format('MM/DD/YYYY h:mm a')
          : '')}
        {selectedTerm.type === 'ip' && getItem(translations.terminalInfo.ipAddress, selectedTerm.ipAddress)}
        {selectedTerm.type !== 'ip' && getItem(translations.terminalInfo.icpHost, selectedTerm.icpHost)}
        {getItem(translations.terminalInfo.lastComm, selectedTerm.last_communicated)}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.termGrid.changed,
  selectedTerm: state.termGrid.selectedTerm,
  translations: state.termGrid.translations
});

DetailsCard.propTypes = {
  helperClass: PropTypes.object,
  getItem: PropTypes.func,
  selectedTerm: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(DetailsCard);
