import { Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { discountTypes } from '../../constants/constants';
import TieMap from './TieMap';

export default class SubTotal extends TieMap {
  render() {
    return (
      <Fragment>
        <Grid item xs={12}>
          {/* {this.getDropDown('D0', discountTypes)} */}
          {this.getDropDown('D1', discountTypes)}
          {this.getDropDown('D2', discountTypes)}
          {this.getDropDown('D3', discountTypes)}
          {this.getDropDown('D4', discountTypes)}
          {this.getDropDown('D5', discountTypes)}
          {this.getDropDown('D6', discountTypes)}
          {this.getDropDown('D7', discountTypes)}
          {this.getDropDown('D8', discountTypes)}
          {this.getDropDown('D9', discountTypes)}
        </Grid>
      </Fragment>
    );
  }
}
