import { arrayMoveImmutable } from 'array-move';
import { removePipe } from '../../../utils/textUtil';
import { CORE, durations, PROPERTY, SERVER_STREAM } from '../constants/constants';

// Function to compare all fields of a channel
export const compareChannels = (channel1, channel2) => {
  let areEqual = channel1.id === channel2.id &&
    channel1.name === channel2.name &&
    channel1.enabled === channel2.enabled &&
    channel1.locked === channel2.locked &&
    channel1.type === channel2.type &&
    channel1.ipg.source_id === channel2.ipg.source_id &&
    channel1.ipg.affiliate === channel2.ipg.affiliate &&
    channel1.ipg.call_letters === channel2.ipg.call_letters &&
    channel1.ipg.description === channel2.ipg.description &&
    channel1.ipg.duration === channel2.ipg.duration &&
    channel1.ipg.entry === channel2.ipg.entry &&
    ((channel1.ip && channel2.ip) || (!channel1.ip && !channel2.ip)) &&
    ((channel1.digital && channel2.digital) || (!channel1.digital && !channel2.digital)) &&
    ((channel1.analog && channel2.analog) || (!channel1.analog && !channel2.analog));

  if (areEqual) {
    if (channel1.ip) {
      areEqual = channel1.ip.address === channel2.ip.address &&
        channel1.ip.encryption === channel2.ip.encryption &&
        channel1.ip.mbps === channel2.ip.mbps &&
        channel1.ip.port === channel2.ip.port &&
        channel1.ip.stream === channel2.ip.stream &&
        channel1.ip.video_format === channel2.ip.video_format;
    }
    if (channel1.digital) {
      areEqual = channel1.digital.encryption === channel2.digital.encryption &&
        channel1.digital.mbps === channel2.digital.mbps &&
        channel1.digital.modulator === channel2.digital.modulator &&
        channel1.digital.stream === channel2.digital.stream &&
        channel1.digital.tuning === channel2.digital.tuning &&
        channel1.digital.video_format === channel2.digital.video_format;
    }
    if (channel1.analog) {
      areEqual = channel1.analog.modulator === channel2.analog.modulator &&
        channel1.analog.tuning === channel2.analog.tuning;
    }
  }
  return areEqual;
};

// Handle a move channel with locks
// Overview is split current list into a locked list and unlocked list
// Handle move on unlocked list, the sort the two lists back together
// !!!NO NEW CHANNEL NUMBER ARE ADDED AND NO CHANNEL NUMBERS ARE LOST!!!
export const handleChannelMove = (channelList, oldIndex, newIndex) => {
  const lockedArray = [];
  const unlockedArray = [];
  let convertedNewIndex = -1,
    convertedOldIndex = -1;

  // Split the arrays into locked and unlocked
  for (let i = 0; i < channelList.length; i++) {
    if (channelList[i].locked) {
      lockedArray.push(channelList[i]);
    } else {
      unlockedArray.push(channelList[i]);
    }

    // Get the new move index in the shorter unlocked array
    if (i === newIndex) {
      if (i < oldIndex) {
        convertedNewIndex = channelList[i].locked ? unlockedArray.length : unlockedArray.length - 1;
      } else {
        convertedNewIndex = unlockedArray.length - 1;
      }
    }

    // Get the old move index in the shorter unlocked array
    if (i === oldIndex) {
      convertedOldIndex = unlockedArray.length - 1;
    }
  }

  // Perform move on unlocked array
  const newUnlocked = arrayMoveImmutable(unlockedArray, convertedOldIndex, convertedNewIndex);
  const orderedUnlocked = [];

  // Update the channel numbers
  for (let i = 0; i < newUnlocked.length; i++) {
    orderedUnlocked.push({ ...newUnlocked[i], id: unlockedArray[i].id });
  }

  const movedChannel = orderedUnlocked[convertedNewIndex];


  // Add the locked array back on and sort
  const finalList = orderedUnlocked.concat(lockedArray);
  finalList.sort((a, b) => a.id - b.id);

  const finalIndex = finalList.findIndex((channel) => compareChannels(channel, movedChannel));

  return { finalList, finalIndex };
};

export const findDuplicateDisplayChannels = (channelList, currentChannel) => {
  // Find index of a channel with the same display number as the edited channel
  let displayChannelIndex1 =
    channelList.findIndex((channel) => channel.id === currentChannel.id),

    // Try to find a second channel with the same display number as the edited channel
    displayChannelIndex2 =
      channelList.slice(displayChannelIndex1 + 1)
        .findIndex((channel) => channel.id === currentChannel.id);

  // If there is a duplicate
  if (displayChannelIndex2 !== -1) {
    // Get duplicate index in full array
    displayChannelIndex2 = displayChannelIndex2 + displayChannelIndex1 + 1;

    // Figure out which index points at the updated channel
    if (compareChannels(channelList[displayChannelIndex2], currentChannel)) {
      const swap = displayChannelIndex1;
      displayChannelIndex1 = displayChannelIndex2;
      displayChannelIndex2 = swap;
    }
  }

  return { originalIndex: displayChannelIndex1, duplicateIndex: displayChannelIndex2 };
};

export const arraySwap = (array, index1, index2) => {
  const swap = array[index1];
  array[index1] = array[index2];
  array[index2] = swap;
  return array;
};

export const incrementChannel = (channelArray, index) => {
  // If the channel to be incremented is locked, swap with the previous row in the array
  // which has the same channel number than increment that row
  if (channelArray[index].locked) {
    channelArray = arraySwap(channelArray, index, index - 1);
  }
  channelArray[index].id = channelArray[index].id + 1;
};

export const formatUpdateValue = (objectName, valueName, value) => {
  if (valueName === 'id' && !objectName) {
    value = Number(value);
    if (value === 'NaN') {
      value = '';
    }
  }
  return value;
};

export const resortChannels = (currentChannels, updatedIndex) => {
  const updatedChannel = currentChannels[updatedIndex];

  // Sort array of channels by display number
  currentChannels.sort((a, b) => a.id - b.id);

  // Check for duplicates
  let displayChannelIndexes = findDuplicateDisplayChannels(currentChannels, updatedChannel);

  // If there is a duplicate and the duplicate is locked
  // Increment the changed value by 1 and check for duplicates again
  // Or should we throw an error
  while (displayChannelIndexes.duplicateIndex !== -1 &&
    currentChannels[displayChannelIndexes.duplicateIndex].locked) {
    // Increment Channel Number in master channel list and on current channel
    const incrementedChannel = currentChannels[displayChannelIndexes.originalIndex].id + 1;
    currentChannels[displayChannelIndexes.originalIndex].id = incrementedChannel;
    updatedChannel.id = incrementedChannel;

    // Sort channels again
    currentChannels.sort((a, b) => a.id - b.id);

    // Check for duplicates again
    displayChannelIndexes = findDuplicateDisplayChannels(currentChannels, updatedChannel);
  }

  // if there is a duplicate enter this to increment duplicates
  if (displayChannelIndexes.duplicateIndex !== -1) {
    // Making sure duplicate comes second in the array so it get increment rather than the updated channel
    if (displayChannelIndexes.duplicateIndex < displayChannelIndexes.originalIndex) {
      currentChannels = arraySwap(currentChannels, displayChannelIndexes.duplicateIndex,
        displayChannelIndexes.originalIndex);
      displayChannelIndexes.duplicateIndex = displayChannelIndexes.originalIndex;
    }

    // Increment the display number of the channels following the edited one until no longer necessary
    for (let i = displayChannelIndexes.duplicateIndex; i < currentChannels.length; i++) {
      if (currentChannels[i].id === currentChannels[i - 1].id) {
        incrementChannel(currentChannels, i);
      } else {
        break;
      }
    }
  }
  return currentChannels;
};

export const deepCopyJson = (jsonObj) => JSON.parse(JSON.stringify(jsonObj));

export const formatDisplayName = (channel, channelList) => {
  let displayName = channel.name;

  if (channel.type === CORE || channel.type === PROPERTY || channel.type === SERVER_STREAM) {
    if (!channel.name) {
      displayName = 'Filler Name';
    }
  } else if (channel.ipg && channel.ipg.call_letters !== '' && channel.ipg.call_letters !== null) {
    if (
      !channel.ipg.affiliate ||
      channel.ipg.affiliate === null ||
      channel.ipg.affiliate === undefined ||
      channel.ipg.affiliate === '') {
      displayName = channel.ipg.call_letters;
    } else if (areDuplicateAffiliates(channel.ipg.affiliate, channelList)) {
      displayName = `${channel.ipg.affiliate}-${channel.ipg.call_letters}`;
    } else {
      displayName = `${channel.ipg.affiliate}`;
    }
  }

  return removePipe(displayName);
};

export const areDuplicateAffiliates = (affiliate, channelList) => {
  let affiliateCount = 0;

  for (let i = 0; i < channelList.length; i++) {
    if (channelList[i]?.ipg?.affiliate === affiliate) {
      affiliateCount += 1;
    }
  }

  return affiliateCount > 1;
};

export const formatAllDisplayName = (channelList) => {
  for (let i = 0; i < channelList.length; i++) {
    channelList[i].name = formatDisplayName(channelList[i], channelList);

    if (channelList[i].type === PROPERTY && channelList[i].ipg && channelList[i].ipg.duration === null) {
      channelList[i].ipg.duration = durations[0].value;
    }

    // if(channelList[i].ipg && (channelList[i].ipg.call_letters === null || channelList[i].ipg.call_letters === '')) {
    //   channelList[i].ipg.call_letters = formatDisplayName(channelList[i], channelList);
    // }
  }

  return channelList;
};
