import { Upload } from '@mui/icons-material';
import {
  Button, Dialog, DialogActions, DialogContent, Grid, Step, StepLabel, Stepper, Tooltip
} from '@mui/material';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { SonifiButton, SonifiDivider } from '../../../containers';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { getObjectPromise } from '../../../utils/api';
import { IPG } from '../../Channels2/constants/constants';
import { saveReplacementChannel, stopJobBegin, stopJobComplete } from '../actions/batchChannelsAction';
import { parseSites } from '../utils';
import Finish from './NewBatch/Finish';
import StepOne from './NewBatch/StepOne';
import StepThree from './NewBatch/StepThree';
import StepTwo from './NewBatch/StepTwo';

const steps = ['Sites', 'Channel To Replace', 'Replacement Channels'];
export const providerValue = ['EPG', 'ROVI'];

const blankError = {
  sites: '',
  targetChannel: { number: '', sourceId: '' },
  replacements: ''
};

export class NewBatchDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      error: blankError,
      provider: providerValue[0],
      replacements: [],
      sites: [],
      targetChannel: {
        affiliate: '',
        call_letters: '',
        description: null,
        duration: null,
        entry: null,
        id: '',
        number: null,
        type: IPG
      }
    };
    this.changeState = this.changeState.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  handleNext = () => {
    const errors = JSON.parse(JSON.stringify(blankError));
    let error = false;

    this.setState({
      error: errors
    }, () => {
      if (this.state.activeStep === 0 && this.state.sites.length === 0) {
        errors.sites = this.props.translations.errors.noSites;
        error = true;
      } else if (this.state.sites.filter((site) => site.found && site.platforms.includes('Marina')).length < 1) {
        errors.sites = this.props.translations.errors.noMarinaSites;
        error = true;
      } else if (this.state.activeStep === 1) {
        if (this.state.targetChannel.number === null || this.state.targetChannel.number === '') {
          errors.targetChannel.number = this.props.translations.errors.noNumber;
          error = true;
        }

        if (this.state.targetChannel.id === '') {
          errors.targetChannel.sourceId = this.props.translations.errors.noId;
          error = true;
        }
      } else if (this.state.activeStep === 2 && this.state.replacements.length === 0) {
        errors.replacements = this.props.translations.errors.noReplacements;
        error = true;
      }

      if (!error) {
        this.setState({ activeStep: this.state.activeStep + 1 });
      } else {
        this.setState({ error: errors });
      }
    });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  changeState(name, value) {
    this.setState({ [name]: value, error: blankError });
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.close();
    }
  }

  onSave() {
    this.props.dispatch(saveReplacementChannel(this.state))
      .then((data) => {
        if (data) {
          this.props.close();
        }
      });
  }

  getPageDetails() {
    const { activeStep } = this.state;
    return (
      activeStep === steps.length
        ? <Finish currentState={this.state} />
        : <Grid container>
          <Grid item xs={12}>
            {activeStep === 0 && <StepOne currentState={this.state} changeState={this.changeState} />}
            {activeStep === 1 &&
              <StepTwo currentState={this.state} changeState={this.changeState} providerValue={providerValue} />}
            {activeStep === 2 &&
              <StepThree currentState={this.state} changeState={this.changeState} providerValue={providerValue} />}
          </Grid>
        </Grid>
    );
  }

  handleOnSubmit = (e) => {
    this.props.dispatch(stopJobBegin());
    e.preventDefault();

    const reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const sites = parseSites(wb);

      const levels = sites.map((site) => getObjectPromise('sites', site.id)
        .then((data) => data)
        .catch((error) => {
          console.log('Error-3: ', error);
        }));

      Promise.all(levels).then((responses) => {
        const foundSites = responses.filter((element) => element !== undefined);
        for (let i = 0; i < foundSites.length; i++) {
          const site = find(sites, { id: foundSites[i].id });
          site.found = true;
          site.name = foundSites[i].name;
          site.platforms = foundSites[i].platforms;
        }

        this.setState({ sites: [...this.state.sites, ...sites], error: blankError });
        this.props.dispatch(stopJobComplete());
      });
    };
    reader.readAsBinaryString(e.target.files[0]);
  };

  render() {
    const {
      globalTranslations, translations
    } = this.props;

    const { activeStep } = this.state;

    return (
      <Dialog open={true} onClose={this.onCancel} fullWidth maxWidth="lg" PaperProps={{ sx: { height: '100%' } }}>
        <SonifiModalHeader
          header={translations.title}
          label={globalTranslations.save}
          onCancel={this.onCancel}
          onSubmit={this.onSave}
          onlyClose={activeStep !== steps.length}
        />
        <DialogContent style={{ overflow: 'hidden' }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <SonifiDivider />
          <Grid container style={{ height: '97%' }}>
            <SonifiTemplate
              header={steps[activeStep]}
              pageDetails={this.getPageDetails()}
              middleExtra={
                <Tooltip title={translations.toolTip} >
                  <Button component="label"
                    variant="text"
                    style={{ visibility: activeStep === 0 ? 'visible' : 'hidden' }}
                    color="secondary">
                    <Upload /> Upload
                    <input hidden accept=".xlsx, .xls, .csv" multiple type="file" onChange={this.handleOnSubmit} />
                  </Button>
                </Tooltip>
              }
            />
          </Grid>

        </DialogContent>
        <DialogActions>
          <SonifiButton
            caption={globalTranslations.back}
            onClick={this.handleBack}
            secondary
            disabled={activeStep === 0}
          />
          <SonifiButton
            caption={globalTranslations.next}
            onClick={this.handleNext}
            disabled={activeStep === steps.length}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  translations: state.batchChannels.translations.addDialog
});

NewBatchDialog.propTypes = {
  close: PropTypes.func,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(NewBatchDialog);
