import { LOAD_LANGUAGE_LITERALS } from '../../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../../constants/constants';
import { getTranslationObject } from '../../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  loading: false,
  logs: [],
  maxPages: 0,
  pageSize: 0,
  selectedLog: null,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  totalItems: 0,
  translations: null
};

export default function pmsLoggingReducer(state = initialState, action) {
  let miscArray = null;

  switch (action.type) {
    case types.RESET_PMS_LOGGING:
      return {
        ...state,
        loading: true,
        logs: [],
        maxPages: 0,
        pageSize: 0,
        selectedLog: null,
        snackBarMessage: '',
        totalItems: 0
      };

    case types.GET_PMS_LOGGING_DATA_BEGIN:
      return { ...state, snackBarMessage: '' };

    case types.GET_PMS_LOGGING_DATA_SUCCESS:
      miscArray = state.logs ? [...state.logs, ...action.logs.messages] : [...action.logs.messages];
      return {
        ...state,
        loading: false,
        logs: miscArray, // action.logs.messages,
        maxPages: action.logs.page_count,
        pageSize: action.logs.page_size,
        totalItems: action.logs.total_items
      };

    case types.GET_PMS_LOGGING_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        maxPages: 0,
        pageSize: 0,
        snackBarMessage: `${state.translations.grid.errors.fetchError} ${action.error}`,
        snackBarType: ERROR,
        totalItems: 0
      };

    case types.VIEW_PMS_LOG:
      return { ...state, selectedLog: action.log, snackBarMessage: '' };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
