import { AccountCircle } from '@mui/icons-material';
import {
  AppBar, Grid, Toolbar
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import { getSite, updateChange } from '../../actions/globalActions';
import { USER_GROUPS } from '../../constants/apiEndpoints';
import {
  ADMIN_ROLES, FIRMWARE_ROLES, SOFTWARE_ROLES, SONIFI_ROLES, USER_ACCOUNT_ROLES
} from '../../constants/roleGroups';
import { SONIFI_ADMIN } from '../../constants/roles';
import SonifiButton from '../../containers/SonifiButton';
import SonifiConfirm from '../../containers/SonifiConfirm';
import SonifiIconButton from '../../containers/SonifiIconButton';
import SonifiTextAsync from '../../containers/SonifiTextAsync';
import Logo from '../../img/SONIFI_Logo_NoTag_Header.png';
import { logOut } from '../../utils';
import { getObjectPromise } from '../../utils/api';
import { checkForAtLeastOneUserPermission } from '../../utils/rolesUtil';
import { setSiteId } from '../../v2/GlobalSlices/siteSlice';
import TopMenu from './TopMenu';

const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexShrink: 0,
    flexGrow: 0,
  },
  toolbar: {
    backgroundColor: theme.palette.defaults.defaultBackground,
    color: theme.palette.primary.main,
    height: '70px'
  },
  link: {
    textDecoration: 'none',
    float: 'right'
  },
  profile: {
    float: 'right',
    paddingTop: '5px'
  },
  displayGrid: {
    display: 'flex',
    alignItems: 'center'
  }
});

class PrimarySearchAppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      showSearch: false,
      siteSelected: false
    };
  }

  componentDidMount() {
    if (this.props.userGroup !== null) {
      getObjectPromise(USER_GROUPS, this.props.userGroup, { limit: 5 })
        .then((data) => {
          if ((data.sites.length === 1 || this.props.userAssignedSites[0] !== '*') &&
            this.props.userAssignedSites.length === 1) {
            this.props.dispatch(getSite(this.props.userAssignedSites[0]));
            this.setState({ showSearch: false });
            this.props.dispatch(setSiteId(this.props.userAssignedSites[0]));
            this.setState({ siteSelected: true });
          } else {
            this.setState({ showSearch: true });
          }
        }).catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({ showSearch: true });
    }
  }

  componentDidUpdate() {
    if (this.state.siteSelected) {
      this.setState({ siteSelected: false });
    }
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleChange = (option) => {
    if (option && option.value && option.value.id) {
      this.props.dispatch(setSiteId(option.value.id));
      this.setState({ siteSelected: true });
    }
  };

  // function for filter async
  createFilterObj = (inputData) => ({
    search: inputData,
    limit: 20
  });

  // function for filter async
  createDisplayObj = (site) => ({
    value: site,
    label: `${site.id} ${(site.name ? `: ${site.name}` : '')}`,
  });

  confirmDialogConfirmFunc() {
    this.props.dispatch({ type: 'USER_LOGGED_OUT' });
    logOut();
  }

  handleActivate = () => {
    this.props.dispatch(updateChange());
  };

  render() {
    console.log(this.props);
    const { anchorEl, showSearch, siteSelected } = this.state;
    const {
      classes, globalError, translations, globalTranslations, userPermissions, siteId, siteName, siteDisplayName
    } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const siteLink = siteId ? `/site/${siteId}` : '/site';

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.toolbar}>
            {siteSelected && siteId && (
              <Navigate to={`/site/${siteId}`} />
            )}
            <Grid container spacing={8}>
              <Grid item xs={2} className={classes.displayGrid}>
                <img
                  src={Logo}
                  alt="SONIFI logo"
                  style={{ width: 135 }}
                />
              </Grid>
              <Grid item xs={2} className={classes.displayGrid}>
                {/* Update toggle permission based on tab permissioins */}
                {(checkForAtLeastOneUserPermission(ADMIN_ROLES, userPermissions) ||
                  checkForAtLeastOneUserPermission(
                    [...SOFTWARE_ROLES, ...USER_ACCOUNT_ROLES, SONIFI_ADMIN],
                    userPermissions
                  ) ||
                  checkForAtLeastOneUserPermission([...FIRMWARE_ROLES, ...SONIFI_ROLES], userPermissions) ||
                  checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)) &&
                  <Fragment>
                    <Link to="/global" style={{ textDecoration: 'none' }}>
                      <SonifiButton caption="Global" onClick={(() => this.handleActivate())}
                        secondary={!window.location.pathname.startsWith('/global')} marginTop noLeftRightMargin />
                    </Link>
                    <Link to={siteLink} style={{ textDecoration: 'none' }}>
                      <SonifiButton caption="Site" onClick={(() => this.handleActivate())}
                        secondary={!window.location.pathname.startsWith('/site')} marginTop noLeftRightMargin />
                    </Link>
                  </Fragment>}
                {!checkForAtLeastOneUserPermission(ADMIN_ROLES, userPermissions) &&
                  window.location.pathname.startsWith('/user') &&
                  <Link to="/site" style={{ textDecoration: 'none' }}>
                    <SonifiButton caption="Site" onClick={(() => this.handleActivate())}
                      secondary={!window.location.pathname.startsWith('/site')} marginTop noLeftRightMargin />
                  </Link>}
              </Grid>
              <Grid item xs={5} className={classes.displayGrid}>
                {window.location.pathname.startsWith('/site') &&
                  <SonifiTextAsync
                    dataName="sites"
                    defaultValue={siteName}
                    disabled={!showSearch}
                    displayFunc={this.createDisplayObj}
                    filterFunc={this.createFilterObj}
                    onSelect={this.handleChange}
                    placeholder={siteDisplayName || translations.searchBar.title}
                    resource="sites"
                    siteObj={false}
                    type="site"
                    dataTest="siteSearchBar"
                  />}
              </Grid>
              <Grid item xs={3}>
                <div className={classes.profile}>
                  <SonifiIconButton
                    onClick={this.handleProfileMenuOpen}
                    icon={<AccountCircle />}
                    label="&nbsp;"
                    testLabel="profileIconButton"
                  />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <TopMenu anchorEl={anchorEl} open={isMenuOpen} close={this.handleMenuClose} />
        <SonifiConfirm
          dialogOpen={!!(globalError && globalError.length > 0)}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
          hideCancel={true}
          confirmTitle={translations.globalError}
          confirmText={globalError}
          buttonConfirmText={globalTranslations.ok}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  state,
  change: state.global.change,
  globalError: state.global.globalError,
  globalTranslations: state.global.translations.defaults,
  translations: state.global.translations.tabs,
  userAssignedSites: state.global.userAssignedSites,
  userGroup: state.global.group,
  userPermissions: state.global.permissions,
  siteId: state.v2.site.id,
  siteName: state.v2.site.name,
  siteDisplayName: state.v2.site.display_name
});


PrimarySearchAppBar.propTypes = {
  state: PropTypes.object,
  change: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  globalError: PropTypes.string,
  globalTranslations: PropTypes.object,
  translations: PropTypes.object,
  userAssignedSites: PropTypes.array,
  userGroup: PropTypes.string,
  userPermissions: PropTypes.array,
  siteId: PropTypes.string,
  siteName: PropTypes.string,
  siteDisplayName: PropTypes.string
};

export default connect(mapStateToProps)(withStyles(PrimarySearchAppBar, styles, { withTheme: true }));
