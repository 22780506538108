import { findIndex } from 'lodash';
import { USERS } from '../../../constants/apiEndpoints';
import { getObjectPromise } from '../../../utils/api';
import { textHelper } from '../../../utils/textUtil';

export function validUser(user, userRoles, groups, translations, globalTranslation) {
  const errors = {};

  const givenNameError = checkName('given_name', user, translations, globalTranslation);
  if (givenNameError !== null) {
    errors.given_name = givenNameError;
  }

  const familyNameError = checkName('family_name', user, translations, globalTranslation);
  if (familyNameError !== null) {
    errors.family_name = familyNameError;
  }

  if (user.role === null || user.role === undefined || user.role === '') {
    errors.role = translations.role; // 'Need a role';
  } else if (findIndex(userRoles, (o) => o.id === user.role) === -1) {
    errors.role = translations.role; // 'Need to pick a role defined by us';
  }

  if (user.group === null || user.group === undefined || user.group === '') {
    errors.group = translations.group; // 'Need a group';
  } else if (user.group !== null && user.group !== undefined && user.group !== '') {
    if (findIndex(groups, (o) => o.id === user.group) === -1) {
      errors.group = translations.group; // 'Need to pick a group defined by us';
    }
  }

  if (!user.sites || user.sites.length < 1) {
    errors.sites = translations.needSites;
  }

  const checkEmailError = checkName('email', user, translations, globalTranslation);
  if (checkEmailError !== null) {
    errors.email = checkEmailError;
  } else {
    const validEmail = validateEmail(user.email, translations);
    if (validEmail !== null) {
      errors.email = validEmail;
    }
  }

  return errors;
}

function validateEmail(inputText, translations) {
  const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const sonifiFormat = /^\w+([.-]?\w+)*@(sonifi)+(\.\w{2,3})+$/;

  if (inputText.match(sonifiFormat)) {
    return translations.sonifiEmail;
  }

  if (inputText.match(mailformat)) {
    return null;
  }
  return translations.invalidEmail;
}

function checkName(fieldName, user, translations, globalTranslation) {
  if (user[fieldName] === null || user[fieldName] === undefined || user[fieldName].trim() === '') {
    return translations[fieldName];
  } else if (textHelper(user[fieldName].trim())) {
    return globalTranslation.symbols;
  }
  return null;
}

export function validId(email, translations) {
  return new Promise((resolve, reject) => {
    const promises = [];
    promises.push(getObjectPromise(USERS, email)
      .then(() => {
        resolve({ message: translations.inUse });
      }).catch((error) => {
        reject(error);
      }));
  });
}
