import { cloneDeep, orderBy } from 'lodash';
import * as types from '../actions/globalActionTypes';
import enAdmin from '../components/Global/i18n/en';
import enTopMenu from '../components/Layouts/i18n/en';
import esTopMenu from '../components/Layouts/i18n/es';
import enDashboard from '../components/Site/i18n/dashboard_en';
import enProduct from '../components/Site/i18n/product_en';
import enReport from '../components/Site/i18n/reporting_en';
import enSystem from '../components/Site/i18n/system_en';
import { RESET_GLOBAL_SNACKBAR } from '../components/SiteManagement/actions/actionTypes';
import { ERROR, HEALTHCARE, MARINA, SUCCESS } from '../constants/constants';
import en from '../i18n/en';
import es from '../i18n/es';
import { findArrayIdByKey, mergeDeep } from '../utils/index';

const langs = { en, es };
const langDashboard = { enDashboard };
const langSystem = { enSystem };
const langProduct = { enProduct };
const langReporting = { enReport };
const langAdmin = { enAdmin };
const langTopMenu = { enTopMenu, esTopMenu };

const initialState = {
  buildings: null,
  change: false,
  currentLanguage: 'en',
  defaultLanguage: 'en',
  globalError: '',
  globalSnackBarMessage: '',
  globalSnackBarOpen: false,
  globalSnackBarType: SUCCESS,
  group: null,
  isHealthcareSite: false,
  isProd: true,
  labMode: false,
  loadingSite: true,
  marinaOnlySite: false,
  permissions: [],
  role: null,
  savingNetwork: false,
  selectableRooms: [],
  site: { product_parents: [] },
  siteError: null,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  systemError: null,
  systems: null,
  tabSelected: '/',
  translations: getTranslationObject('en'),
  user: null,
  userAssignedSites: null
};

/* eslint-disable max-lines-per-function */
export default function globalReducer(state = initialState, action) {
  const universalArray = [];

  let universalVariable = null,
    buildings = null,
    floors = null,
    rooms = null;

  switch (action.type) {
    case 'DESTROY_SESSION':
      return { ...state, user: null };

    case types.CHANGE:
      return { ...state, change: !state.change };
    case types.GET_SYSTEM_SCHEDULE_BEGIN:
      return {
        ...state,
        languages: null,
        menusets: null,
        schedError: null
      };
    case types.GET_SYSTEM_SCHEDULE_SUCCESS:
      if (action.systems.schedule.languages === undefined || action.systems.schedule.languages.length < 1) {
        return {
          ...state,
          languages: [{
            sequence: 1, iso_code_639_1: 'en', iso_code_639_2: 'eng', name: 'English', natural_name: 'English'
          }],
          menusets: [{ id: 0, name: 'None' }]
        };
      }
      action.systems.schedule.menusets.push({ id: 0, name: 'None' });
      return {
        ...state,
        languages: orderBy(
          action.systems.schedule.languages,
          [(a) => a.sequence],
          ['asc']
        ),
        menusets: orderBy(
          action.systems.schedule.menusets,
          [(a) => a.id],
          ['asc']
        )
      };
    case types.GET_SYSTEM_SCHEDULE_ERROR:
      return {
        ...state,
        languages: [{
          sequence: 1, iso_code_639_1: 'en', iso_code_639_2: 'eng', name: 'English', natural_name: 'English'
        }],
        menusets: [{ id: 0, name: 'None' }],
        schedError: action.error
      };
    case types.SET_PROD_STATUS:
      return {
        ...state,
        isProd: !(window.location.hostname === 'localhost' ||
          window.location.hostname === 'cca-devel.sonifi.cloud' ||
          window.location.hostname === 'cca-test.sonifi.cloud' ||
          window.location.hostname === 'signin-devel.sonifi.cloud' ||
          window.location.hostname === 'signin-test.sonifi.cloud')
      };
    case types.SET_TAB_SELECTED:

      return {
        ...state,
        tabSelected: action.tabSelected,
        change: !state.change
      };
    case types.UPDATE_PRODUCTION_STATUS:
      return {
        ...state,
        isProd: !state.isProd
      };
    case types.UPDATE_LANGUAGES:
      return {
        ...state,
        languages: [...action.payload.languages],
      };

    case types.UPDATE_SITE: {
      return {
        ...state,
        change: !state.change,
        site: action.siteInfo,
      };
    }

    case types.UPDATE_ROLE: {
      // This code is to remove a role to test your screen without the permission
      // const index = action.payload.role.roles.findIndex((rolex) => rolex === 'KeyStrokeRouter-Edit');
      // action.payload.role.roles[index] = 'nothing';
      return {
        ...state,
        role: null,
        permissions: action.role.permissions,
        change: !state.change
      };
    }

    case types.UPDATE_GROUP: {
      return {
        ...state,
        group: action.group,
        change: !state.change
      };
    }

    case types.UPDATE_SITES: {
      return {
        ...state,
        userAssignedSites: action.sites,
        change: !state.change
      };
    }

    case types.LOAD_LANGUAGE_LITERALS:
      return {
        ...state,
        translations: getTranslationObject(action.lang),
        currentLanguage: action.lang,
        globalError: ''
      };

    case types.LOAD_START_HIERARCHY_BEGIN:
      return {
        ...state,
        loadingSite: true,
        buildings: null
      };

    case types.HIERARCHY_LOADED:
      buildings = state.buildings;
      if (!action.showDeadBranches) {
        universalVariable = cloneDeep(state.buildings);
        buildings = removeEmpty(universalVariable);
      }
      return {
        ...state,
        loadingSite: false,
        buildings,
        change: !state.change
      };

    case types.LOAD_START_HIERARCHY_SUCCESS:
      if (!action.buildings || action.buildings.length < 1) {
        return { ...state, buildings: [] };
      }

      for (let j = 0; j < action.buildings.length; j++) {
        universalVariable = {
          key: `${action.buildings[j].name}`,
          title: `${action.buildings[j].name}`,
          type: 'building',
          canEdit: true
        };
        if (action.buildings[j].floors && action.buildings[j].floors !== null) {
          for (let k = 0; k < action.buildings[j].floors.length; k++) {
            if (!universalVariable.treeData) {
              universalVariable.treeData = [];
            }

            universalVariable.treeData.push({
              key: `${action.buildings[j].name}/${action.buildings[j].floors[k].name}`,
              title: action.buildings[j].floors[k].name,
              treeData: rooms,
              type: 'floor',
              canEdit: true
            });
          }
        }
        universalArray.push(universalVariable);
      }

      return {
        ...state,
        buildings: [{
          key: 'Whole Site',
          title: 'Whole Site',
          type: 'site',
          treeData: universalArray,
          canEdit: false
        }],

        selectableRooms: []
      };

    case types.LOAD_HIERARCHY_ROOMS_SUCCESS: {
      if (!action.rooms || action.rooms.length === 0) {
        return state;
      }
      const selectableRooms = [];
      universalVariable = [...state.buildings];

      buildings = findArrayIdByKey(universalVariable[0].treeData, 'key', action.building);
      floors = findArrayIdByKey(universalVariable[0].treeData[buildings].treeData, 'title', action.floor);

      rooms = [];
      for (let m = 0; m < action.rooms.length; m++) {
        selectableRooms.push(action.rooms[m].id);
        rooms.push({
          key: `${action.rooms[m].id}`,
          title: action.rooms[m].id,
          type: 'room'
        });
      }

      universalVariable[0].treeData[buildings].canEdit = false;
      universalVariable[0].treeData[buildings].treeData[floors].canEdit = false;
      universalVariable[0].treeData[buildings].treeData[floors].treeData = rooms;

      return {
        ...state,
        buildings: universalVariable,
        selectableRooms: orderBy(
          [...state.selectableRooms, ...selectableRooms],
          [(a) => a.toLowerCase()],
          ['asc']
        )
      };
    }

    case types.UPDATE_CURRENT_USER:
      return {
        ...state,
        group: action.token['custom:group'],
        user: {
          email_verified: !!(action.token.email_verified),
          email: action.token.email,
          family_name: action.token.family_name,
          given_name: action.token.given_name,
          group: action.token['custom:group'],
          phone_number_verified: !!(action.token.phone_number_verified),
          phone_number: action.token.phone_number || '',
          role: action.token['custom:role'],
          sites: (action.token['custom:sites'] ? action.token['custom:sites'].split(' ') : [])
        }
      };

    case types.UPDATE_PROFILE: {
      const newUser = {
        ...state.user,
        family_name: action.token.family_name,
        given_name: action.token.given_name,
        phone_number: action.token.phone_number || '',
      };

      return {
        ...state,
        user: newUser
      };
    }

    case types.UPDATE_GLOBAL_ERROR:
      return {
        ...state,
        globalError: action.error
      };

    case types.GET_SITE_BEGIN:
      return { ...state, loadingSite: true };
    case types.GET_SITE_FAILURE:
      return { ...state, siteError: action.error, loadingSite: false };
    case types.GET_SITE_SUCCESS:
      universalVariable = { ...action.site };
      if (action.site.industry === HEALTHCARE && universalVariable.hl7 === null) {
        universalVariable.hl7 = {
          subnets: [],
          port: 0
        };
      }
      return {
        ...state,
        isHealthcareSite: action.site.industry === HEALTHCARE,
        site: universalVariable,
        marinaOnlySite: action.site.platforms &&
          action.site.platforms.length === 1 &&
          action.site.platforms[0].toUpperCase() === MARINA,
        labMode: action.site.is_lab ? action.site.is_lab : false,
        loadingSite: false
      };
    case types.RESET_LOADING_SITE:
      return { ...state, loadingSite: false };

    case types.PATCH_SITE_BEGIN:
      return { ...state, savingNetwork: true, snackBarMessage: '' };
    case types.PATCH_SITE_FAILURE:
      return {
        ...state,
        savingNetwork: false,
        snackBarMessage: `${state.translations.snackBar.patchError} - ${action.error}`,
        snackBarType: ERROR
      };
    case types.PATCH_SITE_SUCCESS:
      return {
        ...state,
        savingNetwork: false,
        snackBarMessage: `${state.translations.snackBar.patchSuccess}`,
        snackBarType: SUCCESS
      };

    case RESET_GLOBAL_SNACKBAR:
      return { ...state, snackBarMessage: '' };

    case types.CLOSE_SNACK_BAR:
      return {
        ...state,
        globalSnackBarOpen: false,
        globalSnackBarMessage: '',
        // globalSnackBarType: SUCCESS,
      };

    case types.UPDATE_SNACK_BAR:
      return {
        ...state,
        globalSnackBarOpen: !!(action.message && action.message.length > 0),
        globalSnackBarMessage: action.message,
        globalSnackBarType: action.snackbarType
      };

    default:
      return state;
  }
}

export function getTranslationObject(language) {
  if (language === 'en') {
    return {
      ...langs.en,
      ...langDashboard.enDashboard,
      ...langSystem.enSystem,
      ...langProduct.enProduct,
      ...langReporting.enReport,
      ...langAdmin.enAdmin,
      ...langTopMenu.enTopMenu
    };
  } else {
    const english = JSON.parse(JSON.stringify({ ...langs.en }));
    const englishDashboard = JSON.parse(JSON.stringify({ ...langDashboard.enDashboard }));
    const englishSystem = JSON.parse(JSON.stringify({ ...langSystem.enSystem }));
    const englishProduct = JSON.parse(JSON.stringify({ ...langProduct.enProduct }));
    const englishReporting = JSON.parse(JSON.stringify({ ...langReporting.enReport }));
    const englishAdmin = JSON.parse(JSON.stringify({ ...langAdmin.enAdmin }));
    const englishTopMenu = JSON.parse(JSON.stringify({ ...langTopMenu.enTopMenu }));

    const glob = mergeDeep(english, { ...langs[language] });
    const dashboard = mergeDeep(englishDashboard, { ...langDashboard[`${language}Dashboard`] });
    const system = mergeDeep(englishSystem, { ...langSystem[`${language}System`] });
    const product = mergeDeep(englishProduct, { ...langProduct[`${language}Product`] });
    const reporting = mergeDeep(englishReporting, { ...langReporting[`${language}Product`] });
    const admin = mergeDeep(englishAdmin, { ...langAdmin[`${language}Admin`] });
    const topMenu = mergeDeep(englishTopMenu, { ...langTopMenu[`${language}TopMenu`] });

    return {
      ...glob,
      ...dashboard,
      ...system,
      ...product,
      ...reporting,
      ...admin,
      ...topMenu
    };
  }
}

function removeEmpty(hierarchy) {
  for (let i = hierarchy[0].treeData.length - 1; i >= 0; i--) {
    let shouldKeep = false;
    for (let j = hierarchy[0].treeData[i].treeData.length - 1; j >= 0; j--) {
      if (hierarchy[0].treeData[i].treeData[j].treeData !== null) {
        shouldKeep = true;
      } else {
        hierarchy[0].treeData[i].treeData.splice(j, 1);
      }
    }
    if (!shouldKeep) {
      hierarchy[0].treeData.splice(i, 1);
    }
  }

  return hierarchy;
}
