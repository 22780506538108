import { find } from 'lodash';
import { TERM_MODELS } from '../../../constants/apiEndpoints';
import { getObjectPromise } from '../../../utils/api';
import { textHelper } from '../../../utils/textUtil';
import { makes } from '../constants/index';

export function isValidTermModel(termMod, models, translations, globalTranslations) {
  const errors = {};
  const promises = [];

  if (termMod.id.length === 0) {
    errors.id = translations.needAModelNum;
  } else if (textHelper(termMod.id)) {
    errors.id = globalTranslations.symbols;

    // } else if (find(models, (o) => o.id === termMod.id) !== undefined) {
    //   errors.id = translations.duplicateModel;
  }

  if (termMod.make === 0 || find(makes, (o) => o.value === termMod.make) === undefined) {
    errors.make = translations.needAType;
  }

  promises.push(validateId(termMod.id, translations).catch((e) => e));

  return Promise.all(promises)
    .then((data) => {
      if (data[0]) {
        errors.id = translations.duplicateModel;
      }
      return errors;
    })
    .catch((error) => {
      console.log('Term Model Dialog - Error in executing', error);
      return errors;
    });
}

export function validateId(fieldName) {
  return getObjectPromise(TERM_MODELS, encodeURIComponent(fieldName))
    .then(() => true)
    .catch(() => false);
}
