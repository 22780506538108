import { Add, Search } from '@mui/icons-material';
import {
  Grid, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { DISTANCE_FROM_END } from '../../../constants/magic';
import { FIRMWARE_ROLES } from '../../../constants/roleGroups';
import { FIRMWARE_EDIT } from '../../../constants/roles';
import {
  SonifiConfirm, SonifiLabel, SonifiLockoutModalSpinner, SonifiSnackBar,
  SonifiSpinner, SonifiTableHead, SonifiText
} from '../../../containers';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { buildSortString } from '../../../utils';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../../utils/rolesUtil';
import {
  addFirmware, clearFirmware, deleteFirmwareById, fetchFirmware, getFirmwareRegex, resetSnackBar,
  startFirmware, updateDeleteRow, updateSelectedFirmware
} from '../actions/firmwareActions';
import { termTypes } from '../constants/constants';
import FirmwareRow from '../containers/FirmwareRow';
import FirmwareDialog from './FirmwareDialog';

export class FirmwareGrid extends Component {
  state = {
    filter: '',
    firstload: true,
    limit: 20,
    order: 'asc',
    orderBy: 'id',
    page: 1,
    selectedMake: 'all'
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(startFirmware());
    setTimeout(() => {
      dispatch(fetchFirmware(this.buildQueryStringObject()));
    }, 200);

    this.setState({ firstload: false });
    dispatch(getFirmwareRegex());
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(fetchFirmware(this.buildQueryStringObject()));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(fetchFirmware(this.buildQueryStringObject()));
    }
  }

  handleRequestSort = (property) => {
    this.props.dispatch(updateSelectedFirmware(-1));
    this.props.dispatch(clearFirmware());
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    });
  };

  buildQueryStringObject() {
    const queryString = { ...this.state };
    if (this.state.selectedMake !== 'all') {
      queryString.make = this.state.selectedMake;
    }
    return buildSortString(queryString);
  }

  reset(deleteId) {
    const { dispatch } = this.props;
    this.setState({
      page: 1,
      filter: ''
    }, () => {
      if (deleteId) {
        dispatch(deleteFirmwareById(deleteId, this.buildQueryStringObject()));
      }
    });
  }

  confirmDialogConfirmFunc() {
    const { deleteFirmware } = this.props;
    this.reset(deleteFirmware.id);
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteRow(null));
  }

  addFirmware() {
    this.props.dispatch(addFirmware());
  }

  closeSnackBar() {
    this.props.dispatch(resetSnackBar('', ''));
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  filterFirmware = ({ target: { value } }) => {
    this.setState({ filter: value, page: 1 }, () => {
      this.debouncedLoadMoreData();
    });
  };

  filterData() {
    this.props.dispatch(clearFirmware());
    this.props.dispatch(fetchFirmware(this.buildQueryStringObject()));
  }

  makeEdit = ({ target: { value } }) => {
    this.setState({
      page: 1, selectedMake: value
    }, () => {
      this.debouncedLoadMoreData();
    });
  };

  getPageDetails() {
    const {
      filter, firstload, order, orderBy, page, selectedMake
    } = this.state;
    const {
      firmware, firmwareError, globalTranslations, loading, maxPages,
      saving, sorting, translations, userPermissions
    } = this.props;

    const canEdit = checkForSingleUserPermission(FIRMWARE_EDIT, userPermissions);

    if (loading || saving) {
      return <SonifiSpinner />;
    }

    const tableHeader = [
      {
        id: 'active', sortable: firmware.length > 1, numeric: false, label: `${translations.active}`, narrow: true
      },
      { id: 'make', sortable: firmware.length > 1, numeric: false, label: `${translations.make}` },
      { id: 'type', sortable: firmware.length > 1, numeric: false, label: `${translations.type}` },
      { id: 'version', sortable: firmware.length > 1, numeric: false, label: `${translations.version}` },
      { id: 'id', sortable: firmware.length > 1, numeric: false, label: `${translations.name}` },
      { id: 'model', sortable: false, numeric: false, label: `${translations.model}` }
    ];

    return (
      <Grid container style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <SonifiText
            defaultValue={selectedMake}
            change={this.makeEdit}
            label={translations.make}
            size="mdNoPad"
            select={true}
            items={termTypes}
          />
        </Grid>
        <Grid item>
          <SonifiText
            label={globalTranslations.search}
            defaultValue={filter}
            change={this.filterFirmware}
            icon={<Search />}
            iconPosition="end"
            size="mdNoPad"
          />
        </Grid>
        <Grid item xs={12}>
          <Table stickyHeader={true}>
            <SonifiTableHead
              headColumns={tableHeader}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            <TableBody>
              {(!firmware || firmware.length === 0)
                ? <TableRow>
                  <TableCell colSpan={6}>
                    {sorting
                      ? <SonifiSpinner />
                      : <SonifiLabel error label={(firstload
                        ? translations.errors.noFirmware
                        : translations.errors.noMatch)} />}
                  </TableCell>
                </TableRow>
                : firmware.map((option, index) => (
                  <Fragment key={`w_${index}`}>
                    <FirmwareRow key={index} rowIndex={index} canEdit={canEdit}
                      deleteEnabled={canEdit && !loading && !firmwareError} />

                    {index === firmware.length - DISTANCE_FROM_END && page < maxPages && (
                      <TableRow>
                        <TableCell>
                          <Waypoint onEnter={() => { this.getMoreData(); }} />
                        </TableCell>
                      </TableRow>)
                    }
                  </Fragment>
                ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      addingFirmware, deleteFirmware, firmware, firmwareError, globalTranslations, loading, popupLoading,
      saving, selectedFirmware, snackBarMessage, snackBarType, translations, userPermissions, uploadStarted
    } = this.props;

    const canEdit = checkForSingleUserPermission(FIRMWARE_EDIT, userPermissions);

    if (!checkForAtLeastOneUserPermission(FIRMWARE_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={popupLoading || saving || addingFirmware || uploadStarted !== null} />
        <SonifiConfirm
          dialogOpen={deleteFirmware !== null && deleteFirmware !== undefined && !popupLoading}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          confirmTitle={translations.deleteDialog.deleteTitle}
          confirmText={`${translations.deleteDialog.deleteText} ${(deleteFirmware ? deleteFirmware.id : '')}?`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
        <SonifiTemplate
          header={translations.title}
          onSubmit={this.addFirmware.bind(this)}
          showButton={canEdit && !firmwareError && !loading}
          label={globalTranslations.add}
          icon={<Add />}
          pageDetails={this.getPageDetails()}
        />
        {selectedFirmware !== -1 && firmware.length > 0 &&
          <FirmwareDialog canEdit={canEdit} reset={this.reset.bind(this)}
            sort={`${this.state.orderBy}:${this.state.order}`} />}
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  addingFirmware: state.firmware.addingFirmware,
  deleteFirmware: state.firmware.deleteFirmware,
  firmware: state.firmware.firmware,
  firmwareError: state.firmware.firmwareError,
  globalTranslations: state.global.translations.defaults,
  loading: state.firmware.loading,
  maxPages: state.firmware.maxPages,
  popupLoading: state.firmware.popupLoading,
  saving: state.firmware.saving,
  selectedFirmware: state.firmware.selectedFirmware,
  snackBarMessage: state.firmware.snackBarMessage,
  snackBarType: state.firmware.snackBarType,
  sorting: state.firmware.sorting,
  translations: state.firmware.translations.grid,
  uploadStarted: state.firmware.uploadStarted,
  userPermissions: state.global.permissions
});

FirmwareGrid.propTypes = {
  addingFirmware: PropTypes.bool,
  deleteFirmware: PropTypes.object,
  dispatch: PropTypes.func,
  firmware: PropTypes.array,
  firmwareError: PropTypes.bool,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  maxPages: PropTypes.number,
  popupLoading: PropTypes.bool,
  saving: PropTypes.bool,
  selectedFirmware: PropTypes.number,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  sorting: PropTypes.bool,
  translations: PropTypes.object,
  uploadStarted: PropTypes.number,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(FirmwareGrid);
