import PropTypes from 'prop-types';
import { Component } from 'react';

// Code was influenced by:
// http://dev.to/flexdinesh/cache-busting-a-react-app-22lk

// Here's the strategy of this class.  When the user logs in, we will compare the value
// in local storage vs the value passed in as an environment variable (REACT_APP_CCA_VERSION).
// If they do not match, set the value, clear the cache, and force a refresh.

class CacheBuster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log('Clearing cache and hard reloading...');
        try {
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then((names) => {
              for (const name of names) {
                caches.delete(name);
              }
            });
          }
        } catch(err) {
          console.log('Cache Error', err);
        }

        // set local storage to current value
        localStorage.setItem('version', process.env.REACT_APP_CCA_VERSION);

        // delete browser cache and hard reload
        window.location.reload(true);
      }
    };
  }

  componentDidMount() {
    // get version from local storage
    const localVersion = localStorage.getItem('version');

    console.log('BUILD VERSION: ', process.env.REACT_APP_CCA_VERSION, localVersion);

    if (localVersion === 'Local Debug') {
      console.log('VERSION STORED IN LOCAL STORAGE:  ', localVersion);
      console.log('CacheBuster:process:  ', process);
      console.log('CacheBuster:process.env:  ', process.env);
    }

    const isCurrentVersion = localVersion === process.env.REACT_APP_CCA_VERSION;
    if (isCurrentVersion) {
      console.log(`You already have the latest version - ${localVersion}. No cache refresh needed.`);
      this.setState({ loading: false, isLatestVersion: true });
    } else {
      console.log(`We have a new version - ${localVersion}. Should force refresh`);
      this.setState({ loading: false, isLatestVersion: false });
    }
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}

CacheBuster.propTypes = {
  children: PropTypes.func
};

export default CacheBuster;
