import { textHelper } from '../../../utils/textUtil';
import {
  ANALOG_MOD_MAX, AUX, BINARY_ALL_ONES, DIGI_MOD_MAX, IPG, MAX_CHANNEL_NUMBER,
  MAX_DESC_COUNT, MAX_FREQUENCY_KHZ, MAX_FREQUENCY_MHZ, MAX_IP, MAX_TITLE_COUNT,
  MIN_FREQUENCY_KHZ, MIN_FREQUENCY_MHZ
} from '../constants/constants';
import { hasValue, inRange, isNumber } from './ValidatorRules';

// #region Top Level Validation
export const validateId = (id) => {
  if (!hasValue(id)) {
    return 'Channel No. is required';
  } else if (!isNumber(id)) {
    return 'Channel No. must be a number';
  } else if (!inRange(id, 1, MAX_CHANNEL_NUMBER)) {
    return `Channel No. must be between 1 and ${MAX_CHANNEL_NUMBER}`;
  }
  return null;
};


// TODO: Automatically trim strings!
export const validateDisplayName = (name, globalTranslations) => {
  if (name === null || name === undefined || name.trim() === '') {
    return 'Display name is required';
  } else if (textHelper(name)) {
    return globalTranslations.symbols;
  }
  return null;
};

export const validateType = (type) => {
  if (type === null || type === undefined || type.trim() === '') {
    return 'Type is required';
  }
  return null;
};

// #endregion

// #region Ipg Validation
export const validateSourceId = (sourceId, channelId, channelList) => {
  if (sourceId === null || sourceId === undefined || sourceId.trim() === '') {
    return 'Source is required';
  }

  let numOfSameSourceIDs = 0;

  for (let i = 0; i < channelList.length; i++) {
    if (channelList[i].id !== channelId && channelList[i].ipg.source_id === sourceId) {
      numOfSameSourceIDs += 1;
    }
  }

  if (numOfSameSourceIDs > 0) {
    return 'Source must be unique';
  }

  return null;
};

export const validateDuration = (duration, type) => {
  if (type !== IPG && (duration === null || duration === undefined || duration.trim() === '')) {
    return 'Duration is required';
  }
  return null;
};

export const validateTitle = (title, type, globalTranslations) => {
  if (type !== IPG) {
    if (title === null || title === undefined || title.trim() === '') {
      return 'Title is required';
    } else if (title.trim().length > MAX_TITLE_COUNT) {
      return `Title must be less than ${MAX_TITLE_COUNT} characters`;
    } else if (textHelper(title)) {
      return globalTranslations.symbols;
    }
  }

  return null;
};

export const validateDescription = (description, type, globalTranslations) => {
  if (type !== IPG) {
    if (description === null || description === undefined || description.trim() === '') {
      return 'Description is required';
    } else if (description.trim().length > MAX_DESC_COUNT) {
      return `Description must be less than ${MAX_DESC_COUNT} characters`;
    } else if (textHelper(description)) {
      return globalTranslations.symbols;
    }
  }

  return null;
};

// #endregion

// #region Analog Validation
export const validateAnalogMod = (modulator, channelId, channelList) => {
  if (modulator === null || modulator === undefined || (typeof modulator === 'string' && modulator.trim() === '')) {
    return 'Analog Modulator is required';
  }

  if (modulator >= ANALOG_MOD_MAX) {
    return `Analog Modulator must be less than ${ANALOG_MOD_MAX}`;
  }

  if (modulator <= 0) {
    return 'Analog Modulator must be greater than 0';
  }

  let channelsWithSameMod = 0;
  for (let i = 0; i < channelList.length; i++) {
    if (channelList[i].id !== channelId &&
      channelList[i].analog &&
      channelList[i].analog.modulator === modulator) {
      channelsWithSameMod += 1;
    }
  }

  if (channelsWithSameMod > 0) {
    return 'Analog Modulator must be unique';
  }

  return null;
};

export const validateAnalogTuning = (tuning) => {
  if (tuning === null || tuning === undefined || (typeof tuning === 'string' && tuning.trim() === '')) {
    return 'Analog Tuning is required';
  }
  return null;
};

export const validateAnalogStandard = (standard) => {
  if (standard === null || standard === undefined || (typeof standard === 'string' && standard.trim() === '')) {
    return 'Analog Standard is required';
  }
  return null;
};

// #endregion

// #region Digital Validation
export const validateDigitalModulatorStream = (channelType, modulator, stream, channelId, channelList) => {
  if (modulator === null || modulator === undefined || (typeof modulator === 'string' && modulator.trim() === '')) {
    return 'Digital Modulator and Stream are required. Format: Modulator-Stream.';
  }

  if (channelType !== AUX && parseInt(modulator, 10) >= parseInt(DIGI_MOD_MAX, 10)) {
    return `Modulator must be less than ${DIGI_MOD_MAX}`;
  }

  if (stream === null || stream === undefined || (typeof stream === 'string' && stream.trim() === '')) {
    return 'Digital Modulator and Stream are required. Format: Modulator-Stream.';
  }

  if (modulator <= 0) {
    return 'Digital Modulator must be greater than 0';
  }

  if (stream <= 0) {
    return 'Digital Stream must be greater than 0';
  }

  const unqiueModStream = `${modulator}-${stream}`;
  let channelsWithSameModStream = 0;

  for (let i = 0; i < channelList.length; i++) {
    if (channelList[i].id !== channelId &&
      channelList[i].digital &&
      `${channelList[i].digital.modulator}-${channelList[i].digital.stream}` === unqiueModStream) {
      channelsWithSameModStream += 1;
    }
  }

  if (channelsWithSameModStream > 0) {
    return 'Digital Modulator and Stream are already in use.';
  }

  return null;
};

// export const validateDigitalModulator = (modulator, stream, channelId, channelList) => {
//   if(modulator === null || modulator === undefined || (typeof modulator === 'string' && modulator.trim() === '')) {
//     return 'Digital Modulator is required';
//   }

//   if(modulator <= 0) {
//     return 'Digital Modulator must be greater than 0';
//   }

//   const unqiueModStream = `${modulator}-${stream}`;
//   let channelsWithSameModStream = 0;

//   for(let i = 0; i < channelList.length; i++) {
//     if(channelList[i].id !== channelId &&
//        channelList[i].digital &&
//        `${channelList[i].digital.modulator}-${channelList[i].digital.stream}` === unqiueModStream) {
//       channelsWithSameModStream += 1;
//     }
//   }

//   if(channelsWithSameModStream > 0) {
//     return 'Digital Modulator and Stream must be a unique combination';
//   }

//   return null;
// };

// export const validateDigitalStream = (stream, modulator, channelId, channelList) => {
//   if(stream === null || stream === undefined || (typeof stream === 'string' && stream.trim() === '')) {
//     return 'Digital Stream is required';
//   }

//   if(stream <= 0) {
//     return 'Digital Stream must be greater than 0';
//   }

//   const unqiueModStream = `${modulator}-${stream}`;
//   let channelsWithSameModStream = 0;

//   for(let i = 0; i < channelList.length; i++) {
//     if(channelList[i].id !== channelId &&
//       channelList[i].digital &&
//       `${channelList[i].digital.modulator}-${channelList[i].digital.stream}` === unqiueModStream) {
//       channelsWithSameModStream += 1;
//     }
//   }

//   if(channelsWithSameModStream > 0) {
//     return 'Digital Modulator and Stream must be a unique combination';
//   }

//   return null;
// };

export const validateDigitalMBPS = (mbps) => {
  if (mbps === null || mbps === undefined || (typeof mbps === 'string' && mbps.trim() === '')) {
    return 'Digital MBPS is required';
  }

  const floatValue = parseFloat(mbps);

  if (isNaN(floatValue)) {
    return 'Digital MBPS must be a number';
  }
  return null;
};

export const validateDigitalVideoFormat = (videoFormat) => {
  if (videoFormat === null || videoFormat === undefined ||
    (typeof videoFormat === 'string' && videoFormat.trim() === '')) {
    return 'Digital MPEG Format is required';
  }
  return null;
};

export const validateDigitalEncryption = (encryption) => {
  if (encryption === null || encryption === undefined ||
    (typeof encryption === 'string' && encryption.trim() === '')) {
    return 'Digital Encryption is required';
  }
  return null;
};

export const validateDigitalTuning = (tuning) => {
  if (tuning === null || tuning === undefined ||
    (typeof tuning === 'string' && tuning.trim() === '')) {
    return 'Digital Tuning is required';
  }
  return null;
};

// #endregion

// #region digital intl Validation

export const validateIntlBandwidth = (bandwidth) => {
  if (bandwidth === null || bandwidth === undefined || (typeof bandwidth === 'string' && bandwidth.trim() === '')) {
    return 'Digital Bandwidth is required';
  }
  return null;
};

export const validateIntlModulation = (modulation) => {
  if (modulation === null || modulation === undefined || (typeof modulation === 'string' && modulation.trim() === '')) {
    return 'Digital Modulation is required';
  }
  return null;
};

export const validateIntlPolarization = (polarization) => {
  if (polarization === null || polarization === undefined ||
    (typeof polarization === 'string' && polarization.trim() === '')) {
    return 'Digital Polarization is required';
  }
  return null;
};

export const validateCarrier = (carrier) => {
  if (carrier === null || carrier === undefined || (typeof carrier === 'string' && carrier.trim() === '')) {
    return 'Type is required';
  }
  return null;
};

export const validateIntlSatelliteId = (satelliteId) => {
  if (satelliteId === null || satelliteId === undefined ||
    (typeof satelliteId === 'string' && satelliteId.trim() === '')) {
    return 'DVB-S Satellite Id is required';
  }

  if (satelliteId >= BINARY_ALL_ONES) {
    return `DVB-S Satellite Id must be less than or equal to ${BINARY_ALL_ONES}`;
  }

  if (satelliteId <= 0) {
    return 'DVB-S Satellite Id must be greater than 0';
  }
};

export const validateIntlStream = (stream) => {
  if (stream === null || stream === undefined || (typeof stream === 'string' && stream.trim() === '')) {
    return 'International Stream is required';
  }

  if (stream >= BINARY_ALL_ONES) {
    return `International Stream must be less than or equal to ${BINARY_ALL_ONES}`;
  }

  if (stream <= 0) {
    return 'International Stream must be greater than 0';
  }
};

export const validateFrequencyKhz = (frequencyKhz) => {
  if (frequencyKhz === null || frequencyKhz === undefined ||
    (typeof frequencyKhz === 'string' && frequencyKhz.trim() === '')) {
    return 'Frequency Khz is required';
  }

  if (frequencyKhz >= MAX_FREQUENCY_KHZ) {
    return `Frequency Khz must be less than ${MAX_FREQUENCY_KHZ}`;
  }

  if (frequencyKhz <= MIN_FREQUENCY_KHZ) {
    return `Frequency Khz must be greater than ${MIN_FREQUENCY_KHZ}`;
  }
};

export const validateFrequencyMhz = (frequencyMhz) => {
  if (frequencyMhz === null || frequencyMhz === undefined ||
    (typeof frequencyMhz === 'string' && frequencyMhz.trim() === '')) {
    return 'Frequency Khz is required';
  }

  if (frequencyMhz >= MAX_FREQUENCY_MHZ) {
    return `Frequency Mhz must be less than ${MAX_FREQUENCY_MHZ}`;
  }

  if (frequencyMhz <= MIN_FREQUENCY_MHZ) {
    return `Frequency Mhz must be greater than ${MIN_FREQUENCY_MHZ}`;
  }
};

export const validatePlpId = (plpID) => {
  if (plpID === null || plpID === undefined || (typeof plpID === 'string' && plpID.trim() === '')) {
    return 'PLP Id is required';
  }

  if (plpID >= MAX_DESC_COUNT) {
    return `PLP Id must be less than or equal to ${MAX_DESC_COUNT}`;
  }

  if (plpID < 0) {
    return 'PLP Id Mhz must be greater than -1';
  }
};

export const validateSymbolRate = (symbolRate) => {
  if (symbolRate === null || symbolRate === undefined || (typeof symbolRate === 'string' && symbolRate.trim() === '')) {
    return 'Symbol Rate is required';
  }

  if (symbolRate >= BINARY_ALL_ONES) {
    return `Symbol Rate must be less than or equal to ${BINARY_ALL_ONES}`;
  }

  if (symbolRate < 0) {
    return 'Symbol Rate must be greater than -1';
  }
};

// #region Ip Validation
export const validateIpAddress = (address, port, channelId, channelList) => {
  if (address === null || address === undefined || (typeof address === 'string' && address.trim() === '')) {
    return 'IP Address is required';
  }

  /* eslint-disable max-len */
  if (!(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(address)) {
    return 'IP Address invalid. Valid range is 0.0.0.0 to 255.255.255.255';
  }

  const unqiueAddressPort = `${address}-${port}`;
  let channelsWithSameAddressPort = 0;

  for (let i = 0; i < channelList.length; i++) {
    if (channelList[i].id !== channelId &&
      channelList[i].ip &&
      `${channelList[i].ip.address}-${channelList[i].ip.port}` === unqiueAddressPort) {
      channelsWithSameAddressPort += 1;
    }
  }

  if (channelsWithSameAddressPort > 0) {
    return 'IP Address and Port must be a unique combination';
  }

  return null;
};

export const validateIpPort = (port, address, channelId, channelList) => {
  if (port === null || port === undefined || (typeof port === 'string' && port.trim() === '')) {
    return 'IP Port is required';
  }

  const unqiueAddressPort = `${address}-${port}`;
  let channelsWithSameAddressPort = 0;

  const portNumber = parseInt(port, 10);

  if (isNaN(port)) {
    return 'IP Port must be a number';
  }
  if (portNumber < 1 || portNumber > MAX_IP) {
    return `IP Port must be between 1 and ${MAX_IP}`;
  }

  for (let i = 0; i < channelList.length; i++) {
    if (channelList[i].id !== channelId &&
      channelList[i].ip &&
      `${channelList[i].ip.address}-${channelList[i].ip.port}` === unqiueAddressPort) {
      channelsWithSameAddressPort += 1;
    }
  }

  if (channelsWithSameAddressPort > 0) {
    return 'IP Address and Port must be a unique combination';
  }

  return null;
};

export const validateIpStream = (stream) => {
  if (stream === null || stream === undefined || (typeof stream === 'string' && stream.trim() === '')) {
    return 'IP Stream is required';
  }
  return null;
};

export const validateIpVideoFormat = (videoFormat) => {
  if (videoFormat === null || videoFormat === undefined ||
    (typeof videoFormat === 'string' && videoFormat.trim() === '')) {
    return 'IP MPEG Format is required';
  }
  return null;
};

export const validateIpEncryption = (encryption) => {
  if (encryption === null || encryption === undefined || (typeof encryption === 'string' && encryption.trim() === '')) {
    return 'IP Encryption is required';
  }
  return null;
};

export const validateIpMBPS = (mbps) => {
  if (mbps === null || mbps === undefined || (typeof mbps === 'string' && mbps.trim() === '')) {
    return 'IP MBPS is required';
  }

  const floatValue = parseFloat(mbps);

  if (isNaN(floatValue)) {
    return 'IP MBPS must be a number';
  }
  return null;
};

// #endregion
