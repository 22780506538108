import { Person } from '@mui/icons-material';
import {
    Card, CardActionArea, CardContent, Tooltip, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { closeSnackBar } from '../../../actions/globalActions';
import { ROOM_DETAIL_ROLES, TERMINAL_DETAIL_ROLES } from '../../../constants/roleGroups';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { editRoom, getTerminals } from '../actions/roomActions';
import { updateSelectedTerminal } from '../actions/terminalActions';

const styles = (theme) => ({
  card: {
    'backgroundColor': theme.palette.primary.light,
    '&:hover': {
      backgroundColor: '#0e6393',
      color: '#fff'
    }
  },
  cardContent: {
    flexGrow: 1,
    minHeight: '10vw',
  },
  icon: {
    float: 'right', marginTop: '5px',
  }
});

class EditCard extends Component {
  onClick() {
    if (checkForAtLeastOneUserPermission([...ROOM_DETAIL_ROLES, ...TERMINAL_DETAIL_ROLES],
      this.props.userPermissions)) {
      this.props.dispatch(updateSelectedTerminal(-1));
      this.props.dispatch(editRoom(this.props.index, this.props.roomID));
      this.props.dispatch(closeSnackBar());
      this.props.dispatch(getTerminals(this.props.roomID));
    }
  }

  render() {
    const { classes, roomID, occupied } = this.props;

    return (
      <Card className={classes.card} >
        <CardActionArea onClick={this.onClick.bind(this)}>
          <CardContent className={classes.cardContent}>
            <Typography variant="subtitle1">
              {roomID}  {occupied
                ? <Tooltip title="Occupied"><Person className={classes.icon} /></Tooltip>
                : ''}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userPermissions: state.global.permissions,
});

EditCard.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  index: PropTypes.number.isRequired,
  occupied: PropTypes.bool.isRequired,
  roomID: PropTypes.string.isRequired,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(EditCard, styles, { withTheme: true }));
