import { FilterList } from '@mui/icons-material';
import {
  FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IPG_SOURCES } from '../../../../constants/apiEndpoints';
import { SonifiText, SonifiTextAsync } from '../../../../containers';
import SonifiAutoCompleteText from '../../../../containers/SonifiAutoCompleteText';
import { availableTimezones } from '../../../Channels2/constants/constants';


export class StepTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asyncSelectRef: null,
      timeZoneSearch: ' '
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleRadio = this.handleRadio.bind(this);
  }

  getMoreInfo() {
    // const { targetChannel } = this.state;
    const { currentState } = this.props;
    if (Object.keys(currentState.targetChannel).length === 0) {
      return '';
    }
    const aff = (currentState.targetChannel.affiliate !== null &&
      currentState.targetChannel?.affiliate?.length > 0)
      ? currentState.targetChannel?.affiliate
      : 'none';
    const call = (currentState.targetChannel.call_letters !== null &&
      currentState.targetChannel?.call_letters?.length > 0
      ? currentState.targetChannel?.call_letters
      : 'none');
    return `${aff} | ${call}`;
  }

  editSearchCriteria = (name) => (event, value) => {
    this.setState({ [name]: (!value || value === null) ? '' : value.value });
  };

  createDisplayObj = (source) => ({
    label:
      `${source.affiliate ? source.affiliate : 'none'}|
      ${source.call_letters ? source.call_letters : 'none'}|
      ${source.name}|${source.timezone}`,
    value: source
  });

  createFilterObj = (inputData) => {
    const affCallL = inputData.split('|');
    if (affCallL.length > 1) {
      return {
        affiliate: (affCallL[0] === 'none' ? '' : affCallL[0]),
        call_letters: (affCallL[1] === 'none' ? '' : affCallL[1]),
        name: (affCallL[2] ? affCallL[2] : ''),
        platform: 'Marina',
        timezone: (affCallL[3] ? affCallL[3] : this.state.timeZoneSearch),
        provider: this.props.currentState.provider.toLowerCase(),
        limit: 100
      };
    }
    return {
      provider: this.props.currentState.provider.toLowerCase(),
      platform: 'Marina',
      search: inputData,
      timezone: this.state.timeZoneSearch,
      limit: 100
    };
  };

  getSelectRef = (node) => {
    this.setState({ asyncSelectRef: node });
  };

  handleChange = (option) => {
    if (option && option.value) {
      const channelToMod = JSON.parse(JSON.stringify(option.value));
      channelToMod.number = this.props.currentState.targetChannel.number;
      this.props.changeState('targetChannel', channelToMod);
    }
  };

  handleRadio = ({ target: { value } }) => {
    this.props.changeState('provider', value);
  };

  editNumber = ({ target: { value } }) => {
    const channelToMod = JSON.parse(JSON.stringify(this.props.currentState.targetChannel));
    channelToMod.number = (Number(value) < 0 ? 0 : Number(value));
    this.props.changeState('targetChannel', channelToMod);
  };

  render() {
    const { currentState, providerValue, stepThreeTranslations, translations } = this.props;
    const moreChannelInfo = this.getMoreInfo();
    const { error, provider } = currentState;

    return (
      <Grid container>
        <Grid item xs={4} style={{ paddingBottom: '15px' }}>
          <SonifiText
            defaultValue={currentState.targetChannel.number}
            change={this.editNumber}
            label={stepThreeTranslations.tv}
            size="percent"
            type="number"
            helperText={stepThreeTranslations.tvHelper}
            error={error?.targetChannel?.number !== ''}
            errorText={error?.targetChannel?.number}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Provider</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={provider}
                  onChange={this.handleRadio} >
                  <FormControlLabel value={providerValue[0]} control={<Radio />} label={providerValue[0]} />
                  <FormControlLabel value={providerValue[1]} control={<Radio />} label={providerValue[1]} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <SonifiAutoCompleteText
                helperText={translations.timezoneFilter}
                label={translations.timezone}
                onChange={this.editSearchCriteria('timeZoneSearch')}
                options={availableTimezones}
                placeholder={translations.optional}
                popupIcon={<FilterList />}
                size="percent" />
            </Grid>
            <Grid item xs={7}>
              <SonifiTextAsync
                dataName="sources"
                isIpg
                displayFunc={this.createDisplayObj}
                filterFunc={this.createFilterObj}
                getRefFunc={this.getSelectRef}
                label={translations.sourceId}
                onSelect={this.handleChange}
                placeholder={(currentState.targetChannel.id !== '' ? moreChannelInfo : translations.affCall)}
                resource={IPG_SOURCES}
                siteObj={false}
                type={IPG_SOURCES} />
            </Grid>
            <Grid item xs={12}>
              <SonifiText
                defaultValue={`${currentState?.targetChannel?.id}` || ''}
                disabled={true}
                label={translations.sourceId}
                error={error?.targetChannel?.sourceId !== ''}
                errorText={error?.targetChannel?.sourceId}
                size="percent" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations,
  stepThreeTranslations: state.batchChannels.translations.grid,
  translations: state.channels.translations.editDialog
});

StepTwo.propTypes = {
  changeState: PropTypes.func,
  currentState: PropTypes.object,
  stepThreeTranslations: PropTypes.object,
  globalTranslations: PropTypes.object,
  providerValue: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(StepTwo);
