import { TERM_MODELS } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import { deleteSitelessObjectPromise, getObjectPromise, putSitelessObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

export const startModels = () => ({
  type: types.START_MODELS
});

export const clearModels = () => ({
  type: types.CLEAR_MODELS
});

export const fetchModelsBegin = () => ({
  type: types.FETCH_ALL_MODELS_BEGIN
});

export const fetchModelsSuccess = (models) => ({
  type: types.FETCH_ALL_MODELS_SUCCESS,
  models
});

export const fetchModelsFailure = (error) => ({
  type: types.FETCH_ALL_MODELS_FAILURE,
  error
});

export function fetchModels(filter) {
  return (dispatch) => {
    dispatch(fetchModelsBegin());
    return getObjectPromise(TERM_MODELS, null, filter)
      .then((json) => {
        dispatch(fetchModelsSuccess(json));
        return json;
      })
      .catch((error) => {
        dispatch(fetchModelsFailure(error.status));
        return error;
      });
  };
}

export const updateSelectedTermModel = (index) => ({
  type: types.UPDATE_SELECTED_TERM_MODEL,
  index
});

export const updateDeleteRow = (termModel) => ({
  type: types.DELETE_CHECK,
  termModel
});

export const deleteTermModelBegin = () => ({
  type: types.DELETE_TERM_MODEL_BEGIN
});

export const deleteTermModelSuccess = (id) => ({
  type: types.DELETE_TERM_MODEL_SUCCESS,
  id
});

export const deleteTermModelError = (error) => ({
  type: types.DELETE_TERM_MODEL_FAILURE,
  error
});

export function deleteTermModelById(id, sort) {
  return (dispatch) => {
    dispatch(deleteTermModelBegin());
    return deleteSitelessObjectPromise(TERM_MODELS, encodeURIComponent(id))
      .then(() => {
        dispatch(clearModels());
        return dispatch(fetchModels(sort))
          .then(() => {
            dispatch(deleteTermModelSuccess(id));
            return id;
          });
      }).catch((error) => {
        dispatch(deleteTermModelError(`${id} - (${error.status})`));
        return error;
      });
  };
}

export const addTermModel = () => ({
  type: types.ADD_NEW_TERM_MODEL
});

export const saveTermModelBegin = () => ({
  type: types.SAVE_TERM_MODEL_BEGIN
});

export const saveTermModelSuccess = (termId) => ({
  type: types.SAVE_TERM_MODEL_SUCCESS,
  termId
});

export const saveTermModelError = (error) => ({
  type: types.SAVE_TERM_MODEL_FAILURE,
  error
});

export const saveTermModelReset = () => ({
  type: types.SAVE_TERM_MODEL_RESET
});

export function saveTermModel(termModel, sort) {
  return (dispatch) => {
    dispatch(saveTermModelBegin());

    return putSitelessObjectPromise(TERM_MODELS, encodeURIComponent(termModel.id.trim()), { make: termModel.make })
      .then((response) => {
        if (!httpSuccess(response.status)) {
          dispatch(saveTermModelError(response.status));
          return response;
        }

        dispatch(clearModels());
        return dispatch(fetchModels({ sort, page: 1 }))
          .then(() => {
            dispatch(saveTermModelSuccess(termModel.id.trim()));
            return response;
          });
      })
      .catch((error) => {
        dispatch(saveTermModelError(error.status));
        return error;
      });
  };
}

export const resetSnackBar = () => ({
  type: types.RESET_SNACKBAR
});
