import { Check, Delete, GetApp, Publish } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiIconButton } from '../../../../containers';
import { getChannelsThatCanBeInLineup, validLineup } from '../../../TvLineup/utils/validator';
import * as actions from '../../actions/channelsActions';

class DraftButtons extends Component {
  state = {
    allowDownload: true
  };

  handleRequestDownload() {
    this.setState({ allowDownload: false });
    this.props.dispatch(actions.getChannelsExport(this.props.channelSet.id));
    setTimeout(() => {
      this.setState({ allowDownload: true });
    }, 2000);
  }

  checkLineups() {
    const { globalTranslations, lineups, lineupErrors } = this.props;

    for (let i = 0; i < lineups.length; i++) {
      const chans = getChannelsThatCanBeInLineup(lineups[i], this.props.realChannels);
      const valid = validLineup(lineups[i], chans, lineupErrors, globalTranslations);

      if (valid.validationErrors && valid.validationErrors.length > 0) {
        return false;
      }
    }
    return true;
  }

  getPublishToolTip() {
    const { lineups, realChannels, translations, undoStack } = this.props;
    let publishButtonToolTip = '';
    if (undoStack.length > 0) {
      publishButtonToolTip += translations.saveBeforePublish;
    }
    if (lineups.length === 0) {
      if (publishButtonToolTip.length > 0) {
        publishButtonToolTip += '\n';
      }
      publishButtonToolTip += translations.oneLineup;
    }
    if (realChannels.length === 0) {
      if (publishButtonToolTip.length > 0) {
        publishButtonToolTip += '\n';
      }
      publishButtonToolTip += translations.oneChannel;
    }

    const validLineups = this.checkLineups();
    if (!validLineups) {
      if (publishButtonToolTip.length > 0) {
        publishButtonToolTip += '\n';
      }
      publishButtonToolTip += translations.noLineups;
    }
    return publishButtonToolTip;
  }

  checkType(type) {
    this.props.dispatch(actions.channelSetCheck(type));
  }

  getSaveToolTip() {
    const { realChannels, translations, undoStack } = this.props;
    let publishButtonToolTip = '';
    if (undoStack.length < 1) {
      publishButtonToolTip = translations.allSaved;
    }

    if (realChannels.length === 0) {
      if (publishButtonToolTip.length > 0) {
        publishButtonToolTip += '\n';
      }
      publishButtonToolTip = translations.oneChannel;
    }
    return publishButtonToolTip;
  }

  saveCheck() {
    this.props.dispatch(actions.saveCheck(this.props.realChannels, { type: 'save', chan: '' }));
  }

  render() {
    const {
      firstLoad, globalTranslations, lineups, lineupsLoading, loading, realChannels, translations, undoStack
    } = this.props;
    const { allowDownload } = this.state;
    const updateInProgress = (lineupsLoading || loading || firstLoad);
    const validLineups = this.checkLineups();
    return (
      <Grid container justifyContent="flex-end">
        <Grid item>
          <SonifiIconButton label={globalTranslations.download}
            onClick={this.handleRequestDownload.bind(this)}
            toolTipMessage={translations.downloadDraft}
            showToolTip={true}
            disabled={updateInProgress || !allowDownload}
            icon={<GetApp />} />
        </Grid>
        <Grid item>
          <SonifiIconButton label={globalTranslations.publish}
            onClick={this.checkType.bind(this, 'publish')}
            icon={<Publish />}
            toolTipMessage={this.getPublishToolTip()}
            showToolTip={undoStack.length > 0 || lineups.length === 0 ||
              realChannels.length === 0 || !validLineups}
            disabled={undoStack.length > 0 || lineups.length === 0 ||
              realChannels.length === 0 || !validLineups || updateInProgress}
          />
        </Grid>
        <Grid item>
          <SonifiIconButton label={globalTranslations.delete}
            onClick={this.checkType.bind(this, 'delete')}
            icon={<Delete />}
            disabled={updateInProgress}
            toolTipMessage={translations.deleteDraft}
            showToolTip={true} />
        </Grid>
        <Grid item>
          <SonifiIconButton label={globalTranslations.save}
            onClick={this.saveCheck.bind(this)}
            icon={<Check />}
            toolTipMessage={this.getSaveToolTip()}
            showToolTip={undoStack.length < 1 || realChannels.length === 0}
            disabled={undoStack.length < 1 || realChannels.length === 0 || updateInProgress} />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  channelSet: state.channels.channelSet,
  firstLoad: state.channels.firstLoad,
  globalTranslations: state.global.translations.defaults,
  lineupErrors: state.tvLineup.translations.editDialog.errors,
  lineups: state.tvLineup.channelLineups,
  lineupsLoading: state.tvLineup.lineupsLoading,
  loading: state.channels.loading,
  realChannels: state.channels.realChannels,
  translations: state.channels.translations.buttonPanel,
  undoStack: state.channels.undoStack,
  userPermissions: state.global.permissions
});

DraftButtons.propTypes = {
  channelSet: PropTypes.object,
  checkType: PropTypes.func,
  dispatch: PropTypes.func,
  firstLoad: PropTypes.bool,
  globalTranslations: PropTypes.object,
  lineupErrors: PropTypes.object,
  lineups: PropTypes.array,
  lineupsLoading: PropTypes.bool,
  loading: PropTypes.bool,
  realChannels: PropTypes.array,
  translations: PropTypes.object,
  undoStack: PropTypes.array,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(DraftButtons);
