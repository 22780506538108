import { pickBy } from 'lodash';
import { HIGH_HTTP, LOW_HTTP } from '../../../constants/apiEndpoints';
import { SONIFI } from '../../../constants/constants';
import { saveSiteFirmware } from '../../TermFiles/actions/FirmwareActions';
import { LG, SAMSUNG } from '../../TermFiles/constants/TermFileConstants';
import * as types from './actionTypes';

export const closeTermSnackBar = () => ({
  type: types.CLOSE_SNACK_BAR
});

export const updateGlobalTermFilesBegin = (termInfo) => ({
  type: types.UPDATE_GLOBAL_TERM_FILES_BEGIN,
  termInfo
});

export const updateGlobalTermFilesComplete = (responses) => ({
  type: types.UPDATE_GLOBAL_TERM_FILES_COMPLETE,
  responses
});

export const updateGlobalTermFilesError = (error) => ({
  type: types.UPDATE_GLOBAL_TERM_FILES_ERROR,
  error
});

export function updateGlobalTermFiles(termInfo) {
  return (dispatch) => {
    dispatch(updateGlobalTermFilesBegin(termInfo));

    const levels = termInfo.map((termFile) => {
      let returnObject = null;
      if (termFile.toolTip || !termFile.siteNum) {
        console.log('Just pass as there was an error with this one.', termFile);
      } else if (termFile.modelNum) {
        returnObject = modelSet(termFile, dispatch);
        return dispatch(saveSiteFirmware(returnObject, returnObject.make, termFile.siteNum, false));
      } else if (!termFile.modelNum) {
        returnObject = noModelSet(termFile);
        return dispatch(saveSiteFirmware(returnObject, returnObject.make, termFile.siteNum, false));
      }

      return new Promise(((resolve, reject) => {
        reject(new Error({ status: 1 }));
      })).catch((e) => {
        console.log(e); // works!
      });
    });

    return Promise.all(levels).then((responses) => {
      for (let i = 0; i < responses.length; i++) {
        if (responses[i] && (responses[i].status < LOW_HTTP || responses[i].status >= HIGH_HTTP)) {
          const errorMsg = `${responses[i].siteNum}:${responses[i].id} - ${responses[i].title}: ${responses[i].detail}`;
          dispatch(updateGlobalTermFilesError(errorMsg));
        }
      }
      return dispatch(updateGlobalTermFilesComplete(responses));
    });
  };
}


function modelSet(termFile) {
  let termType = '';
  const firmwareObject = {};
  if (termFile.lgCPU || termFile.lgPTC || termFile.lgBrowser) {
    termType = LG;
    firmwareObject.BROWSER = termFile.lgBrowser;
    firmwareObject.CPU = termFile.lgCPU;
    firmwareObject.PTC = termFile.lgPTC;
  } else if (termFile.samsungFirmware || termFile.samsungClone) {
    termType = SAMSUNG;
    firmwareObject.CLIENT = termFile.samsungFirmware;
    firmwareObject.CLONE = termFile.samsungClone;
  } else if (termFile.sonifiFirmware) {
    termType = SONIFI;
    firmwareObject['HC-11'] = null;
    firmwareObject['HC-12'] = null;
    firmwareObject['SWT-54'] = null;

    if (termFile.sonifiFirmware.toLowerCase().substring(0, 5) === 'hc-11') {
      firmwareObject['HC-11'] = termFile.sonifiFirmware;
    } else if (termFile.sonifiFirmware.toLowerCase().substring(0, 5) === 'hc-12') {
      firmwareObject['HC-12'] = termFile.sonifiFirmware;
    } else if (termFile.sonifiFirmware.toLowerCase().substring(0, 3) === 'swt') {
      firmwareObject['SWT-54'] = termFile.sonifiFirmware;
    }
  }

  const cleanFirmware = pickBy(firmwareObject, (value) => !(value === undefined || value === null || value === ''));
  return { id: termFile.modelNum, firmware: cleanFirmware, make: termType };
}

function noModelSet(termFile) {
  let termType = '';
  const firmwareObject = {};
  if (termFile.lgTermClient) {
    termType = LG;
    firmwareObject.CLIENT = termFile.lgTermClient;
  } else if (termFile.samsungTermClient) {
    termType = SAMSUNG;
    firmwareObject.CLIENT = termFile.samsungTermClient;
  }

  const cleanFirmware = pickBy(firmwareObject, (value) => !(value === undefined || value === null || value === ''));
  return { id: '*', firmware: cleanFirmware, make: termType };
}
