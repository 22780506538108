import { Clear, ErrorOutline } from '@mui/icons-material';
import {
  Grid, Table, TableBody, TableCell, TableRow, Tooltip
} from '@mui/material';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel, SonifiTableHead, SonifiTextAsync } from '../../../../containers';
import SonifiPopover from '../../../../containers/SonifiPopover';

export class StepOne extends Component {
  constructor(props) {
    super(props);
  }

  createDisplayObj = (site) => ({
    value: site,
    label: `${site.id} ${(site.name ? `: ${site.name}` : '')}`,
  });

  createFilterObj = (inputData) => ({
    search: inputData,
    limit: 20
  });

  handleChange = (option) => {
    if (option && option.value && option.value.id) {
      const siteToMod = JSON.parse(JSON.stringify(this.props.currentState.sites));
      siteToMod.unshift({ ...option.value, found: true });
      this.props.changeState('sites', uniqBy(siteToMod, (e) => e.id));
    }
  };

  uploadCsv = () => () => {
    console.log('Upload CSV button triggered.');
  };

  handleEditDialog(index) {
    const siteToMod = JSON.parse(JSON.stringify(this.props.currentState.sites));
    siteToMod.splice(index, 1);
    this.props.changeState('sites', siteToMod);
  }

  getSiteTable() {
    const { currentState, globalTranslations, translations } = this.props;
    return (
      <Grid container justifyContent="space-between" style={{ height: 'calc(100% - 1px)' }}>
        <Grid item xs={12}>
          <SonifiTextAsync
            dataName="sites"
            dataTest="siteSearchBar"
            defaultValue=""
            displayFunc={this.createDisplayObj}
            error={currentState.error.sites !== ''}
            errorText={currentState.error.sites}
            filterFunc={this.createFilterObj}
            onSelect={this.handleChange}
            placeholder={translations.siteSearch}
            resource="sites"
            siteObj={false}
            type="site"
          />
        </Grid>
        <Grid item xs={12} style={{ height: '100%' }}>
          <Table stickyHeader>
            <SonifiTableHead
              headColumns={[
                {
                  id: 'found', sortable: false, numeric: false, label: '', narrow: true, noLabel: true
                },
                { id: 'siteId', sortable: false, numeric: false, label: 'ID' },
                { id: 'siteName', sortable: false, numeric: false, label: 'Name' },
                { id: 'platform', sortable: false, numeric: false, label: 'Platform' },
              ]}
            />
            <TableBody>
              {currentState.sites.length === 0
                ? <TableRow>
                  <TableCell colSpan={4}>
                    <SonifiLabel blue label={this.props.translations.noChannels} />
                  </TableCell>
                </TableRow>
                : currentState.sites.map((info, index) => (
                  <SonifiPopover
                    key={index}
                    functionOneFunc={this.handleEditDialog.bind(this, index)}
                    functionOneTitle={globalTranslations.defaults.remove}
                    functionOneIcon={<Clear />}
                    hideDelete={true}
                    hideEdit={true}
                    showFunctionOne={true}
                    tableRow={
                      <Fragment>
                        <TableCell component="th" scope="row">
                          {info.found
                            ? info.platforms.includes('Marina')
                              ? <Fragment />
                              : <Tooltip title="Ocean: Future Devel">
                                <ErrorOutline style={{ color: 'blue' }} />
                              </Tooltip>
                            : <Tooltip title="Site not found"><ErrorOutline style={{ color: 'red' }} /></Tooltip>
                          }
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <SonifiLabel label={`${info.id}`} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <SonifiLabel label={`${info.name}`} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <SonifiLabel label={`${info.platforms.toString()}`} />
                        </TableCell>
                      </Fragment>
                    }
                  />
                ))}
            </TableBody>

          </Table>
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <Grid container justifyContent="center">
        <Grid item xs={10}>
          {this.getSiteTable()}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations,
  translations: state.batchChannels.translations.addDialog
});

StepOne.propTypes = {
  changeState: PropTypes.func,
  close: PropTypes.func,
  currentState: PropTypes.object,
  globalTranslations: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(StepOne);
