import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import SonifiIconButton from './SonifiIconButton';
import SonifiLabel from './SonifiLabel';

const styles = () => ({
  right: {
    float: 'right',
  },
  header:
  {
    alignItems: 'center',
    color: '#8e8e8e',
    display: 'flex',
    fontFamily: 'Open Sans',
    fontSize: '16px',
    fontWeight: 900, /* Avenir 85 */
    height: '100%'
  },
  middle: {
    // display: 'flex'
  },
  topPadding: {
    paddingTop: '24px'
  }
});

export class SonifiSubHeader extends Component {
  render() {
    const {
      classes, disabled, header, icon, label, noPadding, onSubmit, showButton, smallerHeader, toolTip, topPadding
    } = this.props;


    return (
      <Grid container justifyContent="space-between" className={`${!!topPadding && classes.topPadding}`}>
        <Grid item>
          {smallerHeader
            ? <SonifiLabel blue label={header} noPadding={!!noPadding} />
            : <Typography className={classes.header} noWrap>
              {header}
            </Typography>}
        </Grid>
        <Grid item className={classes.middle}>
          {this.props.middleExtra}
        </Grid>
        {showButton &&
          <SonifiIconButton disabled={disabled} onClick={onSubmit} icon={icon} label={label}
            toolTipMessage={toolTip} showToolTip={!!toolTip}
          />
        }
      </Grid >
    );
  }
}

SonifiSubHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  header: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.string,
  middleExtra: PropTypes.object,
  noPadding: PropTypes.bool,
  onSubmit: PropTypes.func,
  showButton: PropTypes.bool.isRequired,
  smallerHeader: PropTypes.bool,
  toolTip: PropTypes.string,
  topPadding: PropTypes.bool,
};
export default withStyles(SonifiSubHeader, styles);
