import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLockoutModalSpinner, SonifiSnackBar, SonifiSpinnerWithMessage } from '../../../containers';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiTabs from '../../../containers/SonifiTabs';
import { fetchRoomsOptions } from '../../Rooms/actions/roomActions';
import { fetchChannelOptions } from '../../SiteManagement/actions/siteManagementActions';
import { getAllLineupsForChannelSet, setLineupSnackBar } from '../../TvLineup/actions/tvLineupActions';
import LineupsGrid from '../../TvLineup/components/LineupsGrid';
import * as actions from '../actions/channelsActions';
import ActiveButtons from '../containers/Panels/ActiveButtons';
import DraftButtons from '../containers/Panels/DraftButtons';
import { getMessage, getTitle } from '../utils/helper';
import MasterChannelList from './MasterChannelList';

class ChannelsGrid extends Component {
  state = {
    // showConfirmDialog: false,
    selectedTab: null,
    masterChannelList: null,
    newTabValue: ''
  };

  componentDidMount() {
    this.props.dispatch(actions.firstMasterChannelLoad());
    this.props.dispatch(fetchRoomsOptions());

    // this.props.dispatch(closeLineupSaveSuccess());
    this.props.dispatch(actions.setSnackBar());
    this.props.dispatch(fetchChannelOptions());
  }

  componentDidUpdate() {
    if (this.props.availableChannelSets !== this.state.masterChannelList) {
      // const selected = maxBy(this.props.masterChannelList, (o) => o.date);

      this.setState({
        masterChannelList: this.props.availableChannelSets,
        selectedTab: this.props.availableChannelSets.length > 1 ? 'Draft' : 'Active',
      });
    }
  }

  handleChange(event, newValue) {
    const { availableChannelSets, dispatch, undoStack } = this.props;

    if (newValue === this.state.selectedTab) {
      return;
    }

    const newChannelSet = find(availableChannelSets, { label: newValue });
    if (undoStack.length === 0) {
      this.setState({ selectedTab: newValue });
      dispatch(actions.selectChannelSet(newChannelSet.value));
      dispatch(getAllLineupsForChannelSet(newChannelSet.value.id));
    } else {
      // If changes, save the set the user wants to change to and display confirm dialog
      this.props.dispatch(actions.channelSetCheck('tab'));
      this.setState({
        changeToSet: newChannelSet.value,
        newTabValue: newValue
      });
    }
  }

  // This function is for continue to change sets even if there are unsaved changes
  channelSetChangeConfirm = () => {
    const {
      checkType, dispatch, lineups, realChannels
    } = this.props;
    if (checkType === 'tab') {
      this.setState({ selectedTab: this.state.newTabValue });
      dispatch(actions.selectChannelSet(this.state.changeToSet));
      dispatch(getAllLineupsForChannelSet(this.state.changeToSet.id));
    } else if (checkType === 'delete') {
      dispatch(actions.deleteDraft())
        .then(() => {
          this.setState({ newTabValue: 'Active' });
        });
    } else if (checkType === 'publish') {
      const activeSet = this.props.availableChannelSets.find((set) => set.value.id === 'Default');
      dispatch(actions.publishDraftToActive(realChannels, activeSet.value, lineups))
        .then(() => {
          this.setState({ newTabValue: 'Active' });
        });
    }
  };

  // This a cancel for the confirm dialog
  universalCancel = () => {
    this.props.dispatch(actions.channelSetCheck(null));
  };

  closeSnackBar() {
    this.props.dispatch(actions.setSnackBar());
  }

  closeLineupSnackBar() {
    this.props.dispatch(setLineupSnackBar(''));
  }

  render() {
    const { selectedTab } = this.state;
    const {
      availableChannelSets, channelSet, checkType, globalTranslations, lineupMessage, lineupMessageType, loading,
      snackBarMessage, snackBarType, translations, lineupSaving, creatingDraft, saving
    } = this.props;


    if (!availableChannelSets || availableChannelSets.length < 1 || selectedTab === null) {
      return <SonifiSpinner />;
    }

    const draftExists = availableChannelSets.length > 1;

    return (<Fragment>
      <SonifiLockoutModalSpinner show={(saving || loading || lineupSaving) && !creatingDraft} />
      <SonifiSpinnerWithMessage show={creatingDraft} message={translations.savingDraft} />

      <SonifiConfirm
        buttonCancelText={globalTranslations.cancel}
        buttonConfirmText={globalTranslations.save}
        confirmTitle={getTitle(checkType, translations)}
        confirmText={getMessage(checkType, translations, selectedTab)}
        dialogOpen={checkType !== null}
        onConfirm={this.channelSetChangeConfirm.bind(this)}
        onCancel={this.universalCancel.bind(this)}
      />

      <SonifiSnackBar message={snackBarMessage} variant={(snackBarType)}
        open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />

      <SonifiSnackBar message={lineupMessage} variant={lineupMessageType}
        open={lineupMessage !== ''} onClose={this.closeLineupSnackBar.bind(this)} />

      <SonifiTabs
        tabs={availableChannelSets.map(({ label }) => ({
          id: label,
          title: `${label}`,
        }))}
        handleChange={this.handleChange.bind(this)}
        tabValue={selectedTab}
        showingTab={
          <Fragment>
            <LineupsGrid lineupName={selectedTab} draftExists={draftExists && selectedTab === 'Active'} />
            <MasterChannelList draftExists={draftExists && selectedTab === 'Active'} />
          </Fragment>
        }
        extraButton={channelSet.id === 'Draft'
          ? <DraftButtons />
          : <ActiveButtons draftExists={draftExists} />}
      />
    </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  availableChannelSets: state.channels.availableChannelSets,
  channelSet: state.channels.channelSet,
  checkType: state.channels.checkType,
  creatingDraft: state.channels.creatingDraft,
  globalTranslations: state.global.translations.defaults,
  lineupMessage: state.tvLineup.snackBarMessage,
  lineupMessageType: state.tvLineup.snackBarType,
  lineups: state.tvLineup.channelLineups,
  lineupSaving: state.tvLineup.lineupSaving,
  loading: state.channels.loading,
  realChannels: state.channels.realChannels,
  saving: state.channels.saving,
  snackBarMessage: state.channels.snackBarMessage,
  snackBarType: state.channels.snackBarType,
  translations: state.channels.translations.channels,
  undoStack: state.channels.undoStack
});

ChannelsGrid.propTypes = {
  availableChannelSets: PropTypes.array,
  channelSet: PropTypes.object,
  checkType: PropTypes.string,
  creatingDraft: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  lineupMessage: PropTypes.string,
  lineupMessageType: PropTypes.string,
  lineups: PropTypes.array,
  lineupSaving: PropTypes.bool,
  loading: PropTypes.bool,
  realChannels: PropTypes.array,
  saving: PropTypes.bool,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  translations: PropTypes.object,
  undoStack: PropTypes.array
};

export default connect(mapStateToProps)(ChannelsGrid);
