import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { QrReader } from 'react-qr-reader';
import { connect } from 'react-redux';
import { SonifiButton, SonifiSpinner } from '../../../containers';
import SonifiLabel from '../../../containers/SonifiLabel';
import { getProvisionTerm } from '../actions/provisionTermsActions';

/* https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints/facingMode
 * "user" - The video source is facing toward the user;
 *       this includes, for example, the front-facing camera on a smartphone.
 * "environment" - The video source is facing away from the user, thereby viewing their environment.
 *       This is the back camera on a smartphone.
 */

class QrCode extends Component {
  state = {
    data: null,
    error: null,
    processing: false,
    result: '',
    facingMode: 'environment'
  };

  continueToMobile() {
    this.props.continue(this.state.data);
  }

  cancelMobile() {
    this.setState({ data: null });
  }

  handleScan(result) {
    this.setState({ result, processing: true });
    const resultArray = result.split('?');
    if (resultArray[resultArray.length - 1].startsWith('terminal=')) {
      const terminalAddress = result.split('=');
      this.props.dispatch(
        getProvisionTerm(terminalAddress[terminalAddress.length - 1])
      ).then((data) => {
        this.setState({ processing: false, data, error: null });
      }).catch((error) => {
        this.setState({ processing: false, data: null, error });
      });
    }
  }

  showSiteRoomInfo() {
    const { globalTranslations, translations } = this.props;
    const {
      data, result
    } = this.state;
    let sameUrl = result.startsWith(process.env.REACT_APP_COGNITO_REDIRECT_URI);
    if (process.env.REACT_APP_COGNITO_REDIRECT_URI === 'http://localhost:3000') {
      sameUrl = result.startsWith('https://signin-devel.sonifi.cloud') ||
        result.startsWith('https://cca-devel.sonifi.cloud');
    }

    return (
      <Fragment>
        <Grid item xs={12}>
          <SonifiLabel boldLabel={translations.terminalId} label={`${data.id}`} />
        </Grid>
        {!sameUrl && <Grid item xs={12}>
          <SonifiLabel boldLabel={translations.url} label={`${result}`} />
        </Grid>}
        {!sameUrl && <Grid item xs={12}>
          <SonifiLabel boldLabel={translations.notice} label={translations.noticeError} italic size="xs" />
        </Grid>}
        <Grid item>
          <SonifiButton secondary caption={globalTranslations.cancel}
            onClick={this.cancelMobile.bind(this)} />
        </Grid>
        <Grid item>
          <SonifiButton caption={globalTranslations.submit}
            onClick={this.continueToMobile.bind(this)} />
        </Grid>
      </Fragment>
    );
  }

  qrInfo() {
    const previewStyle = {
      height: '100%',
      width: '100%',
      boxShadow: 'rgba(255, 0, 0, 0.5) 0px 0px 0px 5px inset'
    };

    return (
      <Fragment>
        <Grid item xs={12}>
          <QrReader
            constraints={{ facingMode: `${this.state.facingMode}` }}
            key={`${this.state.facingMode}`}
            onResult={(result) => {
              if (result && result.text) {
                this.handleScan(result?.text);
              }
            }}
            videoStyle={previewStyle}
          />
        </Grid>
        <Grid item xs={12}>
          <SonifiButton
            caption={`Change to ${this.state.facingMode === 'environment' ? 'Front' : 'Back'} Camera`}
            marginTop
            onClick={this.reset.bind(this)} />
        </Grid>
      </Fragment>

    );
  }

  reset() {
    this.setState({
      facingMode: this.state.facingMode === 'environment' ? 'user' : 'environment'
    });
  }

  render() {
    const { data, processing } = this.state;

    if (processing) {
      return <SonifiSpinner />;
    }

    return (
      <Grid container>
        {data && !data.room_id
          ? this.showSiteRoomInfo()
          : this.qrInfo()
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  translations: state.provisionTerms.translations.qrReader
});

QrCode.propTypes = {
  continue: PropTypes.func,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(QrCode);

