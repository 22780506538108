import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { LOGOS } from '../../../constants/apiEndpoints';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import { updateDeleteRow, updateSelectedLogo } from '../actions/logoActions';

class LogoRow extends Component {
  state = {
    changed: false, image: null
  };

  componentDidMount() {
    const { maps, rowIndex } = this.props;
    const { isNew, raster, vector } = maps[rowIndex];

    if (!isNew) {
      const imageName = (vector ? vector : raster);
      const myImage = document.getElementById(imageName);
      if (myImage) {
        fetch(`${process.env.REACT_APP_IPG_URL}/${LOGOS}/${imageName}`)
          .then((response) => response.blob())
          .then((myBlob) => {
            const objectURL = URL.createObjectURL(myBlob);
            myImage.src = objectURL;
            this.setState({ changed: !this.state.changed, image: myImage });
          });
      }
    }
  }

  handleEditDialog(type) {
    this.props.dispatch(updateSelectedLogo(this.props.rowIndex, type));
  }

  handleDeleteDialog() {
    const { dispatch, rowIndex, maps } = this.props;
    dispatch(updateDeleteRow(maps[rowIndex]));
  }

  render() {
    const {
      deleteEnabled, globalTranslations, key, maps, rowIndex,
    } = this.props;
    const { raster, sources, vector } = maps[rowIndex];

    let channelNamesTemp = '';
    if (sources) {
      Object.values(sources).forEach((value) => {
        if (!value.name) {
          return channelNamesTemp;
        }
        return (channelNamesTemp += `${value.name} | `);
      });
    }
    const channelNames = channelNamesTemp.trim().slice(0, -1);

    return (
      <SonifiPopover
        deleteDisabled={!deleteEnabled}
        deleteFunc={this.handleDeleteDialog.bind(this)}
        deleteTitle={globalTranslations.delete}
        editFunc={this.handleEditDialog.bind(this, 'edit')}
        editTitle={globalTranslations.edit}
        functionOneFunc={this.handleEditDialog.bind(this, 'read')}
        functionOneTitle={globalTranslations.view}
        key={key}
        showFunctionOne={true}
        tableRow={
          <Fragment>
            <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
              <img id={(vector ? vector : raster)} alt={(vector ? vector : raster)} style={{ maxWidth: '100%' }}></img>
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={(vector ? vector : raster)} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={channelNames} />
            </TableCell>
          </Fragment >
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  maps: state.logo.maps
});

LogoRow.propTypes = {
  deleteEnabled: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  key: PropTypes.number,
  maps: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(LogoRow);

