import moment from 'moment';
import { textHelper } from '../utils/textUtil';
export const LIBRARY_RESOURCE = 'library-messages';
export const PUBLISH_RESOURCE = 'messages';
export const NO_LIBRARY_ITEM = '-1';
export const NO_PUBLISH_ITEM = -1;
export const GENERIC_ERROR = -1;
export const DROPDOWN_ADD_NEW = -1;
export const NOT_FOUND = -1;

export const FORM_TYPE = {
  LIBRARY: 'message',
  PUBLISH: 'publish',
};

export const FORM_MODE = {
  ADD: 'add',
  EDIT: 'edit',
  VIEW: 'view',
  COPY: 'copy',
  DELETE: 'delete',
};

export const SOURCE_TYPE = {
  LIBRARY: 'library',
  INTEGRATION: 'integration',
};
export const DEFAULT_SOURCE_TYPE = SOURCE_TYPE.LIBRARY; // 'library';
export const EMPTY_LIBRARY_ITEM = {
  id: '',
  source_type: DEFAULT_SOURCE_TYPE,
  translations: [],
  user_name: '',
};

// -------------------------------------------------------------------
// Message Types
// -------------------------------------------------------------------
export const MESSAGE_TYPE = {
  ROOM: 'room',
  CONSUMER: 'consumer'
};

const MESSAGE_TYPES = [
  {
    value: MESSAGE_TYPE.ROOM,
    label: 'Room',
  },
  {
    value: MESSAGE_TYPE.CONSUMER,
    label: 'Consumer',
  }
];

export function getMessageTypeDisplayName(messageType) {
  if (!messageType) {
    return '';
  }

  return MESSAGE_TYPES.filter(((e) => e.value === messageType))[0].label;
}

// -------------------------------------------------------------------
// Takeover Types
// -------------------------------------------------------------------
export const TAKEOVER_TYPE = {
  NONE: 'none',
  ALL_ROOMS: 'all',
  OCCUPIED_ROOMS: 'occupied'
};

export const TAKEOVER_TYPE_ARRAY = [TAKEOVER_TYPE.NONE, TAKEOVER_TYPE.ALL_ROOMS, TAKEOVER_TYPE.OCCUPIED_ROOMS];
export const TAKEOVER_TYPES = [
  {
    value: TAKEOVER_TYPE.NONE,
    label: 'None',
  },
  {
    value: TAKEOVER_TYPE.ALL_ROOMS,
    label: 'All Rooms',
  },
  {
    value: TAKEOVER_TYPE.OCCUPIED_ROOMS,
    label: 'Occupied Rooms',
  }
];

export function getTakeoverTypeDisplayName(takeoverType) {
  if (!takeoverType) {
    return '';
  }

  return TAKEOVER_TYPES.filter(((e) => e.value === takeoverType))[0].label;
}

// -------------------------------------------------------------------
// Display Types
// -------------------------------------------------------------------
export const DISPLAY_TYPE = {
  TAKE_OVER: 'take_over',
  DISMISSIBLE: 'dismissible',
  TICKER: 'ticker',
  INBOX: 'inbox',
};
export const DISPLAY_TYPE_ARRAY = [
  DISPLAY_TYPE.TAKE_OVER, DISPLAY_TYPE.DISMISSIBLE, DISPLAY_TYPE.TICKER, DISPLAY_TYPE.INBOX
];
export const DISPLAY_TYPES = [
  {
    value: DISPLAY_TYPE.TAKE_OVER,
    label: 'Take Over',
  },
  {
    value: DISPLAY_TYPE.DISMISSIBLE,
    label: 'Dismissible',
  },
  {
    value: DISPLAY_TYPE.TICKER,
    label: 'Ticker',
  },
  {
    value: DISPLAY_TYPE.INBOX,
    label: 'Inbox',
  },
];

export function getDisplayTypeDisplayName(displayType) {
  if (!displayType) {
    return '';
  }

  return DISPLAY_TYPES.filter(((e) => e.value === displayType))[0].label;
}

// --------------------------------------------------------------------------
// VALIDATION
// --------------------------------------------------------------------------
export const requiredPublishFields = [
  'display_type',
  'end_timestamp',
  'nameDropdown',
  'neverExpires',
  'rooms',
  'start_timestamp',
  'startNow',
  'takeover_type'
];
export const requiredMessageFields = ['messageId', 'title', 'body'];

// export function validMessageId(value) {
//   if (value === undefined || value === null || value.trim() === '') {
//     return false;
//   }
//   return !textHelper(value.trim());
// }

// export function validDisplayType(value) {
//   return DISPLAY_TYPE_ARRAY.includes(value);
// }

// export function validTakeoverType(value) {
//   return TAKEOVER_TYPE_ARRAY.includes(value);
// }

export function validTranslations(translations, globalTranslations, translation) {
  const oneValidTranslation = {};
  if (translations && translations.length) {
    translations.forEach((item) => {
      if (item.title === null || item.title === undefined || item.title.trim() === '') {
        oneValidTranslation.title = translation.title;
      } else if (textHelper(item.title.trim())) {
        oneValidTranslation.title = globalTranslations.symbols;
      }

      if (item.body === null || item.body === undefined || item.body.trim() === '') {
        oneValidTranslation.body = translation.body;
      } else if (textHelper(item.body.trim())) {
        oneValidTranslation.body = globalTranslations.symbols;
      }
    });
  } else {
    oneValidTranslation.title = translation.title;
    oneValidTranslation.body = translation.body;
  }

  return oneValidTranslation;
}

export function isMessageExpired(item, timezone) {
  if ((!item.end_timestamp) ||
    (moment.utc(item.end_timestamp).tz(timezone).toDate() > moment.utc().tz(timezone).toDate())) {
    return false;
  } else {
    return true;
  }
}

export function hasMessagePublished(item, timezone) {
  const now = moment.utc().tz(timezone).toDate();
  const start = moment.utc(item.start_timestamp).tz(timezone).toDate();
  if (start < now) {
    return true;
  } else {
    return false;
  }
}

export function getNow(timezone) {
  return moment.utc().tz(timezone).format();
}
