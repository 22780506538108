export const BATCH_CHANGES = 'batch-site-release-updates';
export const CHANNEL_CHANGES = 'channel-changes';
export const CHANNEL_OPTIONS = 'channel-options';
export const CHANNEL_SETS = 'channel-sets';
export const CHANNELS = 'channels';
export const CLIENTS = 'clients';
export const CORE_CHANNELS = 'core-channels';
export const DELETE_ROOM_RANGE = 'rooms';
export const GLOBAL = 'global';
export const GROUP_CODES = 'group-codes';
export const GROUP_SERVICES = 'groups';
export const HIGH_HTTP = 300;
export const HL7_PMS_ROOM_CONFIG = 'hl7-pms-room-config';
export const ICP_RESOURCE = 'icps';
export const IPG_FILES = 'ipg-files';
export const IPG_SOURCES = 'ipg-sources';
export const LATE_CHECKOUT_PURCHASES = 'late-checkout-purchases';
export const LATE_CHECKOUTS = 'late-checkouts';
export const LINEUPS = 'lineups';
export const LOGOS = 'logos';
export const LOW_HTTP = 200;
export const MAPS = 'maps';
export const OPTIONS_RESOURCE = 'room-options';
export const PERMISSIONS = 'permissions';
export const PMS_LOGS = 'pms-logs';
export const PRODUCT_PARENTS = 'product-parents';
export const PURCHASE_CSV = 'purchase-logs-csv';
export const RELEASE_TEMPLATES = 'release-templates';
export const REQUESTS = 'requests';
export const ROLES = 'roles';
export const ROOM_RESOURCE = 'rooms';
export const SERVER_STREAM = 'serverstream-sources';
export const SITES = 'sites';
export const SOFTWARE_OPTIONS = 'software-options';
export const SOFTWARE_PACKAGES = 'software-packages';
export const SYSTEMS = 'systems';
export const TERM_COMMS_MONITOR = 'terminal-communications-monitor';
export const TERM_FIRMWARE = 'terminal-firmware';
export const TERM_MODELS = 'terminal-models';
export const TERM_MODELS_REPORT = 'terminal-models-report';
export const TERMINAL_FIRMWARE_OPTIONS = 'terminal-firmware-options';
export const TERMINAL_OPTIONS = 'terminal-options';
export const TERMINAL_RESOURCE = 'terminals';
export const TERMINAL_STATUSES = 'terminal-statuses';
export const TLX_RESOURCE = 'tlx-files';
export const USER_GROUPS = 'user-groups';
export const USER_LOGS = 'user-logs';
export const USERS = 'users';
