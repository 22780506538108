import { Dialog, DialogContent, Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel, SonifiText } from '../../../containers';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import { showAuditInfo } from '../actions/auditActions';

export class AuditDialog extends Component {
  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(showAuditInfo(-1));
    }
  }

  render() {
    const { auditInfo, auditDialogIndex, translations } = this.props;
    const {
      body, method, path, timestamp, username
    } = auditInfo[auditDialogIndex];

    return (
      <Dialog open={true} onClose={this.onCancel} fullWidth maxWidth="md">
        <SonifiModalHeader
          header={translations.dialog.title}
          onCancel={this.onCancel.bind(this)}
          onlyClose={true}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={3}>
              <SonifiText
                defaultValue={username}
                label={translations.grid.username}
                size="percent"
                disabled={true} />
            </Grid>
            <Grid item xs={3}>
              <SonifiText
                defaultValue={moment(timestamp).format('MM/DD/YYYY hh:mm a')}
                label={translations.grid.timestamp}
                size="percent"
                disabled={true} />
            </Grid>
            <Grid item xs={3}>
              <SonifiText
                defaultValue={method}
                label={translations.grid.method}
                size="percent"
                disabled={true} />
            </Grid>
            <Grid item xs={12}>
              <SonifiText
                defaultValue={path}
                label={translations.grid.path}
                size="percent"
                disabled={true} />
            </Grid>
            {method !== 'DELETE' &&
              <Grid item xs={12} style={{ border: '1px solid #d6d6d6', padding: '24px' }}>
                <SonifiLabel boldLabel={translations.grid.body} />
                <SonifiLabel label={`${JSON.stringify(body, null, 4)}`} />
              </Grid>}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  auditDialogIndex: state.auditReport.auditDialogIndex,
  auditInfo: state.auditReport.auditInfo,
  translations: state.auditReport.translations,
});

AuditDialog.propTypes = {
  auditDialogIndex: PropTypes.number,
  auditInfo: PropTypes.array,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(AuditDialog);
