export const CLIENT_CREDENTIALS = 'client_credentials';
export const JWT_BEARER = 'jwt_bearer';
export const AUTH_OPTIONS = [JWT_BEARER, CLIENT_CREDENTIALS];
export const SCOPE_OPTIONS = ['pms:read',
  'irc:read',
  'tms:read',
  'pms:write',
  'irc:write',
  'tms:write'];
export const BRAND_OPTIONS = [
  { name: 'None', value: 'None' },
  { name: 'Hilton Worldwide, Inc.', value: 'hilton' },
  { name: 'Hyatt Hotels Corporation', value: 'hyatt' },
  { name: 'Marriott International, Inc.', value: 'marriot' },
  { name: 'Walt Disney World Company', value: 'disney' }
];
export const CLIENT_TYPE_3RD_PARTY = '3rd Party';
export const CLIENT_TYPE_SONIFI = 'SONIFI';
export const CLIENT_TYPE_OPTIONS = [CLIENT_TYPE_3RD_PARTY, CLIENT_TYPE_SONIFI];
