import SearchIcon from '@mui/icons-material/Search';
import { Dialog, Grid, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiCheckbox from '../../../containers/SonifiCheckbox';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiText from '../../../containers/SonifiText';
import { checkSingleUserPermission } from '../../../utils/rolesUtil';
import { getRoleInfo, updateSelectedRole } from '../actions/roleActions';

class RoleDialog extends Component {
  state = {
    open: false,
    searchField: ''
  };

  handlePrivate() {
    console.log('Toggle Private?');
  }

  handleRoleSelection = (roleName) => ({ target: { checked } }) => {
    console.log('handleRoleSelection', roleName, checked);

    // (checked)
    //   ? this.props.dispatch(addRole(roleName))
    //   : this.props.dispatch(removeRole(roleName));
  };

  handleSearchFieldChange = ({ target: { value } }) => {
    this.setState({ searchField: value });
  };

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(updateSelectedRole(-1));
      this.props.dispatch(getRoleInfo(-1));
    }
  }

  onSubmit() {
    // this.props.dispatch(updateSelectedRole(-1));
    console.log('Update Role here');
  }

  render() {
    const {
      globalTranslations, roles, selectedRole, translations, getRoleLoading
    } = this.props;

    const title = (roles[selectedRole] ? roles[selectedRole].id : '');
    const description = (roles[selectedRole] ? roles[selectedRole].description : '');
    const isPrivate = (roles[selectedRole] ? !!roles[selectedRole].private : false);
    const myRoles = (roles[selectedRole] && roles[selectedRole].roles ? roles[selectedRole].roles : []);

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={this.onCancel.bind(this)}
        open={true}>
        {getRoleLoading
          ? <SonifiSpinner />
          : <Fragment>
            <SonifiModalHeader
              header={`${translations.title}: ${title}`}
              onCancel={this.onCancel.bind(this)}
              onSubmit={this.onSubmit.bind(this)}
              onlyClose={true}
              label={globalTranslations.save}
            />
            <DialogContent>
              <Typography><br />{description}</Typography>
              <SonifiCheckbox label="Private"
                value={isPrivate}
                onChange={this.handlePrivate}
                disabled
              />
              <Fragment>
                <SonifiText
                  defaultValue={this.state.searchField}
                  change={this.handleSearchFieldChange}
                  label="Role Filter"
                  icon={<SearchIcon />}
                  iconPosition="end"
                />
                <Grid container>
                  {roles[selectedRole].roles.length > 0 && roles[selectedRole].roles.map((role, index) => (
                    <Fragment key={index}>
                      {role.toLowerCase().includes(this.state.searchField.toLowerCase()) &&
                        <Grid item xs={4} >
                          <SonifiCheckbox label={role}
                            value={checkSingleUserPermission(role, myRoles)}
                            onChange={this.handleRoleSelection(role)}
                          />
                        </Grid>
                      }
                    </Fragment>
                  ))}
                </Grid>
              </Fragment>
            </DialogContent>
          </Fragment>}
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  getRoleLoading: state.roles.getRoleLoading,
  globalTranslations: state.global.translations.defaults,
  roles: state.roles.roles,
  selectedRole: state.roles.selectedRole,
  translations: state.roles.translations.editDialog
});

RoleDialog.propTypes = {
  dispatch: PropTypes.func,
  getRoleLoading: PropTypes.bool,
  globalTranslations: PropTypes.object,
  roles: PropTypes.array,
  selectedRole: PropTypes.number,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(RoleDialog);
