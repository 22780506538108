import { compareValues } from '../../../utils';
import {
  ANALOG_RF, AUX, AUX_CHANNEL, DIGITAL_RF, durations, IPG, IPTV, MAX_NUM_CHANNELS
} from '../constants/constants';
import {
  checkAnalogChannel, checkDigitalChannel, checkIntlAnalogChannel, checkIntlDigitalChannel, checkIpChannel,
  getColumnNames, getPlaceInLine, getTableHeaders
} from './channelImport';

/* eslint-disable max-params */
function getIpgObject(currChannel, channelType, siteNum, curdate, ipgSourceId, affiliate, callLetters, entry,
  channelDesc, duration) {
  if (currChannel[channelType] === IPG && ipgSourceId !== -1 && currChannel[ipgSourceId] !== '') {
    return (
      {
        source_id: (currChannel[ipgSourceId]),
        affiliate: (affiliate === -1 ? ' ' : currChannel[affiliate]),
        call_letters: (callLetters === -1 ? ' ' : currChannel[callLetters]),
        entry: null,
        description: null,
        duration: null
      });
  }

  let whatIsDuration = 'PT1H';
  if (duration !== -1) {
    const filteredDuration = durations.filter((e) => e.extra === currChannel[duration]);
    whatIsDuration = filteredDuration && filteredDuration.length > 0 ? filteredDuration[0].value : durations[0].value;
  }

  return (
    {
      source_id: `SONIFI-${siteNum}-${curdate}`,
      affiliate: '',
      call_letters: '',
      entry: (entry === -1 ? '' : currChannel[entry]),
      description: (channelDesc === -1 ? ' ' : `${currChannel[channelDesc]} `),
      duration: whatIsDuration
    }
  );
}

/* eslint-disable max-lines-per-function */
export function parseDownloadFileChannels(channelInfo, siteNum, channelTypes, channelHeaders, translations) {
  const channels = [];
  const channelNumbers = [];
  const aAnalogMod = [];
  const aDigital = [];
  const aIp = [];
  const titles = [];
  let areThereErrors = false,
    channelConfigErrors = false,
    hasAuxChan = false,
    hasAnalog = false,
    hasIntlAnalog = false,
    hasDigital = false,
    hasDvbC = false,
    hasDvbS = false,
    hasDvbT = false,
    hasIP = false;

  for (let i = 0; i < channelInfo[0].length; i++) {
    titles.push(channelInfo[0][i].toUpperCase().replace(new RegExp('"', 'g'), ''));
  }

  const now = new Date();
  let curdate = Math.round(now.getTime() / 1000);
  for (let i = 0; i < channelTypes.length; i++) {
    if (channelTypes[i].checked) {
      if (channelTypes[i].name === ANALOG_RF) {
        hasAnalog = true;
      } else if (channelTypes[i].name === DIGITAL_RF) {
        hasDigital = true;
      } else if (channelTypes[i].name === IPTV) {
        hasIP = true;
      }
    }
  }

  const channelNumber = getPlaceInLine(titles, 'Display');
  const channelName = getPlaceInLine(titles, 'Name');
  const channelDesc = getPlaceInLine(titles, 'Description');
  const ipgSourceId = getPlaceInLine(titles, 'ipg_source_id');
  const affiliate = getPlaceInLine(titles, 'affiliate');
  const callLetters = getPlaceInLine(titles, 'call_letters');
  const entry = getPlaceInLine(titles, 'entry');
  const channelType = getPlaceInLine(titles, 'channel_type');
  const duration = getPlaceInLine(titles, 'duration');
  const analogMod = getPlaceInLine(titles, 'analog_modulator');
  const analogTuning = getPlaceInLine(titles, 'analog_tuning');

  const digitalMod = getPlaceInLine(titles, 'digital_modulator');
  const digitalStream = getPlaceInLine(titles, 'digital_stream');
  const digitalTuning = getPlaceInLine(titles, 'digital_tuning');
  const digitalFormat = getPlaceInLine(titles, 'digital_video_format');
  const digitalEncryption = getPlaceInLine(titles, 'digital_encryption');

  const ipAddress = getPlaceInLine(titles, 'ip_address');
  const ipPort = getPlaceInLine(titles, 'ip_port');
  const ipStream = getPlaceInLine(titles, 'ip_stream');
  const ipFormat = getPlaceInLine(titles, 'ip_video_format');
  const ipEncryption = getPlaceInLine(titles, 'ip_encryption');

  const intlAnaType = getPlaceInLine(titles, 'intl_analog_carrier');
  const intlAnaFreq = getPlaceInLine(titles, 'intl_analog_frequency_khz');
  const intlAnaStd = getPlaceInLine(titles, 'intl_analog_standard');

  const dvbcFreq = getPlaceInLine(titles, 'intl_dvb_c_frequency_khz');
  const dvbcStream = getPlaceInLine(titles, 'intl_dvb_c_stream');
  const dvbcFormat = getPlaceInLine(titles, 'intl_dvb_c_video_format');
  const dvbcEncryption = getPlaceInLine(titles, 'intl_dvb_c_encryption');
  const dvbcMod = getPlaceInLine(titles, 'intl_dvb_c_modulation');
  const dvbcBand = getPlaceInLine(titles, 'intl_dvb_c_bandwidth');
  const dvbcSymbol = getPlaceInLine(titles, 'intl_dvb_c_symbol_rate');

  const dvbsFreq = getPlaceInLine(titles, 'intl_dvb_s_frequency_mhz');
  const dvbsStream = getPlaceInLine(titles, 'intl_dvb_s_stream');
  const dvbsFormat = getPlaceInLine(titles, 'intl_dvb_s_video_format');
  const dvbsEncryption = getPlaceInLine(titles, 'intl_dvb_s_encryption');
  const dvbsMod = getPlaceInLine(titles, 'intl_dvb_s_modulation');
  const dvbsBand = getPlaceInLine(titles, 'intl_dvb_s_bandwidth');
  const dvbsSymbol = getPlaceInLine(titles, 'intl_dvb_s_symbol_rate');
  const dvbsSatID = getPlaceInLine(titles, 'intl_dvb_s_satellite_id');
  const dvbsPolar = getPlaceInLine(titles, 'intl_dvb_s_polarization');
  const dvbsType = getPlaceInLine(titles, 'intl_dvb_s_type');

  const dvbtFreq = getPlaceInLine(titles, 'intl_dvb_t_frequency_khz');
  const dvbtStream = getPlaceInLine(titles, 'intl_dvb_t_stream');
  const dvbtFormat = getPlaceInLine(titles, 'intl_dvb_t_video_format');
  const dvbtEncryption = getPlaceInLine(titles, 'intl_dvb_t_encryption');
  const dvbtMod = getPlaceInLine(titles, 'intl_dvb_t_modulation');
  const dvbtBand = getPlaceInLine(titles, 'intl_dvb_t_bandwidth');
  const dvbtPlpID = getPlaceInLine(titles, 'intl_dvb_t_plp_id');


  let channelIssue = false;
  const channelsThatHaveIssues = [];

  for (let i = 1; i < channelInfo.length; i++) {
    const completelyEmpty = channelInfo[i].join('');
    if (completelyEmpty.length === 0) {
      continue;
    }

    if (channels.length >= MAX_NUM_CHANNELS) {
      break;
    }

    const currChannel = channelInfo[i].map((x) => x.replace(new RegExp('"', 'g'), ''));

    channelIssue = false;
    curdate++;

    const channel = {
      id: (channelNumber !== -1
        ? ((!isNaN(currChannel[channelNumber]) && currChannel[channelNumber].length > 0)
          ? parseInt(currChannel[channelNumber], 10)
          : -1)
        : i),
      name: (channelName === -1 ? '' : currChannel[channelName]),
      type: currChannel[channelType],
      locked: false,
      enabled: true,
      ipg: getIpgObject(currChannel, channelType, siteNum, curdate, ipgSourceId, affiliate, callLetters, entry,
        channelDesc, duration),
      error: {},
      toolTip: ''
    };

    if (channelName === -1 || channel.name === '') {
      areThereErrors = true;
      channel.error.name = `${translations.channelName} \n`;
      channel.toolTip += `${translations.channelName} \n`;
    }

    if (channel.id !== -1 && !channelNumbers.includes(channel.id)) {
      channelNumbers.push(channel.id);
    } else {
      let channelNumberError = channel.id === -1
        ? `${translations.channelName} \n`
        : `${channel.id} ${translations.duplicate} \n`;
      if (isNaN(currChannel[channelNumber])) {
        channelNumberError = `${translations.channelNumberIssue} \n`;
      }
      channel.error.id = channelNumberError;
      channel.toolTip += channelNumberError;
      areThereErrors = true;
      channelIssue = true;
    }

    if (hasAnalog && analogMod !== -1 && currChannel[analogMod] !== '' && currChannel[analogMod]) {
      const anaTune = (analogTuning !== -1 ? currChannel[analogTuning] : 'std');

      const aChan = checkAnalogChannel(currChannel[analogMod], anaTune, translations);
      if (!aAnalogMod.includes(`${aChan.modulator}`)) {
        aAnalogMod.push(`${aChan.modulator}`);
      } else {
        aChan.errorToolTip += `${aChan.modulator} ${translations.duplicate} \n`;
      }

      if (aChan.errorToolTip.length === 0) {
        delete (aChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        channel.error.analog = true;
        aChan.error = true;
        aChan.toolTip += `\n${aChan.errorToolTip}`;
        channel.toolTip += aChan.errorToolTip;
      }

      channel.analog = aChan;
    }
    if (hasAnalog && intlAnaFreq !== -1 && currChannel[intlAnaFreq] !== '') {
      const anaType = (intlAnaType !== -1 ? currChannel[intlAnaType] : 'analog_air');
      const anaStd = (intlAnaStd !== -1 ? currChannel[intlAnaStd] : 'PAL_BG');
      hasIntlAnalog = true;
      const intlAnaChan = checkIntlAnalogChannel(anaType, currChannel[intlAnaFreq], anaStd, translations);
      if (intlAnaChan.errorToolTip.length === 0) {
        delete (intlAnaChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        channel.error.intl_analog = true;
        intlAnaChan.error = true;
        intlAnaChan.toolTip += `\n${intlAnaChan.errorToolTip}`;
        channel.toolTip += intlAnaChan.errorToolTip;
      }
      channel.intl_analog = intlAnaChan;
    }


    if (digitalMod !== -1 && currChannel[digitalMod] !== '' &&
      digitalStream !== -1 && currChannel[digitalStream] !== '') {
      const digiMod = `${currChannel[digitalMod]}-${currChannel[digitalStream]}`;
      const digiTune = (digitalTuning !== -1 ? currChannel[digitalTuning] : 'atsc');
      const digiForm = (digitalFormat !== -1 ? currChannel[digitalFormat] : 'mpeg4');
      const digiEncrypt = (digitalEncryption !== -1 && currChannel[digitalEncryption]
        ? currChannel[digitalEncryption].toLowerCase()
        : 'proidiom');

      const dChan = checkDigitalChannel(digiMod, digiTune, digiForm, digiEncrypt, translations);

      if (!aDigital.includes(`${dChan.modulator}-${dChan.stream}`)) {
        aDigital.push(`${dChan.modulator}-${dChan.stream}`);
      } else {
        dChan.errorToolTip += `${dChan.modulator}-${dChan.stream} ${translations.duplicate} \n`;
      }

      if (dChan.errorToolTip.length === 0) {
        delete (dChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        dChan.error = true;
        dChan.toolTip += `\n${dChan.errorToolTip}`;
        channel.error.digital = true;
        channel.toolTip += dChan.errorToolTip;
      }

      if (hasDigital || (`${dChan.modulator}` === AUX_CHANNEL)) {
        channel.digital = dChan;
      }

      if (channel.digital && `${channel.digital.modulator}` === AUX_CHANNEL) {
        channel.type = AUX;
        hasAuxChan = true;
      }
    }

    if (dvbcFreq !== -1 && currChannel[dvbcFreq] !== '') {
      const freq = currChannel[dvbcFreq];
      const stream = currChannel[dvbcStream];
      const format = (dvbcFormat !== -1 ? currChannel[dvbcFormat] : 'mpeg4');
      const encrypt = (dvbcEncryption !== -1 && currChannel[dvbcEncryption]
        ? currChannel[dvbcEncryption].toLowerCase()
        : 'proidiom');
      const mod = (dvbcMod !== -1 ? currChannel[dvbcMod] : 'QAM256');
      const bandwidth = String((dvbcBand !== -1 ? currChannel[dvbcBand] : '8'));
      const symbol = currChannel[dvbcSymbol];
      const dvbcObj = {
        errorToolTip: '',
        toolTip: '',
        intlType: 'dvbc',
        carrier: 'digital_cable',
        frequency_khz: freq,
        stream,
        video_format: format,
        encryption: encrypt,
        modulation: mod,
        symbol_rate: symbol,
        bandwidth
      };
      hasDvbC = true;
      const dvbcChan = checkIntlDigitalChannel(dvbcObj, translations);
      if (dvbcChan.errorToolTip.length === 0) {
        delete (dvbcChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        dvbcChan.error = true;
        dvbcChan.toolTip += `\n${dvbcChan.errorToolTip}`;
        channel.error.digital = true;
        channel.toolTip += dvbcChan.errorToolTip;
      }
      channel.intl_dvb_c = dvbcChan;
    }

    if (dvbsFreq !== -1 && currChannel[dvbsFreq] !== '') {
      const freq = currChannel[dvbsFreq];
      const stream = currChannel[dvbsStream];
      const format = (dvbsFormat !== -1 ? currChannel[dvbsFormat] : 'mpeg4');
      const encrypt = (dvbsEncryption !== -1 && currChannel[dvbsEncryption]
        ? currChannel[dvbsEncryption].toLowerCase()
        : 'proidiom');
      const mod = (dvbsMod !== -1 ? currChannel[dvbsMod] : 'QAM256');
      const bandwidth = (dvbsBand !== -1 ? currChannel[dvbsBand] : '8');
      const symbol = currChannel[dvbsSymbol];
      const satID = currChannel[dvbsSatID];
      const polar = (dvbsPolar !== -1 ? currChannel[dvbsPolar] : 'left');
      const type = (dvbsType !== -1 ? currChannel[dvbsType] : 'digital_satellite');
      const dvbsObj = {
        errorToolTip: '',
        toolTip: '',
        intlType: 'dvbs',
        carrier: type,
        frequency_mhz: freq,
        stream,
        video_format: format,
        encryption: encrypt,
        modulation: mod,
        symbol_rate: symbol,
        bandwidth,
        satellite_id: satID,
        polarization: polar,
      };
      hasDvbS = true;
      const dvbsChan = checkIntlDigitalChannel(dvbsObj, translations);
      if (dvbsChan.errorToolTip.length === 0) {
        delete (dvbsChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        dvbsChan.error = true;
        dvbsChan.toolTip += `\n${dvbsChan.errorToolTip}`;
        channel.error.digital = true;
        channel.toolTip += dvbsChan.errorToolTip;
      }
      channel.intl_dvb_s = dvbsChan;
    }
    if (dvbtFreq !== -1 && currChannel[dvbtFreq] !== '') {
      const freq = currChannel[dvbtFreq];
      const stream = currChannel[dvbtStream];
      const format = (dvbtFormat !== -1 ? currChannel[dvbtFormat] : 'mpeg4');
      const encrypt = (dvbtEncryption !== -1 && currChannel[dvbtEncryption]
        ? currChannel[dvbtEncryption].toLowerCase()
        : 'proidiom');
      const mod = (dvbtMod !== -1 ? currChannel[dvbtMod] : 'QAM64');
      const bandwidth = (dvbtBand !== -1 ? currChannel[dvbtBand] : '8');
      const plpID = currChannel[dvbtPlpID];
      const dvbtObj = {
        errorToolTip: '',
        toolTip: '',
        intlType: 'dvbt',
        carrier: 'digital_air',
        frequency_khz: freq,
        stream,
        video_format: format,
        encryption: encrypt,
        modulation: mod,
        bandwidth,
        plp_id: plpID
      };
      hasDvbT = true;
      const dvbtChan = checkIntlDigitalChannel(dvbtObj, translations);
      if (dvbtChan.errorToolTip.length === 0) {
        delete (dvbtChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        dvbtChan.error = true;
        dvbtChan.toolTip += `\n${dvbtChan.errorToolTip}`;
        channel.error.digital = true;
        channel.toolTip += dvbtChan.errorToolTip;
      }
      channel.intl_dvb_t = dvbtChan;
    }

    if (hasIP && ipAddress !== -1 && ipPort !== -1 && ipStream !== -1 &&
      currChannel[ipAddress] !== '' && currChannel[ipPort] !== '' && currChannel[ipStream] !== ''
    ) {
      const iAddr = currChannel[ipAddress];
      const iPort = parseInt(currChannel[ipPort], 10);
      const iStream = parseInt(currChannel[ipStream], 10);
      const iFormat = (ipFormat === -1 || !currChannel[ipFormat] ? 'mpeg4' : currChannel[ipFormat]);
      const iEncryption = (ipEncryption === -1 || !currChannel[ipEncryption]
        ? 'proidiom'
        : currChannel[ipEncryption].toLowerCase());
      const ipChan = checkIpChannel(iAddr, iPort, iStream, iFormat, iEncryption, translations);

      const ipAddStream = `${ipChan.address}:${ipChan.port}`;
      if (!aIp.includes(ipAddStream)) {
        aIp.push(ipAddStream);
      } else {
        ipChan.errorToolTip += `${ipAddStream} ${translations.duplicate} \n`;
      }

      if (ipChan.errorToolTip.length === 0) {
        delete (ipChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        ipChan.error = true;
        ipChan.toolTip += `\n${ipChan.errorToolTip}`;
        channel.error.ip = true;
        channel.toolTip += ipChan.errorToolTip;
      }
      channel.ip = ipChan;
    }

    if (channel.digital) {
      if (channel.digital && channel.digital.modulator === parseInt(AUX_CHANNEL, 10)
        && (channel.analog || channel.ip)) {
        channelIssue = true;
        if (channel.analog) {
          // if (!channel.analog.error) {
          channel.analog.error = true;
          channel.error.analog = true;
          channelConfigErrors = true;
          channelIssue = true;

          // }
          channel.toolTip += `\n ${translations.auxAnalog} \n`;
          channel.analog.toolTip += `\n ${translations.auxAnalog} \n`;
        }
        if (channel.ip) {
          // if (!channel.ip.error) {
          channel.ip.error = true;
          channel.error.ip = true;
          channelConfigErrors = true;
          channelIssue = true;

          // }
          channel.toolTip += `\n ${translations.auxIP} \n`;
          channel.ip.toolTip += `\n ${translations.auxIP} \n`;
        }
      }
    }

    if (channelIssue) {
      channelsThatHaveIssues.push(channel.id);
    }

    if (Object.keys(channel.error).length === 0) {
      delete (channel.error);
      delete channel.toolTip;
    }
    channels.push(channel);
  }

  if (channelNumbers.length === 0) {
    for (let i = 0; i < channels.length; i++) {
      channels[i].id = (i + 1);
      if (channels[i].error.length > 0) {
        channels[i].error.shift();
        if (channels[i].error.length === 0) {
          delete (channels[i].error);
        }
      }
    }
    areThereErrors = false;
  } else if (!areThereErrors) {
    channels.sort(compareValues('id'));
  }

  return {
    channels,
    channelNumberError: areThereErrors,
    configErrors: channelConfigErrors,
    tableHeader: getTableHeaders(
      channelHeaders, hasAnalog, hasIntlAnalog, (hasAuxChan || hasDigital), hasIP, hasDvbC, hasDvbS, hasDvbT
    ),
    columnNames: getColumnNames(hasAnalog, hasIntlAnalog, (hasAuxChan || hasDigital), hasIP, hasDvbC, hasDvbS, hasDvbT),
  };
}
