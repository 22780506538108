export const ADD_PACKAGE_ROW_BEGIN = 'ADD_PACKAGE_ROW_BEGIN';
export const ADD_PACKAGE_ROW_FAILURE = 'ADD_PACKAGE_ROW_FAILURE';
export const ADD_PACKAGE_ROW_SUCCESS = 'ADD_PACKAGE_ROW_SUCCESS';
export const ADD_TEMPLATE_ROW_BEGIN = 'ADD_TEMPLATE_ROW_BEGIN';
export const ADD_TEMPLATE_ROW_FAILURE = 'ADD_TEMPLATE_ROW_FAILURE';
export const ADD_TEMPLATE_ROW_SUCCESS = 'ADD_TEMPLATE_ROW_SUCCESS';
export const CLEAR_TABLE = 'CLEAR_TABLE';
export const CREATE_NEW_DEPLOYMENT = 'CREATE_NEW_DEPLOYMENT';
export const DELETE_CHECK = 'DELETE_TEMPLATE_CHECK';
export const DELETE_PACKAGE_ROW = 'DELETE_PACKAGE_ROW';
export const DELETE_TEMPLATE_ROW_BEGIN = 'DELETE_TEMPLATE_ROW_BEGIN';
export const DELETE_TEMPLATE_ROW_FAILURE = 'DELETE_TEMPLATE_ROW_FAILURE';
export const DELETE_TEMPLATE_ROW_SUCCESS = 'DELETE_TEMPLATE_ROW_SUCCESS';
export const EDIT_TEMPLATE_ROW_BEGIN = 'EDIT_TEMPLATE_ROW_BEGIN';
export const EDIT_TEMPLATE_ROW_FAILURE = 'EDIT_TEMPLATE_ROW_FAILURE';
export const EDIT_TEMPLATE_ROW_SUCCESS = 'EDIT_TEMPLATE_ROW_SUCCESS';
export const GET_RELEASE_TEMPLATE_BEGIN = 'GET_RELEASE_TEMPLATE_BEGIN';
export const GET_RELEASE_TEMPLATE_FAILURE = 'GET_RELEASE_TEMPLATE_FAILURE';
export const GET_RELEASE_TEMPLATE_SUCCESS = 'GET_RELEASE_TEMPLATE_SUCCESS';
export const GET_RELEASE_TEMPLATES_BEGIN = 'GET_RELEASE_TEMPLATES_BEGIN';
export const GET_RELEASE_TEMPLATES_FAILURE = 'GET_RELEASE_TEMPLATES_FAILURE';
export const GET_RELEASE_TEMPLATES_SUCCESS = 'GET_RELEASE_TEMPLATES_SUCCESS';
export const GET_SOFTWARE_OPTIONS_BEGIN = 'GET_SOFTWARE_OPTIONS_BEGIN';
export const GET_SOFTWARE_OPTIONS_FAILURE = 'GET_SOFTWARE_OPTIONS_FAILURE';
export const GET_SOFTWARE_OPTIONS_SUCCESS = 'GET_SOFTWARE_OPTIONS_SUCCESS';
export const GET_TEMPLATE_PACKAGES_BEGIN = 'GET_TEMPLATE_PACKAGES_BEGIN';
export const GET_TEMPLATE_PACKAGES_FAILURE = 'GET_TEMPLATE_PACKAGES_FAILURE';
export const GET_TEMPLATE_PACKAGES_SUCCESS = 'GET_TEMPLATE_PACKAGES_SUCCESS';
export const IMMUTABLE_CHECK = 'IMMUTABLE_CHECK';
export const MAKE_IMMUTABLE_BEGIN = 'MAKE_IMMUTABLE_BEGIN';
export const MAKE_IMMUTABLE_FAILURE = 'MAKE_IMMUTABLE_FAILURE';
export const MAKE_IMMUTABLE_SUCCESS = 'MAKE_IMMUTABLE_SUCCESS';
export const RELEASE_SAVING = 'RELEASE_SAVING';
export const SET_TEMPLATE_PACKAGES = 'SET_TEMPLATE_PACKAGES';
export const UPDATE_SELECTED_PACKAGE_ROW = 'UPDATE_SELECTED_PACKAGE_ROW';
export const UPDATE_SELECTED_RELEASE_TEMPLATE = 'UPDATE_SELECTED_RELEASE_TEMPLATE';
export const UPDATE_SNACK_BAR = 'RELEASE_UPDATE_SNACK_BAR';
export const UPDATE_TEMPLATE_PACKAGES = 'UPDATE_TEMPLATE_PACKAGES';
