import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSiteNumFromURI } from '../../utils';
import SoftwareTabs from './components/SoftwareTabs';

export default function SoftwareManagement() {
  if (getSiteNumFromURI() === null) {
    return <Navigate replace to="/" />;
  }

  return (
    <SoftwareTabs />
  );
}
