import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSiteNumFromURI } from '../../utils';
import ChannelsGrid from './components/ChannelsGrid';

export default function Channels() {
  if (getSiteNumFromURI() === null) {
    return <Navigate replace to="/" />;
  }
  return (
    <ChannelsGrid />
  );
}
