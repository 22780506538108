import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import IMG from '../images/Background-Login.jpg';

const styles = {
  bkg: {
    '-moz-background-size': 'cover',
    '-o-background-size': 'cover',
    '-webkit-background-size': 'cover',
    'background-position': 'center',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    'left': '0',
    'position': 'absolute',
    'width': '100%',
    'zIndex': '-1'
  }
};

class Background extends Component {
  render() {
    const { classes } = this.props;
    return (
      <img alt="SONIFI" className={classes.bkg} src={IMG} />
    );
  }
}

Background.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(Background, styles, { withTheme: true });
