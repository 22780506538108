import { filter } from 'lodash';
import { ROOM_RESOURCE, TERMINAL_RESOURCE } from '../../../constants/apiEndpoints';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import { findObjectByKey } from '../../../utils';
import { getObjectPromise, getSiteObjectPromise } from '../../../utils/api';
import { updateTerminal } from '../../../utils/terminal';
import { getTerminalInfo } from '../../Rooms/actions/terminalActions';
import { getAssignedLocations } from '../../Rooms/reducers/terminalsReducer';
import * as types from './actionTypes';

export const resetProvisionLink = () => ({
  type: types.RESET_PROVISION_LINK
});

export const updateProvisionLinkSnackbar = (snackBarType, snackBarMessage) => ({
  type: types.UPDATE_PROVISION_LINK,
  snackBarType,
  snackBarMessage
});

export const getProvisionTermBegin = () => ({
  type: types.GET_PROVISION_TERM_INFO_BEGIN,
});

export const getProvisionTermSuccess = (info) => ({
  type: types.GET_PROVISION_TERM_INFO_SUCCESS,
  info
});

export const getProvisionTermFailure = (error) => ({
  type: types.GET_PROVISION_TERM_INFO_FAILURE,
  error
});

export function getProvisionTerm(terminalAddress) {
  return (dispatch) => {
    dispatch(getProvisionTermBegin());

    return getObjectPromise(TERMINAL_RESOURCE, terminalAddress)
      .then((data) => {
        if (data.room_id) {
          dispatch(updateProvisionLinkSnackbar(
            ERROR,
            `Terminal ${data.id} already assigned for room ${data.room_id} at site ${data.site_id}`
          ));
        } else {
          dispatch(getProvisionTermSuccess(data));
        }
        return data;
      })
      .catch((error) => error.json())
      .then((response) => {
        if (response.detail) {
          dispatch(getProvisionTermFailure(response.detail));
          throw response;
        }
        return response;
      });
  };
}

export const getSiteRoomsBegin = () => ({
  type: types.GET_SITE_ROOMS_BEGIN,
});

export const getSiteRoomsSuccess = (info) => ({
  type: types.GET_SITE_ROOMS_SUCCESS,
  info
});


export const getProvRoomTerminalsSuccess = (terminalData, siteInfo) => ({
  type: types.GET_PROVISION_ROOM_TERMINALS_SUCCESS,
  terminalData,
  siteInfo
});

export const doesRoomExistBegin = () => ({
  type: types.DOES_ROOM_EXIST_BEGIN
});

export function doesRoomExist(site, room) {
  return (dispatch) => {
    dispatch(doesRoomExistBegin());
    return getSiteObjectPromise(ROOM_RESOURCE, `${room}`, site)
      .then((data) => {
        if (!data.status) {
          return true;
        }
        return httpSuccess(data.status);
      }).catch(() => false);
  };
}

export function getProvRoomTerminals(site, room, siteInfo) {
  return (dispatch) => {
    dispatch(getSiteRoomsBegin());
    return getSiteObjectPromise(ROOM_RESOURCE, `${room}/terminals`, site)
      .then((data) => {
        const miscObj = getAssignedLocations({ ...siteInfo }, data.terminals);
        siteInfo.locations = filter(miscObj, (o) => !o.terminalId || o.terminalId === null);
        dispatch(getProvRoomTerminalsSuccess(data.terminals, siteInfo));
        return siteInfo;
      }).catch((error) => error);
  };
}

export function addTerminalToRoom(roomState, roomTermLocations) {
  return (dispatch) => dispatch(getTerminalInfo(roomState.id, roomState.siteId))
    .then((data) => {
      const putSomething = { ...data };
      putSomething.room_id = roomState.room.trim();
      putSomething.location = findObjectByKey(roomTermLocations, 'id', roomState.termLocation).name;
      putSomething.menuset = roomState.termMenuSet;
      putSomething.lineup_id = roomState.termLineup;
      putSomething.language = (roomState.language ? roomState.language : 'en');

      return updateTerminal(putSomething, roomState.siteId)
        .then((response) => {
          const isError = response.includes('Error');
          dispatch(updateProvisionLinkSnackbar(
            isError ? ERROR : SUCCESS,
            isError
              ? response
              : `Terminal ${roomState.id} successfully assigned to room ${putSomething.room_id} at site ${data.site_id}`
          ));
          return isError;
        }).catch((error) => {
          dispatch(updateProvisionLinkSnackbar(
            ERROR,
            `Error adding Terminal ${roomState.id} to room ${putSomething.room_id} at site ${data.site_id}`
          ));
          return error;
        });
    }).catch((error) => error);
}
