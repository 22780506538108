import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { findObjectByKey } from '../../../utils/index';

class OtherInfoCard extends Component {
  render() {
    const {
      getItem, helperClass, lineups, selectedTerm, translations
    } = this.props;
    const lineupName = findObjectByKey(lineups, 'id', selectedTerm.lineup_id) || { name: '' };

    return (
      <Fragment>
        <Grid container justifyContent="space-between" className={helperClass.card} >
          {getItem(translations.channels.lineup, lineupName.name)}
          {getItem(translations.channels.powerOnChan, selectedTerm.power_on_channel)}
        </Grid>
        <Grid container justifyContent="space-between" className={helperClass.card} >
          {getItem(translations.terminalInfo.vHardware, selectedTerm.hardware)}
          {getItem(translations.terminalInfo.vSoftware, selectedTerm.software)}
          {getItem(translations.terminalInfo.encryption, selectedTerm.encryption)}
          {getItem(translations.terminalInfo.tunerType, selectedTerm.tuner_type)}
          {getItem(translations.terminalInfo.vFirmware, selectedTerm.firmware)}
          {getItem(translations.terminalInfo.videoFormat, selectedTerm.mpeg_format)}
          {getItem(translations.terminalInfo.tvControl, selectedTerm.tv_control)}

        </Grid>
        <Grid container justifyContent="space-between" className={helperClass.card} >
          {getItem(translations.terminalInfo.cables, selectedTerm.cables)}
          {getItem(translations.terminalInfo.ccPort, selectedTerm.ccPort)}
        </Grid>
        <Grid container justifyContent="space-between" className={helperClass.card} >
          {getItem(translations.terminalInfo.wifiSSID, selectedTerm.wifiSsid || '')}
          {getItem(translations.terminalInfo.wifiRSSI, selectedTerm.wifiRssi || '')}
          {getItem(translations.terminalInfo.wifiChannel, selectedTerm.wifiChannel || '')}
        </Grid>

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.termGrid.changed,
  lineups: state.termGrid.lineups,
  selectedTerm: state.termGrid.selectedTerm,
  translations: state.termGrid.translations
});

OtherInfoCard.propTypes = {
  getItem: PropTypes.func,
  helperClass: PropTypes.object,
  lineups: PropTypes.array,
  selectedTerm: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(OtherInfoCard);
