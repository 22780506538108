import { GetApp } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiPopover from '../../../../containers/SonifiPopover';
import { editTLXFile, exportTLXFile, updateDeleteTlxRow } from '../../actions/tlxFileActions';

class TLXFileRow extends Component {
  handleExportAction() {
    const { dispatch, tlxFile } = this.props;
    dispatch(exportTLXFile(tlxFile.filename));
  }

  handleEditDialog() {
    this.props.dispatch(editTLXFile(true));
  }

  tlxRow() {
    const { tlxFile } = this.props;
    return (
      <Fragment>
        <TableCell>{tlxFile.filename}</TableCell>
        <TableCell>{tlxFile.md5}</TableCell>
        <TableCell>{moment(tlxFile.uploaded).format('MM/DD/YYYY h:mm a')}</TableCell>
      </Fragment >
    );
  }

  handleDeleteDialog() {
    const { dispatch, tlxFile } = this.props;
    dispatch(updateDeleteTlxRow(tlxFile.filename));
  }

  render() {
    const { canEdit, globalTranslations, rowIndex } = this.props;
    return (
      canEdit
        ? <SonifiPopover
          showFunctionTwo={true}
          functionTwoFunc={this.handleExportAction.bind(this)}
          functionTwoIcon={<GetApp fontSize="small" />}
          functionTwoTitle={globalTranslations.export}
          editFunc={this.handleEditDialog.bind(this)}
          editTitle={globalTranslations.edit}
          deleteFunc={this.handleDeleteDialog.bind(this)}
          deleteTitle={globalTranslations.delete}
          key={rowIndex}
          tableRow={this.tlxRow()}
        />
        : <TableRow> {this.tlxRow()} </TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.termFile.changed,
  globalTranslations: state.global.translations.defaults,
  groups: state.groups.groups,
  userPermissions: state.global.permissions
});

TLXFileRow.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  rowIndex: PropTypes.number,
  tlxFile: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(TLXFileRow);
