export const PRODUCTION = 'Production';
export const ALPHA = 'Alpha';
export const BETA = 'Beta';
export const INHOUSE = 'Inhouse';
export const TESTING = 'Testing';
export const DEVELOPMENT = 'Development';

export const PROD = { label: PRODUCTION, value: PRODUCTION.toLowerCase() };
export const AL = { label: ALPHA, value: ALPHA.toLowerCase() };
export const BE = { label: BETA, value: BETA.toLowerCase() };
export const INH = { label: INHOUSE, value: INHOUSE.toLowerCase() };
export const TEST = { label: TESTING, value: TESTING.toLowerCase() };
export const DEVEL = { label: DEVELOPMENT, value: DEVELOPMENT.toLowerCase() };

export const PROD_OPT = [PROD];
export const BETA_OPT = [BE, PROD];
export const ALPHA_OPT = [AL, BE];
export const INHOUSE_OPT = [INH, AL];
export const TESTING_OPT = [TEST, INH];
export const DEVEL_OPT = [DEVEL, TEST];

export function getStatusOptions(channel) {
  if (channel === PRODUCTION) {
    return PROD_OPT;
  } else if (channel === BETA) {
    return BETA_OPT;
  } else if (channel === ALPHA) {
    return ALPHA_OPT;
  } else if (channel === INHOUSE) {
    return INHOUSE_OPT;
  } else if (channel === TESTING) {
    return TESTING_OPT;
  }
  return DEVEL_OPT;
}

export function currentStatus(status) {
  return `${status.charAt(0).toUpperCase()}${status.slice(1)}`;
}
