import React, { Component } from 'react';
import Background from '../../../img/HomeImageOnly.png';
import { withStyles } from 'tss-react/mui';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearSite } from '../../../v2/GlobalSlices/siteSlice';

const styles = () => ({
  backgroundImageStyle: {
    alignItems: 'center',
    backgroundImage: `url(${Background})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw'
  },
});

class GettingStarted extends Component {
  constructor(props) {
    super(props);
  }

    componentDidMount() {
      this.props.dispatch(clearSite());
    }

    render() {
        const { classes } = this.props;
        return (<div
            style={{ position: 'relative', zIndex: '2' }}
            className={classes.backgroundImageStyle} data-testid={'gettingStartedBackground'}
            />);
    }
}

GettingStarted.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};


export default connect()(withStyles(GettingStarted, styles));

