export const ANALOG = 'analog';
export const ANALOG_MOD_MAX = '126';
export const ANALOG_RF = 'Analog RF';
export const ATSC = 'atsc';
export const AUX = 'aux';
export const AUX_CHANNEL = '254';
export const BINARY_ALL_ONES = 65535;
export const CORE = 'core';
export const DIGI_MOD_MAX = '159';
export const DIGITAL = 'digital';
export const DIGITAL_RF = 'Digital RF';
export const DVB_C = 'intl_dvb_c';
export const DVB_S = 'intl_dvb_s';
export const DVB_T = 'intl_dvb_t';
export const INTL_ANALOG = 'intl_analog';
export const IP = 'ip';
export const IPG = 'ipg';
export const IPTV = 'IPTV';
export const MAX_CHANNEL_NUMBER = 9999;
export const MAX_DESC_COUNT = 256;
export const MAX_FREQUENCY_KHZ = 1000001;
export const MAX_FREQUENCY_MHZ = 100001;
export const MAX_IP = 65535;
export const MAX_NUM_CHANNELS = 254;
export const MAX_TITLE_COUNT = 64;
export const MIN_FREQUENCY_KHZ = 39999;
export const MIN_FREQUENCY_MHZ = 999;
export const PROPERTY = 'property';
export const SERVER_STREAM = 'serverstream';

export const PROIDIOM = ['PRO-IDIOM', 'PROIDIOM'];

export const channelTypes = [
  { label: 'Property', value: PROPERTY },
  { label: 'IPG', value: IPG },
  { label: 'Auxiliary', value: AUX }
];

export const STREAM_TYPE = { label: 'Server Stream', value: SERVER_STREAM };
export const CORE_TYPE = { label: 'Core', value: CORE };

export const digitalAuxiliary = [
  { label: 'Composite', value: '00001' },
  { label: 'S-Video', value: '00010' },
  { label: 'Component', value: '00011' },
  { label: 'VGA', value: '00100' },
  { label: 'HDMI', value: '00101' },
];

export const digitalSource = [
  { label: '1', value: '000' },
  { label: '2', value: '001' },
  { label: '3', value: '010' },
  { label: '4', value: '011' },
  { label: '5', value: '100' },
  { label: '6', value: '101' },
  { label: '7', value: '110' },
  { label: '8', value: '111' },
];

export const durations = [
  { label: '1 Hour', value: 'PT1H', extra: '01:00:00' },
  { label: '2 Hours', value: 'PT2H', extra: '02:00:00' },
  { label: '3 Hours', value: 'PT3H', extra: '03:00:00' },
  { label: '4 Hours', value: 'PT4H', extra: '04:00:00' },
  { label: '6 Hours', value: 'PT6H', extra: '06:00:00' },
  { label: '12 Hours', value: 'PT12H', extra: '12:00:00' }
];

export const analogTuningValues = [
  { label: 'hrc', value: 'hrc' },
  { label: 'irc', value: 'irc' },
  { label: 'jpn', value: 'jpn' },
  { label: 'std', value: 'std' }
];

export const analogStandardValues = [
  { label: 'NTSC', value: 'NTSC' },
  { label: 'PAL_BG', value: 'PAL_BG' },
  { label: 'PAL_DK', value: 'PAL_DK' },
  { label: 'PAL_I', value: 'PAL_I' },
  { label: 'PAL_M', value: 'PAL_M' },
  { label: 'PAL_N', value: 'PAL_N' },
  { label: 'SECAM_BG', value: 'SECAM_BG' },
  { label: 'SECAM_DK', value: 'SECAM_DK' },
  { label: 'SECAM_L', value: 'SECAM_L' }
];

export const analogTypeValues = [
  { label: 'analog_air', value: 'analog_air' },
  { label: 'analog_cable', value: 'analog_cable' }
];

export const mpegFormatValues = [
  { label: 'mpeg2', value: 'mpeg2' },
  { label: 'mpeg4', value: 'mpeg4' },
  { label: 'hevc', value: 'hevc' }
];

export const encryptionValues = [
  { label: 'proidiom', value: 'proidiom' },
  { label: 'lynk', value: 'lynk' },
  { label: 'none', value: 'none' }
];

export const digitalTuningValues = [
  { label: 'atsc', value: 'atsc' },
  { label: 'dvb-c', value: 'intl_dvb_c' },
  { label: 'dvb-s', value: 'intl_dvb_s' },
  { label: 'dvb-t', value: 'intl_dvb_t' }
];

export const digitalTypeValues = [
  { label: 'digital_satellite', value: 'digital_satellite' },
  { label: 'digital_satellite2', value: 'digital_satellite2' }
];

export const bandwidthValues = [
  { label: '1.7 MHz', value: '1.7' },
  { label: '5 MHz', value: '5' },
  { label: '6 MHz', value: '6' },
  { label: '7 MHz', value: '7' },
  { label: '8 MHz', value: '8' },
  { label: '9 MHz', value: '9' },
  { label: '10 MHz', value: '10' }
];

export const dvbtModulationValues = [
  { label: 'OFDM', value: 'OFDM' },
  { label: 'OFDM_T2', value: 'OFDM_T2' },
  { label: 'QPSK', value: 'QPSK' },
  { label: 'QAM16', value: 'QAM16' },
  { label: 'QAM64', value: 'QAM64' },
  { label: 'QAM256', value: 'QAM256' }
];

export const dvbcAndsModulationValues = [
  { label: 'QPSK', value: 'QPSK' },
  { label: 'QAM', value: 'QAM' },
  { label: 'QAM4', value: 'QAM4' },
  { label: 'QAM8', value: 'QAM8' },
  { label: 'QAM16', value: 'QAM16' },
  { label: 'QAM32', value: 'QAM32' },
  { label: 'QAM64', value: 'QAM64' },
  { label: 'QAM128', value: 'QAM128' },
  { label: 'QAM256', value: 'QAM256' }
];

export const analogTypes = [
  { label: 'ntsc', value: 'analog' },
  { label: 'international analog', value: 'intl_analog' }
];
export const intlAnalogTypes = [
  { label: 'analog_air', value: 'analog_air' },
  { label: 'analog_cable', value: 'analog_cable' }
];

export const polarizationValues = [
  { label: 'vertical', value: 'vertical' },
  { label: 'horizontal', value: 'horizontal' },
  { label: 'right', value: 'right' },
  { label: 'left', value: 'left' }
];

export const satelliteTypes = [
  { label: 'digital_satellite', value: 'digital_satellite' },
  { label: 'digital_satellite2', value: 'digital_satellite2' }
];

export const availableTimezones = [{ label: 'ALL', value: ' ' },
{ label: 'AKS', value: 'AKS' },
{ label: 'AST', value: 'AST' },
{ label: 'AT', value: 'AT' },
{ label: 'Baghdad', value: 'Baghdad' },
{ label: 'Bering', value: 'Bering' },
{ label: 'BZD', value: 'BZD' },
{ label: 'CED', value: 'CED' },
{ label: 'China Coast', value: 'China Coast' },
{ label: 'CST', value: 'CST' },
{ label: 'CT', value: 'CT' },
{ label: 'EET', value: 'EET' },
{ label: 'ET', value: 'ET' },
{ label: 'GMT', value: 'GMT' },
{ label: 'Guam', value: 'Guam' },
{ label: 'HST', value: 'HST' },
{ label: 'Indian', value: 'Indian' },
{ label: 'JPT', value: 'JPT' },
{ label: 'MSK', value: 'MSK' },
{ label: 'MSK+1', value: 'MSK+1' },
{ label: 'MSK+2', value: 'MSK+2' },
{ label: 'MT', value: 'MT' },
{ label: 'NST', value: 'NST' },
{ label: 'PT', value: 'PT' },
{ label: 'S. Sumatra', value: 'S. Sumatra' },
{ label: 'Venezuela', value: 'Venezuela' }];
