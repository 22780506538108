export const ADD_USER_BEGIN = 'ADD_USER_BEGIN';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const CLEAR_ALL_USERS = 'CLEAR_ALL_USERS';
export const DELETE_CHECK = 'DELETE_CHECK';
export const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DISPLAY_SORTED_USERS = 'DISPLAY_SORTED_USERS';
export const FETCH_USERS_BEGIN = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FILTER_SITES_BEGIN = 'FILTER_SITES_BEGIN';
export const FILTER_SITES_FAILURE = 'FILTER_SITES_FAILURE';
export const FILTER_SITES_SUCCESS = 'FILTER_SITES_SUCCESS';
export const GET_SITES_BEGIN = 'GET_SITES_BEGIN';
export const GET_SITES_FAILURE = 'GET_SITES_FAILURE';
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
export const GET_USER_SITES_BEGIN = 'GET_USER_SITES_BEGIN';
export const GET_USER_SITES_FAILURE = 'GET_USER_SITES_FAILURE';
export const GET_USER_SITES_SUCCESS = 'GET_USER_SITES_SUCCESS';
export const INITIAL_LOAD_TRIGGERED = 'INITIAL_LOAD_TRIGGERED';
export const RESEND_PASSWORD_BEGIN = 'RESEND_PASSWORD_BEGIN';
export const RESEND_PASSWORD_FAILURE = 'RESEND_PASSWORD_FAILURE';
export const RESEND_PASSWORD_SUCCESS = 'RESEND_PASSWORD_SUCCESS';
export const SET_USER_FILTER = 'SET_USER_FILTER';
export const SORT_USERS_BEGIN = 'SORT_USERS_BEGIN';
export const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER';
export const UPDATE_SNACK_BAR = 'USER_UPDATE_SNACK_BAR';
