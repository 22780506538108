import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiSection from '../../../containers/SonifiSection';
import SonifiText from '../../../containers/SonifiText';

class ScheduleDetails extends Component {
  state = { data: null };

  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  getBodyInfo() {
    const { data } = this.state;
    const { scheduleId, translations } = this.props;

    return (
      <Fragment>
        <SonifiText
          label={translations.labels.configId}
          defaultValue={scheduleId}
          disabled={true}
          size="md"
        />
        <SonifiText
          label={translations.labels.scheduleId}
          defaultValue={data.id}
          disabled={true}
          size="md"
        />
        <SonifiText
          label={translations.labels.releaseStatus}
          defaultValue={data.status}
          disabled={true}
          size="md"
        />
        <SonifiText
          label={translations.labels.timezone}
          defaultValue={this.props.timezone}
          disabled={true}
          size="md"
        />
      </Fragment>
    );
  }

  render() {
    const { data } = this.state;
    const { sectionLabel } = this.props;

    if (!data) {
      return <Fragment></Fragment>;
    }

    return (
      <SonifiSection title={sectionLabel} body={this.getBodyInfo()} />
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.systemManagement.translations,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago')
});

ScheduleDetails.propTypes = {
  translations: PropTypes.object,
  sectionLabel: PropTypes.string.isRequired,
  scheduleId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  timezone: PropTypes.string
};

export default connect(mapStateToProps)(ScheduleDetails);
