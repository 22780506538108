import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { SONIFI } from '../../../constants/constants';
import { FIRMWARE_EDIT_ROLES, SITE_ROLES } from '../../../constants/roleGroups';
import { SITE_TLX_WRITE } from '../../../constants/roles';
import SonifiTabs from '../../../containers/SonifiTabs';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../../utils/rolesUtil';
import { LG, SAMSUNG, tabsArray } from '../constants/TermFileConstants';
import LgClientGrid from '../containers/LG/LgClientGrid';
import LgFirmwareGrid from '../containers/LG/LgFirmwareGrid';
import TLXFileGrid from '../containers/LG/TLXFileGrid';
import SamsungClientGrid from '../containers/SAMSUNG/SamsungClientGrid';
import SamsungFirmwareGrid from '../containers/SAMSUNG/SamsungFirmwareGrid';
import SonifiFirmwareGrid from '../containers/SONIFI/SonifiFirmwareGrid';
import AddModelDialog from './AddModelDialog';

class TermTabs extends Component {
  state = {
    value: LG
  };

  handleChange(event, newValue) {
    if (newValue !== this.state.value) {
      this.changeTab(newValue);
    }
  }

  changeTab(newValue) {
    this.setState({ value: newValue });
  }

  getTabContainer() {
    const { userPermissions } = this.props;
    let canEditTLX = false;
    const canEdit = checkForAtLeastOneUserPermission(FIRMWARE_EDIT_ROLES, userPermissions);

    switch (this.state.value) {
      case LG:
        canEditTLX = checkForSingleUserPermission(SITE_TLX_WRITE, userPermissions);
        return (
          <Grid container>
            <TLXFileGrid canEdit={canEditTLX} />
            <LgFirmwareGrid canEdit={canEdit} tabValue={this.state.value} />
            <LgClientGrid canEdit={canEdit} />
          </Grid>
        );
      case SONIFI:
        return <Grid container>
          <SonifiFirmwareGrid canEdit={canEdit} tabValue={this.state.value} />
        </Grid>;
      case SAMSUNG:
        return <Grid container>
          <SamsungFirmwareGrid canEdit={canEdit} tabValue={this.state.value} />
          <SamsungClientGrid canEdit={canEdit} />
        </Grid>;
      default:
        return <Fragment />;
    }
  }

  render() {
    const { modelType, userPermissions } = this.props;
    const { value } = this.state;

    if (!checkForAtLeastOneUserPermission(SITE_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    return (
      <Fragment>

        <SonifiTabs
          tabs={tabsArray}
          handleChange={this.handleChange.bind(this)}
          tabValue={value}
          showingTab={this.getTabContainer()} />
        {modelType !== '' && <AddModelDialog />}
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  modelType: state.termFile.modelType,
  userPermissions: state.global.permissions,
});

TermTabs.propTypes = {
  dispatch: PropTypes.func,
  modelType: PropTypes.string,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(TermTabs);
