import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  columnNames: [],
  headers: [],
  parsing: false,
  saving: false,
  tableList: [],
  saved: false,
  popupInfo: null,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  translations: null
};

export default function softwareChangesReducer(state = initialState, action) {
  const miscArr = [...state.tableList];
  switch (action.type) {
    case types.INIT_SOFTWARE_CHANGES:
      return {
        ...state,
        columnNames: [],
        headers: [],
        tableList: [],
        snackBarMessage: '',
        snackBarType: SUCCESS,
        saved: false
      };

    case types.UPDATE_SOFTWARE_CHANGES_SNACKBAR:
      return {
        ...state,
        snackBarMessage: action.messsage,
        snackBarType: (action.msgType ? action.msgType : state.snackBarType)
      };

    case types.PARSE_SOFTWARE_BEGIN:
      return {
        ...state,
        parsing: true
      };

    case types.SAVE_SOFTWARE_CHANGES_BEGIN:
      return {
        ...state,
        saving: true
      };

    case types.SAVE_SOFTWARE_CHANGES_SUCCESS:
      return {
        ...state,
        saving: false
      };

    case types.SAVE_SOFTWARE_CHANGES_FAILURE:
      return {
        ...state,
        saving: false
      };

    case types.UPDATE_SOFTWARE_TABLE:
      return {
        ...state,
        ...action.tableInfo,
        parsing: false
      };

    case types.UPDATE_TABLE_RESPONSE:
      miscArr[action.index].success = action.success;
      miscArr[action.index].details = action.details ?? action.details;
      return {
        ...state,
        tableList: miscArr,
        saved: true,
        snackBarMessage: action.failureMsg ? action.failureMsg : '',
        snackBarType: action.failureMsg ? ERROR : SUCCESS,
      };

    case types.SAVE_BATCH_SOFTWARE_CHANGES_FAILURE:
      return {
        ...state,
        saving: false,
        saved: true,
        snackBarMessage: `${state.translations.errors.save} - (${action.error})`,
        snackBarType: ERROR
      };

    case types.SHOW_POPUP:
      return {
        ...state,
        popupInfo: action.rowInfo
      };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
