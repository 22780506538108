import { Add } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { EDIT } from '../../constants/constants';
import { DISTANCE_FROM_END } from '../../constants/magic';
import { GROUP_SERVICES_ROLES } from '../../constants/roleGroups';
import { GROUP_SERVICES_EDIT } from '../../constants/roles';
import {
  SonifiConfirm, SonifiLockoutModalSpinner, SonifiSnackBar, SonifiSpinner, SonifiTableHead
} from '../../containers';
import SonifiLabel from '../../containers/SonifiLabel';
import SonifiTemplate from '../../containers/SonifiTemplate';
import { hasGroupServiceFeat } from '../../utils';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';
import { getIntegrations } from '../Integrations/actions/integrationActions';
import * as actions from './actions/groupServicesActions';
import GroupServicesDialog from './components/GroupServicesDialog';
import { NEW_GROUP } from './constants';
import GroupRow from './containers/GroupRow';

class GroupServices extends Component {
  state = {
    order: 'asc',
    orderBy: 'arrival',
    page: 1
  };

  componentDidMount() {
    const { dispatch } = this.props;
    this.closeSnackBar();
    dispatch(actions.fetchGroupServices(this.buildQueryStringObject(), true));

    this.props.dispatch(getIntegrations());
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(actions.fetchGroupServices(this.buildQueryStringObject(), true));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(actions.fetchGroupServices(this.buildQueryStringObject()));
    }
  }

  buildQueryStringObject() {
    let sortOrder = `${this.state.orderBy}:${this.state.order}`;
    if (this.state.orderBy !== 'name') {
      sortOrder += ',name:asc';
    }
    return {
      sort: sortOrder,
      page: this.state.page,
    };
  }

  refetchGroupsHandler() {
    this.setState({
      order: 'asc',
      orderBy: 'arrival',
      page: 1
    }, () => {
      this.props.dispatch(actions.fetchGroupServices(this.buildQueryStringObject(), true));
    });
  }

  addNewGroupService() {
    const { menusets } = this.props;
    const addNewGroup = JSON.parse(JSON.stringify(NEW_GROUP));
    addNewGroup.arrival = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    addNewGroup.id = Date.now();
    addNewGroup.menuset = menusets && menusets.length > 0 ? menusets[0].id : 0;
    this.props.dispatch(actions.updateSelectedGroup(addNewGroup, EDIT));
  }

  handleRequestSort = (property) => {
    if (this.props.loadingGroups) {
      return;
    }

    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      page: 1,
      orderBy: property,
      order: isDesc ? 'asc' : 'desc'
    });
  };

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    }
  }

  closeSnackBar() {
    this.props.dispatch(actions.setSnackBar());
  }

  confirmDialogConfirmFunc() {
    const { dispatch, deleteGroup } = this.props;
    dispatch(actions.deleteGroup(deleteGroup.id));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(actions.deleteCheckGroup(null));
  }

  getPageDetails() {
    const { order, orderBy, page } = this.state;
    const {
      groups, loadingGroups, maxPages, translations, userPermissions
    } = this.props;

    const canEdit = checkForSingleUserPermission(GROUP_SERVICES_EDIT, userPermissions);
    const tableHeader = [
      { id: 'name', sortable: groups && groups.length > 1, numeric: false, label: `${translations.headers.id}` },
      {
        id: 'arrival',
        sortable: groups && groups.length > 1,
        numeric: false,
        label: `${translations.headers.arrival}`
      },
      {
        id: 'departure',
        sortable: groups && groups.length > 1,
        numeric: false,
        label: `${translations.headers.departure}`
      },
      { id: 'menuset', sortable: false, numeric: false, label: `${translations.headers.menuset}` },
      { id: 'type', sortable: groups && groups.length > 1, numeric: false, label: `${translations.headers.type}` },
    ];

    return <Table stickyHeader={true}>
      <SonifiTableHead
        headColumns={tableHeader}
        order={order}
        orderBy={orderBy}
        onRequestSort={this.handleRequestSort}
      />
      {loadingGroups
        ? <TableBody>
          <TableRow>
            <TableCell colSpan={5}>
              <SonifiSpinner />
            </TableCell>
          </TableRow>
        </TableBody>
        : <TableBody>
          {(!groups || groups.length === 0)
            ? <TableRow>
              <TableCell colSpan={5}>
                <SonifiLabel error label={translations.errors.noGroups} />
              </TableCell>
            </TableRow>
            : groups.map((option, index) => (
              <Fragment key={`w_${index}`}>
                <GroupRow key={index.toString()} group={option} canEdit={canEdit && !loadingGroups} />

                {index === groups.length - DISTANCE_FROM_END && page < maxPages && (
                  <TableRow>
                    <TableCell>
                      <Waypoint onEnter={() => { this.getMoreData(); }} />
                    </TableCell>
                  </TableRow>)
                }
              </Fragment>
            ))}
        </TableBody>}
    </Table>;
  }

  render() {
    const {
      deleteGroup, globalTranslations, loadingGroups, saving, selectedGroup, sendingMessage, snackBarMessage,
      snackBarType, translations, userPermissions
    } = this.props;

    if (!checkForAtLeastOneUserPermission(GROUP_SERVICES_ROLES, userPermissions) ||
      hasGroupServiceFeat() !== 'true') {
      return <Navigate replace to="/" />;
    }

    const canEdit = checkForSingleUserPermission(GROUP_SERVICES_EDIT, userPermissions);

    return (
      <Fragment>
        <SonifiTemplate
          disabled={loadingGroups}
          header={translations.title}
          icon={<Add />}
          label={globalTranslations.add}
          onSubmit={this.addNewGroupService.bind(this)}
          pageDetails={this.getPageDetails()}
          showButton={canEdit}
        />
        {selectedGroup && <GroupServicesDialog canEdit={canEdit}
          refetchGroupsHandler={this.refetchGroupsHandler.bind(this)} />}
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
        <SonifiLockoutModalSpinner show={saving || sendingMessage} />
        <SonifiConfirm
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
          confirmText={`${translations.delete.confirm} ${deleteGroup ? deleteGroup.id : ''}?`}
          confirmTitle={translations.delete.title}
          dialogOpen={!saving && deleteGroup !== null && deleteGroup !== undefined}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
        />
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  deleteGroup: state.groupServices.deleteGroup,
  globalTranslations: state.global.translations.defaults,
  groups: state.groupServices.groups,
  loadingGroups: state.groupServices.loadingGroups,
  maxPages: state.groupServices.maxPages,
  menusets: state.termGrid.menusets,
  saving: state.groupServices.saving,
  selectedGroup: state.groupServices.selectedGroup,
  sendingMessage: state.groupServices.sendingMessage,
  snackBarMessage: state.groupServices.snackBarMessage,
  snackBarType: state.groupServices.snackBarType,
  translations: state.groupServices.translations.grid,
  userPermissions: state.global.permissions
});

GroupServices.propTypes = {
  deleteGroup: PropTypes.object,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  groups: PropTypes.array,
  loadingGroups: PropTypes.bool,
  maxPages: PropTypes.number,
  menusets: PropTypes.array,
  saving: PropTypes.bool,
  selectedGroup: PropTypes.object,
  sendingMessage: PropTypes.bool,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(GroupServices);
