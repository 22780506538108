import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import AgilysysBase from './AgilysysBase';

class Server extends AgilysysBase {
  render() {
    const { editState, translations } = this.props;
    return (
      <Grid item xs={12}>
        {this.getTextBox(translations.url, editState.server.url, 'url', 'server')}
        {this.getTextBox(translations.username, editState.server.username, 'username', 'server')}
        {this.getTextBox(translations.password, editState.server.password, 'password', 'server')}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.integrations.translations.agilysys.server
});

Server.propTypes = {
  editState: PropTypes.object,
  notEditable: PropTypes.bool,
  updateTypeState: PropTypes.func,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(Server);
