import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiText from '../../../containers/SonifiText';
import { deleteGroup, updateDeleteRow, updateSelectedGroup } from '../actions/groupActions';
import EditGroup from './EditGroup';

class GroupFilter extends Component {
  closeDialog() {
    this.props.dispatch(updateSelectedGroup(null, ''));
  }

  confirmDialogConfirmFunc() {
    const { dispatch, deleteSite, userState } = this.props;
    dispatch(deleteGroup(deleteSite, userState));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteRow(null));
  }

  render() {
    const {
      canEdit, deleteSite, filterGroup, userState, globalTranslations, selectedGroup, translations
    } = this.props;

    return (
      <Fragment>
        <Grid container style={{ justifyContent: 'flex-end' }}>
          <Grid item>
            <SonifiText
              label={globalTranslations.search}
              defaultValue={userState.filter}
              change={filterGroup}
              icon={<SearchIcon />}
              iconPosition="end"
              size="mdNoPad"
            />
          </Grid>
        </Grid>

        {selectedGroup !== null &&
          <EditGroup onCancel={this.closeDialog.bind(this)} userState={userState} canEdit={canEdit} />
        }
        <SonifiConfirm
          dialogOpen={deleteSite !== null && deleteSite !== undefined}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          confirmTitle={translations.deleteTitle}
          confirmText={`${translations.deleteText} ${(deleteSite ? deleteSite.description : '')}?`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteSite: state.groups.deleteSite,
  globalTranslations: state.global.translations.defaults,
  selectedGroup: state.groups.selectedGroup,
  translations: state.groups.translations.grid,
  userPermissions: state.global.permissions
});

GroupFilter.propTypes = {
  canEdit: PropTypes.bool,
  deleteSite: PropTypes.object,
  dispatch: PropTypes.func,
  filterGroup: PropTypes.func,
  globalTranslations: PropTypes.object,
  selectedGroup: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array,
  userState: PropTypes.object
};

export default connect(mapStateToProps)(GroupFilter);
