export const AGILYSYS = 'agilysys';
export const BAUD_MAX = 115200;
export const BAUD_MIN = 1200;
export const CMD_TIMEOUT_MAX = 60;
export const CMD_TIMEOUT_MIN = 5;
export const DISNEY = 'disney';
export const DISCOUNT = 'Discount';
export const ECI10 = 'ECI10DigitAccountNumber';
export const EVENT = 'Event';
export const FIAS_IP = 'fias_ip';
export const FIAS_SERIAL = 'fias_serial';
export const GEAC = 'geac/encore/flaglar';
export const GENERATOR = 'generator';
export const HIS = 'HIS/CLS/NSI';
export const HSS = 'hss';
export const INTERFACE = 'Interface';
export const IP = 'IP';
export const LODGISTIX = 'lodgistix';
export const MARRIOTT = 'marriott';
export const MAX_IP = 65535;
export const NONE = 'none';
export const OUTRIGGER = 'outrigger';
export const PMSD = 'pmsd';
export const PROMUS = 'promusvco';
export const REQUEST = 'Request';
export const SERIAL = 'Serial';
export const SERVER = 'Server';
export const SUBTOTAL = 'SubTotal';
export const TAX = 'Tax';
export const USER = 'User';
export const VENDORS = 'Vendors';

export const HOURS = [
  { label: 'Midnight', value: 0 },
  { label: '1 am', value: 1 },
  { label: '2 am', value: 2 },
  { label: '3 am', value: 3 },
  { label: '4 am', value: 4 },
  { label: '5 am', value: 5 },
  { label: '6 am', value: 6 },
  { label: '7 am', value: 7 },
  { label: '8 am', value: 8 },
  { label: '9 am', value: 9 },
  { label: '10 am', value: 10 },
  { label: '11 am', value: 11 },
  { label: 'Noon', value: 12 },
  { label: '1 pm', value: 13 },
  { label: '2 pm', value: 14 },
  { label: '3 pm', value: 15 },
  { label: '4 pm', value: 16 },
  { label: '5 pm', value: 17 },
  { label: '6 pm', value: 18 },
  { label: '7 pm', value: 19 },
  { label: '8 pm', value: 20 },
  { label: '9 pm', value: 21 },
  { label: '10 pm', value: 22 },
  { label: '11 pm', value: 23 },
];

export const categories = [
  { label: 'In Room Controls', value: 'In Room Controls' },
  { label: 'Point of Sale', value: 'Point of Sale' },
  { label: 'Property Management', value: 'Property Management' },
  { label: 'Ticket Management', value: 'Ticket Management' }
];

export const availableTypes = [
  { label: PMSD, value: PMSD },
  { label: GENERATOR, value: GENERATOR },
  { label: 'Disney', value: 'disney' },
];

export const interfaceType = [
  { label: 'FIAS IP', value: FIAS_IP },
  { label: 'FIAS Serial', value: FIAS_SERIAL },
  { label: HIS, value: HIS },
  { label: 'ECI 10 Digit Account Number', value: ECI10 },
  { label: 'Geac/Encore/Flaglar', value: GEAC },
  { label: 'Hss', value: HSS },
  { label: 'Lodgistix', value: LODGISTIX },
  { label: 'Marriott', value: MARRIOTT },
  { label: 'Outrigger', value: OUTRIGGER },
  { label: 'Promus (VCO)', value: PROMUS },
];

export const interfaceLevel = [
  { label: 'vco', value: 'vco' },
  { label: 'boiplus', value: 'boiplus' }
];

export const fiasIpInterfaceLevel = [
  { label: 'vco', value: 'vco' },
];

export const port = {
  baud: 9600,
  parity: 'none',
  data_bits: 8,
  stop_bits: 1,
  port: 1,
  device_name: '/dev/cuaU0',
  hardware: 'pmsd',
  send_attempts: 3,
  send_timeout: 3,
  command_timeout: 60
};

export const ip = {
  address: '',
  port: '',
  send_attempts: 3,
  send_timeout: 3,
  command_timeout: 60
};

export const vendors = [
  {
    client_id: 'hive-site-appliance',
    default: true,
    purchase_type: 'vod',
    revenue_code: '1',
    description: 'TV SERVICES',
  }
];


export const parity = [
  { label: 'none', value: 'none' },
  { label: 'odd', value: 'odd' },
  { label: 'even', value: 'even' }
];

export const defaultPmsd = {
  interface: {
    enhanced_flag: false,
    first_last_flag: false,
    guest_preference_id_flag: false,
    interface_flag: true,
    interface_level: 'vco',
    interface_type: FIAS_SERIAL,
    name_format: 'none',
    post_credits_flag: false,
    post_no_charge_flag: false,
    post_tax_separate: false,
    tax_description: 'TVSERV TAX',
    revenue_code_tax: '2'
  },
  port,
  ip,
  map: {},
  vendors
};

export const defaultAgilysys = {
  event: {
    password: '',
    username: ''
  },
  request: {
    cashier_employee_ID: 89,
    client_ID: 0,
    meal_period: 1,
    post_property_ID: 0,
    register_ID: 8,
    resort_ID: '',
    server_employee_ID: 89,
  },
  server: {
    password: '',
    url: '',
    username: ''
  }
};

export const serialPorts = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' }
];

export const userTypes = [
  { label: '--- Nullus ---', value: null },
  { label: 'User 0', value: { name: 'user.user0', type: 'string' } },
  { label: 'User 1', value: { name: 'user.user1', type: 'string' } },
  { label: 'User 2', value: { name: 'user.user2', type: 'string' } },
  { label: 'User 3', value: { name: 'user.user3', type: 'string' } },
  { label: 'User 4', value: { name: 'user.user4', type: 'string' } },
  { label: 'User 5', value: { name: 'user.user5', type: 'string' } },
  { label: 'User 6', value: { name: 'user.user6', type: 'string' } },
  { label: 'Affinity Member', value: { name: 'affinity.member', type: 'string' } },
  { label: 'Affinity Status', value: { name: 'affinity.status', type: 'string' } },
  { label: 'Channel Preference', value: { name: 'channel.preference', type: 'string' } },
  { label: 'Checkout Date', value: { name: 'checkout_date', type: 'string' } },
  { label: 'E-mail', value: { name: 'e-mail', type: 'string' } },
  { label: 'Group Code', value: { name: 'group.code', type: 'string' } },
  { label: 'Room Type', value: { name: 'room_type', type: 'string' } }
];

export const taxTypes = [
  { label: '--- Nullus ---', value: null },

  // { label: 'tax.0', value: { name: 'tax.0', type: 'number' } },
  { label: 'Tax 1', value: { name: 'tax.1', type: 'number' } },
  { label: 'Tax 2', value: { name: 'tax.2', type: 'number' } },
  { label: 'Tax 3', value: { name: 'tax.3', type: 'number' } },
  { label: 'Tax 4', value: { name: 'tax.4', type: 'number' } },
  { label: 'Tax 5', value: { name: 'tax.5', type: 'number' } },
  { label: 'Tax 6', value: { name: 'tax.6', type: 'number' } },
  { label: 'Tax 7', value: { name: 'tax.7', type: 'number' } },
  { label: 'Tax 8', value: { name: 'tax.8', type: 'number' } },
  { label: 'Tax 9', value: { name: 'tax.9', type: 'number' } },
  { label: 'Miscellaneous', value: { name: 'tax.miscellaneous', type: 'string' } },
  { label: 'State', value: { name: 'tax.state', type: 'string' } },
  { label: 'City', value: { name: 'tax.city', type: 'string' } },
];

export const subTotalTypes = [
  { label: '--- Nullus ---', value: null },

  // { label: 'subtotal.0', value: { name: 'subtotal.0', type: 'number' } },
  { label: 'Subtotal 1', value: { name: 'subtotal.1', type: 'number' } },
  { label: 'Subtotal 2', value: { name: 'subtotal.2', type: 'number' } },
  { label: 'Subtotal 3', value: { name: 'subtotal.3', type: 'number' } },
  { label: 'Subtotal 4', value: { name: 'subtotal.4', type: 'number' } },
  { label: 'Subtotal 5', value: { name: 'subtotal.5', type: 'number' } },
  { label: 'Subtotal 6', value: { name: 'subtotal.6', type: 'number' } },
  { label: 'Subtotal 7', value: { name: 'subtotal.7', type: 'number' } },
  { label: 'Subtotal 8', value: { name: 'subtotal.8', type: 'number' } },
  { label: 'Subtotal 9', value: { name: 'subtotal.9', type: 'number' } },
  { label: 'Entertainment', value: { name: 'subtotal.entertainment', type: 'string' } },
  { label: 'Network', value: { name: 'subtotal.network', type: 'string' } },
  { label: 'Services', value: { name: 'subtotal.services', type: 'string' } },
  { label: 'Miscellaneous', value: { name: 'subtotal.miscellaneous', type: 'string' } },
];

export const discountTypes = [
  { label: '--- Nullus ---', value: null },

  // { label: 'Discount 0', value: { name: 'discount.0', type: 'number' } },
  { label: 'Discount 1', value: { name: 'discount.1', type: 'number' } },
  { label: 'Discount 2', value: { name: 'discount.2', type: 'number' } },
  { label: 'Discount 3', value: { name: 'discount.3', type: 'number' } },
  { label: 'Discount 4', value: { name: 'discount.4', type: 'number' } },
  { label: 'Discount 5', value: { name: 'discount.5', type: 'number' } },
  { label: 'Discount 6', value: { name: 'discount.6', type: 'number' } },
  { label: 'Discount 7', value: { name: 'discount.7', type: 'number' } },
  { label: 'Discount 8', value: { name: 'discount.8', type: 'number' } },
  { label: 'Discount 9', value: { name: 'discount.9', type: 'number' } },
  { label: 'Miscellaneous', value: { name: 'discount.miscellaneous', type: 'string' } },
  { label: 'AAA Discount', value: { name: 'aaa.discount', type: 'string' } },
  { label: 'Loyalty', value: { name: 'discount.loyalty', type: 'string' } }
];

// export const nameFormats = [
//   { label: 'None', value: 'none' },
//   { label: 'First Last (John Smith) space delimited', value: 'first_last' },
//   { label: 'Last First (Smith John) space delimited', value: 'last_first' },
//   { label: 'Courtesy First Last (Mr. John Smith) space delimited', value: 'c_first_last' },
//   { label: 'Last, First (Smith, John) comma space delimited', value: 'last_comma_first' },
//   { label: 'First Last (John Smith) fixed length (6, 10)', value: 'first_last_6' },
//   { label: 'Last First (Smith John) fixed length (10, 6)', value: 'last_first_6' },
//   { label: 'Last/First (Smith/John) "/" delimited', value: 'last_/_first' },
//   { label: 'Last, First (Smith, John) "," delimited', value: 'last_,_first' }
// ];

export const nameFormats = [
  { label: 'None', value: 'none' },
  {
    label: 'First Last (John Smith) space delimited',
    value: {
      order: [1, 2],
      pattern: '^(.*) +(.*)'
    }
  },
  {
    label: 'Last First (Smith John) space delimited',
    value: {
      order: [2, 1],
      pattern: '^(.*) +(.*)'
    }
  },
  {
    label: 'Courtesy First Last (Mr. John Smith) space delimited',
    value: {
      order: [1, 2],
      pattern: '^.* +(.*) +(.*)'
    }
  },
  {
    label: 'Last, First (Smith, John) comma space delimited',
    value: {
      order: [2, 1],
      pattern: '^(.*), +(.*)'
    }
  },
  {
    label: 'First Last (John Smith) fixed length (6, 10)',
    value: {
      order: [1, 2],
      pattern: '^(.{6})(.{0,10})'
    }
  },
  {
    label: 'Last First (Smith John) fixed length (10, 6)',
    value: {
      order: [2, 1],
      pattern: '^(.{10})(.{0,6})'
    }
  },
  {
    label: 'Last/First (Smith/John) "/" delimited',
    value: {
      order: [2, 1],
      pattern: '^(.*)/(.*).*'
    }
  },
  {
    label: 'Last,First (Smith,John) "," delimited',
    value: {
      order: [2, 1],
      pattern: '^(.*),(.*)'
    }
  }
];

export const disneySource = [
  { label: 'Disney Land Resort', value: 'dlr' },
  { label: 'Walt Disney World', value: 'wdw' },

];

export const LATE_CHECKOUT = {
  client_id: 'hive-site-appliance',
  default: false,
  description: 'Late Checkout',
  purchase_type: 'late_checkout',
  revenue_code: '2'
};
