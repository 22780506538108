import { Dialog, DialogContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { READ } from '../../../constants/constants';
import { SonifiText } from '../../../containers';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import { getErrorText, isError } from '../../../utils';
import { removePipe, textHelper } from '../../../utils/textUtil';
import { durations, SERVER_STREAM } from '../../Channels2/constants/constants';
import { validateDescription, validateDisplayName, validateTitle } from '../../Channels2/Validator/ValidationFunctions';
import * as actions from '../actions/cloudStreamAction';

export class CloudStreamDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      errors: {}
    };
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
    this.blurringCode = this.blurringCode.bind(this);
  }

  componentDidMount() {
    const { cloudChans, cloudStreamIndex } = this.props;
    const miscObj = JSON.parse(JSON.stringify(cloudChans[cloudStreamIndex]));
    this.setState({ ...miscObj });
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(actions.cloudStreamDialog(-1));
    }
  }

  onSave() {
    const { globalTranslations } = this.props;
    const {
      description, entry, name
    } = this.state;

    const err = {};
    const validatedName = validateDisplayName(name, globalTranslations.errors);
    if (validatedName) {
      err.name = validatedName;
    }

    const validatedDesc = validateDescription(description, SERVER_STREAM, globalTranslations.errors);
    if (validatedDesc) {
      err.description = validatedDesc;
    }

    const validatedEntry = validateTitle(entry, SERVER_STREAM, globalTranslations.errors);
    if (validatedEntry) {
      err.entry = validatedEntry;
    }

    this.setState({
      errors: err
    }, () => {
      if (Object.keys(this.state.errors).length > 0) {
        console.log('THERE ARE ERRORS...FIX EM');
      } else {
        const saveThisChannel = { ...this.state };
        delete (saveThisChannel.errors);
        delete (saveThisChannel.isNew);
        this.props.dispatch(actions.saveStreamChannel(saveThisChannel));
      }
    });
  }

  edit = (name) => ({ target: { value } }) => {
    this.setState({ [name]: removePipe(value) });
  };

  blurringCode(name, defaultValue) {
    const errors = this.state.errors;
    if (textHelper(defaultValue) && !this.state.errors[name]) {
      errors[name] = this.props.globalTranslations.errors.symbols;
    } else {
      delete (errors[name]);
    }
    this.setState({ errors });
  }

  getText(defaultValue, name, label, disabled) {
    return (
      <Grid item xs={6}>
        <SonifiText
          defaultValue={defaultValue}
          change={this.edit(name)}
          label={label}
          size="percent"
          disabled={disabled}
          onBlur={() => this.blurringCode(name, defaultValue)}
          error={isError(name, this.state.errors)}
          errorText={getErrorText(name, this.state.errors)}
        />
      </Grid>
    );
  }

  /* eslint-disable camelcase */
  render() {
    const { dialogType, globalTranslations, translations } = this.props;
    const {
      description, duration, isNew, name, entry
    } = this.state;

    const readOnly = dialogType === READ;

    return (
      <Dialog open={true} onClose={this.onCancel} fullWidth maxWidth="md">
        <SonifiModalHeader
          header={isNew ? translations.addTitle : translations.viewTitle}
          onlyClose={readOnly}
          // disabled={name?.trim() === '' || description?.trim() === '' || title?.trim() === ''}
          onCancel={this.onCancel}
          onSubmit={this.onSave}
          label={globalTranslations.defaults.save}
        />
        <DialogContent>
          <Grid container>
            {this.getText(name, 'name', translations.name, readOnly)}
            {/* {this.getText(id, 'id', translations.id, true)} */}
            <Grid item xs={6} />
            <Grid item xs={6}>
              <SonifiText
                defaultValue={duration}
                change={this.edit('duration')}
                label={translations.duration}
                size="percent"
                select={true}
                items={durations}
                disabled={readOnly}
              />
            </Grid>
            {this.getText(entry, 'entry', translations.entry, readOnly)}
            <Grid item xs={12}>
              <SonifiText
                change={this.edit('description')}
                defaultValue={description}
                disabled={readOnly}
                error={isError('description', this.state.errors)}
                errorText={getErrorText('description', this.state.errors)}
                label={translations.desc}
                multiline={true}
                onBlur={() => this.blurringCode('description', description)}
                rows={3}
                size="percent"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  cloudChans: state.cloudStream.cloudChans,
  cloudStreamIndex: state.cloudStream.cloudStreamIndex,
  dialogType: state.cloudStream.dialogType,
  globalTranslations: state.global.translations,
  translations: state.cloudStream.translations.grid
});

CloudStreamDialog.propTypes = {
  cloudChans: PropTypes.array,
  cloudStreamIndex: PropTypes.number,
  dialogType: PropTypes.string,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(CloudStreamDialog);
