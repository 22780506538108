import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiText from '../../../containers/SonifiText';

class Selects extends Component {
  state = {
    buildings: [],
    floors: [],
    units: []
  };

  componentDidMount() {
    this.checkAllStatus(null, null);
  }

  checkAllStatus(name, value) {
    const { includeAll, site } = this.props;
    let { building, floor } = this.props;

    if (!site) {
      return;
    }

    if (name === 'building') {
      building = value;
    } else if (name === 'floor') {
      floor = value;
    }

    let allBuildings = (includeAll ? ['All'] : []),
      allFloors = (includeAll ? ['All'] : []),
      allUnits = (includeAll ? ['All'] : []);
    for (let i = 0; i < site.length; i++) {
      if (site[i].floors && site[i].floors.length > 0) {
        if (!allBuildings.includes(site[i].name)) {
          allBuildings.push(site[i].name);
        }
        if ((building === 'All' || building === site[i].name) && site[i].floors) {
          for (let j = 0; j < site[i].floors.length; j++) {
            if (!allFloors.includes(site[i].floors[j].name)) {
              allFloors.push(site[i].floors[j].name);
            }
            if ((floor === 'All' || floor === site[i].floors[j].name) && site[i].floors[j].units) {
              for (let k = 0; k < site[i].floors[j].units.length; k++) {
                allUnits.push(site[i].floors[j].units[k].name);
              }
            }
          }
        }
      }
    }


    if (includeAll) {
      if (allBuildings.length === 1) {
        allBuildings = [];
      }
      if (allFloors.length === 1) {
        allFloors = [];
      }
      if (allUnits.length === 1) {
        allUnits = [];
      }
    }

    this.setState({
      buildings: allBuildings,
      floors: allFloors,
      units: allUnits,
    }, () => {
      console.log('Filtering', this.state);
    });
  }

  handleChange = (name) => ({ target: { value } }) => {
    if (value === null || value.length === 0) {
      value = null;
    }

    if (name === 'building') {
      this.props.onChange('floor', null);
    }

    this.props.onChange(name, value);
    this.checkAllStatus(name, value);
  };

  render() {
    const {
      building, disabled, errors, floor, includeAll, unit, translations
    } = this.props;

    const {
      buildings, floors, units
    } = this.state;

    if (!buildings) {
      return <div></div>;
    }

    return (
      <Fragment>
        {buildings && buildings.length > 0 && (
          <SonifiText
            label={translations.building}
            select
            size={includeAll ? 'marginRightSm' : 'sm'}
            items={buildings.map((suggestion) => ({
              id: suggestion,
              value: suggestion,
              label: suggestion,
            }))}
            error={errors && (!!errors.building || !!errors.hierarchy)}
            errorText={errors && ((errors.building
              ? errors.building
              : null) || (errors.hierarchy
                ? errors.hierarchy
                : null))}
            defaultValue={(building || this.props.translations.all)}
            change={this.handleChange('building')}
            disabled={disabled || buildings.length === 1}
          />)}
        {floors && floors.length > 0
          ? <SonifiText
            label={translations.floor}
            select
            size={includeAll ? 'marginRightSm' : 'sm'}
            items={floors.map((suggestion) => ({
              id: suggestion,
              value: suggestion,
              label: suggestion,
            }))}
            error={errors && !!errors.floor}
            errorText={errors && errors.floor ? errors.floor : null}
            defaultValue={(floor || this.props.translations.all)}
            change={this.handleChange('floor')}
            disabled={disabled || floors.length === 1}
          />
          : <SonifiText label={translations.floor} disabled size={includeAll ? 'marginRight' : 'sm'} />}
        {units && units.length > 0 && (
          <SonifiText
            label={translations.unit}
            select
            size={includeAll ? 'marginRightSm' : 'sm'}
            items={units.map((suggestion) => ({
              id: suggestion,
              value: suggestion,
              label: suggestion,
            }))}
            defaultValue={(unit || this.props.translations.all)}
            change={this.handleChange('unit')}
            disabled={disabled}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  site: state.rooms.buildings,
  translations: state.rooms.translations.main
});

Selects.propTypes = {
  building: PropTypes.string,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  floor: PropTypes.string,
  includeAll: PropTypes.bool,
  onChange: PropTypes.func,
  site: PropTypes.array,
  translations: PropTypes.object,
  unit: PropTypes.string
};

export default connect(mapStateToProps)(Selects);
