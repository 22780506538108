import { Add, Check } from '@mui/icons-material';
import { Divider, Grid, Table, TableBody } from '@mui/material';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getSite } from '../../../actions/globalActions';
import { SITE_EDIT } from '../../../constants/roles';
import { SonifiSpinner } from '../../../containers';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSubHeader from '../../../containers/SonifiSubHeader';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import { getErrorText } from '../../../utils/index';
import { checkSingleUserPermission } from '../../../utils/rolesUtil';
import { getCheckBox, getLgTextBox } from '../../../utils/ui';
import { getTerminalOptions } from '../../Terminals/actions/terminalActions';
import {
  closeSnackBar, fetchChannelOptions, saveIpNetwork, updateChangeVariable, updateIpNetwork
} from '../actions/siteManagementActions';
import { defaultIptvNetwork } from '../constants/SiteContants';
import NetworkDialog from '../containers/IpNetworks/NetworkDialog';
import NetworkRow from '../containers/IpNetworks/NetworkRow';
import { getDhcp, isValidIP } from '../utils/validator';

class IpNetworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // addEditText: '',
      deleteId: -1,
      errors: {},
      id: -1,
      ipLoading: true,
      iptvNetwork: null,
      networksUsed: null,
      showAddEdit: false,
      order: 'desc',
      orderBy: 'networks',
      showDelete: false
    };
    this.saveIp = this.saveIp.bind(this);
    this.updateNetworks = this.updateNetworks.bind(this);
    this.verifyCancelDialog = this.verifyCancelDialog.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(updateChangeVariable(true));
    this.props.dispatch(closeSnackBar());
    this.props.dispatch(getSite());
    this.props.dispatch(fetchChannelOptions());
    this.props.dispatch(getTerminalOptions())
      .then(() => {
        const iptv = (this.props.site.iptv_network ? { ...this.props.site.iptv_network } : { ...defaultIptvNetwork });
        this.setState({
          networksUsed: { ...this.props.networksUsed },
          iptvNetwork: iptv,
          ipLoading: false,
          showAddEdit: false,

          // addEditText: '',
          id: -1,
          errors: {}
        }, () => {
          this.props.dispatch(updateIpNetwork(this.state));
        });
      });
  }

  changeValue = (name, secondaryName, tertiaryName) => ({ target: { value } }) => {
    this.updateValues(name, secondaryName, tertiaryName, value);
  };

  editToggleValue = (name, secondaryName, tertiaryName) => ({ target: { checked } }) => {
    this.updateValues(name, secondaryName, tertiaryName, checked);
  };

  updateValues(name, secondaryName, tertiaryName, value) {
    if (value === true || value === false) {
      console.log('');
    } else if (!(/^[0-9./]+$/).test(value)) {
      return;
    }

    const secondary = { ...this.state[secondaryName] };
    if (tertiaryName) {
      const tertiary = { ...secondary[tertiaryName] };
      tertiary[name] = value;
      secondary[tertiaryName] = tertiary;
      this.updateIpState(secondaryName, secondary);
      this.props.dispatch(updateChangeVariable(false));
    } else if (secondaryName) {
      secondary[name] = value;
      this.updateIpState(secondaryName, secondary);

      if (secondaryName === 'iptvNetwork') {
        if (!secondary.layer3_enabled && !this.state.iptvNetwork.dhcp) {
          secondary.dhcp = getDhcp(this.state.iptvNetwork.subnet_mask);
        }
      }

      this.props.dispatch(updateChangeVariable(false));
    } else {
      this.updateIpState(name, value);
      this.props.dispatch(updateChangeVariable(false));
    }
  }

  updateIpState(name, value) {
    this.setState({ [name]: value }, () => {
      this.props.dispatch(updateIpNetwork(this.state));
    });
  }

  isAvailable(name) {
    const channel = find(this.props.channelDelivery, (o) => o.name === name);
    return (channel && channel.checked);
  }

  saveIp() {
    const validIP = isValidIP(this.state);
    this.setState({ errors: validIP });
    if (Object.entries(validIP).length === 0) {
      this.props.dispatch(saveIpNetwork(this.state));
      this.props.dispatch(updateChangeVariable(true));
    }
  }

  handleAddDialog(value) {
    this.setState({
      showAddEdit: true,

      // addEditText: (value === -1 ? '' : this.state.iptvNetwork.layer3_netmasks[value]),
      id: value
    });
  }

  handleDeleteDialog(id) {
    this.setState({
      showDelete: true,
      deleteId: id
    });
  }

  deleteNetwork = () => {
    const currentNetworks = this.state.iptvNetwork.layer3_netmasks.filter((_, i) => i !== this.state.deleteId);

    this.setState({
      id: -1,
      showAddEdit: false,
      showDelete: false,

      // addEditText: '',
      deleteId: -1,
      errors: {},
      iptvNetwork: {
        ...this.state.iptvNetwork,
        layer3_netmasks: currentNetworks
      }
    });
  };

  verifyCancelDialog = () => {
    this.setState({
      deleteId: -1, showAddEdit: false, showDelete: false, id: -1, errors: null
    });
  };

  // getTextOption() {
  //   return <SonifiText
  //     defaultValue={this.state.addEditText}
  //     change={this.changeText.bind(this)}
  //     label={this.props.translations.networkIp}
  //     error={isError('addEditText', this.state.errors)}
  //     errorText={getErrorText('addEditText', this.state.errors)}
  //     size="md" />;
  // }

  // changeText({ target: { value } }) {
  //   if (!(/^[0-9./]+$/).test(value)) {
  //     return;
  //   }
  //   this.setState({ addEditText: value });
  // }

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    const currentNetworks = (this.state.iptvNetwork.layer3_netmasks ? [...this.state.iptvNetwork.layer3_netmasks] : []);

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      iptvNetwork: {
        ...this.state.iptvNetwork,
        layer3_netmasks: isDesc ? currentNetworks.sort() : currentNetworks.reverse()
      }
    });
  };

  updateNetworks(currentNetworks) {
    this.setState({
      id: -1,
      showAddEdit: false,

      // addEditText: '',
      deleteId: -1,
      errors: {},
      iptvNetwork: {
        ...this.state.iptvNetwork,
        layer3_netmasks: currentNetworks
      }
    });
  }

  /* eslint-disable max-lines-per-function */
  render() {
    const {
      deleteId, errors, id, ipLoading, iptvNetwork, networksUsed, order, orderBy, showAddEdit, showDelete
    } = this.state;
    const {
      loadingSite, translations, userPermissions, globalTranslations, channelOptionsLoading
    } = this.props;
    const readOnly = !checkSingleUserPermission(SITE_EDIT, userPermissions);
    const headColumns = [{ id: 'networks', label: translations.networks, sortable: true, numeric: false }];

    if (loadingSite || ipLoading || channelOptionsLoading) {
      return <SonifiSpinner />;
    }

    return (
      <Fragment>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={12}>
            {!readOnly && <SonifiSubHeader
              disabled={readOnly}
              header=""
              icon={<Check />}
              label={globalTranslations.save}
              onSubmit={this.saveIp}
              showButton={true}
            />}
          </Grid>
          <Grid item xs={12} style={{ overflow: 'auto', height: '91%' }}>
            <Grid container style={{ backgroundColor: '#f6f6f6', padding: '10px' }}>
              <Grid item>
                <SonifiLabel boldLabel={translations.termTypes} />
                {networksUsed !== null &&
                  <Grid item xs={12}>
                    {this.isAvailable('IPTV') && getCheckBox(translations.ip, networksUsed.ip,
                      this.editToggleValue('ip', 'networksUsed'), !readOnly)}
                    {getCheckBox(translations.wifi, networksUsed.wifi,
                      this.editToggleValue('wifi', 'networksUsed'), !readOnly)}
                    {(this.isAvailable('Analog RF') || this.isAvailable('Digital RF')) &&
                      getCheckBox(translations.rf, networksUsed.rf, this.editToggleValue('rf', 'networksUsed'),
                        !readOnly)}
                  </Grid>}
              </Grid>
            </Grid>
            <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
            {(iptvNetwork !== null && (networksUsed.ip || networksUsed.wifi)) &&
              <Fragment>
                {/* <SonifiLabel blue label={translations.ipWifi} /> */}
                <Grid item xs={12}>
                  {getLgTextBox(translations.ipTermNetwork, iptvNetwork.subnet_mask,
                    'subnet_mask', this.changeValue('subnet_mask', 'iptvNetwork'),
                    getErrorText('subnet_mask', errors), !readOnly)}
                </Grid>
                <Grid item xs={12}>
                  {getLgTextBox(translations.gateway, iptvNetwork.gateway_address,
                    'gateway_address', this.changeValue('gateway_address', 'iptvNetwork'),
                    getErrorText('gateway_address', errors), !readOnly)}
                </Grid>
                <Grid item xs={12}>
                  {getCheckBox(translations.layer3, iptvNetwork.layer3_enabled,
                    this.editToggleValue('layer3_enabled', 'iptvNetwork'), !readOnly)}
                  {!iptvNetwork.layer3_enabled
                    ? <Grid container style={{ border: '1px solid #d6d6d6', padding: '24px' }}>
                      <Grid item xs={12}>
                        <SonifiLabel blue label={translations.dhcpTitle} />
                        <SonifiLabel label={translations.addressPool} />
                        {getLgTextBox(translations.start, iptvNetwork.dhcp ? iptvNetwork.dhcp.pool_start : '',
                          'pool_start', this.changeValue('pool_start', 'iptvNetwork', 'dhcp'),
                          getErrorText('pool_start', errors), !readOnly)}
                        {getLgTextBox(translations.end, iptvNetwork.dhcp ? iptvNetwork.dhcp.pool_end : '',
                          'pool_end', this.changeValue('pool_end', 'iptvNetwork', 'dhcp'),
                          getErrorText('pool_end', errors), !readOnly)}
                        {getLgTextBox(translations.dns, iptvNetwork.dhcp ? iptvNetwork.dhcp.dns_server : '',
                          'dns_server', this.changeValue('dns_server', 'iptvNetwork', 'dhcp'),
                          getErrorText('dns_server', errors), !readOnly)}
                        {getCheckBox(translations.enableDevices,
                          iptvNetwork.dhcp ? iptvNetwork.dhcp.device_filtering : false,
                          this.editToggleValue('device_filtering', 'iptvNetwork', 'dhcp'), !readOnly)}
                      </Grid>
                    </Grid>
                    : <Grid container style={{ border: '1px solid #d6d6d6', padding: '24px' }}>
                      <Grid item xs={12}>
                        <SonifiSubHeader
                          header={translations.layer3Title}
                          smallerHeader={true}
                          onSubmit={this.handleAddDialog.bind(this, -1)}
                          showButton={true} label={translations.add} icon={<Add />} disabled={false} />
                        {iptvNetwork.layer3_netmasks && iptvNetwork.layer3_netmasks.length > 0 && <Table >
                          <SonifiTableHead
                            headColumns={headColumns}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={this.handleRequestSort}
                          />
                          <TableBody>
                            {iptvNetwork.layer3_netmasks.map((option, index) => (
                              <NetworkRow key={index} netmask={option} canEdit={!readOnly}
                                handleDeleteDialog={this.handleDeleteDialog.bind(this, index)}
                                handleEditDialog={this.handleAddDialog.bind(this, index)} />
                            ))}
                          </TableBody>
                        </Table>}
                      </Grid>
                    </Grid>
                  }
                </Grid>
              </Fragment>}
          </Grid>
        </Grid>
        {showAddEdit && <NetworkDialog
          deleteId={deleteId} id={id}
          updateNetworks={this.updateNetworks} onCancel={this.verifyCancelDialog}
          layer3_netmasks={this.state.iptvNetwork.layer3_netmasks} readOnly={readOnly}
        />}

        <SonifiConfirm
          dialogOpen={showDelete}
          onConfirm={this.deleteNetwork}
          onCancel={this.verifyCancelDialog}
          confirmTitle={translations.popup.delete}
          confirmText={
            `${translations.popup.deleteText} ${deleteId !== -1 && this.state.iptvNetwork.layer3_netmasks[deleteId]}`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.save}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.terminals.changed,
  channelDelivery: state.siteManagement.channelDelivery,
  channelOptionsLoading: state.siteManagement.channelOptionsLoading,
  globalTranslations: state.global.translations.defaults,
  ipNetwork: state.siteManagement.ipNetwork,
  loadingSite: state.global.loadingSite,
  networksUsed: state.termGrid.networksUsed,
  site: state.global.site,
  translations: state.siteManagement.translations.ipNetwork,
  userPermissions: state.global.permissions
});

IpNetworks.propTypes = {
  channelDelivery: PropTypes.array,
  channelOptionsLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  ipNetwork: PropTypes.object,
  loadingSite: PropTypes.bool,
  networksUsed: PropTypes.object,
  site: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(IpNetworks);
