import SearchIcon from '@mui/icons-material/Search';
import {
  Grid, Paper, Table, TableBody, TableCell, TableFooter, TableRow
} from '@mui/material';
import {
  debounce, filter, find, findIndex, map
} from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { SONIFI_ROLES } from '../../../constants/roleGroups';
import { SonifiCheckbox, SonifiLabel, SonifiTableHead, SonifiText } from '../../../containers/';
import SonifiAutoCompleteText from '../../../containers/SonifiAutoCompleteText';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { filterGroupSite } from '../../GroupManagement/actions/groupActions';
import { USERS_THAT_EDIT } from '../constants/index';
import { getErrorText, isError } from '../utils/index';

const styles = () => ({
  table: {
    minWidth: 700,
    maxHeight: '90%',
  },
});

class SiteSelector extends Component {
  state = {
    allowSave: true,
    filter: '',
    limit: 100,
    order: 'asc',
    orderBy: 'room_id',
    page: 1,
    selectAllChecked: false
  };

  tableHeads = () => [
    {
      centerHeader: false,
      checkbox: true,
      checkValue: this.state.selectAllChecked || this.isAllSites(),
      disabled: this.isAllSites() || !this.props.canEdit,
      error: isError('sites', this.props.errors),
      id: '',
      label: '',
      noLabel: true,
      numeric: false,
      onChange: this.checkboxChange(),
      sortable: false
    },
    {
      centerHeader: false,
      id: 'id',
      label: this.props.translations.id,
      numeric: false,
      sortable: false
    },
    {
      centerHeader: false,
      id: 'name',
      label: this.props.translations.name,
      numeric: false,
      sortable: false
    }
  ];

  componentDidMount() {
    this.updateUserSites(this.props.selectedUser.sites);
  }

  updateUserSites(sites) {
    this.props.updateSites('sites', sites);
  }

  checkboxChange = () => () => {
    if (this.state.selectAllChecked) {
      this.updateUserSites([]);
      this.setState({ selectAllChecked: false });
    } else {
      this.updateUserSites(map(this.props.groupSites, 'id'));
      this.setState({ selectAllChecked: true });
    }
  };

  userAccessToSite = (siteId) => {
    const { userSites } = this.props;

    if (this.isAllSites()) {
      return true;
    }

    if (!userSites || userSites.length === 0) {
      return false;
    }

    const selected = find(userSites, (o) => o === siteId);
    return selected !== undefined;
  };

  allSites = () => () => {
    if (!this.props.canEdit) {
      return;
    }

    this.setState({ selectAllChecked: true });

    if (this.isAllSites()) {
      this.updateUserSites(map(this.props.groupSites, 'id'));
    } else {
      this.updateUserSites(['*']);
    }
  };

  updateSiteCheck(site) {
    if (this.isAllSites() || !this.props.canEdit) {
      return;
    }

    const tempSites = this.props.userSites ? [...this.props.userSites] : [];
    const channelIndex = findIndex(tempSites, (o) => o === site);
    if (channelIndex === -1) {
      tempSites.push(site);
    } else {
      tempSites.splice(channelIndex, 1);
      this.setState({ selectAllChecked: false });
    }
    this.updateUserSites(tempSites);
  }

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';

    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    });
  };

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  filterSite = ({ target: { value } }) => {
    this.setState({ filter: value }, () => {
      this.debouncedLoadMoreData();
    });
  };

  filterData() {
    this.props.dispatch(filterGroupSite(this.state.filter));
  }

  getGroupSites = (event, value) => {
    this.setState({ selectAllChecked: false });
    this.props.updateGroup((value ? value.id : null));
  };

  isAllSites() {
    const { selectedUser } = this.props;
    return (selectedUser && selectedUser.sites && selectedUser.sites[0] === '*');
  }

  getShowAllSites() {
    const { groupId, userPermissions, userAssignedSites } = this.props;
    if (groupId && groupId.length > 0) {
      if (checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions) || userAssignedSites[0] === '*') {
        return true;
      }
    }
    return false;
  }

  render() {
    const {
      userGroup, canEdit, classes, errors, filteredSitesForUser,
      filterLoad, gettingGroupSites, gettingUserSites, globalTranslations,
      groupId, groups, selectedUser, translations, userPermissions
    } = this.props;
    const { order, orderBy } = this.state;

    const showAllSites = this.getShowAllSites();

    const allowEditingOfGroup = (selectedUser.isNew && (userGroup === null || userGroup === '')) ||
      (checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions));

    return (
      <Paper style={{ padding: '10px' }}>
        <Grid container>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item>
              <Grid container >
                <Grid item>
                  {allowEditingOfGroup
                    ? <SonifiAutoCompleteText
                      size="md"
                      defaultValue={groupId ? filter(groups, (o) => (o.id || o) === groupId)[0] : null}
                      label={translations.chooseGroup}
                      options={groups}
                      onChange={this.getGroupSites}
                      error={isError('group', errors)}
                      errorText={getErrorText('group', errors)}
                    />
                    : <SonifiText
                      size="md"
                      label={translations.group}
                      defaultValue={filter(groups, (o) => (o.id || o) === groupId)[0].description}
                      disabled
                    />}
                </Grid>
                <Grid item>
                  {(groupId && groupId.length > 0 && showAllSites) && <SonifiCheckbox
                    label={translations.allSitesLabel}
                    onChange={this.allSites()}
                    value={this.isAllSites()}
                    disabled={!groupId || groupId === null || groupId === '' || !canEdit ||
                      USERS_THAT_EDIT.includes(selectedUser.role)}
                  />}
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {(groupId && groupId.length > 0) && <SonifiText
                label={globalTranslations.search}
                defaultValue={this.state.filter}
                change={this.filterSite}
                icon={<SearchIcon />}
                iconPosition="end"
              />}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table}>
              <SonifiTableHead
                headColumns={this.tableHeads()}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              {!(groupId && groupId.length > 0) || filteredSitesForUser.length === 0
                ? <TableBody className={classes.tableBody}>
                  <TableRow>
                    <TableCell colSpan={3}>
                      {!(filterLoad || gettingUserSites || gettingGroupSites || filteredSitesForUser === null) &&
                        <SonifiLabel error label={((groupId === '' || groupId === null)
                          ? translations.errors.noGroup
                          : translations.errors.noSites)} />}
                    </TableCell>
                  </TableRow>
                </TableBody>
                : <TableBody className={classes.tableBody}>
                  {filteredSitesForUser.map((site, index) => (
                    <TableRow key={index} onClick={this.updateSiteCheck.bind(this, site.id)}>
                      <TableCell>
                        <SonifiCheckbox
                          noLabel={true}
                          label=""
                          value={this.userAccessToSite(site.id)}
                          disabled={this.isAllSites() || !canEdit}
                        />
                      </TableCell>
                      <TableCell>{site.id}</TableCell>
                      <TableCell>{site.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>}
              <TableFooter>
                <TableRow>

                </TableRow>
              </TableFooter>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.groups.changed,
  filteredSitesForUser: state.groups.filteredSitesForUser,
  filterLoad: state.groups.filterLoad,
  gettingGroupSites: state.groups.gettingGroupSites,
  gettingUserSites: state.users.gettingUserSites,
  globalTranslations: state.global.translations.defaults,
  groups: state.groups.groups,
  groupSites: (state.groups.selectedGroup && state.groups.selectedGroup.sites ? state.groups.selectedGroup.sites : []),
  maxPages: state.users.maxPages,
  selectedUser: state.users.selectedUser,
  translations: state.users.translations.dialog,
  userGroup: state.global.group,
  userPermissions: state.global.permissions,
  userAssignedSites: state.global.userAssignedSites
});

SiteSelector.propTypes = {
  canEdit: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  errors: PropTypes.object,
  filteredSitesForUser: PropTypes.array,
  filterLoad: PropTypes.bool,
  gettingGroupSites: PropTypes.bool,
  gettingUserSites: PropTypes.bool,
  globalTranslations: PropTypes.object,
  groupId: PropTypes.string,
  groups: PropTypes.array,
  groupSites: PropTypes.array,
  maxPages: PropTypes.number,
  onCancel: PropTypes.func,
  selectedUser: PropTypes.object,
  translations: PropTypes.object,
  updateGroup: PropTypes.func,
  updateSites: PropTypes.func,
  userSites: PropTypes.array,
  userGroup: PropTypes.string,
  userPermissions: PropTypes.array,
  userAssignedSites: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(SiteSelector, styles, { withTheme: true }));
