/* eslint-disable max-lines-per-function */
import { CheckCircleOutline, ErrorOutline, Loop } from '@mui/icons-material';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { TERMINAL_DETAIL_EDIT } from '../../../constants/roles';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSearchDropdown from '../../../containers/SonifiSearchDropdown';
import SonifiTextInline from '../../../containers/SonifiTextInline';
import { findArrayIdByKey, getNameFromObjectArray } from '../../../utils/index';
import { checkSingleUserPermission } from '../../../utils/rolesUtil';
import { updateSelectedTerminal, updateTerminal } from '../actions/terminalActions';

const styles = (theme) => ({
  highlightedRow: {
    color: theme.palette.primary.contrastText,
    backgroundColor: `${theme.palette.secondary.main} !important`
  },
  leftSide: {
    float: 'left',
    paddingLeft: '0px'
  },
  rightSide: {
    float: 'right',
    height: '40px'
  },
  row: {
    minWidth: '210px',
    maxWidth: '210px'
  },
  goodStatus: {
    color: `${theme.palette.defaults.green} !important`
  },
  badStatus: {
    color: `${theme.palette.defaults.red} !important`
  }
});

class SelectedRow extends Component {
  terminalRowClick() {
    if (this.props.rowIndex === this.props.selectedTerminal) {
      // this.props.dispatch(updateSelectedTerminal(-1));
    } else {
      this.props.dispatch(updateSelectedTerminal(this.props.rowIndex));
    }
  }

  handleChange = (name) => ({ target: { value, innerText } }) => {
    const targetValue = value !== 0 ? value : innerText;
    const rowTerm = this.props.terminals[this.props.rowIndex];

    if (rowTerm[name] !== targetValue) {
      this.props.dispatch(updateTerminal(this.props.rowIndex, 'edited', true));
    }

    this.props.dispatch(updateTerminal(this.props.rowIndex, name, targetValue));

    const terminalIndex = findArrayIdByKey(this.props.freeTerminals, 'id', targetValue);
    if (terminalIndex) {
      const addedTerminal = this.props.freeTerminals[terminalIndex];

      this.props.dispatch(updateTerminal(this.props.rowIndex, 'swapInfo', addedTerminal));
    }
  };

  getRowItemsIdIdName(arr) {
    return arr.map((suggestion) => ({
      id: suggestion.id,
      value: suggestion.id,
      label: suggestion.name,
    }));
  }

  swapTerm() {
    this.setState({ swap: true });
    this.props.dispatch(updateTerminal(this.props.rowIndex, 'edited', true));
    this.props.dispatch(updateTerminal(this.props.rowIndex, 'swapId', this.props.terminals[this.props.rowIndex].id));
  }

  createFilterObj = (inputData) => ({
    unassigned: null,
    search: inputData,
    limit: 25
  });

  // function for filter async
  createDisplayObj = (terminal) => ({
    value: `${terminal.id}`,
    label: `${terminal.id}`
  });

  getMenusetOptions() {
    const { menusets, rowIndex, terminals } = this.props;
    const { menuset } = terminals[rowIndex];

    // A terminal's menuset has to exist in the site's menuset list to show the bolded Label below.
    const termMenusetExistsInSiteMenusets = filter(menusets, (o) => o.id === menuset);
    if (menusets && menusets.length < 2 && termMenusetExistsInSiteMenusets.length > 0) {
      return <SonifiLabel boldLabel={getNameFromObjectArray(menusets, menuset)} />;
    }

    return <SonifiTextInline
      select
      items={menusets.map((suggestion) => ({
        id: suggestion.id,
        value: suggestion.id,
        label: suggestion.name + (suggestion.description !== undefined ? ` : ${suggestion.description}` : ''),
      }))}
      defaultValue={`${menuset}`}
      change={this.handleChange('menuset')} />;
  }

  /* eslint-disable camelcase */
  render() {
    const {
      classes, freeTerminals, globalTranslations, locations, rowIndex,
      selectedTerminal, terminals, lineups, languages, translations, userPermissions
    } = this.props;

    const {
      id, location, lineup_id, language, isNew, swapId, remove, passive_status
    } = terminals[rowIndex];

    const selected = rowIndex === selectedTerminal;
    if (!lineup_id) {
      return <Fragment />;
    }

    if (remove) {
      return <Fragment></Fragment>;
    }


    return (
      <TableRow
        onClick={() => { this.terminalRowClick(); }}
        className={`${selected ? classes.highlightedRow : ''}`} >
        <TableCell component="th" scope="row" >
          {passive_status !== 'bad'
            ? <Tooltip title={translations.goodTerm}><CheckCircleOutline className={classes.goodStatus} /></Tooltip>
            : <Tooltip title={translations.badTerm}><ErrorOutline className={classes.badStatus} /></Tooltip> || ''}
        </TableCell>
        <TableCell component="th" scope="row" className={classes.row} >
          <SonifiTextInline
            select
            items={
              locations.filter(
                (suggestion) => ((suggestion.terminalId === id ||
                  !suggestion.terminalId ||
                  (isNew && suggestion.terminalId === 'Add')) && suggestion.is_active)
              ).map((suggestion) => ({
                id: suggestion.id,
                value: suggestion.name,
                label: suggestion.name,
              }))
            }
            defaultValue={`${location}`}
            change={this.handleChange('location')}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <SonifiLabel boldLabel={getNameFromObjectArray(languages, language)} />
        </TableCell>
        <TableCell component="th" scope="row" className={classes.row} >
          {lineups && lineups.length < 2
            ? <SonifiLabel boldLabel={getNameFromObjectArray(lineups, lineup_id)} />
            : <SonifiTextInline
              select
              items={lineups.map((suggestion) => ({
                id: suggestion.id,
                value: suggestion.id,
                label: suggestion.name,
              }))}
              defaultValue={`${lineup_id}`}
              change={this.handleChange('lineup_id')} />
          }
        </TableCell>
        <TableCell component="th" scope="row" className={classes.row} >
          {this.getMenusetOptions()}
        </TableCell>
        <TableCell component="th" scope="row" className={classes.row}>
          {swapId
            ? <SonifiSearchDropdown
              data={this.props.freeTerminals}
              dataName="terminals"
              displayFunc={this.createDisplayObj}
              filterFunc={this.createFilterObj}
              onSubmitSearch={this.handleChange('swapId')}
              placeholder={translations.searchPlaceholder}
              resource="terminals"
              siteObj={true} />
            : <SonifiLabel label={`${swapId || id}`} additionalClasses={classes.leftSide} />
          }
          {(!swapId && freeTerminals.length > 0 && checkSingleUserPermission(TERMINAL_DETAIL_EDIT, userPermissions)) &&
            <Tooltip title={globalTranslations.replace} aria-label={globalTranslations.replace}>
              <Loop
                className={`${classes.rightSide}`}
                onClick={this.swapTerm.bind(this)} /></Tooltip>
          }
        </TableCell>
      </TableRow >
    );
  }
}

const mapStateToProps = (state) => ({
  freeTerminals: state.terminals.freeTerminals,
  globalTranslations: state.global.translations.defaults,
  languages: state.termGrid.languages,
  lineups: state.terminals.lineups,
  locations: state.terminals.locations,
  menusets: state.termGrid.menusets,
  selectedTerminal: state.terminals.selectedTerminal,
  terminals: state.terminals.terminals,
  translations: state.rooms.translations.editDialog,
  userPermissions: state.global.permissions
});

SelectedRow.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  freeTerminals: PropTypes.array.isRequired,
  globalTranslations: PropTypes.object,
  languages: PropTypes.array.isRequired,
  lineups: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  menusets: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired,
  selectedTerminal: PropTypes.number.isRequired,
  terminals: PropTypes.array.isRequired,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(SelectedRow, styles, { withTheme: true }));

