import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { SonifiText } from '../../../../../containers';
import {
  ATSC, DIGITAL, digitalTuningValues, DVB_C, DVB_S, DVB_T, encryptionValues, mpegFormatValues
} from '../../../constants/constants';
import IntlDvbcForm from './IntlDvbcForm';
import IntlDvbsForm from './IntlDvbsForm';
import IntlDvbtForm from './IntlDvbtForm';

export default class ChannelTableDigitalColumn extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    if (this.props.dvbtObj.plp_id !== '') {
      this.state = {
        tuning: DVB_T,
      };
      this.props.editNonEventFunction(DVB_T, 'channelType', 'string', DVB_T);
    } else if (this.props.dvbcObj.frequency_khz !== '') {
      this.state = {
        tuning: DVB_C,
      };
      this.props.editNonEventFunction(DVB_C, 'channelType', 'string', DVB_C);
    } else if (this.props.dvbsObj.frequency_mhz !== '') {
      this.state = {
        tuning: DVB_S,
      };
      this.props.editNonEventFunction(DVB_S, 'channelType', 'string', DVB_S);
    } else {
      this.state = {
        modStream: `${this.props.digitalObj.modulator}-${this.props.digitalObj.stream}`,
        tuning: this.props.digitalObj.tuning,
      };
      this.props.editNonEventFunction(DIGITAL, 'channelType', 'string', DIGITAL);
    }
  }

  tuningEdit = ({ target: { value } }) => {
    this.setState({ tuning: value });
    this.props.editNonEventFunction(DIGITAL, 'channelType', 'string', value);
  };

  modStreamEdit = ({ target: { value } }) => {
    // Make sure the inputs are 0-9 or a '-' and make sure there is only 1 '-'
    if ((/^[0-9,-]*$/).test(value) && !((value.match(new RegExp('-', 'g')) || []).length > 1)) {
      this.setState({ modStream: value });
    }

    // If it fits the {mod}-{stream} format then save
    let mod = null,
      stream = null,
      modStreamType = null;
    if ((/^[0-9]+-[0-9]+$/).test(value)) {
      const valueArray = value.split('-');
      mod = valueArray[0];
      stream = valueArray[1];
      modStreamType = 'number';
    }
    this.props.editNonEventFunction(DIGITAL, 'modulator', modStreamType, mod);
    setTimeout(() => {
      this.props.editNonEventFunction(DIGITAL, 'stream', modStreamType, stream);
    }, 200);
  };

  render() {
    const {
      digitalObj, errors, readOnly, translations
    } = this.props;

    return (
      <Grid container>
        <Grid item>
          <SonifiText
            defaultValue={this.state.tuning}
            change={this.tuningEdit}
            select={true}
            label={translations.tuning}
            size="md"
            items={digitalTuningValues}
            error={!!errors.digitalTuning}
            errorText={(errors.digitalTuning ? errors.digitalTuning : '')}
            disabled={readOnly} />
          {this.state.tuning === ATSC
            ? <Fragment>
              <SonifiText
                defaultValue={(this.state.modStream === '-' ? '' : this.state.modStream)}
                label={translations.digModStream}
                change={this.modStreamEdit}
                size="md"
                error={!!errors.digitalModStream}
                errorText={(errors.digitalModStream ? errors.digitalModStream : '')}
                disabled={readOnly} />
              <SonifiText
                defaultValue={digitalObj.video_format}
                change={this.props.editFunction('digital', 'video_format')}
                select={true}
                label={translations.dFormat}
                size="md"
                items={mpegFormatValues}
                digitalFormat
                error={!!errors.digitalFormat}
                errorText={(errors.digitalFormat ? errors.digitalFormat : '')}
                disabled={readOnly} />
              <SonifiText
                defaultValue={digitalObj.encryption}
                change={this.props.editFunction('digital', 'encryption')}
                select={true}
                label={translations.dEncryption}
                size="md"
                items={encryptionValues}
                error={!!errors.digitalEncryption}
                errorText={(errors.digitalEncryption ? errors.digitalEncryption : '')}
                disabled={readOnly} />
            </Fragment>
            : ''}
        </Grid>
        {this.state.tuning === DVB_C
          ? <IntlDvbcForm
            readOnly={readOnly}
            editFunction={this.props.editFunction}
            editNonEventFunction={this.props.editNonEventFunction}
            dvbcObj={this.props.dvbcObj}
            tuning={this.state.tuning}
            errors={errors}
            translations={translations} />
          : ''}
        {this.state.tuning === DVB_S
          ? <IntlDvbsForm
            readOnly={readOnly}
            editFunction={this.props.editFunction}
            editNonEventFunction={this.props.editNonEventFunction}
            dvbsObj={this.props.dvbsObj}
            tuning={this.state.tuning}
            errors={errors}
            translations={translations} />
          : ''}
        {this.state.tuning === DVB_T
          ? <IntlDvbtForm
            readOnly={readOnly}
            editFunction={this.props.editFunction}
            editNonEventFunction={this.props.editNonEventFunction}
            dvbtObj={this.props.dvbtObj}
            tuning={this.state.tuning}
            errors={errors}
            translations={translations} />
          : ''}
        {/* <Grid item>
          <SonifiText
            defaultValue={digitalObj.mbps}
            label="Digital MBPS"
            change={this.props.editFunction('digital', 'mbps')}
            size="md"
            error={!!errors.digitalMbps}
            errorText={(errors.digitalMbps ? errors.digitalMbps : '')}
            disabled={readOnly}/>
        </Grid> */}
      </Grid>
    );
  }
}

ChannelTableDigitalColumn.propTypes = {
  digitalObj: PropTypes.object,
  dvbcObj: PropTypes.object,
  dvbsObj: PropTypes.object,
  dvbtObj: PropTypes.object,
  editFunction: PropTypes.func.isRequired,
  editNonEventFunction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
  translations: PropTypes.object
};
