import { CheckCircleOutline, Warning } from '@mui/icons-material';
import { TableCell, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import { updateDeleteRow, updateSelectedFirmware } from '../actions/firmwareActions';

const styles = (theme) => ({
  badStatus: {
    color: `${theme.palette.defaults.red} !important`
  },
  goodStatus: {
    color: 'green !important'
  }
});

class FirmwareRow extends Component {
  handleEditDialog(firmwareType, type) {
    this.props.dispatch(updateSelectedFirmware(this.props.rowIndex, firmwareType, type));
  }

  handleDeleteDialog() {
    const { dispatch, rowIndex, firmware } = this.props;
    dispatch(updateDeleteRow(firmware[rowIndex]));
  }

  render() {
    const {
      canEdit, classes, deleteEnabled, firmware, globalTranslations, key, rowIndex, translations
    } = this.props;
    const {
      active, corrupted, id, make, models, type, version
    } = firmware[rowIndex];

    let tempModelString = '',
      modelNames = '';
    if (models) {
      if (models[0] === '*') {
        modelNames = tempModelString = translations.allModels;
      } else {
        Object.values(models).forEach((value) => (tempModelString += `${value} | `));
        modelNames = tempModelString.trim().slice(0, -1);
      }
    }

    return (
      <SonifiPopover
        deleteDisabled={!deleteEnabled || active}
        deleteFunc={this.handleDeleteDialog.bind(this)}
        deleteTitle={globalTranslations.delete}
        editFunc={this.handleEditDialog.bind(this, make, 'edit')}
        editTitle={globalTranslations.edit}
        editDisabled={models[0] === '*'}
        functionOneFunc={this.handleEditDialog.bind(this, make, 'read')}
        functionOneTitle={globalTranslations.view}
        hideDelete={!canEdit}
        hideEdit={!canEdit}
        key={key}
        showFunctionOne={true}
        tableRow={
          <Fragment>
            <TableCell component="th" scope="row" >
              {active && <Tooltip title={globalTranslations.active}>
                <CheckCircleOutline className={classes.goodStatus} />
              </Tooltip>}
              {corrupted && <Tooltip title={translations.corruption}>
                <Warning className={classes.badStatus} />
              </Tooltip>}
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel flexDisplay label={make} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel flexDisplay label={type} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel flexDisplay label={version} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel flexDisplay label={id} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel flexDisplay label={modelNames} />
            </TableCell>
          </Fragment >
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  firmware: state.firmware.firmware,
  globalTranslations: state.global.translations.defaults,
  translations: state.firmware.translations.editDialog
});

FirmwareRow.propTypes = {
  canEdit: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  deleteEnabled: PropTypes.bool,
  dispatch: PropTypes.func,
  firmware: PropTypes.array.isRequired,
  globalTranslations: PropTypes.object,
  key: PropTypes.number,
  rowIndex: PropTypes.number.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(FirmwareRow, styles, { withTheme: true }));

