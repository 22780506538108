import { Add, Clear, FilterList } from '@mui/icons-material';
import {
  FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { find, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { IPG_SOURCES } from '../../../../constants/apiEndpoints';
import {
  SonifiIconButton, SonifiLabel, SonifiTableHead, SonifiText, SonifiTextAsync
} from '../../../../containers';
import SonifiAutoCompleteText from '../../../../containers/SonifiAutoCompleteText';
import SonifiPopover from '../../../../containers/SonifiPopover';
import { availableTimezones, IPG } from '../../../Channels2/constants/constants';

const blankChannel = {
  affiliate: '',
  call_letters: '',
  description: null,
  duration: null,
  entry: null,
  number: null,
  source_id: '',
  type: IPG
};

export class StepThree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      asyncSelectRef: null,
      errors: {
        id: '',
        number: ''
      },
      searchChannel: blankChannel,
      timeZoneSearch: ' '
    };

    this.addReplacementChannel = this.addReplacementChannel.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  getMoreInfo() {
    const { searchChannel } = this.state;
    const aff = (searchChannel.affiliate !== null && searchChannel.affiliate.length > 0)
      ? searchChannel.affiliate
      : 'none';
    const call = (searchChannel.call_letters !== null && searchChannel.call_letters.length > 0
      ? searchChannel.call_letters
      : 'none');
    return `${aff} | ${call}`;
  }

  editSearchCriteria = (name) => (event, value) => {
    this.setState({ [name]: (!value || value === null) ? '' : value.value });
  };

  createDisplayObj = (source) => ({
    label:
      `${source.affiliate ? source.affiliate : 'none'}|
      ${source.call_letters ? source.call_letters : 'none'}|
      ${source.name}|${source.timezone}`,
    value: source
  });

  createFilterObj = (inputData) => {
    const affCallL = inputData.split('|');
    if (affCallL.length > 1) {
      return {
        affiliate: (affCallL[0] === 'none' ? '' : affCallL[0]),
        call_letters: (affCallL[1] === 'none' ? '' : affCallL[1]),
        name: (affCallL[2] ? affCallL[2] : ''),
        platform: 'Marina',
        timezone: (affCallL[3] ? affCallL[3] : this.state.timeZoneSearch),
        provider: this.props.currentState.provider.toLowerCase(),
        limit: 100
      };
    }
    return {
      provider: this.props.currentState.provider.toLowerCase(),
      platform: 'Marina',
      search: inputData,
      timezone: this.state.timeZoneSearch,
      limit: 100
    };
  };

  getSelectRef = (node) => {
    this.setState({ asyncSelectRef: node });
  };

  handleChange = (option) => {
    if (option && option.value) {
      this.setState({
        searchChannel: {
          ...option.value,
          affiliate: option.value.affiliate || '',
          number: Number(this.state.searchChannel.number),
          source_id: `${option.value.id}`
        }
      });

      this.props.changeState('searchChannel', `${option.value.id}`);
    }
  };

  addReplacementChannel() {
    const { searchChannel } = this.state;
    const { currentState, stepThreeTranslations } = this.props;

    if (searchChannel.id === '' || searchChannel.source_id === '' ||
      searchChannel.number === null || searchChannel.number === '') {
      this.setState({
        errors: {
          id: searchChannel.source_id === '' ? stepThreeTranslations.addDialog.errors.noId : '',
          number: (searchChannel.number === null || searchChannel.number === '')
            ? stepThreeTranslations.addDialog.errors.noNumber
            : ''
        }
      });
      return;
    }

    let idError = '';
    let numberError = '';
    if (currentState.targetChannel.number === this.state.searchChannel.number) {
      numberError = stepThreeTranslations.addDialog.errors.channelToReplaceNumber;
    } else if (find(currentState.replacements, { number: this.state.searchChannel.number }) !== undefined) {
      numberError = stepThreeTranslations.addDialog.errors.numberExists; // 'Number Already in the replacements';
    }

    if (currentState.targetChannel.id === this.state.searchChannel.id) {
      idError = stepThreeTranslations.addDialog.errors.channelToReplaceSourceId;
    } else if (find(currentState.replacements, { id: this.state.searchChannel.id }) !== undefined) {
      idError = stepThreeTranslations.addDialog.errors.idExists;// 'ID already in the replacements';
    }

    if (idError !== '' || numberError !== '') {
      this.setState({ errors: { id: idError, number: numberError } });
      return;
    }
    const channels = [...currentState.replacements, this.state.searchChannel];
    this.props.changeState('replacements', uniqBy(channels, (e) => (e.id + e.number)));
    this.setState({ errors: { id: '', number: '' }, searchChannel: blankChannel });
  }

  editNumber = ({ target: { value } }) => {
    this.setState({ searchChannel: { ...this.state.searchChannel, number: (Number(value) < 0 ? 0 : Number(value)) } });
  };

  handleRadio = ({ target: { value } }) => {
    this.props.changeState('provider', value);
  };

  handleEditDialog(index) {
    const channels = JSON.parse(JSON.stringify(this.props.currentState.replacements));
    channels.splice(index, 1);
    this.props.changeState('replacements', channels);
  }

  render() {
    const {
      currentState, globalTranslations, providerValue, stepThreeTranslations, translations
    } = this.props;
    const { errors, searchChannel } = this.state;
    const hasError = currentState?.error?.replacements !== '';
    const tableHeader = [
      {
        id: 'tv', sortable: false, numeric: false, label: stepThreeTranslations.grid.tv, error: hasError
      },
      {
        id: 'name', sortable: false, numeric: false, label: stepThreeTranslations.grid.name, error: hasError
      },
      {
        id: 'affiliate',
        sortable: false,
        numeric: false,
        label: stepThreeTranslations.grid.affiliate,
        error: hasError
      },
      {
        id: 'tz', sortable: false, numeric: false, label: stepThreeTranslations.grid.tz, error: hasError
      },
      {
        id: 'source_id',
        sortable: false,
        numeric: false,
        label: stepThreeTranslations.grid.sourceID,
        error: hasError
      },
    ];

    const moreChannelInfo = this.getMoreInfo();
    return (
      <Grid container>
        <Grid item xs={4} style={{ paddingBottom: '15px' }}>
          <SonifiText
            defaultValue={searchChannel.number}
            change={this.editNumber}
            label={stepThreeTranslations.grid.tv}
            size="percent"
            type="number"
            helperText={stepThreeTranslations.grid.tvHelper}
            error={errors.number !== ''}
            errorText={errors.number}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item>
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Provider</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={currentState.provider}
                  onChange={this.handleRadio} >
                  <FormControlLabel value={providerValue[0]} control={<Radio />} label={providerValue[0]} />
                  <FormControlLabel value={providerValue[1]} control={<Radio />} label={providerValue[1]} />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <SonifiAutoCompleteText
                helperText={translations.timezoneFilter}
                label={translations.timezone}
                onChange={this.editSearchCriteria('timeZoneSearch')}
                options={availableTimezones}
                placeholder={translations.optional}
                popupIcon={<FilterList />}
                size="percent" />
            </Grid>
            <Grid item xs={7}>
              <SonifiTextAsync
                dataName="sources"
                isIpg
                displayFunc={this.createDisplayObj}
                filterFunc={this.createFilterObj}
                getRefFunc={this.getSelectRef}
                label={translations.sourceId}
                onSelect={this.handleChange}
                placeholder={(searchChannel.source_id !== '' ? moreChannelInfo : translations.affCall)}
                resource={IPG_SOURCES}
                siteObj={false}
                type={IPG_SOURCES} />
            </Grid>
            <Grid item xs={6}>
              <SonifiText
                defaultValue={searchChannel.source_id || ''}
                disabled={true}
                label={translations.sourceId}
                size="percent"
                error={errors.id !== ''}
                errorText={errors.id}
              />
            </Grid>
            <Grid item xs={1}>
              <SonifiIconButton
                icon={<Add />}
                label={globalTranslations.defaults.add}
                marginTop={6}
                disabled={currentState.replacements.length > 4}
                onClick={this.addReplacementChannel}
              />
            </Grid>
          </Grid>
        </Grid>
        <Table stickyHeader>
          <SonifiTableHead
            headColumns={tableHeader}
            hideSortIcon={true}
          />
          <TableBody>
            {currentState.replacements.length === 0
              ? <TableRow>
                <TableCell colSpan={tableHeader.length}>
                  <SonifiLabel blue label={stepThreeTranslations.grid.addChannel} />
                </TableCell>
              </TableRow>
              : currentState.replacements.map((channel, index) => (
                <SonifiPopover key={index}
                  functionOneFunc={this.handleEditDialog.bind(this, index)}
                  functionOneTitle={globalTranslations.defaults.remove}
                  functionOneIcon={<Clear />}
                  hideDelete={true}
                  hideEdit={true}
                  showFunctionOne={true}
                  tableRow={
                    <Fragment>
                      <TableCell> <SonifiLabel label={channel.number} /></TableCell>
                      <TableCell> <SonifiLabel label={channel.name} /></TableCell>
                      <TableCell> <SonifiLabel label={channel.affiliate} /></TableCell>
                      <TableCell> <SonifiLabel label={channel.timezone} /></TableCell>
                      <TableCell> <SonifiLabel label={channel.source_id} /></TableCell>
                    </Fragment >
                  }
                />
              ))}
          </TableBody>
        </Table>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations,
  stepThreeTranslations: state.batchChannels.translations,
  translations: state.channels.translations.editDialog
});

StepThree.propTypes = {
  changeState: PropTypes.func,
  currentState: PropTypes.object,
  globalTranslations: PropTypes.object,
  providerValue: PropTypes.array,
  stepThreeTranslations: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(StepThree);
