import { httpSuccess } from '../../../constants/http';
import { getTieObjectPromise, putTieObjectPromise } from '../../../utils/tieApi';
import {
  DISNEY, FIAS_IP, FIAS_SERIAL, GENERATOR, HSS, LODGISTIX, MARRIOTT, NONE, PMSD, PROMUS
} from '../constants/constants';
import * as types from './actionTypes';

export const getIntegrationsBegin = () => ({
  type: types.GET_INTEGRATIONS_BEGIN
});

export const getIntegrationsSuccess = (pms) => ({
  type: types.GET_INTEGRATIONS_SUCCESS,
  pms
});

export const getIntegrationsError = () => ({
  type: types.GET_INTEGRATIONS_FAILURE
});

export function getIntegrations() {
  return (dispatch) => {
    dispatch(getIntegrationsBegin());

    return getTieObjectPromise()
      .then((data) => {
        data.pms ?
        dispatch(getIntegrationsSuccess(data.pms ? data.pms : null)) :
        dispatch(getIntegrationsError());
        return data;
      }).catch((error) => {
        dispatch(getIntegrationsError());
        return error;
      });
  };
}

export const updateDeleteRow = (integration) => ({
  type: types.DELETE_CHECK,
  integration
});

export const updateIntegrationVendors = (vendors) => ({
  type: types.UPDATE_INTEGRATION_VENDORS,
  vendors
});

export const updateSelectedIntegration = (integration, editType) => ({
  type: types.UPDATE_SELECTED_INTEGRATION,
  integration,
  editType
});

export const addIntegrationBegin = () => ({
  type: types.ADD_INTEGRATION_BEGIN
});

export const addIntegrationSuccess = (integration, isNew) => ({
  type: types.ADD_INTEGRATION_SUCCESS,
  integration,
  isNew
});

export const addIntegrationError = (error, isNew) => ({
  type: types.ADD_INTEGRATION_FAILURE,
  error,
  isNew
});

export function addIntegration(isNew, integrationState, tvControlConfigs) {
  const integration = { ...integrationState };
  delete integration.errors;
  delete integration.isNew;
  console.log(`CHRISTINA integrationState.type is: ${integrationState.type}`);
  return (dispatch) => {
    dispatch(addIntegrationBegin());
    let integrationObject = null;
    if (integrationState.type === GENERATOR) {
      integrationObject = getGenerator(integrationState);
    } else if (integrationState.type === DISNEY) {
      integrationObject = getDisney(integrationState);
    } else if (integrationState.type === PMSD) {
      integrationObject = getPmsd(integrationState);
    } else if (integrationState.type === NONE) {
      integrationObject = {};
    }

    return putTieObjectPromise(integrationObject, tvControlConfigs)
      .then((response) => {
        if (!httpSuccess(response.status)) {
          dispatch(addIntegrationError(`${integrationObject.type} - (${response.status})`, isNew));
          return;
        }
        return dispatch(getIntegrations())
          .then((data) => {
            dispatch(addIntegrationSuccess(integrationObject, isNew));
            return data;
          }).catch((error) => error);
      }).catch((error) => {
        dispatch(addIntegrationError(`${integrationObject.type} - (${error.status})`, isNew));
        return error;
      });
  };
}

function getDisney(integration) {
  console.log('CHRISTINA getDisney');
  const generatorObj = {
    source: integration.source,
    type: integration.type,
  };
  console.log('CHRISTINA generatorObj is: ', generatorObj);
  return generatorObj;
}

function getGenerator(integration) {
  const generatorObj = {
    hour: integration.hour,
    timezone: integration.timezone,
    type: integration.type,
  };
  return generatorObj;
}

function removeNullus(obj) {
  return Object.entries(obj).reduce(
    (a, [k, v]) => (v === null ? a : ((a[k] = v), a)),
    {}
  );
}

function getPmsd(integration) {
  let tempInterface = {};
  const intType = integration.interface.interface_type;

  const generatorObj = {
    map: removeNullus(integration.map),
    type: integration.type,
    vendors: integration.vendors.map((a) => ({ ...a }))
  };

  if (intType === FIAS_IP) {
    generatorObj.ip = { ...integration.ip };
  } else {
    generatorObj.port = { ...integration.port };
  }

  tempInterface.interface_type = intType;
  tempInterface.post_credits_flag = integration.interface.post_credits_flag;
  tempInterface.post_no_charge_flag = integration.interface.post_no_charge_flag;
  tempInterface.tax_description = integration.interface.tax_description;
  tempInterface.revenue_code_tax = integration.interface.revenue_code_tax;
  tempInterface.interface_level = integration.interface.interface_level;
  tempInterface.interface_flag = integration.interface.interface_flag;

  if (intType === FIAS_IP || intType === FIAS_SERIAL) {
    tempInterface.post_tax_separate = false;
  } else if (intType === HSS || intType === PROMUS) {
    tempInterface.post_tax_separate = integration.interface.post_tax_separate;
  } else if (intType === LODGISTIX || intType === MARRIOTT) {
    tempInterface.name_format = integration.interface.name_format;
    tempInterface.post_tax_separate = integration.interface.post_tax_separate;
  } else {
    tempInterface = { ...integration.interface };
  }

  if (intType !== FIAS_IP && intType !== FIAS_SERIAL) {
    delete (generatorObj.map);
  }

  if (tempInterface.post_tax_separate) {
    generatorObj.map = {
      T1: integration.map.T1,
      S1: integration.map.S1
    };
  }

  if (tempInterface.name_format && tempInterface.name_format === 'none') {
    tempInterface.name_format = null;
  }

  generatorObj.interface = tempInterface;
  return generatorObj;
}

export const clearSuccessFailure = () => ({
  type: types.CLEAR_SUCCESS_FAILURE
});

export const deleteIntegrationBegin = () => ({
  type: types.DELETE_INTEGRATION_BEGIN
});

export const deleteIntegrationSuccess = (id) => ({
  type: types.DELETE_INTEGRATION_SUCCESS,
  id
});

export const deleteIntegrationError = (error) => ({
  type: types.DELETE_INTEGRATION_FAILURE,
  error
});

export function deleteAllIntegrations(integrationObject, tvControlConfigs) {
  return (dispatch) => {
    dispatch(deleteIntegrationBegin());

    return putTieObjectPromise(undefined, tvControlConfigs)
      .then((response) => {
        if (!httpSuccess(response.status)) {
          dispatch(deleteIntegrationError(`${integrationObject.type} - (${response.status})`));
          return;
        }
        return dispatch(getIntegrations())
          .then((data) => {
            dispatch(deleteIntegrationSuccess(integrationObject));
            return data;
          }).catch((error) => {
            dispatch(deleteIntegrationSuccess(integrationObject));
            return error;
          });
      }).catch((error) => {
        dispatch(deleteIntegrationError(`${integrationObject.type} - (${error.status})`));
        return error;
      });
  };
}

export const validateIntegrationFailure = (error) => ({
  type: types.VALIDATE_INTEGRATION_FAILURE,
  error
});
