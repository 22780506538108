import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SonifiAuthInput extends Component {
  onChange = (event) => {
    this.props.onChange(event);
  };

  render() {
    const { placeholder, defaultValue } = this.props;
    return (
      <div className=" react-tel-input "
        style={{
          border: 'none', left: '0px', padding: '0px', background: 'transparent', margin: '8px 0px 0.625rem'
        }}>
        <input className="form-control "
          onChange={this.onChange}
          placeholder={placeholder}
          value={defaultValue}
          style={{
            fontWeight: 400,
            borderRadius: 0,
            fontFamily: '"Open Sans"',
            fontSize: `${12}pt`,
            height: `${3.125}rem`,
            width: '100%',
            padding: '18.5px'
          }} />
      </div>
    );
  }
}

SonifiAuthInput.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};
