import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SonifiText } from '../../../../../containers';
import { AUX_CHANNEL, digitalAuxiliary, digitalSource } from '../../../constants/constants';
import { changeBinaryToDecimal, changeDecimalToBinary } from '../../../utils/binaryHelper';

export default class AuxiliaryDigital extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    const stream = (this.props.digitalObj.stream ? this.props.digitalObj.stream : '1');
    const binaryInfo = changeDecimalToBinary(stream);
    this.props.editNonEventFunction('digital', 'modulator', 'number', AUX_CHANNEL);
    setTimeout(() => {
      this.props.editNonEventFunction('digital', 'stream', 'number', stream);
    }, 200);
    this.state = {
      modStream: `${AUX_CHANNEL}-${stream}`,
      auxType: binaryInfo.substring(3),
      auxSource: binaryInfo.substring(0, 3)
    };
  }

  changeType({ target: { value } }) {
    this.updateData(value, this.state.auxSource);
  }

  changeSource({ target: { value } }) {
    this.updateData(this.state.auxType, value);
  }

  updateData(auxType, auxSource) {
    const binaryNumber = changeBinaryToDecimal(`${auxSource}${auxType}`);
    this.props.editNonEventFunction('digital', 'stream', 'number', binaryNumber);
    this.setState({
      auxType,
      auxSource,
      modStream: `${AUX_CHANNEL}-${binaryNumber}`
    });
  }

  render() {
    const { errors, readOnly, translations } = this.props;
    const { auxSource, auxType } = this.state;

    return (
      <Grid container>
        <Grid item>
          <SonifiText
            defaultValue={auxType}
            change={this.changeType.bind(this)}
            select={true}
            label={translations.auxType}
            size="md"
            items={digitalAuxiliary}
            disabled={readOnly} />
          <SonifiText
            defaultValue={auxSource}
            change={this.changeSource.bind(this)}
            select={true}
            label={translations.auxSource}
            size="md"
            items={digitalSource}
            disabled={readOnly} />
        </Grid>
        <Grid item>
          <SonifiText
            defaultValue={this.state.modStream}
            label={translations.digModStream}
            size="md"
            error={!!errors.digitalModStream}
            errorText={(errors.digitalModStream ? errors.digitalModStream : '')}
            disabled={true} />
        </Grid>
      </Grid>
    );
  }
}

AuxiliaryDigital.propTypes = {
  digitalObj: PropTypes.object.isRequired,
  editFunction: PropTypes.func.isRequired,
  editNonEventFunction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
  translations: PropTypes.object
};
