import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ERROR, MARINA, SUCCESS } from '../../../constants/constants';
import { SITE_ROLES } from '../../../constants/roleGroups';
import { SonifiLockoutModalSpinner } from '../../../containers';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiSnackBar from '../../../containers/SonifiSnackBar';
import SonifiTabs from '../../../containers/SonifiTabs';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { closeSnackBar, updateChangeVariable } from '../actions/siteManagementActions';
import { GENERAL, LOCATIONS, NETWORK } from '../constants/Tabs';
import General from './General';
import HcRoomFields from './HcRoomFields';
import HL7 from './HL7';
import IpNetworks from './IpNetworks';
import MarinaConfig from './MarinaConfig';
import SiteLocations from './SiteLocations';

class SiteTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.marinaOnlySite ? MARINA : GENERAL,
      showConfirm: false,
      nextTab: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.marinaOnlySite !== this.props.marinaOnlySite) {
      this.setState({
        value: this.props.marinaOnlySite ? MARINA : GENERAL
      });
    }
  }

  handleChange(event, newValue) {
    if (newValue !== this.state.value) {
      if (!this.props.allowTabChange) {
        this.setState({ showConfirm: true, nextTab: newValue });
      } else {
        this.changeTab(newValue);
      }
    }
  }

  confirmFunc = () => {
    this.props.dispatch(updateChangeVariable(true));

    this.setState({
      showConfirm: false,
      value: this.state.nextTab
    }, () => {
      this.setState({ nextTab: '' });
      this.props.dispatch(closeSnackBar());
    });
  };

  cancelFunc = () => {
    this.setState({ showConfirm: false });
  };

  changeTab(newValue) {
    this.setState({ value: newValue });
  }

  closeSnackBar = () => {
    this.props.dispatch(closeSnackBar());
  };

  getTabContainer() {
    switch (this.state.value) {
      case LOCATIONS:
        return <SiteLocations />;
      case GENERAL:
        return <General />;
      case NETWORK:
        return <IpNetworks />;
      case MARINA:
        return <MarinaConfig />;
      case 'HL7':
        return <HL7 />;
      case 'hcRoomConfig':
        return <HcRoomFields />;
      default:
        return <Fragment />;
    }
  }

  render() {
    const {
      marinaOnlySite, snackBar, snackbarOpen, snackBarMessage, snackBarMessageTwo, isHealthcareSite,
      snackBarTwo, translations, userPermissions, saving, chromecastSaving,
      loadingSite, terminalOptionsLoading, loading, ipNetworkSaving, globalTranslations
    } = this.props;
    const { value } = this.state;

    if (!checkForAtLeastOneUserPermission(SITE_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    const msg = (snackBarMessageTwo === '' ? snackBarMessage : `${snackBarMessage} \n ${snackBarMessageTwo}`);
    // if (snackBarTwo === SUCCESS && snackBar === SUCCESS) {
    //   msg = translations.saveSuccess;
    // }
    const sucFail = (snackBarTwo === SUCCESS && snackBar === SUCCESS ? SUCCESS : ERROR);
    let showSnackbar = false;
    if (value === GENERAL || value === MARINA) {
      showSnackbar = !(saving || chromecastSaving);
    } else if (value === LOCATIONS) {
      showSnackbar = !(loadingSite || terminalOptionsLoading || loading);
    } else if (value === NETWORK) {
      showSnackbar = !(ipNetworkSaving);
    }

    const tabsArray = marinaOnlySite
      ? [({ id: MARINA, title: translations.marina.title })]
      : [
        { id: GENERAL, title: translations.general.title },
        { id: LOCATIONS, title: translations.locations.title },
        { id: NETWORK, title: translations.ipNetwork.title }
      ];

    if (isHealthcareSite) {
      tabsArray.push({ id: 'HL7', title: translations.hl7.title });
      tabsArray.push({ id: 'hcRoomConfig', title: translations.hcRoomConfig.title });
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={saving || chromecastSaving || ipNetworkSaving || loading} />

        <SonifiTabs
          tabs={tabsArray}
          handleChange={this.handleChange}
          tabValue={value}
          showingTab={this.getTabContainer()} />

        {showSnackbar &&
          <SonifiSnackBar message={msg} variant={sucFail}
            open={snackbarOpen} onClose={this.closeSnackBar} />}

        <SonifiConfirm
          dialogOpen={this.state.showConfirm}
          onConfirm={this.confirmFunc}
          onCancel={this.cancelFunc}
          confirmTitle={translations.general.confirmTitle}
          confirmText={translations.general.confirmText}
          buttonCancelText={globalTranslations.return}
          buttonConfirmText={globalTranslations.continueWithoutSaving}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allowTabChange: state.siteManagement.allowTabChange,
  change: state.global.change,
  chromecastSaving: state.siteManagement.chromecastSaving,
  globalTranslations: state.global.translations.defaults,
  ipNetwork: state.siteManagement.ipNetwork,
  ipNetworkSaving: state.siteManagement.ipNetworkSaving,
  isHealthcareSite: state.global.isHealthcareSite,
  loading: state.siteManagement.loading,
  loadingSite: state.global.loadingSite,
  marinaOnlySite: state.global.marinaOnlySite,
  saving: state.siteManagement.saving,
  snackBar: state.siteManagement.snackBar,
  snackBarMessage: state.siteManagement.snackBarMessage,
  snackBarMessageTwo: state.siteManagement.snackBarMessageTwo,
  snackbarOpen: state.siteManagement.snackbarOpen,
  snackBarTwo: state.siteManagement.snackBarTwo,
  terminalOptionsLoading: state.terminals.terminalOptionsLoading,
  translations: state.siteManagement.translations,
  userPermissions: state.global.permissions
});

SiteTabs.propTypes = {
  allowTabChange: PropTypes.bool,
  chromecastSaving: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  ipNetwork: PropTypes.object,
  ipNetworkSaving: PropTypes.bool,
  isHealthcareSite: PropTypes.bool,
  loading: PropTypes.bool,
  loadingSite: PropTypes.bool,
  marinaOnlySite: PropTypes.bool,
  saving: PropTypes.bool.isRequired,
  snackBar: PropTypes.string,
  snackBarMessage: PropTypes.string,
  snackBarMessageTwo: PropTypes.string,
  snackbarOpen: PropTypes.bool,
  snackBarTwo: PropTypes.string,
  terminalOptionsLoading: PropTypes.bool,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

// export default connect(mapStateToProps)(withStyles(SiteTabs, styles, { withTheme: true }));
export default connect(mapStateToProps)(SiteTabs);
