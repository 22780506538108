import { Add } from '@mui/icons-material';
import {
  Grid, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { EDIT } from '../../../constants/constants';
import { DISTANCE_FROM_END } from '../../../constants/magic';
import { SOFTWARE_ROLES } from '../../../constants/roleGroups';
import { SOFTWARE_WRITE } from '../../../constants/roles';
import {
  SonifiCheckbox, SonifiConfirm, SonifiLabel, SonifiLockoutModalSpinner, SonifiSnackBar, SonifiSpinner,
  SonifiTableHead, SonifiText
} from '../../../containers';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { buildSortString } from '../../../utils';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../../utils/rolesUtil';
import {
  getProductParents
} from '../../Deployments/actions/deploymentsActions';
import {
  clearTable, deleteTemplateById, getReleaseTemplates, makeTemplateImmutable, updateDeleteRow,
  updateImmutableRow, updateSelectedReleaseTemplate, updateSnackBar
} from '../actions/releaseTemplateActions';
import ReleaseRow from '../containers/ReleaseRow';
import CreateNewReleaseDialog from './CreateNewReleaseDialog';
import ReleaseTemplateDialog from './ReleaseTemplateDialog';

export class ReleaseTemplatesGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: true,
      order: 'asc',
      orderBy: 'product',
      page: 1,
      product_code: 'All'
    };

    this.addNewTemplate = this.addNewTemplate.bind(this);
    this.addReleaseTemplate = this.addReleaseTemplate.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.confirmDialogCancelFunc = this.confirmDialogCancelFunc.bind(this);
    this.confirmDialogConfirmFunc = this.confirmDialogConfirmFunc.bind(this);
    this.getMoreData = this.getMoreData.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getProductParents());
    dispatch(clearTable());

    setTimeout(() => {
      dispatch(getReleaseTemplates(this.getFilter()));
    }, 200);
  }

  addNewTemplate() {
    this.setState({
      product_code: 'All', active: false, page: 1
    }, () => {
      this.props.dispatch(getReleaseTemplates(this.getFilter()));
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(getReleaseTemplates(this.getFilter()));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(getReleaseTemplates(this.getFilter()));
    }
  }

  getFilter() {
    const filterCode = buildSortString(this.state);
    if (this.state.product_code !== 'All') {
      filterCode.product_code = this.state.product_code;
    }
    return filterCode;
  }

  handleRequestSort = (property) => {
    const { dispatch } = this.props;
    this.props.dispatch(updateSelectedReleaseTemplate(-1, EDIT));
    dispatch(clearTable());
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    });
  };

  confirmDialogConfirmFunc() {
    const {
      dispatch, deleteTemplate, immutable, productName, releaseTemplates
    } = this.props;
    if (immutable !== -1) {
      if (releaseTemplates[immutable].active) {
        dispatch(makeTemplateImmutable({ ...releaseTemplates[immutable], active: false }, productName));
      } else {
        dispatch(makeTemplateImmutable({ ...releaseTemplates[immutable], active: true }, productName));
      }
    } else {
      this.setState({
        page: 1
      }, () => {
        dispatch(deleteTemplateById(
          deleteTemplate.id, productName, deleteTemplate.version, this.getFilter()
        ));
      });
    }
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteRow(null));
    this.props.dispatch(updateImmutableRow(-1));
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  closeSnackBar() {
    this.props.dispatch(updateSnackBar(''));
  }

  addReleaseTemplate() {
    this.props.dispatch(updateSelectedReleaseTemplate(-2, EDIT));
  }

  handleTermLocationChange = ({ target: { value } }) => {
    this.setState({ product_code: value, page: 1 },
      () => {
        this.props.dispatch(clearTable());
        this.props.dispatch(getReleaseTemplates(this.getFilter()));
      });
  };

  editToggleValue = ({ target: { checked } }) => {
    this.setState({ active: checked, page: 1 },
      () => {
        this.props.dispatch(clearTable());
        this.props.dispatch(getReleaseTemplates(this.getFilter()));
      });
  };

  getPageDetails(canEdit) {
    const {
      loading, productParents, templateLoading, translations,
    } = this.props;

    return (
      <Grid container justifyContent="end">
        <Grid item>
          <SonifiCheckbox
            label={translations.active}
            // noLabel={true}
            onChange={this.editToggleValue}
            value={this.state.active}
          />
        </Grid>
        <Grid item xs={3}>
          <SonifiText
            change={this.handleTermLocationChange}
            defaultValue={this.state.product_code}
            items={
              [{ id: 'All', name: 'All' }, ...productParents].map((suggestion) => ({
                id: suggestion.id,
                value: suggestion.id,
                label: suggestion.name,
              }))
            }
            label={translations.productName}
            select={true}
            size="percentNoPad"
            disabled={templateLoading || loading}
          />
        </Grid>
        <Grid item xs={12}>
          {this.getTable(canEdit)}
        </Grid>
      </Grid>
    );
  }

  getTable(canEdit) {
    const { order, orderBy, page } = this.state;
    const {
      globalTranslations, loading, releaseTemplates, maxPages, templateLoading, translations,
    } = this.props;

    const tableHeader = [
      { id: 'immutable', sortable: releaseTemplates.length > 1, numeric: false, label: '' },
      { id: 'active', sortable: releaseTemplates.length > 1, numeric: false, label: `${globalTranslations.active}` },
      { id: 'product', sortable: releaseTemplates.length > 1, numeric: false, label: `${translations.productName}` },
      { id: 'version', sortable: releaseTemplates.length > 1, numeric: false, label: `${translations.version}` },
      { id: 'virtos', sortable: releaseTemplates.length > 1, numeric: false, label: `${translations.virtOS}` },
      { id: 'packageReq', sortable: false, numeric: false, label: `${translations.packageReq}` }
    ];

    return <Table stickyHeader={true}>
      <SonifiTableHead
        headColumns={tableHeader}
        order={order}
        orderBy={orderBy}
        onRequestSort={this.handleRequestSort}
      />
      {templateLoading || loading
        ? <TableBody>
          <TableRow>
            <TableCell colSpan={6}>
              <SonifiSpinner />
            </TableCell>
          </TableRow>
        </TableBody>
        : <TableBody>
          {(!releaseTemplates || releaseTemplates.length === 0)
            ? <TableRow>
              <TableCell colSpan={6}>
                <SonifiLabel error label={translations.errors.noData} />
              </TableCell>
            </TableRow>
            : releaseTemplates.map((option, index) => (
              <Fragment key={`w_${index}`}>
                <ReleaseRow key={index} rowIndex={index} deleteEnabled={canEdit && !loading} canEdit={canEdit} />

                {index === releaseTemplates.length - DISTANCE_FROM_END && page < maxPages && (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Waypoint onEnter={this.getMoreData} />
                    </TableCell>
                  </TableRow>)
                }
              </Fragment>
            ))}
        </TableBody>}
    </Table>;
  }

  isDialogOpen() {
    const { deleteTemplate, immutable, popupLoading, saving } = this.props;
    return ((deleteTemplate !== null && deleteTemplate !== undefined) || immutable !== -1) &&
      !popupLoading && !saving;
  }

  getDialogText() {
    const {
      deleteTemplate, immutable, productName, translations, releaseTemplates
    } = this.props;
    if (immutable !== -1) {
      if (releaseTemplates[immutable].active) {
        return translations.deleteDialog.deactivateText;
      } else if (releaseTemplates[immutable].immutable) {
        return translations.deleteDialog.reactivateText;
      } else {
        return translations.deleteDialog.activateText;
      }
    } else if (deleteTemplate) {
      const tempText = `${translations.deleteDialog.deleteText} ${productName}`;
      const versionText = `${translations.version} ${deleteTemplate.version}?`;
      return `${tempText} ${versionText}?`;
    }
    return '';
  }

  getDialogTitle() {
    const {
      deleteTemplate, immutable, productName, translations, releaseTemplates
    } = this.props;
    if (immutable !== -1) {
      let title = '';
      if (releaseTemplates[immutable].active) {
        title = translations.deleteDialog.deactivateTitle;
      } else if (releaseTemplates[immutable].immutable) {
        title = translations.deleteDialog.reactivateTitle;
      } else {
        title = `${translations.deleteDialog.activateTitle}`;
      }
      title += ` - ${productName} ${translations.version} ${releaseTemplates[immutable].version}`;
      return title;
    } else if (deleteTemplate) {
      return translations.deleteDialog.deleteTitle;
    }
    return '';
  }

  getButtonText() {
    const { globalTranslations, immutable, releaseTemplates } = this.props;
    if (immutable !== -1) {
      if (releaseTemplates[immutable].active) {
        return globalTranslations.deactivate;
      } else if (releaseTemplates[immutable].immutable) {
        return globalTranslations.reactivate;
      } else {
        return globalTranslations.activate;
      }
    }
    return globalTranslations.delete;
  }

  render() {
    const {
      globalTranslations, productParents, saving, selected, snackBarMessage,
      snackBarType, totalItems, translations, userPermissions, createNewRelease
    } = this.props;

    if (!checkForAtLeastOneUserPermission(SOFTWARE_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    const canEdit = checkForSingleUserPermission(SOFTWARE_WRITE, userPermissions);

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={saving} />
        <SonifiConfirm
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={this.getButtonText()}
          confirmText={this.getDialogText()}
          confirmTitle={this.getDialogTitle()}
          dialogOpen={this.isDialogOpen()}
          onCancel={this.confirmDialogCancelFunc}
          onConfirm={this.confirmDialogConfirmFunc}
        />
        <SonifiTemplate
          header={translations.header}
          icon={<Add />}
          label={globalTranslations.add}
          onSubmit={this.addReleaseTemplate}
          pageDetails={this.getPageDetails(canEdit)}
          showButton={totalItems > -1 && productParents.length > 0 && canEdit}
        />
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar} />
        {selected !== -1 && <ReleaseTemplateDialog canEdit={canEdit} addNewTemplate={this.addNewTemplate} />}
        {createNewRelease && <CreateNewReleaseDialog />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.releaseTemplates.changed,
  createNewRelease: state.releaseTemplates.createNewRelease,
  deleteTemplate: state.releaseTemplates.deleteTemplate,
  globalTranslations: state.global.translations.defaults,
  immutable: state.releaseTemplates.immutable,
  loading: state.releaseTemplates.loading,
  maxPages: state.releaseTemplates.maxPages,
  platforms: state.releaseTemplates.platforms,
  popupLoading: state.releaseTemplates.popupLoading,
  productName: state.releaseTemplates.productName,
  productParents: state.deployments.productParents,
  releaseTemplates: state.releaseTemplates.releaseTemplates,
  saving: state.releaseTemplates.saving,
  selected: state.releaseTemplates.selected,
  snackBarMessage: state.releaseTemplates.snackBarMessage,
  snackBarType: state.releaseTemplates.snackBarType,
  templateLoading: state.deployments.loading,
  totalItems: state.releaseTemplates.totalItems,
  translations: state.releaseTemplates.translations.main,
  userPermissions: state.global.permissions
});

ReleaseTemplatesGrid.propTypes = {
  changed: PropTypes.bool,
  createNewRelease: PropTypes.bool,
  createTranslations: PropTypes.object,
  deleteTemplate: PropTypes.object,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  immutable: PropTypes.number,
  loading: PropTypes.bool,
  maxPages: PropTypes.number,
  platforms: PropTypes.array,
  popupLoading: PropTypes.bool,
  productName: PropTypes.string,
  productParents: PropTypes.array,
  releaseTemplates: PropTypes.array,
  saving: PropTypes.bool,
  selected: PropTypes.number,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  templateLoading: PropTypes.bool,
  totalItems: PropTypes.number,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(ReleaseTemplatesGrid);
