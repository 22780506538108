import { Add, Check, GetApp, Warning } from '@mui/icons-material';
import {
  Dialog, DialogContent, Grid, Table, TableBody,
  TableCell, TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import { SonifiIconButton, SonifiLabel, SonifiModalHeader, SonifiTableHead } from '../';
import { MAX_SIZE } from '../../constants/magic';
import SonifiDropZone from '../SonifiDropZone/SonifiDropZone';

const styles = (theme) => ({
  channelListContainer: {
    overflow: 'auto',
    height: '100%',
    paddingBottom: '5px'
  },
  dropFile: {
    height: '45%',
    width: '45%',
    backgroundColor: theme.palette.secondary.main
  },
  centered: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.contrastText,
    fontSize: '12pt',
    fontFamily: 'Open Sans',
    fontWeight: 900, /* Avenir 85 */
    outline: 'none'
  },
  noPadding: {
    padding: 0
  },
  textCenter: {
    textAlign: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '100%',
    rowGap: '5%'
  },
  hoverCursor: {
    cursor: 'cell'
  },
  gridHeight: {
    height: '80%',
    marginTop: '10%'
  },
  gridHeightTall: {
    height: '75%',
  },
  gridHeightShort: {
    height: '15%',
    width: '100%'
  }
});

class ImportDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableList: null,
      allowSave: false,
      importError: null
    };

    this.downloadCsv = this.downloadCsv.bind(this);
    this.getIntroStuff = this.getIntroStuff.bind(this);
    this.onAcceptedDrop = this.onAcceptedDrop.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  onAcceptedDrop(acceptedFiles) {
    const { parseImportFile } = this.props;
    const reader = new FileReader();
    reader.onload = (function() {
      const lines = reader.result.split(/\r?\n|\r/),
        result = lines.map((line) => line.split(','));

      const tableInfo = parseImportFile(result);
      tableInfo.errors
        ? this.setState({
          importError: tableInfo.errors
        })
        : this.setState({
          tableList: tableInfo.tableList,
          allowSave: !(tableInfo.allowSave),
          headers: tableInfo.headers,
          columnNames: tableInfo.columnNames,
          importError: null
        });
    }).bind(this);
    reader.readAsText(acceptedFiles[0]);
  }

  onSave = () => {
    const { serverValidation } = this.props;
    if (serverValidation) {
      const result = this.props.saveListFunc(this.state.tableList);
      result.then((data) => {
        if (Object.prototype.hasOwnProperty.call(data, 'detail')) {
          const terminalErrors = data.detail.terminals;
          for (const row in terminalErrors) {
            const newTableList = this.state.tableList;
            newTableList[row] = {
              ...this.state.tableList[row],
              error: terminalErrors[row],
              toolTip: Object.values(terminalErrors[row]).join('\n')
            };
            this.setState({
              tableList: newTableList,
              allowSave: false
            });
          }
        }
      }).catch((error) => {
        console.log(error);
      });
    } else {
      this.props.saveListFunc(this.state.tableList);
    }
  };

  downloadCsv() {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(this.props.csvText)}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'example.csv';
    hiddenElement.click();
  }

  createTable() {
    return this.props.getTableBody(this.state.tableList);
  }

  getRow(rowInfo, index) {
    const { columnNames } = this.state;
    let error = null;

    if (rowInfo.error) {
      error = rowInfo.error;
      delete rowInfo.error;
    }

    return (
      <TableRow key={index}>
        <TableCell>
          {(error &&
            <SonifiIconButton
              icon={<Warning />}
              showToolTip={true}
              toolTipMessage={`${rowInfo.toolTip}`}
              usePrimary={false}
            />
          )}
        </TableCell>
        {columnNames.map((name, indexOne) => (
          <TableCell key={indexOne}>
            {name.type === 'label' && rowInfo[name.id] &&
              <SonifiLabel label={(rowInfo[name.id] === -1
                ? 'ERROR'
                : `${rowInfo[name.id]}`)}
                error={error && !!error[name.id]} />}
            {name.type === 'check' && rowInfo[name.id] &&
              <SonifiIconButton
                showToolTip={true}
                extraStyles={rowInfo[name.id].error ? { color: 'red' } : {}}
                toolTipMessage={rowInfo[name.id].toolTip}
                usePrimary={false}
                icon={<Check />}
              />
            }
          </TableCell>
        ))}
      </TableRow>
    );
  }

  getIntroStuff() {
    return <Grid container className={this.props.classes.textCenter}>
      <Grid item>
        <div >
          <Add />
          <SonifiLabel additionalClasses={this.props.classes.noPadding} label={this.props.globalTranslations.addCsv} />
          <SonifiLabel additionalClasses={this.props.classes.noPadding} label={this.props.globalTranslations.addDrop} />
        </div>
      </Grid>
    </Grid>;
  }

  render() {
    const {
      classes, title, globalTranslations
    } = this.props;
    const { allowSave, headers, importError, tableList } = this.state;

    return (
      <Dialog open={true}
        onClose={this.onCancel}
        fullWidth
        maxWidth="lg"
        PaperProps={{
          'data-testid': 'import-dialog-component'
        }}
      >
        <SonifiModalHeader
          header={title}
          onlyClose={!allowSave}
          onCancel={this.onCancel}
          onSubmit={this.onSave}
          label={globalTranslations.import}
        />
        <DialogContent >
          {tableList && tableList.length > 0
            ? <div className={classes.channelListContainer}>
              <Table stickyHeader={true}>
                <SonifiTableHead
                  headColumns={headers}
                  hideSortIcon={true}
                />
                <TableBody>
                  {tableList.map((channel, index) => (
                    this.getRow(channel, index)
                  ))}
                </TableBody>
              </Table>
            </div>
            : <Grid container className={`${classes.gridHeight} ${classes.textCenter}`}>
              <Grid item xs={8} className={classes.gridHeightTall}>
                <SonifiDropZone
                  acceptedFiles={['text/csv', '.csv', 'application/vnd.ms-excel']}
                  allowMultiple={false}
                  maxSize={MAX_SIZE}
                  onAcceptedDropHandler={this.onAcceptedDrop}
                  displayDropZoneContent={this.getIntroStuff}
                />
              </Grid>
              <Grid item className={`${classes.gridHeightShort} ${classes.textCenter}`}>
                <SonifiIconButton icon={<GetApp />}
                  label={globalTranslations.example}
                  onClick={this.downloadCsv} />
                {importError !== null && <SonifiLabel error label={importError} />}
              </Grid>
            </Grid>
          }
        </DialogContent>
      </Dialog>
    );
  }
}

ImportDialog.propTypes = {
  csvText: PropTypes.string,
  classes: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  getTableBody: PropTypes.func,
  globalTranslations: PropTypes.object,
  isValidForm: PropTypes.func,
  parseImportFile: PropTypes.func.isRequired,
  saveListFunc: PropTypes.func.isRequired,
  serverValidation: PropTypes.bool,
  title: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

export default (withStyles(ImportDialog, styles, { withTheme: true }));
