import { saveIpNetworkFailure, saveIpNetworkSuccess } from '../components/SiteManagement/actions/siteManagementActions';
import {
  OPTIONS_RESOURCE, ROOM_RESOURCE, SITES, SYSTEMS
} from '../constants/apiEndpoints';
import { httpSuccess } from '../constants/http';
import { getObjectPromise, getSiteObjectPromise, patchObjectPromise } from '../utils/api';
import {
  buildUrl, compareValues, fetchData, getSiteNumFromURI, setHasCoreChannels,
  setHasStreamChannels, setGroupServices, setHotelDir, setLateCheckout
} from '../utils/index';
import { setSiteDisplayName, setSiteName } from '../v2/GlobalSlices/siteSlice';
import * as types from './globalActionTypes';

export const updateChange = () => ({
  type: types.CHANGE
});

export const resetLoadingSite = () => ({
  type: types.RESET_LOADING_SITE
});

export const getSystemScheduleBegin = () => ({
  type: types.GET_SYSTEM_SCHEDULE_BEGIN
});

export const getSystemScheduleSuccess = (systems) => ({
  type: types.GET_SYSTEM_SCHEDULE_SUCCESS,
  systems
});

export const getSystemScheduleError = (error) => ({
  type: types.GET_SYSTEM_SCHEDULE_ERROR,
  error
});

export const updateDevProd = () => ({
  type: types.UPDATE_PRODUCTION_STATUS,
});

export const setProdStatus = () => ({
  type: types.SET_PROD_STATUS
});

export const setTabSelected = (tabSelected) => ({
  type: types.SET_TAB_SELECTED,
  tabSelected
});

export const updateLanguages = (languages) => ({
  type: types.UPDATE_LANGUAGES,
  payload: { languages }
});

export const updateSite = (siteInfo) => ({
  type: types.UPDATE_SITE,
  siteInfo
});

export const updateRole = (role) => ({
  type: types.UPDATE_ROLE,
  role
});

export const updateGroup = (group) => ({
  type: types.UPDATE_GROUP,
  group
});

export const updateSites = (sites) => ({
  type: types.UPDATE_SITES,
  sites
});

export const loadLiterals = (lang) => ({
  type: types.LOAD_LANGUAGE_LITERALS,
  lang
});

export const loadSiteHierarchyBegin = () => ({
  type: types.LOAD_START_HIERARCHY_BEGIN
});

export const loadSiteHierarchySuccess = (siteConfig) => ({
  type: types.LOAD_START_HIERARCHY_SUCCESS,
  buildings: siteConfig.buildings
});

export const loadSiteHierarchyFailure = (error) => ({
  type: types.LOAD_START_HIERARCHY_FAILURE,
  error
});

export const hierarchyLoaded = (showDeadBranches) => ({
  type: types.HIERARCHY_LOADED,
  showDeadBranches
});


export function getSystemSchedule(siteId = null) {
  const siteNum = (siteId ? siteId : getSiteNumFromURI());
  return (dispatch) => {
    dispatch(getSystemScheduleBegin());
    return getSiteObjectPromise(SYSTEMS, null, siteNum)
      .then((json) => getSiteObjectPromise(SYSTEMS, json[0].id, siteNum)
        .then((schedJson) => {
          dispatch(getSystemScheduleSuccess(schedJson));
          return schedJson;
        }))
      .catch((error) => dispatch(getSystemScheduleError(error)));
  };
}

export function loadSiteHierarchy(model, showDeadBranches = true) {
  return (dispatch) => {
    dispatch(loadSiteHierarchyBegin());
    return getSiteObjectPromise(OPTIONS_RESOURCE)
      .then((json) => {
        if (json.buildings) {
          // dispatch(loadSiteHierarchySuccess(json));
          appendItem(json, dispatch).then(() => {
            const filteredStuff = [];
            for (let j = 0; j < json.buildings.length; j++) {
              if (json.buildings[j].floors && json.buildings[j].floors !== null) {
                for (let k = 0; k < json.buildings[j].floors.length; k++) {
                  filteredStuff.push(getFiltered(
                    dispatch, json.buildings[j].name, json.buildings[j].floors[k].name, null, model
                  ));
                }
              }
            }

            Promise.all(filteredStuff).then(() => {
              dispatch(hierarchyLoaded(showDeadBranches));
            });
          });
        } else {
          dispatch(loadSiteHierarchySuccess({ buildings: [] }));
          dispatch(hierarchyLoaded(showDeadBranches));
        }
        return json;
      })
      .catch((error) => dispatch(loadSiteHierarchyFailure(error)));
  };
}

const appendItem = (json, dispatch) => new Promise((resolve) => {
  // do anything here
  dispatch(loadSiteHierarchySuccess(json));
  resolve();
});

export function getFiltered(dispatch, building, floor, unit, model) {
  const filterObj = {
    building,
    floor,
    limit: 500,
    model,
    offset: 0,
    unit
  };

  if (!filterObj.building) {
    delete filterObj.building;
  }
  if (!filterObj.floor) {
    delete filterObj.floor;
  }
  if (!filterObj.unit) {
    delete filterObj.unit;
  }
  if (!filterObj.model) {
    delete filterObj.model;
  }

  return getSiteObjectPromise(ROOM_RESOURCE, null, null, filterObj)
    .then((json) => {
      dispatch(filterRoomsSuccess(json, building, floor, unit));
      return json;
    }).catch((error) => {
      console.log('filterRoomsFailure PROMISE FAIL');
      console.log(error);

      // dispatch(filterRoomsFailure(error));
    });
}

export const filterRoomsSuccess = (rooms, building, floor, unit) => ({
  type: types.LOAD_HIERARCHY_ROOMS_SUCCESS,
  building,
  floor,
  unit,
  rooms: rooms.rooms.sort(compareValues('id'))

  // rooms: rooms.rooms
});

export const updateCurrentUser = (token) => ({
  type: types.UPDATE_CURRENT_USER,
  token
});

export const updateGlobalError = (error) => ({
  type: types.UPDATE_GLOBAL_ERROR,
  error
});

export const updateProfileUser = (token) => ({
  type: types.UPDATE_PROFILE,
  token
});

export function getProvisionSiteObjectPromise(resource, info, siteId, obj) {
  return new Promise((resolve, reject) => {
    const siteNum = (siteId ? siteId : getSiteNumFromURI());
    let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}`, obj);

    if (info) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}/${info}`, obj);
    }
    fetchData(url)
      .then((data) => {
        resolve(data);
      })
      .catch((reason) => reject(reason));
  });
}

export const getSiteBegin = () => ({
  type: types.GET_SITE_BEGIN
});

export const getSiteSuccess = (site) => ({
  type: types.GET_SITE_SUCCESS,
  site
});

export const getSiteFailure = (error) => ({
  type: types.GET_SITE_FAILURE,
  error
});

export function getSite(siteId) {
  return (dispatch) => {
    dispatch(getSiteBegin());
    const siteNum = (siteId ? siteId : getSiteNumFromURI());
    return getObjectPromise(SITES, siteNum)
      .then((site) => {
        const displayName = `${siteNum} : ${site.name}`;
        dispatch(setSiteName(site.name));
        dispatch(setSiteDisplayName(displayName));
        setHotelDir(site.has_hotel_directory);
        setGroupServices(site.has_group_services);
        setLateCheckout(site.has_late_checkout);
        setHasCoreChannels(site.has_core_channels);
        setHasStreamChannels(site.has_stream_channels);
        dispatch(getSiteSuccess(site));
        return site;
      }).catch((error) => {
        dispatch(getSiteFailure(error));
        return error;
      });
  };
}

export const patchSiteBegin = () => ({
  type: types.PATCH_SITE_BEGIN
});

export const patchSiteSuccess = (site) => ({
  type: types.PATCH_SITE_SUCCESS,
  site
});

export const patchSiteFailure = (error) => ({
  type: types.PATCH_SITE_FAILURE,
  error
});

export function patchSite(name, value) {
  return (dispatch) => {
    dispatch(patchSiteBegin());
    const patchValue = (typeof value === 'boolean' ? value : { ...value });
    return patchObjectPromise(null, null, { [name]: patchValue })
      .then((json) => {
        if (!httpSuccess(json.status)) {
          dispatch(patchSiteFailure(json.status));
          if (name === 'iptv_network') {
            dispatch(saveIpNetworkFailure(json));
          }
          return json;
        }
        dispatch(getSite()).then(() => {
          dispatch(patchSiteSuccess(json));
        });
        if (name === 'iptv_network') {
          dispatch(saveIpNetworkSuccess());
        }
        return json;
      }).catch((error) => {
        dispatch(patchSiteFailure(error.status));
        if (name === 'iptv_network') {
          dispatch(saveIpNetworkFailure(error));
        }
      });
  };
}

export const displaySnackBar = (type, message, persist = false) => (dispatch) => {
  if (!persist) {
    dispatch(updateSnackBar(type, message));
    setTimeout(() => {
      dispatch(closeSnackBar());
    }, 5000);
  } else {
    dispatch(updateSnackBar(type, message));
  }
};

export const updateSnackBar = (snackbarType, message) => ({
  type: types.UPDATE_SNACK_BAR,
  message,
  snackbarType
});

export const closeSnackBar = () => ({
  type: types.CLOSE_SNACK_BAR
});
