import { maxBy } from 'lodash';
import { PRODUCT_PARENTS, SOFTWARE_OPTIONS, SYSTEMS } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import {
  getSitelessObjectPromise, getSiteObjectPromise, patchObjectPromise, putObjectPromise
} from '../../../utils/api';
import * as types from './actionTypes';

export const updateSnackBar = (snackBarMessage, snackBarType) => ({
  type: types.UPDATE_SNACK_BAR,
  snackBarMessage,
  snackBarType,
});

export const getSystemsBegin = () => ({
  type: types.GET_SYSTEMS_BEGIN,
});

export const getSystemsSuccess = (systems, systemId) => ({
  type: types.GET_SYSTEMS_SUCCESS,
  systems,
  systemId
});

export const getSystemsError = (error) => ({
  type: types.GET_SYSTEMS_ERROR,
  error,
});

export function getSystems() {
  return (dispatch) => {
    dispatch(getSystemsBegin());
    return getSiteObjectPromise(SYSTEMS)
      .then((json) => {
        const selected = maxBy(json, (o) => o.date).id;
        dispatch(getSystemsSuccess(json, selected));
        return json;
      })
      .catch((error) => dispatch(getSystemsError(error)));
  };
}

export const getScheduleBegin = () => ({
  type: types.GET_SCHEDULE_BEGIN,
});

export const getScheduleSuccess = (systemId, schedule) => ({
  type: types.GET_SCHEDULE_SUCCESS,
  schedule,
  systemId,
});

export const getScheduleError = (error) => ({
  type: types.GET_SCHEDULE_ERROR,
  error,
});

export function getScheduleData(systemId) {
  return (dispatch) => {
    dispatch(getScheduleBegin());
    return getSiteObjectPromise(SYSTEMS, systemId, null, null)
      .then((json) => {
        dispatch(getScheduleSuccess(systemId, json));
        return json;
      })
      .catch((error) => {
        dispatch(getScheduleError(error));
        return error;
      });
  };
}

export const getProductsBegin = () => ({
  type: types.GET_PRODUCTS_BEGIN,
});

export const getProductsSuccess = (productId, versions) => ({
  type: types.GET_PRODUCTS_SUCCESS,
  productId,
  versions,
});

export const getProductsError = (error) => ({
  type: types.GET_PRODUCTS_ERROR,
  error,
});

export function getProductsData(productId, virtos, channel = 'production') {
  return (dispatch) => {
    dispatch(getProductsBegin());

    return getSitelessObjectPromise(PRODUCT_PARENTS, `${productId}/versions`, { channel, virtos, limit: 2000 })
      .then((json) => {
        if (json.page_count < 2) {
          dispatch(getProductsSuccess(productId, json.versions));
          return json;
        }
      })
      .catch((error) => dispatch(getProductsError(error)));
  };
}

export const getUpgradeWindowBegin = () => ({
  type: types.GET_UPGRADE_WINDOW_BEGIN
});

export const getUpgradeWindowSuccess = (upgrade) => ({
  type: types.GET_UPGRADE_WINDOW_SUCCESS,
  upgrade
});

export const getUpgradeWindowFailure = (error) => ({
  type: types.GET_UPGRADE_WINDOW_FAILURE,
  error
});

export function getUpgradeWindow() {
  return (dispatch) => {
    dispatch(getUpgradeWindowBegin());

    return getSiteObjectPromise(SOFTWARE_OPTIONS)
      .then((json) => {
        dispatch(getUpgradeWindowSuccess(json));
        return json;
      })
      .catch((error) => {
        error.json()
          .then((err) => {
            dispatch(getUpgradeWindowFailure(`${err.detail} (${err.status})`));
          }).catch((err) => {
            dispatch(getUpgradeWindowFailure(`${err.status})`));
          });
      });
  };
}

export const setUpgradeWindowBegin = () => ({
  type: types.SET_UPGRADE_WINDOW_BEGIN
});

export const setUpgradeWindowSuccess = (upgradeObject) => ({
  type: types.SET_UPGRADE_WINDOW_SUCCESS,
  upgradeObject
});

export const setUpgradeWindowFailure = (status) => ({
  type: types.SET_UPGRADE_WINDOW_FAILURE,
  status
});

export function setUpgradeWindow(upgradeObject) {
  return (dispatch) => {
    dispatch(setUpgradeWindowBegin());
    return putObjectPromise(SOFTWARE_OPTIONS, null, upgradeObject)
      .then((data) => {
        if (!httpSuccess(data.status)) {
          dispatch(setUpgradeWindowFailure(data.status));
          return data;
        }
        dispatch(setUpgradeWindowSuccess(upgradeObject));
        return data;
      }).catch((error) => {
        dispatch(setUpgradeWindowFailure(error.status));
        return error;
      });
  };
}

export function justSetUpgradeWindow(upgradeObject, siteId) {
  return putObjectPromise(SOFTWARE_OPTIONS, null, upgradeObject, siteId)
    .then((data) => {
      if (!httpSuccess(data.status)) {
        return data.json()
          .then((errorObjDetails) => ({ status: data.status, details: errorObjDetails.detail }));
      }
      return { status: data.status };
    }).catch((error) => error);
}

export const updateHostRow = (hostRow) => ({
  type: types.UPDATE_HOST_ROW,
  hostRow
});

export const upgradeSystemHostBegin = () => ({
  type: types.UPDATE_HOST_BEGIN
});

export const upgradeSystemHostSuccess = (msg) => ({
  type: types.UPDATE_HOST_SUCCESS,
  msg
});

export const upgradeSystemHostFailure = (error) => ({
  type: types.UPDATE_HOST_FAILURE,
  error
});

// https://sonifi.atlassian.net/wiki/spaces/CCA/pages/870711313/APP+API+-+System+Management
export function upgradeSystemHost(systemId, productParent, upgradeVersion, ppName) {
  return (dispatch) => {
    dispatch(upgradeSystemHostBegin());

    const submitObj = {
      id: productParent,
      version: upgradeVersion
    };

    return patchObjectPromise(`${SYSTEMS}/${systemId}/${PRODUCT_PARENTS}`, null, submitObj)
      .then((json) => {
        if (!httpSuccess(json.status)) {
          dispatch(upgradeSystemHostFailure(json.status));
          return json;
        } else {
          return dispatch(getScheduleData(systemId))
            .then(() => {
              dispatch(upgradeSystemHostSuccess(`${ppName}-${upgradeVersion}`));
              return json;
            });
        }
      }).catch((error) => {
        dispatch(upgradeSystemHostFailure(error.status));
        return error;
      });
  };
}

export function justUpgradeSystemHost(systemId, productParent, upgradeVersion, siteId) {
  const submitObj = {
    id: productParent,
    version: upgradeVersion
  };

  return patchObjectPromise(`${SYSTEMS}/${systemId}/${PRODUCT_PARENTS}`, null, submitObj, siteId)
    .then((data) => {
      if (!httpSuccess(data.status)) {
        return data.json()
          .then((errorObjDetails) => ({ status: data.status, details: errorObjDetails.detail }));
      }
      return { status: data.status };
    }).catch((error) => error);

  // .then((json) => json)
  // .catch((error) => error);
}
