import { combineReducers } from 'redux';
import auditInfo from '../components/AuditReport/reducers';
import batchChannels from '../components/BatchChannels/reducers';
import logo from '../components/ChannelLogos/reducers';
import channelsReducers from '../components/Channels2/reducers';
import cloudStream from '../components/CloudStream/reducers';
import deploymentsReducer from '../components/Deployments/reducers';
import firmware from '../components/Firmware/reducers';
import globalTermFiles from '../components/GlobalTermFiles/reducers';
import groups from '../components/GroupManagement/reducers';
import groupServices from '../components/GroupServices/reducers/';
import icpReducers from '../components/Icp/reducers';
import integrations from '../components/Integrations/reducers';
import downloadsReducer from '../components/IpgDownloads/reducers';
import lateCheckout from '../components/LateCheckout/reducers';
import messagingReducers from '../components/Messaging/reducers';
import profileReducers from '../components/Profile/reducers';
import provisionTerms from '../components/ProvisionTerminals/reducers';
import releaseTemplatesReducer from '../components/ReleaseTemplates/reducers';
import pmsLoggingReducer from '../components/Reports/Pms/reducers';
import purchaseLogsReducer from '../components/Reports/Purchase/reducers';
import termCommReducer from '../components/Reports/TermComm/reducers';
import terminalLogs from '../components/Reports/TerminalReport/reducers';
import roleManagement from '../components/RoleManagement/reducers';
import rolesDev from '../components/RolesDev/reducers';
import roomsReducers from '../components/Rooms/reducers';
import siteLocation from '../components/SiteLocationSelection/reducers';
import siteManagement from '../components/SiteManagement/reducers';
import softwareChanges from '../components/SoftwareChanges/reducers';
import systemManagement from '../components/SystemManagement/reducers';
import termFileReducers from '../components/TermFiles/reducers';
import termGridReducers from '../components/Terminals/reducers';
import termModelReducers from '../components/TermModels/reducers';
import tieClientsReducer from '../components/TieApiClients/reducers';
import tvLineupReducer from '../components/TvLineup/reducers';
import users from '../components/UserManagement/reducers';
import globalReducers from './globalIndex';
import siteReducers from './siteIndex';
import getV2Reducers from '../v2/rootReducer';

const v2Reducers = getV2Reducers();

const appReducer = combineReducers({
  ...auditInfo,
  ...channelsReducers,
  ...cloudStream,
  ...deploymentsReducer,
  ...downloadsReducer,
  ...firmware,
  ...globalReducers,
  ...globalTermFiles,
  ...groups,
  ...groupServices,
  ...icpReducers,
  ...integrations,
  ...lateCheckout,
  ...logo,
  ...batchChannels,
  ...messagingReducers,
  ...pmsLoggingReducer,
  ...profileReducers,
  ...provisionTerms,
  ...purchaseLogsReducer,
  ...releaseTemplatesReducer,
  ...roleManagement,
  ...rolesDev,
  ...roomsReducers,
  ...siteLocation,
  ...siteManagement,
  ...siteReducers,
  ...softwareChanges,
  ...systemManagement,
  ...termCommReducer,
  ...termFileReducers,
  ...termGridReducers,
  ...terminalLogs,
  ...termModelReducers,
  ...tieClientsReducer,
  ...tvLineupReducer,
  ...users,
  ...v2Reducers
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  // if (action.type === 'USER_LOGGED_OUT' || action.type === 'DESTROY_SESSION') {
  //  state = undefined;
  // }
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
