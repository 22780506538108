import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { SonifiSubHeader, SonifiText } from '../../../../../containers';
import {
  bandwidthValues, dvbtModulationValues, DVB_T, encryptionValues, mpegFormatValues
} from '../../../constants/constants';

export default class IntlDvbtForm extends Component {
  render() {
    const { dvbtObj, errors, readOnly, translations } = this.props;

    return (
      <Fragment>
        <Grid item>
          <SonifiSubHeader
            header={translations.international}
            showButton={false}
          />
          <SonifiText
            defaultValue={dvbtObj.carrier}
            label={translations.carrier}
            size="md"
            disabled={true} />
          <SonifiText
            defaultValue={dvbtObj.frequency_khz}
            change={this.props.editFunction(DVB_T, 'frequency_khz')}
            label={translations.frequencyKhz}
            size="md"
            error={!!errors.dvbtFrequency}
            errorText={(errors.dvbtFrequency ? errors.dvbtFrequency : '')}
            disabled={readOnly} />
        </Grid>
        <Grid item>
          <SonifiText
            defaultValue={dvbtObj.video_format}
            change={this.props.editFunction(DVB_T, 'video_format')}
            select={true}
            label={translations.dFormat}
            size="md"
            items={mpegFormatValues}
            error={!!errors.dvbtFormat}
            errorText={(errors.dvbtFormat ? errors.dvbtFormat : '')}
            disabled={readOnly} />
          <SonifiText
            defaultValue={dvbtObj.encryption}
            change={this.props.editFunction(DVB_T, 'encryption')}
            select={true}
            label={translations.dEncryption}
            size="md"
            items={encryptionValues}
            error={!!errors.dvbtEncryption}
            errorText={(errors.dvbtEncryption ? errors.dvbtEncryption : '')}
            disabled={readOnly} />
        </Grid>
        <Grid item>
          <SonifiText
            defaultValue={dvbtObj.stream}
            change={this.props.editFunction(DVB_T, 'stream')}
            label={translations.dIntlStream}
            size="md"
            error={!!errors.dvbtStream}
            errorText={(errors.dvbtStream ? errors.dvbtStream : '')}
            disabled={readOnly} />
          <SonifiText
            defaultValue={dvbtObj.modulation}
            change={this.props.editFunction(DVB_T, 'modulation')}
            select={true}
            label={translations.dModulation}
            size="md"
            items={dvbtModulationValues}
            error={!!errors.dvbtModulation}
            errorText={(errors.dvbtModulation ? errors.dvbtModulation : '')}
            disabled={readOnly} />
        </Grid>
        <Grid item>
          <SonifiText
            defaultValue={dvbtObj.bandwidth}
            change={this.props.editFunction(DVB_T, 'bandwidth')}
            select={true}
            label={translations.dBandwidth}
            size="md"
            items={bandwidthValues}
            error={!!errors.dvbtBandwidth}
            errorText={(errors.dvbtBandwidth ? errors.dvbtBandwidth : '')}
            disabled={readOnly} />
          <SonifiText
            defaultValue={dvbtObj.plp_id}
            change={this.props.editFunction(DVB_T, 'plp_id')}
            label={translations.plpID}
            size="md"
            error={!!errors.plpID}
            errorText={(errors.plpID ? errors.plpID : '')}
            disabled={readOnly} />
        </Grid>
      </Fragment>
    );
  }
}

IntlDvbtForm.propTypes = {
  dvbtObj: PropTypes.object,
  editFunction: PropTypes.func.isRequired,
  editNonEventFunction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
  translations: PropTypes.object,
  tuning: PropTypes.string
};
