import { Lock, LockOpen, MoreVert } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { SonifiLabel, SonifiTooltip } from '../../../containers';
import DragNDropIcon from '../../../containers/DragNDrop/DragNDropIcon';
import SonifiIconButton from '../../../containers/SonifiIconButton';
import * as actions from '../actions/channelsActions';
import ChannelNumberTextField from './ChannelNumberTextField';

const CHAN_MAX = 10000,
  CHAN_MIN = 0;

const styles = (theme) => ({
  backgroundTransition: {
    transition: 'background-color 1s linear'
  },
  highlightedRow: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    transition: 'background-color 0s linear !important'
  },
  rowHover: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.hoverColor} !important`,
      color: theme.palette.primary.contrastText
    }
  }
});

class ChannelRow extends Component {
  state = {
    channelErrors: [],
    collapseTried: false,
    errorChanged: false,
    showTransition: false
  };

  // Calls resort channels after you unfocus from the channel number field
  channelEditBlur = () => {
    const { dispatch, readOnly, rowIndex } = this.props;
    if (!readOnly) {
      dispatch(actions.resortChannels(rowIndex));
    }
  };

  // Changes channel number in the redux store
  editChannelNumber = ({ target: { value } }) => {
    if (!this.props.readOnly && value < CHAN_MAX && value > CHAN_MIN) {
      this.props.dispatch(actions.updateChannelDetails(this.props.rowIndex, null, 'id', value));
    }
  };

  // Changes locked status in redux store
  editLocked = () => {
    if (!this.props.readOnly) {
      const locked = this.props.channels[this.props.rowIndex].locked;
      this.props.dispatch(actions.updateChannelDetails(this.props.rowIndex, null, 'locked', !locked));
    }
  };

  // Sets the selected row to open up pop up
  toggleRow = () => {
    // this.props.dispatch(closeLineupSaveSuccess());
    // this.props.dispatch(clearSuccessFailure());
    const { dispatch, channel } = this.props;
    dispatch(actions.updateSelectedChannel(channel));
  };

  toggleChannelNumber = () => {
    const { dispatch, readOnly, rowIndex } = this.props;
    if (!readOnly) {
      dispatch(actions.editChannelNum(rowIndex));
    }
  };

  // Handles the blue to white transition after drop
  componentDidUpdate = () => {
    if (this.props.droppedRow === this.props.rowIndex) {
      setTimeout(() => {
        this.setState({ showTransition: true });

        this.props.dispatch(actions.setDroppedRow(-1));
        setTimeout(() => {
          this.setState({ showTransition: false });
        }, 1000);
      }, 500);
    }
  };

  /* eslint-disable max-lines-per-function */
  render() {
    const {
      channel, classes, drag, draggingRow, droppedRow, editChannel,
      globalTranslations, readOnly, rowIndex, translations
    } = this.props;

    return (
      <Grid container className={`${draggingRow === -1
        ? classes.rowHover
        : ''} ${this.state.showTransition
          ? classes.backgroundTransition
          : ''} ${(rowIndex === droppedRow)
            ? classes.highlightedRow
            : ''}`}
        style={{
          border: '1px solid #dfe0e2',
          backgroundColor: `${rowIndex % 2 === 0 ? '#f6f6f6' : '#ffffff'}`
        }}
        onContextMenu={this.props.menuEvent(rowIndex)}
      >
        <Grid item xs={1}>
          <Grid container>
            <Grid item xs={6}
              {...drag.dragHandleProps}
              style={{
                visibility: (channel.locked) ? 'hidden' : 'visible',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              {!readOnly &&
                <SonifiTooltip
                  icon={<DragNDropIcon fontSize="small" style={{ cursor: 'move' }} />}
                  title={`${translations.move} ${translations.channel}`} disabled={!readOnly} />
              }
            </Grid>
            <Grid item xs={6}>
              {!readOnly && <SonifiIconButton icon={channel.locked ? <Lock /> : <LockOpen />} onClick={this.editLocked}
                toolTipMessage={`${channel.locked
                  ? globalTranslations.unlock
                  : globalTranslations.lock}`}
                showToolTip
                extraStyles={{ minWidth: 0 }} />}
            </Grid>
          </Grid>
        </Grid>
        {!readOnly && editChannel === rowIndex
          ? <Grid item xs={1} >
            <ChannelNumberTextField
              editFunc={this.editChannelNumber}
              onBlur={this.channelEditBlur}
              enterFunc={this.channelEditBlur}
              disabled={channel.locked}
              redText={!channel.enabled}
              value={channel.id}
              readOnly={readOnly}
            />
          </Grid>
          : <Grid item xs={1} onClick={this.toggleChannelNumber}>
            <SonifiLabel label={`${channel.id}`} />
          </Grid>
        }
        <Grid item xs={2} onClick={this.toggleRow}>
          <SonifiLabel label={channel.type} />
        </Grid>
        <Grid item xs={3} onClick={this.toggleRow}>
          <SonifiLabel label={channel.name} />
        </Grid>
        <Grid item xs={3} onClick={this.toggleRow}>
          <SonifiLabel label={channel.ipg.source_id} />
        </Grid>
        <Grid item xs={1} onClick={this.toggleRow}>
          <SonifiLabel label={(channel.enabled ? translations.enabled : translations.disabled)} />
        </Grid>
        <Grid item xs={1}>
          {!readOnly && <SonifiIconButton icon={<MoreVert />}
            onClick={this.props.menuEvent(rowIndex)}
            toolTipMessage={translations.chanOptions} />}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  // changed: state.channels.changed,
  channels: state.channels.realChannels,
  draggingRow: state.channels.draggingRow,
  droppedRow: state.channels.droppedRow,
  editChannel: state.channels.editChannelNumber,
  globalTranslations: state.global.translations.defaults,
  translations: state.channels.translations.channelHeaders
});

ChannelRow.propTypes = {
  changed: PropTypes.bool,
  channel: PropTypes.object.isRequired,
  channels: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  drag: PropTypes.object,
  draggingRow: PropTypes.number,
  droppedRow: PropTypes.number,
  editChannel: PropTypes.number,
  globalTranslations: PropTypes.object,
  menuEvent: PropTypes.func,
  readOnly: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(ChannelRow, styles, { withTheme: true }));

