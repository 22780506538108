import {
  Alert, Button, PasswordField, TextField, View
} from '@aws-amplify/ui-react';
import { Auth, I18n } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import '../../../AmplifyTheme.css';
import FormFrame from './FormFrame';
import { KEYCODE_ENTER } from '../../../constants/keyCodes';

const formFields = {
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: 'Enter your email address',
      isRequired: true,
      label: 'Email Address *'
    }
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter code',
      isRequired: true,
      label: 'Verification Code'
    },
    password: {
      labelHidden: false,
      placeholder: 'Enter your new password',
      isRequired: true,
      label: 'New Password'
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Confirm your new password',
      isRequired: true,
      label: 'Confirm Password'
    }
  }
};

const PasswordReset = (props) => {
    const [username, setUsername] = useState('');
    const [resetCode, setResetCode] = useState();
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordVerify, setNewPasswordVerify] = useState('');
    const [resetSent, setResetSent] = useState(false);
  
    const passwordResetHander = async () => {
      try {
        // Send confirmation code to user's email
        await Auth.forgotPassword(username);
        setResetSent(true);
        props.setResetSubmitMsg({
          type: 'success', heading: 'Verification Code Sent!', msg: 'Please check your email.'
        });
      } catch(error) {
        console.log(`${error}`);
      }
    };
  
    const passwordResetSubmitHandler = async () => {
      try {
        // Collect confirmation code and new password, then
        Auth.forgotPasswordSubmit(username, resetCode, newPassword);
      } catch(error) {
        console.log(`${error}`);
      }
    };

  const handleSendCodeEnter = (e) => {
    if (e.keyCode === KEYCODE_ENTER) {
      passwordResetHander();
    }
  };
  
  const handlePasswordResetEnter = (e) => {
    if (e.keyCode === KEYCODE_ENTER) {
      passwordResetSubmitHandler();
    }
  };
  
    return (
      <FormFrame>
        <View as="div" id="password-reset">
          { !resetSent &&
            <Fragment>
              <TextField {...formFields.resetPassword.username}
                onChange={(event) => setUsername(event.target.value)}
                onKeyDown={handleSendCodeEnter}
                data-testid="password-reset-username-field"
              />
              {props.resetError &&
                <Alert
                  variation="error"
                  isDismissible={false}
                  hasIcon={true}
                >{props.resetError}</Alert>
              }
              <Button
                variation="primary"
                isFullWidth
                onClick={passwordResetHander}
                type="button"
              >{I18n.get('passwordReset')}</Button>
              <Button
                variation="link"
                isFullWidth
                onClick={() => props.setAuthState('login')}
              >
                {I18n.get('passwordResetBack')}
              </Button>
            </Fragment>
          }
          { (resetSent && (props.resetError === undefined)) &&
            <Fragment>
              <TextField
                {...formFields.confirmResetPassword.confirmation_code}
                onChange={(event) => setResetCode(event.target.value)}
                onKeyDown={handlePasswordResetEnter}
                data-testid="password-reset-confirmation-code-field"
              />
              <PasswordField
                {...formFields.confirmResetPassword.password}
                onChange={(event) => setNewPassword(event.target.value)}
                onKeyDown={handlePasswordResetEnter}
                data-testid="password-reset-new-field-one"
              />
              <PasswordField
                {...formFields.confirmResetPassword.confirm_password}
                onChange={(event) => setNewPasswordVerify(event.target.value)}
                onKeyDown={handlePasswordResetEnter}
                hasError={newPassword !== newPasswordVerify}
                errorMessage="Your passwords should match"
                data-testid="password-reset-new-field-two"
              />
              {props.resetSubmitMsg &&
                <Alert
                  variation={props.resetSubmitMsg.type}
                  isDismissible={false}
                  hasIcon={true}
                  heading={props.resetSubmitMsg.heading ?? undefined}
                >{props.resetSubmitMsg.msg}</Alert>
              }
              <Button
                variation="primary"
                isFullWidth
                onClick={passwordResetSubmitHandler}
                type="button"
              >{I18n.get('passwordResetSubmit')}</Button>
              <Button
                variation="link"
                isFullWidth
                onClick={() => Auth.forgotPassword(username)}
              >
                {I18n.get('passwordResetResend')}
              </Button>
            </Fragment>
          }
        </View>
      </FormFrame>
    );
  };

PasswordReset.propTypes = {
  setAuthState: PropTypes.func,
  setResetSubmitMsg: PropTypes.func,
  resetError: PropTypes.string,
  resetSubmitMsg: PropTypes.object
};

  export default PasswordReset;
