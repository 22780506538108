import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel, SonifiSpinner, SonifiTableHead } from '../../../containers';
import { buildSortString } from '../../../utils';
import { getAllLineupsForChannelSet } from '../actions/tvLineupActions';
import LineupRow from '../containers/LineupRow';

class Lineups extends Component {
  state = {
    order: 'asc',
    orderBy: 'name'
  };

  // componentDidMount() {
  //   const { dispatch } = this.props;
  //   this.setState({ automaticLoading: true });
  //   dispatch(clearSonifiFirmware());
  //   dispatch(getTerminalOptions())
  //     .then((data) => {
  //       this.setState({ automatic: data.automatic_updates.SONIFI, automaticLoading: false });
  //     });
  //   setTimeout(() => {
  //     dispatch(fetchSonifiFirmware());
  //   }, 200);
  // }

  handleRequestSort = (property) => {
    console.log('Lineups:handleRequestSort', property);
    const { channelSet, dispatch } = this.props;
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      orderBy: property,
      order: isDesc ? 'asc' : 'desc',
      page: 1
    }, () => {
      dispatch(getAllLineupsForChannelSet(channelSet.id, buildSortString(this.state)));
    });
  };

  // debouncedAutoUpdate = debounce(this.autoUpdate, KEYBOARD_DELAY);

  // handleCheckChange = (name) => ({ target: { checked } }) => {
  //   this.setState({ [name]: checked }, () => {
  //     this.debouncedAutoUpdate();
  //   });
  // }

  // autoUpdate() {
  //   // this.props.dispatch(patchTerminalOptions({ automatic_updates: { SONIFI: this.state.automatic } }));
  //   const { dispatch, translations } = this.props;
  //   dispatch(resetSnackBar(null, ''));
  //   dispatch(patchTerminalOptions({ automatic_updates: { SONIFI: this.state.automatic } }))
  //     .then((data) => {
  //       const snackBarType = !httpSuccess(data.status) ? ERROR : SUCCESS;
  //       const snackBarMessage = !httpSuccess(data.status) ? translations.sonifi.error : translations.sonifi.success;
  //       dispatch(resetSnackBar(snackBarType, snackBarMessage));
  //     });
  // }

  // getPageDetails() {
  //   const {
  //     canEdit, sonifiFirmware, sonifiLoading, translations
  //   } = this.props;
  //   const { order, orderBy } = this.state;

  //   const tableHeader = [
  //     { id: 'id', sortable: true, numeric: false, label: `${translations.id}` },
  //     { id: 'firmware', sortable: true, numeric: false, label: `${translations.sonifi.firmware}` }
  //   ];

  //   return (
  //     <Table>
  //       <SonifiTableHead
  //         headColumns={tableHeader}
  //         order={order}
  //         orderBy={orderBy}
  //         onRequestSort={this.handleRequestSort}
  //       />
  //       {sonifiLoading
  //         ? <TableBody>
  //           <TableRow>
  //             <TableCell colSpan={2}>
  //               <SonifiSpinner />
  //             </TableCell>
  //           </TableRow>
  //         </TableBody>
  //         : <TableBody>
  //           {!sonifiFirmware || sonifiFirmware.length === 0
  //             ? <TableRow>
  //               <TableCell colSpan={2}>
  //                 <SonifiLabel error label={translations.sonifi.errors.noTerminals} />
  //               </TableCell>
  //             </TableRow >
  //             : sonifiFirmware.map((option, i) => (
  //               <SonifiRow key={i} rowIndex={i} canEdit={canEdit} />
  //             ))}
  //         </TableBody>
  //       }
  //     </Table>
  //   );
  // }

  render() {
    const {
      canEdit, lineups, lineupsLoading, translations
    } = this.props;
    const { order, orderBy } = this.state;

    // return (
    //   <Fragment>
    //     <SonifiTemplate
    //       header={translations.sonifi.title}
    //       showButton={false}
    //       middleExtra={
    //         automaticLoading
    //           ? <SonifiSpinner size="sm" />
    //           : <SonifiSwitch
    //             disabled={!canEdit || sonifiLoading}
    //             label={translations.sonifi.automatic}
    //             onChange={this.handleCheckChange('automatic')}
    //             checked={automatic}
    //           />
    //       }
    //       multiple={true}
    //       pageDetails={this.getPageDetails()} />
    //     {sonifiIndex !== -1 && <EditSonifiFirmware canEdit={canEdit} />}
    //   </Fragment>
    // );

    const tableHeader = [
      { id: 'status', sortable: false, numeric: false, label: `${translations.status}` },
      { id: 'name', sortable: true, numeric: false, label: `${translations.name}` },
      { id: 'classification', sortable: true, numeric: false, label: `${translations.classification}` },
      { id: 'cables', sortable: true, numeric: false, label: `${translations.cables}` },
      { id: 'tuner_type', sortable: true, numeric: false, label: `${translations.tuner}` },
      { id: 'mpeg_format', sortable: true, numeric: false, label: `${translations.mpeg}` },
    ];

    return (
      <Table>
        <SonifiTableHead
          headColumns={tableHeader}
          order={order}
          orderBy={orderBy}
          onRequestSort={this.handleRequestSort}
        />
        {lineupsLoading
          ? <TableBody>
            <TableRow>
              <TableCell colSpan={6}>
                <SonifiSpinner />
              </TableCell>
            </TableRow>
          </TableBody>
          : <TableBody>
            {!lineups || lineups.length === 0
              ? <TableRow>
                <TableCell colSpan={6}>
                  <SonifiLabel error label={translations.errors.noLineups} />
                </TableCell>
              </TableRow >
              : lineups.map((option, i) => (
                <LineupRow key={i} canEdit={canEdit} lineup={option} />
              ))}
          </TableBody>
        }
      </Table>
    );
  }
}

const mapStateToProps = (state) => ({
  channelSet: state.channels.channelSet,
  lineups: state.tvLineup.channelLineups,
  lineupsLoading: state.tvLineup.lineupsLoading,
  translations: state.tvLineup.translations.lineupGrid
});

Lineups.propTypes = {
  canEdit: PropTypes.bool,
  channelSet: PropTypes.object,
  dispatch: PropTypes.func,
  lineups: PropTypes.array,
  lineupsLoading: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(Lineups);
