import { ROLES } from '../../../constants/apiEndpoints';
import { getObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

export const fetchRolesBegin = () => ({
  type: types.FETCH_ROLES_BEGIN
});

export const fetchRolesSuccess = (roles) => ({
  type: types.FETCH_ROLES_SUCCESS,
  roles
});

export const fetchRolesFailure = (error) => ({
  type: types.FETCH_ROLES_FAILURE,
  error
});

export const deleteRoleBegin = () => ({
  type: types.DELETE_ROLE_BEGIN
});

export const deleteRoleSuccess = (index) => ({
  type: types.DELETE_ROLE_SUCCESS,
  index
});

export const deleteRoleFailure = (error) => ({
  type: types.DELETE_ROLE_FAILURE,
  error
});

export const fetchRoleBegin = () => ({
  type: types.FETCH_ROLE_BEGIN
});

export const fetchRoleSuccess = (roleId, role) => ({
  type: types.FETCH_ROLE_SUCCESS,
  roleId,
  role
});

export const fetchRoleFailure = (error) => ({
  type: types.FETCH_ROLE_FAILURE,
  error
});

export const updateRoleLoading = () => ({
  type: types.UPDATE_ROLE_LOADING
});

export const addRole = (roleName) => ({
  type: types.ADD_ROLE,
  roleName
});

export const removeRole = (roleName) => ({
  type: types.REMOVE_ROLE,
  roleName
});

export function fetchRoles() {
  return (dispatch) => {
    dispatch(fetchRolesBegin());
    return getObjectPromise(ROLES)
      .then((json) => {
        dispatch(fetchRolesSuccess(json.roles));
        return json;
      })
      .catch((error) => dispatch(fetchRolesFailure(error)));
  };
}

export function deleteRole(index) {
  return (dispatch) => {
    dispatch(deleteRoleBegin());
    dispatch(deleteRoleSuccess(index));
  };
}

export function getRoleInfo(roleId) {
  return (dispatch) => {
    dispatch(fetchRoleBegin());

    if (roleId === -1) {
      return true;
    }

    return getObjectPromise(ROLES, roleId)
      .then((json) => {
        dispatch(fetchRoleSuccess(roleId, json));
        return json;
      })
      .catch((error) => dispatch(fetchRolesFailure(error)));
  };
}

export const sortRoles = (state) => ({
  type: types.SORT_ROLES,
  state
});

export const updateSelectedRole = (index) => ({
  type: types.UPDATE_SELECTED_ROLE,
  index
});
