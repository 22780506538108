import {
  AppBar, Tab, Tabs, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 24, width: '100%', overflow: 'auto' }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
    width: '100%',
    height: '100%'
  },
  red: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.primary.main
  },
  border: {
    border: `1px solid ${theme.palette.primary.border}`,
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    height: '100%'
  },
  border2: {
    overflow: 'hidden'
  },
  selectedTab: {
    backgroundColor: `${theme.palette.secondary.main} !important`
  },
  normal: {
    color: theme.palette.primary.main
  }
});

class SonifiTabs extends Component {
  render() {
    const {
      classes, extraButton, tabs, tabValue, handleChange, showingTab
    } = this.props;

    return (
      <div className={classes.root}>
        <div style={{ display: 'flex', width: '100%' }}>
          <AppBar position="static">
            <Tabs value={tabValue} onChange={handleChange} className={classes.red} variant="scrollable">
              {
                tabs.map((option, index) => (
                  <Tab key={index} label={option.title} value={option.id}
                    className={`${tabValue === option.id ? classes.selectedTab : classes.normal}`} />
                ))
              }
              {extraButton}
            </Tabs>
          </AppBar>
        </div>
        <div className={classes.border}><TabContainer>{showingTab}</TabContainer></div>
      </div>
    );
  }
}

SonifiTabs.propTypes = {
  allowTabChange: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  extraButton: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  showingTab: PropTypes.object.isRequired,
  tabs: PropTypes.array,
  tabValue: PropTypes.string
};

export default withStyles(SonifiTabs, styles, { withTheme: true });
