import DateRangeIcon from '@mui/icons-material/DateRange';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { default as moment, default as Moment } from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  md: {
    marginRight: theme.spacing(2),
    width: '320px',
    height: theme.spacing(10),
    marginBottom: 0
  },
  percent: {
    marginRight: 0,
    width: '95%',
    height: theme.spacing(10),
    marginBottom: 0
  },
});

export class SonifiDateTimePicker extends Component {
  dateTimeChange = (value) => {
    const changeDate = new Date(value);
    if (value === null || changeDate === null) {
      return;
    }
    this.props.change(changeDate);
  };

  render() {
    const {
      classes, defaultValue, disabled, error, errorText, disablePast, label, maxDate, minDate, size
    } = this.props;
    const showError = ((typeof error === 'undefined') || (error === null)) ? false : error;

    let classSize = '';
    if (size === 'md') {
      classSize = classes.md;
    } else if (size === 'percent') {
      classSize = classes.percent;
    }

    return (<DateTimePicker
      autoOk={true}
      disabled={disabled}
      disableFuture={!disablePast}
      disablePast={disablePast}
      format="MM/DD/YYYY hh:mm a"
      inputVariant="filled"
      label={label}
      renderInput={(params) => <TextField
        {...params} variant="filled" margin="normal" size={size}
        error={showError} helperText={errorText} className={classSize}
      />}
      margin="normal"
      maxDate={maxDate ? moment(maxDate).toDate() : null}
      minDate={minDate ? moment(minDate).toDate() : null}
      onChange={this.dateTimeChange.bind(this)}
      components={{
        OpenPickerIcon: DateRangeIcon
      }}
      value={defaultValue}
      variant="inline"
    />);
  }
}

const mapStateToProps = (state) => ({
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
});

SonifiDateTimePicker.propTypes = {
  change: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(Moment)
  ]),
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  inputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
  maxDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(Moment)]),
  minDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.instanceOf(Moment)]),
  size: PropTypes.string,
  timezone: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(withStyles(SonifiDateTimePicker, styles, { withTheme: true }));
