import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiText } from '../../../../containers/';
import { getErrorText, isError } from '../../../../utils/index';
import { getCheckBox, getLgDropDown } from '../../../../utils/ui';
import {
  ECI10, fiasIpInterfaceLevel, FIAS_IP, FIAS_SERIAL, GEAC, HIS,
  interfaceLevel, interfaceType, LODGISTIX, MARRIOTT, nameFormats, OUTRIGGER
} from '../../constants/constants';

function isInterfaceFias(_interfaceType) {
  return [FIAS_IP, FIAS_SERIAL].indexOf(_interfaceType) > -1;
}

class Interface extends Component {
  changeStateNumber = (name) => ({ target: { value } }) => {
    this.props.updateTypeState(name, value.replace(/([^0-9])/g, ''), 'interface');
  };

  changeValue = (name) => ({ target: { value } }) => {
    this.props.updateTypeState(name, value, 'interface');
  };

  handleCheckChange = (name) => ({ target }) => {
    this.props.updateTypeState(name, target.checked, 'interface');
  };

  getDropDown(label, name, defaultValue, items) {
    const { notEditable, editState } = this.props;
    const { errors } = editState;

    return getLgDropDown(
      label, defaultValue, name, items, this.changeValue(name),
      getErrorText(name, errors), !notEditable
    );
  }

  getTextBox(label, defaultValue, name) {
    const { editState, notEditable } = this.props;
    return (
      <SonifiText
        size="lg"
        label={label}
        change={this.changeValue(name)}
        defaultValue={defaultValue}
        error={isError(name, editState.errors)}
        errorText={getErrorText(name, editState.errors)}
        disabled={notEditable}
      />
    );
  }

  getCheckBox(label, name, defaultVal) {
    const { notEditable } = this.props;
    return <Grid item xs={12}>
      {getCheckBox(label, defaultVal, this.handleCheckChange(name), !notEditable)}
    </Grid>;
  }

  // Not for FIAS_SERIAL,
  showEnhancedCheckin() {
    const myInterfaceType = this.props.editState.interface.interface_type;
    return [ECI10, OUTRIGGER, GEAC, HIS].indexOf(myInterfaceType) > -1;
  }

  // Not for FIAS_SERIAL,
  showGuestId() {
    const myInterfaceType = this.props.editState.interface.interface_type;
    return [ECI10, OUTRIGGER, GEAC, HIS].indexOf(myInterfaceType) > -1;
  }

  // Not for FIAS_SERIAL,
  supportsFullName() {
    const myInterfaceType = this.props.editState.interface.interface_type;
    return [ECI10, OUTRIGGER, GEAC, HIS].indexOf(myInterfaceType) > -1;
  }

  showNameFormat() {
    const myInterfaceType = this.props.editState.interface.interface_type;
    return [ECI10, OUTRIGGER, GEAC, HIS, LODGISTIX, MARRIOTT].indexOf(myInterfaceType) > -1;
  }

  render() {
    const { editState, translations } = this.props;

    const integrationConstants = (editState.interface.interface_type === FIAS_IP
      ? fiasIpInterfaceLevel
      : interfaceLevel);

    const isFias = isInterfaceFias(editState.interface.interface_type);
    const isTaxSeparate = !isFias && editState.interface.post_tax_separate;

    return (
      <Fragment>
        <Grid item xs={12}>
          {this.getDropDown(translations.interfaceType, 'interface_type',
            editState.interface.interface_type, interfaceType)}
          {this.getDropDown(translations.interfaceLevel, 'interface_level',
            editState.interface.interface_level, integrationConstants)}
          {this.getCheckBox(translations.postCredits, 'post_credits_flag', editState.interface.post_credits_flag)}
          {this.getCheckBox(translations.postNoCharge, 'post_no_charge_flag',
            editState.interface.post_no_charge_flag)}
          {this.showEnhancedCheckin() &&
            this.getCheckBox(translations.enhancedCheckin, 'enhanced_flag', editState.interface.enhanced_flag)}
          {this.showGuestId() && this.getCheckBox(translations.guestPreferenceID, 'guest_preference_id_flag',
            editState.interface.guest_preference_id_flag)}
          {this.supportsFullName() &&
            this.getCheckBox(translations.fullName, 'first_last_flag', editState.interface.first_last_flag)}
          {this.showNameFormat() && this.getDropDown(translations.nameFormat, 'name_format',
            (editState.interface.name_format ? editState.interface.name_format : 'none'),
            nameFormats)}
          {!isFias && this.getCheckBox(translations.displayTax, 'post_tax_separate',
            editState.interface.post_tax_separate || false)}
          {isTaxSeparate && this.getTextBox(translations.taxDescription, editState.interface.tax_description,
            'tax_description')}
          {isTaxSeparate && this.getTextBox(translations.taxRevenueCode, editState.interface.revenue_code_tax,
            'revenue_code_tax')}
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  translations: state.integrations.translations.pmsd.interface
});

Interface.propTypes = {
  editState: PropTypes.object,
  globalTranslations: PropTypes.object,
  notEditable: PropTypes.bool,
  translations: PropTypes.object,
  updateTypeState: PropTypes.func
};

export default connect(mapStateToProps)(Interface);
