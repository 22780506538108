// TODO: this component is essentially a copy of NameValueData.js.  The only difference
// is the data that comes in has an "entity" property, rather than a name.  We currently do
// not know the mapping of how to determine the real name that will become the label of the
// textbox, so for now it is its own component.  We may be able to combine this with
// NameValueData once we know the specifics...

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiSection from '../../../containers/SonifiSection';
import SonifiText from '../../../containers/SonifiText';
import { findObjectByKey } from '../../../utils';

class PropertyIdData extends Component {
  state = { data: null };

  componentDidMount() {
    this.setState({ data: this.props.data });
  }

  getShortName(product) {
    const { productParents } = this.props;
    const shortName = findObjectByKey(productParents, 'id', product);
    return shortName ? shortName.name : product;
  }

  getBodyInfo() {
    const { data } = this.state;
    return (
      <Fragment>
        {data.map(({ entity, value, enumeration }, i) => (
          <SonifiText
            key={i}
            label={entity}
            defaultValue={entity === 'sonifi.salesforce.productparent.Interactive' ? this.getShortName(value) : value}

            // currently has no dropdown values, but here in case things change
            select={!!(enumeration) && enumeration.length}
            items={(enumeration)
              ? enumeration.map((choice) => ({
                id: choice,
                value: choice,
                label: choice
              }))
              : null}
            disabled={true}
            size="md"
          />
        ))}
      </Fragment>
    );
  }

  render() {
    const { data } = this.state;
    const { sectionLabel } = this.props;

    if (!data) {
      return <Fragment></Fragment>;
    }

    return (
      <SonifiSection title={sectionLabel} body={this.getBodyInfo()} />
    );
  }
}

const mapStateToProps = (state) => ({
  productParents: state.deployments.productParents
});

PropertyIdData.propTypes = {
  data: PropTypes.array.isRequired,
  productParents: PropTypes.array,
  sectionLabel: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(PropertyIdData);
