import { updateSnackBar } from '../../../../actions/globalActions';
import { TERM_MODELS_REPORT } from '../../../../constants/apiEndpoints';
import { ERROR } from '../../../../constants/constants';
import { getSitelessCsvObjectPromise, getSitelessObjectPromise } from '../../../../utils/api';
import * as types from '../actions/actionTypes';

export const getTerminalLoggingDataBegin = (reset) => ({
  type: types.GET_TERMINAL_LOGGING_BEGIN,
  reset
});

export const getTerminalLoggingDataSuccess = (logs) => ({
  type: types.GET_TERMINAL_LOGGING_SUCCESS,
  logs
});

export const getTerminalLoggingDataFailure = () => ({
  type: types.GET_TERMINAL_LOGGING_FAILURE
});

export function getTerminalLoggingData(filter, reset) {
  return (dispatch) => {
    dispatch(getTerminalLoggingDataBegin(reset));
    return getSitelessObjectPromise(TERM_MODELS_REPORT, null, filter)
      .then((data) => {
        dispatch(getTerminalLoggingDataSuccess(data));
        return data;
      }).catch((error) => {
        dispatch(getTerminalLoggingDataFailure());
        dispatch(updateSnackBar(ERROR, `Error retrieving Terminal Model report. - ${error.status}`));
        return error;
      });
  };
}

export const fetchTerminalReportFailure = () => ({
  type: types.FETCH_TERMINAL_REPORT_EXPORT_FAILURE
});

export function getTerminalModelReportExport() {
  return (dispatch) => getSitelessCsvObjectPromise(TERM_MODELS_REPORT)
    .then((json) => json)
    .catch((error) => {
      dispatch(fetchTerminalReportFailure(error));
    });
}

export const resetTerminalLogging = () => ({
  type: types.RESET_TERMINAL_LOGGING
});
