import { ROLES } from '../../../constants/apiEndpoints';
import { getObjectPromise } from '../../../utils/api';
import * as types from './rolesDevActionTypes';

export const getRolesBegin = () => ({
  type: types.GET_ROLES_BEGIN
});

export const getRolesSuccess = (roles) => ({
  type: types.GET_ROLES_SUCCESS,
  roles
});

export const getRolesFailure = (error) => ({
  type: types.GET_ROLES_FAILURE,
  error
});

export function getRoles(superRole) {
  return (dispatch) => {
    dispatch(getRolesBegin());

    getObjectPromise(ROLES, superRole)
      .then((data) => {
        dispatch(getRolesSuccess(data));
      }).catch((error) => {
        dispatch(getRolesFailure(error));
      });
  };
}

export const updatePermissions = (permissions) => ({
  type: types.DEV_UPDATE_PERMISSION_LIST,
  payload: { permissions }
});

export const updateSelectedPermission = (permission) => ({
  type: types.DEV_UPDATE_SELECTED_PERMISSION,
  payload: { permission }
});

export const updateRoles = (roles) => ({
  type: types.DEV_UPDATE_ROLES,
  payload: { roles }
});

export const updateSelectedRoleId = (roleId) => ({
  type: types.DEV_UPDATE_SELECTED_ROLE_ID,
  payload: { roleId }
});

export const updateSelectedRole = (role) => ({
  type: types.DEV_UPDATE_SELECTED_ROLE,
  payload: { role }
});

export const updateRolePermission = (permissions) => ({
  type: types.DEV_UPDATE_ROLE_PERMISSION,
  payload: { permissions }
});
