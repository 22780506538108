import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import ModalSectionHeader from '../../../containers/ModalSectionHeader';
import SonifiTableHead from '../../../containers/SonifiTableHead';

class Taxes extends Component {
  state = {
    data: null
  };

  componentDidMount() {
    this.setState({
      data: this.props.data
    });
  }

  /* eslint-disable camelcase */
  render() {
    const { data } = this.state;
    const { translations } = this.props;

    if (!data) {
      return <Fragment></Fragment>;
    }

    const tableHeader = [
      { id: 'name', sortable: false, numeric: false, label: translations.desc },
      { id: 'location', sortable: false, numeric: false, label: translations.rate },
      { id: 'format', sortable: false, numeric: false, label: translations.taxCode },
      { id: 'requester', sortable: false, numeric: false, label: translations.payment },
      { id: 'organization', sortable: false, numeric: false, label: translations.effective },
      { id: 'start_timestamp', sortable: false, numeric: false, label: translations.start }
    ];

    return (
      <Fragment>
        <ModalSectionHeader label={translations.header} />
        <div style={{ paddingBottom: '16px' }}>
          <Table id="Taxes" size="small">
            <SonifiTableHead headColumns={tableHeader} />
            <TableBody>
              {data.map(({
                description, rate, tax_code, payment_method, effective_date, geo_code
              }, i) => (
                <TableRow key={i}>
                  <TableCell component="th" scope="row">{description}</TableCell>
                  <TableCell component="th" scope="row">{rate}</TableCell>
                  <TableCell component="th" scope="row">{tax_code}</TableCell>
                  <TableCell component="th" scope="row">{payment_method}</TableCell>
                  <TableCell component="th" scope="row">{effective_date}</TableCell>
                  <TableCell component="th" scope="row">{geo_code}</TableCell>
                </TableRow>))}
            </TableBody>
          </Table>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.systemManagement.translations.taxes,
});

Taxes.propTypes = {
  data: PropTypes.array.isRequired,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(Taxes);
