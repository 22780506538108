import { Add } from '@mui/icons-material';
import { Table } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getSystemSchedule } from '../../actions/globalActions';
import SonifiSnackBar from '../../containers/SonifiSnackBar';
import SonifiTableHead from '../../containers/SonifiTableHead';
import SonifiTemplate from '../../containers/SonifiTemplate';
import { buildSortString, getSiteNumFromURI } from '../../utils';
import { FORM_MODE, FORM_TYPE } from './../../constants/messages';
import { MESSAGE_EDIT } from './../../constants/roles';
import { checkForSingleUserPermission } from './../../utils/rolesUtil';
import { clearLibrary, fetchLibrary, showMessageDialog } from './actions/messagingActions';
import FormDialog from './components/FormDialog';
import MessageTableBody from './components/MessageTableBody';

class Library extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.props.dispatch(clearLibrary());
    this.state = {
      order: 'asc',
      orderBy: 'id',
      page: 1,
      snackBarOpen: true
    };
    this.props.dispatch(getSystemSchedule());
    this.props.dispatch(fetchLibrary(buildSortString(this.state)));
  }

  // dispatch function for adding new libary item
  handleAddDialog() {
    this.props.dispatch(showMessageDialog(FORM_TYPE.LIBRARY, FORM_MODE.ADD));
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('COMPONENTDIDUPDATE library.js');
    // console.log('prevProps', prevProps);
    // console.log('Props', this.props);
    // console.log('prevState', prevState);
    // console.log('State', this.state);

    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(clearLibrary());
      this.props.dispatch(fetchLibrary(buildSortString(this.state)));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(fetchLibrary(buildSortString(this.state)));
    }
  }

  // Infinite scrolling logic, used with Waypoint component
  handleWayPointReached() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  // click on table column headers
  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    });
  };

  closeSnackBar() {
    this.setState({ snackBarOpen: false });
  }

  getPageDetails() {
    const { library, translations } = this.props;
    const { order, orderBy } = this.state;
    const tableHeader = [
      { id: 'id', sortable: library.length > 1, numeric: false, label: translations.name },
      { id: 'source_type', sortable: library.length > 1, numeric: false, label: translations.sourceType },
      { id: 'user_name', sortable: library.length > 1, numeric: false, label: translations.creator }
    ];
    return (
      <Table>
        <SonifiTableHead
          headColumns={tableHeader}
          order={order}
          orderBy={orderBy}
          onRequestSort={this.handleRequestSort}
        />
        <MessageTableBody
          onWayPointReached={this.handleWayPointReached.bind(this)}
        />
      </Table>
    );
  }

  render() {
    const {
      errorDetail, errorOccurred, globalTranslations, translations, userPermissions
    } = this.props;
    const { snackBarOpen } = this.state;

    if (getSiteNumFromURI() === null) {
      return <Navigate replace to="/" />;
    }

    return (
      <Fragment>
        <FormDialog />
        <SonifiTemplate
          header={translations.title}
          onSubmit={this.handleAddDialog.bind(this)}
          showButton={checkForSingleUserPermission(MESSAGE_EDIT, userPermissions)}
          label={globalTranslations.add}
          icon={<Add />}
          disabled={errorOccurred}
          pageDetails={this.getPageDetails()}
        />
        <SonifiSnackBar message={errorDetail} variant="error" open={errorOccurred && snackBarOpen}
          onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  errorDetail: state.messaging.libraryError ? state.messaging.libraryError.detail : '',
  errorOccurred: !!state.messaging.libraryError,
  globalTranslations: state.global.translations.defaults,
  library: state.messaging.library,
  maxPages: state.messaging.library_page_count,
  translations: state.messaging.translations.messaging,
  userPermissions: state.global.permissions
});

Library.propTypes = {
  dispatch: PropTypes.func,
  errorDetail: PropTypes.string,
  errorOccurred: PropTypes.bool,
  globalTranslations: PropTypes.object,
  library: PropTypes.array,
  maxPages: PropTypes.number,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(Library);
