import { FilterList } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IPG_SOURCES } from '../../../../constants/apiEndpoints';
import SonifiAutoCompleteText from '../../../../containers/SonifiAutoCompleteText';
import SonifiText from '../../../../containers/SonifiText';
import SonifiTextAsync from '../../../../containers/SonifiTextAsync';
import { getSiteNumFromURI } from '../../../../utils';
import { availableTimezones } from '../../constants/constants';

class ChannelSourceForm extends Component {
  state = {
    asyncSelectRef: null,
    change: false,
    menuAnchor: null,
    showMenu: false,
    timeZoneSearch: ' '
  };

  editSearchCriteria = (name) => (event, value) => {
    this.setState({ [name]: (!value || value === null) ? '' : value.value });
  };

  handleChange = (option) => {
    if (option && option.value) {
      this.props.editFunction({
        source_id: option.value.id,
        call_letters: option.value.call_letters,
        affiliate: option.value.affiliate,
        entry: null,
        description: null,
        duration: null
      });
    }
  };

  createFilterObj = (inputData) => {
    const { siteManagement } = this.props;
    const affCallL = inputData.split('|');
    if (affCallL.length > 1) {
      return {
        affiliate: (affCallL[0] === 'none' ? '' : affCallL[0]),
        call_letters: (affCallL[1] === 'none' ? '' : affCallL[1]),
        name: (affCallL[2] ? affCallL[2] : ''),
        platform: 'Ocean',
        provider: siteManagement.ipg,
        timezone: (affCallL[3] ? affCallL[3] : this.state.timeZoneSearch),
        limit: 100
      };
    }

    return {
      search: inputData,
      platform: 'Ocean',
      provider: siteManagement.ipg,
      timezone: this.state.timeZoneSearch,
      limit: 100
    };
  };

  createDisplayObj = (source) => ({
    label:
      `${source.affiliate ? source.affiliate : 'none'}|
      ${source.call_letters ? source.call_letters : 'none'}|
      ${source.name}|${source.timezone}`,
    value: source
  });

  openFilterMenu = (event) => {
    this.setState({ showMenu: true, menuAnchor: event.currentTarget });
  };

  closeFilterMenu = () => {
    this.setState({ showMenu: false });
  };

  enterFunc = () => {
    this.setState({ showMenu: false });
  };

  popOverEnter = (event) => {
    if (event.key === 'Enter') {
      this.closeFilterMenu();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    // To get the screen to focus on the select on menu close
    // I have to detect the menu close then change the change variable
    // So it will loop again in order for the focus to work.
    // Seems weird, but something about the dialog was not allowing
    // the UI to focus on the select immediatly after close
    if (this.state.showMenu === false && prevState.showMenu === true) {
      this.setState({ change: !this.state.change });
    }

    if (this.state.change !== prevState.change) {
      this.setFocusOnSelect();
    }
  }

  setFocusOnSelect = () => {
    setTimeout(this.state.asyncSelectRef.focus(), 2000);
  };

  test = () => {
    setTimeout(this.state.asyncSelectRef.focus(), 0);
  };

  getSelectRef = (node) => {
    this.setState({ asyncSelectRef: node });
  };

  getMoreInfo() {
    const { ipgInfo } = this.props;
    const aff = (ipgInfo.affiliate !== null && ipgInfo.affiliate.length > 0) ? ipgInfo.affiliate : 'none';
    const call = (ipgInfo.call_letters !== null && ipgInfo.call_letters.length > 0 ? ipgInfo.call_letters : 'none');
    return `${aff} | ${call}`;
  }

  render() {
    const {
      displayName, errors, ipgInfo, readOnly, translations
    } = this.props;
    const moreChannelInfo = this.getMoreInfo();

    return (
      <Grid container>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Grid item>
            <SonifiAutoCompleteText
              helperText={translations.timezoneFilter}
              label={translations.timezone}
              onChange={this.editSearchCriteria('timeZoneSearch')}
              options={availableTimezones}
              placeholder={translations.optional}
              popupIcon={<FilterList />}
              size="sm" />
          </Grid>
          <Grid item xs={8}>
            <SonifiTextAsync
              dataName="sources"
              disabled={readOnly}
              isIpg
              displayFunc={this.createDisplayObj}
              filterFunc={this.createFilterObj}
              getRefFunc={this.getSelectRef}
              label={translations.sourceId}
              onSelect={this.handleChange}
              placeholder={(ipgInfo.source_id !== '' ? moreChannelInfo : translations.affCall)}
              resource={IPG_SOURCES}
              siteId={getSiteNumFromURI()}
              siteObj={true}
              type={IPG_SOURCES} />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item>
            <SonifiText
              defaultValue={displayName || ''}
              disabled={true}
              error={errors && !!errors.displayName}
              errorText={((errors && errors.displayName) ? errors.displayName : '')}
              label={translations.displayName}
              size="sm" />
          </Grid>
          <Grid item>
            <SonifiText
              defaultValue={ipgInfo.source_id}
              disabled={true}
              error={errors && !!errors.sourceId}
              errorText={((errors && errors.sourceId) ? errors.sourceId : '')}
              label={translations.sourceId}
              size="sm" />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  siteManagement: state.siteManagement,
  translations: state.channels.translations.editDialog
});

ChannelSourceForm.propTypes = {
  dispatch: PropTypes.func,
  displayName: PropTypes.string,
  editFunction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  ipgInfo: PropTypes.object,
  readOnly: PropTypes.bool,
  showPropDetail: PropTypes.bool,
  siteManagement: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(ChannelSourceForm);
