import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TERMINAL_MODELS_REPORT_READ, USERLOG_READ } from '../../constants/roles';
import Navigation from '../../containers/Navigation';
import { createLink } from '../../utils/links';
import { checkForSingleUserPermission } from '../../utils/rolesUtil';
import AuditReport from '../AuditReport';
import TerminalReport from '../Reports/TerminalReport';

class GlobalReports extends Component {
  reports() {
    const { translations, userPermissions } = this.props;
    const title = translations.title;
    const items = [];

    if (checkForSingleUserPermission(USERLOG_READ, userPermissions)) {
      items.push(createLink(translations.audit, 'auditReport', AuditReport));
    }

    if (checkForSingleUserPermission(TERMINAL_MODELS_REPORT_READ, userPermissions)) {
      items.push(createLink(translations.termReport, 'terminalReport', TerminalReport));
    }

    return { title, items };
  }

  render() {
    return (
      <Navigation links={this.reports().items} stackedNav={[this.reports()]} />
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  translations: state.global.translations.reports,
  userPermissions: state.global.permissions,
});

GlobalReports.propTypes = {
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(GlobalReports);
