import { Grid } from '@mui/material';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel, SonifiSpinner } from '../../../containers';
import SonifiText from '../../../containers/SonifiText';
import { fetchFirmware, startFirmware } from '../../Firmware/actions/firmwareActions';
import { updateTerminalField } from '../actions/terminalActions';

class FirmwareSelect extends Component {
  state = {
    tempFirmware: []
  };

  componentDidMount() {
    const { dispatch, terminal } = this.props;
    if (terminal && terminal.version && terminal.version.hardware) {
      dispatch(startFirmware());
      setTimeout(() => {
        dispatch(fetchFirmware({ limit: 100, sort: 'id:asc', model: terminal.version.hardware }))
          .then((data) => {
            const tempFirmware = [...data.firmware];
            const selected = find(tempFirmware, (o) => o.id === terminal.version.firmware);
            if (selected === undefined) {
              tempFirmware.unshift({ id: terminal.version.firmware });
            }
            this.setState({ tempFirmware });
          });
      }, 200);
    }
  }

  handleChange = () => ({ target: { value } }) => {
    const tempVersion = { ...this.props.terminal.version };
    tempVersion.firmware = value;
    this.props.dispatch(updateTerminalField('version', tempVersion));
    setTimeout(() => {
      this.props.dispatch(updateTerminalField('firmware', value));
    }, 200);
  };

  render() {
    const {
      loading, translations, terminal
    } = this.props;

    if (loading || this.state.tempFirmware.length === 0) {
      return <SonifiSpinner />;
    } else if (!terminal || !terminal.version || !terminal.version.hardware) {
      return <SonifiLabel error label={translations.noTerminal} />;
    }

    return (
      <Grid container style={{ justifyContent: 'space-evenly' }}>
        <Grid item xs={12}>
          <SonifiLabel boldLabel={`${translations.room}:`} label={`${terminal.room_id}`} />
        </Grid>
        <Grid item xs={12}>
          <SonifiLabel boldLabel={`${translations.termID}:`} label={`${terminal.id}`} />
        </Grid>
        <Grid item xs={12}>
          <SonifiLabel boldLabel={`${translations.location}:`} label={`${terminal.location}`} />
        </Grid>
        <Grid item xs={12}>
          <SonifiLabel boldLabel={`${translations.make}:`} label={`${terminal.model_make}`} />
        </Grid>
        <Grid item xs={12}>
          <SonifiText
            defaultValue={`${terminal.version.firmware}`}
            change={this.handleChange()}
            select={true}
            label={translations.availableFirmware}
            size="lg"
            items={this.state.tempFirmware.map((suggestion) => ({
              id: suggestion.id,
              value: suggestion.id,
              label: suggestion.id,
            }))}
          />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  firmware: state.firmware.firmware,
  loading: state.firmware.loading,
  terminal: state.termGrid.selectedTerm,
  translations: state.termGrid.translations.terminalInfo
});

FirmwareSelect.propTypes = {
  dispatch: PropTypes.func,
  firmware: PropTypes.array,
  loading: PropTypes.bool,
  terminal: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(FirmwareSelect);
