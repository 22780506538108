import { ADD } from '../../../constants/constants';

export const ADD_OPTION = {
  id: ADD,
  quantity: {
    default: 4,
    today: 4,
    purchased: 0
  },
  price: 1000
};
