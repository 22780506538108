export const ADD_PUT_ROOM_BEGIN = 'ADD_PUT_ROOM_BEGIN';
export const ADD_PUT_ROOM_CLOSE = 'ADD_PUT_ROOM_CLOSE';
export const ADD_PUT_ROOM_FAILURE = 'ADD_PUT_ROOM_FAILURE';
export const ADD_PUT_ROOM_SUCCESS = 'ADD_PUT_ROOM_SUCCESS';
export const ADD_RANGE = 'ADD_RANGE';
export const ADD_TERMINAL = 'ADD_TERMINAL';
export const CLOSE_ROOM_INFO = 'CLOSE_ROOM_INFO';
export const DELETE_CHECK = 'DELETE_CHECK';
export const DELETE_RANGE_BEGIN = 'DELETE_RANGE_BEGIN';
export const DELETE_RANGE_FAILURE = 'DELETE_RANGE_FAILURE';
export const DELETE_RANGE_SUCCESS = 'DELETE_RANGE_SUCCESS';
export const DELETE_ROOM_BEGIN = 'DELETE_ROOM_BEGIN';
export const DELETE_ROOM_FAILURE = 'DELETE_ROOM_FAILURE';
export const DELETE_ROOM_SUCCESS = 'DELETE_ROOM_SUCCESS';
export const DIALOG_TYPE = 'DIALOG_TYPE';
export const DONE_ADDING_ROOMS = 'DONE_ADDING_ROOMS';
export const FETCH_FREE_TERMINAL_INFO_SUCCESS = 'FETCH_FREE_TERMINAL_INFO_SUCCESS';
export const FETCH_FREE_TERMINALS_BEGIN = 'FETCH_FREE_TERMINALS_BEGIN';
export const FETCH_FREE_TERMINALS_FAILURE = 'FETCH_FREE_TERMINALS_FAILURE';
export const FETCH_FREE_TERMINALS_SUCCESS = 'FETCH_FREE_TERMINALS_SUCCESS';
export const FETCH_OCCUPIED_ROOM_COUNT_BEGIN = 'FETCH_OCCUPIED_ROOM_COUNT_BEGIN';
export const FETCH_OCCUPIED_ROOM_COUNT_FAILURE = 'FETCH_OCCUPIED_ROOM_COUNT_FAILURE';
export const FETCH_OCCUPIED_ROOM_COUNT_SUCCESS = 'FETCH_OCCUPIED_ROOM_COUNT_SUCCESS';
export const FETCH_ROOM_OPTIONS_BEGIN = 'FETCH_ROOM_OPTIONS_BEGIN';
export const FETCH_ROOM_OPTIONS_FAILURE = 'FETCH_ROOM_OPTIONS_FAILURE';
export const FETCH_ROOM_OPTIONS_SUCCESS = 'FETCH_ROOM_OPTIONS_SUCCESS';
export const FETCH_ROOMS_BEGIN = 'FETCH_ROOMS_BEGIN';
export const FETCH_ROOMS_FAILURE = 'FETCH_ROOMS_FAILURE';
export const FETCH_ROOMS_SUCCESS = 'FETCH_ROOMS_SUCCESS';
export const FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_BEGIN = 'FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_BEGIN';
export const FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_FAILURE = 'FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_FAILURE';
export const FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_SUCCESS = 'FETCH_ROOMS_WITHOUT_TERMINALS_COUNT_SUCCESS';
export const FETCH_TERMINAL_INFO_BEGIN = 'FETCH_TERMINAL_INFO_BEGIN';
export const FETCH_TERMINAL_INFO_FAILURE = 'FETCH_TERMINAL_INFO_FAILURE';
export const FETCH_TERMINAL_INFO_SUCCESS = 'FETCH_TERMINAL_INFO_SUCCESS';
export const FETCH_TERMINAL_OPTIONS_BEGIN = 'FETCH_TERMINAL_OPTIONS_BEGIN';
export const FETCH_TERMINAL_OPTIONS_FAILURE = 'FETCH_TERMINAL_OPTIONS_FAILURE';
export const FETCH_TERMINAL_OPTIONS_SUCCESS = 'FETCH_TERMINAL_OPTIONS_SUCCESS';
export const FILTER_ROOMS_BEGIN = 'FILTER_ROOMS_BEGIN';
export const FILTER_ROOMS_FAILURE = 'FILTER_ROOMS_FAILURE';
export const FILTER_ROOMS_SUCCESS = 'FILTER_ROOMS_SUCCESS';
export const GET_ROOM_INFO_BEGIN = 'GET_ROOM_INFO_BEGIN';
export const GET_ROOM_INFO_FAILURE = 'GET_ROOM_INFO_FAILURE';
export const GET_ROOM_INFO_SUCCESS = 'GET_ROOM_INFO_SUCCESS';
export const GET_ROOMS_TO_DELETE_BEGIN = 'GET_ROOMS_TO_DELETE_BEGIN';
export const GET_ROOMS_TO_DELETE_FAILURE = 'GET_ROOMS_TO_DELETE_FAILURE';
export const GET_ROOMS_TO_DELETE_SUCCESS = 'GET_ROOMS_TO_DELETE_SUCCESS';
export const GET_TERMINALS_BEGIN = 'GET_TERMINALS_BEGIN';
export const GET_TERMINALS_FAILURE = 'GET_TERMINALS_FAILURE';
export const GET_TERMINALS_SUCCESS = 'GET_TERMINALS_SUCCESS';
export const INVALID_FORM = 'INVALID_FORM';
export const PATCH_ROOM_BEGIN = 'PATCH_ROOM_BEGIN';
export const PATCH_ROOM_FAILURE = 'PATCH_ROOM_FAILURE';
export const PATCH_ROOM_SUCCESS = 'PATCH_ROOM_SUCCESS';
export const PUT_ROOM_BEGIN = 'PUT_ROOM_BEGIN';
export const PUT_ROOM_FAILURE = 'PUT_ROOM_FAILURE';
export const PUT_ROOM_SUCCESS = 'PUT_ROOM_SUCCESS';
export const REMOVE_NEW_TERMINAL = 'REMOVE_NEW_TERMINAL';
export const RESET_ADD_RANGE = 'RESET_ADD_RANGE';
export const RESET_ADD_ROOM = 'RESET_ADD_ROOM';
export const RESET_PIN_BEGIN = 'RESET_PIN_BEGIN';
export const RESET_PIN_COMPLETE = 'RESET_PIN_COMPLETE';
export const RESET_SNACKBAR = 'RESET_ROOMS_SNACKBAR';
export const SET_DIALOG_ERROR = 'SET_DIALOG_ERROR';
export const SORT_TERMINALS = 'SORT_TERMINALS';
export const START_ADD_RANGE = 'START_ADD_RANGE';
export const START_DELETE_ROOM = 'START_DELETE_ROOM';
export const UPDATE_CHROMECAST_PORT_GENERAL = 'UPDATE_CHROMECAST_PORT_GENERAL';
export const UPDATE_ROOM_INFO = 'UPDATE_ROOM_INFO';
export const UPDATE_SELECTED_ROOM = 'UPDATE_SELECTED_ROOM';
export const UPDATE_SELECTED_TERMINAL = 'UPDATE_SELECTED_TERMINAL';
export const UPDATE_TERMINAL = 'UPDATE_TERMINAL';
