import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import SonifiLabel from '../../../../containers/SonifiLabel';
import { dateToString } from '../../../../utils';
import { viewLogInfo } from '../actions/purchaseAction';

const styles = (theme) => ({
  goodStatus: {
    color: `${theme.palette.defaults.green} !important`
  },
  badStatus: {
    color: `${theme.palette.defaults.red} !important`
  }
});

class LogRow extends Component {
  handleEditDialog = () => () => {
    const { dispatch, logs, rowIndex } = this.props;
    dispatch(viewLogInfo(logs[rowIndex]));
  };

  /* eslint-disable camelcase */
  render() {
    const {
      classes, logs, rowIndex, timezone
    } = this.props;

    const { purchase_time, room_number, status, transaction_time } = logs[rowIndex];
    const statusString = status.charAt(0).toUpperCase() + status.slice(1);

    return (
      <TableRow onClick={this.handleEditDialog()}>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          {status === 'accepted' || status === 'purchased'
            ? <Tooltip title={statusString}><CheckCircleOutline className={classes.goodStatus} /></Tooltip>
            : <Tooltip title={statusString}><ErrorOutline className={classes.badStatus} /></Tooltip> || ''}
        </TableCell>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={dateToString(transaction_time, timezone)} />
        </TableCell>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={room_number ? room_number : ''} />
        </TableCell>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={moment(purchase_time, 'HH:mm').format('h:mm a')} />
        </TableCell>
      </TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  logs: state.purchaseLogs.logs,
  translations: state.purchaseLogs.translations.grid,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago')
});

LogRow.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  logs: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(withStyles(LogRow, styles, { withTheme: true }));
