import { Add } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { SonifiCheckbox, SonifiTableHead } from '../../../../containers';
import SonifiTemplate from '../../../../containers/SonifiTemplate';
import { getLateCheckoutFromSession } from '../../../../utils';
import { updateIntegrationVendors } from '../../actions/integrationActions';
import { LATE_CHECKOUT } from '../../constants/constants';
import TieMap from './TieMap';

class Vendors extends TieMap {
  state = {
    vendors: []
  };

  componentDidMount() {
    const { editState } = this.props;
    const tempVendors = editState.vendors ? [...editState.vendors] : [];
    this.setState({ vendors: tempVendors });
  }

  addVendor = () => {
    const { vendors } = this.state;
    const tempVendors = [...vendors];
    tempVendors.push(LATE_CHECKOUT);
    this.setState({ vendors: tempVendors });
    this.props.dispatch(updateIntegrationVendors(tempVendors));
  };

  getToolTipMessage(addEnabled) {
    const { translations } = this.props;
    let addToolTipMsg = '';
    if (!addEnabled) {
      addToolTipMsg = translations.noVendors;
    }
    return addToolTipMsg;
  }

  getAddEnabled() {
    const { vendors } = this.state;
    const lateCheckout = getLateCheckoutFromSession();
    if (lateCheckout === 'true') {
      for (let i = 0; i < vendors.length; i++) {
        if (vendors[i].purchase_type === 'late_checkout') {
          return false;
        }
      }
      return true;
    }
    return false;
  }

  getPageDetails() {
    const { translations } = this.props;
    const { vendors } = this.state;

    const tableHeaders = [
      { id: 'client_id', sortable: false, numeric: false, label: translations.client },
      { id: 'default', sortable: false, numeric: false, label: translations.default },
      { id: 'purchase_type', sortable: false, numeric: false, label: translations.purchaseType },
      { id: 'revenue_code', sortable: false, numeric: false, label: translations.revCode },
      { id: 'description', sortable: false, numeric: false, label: translations.description }
    ];

    return <Table>
      <SonifiTableHead headColumns={tableHeaders} />
      <TableBody>
        {vendors.map((item, index) => (
          <TableRow key={index}>
            <TableCell>
              {item.client_id}
            </TableCell>
            <TableCell>
              <SonifiCheckbox
                value={item.default}
                disabled={true}
              />
            </TableCell>
            <TableCell>
              {item.purchase_type}
            </TableCell>
            <TableCell>
              {item.revenue_code}
            </TableCell>
            <TableCell>
              {item.description}
            </TableCell>
          </TableRow >
        ))}
      </TableBody>
    </Table>;
  }

  render() {
    const { notEditable, globalTranslations, translations } = this.props;
    const addEnabled = this.getAddEnabled();
    const toolTipMsg = this.getToolTipMessage(addEnabled);
    return (
      <SonifiTemplate
        disabled={!addEnabled}
        header={translations.vendors}
        icon={<Add />}
        label={globalTranslations.add}
        onSubmit={this.addVendor.bind(this)}
        pageDetails={this.getPageDetails()}
        showButton={!notEditable}
        toolTip={toolTipMsg}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  selectedIntegration: state.integrations.selectedIntegration,
  translations: state.integrations.translations.pmsd.vendors
});

Vendors.propTypes = {
  dispatch: PropTypes.func,
  editState: PropTypes.object,
  globalTranslations: PropTypes.object,
  notEditable: PropTypes.bool,
  selectedIntegration: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(Vendors);
