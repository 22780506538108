import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import decode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';
import {
  closeSnackBar, getSite, setTabSelected, updateGlobalError, updateGroup, updateRole, updateSites
} from '../../actions/globalActions';
import CacheBuster from '../../CacheBuster';
import { ROLES, USER_GROUPS } from '../../constants/apiEndpoints';
import * as roles from '../../constants/roleGroups';
import { SONIFI_ADMIN, TERM_COMM_READ, USERLOG_READ } from '../../constants/roles';
import { SonifiSnackBar, SonifiSpinner } from '../../containers';
import Background from '../../img/RouteNotFoundNoText.png';
import {
  getItemFromSession, getLateCheckoutFromSession, getSiteNumFromURI, getTokenFromSession, getValueFromSession,
  hasGroupServiceFeat, setSite
} from '../../utils';
import { getObjectPromise, isProduction, isTesting } from '../../utils/api';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';
import AuditReport from '../AuditReport';
import BatchChannels from '../BatchChannels';
import ChannelLogos from '../ChannelLogos';
import Channels from '../Channels2';
import Sandbox from '../ComponentSandbox/Sandbox';
import Deployments from '../Deployments';
import Firmware from '../Firmware';
import Configure from '../Global/Configure';
import GlobalDashboard from '../Global/GlobalDashboard';
import GlobalDownloads from '../Global/GlobalDownloads';
import GlobalReports from '../Global/GlobalReports';
import Manage from '../Global/Manage';
import GlobalTermFiles from '../GlobalTermFiles';
import GroupManagement from '../GroupManagement';
import GroupServices from '../GroupServices';
import HotelDirectory from '../HotelDirectory';
import Icp from '../Icp';
import Integrations from '../Integrations';
import LateCheckout from '../LateCheckout';
import PrimarySearchAppBar from '../Layouts/PrimarySearchAppBar';
import Library from '../Messaging/library';
import { MessagingDashboard } from '../Messaging/manager';
import Mobile from '../Mobile/mobile';
import ProvisionTerms from '../ProvisionTerminals/components/ProvisionTerms';
import ReleaseTemplates from '../ReleaseTemplates';
import PmsLogging from '../Reports/Pms';
import PurchaseLog from '../Reports/Purchase';
import TermComm from '../Reports/TermComm';
import TerminalReport from '../Reports/TerminalReport';
import RoleManagement from '../RoleManagement';
import RolesDev from '../RolesDev/RolesDev';
import Rooms from '../Rooms';
import Product from '../Site/Product';
import Reports from '../Site/Reports';
import SiteDashboard from '../Site/SiteDashboard';
import System from '../Site/System';
import Site from '../SiteLocationSelection/Site';
import SiteManagement from '../SiteManagement';
import SoftwareChanges from '../SoftwareChanges';
import SoftwareManagement from '../SoftwareManagement';
import SystemManagement from '../SystemManagement';
import TermFiles from '../TermFiles/TermFiles';
import NewTerminals from '../Terminals/Terminals';
import TermModels from '../TermModels';
import TieApiClients from '../TieApiClients';
import User from '../User/User';
import UserManagement from '../UserManagement';
import CCATabs from './CCATabs';
import SonifiPhone from './containers/SonifiPhone';
import GettingStarted from './components/GettingStarted';
import { clearSite } from '../../v2/GlobalSlices/siteSlice';

const styles = () => ({
  searcher: {
    flexBasis: 'auto',
    flexGrow: '0',
    flexShrink: '1',
    position: 'absolute',
    width: '100%',
    zIndex: '200'
  },
  header: {
    flexBasis: 'auto',
    flexGrow: '0',
    flexShrink: '1',
    position: 'relative',
    top: '70px',
    width: '100%',
  },
  content: {
    flexBasis: 'auto',
    flexGrow: '1',
    flexShrink: '1',
    marginTop: '70px',
    overflow: 'hidden'
  },
  backgroundImageStyle: {
    alignItems: 'center',
    backgroundImage: `url(${Background})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    width: '100vw'
  },
  backgroundSvgStyle: {
    color: 'white',
    margin: 0
  }
});

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

class SonifiApp extends Component {
  state = {
    changed: false,
    ready: false
  };

  getSelectedTabName() {
    if (window.location.href.indexOf('/user/') !== -1) {
      return 'user';
    }
    const arr = window.location.pathname.split('/');
    return `/${arr[1]}${(arr[2] && arr[2].length > 0) ? `/${arr[2]}` : ''}`;
  }

  componentDidMount() {
    const { dispatch } = this.props;
    this.props.dispatch(clearSite());
    dispatch(setTabSelected(this.getSelectedTabName()));

    const sessionToken = getTokenFromSession();
    let token = null;
    if (sessionToken) {
      token = decode(sessionToken);
      console.log('SonifiApp:token', token);
    }

    //           checkMobile();

    if (token['custom:role']) {
      this.setState({ ready: false });
      getObjectPromise(ROLES, token['custom:role']).then((data) => {
        data.permissions.push(token['custom:role']);
        dispatch(updateRole(data));

        setTimeout(() => {
          this.setState({ ready: true });
        }, 500);
      }).catch((error) => {
        this.setState({ ready: true });
        console.log(error);
      });
    } else { // TODO: ASK WHAT TO DO HERE
      console.log('Could not find role for logged in user?');
      this.setState({ ready: true });
    }

    if (token['custom:sites']) {
      dispatch(updateSites((token['custom:sites'] && token['custom:sites'].length > 1)
        ? token['custom:sites'].split(' ')
        : ['*']));
    }

    if (token['custom:group'] !== undefined) {
      dispatch(updateGroup(token['custom:group']));
      getObjectPromise(USER_GROUPS, token['custom:group'], { limit: 5 })
        .then((data) => {
          if (data.sites.length === 1) {
            if (data.sites[0] === '') {
              dispatch(updateGlobalError(
                'There is an issue with your group.  Please contact your administrator.'
              ));
            } else {
              dispatch(updateSites([data.sites[0]]));
              dispatch(getSite(data.sites[0]))
                .then((site) => {
                  setSite(site.id, site.name);
                });
            }
          }
        }).catch((error) => {
          console.log('App:ERROR', error);
          dispatch(updateGlobalError(
            'There is an issue with your group.  Please contact your administrator.'
          ));
        });
    }
  }

  handleChange = (value) => {
    this.props.dispatch(setTabSelected(value));
  };

  changeFunc() {
    this.setState({ changed: !this.state.changed || false });
  }

  /*
    This function returns accessible site/system routes based on users permissions
  */
  configureSiteSystemRoutes() {
    const { networksUsed, userPermissions, isProd } = this.props;

    // Configure
    const canViewSiteMgmt = checkForAtLeastOneUserPermission(roles.SITE_ROLES, userPermissions);
    const canViewSystemMgmt = checkForAtLeastOneUserPermission(roles.SYSTEM_ROLES, userPermissions);
    const canViewSoftwareMgmt = checkForAtLeastOneUserPermission(roles.SONIFI_ROLES, userPermissions);
    const canViewChannels = checkForAtLeastOneUserPermission(roles.CHANNEL_ROLES, userPermissions);
    const canViewIntegrataions = checkForAtLeastOneUserPermission(roles.SONIFI_ROLES, userPermissions);
    const canViewIcp = checkForAtLeastOneUserPermission(roles.KEY_STROKE_ROUTER_ROLES, userPermissions);

    // Manage
    const canViewRooms = checkForAtLeastOneUserPermission(roles.ROOM_ROLES, userPermissions);
    const canViewTerminals = checkForAtLeastOneUserPermission(roles.TERMINAL_DETAIL_ROLES, userPermissions);
    const canViewTerminalFiles = checkForAtLeastOneUserPermission(roles.TV_TERMINAL_FILE_ROLES, userPermissions);

    const lateCheckout = getLateCheckoutFromSession();
    let canViewLateCheckout = false;
    if (lateCheckout === 'true') {
      canViewLateCheckout = checkForAtLeastOneUserPermission(roles.LATE_CHECKOUT_ROLES, userPermissions);
    }

    const hasAnalogRF = networksUsed && networksUsed.rf;
    return (
      <Route path="system" element={<System />} >
        {canViewSiteMgmt && <Route path="siteManagement" element={<SiteManagement />} />}
        {canViewSystemMgmt && <Route path="systemManagement" element={<SystemManagement />} />}
        {canViewSoftwareMgmt && <Route path="softwareManagement" element={<SoftwareManagement />} />}
        {canViewChannels && <Route path="channels" element={<Channels />} />}
        {canViewIntegrataions && <Route path="integrations" element={<Integrations />} />}
        {hasAnalogRF && canViewIcp && <Route path="icp" element={<Icp />} />}
        {canViewRooms && <Route path="rooms" element={<Rooms />} />}
        {canViewTerminals && <Route path="terminals" element={<NewTerminals />} />}
        {canViewTerminalFiles && <Route path="termFiles" element={<TermFiles />} />}
        {lateCheckout === 'true' && canViewLateCheckout && <Route path="lateCheckout" element={<LateCheckout />} />}
        {!isProd && <Route path="selection" element={<Site />} />}
        {!isProd && <Route path="componentsandbox" element={<Sandbox />} />}
      </Route>
    );
  }

  /*
    This function returns accessible site/product routes based on users permissions
  */
  configureSiteProductRoutes() {
    const { userPermissions } = this.props;

    const canViewLibrary = checkForAtLeastOneUserPermission(roles.MESSAGE_ROLES, userPermissions);
    const canViewManager = checkForAtLeastOneUserPermission(roles.MESSAGEPUBLISH_ROLES, userPermissions);
    const canViewGroupServices = checkForAtLeastOneUserPermission(roles.GROUP_SERVICES_ROLES, userPermissions);

    return (
      <Route path="product" element={<Product />} >
        {canViewLibrary && <Route path="library" element={<Library />} />}
        {canViewManager && <Route path="manager" element={<MessagingDashboard />} />}
        {canViewGroupServices && hasGroupServiceFeat() === 'true' &&
          <Route path="groupServices" element={<GroupServices />} />
        }
        <Route path="portal" element={<HotelDirectory />} />
      </Route>
    );
  }

  /*
    This function returns accessible site/reporting routes based on users permissions
  */
  configureSiteReportingRoutes() {
    const { userPermissions } = this.props;

    const canViewPmsLogging = checkForAtLeastOneUserPermission(roles.SONIFI_ROLES, userPermissions);
    const canViewTermComm = checkForSingleUserPermission(TERM_COMM_READ, userPermissions);
    const canViewPurchaseLogs = (
      getSiteNumFromURI() !== null
      && getLateCheckoutFromSession() === 'true'
      && checkForAtLeastOneUserPermission(roles.LATE_CHECKOUT_ROLES, userPermissions)
    );

    if (canViewPmsLogging || canViewPurchaseLogs || canViewTermComm) {
      return (
        <Route path="reporting" element={<Reports />} >
          {canViewPmsLogging && <Route path="pmsLogging" element={<PmsLogging />} />}
          {canViewPurchaseLogs && <Route path="purchaseLogs" element={<PurchaseLog />} />}
          {canViewTermComm && <Route path="termComm" element={<TermComm />} />}
        </Route>
      );
    }
  }

  /*
    This function returns accessible global/configure routes based on users permissions
  */
  configureGlobalConfigureRoutes() {
    const { isProd, userPermissions } = this.props;
    const canViewChannelLogos = checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);
    const canViewTerminalModelsAndFirmware = checkForAtLeastOneUserPermission(roles.FIRMWARE_ROLES, userPermissions);
    const canViewTieApiClients = checkForAtLeastOneUserPermission(roles.SONIFI_ROLES, userPermissions);
    const canViewGlobalTermFiles = checkForAtLeastOneUserPermission(roles.SONIFI_ROLES, userPermissions);
    const canViewBatchChannels = checkForAtLeastOneUserPermission(roles.SONIFI_ROLES, userPermissions);

    return (
      <Route path="configure" element={<Configure />} >
        {canViewChannelLogos && <Route path="logo" element={<ChannelLogos />} />}
        {canViewTerminalModelsAndFirmware && <Route path="termModels" element={<TermModels />} />}
        {canViewTerminalModelsAndFirmware && <Route path="firmware" element={<Firmware />} />}
        {canViewTieApiClients && <Route path="tieApiClients" element={<TieApiClients />} />}
        {canViewGlobalTermFiles && <Route path="globalTermFiles" element={<GlobalTermFiles />} />}
        {!isTesting(isProd) && canViewBatchChannels && <Route path="batchChannels" element={<BatchChannels />} />}
      </Route>
    );
  }

  /*
    This function returns accessible global/manage routes based on users permissions
  */
  configureGlobalManageRoutes() {
    const { userPermissions, isProd } = this.props;
    const canViewRoles = checkForAtLeastOneUserPermission(roles.ROLES_ROLES, userPermissions);
    const canViewTemplates = checkForAtLeastOneUserPermission(roles.SOFTWARE_ROLES, userPermissions);
    const canViewDeployments = checkForAtLeastOneUserPermission(roles.SOFTWARE_ROLES, userPermissions);
    const canViewSoftware = checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);
    const canViewUserMgmt = checkForAtLeastOneUserPermission(roles.USER_ACCOUNT_ROLES, userPermissions);
    const canViewGroupMgmt = checkForAtLeastOneUserPermission(roles.GROUP_ROLES, userPermissions);
    const canViewCloudStream = checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);

    return (
      <Route path="manage" element={<Manage />} >
        {!isProduction(isProd) && canViewRoles && <Route path="roleManagement" element={<RoleManagement />} />}
        {!isProduction(isProd) && canViewRoles && <Route path="roleManagementDev" element={<RolesDev />} />}
        {canViewTemplates && <Route path="releaseTemplates" element={<ReleaseTemplates />} />}
        {canViewDeployments && <Route path="deployments" element={<Deployments />} />}
        {canViewSoftware && <Route path="software" element={<SoftwareChanges />} />}
        {canViewUserMgmt && <Route path="userManagement" element={<UserManagement />} />}
        {canViewGroupMgmt && <Route path="groupManagement" element={<GroupManagement />} />}
        {canViewCloudStream && <Route path="cloudStream" element={<SoftwareChanges />} />}
      </Route>
    );
  }

  configureGlobalReportsRoutes() {
    const { userPermissions } = this.props;
    const canViewAuditRoles = checkForSingleUserPermission(USERLOG_READ, userPermissions);

    return (
      <Route path="reports" element={<GlobalReports />} >
        {canViewAuditRoles && <Route path="auditReport" element={<AuditReport />} />}
        <Route path="terminalReport" element={<TerminalReport />} />
      </Route>
    );
  }

  configureGlobalDownloadsRoutes() {
    const { userPermissions } = this.props;
    const canViewDownloads = checkForAtLeastOneUserPermission(roles.SONIFI_ROLES, userPermissions);

    return (
      <Route path="downloads" element={<GlobalDownloads />} >
        {canViewDownloads && <Route path="ipgDownloads" element={<GlobalDownloads />} />}
      </Route>
    );
  }

  closeSnackBarHandler = () => {
    this.props.dispatch(closeSnackBar());
  };

  render() {
    const { classes, snackbarOpen, snackBarType, snackBarMessage } = this.props;
    const authDomain = process.env.REACT_APP_COGNITO_DOMAIN_PREFIX;

    if (!this.state.ready) {
      return <SonifiSpinner />;
    }

    if (authDomain.includes('signin')) {
      try {
        const token = decode(getTokenFromSession());

        const mobilePhoneUpdated = getItemFromSession('mobilePhoneUpdated');
        if ((!token.phone_number || token.phone_number === null ||
          token.phone_number === '' || !token.phone_number_verified) && mobilePhoneUpdated === null) {
          return <SonifiPhone
            changeFunc={this.changeFunc.bind(this)}
            phoneNum={(token.phone_number ? token.phone_number : null)} />;
        }
      } catch(error) {
        console.log(error);
      }
    }

    const RouteNotFound = () => (
      <div style={{ display: 'flex', justifyContent: 'center', zIndex: '2' }} className={classes.backgroundImageStyle}>
        <div style={{ margin: '0', textAlign: 'center' }}>
          <p style={{ margin: '5%', fontSize: '1.5vw' }} className={classes.backgroundSvgStyle}>ERROR 404</p>
          <p style={{ fontSize: '3.75vw' }} className={classes.backgroundSvgStyle}>OOPS!</p>
          <p
            style={{ margine: '2.5%', fontSize: '1.75vw' }}
            className={classes.backgroundSvgStyle}
          >We&apos;re sorry, this page doesn&apos;t exist.</p>
        </div>
      </div>
    );

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) {
            return <Fragment />;
          }
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          if (getValueFromSession('Mobile')) {
            return <Mobile />;
          }

          return (
            <CacheProvider value={muiCache}>
              <Router>
                <CssBaseline />
                <div className={classes.searcher}>
                  <PrimarySearchAppBar />
                </div>
                <div className={classes.header}>
                  <CCATabs />
                </div>
                <div className={classes.content}>
                  <Routes>
                    <Route path="/" element={<Navigate to="/site" />} />
                    <Route path="/global" >
                      <Route index element={<GlobalDashboard />} />
                      {this.configureGlobalConfigureRoutes()}
                      {this.configureGlobalManageRoutes()}
                      {this.configureGlobalReportsRoutes()}
                      {this.configureGlobalDownloadsRoutes()}
                    </Route>
                    <Route path="/site" >
                      <Route index element={<GettingStarted />} />
                      <Route path=":siteId" >
                        <Route index element={<SiteDashboard />} />
                        {this.configureSiteSystemRoutes()}
                        {this.configureSiteProductRoutes()}
                        {this.configureSiteReportingRoutes()}
                      </Route>
                    </Route>
                    <Route path="/device" element={<User />} >
                      <Route path="provision" element={<ProvisionTerms />} />
                    </Route>
                    <Route path="*" element={<RouteNotFound />} />
                  </Routes>
                  <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
                    open={snackbarOpen} onClose={this.closeSnackBarHandler} />
                </div>
              </Router>
            </CacheProvider>
          );
        }
        }
      </CacheBuster >
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  changed: state.termGrid.changed,
  currentLanguage: state.global.currentLanguage,
  currentUser: state.global.user,
  isProd: state.global.isProd,
  networksUsed: state.termGrid.networksUsed,
  userPermissions: state.global.permissions,
  snackBarType: state.global.globalSnackBarType,
  snackBarMessage: state.global.globalSnackBarMessage,
  snackbarOpen: state.global.globalSnackBarOpen,
});

SonifiApp.propTypes = {
  change: PropTypes.bool,
  changed: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  currentLanguage: PropTypes.string,
  currentUser: PropTypes.object,
  dispatch: PropTypes.func,
  isProd: PropTypes.bool.isRequired,
  snackbarOpen: PropTypes.bool,
  snackBarType: PropTypes.string,
  snackBarMessage: PropTypes.string,
  networksUsed: PropTypes.object,
  userPermissions: PropTypes.array.isRequired
};

export { SonifiApp };
export default connect(mapStateToProps)(withStyles(SonifiApp, styles));
