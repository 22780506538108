import { filter } from 'lodash';
import { SITES, USER_GROUPS } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import { deleteSitelessObjectPromise, getObjectPromise, putSitelessObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

// GET /user-groups
// PUT /user-groups/{group_id}
// DELETE /user-groups/{group_id}

export const sortGroupsBegin = () => ({
  type: types.SORT_GROUPS_BEGIN
});

export const fetchGroupsBegin = () => ({
  type: types.FETCH_GROUPS_BEGIN
});

export const fetchGroupsSuccess = (groups) => ({
  type: types.FETCH_GROUPS_SUCCESS,
  groups
});

export const fetchGroupsFailure = (allowEditing, error) => ({
  type: types.FETCH_GROUPS_FAILURE,
  allowEditing,
  error
});

export const deleteGroupBegin = () => ({
  type: types.DELETE_GROUP_BEGIN
});

export const deleteGroupSuccess = (groupName) => ({
  type: types.DELETE_GROUP_SUCCESS,
  groupName
});

export const deleteGroupFailure = (error) => ({
  type: types.DELETE_GROUP_FAILURE,
  error
});

export function deleteGroup(group, userState) {
  return (dispatch) => {
    dispatch(deleteGroupBegin());

    deleteSitelessObjectPromise(USER_GROUPS, encodeURIComponent(group.id))
      .then((data) => {
        if (!httpSuccess(data.status)) {
          return dispatch(deleteGroupFailure(`${group.description} (${data.status})`));
        }
        dispatch(deleteGroupSuccess(group.description));
        dispatch(fetchGroups('', `${userState.orderBy}:${userState.order}`, userState.filter, true));
      }).catch(() => {
        dispatch(deleteGroupFailure(group.description));
      });
  };
}

export const updateDeleteRow = (site) => ({
  type: types.DELETE_CHECK,
  site
});

export function fetchGroups(groupId, sorting, filterText, sort) {
  return (dispatch) => {
    if (sort) {
      dispatch(sortGroupsBegin());
    } else {
      dispatch(fetchGroupsBegin());
    }

    const groupObj = { sort: sorting };
    if (groupId !== null && groupId !== '') {
      groupObj.group = groupId;
    }
    if (filterText && filterText.length > 0) {
      groupObj.search = encodeURIComponent(filterText);
    }

    getObjectPromise(USER_GROUPS, null, groupObj)
      .then((groups) => {
        if (groupId !== null && groupId !== '') {
          return dispatch(fetchGroupsSuccess(filter(groups.groups, (o) => o.id === groupId)));
        }
        dispatch(fetchGroupsSuccess(groups.groups));
      }).catch((error) => {
        console.log(error);
        dispatch(fetchGroupsFailure(sort, error));
      });
  };
}

export const updateSelectedGroup = (group, editType) => ({
  type: types.UPDATE_SELECTED_GROUP,
  group,
  editType
});


export const addGroupBegin = () => ({
  type: types.ADD_GROUP_BEGIN
});

export const addGroupSuccess = (groups, isNewGroup) => ({
  type: types.ADD_GROUP_SUCCESS,
  groups,
  isNewGroup
});

export const addGroupFailure = (error, isNewGroup) => ({
  type: types.ADD_GROUP_FAILURE,
  error,
  isNewGroup
});

export function addGroup(groupName, groupObj, isNewGroup, userState) {
  return (dispatch) => {
    dispatch(addGroupBegin());

    putSitelessObjectPromise(USER_GROUPS, encodeURIComponent(groupName.trim()), groupObj)
      .then((groups) => {
        if (!httpSuccess(groups.status)) {
          return dispatch(addGroupFailure(groups.status, isNewGroup));
        }
        dispatch(addGroupSuccess(groups, isNewGroup));
        dispatch(fetchGroups('', `${userState.orderBy}:${userState.order}`, userState.filter, true));
      }).catch((error) => {
        console.log(error);
        dispatch(addGroupFailure(error, isNewGroup));
      });
  };
}

export const getSitesBegin = () => ({
  type: types.GET_SITES_BEGIN
});

export const getSitesSuccess = (sites) => ({
  type: types.GET_SITES_SUCCESS,
  sites
});

export const getSitesFailure = (error) => ({
  type: types.GET_SITES_FAILURE,
  error
});

export const getAllSitesSuccess = (sites) => ({
  type: types.GET_ALL_SITES_SUCCESS,
  sites
});

export const getAllSitesFailure = (error) => ({
  type: types.GET_ALL_SITES_FAILURE,
  error
});

export function getSites(filterObj, all) {
  return (dispatch) => {
    dispatch(getSitesBegin());

    getObjectPromise(SITES, null, filterObj)
      .then((data) => {
        all ? dispatch(getAllSitesSuccess(data)) : dispatch(getSitesSuccess(data));
      }).catch((error) => {
        all ? dispatch(getAllSitesFailure(error)) : dispatch(getSitesFailure(error));
      });
  };
}

export const getGroupSitesBegin = () => ({
  type: types.GET_GROUP_SITES_BEGIN
});

export const getGroupSitesSuccess = (sites) => ({
  type: types.GET_GROUP_SITES_SUCCESS,
  sites: sites.sites
});

export const getGroupSitesFailure = (error) => ({
  type: types.GET_GROUP_SITES_FAILURE,
  error
});

export function getGroupSites(groupId, gFilter) {
  return (dispatch) => {
    dispatch(getGroupSitesBegin());

    if (!groupId) {
      return dispatch(getGroupSitesSuccess({ sites: [] }));
    }

    // const apiEnd = (groupId ? USER_GROUPS : SITES);

    getObjectPromise(USER_GROUPS, groupId, gFilter)
      .then((data) => {
        dispatch(getGroupSitesSuccess(data));
      }).catch((error) => {
        dispatch(getGroupSitesFailure(error));
      });
  };
}

export const resetGroups = () => ({
  type: types.RESET_GROUP
});

export const updateSnackBar = (open) => ({
  type: types.UPDATE_SNACK_BAR,
  open
});

export const filterSitesBegin = () => ({
  type: types.FILTER_SITES_BEGIN
});

export const filterSitesSuccess = (sites) => ({
  type: types.FILTER_SITES_SUCCESS,
  sites
});

export const filterSitesFailure = (error) => ({
  type: types.FILTER_SITES_FAILURE,
  error
});

export function filterSites(groupId, filterObj, userGroup) {
  return (dispatch) => {
    if (userGroup) {
      dispatch(getGroupSites(groupId, filterObj));
    } else {
      dispatch(filterSitesBegin());
      getObjectPromise(SITES, groupId, filterObj)
        .then((data) => {
          dispatch(filterSitesSuccess(data));
        }).catch((error) => {
          dispatch(filterSitesFailure(error));
        });
    }
  };
}

export const filterGroupSite = (filterObj) => ({
  type: types.FILTER_GROUP_SITES_FOR_USER,
  filter: filterObj.toLowerCase()
});
