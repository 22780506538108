import { Error } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = () => ({
  errorText: {
    fontSize: '9pt'
  },
  errorIcon: {
    width: '18px',
    height: '18px',
    paddingRight: '2px'
  },
  errorWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tooltip: {
    backgroundColor: 'red',
    maxWidth: 150
  }
});

export class SonifiErrorTooltip extends Component {
  render() {
    const {
      error, errorText, classes
    } = this.props;


    return (
      <Tooltip open={error}
        placement="bottom-start"
        title={
          <div className={classes.errorWrapper}>
            <Error className={classes.errorIcon} />
            <Typography className={classes.errorText}>
              {errorText}
            </Typography>
          </div>}
        classes={{ tooltip: classes.tooltip }}>
        {this.props.children}
      </Tooltip>
    );
  }
}

SonifiErrorTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object,
  label: PropTypes.string,
  error: PropTypes.bool,
  errorText: PropTypes.string
};
export default withStyles(SonifiErrorTooltip, styles);
