import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel, SonifiSpinner, SonifiText } from '../../../containers';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import { findObjectByKey, getErrorText } from '../../../utils';
import { removePipe } from '../../../utils/textUtil';
import { getDropDown } from '../../../utils/ui';
import {
  addReleaseTemplate, editReleaseTemplate, getSoftwareOptions, getTemplatePackages, updateSelectedPackageRow,
  updateSelectedReleaseTemplate
} from '../actions/releaseTemplateActions';
import { validTemplate } from '../utils/validator';
import DialogGrid from './DialogGrid';

export class ReleaseTemplateDialog extends Component {
  state = {
    channel: '',
    errors: {},
    id: '',
    localLoad: false,
    nextStatus: '',
    platform: '',
    product: '',
    version: '',
    virtos: ''
  };

  retrieveTemplatePackages = () => {
    const { dispatch } = this.props;
    const { virtos } = this.state;

    dispatch(getTemplatePackages(virtos));
  };

  componentDidMount() {
    const {
      dispatch, releaseTemplates, selected
    } = this.props;
    this.setState({ ...releaseTemplates[selected], localLoad: true });
    if (selected === -2) {
      dispatch(getSoftwareOptions())
        .then((data) => {
          this.setState({
            platform: (data.platforms.length ? data.platforms[0] : ''),
            virtos: (data.versions.virtos.length ? data.versions.virtos[0] : ''),
            localLoad: false
          });
        });
    } else {
      this.setState({ localLoad: false });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.virtos !== this.state.virtos) {
      this.retrieveTemplatePackages();
    }
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(updateSelectedReleaseTemplate(-1));
      this.props.dispatch(updateSelectedPackageRow(-1));
    }
  }

  onSubmit() {
    const { id, platform, product, virtos } = this.state;
    const {
      dispatch, editType, productParents, selected, templatePackages, translations
    } = this.props;
    const releaseTemplateObj = {};
    releaseTemplateObj.active = false;
    releaseTemplateObj.productName = this.getShortName(product);
    releaseTemplateObj.platform = platform;
    releaseTemplateObj.virtos = virtos;
    releaseTemplateObj.packages = templatePackages;
    releaseTemplateObj.immutable = false;

    const isValidTemplate = validTemplate(releaseTemplateObj, translations.errors);
    this.setState({ errors: isValidTemplate });
    if (Object.entries(isValidTemplate).length === 0) {
      if (selected === -2) {
        releaseTemplateObj.product = product;
        if (releaseTemplateObj.product.length === 0) {
          releaseTemplateObj.product = productParents[0].id;
          releaseTemplateObj.productName = this.getShortName(releaseTemplateObj.product);
        }
        dispatch(addReleaseTemplate(releaseTemplateObj))
          .then((success) => {
            if (success) {
              this.props.addNewTemplate();
            }
          });
      } else if (editType === 'edit') {
        dispatch(editReleaseTemplate(releaseTemplateObj, id, this.state.version))
          .then((success) => {
            if (success) {
              this.props.addNewTemplate();
            }
          });
      }
    }
  }

  processChange = (name) => ({ target: { value } }) => {
    this.setState({ [name]: value });
  };

  editState = (name) => ({ target: { value } }) => {
    this.setState({ [name]: removePipe(value) });
  };

  getShortName(product) {
    const { productParents } = this.props;
    const shortName = findObjectByKey(productParents, 'id', product);
    return shortName ? shortName.name : product;
  }

  render() {
    const {
      canEdit, editType, globalTranslations, loading, pkgLoading, platforms, productParents, selected,
      translations, virtOsVers
    } = this.props;
    const {
      errors, localLoad, product, platform, version, virtos
    } = this.state;

    const readOnly = editType === 'read' || !canEdit; // || statusOptions.length === ONE;
    let modalTitle = translations.editTitle;
    if (editType === 'read') {
      modalTitle = translations.readTitle;
    } else if (selected === -2) {
      modalTitle = translations.addTitle;
    }

    let prodParentDefValue = '';
    if (productParents.length > 0) {
      prodParentDefValue = productParents[0].id;
    }

    return (
      <Dialog open={true}
        onClose={this.onCancel}
        fullWidth
        maxWidth="md" >
        <SonifiModalHeader
          header={modalTitle}
          onCancel={this.onCancel.bind(this)}
          onSubmit={this.onSubmit.bind(this)}
          onlyClose={readOnly ||
            (selected === -2 && (productParents.length < 1 || platforms.length < 1 || virtOsVers.length < 1)) ||
            loading}
          label={globalTranslations.save}
        />
        <DialogContent style={{ minHeight: '54vh' }}>
          {loading || localLoad
            ? <SonifiSpinner />
            : <Grid container>
              {(selected === -2 && (platforms.length < 1 || virtOsVers.length < 1 || productParents.length < 1))
                ? <SonifiLabel error label={translations.errors.problem} />
                : <Grid item xs={12}>
                  <Grid container>
                    <Grid item>
                      {selected === -2 && productParents.length > 1
                        ? getDropDown(
                          translations.productName, product ? product : prodParentDefValue, '',
                          productParents.map((suggestion) => ({
                            id: suggestion.id,
                            value: suggestion.id,
                            label: suggestion.name,
                          })),
                          this.editState('product'),
                          getErrorText('name', errors), !readOnly, 'sm'
                        )
                        : <SonifiText label={translations.productName}
                          defaultValue={this.getShortName(product)} disabled size="sm" />
                      }
                    </Grid>
                    <Grid item>
                      {platforms.length === 1 || selected !== -2
                        ? <SonifiText label={translations.platform} defaultValue={platform} disabled size="sm" />
                        : getDropDown(readOnly ? translations.platform : translations.platform, platform, '',
                          platforms.map((suggestion) => ({
                            id: suggestion,
                            value: suggestion,
                            label: suggestion,
                          })),
                          this.editState('platform'),
                          getErrorText('platform', errors), !readOnly, 'sm')
                      }
                    </Grid>
                  </Grid>
                  <Grid container>
                    {selected !== -2 &&
                      <Grid item>
                        <SonifiText label={translations.version} defaultValue={version} disabled size="sm" />
                      </Grid>}
                    <Grid item>
                      {virtOsVers.length === 1 || selected !== -2
                        ? <SonifiText label={translations.virtOs} defaultValue={virtos} disabled size="sm" />
                        : getDropDown(readOnly ? translations.virtOs : translations.virtOs, virtos, '',
                          virtOsVers.map((suggestion) => ({
                            id: suggestion,
                            value: suggestion,
                            label: suggestion,
                          })),
                          this.editState('virtos'),
                          getErrorText('virtos', errors), !readOnly, 'sm')}
                    </Grid>
                  </Grid>
                  <Grid container>
                    {pkgLoading
                      ? <SonifiSpinner />
                      : <DialogGrid packageError={getErrorText('packages', errors).length > 0} />}
                  </Grid>
                </Grid>}
            </Grid>}
        </DialogContent>
      </Dialog >
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.releaseTemplates.changed,
  editType: state.releaseTemplates.editType,
  globalTranslations: state.global.translations.defaults,
  platforms: state.releaseTemplates.platforms,
  loading: state.releaseTemplates.loading,
  pkgLoading: state.releaseTemplates.pkgLoading,
  productParents: state.deployments.productParents,
  releaseTemplates: state.releaseTemplates.releaseTemplates,
  selected: state.releaseTemplates.selected,
  templatePackages: state.releaseTemplates.templatePackages,
  translations: state.releaseTemplates.translations.editDialog,
  virtOsVers: state.releaseTemplates.virtOsVers
});

ReleaseTemplateDialog.propTypes = {
  addNewTemplate: PropTypes.func,
  canEdit: PropTypes.bool,
  changed: PropTypes.bool,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  pkgLoading: PropTypes.bool,
  globalTranslations: PropTypes.object,
  platforms: PropTypes.array,
  productParents: PropTypes.array,
  releaseTemplates: PropTypes.array,
  loading: PropTypes.bool,
  reset: PropTypes.func,
  selected: PropTypes.number,
  sort: PropTypes.string,
  templatePackages: PropTypes.array,
  translations: PropTypes.object,
  virtOsVers: PropTypes.array
};

export default connect(mapStateToProps)(ReleaseTemplateDialog);
