import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiCheckbox } from '../../../../containers';
import SonifiIconButton from '../../../../containers/SonifiIconButton';
import SonifiText from '../../../../containers/SonifiText';
import Selects from '../Selects';

class AddComponent extends Component {
  handleChange = (name) => ({ target: { value } }) => {
    this.props.onChange(name, value.replace(/([|])/g, ''));
  };

  handleRoomNumber = (name) => ({ target: { value } }) => {
    this.props.onChange(name, value.replace(/([^0-9])/g, ''));
  };

  /* eslint-disable camelcase */
  render() {
    const {
      building, floor, id, is_test, rangeEnd, unit
    } = this.props.addState;
    const {
      addRangeEnd, errors, handleCheckboxChange, range, translations
    } = this.props;

    return (
      <Grid container>
        <Grid item>
          <SonifiText
            change={(range ? this.handleRoomNumber('id') : this.handleChange('id'))}
            defaultValue={id}
            error={!!errors.id}
            errorText={errors.id}
            label={range ? translations.addDialog.rangeStart : translations.main.roomNum}
            size="sm"
          />
        </Grid>
        {range
          ? <Grid item>
            <SonifiText
              change={this.handleRoomNumber('rangeEnd')}
              defaultValue={rangeEnd}
              error={!!errors.end}
              errorText={errors.end}
              label={translations.addDialog.rangeEnd}
              size="sm"
            />
          </Grid>
          : <Grid item style={{ width: '200px', marginRight: '16px', paddingTop: '5px' }}>
            <SonifiIconButton label={translations.addDialog.rangeEnd}
              onClick={addRangeEnd}
              icon={<Add />} />
          </Grid>
        }
        <Grid item>
          <Selects
            building={building}
            errors={errors}
            floor={floor}
            onChange={this.props.onChange}
            unit={unit}
          />
        </Grid>
        <Grid item>
          <SonifiCheckbox
            float="right"
            label={translations.editDialog.testRoom}
            onChange={handleCheckboxChange('is_test')}
            value={is_test || false}
          />
        </Grid>
      </Grid>

    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.rooms.translations
});

AddComponent.propTypes = {
  addRangeEnd: PropTypes.func,
  addState: PropTypes.object.isRequired,
  errors: PropTypes.object,
  handleCheckboxChange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  range: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(AddComponent);
