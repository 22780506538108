export function validatePassword(nPassword, vPassword) {
  // if (checknPassword) {
  const firstPassword = nPassword.trim();
  const secondPassword = vPassword.trim();

  const lowerCaseLetters = /[a-z]/g;
  const upperCaseLetters = /[A-Z]/g;
  const numbers = /[0-9]/g;
  const nonNubmers = /([^A-Za-z0-9])/g;

  if (firstPassword !== secondPassword) {
    return 'Passwords do not match';
  }

  if (nPassword === null || firstPassword === '') {
    return 'Need to enter a password';
  } else if (firstPassword.length < 10) {
    return 'Minimum length must be greater than 10';
  } else if (firstPassword.length > 64) {
    return 'Maximum length must be less than 64 characters';
  } else if (!firstPassword.match(lowerCaseLetters)) {
    return 'Need to include lowercase character';
  } else if (!firstPassword.match(upperCaseLetters)) {
    return 'Need to include uppercase character';
  } else if (!firstPassword.match(numbers)) {
    return 'Need to include a number';
  } else if (!firstPassword.match(nonNubmers)) {
    return 'Need to include a non-alphanumeric character';
  }

  if (vPassword === null || secondPassword === '') {
    return 'Need to confirm password';
  }

  return '';
}

export function validCode(code) {
  if (code === null || code.trim() === '') {
    return 'Need to enter code';
  }
  return null;
}
