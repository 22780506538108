import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel, SonifiText } from '../../../../containers';
import SonifiModalHeader from '../../../../containers/SonifiModalHeader';
import SonifiSpinner from '../../../../containers/SonifiSpinner';
import { getErrorText, isError } from '../../../../utils';
import { fetchFirmware } from '../../../Firmware/actions/firmwareActions';
import { saveSiteFirmware } from '../../actions/FirmwareActions';
import * as actions from '../../actions/SamsungFirmwareActions';
import { CLIENT, SAMSUNG } from '../../constants/TermFileConstants';

class SamsungClientDialog extends Component {
  state = {
    origClient: null,
    selectedClient: null,
  };

  componentDidMount() {
    const { dispatch, samsungClient } = this.props;
    dispatch(fetchFirmware({
      limit: 100, sort: 'id:asc', make: SAMSUNG, model: '*'
    }));

    this.setState({
      selectedClient: samsungClient,
      origClient: samsungClient
    });
  }

  updateFirmware() {
    const { dispatch, translations } = this.props;
    const { selectedClient, origClient } = this.state;

    if (selectedClient === origClient) {
      this.onCancel();
      return;
    }

    const samsungFirmwareObj = {};
    samsungFirmwareObj.id = '*';
    samsungFirmwareObj.firmware = {
      [CLIENT]: selectedClient === translations.unconfigureClient ? null : selectedClient,
    };

    dispatch(saveSiteFirmware(samsungFirmwareObj, SAMSUNG));
  }

  onCancel() {
    this.props.dispatch(actions.editSamsungFile(''));
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.onCancel();
    }
  }

  handleClientChange = (name) => ({ target: { value } }) => {
    this.setState({ [name]: value });
  };

  getBrowserOptions(name, type) {
    let clientOptions = [];
    const { configuredFirmware, loading, translations } = this.props;

    if (!loading) {
      clientOptions = [...configuredFirmware];
      if (this.state[`orig${name}`] !== '' && this.state[`orig${name}`] !== null &&
        this.state[`orig${name}`] !== undefined) {
        const selected = find(clientOptions, (o) => o.id === this.state[`orig${name}`]);
        if (selected === undefined) {
          clientOptions.unshift({ id: this.state[`orig${name}`], type });
        }
        clientOptions.unshift({
          id: translations.unconfigureClient,
          type
        });
      }
    }
    return clientOptions;
  }

  render() {
    const {
      canEdit, fetching, globalTranslations, loading, translations
    } = this.props;
    const { errors, selectedClient } = this.state;

    return (
      <Dialog
        open={true}
        maxWidth="sm"
        onClose={this.onClose.bind(this)}
        fullWidth>
        <SonifiModalHeader
          header={translations.clientTitle}
          onlyClose={!canEdit || loading || fetching}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.updateFirmware.bind(this)}
          label={globalTranslations.save}
        />
        <DialogContent>
          {loading || fetching
            ? <SonifiSpinner />
            : <Grid container>
              <Grid item xs={12}>
                <SonifiLabel boldLabel={translations.clientText} />
              </Grid>
              <Grid item xs={8}>
                <SonifiText
                  defaultValue={selectedClient}
                  change={this.handleClientChange('selectedClient')}
                  select={true}
                  label={translations.client}
                  size="percent"
                  error={isError(CLIENT, errors)}
                  errorText={getErrorText(CLIENT, errors)}
                  items={this.getBrowserOptions('Client', CLIENT).filter(
                    (suggestion) => (suggestion.type === CLIENT && !suggestion.corrupted)
                  ).map((suggestion) => ({
                    id: suggestion.id,
                    value: suggestion.id,
                    label: suggestion.id,
                  }))}
                />
              </Grid>
            </Grid>}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  configuredFirmware: state.firmware.firmware,
  fetching: state.firmware.fetching,
  globalTranslations: state.global.translations.defaults,
  loading: state.firmware.loading,
  samsungClient: state.termFile.samsungClient,
  samsungEditType: state.termFile.samsungEditType,
  translations: state.termFile.translations.dialog.lg
});

SamsungClientDialog.propTypes = {
  canEdit: PropTypes.bool,
  configuredFirmware: PropTypes.array,
  dispatch: PropTypes.func,
  fetching: PropTypes.bool,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  samsungClient: PropTypes.string,
  samsungEditType: PropTypes.string,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(SamsungClientDialog);
