import { Add } from '@mui/icons-material';
import { Grid, Table, TableBody } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ROLES_ROLES } from '../../../constants/roleGroups';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiSubHeader from '../../../containers/SonifiSubHeader';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { fetchRoles, sortRoles, updateSelectedRole } from '../actions/roleActions';
import RoleRow from '../containers/RoleRow';
import RoleDialog from './RoleDialog';

class RoleGrid extends Component {
  state = {
    order: 'desc',
    orderBy: 'name'
  };

  componentDidMount() {
    this.props.dispatch(fetchRoles());
  }

  handleRequestSort = (property) => {
    this.props.dispatch(updateSelectedRole(-1));
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    }, () => {
      this.props.dispatch(sortRoles(this.state));
    });
  };

  add() {
    console.log('Add something here');
  }

  saveComponent(submitFunc) {
    return (
      <SonifiSubHeader
        header=""
        onSubmit={submitFunc}
        label={this.props.globalTranslations.add}
        icon={<Add />}
        showButton={true}
      />
    );
  }

  render() {
    const { order, orderBy } = this.state;
    const {
      loading, roles, selectedRole, translations, userPermissions
    } = this.props;

    if (!checkForAtLeastOneUserPermission(ROLES_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    const tableHeader = [
      { id: 'name', sortable: true, numeric: false, label: `${translations.name}` },
      { id: 'owner', sortable: true, numeric: false, label: `${translations.owner}` },
      { id: 'description', sortable: true, numeric: false, label: `${translations.description}` }
    ];

    return (
      <Grid container style={{
        minHeight: '250px',
        width: '98%',
        overflowX: 'auto'
      }}>
        <Grid item xs={12}>
          {loading
            ? <SonifiSpinner />
            : <Table>
              <SonifiTableHead
                headColumns={tableHeader}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <TableBody>
                {roles.map((option, index) => (
                  <RoleRow key={index} rowIndex={index} />
                ))}
              </TableBody>
            </Table>
          }
        </Grid>
        {selectedRole !== -1 && <RoleDialog />}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  loading: state.roles.loading,
  roles: state.roles.roles,
  selectedRole: state.roles.selectedRole,
  translations: state.roles.translations.grid,
  userPermissions: state.global.permissions
});

RoleGrid.propTypes = {
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  roles: PropTypes.array,
  selectedRole: PropTypes.number,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(RoleGrid);
