import { Paper, Table } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { getSystemSchedule } from '../../../actions/globalActions';
import SonifiSubHeader from '../../../containers/SonifiSubHeader';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import { buildSortString } from '../../../utils';
import { clearLive, fetchLiveMessages } from '../actions/messagingActions';
import LiveTableBody from './LiveTableBody';

const styles = () => ({
  contents: {
    backgroundColor: '#fff',
    boxShadow: 'none',
    border: '1px solid #c6c4c4',
    padding: '15px',
    height: 400,
    width: '100%'
  },
  innerContents: {
    width: '100%',
    overflowX: 'auto',
    marginTop: 15,
    border: 'none',
    maxHeight: '85%',
    flexGrow: 1,
  },
  table: {
    minWidth: 700,
  },
});

class LiveMessages extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.props.dispatch(clearLive());

    // to adjust default column sort and direction, do it here
    this.state = {
      order: 'desc',
      orderBy: 'start_timestamp',
      page: 1,
      reload: false,
      active: true,
    };
    this.props.dispatch(getSystemSchedule());
    this.props.dispatch(fetchLiveMessages(buildSortString(this.state)));
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('COMPONENTDIDUPDATE publishedmessages.js');
    // console.log('prevProps', prevProps);
    // console.log('Props', this.props);
    // console.log('prevState', prevState);
    // console.log('State', this.state);

    // if 'reload' is detected, clear list and start fetching data again.
    // List was being duplicated, and this is a way to refresh the list to keep current
    // if message had been saved/updated
    if (this.state.reload) {
      this.props.dispatch(clearLive());
      this.props.dispatch(fetchLiveMessages(buildSortString(this.state)));
      this.setState({
        reload: false
      });
    }

    // if save occurred,set page to 1 and reload data
    if (!prevProps.dialogSaveAttemptComplete && this.props.dialogSaveAttemptComplete) {
      this.setState({
        page: 1,
        reload: true
      });
    }

    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(clearLive());
      this.props.dispatch(fetchLiveMessages(buildSortString(this.state)));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(fetchLiveMessages(buildSortString(this.state)));
    }
  }

  handleWayPointReached() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    });
  };

  render() {
    const { classes, live, tableHeader, translations } = this.props;
    const { order, orderBy } = this.state;

    tableHeader.map((headerOption) => (
      headerOption.sortable = live.length > 1
    ));

    return (
      <div style={{ display: 'flex', width: '100%' }}>
        <Paper className={classes.contents}>
          <SonifiSubHeader
            header={translations.liveTitle}
            showButton={false}
          />
          <Paper className={classes.innerContents}>
            <Table className={classes.table}>
              <SonifiTableHead
                headColumns={tableHeader}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              <LiveTableBody
                onWayPointReached={this.handleWayPointReached.bind(this)}
              />
            </Table>
          </Paper>
        </Paper>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dialogSaveAttemptComplete: state.messaging.dialogSaveAttemptComplete,
  live: state.messaging.live,
  maxPages: state.messaging.live_page_count,
  translations: state.messaging.translations.messaging
});

LiveMessages.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogSaveAttemptComplete: PropTypes.bool,
  dispatch: PropTypes.func,
  live: PropTypes.array,
  maxPages: PropTypes.number,
  tableHeader: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(LiveMessages, styles));

