import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SonifiLabel, SonifiTableHead } from '../../../../containers';
import SonifiTemplate from '../../../../containers/SonifiTemplate';

export default class ReplacementTable extends Component {
  getHeaderInfo() {
    const { translations } = this.props;
    return [
      { id: 'tv', sortable: false, numeric: false, label: translations.tv },
      { id: 'name', sortable: false, numeric: false, label: translations.name },
      { id: 'affiliate', sortable: false, numeric: false, label: translations.affiliate },
      { id: 'tz', sortable: false, numeric: false, label: translations.tz },
      { id: 'source_id', sortable: false, numeric: false, label: translations.sourceID }
    ];
  }

  getPageDetails() {
    return (
      <Table stickyHeader>
        <SonifiTableHead
          headColumns={this.getHeaderInfo()}
        />
        <TableBody>
          {this.props.replacements.map((channel, index) => (
            <TableRow key={index}>
              <TableCell> <SonifiLabel label={`${channel.number}`} /></TableCell>
              <TableCell> <SonifiLabel label={channel.name} /></TableCell>
              <TableCell> <SonifiLabel label={channel.affiliate} /></TableCell>
              <TableCell> <SonifiLabel label={channel.timezone} /></TableCell>
              <TableCell> <SonifiLabel label={`${channel.source_id}`} /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  render() {
    const { translations } = this.props;
    return (
      <SonifiTemplate
        header={translations.viewChannels}
        multiple={true}
        paddingBottom={true}
        pageDetails={this.getPageDetails()}
      />
    );
  }
}

ReplacementTable.propTypes = {
  replacements: PropTypes.array,
  translations: PropTypes.object
};
