import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import AgilysisBase from './AgilysysBase';

class Request extends AgilysisBase {
  render() {
    const { editState, translations } = this.props;
    return (
      <Grid item xs={12}>
        {this.getTextBox(translations.cashier_employee_ID, editState.request.cashier_employee_ID,
          'cashier_employee_ID', 'request')}
        {this.getTextBox(translations.register_ID, editState.request.register_ID, 'register_ID', 'request')}
        {this.getTextBox(translations.post_property_ID, editState.request.post_property_ID,
          'post_property_ID', 'request')}
        {this.getTextBox(translations.meal_period, editState.request.meal_period, 'meal_period', 'request')}
        {this.getTextBox(translations.server_employee_ID, editState.request.server_employee_ID,
          'server_employee_ID', 'request')}
        {this.getTextBox(translations.resort_ID, editState.request.resort_ID, 'resort_ID', 'request')}
        {this.getTextBox(translations.client_ID, editState.request.client_ID, 'client_ID', 'request')}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.integrations.translations.agilysys.request
});

Request.propTypes = {
  editState: PropTypes.object,
  notEditable: PropTypes.bool,
  translations: PropTypes.object,
  updateTypeState: PropTypes.func
};

export default connect(mapStateToProps)(Request);
