import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils';
import * as actions from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };
const initialState = {
  saving: false,
  termInfo: [],
  snackBarMessage: '',
  snackBarType: SUCCESS,
  translations: null
};

export default function globalTermFilesReducer(state = initialState, action) {
  let miscObj = null;
  switch (action.type) {
    case actions.CLOSE_SNACK_BAR:
      return { ...state, snackBarMessage: '' };

    case actions.UPDATE_GLOBAL_TERM_FILES_BEGIN:
      return {
        ...state, saving: true, termInfo: action.termInfo, snackBarMessage: '', snackBarType: SUCCESS
      };

    case actions.UPDATE_GLOBAL_TERM_FILES_COMPLETE:
      miscObj = JSON.parse(JSON.stringify(state.termInfo));

      for (let i = 0; i < action.responses.length; i++) {
        if (action.responses[i] && action.responses[i].status) {
          miscObj[i].response = action.responses[i].status;
          if (action.responses[i].detail) {
            miscObj[i].detail = `${action.responses[i].title}: ${action.responses[i].detail}`;
          }
        }
      }
      return { ...state, saving: false, termInfo: miscObj };

    case actions.UPDATE_GLOBAL_TERM_FILES_ERROR:
      miscObj = JSON.parse(JSON.stringify(state.snackBarMessage));
      miscObj += `${action.error} \n`;
      return { ...state, snackBarMessage: miscObj, snackBarType: ERROR };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };
    default:
      return state;
  }
}
