import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import SonifiLabel from '../SonifiLabel';

const styles = (theme) => ({
    dropFile: {
        height: '100%',
        backgroundColor: theme.palette.secondary.main
    },
    ninetyEight: {
        height: '98%'
    },
    textCenter: {
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
    },
    noPadding: {
        fontWeight: 900, /* Avenir 85 */
        fontFamily: 'Open Sans',
        fontSize: '14pt',
        padding: 0
    },
    default: {
        color: theme.palette.primary.contrastText,
        fontFamily: 'Open Sans',
        fontSize: '12pt',
        fontWeight: 900, /* Avenir 85 */
        height: '100%',
        outline: 'none'
    },
    hoverCursor: {
        cursor: 'cell'
    },
    oneHundredMax: {
        height: '100%',
        maxWidth: '100%',
        display: 'block',
        margin: 'auto',
        padding: '1%'
    },
    error: {
        backgroundColor: '#d32f2f'
    }
});

/**
 * A wrapper used to simplify and standardize the react-dropzone Dropzone component
 *
 * @visibleName Sonifi Dropzone
 * @version 1.0.0
 * @author [Cameron Powers](https://git.sonifi.com/cpowers)
 * @see See [react-dropzone](https://react-dropzone.js.org/#section-components) for more documentation
 */
class SonifiDropZone extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            dropRejected: false
        };
        this.onRejectDropHandler = this.onRejectDropHandler.bind(this);
        this.getDropIt = this.getDropIt.bind(this);
        this.getIntroStuff = this.getIntroStuff.bind(this);
    }

    /**
     * Sets error and updates state when file is rejected.
     * @private
     * @param {array} arr An array of error objects
     * @returns {undefined}
     */
    onRejectDropHandler(arr) {
        const errorArray = arr[0].errors;

        this.setState({
            dropRejected: true,
            errors: errorArray
        });
    }

    /**
     * Gets the text to be rendered when user drags over the drop zone.
     * @private
     * @returns {function} Returns the JSX to be rendered
     */
    getDropIt() {
        const { classes, globalTranslations } = this.props;
        return (
            <Grid container className={`${classes.textCenter}`}>
                <Grid item>
                    <SonifiLabel additionalClasses={classes.noPadding} label={globalTranslations.importDialog.drop} />
                </Grid>
            </Grid>
        );
    }

    /**
     * Triggers the callsbacks pasted as props to render the dropzone content
     * @private
     * @returns {function} Returns the JSX to be rendered
     */
    getIntroStuff() {
        const { displayOnlyContent, displayDropZoneContent, disabled, files } = this.props;

        if (disabled || (files && files.length > 0)) {
            return displayOnlyContent();
        }
        return displayDropZoneContent();
    }

    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
    getAcceptedObject() {
        const { acceptedFiles } = this.props;
        const returnObj = {};
        if (acceptedFiles.includes('text/csv') || acceptedFiles.includes('.csv')) {
            returnObj['text/csv'] = ['.csv'];
        }

        if (acceptedFiles.includes('application/vnd.ms-excel')) {
            returnObj['application/vnd.ms-excel'] = ['.xls'];
        }

        if (acceptedFiles.includes('image/png')) {
            returnObj['image/png'] = ['.png'];
        }

        if (acceptedFiles.includes('image/svg')) {
            returnObj['image/svg+xml'] = ['.svg'];
        }

        if (acceptedFiles.includes('.tlx')) {
            returnObj['.tlx'] = ['.tlx'];
        }
        return returnObj;
    }

    render() {
        const {
            acceptedFiles, allowMultiple, classes, disabled, files, maxSize, onAcceptedDropHandler, onRejectDropHandler,
        } = this.props;
        const { errors, dropRejected } = this.state;
        let typeError, sizeError, otherError;

        if (dropRejected) {
            errors.forEach((errorObj) => {
                if (errorObj.code === 'file-invalid-type') {
                    const formattedMsg = errorObj.message.replaceAll(',', ', ');
                    typeError = (
                        <Grid container className={`${classes.textCenter}`}>
                            <Grid item>
                                <SonifiLabel
                                    additionalClasses={classes.noPadding}
                                    label={formattedMsg}
                                />
                            </Grid>
                        </Grid>
                    );
                } else if (errorObj.code === 'file-too-large') {
                    sizeError = (
                        <Grid container className={`${classes.textCenter}`}>
                            <Grid item>
                                <SonifiLabel
                                    additionalClasses={classes.noPadding}
                                    label={errorObj.message}
                                />
                            </Grid>
                        </Grid>
                    );
                } else {
                    otherError = (
                        <Grid container className={`${classes.textCenter}`}>
                            <Grid item>
                                <SonifiLabel
                                    additionalClasses={classes.noPadding}
                                    label={errorObj.message}
                                />
                            </Grid>
                        </Grid>
                    );
                }
            });
        }

        return (
            <Dropzone
                accept={acceptedFiles ? this.getAcceptedObject() : undefined}
                disabled={disabled ?? undefined}
                onDropAccepted={(event) => {
                    this.setState({ dropRejected: false });
                    onAcceptedDropHandler(event);
                }}
                onDropRejected={onRejectDropHandler ?? this.onRejectDropHandler}
                minSize={0}
                maxSize={maxSize ?? undefined}
                multiple={allowMultiple}
            >
                {({
                    getRootProps, getInputProps, isDragActive
                }) => (
                    <div {...getRootProps()}
                        className={
                            `${(dropRejected ? classes.error : '')} 
                            ${(files && files.length > 0 ? '' : classes.dropFile)
                            } ${classes.default} ${classes.hoverCursor}`
                        }>
                        <input {...getInputProps()} />
                        <div className={classes.default}>
                            {!isDragActive && !dropRejected && this.getIntroStuff()}
                            {isDragActive && !dropRejected && this.getDropIt()}
                            {dropRejected && typeError}
                            {dropRejected && sizeError}
                            {dropRejected && otherError}
                        </div>
                    </div>
                )}

            </Dropzone>
        );
    }
}
const mapStateToProps = (state) => ({
    globalTranslations: state.global.translations,
});

SonifiDropZone.propTypes = {
    /**
     * The MIME or extensions which are accepted by the dropzone
     *
     * @see See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types) for types.
     */
    acceptedFiles: PropTypes.array,

    /**
     * Configuration to allow multiple file uploads
     */
    allowMultiple: PropTypes.bool.isRequired,

    /**
     * Classes used to style the component
     *
     * @ignore
     */
    classes: PropTypes.object,

    /**
     * Configuration to disable the dropzone
     */
    disabled: PropTypes.bool,

    /**
     * Callback triggered to render content when component is disabled or which an image file is uploaded
     */
    displayOnlyContent: PropTypes.func.isRequired,

    /**
     * Callback triggered to render the default content for the dropzone
     */
    displayDropZoneContent: PropTypes.func.isRequired,

    /**
     * Used to determine dropdown styling which uploading images
     */
    files: PropTypes.array,

    /**
     * The maximum allowable size of the file
     */
    maxSize: PropTypes.number,

    /**
     * Configurable text used for this application
     *
     * @ignore
     */
    globalTranslations: PropTypes.object,

    /**
     * The callback triggered which a drop has been accepted
     */
    onAcceptedDropHandler: PropTypes.func.isRequired,

    /**
     * The callback triggered which a drop has been rejected
     */
    onRejectDropHandler: PropTypes.func
};

/**
 * Needed to show PropType documentation.... current doesn't support object notation : (
 * @component
 */
export default connect(mapStateToProps)(withStyles(SonifiDropZone, styles, { withTheme: true }));
