import { Grid } from '@mui/material';
import { debounce, find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { SONIFI_ADMIN } from '../../../constants/roles';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSection from '../../../containers/SonifiSection';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiSwitch from '../../../containers/SonifiSwitch';
import SonifiText from '../../../containers/SonifiText';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import { getSoftwareOptions } from '../../ReleaseTemplates/actions/releaseTemplateActions';
import { getUpgradeWindow, setUpgradeWindow } from '../../SystemManagement/actions/systemManagementActions';
import { softwareChannels } from '../constants';

class UpgradeWindow extends Component {
  state = {
    data: null,
    loading: true
  };

  componentDidMount() {
    this.props.dispatch(getSoftwareOptions());
    this.props.dispatch(getUpgradeWindow())
      .then((data) => {
        if (!data) {
          return;
        }
        if (data['update-mode'] === 'manual') {
          this.setState({
            data: {
              ...data,
              'automatic-window': {
                schedule: '*-*-* 00:00:00',
                delay: 0
              }
            },
            loading: false
          });
        } else {
          this.setState({ data: { ...data }, loading: false });
        }
      });
  }

  debouncedAutoUpdate = debounce(this.autoUpdate, KEYBOARD_DELAY);

  handleCheckChange = ({ target: { checked } }) => {
    this.updateData('update-mode', checked ? 'automatic' : 'manual');
  };

  handleTermLocationChange = (name) => ({ target: { value } }) => {
    this.updateData(name, value);
  };

  updateData(name, value) {
    this.setState({
      data:
      {
        ...this.state.data,
        [name]: value
      }
    }, () => {
      this.debouncedAutoUpdate();
    });
  }

  autoUpdate() {
    const { data } = this.state;
    const upgradeObj = { ...data };
    if (upgradeObj['update-mode'] === 'automatic') {
      upgradeObj['automatic-window'] = {
        schedule: '*-*-* 00:00:00',
        delay: 0
      };
    }
    this.props.dispatch(setUpgradeWindow(upgradeObj));
  }

  upgradeBody() {
    const {
      translations, upgradeWindow, upgradeWindowLoading, userPermissions
    } = this.props;
    const { data, loading } = this.state;
    const readOnly = !checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);
    const isAutomatic = data && data['update-mode'] === 'automatic';
    return (
      upgradeWindowLoading || loading
        ? <SonifiSpinner />
        : <Fragment>
          {upgradeWindow === null
            ? <SonifiLabel error label={translations.errors.noUpgradeWindow} />
            : <Grid container spacing={1}>
              <SonifiSwitch
                disabled={readOnly}
                label={translations.labels.upgradeMode}
                leftLabel={translations.labels.manual}
                onChange={this.handleCheckChange.bind(this)}
                checked={isAutomatic}
              />
              <Fragment>
                <Grid item>
                  <SonifiText
                    label={translations.labels.systemd}
                    defaultValue={data['automatic-window'].schedule}
                    disabled={true}
                    hide={!isAutomatic}
                    size="percent"
                  />
                </Grid>
                <Grid item>
                  <SonifiText
                    label={translations.labels.delay}
                    defaultValue={`${data['automatic-window'].delay} ${translations.labels.seconds}`}
                    disabled={true}
                    hide={!isAutomatic}
                    size="percent"
                  />
                </Grid>
              </Fragment>
            </Grid>}
        </Fragment>
    );
  }

  softwareBody() {
    const {
      translations, upgradeWindowLoading, userPermissions
    } = this.props;
    const { data, loading } = this.state;
    const readOnly = !checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);

    return (upgradeWindowLoading || loading
      ? <SonifiSpinner />
      : <Fragment>
        {readOnly
          ? <SonifiText
            defaultValue={find(softwareChannels, { value: data.channel }).label}
            disabled={readOnly}
            label={translations.labels.channel}
            size="percent"
          />
          : <SonifiText
            change={this.handleTermLocationChange('channel')}
            defaultValue={data.channel}
            disabled={readOnly}
            items={softwareChannels}
            label={translations.labels.channel}
            select={true}
            size="percent"
          />}
      </Fragment>
    );
  }

  virtOsBody() {
    const {
      translations, upgradeWindowLoading, userPermissions, virtOsVers
    } = this.props;
    const { data, loading } = this.state;
    const readOnly = !checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);

    if (virtOsVers.length === 0 || data === null) {
      return <Fragment />;
    }

    return (upgradeWindowLoading || loading
      ? <SonifiSpinner />
      : <Fragment>
        {readOnly
          ? <SonifiText
            defaultValue={data.virtos}
            disabled={readOnly}
            label={translations.labels.virtOs}
            size="percent"
          />
          : <SonifiText
            change={this.handleTermLocationChange('virtos')}
            defaultValue={data.virtos}
            disabled={readOnly}
            items={
              virtOsVers.map((suggestion) => ({
                id: suggestion,
                value: suggestion,
                label: suggestion,
              }))
            }
            label={translations.labels.virtOs}
            select={true}
            size="percent"
          />}
      </Fragment>
    );
  }

  render() {
    const { translations } = this.props;
    return (
      <Grid container justifyContent="space-between">
        <Grid item xs={7.5}>
          <SonifiSection title={translations.headers.upgradeWindow} body={this.upgradeBody()} allPadding />
        </Grid>
        <Grid item xs={2}>
          <SonifiSection title={translations.headers.virtOsVersion} body={this.virtOsBody()} allPadding />
        </Grid>
        <Grid item xs={2}>
          <SonifiSection title={translations.headers.softwareChannel} body={this.softwareBody()} allPadding />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.systemManagement.translations,
  upgradeWindow: state.systemManagement.upgradeWindow,
  upgradeWindowLoading: state.systemManagement.upgradeWindowLoading,
  userPermissions: state.global.permissions,
  virtOsVers: state.releaseTemplates.virtOsVers
});

UpgradeWindow.propTypes = {
  dispatch: PropTypes.func,
  translations: PropTypes.object,
  upgradeWindow: PropTypes.object,
  upgradeWindowLoading: PropTypes.bool,
  userPermissions: PropTypes.array,
  virtOsVers: PropTypes.array
};

export default connect(mapStateToProps)(UpgradeWindow);
