import { RELEASE_TEMPLATES, SOFTWARE_OPTIONS, SOFTWARE_PACKAGES } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import { MAX_SIZE } from '../../../constants/magic';
import {
  deleteSitelessObjectPromise, getSitelessObjectPromise, postSitelessObjectPromise, putSitelessObjectPromise
} from '../../../utils/api';
import * as types from './actionTypes';

export const releaseSaving = (saving) => ({
  type: types.RELEASE_SAVING,
  saving
});

export const addPackageRowBegin = () => ({
  type: types.ADD_PACKAGE_ROW_BEGIN,
});

export const addPackageRowSuccess = () => ({
  type: types.ADD_PACKAGE_ROW_SUCCESS,
});

export const addPackageRowFailure = (error) => ({
  type: types.ADD_PACKAGE_ROW_FAILURE,
  error,
});

export const addTemplateRowBegin = () => ({
  type: types.ADD_TEMPLATE_ROW_BEGIN,
});

export const addTemplateRowSuccess = (productName, versionNum) => ({
  type: types.ADD_TEMPLATE_ROW_SUCCESS,
  productName,
  versionNum
});

export const addTemplateRowFailure = (error) => ({
  type: types.ADD_TEMPLATE_ROW_FAILURE,
  error,
});

export const clearTable = () => ({
  type: types.CLEAR_TABLE
});

export const deletePackageRow = (rowIndex) => ({
  type: types.DELETE_PACKAGE_ROW,
  rowIndex
});

export const showCreateNewDeployment = (showDialog, releaseInfo = null) => ({
  type: types.CREATE_NEW_DEPLOYMENT,
  showDialog,
  releaseInfo
});

export const updateSnackBar = (snackBarMessage, snackBarType = null) => ({
  type: types.UPDATE_SNACK_BAR,
  snackBarType,
  snackBarMessage
});

export const deleteTemplateRowBegin = () => ({
  type: types.DELETE_TEMPLATE_ROW_BEGIN,
});

export const deleteTemplateRowSuccess = (productName, versionNum) => ({
  type: types.DELETE_TEMPLATE_ROW_SUCCESS,
  productName,
  versionNum
});

export const editTemplateRowBegin = () => ({
  type: types.EDIT_TEMPLATE_ROW_BEGIN,
});

export const editTemplateRowSuccess = (productName, versionNum) => ({
  type: types.EDIT_TEMPLATE_ROW_SUCCESS,
  productName,
  versionNum
});

export const editTemplateRowFailure = (productName, versionNum, error) => ({
  type: types.EDIT_TEMPLATE_ROW_FAILURE,
  productName,
  versionNum,
  error
});

export const deleteTemplateRowFailure = (productName, versionNum, error) => ({
  type: types.DELETE_TEMPLATE_ROW_FAILURE,
  productName,
  versionNum,
  error
});

export const getSoftwareOptionsBegin = () => ({
  type: types.GET_SOFTWARE_OPTIONS_BEGIN
});

export const getSoftwareOptionsSuccess = (softwareOptions) => ({
  type: types.GET_SOFTWARE_OPTIONS_SUCCESS,
  softwareOptions
});

export const getSoftwareOptionsFailure = (error) => ({
  type: types.GET_SOFTWARE_OPTIONS_FAILURE,
  error
});

export const getTemplatePackagesBegin = () => ({
  type: types.GET_TEMPLATE_PACKAGES_BEGIN
});

export const getTemplatePackagesSuccess = (packages) => ({
  type: types.GET_TEMPLATE_PACKAGES_SUCCESS,
  packages
});

export const getTemplatePackagesFailure = (error) => ({
  type: types.GET_TEMPLATE_PACKAGES_FAILURE,
  error
});

export const getReleaseTemplateBegin = () => ({
  type: types.GET_RELEASE_TEMPLATE_BEGIN
});

export const getReleaseTemplateSuccess = (releaseTemplate) => ({
  type: types.GET_RELEASE_TEMPLATE_SUCCESS,
  releaseTemplate
});

export const getReleaseTemplateFailure = (error) => ({
  type: types.GET_RELEASE_TEMPLATE_FAILURE,
  error
});

export const getReleaseTemplatesBegin = (loading) => ({
  type: types.GET_RELEASE_TEMPLATES_BEGIN,
  loading
});

export const getReleaseTemplatesSuccess = (releaseTemplates, pageCount, totalItems, pageSize) => ({
  type: types.GET_RELEASE_TEMPLATES_SUCCESS,
  releaseTemplates,
  pageCount,
  totalItems,
  pageSize
});

export const getReleaseTemplatesFailure = (error) => ({
  type: types.GET_RELEASE_TEMPLATES_FAILURE,
  error
});

export const setTemplatePackages = () => ({
  type: types.SET_TEMPLATE_PACKAGES
});

export const updateDeleteRow = (template, productName) => ({
  type: types.DELETE_CHECK,
  template,
  productName
});

export function addReleaseTemplate(templateObj) {
  const productName = templateObj.productName;
  delete templateObj.productName;
  delete templateObj.version;

  return (dispatch) => {
    dispatch(addTemplateRowBegin());

    return postSitelessObjectPromise(RELEASE_TEMPLATES, null, templateObj, false)
      .then((data) => {
        dispatch(clearTable());
        return dispatch(getReleaseTemplates({ page: 1, order: 'asc' }))
          .then(() => {
            const versionArr = data.split('-');
            dispatch(addTemplateRowSuccess(productName, versionArr[versionArr.length - 1]));
            return true;
          });
      }).catch((error) => {
        console.log(error);
        dispatch(addTemplateRowFailure(`${error.detail} (${error.status})`));
        return false;
      });
  };
}

export function editReleaseTemplate(templateObj, productID, version) {
  const productName = `${templateObj.productName}`;
  delete templateObj.productName;
  return (dispatch) => {
    dispatch(editTemplateRowBegin());

    return putSitelessObjectPromise(RELEASE_TEMPLATES, productID, templateObj)
      .then(() => {
        dispatch(clearTable());
        return dispatch(getReleaseTemplates({ page: 1, order: 'asc' }))
          .then(() => {
            dispatch(editTemplateRowSuccess(productName, version));
            return true;
          });
      }).catch((error) => {
        console.log(error);
        dispatch(editTemplateRowFailure(productName, version, `${error.detail} (${error.status})`));
        return false;
      });
  };
}

export const updateImmutableRow = (templateIndex, productName) => ({
  type: types.IMMUTABLE_CHECK,
  productName,
  templateIndex
});

export const makeTemplateImmutableBegin = () => ({
  type: types.MAKE_IMMUTABLE_BEGIN,
});

export const makeTemplateImmutableSuccess = (productName, versionNum) => ({
  type: types.MAKE_IMMUTABLE_SUCCESS,
  productName,
  versionNum
});

export const makeTemplateImmutableFailure = (productName, versionNum, error) => ({
  type: types.MAKE_IMMUTABLE_FAILURE,
  error,
  productName,
  versionNum
});

export function makeTemplateImmutable(index, productName) {
  return (dispatch) => {
    dispatch(makeTemplateImmutableBegin());

    const versionNum = JSON.parse(JSON.stringify(index.version));

    const id = encodeURIComponent(index.id);
    delete index.id;
    delete index.version;
    delete index.product;

    putSitelessObjectPromise(RELEASE_TEMPLATES, id, index)
      .then((template) => {
        if (!httpSuccess(template.status)) {
          return dispatch(makeTemplateImmutableFailure(template.status));
        }
        dispatch(clearTable());
        dispatch(getReleaseTemplates({ page: 1, order: 'asc' }))
          .then(() => {
            dispatch(makeTemplateImmutableSuccess(productName, versionNum));
          });
      }).catch((error) => {
        console.log(error);
        dispatch(makeTemplateImmutableFailure(productName, versionNum, error));
      });
  };
}

export function deleteTemplateById(id, productName, versionNum, sort) {
  return (dispatch) => {
    dispatch(deleteTemplateRowBegin());

    deleteSitelessObjectPromise(RELEASE_TEMPLATES, id)
      .then(() => {
        dispatch(clearTable());
        dispatch(getReleaseTemplates(sort))
          .then(() => {
            dispatch(deleteTemplateRowSuccess(productName, versionNum));
          });
      }).catch((error) => {
        dispatch(deleteTemplateRowFailure(productName, versionNum, `${error.status}`));
      });
  };
}

export function getReleaseTemplates(filter) {
  return (dispatch) => {
    dispatch(getReleaseTemplatesBegin(filter.page === 1));
    return getSitelessObjectPromise(RELEASE_TEMPLATES, null, filter)
      .then((data) => {
        dispatch(getReleaseTemplatesSuccess(data.templates, data.page_count, data.total_items, data.page_size));
        return data.templates;
      })
      .catch((error) => {
        dispatch(getReleaseTemplatesFailure(error));
        return error;
      });
  };
}

export function getReleaseTemplate(templateID) {
  return (dispatch) => {
    dispatch(getReleaseTemplateBegin());
    getSitelessObjectPromise(RELEASE_TEMPLATES, templateID)
      .then((data) => {
        dispatch(getReleaseTemplateSuccess(data.template));
        return true;
      })
      .catch((error) => {
        dispatch(getReleaseTemplateFailure(error));
        return error;
      });
  };
}

export function getSoftwareOptions() {
  return (dispatch) => {
    dispatch(getSoftwareOptionsBegin());

    return getSitelessObjectPromise(SOFTWARE_OPTIONS)
      .then((softwareOptions) => {
        dispatch(getSoftwareOptionsSuccess(softwareOptions));
        return softwareOptions;
      }).catch((error) => {
        try {
          error.json().then((err) => {
            dispatch(getSoftwareOptionsFailure(`${err.detail} (${err.status})`));
            return null;
          });
        } catch(err) {
          dispatch(getSoftwareOptionsFailure(`${err.detail} (${err.status})`));
          return null;
        }
      });
  };
}

export function getTemplatePackages(virtos) {
  // This is a paginated endpoint, so fetch all the pages before dispatching a "Success"
  return (dispatch) => {
    dispatch(getTemplatePackagesBegin());
    getSitelessObjectPromise(SOFTWARE_PACKAGES, null, { order: 'asc', virtos, limit: MAX_SIZE })
      .then((results) => {
        dispatch(getTemplatePackagesSuccess(results.packages));
      })
      .catch((error) => {
        try {
          error.json().then((err) => {
            dispatch(getTemplatePackagesFailure(`${err.detail} (${err.status})`));
          });
        } catch(err) {
          dispatch(getTemplatePackagesFailure(`${err.detail} (${err.status})`));
        }
      });
  };
}

export const updateSelectedPackageRow = (index, editType) => ({
  type: types.UPDATE_SELECTED_PACKAGE_ROW,
  index,
  editType
});

export const updateSelectedReleaseTemplate = (index, editType) => ({
  type: types.UPDATE_SELECTED_RELEASE_TEMPLATE,
  index,
  editType
});

export const updateTemplatePackages = (packages) => ({
  type: types.UPDATE_TEMPLATE_PACKAGES,
  packages
});
