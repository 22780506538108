import { TERM_FIRMWARE } from '../../../constants/apiEndpoints';
import { getObjectPromise } from '../../../utils/api';
import { BROWSER, CPU, PTC } from '../constants/TermFileConstants';

export function validTLX(tlx, translations) {
  const errors = {};

  if (!tlx.name || tlx.name === '' || tlx.name.length === 0 || tlx.name === 'add') {
    errors.name = translations.noFile;
  }

  return errors;
}

export function allowUpload(filePath) {
  if (!filePath.match(/^[A-Za-z0-9_-]+.tlx$/)) {
    return false;
  } else {
    return true;
  }
}

export function validSONIFIFirmware(firmware, translations, unconfigureString) {
  const errors = {};
  const promises = [];

  if (!firmware.firmware) {
    errors.vFirmware = translations.noSelection;
  } else if (firmware.firmware === unconfigureString) {
    return Promise.resolve(errors);
  }

  promises.push(getObjectPromise(TERM_FIRMWARE, firmware.firmware));
  return Promise.all(promises)
    .then((data) => {
      if (data[0] && data[0].corrupted) {
        errors.vFirmware = translations.corruptedFile;
      }
      return errors;
    })
    .catch((error) => {
      console.log('SONIFI - Error in executing', error);
      errors.vFirmware = translations.general;
      return errors;
    });
}

export function validLGFirmware(firmware, translations) {
  const errors = {};
  const promises = [];
  const cpuPtc = [];

  if (firmware.firmware.CPU) {
    // errors.cpu = translations.needCPU;
    // } else {
    cpuPtc.push(CPU.toLowerCase());
    promises.push(getObjectPromise(TERM_FIRMWARE, firmware.firmware.CPU));
  }

  if (firmware.firmware.PTC) {
    // errors.ptc = translations.needPTC;
    cpuPtc.push(PTC.toLowerCase());
    promises.push(getObjectPromise(TERM_FIRMWARE, firmware.firmware.PTC));
  }

  if (firmware.firmware.BROWSER) {
    // errors.ptc = translations.needPTC;
    cpuPtc.push(BROWSER.toLowerCase());
    promises.push(getObjectPromise(TERM_FIRMWARE, firmware.firmware.BROWSER));
  }

  if (promises.length === 0) {
    return Promise.resolve(errors);
  }

  return Promise.all(promises)
    .then((data) => {
      if (data[0] && data[0].corrupted) {
        errors[cpuPtc[0]] = translations.corruptedFile;
      }

      if (data[1] && data[1].corrupted) {
        errors[cpuPtc[1]] = translations.corruptedFile;
      }

      if (data[2] && data[2].corrupted) {
        errors[cpuPtc[2]] = translations.corruptedFile;
      }
      return errors;
    })
    .catch((error) => {
      console.log('LG - Error in executing', error);
      errors.vFirmware = translations.general;
      return errors;
    });
}

export function validSamsungFirmware(firmware, translations) {
  const errors = {};
  const promises = [];

  if (firmware.firmware.BEM) {
    promises.push(getObjectPromise(TERM_FIRMWARE, firmware.firmware.BEM));
  }

  if (firmware.firmware.CLONE) {
    promises.push(getObjectPromise(TERM_FIRMWARE, firmware.firmware.CLONE));
  }

  if (firmware.firmware.CLIENT) {
    promises.push(getObjectPromise(TERM_FIRMWARE, firmware.firmware.CLIENT));
  }

  if (promises.length === 0) {
    return Promise.resolve(errors);
  }

  return Promise.all(promises)
    .then((data) => {
      if (data[0] && data[0].corrupted) {
        errors.firmware = translations.corruptedFile;
      }
      return errors;
    })
    .catch((error) => {
      console.log('Samsung - Error in executing', error);
      errors.firmware = translations.general;
      return errors;
    });
}
