import { ROOM_RESOURCE } from '../../../constants/apiEndpoints';
import { getSiteObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

export const filterRoomsBegin = (products) => ({
  type: types.FILTER_ROOMS_BEGIN,
  products
});

export const filterRoomsSuccess = (products) => ({
  type: types.FILTER_ROOMS_SUCCESS,
  products
});

export const filterRoomsFailure = (error) => ({
  type: types.FILTER_ROOMS_FAILURE,
  error
});

export function filterRooms(filterObj) {
  return (dispatch) => {
    dispatch(filterRoomsBegin(filterObj));

    return getSiteObjectPromise(ROOM_RESOURCE, null, null, filterObj)
      .then((json) => {
        dispatch(filterRoomsSuccess(json));
        return json;
      }).catch((error) => {
        dispatch(filterRoomsFailure(error));
      });
  };
}
