import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../containers/SonifiLabel';
import { showAuditInfo } from '../actions/auditActions';

class AuditRow extends Component {
  handleViewDialog() {
    const { dispatch, rowIndex } = this.props;
    dispatch(showAuditInfo(rowIndex));
  }

  render() {
    const { auditRow } = this.props;

    return (
      <TableRow onClick={this.handleViewDialog.bind(this)}>
        <TableCell component="th" scope="row">
          <SonifiLabel label={auditRow.username} />
        </TableCell>
        <TableCell component="th" scope="row">
          <SonifiLabel label={`${moment(auditRow.timestamp).format('MM/DD/YYYY hh:mm a')}`} />
        </TableCell>
        <TableCell component="th" scope="row">
          <SonifiLabel label={`${auditRow.method}`} />
        </TableCell>
        <TableCell component="th" scope="row">
          <SonifiLabel label={`${decodeURIComponent(auditRow.path)}`} />
        </TableCell>
      </TableRow>
    );
  }
}

const mapStateToProps = () => ({});

AuditRow.propTypes = {
  auditRow: PropTypes.object,
  dispatch: PropTypes.func,
  rowIndex: PropTypes.number.isRequired
};


export default connect(mapStateToProps)(AuditRow);
