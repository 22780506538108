import { Button } from '@aws-amplify/ui-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class SonifiUnderlineButton extends Component {
  render() {
    const { disabled, label, onClick } = this.props;
    return (
      <Button
        className="amplify-button amplify-field-group__control"
        color="secondary"
        data-fullwidth="false"
        data-size="small"
        data-variation="link"
        disabled={disabled}
        onClick={onClick}
        style={{ fontWeight: 'normal' }}
      >
        {label}
      </Button>
    );
  }
}
SonifiUnderlineButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default SonifiUnderlineButton;
