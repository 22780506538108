export const INIT_SOFTWARE_CHANGES = 'INIT_SOFTWARE_CHANGES';
export const PARSE_SOFTWARE_BEGIN = 'PARSE_SOFTWARE_BEGIN';
export const SAVE_BATCH_SOFTWARE_CHANGES_FAILURE = 'SAVE_BATCH_SOFTWARE_CHANGES_FAILURE';
export const SAVE_SOFTWARE_CHANGES_BEGIN = 'SAVE_SOFTWARE_CHANGES_BEGIN';
export const SAVE_SOFTWARE_CHANGES_FAILURE = 'SAVE_SOFTWARE_CHANGES_FAILURE';
export const SAVE_SOFTWARE_CHANGES_SUCCESS = 'SAVE_SOFTWARE_CHANGES_SUCCESS';
export const SHOW_POPUP = 'SHOW_POPUP';
export const UPDATE_SOFTWARE_CHANGES_SNACKBAR = 'UPDATE_SOFTWARE_CHANGES_SNACKBAR';
export const UPDATE_SOFTWARE_TABLE = 'UPDATE_SOFTWARE_TABLE';
export const UPDATE_TABLE_RESPONSE = 'UPDATE_TABLE_RESPONSE';
