import {
  ArrowDropDown, ArrowRight, CheckBox, CheckBoxOutlineBlank,
  IndeterminateCheckBox
} from '@mui/icons-material';
import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import Tree, { TreeNode } from 'rc-tree';
import React, { Component, Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import SonifiError from '../../SonifiError';
import SonifiText from '../../SonifiText';

const styles = (theme) => ({
  bold: { fontWeight: 'bold', color: theme.palette.secondary.main },
  disabled: { color: theme.palette.primary.border },
  error: { color: 'red' },
  regular: { color: theme.palette.secondary.main },
  search: { height: '25%' },
  tree: { height: '75%', overflow: 'auto' }
});

class RcTree extends Component {
  state = {
    inputValue: '',
    expandedKeys: [],
    autoExpandParent: true,
    checkedKeys: this.props.keys || [],
    halfCheckedKeys: this.props.halfChecked || [],
    isLoading: true,
    changed: false
  };

  componentDidMount() {
    this.setState({ expandedKeys: this.props.autoExpandKeys, isLoading: false });
  }

  onChange = (event) => {
    this.setState({ inputValue: event.target.value }, () => {
      this.debouncedLoadMoreData();
    });
  };

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  filterData() {
    this.filterKeys = (this.state.inputValue.length === 0 ? undefined : []);
    this.setState({ changed: !this.state.changed });
  }

  onCheck = (checkedKeys, info) => {
    const { onChange, updateCheckedPos } = this.props;
    this.setState({
      checkedKeys: checkedKeys.sort(),
      halfCheckedKeys: info.halfCheckedKeys.sort()
    });

    if (updateCheckedPos && !isNaN(updateCheckedPos)) {
      const checkArr = [];
      for (let i = 0; i < info.checkedNodesPositions.length; i++) {
        if (info.checkedNodesPositions[i].pos.split('-').length === updateCheckedPos) {
          checkArr.push(info.checkedNodesPositions[i].node.key);
        }
      }
      onChange(checkArr);
    } else {
      onChange(checkedKeys);
    }
  };

  onExpand = (expandedKeys) => {
    this.filterKeys = undefined;
    this.setState({
      expandedKeys,
      autoExpandParent: false
    });
  };

  filterTreeNode = (treeNode) => this.filterFn(treeNode.props.eventKey);

  filterFn = (key) => {
    if (!key) {
      return;
    }

    if (this.state.inputValue === '' || this.state.inputValue.length === 0) {
      return true;
    }

    const a = key.toLowerCase();
    const b = this.state.inputValue.toLowerCase();

    if (this.state.inputValue && a.indexOf(b) > -1) {
      return true;
    }
    return false;
  };

  loop = (data) => data.map((item) => {
    if (this.filterKeys && this.filterFn(item.key)) {
      this.filterKeys.push(item.key);
    }

    const { classes, errorMessage } = this.props;
    let errorColor = (errorMessage && errorMessage.length > 0 ? classes.error : classes.regular);
    if (errorMessage && errorMessage.length > 0) {
      errorColor = classes.error;
    } else if (this.props.disabled) {
      errorColor = classes.disabled;
    }
    const showBoldText = this.state.inputValue.length > 0 &&
      item.title.toLowerCase().includes(this.state.inputValue.toLowerCase());

    if (item.treeData) {
      return <TreeNode key={item.key} title={item.title}
        className={(showBoldText ? classes.bold : '')}
        switcherIcon={(props) => {
          if (props.expanded) {
            return <ArrowDropDown className={errorColor} />;
          }
          return <ArrowRight className={errorColor} />;
        }}
        icon={(props) => {
          if (props.halfChecked) {
            return <IndeterminateCheckBox className={errorColor} />;
          } else if (props.checked) {
            return <CheckBox className={errorColor} />;
          }
          return <CheckBoxOutlineBlank className={errorColor} />;
        }}
      >
        {this.loop(item.treeData)}</TreeNode>;
    }

    return <TreeNode key={item.key} title={item.title}
      className={(showBoldText ? classes.bold : '')}
      icon={(props) => {
        if (props.halfChecked) {
          return <IndeterminateCheckBox className={errorColor} />;
        } else if (props.checked) {
          return <CheckBox className={errorColor} />;
        }
        return <CheckBoxOutlineBlank className={errorColor} />;
      }}
    />;
  });

  render() {
    const { classes, disabled, errorMessage } = this.props;
    const { isLoading } = this.state;

    if (isLoading) {
      return <Fragment />;
    }

    this.state.checkedKeys.sort();

    if (!this.props.nodes || this.props.nodes.length < 1) {
      return <SonifiError label="There was an error with the site hierarchy." />;
    }

    let expandedKeys = this.state.expandedKeys,
      autoExpandParent = this.state.autoExpandParent;
    if (this.filterKeys) {
      expandedKeys = this.filterKeys;
      autoExpandParent = true;
    }

    return (
      <Grid container>
        <Grid item xs={12} className={classes.search}>
          <SonifiText
            label="Search..."
            defaultValue={this.state.inputValue}
            size="sm"
            change={this.onChange}
            helperText={errorMessage || ''}
          />
        </Grid>
        <Grid item xs={12} className={classes.tree}>
          <Tree
            checkable
            selectable={false}
            disabled={disabled}
            onExpand={this.onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={this.onCheck}
            filterTreeNode={this.filterTreeNode}
            checkedKeys={this.state.checkedKeys}
            className={classes.treeWidth}
          >
            {this.loop(this.props.nodes)}
          </Tree>
        </Grid>
      </Grid>
    );
  }
}

RcTree.propTypes = {
  autoExpandKeys: PropTypes.array,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  halfChecked: PropTypes.array,
  keys: PropTypes.array,
  nodes: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  updateCheckedPos: PropTypes.number
};

export default withStyles(RcTree, styles, { withTheme: true });
