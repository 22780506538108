export const NEW_GROUP = {
  arrival: null,
  channels: {
    add: [],
    remove: [],
    power_on: 1
  },
  codes: [],
  departure: null,
  id: null,
  isNew: true,
  language: 'en',
  menuset: 0,
  name: '',
  rooms: [],
  type: 'conference'
};

export const GROUP_SERVICE_TYPES = [
  { label: 'Conference', value: 'conference' },

  // { label: 'Loyalty', value: 'loyalty' },
  // { label: 'VIP', value: 'VIP' }
];

export const CHANNELS = 'channels';
export const MESSAGES = 'messages';
export const CODES = 'codes';
export const ROOMS = 'rooms';
