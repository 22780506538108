import { findIndex, uniq } from 'lodash';
import { displaySnackBar, patchSite } from '../../../actions/globalActions';
import { CHANNEL_OPTIONS, OPTIONS_RESOURCE, SITES, TERMINAL_OPTIONS } from '../../../constants/apiEndpoints';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import { getSiteNumFromURI } from '../../../utils';
import { getSiteObjectPromise, patchObjectPromise, putObjectPromise } from '../../../utils/api';
import { getIpgPromise, putIpgPromise } from '../../../utils/ipg';
import { getTieObjectPromise, patchTieObjectPromise } from '../../../utils/tieApi';
import { fetchRoomsOptions } from '../../Rooms/actions/roomActions';
import { getTerminalOptions, patchTerminalOptions } from '../../Terminals/actions/terminalActions';
import { ANALOG_RF, DIGITAL_RF, IPTV } from '../constants/ChannelTypes';
import * as types from './actionTypes';

export const closeSnackBar = () => ({
  type: types.CLOSE_SNACK_BAR
});

export const updateSnackBar = (snackbarType, message) => ({
  type: types.UPDATE_SNACK_BAR,
  message,
  snackbarType
});

export const fetchChannelOptionsBegin = () => ({
  type: types.FETCH_CHANNEL_OPTIONS_BEGIN
});

export const fetchChannelOptionsSuccess = (channelOptions) => ({
  type: types.FETCH_CHANNEL_OPTIONS_SUCCESS,
  channelOptions
});

export const fetchChannelOptionsFailure = (error) => ({
  type: types.FETCH_CHANNEL_OPTIONS_FAILURE,
  error
});

export function fetchChannelOptions() {
  return (dispatch) => {
    dispatch(fetchChannelOptionsBegin());
    return getSiteObjectPromise(CHANNEL_OPTIONS)
      .then((json) => {
        dispatch(fetchChannelOptionsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchChannelOptionsFailure(error)));
  };
}

export const updateRoomTypeBegin = () => ({
  type: types.UPDATE_ROOM_TYPES_BEGIN
});

export const updateRoomTypeSuccess = () => ({
  type: types.UPDATE_ROOM_TYPES_SUCCESS
});

export const updateRoomTypeError = (error) => ({
  type: types.UPDATE_ROOM_TYPES_ERROR,
  error
});

export const updateRoomTypeFailure = (error) => ({
  type: types.UPDATE_ROOM_TYPES_FAILURE,
  error
});

export const updateRoomTypes = (roomTypes) => (dispatch) => {
  dispatch(updateRoomTypeBegin());
  const rTypes = JSON.parse(JSON.stringify(roomTypes));
  const submitObj = { room_types: rTypes };

  patchObjectPromise(OPTIONS_RESOURCE, null, submitObj)
    .then((json) => {
      if (!httpSuccess(json.status)) {
        console.log('ERROR: Saving Room Types: ', json);
        dispatch(updateRoomTypeFailure(json.status));
      } else {
        dispatch(updateRoomTypeSuccess());
      }
      return json;
    }).catch((error) => {
      console.log('ERROR: Saving Room Types: ', error);
      dispatch(updateRoomTypeFailure(error.status));
    });
};

export const updateSiteManagementGeneralBegin = () => ({
  type: types.UPDATE_SITE_MANAGEMENT_GENERAL_BEGIN
});

export const updateSiteManagementGeneralEnd = () => ({
  type: types.UPDATE_SITE_MANAGEMENT_GENERAL_END
});

export const updateSiteManagementGeneralStatus = (status) => ({
  type: types.UPDATE_SITE_MANAGEMENT_GENERAL_STATUS,
  status
});

export const updateTerminalLocationBegin = () => ({
  type: types.UPDATE_TERM_LOC_BEGIN
});

export const updateTerminalLocationSuccess = () => ({
  type: types.UPDATE_TERM_LOC_SUCCESS
});

export const updateTerminalLocationFailure = (error) => ({
  type: types.UPDATE_TERM_LOC_FAILURE,
  error
});

export function updateTerminalLocations(termLocations) {
  return (dispatch) => {
    dispatch(updateTerminalLocationBegin());

    const terms = JSON.parse(JSON.stringify(termLocations));
    terms.map((location, index) => {
      location.sequence = index;
      delete location.count;
      return location;
    });

    const submitObj = {
      locations: terms
    };

    patchObjectPromise(TERMINAL_OPTIONS, null, submitObj)
      .then((json) => {
        if (!httpSuccess(json.status)) {
          console.log('ERROR: Saving Terminal Locations: ', json);
          dispatch(updateTerminalLocationFailure(json.status));
        } else {
          dispatch(updateTerminalLocationSuccess());
        }
        return json;
      }).catch((error) => {
        console.log('ERROR: Saving Terminal Locations: ', error);
        dispatch(updateTerminalLocationFailure(error.status));
      });
  };
}

// export const updateTermLocationSeq = (oldVal, newVal) => ({
//   type: types.UPDATE_TERM_LOC_SEQUENCE,
//   oldVal,
//   newVal
// });

// export const updateItemLocationSeq = (parentLoc, oldVal, newVal) => ({
//   type: types.UPDATE_ITEM_LOC_SEQUENCE,
//   parentLoc,
//   oldVal,
//   newVal
// });
export const updateData = (name, value) => ({
  type: types.UPDATE_SITE_DATA,
  name,
  value
});

// export const updateNestedValue = (nested, name, value) => ({
//   type: types.UPDATE_NESTED_SITE_DATA,
//   nested,
//   name,
//   value
// });

// export const updateSelectedItem = (value) => ({
//   type: types.UPDATE_SELECTED_ITEM,
//   value
// });

// export const updateSelectedTermLocation = (value) => ({
//   type: types.UPDATE_SELECTED_TERM_LOCATION,
//   value
// });

// export const addTermLocation = () => ({
//   type: types.ADD_TERM_LOCATION
// });

// export const addAvailableLocation = (parentLoc) => ({
//   type: types.ADD_AVAILABLE_LOCATION,
//   parentLoc
// });

export const updateDeleteRow = (deleteType, buildingIndex, floorIndex, itemName) => ({
  type: types.DELETE_CHECK,
  deleteType,
  buildingIndex,
  floorIndex,
  itemName
});

// export const deleteAvailableLocation = (parentLoc, itemId) => ({
//   type: types.DELETE_AVAILABLE_LOCATION,
//   parentLoc,
//   itemId
// });

// export const deleteTermLocation = (itemId) => ({
//   type: types.DELETE_TERM_LOCATION,
//   itemId
// });

// export const updateTermLocation = (termId) => ({
//   type: types.UPDATE_TERM_LOCATION,
//   termId
// });

// export const updateAvailableLocation = (itemId) => ({
//   type: types.UPDATE_AVAILABLE_LOCATION,
//   itemId
// });

// export const editSelectedItem = (id, value, parentLoc) => ({
//   type: types.EDIT_SELECTED_ITEM,
//   id,
//   value,
//   parentLoc
// });

// export const editSelectedTermLocation = (id, value) => ({
//   type: types.EDIT_SELECTED_TERM_LOCATION,
//   id,
//   value
// });

// export const setDroppedRow = (rowIndex) => ({
//   type: types.SET_DROPPED_TERM_LOC_ROW,
//   rowIndex
// });

// export const setDroppedItemRow = (rowIndex, parentLoc) => ({
//   type: types.SET_DROPPED_ITEM_ROW,
//   rowIndex,
//   parentLoc
// });

export const fetchRoomOptionsBegin = () => ({
  type: types.FETCH_ROOM_OPTIONS_CONFIG_BEGIN
});

export const fetchRoomOptionsSuccess = (roomOptions) => ({
  type: types.FETCH_ROOM_OPTIONS_CONFIG_SUCCESS,
  roomOptions
});

export const fetchRoomOptionsFailure = (error) => ({
  type: types.FETCH_ROOM_OPTIONS_CONFIG_FAILURE,
  error
});

export const fetchRoomOptions = () => (dispatch) => {
  dispatch(fetchRoomOptionsBegin());
  return getSiteObjectPromise(OPTIONS_RESOURCE)
    .then((json) => {
      dispatch(fetchRoomOptionsSuccess(json));
      return json;
    })
    .catch((error) => dispatch(fetchRoomOptionsFailure(error)));
};

export const saveRoomOptions = () => ({
  type: types.SAVE_ROOM_OPTIONS
});

export const saveRoomOptionsSuccess = (data) => ({
  type: types.SAVE_ROOM_OPTIONS_SUCCESS,
  data
});

export const saveRoomOptionsFailure = (error) => ({
  type: types.SAVE_ROOM_OPTIONS_FAILURE,
  error
});

export const resetHierarchy = () => ({
  type: types.RESET_HIERARCHY_VARS
});

// https://app-api.cca-devel.sonifi.cloud/v1/sites/0000054/room-options
export function saveAvailableLocations(submitObj) {
  return (dispatch) => {
    dispatch(saveRoomOptions());
    return putObjectPromise(OPTIONS_RESOURCE, null, submitObj)
      .then((json) => {
        if (!httpSuccess(json.status)) {
          return dispatch(saveRoomOptionsFailure(json));
        }

        dispatch(fetchRoomsOptions())
          .then(() => {
            dispatch(saveRoomOptionsSuccess(json));
          }).catch((error) => {
            dispatch(saveRoomOptionsSuccess(json));
            return error;
          });

        return json;
      }).catch((error) => {
        dispatch(saveRoomOptionsFailure(error));
        return error;
      });
  };
}

export const updateIpNetwork = (value) => ({
  type: types.UPDATE_IP_NETWORK,
  value
});

export const saveIpNetworkBegin = () => ({
  type: types.SAVE_IP_NETWORK_BEGIN
});

export const saveIpNetworkSuccess = () => ({
  type: types.SAVE_IP_NETWORK_SUCCESS
});

export const saveIpNetworkFailure = (error) => ({
  type: types.SAVE_IP_NETWORK_FAILURE,
  error
});

export function saveIpNetwork(ipObject) {
  if (ipObject.iptvNetwork.layer3_enabled) {
    delete ipObject.iptvNetwork.dhcp;
  }

  return (dispatch) => {
    dispatch(saveIpNetworkBegin());
    dispatch(patchSite('iptv_network', ipObject.iptvNetwork));
    dispatch(patchTerminalOptions({ networks_used: { ...ipObject.networksUsed } }))
      .then((json) => {
        const siteNum = getSiteNumFromURI();
        dispatch(getTerminalOptions(siteNum));
        return json;
      })
      .catch((error) => {
        console.log('Site Management:error', error);
        return error;
      });
  };
}

export const resetGlobalSnackBar = () => ({
  type: types.RESET_GLOBAL_SNACKBAR
});

export const updateChannelDelivery = (name, value) => ({
  type: types.UPDATE_CHANNEL_OPTIONS,
  name,
  value
});

export const updateChannelOptionsBegin = () => ({
  type: types.UPDATE_CHANNEL_OPTIONS_BEGIN
});

export const updateChannelOptionsSuccess = () => ({
  type: types.UPDATE_CHANNEL_OPTIONS_SUCCESS
});

export const updateChannelOptionsFailure = (error) => ({
  type: types.UPDATE_CHANNEL_OPTIONS_FAILURE,
  error
});

export function updateChannelOptions(channelOptions, ipg) {
  return (dispatch) => {
    dispatch(updateSiteManagementGeneralBegin());

    const submitObj = {
      rf: {
        analog: getChannelOptionValue(channelOptions, ANALOG_RF),
        digital: getChannelOptionValue(channelOptions, DIGITAL_RF)
      },
      ip: getChannelOptionValue(channelOptions, IPTV),
      ipg
    };

    putObjectPromise(CHANNEL_OPTIONS, null, submitObj)
      .then(() => {
        dispatch(updateChannelOptionsSuccess());
      }).catch((error) => {
        error.json().then((err) => {
          dispatch(updateChannelOptionsFailure(err));
        });
      });
  };
}

function getChannelOptionValue(options, name) {
  const pos = findIndex(options, { name });
  return options[pos].checked;
}

export const updateChromecastPortBegin = () => ({
  type: types.UPDATE_CHROMECAST_PORT_BEGIN
});

export const updateChromecastPortSuccess = () => ({
  type: types.UPDATE_CHROMECAST_PORT_SUCCESS
});

export const updateChromecastPortFailure = (error) => ({
  type: types.UPDATE_CHROMECAST_PORT_FAILURE,
  error
});

export function updateChromecastPort(chromecastPort) {
  return (dispatch) => {
    dispatch(updateChromecastPortBegin());

    const submitObj = {
      chromecast_port: chromecastPort
    };

    patchObjectPromise(TERMINAL_OPTIONS, null, submitObj)
      .then(() => {
        dispatch(updateChromecastPortSuccess());
      }).catch((error) => {
        error.json().then((err) => {
          dispatch(updateChromecastPortFailure(err));
        });
      });
  };
}

export const updateChangeVariable = (changed) => ({
  type: types.CHANGE_VARIABLE,
  changed
});

export function updateSiteClientConfig(clientType, value) {
  const clientConfigObj = {};
  clientConfigObj[clientType] = value;
  return (dispatch) => {
    patchTieObjectPromise(clientConfigObj)
      .then((data) => {
        if (!httpSuccess(data.status)) {
          dispatch(updateSiteClientConfigFailure());
          return data;
        }
        dispatch(updateSiteClientConfigSuccess(data));
        return data;
      })
      .catch((error) => {
        dispatch(updateSiteClientConfigFailure());
        return error;
      });
  };
}

export const updateSiteClientConfigSuccess = () => ({
  type: types.UPDATE_SITE_CLIENT_CONFIG_SUCCESS
});

export const updateSiteClientConfigFailure = () => ({
  type: types.UPDATE_SITE_CLIENT_CONFIG_FAILURE
});

export const fetchSiteConfigBegin = () => ({
  type: types.FETCH_SITE_CONFIG_BEGIN
});

export const fetchSiteConfigSuccess = (siteConfigs) => ({
  type: types.FETCH_SITE_CONFIG_SUCCESS,
  siteConfigs
});

export const fetchSiteConfigFailure = () => ({
  type: types.FETCH_SITE_CONFIG_FAILURE
});

export function fetchSiteConfigs() {
  return (dispatch) => {
    dispatch(fetchSiteConfigBegin());
    return getTieObjectPromise()
      .then((json) => {
        dispatch(fetchSiteConfigSuccess(json));
        return json;
      })
      .catch(() => dispatch(fetchSiteConfigFailure()));
  };
}


export const getMarinaMobileInfoBegin = () => ({
  type: types.GET_MARINA_MOBILE_BEGIN
});

export const getMarinaMobileInfoSuccess = (siteConfigs) => ({
  type: types.GET_MARINA_MOBILE_SUCCESS,
  siteConfigs
});

export const getMarinaMobileInfoFailure = () => ({
  type: types.GET_MARINA_MOBILE_FAILURE
});

export function getMarinaMobileInfo() {
  return (dispatch) => {
    dispatch(getMarinaMobileInfoBegin());
    return getIpgPromise(`${SITES}/${getSiteNumFromURI()}`)
      .then((json) => {
        dispatch(getMarinaMobileInfoSuccess(json));
        return json;
      })
      .catch(() => dispatch(getMarinaMobileInfoFailure()));
  };
}

export const updateMarinaMobileInfoBegin = () => ({
  type: types.UPDATE_MARINA_MOBILE_BEGIN
});

export const updateMarinaMobileInfoSuccess = (siteConfigs) => ({
  type: types.UPDATE_MARINA_MOBILE_SUCCESS,
  siteConfigs
});

export const updateMarinaMobileInfoFailure = () => ({
  type: types.UPDATE_MARINA_MOBILE_FAILURE
});

export function updateMarinaMobileInfo(submitObj) {
  return (dispatch) => {
    dispatch(updateMarinaMobileInfoBegin());
    return putIpgPromise(`${SITES}/${getSiteNumFromURI()}`, null, submitObj)
      .then((json) => {
        dispatch(updateMarinaMobileInfoSuccess(json));
        return json;
      })
      .catch(() => dispatch(updateMarinaMobileInfoFailure()));
  };
}

export const updateHL7ConfigBegin = () => ({
  type: types.UPDATE_HL7_CONFIG_BEGIN
});

export const updateHL7ConfigComplete = () => ({
  type: types.UPDATE_HL7_CONFIG_COMPLETE
});

export function updateHL7Config(hl7Config) {
  return (dispatch) => {
    dispatch(updateHL7ConfigBegin());

    if (hl7Config.subnets && hl7Config.subnets.length > 0 && !Array.isArray(hl7Config.subnets)) {
      const ipString = hl7Config.subnets.replace(/\n+/g, ',');
      const ipWhitespaceArray = ipString.replace(/\s+/g, '');
      const ipArray = ipWhitespaceArray.split(',');
      const commaArray = ipArray.filter((val) => val !== '');
      hl7Config.subnets = uniq(commaArray);
    }

    delete (hl7Config.errors);

    return dispatch(patchSite('hl7', hl7Config))
      .then((json) => {
        let errorMsg = '';
        if (!httpSuccess(json.status)) {
          return json.json()
            .then((err) => {
              for (const name in err.detail.hl7.subnets) {
                errorMsg += `${hl7Config.subnets[name]} - ${err.detail.hl7.subnets[name]} \n`;
              }
              dispatch(displaySnackBar(ERROR,
                `Unable to save new channel replacement - (${json.status}) \n ${errorMsg}`));

              dispatch(updateHL7ConfigComplete());
              return errorMsg;
            });
        } else {
          dispatch(displaySnackBar(SUCCESS, 'Successfully saved HL7 configuration'));
        }
        dispatch(updateHL7ConfigComplete());
        return json;
      })
      .catch((error) => {
        error.json()
          .then((err) => {
            dispatch(updateHL7ConfigComplete());
            dispatch(displaySnackBar(ERROR,
              `Unable to save new channel replacement - ${err.detail} (${err.status}))`));
          });

        return error;
      });
  };
}
