import React from 'react';
import SonifiCheckbox from '../containers/SonifiCheckbox';
import SonifiText from '../containers/SonifiText';

/* eslint-disable max-params */
export function getLgTextBox(
  label, defaultValue, name, changeFunc,
  errorText = '', editable = true, type = 'string', size = 'lg',
  onBlur = null
) {
  return (
    <SonifiText
      change={changeFunc}
      defaultValue={defaultValue}
      disabled={!editable}
      error={errorText.length > 0}
      errorText={errorText}
      label={label}
      onBlur={onBlur}
      size={size}
      type={type}
    />
  );
}

export function getLgDropDown(
  label, defaultValue, name, items, changeFunc,
  errorText = '', editable = true
) {
  return (
    <SonifiText
      change={changeFunc}
      defaultValue={defaultValue}
      disabled={!editable}
      error={errorText.length > 0}
      errorText={errorText}
      items={items}
      label={label}
      select={true}
      size="lg"
    />
  );
}

export function getDropDown(
  label, defaultValue, name, items, changeFunc,
  errorText = '', editable = true, size = 'lg'
) {
  return (
    <SonifiText
      change={changeFunc}
      defaultValue={defaultValue}
      disabled={!editable}
      error={errorText.length > 0}
      errorText={errorText}
      items={items}
      label={label}
      select={true}
      size={size}
    />
  );
}

export function getCheckBox(label, defaultVal, changeFunc, editable = true) {
  return (
    <SonifiCheckbox
      disabled={!editable}
      label={label}
      onChange={changeFunc}
      value={defaultVal}
    />
  );
}
