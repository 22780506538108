import { TableBody } from '@aws-amplify/ui-react';
import { Autorenew } from '@mui/icons-material';
import { Table, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { DISTANCE_FROM_END } from '../../constants/magic';
import { SONIFI_ROLES } from '../../constants/roleGroups';
import { SONIFI_ADMIN } from '../../constants/roles';
import {
  SonifiConfirm, SonifiLabel, SonifiLockoutModalSpinner, SonifiSpinner, SonifiTableHead
} from '../../containers';
import SonifiTemplate from '../../containers/SonifiTemplate';
import { buildSortString } from '../../utils';
import { isTesting } from '../../utils/api';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';
import * as actions from './actions/batchChannelsAction';
import BatchRow from './components/batchRow';
import NewBatchDialog from './components/NewBatchDialog';
import ViewBatchDialog from './components/ViewBatchDialog';

class BatchChannels extends Component {
  constructor(props) {
    super(props);
    this.addChannelChange = this.addChannelChange.bind(this);
    this.cancelChannelChange = this.cancelChannelChange.bind(this);
    this.stopInProgressJob = this.stopInProgressJob.bind(this);
    this.universalCancel = this.universalCancel.bind(this);

    this.state = {
      open: false,
      page: 1,
      limit: 20
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.resetBatchChannels());
    dispatch(actions.getChannelChanges(buildSortString(this.state)));
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(actions.getChannelChanges(buildSortString(this.state)));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(actions.getChannelChanges(buildSortString(this.state)));
    }
  }

  addChannelChange() {
    this.setState({ open: true });
  }

  cancelChannelChange() {
    this.setState({ open: false });
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  getPageDetails() {
    const {
      channels, loading, maxPages, translations, userPermissions
    } = this.props;
    const tableHeader = [
      {
        id: 'statusIcon', sortable: false, numeric: false, label: '', narrow: true
      },
      { id: 'date', sortable: false, numeric: false, label: translations.date },
      { id: 'tv', sortable: false, numeric: false, label: translations.tv },
      { id: 'name', sortable: false, numeric: false, label: translations.name },
      { id: 'affiliate', sortable: false, numeric: false, label: translations.affiliate },
      { id: 'tz', sortable: false, numeric: false, label: translations.tz },
      { id: 'sourceID', sortable: false, numeric: false, label: translations.sourceID }
    ];

    const canEdit = checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);
    return (
      <Table stickyHeader={true}>
        <SonifiTableHead headColumns={tableHeader} />
        <TableBody>
          {loading
            ? <TableRow><TableCell colSpan={tableHeader.length}><SonifiSpinner /></TableCell></TableRow>
            : <Fragment>
              {!channels || channels.length === 0
                ? <TableRow>
                  <TableCell colSpan={tableHeader.length}>
                    <SonifiLabel error label={translations.errors.noJobs} />
                  </TableCell>
                </TableRow>
                : channels.map((info, index) => (
                  <Fragment key={index}>
                    <BatchRow index={index} canEdit={canEdit} />

                    {index === channels.length - DISTANCE_FROM_END && this.state.page < maxPages && (
                      <TableRow>
                        <TableCell>
                          <Waypoint onEnter={() => { this.getMoreData(); }} />
                        </TableCell>
                      </TableRow>)
                    }
                  </Fragment>
                ))
              }
            </Fragment>
          }
        </TableBody>
      </Table>);
  }

  stopInProgressJob() {
    this.props.dispatch(actions.stopJob(this.props.job));
  }

  universalCancel() {
    this.props.dispatch(actions.stopJobCheck(''));
  }

  render() {
    const {
      gettingInfo, globalTranslations, isProd, job, loading, selectedChannel, saving, translations, userPermissions
    } = this.props;
    if (isTesting(isProd) || !checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)) {
      return <Navigate replace to="/global" />;
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={gettingInfo || saving} />
        <SonifiTemplate
          header={translations.title}
          icon={<Autorenew />}
          label={globalTranslations.replace}
          onSubmit={this.addChannelChange}
          pageDetails={this.getPageDetails()}
          showButton={!loading && checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)}
        />
        {!gettingInfo && selectedChannel !== null && <ViewBatchDialog />}
        {this.state.open && <NewBatchDialog close={this.cancelChannelChange} />}
        <SonifiConfirm
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.save}
          confirmTitle={translations.confirm.title}
          confirmText={translations.confirm.text}
          dialogOpen={job !== ''}
          onConfirm={this.stopInProgressJob}
          onCancel={this.universalCancel}
        />
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  channels: state.batchChannels.channels,
  gettingInfo: state.batchChannels.gettingInfo,
  globalTranslations: state.global.translations.defaults,
  isProd: state.global.isProd,
  job: state.batchChannels.job,
  loading: state.batchChannels.loading,
  maxPages: state.firmware.maxPages,
  saving: state.batchChannels.saving,
  selectedChannel: state.batchChannels.selectedChannel,
  translations: state.batchChannels.translations.grid,
  userPermissions: state.global.permissions
});

BatchChannels.propTypes = {
  channels: PropTypes.array,
  dispatch: PropTypes.func,
  gettingInfo: PropTypes.bool,
  globalTranslations: PropTypes.object,
  isProd: PropTypes.bool.isRequired,
  job: PropTypes.string,
  loading: PropTypes.bool,
  maxPages: PropTypes.number,
  saving: PropTypes.bool,
  selectedChannel: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(BatchChannels);
