// DataTable rows of Libary items with popover and event handlers

import { TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { withStyles } from 'tss-react/mui';
import SonifiCheckbox from '../../../containers/SonifiCheckbox';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSpinner from '../../../containers/SonifiSpinner';

const DISTANCE_FROM_END = 3; // insert waypoint object 3 items from the end of the list
const styles = () => ({
  hover: {
    // Do not remove
  }
});

class SiteTableBody extends Component {
  getMoreData() {
    this.props.onWayPointReached();
  }

  handlePopover = (id) => () => {
    this.props.updateIds(id);
  };

  /* eslint-disable camelcase */
  getChannelSearch(channels, searchable) {
    const { canEdit, translations } = this.props;

    return (
      <TableBody>
        {(!channels || channels.length === 0)
          ? <TableRow>
            <TableCell colSpan={3}>
              <SonifiLabel error label={(searchable ? translations.errors.noChannels : translations.errors.noSearch)} />
            </TableCell>
          </TableRow>
          : channels.map(({
            id, name, affiliate, call_letters, timezone
          }, i) => (
            <TableRow
              key={id}
              hover={true}
              onClick={this.handlePopover({
                id, name, affiliate, call_letters, timezone
              })}
            >
              <TableCell>
                <SonifiCheckbox
                  noLabel={true}
                  label=""
                  value={this.props.isLogoSelected(id)}
                  disabled={!canEdit}
                />

                {i === channels.length - DISTANCE_FROM_END && (
                  <Waypoint onEnter={() => {
                    console.log('Waypoint reached: ', i);
                    console.log('Total Records: ', channels.length);
                    this.getMoreData();
                  }}
                  />)
                }
              </TableCell>
              <TableCell>{name}</TableCell>
              <TableCell>{`${affiliate !== null ? `${affiliate}|` : ''}${call_letters}|${timezone}`}</TableCell>
              <TableCell>{id}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    );
  }

  render() {
    const {
      channels, loadingChannels, sources
    } = this.props;

    if (loadingChannels && channels && channels.length === 0) {
      return (<TableBody>
        <TableRow>
          <TableCell colSpan={3} >
            <SonifiSpinner />
          </TableCell>
        </TableRow>
      </TableBody>);
    }

    return (<Fragment>
      {
        channels && channels.length > 0
          ? this.getChannelSearch(channels, false)
          : this.getChannelSearch(sources, channels === null)
      }
    </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  channels: state.logo.channels,
  loadingChannels: state.logo.loadingChannels,
  translations: state.logo.translations.editDialog,
});

SiteTableBody.propTypes = {
  canEdit: PropTypes.bool,
  channels: PropTypes.array,
  dispatch: PropTypes.func,
  error: PropTypes.bool,
  isLogoSelected: PropTypes.func,
  loadingChannels: PropTypes.bool,
  onWayPointReached: PropTypes.func,
  translations: PropTypes.object,
  updateIds: PropTypes.func,
  sources: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(SiteTableBody, styles, { withTheme: true }));

