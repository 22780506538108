import { ArrowRightAlt } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiIconLabel from '../../../containers/SonifiIconLabel';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import * as actions from '../actions/softwareChangesActions';
import { findProductParentId } from '../utils/softwareImport';

class SoftwareDialog extends Component {
  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(actions.showPopup(null));
    }
  }

  getRowInformation(title, rowLabel) {
    const { popupInfo } = this.props;
    const auto = rowLabel && rowLabel !== '' ? popupInfo[rowLabel] : '';
    let currRow = '';
    if (rowLabel === 'id') {
      currRow = auto;
    } else if (rowLabel === 'automatic_updates') {
      currRow = popupInfo.response.software['update-mode'];
    } else if (rowLabel === 'software_channel') {
      currRow = popupInfo.response.software.channel;
      // } else if (rowLabel === 'virtos_version') {
      //   currRow = `${popupInfo.response.software.virtos}`;
    } else if (rowLabel === 'version') {
      return;
    }

    const showChange = auto !== currRow && currRow !== '' && auto !== '';
    return <SonifiIconLabel blue={showChange} boldLabel={title} label={`${currRow}`}
      icon={showChange ? <ArrowRightAlt /> : null}
      labelTwo={showChange ? `${auto}` : ''} />;
  }

  getProductParentColumn(rowLabel, title) {
    const { popupInfo, translations } = this.props;
    const ppName = findProductParentId(popupInfo.product_code, this.props.productParents).name;
    if (popupInfo.response.system && popupInfo.response.system.hosts) {
      if (popupInfo.response.system.hosts.length > 0) {
        const currRow = popupInfo.response.system.versions.toString();
        const showChange = true;
        const auto = popupInfo.version;

        if (auto === null) {
          return <SonifiLabel error boldLabel={ppName} label={translations.errors.automatic} />;
        }

        return <Fragment>
          <SonifiLabel boldLabel={ppName}
            label={popupInfo.response.system.hosts.toString()} />
          <SonifiIconLabel blue={showChange} boldLabel={title} label={`${currRow}`}
            icon={showChange ? <ArrowRightAlt /> : null}
            labelTwo={showChange ? `${auto}` : ''} />
        </Fragment>;
      } else if (rowLabel === 'product_code') {
        return (<SonifiLabel error boldLabel={ppName} label="does not exist on the system." />
        );
      }
    } else if (rowLabel === 'product_code') {
      return <SonifiLabel error label="No Systems Available." />;
    }
  }

  render() {
    const { popupInfo, translations } = this.props;

    return (
      <Dialog
        open={true}
        maxWidth="md"
        onClose={this.onCancel.bind(this)}
        fullWidth
      >
        <SonifiModalHeader
          header={`${translations.headers.popup} ${popupInfo.id}`}
          onCancel={this.onCancel.bind(this)}
          onlyClose={true}
          label={popupInfo.id}
        />
        <DialogContent>
          {this.getRowInformation(translations.headers.site, 'id')}
          {/* {this.getRowInformation(translations.headers.virtOS, 'virtos_version')} */}
          {this.getRowInformation(translations.headers.autoUpdate, 'automatic_updates')}
          {this.getRowInformation(translations.headers.softwareChannel, 'software_channel')}
          {this.getProductParentColumn('product_code', translations.headers.version)}
          <SonifiLabel error={!popupInfo.success}
            label={popupInfo.success ? translations.headers.saveSuccess : popupInfo.details} />
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  popupInfo: state.softwareChanges.popupInfo,
  productParents: state.deployments.productParents,
  translations: state.softwareChanges.translations.grid
});

SoftwareDialog.propTypes = {
  dispatch: PropTypes.func,
  popupInfo: PropTypes.object,
  productParents: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(SoftwareDialog);
