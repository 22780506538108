import { FormControlLabel, Grid, Switch } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SonifiLabel from './SonifiLabel';

export class SonifiSwitch extends Component {
  render() {
    const {
      checked, disabled, onChange, label, leftLabel
    } = this.props;

    return (
      <Fragment>
        {leftLabel &&
          <Grid item style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <SonifiLabel label={leftLabel} size="md" disabled={!!disabled} />
          </Grid>}
        <Grid item style={{ marginTop: 'auto', marginBottom: 'auto' }}>
          <FormControlLabel key={label}
            control={
              <Switch
                checked={checked}
                onChange={onChange}
                disabled={!!disabled}
                value={checked} />
            }
            label={label}
          />
        </Grid>
      </Fragment>
    );
  }
}

SonifiSwitch.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  leftLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default SonifiSwitch;
