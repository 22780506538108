import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import SonifiTextInline from '../../../containers/SonifiTextInline';
import { findObjectByKey } from '../../../utils';
import { updateSelectedPackageRow } from '../actions/releaseTemplateActions';

class DialogRow extends Component {
  state = {
    packageName: '',
    version: '',
  };

  componentDidMount() {
    const { name, version } = this.props;
    this.setState({ packageName: name, version });
  }

  componentDidUpdate(prevProps, prevState) {
    const { name, version } = this.props;
    if (prevState.packageName !== name) {
      this.setState({ packageName: name, version });
    }
  }


  handleChange = (name) => ({ target: { value } }) => {
    if (name === 'packageName') {
      let versionsOpt = [];
      if (value.length > 0) {
        versionsOpt = this.getVersionOptions(value);
      }

      this.setState({ [name]: value, version: versionsOpt && versionsOpt.length > 0 ? versionsOpt[0] : '' }, () => {
        this.props.changeFunc(this.state);
      });
      return;
    }

    this.setState({ [name]: value }, () => {
      this.props.changeFunc(this.state);
    });
  };

  handleDeleteDialog() {
    const { dispatch, rowIndex, editType } = this.props;
    dispatch(updateSelectedPackageRow(rowIndex, editType));
    this.props.gridDeleteFunc(rowIndex);
  }

  updateSelectedPackage(rowIndex) {
    const { dispatch, editType } = this.props;
    dispatch(updateSelectedPackageRow(rowIndex, editType));
  }

  getVersionOptions(pkgName) {
    const { packagesOpt } = this.props;
    const shortName = findObjectByKey(packagesOpt, 'id', pkgName);
    return (shortName && shortName.releases && shortName.releases.length > 0 ? shortName.releases : []);
  }

  getRow() {
    const {
      canEdit, availablePackages, editType, rowIndex, selectedPkgRow
    } = this.props;

    const { packageName, version } = this.state;
    let versionsOpt = [];
    if (packageName.length > 0) {
      versionsOpt = this.getVersionOptions(packageName);
    }
    const tempAvailPackages = [];
    for (let i = 0; i < availablePackages.length; i++) {
      if (availablePackages[i].visible || availablePackages[i].id === packageName) {
        tempAvailPackages.push(availablePackages[i]);
      }
    }

    const selected = rowIndex === selectedPkgRow && canEdit && editType !== 'read';
    return (
      <Fragment>
        {selected
          ? <Fragment>
            <TableCell component="td" scope="row" >
              {tempAvailPackages.length > 1
                ? <SonifiTextInline
                  select
                  items={
                    tempAvailPackages.map((suggestion) => ({
                      id: suggestion.id,
                      value: suggestion.id,
                      label: suggestion.id
                    }))
                  }
                  defaultValue={packageName}
                  change={this.handleChange('packageName')}
                />
                : <SonifiLabel label={packageName} />}
            </TableCell>
            <TableCell component="td" scope="row">
              {versionsOpt.length > 1
                ? <SonifiTextInline
                  select
                  items={
                    versionsOpt.map((suggestion) => ({
                      id: suggestion,
                      value: suggestion,
                      label: suggestion
                    }))
                  }
                  defaultValue={version}
                  change={this.handleChange('version')}
                />
                : <SonifiLabel label={`${version}`} />}
            </TableCell>
          </Fragment>
          : <Fragment>
            <TableCell component="td" scope="row" >
              <SonifiLabel label={packageName} />
            </TableCell>
            <TableCell component="td" scope="row">
              <SonifiLabel label={`${version}`} />
            </TableCell>
          </Fragment>
        }
      </Fragment>
    );
  }

  render() {
    const {
      canEdit, deleteEnabled, editType, key, globalTranslations, rowIndex, selectedPkgRow, translations
    } = this.props;

    if (editType !== 'read' && rowIndex !== selectedPkgRow) {
      return (
        <SonifiPopover
          editFunc={this.updateSelectedPackage.bind(this, rowIndex)}
          editTitle={globalTranslations.edit}
          editDisabled={!canEdit}
          deleteDisabled={!deleteEnabled || !canEdit}
          deleteFunc={this.handleDeleteDialog.bind(this)}
          deleteTitle={translations.removeTemplate}
          key={key}
          tableRow={this.getRow()}
        />);
    }
    return (
      <TableRow>{this.getRow()}</TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.releaseTemplates.changed,
  editType: state.releaseTemplates.editType,
  globalTranslations: state.global.translations.defaults,
  packagesOpt: state.releaseTemplates.packagesOpt,
  selectedPkgRow: state.releaseTemplates.selectedPkgRow,
  translations: state.releaseTemplates.translations.editDialog
});

DialogRow.propTypes = {
  availablePackages: PropTypes.array,
  canEdit: PropTypes.bool,
  changed: PropTypes.bool,
  changeFunc: PropTypes.func,
  deleteEnabled: PropTypes.bool,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  globalTranslations: PropTypes.object,
  gridDeleteFunc: PropTypes.func,
  key: PropTypes.number,
  name: PropTypes.string,
  packagesOpt: PropTypes.array,
  rowIndex: PropTypes.number.isRequired,
  selectedPkgRow: PropTypes.number,
  translations: PropTypes.object,
  version: PropTypes.string
};

export default connect(mapStateToProps)(DialogRow);

