import { TERMINAL_FIRMWARE_OPTIONS, TERM_FIRMWARE } from '../../../constants/apiEndpoints';
import { SONIFI } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import {
  deleteSitelessObjectPromise, getObjectPromise, patchSitelessObjectPromise, postSitelessObjectPromise
} from '../../../utils/api';
import { putFileNoAuthHeader } from '../../../utils/index';
import { fetchModels, startModels } from '../../TermModels/actions/termModelActions';
import * as types from './actionTypes';

export const clearFirmware = () => ({
  type: types.CLEAR_FIRMWARE,
});

export const startFirmware = () => ({
  type: types.START_FIRMWARE,
});

export const fetchFirmwareBegin = () => ({
  type: types.FETCH_FIRMWARE_BEGIN,
});

export const fetchFirmwareSuccess = (firmware) => ({
  type: types.FETCH_FIRMWARE_SUCCESS,
  firmware,
});

export const fetchFirmwareFailure = () => ({
  type: types.FETCH_FIRMWARE_FAILURE
});

export function fetchFirmware(filter) {
  return (dispatch) => {
    dispatch(fetchFirmwareBegin());

    return getObjectPromise(TERM_FIRMWARE, null, filter)
      .then((response) => {
        dispatch(fetchFirmwareSuccess(response));
        dispatch(getCorrupedFileData(response));
        return response;
      })
      .catch(() => dispatch(fetchFirmwareFailure()));
  };
}

export function getCorrupedFileData(data) {
  return (dispatch) => {
    dispatch(fetchFirmwareFileBegin());

    const corruptedTest = data.firmware.map((firmware) => getObjectPromise(TERM_FIRMWARE, firmware.id));
    Promise.all(corruptedTest)
      .then((responses) => {
        dispatch(updateCorruptedFile(data.firmware, responses));
      });
  };
}

export const updateCorruptedFile = (data, firmware) => ({
  type: types.UPDATE_CORRUPTED_FILE,
  firmware,
  data
});

export const fetchFirmwareFileBegin = () => ({
  type: types.FETCH_FIRMWARE_FILE_BEGIN,
});

export const fetchFirmwareFileSuccess = (firmware) => ({
  type: types.FETCH_FIRMWARE_FILE_SUCCESS,
  firmware,
});

export const fetchFirmwareFileFailure = () => ({
  type: types.FETCH_FIRMWARE_FILE_FAILURE
});

export function fetchFirmwareFile(filename) {
  return (dispatch) => {
    dispatch(fetchFirmwareFileBegin());

    return getObjectPromise(TERM_FIRMWARE, filename)
      .then((response) => {
        dispatch(fetchFirmwareFileSuccess(response));
        return response;
      })
      .catch(() => dispatch(fetchFirmwareFileFailure()));
  };
}

export const updateSelectedFirmwareBegin = (index, editType) => ({
  type: types.UPDATE_SELECTED_FIRMWARE,
  index,
  editType,
});

export function updateSelectedFirmware(index, make, editType) {
  return (dispatch) => {
    dispatch(updateSelectedFirmwareBegin(index, editType));
    dispatch(startModels());

    if (make) {
      return dispatch(fetchModels({ make }))
        .then(() => {
          dispatch(addFirmwareSuccess());
        })
        .catch(() => dispatch(addFirmwareFailure()));
    }
  };
}

export const updateDeleteRow = (firmware) => ({
  type: types.DELETE_CHECK,
  firmware,
});

export const deleteFirmwareBegin = () => ({
  type: types.DELETE_FIRMWARE_BEGIN,
});

export const deleteFirmwareSuccess = (id, showSnackBar = true) => ({
  type: types.DELETE_FIRMWARE_SUCCESS,
  id,
  showSnackBar,
});

export const deleteFirmwareError = (error) => ({
  type: types.DELETE_FIRMWARE_FAILURE,
  error,
});

export function deleteFirmwareById(id, sort, showSnackBar = true) {
  return (dispatch) => {
    dispatch(deleteFirmwareBegin());
    return deleteSitelessObjectPromise(TERM_FIRMWARE, encodeURIComponent(id))
      .then(() => {
        if (sort) {
          // added so I can delete on the fly
          dispatch(clearFirmware());
          return dispatch(fetchFirmware(sort)).then(() => {
            dispatch(deleteFirmwareSuccess(id, showSnackBar));
            return true;
          });
        }
      })
      .catch((error) => {
        dispatch(clearFirmware());
        return dispatch(fetchFirmware(sort)).then(() => {
          dispatch(deleteFirmwareError(`${id} - (${error.status})`));
          return false;
        });
      });
  };
}

export const addFirmwareBegin = () => ({
  type: types.ADD_FIRMWARE_BEGIN,
});

export const addFirmwareSuccess = () => ({
  type: types.ADD_FIRMWARE_SUCCESS,
});

export const addFirmwareFailure = () => ({
  type: types.ADD_FIRMWARE_FAILURE
});

export function addFirmware() {
  return (dispatch) => {
    dispatch(addFirmwareBegin());
    dispatch(startModels());

    return dispatch(fetchModels({ make: SONIFI }))
      .then(() => {
        dispatch(addFirmwareSuccess());
      })
      .catch(() => dispatch(addFirmwareFailure()));
  };
}

export const resetSnackBar = (snackBarType, snackBarMessage) => ({
  type: types.RESET_SNACKBAR,
  snackBarType,
  snackBarMessage,
});

export const saveFirmwareBegin = (controller) => ({
  type: types.SAVE_FIRMWARE_BEGIN,
  controller,
});

export const saveFirmwareSuccess = (firmwareName) => ({
  type: types.SAVE_FIRMWARE_SUCCESS,
  firmwareName,
});

export const saveFirmwareError = (error) => ({
  type: types.SAVE_FIRMWARE_FAILURE,
  error
});

export function saveFirmware(firmware, sort) {
  return (dispatch) => {
    const controller = new AbortController();
    dispatch(saveFirmwareBegin(controller));

    const firmwareName = firmware.id.trim();
    const md5 = firmware.md5.trim();
    return postSitelessObjectPromise(TERM_FIRMWARE, null, { filename: firmwareName, md5 }, true)
      .then((importUrl) => {
        if (importUrl === null && firmware.isNew) {
          dispatch(saveFirmwareError('File error. Please check the version does not already exist'));
          return false;
        }
        return patchSitelessObjectPromise(TERM_FIRMWARE, firmwareName, { models: firmware[firmware.make] }, controller)
          .then((response) => {
            if (response.status && !httpSuccess(response.status)) {
              return response.json().then((errorObjDetails) => {
                dispatch(saveFirmwareError(errorObjDetails.detail));
                return false;
              });
            }

            if (firmware.fileBinary && firmware.isNew) {
              const headers = {
                'Content-Type': 'vnd.cloud.sonifi.cca.firmware',
                'Content-Disposition': `attachment; filename=${firmwareName}`,
              };

              return putFileNoAuthHeader(importUrl, firmware.fileBinary, headers)
                .then((data) => {
                  if (!httpSuccess(data.status)) {
                    return data
                      .text()
                      .then((text) => {
                        try {
                          const errorObjDetails = JSON.parse(text);
                          dispatch(saveFirmwareError(errorObjDetails.detail));
                        } catch(err) {
                          dispatch(saveFirmwareError('Network Error.'));
                        }
                        return false;
                      })
                      .catch((error) => {
                        dispatch(saveFirmwareError(error));
                        return false;
                      });
                  }
                  dispatch(clearFirmware());
                  return dispatch(fetchFirmware(sort))
                    .then(() => {
                      dispatch(saveFirmwareSuccess(firmware.id));
                      return true;
                    });
                })
                .catch((error) => {
                  dispatch(saveFirmwareError(error));
                  return false;
                });
            } else {
              if (response.status && !httpSuccess(response.status)) {
                return response.json().then((errorObjDetails) => {
                  dispatch(saveFirmwareError(errorObjDetails.detail));
                  return false;
                });
              }
              dispatch(clearFirmware());
              return dispatch(fetchFirmware(sort))
                .then(() => {
                  dispatch(saveFirmwareSuccess(firmware.id));
                  return true;
                });
            }
          })
          .catch((error) => {
            dispatch(saveFirmwareError(error));
            return false;
          });
      })
      .catch((error) => {
        dispatch(saveFirmwareError(error));
        return false;
      });
  };
}

export const getFirmwareRegexBegin = () => ({
  type: types.GET_REGEX_BEGIN,
});

export const getFirmwareRegexSuccess = (regex) => ({
  type: types.GET_REGEX_SUCCESS,
  regex
});

export const getFirmwareRegexError = (error) => ({
  type: types.GET_REGEX_FAILURE,
  error
});

export function getFirmwareRegex() {
  return (dispatch) => {
    dispatch(getFirmwareRegexBegin());

    return getObjectPromise(TERMINAL_FIRMWARE_OPTIONS)
      .then((response) => {
        dispatch(getFirmwareRegexSuccess(response.filenaming));
        return response;
      })
      .catch((error) => dispatch(getFirmwareRegexError(error)));
  };
}
