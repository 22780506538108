import { GetApp } from '@mui/icons-material';
import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiError } from '../../../containers';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import { downloadIpgFile } from '../actions/downloadsActions';

class IpgFileRow extends Component {
   state = { displayToolTip: false };

   addOnClick = () => {
        const { ipgFileRow } = this.props;

        this.props.dispatch(
            downloadIpgFile(ipgFileRow.directory, ipgFileRow.filename)
        );
    };

    rowClickHandler = () => {
        this.setState({ displayToolTip: !this.state.displayToolTip });
    };

    render() {
        const { ipgFileRow, error } = this.props;

        if (error) {
            return <SonifiError label={`Error! ${error.message}`} />;
        }

        const newDateString = `${ipgFileRow.directory.slice(0, 4)
        }-${ ipgFileRow.directory.slice(4, 6) }-${ ipgFileRow.directory.slice(6, 8)}`;
        const dateObject = new Date(newDateString);

        return (
            <SonifiPopover
                hideDelete={true}
                hideEdit={true}
                showFunctionTwo={false}
                showFunctionOne={true}
                functionOneFunc={this.addOnClick}
                key={ipgFileRow.directory + ipgFileRow.filename}
                functionOneIcon={<GetApp fontSize="small" />}
                functionOneTitle={'Download'}
                tableRow={
                <Fragment>
                    <TableCell component="th" scope="row">
                        <SonifiLabel label={dateObject.toLocaleDateString()} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                        <SonifiLabel label={ipgFileRow.filename} />
                    </TableCell>
                </Fragment >
                }
            />
        );
    }
}

const mapStateToProps = (state) => ({
    error: state.downloads.error
});

IpgFileRow.propTypes = {
    error: PropTypes.object,
    ipgFileRow: PropTypes.object,
    dispatch: PropTypes.func
};

export default connect(mapStateToProps)(IpgFileRow);
