/* eslint-disable max-lines-per-function */
import { has, isNil, orderBy, sortBy } from 'lodash';
import moment from 'moment';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, READ, SONIFI, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import { LG, SAMSUNG } from '../constants/TermFileConstants';
import en from '../i18n/en';

const langs = { en };
const initialState = {
  changed: true,
  delay: 0,
  deleteTlx: null,
  dialogLoading: false,
  duration: 0,
  durationHours: 0,
  editTLX: false,
  editType: READ,
  errorMsg: '',
  gettingModels: false,
  lgClient: '',
  lgEnabled: false,
  lgFirmware: [],
  lgIndex: -1,
  lgLoading: false,
  loading: false,
  midnight: moment.utc().format('MM/DD/YYYY 00:00'),
  modelType: '',
  samsungClient: '',
  samsungEditType: '',
  samsungEnabled: false,
  samsungFirmware: [],
  samsungIndex: -1,
  saving: false,
  selectedTermFile: {},
  snackBarMessage: '',
  snackBarType: SUCCESS,
  sonifiEnabled: false,
  sonifiFirmware: [],
  sonifiIndex: -1,
  sonifiLoading: false,
  startDate: null,
  termOptLoading: false,
  tlxFile: [],
  translations: null
};

export default function termFileReducer(state = initialState, action) {
  let sortObj = null,
    tempStart = null,
    tempDelay = null,
    tempDuration = null,
    tempString = '';
  switch (action.type) {
    case types.RESET_TERM_FILES:
      return {
        ...state, dialogLoading: false, lgLoading: false, loading: false, saving: false, sonifiLoading: false
      };

    case types.DELETE_TLX:
      return { ...state, deleteTlx: action.filename, snackBarMessage: '' };

    case types.DELETE_TLX_FILE_BEGIN:
      return { ...state, saving: true };

    case types.DELETE_TLX_FILE_SUCCESS:
      return {
        ...state,
        deleteTlx: null,
        saving: false,
        snackBarType: SUCCESS,
        snackBarMessage: `${state.translations.dialog.deleteBox.success} ${action.filename}`
      };

    case types.DELETE_TLX_FILE_FAILURE:
      return {
        ...state,
        deleteTlx: null,
        saving: false,
        snackBarType: ERROR,
        snackBarMessage: `${state.translations.dialog.deleteBox.error} ${action.filename} - ${action.error}`
      };

    case types.CLEAR_TLX_FILE:
      return { ...state, tlxFile: [], editTLX: false, loading: false };

    case types.FETCH_TLX_FILE_BEGIN:
      return { ...state, snackBarMessage: '', loading: true };

    case types.FETCH_TLX_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        tlxFile: action.tlxFile,
        changed: !state.changed
      };

    case types.FETCH_TLX_FILE_FAILURE:
      return { ...state, loading: false, changed: !state.changed };

    case types.FETCH_TERMINAL_OPTIONS_BEGIN:
      return { ...state, snackBarMessage: '', termOptLoading: true };

    case types.FETCH_TERMINAL_OPTIONS_SUCCESS:
      tempDelay = (action.maintWindow && action.maintWindow.delay) &&
      (action.maintWindow.delay > 0 && action.maintWindow.delay < 86400)
      ? action.maintWindow.delay
      : 7200;

      // tempStart = moment(state.midnight).add(tempDelay, 'seconds');
      tempStart = moment.utc(state.midnight).add(tempDelay, 'seconds').format('YYYY-MM-DD HH:mm:ss');
      tempDuration = (action.maintWindow && action.maintWindow.duration)
        ? action.maintWindow.duration
        : 7200;

      return {
        ...state,
        termOptLoading: false,
        delay: tempDelay,
        duration: tempDuration,
        durationHours: tempDuration / 3600,
        sonifiEnabled: (action.autoUpdates && has(action.autoUpdates, SONIFI) && !isNil(action.autoUpdates.SONIFI))
          ? action.autoUpdates.SONIFI
          : false,
        lgEnabled: (action.autoUpdates && has(action.autoUpdates, LG) && !isNil(action.autoUpdates.LG))
          ? action.autoUpdates.LG
          : false,
        samsungEnabled: (action.autoUpdates && has(action.autoUpdates, SAMSUNG) && !isNil(action.autoUpdates.SAMSUNG))
          ? action.autoUpdates.SAMSUNG
          : false,
        startDate: tempStart
      };

    case types.FETCH_TERMINAL_OPTIONS_FAILURE:
      return {
        ...state,
        termOptLoading: false,
        snackBarType: ERROR,
        snackBarMessage: `${state.translations.grid.errors.termFirm} - ${action.error}`
      };

    case types.IMPORT_TLX_FILE_BEGIN:
      return {
        ...state,
        saving: true,
        snackBarMessage: ''
      };

    case types.IMPORT_TLX_FILE_FAILURE:
      return {
        ...state,
        saving: false,
        snackBarType: ERROR,
        snackBarMessage: `${state.translations.grid.tlx.errors.errorSaving}`
      };

    case types.IMPORT_TLX_FILE_SUCCESS:
      return {
        ...state,
        saving: false,
        snackBarType: SUCCESS,
        snackBarMessage: `${state.translations.grid.tlx.successSave}`
      };

    case types.SET_SELECTED_TLX_FILE:
      return { ...state, selectedTermFile: action.tlxFile, snackBarMessage: '' };


    /*************************************/
    case types.FETCH_TERM_FIRMWARE_BEGIN:
      return {
        ...state,
        snackBarMessage: '',
        lgLoading: action.make === LG,
        samsungLoading: action.make === SAMSUNG,
        sonifiLoading: action.make === SONIFI
      };

    case types.FETCH_TERM_FIRMWARE_SUCCESS:
      if (action.make === LG) {
        return {
          ...state,
          lgFirmware: action.firmwares,
          lgClient: action.clients,
          lgLoading: false,
          changed: !state.changed
        };
      } else if (action.make === SAMSUNG) {
        return {
          ...state,
          changed: !state.changed,
          samsungClient: action.clients || '',
          samsungFirmware: [...action.firmwares],
          samsungLoading: false
        };
      } else if (action.make === SONIFI) {
        return {
          ...state,
          sonifiFirmware: [...action.firmwares],
          sonifiLoading: false,
          changed: !state.changed
        };
      }
      break;

    case types.FETCH_TERM_FIRMWARE_FAILURE:
      tempString = `${state.translations.grid.sonifi.errors.unableToGet}`;
      if (action.make === LG) {
        tempString = `${state.translations.grid.lg.errors.unableToGet}`;
      } else if (action.make === SAMSUNG) {
        tempString = `${state.translations.grid.samsung.errors.unableToGet}`;
      }
      return {
        ...state,
        changed: !state.changed,
        lgLoading: false,
        samsungLoading: false,
        snackBarMessage: `${tempString} - (${action.error})`,
        snackBarType: ERROR,
        sonifiLoading: false
      };

    /*************************************/


    case types.CLEAR_LG_FIRMWARE:
      return {
        ...state, editClient: false, lgFirmware: [], lgClient: '', snackBarMessage: '', lgLoading: true, lgIndex: -1
      };

    // case types.FETCH_LG_FIRMWARE_BEGIN:
    //   return { ...state, snackBarMessage: '', lgLoading: true };

    // case types.FETCH_LG_FIRMWARE_SUCCESS:
    //   return {
    //     ...state,
    //     lgFirmware: action.lgFirmware,
    //     lgClient: action.lgClient,
    //     lgLoading: false,
    //     changed: !state.changed
    //   };

    // case types.FETCH_LG_FIRMWARE_FAILURE:
    //   return {
    //     ...state,
    //     changed: !state.changed,
    //     lgLoading: false,
    //     snackBarType: ERROR,
    //     snackBarMessage: `${state.translations.grid.lg.errors.unableToGet} - (${action.error})`
    //   };

    case types.SAVE_SITE_FIRMWARE_BEGIN:
      return {
        ...state,
        saving: true,
        snackBarMessage: ''
      };

    case types.SAVE_SITE_FIRMWARE_FAILURE:
      tempString = `${state.translations.grid.sonifi.errors.saveError}`;
      if (action.make === LG) {
        tempString = `${state.translations.grid.lg.errors.saveError}`;
      } else if (action.make === SAMSUNG) {
        tempString = `${state.translations.grid.samsung.errors.saveError}`;
      }
      return {
        ...state,
        saving: false,
        snackBarType: ERROR,
        snackBarMessage: `${tempString} - (${action.error})`
      };

    case types.SAVE_SITE_FIRMWARE_SUCCESS:
      return {
        ...state,
        saving: false,
        snackBarMessage: state.translations.grid.saveSuccess.replace('#termType', action.termType),
        snackBarType: SUCCESS
      };

    case types.CLEAR_SONIFI_FIRMWARE:
      return {
        ...state, sonifiFirmware: [], snackBarMessage: '', sonifiLoading: true, sonifiIndex: -1
      };

    // case types.FETCH_SONIFI_FIRMWARE_BEGIN:
    //   return { ...state, snackBarMessage: '', sonifiLoading: true };

    // case types.FETCH_SONIFI_FIRMWARE_SUCCESS:
    //   return {
    //     ...state,
    //     sonifiFirmware: [...action.sonifiFirmware],
    //     sonifiLoading: false,
    //     changed: !state.changed
    //   };

    // case types.FETCH_SONIFI_FIRMWARE_FAILURE:
    //   return {
    //     ...state,
    //     changed: !state.changed,
    //     sonifiLoading: false,
    //     snackBarType: ERROR,
    //     snackBarMessage: `${state.translations.grid.sonifi.errors.unableToGet} - ${action.error}`
    //   };

    case types.UPDATE_SELECTED_SONIFI_FIRMWARE:
      return { ...state, sonifiIndex: action.sonifiIndex, editType: action.editType, snackBarMessage: '' };

    case types.EDIT_TLX:
      return { ...state, editTLX: action.editable };

    case types.SET_SELECTED_LG_FIRMWARE:
      return {
        ...state,
        changed: !state.changed,
        lgIndex: action.firmware,
        snackBarMessage: ''
      };

    case types.UPDATE_CLIENT_FILE:
      return { ...state, editClient: action.show, snackBarMessage: '' };

    case types.UPDATE_MAINTENANCE_WINDOW:
      return { ...state, [action.name]: action.value };

    case types.RESET_SNACK_BAR:
      return {
        ...state,
        saving: false,
        snackBarMessage: action.snackBarMessage,
        snackBarType: (action.snackBarType ? action.snackBarType : state.snackBarType)
      };

    case types.CLEAR_SAMSUNG_FIRMWARE:
      return {
        ...state, samsungFirmware: [], samsungEditType: '', snackBarMessage: '', samsungLoading: true, samsungIndex: -1
      };

    // case types.FETCH_SAMSUNG_FIRMWARE_BEGIN:
    //   return { ...state, snackBarMessage: '', samsungLoading: true };

    // case types.FETCH_SAMSUNG_FIRMWARE_SUCCESS:
    //   return {
    //     ...state,
    //     changed: !state.changed,
    //     samsungClient: action.client || '',
    //     samsungFirmware: [...action.samsungFirmware],
    //     samsungLoading: false
    //   };

    // case types.FETCH_SAMSUNG_FIRMWARE_FAILURE:
    //   return {
    //     ...state,
    //     changed: !state.changed,
    //     samsungLoading: false,
    //     snackBarMessage: `${state.translations.grid.samsung.errors.unableToGet} - ${action.error}`,
    //     snackBarType: ERROR
    //   };

    case types.SORT_TERMINAL_MODELS:
      sortObj = action.sortObj.sort.split(':');

      if (action.sortObj.make === LG) {
        tempStart = [...state.lgFirmware];
      } else if (action.sortObj.make === SAMSUNG) {
        tempStart = [...state.samsungFirmware];
      } else {
        tempStart = [...state.sonifiFirmware];
      }

      if (sortObj[0] === 'id' || sortObj[0] === 'model' || sortObj[0] === 'version') {
        tempDuration = orderBy(tempStart, sortObj[0], sortObj[1]);
      } else {
        tempDuration = sortBy(tempStart, [
          (item) => (item.firmware && item.firmware[sortObj[0]] ? item.firmware[sortObj[0]] : null),
          (item) => item.id
        ]);

        if (sortObj[1] === 'desc') {
          tempDuration.reverse();
        }
      }

      return {
        ...state,
        lgFirmware: action.sortObj.make === LG ? tempDuration : state.lgFirmware,
        samsungFirmware: action.sortObj.make === SAMSUNG ? tempDuration : state.samsungFirmware,
        sonifiFirmware: action.sortObj.make === SONIFI ? tempDuration : state.sonifiFirmware
      };

    case types.UPDATE_SELECTED_SAMSUNG_FIRMWARE:
      return { ...state, samsungIndex: action.samsungIndex, editType: action.editType, snackBarMessage: '' };

    case types.UPDATE_SAMSUNG_FILE:
      return { ...state, samsungEditType: action.editType, snackBarMessage: '' };

    case types.ADD_NEW_MODEL:
      return { ...state, modelType: action.modelType };

    case types.GET_SITE_MODELS_BEGIN:
      return { ...state, gettingModels: true, snackBarMessage: '' };

    case types.GET_SITE_MODELS_SUCCESS:
    case types.GET_SITE_MODELS_FAILURE:
      return { ...state, gettingModels: false };

    case types.SAVE_NEW_MODEL_BEGIN: {
      return { ...state, saving: true };
    }

    case types.SAVE_NEW_MODEL_SUCCESS: {
      tempString = state.translations.dialog.add.saveSuccess.replace('#modelType', action.modelType);
      tempString = tempString.replace('#modelId', action.modelId);
      return { ...state, saving: false, snackBarMessage: tempString, snackBarType: SUCCESS };
    }

    case types.SAVE_NEW_MODEL_FAILURE: {
      tempString = state.translations.dialog.add.saveError.replace('#modelType', action.modelType);
      tempString = tempString.replace('#modelId', action.modelId);
      return { ...state, saving: false, snackBarMessage: `${tempString} (${action.error})`, snackBarType: ERROR };
    }

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}

