import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { getTerminalInfo } from '../actions/terminalActions';
import Row from './Row';
import SelectedRow from './SelectedRow';

class TerminalRow extends Component {
  componentDidMount() {
    const rowTerm = this.props.terminals[this.props.rowIndex];
    if (rowTerm.id !== 'Add') {
      this.props.dispatch(getTerminalInfo(rowTerm.id));
    }
  }

  /* eslint-disable camelcase */
  render() {
    const {
      rowIndex, selectedTerminal, terminals
    } = this.props;

    const { lineup_id } = terminals[rowIndex];
    const selected = rowIndex === selectedTerminal;

    // TODO: Remove this code... why are we doing this?
    
    if (!lineup_id) {
      return <Fragment />;
    }
    return (
      <Fragment>
        {selected
          ? <SelectedRow rowIndex={rowIndex} />
          : <Row rowIndex={rowIndex} />
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedTerminal: state.terminals.selectedTerminal,
  terminals: state.terminals.terminals,
});

TerminalRow.propTypes = {
  dispatch: PropTypes.func,
  rowIndex: PropTypes.number.isRequired,
  selectedTerminal: PropTypes.number.isRequired,
  terminals: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(TerminalRow);

