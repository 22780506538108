import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiPopover from '../../../../containers/SonifiPopover';

class NetworkRow extends Component {
  render() {
    const {
      canEdit, netmask, translations, id, handleDeleteDialog, handleEditDialog, key
    } = this.props;

    return (
      <SonifiPopover
        deleteDisabled={!canEdit}
        deleteFunc={() => handleDeleteDialog(id)}
        deleteTitle={translations.delete}
        editFunc={() => handleEditDialog(id)}
        editTitle={translations.edit}
        hideDelete={!canEdit}
        hideEdit={!canEdit}
        key={key}
        showFunctionOne={false}
        tableRow={
          <Fragment>
            <TableCell>{netmask}</TableCell>
          </Fragment>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.siteManagement.translations.ipNetwork,
});

NetworkRow.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  handleDeleteDialog: PropTypes.func,
  handleEditDialog: PropTypes.func,
  id: PropTypes.number,
  key: PropTypes.number,
  netmask: PropTypes.string,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(NetworkRow);
