import { TERMINAL_OPTIONS, TERMINAL_RESOURCE } from '../../../constants/apiEndpoints';
import { getSiteObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

export const fetchTerminalOptionsBegin = () => ({
  type: types.FETCH_TERMINAL_OPTIONS_BEGIN
});

export const fetchTerminalOptionsSuccess = (terminalOptions) => ({
  type: types.FETCH_TERMINAL_OPTIONS_SUCCESS,
  terminalOptions
});

export const fetchTerminalOptionsFailure = (error) => ({
  type: types.FETCH_TERMINAL_OPTIONS_FAILURE,
  error
});

export const fetchTerminalInfoBegin = (terminalId) => ({
  type: types.FETCH_TERMINAL_INFO_BEGIN,
  terminalId
});

export const fetchTerminalInfoSuccess = (terminal) => ({
  type: types.FETCH_TERMINAL_INFO_SUCCESS,
  terminal
});

export const fetchTerminalInfoFailure = (error) => ({
  type: types.FETCH_TERMINAL_INFO_FAILURE,
  error
});

export const updateTerminalBegin = (terminalIndex, name, value) => ({
  type: types.UPDATE_TERMINAL,
  terminalIndex,
  name,
  value
});

export const removeNewTerminal = (terminalIndex) => ({
  type: types.REMOVE_NEW_TERMINAL,
  terminalIndex
});

export const fetchFreeTerminalsBegin = () => ({
  type: types.FETCH_FREE_TERMINALS_BEGIN
});

export const fetchFreeTerminalsSuccess = (freeTerminals) => ({
  type: types.FETCH_FREE_TERMINALS_SUCCESS,
  freeTerminals
});

export const fetchFreeTerminalInfoSuccess = (freeTerminalInfo) => ({
  type: types.FETCH_FREE_TERMINAL_INFO_SUCCESS,
  freeTerminalInfo
});

export const fetchFreeTerminalsFailure = (error) => ({
  type: types.FETCH_FREE_TERMINALS_FAILURE,
  error
});

export const addTerminal = (menusets) => ({
  type: types.ADD_TERMINAL,
  menusets
});

export const sortTerminals = (state) => ({
  type: types.SORT_TERMINALS,
  state
});

export const updateSelectedTerminal = (index) => ({
  type: types.UPDATE_SELECTED_TERMINAL,
  index
});

export function fetchTerminalOptions(options) {
  return (dispatch) => {
    dispatch(fetchTerminalOptionsBegin());
    return getSiteObjectPromise(TERMINAL_OPTIONS, null, null, options)
      .then((json) => {
        dispatch(fetchTerminalOptionsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchTerminalOptionsFailure(error)));
  };
}

export function getTerminalInfo(terminalId, siteId = null) {
  return (dispatch) => {
    dispatch(fetchTerminalInfoBegin(terminalId));
    return getSiteObjectPromise(TERMINAL_RESOURCE, terminalId, siteId)
      .then((json) => {
        dispatch(fetchTerminalInfoSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchTerminalInfoFailure(error)));
  };
}

export function updateTerminal(terminalIndex, name, value) {
  return (dispatch) => {
    dispatch(updateTerminalBegin(terminalIndex, name, value));
  };
}

export function fetchFreeTerminals() {
  return (dispatch) => {
    dispatch(fetchFreeTerminalsBegin());
    return getSiteObjectPromise(`${TERMINAL_RESOURCE}?unassigned`)
      .then((json) => {
        console.log(json.terminals);

        for (let j = 0; j < json.terminals.length; j++) {
          dispatch(getFreeTerminalsInfo(json.terminals[j].id));
        }

        dispatch(fetchFreeTerminalsSuccess(json.terminals));
        return json.terminals;
      })
      .catch((error) => dispatch(fetchFreeTerminalsFailure(error)));
  };
}

export function getFreeTerminalsInfo(terminalId) {
  return (dispatch) => getSiteObjectPromise(TERMINAL_RESOURCE, terminalId)
    .then((json) => {
      dispatch(fetchFreeTerminalInfoSuccess(json));
      return json;
    });
}

export const updateChromecastPort = (chromecastPort) => ({
  type: types.UPDATE_CHROMECAST_PORT_GENERAL,
  chromecastPort
});
