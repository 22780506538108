import { Grid } from '@mui/material';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSiteNumFromURI } from '../../utils';
import RoomsFilter from './components/RoomsFilter';
import RoomsGrid from './components/RoomsGrid';

export default function Rooms() {
  if (getSiteNumFromURI() === null) {
    return <Navigate replace to="/" />;
  }
  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <RoomsFilter />
      </Grid>
      <Grid item xs={12} style={{ height: 'calc(100% - 179px)', overflow: 'auto', paddingTop: 0 }}>
        <RoomsGrid />
      </Grid>
    </Grid>
  );
}
