import {
    FormControl, FormControlLabel, IconButton, List, ListItem, Radio, RadioGroup
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { mapStateToProps } from '../components/Reports/Pms/components/PmsFilter';

const styles = () => ({
  hideDropDownList: {
    display: 'none'
  },
  displayDropDownList: {
    border: 'solid thin black',
    borderColor: 'rgba(0,0,0,0.12)',
    backgroundColor: 'white',
    display: 'block',
    position: 'absolute',
    right: '25%',
    top: '100%',
    whiteSpace: 'pre',
    zIndex: 2
  },
  formatRadioButtons: {
    alignItems: 'start',
    width: 'fit-content',
  }

});

/** An component that diplays and icon which renders radio buttons and provides user defined actions.
 * PROPS:
 * @param {Component} buttonIcon A React component that renders an icon.
 * @param {object} data A object representing the contents displayed for each radio button, and must contain a label and value.
 * @param {function} itemSelectHandler A function which determines the action to take on selection of a button.
 * @param {string} checkedButton A string value matching the value of the selected button.
 */
class SonifiFilterDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hidden: true
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  handleButtonClick() {
    this.setState({ hidden: !this.state.hidden });
  }

  // TODO: Add the ability for a user to pass a type and render form control component based on that type
  render() {
    const {
      buttonIcon, data, containerStyles, itemSelectHandler, classes, checkedButton
    } = this.props;

    const { hidden } = this.state;

    return (
      <div className="sonifiFilterDropdown" style={containerStyles}>
        <IconButton onClick={this.handleButtonClick}>
          {buttonIcon}
        </IconButton>
        <List
          className="filterDropdownList"
          classes={hidden ? { root: classes.hideDropDownList } : { root: classes.displayDropDownList }}
        >
          <ListItem>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="room-filters"
                name="room-filters"
                onClick={itemSelectHandler.bind(this)}
                value={checkedButton}
              >
                {data.map((item, key) => (
                  <FormControlLabel
                    control={<Radio
                      classes={{ root: classes.formatRadioButtons }}
                    />}
                    key={`radioRoomFilter-${key}`}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </ListItem>
        </List>
      </div>
    );
  }
}

SonifiFilterDropDown.propTypes = {
  buttonIcon: PropTypes.element.isRequired,
  classes: PropTypes.object.isRequired,
  containerStyles: PropTypes.object,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dispatch: PropTypes.func,
  itemSelectHandler: PropTypes.func.isRequired,
  checkedButton: PropTypes.string
};

export default connect(mapStateToProps)(withStyles(SonifiFilterDropDown, styles));
