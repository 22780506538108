export const emptyAnalog = {
  modulator: '',
  tuning: 'std'
};

export const emptyIntlAnalog = {
  carrier: 'analog_air',
  frequency_khz: '',
  standard: 'NTSC'
};

export const emptyDigital = {
  modulator: '',
  stream: '',
  mbps: '0.000000',
  video_format: 'mpeg2',
  encryption: 'none',
  tuning: 'atsc'
};

export const emptyDvbC = {
  carrier: 'digital_cable',
  frequency_khz: '',
  stream: '',
  video_format: 'mpeg4',
  encryption: 'none',
  modulation: 'QPSK',
  bandwidth: '1.7',
  symbol_rate: 0
};
export const emptyDvbS = {
  carrier: 'digital_satellite',
  frequency_mhz: '',
  stream: '',
  video_format: 'mpeg4',
  encryption: 'none',
  modulation: 'QPSK',
  bandwidth: '1.7',
  symbol_rate: 0,
  satellite_id: 0,
  polarization: 'left'
};
export const emptyDvbT = {
  carrier: 'digital_air',
  frequency_khz: '',
  stream: '',
  video_format: 'mpeg4',
  encryption: 'none',
  modulation: 'QPSK',
  bandwidth: '1.7',
  plp_id: ''
};

export const emptyIP = {
  address: '',
  port: '',
  stream: '',
  video_format: 'mpeg2',
  encryption: 'none',
  mbps: '0.000000',
};

export const emptyChannel = {
  id: 0,
  type: 'ipg',
  locked: false,
  enabled: true,
  ipg: {
    source_id: '',
    affiliate: '',
    call_letters: '',
    entry: '',
    duration: '',
    description: ''
  }
};

