import { compact, uniqBy } from 'lodash';
import moment from 'moment';
import {
  DISPLAY_TYPE_ARRAY, LIBRARY_RESOURCE, requiredMessageFields, TAKEOVER_TYPE_ARRAY, validTranslations
} from '../../../constants/messages';
import { getSiteObjectPromise } from '../../../utils/api';
import { textHelper } from '../../../utils/textUtil';

export function validStartTimestamp(value, startNow, error) {
  if (startNow) {
    return null;
  } else if (value === undefined || value === null) {
    return {
      fieldName: 'start_timestamp',
      message: error.startDate
    };
  }
  return null;
}

export function validEndTimestamp(value, timezone, error) {
  const fieldName = 'end_timestamp';
  if (value.neverExpires) {
    return null;
  }

  // get current time in timezone and compare to see if date is in the past
  const endDate = moment(value.end_timestamp).tz(timezone, true);
  const now = moment().tz(timezone);
  const startDate = moment(value.start_timestamp).tz(timezone, true);

  if ((value.end_timestamp === undefined) || (value.end_timestamp === null)) {
    return {
      fieldName,
      message: error.endDate // End Date cannot be empty
    };
  } else if (endDate < now) {
    return {
      fieldName,
      message: error.endPast // datetime is in the past in that timezone
    };
  } else if (endDate <= startDate) {
    return {
      fieldName,
      message: error.endBeforeStart // datetime is before start date
    };
  }
  return null;
}

export function validMessageId(value, error, globalTranslations) {
  if (value === undefined || value === null) {
    return error.blank;
  }

  const strVal = `${value}`;
  if (strVal.trim() === '') {
    return error.blank;
  } else if (textHelper(strVal.trim())) {
    return globalTranslations.symbols;
  }
  return '';
}

export function validDisplayType(value) {
  return DISPLAY_TYPE_ARRAY.includes(value);
}

export function validTakeoverType(value) {
  return TAKEOVER_TYPE_ARRAY.includes(value);
}

// check against required fields.  Promise.all is used because the validation for
// Message Name requires an ajax call
export function getFormErrors(
  message, globalTranslations, translation, requiredFields = requiredMessageFields, timezone
) {
  return new Promise((resolve, reject) => {
    let promises = [];

    // create an array of promises to pass to promise.all
    promises = requiredFields.map((field) => validateField(
      field, message, globalTranslations, translation, timezone
    )
      .catch((e) => e));

    Promise.all(promises)
      .then((fieldValidationErrors) => {
        resolve(uniqBy(compact(fieldValidationErrors)), 'fieldName');
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function validateField(fieldName, message, globalTranslations, translation, timezone) {
  return new Promise((resolve) => {
    let titleObj = null;
    if (fieldName === 'title' || fieldName === 'body') {
      titleObj = validTranslations(message.translations, globalTranslations.errors, translation.errors);
    }
    let returnObj;

    switch (fieldName) {
      case 'nameDropdown':
        returnObj = validMessageId(message.nameDropdown, translation.errors, globalTranslations.errors);
        returnObj.length === 0
          ? resolve() // reject(new Error('Name is not valid'))
          : resolve({ fieldName, message: translation.errors.nameDropDown });
        break;
      case 'start_timestamp':
        returnObj = validStartTimestamp(message.start_timestamp, message.startNow, translation.errors);
        // (returnObj === null ? reject(new Error('Start timestamp is not valid')) : resolve(returnObj));
        returnObj === null ? resolve() : resolve(returnObj);
        break;
      case 'end_timestamp':
        returnObj = validEndTimestamp(message, timezone, translation.errors);
        // (returnObj === null ? reject(new Error('End timestamp is not valid')) : resolve(returnObj));
        returnObj === null ? resolve() : resolve(returnObj);
        break;
      case 'messageId':
        returnObj = validMessageId(message.messageId, translation.errors, globalTranslations.errors);
        if (returnObj.length === 0) {
          if (message.origMessageId !== message.messageId) {
            getSiteObjectPromise(LIBRARY_RESOURCE, encodeURIComponent(message[fieldName]))
              .then(() => {
                resolve({ fieldName, message: translation.errors.inuse });
              }).catch((error) => {
                console.log('This message does not exist and can be saved!', error);
                resolve();
              });
          } else {
            resolve();
          }
        } else {
          resolve({ fieldName, message: returnObj });
        }
        break;
      case 'title':
        (!titleObj.title || titleObj.title.length === 0)
          ? resolve() // reject(new Error('Title must be defined'))
          : resolve({ fieldName, message: titleObj.title });
        break;
      case 'body':
        (!titleObj.body || titleObj.body.length === 0)
          ? resolve() // reject(new Error('Body must not be empty'))
          : resolve({ fieldName, message: titleObj.body });
        break;
      case 'display_type':
        validDisplayType(message[fieldName])
          ? resolve() // reject(new Error('Display type is not valid'))
          : resolve({ fieldName, message: translation.errors.displayType });
        break;
      case 'takeover_type':
        (validTakeoverType(message.takeover_type)
          ? resolve() // reject(new Error('Takeover type is not valid'))
          : resolve({ fieldName, message: translation.errors.tvTurnOn }));
        break;
      case 'rooms':
        (message.rooms && message.rooms.length > 0
          ? resolve() // reject(new Error('Rooms must not be empty'))
          : resolve({ fieldName, message: translation.errors.needRoomSelection }));
        break;
      default:
        // reject(new Error(`${fieldName}:Invalid key passed`));
        console.log(`${fieldName} not in case statements`);
        resolve();
        break;
    }
  });
}
