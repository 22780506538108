import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { KEYBOARD_DELAY } from '../../constants/keyCodes';
import { USER_MANAGEMENT_ROLES } from '../../constants/roleGroups';
import { USERACCOUNT_EDIT } from '../../constants/roles';
import SonifiSnackBar from '../../containers/SonifiSnackBar';
import SonifiTemplate from '../../containers/SonifiTemplate';
import { checkForAtLeastOneUserPermission, checkSingleUserPermission } from '../../utils/rolesUtil';
import { fetchGroups, getSites } from '../GroupManagement/actions/groupActions';
import { fetchRoles } from '../RoleManagement/actions/roleActions';
import {
  clearUsers, fetchUsers, initialDataLoad, setFilter, sortUsers, updateSelectedUser, updateSnackBar
} from './actions/userActions';
import UserFilter from './components/UserFilter';
import UserGrid from './components/UserGrid';

class UserManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      groupFilter: '',
      order: 'asc',
      orderBy: undefined,
    };

    this.pageChangeHandler = this.pageChangeHandler.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
  }

  componentDidMount() {
    const { allSites, dispatch, userGroup } = this.props;
    const { filter } = this.state;

    dispatch(initialDataLoad(true));
    dispatch(clearUsers());
    dispatch(fetchRoles());
    dispatch(fetchUsers(userGroup, filter, false));
    dispatch(fetchGroups(userGroup, 'description:asc', filter, false));
    if (allSites === null) {
      dispatch(getSites({ limit: 10000 }, true));
    }
  }

  pageChangeHandler() {
    const { dispatch, userGroup, paginationToken } = this.props;
    const { filter } = this.state;
    dispatch(initialDataLoad(false));
    dispatch(fetchUsers(userGroup, filter, false, paginationToken));
  }

  handleRequestSort = (property) => {
    const { order } = this.state;
    const { dispatch, users } = this.props;

    const newOrderBy = property;
    let newOrder = '';
    if (!order) {
      newOrder = 'asc';
    } else if (order === 'asc') {
      newOrder = 'desc';
    } else {
      newOrder = 'asc';
    }

    this.setState({
      order: newOrder,
      orderBy: newOrderBy
    });

    dispatch(sortUsers(users, `${newOrderBy}:${newOrder}`));
  };

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  filterUser = ({ target: { value } }) => {
    const { dispatch } = this.props;
    dispatch(clearUsers());
    dispatch(initialDataLoad(true));
    this.setState({ filter: value }, () => {
      this.debouncedLoadMoreData();
    });
    dispatch(setFilter(value));
  };

  filterData() {
    this.props.dispatch(fetchUsers(this.props.userGroup,
      this.state.filter, true));
  }

  closeSnackBar = () => {
    this.props.dispatch(updateSnackBar(false));
  };

  handleButtonClick = () => {
    const { dispatch, userAssignedSites } = this.props;
    dispatch(updateSelectedUser({ id: null, description: null, sites: userAssignedSites, isNew: true }));
  };

  getPageDetails() {
    const { allowEditing, userPermissions } = this.props;
    const { orderBy, order } = this.state;
    const canEdit = checkSingleUserPermission(USERACCOUNT_EDIT, userPermissions);
    return (
      <Grid container style={{ alignContent: 'start', width: '100%' }}>
        <Grid item xs={12}>
          {allowEditing &&
            <UserFilter filterUser={this.filterUser.bind(this)} userState={this.state} canEdit={canEdit} />
          }
        </Grid>
        <Grid item xs={12} >
          <UserGrid
            userState={this.state}
            handleRequestSort={this.handleRequestSort.bind(this)}
            canEdit={canEdit}
            pageChangeHandler={this.pageChangeHandler}
            currentPage={this.state.currentPage}
            order={order}
            orderBy={orderBy}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      snackBar, snackbarOpen, snackBarMessage, translations, userPermissions
    } = this.props;

    if (!checkForAtLeastOneUserPermission(USER_MANAGEMENT_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    const canEdit = checkSingleUserPermission(USERACCOUNT_EDIT, userPermissions);

    return (
      <Fragment>
        <SonifiTemplate
          header={translations.title}
          pageDetails={this.getPageDetails()}
          icon={<Add />}
          label={translations.user}
          onSubmit={this.handleButtonClick.bind(this)}
          showButton={canEdit}
        />
        <SonifiSnackBar message={snackBarMessage} variant={snackBar}
          open={snackbarOpen} onClose={this.closeSnackBar} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allowEditing: state.users.allowEditing,
  allSites: state.groups.allSites,
  paginationToken: state.users.paginationToken,
  snackBar: state.users.snackBar,
  snackBarMessage: state.users.snackBarMessage,
  snackbarOpen: state.users.snackbarOpen,
  translations: state.users.translations.grid,
  userAssignedSites: state.global.userAssignedSites,
  userGroup: state.global.group,
  userPermissions: state.global.permissions,
  users: state.users.users
});

UserManagement.propTypes = {
  allowEditing: PropTypes.bool,
  allSites: PropTypes.array,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  paginationToken: PropTypes.string,
  snackBar: PropTypes.string,
  snackBarMessage: PropTypes.string,
  snackbarOpen: PropTypes.bool,
  translations: PropTypes.object,
  userGroup: PropTypes.string,
  userAssignedSites: PropTypes.array,
  userPermissions: PropTypes.array,
  users: PropTypes.array
};

export default connect(mapStateToProps)(UserManagement);
