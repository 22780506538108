import { Grid } from '@mui/material';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SonifiText } from '../../../../containers';
import { CORE, durations } from '../../constants/constants';

export default class CoreForm extends Component {
  editStream = ({ target: { value } }) => {
    const selectedChannel = find(this.props.coreChannels, { id: value });
    if (selectedChannel.duration === null) {
      selectedChannel.duration = 'PT1H';
    }
    this.props.edit(CORE, selectedChannel);
  };

  /* eslint-disable camelcase */
  render() {
    const {
      coreChannels, coreEdit, errors, streamInfo, readOnly, translations
    } = this.props;

    const {
      description, duration, entry, id, source_id
    } = streamInfo;

    return (
      <Grid container>
        <Grid item xs={6}>
          <SonifiText
            change={this.editStream}
            defaultValue={id}
            disabled={readOnly}
            items={coreChannels.map((suggestion) => ({
              id: suggestion.id,
              value: suggestion.id,
              label: `${suggestion.name}`
            }))}
            label={translations.coreChannel}
            select={true}
            size="percent"
          />
        </Grid>
        <Grid item xs={6}>
          <SonifiText defaultValue={source_id} label="Source ID" disabled={true} size="percent" />
        </Grid>
        <Grid item xs={6}>
          <SonifiText
            change={coreEdit(CORE, 'duration')}
            defaultValue={duration ?? durations[0].value}
            disabled={readOnly || source_id === ''}
            items={durations}
            label={translations.duration}
            select={true}
            size="percent"
          />
        </Grid>
        <Grid item xs={6}>
          <SonifiText
            change={coreEdit(CORE, 'entry')}
            defaultValue={entry}
            disabled={readOnly || source_id === ''}
            error={!!errors.entry}
            errorText={(errors.entry ? errors.entry : '')}
            label={translations.title}
            size="md"
          />
        </Grid>
        <Grid item xs={12}>
          <SonifiText
            change={coreEdit(CORE, 'description')}
            defaultValue={description}
            disabled={readOnly || source_id === ''}
            error={!!errors.description}
            errorText={(errors.description ? errors.description : '')}
            label={translations.description}
            multiline={true}
            rows={3}
            size="textBox"
          />
        </Grid>
      </Grid>
    );
  }
}

CoreForm.propTypes = {
  coreChannels: PropTypes.array,
  coreEdit: PropTypes.func,
  edit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
  selectedChannel: PropTypes.object,
  streamInfo: PropTypes.object,
  translations: PropTypes.object
};
