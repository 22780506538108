import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { SonifiSubHeader, SonifiText } from '../../../../../containers';
import {
  bandwidthValues, dvbcAndsModulationValues, DVB_C, encryptionValues, mpegFormatValues
} from '../../../constants/constants';

export default class IntlDvbcForm extends Component {
  render() {
    const { dvbcObj, errors, readOnly, translations } = this.props;

    return (
      <Fragment>
        <Grid item>
          <SonifiSubHeader
            header={'International Settings'}
            showButton={false}
          />
          <SonifiText
            defaultValue={dvbcObj.carrier}
            label={translations.carrier}
            size="md"
            disabled={true} />
          <SonifiText
            defaultValue={dvbcObj.frequency_khz}
            change={this.props.editFunction(DVB_C, 'frequency_khz')}
            label={translations.frequencyKhz}
            size="md"
            error={!!errors.dvbcFrequency}
            errorText={(errors.dvbcFrequency ? errors.dvbcFrequency : '')}
            disabled={readOnly} />
        </Grid>
        <Grid item>
          <SonifiText
            defaultValue={dvbcObj.video_format}
            change={this.props.editFunction(DVB_C, 'video_format')}
            select={true}
            label={translations.dFormat}
            size="md"
            items={mpegFormatValues}
            digitalFormat
            error={!!errors.dvbcFormat}
            errorText={(errors.dvbcFormat ? errors.dvbcFormat : '')}
            disabled={readOnly} />
          <SonifiText
            defaultValue={dvbcObj.encryption}
            change={this.props.editFunction(DVB_C, 'encryption')}
            select={true}
            label={translations.dEncryption}
            size="md"
            items={encryptionValues}
            error={!!errors.dvbcEncryption}
            errorText={(errors.dvbcEncryption ? errors.dvbcEncryption : '')}
            disabled={readOnly} />
        </Grid>
        <Grid item>
          <SonifiText
            defaultValue={dvbcObj.stream}
            change={this.props.editFunction(DVB_C, 'stream')}
            label={translations.dIntlStream}
            size="md"
            error={!!errors.dvbcStream}
            errorText={(errors.dvbcStream ? errors.dvbcStream : '')}
            disabled={readOnly} />
          <SonifiText
            defaultValue={dvbcObj.modulation}
            change={this.props.editFunction(DVB_C, 'modulation')}
            select={true}
            label={translations.dModulation}
            size="md"
            items={dvbcAndsModulationValues}
            error={!!errors.dvbcModulation}
            errorText={(errors.dvbcModulation ? errors.dvbcModulation : '')}
            disabled={readOnly} />
        </Grid>
        <Grid item>
          <SonifiText
            defaultValue={dvbcObj.bandwidth}
            change={this.props.editFunction(DVB_C, 'bandwidth')}
            select={true}
            label={translations.dBandwidth}
            size="md"
            items={bandwidthValues}
            error={!!errors.dvbcBandwidth}
            errorText={(errors.dvbcBandwidth ? errors.dvbcBandwidth : '')}
            disabled={readOnly} />
          <SonifiText
            defaultValue={dvbcObj.symbol_rate}
            change={this.props.editFunction(DVB_C, 'symbol_rate')}
            label={translations.dSymbolRate}
            size="md"
            error={!!errors.dvbcSymbolRate}
            errorText={(errors.dvbcSymbolRate ? errors.dvbcSymbolRate : '')}
            disabled={readOnly} />
        </Grid>
      </Fragment>
    );
  }
}

IntlDvbcForm.propTypes = {
  dvbcObj: PropTypes.object,
  editFunction: PropTypes.func.isRequired,
  editNonEventFunction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
  translations: PropTypes.object,
  tuning: PropTypes.string
};
