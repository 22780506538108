import { arrayMoveImmutable } from 'array-move';
import { orderBy } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  deleteOption: null,
  deleting: false,
  editType: '',
  fetchingOptions: false,
  fetchingRoomTypes: false,
  draggingRow: -1, // Styling Flag
  droppedRow: -1, // Styling Flag
  // loading: false,
  options: [],
  removingRoomType: false,
  roomTypes: [],
  saving: false,
  savingOptions: false,
  selectedOption: null,
  selectedRoomTypes: [],
  showRoomTypesDialog: false,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  sorting: false,
  translations: null
};

/* eslint-disable max-lines-per-function */
export default function lateCheckoutReducer(state = initialState, action) {
  let miscObj = null,
    miscObj2 = null,
    index = 0;
  switch (action.type) {
    case types.DELETE_OPTION_CHECK:
      return { ...state, deleteOption: action.option, snackBarMessage: '' };

    case types.DELETE_OPTION_BEGIN:
      return { ...state, deleting: true };

    case types.DELETE_OPTION_SUCCESS:
      return {
        ...state,
        deleting: false,
        deleteOption: null,
        snackBarMessage: `${state.translations.options.deleteSuccess} ${action.time}`,
        snackBarType: SUCCESS
      };

    case types.DELETE_OPTION_FAILURE:
      return {
        ...state,
        deleting: false,
        deleteOption: null,
        snackBarMessage: `${state.translations.options.deleteFailure} ${action.time}`,
        snackBarType: ERROR
      };

    case types.GET_OPTIONS_BEGIN:
      return {
        ...state, fetchingOptions: true, options: [], snackBarMessage: '', selectedRoomTypes: []
      };

    case types.GET_OPTIONS_SUCCESS:
      miscObj2 = new Date();
      miscObj = orderBy((action.lateCheckouts ? [...action.lateCheckouts] : []), [(a) => a.sequence], ['asc']);
      miscObj.forEach((part) => {
        const splitTime = part.id.split('+');
        part.time = `${miscObj2.toDateString()} ${splitTime[0]}`;
        part.sequence = index++;
      });

      return {
        ...state,
        fetchingOptions: false,
        options: miscObj, // orderBy(miscObj, [(a) => a.sequence], ['asc']), // miscObj,
        selectedRoomTypes: (action.roomTypes ? action.roomTypes : [])
      };

    case types.GET_OPTIONS_FAILURE:
      return {
        ...state,
        fetchingOptions: false,
        options: null,
        selectedRoomTypes: null
      };

    case types.FETCH_ROOM_TYPES_BEGIN:
      return { ...state, fetchingRoomTypes: true, roomTypes: [] };

    case types.FETCH_ROOM_TYPES_SUCCESS:
      return { ...state, fetchingRoomTypes: false, roomTypes: action.roomTypes.sort() };

    case types.FETCH_ROOM_TYPES_FAILURE:
      return { ...state, fetchingRoomTypes: false };

    case types.MOVE_OPTION:
      miscObj2 = JSON.parse(JSON.stringify(state.options));
      miscObj = arrayMoveImmutable(miscObj2, action.oldIndex, action.newIndex);

      miscObj.forEach((part) => {
        part.sequence = index++;
      });

      return { ...state, options: miscObj, selectedChannel: -1 };

    case types.REMOVE_SELECTED_ROOM_TYPE_BEGIN:
      return { ...state, removingRoomType: true };

    case types.REMOVE_SELECTED_ROOM_TYPE_SUCCESS:
      miscObj = [...state.selectedRoomTypes];
      return {
        ...state,
        removingRoomType: false,
        selectedRoomTypes: miscObj.filter((e) => e !== action.item),
        snackBarMessage: `${state.translations.roomType.deleteSuccess} ${action.item}`,
        snackBarType: SUCCESS
      };

    case types.REMOVE_SELECTED_ROOM_TYPE_FAILURE:
      miscObj = [...state.selectedRoomTypes];
      return {
        ...state,
        removingRoomType: false,
        snackBarMessage: `${state.translations.roomType.deleteFailure} ${action.item}`,
        snackBarType: ERROR
      };

    case types.SHOW_ROOM_TYPES_DIALOG:
      return { ...state, showRoomTypesDialog: action.show };

    case types.REORDER_SAVE_BEGIN:
      return { ...state, savingOptions: true, snackBarMessage: '' };

    case types.REORDER_SAVE_SUCCESS:
      return {
        ...state,
        savingOptions: false,
        snackBarMessage: state.translations.options.reOrderSuccess,
        snackBarType: SUCCESS
      };

    case types.REORDER_SAVE_FAILURE:
      return {
        ...state,
        savingOptions: false,
        snackBarMessage: `${state.translations.options.reOrderFailure} - ${action.error}`,
        snackBarType: ERROR
      };

    case types.RESET_SNACKBAR:
      return {
        ...state,
        saving: false,
        snackBarMessage: action.snackBarMessage,
        snackBarType: action.snackBarType ? action.snackBarType : state.snackBarType,
      };

    case types.SAVE_OPTION_BEGIN:
      return { ...state, saving: true, snackBarMessage: '' };

    case types.SAVE_OPTION_SUCCESS:
      return {
        ...state,
        saving: false,
        selectedOption: null,
        snackBarMessage:
          `${state.translations.optionDialog.saveSuccess} ${action.time}`,
        snackBarType: SUCCESS
      };

    case types.SAVE_OPTION_FAILURE:
      return {
        ...state,
        saving: false,
        snackBarMessage:
          `${state.translations.optionDialog.saveFailure} ${action.time}`,
        snackBarType: ERROR
      };

    case types.SAVE_ROOM_TYPES_BEGIN:
      return { ...state, savingRoomTypes: true };

    case types.SAVE_ROOM_TYPES_SUCCESS:
      return {
        ...state,
        savingRoomTypes: false,
        showRoomTypesDialog: false,
        snackBarMessage: state.translations.roomType.saveSuccess,
        snackBarType: SUCCESS
      };

    case types.SAVE_ROOM_TYPES_FAILURE:
      return {
        ...state,
        savingRoomTypes: false,
        snackBarMessage: `${state.translations.roomType.saveFailure} - ${action.error}`,
        snackBarType: ERROR
      };

    case types.SELECT_OPTION:
      return { ...state, selectedOption: action.option, editType: action.editType, snackBarMessage: '' };

    case types.SORT_ROOM_TYPES:
      return { ...state, roomTypes: [...state.roomTypes.reverse()] };

    case types.SET_DRAGGING_ROW:
      return { ...state, draggingRow: action.rowIndex };

    case types.SET_DROPPED_ROW:
      return { ...state, droppedRow: action.rowIndex };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
