/* eslint-disable valid-jsdoc */
import { Search } from '@mui/icons-material';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../constants/keyCodes';
import { getObjectPromise, getSiteObjectPromise } from '../utils/api';

const _ONE = 1;

const styles = (theme) => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  small: {
    maxWidth: '210px',
    marginBottom: theme.spacing(_ONE),
    marginTop: theme.spacing(_ONE),
  },
  inputSmall: {
    'padding': '0px !important',
    '& input': {
      padding: '0px 5px'
    }
  },
  medium: {
    maxWidth: '420px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  inputMedium: {
    'padding': '14px 0px 14px 0px',
    '& input': {
      padding: '0px 10px'
    }
  },
  large: {
    maxWidth: '840px',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  inputLarge: {
    'padding': '28px 0px 28px 0px',
    '& input': {
      padding: '0px 20px'
    }
  }
});

const introFilterMessage = 'Please start typing to begin filter.';
const notFoundFilterMessage = 'No Options Found.';

/**
 * A generalized searchable dropdown component
 *
 * @param {string} placeholder This is prop is used as a placeholder in the text field before typing
 * @param {function} onSubmitSearch This is prop can be used to trigger any side effects after user selects an optioin
 * @param {string} resource This is a prop used in the declare which object you are requesting from the api
 * @param {boolean} siteObject This prop determines if the requested rersource is a site resource
 * @param {object} dataName The key name of the data in the response object, must be at root level
 * @param {function} filterFunction This props takes a function which returns an object with key value pairs
 * representing the filter option of the data request
 * @param {function} displayFunction This props takes a function which returns an object with two keys ('value' & 'label)
 * which are mapped to the specified data object attributes.
 * @param {array} data This prop is the default data to be displayed on select before user filters
 * @param {string} size This prop will change the size of search text field. Optioins are ('small'(default), 'medium', large')
 * @returns {object}Searchable Dropdown
 */
export class SonifiSearchDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: '',
      key: new Date().toString(),
      loading: false,
      open: false,
      selected: false,
      selection: { value: null, label: null },
      options: [{ value: null, label: introFilterMessage }],
      defaultOptions: []
    };
  }

  componentDidMount() {
    if (this.props.data) {
      const preloadOptions = this.props.data.map((obj) => this.props.displayFunc(obj));

      const newOptions = this.state.options.concat(preloadOptions);
      this.setState({ options: newOptions, defaultOptions: newOptions });
    }
  }

  getSizeClassNames() {
    const { classes, size } = this.props;
    let rootSizeClass, inputSizeClass;

    switch (size) {
      case 'medium':
        rootSizeClass = classes.medium;
        inputSizeClass = classes.inputMedium;
        break;
      case 'large':
        rootSizeClass = classes.large;
        inputSizeClass = classes.inputLarge;
        break;
      default:
        rootSizeClass = classes.small;
        inputSizeClass = classes.inputSmall;
    }

    return { rootSizeClass, inputSizeClass };
  }

  // There are other parameters/options that can be passed to debounce.
  // I believe this is the perfect situation to use this function, but we
  // may find that it needs to be tweaked a little more
  // *https://lodash.com/docs/4.17.15#debounce*
  debouncedLoadMoreData = debounce(this.loadMoreData, KEYBOARD_DELAY);

  loadMoreData() {
    if (this.state.filter && this.state.filter !== '') {
      this.getFilteredData(this.state.filter).then((data) => {
        this.setState({
          loading: false,
          options: ((!data || data.length < 1) ? [{ value: null, label: notFoundFilterMessage }] : data)
        });
      });
    } else {
      this.setState({
        loading: false,
        options: [{ value: null, label: notFoundFilterMessage }]
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filter !== prevState.filter) {
      if (this.state.filter.length === 0) {
        this.setState({
          loading: false,
          options: this.state.defaultOptions
        });
      } else if (this.state.filter && this.state.filter.length) {
        this.setState({
          loading: true
        });
        this.debouncedLoadMoreData();
      }
    }
  }

  getFilteredData(filter) {
    const {
      dataName, displayFunc, filterFunc, info, resource, siteId, siteObj
    } = this.props;

    return new Promise((resolve) => {
      if (siteObj) {
        getSiteObjectPromise(resource, info, siteId,
          filterFunc(filter)).then((data) => {
            const options = data[dataName].map((d) => (displayFunc(d)));
            resolve(options);
          }).catch((error) => {
            console.log('Error-3: ', error);
            resolve([]);
          });
      } else {
        getObjectPromise(resource, info, filterFunc(filter)).then((data) => {
          const options = data[dataName].map((d) => (displayFunc(d)));
          resolve(options);
        }).catch((error) => {
          console.log('Error-4: ', error);
          resolve([]);
        });
      }
    });
  }

  filterOptions = (options) => (options);

  setOpen(open) {
    this.setState({ open });
  }

  // Represents value displayed in box
  handleInputChange = (event, newInputValue, reason) => {
    if (reason === 'clear') {
      this.setState({ filter: '' });
    } else if (reason === 'reset' && newInputValue !== '') {
      if (newInputValue === introFilterMessage || newInputValue === notFoundFilterMessage) {
        this.setState({ filter: '' });
      } else if (newInputValue) {
        this.setState({ filter: newInputValue });
      }
    }
  };

  handleChange = (event) => {
    const value = event.target.value;
    this.setState({ filter: value });
  };

  // Represents values selected by user
  handleSelect = (event, item) => {
    if (event.type === 'blur') {
      return;
    }

    if (item && item.value !== null) {
      this.setState({
        filter: item.label,
        key: new Date().toString(),
        selected: true,
        selection: { value: item.value, label: item.label }
      });

      this.props.onSubmitSearch(event);
    } else if (item && item.value === null) {
      this.setState({
        key: new Date().toString(),
        selected: true,
        selection: { value: null, label: null }
      });
    }
  };

  render() {
    const {
      key, loading, open, options, selected, selection, filter
    } = this.state;
    const {
      classes, disabled, error, errorText, helperText, label
    } = this.props;

    const showError = ((typeof error === 'undefined') || (error === null)) ? false : error;
    const sizeObject = this.getSizeClassNames();

    return (
      <Autocomplete
        key={key}
        disabled={disabled}
        className={`${classes.root} ${sizeObject.inputSizeClass}`}
        open={open}
        onOpen={() => {
          this.setOpen(true);
        }}
        onClose={() => {
          this.setOpen(false);
        }}
        autoHighlight={true}
        autoSelect={true}
        freeSolo={true}
        filterOptions={this.filterOptions}
        getOptionLabel={(option) => (option.label || '')}
        options={options}
        inputValue={filter ?? ''}
        value={selection.label ?? ''}
        onChange={this.handleSelect}
        onInputChange={this.handleInputChange}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            className={`${classes.root} ${sizeObject.rootSizeClass}`}
            variant="filled"
            onChange={this.handleChange}
            error={showError}
            helperText={(showError && errorText) ? errorText : helperText}
            placeholder={selected ? selection.label : this.props.placeholder}
            InputProps={{
              ...params.InputProps,
              className: (classes.input, sizeObject.inputSizeClass),
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment: (
                <Search />
              ),
            }}
          />
        )
        }
      />
    );
  }
}

SonifiSearchDropdown.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  displayFunc: PropTypes.func.isRequired,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  filterFunc: PropTypes.func.isRequired,
  getRefFunc: PropTypes.func,
  helperText: PropTypes.string,
  info: PropTypes.string,
  label: PropTypes.string,
  onSubmitSearch: PropTypes.func,
  placeholder: PropTypes.string,
  resource: PropTypes.string.isRequired,
  siteId: PropTypes.string,
  siteObj: PropTypes.bool,
  size: PropTypes.string
};

export default withStyles(SonifiSearchDropdown, styles, { withTheme: true });
