import { Button, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export default class SonifiButton extends Component {
  handleSubmit = (event) => {
    this.props.onClick(event);
  };

  getButton() {
    const {
      caption, disabled, fullWidth, marginTop, noLeftRightMargin, secondary, tertiary, floatRight
    } = this.props;

    return <Button
      sx={{
        'backgroundColor': ((theme) => (secondary
          ? theme.palette.primary.cancelBtn
          : (tertiary
            ? theme.palette.primary.contrastText
            : theme.palette.secondary.main))),
        'color': ((theme) => (tertiary ? theme.palette.primary.cancelBtn : theme.palette.primary.contrastText)),
        'float': (floatRight ? 'right' : ''),
        'marginRight': ((theme) => (noLeftRightMargin ? '0px' : theme.spacing(2))),
        'minWidth': '100px',
        '&:hover': {
          backgroundColor: ((theme) => theme.palette.primary.hoverColor)
        },
        'marginTop': (marginTop ? '5px' : '0px'),
        'width': (fullWidth ? '100%' : 'inherit')
      }
      }
      disabled={disabled}
      onClick={this.handleSubmit}
      variant="contained"
    >
      {caption}
    </Button>;
  }

  render() {
    const { toolTip } = this.props;

    if (toolTip && toolTip.length > 0) {
      return (<Tooltip title={toolTip}>
        <span style={{ fontSize: '12pt' }}>
          {this.getButton()}
        </span>
      </Tooltip>);
    }

    return this.getButton();
  }
}
SonifiButton.propTypes = {
  caption: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  floatRight: PropTypes.bool,
  fullWidth: PropTypes.bool,
  marginTop: PropTypes.bool,
  noLeftRightMargin: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  toolTip: PropTypes.string
};
