import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { union, xor } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiCheckbox, SonifiLabel } from '../../../containers';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import { sortRoomTypes } from '../actions/lateCheckoutActions';

class RoomTypeTable extends Component {
  state = {
    filterActive: false,
    loaded: false,
    order: 'asc',
    selectAllChecked: false,
    selected: []
  };

  componentDidMount() {
    this.setState({ selected: [...this.props.selectedRoomTypes] });
  }

  componentDidUpdate() {
    if (this.props.filter.length > 0 && !this.state.filterActive) {
      this.setState({ filterActive: true });
    } else if (this.props.filter.length === 0 && this.state.filterActive) {
      this.setState({ filterActive: false, selectAllChecked: false });
    }
  }

  checkboxChange = () => () => {
    const { filter, roomTypes } = this.props;
    const { selected, selectAllChecked } = this.state;
    const tempArr = [];
    roomTypes.map((item) => (
      tempArr.push(item.name)
    ));

    const filteredList =
      tempArr.filter((suggestion) => (suggestion.toLowerCase().indexOf(filter.toLowerCase()) > -1));

    if (selectAllChecked) {
      this.setState({
        selected: xor(selected, filteredList), selectAllChecked: false
      }, () => {
        this.props.updateSelectedRoomTypes(this.state.selected);
      });
    } else {
      this.setState({
        selected: union(selected, filteredList), selectAllChecked: true
      }, () => {
        this.props.updateSelectedRoomTypes(this.state.selected);
      });
    }
  };

  tableHeads = () => [
    {
      id: '',
      sortable: false,
      numeric: false,
      centerHeader: false,
      label: '',
      checkbox: true,
      checkValue: this.state.selectAllChecked,
      onChange: this.checkboxChange(),
      noLabel: true,

      // disabled: this.props.disabled,
      // error: (this.props.errors && this.props.errors.channels)
    },
    {
      id: 'type', sortable: true, numeric: true, centerHeader: false, label: this.props.translations.type
    }
  ];

  isItemInList = (item) => {
    const { selected } = this.state;
    if (selected.length === 0) {
      return false;
    }
    return (selected.includes(item));
  };

  onItemChange = (item) => () => {
    const { selected } = this.state;
    const updateSelected = [...selected];
    const index = updateSelected.findIndex((channel) => channel === item);

    if (index === -1) {
      updateSelected.push(item);
    } else if (index !== -1) {
      updateSelected.splice(index, 1);
    }

    this.setState({ selected: updateSelected, selectAllChecked: false });
    this.props.updateSelectedRoomTypes(updateSelected);
  };

  handleRequestSort = () => {
    this.setState({
      order: this.state.order === 'desc' ? 'asc' : 'desc'
    }, () => {
      this.props.dispatch(sortRoomTypes());
    });
  };

  render() {
    const { roomTypes, translations } = this.props;
    const { order } = this.state;
    if (!roomTypes) {
      return <Fragment />;
    }
    const tempRoomTypes = [];
    roomTypes.map((item) => (
      tempRoomTypes.push(item.name)
    ));

    const filteredList =
      tempRoomTypes.filter((suggestion) => (suggestion.toLowerCase().indexOf(this.props.filter.toLowerCase()) > -1));

    return (
      <div style={{
        overflow: 'auto',
        maxHeight: 'calc(88vh - 300px)',
        minHeight: 'calc(88vh - 300px)',
        borderBottom: '1px solid #dedfdf'
      }}>
        <Table>
          <SonifiTableHead
            headColumns={this.tableHeads()}
            order={order}
            orderBy="type"
            onRequestSort={this.handleRequestSort.bind(this)}
            hideSortIcon={true}
          />
          <TableBody>
            {roomTypes.length < 1 || filteredList.length < 1
              ? <TableRow >
                <TableCell colSpan={5} style={{ textAlign: 'left' }}>
                  <SonifiLabel error label={translations.noRoomTypes} />
                </TableCell>
              </TableRow>
              : filteredList.map((item, index) => (
                <TableRow key={index} onClick={this.onItemChange(item)}>
                  <TableCell>
                    <SonifiCheckbox
                      noLabel={true}
                      label=""

                      // disabled={disabled}
                      value={this.isItemInList(item)}
                    />
                  </TableCell>
                  <TableCell>{item}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  roomTypes: state.lateCheckout.roomTypes,
  selectedRoomTypes: state.lateCheckout.selectedRoomTypes,
  translations: state.lateCheckout.translations.roomTypeDialog
});

RoomTypeTable.propTypes = {
  dispatch: PropTypes.func,
  filter: PropTypes.string,
  roomTypes: PropTypes.array,
  selectedRoomTypes: PropTypes.array,
  translations: PropTypes.object,
  updateSelectedRoomTypes: PropTypes.func,
};

export default connect(mapStateToProps)(RoomTypeTable);
