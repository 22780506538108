import SearchIcon from '@mui/icons-material/Search';
import {
    Grid, Paper, Table, TableBody, TableCell, TableFooter, TableRow
} from '@mui/material';
import { debounce, find, findIndex, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { DISTANCE_FROM_END } from '../../../constants/magic';
import {
    SonifiCheckbox, SonifiLabel, SonifiSpinner, SonifiTableHead, SonifiText
} from '../../../containers/';
import { findObjectByKey } from '../../../utils';
import { getTieSites } from '../actions/tieApiClientActions';
import { BRAND_OPTIONS } from '../constants/TieApiClientConstants';

const styles = () => ({
  table: {
    minWidth: 700,
    maxHeight: '90%',
  }
});

class SiteSelector extends Component {
  state = {
    allowSave: true,
    brand: this.props.brand || 'None',
    brandSites: {},
    filter: '',
    limit: 10,
    page: 1,
    selectAllChecked: false
  };

  tableHeads = () => [
    {
      centerHeader: false,
      checkbox: true,
      checkValue: this.state.selectAllChecked,
      disabled: !this.props.canEdit,
      error: this.props.errors,
      id: '',
      label: '',
      noLabel: true,
      numeric: false,
      onChange: this.checkboxChange(),
      sortable: false
    },
    {
      centerHeader: false,
      id: 'id',
      label: this.props.translations.id,
      numeric: false,
      sortable: false
    },
    {
      centerHeader: false,
      id: 'name',
      label: this.props.translations.name,
      numeric: false,
      sortable: false
    }
  ];

  componentDidMount() {
    const { clientSites } = this.props;
    this.filterData();
    this.setState({
      brandSites: {
        [this.state.brand]: [...clientSites]
      }
    });

    this.props.updateSites([...clientSites]);
  }

  checkboxChange = () => () => {
    this.setState({
      selectAllChecked: !this.state.selectAllChecked
    }, () => {
      const { brand, selectAllChecked } = this.state;
      const { sites } = this.props;
      const tempBrandSites = JSON.parse(JSON.stringify(this.state.brandSites));

      if (!selectAllChecked) {
        tempBrandSites[brand] = [];
      } else {
        for (let i = 0; i < sites.length; i++) {
          tempBrandSites[brand].push(sites[i].id);
        }
      }

      this.setState({ brandSites: tempBrandSites, selectAllChecked: this.state.selectAllChecked });
      this.props.updateSites(uniqBy(tempBrandSites[brand]));
    });
  };

  getBrandName(brand) {
    const brandName = findObjectByKey(BRAND_OPTIONS, 'value', brand);
    return brandName ? brandName.name : brand;
  }

  getBrandSites = (event, value) => {
    const { brandSites } = this.state;
    const brandValue = value.props.value;
    const tempBrandSites = JSON.parse(JSON.stringify(this.state.brandSites));

    if (!brandSites[brandValue] || brandSites[brandValue] === null) {
      tempBrandSites[brandValue] = [];
    }

    this.setState({
      selectAllChecked: false, brand: brandValue, page: 1, brandSites: tempBrandSites
    }, () => {
      this.filterData();
      this.props.updateBrand(brandValue);
      setTimeout(() => {
        this.props.updateSites(tempBrandSites[brandValue]);
      }, 200);
    });
  };

  isSiteInClient = (siteId) => {
    const { sites } = this.props;
    const { brand, brandSites } = this.state;

    if (!sites || sites.length === 0) {
      return false;
    }

    const selected = find(brandSites[brand], (o) => o === siteId);
    return selected !== undefined;
  };

  updateSiteCheck(site) {
    if (!this.props.canEdit) {
      return;
    }
    const { brand, brandSites } = this.state;
    const tempSites = [...brandSites[brand]];
    const siteIndex = findIndex(tempSites, (o) => o === site);

    if (siteIndex === -1) {
      tempSites.push(site);
    } else {
      tempSites.splice(siteIndex, 1);
    }

    const tempBrandSites = JSON.parse(JSON.stringify(this.state.brandSites));
    tempBrandSites[brand] = tempSites;

    this.setState({ brandSites: tempBrandSites, selectAllChecked: false });
    this.props.updateSites(tempSites);
  }

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  filterSite = ({ target: { value } }) => {
    this.setState({ filter: value, selectAllChecked: false, page: 1 }, () => {
      this.debouncedLoadMoreData();
    });
  };

  filterData(clearTable = true) {
    this.props.dispatch(getTieSites(this.getFilterObject(), clearTable));
  }

  getFilterObject() {
    const filterObj = { page: this.state.page, limit: this.state.limit };
    if (this.state.filter && this.state.filter !== '') {
      filterObj.search = encodeURIComponent(this.state.filter);
    }

    if (this.state.brand !== 'None') {
      filterObj.brand = encodeURIComponent(this.state.brand);
    }

    return filterObj;
  }

  handleWayPointReached() {
    if (this.state.page < this.props.maxPages) {
      this.setState({ page: this.state.page + 1, selectAllChecked: false }, () => {
        this.filterData(false);
      });
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  render() {
    const { brand, page } = this.state;
    const {
      canEdit, classes, sites, gettingSites, globalTranslations,
      maxPages, selectedClient, tieTranslations, translations,
    } = this.props;

    return (
      <Paper style={{ padding: '10px', height: '100%', overflow: 'hidden' }}>
        <Grid container style={{ height: '100%', overflow: 'auto' }}>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item xs={3}>
              <SonifiText
                change={this.getBrandSites}
                defaultValue={(brand ? brand : 'None')}
                disabled={!canEdit || selectedClient !== -2 || gettingSites}
                items={BRAND_OPTIONS.map((suggestion) => ({
                  id: suggestion.value,
                  value: suggestion.value,
                  label: suggestion.name,
                }))}
                label={(canEdit && selectedClient !== -2) ? tieTranslations.brand : tieTranslations.setBrand}
                select
                size="percent"
              />
            </Grid>
            <Grid item style={{ justifyContent: 'flex-end' }}>
              <SonifiText
                change={this.filterSite}
                defaultValue={this.state.filter}
                icon={<SearchIcon />}
                iconPosition="end"
                label={globalTranslations.search}
                size="mdNoPad"
              />
            </Grid>
          </Grid>
          {(gettingSites && sites.length === 0)
            ? <SonifiSpinner />
            : <Fragment>
              <Grid item xs={12} style={{ height: '100%' }}>
                <Table className={classes.table}>
                  <SonifiTableHead headColumns={this.tableHeads()} />
                  {sites.length === 0
                    ? <TableBody className={classes.tableBody}>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <SonifiLabel error label={translations.errors.noSites} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    : <TableBody className={classes.tableBody}>
                      {sites.map((site, index) => (
                        <TableRow key={index} onClick={this.updateSiteCheck.bind(this, site.id)}>
                          <TableCell>
                            {index === sites.length - DISTANCE_FROM_END && page < maxPages && (
                              <Waypoint onEnter={() => { this.handleWayPointReached(); }} />
                            )}
                            <SonifiCheckbox
                              disabled={!canEdit}
                              label=""
                              noLabel={true}
                              value={this.isSiteInClient(site.id)}
                            />
                          </TableCell>
                          <TableCell>{site.id}</TableCell>
                          <TableCell>{site.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>}
                  <TableFooter>
                    <TableRow>
                    </TableRow>
                  </TableFooter>
                </Table>
              </Grid>
            </Fragment>
          }
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  gettingSites: state.tieClients.gettingSites,
  globalTranslations: state.global.translations.defaults,
  maxPages: state.tieClients.maxPages,
  selectedClient: state.tieClients.selectedClient,
  sites: state.tieClients.sites,
  translations: state.groups.translations.dialog,
  tieTranslations: state.tieClients.translations.dialog
});

SiteSelector.propTypes = {
  brand: PropTypes.string,
  canEdit: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  clientSites: PropTypes.array,
  dispatch: PropTypes.func,
  errors: PropTypes.bool,
  gettingSites: PropTypes.bool,
  globalTranslations: PropTypes.object,
  maxPages: PropTypes.number,
  selectedClient: PropTypes.number,
  sites: PropTypes.array,
  tieTranslations: PropTypes.object,
  translations: PropTypes.object,
  updateBrand: PropTypes.func,
  updateSites: PropTypes.func
};

export default connect(mapStateToProps)(withStyles(SiteSelector, styles, { withTheme: true }));
