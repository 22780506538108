import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Grid } from '@mui/material';
import { Delete } from '@mui/icons-material';

import SonifiIconButton from './SonifiIconButton';
import SonifiLabel from './SonifiLabel';
import SonifiError from './SonifiError';
import SonifiTextInline from './SonifiTextInline';


class SonifiEditableList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: props.list,
            newInput: ''
        };
    }

    componentDidMount() {
        this.setState({
            list: this.props.list
          });
      }

    handleListItemChange = (event, index) => {
        const newList = [...this.state.list];
        newList[index] = event.target.value;
        this.setState(
            {
                list: newList
            },
            () => {
                this.props.onListChange(newList);
            }
        );
    };

    deleteItem = (index) => {
        const newList = [...this.state.list];
        newList.splice(index, 1);

        this.setState(
            {
                list: newList
            },
            () => {
                this.props.onListChange(this.state.list);
            }
        );
    };

    handleDeleteButtonClick = (index) => (event) => {
            // In order for the form not to be submitted
            event.preventDefault();
            this.deleteItem(index);
        };

    onChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    onKeyUp = (event) => {
        if (event.key === 'Enter' && event.target.value.trim(' ').length > 0) {
            const newList = [...this.state.list];
            this.setState(
                {
                    list: newList.concat(event.target.value),
                    newInput: ''
                },
                () => {
                    this.props.onListChange(this.state.list);
                }
            );
        }
    };

    render() {
        const {
                disabled, label, newItemPlaceholder, error, errorText
        } = this.props;

        return (
            <Fragment>
                <Grid container justifyContent="space-between" style={{ height: 'calc(100% - 1px)' }} >
                    <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <SonifiLabel boldLabel={label} />
                    </Grid>
                    
                    <Grid item xs={12} style={{ padding: '3px 3px 3px 0px' }}>
                        <SonifiTextInline
                            name="newInput"
                            change={this.onChange}
                            disabled={disabled}
                            onKeyUp={this.onKeyUp}
                            placeholder={newItemPlaceholder}
                            defaultValue={this.state.newInput}/>
                    </Grid>
                
                <Grid item xs={12} style={{ height: !disabled ? '88%' : '100%' }}>
                    {this.state.list.map((elem, index) => (
                        <Grid key={index} container wrap="nowrap" style={{ padding: '3px 3px 3px 0px' }}>
                            <SonifiTextInline
                                defaultValue={elem}
                                disabled={disabled}
                                type="text"
                                change={(e) => {
                                    this.handleListItemChange(e, index);
                                }}
                            />
                            <Grid item xs={1}>
                            <SonifiIconButton
                                label=""
                                disabled={disabled}
                                onClick={this.handleDeleteButtonClick(index)}
                                icon={<Delete />}
                            />
                            </Grid>
                        </Grid>
                    ))}
                    { error && <SonifiError label={errorText} />}
                   </Grid>
        </Grid>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    // list: state.siteManagement.deleteType,

/*     generalTranslations: state.siteManagement.translations.general,
    globalTranslations: state.global.translations.defaults,
    itemName: state.siteManagement.itemName,
    snackbarTranslations: state.siteManagement.translations.snackbarMessages,
    termLocIndex: state.siteManagement.buildingIndex,
    terminalLocations: state.terminals.locations, */
    // translations: state.siteManagement.translations.site,
    userPermissions: state.global.permissions
  });

SonifiEditableList.propTypes = {
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.string,
    label: PropTypes.string,
    list: PropTypes.array.isRequired,
    newItemPlaceholder: PropTypes.string,
    onListChange: PropTypes.func.isRequired,
    // userPermissions: PropTypes.array
};

SonifiEditableList.defaultProps = {
    dispatch: PropTypes.func,
    newItemPlaceholder: 'Add an item',
    showBullets: true
};

export default connect(mapStateToProps)(SonifiEditableList);
// export default React.memo(withStyles(SonifiEditableList, styles, { withTheme: true }));
