export const DELETE_CHECK_SELECTED_GROUP = 'DELETE_CHECK_SELECTED_GROUP';
export const DELETE_GROUP_SERVICE_BEGIN = 'DELETE_GROUP_SERVICE_BEGIN';
export const DELETE_GROUP_SERVICE_FAILURE = 'DELETE_GROUP_SERVICE_FAILURE';
export const DELETE_GROUP_SERVICE_SUCCESS = 'DELETE_GROUP_SERVICE_SUCCESS';
export const EDIT_SELECTED_GROUP = 'EDIT_SELECTED_GROUP';
export const FETCH_GROUP_SERVICES_BEGIN = 'FETCH_GROUP_SERVICES_BEGIN';
export const FETCH_GROUP_SERVICES_FAILURE = 'FETCH_GROUP_SERVICES_FAILURE';
export const FETCH_GROUP_SERVICES_SUCCESS = 'FETCH_GROUP_SERVICES_SUCCESS';
export const GET_GROUP_MESSAGES_BEGIN = 'GET_GROUP_MESSAGES_BEGIN';
export const GET_GROUP_MESSAGES_FAILURE = 'GET_GROUP_MESSAGES_FAILURE';
export const GET_GROUP_MESSAGES_SUCCESS = 'GET_GROUP_MESSAGES_SUCCESS';
export const GET_GROUP_ROOMS_BEGIN = 'GET_GROUP_ROOMS_BEGIN';
export const GET_GROUP_ROOMS_FAILURE = 'GET_GROUP_ROOMS_FAILURE';
export const GET_GROUP_ROOMS_SUCCESS = 'GET_GROUP_ROOMS_SUCCESS';
export const ORDER_GROUP_ROOMS = 'ORDER_GROUP_ROOMS';
export const REMOVE_NULL_GROUP_CODES = 'REMOVE_NULL_GROUP_CODES';
export const RESET_GROUP_DIALOG = 'RESET_GROUP_DIALOG';
export const RESET_SEND_GROUP_MESSAGE = 'RESET_SEND_GROUP_MESSAGE';
export const SAVE_GROUP_BEGIN = 'SAVE_GROUP_BEGIN';
export const SAVE_GROUP_FAILURE = 'SAVE_GROUP_FAILURE';
export const SAVE_GROUP_SUCCESS = 'SAVE_GROUP_SUCCESS';
export const SEND_GROUP_MESSAGE_BEGIN = 'SEND_GROUP_MESSAGE_BEGIN';
export const SEND_GROUP_MESSAGE_SUCCESS = 'SEND_GROUP_MESSAGE_SUCCESS';
export const SET_GROUP_SERVICES_SNACKBAR = 'SET_GROUP_SERVICES_SNACKBAR';
export const UPDATE_SELECTED_GROUP = 'UPDATE_SELECTED_GROUP';
