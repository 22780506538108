import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';

class AddRoomSuccess extends Component {
  handleClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  render() {
    const { addRoomStatus, translations } = this.props;

    if (!addRoomStatus) {
      return <Fragment />;
    }

    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={this.handleClose.bind(this)}
        open={true}
      >
        <SonifiModalHeader
          header={translations.addRoom}
          onCancel={this.handleClose.bind(this)}
          onlyClose={true}
        />
        <DialogContent>
          {addRoomStatus && addRoomStatus.added && addRoomStatus.added.length > 0 &&
            <SonifiLabel
              label={
                (addRoomStatus.added.length === 1
                  ? translations.addedSuccessSingle
                  : translations.addedSuccess) + addRoomStatus.added.sort((a, b) => a - b)
              }
            />
          }

          {addRoomStatus && addRoomStatus.errors && addRoomStatus.errors.length > 0 &&
            <SonifiLabel error
              label={
                (addRoomStatus.errors.length === 1
                  ? translations.addedFailureSingle
                  : translations.addedFailure) + addRoomStatus.errors.sort((a, b) => a - b)
              }
            />
          }

          {addRoomStatus && addRoomStatus.duplicates && addRoomStatus.duplicates.length > 0 &&
            <SonifiLabel error
              label={`${translations.duplicateRoomsInRange} ${addRoomStatus.duplicates.sort((a, b) => a - b)}`}
            />
          }
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  addRoomStatus: state.rooms.addRoomStatus,
  translations: state.rooms.translations.addDialog
});

AddRoomSuccess.propTypes = {
  addRoomStatus: PropTypes.object,
  onCancel: PropTypes.func.isRequired,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(AddRoomSuccess);
