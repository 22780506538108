import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { SonifiText } from '../../../../containers';
import { getErrorText, isError } from '../../../../utils/index';

export default class AgilysysBase extends Component {
  changeValue = (name, type) => ({ target: { value } }) => {
    this.props.updateTypeState(name, value, type);
  };

  getTextBox(label, defaultValue, name, type) {
    const { editState, notEditable } = this.props;
    return (
      <SonifiText
        size="lg"
        label={label}
        change={this.changeValue(name, type)}
        defaultValue={defaultValue}
        error={isError(name, editState.errors)}
        errorText={getErrorText(name, editState.errors)}
        disabled={notEditable}
      />
    );
  }

  render() {
    return (
      <Fragment />
    );
  }
}

AgilysysBase.propTypes = {
  editState: PropTypes.object,
  notEditable: PropTypes.bool,
  updateTypeState: PropTypes.func
};
