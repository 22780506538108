import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { SONIFI_ROLES } from '../../constants/roleGroups';
import { TERM_COMM_READ } from '../../constants/roles';
import Navigation from '../../containers/Navigation';
import { getLateCheckoutFromSession, getSiteNumFromURI } from '../../utils';
import { isTesting } from '../../utils/api';
import { createLink } from '../../utils/links';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';
import PmsLogging from '../Reports/Pms';
import PurchaseLog from '../Reports/Purchase';
import TermComm from '../Reports/TermComm';

class Reports extends Component {
  monitoringReports() {
    const { monitoring } = this.props.translations;
    const title = monitoring.title;
    const items = [];

    if (checkForAtLeastOneUserPermission(SONIFI_ROLES, this.props.userPermissions)) {
      items.push(createLink(monitoring.pmsLogs.title, 'pmsLogging', PmsLogging));
    }

    const lateCheckout = getLateCheckoutFromSession();
    if (getSiteNumFromURI() !== null && lateCheckout === 'true') {
      items.push(createLink(monitoring.purchaseLogs.title, 'purchaseLogs', PurchaseLog));
    }

    if (!isTesting(this.props.isProd) && checkForSingleUserPermission(TERM_COMM_READ, this.props.userPermissions)) {
      items.push(createLink(monitoring.termComm.title, 'termComm', TermComm));
    }

    return { title, items };
  }

  render() {
    if (getSiteNumFromURI() === null) {
      return <Navigate to="/" />;
    }
    const links = [...this.monitoringReports().items];
    const stacked = [this.monitoringReports()];

    return (
      <Navigation links={links} stackedNav={stacked} />
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  isProd: state.global.isProd,
  translations: state.global.translations,
  userPermissions: state.global.permissions
});

Reports.propTypes = {
  change: PropTypes.bool,
  isProd: PropTypes.bool.isRequired,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(Reports);
