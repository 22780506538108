import { Dialog, DialogContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import ModalSectionHeader from '../../../containers/ModalSectionHeader';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiText from '../../../containers/SonifiText';
import { getReleaseTemplates } from '../../ReleaseTemplates/actions/releaseTemplateActions';
import {
  getProductsData, updateHostRow, upgradeSystemHost
} from '../../SystemManagement/actions/systemManagementActions';
import { getAvailableProductUpgrades, getShortName } from '../utils';
import { validateUpgrade } from '../validator/validator';

class UpgradeDialog extends Component {
  state = {
    errors: {},
    loading: true,
    updateVersion: '',
    activeVersions: []
  };

  componentDidMount() {
    const { dispatch, updateRow, upgradeWindow } = this.props;

    this.setState({
      updateVersion: updateRow.assigned ?? updateRow.version,
      loading: true,
    });

    dispatch(getProductsData(updateRow.id, upgradeWindow.virtos, upgradeWindow.channel))
      .then(() => {
        this.setState({ loading: false });
      });

    // TODO: ENGCCA-4439: Should be able to get rid of this.
    dispatch(getReleaseTemplates({ limit: 10000, order: 'asc', orderBy: 'product' }))
      .then(() => {
        const activeTemplates = this.props.releaseTemplates.filter(
          (e) => e.product === updateRow.id && e.active === true
        );
        this.setState({ activeVersions: activeTemplates.map((a) => `${a.version}`) });
      });
  }

  handleSubmit() {
    const {
      dispatch, productParents, systemId, translations, updateRow
    } = this.props;
    const { updateVersion } = this.state;

    this.setState({ errors: {} });

    if (updateVersion === updateRow.assigned) {
      this.handleClose();
      return;
    }

    const validationErrors = validateUpgrade(
      translations.errors, this.props.updateRow.version, updateVersion
    );

    this.setState({ errors: validationErrors });
    if (Object.entries(validationErrors).length === 0) {
      dispatch(
        upgradeSystemHost(
          systemId, updateRow.id, updateVersion, getShortName(updateRow.id, productParents)
        )
      );
      this.handleClose();
      return;
    }
  }

  handleClose() {
    this.props.dispatch(updateHostRow(null));
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.handleClose();
    }
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ updateVersion: value });
  };

  renderModalLine() {
    const {
      gettingProducts, latestVersions, productParents, productVersions, translations, updateRow, upgradeWindow,
    } = this.props;
    const { assigned, hosts, id, version } = updateRow;

    if (gettingProducts || this.state.loading) {
      return <SonifiSpinner />;
    }

    const availableProductUpgrades = getAvailableProductUpgrades(
      version, assigned, upgradeWindow.virtos, productVersions[id], this.state.activeVersions
    );
    const isLatest = productVersions[id] && version === latestVersions[id][upgradeWindow.virtos];
    const {
      currentLabel, hostLabel, latest: latestLabel, productLabel, upgradeLabel
    } = translations.labels;
    const { errors, updateVersion } = this.state;

    return (
      <Grid container>
        <Grid item xs={3}>
          <SonifiText label={hostLabel} defaultValue={hosts} disabled size="percent" />
        </Grid>
        <Grid item xs={3}>
          <SonifiText label={productLabel} defaultValue={getShortName(updateRow.id, productParents)}
            disabled size="percent" />
        </Grid>
        <Grid item xs={3}>
          <SonifiText label={currentLabel} defaultValue={version || ''} disabled size="percent" />
        </Grid>

        {
          availableProductUpgrades.length === 0 || latestVersions[id][upgradeWindow.virtos] === null
            ? <Grid item xs={3}>
              < SonifiText defaultValue={version ? version : assigned}
                disabled label={upgradeLabel} size="percent" />
            </Grid>
            : <Grid item xs={3}>
              {!isLatest
                ? <SonifiText
                  label={upgradeLabel}
                  size="percent"
                  defaultValue={updateVersion || ''}
                  select
                  items={availableProductUpgrades.map((choice) => ({
                    id: choice.id,
                    value: choice.id,
                    label: latestVersions[id][upgradeWindow.virtos] === choice.id
                      ? `${choice.id} (${latestLabel})`
                      : choice.id,
                  }))}
                  error={!!errors.upgrade}
                  errorText={errors.upgrade}
                  change={this.handleChange}
                />
                : < SonifiText defaultValue={
                  latestVersions[id][upgradeWindow.virtos] === version
                    ? `${version} (${latestLabel})`
                    : version
                }
                  disabled label={upgradeLabel} size="percent" />
              }
            </Grid>
        }
      </Grid>
    );
  }

  render() {
    const {
      gettingProducts, globalTranslations, latestVersions, productVersions, translations, updateRow, upgradeWindow
    } = this.props;
    const { id, version } = updateRow;

    return (
      <Dialog
        open={true}
        onClose={this.onClose.bind(this)}
        fullWidth
        maxWidth="md">
        <SonifiModalHeader
          header={translations.labels.upgradeHeader}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          onlyClose={
            gettingProducts ||
            this.state.loading ||
            !(productVersions[id] && version !== latestVersions[id][upgradeWindow.virtos])
          }
          label={globalTranslations.save}
        />
        <DialogContent>
          <ModalSectionHeader label={translations.labels.sectionLabel} />
          {this.renderModalLine()}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  latestVersions: state.systemManagement.latestVersions,
  productParents: state.deployments.productParents,
  productVersions: state.systemManagement.productVersions,
  releaseTemplates: state.releaseTemplates.releaseTemplates,
  systemId: state.systemManagement.systemId,
  gettingProducts: state.systemManagement.gettingProducts,
  translations: state.systemManagement.translations,
  upgradeWindow: state.systemManagement.upgradeWindow,
  updateRow: state.systemManagement.updateRow,
});

UpgradeDialog.propTypes = {
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  latestVersions: PropTypes.object,
  productParents: PropTypes.array,
  productVersions: PropTypes.object,
  gettingProducts: PropTypes.bool,
  releaseTemplates: PropTypes.array,
  translations: PropTypes.object,
  systemId: PropTypes.string,
  upgradeWindow: PropTypes.object,
  updateRow: PropTypes.object,
};

export default connect(mapStateToProps)(UpgradeDialog);
