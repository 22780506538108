export const CLOSE_ICP_INFO = 'CLOSE_ICP_INFO';
export const DELETE_CHECK = 'DELETE_CHECK';
export const DELETE_ICP_BEGIN = 'DELETE_ICP_BEGIN';
export const DELETE_ICP_FAILURE = 'DELETE_ICP_FAILURE';
export const DELETE_ICP_SUCCESS = 'DELETE_ICP_SUCCESS';
export const EDIT_SELECTED_ICP = 'EDIT_SELECTED_ICP';
export const FETCH_ICPS_BEGIN = 'FETCH_ICPS_BEGIN';
export const FETCH_ICPS_FAILURE = 'FETCH_ICPS_FAILURE';
export const FETCH_ICPS_SUCCESS = 'FETCH_ICPS_SUCCESS';
export const GET_ICP_INFO_BEGIN = 'GET_ICP_INFO_BEGIN';
export const GET_ICP_INFO_FAILURE = 'GET_ICP_INFO_FAILURE';
export const GET_ICP_INFO_SUCCESS = 'GET_ICP_INFO_SUCCESS';
export const PUT_ICP_BEGIN = 'PUT_ICP_BEGIN';
export const PUT_ICP_FAILURE = 'PUT_ICP_FAILURE';
export const PUT_ICP_SUCCESS = 'PUT_ICP_SUCCESS';
export const RESET_ICP_SUCCESS = 'RESET_ICP_SUCCESS';
export const SORT_ICPS = 'SORT_ICPS';
export const UPDATE_ICP_INFO = 'UPDATE_ICP_INFO';
export const UPDATE_SELECTED_ICP = 'UPDATE_SELECTED_ICP';
