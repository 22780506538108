// Generic dialog which will contain either a Library item
// or a Publish (Message) item

import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DROPDOWN_ADD_NEW, FORM_MODE, FORM_TYPE } from '../../../constants/messages';
import SonifiLockoutModalSpinner from '../../../containers/SonifiLockoutModalSpinner';
import {
  addLibraryItem, addPublishItem, closeMessageDialog, editLibraryItem, editPublishItem
} from '../actions/messagingActions';
import MessageForm from './MessageForm';
import PublishForm from './PublishForm';

class FormDialog extends Component {
  handleDialogClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(closeMessageDialog());
    }
  }

  addEditLibrary = (libraryItem, translations) => {
    libraryItem.id = libraryItem.messageId;
    delete libraryItem.messageId;
    switch (this.props.dialogMode) {
      case FORM_MODE.ADD:
        console.log('SUBMIT LIBRARY FORM - ADD', libraryItem, translations);
        this.props.dispatch(addLibraryItem(libraryItem, translations));
        break;
      case FORM_MODE.EDIT:
        console.log('SUBMIT LIBRARY FORM - EDIT', libraryItem, translations);
        this.props.dispatch(editLibraryItem(libraryItem, translations, this.props.timezone));
        break;
      case FORM_MODE.COPY:
        console.log('SUBMIT LIBRARY FORM - COPY', libraryItem);
        this.props.dispatch(addLibraryItem(libraryItem, translations));
        break;
      default:
        break;
    }
  };

  convertToSiteTimezone = (dateStr) => {
    if (!dateStr) {
      return null;
    }
    return moment(dateStr).tz(this.props.timezone, true).format();
  };


  addEditPublish = (publishItem) => {
    switch (this.props.dialogMode) {
      case FORM_MODE.ADD:
        console.log('SUBMIT PUBLISH FORM - ADD', publishItem);

        // If ad-hoc message
        if (publishItem.nameDropdown === DROPDOWN_ADD_NEW) {
          const obj = {
            body: {
              display_type: publishItem.display_type,
              message_type: publishItem.message_type,
              takeover_type: publishItem.takeover_type,
              translations: publishItem.translations,
              user_name: publishItem.user_name,
            },
            end_timestamp: this.convertToSiteTimezone(publishItem.end_timestamp),
            name: publishItem.messageId,
            organization: publishItem.organization,
            requester: publishItem.requester,
            rooms: publishItem.rooms,
            start_timestamp: this.convertToSiteTimezone(publishItem.start_timestamp),
            user_name: publishItem.user_name
          };

          this.props.dispatch(addPublishItem(obj, this.props.timezone));
        } else {
          // publish a predefined library item
          this.props.dispatch(addPublishItem({
            body: {
              display_type: publishItem.display_type,
              message_type: publishItem.message_type,
              takeover_type: publishItem.takeover_type,
              user_name: publishItem.user_name,
            },
            end_timestamp: this.convertToSiteTimezone(publishItem.end_timestamp),
            name: publishItem.nameDropdown,
            organization: publishItem.organization,
            requester: publishItem.requester,
            rooms: publishItem.rooms,
            start_timestamp: this.convertToSiteTimezone(publishItem.start_timestamp),
            user_name: publishItem.user_name
          }, this.props.timezone));
        }
        break;
      case FORM_MODE.EDIT:
        console.log('SUBMIT PUBLISH FORM - EDIT', publishItem);

        // When editing a publish record, you may only edit the start/end datetimes.
        // Otherwise it's a manual process.  Delete original and create a new publish record.

        this.props.dispatch(editPublishItem({
          id: this.props.selectedPublishItem,
          start_timestamp: this.convertToSiteTimezone(publishItem.start_timestamp),
          end_timestamp: this.convertToSiteTimezone(publishItem.end_timestamp),
        }));
        break;

      // Spoke with Jim Fenno on 9/9/19.
      // This implementation of a copy may not provide the desired results, but is the
      // cleanest in this situation.
      // Essentially we are taking a 'version' of a published item and republishing
      // as an ad-hoc message.  It will break the link to the library item if one exists,
      // and it may not be the most recent version in the library.  We could add this publish
      // item by referencing the library item, but if the referenced library item has
      // updates and the user is attempting to republish the text that is in front of them,
      // and then it changes to the most recent version, is it really even a copy?  Would it
      // be misleading to the customer to get the 'updated' text from the library if they
      // purposely tried to copy this particular message, even if it's outdated?
      case FORM_MODE.COPY: {
        console.log('SUBMIT PUBLISH FORM - COPY', publishItem);

        const obj = {
          body: {
            display_type: publishItem.display_type,
            message_type: publishItem.message_type,
            takeover_type: publishItem.takeover_type,
            translations: publishItem.translations,
            user_name: publishItem.user_name
          },
          end_timestamp: this.convertToSiteTimezone(publishItem.end_timestamp),
          name: publishItem.messageId,
          organization: publishItem.organization,
          requester: publishItem.requester,
          rooms: publishItem.rooms,
          start_timestamp: this.convertToSiteTimezone(publishItem.start_timestamp),
          user_name: publishItem.user_name
        };

        this.props.dispatch(addPublishItem(obj, this.props.timezone));
        break;
      }
      default:
        break;
    }
  };

  // TODO:
  // In MessageForm, find a better way to pass translations
  // would like to only pass one object back to this function.
  // possibly wrap the translations object in an array within the obj object

  // This is the main event handler for adding/editing library items
  // or publish records.  Depending on the type of form, it will be routed to the
  // appropriate method to make the api call.
  handleFormSubmit = (obj, translations) => {
    switch (this.props.dialogFormType) {
      case FORM_TYPE.LIBRARY:
        // console.log('SUBMIT LIBRARY FORM', obj, translations);
        this.addEditLibrary(obj, translations);
        break;
      case FORM_TYPE.PUBLISH:
        // console.log('SUBMIT PUBLISH FORM', obj);
        this.addEditPublish(obj);
        break;
      default:
        break;
    }
  };

  render() {
    const {
      dialogOpen, dialogMode, dialogFormType, dialogError, dialogSaving, translation
    } = this.props;

    const isLibrary = dialogFormType === FORM_TYPE.LIBRARY;
    let header;
    switch (dialogMode) {
      case FORM_MODE.ADD:
        header = isLibrary ? translation.addMsg : translation.publishMsg;
        break;
      case FORM_MODE.EDIT:
        header = isLibrary ? translation.editMsg : translation.editPublishedMsg;
        break;
      case FORM_MODE.COPY:
        header = isLibrary ? translation.copyMsg : translation.copyPublishedMsg;
        break;
      case FORM_MODE.VIEW:
        header = isLibrary ? translation.previewMsg : translation.previewPublishedMsg;
        break;
      default:
        return <Fragment></Fragment>;
    }

    const publishForm = <PublishForm
      mode={dialogMode}
      header={header}
      onSubmit={this.handleFormSubmit}
      onCancel={this.handleDialogClose.bind(this)}
    />;

    const messageForm = <MessageForm
      mode={dialogMode}
      header={header}
      onSubmit={this.handleFormSubmit}
      onCancel={this.handleDialogClose.bind(this)}
    />;

    const showSpinner = dialogSaving && (dialogError.length === 0);

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={showSpinner} />
        <Dialog
          open={dialogOpen}
          onClose={this.handleDialogClose.bind(this)}
          fullWidth
          maxWidth={isLibrary ? 'sm' : 'md'} >
          {isLibrary ? messageForm : publishForm}
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dialogError: state.messaging.dialogError,
  dialogFormType: state.messaging.dialogFormType,
  dialogMode: state.messaging.dialogMode,
  dialogOpen: state.messaging.dialogOpen,
  dialogSaveAttemptComplete: state.messaging.dialogSaveAttemptComplete,
  dialogSaving: state.messaging.dialogSaving,
  selectedPublishItem: state.messaging.selectedPublishItem,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
  translation: state.messaging.translations.form
});

FormDialog.propTypes = {
  dialogError: PropTypes.array,
  dialogFormType: PropTypes.string,
  dialogMode: PropTypes.string.isRequired,
  dialogOpen: PropTypes.bool.isRequired,
  dialogSaveAttemptComplete: PropTypes.bool.isRequired,
  dialogSaving: PropTypes.bool,
  dispatch: PropTypes.func,
  selectedPublishItem: PropTypes.number,
  timezone: PropTypes.string.isRequired,
  translation: PropTypes.object
};

export default connect(mapStateToProps)(FormDialog);

