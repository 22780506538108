export const ADD_NEW_LOGO = 'ADD_NEW_LOGO';
export const CLEAR_LOGOS = 'CLEAR_LOGOS';
export const DELETE_CHECK = 'DELETE_CHECK';
export const DELETE_LOGO_BEGIN = 'DELETE_LOGO_BEGIN';
export const DELETE_LOGO_FAILURE = 'DELETE_LOGO_FAILURE';
export const DELETE_LOGO_SUCCESS = 'DELETE_LOGO_SUCCESS';
export const FETCH_CHANNEL_LOGOS_BEGIN = 'FETCH_CHANNEL_LOGOS_BEGIN';
export const FETCH_CHANNEL_LOGOS_FAILURE = 'FETCH_CHANNEL_LOGOS_FAILURE';
export const FETCH_CHANNEL_LOGOS_SUCCESS = 'FETCH_CHANNEL_LOGOS_SUCCESS';
export const FETCH_LOGO_BEGIN = 'FETCH_LOGO_BEGIN';
export const FETCH_LOGO_FAILURE = 'FETCH_LOGO_FAILURE';
export const FETCH_LOGO_SUCCESS = 'FETCH_LOGO_SUCCESS';
export const FILTER_CHANNELS_BEGIN = 'FILTER_CHANNELS_BEGIN';
export const FILTER_CHANNELS_FAILURE = 'FILTER_CHANNELS_FAILURE';
export const FILTER_CHANNELS_SUCCESS = 'FILTER_CHANNELS_SUCCESS';
export const REMOVE_NEW_LOGO = 'REMOVE_NEW_LOGO';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';
export const SAVE_LOGO_BEGIN = 'SAVE_LOGO_BEGIN';
export const SAVE_LOGO_FAILURE = 'SAVE_LOGO_FAILURE';
export const SAVE_LOGO_SUCCESS = 'SAVE_LOGO_SUCCESS';
export const START_LOGOS = 'START_LOGOS';
export const UPDATE_LOGO_LOADING = 'UPDATE_LOGO_LOADING';
export const UPDATE_SELECTED_LOGO = 'UPDATE_SELECTED_LOGO';
