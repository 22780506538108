const config = {
  Auth: {
    region: process.env.REACT_APP_CCA_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: 'USER_SRP_AUTH'
    // oauth: {
    //   domain:
    //     `${process.env.REACT_APP_COGNITO_DOMAIN_PREFIX
    //     }.auth.${
    //     process.env.REACT_APP_CCA_REGION
    //     }.amazoncognito.com`,
    //   scope: ['email', 'openid', 'profile'],
    //   redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URI,
    //   redirectSignOut: '',
    //   responseType: 'code',
    // },
  },
};

export default config;
