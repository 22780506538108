import { BAUD_MAX } from '../constants/constants';

export function isValidBaud(value) {
  if (value > BAUD_MAX) {
    return false;
  }
  return true;
}

export function isValidBits(value) {
  if (value > 9 || value < 0) {
    return false;
  }
  return true;
}

export function isValidCommand(value) {
  if (value > 120 || value < 0) {
    return false;
  }
  return true;
}
