import { SONIFI } from '../../../constants/constants';

export const BROWSER = 'BROWSER';
export const CLIENT = 'CLIENT';
export const CPU = 'CPU';
export const LG = 'LG';
export const MANUAL = 'MANUAL';
export const PTC = 'PTC';
export const SAMSUNG = 'SAMSUNG';
export const CLONE = 'CLONE';
export const BEM = 'BEM';
export const tabsArray = [
  { id: LG, title: LG },
  { id: SONIFI, title: SONIFI },
  { id: SAMSUNG, title: SAMSUNG }
];
export const HOURS = [
  { label: '1 hour', value: 1 },
  { label: '2 hours', value: 2 },
  { label: '3 hours', value: 3 },
  { label: '4 hours', value: 4 },
  { label: '5 hours', value: 5 },
  { label: '6 hours', value: 6 },
  { label: '7 hours', value: 7 },
  { label: '8 hours', value: 8 },
  { label: '9 hours', value: 9 },
  { label: '10 hours', value: 10 },
  { label: '11 hours', value: 11 },
  { label: '12 hours', value: 12 },
  { label: '13 hours', value: 13 },
  { label: '14 hours', value: 14 },
  { label: '15 hours', value: 15 },
  { label: '16 hours', value: 16 },
  { label: '17 hours', value: 17 },
  { label: '18 hours', value: 18 },
  { label: '19 hours', value: 19 },
  { label: '20 hours', value: 20 },
  { label: '21 hours', value: 21 },
  { label: '22 hours', value: 22 },
  { label: '23 hours', value: 23 },
];
