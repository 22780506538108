import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
// import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';
 
const langs = { en };
const initialState = {
  ipgFilenames: [],
  loading: false,
  maxPages: 1,
  translations: null
};

export default function downloadsReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_IPG_FILES_BEGIN:
      return {
        ...state,
        ipgFilenames: action.resetDownloadInfo ? [] : [...state.ipgFilenames],
        loading: action.resetDownloadInfo
      };
    case types.FETCH_IPG_FILES_FAILURE:
      return {
        ...state,
        loading: false
      };
    case types.FETCH_IPG_FILES_SUCCESS:
      return {
        ...state,
        ipgFilenames: state.ipgFilenames.concat(action.info),
        loading: false
      };
    case types.SHOW_IPG_FILES:
      return {
        ...state
      };
    case types.DOWNLOAD_IPG_FILE_ERROR:
      return {
        ...state,
        loading: false
      };
    case types.DOWNLOAD_IPG_FILE_BEGIN:
      return {
        ...state,
        loading: false
      };
    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };
    default:
      return state;
  }
}
