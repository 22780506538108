import SearchIcon from '@mui/icons-material/Search';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { ModalSectionHeader, SonifiText } from '../../containers/';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  border: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: theme.palette.primary.border,
    width: '100%',
    display: 'flex',
    overflow: 'auto'
  },
});

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 24, width: '100%' }}>
      {props.children}
    </Typography>
  );
}


class Sandbox extends Component {
  state = {
    test1: 'Normal Text, size sm',
    test2: 'With Helper Text',
    test3: 'With Error',
    test4: 'With Error',
    test5: 'Disabled',
    test6: 'size xs',
    test7: 'size md',
    test8: 'size lg',
    test9: '95% width',
    test10: 'default - no margin',
    test11: 'default - no margin',
    test12: 'marginRight class',
    test13: 'textBox class - no top margin',
    test14: 'highlight on focus',
    test15: '2',
    test16: '2',
    test17: 'multiline default 10 rows',
    test18: 'multiline 3 rows',
    test19: '',
    test20: 'really long sample text to fill textbox all the way',
    test21: '',
    test22: 'really long sample text to fill textbox all the way',
    test23: '',
    test24: 'really long sample text to fill textbox all the way',
  };


  handleOnChange = (name) => (e) => {
    this.setState({
      [name]: e.target.value
    });
  };

  // eslint-disable-next-line max-lines-per-function
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.border}>
          <TabContainer>
            <ModalSectionHeader
              label={'SonifiText'}
            />

            <SonifiText
              label={'Test 1, size sm'}
              size={'sm'}
              change={this.handleOnChange('test1')}
              defaultValue={this.state.test1}
            />
            <SonifiText
              label={'Test 2 with helper text'}
              helperText={'Sample helper text'}
              size={'sm'}
              change={this.handleOnChange('test2')}
              defaultValue={this.state.test2}
            />
            <SonifiText
              label={'Test 3 with error'}
              helperText={'Sample helper text'}
              error={true}
              errorText={'An error occurred'}
              size={'sm'}
              change={this.handleOnChange('test3')}
              defaultValue={this.state.test3}
            />
            <SonifiText
              label={'Test 4'}
              helperText={'Sample helper text'}
              error={true}
              // eslint-disable-next-line max-len
              errorText={'An error occurred, and here is some extra long error text that we need to find a solution for'}
              size={'sm'}
              change={this.handleOnChange('test4')}
              defaultValue={this.state.test4}
            />
            <SonifiText
              label={'Test 5'}
              disabled={true}
              size={'sm'}
              change={this.handleOnChange('test5')}
              defaultValue={this.state.test5}
            />
            <br />


            <SonifiText
              label={'Test 6, xs'}
              size={'xs'}
              change={this.handleOnChange('test6')}
              defaultValue={this.state.test6}
            />

            <SonifiText
              label={'Test 7, md'}
              size={'md'}
              change={this.handleOnChange('test7')}
              defaultValue={this.state.test7}
            />

            <SonifiText
              label={'Test 8, lg'}
              size={'lg'}
              change={this.handleOnChange('test8')}
              defaultValue={this.state.test8}
            />
            <br />


            <SonifiText
              label={'Test 9'}
              size={'percent'}
              change={this.handleOnChange('test9')}
              defaultValue={this.state.test9}
            />
            <br />
            <SonifiText
              label={'Test 10'}
              change={this.handleOnChange('test10')}
              defaultValue={this.state.test10}
            />
            <SonifiText
              label={'Test 11'}
              change={this.handleOnChange('test11')}
              defaultValue={this.state.test11}
            />
            <br />
            <SonifiText
              label={'Test 12'}
              size={'marginRight'}
              change={this.handleOnChange('test12')}
              defaultValue={this.state.test12}
            />
            <SonifiText
              label={'Test 13'}
              size={'textBox'}
              change={this.handleOnChange('test13')}
              defaultValue={this.state.test13}
            />

            <br />
            <SonifiText
              label={'Test 14'}
              highlightText={true}
              size={'sm'}
              change={this.handleOnChange('test14')}
              defaultValue={this.state.test14}
            />

            <br />
            <SonifiText
              label={'Test 15'}
              select={true}
              items={
                [{ value: 1, label: 'one' }, { value: 2, label: 'two' }, { value: 3, label: 'three' }]
              }
              size={'sm'}
              change={this.handleOnChange('test15')}
              defaultValue={this.state.test15}
            />

            <SonifiText
              label={'Test 16 disabled'}
              select={true}
              disabled={true}
              items={
                [{ value: 1, label: 'one' }, { value: 2, label: 'two' }, { value: 3, label: 'three' }]
              }
              size={'sm'}
              change={this.handleOnChange('test16')}
              defaultValue={this.state.test16}
            />
            <br />
            <SonifiText
              label={'Test 17, multiline'}
              multiline={true}
              size={'sm'}
              change={this.handleOnChange('test17')}
              defaultValue={this.state.test17}
            />
            <br />
            <SonifiText
              label={'Test 18'}
              multiline={true}
              rows={3}
              size={'sm'}
              change={this.handleOnChange('test18')}
              defaultValue={this.state.test18}
            />
            <br />
            <SonifiText
              label={'Test 19 End Adornment Disabled'}
              size={'md'}
              icon={<SearchIcon />}
              iconPosition={'end'}
              disabled={true}
              change={this.handleOnChange('test19')}
              defaultValue={this.state.test19}
            />
            <SonifiText
              label={'Test 20 End Adornment Disabled'}
              size={'md'}
              icon={<SearchIcon />}
              iconPosition={'end'}
              disabled={true}
              change={this.handleOnChange('test20')}
              defaultValue={this.state.test20}
            />
            <br />
            <SonifiText
              label={'Test 21 End Adornment'}
              size={'md'}
              icon={<SearchIcon />}
              iconPosition={'end'}
              change={this.handleOnChange('test21')}
              defaultValue={this.state.test21}
            />
            <SonifiText
              label={'Test 22 End Adornment'}
              size={'md'}
              icon={<SearchIcon />}
              iconPosition={'end'}
              change={this.handleOnChange('test22')}
              defaultValue={this.state.test22}
            />
            <br />
            <SonifiText
              label={'Test 23 Start Adornment'}
              size={'md'}
              icon={<SearchIcon />}
              iconPosition={'start'}
              change={this.handleOnChange('test23')}
              defaultValue={this.state.test23}
            />
            <SonifiText
              label={'Test 24 Start Adornment'}
              size={'md'}
              icon={<SearchIcon />}
              iconPosition={'start'}
              change={this.handleOnChange('test24')}
              defaultValue={this.state.test24}
            />

            <br />
          </TabContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

Sandbox.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object
};

TabContainer.propTypes = {
  children: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(Sandbox, styles, { withTheme: true }));

