import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { SonifiText } from '../../../../../containers';
import { encryptionValues, mpegFormatValues } from '../../../constants/constants';

/* eslint-disable camelcase */
export default class ChannelTableIpColumn extends Component {
  render() {
    const {
      editFunction, errors, ipObj, readOnly, translations
    } = this.props;

    return (
      <Grid container>
        <Grid item>
          <SonifiText
            defaultValue={ipObj.address}
            label={translations.ipAddr}
            change={editFunction('ip', 'address')}
            size="md"
            error={!!errors.ipAddress}
            errorText={(errors.ipAddress ? errors.ipAddress : '')}
            disabled={readOnly} />
          <SonifiText
            defaultValue={ipObj.port}
            label={translations.ipPort}
            change={editFunction('ip', 'port', 'number')}
            size="md"
            error={!!errors.ipPort}
            errorText={(errors.ipPort ? errors.ipPort : '')}
            disabled={readOnly} />
        </Grid>
        <Grid item>
          <SonifiText
            defaultValue={ipObj.stream}
            label={translations.ipStream}
            change={editFunction('ip', 'stream', 'number')}
            size="md"
            error={!!errors.ipStream}
            errorText={(errors.ipStream ? errors.ipStream : '')}
            disabled={readOnly} />
          <SonifiText
            defaultValue={ipObj.video_format}
            change={editFunction('ip', 'video_format')}
            select={true}
            label={translations.ipFormat}
            size="md"
            items={mpegFormatValues}
            error={!!errors.ipFormat}
            errorText={(errors.ipFormat ? errors.ipFormat : '')}
            disabled={readOnly} />
        </Grid>
        <Grid item>
          <SonifiText
            defaultValue={ipObj.encryption}
            change={editFunction('ip', 'encryption')}
            select={true}
            label={translations.ipEncryption}
            size="md"
            items={encryptionValues}
            error={!!errors.ipEncryption}
            errorText={(errors.ipEncryption ? errors.ipEncryption : '')}
            disabled={readOnly} />
          {/* <SonifiText
            defaultValue={ipObj.mbps}
            label="IP MBPS"
            change={editFunction('ip', 'mbps')}
            size='md'
            error={!!errors.ipMbps}
            errorText={(errors.ipMbps ? errors.ipMbps : '')}
            disabled={readOnly}/> */}
        </Grid>
      </Grid>
    );
  }
}

ChannelTableIpColumn.propTypes = {
  editFunction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  ipObj: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  translations: PropTypes.object
};
