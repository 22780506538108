import SearchIcon from '@mui/icons-material/Search';
import {
 Table, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup
} from '@mui/material';
import { compact, debounce, map, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import SonifiText from '../../../containers/SonifiText';
import { compareValues } from '../../../utils/index';
import { filteredChannels, filteredChannelsSuccess } from '../actions/logoActions';
import SiteTableBody from './SiteTableBody';

export const providerValue = ['ALL', 'EPG', 'ROVI'];

class SiteSelector extends Component {
  state = {
    filter: '',
    provider: providerValue[0],
    limit: 100,
    logoIds: [],
    order: 'asc',
    orderBy: 'name',
    page: 1,
    selectAllChecked: false,
    sources: [],
    timeZoneSearch: ' '
  };

  tableHeads = () => [
    {
      centerHeader: false,
      checkbox: true,
      checkValue: this.state.selectAllChecked,
      disabled: !this.props.canEdit,
      error: this.props.errors,
      id: '',
      label: '',
      noLabel: true,
      numeric: false,
      onChange: this.checkboxChange(),
      sortable: false
    },
    {
      centerHeader: false,
      id: 'name',
      label: this.props.translations.name,
      numeric: false,
      sortable: true
    },
    {
      centerHeader: false,
      id: 'call_letters',
      label: this.props.translations.fullName,
      numeric: false,
      sortable: true
    },
    {
      centerHeader: false,
      id: 'name',
      label: this.props.translations.sourceId,
      numeric: false,
      sortable: true
    }

  ];

  tableHeadsRead = () => [
    {
      id: '',
      label: '',
      noLabel: true,
    },
    {
      centerHeader: false,
      id: 'name',
      label: this.props.translations.name,
      numeric: false,
      sortable: true
    },
    {
      centerHeader: false,
      id: 'call_letters',
      label: this.props.translations.fullName,
      numeric: false,
      sortable: true
    },
    {
      centerHeader: false,
      id: 'id',
      label: this.props.translations.sourceId,
      numeric: false,
      sortable: true
    }

  ];

  componentDidMount() {
    const { maps, selectedLogo } = this.props;
    const ids = map(maps[selectedLogo].sources, 'id');

    this.setState({
      selectAllChecked: !maps[selectedLogo].isNew,
      logoIds: ids,
      ...maps[selectedLogo]
    }, () => {
      const sortedSources = (this.state.sources ? [...this.state.sources] : []);
      this.setState({
        sources: sortedSources.sort(compareValues(this.state.orderBy, this.state.order))
      }, () => {
        this.props.updateSources(this.state.logoIds);
      });
    });
  }

  checkboxChange = () => () => {
    this.setState({
      selectAllChecked: !this.state.selectAllChecked
    }, () => {
      let logos = [];
      if (this.state.selectAllChecked) {
        const searchableChannels = (this.props.channels ? this.props.channels : []);
        logos = compact([...this.state.sources, ...searchableChannels]);
        const uniqueLogos = uniqBy(logos, (e) => e.id);
        logos = uniqueLogos;
      }

      this.setState({
        logoIds: map(logos, 'id'),
        sources: logos.sort(compareValues(this.state.orderBy, this.state.order))
      }, () => {
        this.props.updateSources(this.state.logoIds);
      });
    });
  };

  handleRadio = ({ target: { value } }) => {
    this.setState({ provider: value }, () => {
      this.filterData();
    });
  };

  // Infinite scrolling logic, used with Waypoint component
  handleWayPointReached() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    });
  };

  filterData() {
    const affCallL = this.state.filter.split('|');
    if (this.state.filter === null || this.state.filter.trim() === '') {
      this.props.dispatch(filteredChannelsSuccess(null));
      return;
    }

    let obj = null;
    if (affCallL.length > 1) {
      obj = {
        affiliate: (affCallL[0] === 'none' ? '' : affCallL[0]),
        call_letters: (affCallL[1] === 'none' ? '' : affCallL[1]),
        name: (affCallL[2] ? affCallL[2] : ''),
        timezone: (affCallL[3] ? affCallL[3] : this.state.timeZoneSearch),
        provider: this.state.provider.toLowerCase(),
        platform: 'Ocean',
        limit: this.state.limit
      };
    } else {
      obj = {
        search: this.state.filter,
        provider: this.state.provider.toLowerCase(),
        platform: 'Ocean',
        timezone: this.state.timeZoneSearch,
        limit: this.state.limit
      };
    }
    this.props.dispatch(filteredChannels(obj));
  }

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  updateChannelSearch = ({ target: { value } }) => {
    this.setState({ filter: value }, () => {
      this.debouncedLoadMoreData();
    });
  };

  isLogoSelected = (logoId) => {
    const isInArray = this.state.logoIds.includes(logoId);
    return isInArray;
  };

  updateIds(channelObj) {
    if (!this.props.canEdit) {
      return;
    }
    const checked = !this.isLogoSelected(channelObj.id);
    let logos = [...this.state.logoIds],
      nextLogo = null;
    const newSources = [...this.state.sources];
    if (checked) {
      logos.push(channelObj.id);
      newSources.push(channelObj);
    } else {
      nextLogo = logos.filter((e) => e !== channelObj.id);
      logos = nextLogo;
    }

    this.setState({
      logoIds: logos,
      sources: uniqBy(newSources, (e) => e.id)
    }, () => {
      this.props.updateSources(this.state.logoIds);
    });
  }

  getPageDetails() {
    const {
      globalTranslations, canEdit, channels
    } = this.props;
    const { logoIds, order, orderBy } = this.state;
    const headers = ((canEdit && (logoIds.length > 0 || (channels !== null && channels.length > 0)))
      ? this.tableHeads()
      : this.tableHeadsRead());
    return (
      <Fragment>
        {canEdit && <SonifiText
          label={globalTranslations.search}
          defaultValue={this.state.filter}
          change={this.updateChannelSearch.bind(this)}
          icon={<SearchIcon />}
          iconPosition={'end'}
          size="lg"
        />}
              {canEdit && <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Provider</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={this.state.provider}
                  onChange={this.handleRadio.bind(this)} >
                  <FormControlLabel value={providerValue[0]} control={<Radio />} label={providerValue[0]} />
                  <FormControlLabel value={providerValue[1]} control={<Radio />} label={providerValue[1]} />
                  <FormControlLabel value={providerValue[2]} control={<Radio />} label={providerValue[2]} />
                </RadioGroup>
                </FormControl>}
        <Table >
          <SonifiTableHead
            headColumns={headers}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />
          <SiteTableBody canEdit={canEdit} isLogoSelected={this.isLogoSelected.bind(this)}
            onWayPointReached={this.handleWayPointReached.bind(this)}
            updateIds={this.updateIds.bind(this)} sources={this.state.sources}
          />
        </Table>
      </Fragment>
    );
  }

  render() {
    return (
      <SonifiTemplate
        pageDetails={this.getPageDetails()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  channels: state.logo.channels,
  globalTranslations: state.global.translations.defaults,
  maps: state.logo.maps,
  maxPages: state.logo.maxPages,
  selectedLogo: state.logo.selectedLogo,
  translations: state.logo.translations.editDialog
});

SiteSelector.propTypes = {
  canEdit: PropTypes.bool,
  channels: PropTypes.array,
  dispatch: PropTypes.func,
  errors: PropTypes.bool,
  globalTranslations: PropTypes.object,
  maps: PropTypes.array,
  maxPages: PropTypes.number,
  selectedLogo: PropTypes.number,
  translations: PropTypes.object,
  updateSources: PropTypes.func,
};

export default connect(mapStateToProps)(SiteSelector);
