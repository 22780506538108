/* eslint-disable max-lines */
import {
  REQUESTS, ROOM_RESOURCE, TERMINAL_OPTIONS, TERMINAL_RESOURCE, TERMINAL_STATUSES
} from '../../../constants/apiEndpoints';
import { SONIFI } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import {
  getSiteCsvObjectPromise, getSiteObjectPromise, patchObjectPromise, postObjectPromise
} from '../../../utils/api';
import { deleteTerminal, updateTerminal } from '../../../utils/terminal';
import { saveSiteFirmwareSuccess } from '../../TermFiles/actions/FirmwareActions';
import { updateSonifiFirmware } from '../../TermFiles/actions/SonifiFirmwareActions';
import * as actions from './actionTypes';

export const clearKeyBegin = () => ({
  type: actions.CLEAR_KEY_BEGIN
});

export const clearKeyEmpty = (termID) => ({
  type: actions.CLEAR_KEY_EMPTY,
  termID
});

export const clearKeySuccess = (payload, termID) => ({
  type: actions.CLEAR_KEY_SUCCESS,
  payload,
  termID
});

export const clearKeyFailure = (error, termID) => ({
  type: actions.CLEAR_KEY_FAILURE,
  error,
  termID
});

export const clearTable = () => ({
  type: actions.CLEAR_TABLE
});

export const closeDialog = () => ({
  type: actions.CLOSE_DIALOG
});

export const closeSnack = () => ({
  type: actions.CLOSE_SNACK
});

export const deleteTermBegin = (termID) => ({
  type: actions.DELETE_TERM_BEGIN,
  termID
});

export const deleteTermSuccess = (products, termID) => ({
  type: actions.DELETE_TERM_SUCCESS,
  products,
  termID
});

export const deleteTermFailure = (error, termID) => ({
  type: actions.DELETE_TERM_FAILURE,
  error,
  termID
});

export const fetchRoomBegin = () => ({
  type: actions.FETCH_ROOM_BEGIN
});

export const fetchRoomSuccess = (payload) => ({
  type: actions.FETCH_ROOM_SUCCESS,
  payload
});

export const fetchRoomFailure = (payload) => ({
  type: actions.FETCH_ROOM_FAILURE,
  payload
});

export const fetchRoomTerminalsBegin = () => ({
  type: actions.FETCH_ROOM_TERMINALS_BEGIN
});

export const fetchRoomTerminalsSuccess = (payload, termID) => ({
  type: actions.FETCH_ROOM_TERMINALS_SUCCESS,
  payload,
  termID
});

export const fetchRoomTerminalsFailure = (payload) => ({
  type: actions.FETCH_ROOM_TERMINALS_FAILURE,
  payload
});

export const fetchTerminalBegin = () => ({
  type: actions.FETCH_TERMINAL_BEGIN
});

export const fetchTerminalSuccess = (payload) => ({
  type: actions.FETCH_TERMINAL_SUCCESS,
  payload
});

export const fetchTerminalFailure = (payload) => ({
  type: actions.FETCH_TERMINAL_FAILURE,
  payload
});

export const fetchTerminalStatusBegin = () => ({
  type: actions.FETCH_TERMINAL_STATUS_BEGIN,
});

export const fetchTerminalStatusSuccess = (products, termID) => ({
  type: actions.FETCH_TERMINAL_STATUS_SUCCESS,
  products,
  termID
});

export const fetchTerminalStatusFailure = (products, termID) => ({
  type: actions.FETCH_TERMINAL_STATUS_FAILURE,
  products,
  termID
});

export const fetchTerminalsBegin = () => ({
  type: actions.FETCH_TERMINALS_BEGIN,
});

export const fetchTerminalsSuccess = (payload, options) => ({
  type: actions.FETCH_TERMINALS_SUCCESS,
  options,
  payload
});

export const fetchTerminalsFailure = (payload) => ({
  type: actions.FETCH_TERMINALS_FAILURE,
  payload
});

export const setSelectedTermID = (termID) => ({
  type: actions.SET_SELECTED_TERM_ID,
  termID
});

export const rebootTermBegin = () => ({
  type: actions.REBOOT_TERM_BEGIN
});

export const rebootTermSuccess = (products, termID) => ({
  type: actions.REBOOT_TERM_SUCCESS,
  products,
  termID
});

export const rebootTermFailure = (error, termID) => ({
  type: actions.REBOOT_TERM_FAILURE,
  error,
  termID
});

export const fetchTerminalOptionsBegin = () => ({
  type: actions.FETCH_TERMINAL_OPT_BEGIN,

});

export const fetchTerminalOptionsSuccess = (payload) => ({
  type: actions.FETCH_TERMINAL_OPT_SUCCESS,
  payload
});

export const fetchTerminalOptionsFailure = (error) => ({
  type: actions.FETCH_TERMINAL_OPT_FAILURE,
  error
});

export const putTerminalBegin = () => ({
  type: actions.PUT_TERMINAL_BEGIN
});

export const putTerminalSuccessFailure = (msg) => ({
  type: actions.PUT_TERMINAL_SUCCESS_FAILURE,
  msg
});

export const searchTerminalsBegin = () => ({
  type: actions.SEARCH_TERMINALS_BEGIN
});

export const searchTerminalsSuccess = (payload, options) => ({
  type: actions.SEARCH_TERMINALS_SUCCESS,
  options,
  payload
});

export const updateTerminalField = (name, value) => ({
  type: actions.UPDATE_TERMINAL_FIELD,
  name,
  value
});

export const fetchTerminalsExportBegin = () => ({
  type: actions.FETCH_TERMINAL_EXPORT_BEGIN
});

export function editTerminalField(name, value) {
  return (dispatch) => {
    dispatch(updateTerminalField(name, value));
  };
}

export const unclaimTerminal = (selectedTerm) => ({
  type: actions.UNCLAIM_TERMINAL,
  selectedTerm
});

export function setTermID(termID) {
  return (dispatch) => {
    dispatch(setSelectedTermID(termID));
  };
}

export function searchTerminals(searchObj) {
  return (dispatch) => {
    dispatch(searchTerminalsBegin());
    return dispatch(getAllTerminals(searchObj, true));
  };
}

export function getRoom(roomID) {
  return (dispatch) => {
    dispatch(fetchRoomBegin());

    return getSiteObjectPromise(ROOM_RESOURCE, encodeURIComponent(roomID))
      .then((json) => {
        dispatch(fetchRoomSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchRoomFailure(error)));
  };
}

export function getRoomTerminals(roomID, termID) {
  return (dispatch) => {
    dispatch(fetchRoomTerminalsBegin());

    return getSiteObjectPromise(ROOM_RESOURCE, `${encodeURIComponent(roomID)}/${TERMINAL_RESOURCE}`)
      .then((json) => {
        dispatch(fetchRoomTerminalsSuccess(json, termID));
        return json;
      }).catch((error) => dispatch(fetchRoomTerminalsFailure(error)));
  };
}

export function getTerminal(termID) {
  return (dispatch) => {
    dispatch(fetchTerminalBegin());
    dispatch(getTerminalOptions());
    return getSiteObjectPromise(TERMINAL_RESOURCE, termID)
      .then((termData) => {
        if (termData.room_id !== null) {
          dispatch(getRoom(termData.room_id));
          dispatch(getRoomTerminals(termData.room_id, termID));
        }
        dispatch(fetchTerminalSuccess(termData));
        return termData;
      }).catch((error) => {
        dispatch(fetchTerminalFailure(error.status));
        return error;
      });
  };
}

export function getTerminalStatus(termID) {
  return (dispatch) => {
    dispatch(fetchTerminalStatusBegin());
    const submitObj = {
      resource: 'terminal',
      action: 'status',
      keys: {
        terminal_id: termID
      }
    };
    return postObjectPromise(REQUESTS, submitObj)
      .then((data) => {
        dispatch(fetchTerminalStatusSuccess(data.status, termID));
        return data;
      })
      .catch((error) => {
        dispatch(fetchTerminalStatusFailure(error.status, termID));
        return error;
      });
  };
}

export function getAllTerminals(options, search = false) {
  return (dispatch) => {
    dispatch(fetchTerminalsBegin());

    return getSiteObjectPromise(TERMINAL_RESOURCE, null, null, options)
      .then((data) => {
        if (!search && (data.terminals.length < 1)) {
          dispatch(fetchTerminalsFailure());
        } else {
          data.terminals.map((item) => [
            item.room_id,
            item.id,
            item.location || '',
            item.lineup_id,
            item.version.firmware,
            item.version.hardware,
            item.version.software,
            item.passive_status || ''
          ]);
          if (search) {
            dispatch(searchTerminalsSuccess(data, options));
          } else {
            dispatch(fetchTerminalsSuccess(data, options));
          }
        }
        return data;
      }).catch((error) => {
        dispatch(fetchTerminalsFailure(error.status));
        return error;
      });
  };
}

/**
 * Return a function that makes a GET request to
 * `/sites/{site_id}/terminal-statuses`.
 * @param {string} siteId of the site
 * @returns {Function} Callback for `props.dispatch`.
 */
export function getTerminalStatuses(siteId = null) {
  return (dispatch) => {
    dispatch(fetchTerminalStatusesBegin());

    return getSiteObjectPromise(TERMINAL_STATUSES, null, siteId)
      .then((terminalStatuses) => {
        dispatch(fetchTerminalStatusesSuccess(terminalStatuses));
        return terminalStatuses;
      }).catch((error) => {
        dispatch(fetchTerminalStatusesFailure(error.status));
        return error;
      });
  };
}

export function fetchTerminalStatusesBegin() {
  return {
    type: actions.FETCH_TERMINAL_STATUSES_BEGIN
  };
}

export function fetchTerminalStatusesSuccess(terminalStatuses) {
  return {
    type: actions.FETCH_TERMINAL_STATUSES_SUCCESS,
    terminalStatuses
  };
}

export function fetchTerminalStatusesFailure(errorStatus) {
  return {
    type: actions.FETCH_TERMINAL_STATUSES_FAILURE,
    error: errorStatus
  };
}

export function getTerminalOptions(siteId = null) {
  return (dispatch) => {
    dispatch(fetchTerminalOptionsBegin());
    return getSiteObjectPromise(
        TERMINAL_OPTIONS, null, siteId, { location_status: 'active' }
      )
      .then((data) => {
        dispatch(fetchTerminalOptionsSuccess(data));
        return data;
      }).catch((error) => {
        // console.log(`Error getting terminal options ${error.status}`);
        dispatch(fetchTerminalOptionsFailure(error.status));
        return error;
      });
  };
}

export function rebootTerminal(termID) {
  return (dispatch) => {
    dispatch(rebootTermBegin());
    const submitObj = {
      resource: 'terminal',
      action: 'reboot',
      keys: {
        terminal_id: termID
      }
    };
    return postObjectPromise(REQUESTS, submitObj)
      .then((data) => {
        // console.log('POSTED REBOOT');
        // console.log(data);
        dispatch(rebootTermSuccess(data.status, termID));
        return data;
      })
      .catch((error) => {
        // console.log('POSTED REBOOT ERROR');
        // console.log(error);
        dispatch(rebootTermFailure(error.status, termID));
        return error;
      });
  };
}

export function clearPublicKey(termID) {
  return (dispatch) => {
    dispatch(clearKeyBegin());
    return getSiteObjectPromise(TERMINAL_RESOURCE, termID)
      .then((termData) => {
        // console.log('getTerminalInfo PROMISE SUCCESS');
        // console.log(termData);
        if (termData.ip && termData.ip.public_key !== undefined && termData.ip.public_key !== null) {
          termData.ip.public_key = null;
          return updateTerminal(termData)
            .then((data) => {
              dispatch(clearKeySuccess(data, termID));
            }).catch((error) => {
              // console.log('getTerminalInfo PROMISE FAIL');
              // console.log(error);
              dispatch(clearKeyFailure(error.status, termID));
            });
        } else {
          dispatch(clearKeyEmpty(termID));
          return;
        }
      })
      .catch((error) => {
        // console.log('POSTED REBOOT ERROR');
        // console.log(error);
        dispatch(clearKeyFailure(error.status, termID));
        return error;
      });
  };
}

// TODO
export function deleteTerm(termID) {
  return (dispatch) => {
    dispatch(deleteTermBegin());
    deleteTerminal(termID)
      .then((data) => {
        dispatch(deleteTermSuccess(data.status, termID));
      });
  };
}

// TODO
export function putTerm(terminal) {
  return (dispatch) => {
    dispatch(putTerminalBegin());
    delete terminal.add;
    delete terminal.building;
    delete terminal.cables;
    delete terminal.ccPort;
    delete terminal.changed;
    delete terminal.encryption;
    delete terminal.firmware;
    delete terminal.floor;
    delete terminal.hardware;
    delete terminal.host_connected;
    delete terminal.icpHost;
    delete terminal.icpIndex;
    delete terminal.input_source;
    delete terminal.ipAddress;
    delete terminal.last_communicated;
    delete terminal.lowBattery;
    delete terminal.lowBatteryKey;
    delete terminal.mpeg_format;
    delete terminal.power_on_channel;
    delete terminal.publicKey;
    delete terminal.remove;
    delete terminal.rf_comms_percentage;
    delete terminal.rf_comms_throughput;
    delete terminal.rf_rssi;
    delete terminal.software;
    delete terminal.tuner_type;
    delete terminal.tv_communication;
    delete terminal.tv_control;
    delete terminal.tv_mode;
    delete terminal.type;
    delete terminal.unit;
    delete terminal.wifiChannel;
    delete terminal.wifiRssi;
    delete terminal.wifiSsid;

    return updateTerminal(terminal)
      .then((json) => {
        dispatch(putTerminalSuccessFailure(json));
        dispatch(getAllTerminals({ limit: 25, page: 1, sort: 'room_id:asc' }));
        return true;
      });
  };
}

export const updateLogBegin = () => ({
  type: actions.UPDATE_LOG_BEGIN
});

export const updateLogSuccess = (termID) => ({
  type: actions.UPDATE_LOG_SUCCESS,
  termID
});

export const updateLogFailure = (termID) => ({
  type: actions.UPDATE_LOG_FAILURE,
  termID
});

export function updateLog(termID, logStatus) {
  return (dispatch) => {
    dispatch(updateLogBegin());
    return patchObjectPromise(TERMINAL_RESOURCE, termID, { log_forwarding: logStatus })
      .then((json) => {
        if (!httpSuccess(json.status)) {
          dispatch(updateLogFailure(termID));
          return json;
        }

        return dispatch(getAllTerminals({ limit: 25, page: 1, sort: 'room_id:asc' })).then(() => {
          dispatch(updateLogSuccess(termID));
          return json;
        });
      }).catch((error) => {
        dispatch(updateLogFailure(termID));
        return error;
      });
  };
}

// TODO?
export function getTerminalsExport() {
  return (dispatch) => {
    dispatch(fetchTerminalsExportBegin());

    return getSiteCsvObjectPromise(TERMINAL_RESOURCE)
      .then((json) => json).catch((error) => {
        dispatch(fetchRoomTerminalsFailure(error));
      });
  };
}

export const patchTerminalOptionsBegin = () => ({
  type: actions.PATCH_TERMINAL_OPT_BEGIN
});

export const patchTerminalOptionsSuccess = () => ({
  type: actions.PATCH_TERMINAL_OPT_SUCCESS
});

export const patchTerminalOptionsFailure = (error) => ({
  type: actions.PATCH_TERMINAL_OPT_FAILURE,
  error
});

export function patchTerminalOptions(options) {
  return (dispatch) => {
    dispatch(patchTerminalOptionsBegin());
    return patchObjectPromise(TERMINAL_OPTIONS, null, options)
      .then((json) => {
        if (!httpSuccess(json.status)) {
          dispatch(patchTerminalOptionsFailure(json.status));
          return json;
        }
        dispatch(patchTerminalOptionsSuccess());
        return json;
      }).catch((error) => {
        dispatch(patchTerminalOptionsFailure(error.status));
      });
  };
}

export const updateSelectedTerminal = (terminal) => ({
  type: actions.UPDATE_SELECTED_TERMINAL2,
  terminal
});

export const updateTerminalFirmwareBegin = () => ({
  type: actions.UPDATE_TERM_FIRM_BEGIN
});

export const updateTerminalFirmwareSuccess = (termID) => ({
  type: actions.UPDATE_TERM_FIRM_SUCCESS,
  termID
});

export const updateTerminalFirmwareFailure = (termID) => ({
  type: actions.UPDATE_TERM_FIRM_FAILURE,
  termID
});

export function updateTerminalFirmware(terminal, sort) {
  return (dispatch) => {
    dispatch(updateTerminalFirmwareBegin());
    const submitObj = {
      resource: 'terminal',
      action: 'update',
      keys: {
        terminal_id: `${terminal.id}`
      },
      attributes: {
        firmware: terminal.firmware
      }
    };

    return postObjectPromise(REQUESTS, submitObj)
      .then((json) => {
        dispatch(clearTable());
        return dispatch(getTerminalOptions())
          .then(() => dispatch(getAllTerminals({ ...sort, page: 1 }))
            .then(() => {
              dispatch(updateTerminalFirmwareSuccess(terminal.id));
              return json;
            }));
      }).catch((error) => {
        dispatch(updateTerminalFirmwareFailure(terminal.id));
        return error;
      });
  };
}

export const resetTermGrid = () => ({
  type: actions.RESET_TERM_GRID
});

export const updateRoomTerminalFirmwareBegin = () => ({
  type: actions.UPDATE_ROOM_TERM_FIRM_BEGIN
});

export const updateRoomTerminalFirmwareSuccess = (termID) => ({
  type: actions.UPDATE_ROOM_TERM_FIRM_SUCCESS,
  termID
});

export const updateRoomTerminalFirmwareFailure = (termID) => ({
  type: actions.UPDATE_ROOM_TERM_FIRM_FAILURE,
  termID
});

// commented out
export function updateRoomTerminalFirmware(terminal, firmware, rooms) {
  return (dispatch) => {
    dispatch(updateRoomTerminalFirmwareBegin());
    const submitObj = {
      resource: 'terminal',
      action: 'model_update',
      keys: {
        model: `${terminal.id}`,
        rooms
      },
      attributes: {
        firmware
      }
    };

    return postObjectPromise(REQUESTS, submitObj)
      .then((json) => {
        if (!httpSuccess(json.status)) {
          dispatch(updateRoomTerminalFirmwareFailure(terminal.id));
          return json;
        }
        dispatch(updateRoomTerminalFirmwareSuccess(terminal.id));
        dispatch(updateSonifiFirmware(-1, ''));
        dispatch(saveSiteFirmwareSuccess(SONIFI));
        return json;
      }).catch((error) => {
        dispatch(updateRoomTerminalFirmwareFailure(terminal.id));
        return error;
      });
  };
}

export const fetchRangeTerminalsBegin = () => ({
  type: actions.FETCH_RANGE_TERMINALS_BEGIN
});

export const fetchRangeTerminalsSuccess = (rangeTerminals) => ({
  type: actions.FETCH_RANGE_TERMINALS_SUCCESS,
  rangeTerminals
});

export const fetchRangeTerminalsFailure = (range, error) => ({
  type: actions.FETCH_RANGE_TERMINALS_FAILURE,
  error,
  range
});

export function fetchRangeTerminals(start, end) {
  return (dispatch) => {
    dispatch(fetchRangeTerminalsBegin());
    return getSiteObjectPromise(
      `${TERMINAL_RESOURCE}?filter=room_start:${start},room_end:${end}&sort=room_id:asc&limit=100`
    )
      .then((json) => {
        dispatch(fetchRangeTerminalsSuccess(json.terminals.filter((val) => val.room_id !== null)));
        return json.terminals;
      })
      .catch((error) => dispatch(fetchRangeTerminalsFailure(`${start} - ${end}`, error.status)));
  };
}

export const resetRange = () => ({
  type: actions.RESET_RANGE
});

export const updateSelectedRange = (termID, checked) => ({
  type: actions.UPDATE_SELECTED_RANGE,
  termID,
  checked
});

export const updateTerminalRange = (checked) => ({
  type: actions.UPDATE_TERMINAL_RANGE,
  checked
});

export const batchUpdateTerminalRangeBegin = () => ({
  type: actions.BATCH_TERMINAL_RANGE_BEGIN
});

export const batchUpdateTerminalRangeSuccess = () => ({
  type: actions.BATCH_TERMINAL_RANGE_SUCCESS
});

export const batchUpdateTerminalRangeFailure = (error) => ({
  type: actions.BATCH_TERMINAL_RANGE_FAILURE,
  error
});

export function batchUpdateTerminalRange(range, submitObj) {
  submitObj.ids = range;
  return (dispatch) => {
    dispatch(batchUpdateTerminalRangeBegin());
    return patchObjectPromise(TERMINAL_RESOURCE, null, submitObj)
      .then((json) => {
        if (!httpSuccess(json.status)) {
          dispatch(batchUpdateTerminalRangeFailure(json.status));
          return json;
        } else {
          dispatch(clearTable());
          return dispatch(getAllTerminals({ limit: 25, page: 1, sort: 'room_id:asc' }))
            .then(() => {
              dispatch(batchUpdateTerminalRangeSuccess());
              return json;
            });
        }
      })
      .catch((error) => {
        dispatch(batchUpdateTerminalRangeFailure(error.status));
        return error;
      });
  };
}
