import { Dialog, DialogContent, Grid } from '@mui/material';
import {
    debounce, filter, intersectionBy,
    map, orderBy
} from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { SonifiLabel, SonifiLockoutModalSpinner, SonifiModalHeader, SonifiText } from '../../../containers/';
import { removePipe } from '../../../utils/textUtil';
import { getGroupSites } from '../../GroupManagement/actions/groupActions';
import { addUser, getUserSites, updateSelectedUser } from '../actions/userActions';
import { USERS_THAT_EDIT } from '../constants/index';
import { getErrorText, isError } from '../utils/index';
import { validId, validUser } from '../utils/validator';
import SiteSelector from './SiteSelector';

const styles = (theme) => ({
  editContent: {
    height: '100%',
    overflow: 'hidden'
  },
  siteAssignment: {
    overflowX: 'hidden',
    maxHeight: '88px',
    minHeight: '88px',
    wordBreak: 'break-all',
    border: `1px ${theme.palette.primary.border} solid`,
    marginBottom: '15px',
    paddingLeft: '7px'
  },
  siteSelect: {
    height: 'calc(100% - 220px)',
    overflow: 'auto',
    borderBottom: `1px ${theme.palette.primary.border} solid`,
  }
});

class EditUser extends Component {
  state = {
    allowSave: true,
    email: '',
    errors: null,
    given_name: '',
    group: '',
    isNew: false,
    family_name: '',
    limit: 10000,
    role: '',
    sites: [],
    username: ''
  };

  componentDidMount() {
    const { selectedUser, userGroup } = this.props;

    let myGroup = selectedUser.group || '';
    if (userGroup && userGroup !== null && userGroup.length > 0) {
      myGroup = userGroup;
    }

    let mySites = selectedUser.sites || [];
    if (!!(selectedUser.isNew) && myGroup.length > 0) {
      mySites = ['*'];
    }

    this.setState({
      email: selectedUser.email,
      given_name: selectedUser.given_name || '',
      group: myGroup,
      isNew: !!(selectedUser.isNew),
      family_name: selectedUser.family_name || '',
      role: selectedUser.role || '',
      sites: mySites,
      username: selectedUser.username || ''
    }, () => {
      this.filterData(!selectedUser.isNew, (myGroup !== '' && myGroup !== null));
    });
  }

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  updateField = (name) => ({ target: { value } }) => {
    this.updateState(name, removePipe(value));
  };

  updateRole = ({ target: { value } }) => {
    if (USERS_THAT_EDIT.includes(value)) {
      this.setState({ role: value, sites: ['*'] }, () => {
        this.debouncedLoadMoreData();
      });
    } else {
      this.updateState('role', value);
    }
  };

  updateState(name, value) {
    this.setState({ [name]: value }, () => {
      this.debouncedLoadMoreData();
    });
  }

  updateGroup = (value) => {
    this.setState({ group: removePipe(value) }, () => {
      this.props.dispatch(getGroupSites(this.state.group, { limit: this.state.limit }));
      this.debouncedLoadMoreData();
    });
  };

  filterData(existingUser, myGroup) {
    const currUser = {};
    currUser.email = this.state.email;
    currUser.given_name = this.state.given_name;
    currUser.isNew = this.state.isNew;
    currUser.family_name = this.state.family_name;

    // currUser.sites = (this.state.sites === ['*'] ? ['*'] : orderBy(this.state.sites, [(a) => a.id], ['asc']));
    currUser.sites = (this.state.sites === ['*'] ? ['*'] : orderBy(this.state.sites, [], ['asc']));
    currUser.username = this.state.username;
    currUser.group = this.state.group;
    currUser.role = this.state.role;

    this.props.dispatch(updateSelectedUser(currUser));
    if (existingUser) {
      this.props.dispatch(getUserSites(currUser.username, currUser.email));
    }

    if (myGroup) {
      this.props.dispatch(getGroupSites(currUser.group, { limit: this.state.limit }));
    }
  }

  onSave = () => {
    const {
      globalTranslations, selectedUser, groups, groupSites, translations, userRoles
    } = this.props;

    const currUser = {}; // { ...selectedUser };
    currUser.email = (selectedUser.email && selectedUser.email.length > 0
      ? selectedUser.email.trim().toLowerCase()
      : '');
    currUser.given_name = (selectedUser.given_name && selectedUser.given_name.length > 0
      ? selectedUser.given_name.trim()
      : '');// selectedUser.given_name.trim();
    currUser.family_name = (selectedUser.family_name && selectedUser.family_name.length > 0
      ? selectedUser.family_name.trim()
      : '');// selectedUser.family_name.trim();
    currUser.group = (selectedUser.group && selectedUser.group.length > 0
      ? selectedUser.group.trim()
      : '');// selectedUser.group.trim();
    currUser.role = (selectedUser.role && selectedUser.role.length > 0
      ? selectedUser.role.trim()
      : '');// selectedUser.role.trim();
    currUser.username = `${currUser.email.trim().toLowerCase()}`;
    currUser.sites = (selectedUser.sites[0] === '*'
      ? ['*']
      : intersectionBy(selectedUser.sites, map(groupSites, 'id')));

    const validationErrors = validUser(currUser, userRoles, groups, translations.errors, globalTranslations.errors);
    this.setState({ errors: validationErrors });

    if (selectedUser.isNew && !validationErrors.email) {
      validId(currUser.email, translations.errors).then((data) => {
        validationErrors.email = data.message;
        this.onSaveContinue(currUser, validationErrors);
      }).catch(() => {
        this.onSaveContinue(currUser, validationErrors);
      });
    } else {
      this.onSaveContinue(currUser, validationErrors);
    }
  };

  onSaveContinue(currUser, validationErrors) {
    const { userFilter } = this.props;

    if (Object.entries(validationErrors).length === 0) {
      this.setState({ errors: null });
      this.props.dispatch(addUser(currUser, this.props.userGroup, userFilter));
    } else {
      this.setState({ errors: validationErrors });
    }
  }

  getSiteAssignments = () => {
    const { groupSites, selectedUser, translations } = this.props;
    if (selectedUser.sites && selectedUser.sites[0] === '*') {
      return translations.allSites;
    }

    if (selectedUser.sites && selectedUser.sites.length > 0 && groupSites.length > 0) {
      const result1 = filter(groupSites, (o) => selectedUser.sites.includes(o.id));
      if (result1.length > 0) {
        let assignedSites = `${result1[0].name}`;
        for (let i = 1; i < result1.length; i++) {
          assignedSites += `, ${result1[i].name}`;
        }
        return assignedSites;
      }
    }
    return '';
  };

  getUserText(fieldName, disabled) {
    const { canEdit, translations } = this.props;
    const { errors } = this.state;

    return (<Grid item xs={3}>
      <SonifiText
        size="percent"
        label={translations[fieldName]}
        defaultValue={this.state[fieldName]}
        change={this.updateField(fieldName)}
        error={isError(fieldName, errors)}
        errorText={getErrorText(fieldName, errors)}
        disabled={disabled || !canEdit}
      />
    </Grid>
    );
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  render() {
    const {
      addingUser, canEdit, classes, gettingUserSites, globalTranslations, selectedUser, translations, userRoles
    } = this.props;
    const {
      allowSave, errors, role
    } = this.state;
    const siteError = isError('sites', errors);

    if (addingUser) {
      return (<SonifiLockoutModalSpinner show={addingUser} />);
    }

    const currentlySelectedSites = this.getSiteAssignments();

    return (
      <Dialog open={true}
        onClose={this.onClose.bind(this)}
        fullWidth
        maxWidth="lg" >
        <SonifiModalHeader
          header={`${(selectedUser.isNew ? translations.addTitle : translations.editTitle)}`}
          onlyClose={!allowSave || !canEdit}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.onSave.bind(this)}
          label={globalTranslations.defaults.save}
        />
        <DialogContent >
          <Grid container className={classes.editContent}>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                {this.getUserText('given_name')}
                {this.getUserText('family_name')}
                {this.getUserText('email', !selectedUser.isNew)}
                <Grid item xs={3}>
                  <SonifiText
                    label={translations.role}
                    select
                    items={userRoles.filter((pilot) => !pilot.private).map((suggestion) => ({
                      id: suggestion.id,
                      value: suggestion.id,
                      label: suggestion.id,
                    }))}
                    size="percent"
                    change={this.updateRole.bind(this)}
                    defaultValue={role}
                    error={isError('role', errors)}
                    errorText={getErrorText('role', errors)}
                    disabled={!canEdit}
                    testLabel="userRoleEditDropdown"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <SonifiLabel label={translations.siteAssign} />
            </Grid>
            <Grid item xs={12} className={classes.siteAssignment}>
              {((selectedUser && selectedUser.sites && selectedUser.sites.length === 0 && !siteError) ||
                currentlySelectedSites.length === 0)
                ? <SonifiLabel disabled label={translations.noSelectedSites} />
                : <SonifiLabel
                  error={siteError}
                  label={siteError
                    ? getErrorText('sites', errors)
                    : currentlySelectedSites} />
              }
            </Grid>
            <Grid item xs={12} className={classes.siteSelect}>
              {!gettingUserSites && <SiteSelector
                canEdit={canEdit}
                errors={errors}
                groupId={this.state.group}
                updateGroup={this.updateGroup.bind(this)}
                updateSites={this.updateState.bind(this)}
                userSites={this.state.sites} />}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  addingUser: state.users.addingUser,
  allSites: state.groups.allSites,
  gettingUserSites: state.users.gettingUserSites,
  globalTranslations: state.global.translations,
  groups: state.groups.groups,
  groupSites: (state.groups.selectedGroup && state.groups.selectedGroup.sites ? state.groups.selectedGroup.sites : []),
  selectedUser: state.users.selectedUser,
  translations: state.users.translations.dialog,
  userFilter: state.users.userFilter,
  userGroup: state.global.group,
  userRoles: state.roles.roles
});

EditUser.propTypes = {
  addingUser: PropTypes.bool,
  allSites: PropTypes.array,
  canEdit: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  gettingUserSites: PropTypes.bool,
  globalTranslations: PropTypes.object,
  groups: PropTypes.array,
  groupSites: PropTypes.array,
  onCancel: PropTypes.func,
  selectedUser: PropTypes.object,
  translations: PropTypes.object,
  userFilter: PropTypes.string,
  userGroup: PropTypes.string,
  userRoles: PropTypes.array,
  userState: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(EditUser, styles, { withTheme: true }));
