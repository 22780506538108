import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiTabs from '../../../containers/SonifiTabs';
import { EVENT, REQUEST, SERVER } from '../constants/constants';
import Event from './agilysys/Event';
import Request from './agilysys/Request';
import Server from './agilysys/Server';

class Agilysys extends Component {
  state = {
    selectedTab: SERVER
  };

  handleChange(event, newValue) {
    this.setState({ selectedTab: newValue });
  }

  getTabContainer() {
    const { notEditable, editState } = this.props;

    switch (this.state.selectedTab) {
      case SERVER:
        return <Server notEditable={notEditable} editState={editState} updateTypeState={this.props.updateTypeState} />;
      case EVENT:
        return <Event notEditable={notEditable} editState={editState} updateTypeState={this.props.updateTypeState} />;
      case REQUEST:
        return <Request notEditable={notEditable} editState={editState} updateTypeState={this.props.updateTypeState} />;
      default:
        return <Fragment />;
    }
  }

  render() {
    const { selectedTab } = this.state;

    const tabsArray = [
      { id: SERVER, title: SERVER },
      { id: EVENT, title: EVENT },
      { id: REQUEST, title: REQUEST }
    ];

    return (<SonifiTabs
      tabs={tabsArray}
      handleChange={this.handleChange.bind(this)}
      tabValue={selectedTab}
      showingTab={this.getTabContainer()} />
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  translations: state.integrations.translations.agilysys
});

Agilysys.propTypes = {
  dispatch: PropTypes.func,
  editState: PropTypes.object,
  globalTranslations: PropTypes.object,
  notEditable: PropTypes.bool,
  translations: PropTypes.object,
  updateTypeState: PropTypes.func
};

export default connect(mapStateToProps)(Agilysys);
