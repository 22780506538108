import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import SonifiSpinner from './SonifiSpinner';

const styles = () => ({
  spinner: {
    display: 'flex', flexDirection: 'column', justifyContent: 'center'
  }
});

export class SonifiSpinnerWithMessage extends Component {
  render() {
    const { classes, message, show } = this.props;
    return (
      <Dialog
        open={show}
      >
        <DialogContent style={{ overflow: 'hidden' }} >
          <div className={classes.spinner}>
            <SonifiSpinner />
            <div>
              {message}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

SonifiSpinnerWithMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};

export default withStyles(SonifiSpinnerWithMessage, styles);
