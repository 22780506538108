import * as types from './actionTypes';

export const resetTermFiles = () => ({
  type: types.RESET_TERM_FILES
});

export const clearSonifiFirmware = () => ({
  type: types.CLEAR_SONIFI_FIRMWARE
});

// export const fetchSonifiFirmwareBegin = () => ({
//   type: types.FETCH_SONIFI_FIRMWARE_BEGIN
// });

// export const fetchSonifiFirmwareSuccess = (sonifiFirmware) => ({
//   type: types.FETCH_SONIFI_FIRMWARE_SUCCESS,
//   sonifiFirmware
// });

// export const fetchSonifiFirmwareFailure = (error) => ({
//   type: types.FETCH_SONIFI_FIRMWARE_FAILURE,
//   error
// });

// export function fetchSonifiFirmware() {
//   return (dispatch) => {
//     dispatch(fetchSonifiFirmwareBegin());

//     return getSiteObjectPromise(TERM_MODELS, null, null, { make: SONIFI })
//       .then((termFiles) => {
//         dispatch(fetchSonifiFirmwareSuccess(termFiles.models));
//         return true;
//       }).catch((error) => {
//         dispatch(fetchSonifiFirmwareFailure(`${error.status}`));
//         return false;
//       });
//   };
// }

export const updateSonifiFirmware = (sonifiIndex, editType) => ({
  type: types.UPDATE_SELECTED_SONIFI_FIRMWARE,
  sonifiIndex,
  editType
});
