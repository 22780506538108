// DataTable rows of Publish items with popover and event handlers
// Very similar to MessageTableBody

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import StopIcon from '@mui/icons-material/Stop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid, TableBody, TableCell, TableRow } from '@mui/material';
import Popover from '@mui/material/Popover';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { withStyles } from 'tss-react/mui';
import { FORM_MODE, FORM_TYPE, getDisplayTypeDisplayName } from '../../../constants/messages';
import { MESSAGEPUBLISH_EDIT } from '../../../constants/roles';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiLockoutModalSpinner from '../../../containers/SonifiLockoutModalSpinner';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiTooltip from '../../../containers/SonifiTooltip';
import { dateToString } from '../../../utils';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import {
  cancelDialog, clearDialogVariables, clearSelectedPublishItem, deletePublishItem, getPublishItem,
  setSelectedPublishItem, showMessageDialog, stopPublishItem
} from '../actions/messagingActions';

/* eslint-disable camelcase */
const DISTANCE_FROM_END = 3; // insert waypoint object 3 items from the end of the list
const styles = (theme) => ({
  hover: {
    // Do not remove
  },
  deletedOrExpired: {
    // Do not remove
    color: '#d7d7d7'
  },
  // tableCell: {
  //   '$deletedOrExpired &': {
  //     color: '#d7d7d7'
  //   },
  // },
  popOverContainer: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
});

class PublishTableBody extends Component {
  state = {
    deleteDialogOpen: false,
    open: false,
    popOverElement: null,
    popOverHasStarted: false,
    popOverHeight: null,
    popOverId: null,
    popOverIsExpired: false,
    stopDialogOpen: false
  };

  handleViewDialog() {
    this.setState({ open: false });
    this.props.dispatch(getPublishItem(this.state.popOverId));
    this.props.dispatch(showMessageDialog(FORM_TYPE.PUBLISH, FORM_MODE.VIEW));
  }

  handleEditDialog() {
    this.setState({ open: false });
    this.props.dispatch(getPublishItem(this.state.popOverId));
    this.props.dispatch(showMessageDialog(FORM_TYPE.PUBLISH, FORM_MODE.EDIT));
  }

  handleCopyDialog() {
    this.setState({ open: false });
    this.props.dispatch(getPublishItem(this.state.popOverId));
    this.props.dispatch(showMessageDialog(FORM_TYPE.PUBLISH, FORM_MODE.COPY));
  }

  handleStopDialog() {
    this.setState({ stopDialogOpen: true, open: false });
  }

  handleDeleteDialog() {
    this.setState({ deleteDialogOpen: true, open: false });
  }

  getMoreData() {
    this.props.onWayPointReached();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((!prevProps.dialogSaveAttemptComplete && this.props.dialogSaveAttemptComplete) ||
      (!prevProps.dialogCancelSuccess && this.props.dialogCancelSuccess)) {
      this.props.dispatch(clearDialogVariables());
      this.setState({
        open: false,
        stopDialogOpen: false,
        deleteDialogOpen: false
      });
    }
    if (prevState.open !== this.state.open) {
      if (this.state.open) {
        this.props.dispatch(setSelectedPublishItem(this.state.popOverId));
      } else if (this.state.popOverId === null) {
        this.props.dispatch(clearSelectedPublishItem());
      }
    }
  }

  // check end_timestamp to determine if message has expired
  isMessageExpired = (item) => {
    const end = moment.utc(item.end_timestamp).tz(this.props.timezone).toDate();
    const now = moment.utc().tz(this.props.timezone).toDate();
    if ((!item.end_timestamp) || (end > now)) {
      return false;
    } else {
      return true;
    }
  };

  // used to determine if this is an "active" message
  hasMessageStarted = (item) => {
    const start = moment.utc(item.start_timestamp).tz(this.props.timezone).toDate();
    const now = moment.utc().tz(this.props.timezone).toDate();
    return (start <= now);
  };

  handlePublishItemDelete = () => {
    this.props.dispatch(deletePublishItem(this.props.selectedPublishItem));
  };

  verifyCancelDialog = () => {
    this.setState({ deleteDialogOpen: false, stopDialogOpen: false, open: false });
    this.props.dispatch(cancelDialog());
  };

  handleStopPublishItem = () => {
    this.props.dispatch(stopPublishItem(this.props.selectedPublishItem, moment.utc().tz(this.props.timezone).format()));
  };

  getPublishItem = (id) => this.props.publish.filter(((e) => e.id === id))[0];

  handlePopover = (id) => (event) => {
    const { currentTarget } = event;
    const item = this.getPublishItem(id);
    this.setState((state) => ({
      popOverId: id,
      popOverElement: currentTarget,
      popOverHeight: currentTarget.clientHeight,
      popOverIsExpired: this.isMessageExpired(item),
      popOverHasStarted: this.hasMessageStarted(item),
      open: !state.open
    }));
  };

  handlePopoverClose = (event) => {
    // this.props.dispatch(clearSelectedLibraryItem());
    if (event) {
      if (this.state.popOverElement.contains(event.target)) {
        return;
      }
    }

    this.setState({ open: false });
  };

  getToolTip(title, icon, onClick, disabled = false) {
    return (<SonifiTooltip
      onClick={onClick}
      icon={icon}
      title={title}
      disabled={disabled}
    />);
  }

  /* eslint-disable max-lines-per-function */
  render() {
    const {
      classes, confirmLoading, globalTranslations, publish, publishLoading, timezone, translations, userPermissions
    } = this.props;
    const {
      open, deleteDialogOpen, stopDialogOpen, popOverElement, popOverHeight, popOverIsExpired, popOverHasStarted
    } = this.state;

    if (publishLoading && publish.length === 0) {
      return (<TableBody className={classes.tableBody}>
        <TableRow>
          <TableCell colSpan={7} >
            <SonifiSpinner />
          </TableCell>
        </TableRow>
      </TableBody>);
    }

    return (<Fragment>
      <SonifiConfirm
        dialogOpen={deleteDialogOpen && !confirmLoading}
        onConfirm={this.handlePublishItemDelete}
        onCancel={this.verifyCancelDialog}
        confirmTitle={translations.publishDelete}
        confirmText={''}
        buttonCancelText={globalTranslations.cancel}
        buttonConfirmText={globalTranslations.delete}
      />
      <SonifiConfirm
        dialogOpen={stopDialogOpen && !confirmLoading}
        onConfirm={this.handleStopPublishItem}
        onCancel={this.verifyCancelDialog}
        confirmTitle={translations.publishStop}
        confirmText={''}
        buttonCancelText={globalTranslations.cancel}
        buttonConfirmText={globalTranslations.stop}
      />

      <SonifiLockoutModalSpinner show={confirmLoading} />

      <Popover
        open={open}
        anchorEl={popOverElement}
        PaperProps={{
          style: { borderRadius: 0 }
        }}
        onClose={this.handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.popOverContainer}
          style={{ height: { popOverHeight } }}
        >
          {this.getToolTip(globalTranslations.view,
            <VisibilityIcon fontSize="small" />, this.handleViewDialog.bind(this))}
          {checkForSingleUserPermission(MESSAGEPUBLISH_EDIT, userPermissions) &&
            <Fragment>
              {this.getToolTip(globalTranslations.edit,
                <EditIcon fontSize="small" />, this.handleEditDialog.bind(this),
                (popOverHasStarted || popOverIsExpired))}
              {this.getToolTip(globalTranslations.copy,
                <FileCopyIcon fontSize="small" />, this.handleCopyDialog.bind(this))}
              {this.getToolTip(globalTranslations.stop,
                <StopIcon fontSize="small" />, this.handleStopDialog.bind(this),
                (!popOverHasStarted || popOverIsExpired))}
              {this.getToolTip(globalTranslations.delete,
                <DeleteIcon fontSize="small" />, this.handleDeleteDialog.bind(this), popOverHasStarted)}
            </Fragment>
          }
        </Grid>
      </Popover>
      <TableBody>
        {publish.length === 0 &&
          <TableRow>
            <TableCell colSpan={7}>
              <SonifiLabel error label={translations.errors.noScheduled} />
            </TableCell>
          </TableRow>
        }
        {publish.map(({
          id, body, user_name, start_timestamp, end_timestamp, requester, organization
        }, i, item) => (
          <TableRow
            key={id}
            hover={true}
            classes={{ hover: classes.hover }}
            className={
              classNames(this.isMessageExpired(item[i]) ? classes.deletedOrExpired : null)
            }
            onClick={this.handlePopover(id)}
          >
            <TableCell className={classes.tableCell}>{body.name}
              {i === publish.length - DISTANCE_FROM_END && (
                <Waypoint onEnter={() => {
                  this.getMoreData();
                }}
                />)}
            </TableCell>
            <TableCell className={classes.tableCell}>{getDisplayTypeDisplayName(body.display_type)}</TableCell>
            <TableCell className={classes.tableCell}>{requester}</TableCell>
            <TableCell className={classes.tableCell}>{organization}</TableCell>
            <TableCell className={classes.tableCell}>{dateToString(start_timestamp, timezone)}</TableCell>
            <TableCell className={classes.tableCell}>{dateToString(end_timestamp, timezone)}</TableCell>
            <TableCell className={classes.tableCell}>{user_name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  confirmLoading: state.messaging.confirmLoading,
  dialogCancelSuccess: state.messaging.dialogCancelSuccess,
  dialogOpen: state.messaging.dialogOpen,
  dialogSaveAttemptComplete: state.messaging.dialogSaveAttemptComplete,
  globalTranslations: state.global.translations.defaults,
  publish: state.messaging.publish,
  publishLoading: state.messaging.publishLoading,
  selectedPublishItem: state.messaging.selectedPublishItem,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
  translations: state.messaging.translations.messaging,
  userPermissions: state.global.permissions
});

PublishTableBody.propTypes = {
  classes: PropTypes.object.isRequired,
  confirmLoading: PropTypes.bool,
  dialogCancelSuccess: PropTypes.bool,
  dialogOpen: PropTypes.bool,
  dialogSaveAttemptComplete: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  library: PropTypes.array,
  onWayPointReached: PropTypes.func,
  publish: PropTypes.array,
  publishLoading: PropTypes.bool,
  selectedLibraryItem: PropTypes.string,
  selectedPublishItem: PropTypes.number,
  timezone: PropTypes.string.isRequired,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(PublishTableBody, styles, { withTheme: true }));

