import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../../containers/SonifiLabel';
import SonifiPopover from '../../../../containers/SonifiPopover';
import SonifiSpinner from '../../../../containers/SonifiSpinner';
import SonifiTableHead from '../../../../containers/SonifiTableHead';
import SonifiTemplate from '../../../../containers/SonifiTemplate';
import { clearFirmware, fetchFirmwareFileBegin } from '../../../Firmware/actions/firmwareActions';
import { editClientFile } from '../../actions/LgFirmwareActions';
import LgClientDialog from './LgClientDialog';

class LgClientGrid extends Component {
  handleEditDialog() {
    this.props.dispatch(fetchFirmwareFileBegin());
    this.props.dispatch(clearFirmware());

    setTimeout(() => {
      this.props.dispatch(editClientFile(true));
    }, 200);
  }

  getTableRow() {
    const { lgClient, translations } = this.props;

    if (!lgClient || lgClient.length === 0) {
      return <TableCell>
        <SonifiLabel error label={translations.lg.errors.noClientFirmware} />
      </TableCell>;
    }

    return <TableCell>{lgClient}</TableCell>;
  }

  getPageDetails() {
    const {
      canEdit, lgLoading, translations, globalTranslations
    } = this.props;

    const tableHeader = [
      { id: 'id', sortable: false, numeric: false, label: `${translations.tlx.filename}` }
    ];

    return (
      <Table>
        <SonifiTableHead headColumns={tableHeader} />
        {lgLoading
          ? <TableBody>
            <TableRow>
              <TableCell colSpan={2}>
                <SonifiSpinner />
              </TableCell>
            </TableRow >
          </TableBody>
          : <TableBody>
            {canEdit
              ? <SonifiPopover
                hideEdit={!canEdit}
                editFunc={this.handleEditDialog.bind(this)}
                editTitle={globalTranslations.edit}
                hideDelete={true}
                tableRow={this.getTableRow()}
              />
              : <TableRow>{this.getTableRow()}</TableRow>
            }
          </TableBody>
        }
      </Table>
    );
  }

  render() {
    const { canEdit, editClient, translations } = this.props;
    return (
      <Fragment>
        <SonifiTemplate
          header={translations.lg.clientTitle}
          pageDetails={this.getPageDetails()}
          multiple={true}
        />
        {editClient && <LgClientDialog canEdit={canEdit} />}
      </Fragment>

    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.termFile.changed,
  globalTranslations: state.global.translations.defaults,
  lgClient: state.termFile.lgClient,
  editClient: state.termFile.editClient,
  lgLoading: state.termFile.lgLoading,
  translations: state.termFile.translations.grid
});

LgClientGrid.propTypes = {
  canEdit: PropTypes.bool,
  changed: PropTypes.bool,
  globalTranslations: PropTypes.object,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  lgClient: PropTypes.string,
  editClient: PropTypes.bool,
  lgLoading: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(LgClientGrid);
