import { Grid, IconButton, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  smallButton: {
    'margin': theme.spacing(1),
    'padding': 6,
    'backgroundColor': 'transparent',
    'color': '#fff',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: '#fff',
    },
  }
});

export class SonifiTooltip extends Component {
  render() {
    const { onClick, icon, classes, title } = this.props;

    const disabled = (this.props.disabled) ? this.props.disabled : false;

    return (
      <Grid item >
        <Tooltip title={title} aria-label={title}>
          <div>
            <IconButton onClick={onClick} className={classes.smallButton} disabled={disabled}>
              {icon}
            </IconButton>
          </div>
        </Tooltip>
      </Grid>
    );
  }
}

SonifiTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  icon: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

export default withStyles(SonifiTooltip, styles, { withTheme: true });
