import { Check, CheckCircleOutline } from '@mui/icons-material';
import { TableCell, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import { findObjectByKey } from '../../../utils';
import { updateActiveRow, updateSelectedClient } from '../actions/tieApiClientActions';
import { BRAND_OPTIONS, JWT_BEARER } from '../constants/TieApiClientConstants';

class ClientRow extends Component {
  getBrandName(brand) {
    const brandName = findObjectByKey(BRAND_OPTIONS, 'value', brand);
    return brandName ? brandName.name : brand;
  }

  handleEditDialog(type) {
    const { rowIndex } = this.props;
    this.props.dispatch(updateSelectedClient(rowIndex, type));
  }

  handleActiveTieClient() {
    const { clients, dispatch, rowIndex } = this.props;
    dispatch(updateActiveRow(clients[rowIndex], rowIndex));
  }

  render() {
    const {
      canEdit, globalTranslations, key, clients, rowIndex
    } = this.props;
    const {
      active, config, id, type
    } = clients[rowIndex];
    const clientType = clients[rowIndex].client_type;
    let ip = '',
      brand = '',
      scopes = '';
    if (config) {
      if (config.brand) {
        brand = config.brand;
      }
      ip = config.ip || '';
      scopes = config.scopes;
    }

    let scopeNamesTemp = '';
    if (scopes) {
      // Object.values(scope).forEach((value) => scopeNamesTemp += `${value.name}:${value.type} | `);
      Object.values(scopes).forEach((value) => (scopeNamesTemp += `${value} | `));
    }
    const scopeNames = scopeNamesTemp.trim().slice(0, -1);
    const ipArray = typeof (ip) === 'string' ? [`${ip}`] : [...ip];

    return (
      <SonifiPopover
        hideDelete={true}
        editDisabled={type === JWT_BEARER || !canEdit}
        editFunc={this.handleEditDialog.bind(this, 'edit')}
        editTitle={globalTranslations.edit}
        functionOneFunc={this.handleEditDialog.bind(this, 'read')}
        functionOneTitle={globalTranslations.view}
        functionTwoFunc={this.handleActiveTieClient.bind(this, 'read')}
        functionTwoIcon={<Check fontSize="small" />}
        functionTwoTitle={(active ? globalTranslations.deactivate : globalTranslations.activate)}
        functionTwoDisabled={!canEdit}
        key={key}
        showFunctionOne={true}
        showFunctionTwo={true}
        tableRow={
          <Fragment>
            <TableCell>
              {active && <Tooltip title={globalTranslations.active}>
                <CheckCircleOutline style={{ color: 'green', width: '52px' }} />
              </Tooltip>}
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={`${decodeURIComponent(id)}` || ''} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={this.getBrandName(brand) || ''} />
            </TableCell>
            <TableCell component="th" scope="row" >
              {ip && ipArray.map((object, index) => (
                <SonifiLabel key={index} label={object} noPadding />
              ))}
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={`${type}` || ''} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={clientType} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={scopeNames} />
            </TableCell>
          </Fragment >
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  clients: state.tieClients.clients,
  globalTranslations: state.global.translations.defaults,
  translations: state.tieClients.translations
});

ClientRow.propTypes = {
  canEdit: PropTypes.bool,
  clients: PropTypes.array,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  key: PropTypes.number,
  rowIndex: PropTypes.number.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(ClientRow);

