import React from 'react';
import { Navigate } from 'react-router-dom';
import { getSiteNumFromURI } from '../../utils';
import IcpGrid from './components/IcpGrid';
export default function Icp() {
  if (getSiteNumFromURI() === null) {
    return <Navigate replace to="/" />;
  }
  return (
    <IcpGrid />
  );
}
