import { Add, Cancel, Check } from '@mui/icons-material';
import {
    Grid, Paper, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import {
    filter, map, reduce, sortBy, without
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { NOT_FOUND } from '../../../constants/messages';
import { MESSAGEPUBLISH_EDIT, MESSAGE_EDIT } from '../../../constants/roles';
import {
    SonifiIconButton, SonifiLabel, SonifiSpinner, SonifiSubHeader, SonifiTableHead, SonifiText
} from '../../../containers';
import SonifiDateTimePicker from '../../../containers/SonifiDateTimePicker';
import { getErrorText, isError } from '../../../utils';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { getFormErrors } from '../../Messaging/utils/validator';
import * as actions from '../actions/groupServicesActions';

const styles = () => ({
  contents: {
    width: '100%'
  },
  innerContents: {
    border: 'none',
    borderBottom: '1px solid #d6d6d6',
    flexGrow: 1,
    maxHeight: '96%',
    overflowX: 'auto',
    width: '100%',
  },
  messageForm: {
    border: '1px solid #dedfdf',
    padding: '10px'
  },
  outerLayer: {
    display: 'flex',
    height: '100%',
    width: '100%'
  }
});

class GroupMessages extends Component {
  state = {
    currentMomentTime: null,
    arrival: null,
    departure: null,
    formErrors: {},
    language: '',
    showMessageForm: false,
    translations: []
  };

  componentDidMount() {
    const { defaultLanguage, selectedGroup } = this.props;
    const minDate = moment(selectedGroup.arrival);
    const currentMomentTime = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const arrivalTimeInPast = selectedGroup.arrival < currentMomentTime;

    this.setState({
      currentMomentTime,
      arrival: arrivalTimeInPast ? currentMomentTime : minDate,
      departure: selectedGroup.departure,
      language: defaultLanguage,
      translations: []
    });
  }

  componentDidUpdate() {
    const { arrival, departure } = this.props.selectedGroup;
    let start = this.state.arrival;
    let end = this.state.departure;

    if (departure < end) {
      end = departure;
    }

    if (start < arrival) {
      start = arrival;
    }

    if (start < this.state.currentMomentTime) {
      start = this.state.currentMomentTime;
    }

    if (end !== this.state.departure || start !== this.state.arrival) {
      this.setState({ arrival: start, departure: end });
    }
  }

  renderTable() {
    const { dialogTranslations, gettingMessages, messages, sendingMessage } = this.props;

    if (!messages) {
      return <div />;
    }

    const tableHeader = [
      { id: 'name', sortable: false, numeric: false, label: dialogTranslations.messageName },
      { id: 'title', sortable: false, numeric: false, label: dialogTranslations.messageTitle },
      { id: 'body', sortable: false, numeric: false, label: dialogTranslations.messageBody }
    ];
    return (
      <Table id="GroupMessages" size="small">
        <SonifiTableHead headColumns={tableHeader} />
        <TableBody>
          {messages && messages.length === 0
            ? <TableRow><TableCell colSpan={3}>
              {sendingMessage || gettingMessages
                ? <SonifiSpinner />
                : <SonifiLabel label={dialogTranslations.noMessages} />}
            </TableCell></TableRow>
            : messages.map((item, i) => (
              <TableRow key={i}>
                {/* <TableCell component="th" scope="row">{item.user_name}</TableCell> */}
                <TableCell component="th" scope="row">{item.name}</TableCell>
                <TableCell component="th" scope="row">{item.title}</TableCell>
                <TableCell component="th" scope="row">{item.body}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>);
  }

  handleOnTranslationChange = (name) => ({ target: { value } }) => {
    const { language, translations } = this.state;
    const pos = translations.map((e) => e.id).indexOf(language);

    switch (name) {
      case 'title':
        if (pos === NOT_FOUND) {
          translations.push({
            id: language,
            title: value,
            body: '',
          });
        } else {
          translations[pos].title = value;
        }
        break;
      case 'body':
        if (pos === NOT_FOUND) {
          translations.push({
            id: language,
            title: '',
            body: value,
          });
        } else {
          translations[pos].body = value;
        }
        break;
      default:
        break;
    }

    const removeBlankTranslations =
      filter(translations, (transValue) => transValue.title !== '' || transValue.body !== '');

    this.props.updateMessageState(removeBlankTranslations.length > 0);
    this.setState({ translations: removeBlankTranslations });
  };

  handleOnChange = (name, { target: { value } }) => {
    this.setState({
      [name]: value
    });
  };

  handleDateChange = (name) => (value) => {
    if (value === null) {
      return;
    }

    this.setState({
      [name]: (value === null) ? null : moment(value).format('YYYY-MM-DDTHH:mm:ssZ')
    });
  };

  renderMessageForm() {
    const {
      availableLanguages, canEdit, classes, dialogTranslations, messageTranslations, selectedGroup
    } = this.props;
    const {
      arrival, currentMomentTime, departure, formErrors, language, translations
    } = this.state;
    let title = '',
      body = '';
    const selected = translations.filter(((e) => e.id === language));
    if ((selected !== undefined) && (selected[0])) {
      title = selected[0].title;
      body = selected[0].body;
    }
    const arrivalTimeInPast = selectedGroup.arrival < currentMomentTime;
    const minDate = arrivalTimeInPast ? currentMomentTime : moment(selectedGroup.arrival);
    const maxDate = moment(selectedGroup.departure);

    const removeUndefinedLangs = without(availableLanguages, undefined);
    return (
      <Grid container className={classes.messageForm}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={4}>
              <SonifiDateTimePicker
                change={this.handleDateChange('arrival')}
                defaultValue={arrival}
                disabled={!canEdit}
                disablePast={true}
                error={isError('arrival', formErrors)}
                errorText={getErrorText('arrival', formErrors)}
                label={dialogTranslations.startDate}
                maxDate={maxDate}
                minDate={minDate}
                size="percent"
              />
            </Grid>
            <Grid item xs={4}>
              <SonifiDateTimePicker
                change={this.handleDateChange('departure')}
                defaultValue={departure}
                disabled={!canEdit}
                disablePast={true}
                error={isError('departure', formErrors)}
                errorText={getErrorText('departure', formErrors)}
                label={dialogTranslations.endDate}
                maxDate={maxDate}
                minDate={minDate}
                size="percent"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          {removeUndefinedLangs.length === 1
            ? <SonifiText
              label={messageTranslations.language}
              defaultValue={removeUndefinedLangs[0].name}
              size="percent"
              disabled={true}
            />
            : <SonifiText
              label={messageTranslations.language}
              defaultValue={language}
              disabled={removeUndefinedLangs.length === 0 || !canEdit}
              change={this.handleOnChange.bind(this, 'language')}
              items={removeUndefinedLangs.map((suggestion) => ({
                id: suggestion.sequence,
                value: suggestion.iso_code_639_1,
                label: suggestion.name
              }))}
              select={true}
              size="percent"
            />}
        </Grid>
        <Grid item xs={6}>
          <SonifiText
            label={messageTranslations.title}
            disabled={!canEdit}
            defaultValue={title}
            change={this.handleOnTranslationChange('title')}
            size="percent"
            error={isError('title', formErrors) || isError('messageId', formErrors)}
            errorText={getErrorText('title', formErrors) || getErrorText('messageId', formErrors)}
          />
          <SonifiText
            label={messageTranslations.body}
            disabled={!canEdit}
            defaultValue={body}
            change={this.handleOnTranslationChange('body')}
            multiline={true}
            size="percent"
            error={isError('body', formErrors)}
            errorText={getErrorText('body', formErrors)}
          />
        </Grid>
      </Grid>
    );
  }

  addMessage() {
    this.setState({
      arrival: this.props.selectedGroup.arrival,
      departure: this.props.selectedGroup.departure,
      language: this.props.defaultLanguage,
      showMessageForm: !this.state.showMessageForm,
      translations: []
    });
    this.props.updateMessageState(false);
  }

  sendMessage() {
    const {
      availableLanguages, dialogTranslations, dispatch, globalTranslations, messageTranslations,
      selectedGroup, timezone
    } = this.props;
    const { arrival, departure, translations } = this.state;

    dispatch(actions.sendMessageBegin());
    const sortedLang = map(availableLanguages, 'iso_code_639_1');
    const sortedArray = sortBy(translations, (item) => sortedLang.indexOf(item.id));
    const tempMessageObj = { ...this.state, messageId: `${selectedGroup.name} - ${sortedArray[0].title}` };
    getFormErrors(tempMessageObj, globalTranslations, messageTranslations)
      .then((formErrors) => {
        if (arrival > departure || departure === arrival) {
          formErrors.push({
            fieldName: 'arrival',
            message: dialogTranslations.error.dateError
          });
        }

        if (formErrors.length) {
          const objME = reduce(formErrors, (obj, param) => {
            obj[param.fieldName] = param.message;
            return obj;
          }, {});
          this.setState({ formErrors: objME });
          dispatch(actions.resetSendGroupMessage(formErrors));
        } else {
          dispatch(actions.sendMessage(selectedGroup.id,
            {
              id: tempMessageObj.messageId,
              start_timestamp: this.state.arrival,
              end_timestamp: this.state.departure,
              translations: sortedArray
            },
            timezone));
          setTimeout(() => {
            this.setState({ showMessageForm: false, translations: [] });
            this.props.updateMessageState(false);
          }, 1000);
        }
      });
  }

  render() {
    const {
      canEdit, classes, dialogTranslations, gettingMessages, globalTranslations, sendingMessage, selectedGroup,
      userPermissions
    } = this.props;
    const { showMessageForm, translations } = this.state;
    const disableButtons = !canEdit || gettingMessages || sendingMessage;

    if (selectedGroup.isNew) {
      return <SonifiLabel label={dialogTranslations.newGroupNoMessageSend} />;
    }

    return (
      <div className={classes.outerLayer}>
        <div className={classes.contents}>
          <SonifiSubHeader
            disabled={disableButtons}
            header={showMessageForm ? dialogTranslations.createMessage : dialogTranslations.sentTitle}
            icon={<Add />}
            label={dialogTranslations.message}
            onSubmit={this.addMessage.bind(this)}
            showButton={canEdit && !showMessageForm &&
              checkForAtLeastOneUserPermission([MESSAGE_EDIT, MESSAGEPUBLISH_EDIT], userPermissions)}
            smallerHeader
            middleExtra={showMessageForm
              ? <Grid container>
                <Grid item>
                  <SonifiIconButton disabled={disableButtons}
                    onClick={this.addMessage.bind(this)} icon={<Cancel />} label={globalTranslations.defaults.cancel}
                  />
                </Grid>
                <SonifiIconButton disabled={disableButtons || translations.length === 0}
                  onClick={this.sendMessage.bind(this)} icon={<Check />}
                  label={dialogTranslations.sendNow} toolTipMessage={dialogTranslations.directions}
                  showToolTip={!(disableButtons || translations.length === 0)}
                />
              </Grid>
              : <Fragment />
            }
            toolTip={dialogTranslations.addMessage}
          />
          <Paper className={classes.innerContents}>
            {showMessageForm ? this.renderMessageForm() : this.renderTable()}
          </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  availableLanguages: state.global.languages,
  defaultLanguage: state.global.defaultLanguage,
  dialogTranslations: state.groupServices.translations.messagesDialog,
  gettingMessages: state.groupServices.gettingMessages,
  globalTranslations: state.global.translations,
  messages: state.groupServices.selectedGroup.messages,
  messageTranslations: state.messaging.translations.form,
  selectedGroup: state.groupServices.selectedGroup,
  sendingMessage: state.groupServices.sendingMessage,
  timezone: (state?.global?.siteInfo?.location?.timezone ?? 'America/Chicago'),
  userPermissions: state.global.permissions
});

GroupMessages.propTypes = {
  availableLanguages: PropTypes.array.isRequired,
  canEdit: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  defaultLanguage: PropTypes.string,
  dialogTranslations: PropTypes.object,
  dispatch: PropTypes.func,
  gettingMessages: PropTypes.bool,
  globalTranslations: PropTypes.object,
  messages: PropTypes.array,
  messageTranslations: PropTypes.object,
  selectedGroup: PropTypes.object,
  sendingMessage: PropTypes.bool,
  timezone: PropTypes.string.isRequired,
  updateMessageState: PropTypes.func,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(GroupMessages, styles));
