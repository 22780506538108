export const TERMLOC_NEW_ITEM = 'New';
export const TERMLOC_ERROR_EMPTY = 'Cannot be empty';
export const TERMLOC_ERROR_DUPLICATE = 'Location Exists';
export const TERMLOC_OPTIONS = ['Bedroom',
  'Master Bedroom',
  '2nd Bedroom',
  '3rd Bedroom',
  '4th Bedroom',
  'Living Room',
  'Living Room 1',
  'Living Room 2',
  'Den',
  'Office',
  'Dining Room',
  'Kitchen',
  'Bathroom',
  '2nd Bathroom',
  '3rd Bathroom',
  'Patio',
  'Deck',
  'Foyer',
  'Bar',
  'Guest Bedroom'];
export const ROOMTYPE_ERROR_DUPLICATE = 'Room Type Exists';

export const defaultIptvNetwork = {
  dhcp: {
    device_filtering: false,
    dns_server: '10.40.0.1',
    pool_end: '10.40.63.240',
    pool_start: '10.40.0.10'
  },
  gateway_address: '10.40.63.253',
  layer3_enabled: false,
  subnet_mask: '10.40.0.1/18'
};

export const LOCATIONS = [
  { label: 'HDMI 1', value: 'hdmi1' },
  { label: 'HDMI 2', value: 'hdmi2' },
  { label: 'HDMI 3', value: 'hdmi3' },
  { label: 'HDMI 4', value: 'hdmi4' },
];

export const RESTRICTED_SUBNETS = [
  '10.20.0.0/21',
  '172.17.0.0/16',
  '172.18.0.0/16',
  '172.19.0.0/16',
  '172.31.0.0/16'
];
