import { SvgIcon } from '@mui/material';
import React, { Component } from 'react';

export class DragNDropIcon extends Component {
  /* eslint-disable max-len */
  render() {
    return (
      <SvgIcon {...this.props} viewBox="0 0 33 33">
        <polygon style={{ fill: '#333333' }} points="32.5,16.5 26.7,10.7 26.7,15 18,15 18,6.3 22.3,6.3 16.5,0.5 10.7,6.3 15,6.3 15,15 6.3,15 6.3,10.7
              0.5,16.5 6.3,22.3 6.3,18 15,18 15,26.7 10.7,26.7 16.5,32.5 22.3,26.7 18,26.7 18,18 26.7,18 26.7,22.3 "/>
      </SvgIcon>
    );
  }
}


export default DragNDropIcon;
