/* eslint-disable max-lines */
import { Auth } from 'aws-amplify';
import { Buffer } from 'buffer';
import decode from 'jwt-decode';
import moment from 'moment';
import 'moment-timezone';
import { DWB_PRODUCT_CODE, ITV_PRODUCT_CODE } from '../constants/constants';
import { httpSuccess } from '../constants/http';

const EMPTY_DATE_PLACEHOLDER = 'No end';

function getFromLocal(name) {
  return process.env[name];
}

export function requestCognitoTokens(code) {
  return new Promise((resolve, reject) => {
    if (
      !getFromLocal('REACT_APP_COGNITO_DOMAIN_PREFIX') ||
      !getFromLocal('REACT_APP_COGNITO_CLIENT_ID') ||
      !getFromLocal('REACT_APP_CCA_REGION')
    ) {
      reject(new Error('Incomplete Cognito Data - requestCognitoTokens'));
    }

    const AuthUri = `https://${getFromLocal('REACT_APP_COGNITO_DOMAIN_PREFIX')
      }.auth.${getFromLocal('REACT_APP_CCA_REGION')
      }.amazoncognito.com/oauth2/token?grant_type=authorization_code&client_id=
      ${getFromLocal('REACT_APP_COGNITO_CLIENT_ID')
      }&code=${code
      }&redirect_uri=${getFromLocal('REACT_APP_COGNITO_REDIRECT_URI')
      }`;

    // eslint-disable-next-line @babel/new-cap
    const encodedString = new Buffer.from(
      `${getFromLocal('REACT_APP_COGNITO_CLIENT_ID')}:${getFromLocal('REACT_APP_COGNITO_CLIENT_SECRET')}`,
      'ascii'
    ).toString('base64');
    const basicAuthString = `Basic ${encodedString}`;
    console.log(`${getFromLocal('REACT_APP_COGNITO_CLIENT_ID')}:${getFromLocal('REACT_APP_COGNITO_CLIENT_SECRET')}`);
    console.log(basicAuthString);
    fetch(AuthUri, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': basicAuthString
      }
    }).then((response) => response.json())
      .then((data) => {
        if (data.error) {
          logOut();
        } else {
          storeSession(data);
          resolve(true);
        }
      });
  });
}

// Refresh session and return id_token
export function refreshCognitoTokens() {
  return new Promise((resolve, reject) => {
    if (
      !getFromLocal('REACT_APP_COGNITO_DOMAIN_PREFIX') ||
      !getFromLocal('REACT_APP_COGNITO_CLIENT_ID') ||
      !getFromLocal('REACT_APP_CCA_REGION')
    ) { reject(new Error('Incomplete Cognito Data - refreshCognitoTokens')); }

    const AuthUri = `https://${getFromLocal('REACT_APP_COGNITO_DOMAIN_PREFIX')
      }.auth.${getFromLocal('REACT_APP_CCA_REGION')
      }.amazoncognito.com/oauth2/token?grant_type=refresh_token&client_id=${getFromLocal('REACT_APP_COGNITO_CLIENT_ID')
      }&refresh_token=${getRefreshToken()
      }`;

    // eslint-disable-next-line @babel/new-cap
    const encodedString = new Buffer.from(
      `${getFromLocal('REACT_APP_COGNITO_CLIENT_ID')}:${getFromLocal('REACT_APP_COGNITO_CLIENT_SECRET')}`,
      'ascii'
    ).toString('base64');
    const basicAuthString = `Basic ${encodedString}`;
    fetch(AuthUri, {
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': basicAuthString
      }
    }).then((response) => response.json())
      .then((data) => {
        console.log('COGNITO RESPONSE: ', data);
        if (data.error) {
          // deleteSession();
          // reject(data.error);
          logOut();
        } else {
          // console.log('SESSION STORED');
          storeSession(data);
          resolve(data.id_token);
        }
      });
  });
}

export function isSessionValid() {
  const token = getTokenFromSession();
  return (!!token && !isTokenExpired(token));
}

export function storeSession(tokenObject) {
  const keys = Object.keys(tokenObject);
  // eslint-disable-next-line
  for (const key of keys) {
    sessionStorage.setItem(key, tokenObject[key]);
  }

  // set expiration if id_token exists
  if (tokenObject.id_token) {
    try {
      const decoded = decode(tokenObject.id_token);
      sessionStorage.setItem('exp', decoded.exp);
      sessionStorage.setItem('username', decoded['cognito:username']);
    } catch(error) {
      console.log(error);
    }
  }
}

export function deleteSession() {
  let value = null;
  if (getValueFromSession('Mobile')) {
    value = getValueFromSession('Mobile');
  }
  sessionStorage.clear();

  if (value !== null) {
    sessionStorage.setItem('Mobile', value);
  }
}

export function redirectToLogin() {
  deleteSession();

  checkMobile();

  if (
    !getFromLocal('REACT_APP_COGNITO_DOMAIN_PREFIX') ||
    !getFromLocal('REACT_APP_COGNITO_CLIENT_ID') ||
    !getFromLocal('REACT_APP_CCA_REGION')
  ) { return; }

  const loginUri = `https://${getFromLocal('REACT_APP_COGNITO_DOMAIN_PREFIX')
    }.auth.${getFromLocal('REACT_APP_CCA_REGION')
    }.amazoncognito.com/login?response_type=code&client_id=${getFromLocal('REACT_APP_COGNITO_CLIENT_ID')
    }&redirect_uri=${getFromLocal('REACT_APP_COGNITO_REDIRECT_URI')
    }`;

  // console.log(`Redirecting to: ${loginUri}`);

  window.location = loginUri;
}

export function checkMobile() {
  if (window.location.pathname === '/Mobile') {
    sessionStorage.setItem('Mobile', window.location.search);
  }
}

export function isTokenExpired(token) {
  try {
    const oneMillisecond = 1000;
    const decoded = decode(token);
    if (decoded.exp < Date.now() / oneMillisecond) {
      console.log('TOKEN EXPIRED');
      return true;
    } else { console.log('TOKEN STILL VALID'); }
    return false;
  } catch(err) {
    return false;
  }
}

export function setHasCoreChannels(coreChannels) {
  sessionStorage.setItem('has_core_channels', !!coreChannels);
}

export function getHasCoreChannelsFromSession() {
  return getValueFromSession('has_core_channels');
}

export function setHasStreamChannels(streamChannels) {
  sessionStorage.setItem('has_stream_channels', !!streamChannels);
}

export function getHasStreamChannelsFromSession() {
  return getValueFromSession('has_stream_channels');
}

export function setLateCheckout(lateCheckout) {
  sessionStorage.setItem('late_checkout', lateCheckout);
}

export function getLateCheckoutFromSession() {
  return getValueFromSession('late_checkout');
}

export function setHotelDir(hotelDir) {
  sessionStorage.setItem('hotel_dir', hotelDir);
}

export function getHotelDirFromSession() {
  return getValueFromSession('hotel_dir');
}

export function setGroupServices(groupService) {
  sessionStorage.setItem('group_service', groupService);
}

export function hasGroupServiceFeat() {
  return getValueFromSession('group_service');
}

export function setSite(siteNum, siteName) {
  // console.log(`setSite siteNum${siteNum} siteName: ${siteName}`);
  sessionStorage.setItem('site_num', siteNum);
  sessionStorage.setItem('site_name', siteName);
}

export function getSiteNumFromSession() {
  // return sessionStorage.getItem('site_num');
  return getValueFromSession('site_num');
}

export function getSiteNumFromURI() {
  const match = window.location.pathname.match('[0-9]{7}');

  if (match) {
    return match[0];
  }
}

export function getSiteNameFromSession() {
  // return sessionStorage.getItem('site_name');
  return getValueFromSession('site_name');
}

export function getUserNameFromSession() {
  return getValueFromSession('username');
}

export function getFormattedSiteNameFromSession() {
  const siteNum = getValueFromSession('site_num');
  const siteName = getValueFromSession('site_name');
  const name = (siteNum && siteName) ? `${siteNum} : ${siteName}` : null;
  return name;
}

export function getValueFromSession(name) {
  return sessionStorage.getItem(name);
}

export function setToken(idToken) {
  sessionStorage.setItem('id_token', idToken);
  try {
    const decoded = decode(idToken);
    sessionStorage.setItem('exp', decoded.exp);
    sessionStorage.setItem('username', `${decoded.given_name} ${decoded.family_name}`);
  } catch(error) {
    console.log(error);
  }
}

// get token from session, even if it is expired
export function getTokenFromSession() {
  return sessionStorage.getItem('id_token');
}

// promise returning token, or refreshed token if expired
export function getCurrentToken() {
  return new Promise((resolve, reject) => {
    // console.log('CHECK TOKEN');
    const token = getTokenFromSession();
    if (!isTokenExpired(token)) {
      resolve(token);
    } else {
      // console.log('TOKEN EXPIRED, LOGOUT');
      // logOut();

      // Refreshing the token prolongs the user session.  It is functional, but it may
      // allow the user to be using an outdated version of the app.
      // Rather than refresh the token, for now force the user to login again

      refreshCognitoTokens()
        .then((newToken) => resolve(newToken))
        .catch((reason) => reject(reason));
    }
  });
}

export function getRefreshToken() {
  return sessionStorage.getItem('refresh_token');
}

export function getTokenExpiration() {
  const radix = 10;
  return parseInt(sessionStorage.getItem('exp'), radix);
}

export function checkLoginStatus(code, token, domain) {
  return new Promise((resolve) => {
    checkTermAddress();

    if (code) {
      // console.log('REQUEST COGNITO TOKENS: ', code);
      console.log('checkLoginStatus:1', code);
      requestCognitoTokens(code)
        .then((data) => {
          resolve(data);
        }).catch((err) => {
          console.log('checkLoginStatus:Error 1', err);
          resolve(false);
        });
    } else if (domain.includes('signin')) {
      console.log('checkLoginStatus:2', domain);
      Auth.currentSession()
        .then((data) => {
          setToken(data.idToken.jwtToken);
          resolve(true);
        })
        .catch((err) => {
          console.log('checkLoginStatus:Error 2', err);
          resolve(false);
        });
    } else if (token) {
      console.log('checkLoginStatus:3', token);
      if (!isTokenExpired(token)) {
        console.log('TOKEN VALID');
        resolve(true);
      } else {
        console.log('TOKEN EXPIRED');
        resolve(false);
      }
    } else {
      console.log('checkLoginStatus:4');
      resolve(false);
    }
  });
}

export function checkTermAddress() {
  const termAddr = new URLSearchParams(window.location.search).get('terminal');
  if (termAddr) {
    sessionStorage.setItem('terminal_address', termAddr);
  }
}

export function getTermAddress() {
  return sessionStorage.getItem('terminal_address');
}

export function removeTermAddress() {
  return sessionStorage.removeItem('terminal_address');
}

export function removeSessionItem(itemName) {
  return sessionStorage.removeItem(itemName);
}

export function logOut() {
  Auth.signOut();
  setSite('', '');
  deleteSession();
  window.location = window.location.origin;
}

export function buildUrl(url, parameters) {
  const secondarySort = 'secondarySort';

  const qi = [];
  let qs = '';
  // eslint-disable-next-line
  for (const param in parameters) {
    if (Object.prototype.hasOwnProperty.call(parameters, param) && (param !== secondarySort)) {
      if (parameters[param] !== null) {
        const value = parameters[param].toString().trim();
        qi.push(`${encodeURIComponent(param)}=${encodeURIComponent(value)}`);
      } else {
        qi.push(`${encodeURIComponent(param)}`);
      }
    }
  }

  // ensure that the secondary sort param is appended to the end of querystring
  if ((parameters) && Object.prototype.hasOwnProperty.call(parameters, secondarySort)) {
    const value = parameters.secondarySort.toString().trim();
    qi.push(`${encodeURIComponent('sort')}=${encodeURIComponent(value)}`);
  }

  if (qi.length > 0) {
    qs = qi.join('&');
    url = `${url}?${qs}`;
  }

  return url;
}

// wrapper for fetch
// gets the current token first and returns a promise with the data
export function fetchData(url) {
  return new Promise((resolve, reject) => {
    getCurrentToken()
      .then((token) => {
        const bearer = `Bearer ${token}`;
        const object = {
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          },
        };
        fetch(`${url}`, object)
          .then(handleErrors)
          .then((response) => {
            resolve(response.json());
          }).catch((error) => {
            reject(error);
          });
      }).catch((reason) => console.log(reason));
  });
}

// TEXT/CSV wrapper for fetch
// gets the current token first and returns a promise with the data
export function fetchCsvData(url) {
  return new Promise((resolve, reject) => {
    getCurrentToken()
      .then((token) => {
        const bearer = `Bearer ${token}`;
        const object = {
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'text/csv'
          },
        };
        fetch(`${url}`, object)
          .then(handleErrors)
          .then((response) => {
            resolve(response);
          }).catch((error) => {
            console.log('error: ', error);
            if (error.url) {
              window.location = error.url;
            }
            reject(error);
          });
      }).catch((reason) => console.log(reason));
  });
}

// APPLICATION/TAR+GZIP wrapper for fetch
// gets the current token first and returns a promise with the data
export function fetchGzipData(url) {
  return new Promise((resolve, reject) => {
    getCurrentToken()
      .then((token) => {
        const bearer = `Bearer ${token}`;
        const object = {
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/x-tar'
          },
        };
        fetch(`${url}`, object)
          .then(handleErrors)
          .then((response) => {
            resolve(response);
          }).catch((error) => {
            console.log('error: ', error);
            window.location = error.url;
            reject(error);
          });
      }).catch((reason) => console.log(reason));
  });
}

export function setItemInSession(key, value) {
  sessionStorage.setItem(key, value);
}

export function getItemFromSession(key) {
  return sessionStorage.getItem(key);
}

export function fetchDataWithHeaders(url) {
  return new Promise((resolve, reject) => {
    getCurrentToken()
      .then((token) => {
        const bearer = `Bearer ${token}`;
        const object = {
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          },
        };
        fetch(`${url}`, object)
          .then(handleErrors)
          .then((response) => {
            // eslint-disable-next-line
            for (const pair of response.headers.entries()) {
              if (pair[0] === 'etag') {
                setItemInSession('etag', pair[1]);
              }
            }

            resolve(response.json());
          }).catch((error) => {
            reject(error);
          });
      }).catch((reason) => console.log(reason));
  });
}


function handleErrors(response) {
  if (!response.ok) {
    throw response;
  }
  return response;
}

export function putData(url, inputObject, getMoreDetail) {
  return new Promise((resolve, reject) => {
    getCurrentToken()
      .then((token) => {
        const bearer = `Bearer ${token}`;
        const object = {
          method: 'PUT',
          body: JSON.stringify(inputObject),
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          },
        };
        fetch(`${url}`, object)
          // .then((data) => resolve(data))
          .then((data) => {
            if (getMoreDetail) {
              resolve(getIdFromLocationHeader(data));
              return;
            }
            resolve(data);
          })
          .catch((reason) => reject(reason));
      }).catch((reason) => console.log(reason));
  });
}

export function putFileNoAuthHeaderTlx(url, fileUpload, filename) {
  const headers = {
    'Content-Type': 'application/vnd.cloud.sonifi.cca.tlx',
    'Content-Disposition': `attachment; filename=${filename};`
  };
  return putFileNoAuthHeader(url, fileUpload, headers);
}

export function putFileNoAuthHeader(url, fileUpload, headers) {
  return new Promise((resolve, reject) => {
    const object = new Request(url, {
      method: 'PUT',
      body: fileUpload,
      headers: { ...headers },
    });
    fetch(object)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function putDataWithExtraHeaders(url, inputObject, extraHeaders) {
  return new Promise((resolve, reject) => {
    getCurrentToken()
      .then((token) => {
        // console.log('PUTDATA', inputObject);
        const bearer = `Bearer ${token}`;
        const object = {
          method: 'PUT',
          body: JSON.stringify(inputObject),
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
            ...extraHeaders
          },
        };
        fetch(`${url}`, object)
          .then((data) => resolve(data))
          .catch((reason) => reject(reason));
      }).catch((reason) => console.log(reason));
  });
}


function getIdFromLocationHeader(response, completeLocation) {
  try {
    const location = response.headers.get('Location');

    if (completeLocation) {
      return location;
    } else if (location) {
      const res = location.split('/');
      return res[res.length - 1];
    } else {
      return !httpSuccess(response.status) ? response.json() : response;
    }
    // eslint-disable-next-line keyword-spacing
  } catch(err) {
    if (httpSuccess(response.status)) {
      return response;
    } else {
      throw Error(`Location Header could not be parsed ${err}`);
    }
  }
}

export function postData(url, inputObject, completeLocation = false) {
  return new Promise((resolve, reject) => {
    getCurrentToken()
      .then((token) => {
        const bearer = `Bearer ${token}`;
        const object = {
          method: 'POST',
          body: JSON.stringify(inputObject),
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        };
        fetch(`${url}`, object)
          .then((response) => {
            resolve(getIdFromLocationHeader(response, completeLocation));
          })
          .catch((reason) => reject(reason));
      }).catch((reason) => console.log(reason));
  });
}

export function patchData(url, inputObject, controller) {
  return new Promise((resolve, reject) => {
    getCurrentToken()
      .then((token) => {
        // console.log('PATCHDATA', inputObject);
        const bearer = `Bearer ${token}`;
        const object = {
          method: 'PATCH',
          body: JSON.stringify(inputObject),
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          },
          signal: controller ? controller.signal : undefined,
        };
        fetch(`${url}`, object)
          .then((data) => resolve(data))
          .catch((reason) => reject(reason));
      }).catch((reason) => console.log(reason));
  });
}

export function deleteData(url, submitObj) {
  return new Promise((resolve, reject) => {
    getCurrentToken()
      .then((token) => {
        // console.log('DELETEDATA');
        const bearer = `Bearer ${token}`;
        const object = {
          method: 'DELETE',
          headers: {
            'Authorization': bearer,
            'Access-Control-Allow-Origin': '*',
            'Accept': 'application/json'
          },
        };

        if (submitObj) {
          object.body = JSON.stringify(submitObj);
        }

        // console.log('object', object);

        fetch(`${url}`, object)
          .then((data) => resolve(data))
          .catch((reason) => reject(reason));
      }).catch((reason) => console.log(reason));
  });
}

export function dateToString(dateStr, timezone) {
  if (dateStr) {
    return moment.utc(dateStr, moment.ISO_8601, true).tz(timezone).format('MM/DD/YYYY h:mm a');
  } else {
    return EMPTY_DATE_PLACEHOLDER;
  }
}

export function convertToSiteTimezone(dateStr, timezone) {
  if (!dateStr) {
    return null;
  }
  return moment(dateStr).tz(timezone, true).format();
}

export function findObjectByKey(array, key, value) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] !== null && String(array[i][key]).trim().toUpperCase() === String(value).trim().toUpperCase()) {
      return array[i];
    }
  }
  return null;
}

export function findArrayIdByKey(array, key, value) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] !== null && String(array[i][key]).trim().toUpperCase() === String(value).trim().toUpperCase()) {
      return i;
    }
  }
  return null;
}

export function range(start, end) {
  const foo = [];
  const startInt = parseInt(start, 10);
  const endInt = parseInt(end, 10);
  for (let i = startInt; i <= endInt; i++) {
    foo.push(i);
  }
  return foo;
}

export function isObject(item) {
  return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();
  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }
  return mergeDeep(target, ...sources);
}

export function compareValues(key, order = 'asc') {
  // eslint-disable-next-line space-before-function-paren
  return function(a, b) {
    if (!Object.prototype.hasOwnProperty.call(a, key) ||
      !Object.prototype.hasOwnProperty.call(b, key)) {
      // property doesn't exist on either object
      return 0;
    }

    let aKey = key,
      bKey = key;
    if (key === 'id') {
      aKey = (key === 'id' && a.swapId) ? 'swapId' : 'id';
      bKey = (key === 'id' && b.swapId) ? 'swapId' : 'id';
    }

    const varA = (typeof a[aKey] === 'string')
      ? a[aKey].toUpperCase()
      : a[aKey];
    const varB = (typeof b[bKey] === 'string')
      ? b[bKey].toUpperCase()
      : b[bKey];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }

    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  };
}

export function getTranslationObject(language, langs) {
  if (language === 'en') {
    return { ...langs.en };
  }
  const english = JSON.parse(JSON.stringify({ ...langs.en }));
  const glob = mergeDeep(english, { ...langs[language] });
  return { ...glob };
}

export function padSiteNumber(siteId) {
  let numberOfZeros = '';
  while (`${numberOfZeros}${siteId}`.length < 7) {
    numberOfZeros += '0';
  }
  return `${numberOfZeros}${siteId}`;
}

export function isError(fieldName, errors) {
  if (errors === null || errors === undefined || Object.entries(errors).length === 0) {
    return false;
  }
  return !!(Object.prototype.hasOwnProperty.call(errors, fieldName));
}

export function getErrorText(fieldName, errors) {
  if (errors === null || errors === undefined || Object.entries(errors).length === 0) {
    return '';
  }
  const error = Object.prototype.hasOwnProperty.call(errors, fieldName);
  return error ? errors[fieldName] : '';
}

export function validateEmail(inputText) {
  const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const sonifiFormat = /^\w+([.-]?\w+)*@(sonifi)+(\.\w{2,3})+$/;

  if (inputText.match(sonifiFormat)) {
    return false;
  }

  if (inputText.match(mailformat)) {
    return true;
  }
  return false;
}

export function buildSortString(sortObj) {
  const queryString = {
    page: (sortObj.page ? sortObj.page : 1)
  };

  if (sortObj.orderBy && sortObj.order) {
    queryString.sort = `${sortObj.orderBy}:${sortObj.order}`;
  }

  if (sortObj.secondarySort) {
    // This is where we can add the secondary sort if passed in
    // queryString.sort += `,${sortObj.secondarySort}`;
  }

  if (sortObj.limit) {
    queryString.limit = sortObj.limit;
  }

  if (sortObj.make) {
    queryString.make = sortObj.make;
  }

  if (sortObj.filter && sortObj.filter !== '') {
    queryString.search = sortObj.filter;
  } else if (sortObj.search && sortObj.search.length > 0) {
    queryString.search = sortObj.search;
  }

  if (sortObj.active) {
    queryString.active = sortObj.active;
  }

  return queryString;
}

// returns true if site is only contracted for digital whiteboard and nothing else
export function siteIsOnlyDWB(productParents) {
  if (!productParents) {
    return false;
  }
  const dwb = productParents.filter((e) => e === `${DWB_PRODUCT_CODE}`);
  return dwb.length > 0;
}

export function hasITv(productParents) {
  const iTv = productParents.filter((e) => e === `${ITV_PRODUCT_CODE}`);
  return iTv.length > 0;
}

export function getNameFromObjectArray(arr, val) {
  if (!arr) {
    return val;
  }
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].id === val || arr[i].iso_code_639_1 === val) {
      return arr[i].name;
    }
  }
  return val;
}

export function padZeros(num, padlen = 7) {
  const pad = new Array(1 + padlen).join('0');
  return (pad + num).slice(-pad.length);
}
