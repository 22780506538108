import {
    Dialog, DialogContent, Grid, Paper, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { find, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import {
    SonifiButton, SonifiCheckbox, SonifiLabel, SonifiModalHeader, SonifiSpinner, SonifiTableHead, SonifiText
} from '../../../containers';
import { findObjectByKey } from '../../../utils';
import {
    batchUpdateTerminalRange, fetchRangeTerminals, resetRange, updateSelectedRange, updateTerminalRange
} from '../actions/terminalActions';

const styles = (theme) => ({
  innerContents: {
    width: '100%',
    overflowX: 'auto',
    marginTop: 15,
    border: 'none',
    flexGrow: 1,
  },
  paperView: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  }
});

class EditRange extends Component {
  state = {
    lineup: null,
    menuset: null,
    rooms: [],
    startRoom: '',
    endRoom: '',
    order: 'asc',
    orderBy: 'room_id',
    selectedLineup: null,
    selectedMenuset: null,
    selectAllChecked: false
  };

  componentDidMount() {
    this.setState({ lineup: 0, menuset: 0, startRoom: '', endRoom: '' });
  }

  handleSubmit() {
    const { dispatch, selectedRangeTerminals } = this.props;
    const { selectedLineup, selectedMenuset } = this.state;
    const submitObj = {};
    if (selectedLineup !== null) {
      submitObj.lineup_id = selectedLineup;
    }
    if (selectedMenuset !== null) {
      submitObj.menuset = selectedMenuset;
    }

    if ((submitObj.lineup_id || submitObj.menuset) && selectedRangeTerminals.length > 0) {
      dispatch(batchUpdateTerminalRange(selectedRangeTerminals, submitObj));
    } else {
      this.handleClose();
    }
  }

  search() {
    const { startRoom, endRoom } = this.state;
    const tempStart = (startRoom > endRoom ? endRoom : startRoom);
    const tempEnd = (startRoom > endRoom ? startRoom : endRoom);

    this.setState({
      selectAllChecked: true, startRoom: tempStart, endRoom: tempEnd
    }, () => {
      this.props.dispatch(fetchRangeTerminals(this.state.startRoom, this.state.endRoom));
    });
  }

  reset() {
    this.setState({
      startRoom: '', endRoom: '', selectAllChecked: false
    }, () => {
      this.props.dispatch(resetRange());
    });
  }

  handleClose() {
    this.props.onCancel();
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  processLineupMenusetChange = (name) => ({ target: { value } }) => {
    this.setState({ [name]: value === 0 ? null : value });
  };

  processRoomChange = (name) => ({ target: { value } }) => {
    this.setState({ [name]: (value === 0 ? null : value.replace(/([^0-9])/g, '')) });
  };

  checkboxChange = () => () => {
    this.setState({
      selectAllChecked: !this.state.selectAllChecked
    }, () => {
      this.props.dispatch(updateTerminalRange(this.state.selectAllChecked));
    });
  };

  handleCheckChange = (name) => () => {
    this.setState({
      selectAllChecked: false
    }, () => {
      this.props.dispatch(updateSelectedRange(name));
    });
  };

  selectedTerminal = (termId) => {
    const { selectedRangeTerminals } = this.props;
    if (!selectedRangeTerminals || selectedRangeTerminals.length === 0) {
      return false;
    }

    const selected = find(selectedRangeTerminals, (o) => o === termId);
    return selected !== undefined;
  };

  getSearchFields() {
    const { fetchingRange, globalTranslations } = this.props;
    const { startRoom, endRoom } = this.state;
    return (<Fragment>
      <Grid item xs={3}>
        <SonifiText
          label="Start Room"
          defaultValue={startRoom}
          size="percent"
          change={this.processRoomChange('startRoom')}
        />
      </Grid>
      <Grid item xs={3}>
        <SonifiText
          label="End Room"
          defaultValue={endRoom}
          size="percent"
          change={this.processRoomChange('endRoom')}
        />
      </Grid>
      <Grid item xs={3}>
        <SonifiButton
          caption={globalTranslations.search}
          disabled={startRoom === '' || endRoom === '' || fetchingRange}
          marginTop
          onClick={this.search.bind(this)} />
      </Grid>
    </Fragment>);
  }

  getLineupMenuset() {
    const {
      globalTranslations, lineups, menusets, translations
    } = this.props;
    const {
      lineup, menuset, startRoom, endRoom, selectedMenuset, selectedLineup
    } = this.state;

    const selectableMenusets = menusets ? [{ id: 0, name: '-----' }, ...menusets] : [{ id: 0, name: '-----' }];
    const uniqueMenusets = uniqBy(selectableMenusets, (e) => e.id);

    return (
      <Fragment>
        <Grid item>
          <SonifiText
            label="Room Range"
            defaultValue={`${startRoom} - ${endRoom}`}
            size="percent"
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <SonifiText
            label={translations.menuset}
            size="percent"
            select
            items={uniqueMenusets.map((suggestion) => ({
              value: suggestion.id,
              label: suggestion.name,
            }))}
            defaultValue={selectedMenuset ? selectedMenuset : menuset}
            change={this.processLineupMenusetChange('selectedMenuset')}
            disabled={uniqueMenusets.length < 2}
          />
        </Grid>
        <Grid item xs={3}>
          <SonifiText
            label={translations.lineup}
            size="percent"
            select
            items={lineups && [{ id: 0, name: '-----' }, ...lineups].map((suggestion) => ({
              value: suggestion.id,
              label: suggestion.name,
            }))}
            defaultValue={selectedLineup ? selectedLineup : lineup}
            change={this.processLineupMenusetChange('selectedLineup')}
            disabled={false}
          />
        </Grid>
        <Grid item xs={3}>
          <SonifiButton
            caption={globalTranslations.reset}
            marginTop
            onClick={this.reset.bind(this)} />
        </Grid>
      </Fragment>
    );
  }

  /* eslint-disable max-lines-per-function */
  render() {
    const {
      classes, fetchingRange, globalTranslations, lineups, menusets, rangeTerminals, translations
    } = this.props;
    const { endRoom, selectedLineup, selectedMenuset, startRoom } = this.state;

    const tableHeader = [
      {
        centerHeader: false,
        checkbox: true,
        checkValue: this.state.selectAllChecked,
        disabled: rangeTerminals.length < 1 || endRoom === '' || startRoom === '',
        id: '',
        label: '',
        noLabel: true,
        numeric: false,
        onChange: this.checkboxChange(),
        sortable: false
      },
      { id: 'id', sortable: false, numeric: false, label: `${translations.termId}` },
      { id: 'room_id', sortable: false, numeric: false, label: `${translations.room}` },
      { id: 'location', sortable: false, numeric: false, label: `${translations.location}` },
      { id: 'lineup_id', sortable: false, numeric: false, label: `${translations.lineup}` },
      { id: 'menuset_name', sortable: false, numeric: false, label: `${translations.menuset}` }
    ];

    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={this.onClose.bind(this)}
        open={true}
      >
        <SonifiModalHeader
          header={translations.title}
          label={globalTranslations.submit}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          onlyClose={!rangeTerminals || rangeTerminals.length === 0}
          disabled={!rangeTerminals || rangeTerminals.length === 0}
        />
        <DialogContent>
          <div className={classes.paperView}>
            <Paper className={classes.innerContents}>
              <Grid container>
                {rangeTerminals.length === 0
                  ? this.getSearchFields()
                  : this.getLineupMenuset()}
              </Grid>
              <Grid item xs={12}>
                {fetchingRange
                  ? <SonifiSpinner />
                  : <Table>
                    <SonifiTableHead
                      headColumns={tableHeader}
                    />
                    <TableBody>
                      {rangeTerminals && rangeTerminals.length > 0
                        ? rangeTerminals.map((option, index) => (
                          <TableRow key={index} onClick={this.handleCheckChange(option.id)} >
                            <TableCell>
                              <SonifiCheckbox
                                noLabel={true}
                                label=""
                                disabled={rangeTerminals.length < 1}
                                value={this.selectedTerminal(option.id)}
                              />
                            </TableCell>
                            <TableCell>
                              <SonifiLabel label={`${option.id}`} />
                            </TableCell>
                            <TableCell>
                              <SonifiLabel label={`${option.room_id}`} />
                            </TableCell>
                            <TableCell>
                              <SonifiLabel label={`${option.location}`} />
                            </TableCell>
                            <TableCell>
                              <SonifiLabel
                                label={findObjectByKey(lineups, 'id', (selectedLineup
                                  ? selectedLineup
                                  : option.lineup_id)) ?
                                    findObjectByKey(lineups, 'id', (selectedLineup ?? option.lineup_id)).name :
                                    ''
                                  } />
                            </TableCell>
                            <TableCell>
                              {selectedMenuset
                                ? <SonifiLabel label={findObjectByKey(menusets, 'id', selectedMenuset).name} />
                                : <SonifiLabel label={option.menuset_name} />}
                            </TableCell>
                          </TableRow>
                        ))
                        : <TableRow>
                          <TableCell colSpan={6}>
                            <SonifiLabel error label={translations.errors.noRooms} />
                          </TableCell>
                        </TableRow>}
                    </TableBody>
                  </Table>}
              </Grid>
            </Paper>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  lineups: state.termGrid.lineups,
  menusets: state.termGrid.menusets,
  rangeTerminals: state.termGrid.rangeTerminals,
  fetchingRange: state.termGrid.fetchingRange,
  selectedRangeTerminals: state.termGrid.selectedRangeTerminals,
  translations: state.termGrid.translations.editRange
});

EditRange.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  lineups: PropTypes.array,
  menusets: PropTypes.array,
  fetchingRange: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  rangeTerminals: PropTypes.array,
  selectedRangeTerminals: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(EditRange, styles, { withTheme: true }));
