import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel } from '../../../../containers';
import SonifiCheckbox from '../../../../containers/SonifiCheckbox';
import SonifiIconButton from '../../../../containers/SonifiIconButton';
import SonifiText from '../../../../containers/SonifiText';

class DeleteComponent extends Component {
  state = {
    excluded: []
  };

  handleChange = (name) => ({ target: { value } }) => {
    this.props.onChange(name, value.replace(/([|])/g, ''));
  };

  handleRoomNumber = (name) => ({ target: { value } }) => {
    this.props.onChange(name, value.replace(/([a-zA-Z| ])/g, ''));
  };

  isSiteInGroup = (room) => !this.state.excluded.includes(room);

  editToggleValue = (room) => () => {
    let editRoomsArray = this.state.excluded.length === 0 ? [] : [...this.state.excluded];
    if (this.state.excluded.includes(room)) {
      editRoomsArray = editRoomsArray.filter((value) => value !== room);
    } else {
      editRoomsArray.push(room);
    }
    this.setState({ excluded: editRoomsArray });

    this.props.onChange('excluded', editRoomsArray);
  };

  getDeleteRoomLabel() {
    const { deleteRooms, translations } = this.props;
    const { id, rangeEnd } = this.props.addState;

    if (deleteRooms.length === 0) {
      return <SonifiLabel error label={translations.addDialog.noRoomsToDelete} />;
    } else if (deleteRooms.length === 1) {
      return <SonifiLabel boldLabel={`${translations.main.deleteConfirmText} ${deleteRooms[0]}?`} />;
    } else if (deleteRooms.length > 1) {
      return <SonifiLabel boldLabel={translations.addDialog.rangeOfRooms} label={`${id} - ${rangeEnd}`} />;
    }
  }

  render() {
    const { id, rangeEnd } = this.props.addState;
    const {
      addRangeEnd, errors, deleteRooms, range, translations
    } = this.props;

    return (
      <Grid container>
        {deleteRooms === null
          ? <Fragment>
            <Grid item xs={6}>
              <SonifiText
                label={range ? translations.addDialog.rangeStart : translations.main.roomNum}
                defaultValue={id}
                size="percent"
                error={!!errors.id}
                errorText={errors.id}
                disabled={deleteRooms !== null}
                change={(range ? this.handleRoomNumber('id') : this.handleChange('id'))}
              />
            </Grid>
            <Grid item xs={6}>
              {range
                ? <SonifiText
                  label={translations.addDialog.rangeEnd}
                  defaultValue={rangeEnd}
                  size="percent"
                  error={!!errors.end}
                  errorText={errors.end}
                  disabled={deleteRooms !== null}
                  change={this.handleRoomNumber('rangeEnd')}
                />
                : <SonifiIconButton label={translations.addDialog.rangeEnd}
                  onClick={addRangeEnd}
                  icon={<Add />} />
              }
            </Grid>
          </Fragment>
          : <Fragment>
            <Grid item xs={12}>{this.getDeleteRoomLabel()}</Grid>
            <Grid item xs={12}>
              <Grid container>
                {deleteRooms && deleteRooms.length > 1 &&
                  deleteRooms.map((room, index) => (
                    <Grid item xs={4} key={index}>
                      <SonifiCheckbox
                        label={room}
                        noLabel={true}
                        onChange={this.editToggleValue(room)}
                        value={this.isSiteInGroup(room)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          </Fragment>
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteRooms: state.rooms.deleteRooms,
  translations: state.rooms.translations
});

DeleteComponent.propTypes = {
  addRangeEnd: PropTypes.func,
  addState: PropTypes.object.isRequired,
  deleteRooms: PropTypes.array,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  range: PropTypes.bool,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(DeleteComponent);
