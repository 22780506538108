import { HIGH_HTTP, ICP_RESOURCE, LOW_HTTP } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import { deleteObjectPromise, getSiteObjectPromise, putObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

export const putIcpBegin = () => ({
  type: types.PUT_ICP_BEGIN
});

export const updateIcpSuccessStatus = () => ({
  type: types.RESET_ICP_SUCCESS
});

export const editSelectedIcp = (name, value) => ({
  type: types.EDIT_SELECTED_ICP,
  payload: { name, value }
});

export const fetchIcpsBegin = () => ({
  type: types.FETCH_ICPS_BEGIN
});

export const fetchIcpsSuccess = (products) => ({
  type: types.FETCH_ICPS_SUCCESS,
  payload: { products }
});

export const fetchIcpsFailure = (error) => ({
  type: types.FETCH_ICPS_FAILURE,
  payload: { error }
});

export const getIcpBegin = () => ({
  type: types.GET_ICP_INFO_BEGIN,
});

export const getIcpSuccess = (icpID, products) => ({
  type: types.GET_ICP_INFO_SUCCESS,
  payload: { icpID, products }
});

export const getIcpFailure = (error) => ({
  type: types.GET_ICP_INFO_FAILURE,
  payload: { error }
});

export const putIcpSuccess = () => ({
  type: types.PUT_ICP_SUCCESS,
});

export const putIcpFailure = (error) => ({
  type: types.PUT_ICP_FAILURE,
  payload: { error }
});

export const updateSelectedIcp = (index, editType) => ({
  type: types.UPDATE_SELECTED_ICP,
  index,
  editType
});

export const sortIcps = (state) => ({
  type: types.SORT_ICPS,
  state
});

export function fetchIcps() {
  return (dispatch) => {
    dispatch(fetchIcpsBegin());
    return getSiteObjectPromise(ICP_RESOURCE, null)
      .then((json) => {
        dispatch(fetchIcpsSuccess(json));
        return json;
      })
      .catch((error) => dispatch(fetchIcpsFailure(error)));
  };
}

export function editIcp(icpID) {
  return (dispatch) => {
    dispatch(getIcpBegin());
    return getSiteObjectPromise(ICP_RESOURCE, icpID)
      .then((json) => {
        dispatch(getIcpSuccess(icpID, json));
        return json;
      })
      .catch((error) => dispatch(getIcpFailure(error)));
  };
}

export function putIcp(icp) {
  const icpID = icp.id;
  delete icp.id;
  return (dispatch) => {
    dispatch(putIcpBegin());
    return putObjectPromise(ICP_RESOURCE, icpID, icp)
      .then((json) => {
        if (!dispatch) {
          return json;
        }
        if (json.status < LOW_HTTP || json.status >= HIGH_HTTP) {
          dispatch(putIcpFailure(json.title));
          return json;
        }
        dispatch(putIcpSuccess());

        dispatch(fetchIcps());
        return json;
      })
      .catch((error) => {
        if (dispatch) {
          dispatch(putIcpFailure(error));
        }
      });
  };
}

export function saveIcps(icps) {
  return (dispatch) => {
    dispatch(putIcpBegin());

    const levels = icps.map((icp) => {
      const icpId = icp.id;
      delete icp.id;
      if (icp.firmware) {
        return putIcp(icp, icpId);
      }
      return true;
    });


    Promise.all(levels).then((responses) => {
      for (let i = 0; i < responses.length; i++) {
        if (responses[i] && (responses[i].status < LOW_HTTP || responses[i].status >= HIGH_HTTP)) {
          return dispatch(putIcpFailure(responses[i].title));
        }
      }
      dispatch(fetchIcps());
      return dispatch(putIcpSuccess());
    });
  };
}

export const deleteIcpCheck = (icpIndex) => ({
  type: types.DELETE_CHECK,
  icpIndex
});

export const deleteIcpBegin = () => ({
  type: types.DELETE_ICP_BEGIN,
});

export const deleteIcpSuccess = (icpID) => ({
  type: types.DELETE_ICP_SUCCESS,
  icpID
});

export const deleteIcpFailure = (icpID, error) => ({
  type: types.DELETE_ICP_FAILURE,
  icpID,
  error
});

export function deleteIcp(icpID) {
  return (dispatch) => {
    dispatch(deleteIcpBegin());
    return deleteObjectPromise(ICP_RESOURCE, icpID)
      .then((json) => {
        if (!httpSuccess(json.status)) {
          dispatch(deleteIcpFailure(icpID, `${json.status}`));
          return json;
        }

        dispatch(deleteIcpSuccess(icpID));
        dispatch(fetchIcps());
        return json;
      }).catch((error) => {
        dispatch(deleteIcpFailure(icpID, `${error.status}`));
        return error;
      });
  };
}
