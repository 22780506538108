import { filter } from 'lodash';
import { TERM_FIRMWARE } from '../../../constants/apiEndpoints';
import { ADD } from '../../../constants/constants';
import { getObjectPromise } from '../../../utils/api';

export function validFirmware(firmware, translations, regex) {
  const errors = {};
  const promises = [];
  const doesFileMatch = allowUpload(firmware.id.trim(), regex);
  let validId = false;

  if (!firmware.id || firmware.id === '' || firmware.id.length === 0 || firmware.id === ADD) {
    errors.id = translations.noFirmwareFile;
  } else if (!makeMatchFile(firmware.make, firmware.id, regex)) {
    errors.id = translations.firmwareFileMismatch;
  } else if (!doesFileMatch) {
    errors.id = translations.fileMatch;
  } else {
    validId = true;
  }

  if (firmware[firmware.make].length === 0) {
    errors.models = translations.noModels;
  }

  if (firmware.isNew && (firmware.md5 === '' || !firmware.md5.match(/^[a-f0-9]{32}$/))) {
    errors.md5 = translations.md5;
  }

  if (!firmware.isNew || Object.entries(errors).length > 0) {
    return Promise.resolve(errors);
  }

  promises.push(validateId(firmware.id, translations).catch((e) => e));

  if (validId && doesFileMatch && doesFileMatch[1]) {
    promises.push(validateVersion(doesFileMatch[1], firmware.make,
      firmware.id.substr(firmware.id.length - 4).toLowerCase(), translations).catch((e) => e));
  }

  return Promise.all(promises)
    .then((data) => {
      if (data[0] && firmware.isNew) {
        errors.id = translations.inuse;
      } else if (data[1]) {
        errors.id = translations.versionMatch;
      }
      return errors;
    })
    .catch((error) => {
      console.log('FirmwareDialog.js - Error in executing', error);
      return errors;
    });
}

export function makeMatchFile(make, filePath, regex) {
  const firmwareType = getFileRegex(filePath, regex);
  return (firmwareType && firmwareType.make === make);
}

export function allowUpload(filePath, regex) {
  const firmwareType = getFileRegex(filePath, regex);
  return (firmwareType !== null);
}

export function validateId(fieldName) {
  return getObjectPromise(TERM_FIRMWARE, encodeURIComponent(fieldName))
    .then(() => true)
    .catch(() => false);
}

export function validateVersion(versionNum, make, fileExtension) {
  return getObjectPromise(TERM_FIRMWARE, null, {
    page: 1,
    limit: 10000,
    search: `${versionNum}`,
    make
  })
    .then((data) => {
      let myType = fileExtension === '.epk' ? 'CPU' : 'PTC';
      if (fileExtension !== '.epk' && fileExtension !== '.txt') {
        myType = '';
      }

      const onlyModelsThatMatch = filter(data.firmware, (o) => o.make === make &&
        o.type === myType &&
        o.version === versionNum);
      return onlyModelsThatMatch.length > 0;
    })
    .catch(() => false);
}

export function getFirmwareType(filePath, regex) {
  const firmwareType = getFileRegex(filePath, regex);
  return (firmwareType ? firmwareType.make : '');
}

export function getFileRegex(filePath, regex) {
  const lcFP = filePath.toLowerCase();
  for (let i = 0; i < regex.length; i++) {
    if (lcFP.match(regex[i].regex.slice(0, -1))) {
      return regex[i];
    }
  }
  return null;
}
