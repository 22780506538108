import { Add, Delete, Edit, Visibility } from '@mui/icons-material';
import { Grid, Popover } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { TEN_SECONDS } from '../../../constants/keyCodes';
import { LATE_CHECKOUT_EDIT } from '../../../constants/roles';
import {
  SonifiConfirm, SonifiLabel, SonifiSubHeader, SonifiTooltip
} from '../../../containers';
import { checkSingleUserPermission } from '../../../utils/rolesUtil';
import { getTerminalStyle, getTermLocItemStyle } from '../../SiteManagement/utils';
import {
  deleteOption, deleteOptionCheck, moveOption, reOrderSave, selectOption
} from '../actions/lateCheckoutActions';
import { ADD_OPTION } from '../constants';
import CheckoutRow from './CheckoutRow';
import TableHeader from './TableHeader';

const styles = (theme) => ({
  border: {
    border: `1px solid ${theme.palette.secondary.light}`,
  },
  topPadding: {
    paddingTop: 5,
    paddingLeft: 9,
    paddingBottom: 5
  },
  channelTableContainer: {
    border: `1px solid ${theme.palette.primary.light}`,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flexShrink: 1,

    // marginTop: '5px'
  },
  channelTableSubContainer: {
    padding: '10px 5px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden'
  },
  channelTable: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 1,
    overflow: 'hidden',
    backgroundColor: theme.palette.secondary.main,
    // border: `1px solid ${theme.palette.primary.light}`,
  },
  popOverContainer: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

class CheckoutOptions extends Component {
  state = {
    orderBy: 'time',
    order: 'asc',
    menuAnchor: null,
    popOverHeight: null,
    showMenu: false,
    channelIndex: -1,
    saveOptions: false
  };

  // tableHeads = () => [
  //   {
  //     centerHeader: false, id: 'time', label: this.props.translations.time, numeric: false, sortable: false
  //   },
  //   {
  //     centerHeader: false, id: 'quantity', label: this.props.translations.quantity, numeric: false, sortable: false
  //   },
  //   {
  //     centerHeader: false, id: 'today', label: this.props.translations.today, numeric: false, sortable: false
  //   },
  //   {
  //     centerHeader: false, id: 'purchased', label: this.props.translations.purchased, numeric: false, sortable: false
  //   },
  //   {
  //     centerHeader: false, id: 'cost', label: this.props.translations.cost, numeric: false, sortable: false
  //   }
  // ];

  handleAddDialog() {
    this.props.dispatch(selectOption(ADD_OPTION, 'edit'));
  }

  getToolTip() {
    const { options, translations } = this.props;
    if (options === null) {
      return translations.errors.optionsError;
    }
    if (options.length >= 5) {
      return translations.errors.maxAdded;
    }
    return translations.add;
  }

  confirmDialogConfirmFunc() {
    const { dispatch, deleteOptionObj } = this.props;
    dispatch(deleteOption(deleteOptionObj));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(deleteOptionCheck(null));
  }

  onDragStart() {
    this.debouncedLoadMoreData.cancel();
  }

  // Function called at the end of the channel drag
  // Checks to see if channel has changed positions
  // If it has then it calls the move function
  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.setState({ saveOptions: true });

    const oldIndex = result.source.index;
    const newIndex = result.destination.index;
    if (oldIndex !== newIndex) {
      this.props.dispatch(moveOption(oldIndex, newIndex));
      this.debouncedLoadMoreData();
    }
  }

  /********************/
  debouncedLoadMoreData = debounce(this.saveReOrder, TEN_SECONDS);

  saveReOrder() {
    if (this.state.saveOptions) {
      console.log('Save Re-order Stuff');
      this.props.dispatch(reOrderSave(this.props.options));
    }
  }

  /********************/

  openMenu = (channelIndex, channel) => (event) => {
    const { currentTarget } = event;
    this.setState({
      menuAnchor: currentTarget,
      popOverHeight: currentTarget.clientHeight + 8,
      showMenu: true,
      channelIndex,
      deleteChanNum: channel.id,
      deleteChanName: channel.name
    });
    event.preventDefault();
  };

  closeMenu = (event) => {
    if (event && this.state.popOverElement) {
      if (this.state.popOverElement.contains(event.target)) {
        return;
      }
    }
    this.setState({ showMenu: false });
  };

  handleEditDialog(type) {
    const { dispatch, options } = this.props;
    const { channelIndex } = this.state;
    dispatch(selectOption(options[channelIndex], type));
    this.setState({ showMenu: false });
  }

  handleDeleteDialog() {
    const { dispatch, options } = this.props;
    const { channelIndex } = this.state;
    dispatch(deleteOptionCheck(options[channelIndex]));
    this.setState({ showMenu: false });
  }

  render() {
    const {
      classes, deleteOptionObj, globalTranslations, options, translations, userPermissions
    } = this.props;
    const canEdit = checkSingleUserPermission(LATE_CHECKOUT_EDIT, userPermissions);

    return (
      <Fragment>
        <SonifiConfirm
          dialogOpen={canEdit && deleteOptionObj !== null && deleteOptionObj !== undefined}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          confirmTitle={translations.deleteDialog.deleteTitle}
          confirmText={`${translations.deleteDialog.deleteText} ${(deleteOptionObj
            ? moment(deleteOptionObj.time).format('h:mm A')
            : '')}?`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
        <SonifiSubHeader
          smallerHeader
          header={translations.title}
          onSubmit={this.handleAddDialog.bind(this)}
          label={translations.addBtn}
          icon={<Add />}
          toolTip={this.getToolTip()}
          showButton={canEdit}
          disabled={options === null || options.length >= 5}
        />
        <div className={classes.channelTableContainer}>
          <div className={classes.channelTableSubContainer}>
            <TableHeader translations={translations} />
            {(!options || options.length < 1)
              ? <Grid container className={classes.channelTableContainer}>
                <Grid item xs={12}>
                  <SonifiLabel error label={translations.noOptionsConfigured} paddingLeft />
                </Grid>
              </Grid>
              : <div className={classes.channelTable}>
                <DragDropContext onDragEnd={this.onDragEnd.bind(this)} onDragStart={this.onDragStart.bind(this)}>
                  <Droppable droppableId="droppableTermLoc" type="termLoc">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getTerminalStyle(snapshot.isDraggingOver)} >
                        {options.map((option, index) => (
                          <Draggable
                            key={`lateCheckout${index}`}
                            draggableId={`lateCheckout${index}`}
                            isDragDisabled={!canEdit}
                            index={index} >
                            {(draggableProvided, draggableSnapshot) => (
                              <div
                                ref={draggableProvided.innerRef}
                                {...draggableProvided.draggableProps}
                                style={getTermLocItemStyle(
                                  draggableSnapshot.isDragging,
                                  draggableProvided.draggableProps.style,
                                  index
                                )} >
                                <CheckoutRow
                                  key={index}
                                  rowIndex={index}
                                  option={option}
                                  readOnly={!canEdit}
                                  menuEvent={!canEdit ? () => { } : this.openMenu.bind(this, index, option)}
                                  drag={draggableProvided}
                                  isDragging={draggableSnapshot.isDragging}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>}
          </div>
        </div>
        <Popover
          open={this.state.showMenu}
          anchorEl={this.state.menuAnchor}
          PaperProps={{ style: { borderRadius: 0 } }}
          onClose={this.closeMenu}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Grid
            container
            direction="row"
            alignItems="center"
            className={classes.popOverContainer}
            style={{ height: this.state.popOverHeight }} >
            <SonifiTooltip onClick={this.handleEditDialog.bind(this, 'read')}
              icon={<Visibility fontSize="small" />}
              title={globalTranslations.view} disabled={!canEdit} />
            <SonifiTooltip onClick={this.handleEditDialog.bind(this, 'edit')}
              icon={<Edit fontSize="small" />}
              title={globalTranslations.edit} disabled={!canEdit} />
            <SonifiTooltip onClick={this.handleDeleteDialog.bind(this)}
              icon={<Delete fontSize="small" />}
              title={globalTranslations.delete} disabled={!canEdit} />
          </Grid>
        </Popover>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteOptionObj: state.lateCheckout.deleteOption,
  globalTranslations: state.global.translations.defaults,
  options: state.lateCheckout.options,
  selectedRoomTypes: state.lateCheckout.selectedRoomTypes,
  sorting: state.lateCheckout.sorting,
  translations: state.lateCheckout.translations.options,
  userPermissions: state.global.permissions
});

CheckoutOptions.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteOptionObj: PropTypes.object,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  options: PropTypes.array,
  selectedRoomTypes: PropTypes.array,
  sorting: PropTypes.bool,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(CheckoutOptions, styles, { withTheme: true }));

