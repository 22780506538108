export const ADD_INTEGRATION_BEGIN = 'ADD_INTEGRATION_BEGIN';
export const ADD_INTEGRATION_FAILURE = 'ADD_INTEGRATION_FAILURE';
export const ADD_INTEGRATION_SUCCESS = 'ADD_INTEGRATION_SUCCESS';
export const CLEAR_SUCCESS_FAILURE = 'CLEAR_SUCCESS_FAILURE';
export const DELETE_CHECK = 'DELETE_CHECK';
export const DELETE_INTEGRATION_BEGIN = 'DELETE_INTEGRATION_BEGIN';
export const DELETE_INTEGRATION_FAILURE = 'DELETE_INTEGRATION_FAILURE';
export const DELETE_INTEGRATION_SUCCESS = 'DELETE_INTEGRATION_SUCCESS';
export const GET_INTEGRATIONS_BEGIN = 'GET_INTEGRATIONS_BEGIN';
export const GET_INTEGRATIONS_FAILURE = 'GET_INTEGRATIONS_FAILURE';
export const GET_INTEGRATIONS_SUCCESS = 'GET_INTEGRATIONS_SUCCESS';
export const UPDATE_INTEGRATION_VENDORS = 'UPDATE_INTEGRATION_VENDORS';
export const UPDATE_SELECTED_INTEGRATION = 'UPDATE_SELECTED_INTEGRATION';
export const VALIDATE_INTEGRATION_FAILURE = 'VALIDATE_INTEGRATION_FAILURE';
