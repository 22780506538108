import {
  Add, ArrowRightAlt, CheckCircleOutline, GetApp, Warning
} from '@mui/icons-material';
import {
  Grid, Table, TableBody, TableCell, TableRow, Tooltip
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { MAX_SIZE } from '../../../constants/magic';
import { SonifiIconButton, SonifiLabel, SonifiSpinner, SonifiTableHead } from '../../../containers';
import SonifiDropZone from '../../../containers/SonifiDropZone/SonifiDropZone';
import SonifiIconLabel from '../../../containers/SonifiIconLabel';
import * as actions from '../actions/softwareChangesActions';
import { exampleCsv, findProductParentId } from '../utils/softwareImport';

const styles = (theme) => ({
  channelListContainer: {
    overflow: 'auto',
    height: '100%',
    paddingBottom: '5px'
  },
  dropFile: {
    height: '45%',
    width: '45%',
    backgroundColor: theme.palette.secondary.main
  },
  centered: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.primary.contrastText,
    fontSize: '12pt',
    fontFamily: 'Open Sans',
    fontWeight: 900, /* Avenir 85 */
    outline: 'none'
  },
  noPadding: {
    padding: 0
  },
  textCenter: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    rowGap: '5%'
  },
  hoverCursor: {
    cursor: 'cell'
  },
  gridHeight: {
    height: '80%',
    marginTop: '10%'
  },
  gridHeightTall: {
    height: '50%'
  },
  gridHeightShort: {
    height: '15%',
    width: '100%'
  },
  goodStatus: {
    color: 'green !important'
  },
  badStatus: {
    color: `${theme.palette.defaults.red} !important`
  },
  blueStatus: {
    color: `${theme.palette.secondary.main} !important`
  }
});

class SoftwareDrop extends Component {
  /* eslint-disable space-before-function-paren */
  constructor(props) {
    super(props);

    this.onAcceptedDrop = this.onAcceptedDrop.bind(this);
    this.getIntroStuff = this.getIntroStuff.bind(this);
    this.displayOnlyContent = this.displayOnlyContent.bind(this);
  }

  onAcceptedDrop(acceptedFiles) {
    const { parseImportFile } = this.props;
    const reader = new FileReader();
    reader.onload = (function() {
      const lines = reader.result.split(/\r?\n|\r/),
        result = lines.map((line) => line.split(','));
      parseImportFile(result);
    });
    reader.readAsText(acceptedFiles[0]);
  }

  downloadCsv() {
    const { productParents } = this.props;
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `data:text/csv;charset=utf-8,${encodeURI(exampleCsv(productParents))}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'example.csv';
    hiddenElement.click();
  }

  updateSiteCheck(rowInfo) {
    this.props.dispatch(actions.showPopup(rowInfo));
  }

  getRowInformation(rowLabel, rowInfo) {
    let auto = rowLabel && rowLabel !== '' ? rowInfo[rowLabel] : '';
    let currRow = '';
    if (rowLabel === 'id') {
      currRow = auto;
    } else if (rowLabel === 'automatic_updates') {
      currRow = rowInfo.response.software['update-mode'];
    } else if (rowLabel === 'software_channel') {
      currRow = rowInfo.response.software.channel;
    } else if (rowLabel === 'virtos_version') {
      currRow = `${rowInfo.response.software.virtos}`;
    } else if (rowLabel === 'version') {
      return;
    } else if (rowLabel === 'system_id') {
      currRow = auto;
      if (rowInfo.error && rowInfo.error[rowLabel]) {
        auto = rowInfo.response.system.systemId;
      }
    }


    const showChange = auto !== currRow && currRow !== '' && auto !== '';
    return <SonifiIconLabel blue={showChange} label={`${currRow}`} icon={showChange ? <ArrowRightAlt /> : null}
      labelTwo={showChange ? `${auto}` : ''} />;
  }

  getProductParentColumn(rowLabel, rowInfo) {
    const { translations } = this.props;
    const ppName = findProductParentId(rowInfo.product_code, this.props.productParents).name;
    if (rowInfo.response.system && rowInfo.response.system.hosts) {
      if (rowInfo.response.system.hosts.length > 0) {
        const currRow = rowInfo.response.system.versions.toString();
        const showChange = true;
        const auto = rowInfo.version;

        if (auto === null) {
          return <TableCell colSpan={2}>
            <SonifiLabel error boldLabel={ppName} label={translations.errors.automatic} />
          </TableCell>;
        }

        return <Fragment>
          <TableCell>
            <SonifiLabel boldLabel={ppName}
              label={rowInfo.response.system.hosts.toString()} />
          </TableCell>
          <TableCell>
            <SonifiIconLabel blue={showChange} label={`${currRow}`} icon={showChange ? <ArrowRightAlt /> : null}
              labelTwo={showChange ? `${auto}` : ''} />
          </TableCell>
        </Fragment>;
      } else if (rowLabel === 'product_code') {
        return <TableCell colSpan={2}>
          <SonifiLabel error boldLabel={ppName} label={translations.errors.doesNotExist} />
        </TableCell>;
      }
    } else if (rowLabel === 'product_code') {
      return <TableCell colSpan={2}><SonifiLabel error label={translations.errors.noSystems} />
      </TableCell>;
    }
  }

  getRow(rowInfo, index) {
    const { classes, columnNames, saved, translations } = this.props;
    return (
      <TableRow key={index} onClick={this.updateSiteCheck.bind(this, rowInfo)}>
        <TableCell component="th" scope="row" >
          {saved
            ? <Tooltip title={rowInfo.success ? translations.success : rowInfo.details}>
              {rowInfo.success
                ? <CheckCircleOutline className={classes.goodStatus} />
                : <Warning className={classes.badStatus} />}
            </Tooltip>
            : rowInfo.error && rowInfo.toolTip &&
            <Tooltip title={rowInfo.error && rowInfo.toolTip}>
              {rowInfo.error && <Warning className={rowInfo.allowSave ? classes.blueStatus : classes.badStatus} />}
            </Tooltip>
          }
        </TableCell>
        {columnNames.filter((img) => img.id !== 'version').map((name, indexOne) => (
          name.id === 'product_code'
            ? <Fragment key={indexOne}>{this.getProductParentColumn(name.id, rowInfo)}</Fragment>
            : <TableCell key={indexOne}>{this.getRowInformation(name.id, rowInfo)}</TableCell>
        ))}
      </TableRow>
    );
  }

  getIntroStuff() {
    const { classes, globalTranslations } = this.props;
    return <Grid container className={classes.textCenter}>
      <Grid item>
        <div className={classes.textCenter}>
          <Add />
          <SonifiLabel additionalClasses={classes.noPadding} label={globalTranslations.addCsv} />
          <SonifiLabel additionalClasses={classes.noPadding} label={globalTranslations.addDrop} />
        </div>
      </Grid>
    </Grid>;
  }

  displayOnlyContent() {
    return <SonifiLabel label="" />;
  }

  getDialogInfo() {
    const {
      classes, globalTranslations, headers, importError, tableList, translations
    } = this.props;

    return tableList && tableList.length > 0
      ? <div className={classes.channelListContainer}>
        <Table>
          <SonifiTableHead
            headColumns={headers}
            hideSortIcon={true}
          />
          <TableBody>
            {tableList.map((channel, index) => (
              channel.response.software === null && channel.response.system === null
                ? <TableRow key={index}>
                  <TableCell component="th" scope="row"><Tooltip title={channel.error && channel.toolTip}>
                    {channel.error &&
                      <Warning className={channel.allowSave ? classes.blueStatus : classes.badStatus} />}
                  </Tooltip>
                  </TableCell>
                  <TableCell component="th" scope="row"><SonifiLabel label={channel.id} /></TableCell>
                  <TableCell component="th" scope="row" colSpan={6}>
                    <SonifiLabel error
                      label={translations.errors.marinaSite} />
                  </TableCell>
                </TableRow>
                : this.getRow(channel, index)

            ))}
          </TableBody>
        </Table>
      </div>
      : <Grid container className={`${classes.gridHeight} ${classes.textCenter}`}>
        <Grid item xs={6} className={classes.gridHeightTall}>
          <SonifiDropZone
            acceptedFiles={['text/csv', '.csv', 'application/vnd.ms-excel']}
            allowMultiple={false}
            maxSize={MAX_SIZE}
            onAcceptedDropHandler={this.onAcceptedDrop}
            displayDropZoneContent={this.getIntroStuff}
            displayOnlyContent={this.displayOnlyContent}
          />
        </Grid>
        <Grid item className={`${classes.gridHeightShort} ${classes.textCenter}`}>
          <SonifiIconButton icon={<GetApp />}
            label={globalTranslations.example}
            onClick={this.downloadCsv.bind(this)} />
          {importError !== null && <SonifiLabel error label={importError} />}
        </Grid>
      </Grid>;
  }

  render() {
    const { parsing } = this.props;
    if (parsing) {
      return <SonifiSpinner />;
    }
    return (this.getDialogInfo());
  }
}

const mapStateToProps = (state) => ({
  columnNames: state.softwareChanges.columnNames,
  globalTranslations: state.global.translations.importDialog,
  headers: state.softwareChanges.headers,
  importError: state.softwareChanges.importError,
  parsing: state.softwareChanges.parsing,
  productParents: state.deployments.productParents,
  saved: state.softwareChanges.saved,
  translations: state.softwareChanges.translations.grid,
  tableList: state.softwareChanges.tableList,
  userPermissions: state.global.permissions
});

SoftwareDrop.propTypes = {
  classes: PropTypes.object.isRequired,
  columnNames: PropTypes.array,
  csvText: PropTypes.string,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  headers: PropTypes.array,
  importError: PropTypes.string,
  isValidForm: PropTypes.func,
  parseImportFile: PropTypes.func.isRequired,
  parsing: PropTypes.bool,
  productParents: PropTypes.array,
  saved: PropTypes.bool,
  translations: PropTypes.object,
  tableList: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(SoftwareDrop, styles, { withTheme: true }));
