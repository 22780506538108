import { Grid } from '@mui/material';
import { debounce, filter } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { SonifiText } from '../../../containers';
import SonifiDateTimePicker from '../../../containers/SonifiDateTimePicker';
import { getErrorText, isError } from '../../../utils';
import { removePipe } from '../../../utils/textUtil';
import * as action from '../actions/groupServicesActions';
import { GROUP_SERVICE_TYPES } from '../constants';
import { getMaxDepartureTime } from '../utils';

class GroupInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupName: ''
    };
    this.debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);
  }

  filterData() {
    this.props.dispatch(action.editSelectedGroup('name', this.state.groupName));
  }

  componentDidMount() {
    const { selectedGroup } = this.props;
    const { id, isNew, name } = selectedGroup;

    // const menusetName = getNameFromObjectArray(menusets, menuset);
    // if (menusetName === menuset) {
    //   this.props.dispatch(action.editSelectedGroup('menuset', 0));
    // }

    this.setState({
      groupId: id && !isNew ? id : '',
      groupName: name && !isNew ? name : '',
    });
  }

  render() {
    const {
      canEdit, errors, menusets, selectedGroup, translations
    } = this.props;
    const { arrival, departure, menuset, type } = selectedGroup;
    const minDate = moment(arrival);
    const maxEndDate = getMaxDepartureTime(minDate);
    const currentMomentTime = moment().format('YYYY-MM-DDTHH:mm:ssZ');
    const arrivalTimeInPast = arrival < currentMomentTime && !selectedGroup.isNew;
    const isInArray = filter(menusets, (e) => e.id === menuset);
    const isMenusetSelectable = ((menusets && menusets.length > 1) || menuset === 0 || isInArray.length === 0);
    const gsMenuset = (menuset ? menuset : 0);

    return (
      <Grid container>
        <Grid item xs={4}>
          <SonifiText
            id={this.state.groupId}
            change={this.handleGroupNameChange.bind(this)}
            defaultValue={`${this.state.groupName}`}
            disabled={!canEdit}
            error={isError('name', errors)}
            errorText={getErrorText('name', errors)}
            label={translations.groupName}
            size="percent"
          />
        </Grid>
        <Grid item xs={4}>
          <SonifiText
            change={this.handleChange('type')}
            defaultValue={type}
            disabled={!canEdit || GROUP_SERVICE_TYPES.length === 1}
            items={GROUP_SERVICE_TYPES}
            label={translations.type}
            select={true}
            size="percent"
          />
        </Grid>
        <Grid item xs={4}>
          {menusets && menusets.length > 0 && (
            <SonifiText
              change={this.handleChange('menuset')}
              defaultValue={gsMenuset}
              disabled={!canEdit || !isMenusetSelectable}
              items={menusets.map((suggestion) => ({
                id: suggestion.id,
                value: suggestion.id,
                label:
                  suggestion.name +
                  (typeof suggestion.description !== 'undefined'
                    ? ` : ${suggestion.description}`
                    : ''),
              }))}
              label={translations.menuset}
              select
              size="percent"
            />
          )}
        </Grid>
        <Grid item xs={4}>
          <SonifiDateTimePicker
            change={this.handleDateChange('arrival')}
            defaultValue={arrival}
            disabled={!canEdit || arrivalTimeInPast}
            disablePast={selectedGroup.isNew || !arrivalTimeInPast}
            error={isError('arrival', errors)}
            errorText={getErrorText('arrival', errors)}
            label={translations.startDate}
            size="percent"
          />
        </Grid>
        <Grid item xs={4}>
          <SonifiDateTimePicker
            change={this.handleDateChange('departure')}
            defaultValue={departure}
            disabled={!canEdit}
            disablePast={true}
            error={isError('departure', errors)}
            errorText={getErrorText('departure', errors)}
            label={translations.endDate}
            maxDate={maxEndDate}
            minDate={minDate}
            size="percent"
          />
        </Grid>
      </Grid>
    );
  }

  handleGroupNameChange({ target: { value } }) {
    this.setState({ groupName: removePipe(value) }, () => {
      this.debouncedLoadMoreData();
    });
  }

  handleChange(name) {
    return ({ target: { value } }) => {
      this.props.dispatch(action.editSelectedGroup(name, value));
    };
  }

  handleDateChange(name) {
    return (value) => {
      this.props.dispatch(action.editSelectedGroup(
        name, moment(value).format('YYYY-MM-DDTHH:mm:ssZ')
      ));
    };
  }
}

const mapStateToProps = (state) => ({
  menusets: state.termGrid.menusets,
  selectedGroup: state.groupServices.selectedGroup,
  translations: state.groupServices.translations.editDialog,
});

GroupInfo.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  errors: PropTypes.object,
  menusets: PropTypes.array.isRequired,
  selectedGroup: PropTypes.object,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(GroupInfo);
