import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiError from '../../../containers/SonifiError';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import { getScheduleData } from '../../SystemManagement/actions/systemManagementActions';
import ManualWindow from './ManualWindow';

class System extends Component {
  state = {
    schedule: null,
    scheduleId: null,
    error: null
  };

  componentDidMount() {
    this.props.dispatch(getScheduleData(this.props.scheduleId));
  }

  componentDidUpdate() {
    if (this.props.scheduleId !== this.state.scheduleId) {
      this.props.dispatch(getScheduleData(this.props.scheduleId));
      this.setState({
        scheduleId: this.props.scheduleId,
        schedule: null
      });
    }
    if (this.props.error !== this.state.error) {
      this.setState({
        error: this.props.error
      });
    }
    if (this.props.schedule !== this.state.schedule) {
      this.setState({
        scheduleId: this.props.scheduleId,
        schedule: this.props.schedule
      });
    }
  }

  render() {
    const { error, schedule } = this.state;
    const { translations } = this.props;

    if (!error && !schedule) {
      return <SonifiSpinner />;
    }

    if (error) {
      return <SonifiError boldLabel={translations.noSystemData} />;
    }

    if (schedule.hosts && Object.keys(schedule.hosts).length === 0) {
      return <SonifiLabel error label={translations.noHostsConfigured} />;
    }

    return (
      <ManualWindow schedHosts={schedule.hosts} />
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.systemManagement.error,
  schedule: state.systemManagement.schedule,
  translations: state.systemManagement.translations.errors,
});

System.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  schedule: PropTypes.object,
  scheduleId: PropTypes.string.isRequired,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(System);
