import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiSpinner, SonifiTableHead } from '../../../containers';
import SonifiLabel from '../../../containers/SonifiLabel';
import IpgFileRow from './IpgFileRow';

class IpgFileTable extends Component {
    render() {
        const { ipgFilenames, loading, translations } = this.props;
        const tableHeader = [
            { id: 'directory', numeric: false, label: translations.columnHeaders.directory },
            { id: 'filepath', numeric: false, label: translations.columnHeaders.filePath }
        ];
    
        return (
            <Table stickyHeader={true}>
                <SonifiTableHead
                    headColumns={tableHeader}
                />
                <TableBody>
                {loading
                    ? <TableRow><TableCell colSpan={4}><SonifiSpinner /></TableCell></TableRow>
                    : <Fragment>
                        {!ipgFilenames || ipgFilenames.length === 0
                            ? <TableRow>
                                <TableCell colSpan={4}><SonifiLabel error label={translations.error} /></TableCell>
                            </TableRow>
                            : ipgFilenames.map((info, index) => (
                                <Fragment key={index}>
                                    <IpgFileRow ipgFileRow={info} rowIndex={index} />
                                </Fragment>
                            ))
                        }
                    </Fragment>
                }
                </TableBody>
            </Table>
        );
    }
}

const mapStateToProps = (state) => ({
    ipgFilenames: state.downloads.ipgFilenames,
    loading: state.downloads.loading,
    translations: state.downloads.translations.table
});

IpgFileTable.propTypes = {
    ipgFilenames: PropTypes.array,
    loading: PropTypes.bool,
    translations: PropTypes.object
};

export default connect(mapStateToProps)(IpgFileTable);
