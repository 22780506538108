import { filter, find } from 'lodash';
import satisfies from 'semver/functions/satisfies';
import { findObjectByKey } from '../../../utils';

/**
 * Retrieve the short name for a given product id.
 *
 * @param {string} productId - The product id, such as "CC-30005".
 * @param {array} productParents - An array of of product objects.
 *
 * @returns {string} The 'short name' of the product if productParents
 *     has a a product with the given productId. Otherwise, the productId
 *     is given back.
 */
export function getShortName(productId, productParents) {
  const product = findObjectByKey(productParents, 'id', productId);
  return product ? product.name : productId;
}

/**
 * Retrieve a filtered version of the product versions
 * to only ones that we're available to upgrade to. It will
 * always have the assigned version avaiable.
 *
 * @param {string} currentVersion - The current version of the product.
 * @param {string} assignedVersion - The version the product is assigned to.
 * @param {number} siteVirtosVersion - The virtos version to limit the results to.
 * @param {array} productVersions - An array of the available product version objects
 *     to update to.
 * @param {array} activeVersions - An array of active release templates
 *
 * @returns {array} A new array that is filtered to available upgrades based on
 *    the given `productVersions`.
 */
export function getAvailableProductUpgrades(currentVersion, assignedVersion, siteVirtosVersion, productVersions,
  activeVersions) {
  let upgradeVersions = JSON.parse(JSON.stringify(productVersions));

  if (currentVersion) {
    upgradeVersions = upgradeVersions.filter((release) => satisfies(release.id, `>=${currentVersion}`));
  }

  // TODO: ENGCCA-4439: Should be able to get rid of this.
  const activeReleaseTemplates = upgradeVersions.filter((o) => {
    const versionId = o.id.split('.');
    return activeVersions.includes(`${versionId[0]}`);
  });

  if (
    !find(activeReleaseTemplates, {
      id: assignedVersion,
      virtos: siteVirtosVersion,
    }) ||
    activeReleaseTemplates.length === 0
  ) {
    activeReleaseTemplates.unshift({ id: assignedVersion, virtos: siteVirtosVersion });
  }

  return filter(activeReleaseTemplates, (o) => o.id !== null);
}
