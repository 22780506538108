import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import GroupRow from '../containers/GroupRow';

class GroupGrid extends Component {
  render() {
    const {
      allowEditing, canEdit, groups, handleRequestSort, sortGroups, translations, userState
    } = this.props;

    const tableHeader = [
      { id: 'description', sortable: true, numeric: false, label: `${translations.name}` }
    ];

    return (
      <Table stickyHeader={true}>
        <SonifiTableHead
          headColumns={tableHeader}
          order={userState.order}
          orderBy={userState.orderBy}
          onRequestSort={handleRequestSort}
        />
        {sortGroups
          ? <TableBody>
            <TableRow>
              <TableCell><SonifiSpinner /></TableCell>
            </TableRow>
          </TableBody>
          : <TableBody>
            {groups.length === 0 &&
              <TableRow>
                <TableCell>
                  <SonifiLabel error
                    label={(!allowEditing
                      ? translations.errors.gettingGroups
                      : translations.errors.filterGroups)}
                  />
                </TableCell>
              </TableRow >
            }
            {groups.map((option, index) => (
              <GroupRow key={index} group={option} canEdit={canEdit} />
            ))}
          </TableBody>}
      </Table>
    );
  }
}

const mapStateToProps = (state) => ({
  allowEditing: state.groups.allowEditing,
  groups: state.groups.groups,
  sortGroups: state.groups.sortGroups,
  translations: state.groups.translations.grid
});

GroupGrid.propTypes = {
  allowEditing: PropTypes.bool,
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  groups: PropTypes.array,
  handleRequestSort: PropTypes.func,
  sortGroups: PropTypes.bool,
  translations: PropTypes.object,
  userState: PropTypes.object
};

export default connect(mapStateToProps)(GroupGrid);
