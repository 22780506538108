import { Check } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { displaySnackBar } from '../../../actions/globalActions';
import { HL7_PMS_ROOM_CONFIG, ROOM_RESOURCE } from '../../../constants/apiEndpoints';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { SITE_EDIT } from '../../../constants/roles';
import {
  SonifiCheckbox, SonifiDivider, SonifiLabel, SonifiSpinner, SonifiSubHeader
} from '../../../containers';
import { getSiteNumFromURI } from '../../../utils';
import { getSiteObjectPromise, putObjectPromise, readResponseErrorStream } from '../../../utils/api';
import { checkSingleUserPermission } from '../../../utils/rolesUtil';
import { updateChangeVariable, updateHL7ConfigBegin, updateHL7ConfigComplete } from '../actions/siteManagementActions';

const uniqueRoomFields = [
  { value: 'point_of_care', label: 'Point of Care' },
  { value: 'room_number', label: 'Room Number' },
  { value: 'bed', label: 'Bed' },
  { value: 'facility', label: 'Facility' },
  { value: 'location_status', label: 'Location Status' }
];

class HL7 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      gettingRooms: false,
      allowEdit: false
    };

    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(updateHL7ConfigComplete());
    this.setState({
      allowEdit: false, loading: true, gettingRooms: true
    }, () => {
      getSiteObjectPromise(HL7_PMS_ROOM_CONFIG)
        .then((data) => {
          this.setState({ ...data, loading: false });
        }).catch((error) => {
          console.log(error);
          // this.setState({ {}, loading: false });
          this.setState({
            loading: false,
            site_id: getSiteNumFromURI(),
            point_of_care: false,
            room_number: false,
            bed: false,
            facility: false,
            location_status: false
          });
        });

      getSiteObjectPromise(ROOM_RESOURCE)
        .then((json) => {
          this.setState({ gettingRooms: false, allowEdit: json.total_items < 1 });
          return json;
        }).catch((error) => {
          console.log(error);
          this.setState({ gettingRooms: false, allowEdit: false });
        });
    });
  }

  onSave = () => {
    const submitObj = JSON.parse(JSON.stringify({ ...this.state }));
    delete (submitObj.loading);
    delete (submitObj.gettingRooms);
    delete (submitObj.allowEdit);

    this.props.dispatch(updateHL7ConfigBegin());

    putObjectPromise(HL7_PMS_ROOM_CONFIG, null, submitObj)
      .then((json) => {
        if (!json.ok) {
          readResponseErrorStream(json)
            .then((errorDetail) => {
              console.log('readResponseErrorStream:', errorDetail);
            });
          this.props.dispatch(updateHL7ConfigComplete());
          this.props.dispatch(displaySnackBar(ERROR, 'Error saving Unique Room Fields'));
          return json;
        }
        this.props.dispatch(updateHL7ConfigComplete());
        this.props.dispatch(displaySnackBar(SUCCESS, 'Successfully saved Unique Room Fields'));
        this.props.dispatch(updateChangeVariable(true));
        return json;
      })
      .catch((error) => {
        console.log('Saving error', error);
        this.props.dispatch(updateHL7ConfigComplete());
        this.props.dispatch(displaySnackBar(ERROR, 'Error saving Unique Room Fields'));
      });
  };

  handleCheckChange = (value) => () => {
    this.props.dispatch(updateChangeVariable(false));
    this.setState({ [value]: !this.state[value] });
  };

  isValueChecked(roomField) {
    return !!this.state[roomField];
  }

  render() {
    const { allowEdit, gettingRooms, loading } = this.state;
    const { globalTranslations, translations, userPermissions } = this.props;
    const readOnly = !checkSingleUserPermission(SITE_EDIT, userPermissions);
    return (
      <Grid container>
        <Grid item xs={12}>
          <SonifiSubHeader
            disabled={readOnly || loading || !allowEdit}
            header=""
            icon={<Check />}
            label={globalTranslations.save}
            onSubmit={this.onSave}
            showButton={!readOnly && allowEdit}
          />
        </Grid>
        {!readOnly && allowEdit &&
          <Grid item xs={12}>
            <SonifiDivider />
          </Grid>
        }
        {loading || gettingRooms
          ? <SonifiSpinner />
          : <Grid container>
            <Grid item xs={12}>
              <SonifiLabel boldLabel={translations.title} />
            </Grid>

            {uniqueRoomFields.map((info, index) => (
              <Grid style={{ paddingLeft: '10px' }} key={index} item xs={12}>
                <SonifiCheckbox
                  onChange={this.handleCheckChange(info.value)}
                  label={info.label}
                  value={this.isValueChecked(info.value)}
                  disabled={!allowEdit}
                />
              </Grid>
            ))}
            {!allowEdit &&
              <Grid item xs={12} justifyContent="right">
                <SonifiLabel size="xs"
                  label="There are rooms currently configured for this site, saving is not an option." />
              </Grid>
            }
          </Grid>}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  hl7: state.global.site.hl7,
  translations: state.siteManagement.translations.hcRoomConfig,
  userPermissions: state.global.permissions
});

HL7.propTypes = {
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  hl7: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(HL7);
