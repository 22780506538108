export const CLOSE_ERROR_LINEUP_SAVE = 'CLOSE_ERROR_LINEUP_SAVE';
export const CLOSE_SUCCESS_LINEUP_SAVE = 'CLOSE_SUCCESS_LINEUP_SAVE';
export const DELETE_LINEUP_CHECK = 'DELETE_LINEUP_CHECK';
export const DELETE_SINGLE_LINEUP_BEGIN = 'DELETE_SINGLE_LINEUP_BEGIN';
export const DELETE_SINGLE_LINEUP_FAILURE = 'DELETE_SINGLE_LINEUP_FAILURE';
export const DELETE_SINGLE_LINEUP_SUCCESS = 'DELETE_SINGLE_LINEUP_SUCCESS';
export const DETAILS_EDIT_SUCCESS = 'DETAILS_EDIT_SUCCESS';
export const LOADING_LINEUP = 'LOADING_LINEUP';
export const SET_CHANNEL_LINEUPS = 'SET_CHANNEL_LINEUPS';
export const SET_LINEUP_SNACKBAR = 'SET_LINEUP_SNACKBAR';
export const SET_LINEUPS_ERROR = 'SET_LINEUPS_ERROR';
export const SET_LINEUPS_LOADING = 'SET_LINEUPS_LOADING';
export const SET_LINEUPS_SAVING = 'SET_LINEUPS_SAVING';
export const SET_SELECTED_CHANNEL_LINEUP = 'SET_SELECTED_CHANNEL_LINEUP';
export const SET_TV_LINEUP_SUCCESS = 'SET_TV_LINEUP_SUCCESS';
