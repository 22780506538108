import { Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSnackBar from '../../../containers/SonifiSnackBar';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiTabs from '../../../containers/SonifiTabs';
import { getProductParents } from '../../Deployments/actions/deploymentsActions';
import { getScheduleData, getSystems, updateSnackBar } from '../actions/systemManagementActions';
import System from './System';

class SystemTabs extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getSystems());
    dispatch(getProductParents());
  }

  handleChange(event, newValue) {
    this.props.dispatch(getScheduleData(newValue));
  }

  closeSnackBar() {
    this.props.dispatch(updateSnackBar(''));
  }

  render() {
    const {
      translations, snackBarMessage, snackBarType, systemId, systems
    } = this.props;

    return (
      <Fragment>
        <Grid container style={{ alignContent: 'baseline' }}>
          {systems === null
            ? <SonifiSpinner />
            : <Grid item xs={12} style={{ height: '99%' }}>
              {(!systems || systems.length === 0 || systemId === null)
                ? <SonifiLabel error boldLabel={translations.errors.noSystems} />
                : <SonifiTabs
                  tabs={systems.map(({ date, id }) => ({
                    id: `${id}`,
                    title: moment(date).format('YYYY-MM-DD'),
                  }))}
                  handleChange={this.handleChange.bind(this)}
                  tabValue={systemId}
                  showingTab={<System scheduleId={systemId} />}
                />}
            </Grid>}
        </Grid>
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  snackBarMessage: state.systemManagement.snackBarMessage,
  snackBarType: state.systemManagement.snackBarType,
  systemId: state.systemManagement.systemId,
  systems: state.systemManagement.systems,
  translations: state.systemManagement.translations
});

SystemTabs.propTypes = {
  dispatch: PropTypes.func,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  systemId: PropTypes.string,
  systems: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(SystemTabs);
