import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  label: {
    marginTop: theme.spacing(1),
  },
  error: {
    color: 'red !important'
  },
  noPadding: {
    marginTop: 0
  }
});

export class SonifiCheckbox extends Component {
  render() {
    const {
      classes, disabled, error, float, noLabel, noPadding, removeTopPadding, disableRipple
    } = this.props;

    const floatVar = (float ? classes[float] : '');
    let labelClass = '';
    if (!noLabel) {
      labelClass = (removeTopPadding) ? '' : classes.label;
    }

    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={this.props.value}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            className={`${error ? classes.error : ''} ${noPadding ? classes.noPadding : ''}`}
            disabled={disabled}
            disableRipple={disableRipple}
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            onChange={this.props.onChange}
          />
        }
        label={this.props.label}
        className={`${labelClass} ${floatVar} ${noPadding ? classes.noPadding : ''}`}
      />
    );
  }
}

SonifiCheckbox.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  disableRipple: PropTypes.bool,
  error: PropTypes.bool,
  float: PropTypes.string,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
  noPadding: PropTypes.bool,
  onChange: PropTypes.func,
  removeTopPadding: PropTypes.bool,
  value: PropTypes.bool
};

export default withStyles(SonifiCheckbox, styles, { withTheme: true });
