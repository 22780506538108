import { CheckCircleOutline } from '@mui/icons-material';
import {
  Table, TableBody, TableCell, TableRow, Tooltip
} from '@mui/material';
import { find, map, pick } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MANUAL } from '../../../constants/constants';
import { MAX_SIZE } from '../../../constants/magic';
import { SonifiLabel, SonifiSpinner, SonifiTableHead, SonifiTextInline } from '../../../containers';
import { getReleaseTemplates } from '../../ReleaseTemplates/actions/releaseTemplateActions';
import { getReleaseVersions, getVirtosPkgs, sortReleaseVersions } from '../actions/deploymentsActions';

class ParentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'name',
      editRow: -1,
      gotVirtosPkgs: false,
      getRV: false,
      getMajorVersionTemplates: false,
      allowedPkgs: null,
      currentSelections: null,
      templateVersion: -1,
      templatePackages: []
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { channels, dispatch, selected, selectedProductParent } = this.props;
    const { orderBy, order } = this.state;
    dispatch(getVirtosPkgs(channels[selected].virtos))
      .then(() => {
        this.setState({ gotVirtosPkgs: true });
      });

    dispatch(getReleaseTemplates({
      product_code: selectedProductParent, virtos: channels[selected].virtos, limit: MAX_SIZE
    })).then((templates) => {
      const majorVersion = channels[selected].id.split('.');
      const filteredDuration = templates.filter((e) => `${e.version}` === majorVersion[0]);
      this.setState({
        templateVersion: filteredDuration[0].id,
        templatePackages: filteredDuration[0].packages,
        getMajorVersionTemplates: true
      });
    });

    dispatch(getReleaseVersions(
      selectedProductParent,
      channels[selected].id,
      orderBy,
      order,
      channels[selected].virtos
    ))
      .then(() => {
        this.setState({ getRV: true });
      });
  }

  componentDidUpdate() {
    const {
      gotVirtosPkgs, getRV, getMajorVersionTemplates, allowedPkgs, templatePackages
    } = this.state;
    const {
      channels, editType, packages, selected, virtosPkgs
    } = this.props;
    if (gotVirtosPkgs && getRV && getMajorVersionTemplates && allowedPkgs === null) {
      const availPkgs = JSON.parse(JSON.stringify(virtosPkgs));
      const apackages = JSON.parse(JSON.stringify(packages));

      const currentSelections = {};
      const names = map(apackages, 'name');
      const tempPkgs = pick(availPkgs, names);

      let channelIds = map(channels, 'id').reverse();
      channelIds = channelIds.filter((e) => e.split('.')[0] === `${channels[selected].id.split('.')[0]}`);
      while (channelIds.length) {
        if (channelIds[0] === `${channels[selected].id}`) {
          break;
        }
        channelIds.shift();
      }

      Object.keys(tempPkgs).forEach((key) => {
        const keyValue = find(apackages, { name: key });
        const templateVersion = find(templatePackages, { name: key });
        currentSelections[key] = keyValue.version;

        if (editType === MANUAL) {
          let filteredPkgs = availPkgs[key].filter((e) => e.split('.')[0] === `${templateVersion.version}`);
          while (filteredPkgs.length) {
            if (filteredPkgs[0] === `${keyValue.version}`) {
              break;
            }
            filteredPkgs.shift();
          }

          if (channelIds.length > 1) {
            filteredPkgs = availPkgs[key].filter((e) => e.split('.')[1] === `${keyValue.version.split('.')[1]}`);
          }
          tempPkgs[key] = filteredPkgs;
        } else {
          tempPkgs[key] = [`${keyValue.version}`];
        }
      });
      this.setState({ allowedPkgs: tempPkgs, currentSelections });
    }
  }

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    }, () => {
      this.props.dispatch(sortReleaseVersions(this.state.orderBy, this.state.order));
    });
  };

  updatePackageVersion(index) {
    // const { dispatch, rowIndex } = this.props;
    // dispatch(showAuditInfo(rowIndex));
    // Cameron: Do we do a dispatch here to get the latest versions of the selected package?
    // Or do we want to do a dispatch at the componentDidMount DeploymentDialog to get all the packages within this
    // deployment?  That way we can either allow an edit or not (if there are no versions to update to?)
    // Cameron: May need to talk to Eugene about updating or adding an API call to get the
    // possible versions of each of the packages
    //  Package info = {name: 'event-reporting', version: '1.12.2', status: true}
    // GET /software-packages/[package.name]?currentVersion=[package.version]
    // which would return the versions that it could be set to?  If this is 0, then don't allow the row to be selected?

    if (this.props.editType === MANUAL) {
      this.setState({ editRow: index });
    }
  }

  getVersionOptions(pkgName, version) {
    const { allowedPkgs } = this.state;
    return allowedPkgs[pkgName] && allowedPkgs[pkgName].length > 1
      ? <SonifiTextInline
        select
        items={
          allowedPkgs[pkgName].map((suggestion) => ({
            id: suggestion,
            value: suggestion,
            label: suggestion
          }))
        }
        size="percent"
        noPadding
        defaultValue={version}
        change={this.handleChange}
      />
      : <SonifiLabel label={`${version}${allowedPkgs[pkgName].length > 1 ? '*' : ''}`} />;
  }

  handleChange = ({ target: { value } }) => {
    const currentSelections = this.state.currentSelections;
    currentSelections[this.props.packages[this.state.editRow].name] = value;

    this.setState({
      currentSelections
    }, () => {
      this.props.updateManualRelease(this.state.currentSelections, this.state.templateVersion);
    });
  };

  render() {
    const {
      allowedPkgs, currentSelections, editRow, order, orderBy, gotVirtosPkgs, getRV
    } = this.state;
    const {
      packages, gettingPackages, gettingVirtosPkgs, globalTranslations, translations
    } = this.props;
    const tableHeader = [
      { id: 'status', sortable: false, numeric: false, label: `${globalTranslations.active}` },
      { id: 'name', sortable: packages.length > 1, numeric: false, label: `${translations.packageName}` },
      { id: 'version', sortable: packages.length > 1, numeric: false, label: `${translations.version}` }
    ];

    return <div style={{
      border: 'none', flexGrow: 1, height: '100%', overflowX: 'hidden', width: '100%'
    }}>
      <Table>
        <SonifiTableHead
          headColumns={tableHeader}
          order={order}
          orderBy={orderBy}
          onRequestSort={this.handleRequestSort}
        />
        {gettingVirtosPkgs || (gettingPackages && packages.length === 0) ||
          !gotVirtosPkgs || !getRV ||
          currentSelections === null || allowedPkgs === null
          ? <TableBody>
            <TableRow>
              <TableCell colSpan={3}>
                <SonifiSpinner />
              </TableCell>
            </TableRow>
          </TableBody>
          : <TableBody>
            {packages.map((option, index) => (
              <TableRow key={`w_${index}`} onClick={this.updatePackageVersion.bind(this, index)}>
                <TableCell component="th" scope="row">
                  {!!option.status && <Tooltip title={globalTranslations.active}>
                    <CheckCircleOutline style={{ color: 'green' }} />
                  </Tooltip>}
                </TableCell>
                <TableCell component="th" scope="row">
                  <SonifiLabel label={option.name} />
                </TableCell>
                <TableCell component="th" scope="row">
                  {
                    editRow === index
                      ? this.getVersionOptions(option.name, currentSelections[option.name])
                      : <SonifiLabel
                        label={`${currentSelections[option.name]}
                        ${allowedPkgs[option.name] && allowedPkgs[option.name].length > 1 ? '*' : ''}`} />
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>}
      </Table>
    </div>;
  }
}

const mapStateToProps = (state) => ({
  channels: state.deployments.channels,
  editType: state.deployments.editType,
  gettingPackages: state.deployments.gettingPackages,
  globalTranslations: state.global.translations.defaults,
  packagesOpt: state.releaseTemplates.packagesOpt,
  gettingVirtosPkgs: state.deployments.gettingVirtosPkgs,
  packages: state.deployments.packages,
  selected: state.deployments.selected,
  selectedProductParent: state.deployments.selectedProductParent,
  translations: state.releaseTemplates.translations.editDialog,
  virtosPkgs: state.deployments.virtosPkgs,
});

ParentTable.propTypes = {
  editType: PropTypes.string,
  channels: PropTypes.array,
  dispatch: PropTypes.func,
  gettingPackages: PropTypes.bool,
  globalTranslations: PropTypes.object,
  packagesOpt: PropTypes.array,
  packages: PropTypes.array,
  selected: PropTypes.number,
  gettingVirtosPkgs: PropTypes.bool,
  selectedProductParent: PropTypes.string,
  translations: PropTypes.object,
  updateManualRelease: PropTypes.func,
  virtosPkgs: PropTypes.object
};

export default connect(mapStateToProps)(ParentTable);
