// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

// a little function to help us with reordering the result
export const reorderSequence = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  let int = 0;
  const reOrderedResult = result.map((obj) => {
    int++;
    obj.sequence = int;
    return obj;
  });

  return reOrderedResult;
};

// Reindexs the sequence so we don't run into errors
export const reindexSequence = (list) => {
  const result = Array.from(list);
  let int = 0;
  const reIndexedResult = result.map((obj) => {
    int++;
    obj.sequence = int;
    return obj;
  });

  return reIndexedResult;
};

export const getItemStyle = (isDragging, draggableStyle, index) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 1,

  // margin: '0 0 1px 0',

  // change background colour if dragging
  // background: isDragging ? '#1592d9' : '#f6f6f6',
  color: isDragging ? '#FFF' : '#000000DE',
  background: isDragging ? '#1592d9' : index % 2 === 0 ? '#f6f6f6' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle
});

export const getTermLocItemStyle = (isDragging, draggableStyle, index) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 1,
  // height: '48px',

  // Boarder stype to give table like appearance
  border: '0 solid #dedfdf',
  borderWidth: '0px 0px 1px 0px',

  // margin: '0 0 1px 0',

  // change background colour if dragging
  color: isDragging ? '#FFF' : '#000000DE',
  background: isDragging ? '#1592d9' : index % 2 === 0 ? '#f6f6f6' : '#fff',

  // styles we need to apply on draggables
  ...draggableStyle
});

export const getBuildingListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#dfe0e2' : '#f6f6f6',
  padding: 1,
  height: '100%',
  overflow: 'auto'
});

export const getFloorListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#dfe0e2' : '#f6f6f6',
  padding: 1
});

export const getTerminalStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#dfe0e2' : '#f6f6f6',
  padding: 1,
  maxHeight: '90%',
  overflow: 'auto',
  border: '0 solid #dedfdf',
  borderWidth: '1px 1px 0px 1px',
});


// fake data generator
// const getBuildings = (count) => Array.from({ length: count }, (v, k) => k).map((k) => ({
//   id: `${k}`,
//   name: `Building ${k + 1}`,
//   description: null,
//   open: true,
//   floors: getFloors(3)
// }));

// const getFloors = (count) => Array.from({ length: count }, (v, k) => k).map((k) => ({
//   id: `${k}`,
//   name: `Floor ${k + 1}`,
//   open: true,
//   description: null,
//   units: []
// }));

// const getUnits = (count) => Array.from({ length: count }, (v, k) => k).map((k) => ({
//   id: `${k}`,
//   name: `Unit ${k + 1}`,
//   open: true,
//   description: null
// }));

// const myFloors = [
//   this.createFloor(1),
//   this.createFloor(2),
//   this.createFloor(3),
//   this.createFloor(4),
//   this.createFloor(5),
//   this.createFloor(6),
//   this.createFloor(7),
//   this.createFloor(8),
//   this.createFloor(9),
//   this.createFloor(10),
//   this.createFloor(11),
//   this.createFloor(12),
//   this.createFloor(13),
//   this.createFloor(14),
//   this.createFloor(15),
//   this.createFloor(16)
// ];

// this.setState({
//   buildings: [
//     this.createBuilding(0, 'Gran Destino', myFloors),
//     this.createBuilding(1, 'Casita 1', myFloors),
//     this.createBuilding(2, 'Casita 2', myFloors),
//     this.createBuilding(3, 'Casita 3', myFloors),
//     this.createBuilding(4, 'Casita 4', myFloors),
//     this.createBuilding(5, 'Casita 5', myFloors),
//     this.createBuilding(6, 'Rancho 6A', myFloors),
//     this.createBuilding(7, 'Rancho 6B', myFloors),
//     this.createBuilding(8, 'Rancho 7A', myFloors),
//     this.createBuilding(9, 'Rancho 7B', myFloors),
//     this.createBuilding(10, 'Rancho 8A', myFloors),
//     this.createBuilding(11, 'Rancho 8B', myFloors),
//     this.createBuilding(12, 'Rancho 9A', myFloors),
//     this.createBuilding(13, 'Rancho 9B', myFloors),
//     this.createBuilding(14, 'Convention Center', myFloors),
//     this.createBuilding(15, 'Dig Site', myFloors),
//   ]
// });
// createBuilding(id, name, myFloors) {
//   return {
//     description: null,
//     floors: myFloors,
//     id,
//     name: `${name}`,
//     open: true
//   };
// }

// createFloor(name) {
//   return {
//     description: null,
//     units: [],
//     id: name,
//     name: `${name}`,
//     open: false
//   };
// }
