import { Auth } from 'aws-amplify';
import { setToken } from '../../../utils/index';

export function updatePhone(awsPhone, changeFunc, failureFunc) {
  Auth.currentUserPoolUser().then((data) => {
    Auth.updateUserAttributes(data, {
      phone_number: `${awsPhone}`,
    }).then((data1) => {
      console.log('SUCCESS PHONE NUMBER', data1);
      changeFunc();
    })
      .catch((error) => {
        console.log('FAILURE PHONE NUMBER', error);
        failureFunc(error.message);
      });
  }).catch((error) => {
    console.log('Error', error);
    failureFunc(error.message);
  });
}

export function resendPhone(changeFunc) {
  Auth.verifyCurrentUserAttribute('phone_number')
    .then((data1) => {
      console.log('code resent successfully', data1);
      changeFunc();
    })
    .catch((error) => {
      console.log('error resending code: ', error);
      changeFunc();
    });
}

export function confirmSignUp(resetCode) {
  Auth.currentUserPoolUser().then((data) => {
    console.log(data);
    Auth.confirmSignUp(data.username, resetCode)
      .then(() => {
        console.log('code resent successfully');
      })
      .catch((error) => {
        console.log('error resending code: ', error);
      });
  }).catch((error) => {
    console.log('Error', error);
  });
}

export function phoneVerified(changeFunc, verified = true) {
  Auth.currentUserPoolUser()
    .then((data) => {
      Auth.updateUserAttributes(data, {
        phone_number_verified: verified,
      }).then(() => {
        Auth.currentSession()
          .then((data2) => {
            setToken(data2.idToken.jwtToken);
            changeFunc();
          }).catch((error) => {
            console.log('Error', error);
          });
      })
        .catch((error) => {
          console.log('Error', error);
        });
    }).catch((error) => {
      console.log('Error', error);
    });
}

export function confirmCode(mobileCode, changeFunc, errorFunc) {
  Auth.verifyCurrentUserAttributeSubmit('phone_number', mobileCode)
    .then(() => {
      changeFunc();
    }).catch((error) => {
      errorFunc(error);
    });
}
