import { Grid } from '@mui/material';
import React, { Component } from 'react';
import { SonifiLabel } from '../../../containers/';

export default class None extends Component {
  render() {
    return (
      <Grid item xs={12}>
        <SonifiLabel label="None" />
      </Grid>
    );
  }
}
