import { Add } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { filter } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { TERMINAL_DETAIL_ROLES } from '../../../constants/roleGroups';
import { TERMINAL_DETAIL_EDIT } from '../../../constants/roles';
import SonifiIconButton from '../../../containers/SonifiIconButton';
import SonifiTabs from '../../../containers/SonifiTabs';
import { isProduction } from '../../../utils/api';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../../utils/rolesUtil';
import { addTerminal } from '../actions/terminalActions';
import {
  GROUPS, GUEST_INFO, PURCHASES, TAGS, TERM_INFO
} from '../constants/Tabs';
import TerminalTable from './TerminalTable';

const styles = () => ({
  flexGrow: {
    flexGrow: 1
  },
  centerBtn: {
    display: 'inline-grid',
    alignItems: 'bottom-center'
  }
});

class RoomTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: TERM_INFO
    };

    this.addOnClick = this.addOnClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setState({
      value: (checkForAtLeastOneUserPermission(TERMINAL_DETAIL_ROLES, this.props.userPermissions)
        ? TERM_INFO
        : GUEST_INFO)
    });
  }

  addOnClick() {
    if (this.state.value === TERM_INFO) {
      this.props.dispatch(addTerminal(this.props.menusets));
    }
  }

  handleChange(event, newValue) {
    this.setState({ value: newValue });
  }

  getToolTipMessage() {
    const {
      canAddTerminal, freeTerminals, lineups, locations, menusets, terminals, translations
    } = this.props;

    let addToolTipMsg = '';
    if (!canAddTerminal) {
      addToolTipMsg += `${translations.main.addedMaxUnassigned} \n`;
    }

    if (freeTerminals.length < 1) {
      addToolTipMsg += `${translations.main.noFreeTerm} \n`;
    }

    const filteredLocations = filter(locations, (o) => o.is_active);
    if (filteredLocations.length < 1) {
      addToolTipMsg += `${translations.main.noLocations} \n`;
    } else if (terminals.length >= filteredLocations.length) {
      addToolTipMsg += `${translations.main.noMoreLocations} \n`;
    }

    if (lineups.length < 1) {
      addToolTipMsg += `${translations.main.noLineups} \n`;
    }

    if (menusets.length < 1) {
      addToolTipMsg += `${translations.main.noMenusets} \n`;
    }
    return addToolTipMsg;
  }

  getTabsArray() {
    const { isProd, translations, userPermissions } = this.props;
    const tabsArray = [];
    if (checkForAtLeastOneUserPermission(TERMINAL_DETAIL_ROLES, userPermissions)) {
      tabsArray.push({ id: TERM_INFO, title: translations.editDialog.termInfo });
    }

    if (!isProduction(isProd)) {
      tabsArray.push({ id: GUEST_INFO, title: translations.editDialog.guestInfo });
      tabsArray.push({ id: PURCHASES, title: translations.editDialog.purchases });
      tabsArray.push({ id: GROUPS, title: translations.editDialog.groups });
      tabsArray.push({ id: TAGS, title: translations.editDialog.tags });
    }
    return tabsArray;
  }

  getTabContainer() {
    const { translations } = this.props;
    switch (this.state.value) {
      case TERM_INFO:
        return <TerminalTable />;
      case GUEST_INFO:
        return <Fragment>{translations.tabs.guestInfo}</Fragment>;
      case PURCHASES:
        return <Fragment>{translations.tabs.purchase}</Fragment>;
      case TAGS:
        return <Fragment>{translations.tabs.tag}</Fragment>;
      default:
        return <Fragment />;
    }
  }

  getExtraButton() {
    const {
      classes, canAddTerminal, freeTerminals, lineups, locations, menusets, terminals, translations, userPermissions
    } = this.props;
    const { value } = this.state;

    const filteredLocations = filter(locations, (o) => o.is_active);

    const disableAddBtn = (freeTerminals.length < 1 ||
      filteredLocations.length < 1 || terminals.length >= filteredLocations.length ||
      lineups.length < 1 ||
      menusets.length < 1 ||
      !canAddTerminal);

    let addToolTipMsg = translations.main.addATerminal;
    if (disableAddBtn) {
      addToolTipMsg = this.getToolTipMessage();
    }

    if (value === TERM_INFO && checkForSingleUserPermission(TERMINAL_DETAIL_EDIT, userPermissions)) {
      return <Fragment><div className={classes.flexGrow}></div>
        <Tooltip title={addToolTipMsg}>
          <div className={classes.rightSide} >
            <SonifiIconButton
              label={translations.main.terminal}
              icon={<Add />}
              disabled={disableAddBtn}
              onClick={this.addOnClick}
              extraStyles={{ display: 'flex', float: 'right' }} />
          </div>
        </Tooltip>
      </Fragment>;
    }
    return null;
  }

  render() {
    const { value } = this.state;

    return (<SonifiTabs
      extraButton={this.getExtraButton()}
      handleChange={this.handleChange}
      showingTab={this.getTabContainer()}
      tabs={this.getTabsArray()}
      tabValue={value}
    />
    );
  }
}

const mapStateToProps = (state) => ({
  canAddTerminal: state.terminals.canAddTerminal,
  freeTerminals: state.terminals.freeTerminals,
  isProd: state.global.isProd,
  lineups: state.terminals.lineups,
  locations: state.terminals.locations,
  menusets: state.termGrid.menusets,
  terminals: state.terminals.terminals,
  translations: state.rooms.translations,
  userPermissions: state.global.permissions
});

RoomTabs.propTypes = {
  canAddTerminal: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  freeTerminals: PropTypes.array.isRequired,
  isProd: PropTypes.bool,
  lineups: PropTypes.array,
  locations: PropTypes.array,
  menusets: PropTypes.array,
  terminals: PropTypes.array.isRequired,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(RoomTabs, styles, { withTheme: true }));
