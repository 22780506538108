import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiIconButton } from '../../../../containers';
import * as actions from '../../actions/channelsActions';
import { MAX_CHANNEL_NUMBER, MAX_NUM_CHANNELS } from '../../constants/constants';
import { emptyChannel } from '../../dataObjects/channelObjects';

class ChannelButtons extends Component {
  handleAddChannel = () => {
    const { dispatch, realChannels } = this.props;
    let newChannelNumber = 1;
    newChannelNumber = realChannels.length > 0
      ? realChannels[realChannels.length - 1].id + 1
      : 1;
    newChannelNumber = newChannelNumber > MAX_CHANNEL_NUMBER
      ? this.getNextChannel()
      : newChannelNumber;
    dispatch(actions.updateSelectedChannel({ ...emptyChannel, isNew: true, id: newChannelNumber }));
  };

  getNextChannel = () => {
    const { realChannels } = this.props;
    let prevChan = realChannels[0].id;
    for (let i = 1; i < realChannels.length; i++) {
      if ((prevChan + 1) !== realChannels[i].id) {
        return prevChan + 1;
      } else {
        prevChan = realChannels[i].id;
      }
    }
  };

  render() {
    const {
      draftExists, firstLoad, gettingChannelSet, lineupsLoading, loading, realChannels, translations
    } = this.props;
    const updateInProgress = (lineupsLoading || loading || firstLoad || gettingChannelSet);
    return (
      <Grid container>
        <Grid item >
          <SonifiIconButton label={translations.channel}
            onClick={this.handleAddChannel.bind(this)}
            icon={<Add />}
            toolTipMessage={draftExists ? 'Draft Exists' : `${translations.maxChannels} (${realChannels.length})`}
            showToolTip={realChannels.length >= MAX_NUM_CHANNELS || draftExists}
            disabled={realChannels.length >= MAX_NUM_CHANNELS || updateInProgress || draftExists} />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  firstLoad: state.channels.firstLoad,
  lineupsLoading: state.tvLineup.lineupsLoading,
  gettingChannelSet: state.channels.gettingChannelSet,
  loading: state.channels.loading,
  realChannels: state.channels.realChannels,
  translations: state.channels.translations.buttonPanel,
  userPermissions: state.global.permissions
});

ChannelButtons.propTypes = {
  dispatch: PropTypes.func,
  gettingChannelSet: PropTypes.bool,
  draftExists: PropTypes.bool,
  firstLoad: PropTypes.bool,
  lineupsLoading: PropTypes.bool,
  loading: PropTypes.bool,
  realChannels: PropTypes.array,
  saveCheck: PropTypes.func,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(ChannelButtons);
