/* eslint-disable max-lines */
import { createTheme } from '@mui/material/styles';

export const colors = {
  primary: {
    border: '#d6d6d6',
    cancelBtn: '#525252',
    contrastText: '#ffffff',
    dark: '#333333',
    disabled: '#959595',
    hoverColor: '#0e6393',
    light: '#dfe0e2',
    main: '#2e2e2e'
  },
  secondary: {
    main: '#1592d9',
    light: '#dddddd',
    dark: '#2e2e2e',
    contrastText: '#000000'
  },
  tertiary: {
    main: '#FFF'
  },
  defaults: {
    defaultBackground: '#f6f6f6',
    disabledBackground: '#ffffff',
    inputHover: '#e9e9e9',
    green: 'green',
    red: 'red'
  }
};

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Open Sans',
      fontSize: '12pt',
      fontWeight: 400,
      letterSpacing: 'normal',
      textTransform: 'none'
    }
  },
  shadows: Array(25).fill('none'),
  shape: { borderRadius: '0px' },
  palette: colors,
  components: {
    // css: {
    //   root: {
    //     borderRadius: '0',
    //     borderStyle: 'none'
    //   }
    // },

    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            'color': colors.primary.hoverColor,
            '&:hover': {
              color: colors.primary.hoverColor
            },
            '&:focus': {
              color: colors.primary.hoverColor
            }
          },
          '&:hover': {
            color: 'inherit'
          }
        },
        icon: {
          height: 30,
          width: 30,
          color: `${colors.primary.hoverColor} !important`
        }
      }
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.MuiFilledInput-root.Mui-disabled': {
            ':before': {
              borderBottomStyle: 'solid',
              backgroundColor: 'transparent'
            },
            'backgroundColor': 'transparent'
          },
          '&.MuiFilledInput-root.Mui-focused': {
            backgroundColor: colors.defaults.inputHover
          },
          '&.MuiFilledInput-root': {
            marginRight: 0
          }
        },
        input: {
          '&.MuiFilledInput-input.MuiSelect-select': {
            backgroundColor: 'inherit'
          },
          '&.MuiFilledInput-input.Mui-disabled': {
            backgroundColor: 'transparent'
          }
        }
      }
    },

    // MuiPickersBasePicker: {
    //   styleOverrides: {
    //     pickerView: {
    //       maxWidth: 'none'
    //     }
    //   }
    // },
    MuiSnackbarContent: {
      styleOverrides: {
        message: {
          fontSize: '14px'
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          'backgroundColor': colors.defaults.defaultBackground,
          'marginRight': '12px',
          '&:hover': {
            backgroundColor: colors.defaults.inputHover
          },

          //       fontWeight: 500,
          //       paddingTop: 'unset',
          //       marginTop: '19px'
        },

        underline: {
          '&$disabled:before, &$disabled:hover:before': {
            borderBottom: `1px solid ${colors.primary.main}`,
          },
          '&:before': {
            borderBottom: `1px solid ${colors.primary.main}`,
          },
          '&:after': {
            borderBottom: `1px solid ${colors.secondary.main}`
          },
        },

        input: {
          // 'backgroundColor': colors.defaults.defaultBackground,
          '&:hover': {
            backgroundColor: colors.defaults.inputHover
          },
          '&:focus': {
            backgroundColor: colors.defaults.inputHover
          },
          '&.Mui-disabled': {
            color: colors.primary.dark,
            backgroundColor: colors.defaults.disabledBackground,
            WebkitTextFillColor: 'unset'
          }
        },

        //     //     multiline: {
        //     //       'backgroundColor': colors.defaults.defaultBackground,
        //     //       '&:hover': {
        //     //         backgroundColor: colors.defaults.inputHover
        //     //       },
        //     //       '&$disabled': {
        //     //         color: colors.primary.dark,
        //     //         backgroundColor: colors.defaults.disabledBackground
        //     //       }
        //     //     },
        //     //     adornedStart: {
        //     //       'backgroundColor': colors.defaults.defaultBackground,
        //     //       '&:hover:not($disabled)': {
        //     //         'backgroundColor': colors.defaults.inputHover,
        //     //         '& $input': {
        //     //           backgroundColor: colors.defaults.inputHover
        //     //         }
        //     //       },
        //     //       '&$disabled': {
        //     //         'color': colors.primary.dark,
        //     //         'backgroundColor': colors.defaults.disabledBackground,
        //     //         '& $input': {
        //     //           backgroundColor: colors.defaults.disabledBackground
        //     //         }
        //     //       }
        //     //     },
        //     //     adornedEnd: {
        //     //       'backgroundColor': colors.defaults.defaultBackground,
        //     //       '&:hover:not($disabled)': {
        //     //         'backgroundColor': colors.defaults.inputHover,
        //     //         '& $input': {
        //     //           backgroundColor: colors.defaults.inputHover
        //     //         }
        //     //       },
        //     //       '&$disabled': {
        //     //         'color': colors.primary.dark,
        //     //         'backgroundColor': 'transparent',
        //     //         '& $input': {
        //     //           backgroundColor: 'transparent'
        //     //         }
        //     //       }
        //     //     }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: { fontSize: '24px' },
        fontSizeSmall: { fontSize: '20px' },
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: 'inherit'
        }
      }
    },

    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: `${colors.primary.light} !important`
        },
        input: {
          padding: '12px 13px 7px'
        },
        //     multiline: {
        //       padding: '12px 13px 7px'
        //     }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          'color': 'unset',
          '&.Mui-disabled': {
            color: 'unset'
          },
          '&.Mui-focused': {
            color: colors.secondary.main,
          }
        }
      }

      //     outlined: {
      //       transform: 'translate(10px, 14px)',
      //       color: colors.primary.main
      //     },
      //     filled: {
      //       '&$disabled': {
      //         color: colors.primary.dark,
      //       }
      //     }
      //   }
    },

    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&.MuiPickersDay-root.Mui-selected': {
            backgroundColor: `${colors.secondary.main} !important`
          }
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: colors.secondary.main,
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: colors.secondary.main
          },
          '&.Mui-checked.Mui-disabled': {
            color: colors.secondary.main,
            opacity: 0.7
          },
        },
        thumb: {
          boxShadow:
            'rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px'
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.secondary.main,
            color: '#FFF'
          },
          '&:hover': {
            backgroundColor: colors.primary.hoverColor,
            color: colors.primary.contrastText

            // },
            // '&:not(.Mui-selected)': {
            //   border: `1px solid ${colors.secondary.main}`,
          },
          '&:focus': {
            backgroundColor: colors.secondary.main,
            color: '#FFF'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          'fontWeight': 900,
          '&:hover': {
            backgroundColor: colors.secondary.main,
            color: colors.primary.contrastText
          }
        },

        //     textPrimary: {
        //       'color': '#fff',
        //       '&:hover': {
        //         'background-color': colors.secondary.main,
        //         'color': colors.primary.contrastText
        //       }
        //     },
        //     textSecondary: {
        //       '&:hover': {
        //         'background-color': colors.secondary.main,
        //         'color': colors.primary.contrastText
        //       }
        //     }
      }
    },

    // MuiFlatPageButton: {
    //   styleOverrides: {
    //     rootStandard: {
    //       color: colors.primary.main
    //     }
    //   }
    // },
    // MuiFormLabel: {
    //   styleOverrides: {
    //     root: {
    //       'fontFamily': 'Open Sans',
    //       'fontWeight': 400,
    //       'fontSize': '12pt',
    //       'color': colors.primary.main,
    //       '&.Mui-focused': {
    //         color: colors.secondary.main,
    //       },
    //       '&$error': {
    //         color: '#f44336'
    //       }
    //     }
    //   }
    // },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '12px !important',

          //       overflowY: 'auto'
        }
      }
    },

    MuiDialog: {
      styleOverrides: {
        paperWidthLg: {
          height: '100%'
        },

        //     paperScrollPaper: {
        //       maxHeight: 'calc(100% - 94px)',
        //     },
        paperWidthMd: {
          maxWidth: '965px'
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          paddingTop: '2px',
          paddingBottom: '2px'
        },

        //     gutters: {
        //       paddingLeft: '25px'
        //     },
        button: {
          '&:hover': {
            //         'textDecoration': 'none',
            backgroundColor: colors.primary.hoverColor,

            //         // Reset on touch devices, it doesn't add specificity
            //         '@media (hover: none)': {
            //           backgroundColor: 'transparent',
            //         },
          },

          //       '&:focus': {
          //         backgroundColor: colors.primary.hoverColor,
          //       }
        },
      }
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: '24px 24px 0px'
        }
      }
    },

    // MuiPickersDay: {
    //   styleOverrides: {
    //     daySelected: {
    //       'backgroundColor': '#1592d9',
    //       '&:hover': {
    //         backgroundColor: '#1592d9',
    //       },
    //     }
    //   }
    // },
    // MuiPickersToolbar: {
    //   styleOverrides: {
    //     toolbar: {
    //       backgroundColor: '#1592d9'
    //     }
    //   }
    // },
    // MuiPickersClockPointer: {
    //   styleOverrides: {
    //     thumb: {
    //       border: '14px solid #1592d9'
    //     }
    //   }
    // },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: `${colors.secondary.main} !important`,
            color: '#FFF',
          },
          '&:hover': {
            backgroundColor: `${colors.primary.hoverColor} !important`,
            color: '#FFF'
          },

          //       '&$selected': { // <-- mixing the two classes
          //         'backgroundColor': 'transparent',
          //         '&:hover': {
          //           backgroundColor: colors.primary.hoverColor,
          //           color: '#fff'
          //         },
          //         '&:focus': {
          //           backgroundColor: colors.secondary.main,
          //           color: '#fff'
          //         },
          //       },
          //       '&:hover': {
          //         backgroundColor: colors.primary.hoverColor,
          //         color: '#fff'
          //       },
          //       '&:focus': {
          //         backgroundColor: colors.secondary.main,
          //         color: '#fff'
          //       },
        }
      }
    },

    MuiSelect: {
      styleOverrides: {
        select: {
          backgroundColor: '#FFF'
        },
      }
    },
    // MuiNativeSelect: {
    //   styleOverrides: {
    //     select: {
    //       padding: 0,
    //       paddingRight: 0,
    //       width: '100%'
    //     },
    //   }
    // },
    MuiTypography: {
      styleOverrides: {
        //     h6: { fontSize: '20pt' },
        root: {
          letterSpacing: 'normal'

          //       textTransform: 'none'
        },

        subtitle1: {
          //       color: 'none',
          //       fontFamily: 'Open Sans',
          //       fontWeight: 400,
          fontSize: '20pt'
        },
        //     body1: {
        //       fontFamily: 'Open Sans',
        //       fontWeight: 500,
        //       fontSize: '12pt',
        //     },
        //     body2: {
        //       color: 'none',
        //       fontFamily: 'Open Sans',
        //       fontWeight: 400,
        //       fontSize: '12pt',
        //       paddingTop: '3px'
        //     }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          'color': colors.secondary.main,
          '&.Mui-checked': {
            color: colors.secondary.main,
          },
          '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)'
          }
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          border: `1px solid ${colors.primary.border}`
        },
        root: {
          '&.MuiPopover-paper': {
            border: 'none',
            boxShadow:
              '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)'
          }
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          //       borderCollapse: 'separate',
          border: '1px solid #dedfdf',
          borderTop: 'none',

          //       color: colors.primary.main
        }
      }
    },

    // MuiGrid: {
    //   styleOverrides: {
    //     root: {
    //       paddingTop: '0',
    //       item: {
    //         paddingTop: '0'
    //       }
    //     }
    //   }
    // },
    MuiTableRow: {
      styleOverrides: {
        root: {
          'cursor': 'pointer',
          'height': '48px',

          '&:nth-of-type(odd)': {
            backgroundColor: colors.defaults.defaultBackground,
          },
          '&:nth-of-type(even)': {
            backgroundColor: '#FFF',
          },
          'width': '100%',
          'fontFamily': 'Open Sans',
          'fontWeight': 400,
          '&:hover': {
            backgroundColor: `${colors.primary.hoverColor} !important`,
            color: '#FFF'
          },

          'color': colors.primary.main
        },

        //     footer: {
        //       backgroundColor: '#fff !important'
        //     }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        marginNormal: {
          marginTop: '0px'
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          'padding': '0px 5px 0px 24px',
          'color': 'inherit',
          '&[aria-sort]': {
            borderBottom: `3px solid ${colors.secondary.main}`
          }
        }
      }
    },

    // MUIDataTable: {
    //   styleOverrides: {
    //     root: {
    //     },
    //     tableRoot: {
    //       border: '1px solid #e0e0e0',
    //       width: '96%',
    //       margin: '0 24px 24px'
    //     },
    //     paper: {
    //       border: '1px solid #c6c4c4'
    //     },
    //     responsiveScrollMaxHeight: {
    //       minHeight: '65vh',
    //       maxHeight: '65vh'
    //     }
    //   }
    // },
    // MuiTableFooter: {
    //   styleOverrides: {
    //     root: {
    //       border: 'hidden',
    //       borderTopStyle: 'solid',
    //       borderWidth: '1px',
    //       borderColor: '#dedfdf'
    //     }
    //   }
    // },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFF',
          border: `1px solid ${colors.secondary.main}`,
          color: colors.secondary.main,
          fontSize: '12pt',
          whiteSpace: 'break-spaces'
        },

        // popper: {
        //   whiteSpace: 'pre-line'
        // }
      }
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          backgroundColor: colors.secondary.main
        }
      }
    },

    // MUIDataTableToolbar: {
    //   styleOverrides: {
    //     titleText: {
    //       color: '#8e8e8e',
    //       fontSize: '14pt',
    //       fontFamily: 'Open Sans',
    //       fontWeight: 900,
    //       letterSpacing: '2px'
    //     }
    //   }
    // },
    // MUIDataTableHeadCell: {
    //   styleOverrides: {
    //     data: {
    //       fontFamily: 'Open Sans',
    //       fontWeight: 400
    //     },
    //     fixedHeader: {
    //       top: '-1px'
    //     },
    //     sortActive: {
    //       color: colors.primary.hoverColor
    //     }
    //   }
    // },
    // MUIDataTableBodyRow: {
    //   styleOverrides: {
    //     root: {
    //       '&:nth-of-type(odd)': {
    //         backgroundColor: colors.defaults.defaultBackground
    //       }
    //     }
    //   }
    // },
    // MuiTablePagination: {
    //   styleOverrides: {
    //     select: {
    //       height: '100%',
    //       minHeight: '100%',
    //       paddingRight: '25px'
    //     },
    //     caption: {
    //       padding: '10px'
    //     },
    //     selectRoot: {
    //       marginLeft: '0',
    //       marginRight: '0'
    //     }
    //   }
    // },
    // MuiAppBar: {
    //   styleOverrides: {
    //     positionFixed: {
    //       top: 'none',
    //       right: 'none'
    //     }
    //   }
    // },
    // MuiDrawer: {
    //   styleOverrides: {
    //     root: {
    //       fontFamily: 'Open Sans',
    //       fontWeight: 500,
    //       fontSize: '12pt',
    //       color: colors.secondary.contrastText,
    //     },
    //     paper: { top: 'none' }
    //   }
    // },
    // MuiToolbar: {
    //   styleOverrides: {
    //     regular: {
    //       'minHeight': '0',
    //       '@media (min-width: 600px)': {
    //         minHeight: '48px'
    //       }
    //     }
    //   }
    // },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: colors.primary.main,
          color: colors.primary.contrastText
        },

        // fixed: { overflowX: 'unset' },
        indicator: {
          backgroundColor: colors.secondary.main,
          height: '10%'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          'color': '#EEEEEE',
          'fontFamily': 'Open Sans',
          'fontWeight': 900,
          'fontSize': '12pt',
          'textTransform': 'none',
          'padding': '1px',
          '@media (min-width: 600px)': {
            fontSize: '12pt',
            minWidth: '200px',
            maxWidth: '200px',
            lineHeight: '19px',
          },
          '&:hover': {
            backgroundColor: colors.primary.hoverColor,
            color: '#FFF'
          },
          '&.Mui-selected': {
            backgroundColor: 'rgba(14, 99, 147, .3)', // colors.primary.hoverColor
            color: '#FFF'
          }
        },
        textColorInherit: { opacity: 1 },
      }
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          'color': colors.secondary.main,
          '&.Mui-checked': {
            color: colors.secondary.main
          }
        },

        //     colorSecondary: {
        //       '&$checked': {
        //         color: 'none'
        //       }
        //     }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          'color': 'inherit',
          '&:disabled': {
            color: '#8e8e8e'
          }
        }
      }
    },

    // MuiDivider: {
    //   styleOverrides: {
    //     root: {
    //       width: '100%',
    //       marginTop: '0 !important',
    //       marginBottom: '0 !important'
    //     }
    //   }
    // },

    MuiChip: {
      styleOverrides: {
        colorPrimary: {
          // backgroundColor: '#1592d9 !important'
          backgroundColor: colors.secondary.main
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '.75rem',
          marginLeft: 0
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          'fontWeight': 900,
          '&.Mui-selected': {
            'backgroundColor': 'transparent',
            'color': colors.secondary.main,
            '&:hover': {
              backgroundColor: colors.secondary.main,
              color: '#FFF'
            },
          },
          '&:hover': {
            backgroundColor: colors.primary.hoverColor,
            color: '#FFF'
          },
        },
        circular: {
          backgroundColor: 'transparent'
        },
        ellipsis: {
          'color': colors.primary.disabled,
          '&:hover': {
            backgroundColor: 'transparent',
            color: colors.primary.disabled
          },
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.Mui-active': {
            color: colors.secondary.main,
            fontWeight: 900
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          '&.Mui-active': {
            color: colors.secondary.main,
          }
        }
      }
    }
  }
});
