import { Add, Search } from '@mui/icons-material';
import {
  Grid, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Waypoint } from 'react-waypoint';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { DISTANCE_FROM_END } from '../../../constants/magic';
import { FIRMWARE_ROLES } from '../../../constants/roleGroups';
import { FIRMWARE_EDIT } from '../../../constants/roles';
import {
  SonifiConfirm, SonifiLabel, SonifiLockoutModalSpinner, SonifiSnackBar,
  SonifiSpinner, SonifiTableHead, SonifiText
} from '../../../containers';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { buildSortString } from '../../../utils';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../../utils/rolesUtil';
import {
  addTermModel, clearModels, deleteTermModelById, fetchModels, resetSnackBar, startModels, updateDeleteRow,
  updateSelectedTermModel
} from '../actions/termModelActions';
import TermModelRow from '../containers/TermModelRow';
import TermModelDialog from './TermModelDialog';

class TermModelGrid extends Component {
  state = {
    filter: '',
    order: 'asc',
    orderBy: 'id',
    page: 1
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(startModels());
    setTimeout(() => {
      dispatch(fetchModels(buildSortString(this.state)));
    }, 200);
  }

  handleRequestSort = (property) => {
    this.props.dispatch(updateSelectedTermModel(-1));
    this.props.dispatch(clearModels());
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      filter: '',
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    });
  };

  reset() {
    this.setState({ page: 1 });
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(fetchModels(buildSortString(this.state)));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(fetchModels(buildSortString(this.state)));
    }
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  confirmDialogConfirmFunc() {
    const { dispatch, deleteTermModel } = this.props;
    this.setState({
      page: 1,
      filter: ''
    }, () => {
      dispatch(deleteTermModelById(deleteTermModel.id, buildSortString(this.state)));
    });
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteRow(null));
  }

  addTermModel() {
    this.props.dispatch(addTermModel());
  }

  closeSnackBar() {
    this.props.dispatch(resetSnackBar());
  }

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  filterFirmware = ({ target: { value } }) => {
    this.setState({ filter: value }, () => {
      this.debouncedLoadMoreData();
    });
  };

  filterData() {
    this.setState({
      page: 1
    }, () => {
      this.props.dispatch(clearModels());
      this.props.dispatch(fetchModels(buildSortString(this.state)));
    });
  }

  getPageDetails() {
    const { filter, order, orderBy, page } = this.state;
    const {
      globalTranslations, loading, termModelError, maxPages, models,
      sorting, translations, userPermissions
    } = this.props;

    if (loading) {
      return <SonifiSpinner />;
    }

    const canEdit = checkForSingleUserPermission(FIRMWARE_EDIT, userPermissions);
    const tableHeader = [
      {
        id: 'active', sortable: models.length > 1, numeric: false, label: `${translations.active}`, narrow: true
      },
      { id: 'id', sortable: models.length > 1, numeric: false, label: `${translations.id}` },
      { id: 'make', sortable: models.length > 1, numeric: false, label: `${translations.make}` }
    ];

    return (<Grid container style={{ justifyContent: 'flex-end' }}>
      <Grid item>
        <SonifiText
          label={globalTranslations.search}
          defaultValue={filter}
          change={this.filterFirmware}
          icon={<Search />}
          iconPosition="end"
          size="mdNoPad"
        />
      </Grid>
      <Grid item xs={12}>
        <Table stickyHeader={true}>
          <SonifiTableHead
            headColumns={tableHeader}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />
          {sorting
            ? <TableBody>
              <TableRow>
                <TableCell colSpan={3}> <SonifiSpinner /> </TableCell>
              </TableRow>
            </TableBody>
            : <TableBody>
              {(!models || models.length === 0)
                ? <TableRow>
                  <TableCell colSpan={3}>
                    <SonifiLabel error label={translations.errors.noModels} />
                  </TableCell>
                </TableRow>
                : models.map((option, index) => (
                  <Fragment key={`w_${index}`}>
                    <TermModelRow key={index} rowIndex={index}
                      deleteEnabled={canEdit && !termModelError && !loading} canEdit={canEdit} />
                    {index === models.length - DISTANCE_FROM_END && page < maxPages && (
                      <TableRow>
                        <TableCell>
                          <Waypoint onEnter={() => { this.getMoreData(); }} />
                        </TableCell>
                      </TableRow>)
                    }
                  </Fragment>
                ))}
            </TableBody>}
        </Table>
      </Grid>
    </Grid>);
  }

  render() {
    const {
      deleteTermModel, globalTranslations, loading, popupLoading, termModelError,
      saving, selectedTermModel, snackBarMessage, snackBarType, translations, userPermissions
    } = this.props;

    const canEdit = checkForSingleUserPermission(FIRMWARE_EDIT, userPermissions);

    if (!checkForAtLeastOneUserPermission(FIRMWARE_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={popupLoading || saving} />
        <SonifiConfirm
          dialogOpen={deleteTermModel !== null && deleteTermModel !== undefined && !popupLoading}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          confirmTitle={translations.deleteDialog.deleteTitle}
          confirmText={`${translations.deleteDialog.deleteText} ${(deleteTermModel ? deleteTermModel.id : '')}?`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
        <SonifiTemplate
          header={translations.title}
          onSubmit={this.addTermModel.bind(this)}
          showButton={canEdit && !termModelError && !loading}
          label={globalTranslations.add}
          icon={<Add />}
          pageDetails={this.getPageDetails()}
        />
        {selectedTermModel !== -1 &&
          <TermModelDialog canEdit={canEdit} reset={this.reset.bind(this)}
            sort={`${this.state.orderBy}:${this.state.order}`} />
        }
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  deleteTermModel: state.termModel.deleteTermModel,
  globalTranslations: state.global.translations.defaults,
  loading: state.termModel.loading,
  maxPages: state.termModel.maxPages,
  models: state.termModel.models,
  popupLoading: state.termModel.popupLoading,
  saving: state.termModel.saving,
  selectedTermModel: state.termModel.selectedTermModel,
  snackBarMessage: state.termModel.snackBarMessage,
  snackBarType: state.termModel.snackBarType,
  sorting: state.termModel.sorting,
  termModelError: state.termModel.termModelError,
  translations: state.termModel.translations.grid,
  userPermissions: state.global.permissions
});

TermModelGrid.propTypes = {
  deleteTermModel: PropTypes.object,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  maxPages: PropTypes.number,
  models: PropTypes.array,
  popupLoading: PropTypes.bool,
  saving: PropTypes.bool,
  selectedTermModel: PropTypes.number,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  sorting: PropTypes.bool,
  termModelError: PropTypes.bool,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(TermModelGrid);
