import { RotateLeft } from '@mui/icons-material';
import { Dialog, DialogContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { READ } from '../../../constants/constants';
import { SonifiText } from '../../../containers';
import SonifiIconLabel from '../../../containers/SonifiIconLabel';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import { dateToString } from '../../../utils';
import * as actions from '../actions/batchChannelsAction';
import ReplacementTable from './ViewBatch/ReplacementTable';
import ResultsTable from './ViewBatch/ResultsTable';

export class ViewBatchDialog extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(actions.viewChannelChangeSuccess(null));
    }
  }

  getText(defaultValue, name, label) {
    return (
      <Grid item xs={3}>
        <SonifiText
          defaultValue={defaultValue}
          disabled={true}
          label={label}
          size="percent"
        />
      </Grid>
    );
  }

  render() {
    const {
      dialogType, globalTranslations, selectedChannel, timezone, translations
    } = this.props;
    const {
      created, replacements, results, status, target
    } = selectedChannel;

    const readOnly = dialogType === READ;

    return (
      <Dialog open={true} onClose={this.onCancel} fullWidth maxWidth="md">
        <SonifiModalHeader
          header={translations.viewTitle}
          label={globalTranslations.defaults.save}
          onCancel={this.onCancel}
          onlyClose={true}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <SonifiIconLabel blue
                    icon={<RotateLeft />}
                    labelTwo={status} />
                </Grid>
                {this.getText(dateToString(created, timezone), 'created', translations.created, readOnly)}
                {this.getText(target?.name, 'name', translations.name, readOnly)}
                {this.getText(target?.source_id, 'sourceID', translations.sourceID, readOnly)}
                {this.getText(target?.timezone, 'timezone', translations.tz, readOnly)}
              </Grid>
            </Grid>
            {replacements && <Grid item xs={12} style={{ height: '100%' }}>
              <ReplacementTable replacements={replacements} translations={translations} />
            </Grid>}

            {results && <Grid item xs={12} style={{ height: '100%' }}>
              <ResultsTable results={results} translations={translations.results} />
            </Grid>}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  dialogType: state.batchChannels.dialogType,
  globalTranslations: state.global.translations,
  selectedChannel: state.batchChannels.selectedChannel,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
  translations: state.batchChannels.translations.grid
});

ViewBatchDialog.propTypes = {
  dialogType: PropTypes.string,
  dispatch: PropTypes.func,
  gettingInfo: PropTypes.bool,
  globalTranslations: PropTypes.object,
  selectedChannel: PropTypes.object,
  timezone: PropTypes.string.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(ViewBatchDialog);
