import {
  Alert, Button, Grid, PasswordField, Text, View
} from '@aws-amplify/ui-react';
import { Auth, I18n } from 'aws-amplify';
import decode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import * as actions from '../../../actions/globalActions';
import '../../../AmplifyTheme.css';
import { setToken } from '../../../utils';
import FormFrame from './FormFrame';
import { KEYCODE_ENTER } from '../../../constants/keyCodes';

const formFields = {
  forcePasswordChange: {
    password: {
      labelHidden: false,
      placeholder: 'Enter your new password',
      isRequired: true,
      label: 'New Password'
    },
    confirm_password: {
      labelHidden: false,
      placeholder: 'Confirm your new password',
      isRequired: true,
      label: 'Confirm Password'
    }
  }
};

const NewPasswordRequired = (props) => {
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordVerify, setNewPasswordVerify] = useState('');
  
    const passwordChangeSubmitHandler = async () => {
      try {
        const user = await Auth.completeNewPassword(
          props.user, // the Cognito User Object
          newPassword
        );

        const session = user.getSignInUserSession();
        setToken(session.idToken.jwtToken);
        props.dispatch(actions.updateCurrentUser(decode(session.idToken.jwtToken)));

        props.setUser(user);
      } catch(error) {
        console.log(`${error}`);
      }
    };

  const handleNewPasswordEnter = (e) => {
    if (e.keyCode === KEYCODE_ENTER) {
      passwordChangeSubmitHandler();
    }
  };

  const handleNewPasswordVerifyEnter = (e) => {
    if (e.keyCode === KEYCODE_ENTER) {
      passwordChangeSubmitHandler();
    }
  };
  
    return (
      <FormFrame>
        <View as="div" id="force-password-change">
          <Fragment>
            <PasswordField
              {...formFields.forcePasswordChange.password}
              onChange={(event) => setNewPassword(event.target.value)}
              onKeyDown={handleNewPasswordEnter}
              data-testid="force-password-field"
            />
            <PasswordField
              {...formFields.forcePasswordChange.confirm_password}
              onChange={(event) => setNewPasswordVerify(event.target.value)}
              hasError={newPassword !== newPasswordVerify}
              errorMessage="Your passwords should match"
              onKeyDown={handleNewPasswordVerifyEnter}
              data-testid="force-password-verify-field"
            />
            <Grid container style={{ marginTop: '1vh' }}>
              <Grid item>
                <Text style={{ fontStyle: 'italic', fontSize: '0.75rem' }} >Must be between 10-64 characters</Text>
              </Grid>
              <Grid item>
                <Text style={{ fontStyle: 'italic', fontSize: '0.75rem' }}>
                  Must contain mix of letters and numbers</Text>
              </Grid>
              <Grid item>
                <Text style={{ fontStyle: 'italic', fontSize: '0.75rem' }}
                >Need to include a non-alphanumeric character</Text>
              </Grid>
            </Grid>
            {props.initPasswordChangeError &&
              <Alert
                variation="error"
                isDismissible={false}
                hasIcon={true}
              >{props.initPasswordChangeError}</Alert>
            }
            <Button
              variation="primary"
              isFullWidth
              onClick={passwordChangeSubmitHandler}
              type="button"
            >{I18n.get('passwordResetSubmit')}</Button>
            <Button
              variation="link"
              isFullWidth
              onClick={() => props.setAuthState('login')}
            >
              {I18n.get('passwordResetBack')}
            </Button>
          </Fragment>
        </View>
      </FormFrame>
    );
  };

NewPasswordRequired.propTypes = {
  dispatch: PropTypes.func,
  setAuthState: PropTypes.func,
  initPasswordChangeError: PropTypes.string,
  user: PropTypes.object,
  setUser: PropTypes.func
};

  export { NewPasswordRequired };
  export default NewPasswordRequired;
