/* eslint-disable camelcase */
import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { SONIFI_ADMIN } from '../../../../constants/roles';
import SonifiSection from '../../../../containers/SonifiSection';
import SonifiSpinner from '../../../../containers/SonifiSpinner';
import SonifiText from '../../../../containers/SonifiText';
import SonifiTimePicker from '../../../../containers/SonifiTimePicker';
import { checkForSingleUserPermission } from '../../../../utils/rolesUtil';
import { saveTerminalOptions, updateMaintenanceWindow } from '../../actions/FirmwareActions';
import { HOURS } from '../../constants/TermFileConstants';

const styles = () => ({
  popoverTransition: {
     transform: 'translate(342px, 303px) !important'
  }
});


class MaintenanceWindow extends Component {
  debouncedAutoUpdate = debounce(this.saveMaintWindow, 1000);

  handleDateChange = (name) => (value) => {
    const { dispatch } = this.props;
    dispatch(updateMaintenanceWindow(name, (value === null) ? null : moment.utc(value).format('MM/DD/YYYY h:mm a')));
    this.debouncedAutoUpdate();
  };

  handleDuration = (name) => ({ target: { value } }) => {
    const { dispatch } = this.props;
    const durationSec = value * 3600;
    dispatch(updateMaintenanceWindow(name, value));
    dispatch(updateMaintenanceWindow('duration', durationSec));
    this.debouncedAutoUpdate();
  };

  saveMaintWindow() {
    const { dispatch, duration, startDate, translations } = this.props;
    const midnight = moment.utc().format('MM/DD/YYYY 00:00');
    const delay = Math.abs(moment.duration(moment(startDate).diff(midnight)).asSeconds());
    dispatch(updateMaintenanceWindow('delay', delay));
    dispatch(saveTerminalOptions({ maintenance_window: { delay, duration } }, translations));
  }

  upgradeBody() {
    const {
      classes, durationHours, startDate, termOptLoading, translations, userPermissions
    } = this.props;

    const readOnly = !checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);


    return (
      termOptLoading
        ? <SonifiSpinner />
        : <Grid container spacing={1}>
          <Grid item xs={3}>
            <SonifiTimePicker
              label={translations.startTime}
              defaultValue={startDate}
              change={this.handleDateChange('startDate')}
              disabled={termOptLoading || readOnly}
              size="percent"
              popperProps={{ className: classes.popoverTransition }}
            />
          </Grid>
          <Grid item xs={3}>
            <SonifiText
              change={this.handleDuration('durationHours')}
              defaultValue={durationHours}
              disabled={termOptLoading || readOnly}
              items={HOURS.map((suggestion) => ({
                id: suggestion.label,
                value: suggestion.value,
                label: suggestion.label,
              }))}
              label={translations.duration}
              select
              size="percent"
            />
          </Grid>
        </Grid>
    );
  }

  render() {
    const { translations } = this.props;
    return (
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <SonifiSection title={translations.maintWindow} body={this.upgradeBody()} allPadding />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  delay: state.termFile.delay,
  duration: state.termFile.duration,
  durationHours: state.termFile.durationHours,
  termOptLoading: state.termFile.termOptLoading,
  startDate: state.termFile.startDate,
  translations: state.termFile.translations.grid.maintWindow,
  userPermissions: state.global.permissions
});

MaintenanceWindow.propTypes = {
  classes: PropTypes.object.isRequired,
  delay: PropTypes.number,
  duration: PropTypes.number,
  durationHours: PropTypes.number,
  dispatch: PropTypes.func,
  termOptLoading: PropTypes.bool,
  startDate: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(MaintenanceWindow, styles, { withTheme: true }));
