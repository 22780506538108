import {
  Alert, Button, CheckboxField, TextField, View
} from '@aws-amplify/ui-react';
import { Auth, I18n } from 'aws-amplify';
import decode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as actions from '../../../actions/globalActions';
import '../../../AmplifyTheme.css';
import awsExports from '../../../aws-exports';
import { setToken } from '../../../utils';
import FormFrame from './FormFrame';
import { KEYCODE_ENTER } from '../../../constants/keyCodes';

const mfaFormFields = {
  confirmSignIn: {
    confirmation_code: {
      labelHidden: false,
      placeholder: 'Enter MFA code',
      isRequired: true,
      label: 'Verify MFA Code'
    },
    rememberDevice: {
      name: 'remember-device',
      label: 'Remember me ?',
      value: 'yes'
    }
  },
};

const Mfa = (props) => {
  const [code, setCode] = useState();
  const [rememberDevice, setRememberDevice] = useState(false);

  const confirmSignInHander = async () => {
    try {
      rememberDevice ?
        Auth.configure({ ...awsExports, storage: localStorage }) :
        Auth.configure({ ...awsExports, storage: sessionStorage });

      Auth.confirmSignIn(props.user, code)
        .then((session) => {
          setToken(session.signInUserSession.idToken.jwtToken);
          props.dispatch(actions.updateCurrentUser(decode(session.signInUserSession.idToken.jwtToken)));

          if (rememberDevice) {
            Auth.rememberDevice();
          } else {
            session.clearCachedDeviceKeyAndPassword();
          }
          props.setUser(session);
        }).catch((error) => {
          console.log(`Error Confirming Signin: ${error}`);
        });
    } catch(error) {
      console.log(`Error Confirming Signin: ${error}`);
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === KEYCODE_ENTER) {
      confirmSignInHander();
    }
  };

  return (
    <FormFrame>
      <View
        as="div"
        id="mfa"
      >
        <TextField {...mfaFormFields.confirmSignIn.confirmation_code}
          onChange={(event) => setCode(event.target.value)}
          onKeyDown={handleKeyPress}
          data-testid="mfa-code-verification-field"
        />
        <CheckboxField {...mfaFormFields.confirmSignIn.rememberDevice} variation="primary"
          onChange={(e) => setRememberDevice(e.target.checked)}
        />
        {props.mfaError &&
          <Alert
            variation="error"
            isDismissible={false}
            hasIcon={true}
          >{props.mfaError}</Alert>
        }
        <Button
          variation="primary"
          isFullWidth
          // onClick={confirmSignInHander}
          onClick={() => confirmSignInHander()}
          type="button"
        >{I18n.get('mfaCode')}</Button>
      </View>
    </FormFrame>
  );
};

Mfa.propTypes = {
  dispatch: PropTypes.func,
  setUser: PropTypes.func,
  mfaError: PropTypes.string,
  user: PropTypes.object
};

export { Mfa };
export default Mfa;
