import { Lock, LockOpen } from '@mui/icons-material';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { map, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ADD, EDIT } from '../../../constants/constants';
import {
  SonifiDivider, SonifiIconButton, SonifiModalHeader, SonifiSpinner, SonifiText
} from '../../../containers';
import { getHasCoreChannelsFromSession, getHasStreamChannelsFromSession, getSiteNumFromURI } from '../../../utils';
import { removePipe } from '../../../utils/textUtil';
import { getCloudStreamChans } from '../../CloudStream/actions/cloudStreamAction';
import { getCoreChannels, updateSelectedChannel } from '../actions/channelsActions';
import {
  ANALOG, ATSC, AUX, channelTypes, CORE, CORE_TYPE, DIGITAL, DVB_C, DVB_S, INTL_ANALOG, IP, IPG, PROPERTY,
  SERVER_STREAM, STREAM_TYPE
} from '../constants/constants';
import {
  emptyAnalog, emptyDigital, emptyDvbC, emptyDvbS, emptyDvbT, emptyIntlAnalog, emptyIP
} from '../dataObjects/channelObjects';
import { deepCopyJson, formatDisplayName, resortChannels } from '../utils/channelMoveAndSort';
import { createSaveObj } from '../utils/helper';
import { isFormValid } from '../utils/validation';
import ChannelForm from './Dialog/ChannelForm';
import ChannelSourceForm from './Dialog/ChannelSourceForm';
import CoreForm from './Dialog/CoreForm';
import PropertyPopupSection from './Dialog/PropertyPopupSection';
import ServerStreamForm from './Dialog/ServerStreamForm';

class ChannelDetailsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      analogEnabled: false,
      channelType: '',
      digitalEnabled: false,
      errors: {},
      id: '',
      ipEnabled: false,
      loading: true,
      locked: false,
      unusedSS: []
    };

    this.editStream = this.editStream.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSave = this.onSave.bind(this);
    this.lockedEdit = this.lockedEdit.bind(this);
  }

  componentDidMount() {
    const { dispatch, selectedChannel } = this.props;

    this.setState({
      loading: true
    }, () => {
      dispatch(getCoreChannels());

      if (selectedChannel.type === SERVER_STREAM && !selectedChannel.isNew) {
        this.setState({
          ...JSON.parse(JSON.stringify(selectedChannel))
        });
        dispatch(getCloudStreamChans(false))
          .then((data) => {
            const cloudInfo = data.find((set) => set.id === selectedChannel.ipg.source_id);
            cloudInfo.source_id = selectedChannel.ipg.source_id;
            this.continueMount(selectedChannel.type, cloudInfo);
          }).catch((error) => error);
      } else {
        let myChannelType = selectedChannel.type;
        if (selectedChannel.type === AUX) {
          myChannelType = PROPERTY;
        }

        dispatch(getCloudStreamChans(false))
          .then(() => {
            this.continueMount(myChannelType);
          }).catch((error) => error);
      }
    });
  }

  continueMount(myChannelType, streamingInfo = null) {
    const { cloudChans, realChannels, selectedChannel } = this.props;

    const streamingChannelsToRemove = realChannels.filter(
      (e) => e.type === SERVER_STREAM &&
        e.ipg.source_id !== streamingInfo?.source_id
    );

    const arr = map(streamingChannelsToRemove, (element) => {
      if (element.source_id) {
        return element.source_id;
      } else if (element.ipg.source_id) {
        return element.ipg.source_id;
      }
      return null;
    });

    const unUsedCloudChannels = cloudChans.filter(
      (e) => !arr.includes(e.id)
    );

    this.setState({
      ...JSON.parse(JSON.stringify(selectedChannel)),
      [myChannelType]: (streamingInfo
        ? streamingInfo
        : { ...selectedChannel.ipg, select_access: !!selectedChannel.select_access }),
      analogEnabled: (!!selectedChannel.analog || !!selectedChannel.intl_analog),
      digitalEnabled: !!selectedChannel.digital || !!selectedChannel.intl_dvb_c ||
        !!selectedChannel.intl_dvb_s || !!selectedChannel.intl_dvb_t,
      ipEnabled: !!selectedChannel.ip,
      loading: false,
      unusedSS: unUsedCloudChannels
    });
  }

  // Special edit for locked channel
  lockedEdit = () => {
    this.setState({ locked: !this.state.locked });
  };

  // This is a special edit function for the type
  // It saves old data when toggling
  editType = ({ target: { value } }) => {
    let descType = value;
    if (value === PROPERTY || value === AUX) {
      descType = PROPERTY;
    }

    if (value !== this.state.type) {
      if (!(this.state[descType] !== undefined)) {
        console.log(`${descType} not in`, this.state);
        const now = new Date();
        this.setState({
          type: value,
          [descType]: {
            source_id: (value === IPG || value === SERVER_STREAM || value === CORE
              ? ''
              : `SONIFI-${getSiteNumFromURI()}-${Math.round(now.getTime() / 1000)}`),
            affiliate: '',
            call_letters: '',
            entry: '',
            duration: (value === IPG ? '' : 'PT1H'),
            description: ''
          }
        });
      } else {
        console.log(`${value} is in`, this.state);
        this.setState({ type: removePipe(value) });
      }
    }
  };

  // Special Edit for source
  sourceEdit = (value) => {
    this.setState({
      [this.state.type]: removePipe(value)
    }, () => {
      const displayName = formatDisplayName(this.state, this.props.realChannels);
      this.setState({ name: displayName });
    });
  };

  // Edit function you put on a text field component
  edit = (parentObj, name, type) => ({ target: { value } }) => {
    this.editNonEvent(parentObj, name, type, value);
  };

  editStream(parentObj, value) {
    this.setState({ [parentObj]: { source_id: value.id, ...value }, name: value.name });
  }

  // Edit function that you can call without an event
  // This was nessessary for the digital Mod-Stream field
  editNonEvent = (parentObj, name, type, value) => {
    // convert to a number if it's a number
    if (type === 'number') {
      value = Number(value);
      if (isNaN(value)) {
        return;
      }
    }
    if (name === 'channelType') {
      this.setState({
        channelType: value
      });
      if (value === ATSC) {
        value = DIGITAL;
      }
      if (!(this.state[value] !== undefined)) {
        let newChannelObj = {};
        if (value === ANALOG) {
          newChannelObj = emptyAnalog;
        } else if (value === INTL_ANALOG) {
          newChannelObj = emptyIntlAnalog;
        } else if (value === DIGITAL) {
          newChannelObj = emptyDigital;
        } else if (value === DVB_C) {
          newChannelObj = emptyDvbC;
        } else if (value === DVB_S) {
          newChannelObj = emptyDvbS;
        } else {
          newChannelObj = emptyDvbT;
        }

        this.setState({
          [value]: { ...newChannelObj },
          channelType: value
        });
      }

      return;
    }

    // Don't allow user to type more than 256 characters
    if (name === 'description' && parentObj === IPG && value.length > 256) {
      return;
    }

    // Don't allow user to type more than 64 characters
    if (name === 'entry' && parentObj === IPG && value.length > 64) {
      return;
    }

    if (parentObj === 'ip' && name === 'address' && !(/^[0-9,.]*$/).test(value)) {
      return;
    }

    // Save the data to the channel
    if (parentObj === null) {
      this.setState({ [name]: removePipe(value) });
    } else {
      this.setState({
        [parentObj]: { ...this.state[parentObj], [name]: removePipe(value) }
      });
    }
  };

  // Edit for checkboxes on channel for
  // Saves temp data when toggling
  changeChannelFormObj = (name, isEnabled) => {
    let chanType = '';
    this.setState({ [`${name}Enabled`]: isEnabled });
    if (!(this.state[name] !== undefined)) {
      let newChannelObj = {};
      if (name === ANALOG) {
        newChannelObj = emptyAnalog;
        chanType = ANALOG;
      } else if (name === DIGITAL) {
        newChannelObj = emptyDigital;
        chanType = DIGITAL;
      } else if (name === IP) {
        newChannelObj = emptyIP;
        chanType = '';
      }
      this.setState({
        [name]: { ...newChannelObj },
        channelType: chanType
      });
    }
  };

  onSave() {
    const { globalTranslations, realChannels, saveCheck, selectedChannel } = this.props;
    const valid = isFormValid(this.state, realChannels, globalTranslations.errors);
    this.setState({ errors: valid });
    if (Object.entries(valid).length === 0) {
      const saveObject = createSaveObj(deepCopyJson(this.state));
      let channelsCopy = JSON.parse(JSON.stringify(realChannels));
      if (selectedChannel.isNew) {
        channelsCopy.push(saveObject);
        channelsCopy = resortChannels(channelsCopy, channelsCopy.length - 1);
      } else {
        const channelIndex = channelsCopy.findIndex((oldChannel) => oldChannel.id === saveObject.id);
        channelsCopy.splice(channelIndex, 1, saveObject);
      }
      saveCheck(channelsCopy, { type: (selectedChannel.isNew ? ADD : EDIT), chan: saveObject.name });
    }
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(updateSelectedChannel(null));
    }
  }

  getChannelTypes() {
    const { cloudChans, coreChannels, selectedChannel } = this.props;
    const tempTypes = JSON.parse(JSON.stringify(channelTypes));

    if (getHasStreamChannelsFromSession() === 'true') {
      if (cloudChans.length > 0 || selectedChannel.type === SERVER_STREAM) {
        tempTypes.push(STREAM_TYPE);
      }
    }
    if (getHasCoreChannelsFromSession() === 'true') {
      if (coreChannels.length > 0 || selectedChannel.type === CORE) {
        tempTypes.push(CORE_TYPE);
      }
    }
    return uniqBy(tempTypes, (e) => e.value);
  }

  render() {
    const {
      coreChannels, globalTranslations, readOnly, selectedChannel, translations
    } = this.props;
    const {
      errors, id, ipg, loading, locked, name, property, type, unusedSS
    } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={this.onCancel}
        open={true} >
        <SonifiModalHeader
          header={selectedChannel.isNew ? `${translations.addChannel} ${id}` : `${translations.editChannel} ${id}`}
          onlyClose={readOnly}
          onCancel={this.onCancel}
          onSubmit={this.onSave}
          label={globalTranslations.defaults.save}
        />
        <DialogContent style={{ height: '100vh' }}>
          {loading
            ? <SonifiSpinner />
            : <Grid container>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <SonifiIconButton
                  onClick={this.lockedEdit}
                  icon={locked ? <Lock /> : <LockOpen />}
                  label=""
                  disabled={readOnly}
                  marginTop={6}
                />
                <SonifiText
                  defaultValue={type}
                  change={this.editType}
                  select={true}
                  label={translations.type}
                  size="sm"
                  items={this.getChannelTypes()}
                  disabled={readOnly} />
                {type === IPG &&
                  <ChannelSourceForm
                    readOnly={readOnly}
                    editFunction={this.sourceEdit}
                    displayName={name}
                    ipgInfo={ipg}
                    errors={errors} />}
                {(type === PROPERTY || type === AUX) &&
                  <PropertyPopupSection
                    edit={this.edit}
                    displayName={name}
                    propertyInfo={property}
                    errors={errors}
                    type={PROPERTY}
                    readOnly={readOnly}
                    translations={translations} />}
                {type === SERVER_STREAM &&
                  <ServerStreamForm
                    edit={this.editStream}
                    streamInfo={this.state[SERVER_STREAM]}
                    cloudChans={unusedSS}
                    readOnly={readOnly}
                    errors={errors}
                    translations={translations}
                  />
                }
                {type === CORE &&
                  <CoreForm
                    edit={this.editStream}
                    streamInfo={{ ...this.state[CORE], id: this.state[CORE].source_id }}
                    coreChannels={
                      selectedChannel.isNew
                        ? coreChannels
                        : [{ id: selectedChannel.ipg.source_id, name: selectedChannel.name }, ...coreChannels]
                    }
                    readOnly={readOnly}
                    errors={errors}
                    coreEdit={this.edit}
                    selectedChannel={selectedChannel}
                    translations={translations}
                  />
                }
              </Grid>
              <Grid item xs={12}>
                <SonifiDivider />
              </Grid>
              <Grid item xs={10} style={{ height: '100%' }}>
                <ChannelForm
                  channel={this.state}
                  readOnly={readOnly}
                  editFunction={this.edit}
                  editNonEventFunction={this.editNonEvent}
                  changeDetailObjFunc={this.changeChannelFormObj}
                  errors={errors}
                  type={type}
                />
              </Grid>
            </Grid>}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  cloudChans: state.cloudStream.cloudChans,
  coreChannels: state.channels.coreChannels,
  globalTranslations: state.global.translations,
  realChannels: state.channels.realChannels,
  selectedChannel: state.channels.selectedChannel,
  translations: state.channels.translations.editDialog
});

ChannelDetailsDialog.propTypes = {
  cloudChans: PropTypes.array,
  coreChannels: PropTypes.array,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  readOnly: PropTypes.bool,
  realChannels: PropTypes.array,
  saveCheck: PropTypes.func.isRequired,
  selectedChannel: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(ChannelDetailsDialog);
