import { TableCell, TableRow } from '@mui/material';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../../containers/SonifiLabel';
import { dateToString } from '../../../../utils';
import { viewLogInfo } from '../actions/pmsLoggingAction';
import { pmsTypes } from '../constants/index';

class LogRow extends Component {
  handleEditDialog = () => () => {
    const { dispatch, logs, rowIndex } = this.props;
    dispatch(viewLogInfo(logs[rowIndex]));
  };

  render() {
    const { logs, rowIndex, timezone } = this.props;

    return (
      <TableRow onClick={this.handleEditDialog()}>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={dateToString(logs[rowIndex].timestamp, timezone)} />
        </TableCell>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={logs[rowIndex].room ? logs[rowIndex].room : ''} />
        </TableCell>
        <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
          <SonifiLabel label={find(pmsTypes, { value: logs[rowIndex].type }).label} />
        </TableCell>
      </TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  logs: state.pmsLogging.logs,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago')
});

LogRow.propTypes = {
  dispatch: PropTypes.func,
  logs: PropTypes.array.isRequired,
  rowIndex: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(LogRow);
