import { Person, PersonOutline } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel, SonifiSpinner, SonifiTableHead } from '../../../containers';
import * as actions from '../actions/groupServicesActions';

class GroupRooms extends Component {
  state = {
    order: 'asc',
    orderBy: 'room_number',
  };

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(actions.sortSelectedGroupRooms(this.state.orderBy, this.state.order));
    }
  }

  onChange(checked) {
    this.props.dispatch(actions.editSelectedGroup('rooms', checked));
  }

  handleRequestSort = (property) => {
    if (this.props.gettingRooms) {
      return;
    }

    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      orderBy: property,
      order: isDesc ? 'asc' : 'desc'
    });
  };

  /* eslint-disable camelcase */
  render() {
    const { gettingRooms, roomsTranslations, selectedGroup } = this.props;
    const { order, orderBy } = this.state;

    const tableHeader = [
      {
        id: 'is_occupied',
        sortable: selectedGroup?.rooms.length > 0,
        numeric: false,
        label: `${roomsTranslations.occupied}`,
        narrow: true
      },
      {
        id: 'room_number',
        sortable: selectedGroup?.rooms.length > 0,
        numeric: false,
        label: `${roomsTranslations.roomNum}`
      },
    ];
    return (
      <div style={{
        width: '100%', border: 'none', flexGrow: 1, overflowX: 'hidden', height: '100%'
      }}>
        <Table id="GroupRooms" size="small">
          <SonifiTableHead headColumns={tableHeader}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />
          <TableBody>
            {selectedGroup?.rooms.length === 0
              ? <TableRow><TableCell colSpan={3}>
                {gettingRooms
                  ? <SonifiSpinner />
                  : <SonifiLabel label={roomsTranslations.noRoomsToDisplay} />}
              </TableCell></TableRow>
              : selectedGroup.rooms.map(({ room_number, is_occupied }) => (
                <TableRow key={room_number}>
                  <TableCell component="th" scope="row">{is_occupied ? <Person /> : <PersonOutline />}</TableCell>
                  <TableCell component="th" scope="row">{room_number}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>);
  }
}

const mapStateToProps = (state) => ({
  gettingRooms: state.groupServices.gettingRooms,
  roomsTranslations: state.groupServices.translations.rooms,
  selectedGroup: state.groupServices.selectedGroup
});

GroupRooms.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  gettingRooms: PropTypes.bool,
  roomsTranslations: PropTypes.object,
  selectedGroup: PropTypes.object
};

export default connect(mapStateToProps)(GroupRooms);
