import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ROOM_DETAIL_EDIT } from '../../../constants/roles';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiText from '../../../containers/SonifiText';
import { findArrayIdByKey, hasGroupServiceFeat, range } from '../../../utils/index';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import { removePipe } from '../../../utils/textUtil';
import { doneAddingRoom, resetAddRoom, startAddRoom } from '../actions/editRoomActions';
import AddComponent from '../containers/Popup/AddComponent';
import { addRangeOfRooms } from '../utils/addRooms';
import { isFormValid } from '../utils/index';

class AddDialog extends Component {
  state = {
    compositeChecked: [],
    errors: {},
    range: false,
    room: {
      building: null,
      can_bill_to_room: true,
      classification: '',
      floor: null,
      id: '',
      is_occupied: false,
      is_test: false,
      pms_id: null,
      rangeEnd: '',
      room_type: null,
      unit: null
    },
    selectedGroup: undefined,
  };

  componentDidMount() {
    const roomConst = { ...this.state.room };
    roomConst.classification = this.props.classifications[0];

    if (this.props.siteHierarchy.length > 0) {
      roomConst.building = this.props.siteHierarchy[0].name;
      if (this.props.siteHierarchy[0].floors.length > 0) {
        roomConst.floor = this.props.siteHierarchy[0].floors[0].name;
      }
    }
    this.setState({ room: roomConst, errors: {} });
  }

  handleSelectChange(name, value, name2, value2) {
    const roomConst = { ...this.state.room };
    roomConst[name] = value;
    if (name2) {
      roomConst[name2] = value2;
    }
    roomConst.pms_id = roomConst.id;
    this.setState({ room: roomConst });
  }

  handleChange = (name) => ({ target: { value } }) => {
    this.handleSelectChange(name, removePipe(value));
  };

  handleCheckboxChange = (name) => ({ target: { checked } }) => {
    this.handleSelectChange(name, checked);
  };

  handleThisChange = (name, value) => {
    if (name === 'building') {
      const buildingId = findArrayIdByKey(this.props.siteHierarchy, 'name', value);
      if (this.props.siteHierarchy[buildingId].floors.length > 0) {
        this.handleSelectChange(name, removePipe(value),
          'floor', this.props.siteHierarchy[buildingId].floors[0].name);
      } else {
        this.handleSelectChange(name, removePipe(value));
      }
    } else {
      this.handleSelectChange(name, removePipe(value));
    }
  };

  addRangeEnd() {
    const newRoom = this.state.room;
    if (this.state.room.id !== '') {
      newRoom.id = newRoom.id.replace(/([^0-9])/g, '');
    }
    this.setState({ range: true, room: newRoom });
  }

  compositeRoomChange(checkedNodes) {
    this.setState({ compositeChecked: checkedNodes });
  }

  handleSubmit() {
    const roomConst = { ...this.state.room };
    const { dispatch, siteHierarchy, translations, integrations } = this.props;
    const { selectedGroup } = this.state;
    const pmsSite = integrations.type !== 'generator';
    let groupObj = null;

    if (selectedGroup?.id) {
      groupObj = {
        group_id: selectedGroup.id,
        integration_type: pmsSite ? 'pms' : 'generator'
      };
    }

    let leadingZeros = false,
      numEnd = 0;
    roomConst.rangeEnd = roomConst.rangeEnd.trim();
    roomConst.id = roomConst.id.trim();
    this.setState({ errors: {}, room: roomConst });
    dispatch(startAddRoom());

    const validForm = isFormValid(this.state.room, translations.addDialog.validation,
      this.state.range, siteHierarchy);
    if (Object.keys(validForm).length === 0) {
      this.props.onClick(null);
      numEnd = Number(this.state.room.rangeEnd);
      if (numEnd.toString().length < this.state.room.rangeEnd.length) {
        leadingZeros = true;
      }
      const roomsToParse = (this.state.range
        ? range(this.state.room.id.trim(), this.state.room.rangeEnd.trim())
        : [this.state.room.id.trim()]);
      const roomData = [];
      for (let i = 0; i < roomsToParse.length; i++) {
        roomData.push({
          building: `${this.state.room.building}`,
          can_bill_to_room: true,
          classification: `${this.state.room.classification}`,
          floor: `${this.state.room.floor}`,
          group: groupObj,
          id: leadingZeros ? this.padZeros(roomsToParse[i], this.state.room.rangeEnd.length) : `${roomsToParse[i]}`,
          is_occupied: false,
          is_test: this.state.room.is_test,
          room_type: this.state.room.room_type,
          pms_id: leadingZeros
            ? this.padZeros(roomsToParse[i], this.state.room.rangeEnd.length)
            : `${roomsToParse[i]}`,
          unit: null
        });
      }
      addRangeOfRooms(roomData, dispatch);
    } else {
      this.props.dispatch(doneAddingRoom(0));
      this.setState({ errors: validForm });
    }
  }

  padZeros(num, padlen) {
    const pad = new Array(1 + padlen).join('0');
    return (pad + num).slice(-pad.length);
  }

  handleClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  handleCloseAfterSubmit() {
    this.props.dispatch(resetAddRoom());
    this.props.onCancel();
  }

  handleGroupChange(event) {
    const groupId = event.target.value.id,
      groupName = event.target.value.name;
    if (groupId === undefined) {
      this.setState({ selectedGroup: undefined });
    } else {
      this.setState({ selectedGroup: { id: groupId, name: groupName } });
    }
  }

  render() {
    const {
      classifications, globalTranslations, translations, userPermissions,
      hasPms, siteGroups /* , configurePmsRoomTypes, roomTypes*/
    } = this.props;
    const { room, selectedGroup } = this.state;
    const { classification/* , room_type */ } = room;
    const roleAllowed = !checkForSingleUserPermission(ROOM_DETAIL_EDIT, userPermissions);

    return (
      <Dialog
        open={true}
        onClose={this.handleClose.bind(this)}
        fullWidth
        maxWidth="lg"
      >
        <SonifiModalHeader
          header={translations.addDialog.addRoom}
          onCancel={this.handleClose.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          label={globalTranslations.add}
        />
        <DialogContent>
          <AddComponent
            addState={this.state.room}
            onChange={this.handleThisChange.bind(this)}
            addRangeEnd={this.addRangeEnd.bind(this)}
            handleCheckboxChange={this.handleCheckboxChange.bind(this)}
            range={this.state.range}
            errors={this.state.errors}
          />
          <SonifiText
            label={translations.main.classification}
            select
            size="sm"
            defaultValue={classification}
            items={classifications.map((suggestion) => ({
              id: suggestion,
              value: suggestion,
              label: suggestion
            }))}
            change={this.handleChange('classification')}
          />
          {/* {roomTypes.length > 0 && <SonifiText
            label={translations.editDialog.roomType}
            select
            size="sm"
            defaultValue={room_type || 'None'}
            items={[{ name: 'None', sequence: -1 }, ...roomTypes]
              .map(({ name }) => ({ id: name, value: name, label: name }))}
            change={this.handleChange('room_type')}
            disabled={roleAllowed || configurePmsRoomTypes}
          />} */}
          {hasGroupServiceFeat() === 'true' && siteGroups.length > 0 && !hasPms &&
            <SonifiText
              label={translations.editDialog.groupType}
              select
              size="sm"
              defaultValue={selectedGroup !== undefined
                ? siteGroups.find((group) => group.id === selectedGroup.id)
                : { name: 'None' }
              }
              items={[{ name: 'None', sequence: -1 }, ...siteGroups]
                .map((group) => ({ id: group.name, value: { id: group.id, name: group.name } }))}
              change={this.handleGroupChange.bind(this)}
              disabled={!roleAllowed && hasPms ? true : roleAllowed}
            />
          }
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  addingRoom: state.rooms.addingRoom,
  addRoomLoading: state.rooms.addRoomLoading,
  addRoomSuccess: state.rooms.addRoomSuccess,
  configurePmsRoomTypes: state.integrations.configurePmsRoomTypes,
  classifications: state.rooms.classifications,
  globalTranslations: state.global.translations.defaults,
  integrations: state.integrations.integrations,
  loadingIntegrations: state.integrations.loading,
  newRoomArr: state.rooms.newRoomArr,
  hasPms: state.integrations.hasPms,
  siteGroups: state.groupServices.groups,
  siteHierarchy: state.rooms.buildings,
  translations: state.rooms.translations,
  roomTypes: state.lateCheckout.roomTypes,
  userPermissions: state.global.permissions
});

AddDialog.propTypes = {
  addingRoom: PropTypes.bool,
  addRoomLoading: PropTypes.bool,
  configurePmsRoomTypes: PropTypes.bool,
  addRoomSuccess: PropTypes.string,
  classifications: PropTypes.array.isRequired,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  integrations: PropTypes.array,
  loadingIntegrations: PropTypes.bool,
  newRoomArr: PropTypes.array,
  onCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  roomTypes: PropTypes.array,
  hasPms: PropTypes.bool,
  range: PropTypes.bool.isRequired,
  room: PropTypes.object,
  siteGroups: PropTypes.array,
  siteHierarchy: PropTypes.array,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(AddDialog);
