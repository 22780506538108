export function validLogo(logo, translations) {
  const errors = {};

  if (logo.sources.length === 0) {
    errors.sources = translations.needToSelectAChannel;
  }

  if (logo.imageFiles.length === 0) {
    errors.image = translations.needImage;
  }

  return errors;
}
