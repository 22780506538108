import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import SonifiCheckBox from '../../../containers/SonifiCheckbox';
import SonifiDivider from '../../../containers/SonifiDivider';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiText from '../../../containers/SonifiText';
import { encryptionType, mpegFormat, propertyCables, tunerType } from '../constants/lineupConstants';

const styles = () => ({
  displayContents: {
    display: 'contents'
  },
  show: {
    visibility: 'visible'
  },
  hide: {
    visibility: 'hidden'
  }
});

class LineupSelector extends Component {
  getSelectionBox(defValue, labl, val, items) {
    const { canEditDetails, errors, handleChange } = this.props;

    return (
      <Grid item xs={3}>
        <SonifiText
          label={labl}
          select
          size="percent"
          disabled={!canEditDetails}
          defaultValue={defValue}
          error={!!errors[val]}
          errorText={errors[val]}
          items={items.map((suggestion) => ({
            id: suggestion,
            value: suggestion,
            label: suggestion,
          }))}
          change={handleChange(`${val}`)}
        />
      </Grid>);
  }

  /* eslint-disable camelcase*/
  render() {
    const {
      classes, classifications, errors, handleChange, lineupInfo, onCheckboxChange, translations
    } = this.props;

    const {
      bat_warmup_seconds, cables, classification, encryption, is_bat_enabled, is_power_on_surfable,
      is_power_on_enabled, mpeg_format, name, tuner_type
    } = lineupInfo;

    return (
      <Grid container>
        <Grid item xs={3}>
          <SonifiText
            label={translations.lineupName}
            defaultValue={name}
            change={handleChange('name')}
            error={!!errors.name}
            errorText={errors.name}
            size="percent"
            testLabel="lineup-dialog-name-textfield"
          />
        </Grid>
        {this.getSelectionBox(classification, translations.classification, 'classification', classifications, 3)}
        <Grid item xs={2}>
          <SonifiCheckBox
            label={translations.bat}
            value={is_bat_enabled}
            onChange={onCheckboxChange('is_bat_enabled')}
          />
          <div className={`${classes.displayContents} ${is_bat_enabled ? classes.show : classes.hide}`}>
            <SonifiText
              label={translations.seconds}
              defaultValue={bat_warmup_seconds}
              type="number"
              size="xsNoPad"
              error={!!errors.bat}
              errorText={errors.bat}
              change={handleChange('bat_warmup_seconds')}
            />
          </div>
        </Grid>
        <Grid item xs={3} style={{ border: '1px solid #d6d6d6', paddingLeft: '14px', paddingTop: '7px' }}>
          <Grid container>
            <Grid item xs={12}>
              <SonifiLabel size="xs" label={translations.powerOnCh} noPadding grid />
            </Grid>
            <SonifiCheckBox
              label={translations.enabled}
              value={!!is_power_on_enabled}
              noPadding
              onChange={onCheckboxChange('is_power_on_enabled')}
            />
            {is_power_on_enabled && <SonifiCheckBox
              label={translations.surfable}
              noPadding
              value={is_power_on_surfable && is_power_on_enabled}
              onChange={onCheckboxChange('is_power_on_surfable')}
            />}
          </Grid>
        </Grid>
        <SonifiDivider />
        <Grid container>
          <Grid item xs={12}>
            <SonifiLabel size="xs" label={translations.filter} />
          </Grid>
          {this.getSelectionBox(cables, translations.cables, 'cables', propertyCables)}
          {this.getSelectionBox(tuner_type, translations.tunerType, 'tuner_type', tunerType)}
          {this.getSelectionBox(mpeg_format, translations.mpegFormat, 'mpeg_format', mpegFormat)}
          {this.getSelectionBox(encryption, translations.encryption, 'encryption', encryptionType)}
        </Grid>
      </Grid>
    );
  }
}
const mapStateToProps = (state) => ({
  canEditDetails: state.tvLineup.canEditDetails,
  classifications: state.rooms.classifications,
  translations: state.tvLineup.translations.editDialog
});

LineupSelector.propTypes = {
  canEditDetails: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  classifications: PropTypes.array.isRequired,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  lineupInfo: PropTypes.object,
  onCheckboxChange: PropTypes.func.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(LineupSelector, styles, { withTheme: true }));
