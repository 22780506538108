import { SITES } from '../constants/apiEndpoints';
import {
  buildUrl, deleteData, fetchCsvData, fetchData, fetchDataWithHeaders, getSiteNumFromURI,
  patchData, postData, putData, putDataWithExtraHeaders
} from './index';

export function getSiteObjectPromise(resource, info, siteId, obj) {
  return new Promise((resolve, reject) => {
    const siteNum = (siteId ? siteId : getSiteNumFromURI());
    let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}`, obj);

    if (info) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}/${info}`, obj);
    }
    fetchData(url)
      .then((data) => {
        resolve(data);
      })
      .catch((reason) => reject(reason));
  });
}

export function getSiteCsvObjectPromise(resource, siteId, obj) {
  return new Promise((resolve, reject) => {
    const siteNum = (siteId ? siteId : getSiteNumFromURI());
    const url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}`, obj);

    fetchCsvData(url)
      .then((data) => {
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

export function getSitelessCsvObjectPromise(resource, obj) {
  return new Promise((resolve, reject) => {
    const url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}`, obj);

    fetchCsvData(url)
      .then((data) => {
        resolve(data);
      })
      .catch((reason) => {
        reject(reason);
      });
  });
}

export function getSiteObjectPromiseWithETag(resource, info, siteId, obj) {
  return new Promise((resolve, reject) => {
    const siteNum = (siteId ? siteId : getSiteNumFromURI());
    let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}`, obj);

    if (info) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}/${info}`, obj);
    }
    fetchDataWithHeaders(url)
      .then((data) => {
        resolve(data);
      })
      .catch((reason) => reject(reason));
  });
}

export function getObjectPromise(resource, info, obj) {
  return new Promise((resolve, reject) => {
    let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}`, obj);
    if (info) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}/${info}`, obj);
    }

    fetchData(url)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function getSitelessObjectPromise(resource, info, obj) {
  return new Promise((resolve, reject) => {
    let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}`, obj);

    if (info) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}/${info}`, obj);
    }
    fetchData(url)
      .then((data) => {
        resolve(data);
      })
      .catch((reason) => reject(reason));
  });
}

export function patchSitelessObjectPromise(resource, info, submitObj, controller) {
  return new Promise((resolve, reject) => {
    let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}`);
    if (info) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}/${info}`);
    }

    patchData(url, submitObj, controller)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function putObjectPromise(resource, info, submitObj, siteId, moreDetail) {
  return new Promise((resolve, reject) => {
    const siteNum = (siteId ? siteId : getSiteNumFromURI());

    let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}`);
    if (info) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}/${info}`);
    }

    putData(url, submitObj, moreDetail)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function putObjectPromiseWithExtraHeaders(resource, info, submitObj, siteId, extraHeaders) {
  return new Promise((resolve, reject) => {
    const siteNum = (siteId ? siteId : getSiteNumFromURI());

    const url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}/${info}`);
    putDataWithExtraHeaders(url, submitObj, extraHeaders)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}


export function postObjectPromise(resource, submitObj, siteId, completeLocation = false) {
  return new Promise((resolve, reject) => {
    const siteNum = (siteId ? siteId : getSiteNumFromURI());
    const url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}`);
    postData(url, submitObj, completeLocation)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function patchObjectPromise(resource, info, submitObj, siteId) {
  return new Promise((resolve, reject) => {
    const siteNum = (siteId ? siteId : getSiteNumFromURI());

    let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}`);
    if (resource && info) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}/${info}`);
    } else if (resource) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}`);
    }

    patchData(url, submitObj)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function postSitelessObjectPromise(resource, info, submitObj, completeLocation) {
  return new Promise((resolve, reject) => {
    // let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}`, submitObj);
    let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}`);
    if (info) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}/${info}`, submitObj);
    }
    postData(url, submitObj, completeLocation)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function putSitelessObjectPromise(resource, info, submitObj) {
  return new Promise((resolve, reject) => {
    const url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}/${info}`);
    putData(url, submitObj)
      .then((data) => { resolve(data); })
      .catch((reason) => reject(reason));
  });
}

export function deleteSitelessObjectPromise(resource, info) {
  return new Promise((resolve, reject) => {
    const url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${resource}/${info}`);

    deleteData(url)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function deleteObjectPromise(resource, info, submitObj) {
  return new Promise((resolve, reject) => {
    const siteNum = getSiteNumFromURI();
    let url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}`);

    if (info) {
      url = buildUrl(`${process.env.REACT_APP_CCA_API_URL}/${SITES}/${siteNum}/${resource}/${info}`);
    }

    deleteData(url, submitObj)
      .then((data) => resolve(data))
      .catch((reason) => reject(reason));
  });
}

export function isProduction(isProd = true) {
  if (window.location.hostname === 'localhost' || window.location.hostname === 'cca-devel.sonifi.cloud' ||
    window.location.hostname === 'signin-devel.sonifi.cloud') {
    return isProd;
  }
  return true;// window.location.hostname !== 'localhost' && window.location.hostname !== 'cca-devel.sonifi.cloud';

  // return (window.location.hostname === 'localhost' || window.location.hostname === 'cca-devel.sonifi.cloud');
  // return window.location.hostname === 'cca.sonifi.cloud';
}

export function isTesting(isProd = true) {
  if (window.location.hostname === 'localhost' || window.location.hostname === 'cca-devel.sonifi.cloud' ||
    window.location.hostname === 'cca-test.sonifi.cloud' || window.location.hostname === 'signin-devel.sonifi.cloud' ||
    window.location.hostname === 'signin-test.sonifi.cloud') {
    return isProd;
  }
  return true;
}

export function readResponseErrorStream(stream) {
  return new Promise((resolve) => {
    if (!stream.bodyUsed && stream.body) {
      const reader = stream.body.getReader();
      let result = '';
      reader.read().then(function processText({ done, value }) {
        // Result objects contain two properties:
        // done  - true if the stream has already given you all its data.
        // value - some data. Always undefined when done is true.
        if (done) {
          const chars = result.split(','),
            str = String.fromCharCode(...chars),
            error = JSON.parse(str);

          resolve(error);
          return;
        }
        const chunk = value;
        result += chunk;

        // Read some more, and call this function again
        return reader.read().then(processText);
      });
    } else {
      resolve('Error Stream has already been used');
      return;
    }
  });
}
