import { Add, ArrowUpward } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { MANUAL, READ, UPDATE } from '../../../constants/constants';
import SonifiIconButton from '../../../containers/SonifiIconButton';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import { updateDeleteDeployment, updateSelectedDeployment } from '../actions/deploymentsActions';
import { currentStatus, getStatusOptions } from '../constants/constants';

class DeploymentRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusIndex: this.props.option.channels.length - 1
    };

    this.handleDeleteDialog = this.handleDeleteDialog.bind(this);
    this.handleManualChange = this.handleManualChange.bind(this);
    this.handleReadChange = this.handleReadChange.bind(this);
    this.handleUpdateChange = this.handleUpdateChange.bind(this);
  }

  handleEditDialog(type) {
    const { dispatch, rowIndex } = this.props;
    const { statusIndex } = this.state;
    dispatch(updateSelectedDeployment(rowIndex, statusIndex, type));
  }

  handleDeleteDialog() {
    const { dispatch, rowIndex } = this.props;
    const { statusIndex } = this.state;
    dispatch(updateDeleteDeployment(rowIndex, statusIndex));
  }

  inProgress() {
    const { option } = this.props;
    return option.promoting && option.promoting.length > 0;
  }

  onButtonClick() {
    return;
  }

  /* eslint-disable camelcase */
  getTableDetails() {
    const {
      channels, rowIndex, translations
    } = this.props;
    const { statusIndex } = this.state;
    let nextStatus = '';
    const { deployment_date, id, virtos } = channels[rowIndex];
    const status = channels[rowIndex].channels[statusIndex];
    if (this.inProgress()) {
      const statusOptions = getStatusOptions(currentStatus(status));
      nextStatus = statusOptions.length >= 2
        ? currentStatus(statusOptions[1].label)
        : currentStatus(statusOptions[0].label);
    }
    return (<Fragment>
      <TableCell component="th" scope="row">
        {this.inProgress()
          ? <SonifiIconButton label={`${currentStatus(status)} *`}
            onClick={this.onButtonClick()}
            disabled={true}
            extraStyles={{ 'color': '#1592d9', '&:hover': { color: '#FFF !important' }, 'paddingLeft': 0 }}
            toolTipMessage={`${translations.deploymentProgress} ${nextStatus}.`}
            showToolTip={true} />
          : <SonifiLabel label={currentStatus(status)} />
        }
      </TableCell>
      <TableCell component="th" scope="row">
        <SonifiLabel label={id} />
      </TableCell>
      <TableCell component="th" scope="row">
        <SonifiLabel label={`${virtos}`} />
      </TableCell>
      <TableCell component="th" scope="row">
        <SonifiLabel label={`${deployment_date ? moment(deployment_date).format('MM/DD/YYYY h:mm a') : ''}`} />
      </TableCell>
    </Fragment>);
  }

  handleReadChange = () => {
    this.handleEditDialog(READ);
  };

  handleUpdateChange = () => {
    this.handleEditDialog(UPDATE);
  };

  handleManualChange = () => {
    this.handleEditDialog(MANUAL);
  };

  render() {
    const {
      canEdit, channels, key, globalTranslations, rowIndex, translations, manualUpdate
    } = this.props;
    const { statusIndex } = this.state;
    const status = channels[rowIndex].channels[statusIndex];
    const statusOptions = getStatusOptions(currentStatus(status));
    if (!canEdit) {
      return (<TableRow>
        {this.getTableDetails()}
      </TableRow>);
    }

    return (
      <SonifiPopover
        deleteDisabled={true}
        deleteFunc={this.handleDeleteDialog}
        deleteTitle={globalTranslations.delete}
        functionOneFunc={this.handleReadChange}
        functionOneTitle={globalTranslations.view}
        functionTwoDisabled={statusOptions.length < 2 || this.inProgress()}
        functionTwoFunc={this.handleUpdateChange}
        functionTwoIcon={<ArrowUpward fontSize="small" />}
        functionTwoTitle={translations.promoteDeployment}
        editIcon={<Add fontSize="small" />}
        editTitle={translations.manual}
        editDisabled={!manualUpdate}
        editFunc={this.handleManualChange}
        key={key}
        showFunctionOne={true}
        showFunctionTwo={true}
        tableRow={this.getTableDetails()}
      />
    );
  }
}


const mapStateToProps = (state) => ({
  channels: state.deployments.channels,
  globalTranslations: state.global.translations.defaults,
  selectedProductParent: state.deployments.selectedProductParent,
  translations: state.deployments.translations.editDialog
});

DeploymentRow.propTypes = {
  canEdit: PropTypes.bool,
  channels: PropTypes.array,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  key: PropTypes.number,
  manualUpdate: PropTypes.bool,
  option: PropTypes.object,
  rowIndex: PropTypes.number.isRequired,
  selectedProductParent: PropTypes.string,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(DeploymentRow);
