import { find, orderBy, uniqBy, without } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as actions from '../actions/actionTypes';
import en from '../i18n/en';
import es from '../i18n/es';

const langs = { en, es };

export const initialState = {
  batchSaving: false,
  cables: [],
  censorships: [
    { id: 1, name: 'Uncensored' },
    { id: 2, name: 'No Adult' },
    { id: 3, name: 'Teen' },
    { id: 4, name: 'Kid' },
    { id: 5, name: 'No Services' }
  ],
  changed: true,
  dialogLoading: false,
  fetchingRange: false,
  errorMsg: '',
  languages: [],
  lineups: [],
  loading: false,
  locations: [],
  maxPages: 1,
  menusets: [],
  rangeTerminals: [],
  savingFirmware: false,
  selectedTerm: -1,
  selectedTermID: '',
  selectedRangeTerminals: [],
  snack: {
    message: 'TerminalMsg',
    open: false,
    variant: SUCCESS
  },
  terminals: [],
  terminalStatuses: {
    total: '---',
    assigned: '---',
    unassigned: '---',
    notCommunicating: '---',
  },
  totalEntries: 0,
  translations: null,
  type: ''
};

/* eslint-disable max-lines-per-function */
export default function termGridReducer(state = initialState, action) {
  const editTerm = state.selectedTerm === -1 ? {} : { ...state.selectedTerm };
  const currentSnack = state.snack;
  const terminalList = state.terminals;
  const translations = state.translations;
  let miscArray = null,
    miscObj = null;

  switch (action.type) {
    case actions.RESET_TERM_GRID:
      return { ...state, dialogLoading: false, loading: false };

    case actions.CLOSE_SNACK:
      currentSnack.open = false;
      return { ...state, snack: currentSnack, changed: !state.changed };

    case actions.PUT_TERMINAL_BEGIN:
      return {
        ...state, savingFirmware: true, terminals: [], loading: true
      };
    case actions.PUT_TERMINAL_SUCCESS_FAILURE:
      currentSnack.message = `${action.msg}`;
      currentSnack.variant = action.msg.includes('Error') ? ERROR : SUCCESS;
      currentSnack.open = true;
      return { ...state, snack: currentSnack, changed: !state.changed, savingFirmware: false };
    case actions.REBOOT_TERM_SUCCESS:
      currentSnack.message = `${translations.snackbars.rebootSuccess} ${action.termID}`;
      currentSnack.variant = SUCCESS;
      currentSnack.open = true;
      return { ...state, snack: currentSnack, changed: !state.changed };
    case actions.REBOOT_TERM_FAILURE:
      currentSnack.message = `${translations.snackbars.rebootFail} ${action.termID}`;
      currentSnack.variant = ERROR;
      currentSnack.open = true;
      return { ...state, snack: currentSnack, changed: !state.changed };

    case actions.UPDATE_LOG_SUCCESS:
      currentSnack.message = `${translations.snackbars.logSuccess} ${action.termID}`;
      currentSnack.variant = SUCCESS;
      currentSnack.open = true;
      return { ...state, snack: currentSnack, changed: !state.changed };

    case actions.UPDATE_LOG_FAILURE:
      currentSnack.message = `${translations.snackbars.logFail} ${action.termID}`;
      currentSnack.variant = ERROR;
      currentSnack.open = true;
      return { ...state, snack: currentSnack, changed: !state.changed };

    case actions.CLEAR_KEY_EMPTY:
    case actions.CLEAR_KEY_SUCCESS:
      currentSnack.message = `${translations.snackbars.clearSuccess} ${action.termID}`;
      currentSnack.variant = SUCCESS;
      currentSnack.open = true;
      return { ...state, snack: currentSnack, selectedTerm: action.payload, changed: !state.changed };
    case actions.CLEAR_KEY_FAILURE:
      currentSnack.message = `${translations.snackbars.clearFail} ${action.termID}`;
      currentSnack.variant = ERROR;
      currentSnack.open = true;
      return { ...state, snack: currentSnack, changed: !state.changed };
    case actions.CLEAR_TABLE:
      return {
        ...state,
        changed: !state.changed,
        errorMsg: '',
        loading: true,
        selectedTerm: -1,
        terminals: [],
        type: ''
      };
    case actions.DELETE_TERM_SUCCESS:
      for (let i = 0; i < terminalList.length; i++) {
        if (terminalList[i].id === action.termID) {
          terminalList.splice(i, 1);
        }
      }
      currentSnack.message = `${translations.snackbars.deleteSuccess} ${action.termID}`;
      currentSnack.variant = SUCCESS;
      currentSnack.open = true;
      return { ...state, terminals: terminalList, snack: currentSnack, changed: !state.changed };
    case actions.DELETE_TERM_FAILURE:
      currentSnack.message = `${translations.snackbars.deleteFail} ${action.termID}`;
      currentSnack.variant = ERROR;
      currentSnack.open = true;
      return { ...state, snack: currentSnack, changed: !state.changed };

    case actions.FETCH_ROOM_BEGIN:
      return { ...state, dialogLoading: true, changed: !state.changed };
    case actions.FETCH_ROOM_SUCCESS:
      editTerm.building = action.payload.building;
      editTerm.unit = action.payload.unit;
      editTerm.floor = action.payload.floor;
      return { ...state, selectedTerm: editTerm, dialogLoading: false, changed: !state.changed };
    case actions.FETCH_ROOM_FAILURE:
      return { ...state, dialogLoading: false, changed: !state.changed };
    case actions.FETCH_ROOM_TERMINALS_BEGIN:
      return { ...state, dialogLoading: true, changed: !state.changed };
    case actions.FETCH_ROOM_TERMINALS_SUCCESS:
      if (action.payload.terminals.length === 1) {
        return { ...state };
      }
      for (let i = 0; i < action.payload.terminals.length; i++) {
        if (action.payload.terminals[i].id.toString() === action.termID.toString()) {
          continue;
        } else {
          for (let j = 0; j < state.locations.length; j++) {
            if (action.payload.terminals[i].location === state.locations[j].name) {
              state.locations.splice(j, 1);
              continue;
            }
          }
        }
      }
      return { ...state, locations: state.locations, dialogLoading: false, changed: !state.changed };

    case actions.FETCH_TERMINAL_BEGIN:
      return { ...state, dialogLoading: true, changed: !state.changed };
    case actions.FETCH_TERMINAL_SUCCESS:
      state.dialogLoading = true;
      // eslint-disable-next-line no-restricted-syntax
      if ('ip' in action.payload) {
        action.payload.type = 'ip';
        action.payload.ipAddress = action.payload.ip.address;
        action.payload.publicKey = action.payload.ip.public_key;
      } else {
        action.payload.icpHost = action.payload.rf.icp_id;
        action.payload.icpIndex = action.payload.rf.index;
        action.payload.rf_rssi = action.payload.status.rf_rssi;
        action.payload.rf_comms_percentage = action.payload.status.rf_comms_percent;
        action.payload.rf_comms_throughput = action.payload.status.rf_comms_throughput;
        action.payload.type = 'rf';
      }
      // eslint-disable-next-line no-restricted-syntax
      if ('wifi' in action.payload) {
        action.payload.wifiSsid = action.payload.wifi.ssid;
        action.payload.wifiChannel = action.payload.wifi.channel;
        action.payload.wifiRssi = action.payload.wifi.rssi;
      }

      action.payload.hardware = action.payload.versions.hardware;
      action.payload.firmware = action.payload.versions.firmware;
      action.payload.software = action.payload.versions.software;
      action.payload.tuner_type = action.payload.tv_type.tuner_type;
      action.payload.mpeg_format = action.payload.tv_type.mpeg_format;
      action.payload.cables = action.payload.tv_type.cables;
      action.payload.encryption = action.payload.tv_type.encryption;
      action.payload.ccPort = action.payload.tv_type.cc_port;

      action.payload.lowBattery = action.payload.status.low_battery_detected;
      action.payload.lowBatteryKey = action.payload.status.low_battery_key_count;
      action.payload.sca_key_is_valid = action.payload.status.sca_key_is_valid;
      action.payload.last_communicated = action.payload.status.last_communication;
      action.payload.tv_mode = action.payload.status.tv_mode;
      action.payload.input_source = action.payload.status.input_source;
      action.payload.tv_communication = action.payload.status.tv_communication;
      action.payload.host_connected = action.payload.status.host_connected;
      action.payload.tv_control = action.payload.status.tv_control;

      if (action.payload.room_id === null) {
        state.dialogLoading = false;
      }
      return { ...state, selectedTerm: action.payload, errorMsg: '', changed: !state.changed };
    case actions.FETCH_TERMINAL_FAILURE:
      return {
        // eslint-disable-next-line max-len
        ...state, selectedTerm: {}, errorMsg: 'Unable to get terminal information', dialogLoading: false, changed: !state.changed
      };

    case actions.FETCH_TERMINALS_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { terminals } = state;
      for (let j = 0; j < action.payload.terminals.length; j++) {
        action.payload.terminals[j].hardware = action.payload.terminals[j].version.hardware;
        action.payload.terminals[j].software = action.payload.terminals[j].version.software;
        action.payload.terminals[j].firmware = action.payload.terminals[j].version.firmware;
        action.payload.terminals[j].location_id = action.payload.terminals[j].location;
        for (let i = 0; i < state.lineups.length; i++) {
          if (state.lineups[i].id === action.payload.terminals[j].lineup_id) {
            action.payload.terminals[j].lineup_id = state.lineups[i].name;
            break;
          }
        }

        if (!isNaN(action.payload.terminals[j].menuset_name)) {
          const menusetName = find(state.menusets, { id: action.payload.terminals[j].menuset_name });
          action.payload.terminals[j].menuset_name = menusetName
            ? menusetName.name
            : action.payload.terminals[j].menuset_name;
        }
      }

      miscArray = action.options.sort.split(':');
      miscObj = miscArray[0] === 'room_id'
        ? [...terminals, ...action.payload.terminals]
        : orderBy([...terminals, ...action.payload.terminals], [miscArray[0], 'room_id'], [miscArray[1], 'asc']);

      return {
        ...state,
        totalEntries: action.payload.total_items,
        maxPages: action.payload.page_count,
        terminals: uniqBy(miscObj, (e) => e.id),
        loading: false,
        changed: !state.changed
      };
    case actions.FETCH_TERMINALS_FAILURE:
      state.terminals[0] = ERROR;
      return { ...state, loading: false, changed: !state.changed };
    case actions.FETCH_TERMINAL_OPT_SUCCESS:
      return {
        ...state,
        cables: action.payload.cables,
        chromecastPort: action.payload.chromecast_port,
        languages: action.payload.languages && action.payload.languages.length > 1
          ? action.payload.languages
          : [{ id: 'en', name: 'English' }],
        lineups: action.payload.lineups,
        locations: action.payload.locations,
        menusets: (action.payload.menusets && action.payload.menusets.length === 0
          ? [{ id: 0, name: 'None' }]
          : action.payload.menusets),
        networksUsed: action.payload.networks_used,
        changed: !state.changed
      };
    case actions.FETCH_TERMINAL_STATUSES_SUCCESS:
      action.terminalStatuses.notCommunicating = action.terminalStatuses.not_communicating;
      delete action.terminalStatuses.not_communicating;

      return {
        ...state,
        terminalStatuses: action.terminalStatuses,
        loading: false,
        changed: !state.changed
      };
    case actions.FETCH_TERMINAL_STATUSES_FAILURE:
      return {
        ...state,
        loading: false,
        changed: !state.changed,
        error: action.error,
        terminalStatuses: {
          total: '---',
          assigned: '---',
          unassigned: '---',
          notCommunicating: '---',
        },
      };
    case actions.SEARCH_TERMINALS_BEGIN:
    case actions.UPDATE_LOG_BEGIN:
      currentSnack.open = false;
      return {
        ...state, loading: true, terminals: [], changed: !state.changed, snack: currentSnack
      };
    case actions.SEARCH_TERMINALS_SUCCESS:
      for (let j = 0; j < action.payload.terminals.length; j++) {
        action.payload.terminals[j].hardware = action.payload.terminals[j].version.hardware;
        action.payload.terminals[j].software = action.payload.terminals[j].version.software;
        action.payload.terminals[j].firmware = action.payload.terminals[j].version.firmware;
        action.payload.terminals[j].location_id = action.payload.terminals[j].location;
        for (let i = 0; i < state.lineups.length; i++) {
          if (state.lineups[i].id === action.payload.terminals[j].lineup_id) {
            action.payload.terminals[j].lineup_id = state.lineups[i].name;
            break;
          }
        }

        if (!isNaN(action.payload.terminals[j].menuset_name)) {
          const menusetName = find(state.menusets, { id: action.payload.terminals[j].menuset_name });
          action.payload.terminals[j].menuset_name = menusetName
            ? menusetName.name
            : action.payload.terminals[j].menuset_name;
        }
      }

      miscArray = action.payload.terminals.length > 0
        ? orderBy([...action.payload.terminals], ['room_id'], ['asc'])
        : [];

      return {
        ...state,
        totalEntries: action.payload.total_items,
        loading: false,
        maxPages: action.payload.page_count,
        terminals: uniqBy(miscArray, (e) => e.id),
        changed: !state.changed
      };
    case actions.SET_SELECTED_TERM_ID:
      return { ...state, selectedTermID: action.termID };
    case actions.UPDATE_TERMINAL_FIELD:
      editTerm[`${action.name}`] = action.value;
      return { ...state, selectedTerm: { ...editTerm } };

    case actions.UPDATE_SELECTED_TERMINAL2:
      return {
        ...state,
        selectedTerm: action.terminal.term,
        type: action.terminal.type,
        rangeTerminals: [],
        selectedRangeTerminals: []
      };

    case actions.UPDATE_TERM_FIRM_BEGIN:
    case actions.UPDATE_ROOM_TERM_FIRM_BEGIN:
      currentSnack.open = false;
      return { ...state, savingFirmware: true, snack: currentSnack };

    case actions.UPDATE_TERM_FIRM_SUCCESS:
    case actions.UPDATE_ROOM_TERM_FIRM_SUCCESS:
      currentSnack.message = `${translations.snackbars.firmwareSuccess} ${action.termID}`;
      currentSnack.variant = SUCCESS;
      currentSnack.open = true;
      return {
        ...state, savingFirmware: false, type: '', selectedTerm: -1, snack: currentSnack
      };

    case actions.UPDATE_TERM_FIRM_FAILURE:
    case actions.UPDATE_ROOM_TERM_FIRM_FAILURE:
      currentSnack.message = `${translations.snackbars.firmwareFail} ${action.termID}`;
      currentSnack.variant = ERROR;
      currentSnack.open = true;
      return { ...state, savingFirmware: false, snack: currentSnack };

    case actions.RESET_RANGE:
      return { ...state, rangeTerminals: [] };

    case actions.FETCH_RANGE_TERMINALS_BEGIN:
      return { ...state, fetchingRange: true, rangeTerminals: [] };

    case actions.FETCH_RANGE_TERMINALS_SUCCESS:
      miscArray = action.rangeTerminals.map((terminal) => terminal.id);
      return {
        ...state,
        fetchingRange: false,
        rangeTerminals: [...action.rangeTerminals],
        selectedRangeTerminals: miscArray
      };

    case actions.FETCH_RANGE_TERMINALS_FAILURE:
      currentSnack.message = `${translations.snackbars.rangeFail} ${action.range} - (${action.error})`;
      currentSnack.variant = ERROR;
      currentSnack.open = true;
      return {
        ...state,
        fetchingRange: false,
        snack: currentSnack,
        changed: !state.changed
      };

    case actions.UPDATE_SELECTED_RANGE:
      miscArray = state.selectedRangeTerminals.includes(action.termID)
        ? without(state.selectedRangeTerminals, action.termID)
        : [...state.selectedRangeTerminals, `${action.termID}`];
      return { ...state, selectedRangeTerminals: miscArray };

    case actions.UPDATE_TERMINAL_RANGE:
      miscArray = action.checked ? state.rangeTerminals.map((terminal) => terminal.id) : [];
      return { ...state, selectedRangeTerminals: miscArray };

    case actions.BATCH_TERMINAL_RANGE_BEGIN:
      return { ...state, batchSaving: true };

    case actions.BATCH_TERMINAL_RANGE_SUCCESS:
      currentSnack.message = `${translations.snackbars.batchSuccess}`;
      currentSnack.variant = SUCCESS;
      currentSnack.open = true;
      return {
        ...state,
        batchSaving: false,
        rangeTerminals: [],
        selectedRangeTerminals: [],
        selectedTerm: -1,
        snack: currentSnack,
        type: ''
      };

    case actions.BATCH_TERMINAL_RANGE_FAILURE:
      currentSnack.message = `${translations.snackbars.batchFailure}`;
      currentSnack.variant = ERROR;
      currentSnack.open = true;
      return { ...state, batchSaving: false, snack: currentSnack };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    case actions.CLEAR_KEY_BEGIN:
    case actions.DELETE_TERM_BEGIN:
    case actions.FETCH_ROOM_TERMINALS_FAILURE:
    case actions.FETCH_TERMINAL_OPT_BEGIN:
    case actions.FETCH_TERMINAL_OPT_FAILURE:
    case actions.FETCH_TERMINAL_STATUS_BEGIN:
    case actions.FETCH_TERMINAL_STATUS_FAILURE:
    case actions.FETCH_TERMINAL_STATUS_SUCCESS:
    case actions.FETCH_TERMINALS_BEGIN:
    case actions.FETCH_TERMINAL_STATUSES_BEGIN:
    case actions.REBOOT_TERM_BEGIN:
    default:
      return state;
  }
}

