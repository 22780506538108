import {
  Grid, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiDivider, SonifiLabel, SonifiTableHead } from '../../../../containers';

export class Finish extends Component {
  constructor(props) {
    super(props);
  }

  getSiteTable() {
    const { currentState } = this.props;

    return (
      <Table stickyHeader>
        <SonifiTableHead
          headColumns={[
            { id: 'siteId', sortable: false, numeric: false, label: 'Site' },
          ]}
        />
        <TableBody>
          {currentState.sites.filter((site) => site.found && site.platforms.includes('Marina')).map((info, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <SonifiLabel label={`${info.id}:${info.name}`} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  }

  getReplace() {
    const { currentState, translations } = this.props;

    const tableHeader = [
      { id: 'number', sortable: false, numeric: false, label: translations.tv },
      { id: 'name', sortable: false, numeric: false, label: translations.name },
      { id: 'affiliate', sortable: false, numeric: false, label: translations.affiliate },
      { id: 'tz', sortable: false, numeric: false, label: translations.tz },
      { id: 'source_id', sortable: false, numeric: false, label: translations.sourceID },
    ];

    return (
      <Grid container>
        <Grid item xs={12}>
          <SonifiLabel boldLabel={translations.replaceThis} />
          <SonifiLabel boldLabel={translations.sourceID} label={currentState.targetChannel.name} />
          <SonifiLabel boldLabel={translations.tv} label={currentState.targetChannel.number} />
        </Grid>
        <Grid item xs={12}>
          <SonifiDivider />
        </Grid>
        <Grid item xs={12}>
          <Table stickyHeader>
            <SonifiTableHead
              headColumns={tableHeader}
              hideSortIcon={true}
            />
            <TableBody>
              {currentState.replacements.map((channel, index) => (
                <TableRow key={index}>
                  <TableCell> <SonifiLabel label={channel.number} /></TableCell>
                  <TableCell> <SonifiLabel label={channel.name} /></TableCell>
                  <TableCell> <SonifiLabel label={channel.affiliate} /></TableCell>
                  <TableCell> <SonifiLabel label={channel.timezone} /></TableCell>
                  <TableCell> <SonifiLabel label={channel.source_id} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <Grid container justifyContent="space-evenly" style={{ height: '100%' }}>
        <Grid item xs={4} style={{ height: '100%', overflowY: 'auto' }}>
          {this.getSiteTable()}
        </Grid>
        <Grid item xs={7}>
          {this.getReplace()}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.batchChannels.translations.grid
});

Finish.propTypes = {
  currentState: PropTypes.object,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(Finish);
