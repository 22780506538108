import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };
const initialState = {
  canEditDetails: false,
  changed: false,
  channelLineups: [],
  deleteLineup: null,
  lineupError: false,
  lineupErrorMessage: '',
  lineupSaving: false,
  lineupsLoading: false,
  loading: true,
  savingSuccess: false,
  selectedChannelLineup: null,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  translations: null
};

export default function tvLineupReducer(state = initialState, action) {
  switch (action.type) {
    case types.CLOSE_ERROR_LINEUP_SAVE:
      return {
        ...state,
        canEditDetails: false,
        lineupError: false,
        lineupErrorMessage: null,
        loading: false,
        savingSuccess: false
      };

    case types.CLOSE_SUCCESS_LINEUP_SAVE:
      return {
        ...state,
        canEditDetails: false,
        lineupError: false,
        lineupErrorMessage: null,
        loading: false,
        savingSuccess: false,
        selectedChannelLineup: null
      };

    case types.DELETE_LINEUP_CHECK:
      return {
        ...state,
        deleteLineup: action.lineup
      };

    case types.SET_LINEUP_SNACKBAR:
      return {
        ...state,
        snackBarMessage: action.snackBarMessage,
        snackBarType: (action.snackBarType ? action.snackBarType : state.snackBarType)
      };

    case types.DELETE_SINGLE_LINEUP_BEGIN:
      return { ...state, lineupSaving: true };

    case types.DELETE_SINGLE_LINEUP_SUCCESS:
      return {
        ...state,
        deleteLineup: null,
        lineupSaving: false,
        snackBarMessage: `${state.translations.lineupGrid.deleteSuccess} ${action.lineupName}`,
        snackBarType: SUCCESS
      };

    case types.DELETE_SINGLE_LINEUP_FAILURE:
      return {
        ...state,
        deleteLineup: null,
        lineupSaving: false,
        snackBarMessage: `${state.translations.lineupGrid.deleteError} ${action.lineupName}`,
        snackBarType: ERROR
      };

    case types.SET_CHANNEL_LINEUPS:
      return { ...state, channelLineups: action.channelLineups, lineupsLoading: action.loading };

    case types.SET_SELECTED_CHANNEL_LINEUP:
      return { ...state, selectedChannelLineup: action.selectedChannelLineup };

    case types.SET_LINEUPS_LOADING:
      return { ...state, lineupsLoading: action.loading, lineupErrorMessage: null };

    case types.SET_LINEUPS_SAVING:
      return {
        ...state, lineupSaving: action.saving, lineupErrorMessage: null, lineupError: false
      };

    case types.DETAILS_EDIT_SUCCESS:
      return {
        ...state, changed: !state.changed, canEditDetails: action.editable, loading: false
      };

    case types.SET_LINEUPS_ERROR:
      return {
        ...state,
        lineupSaving: false,
        lineupsLoading: false,
        lineupError: action.error,
        lineupErrorMessage: action.message
      };

    case types.SET_TV_LINEUP_SUCCESS:
      return { ...state, savingSuccess: true };

    case types.LOADING_LINEUP:
      return { ...state, loading: action.loading };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
