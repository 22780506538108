import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { TableCell, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';

const styles = () => ({
  badStatus: {
    color: 'red !important'
  },
  goodStatus: {
    color: 'green !important'
  }
});

class TerminalRow extends Component {
  /* eslint-disable camelcase */
  getTerminalStatus() {
    const { classes, terminal, translations } = this.props;
    const {
      host_connected, rf_comms_percent, passive_status
    } = terminal;

    if (((host_connected === false || host_connected === null) &&
      (rf_comms_percent === null || rf_comms_percent === 0)) ||
      passive_status !== 'good') {
      return (<Tooltip title={translations.tooltips.badTerm}>
        <ErrorOutline className={classes.badStatus} />
      </Tooltip>);
    }

    return (
      <Tooltip title={translations.tooltips.goodTerm}>
        <CheckCircleOutline className={classes.goodStatus} />
      </Tooltip>
    );
  }

  /* eslint-disable camelcase */
  render() {
    const { classes, terminal, translations } = this.props;
    const {
      firmware, hardware, id, lineup_id, location, menuset_name, room_id, software
    } = terminal;

    return (
      <Fragment>
        <TableCell>{this.getTerminalStatus()}</TableCell>
        <TableCell>{room_id === null ? ' unassigned ' : room_id}</TableCell>
        <TableCell>{id}</TableCell>
        <TableCell>{location}</TableCell>
        <TableCell>{lineup_id}</TableCell>
        <TableCell>{menuset_name}</TableCell>
        <TableCell>{firmware}</TableCell>
        <TableCell>{hardware}</TableCell>
        <TableCell>{software}</TableCell>
        <TableCell>
          {!!terminal.log_forwarding && <Tooltip title={translations.tooltips.loggingOn}>
            <CheckCircleOutline className={classes.goodStatus} />
          </Tooltip>}
        </TableCell>
      </Fragment >
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.termGrid.translations,
});

TerminalRow.propTypes = {
  classes: PropTypes.object.isRequired,
  terminal: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(TerminalRow, styles, { withTheme: true }));

