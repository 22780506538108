import { Grid, Tooltip } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { SonifiLabel } from '../../../containers';
import DragNDropIcon from '../../../containers/DragNDrop/DragNDropIcon';

const styles = () => ({
  centered: {
    display: 'flex',
    alignItems: 'center'
  },
  leftRightCenter: {
    justifyContent: 'center'
  }
});

class CheckoutRow extends Component {
  render() {
    const {
      classes, drag, isDragging, readOnly, rowIndex, translations
    } = this.props;

    const { option } = this.props;
    const { quantity, price, time } = option;

    return (
      <Grid container onContextMenu={this.props.menuEvent(rowIndex)}>
        <Grid item xs={1}>
          <Grid container style={{ height: '48px' }}>
            <Grid item xs={6}
              {...drag.dragHandleProps}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              {!readOnly &&
                <Tooltip title={translations.moveOption}>
                  <div>
                    <DragNDropIcon style={{
                      cursor: 'move',
                      width: '20px',
                      verticalAlign: 'middle',
                      filter: `${isDragging
                        ? 'brightness(0) invert(1)'
                        : 'brightness(1) invert(0)'}`
                    }} />
                  </div>
                </Tooltip>
              }
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} >
          <Grid container style={{ height: '100%' }} onClick={this.props.menuEvent(rowIndex)} >
            <Grid item xs={3} className={classes.centered}>
              <SonifiLabel label={moment(time).format('h:mm A')} />
            </Grid>
            <Grid item xs={2} className={`${classes.centered} ${classes.leftRightCenter}`}>
              <SonifiLabel label={`${quantity.default}`} />
            </Grid>
            <Grid item xs={2} className={`${classes.centered} ${classes.leftRightCenter}`}>
              <SonifiLabel label={`${quantity.today}`} />
            </Grid>
            <Grid item xs={2} className={`${classes.centered} ${classes.leftRightCenter}`}>
              <SonifiLabel label={`${quantity.purchased}`} />
            </Grid>
            <Grid item xs={3}>
              <SonifiLabel className={classes.centered}
                label={(price / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.lateCheckout.translations.options
});

CheckoutRow.propTypes = {
  classes: PropTypes.object.isRequired,
  drag: PropTypes.object,
  isDragging: PropTypes.bool,
  menuEvent: PropTypes.func,
  option: PropTypes.object,
  readOnly: PropTypes.bool,
  rowIndex: PropTypes.number.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(CheckoutRow, styles, { withTheme: true }));

