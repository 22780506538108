import {
    Grid, Table, TableBody,
    TableCell, TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import SonifiCheckbox from '../../../../containers/SonifiCheckbox';
import SonifiLabel from '../../../../containers/SonifiLabel';
import { updateChangeVariable, updateChannelDelivery } from '../../actions/siteManagementActions';

const styles = () => ({
  tableTop: {
    borderTop: '1px solid #dedfdf',
    borderBottom: 'none'
  }
});

class ChannelDelivery extends Component {
  editToggleValue = (name) => ({ target: { checked } }) => {
    this.props.dispatch(updateChannelDelivery(name, checked));
    this.props.dispatch(updateChangeVariable(false));
  };

  render() {
    const { channelDelivery, classes, readOnly, translations } = this.props;

    return (
      <Fragment>
        <Grid container>
          <Grid item>
            <SonifiLabel boldLabel={translations.channelDelivery} />
          </Grid>
        </Grid>
        <Table className={classes.tableTop}>
          <TableBody>
            {channelDelivery.map(({ name, checked, inUse }) => (
              <TableRow key={name}>
                <TableCell component="th" scope="row">
                  <SonifiCheckbox
                    value={checked}
                    onChange={this.editToggleValue(`${name}`)}
                    label={`${name}`}
                    removeTopPadding={true}
                    disabled={readOnly || (checked && inUse)}
                    disableRipple={readOnly || (checked && inUse)} />
                </TableCell>
              </TableRow>))}
          </TableBody>
        </Table>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  channelDelivery: state.siteManagement.channelDelivery,
  translations: state.siteManagement.translations.general
});

ChannelDelivery.propTypes = {
  channelDelivery: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  readOnly: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(ChannelDelivery, styles, { withTheme: true }));
