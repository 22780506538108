import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import {
  ADD_USER_BEGIN, ADD_USER_FAILURE, ADD_USER_SUCCESS, CLEAR_ALL_USERS, DELETE_CHECK,
  DELETE_USER_BEGIN, DELETE_USER_FAILURE, DELETE_USER_SUCCESS, DISPLAY_SORTED_USERS, FETCH_USERS_BEGIN,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS, FILTER_SITES_BEGIN, FILTER_SITES_FAILURE, FILTER_SITES_SUCCESS, GET_SITES_FAILURE,
  GET_SITES_SUCCESS, GET_USER_SITES_BEGIN, GET_USER_SITES_FAILURE, GET_USER_SITES_SUCCESS, INITIAL_LOAD_TRIGGERED,
  RESEND_PASSWORD_FAILURE, RESEND_PASSWORD_SUCCESS, SET_USER_FILTER, SORT_USERS_BEGIN, UPDATE_SELECTED_USER,
  UPDATE_SNACK_BAR
} from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  addingUser: false,
  allowEditing: true,
  userToDelete: null,
  filterLoad: false,
  gettingUserSites: false,
  users: [],
  loading: false,
  maxPages: 1,
  selectedUser: null,
  siteFilterLoad: false,
  sites: null,
  snackBar: SUCCESS,
  snackBarMessage: '',
  snackbarOpen: false,
  sortUsers: false,
  translations: null,
  userFilter: ''
};

/* eslint-disable max-lines-per-function */
export default function usersReducer(state = initialState, action) {
  let miscObj = {},
    miscArray = null;

  switch (action.type) {
    case ADD_USER_BEGIN:
      return {
        ...state,
        addingUser: true,
        snackbarOpen: false,
        snackBarMessage: ''
      };

    case ADD_USER_SUCCESS:
      return {
        ...state,
        addingUser: false,
        selectedUser: null,
        snackbarOpen: true,
        snackBar: SUCCESS,
        snackBarMessage: (action.sendEmail
          ? state.translations.grid.resendEmail
          : state.translations.grid.updateSuccess)
      };

    case ADD_USER_FAILURE:
      return {
        ...state,
        addingUser: false,
        snackbarOpen: true,
        snackBar: ERROR,
        snackBarMessage: `${(action.sendEmail
          ? state.translations.grid.errors.resendEmail
          : state.translations.grid.errors.addFailure)} - ${action.error}`
      };

    case CLEAR_ALL_USERS:
      return {
        ...state,
        users: []
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        selectedUser: null,
        sortUsers: false,
        snackbarOpen: true,
        snackBar: SUCCESS,
        snackBarMessage: `${state.translations.grid.deleteSuccess} ${action.username}`,
        users: action.users,
        userToDelete: null
      };

    case DELETE_USER_FAILURE:
      return {
        ...state,
        sortUsers: false,
        selectedUser: null,
        snackbarOpen: true,
        snackBar: ERROR,
        snackBarMessage: `${state.translations.grid.deleteFailure} ${action.error}`,
        userToDelete: null
      };

    case FETCH_USERS_BEGIN:
      return {
        ...state,
        filterLoad: false,
        loading: true,
        selectedUser: null,
        siteFilterLoad: false,
        snackbarOpen: false
      };

    case DELETE_USER_BEGIN:
      return {
        ...state
      };

    case DISPLAY_SORTED_USERS:
      return {
        ...state,
        users: [...action.users]
      };
    case SORT_USERS_BEGIN:
      return {
        ...state,
        userToDelete: null,
        sortUsers: true
      };

    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        filterLoad: false,
        users: [...state.users, ...action.users],
        paginationToken: action.paginationToken,
        lastPage: !action.paginationToken,
        loading: false,
        siteFilterLoad: false,
        sortUsers: false
      };

    case FETCH_USERS_FAILURE:
      return {
        ...state,
        allowEditing: action.allowEditing,
        users: [],
        loading: false,
        snackBar: ERROR,
        snackBarMessage: action.error,
        snackbarOpen: true,
        sortUsers: false
      };

    case INITIAL_LOAD_TRIGGERED:
      return {
        ...state,
        initDataLoad: action.bool
      };

    case UPDATE_SELECTED_USER:
      return { ...state, selectedUser: action.user, snackbarOpen: false };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    case FILTER_SITES_SUCCESS:
      return {
        ...state,
        sites: action.sites,
        siteFilterLoad: false,
        maxPages: action.sites.page_count
      };

    case GET_SITES_SUCCESS:
      miscArray = (state.sites === null ? [...action.sites.sites] : [...state.sites.sites, ...action.sites.sites]);
      return {
        ...state,
        sites: { sites: miscArray },
        siteFilterLoad: false,
        maxPages: action.sites.page_count
      };

    case GET_SITES_FAILURE:
      return {
        ...state,
        sites: {}
      };

    case GET_USER_SITES_BEGIN:
      return {
        ...state,
        gettingUserSites: true
      };

    case GET_USER_SITES_SUCCESS:
      miscObj = { ...state.selectedUser };
      miscObj.sites = (action.sites ? action.sites : []);
      return {
        ...state,
        gettingUserSites: false,
        selectedUser: miscObj,
      };

    case GET_USER_SITES_FAILURE:
      return {
        ...state,
        gettingUserSites: false
      };

    case UPDATE_SNACK_BAR:
      return {
        ...state,
        snackbarOpen: action.open,
        snackBar: action.messageType,
        snackBarMessage: action.message
      };

    case FILTER_SITES_BEGIN:
      return {
        ...state,
        siteFilterLoad: true
      };

    case FILTER_SITES_FAILURE:
      return {
        ...state
      };

    case DELETE_CHECK:
      return {
        ...state,
        userToDelete: action.user
      };

    case RESEND_PASSWORD_SUCCESS:
      return {
        ...state,
        selectedUser: null,
        snackbarOpen: true,
        snackBar: SUCCESS,
        snackBarMessage: state.translations.grid.resendEmail
      };

    case RESEND_PASSWORD_FAILURE:
      return {
        ...state,
        selectedUser: null,
        snackbarOpen: true,
        snackBar: ERROR,
        snackBarMessage: state.translations.grid.errors.resendEmail
      };

    case SET_USER_FILTER:
      return {
        ...state,
        userFilter: action.filter
      };

    default:
      return state;
  }
}
