import { TERMINAL_OPTIONS, TERMINAL_RESOURCE } from '../constants/apiEndpoints';
import { httpSuccess } from '../constants/http';
import { deleteObjectPromise, getSiteObjectPromise, putObjectPromise } from './api';

export function addNewTerminal(terminal, roomId) {
  terminal.room_id = roomId;

  return getSiteObjectPromise(TERMINAL_RESOURCE, terminal.id)
    .then((data) => {
      const newTerm = {
        ...data,
        ...terminal
      };

      delete newTerm.isNew;
      return updateTerminal(newTerm)
        .then(() => `${terminal.id} was successfully added`);
    }).catch(() => `${terminal.id} Error`);
}

export function unassociateTerminal(terminal) {
  terminal.room_id = null;
  terminal.location = null;
  return updateTerminal(terminal);
}

export function swapTerminals(terminal) {
  const newTerminal = { ...terminal.swapInfo };
  newTerminal.location = terminal.location;
  newTerminal.language = terminal.language;
  newTerminal.censor_level = terminal.censor_level;
  newTerminal.menuset = terminal.menuset;
  newTerminal.lineup_id = terminal.lineup_id;
  newTerminal.room_id = terminal.room_id;
  delete newTerminal.terminalId;

  /* return unassociateTerminal(terminal)
    .then(() => updateTerminal(newTerminal)
      .then(() => 'Swapping Terminals was successful'));*/

  return deleteTerminal(terminal.id)
    .then(() => updateTerminal(newTerminal)
      .then(() => 'Swapping Terminals was successful'));
}

export function updateTerminal(terminal, siteId = null) {
  const terminalId = terminal.id;
  delete terminal.id;
  delete terminal.index;
  delete terminal.remove;
  delete terminal.edited;
  delete terminal.swap;
  delete terminal.swapId;
  delete terminal.swapInfo;
  if (terminal.tv_lineup_id) {
    terminal.lineup_id = terminal.tv_lineup_id;
    delete terminal.tv_lineup_id;
  }
  delete terminal.site_id;
  terminal.room_id = terminal.room_id ? terminal.room_id.trim() : '';

  return putObjectPromise(TERMINAL_RESOURCE, terminalId, terminal, siteId)
    .then((data) => {
      if (data.status && !httpSuccess(data.status)) {
        return `Error Updating Terminal ${terminalId}`;
      }
      return `Updated Terminal ${terminalId}`;
    })
    .catch(() => `Error Updating Terminal ${terminalId}`);
}

export function getTerminalOptions() {
  return getSiteObjectPromise(TERMINAL_OPTIONS, null)
    .then((data) => data)
    .catch((error) => error);
}

export function deleteTerminal(terminal) {
  return deleteObjectPromise(TERMINAL_RESOURCE, terminal)
    .then((json) => json)
    .catch((error) => error);
}
