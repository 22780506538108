import {
  Alert, Button, PasswordField, TextField, View
} from '@aws-amplify/ui-react';
import { Auth, I18n } from 'aws-amplify';
import decode from 'jwt-decode';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import * as actions from '../../../actions/globalActions';
import '../../../AmplifyTheme.css';
import { KEYCODE_ENTER } from '../../../constants/keyCodes';
import { setToken } from '../../../utils';
import FormFrame from './FormFrame';

const formFields = {
  signIn: {
    username: {
      name: 'username',
      labelHidden: false,
      placeholder: 'Enter your email address',
      isRequired: true,
      label: 'Email Address *'
    },
    password: {
      name: 'password',
      labelHidden: false,
      placeholder: 'Enter your password',
      isRequired: true,
      label: 'Password *'
    },
    rememberDevice: {
      name: 'remember-device',
      label: 'Remember me ?',
      value: 'yes'
    }
  }
};

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const signInHander = async () => {
    try {
      props.setAuthState('loggingIn');

      const user = await Auth.signIn(username, password);

      props.setUser(user);


      const session = user.getSignInUserSession();
      if (session) {
        setToken(session.idToken.jwtToken);
        props.dispatch(actions.updateCurrentUser(decode(session.idToken.jwtToken)));
      } else {
        console.log('User not yet authenticated');
        props.setAuthState('confirmSignIn');
      }
    } catch(error) {
      console.log(error);
    }
  };

  const handleLoginEnter = (e) => {
    if (e.keyCode === KEYCODE_ENTER) {
      signInHander();
    }
  };

  return (
    <FormFrame>
      <View as="form" id="signin">
        <TextField {...formFields.signIn.username}
          onChange={(event) => setUsername(event.target.value)}
          onKeyDown={handleLoginEnter}
          data-testid="login-username-field"

        />
        <PasswordField {...formFields.signIn.password}
          onChange={(event) => setPassword(event.target.value)}
          onKeyDown={handleLoginEnter}
          data-testid="login-password-field"
        />
        {props.signInMsg.msg &&
          <Alert
            variation={props.signInMsg.type}
            isDismissible={false}
            hasIcon={true}
            heading={props.signInMsg.heading ?? undefined}
          >{props.signInMsg.msg}</Alert>
        }
        <Button
          variation="primary"
          isFullWidth
          disabled={props.authState === 'loggingIn' && !props.signInMsg.msg}
          onClick={signInHander}
          type="button"
        >{I18n.get('Sign In')}</Button>
        <Button
          variation="link"
          isFullWidth
          disabled={props.authState === 'loggingIn' && !props.signInMsg.msg}
          onClick={() => props.setAuthState('resetPassword')}
        >{I18n.get('Forgot your password?')}</Button>
      </View>
    </FormFrame>
  );
};

Login.propTypes = {
  authState: PropTypes.string,
  dispatch: PropTypes.func,
  setAuthState: PropTypes.func,
  setUser: PropTypes.func,
  signInMsg: PropTypes.object
};

export { Login };
export default Login;
