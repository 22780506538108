import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  ROOM_DETAIL_EDIT, ROOM_DETAIL_EDIT_LIMITED, TERMINAL_DETAIL_EDIT, TERMINAL_DETAIL_EDIT_LIMITED
} from '../../../constants/roles';
import SonifiCheckbox from '../../../containers/SonifiCheckbox';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiText from '../../../containers/SonifiText';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';

class GuestStayInfo extends Component {
  state = {
    availableLanguages: this.props.languages
  };

  render() {
    const {
      language, isOccupied, stayIdProp, translations, userPermissions
    } = this.props;
    const { availableLanguages } = this.state;
    return (
      <Fragment>
        {checkForAtLeastOneUserPermission([TERMINAL_DETAIL_EDIT, TERMINAL_DETAIL_EDIT_LIMITED], userPermissions) &&
          <SonifiText
            label={translations.editDialog.roomLang}
            select
            items={availableLanguages.map((suggestion) => ({
              id: suggestion.sequence,
              value: suggestion.iso_code_639_1,
              label: suggestion.name,
            }))}
            defaultValue={`${language}`}
            change={this.props.onChange('language')}
            size="sm"
          />}
        {checkForAtLeastOneUserPermission([ROOM_DETAIL_EDIT, ROOM_DETAIL_EDIT_LIMITED], userPermissions)
          ? <SonifiCheckbox
            label={translations.editDialog.occupied}
            onChange={this.props.checkboxChange('is_occupied')}
            value={isOccupied || false}
          />
          : <SonifiLabel label={`${translations.editDialog.roomIs} 
                ${isOccupied ? translations.editDialog.occupied : translations.editDialog.notOccupied}`} />}

        {isOccupied &&
          <SonifiText
            label={translations.editDialog.stayIdProperty}
            disabled={true}
            defaultValue={stayIdProp}
            size="lg"
          />
        }
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  languages: state.global.languages,
  translations: state.rooms.translations,
  userPermissions: state.global.permissions
});

GuestStayInfo.propTypes = {
  checkboxChange: PropTypes.func.isRequired,
  isOccupied: PropTypes.bool,
  language: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  stayIdProp: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(GuestStayInfo);
