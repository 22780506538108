import {
  Dialog, DialogContent, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { filter, find, map } from 'lodash';
import lOrderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpSuccess } from '../../../constants/http';
import {
  SonifiLabel, SonifiModalHeader, SonifiSpinner, SonifiTableHead, SonifiTextInline
} from '../../../containers';
import { findObjectByKey } from '../../../utils';
import { addManualVersion, getVirtosPkgs } from '../../Deployments/actions/deploymentsActions';
import { releaseSaving, showCreateNewDeployment } from '../actions/releaseTemplateActions';

export class CreateNewReleaseDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedPkgs: null,
      currentSelections: null,
      editRow: -1,
      errors: '',
      gotVirtosPkgs: false,
      order: 'asc',
      orderBy: 'name'
    };

    this.handleChange = this.handleChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }


  componentDidMount() {
    const { dispatch, releaseInfo } = this.props;
    dispatch(getVirtosPkgs(releaseInfo.virtos))
      .then((virtPkgs) => {
        const pkgs = filter(virtPkgs, (o) => find(releaseInfo.packages, (obj) => {
          if (obj.name === o.name && `${obj.version}` === o.version.split('.')[0]) {
            return true;
          }
        }));

        const currentSelections = {};
        const allowedPkgs = [];
        for (const pkg of releaseInfo.packages) {
          const pkgVersions = filter(pkgs, (o) => o.name === pkg.name);
          allowedPkgs[pkg.name] =
            map(pkgVersions, 'version').map((a) => a.split('.').map((n) => +n + 100000).join('.')).sort()
              .map((a) => a.split('.').map((n) => +n - 100000).join('.'));
          currentSelections[pkg.name] = allowedPkgs[pkg.name][allowedPkgs[pkg.name].length - 1];
        }

        this.setState({ gotVirtosPkgs: true, allowedPkgs, currentSelections });
      });
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(showCreateNewDeployment(false));
    }
  }

  onSubmit() {
    const { dispatch, releaseInfo } = this.props;
    dispatch(releaseSaving(true));
    dispatch(addManualVersion(releaseInfo.id, releaseInfo.product, this.state.currentSelections))
      .then((data) => {
        if (data.status && !httpSuccess(data.status)) {
          this.setState({ errors: `${data.detail} - This version may already exist in Product Deployments` });
        } else {
          dispatch(showCreateNewDeployment(false));
        }

        dispatch(releaseSaving(false));
      });
  }

  getVersionOptions(pkgName, version) {
    const { allowedPkgs } = this.state;
    return allowedPkgs[pkgName] && allowedPkgs[pkgName].length > 1
      ? <SonifiTextInline
        select
        items={
          allowedPkgs[pkgName].map((suggestion) => ({
            id: suggestion,
            value: suggestion,
            label: suggestion
          }))
        }
        size="percent"
        noPadding
        defaultValue={version}
        change={this.handleChange}
      />
      : <SonifiLabel label={`${version}${allowedPkgs[pkgName].length > 1 ? '*' : ''}`} />;
  }

  handleChange = ({ target: { value } }) => {
    const currentSelections = this.state.currentSelections;
    currentSelections[this.state.editRow] = value;

    this.setState({ currentSelections });
  };

  updatePackageVersion(index) {
    this.setState({ editRow: index });
  }

  getShortName(product) {
    const { productParents } = this.props;
    const shortName = findObjectByKey(productParents, 'id', product);
    return shortName ? shortName.name : product;
  }

  getTitle() {
    const { releaseInfo, translations } = this.props;
    let title = `${translations.createRelease.title} - ${this.getShortName(releaseInfo.product)}`;
    title += ` ${translations.editDialog.version} ${releaseInfo.version}`;
    return title;
  }

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    }, () => {
      this.props.releaseInfo.packages = lOrderBy(this.props.releaseInfo.packages, [property], [this.state.order]);
    });
  };

  render() {
    const {
      gettingVirtosPkgs, gettingPackages, globalTranslations, translations, releaseInfo
    } = this.props;
    const {
      allowedPkgs, currentSelections, editRow, errors, order, orderBy, gotVirtosPkgs
    } = this.state;
    const { packageName, version } = translations.editDialog;

    const tableHeader = [
      { id: 'name', sortable: releaseInfo.packages.length > 1, numeric: false, label: `${packageName}` },
      { id: 'version', sortable: releaseInfo.packages.length > 1, numeric: false, label: `${version}` }
    ];

    return (
      <Dialog open={true}
        onClose={this.onCancel}
        fullWidth
        maxWidth="md" >
        <SonifiModalHeader
          header={this.getTitle()}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          label={globalTranslations.save}
        />
        <DialogContent>
          <div style={{
            border: 'none', flexGrow: 1, height: '100%', overflowX: 'hidden', width: '100%'
          }}>
            {errors !== '' && <SonifiLabel error label={errors} />}
            <Table>
              <SonifiTableHead
                headColumns={tableHeader}
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
              />
              {gettingVirtosPkgs || (gettingPackages && releaseInfo.packages.length === 0) ||
                !gotVirtosPkgs ||
                currentSelections === null || allowedPkgs === null
                ? <TableBody>
                  <TableRow>
                    <TableCell colSpan={2}>
                      <SonifiSpinner />
                    </TableCell>
                  </TableRow>
                </TableBody>
                : <TableBody>
                  {releaseInfo.packages.map((option, index) => (
                    <TableRow key={`w_${index}`} onClick={this.updatePackageVersion.bind(this, option.name)}>
                      <TableCell component="th" scope="row">
                        <SonifiLabel label={option.name} />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {
                          editRow === option.name
                            ? this.getVersionOptions(option.name, currentSelections[option.name])
                            : <SonifiLabel
                              label={`${currentSelections[option.name]}
                        ${allowedPkgs[option.name] && allowedPkgs[option.name].length > 1 ? '*' : ''}`} />
                        }
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>}
            </Table>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  gettingPackages: state.deployments.gettingPackages,
  gettingVirtosPkgs: state.deployments.gettingVirtosPkgs,
  globalTranslations: state.global.translations.defaults,
  productParents: state.deployments.productParents,
  releaseInfo: state.releaseTemplates.releaseInfo,
  translations: state.releaseTemplates.translations,
});

CreateNewReleaseDialog.propTypes = {
  dispatch: PropTypes.func,
  gettingPackages: PropTypes.bool,
  gettingVirtosPkgs: PropTypes.bool,
  globalTranslations: PropTypes.object,
  productParents: PropTypes.array,
  releaseInfo: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(CreateNewReleaseDialog);
