import { Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import SonifiSubHeader from './SonifiSubHeader';

const styles = (theme) => ({
  contents: {
    backgroundColor: theme.palette.defaults.disabledBackground,
    boxShadow: 'none',
    padding: '15px',
    width: '100%',
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  editContent: {
    height: '100%',
    overflow: 'hidden'
  },
  innerContents: {
    width: '100%',
    overflowX: 'auto',
    marginTop: 15,
    border: 'none',
    flexGrow: 1,
  },
  paperView: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  paddingBottom: {
    paddingBottom: '20px'
  },
  siteSelect: {
    height: '77%',
    minHeight: '77%'
  },
  siteSelectIndividual: {
    height: '92%',
    minHeight: '92%'
  }
});

export class SonifiTemplate extends Component {
  render() {
    const {
      classes, disabled, header, icon, label, middleExtra, multiple, onSubmit, paddingBottom, pageDetails, showButton,
      toolTip
    } = this.props;

    return (
      <Grid container className={`${classes.editContent} ${!!paddingBottom && classes.paddingBottom}`}>
        <SonifiSubHeader
          disabled={disabled}
          header={header}
          icon={icon}
          label={label}
          middleExtra={middleExtra}
          onSubmit={onSubmit}
          showButton={!!showButton}
          toolTip={toolTip}
        />

        <Grid item xs={12} className={(multiple ? classes.siteSelect : classes.siteSelectIndividual)}>
          <div className={classes.paperView}>
            <Paper className={classes.contents}>
              <Paper className={classes.innerContents}>
                {pageDetails}
              </Paper>
            </Paper>
          </div>
        </Grid>
      </Grid>
    );
  }
}

SonifiTemplate.propTypes = {
  classes: PropTypes.object,
  disabled: PropTypes.bool,
  header: PropTypes.string,
  icon: PropTypes.object,
  label: PropTypes.string,
  middleExtra: PropTypes.object,
  multiple: PropTypes.bool,
  onSubmit: PropTypes.func,
  paddingBottom: PropTypes.bool,
  pageDetails: PropTypes.object.isRequired,
  showButton: PropTypes.bool,
  toolTip: PropTypes.string
};

export default withStyles(SonifiTemplate, styles, { withTheme: true });
