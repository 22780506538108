import { Add as AddIcon } from '@mui/icons-material';
import { Dialog, DialogContent, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { LOGOS } from '../../../constants/apiEndpoints';
import { ADD } from '../../../constants/constants';
import { MAX_SIZE } from '../../../constants/magic';
import SonifiDropZone from '../../../containers/SonifiDropZone/SonifiDropZone';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import { saveLogo, updateSelectedLogo } from '../actions/logoActions';
import SiteSelector from '../containers/SiteSelector';
import { validLogo } from '../utils/validator';

const styles = () => ({
  editContent: {
    height: '100%',
    overflow: 'hidden'
  },
  siteSelect: {
    height: '70%'
  },
  topLogo: {
    height: '30%'
  },
  oneHundred: {
    height: '100%',
    width: '100%'
  },
  oneHundredMax: {
    height: '100%',
    maxWidth: '100%',
    display: 'block',
    margin: 'auto',
    padding: '1%'
  },
  preview: {
    height: '98%',
    border: '1px solid #d6d6d6'
  },
  ninetyEight: {
    height: '98%'
  },
  textCenter: {
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '100%'
  },
  textTransform: {
    transform: 'translate(0%, 100%)',
  },
  noPadding: {
    fontWeight: 400, /* Avenir 87 */
    fontFamily: 'Open Sans',
    fontSize: '14pt',
    padding: 0
  },
});

class LogoDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {},
      imageBinary: null,
      imageFiles: [],
      sources: []
    };
    
    this.onDropAccepted = this.onDropAccepted.bind(this);
    this.getImagePreview = this.getImagePreview.bind(this);
    this.dropZoneContent = this.dropZoneContent.bind(this);
  }

  componentDidMount() {
    const { maps, selectedLogo } = this.props;
    this.setState({
      ...maps[selectedLogo],
      id: maps[selectedLogo].id === ADD ? Date.now() : maps[selectedLogo].id
    }, () => {
      const { isNew, raster, vector } = this.state;
      const imageName = (vector ? vector : raster);
      if (imageName && imageName.length > 0 && !isNew) {
        fetch(`${process.env.REACT_APP_IPG_URL}/${LOGOS}/${imageName}`)
          .then((response) => response.blob())
          .then((myBlob) => {
            const objectURL = URL.createObjectURL(myBlob);
            this.setState({ imageFiles: [{ preview: objectURL }] });
          });
      }
    });
  }

  updateSources(sources) {
    this.setState({ sources });
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      if (this.state.imageFiles.length > 0) {
        window.URL.revokeObjectURL(this.state.imageFiles[0].preview);
      }
      this.props.dispatch(updateSelectedLogo(-1));
    }
  }

  onSubmit() {
    const isValidLogo = validLogo(this.state, this.props.translations.errors);
    this.setState({ errors: isValidLogo });
    if (Object.entries(isValidLogo).length === 0) {
      this.props.dispatch(saveLogo(this.state, this.props.sort));
    }
  }

  onDropAccepted = (acceptedFiles) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({
        imageFiles: acceptedFiles.map((file) => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })),
        imageBinary: e.target.result
      });
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  getImagePreview() {
    const { classes } = this.props;
    return (<div className={classes.ninetyEight}>
      {this.state.imageFiles.map(
        (file, index) => <img key={index} className={classes.oneHundredMax} src={file.preview} alt="Channel Logo" />
      )}
    </div>);
  }

  dropZoneContent() {
      const { classes, globalTranslations } = this.props;

      return (
      <Grid container className={`${classes.textCenter}`}>
          <Grid item xs={12}>
          <Grid container className={classes.textCenter}>
              <Grid item>
              <AddIcon />
              </Grid>
              <Grid item>
              <SonifiLabel additionalClasses={classes.noPadding} label=".png" />
              </Grid>
          </Grid>
          </Grid>
          <Grid item>
          <SonifiLabel additionalClasses={classes.noPadding} label={globalTranslations.importDialog.browsePng} />
          </Grid>
      </Grid>
      );
  }

  getDropZone() {
    const { imageFiles } = this.state;
    const { canEdit, editType } = this.props;

    const readOnly = editType === 'read' || !canEdit;
    return (
      <SonifiDropZone
        acceptedFiles={['image/png', 'image/svg']}
        allowMultiple={false}
        displayOnlyContent={this.getImagePreview}
        displayDropZoneContent={this.dropZoneContent}
        disabled={readOnly}
        files={imageFiles}
        maxSize={MAX_SIZE}
        onAcceptedDropHandler={this.onDropAccepted}
      />
    );
  }

  render() {
    const {
      canEdit, classes, globalTranslations, maps, selectedLogo, translations, getLogoLoading, editType
    } = this.props;

    const title = (maps[selectedLogo] && maps[selectedLogo].isNew
      ? translations.addNew
      : (editType === 'edit' ? translations.title : translations.viewLogo));

    const readOnly = editType === 'read' || !canEdit;

    return (
      <Dialog open={true}
        onClose={this.onCancel.bind(this)}
        fullWidth
        maxWidth="lg" >
        {getLogoLoading
          ? <DialogContent >
            <SonifiSpinner />
          </DialogContent>
          : <Fragment><SonifiModalHeader
            header={title}
            onCancel={this.onCancel.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            onlyClose={readOnly}
            label={globalTranslations.defaults.save}
          />
            <DialogContent >
              <Grid container className={classes.editContent}>
                <Grid item xs={12} className={classes.topLogo}>
                  <Grid container justifyContent="center" className={classes.oneHundred}>
                    <Grid item xs={4} className={classes.preview}>
                      {
                        this.getDropZone()
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.siteSelect}>
                  <SiteSelector canEdit={!readOnly} updateSources={this.updateSources.bind(this)}
                    errors={!!this.state.errors.sources} />
                </Grid>
              </Grid>
            </DialogContent>
          </Fragment>}
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  editType: state.logo.editType,
  getLogoLoading: state.logo.getLogoLoading,
  globalTranslations: state.global.translations,
  maps: state.logo.maps,
  selectedLogo: state.logo.selectedLogo,
  translations: state.logo.translations.editDialog
});

LogoDialog.propTypes = {
  canEdit: PropTypes.bool,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  getLogoLoading: PropTypes.bool,
  globalTranslations: PropTypes.object,
  maps: PropTypes.array,
  selectedLogo: PropTypes.number,
  sort: PropTypes.string,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(LogoDialog, styles, { withTheme: true }));
