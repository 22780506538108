export function validateProfile(profile) {
  const loginErrors = {};

  const firstNameError = validUsername(profile.given_name, 'First Name');
  if (firstNameError !== null) {
    loginErrors.given_name = firstNameError;
  }

  const lastNameError = validUsername(profile.family_name, 'Last Name');
  if (lastNameError !== null) {
    loginErrors.family_name = lastNameError;
  }

  const phoneError = validPhoneNumber(profile.phone_number, profile.country);
  if (phoneError !== null) {
    loginErrors.phone_number = phoneError;
  }

  return loginErrors;
}

export function validUsername(username, type) {
  if (username === null || username.trim() === '') {
    return `Need to enter a ${type}`;
  }
  return null;
}

export function validPhoneNumber(phone, selectedCountry) {
  if (phone === undefined || !phone || phone.length === 0) {
    return 'Need to enter a phone number';
  }

  const DEFAULT_COUNTRY_FORMAT_LENGTH = 17;
  // This is some incorrect/default from react-phone-input-2 when no format is available

  if (selectedCountry) {
    const validNumberCount = selectedCountry.format.match(/\./g).length;
    const isPhoneNumberInValid = validNumberCount >= DEFAULT_COUNTRY_FORMAT_LENGTH || phone.length !== validNumberCount;
    const isDefaultFormat = selectedCountry.format.match(/\+[.]+ ... ... ... ... ../g) !== null;
    if (isPhoneNumberInValid && !isDefaultFormat) {
        return 'Not a valid phone number';
    }
  }


  return null;
}
