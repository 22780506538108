import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import { SonifiLabel } from '../../containers';
import SonifiSpinner from '../../containers/SonifiSpinner';

const styles = () => ({
  dashboard: {
    padding: '50px',
  },
  itemPadding: {
    border: '2px solid gray',
    margin: '10px',
    padding: '50px'
  }
});

class GlobalDashboard extends Component {
  state = {
    spinner: true
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ spinner: false });
    }, 1000);
  }

  render() {
    const { spinner } = this.state;

    if (spinner) {
      return <SonifiSpinner />;
    }

    const { classes } = this.props;

    return (
      <Grid container className={classes.dashboard}>
        <Box width="48%" className={classes.itemPadding} >
          <SonifiLabel boldLabel="Global Dashboard" />
        </Box>
        <Box width="48%" className={classes.itemPadding} >
          <SonifiLabel boldLabel="Important Info" />
        </Box>
      </Grid>
    );
  }
}

GlobalDashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(GlobalDashboard, styles, { withTheme: true });
