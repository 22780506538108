import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SITE_EDIT, SITE_LIMITED } from '../../../constants/roles';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import Locations from '../containers/BuildingLocations/Locations';

class Hierarchy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLocations: true
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.saving && !this.props.saving) {
      this.setState({
        showLocations: false
      }, () => {
        this.setState({ showLocations: true });
      });
    }
  }

  render() {
    const { siteHierarchy, userPermissions } = this.props;

    if (!siteHierarchy || !this.state.showLocations) {
      return <Fragment />;
    }

    return (
      <Locations
        siteHierarchy={siteHierarchy.length > 0 ? siteHierarchy[0].treeData : []}
        onUpdate={this.props.onSiteUpdate}
        allowEdit={checkForAtLeastOneUserPermission([SITE_EDIT], userPermissions)}
        allowReorder={checkForAtLeastOneUserPermission([SITE_EDIT, SITE_LIMITED], userPermissions)} />
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  saving: state.siteManagement.saving,
  savingStatus: state.siteManagement.savingStatus,
  siteHierarchy: state.global.buildings,
  userPermissions: state.global.permissions
});

Hierarchy.propTypes = {
  buildings: PropTypes.array,
  change: PropTypes.bool,
  dispatch: PropTypes.func,
  onSiteUpdate: PropTypes.func,
  saving: PropTypes.bool,
  savingStatus: PropTypes.number,
  siteHierarchy: PropTypes.array,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(Hierarchy);
