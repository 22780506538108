import { LATE_CHECKOUTS } from '../../../constants/apiEndpoints';
import { ADD } from '../../../constants/constants';
import { getSiteObjectPromise } from '../../../utils/api';

export function validateTime(option, translations) {
  const errors = {};
  const promises = [];
  if (option.price === '' || option.price === null) {
    errors.price = translations.price;
  }

  if (option.id === ADD && option.time) {
    promises.push(getSiteObjectPromise(LATE_CHECKOUTS));
    return Promise.all(promises)
      .then((data) => {
        const splitTime = option.time.split(' ');
        const compareTime = `${splitTime[splitTime.length - 1]}`;
        const timeInUse = data[0].late_checkouts.filter((e) => e.id === compareTime);
        if (timeInUse.length > 0) {
          errors.time = translations.timeUsed;
        }
        return errors;
      })
      .catch((error) => {
        console.log('LateCheckout:validator:error', error);
        errors.time = translations.timeUsed;
        return errors;
      });
  }
  return Promise.resolve(errors);
}
