import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel } from '../../../../containers';
import { BROWSER, CPU, LG, PTC } from '../../constants/TermFileConstants';
import BaseFirmwareGrid from '../BaseFirmwareGrid';
import LgFirmwareDialog from './LgFirmwareDialog';
import LgFirmwareRow from './LgFirmwareRow';

class LgFirmwareGrid extends BaseFirmwareGrid {
  getMake() {
    return LG;
  }

  handleCheckChange = () => ({ target: { checked } }) => {
    this.updateMaintWindow('lgEnabled', checked);
  };

  getHeaderInfo() {
    const { lgFirmware, translations } = this.props;
    return [
      { id: 'id', sortable: lgFirmware.length > 1, numeric: false, label: `${translations.model}` },
      { id: CPU, sortable: lgFirmware.length > 1, numeric: false, label: `${translations.lg.cpuDef}` },
      { id: PTC, sortable: lgFirmware.length > 1, numeric: false, label: `${translations.lg.ptcDef}` },
      { id: BROWSER, sortable: lgFirmware.length > 1, numeric: false, label: `${translations.lg.browserDef}` },
      { id: 'version', sortable: lgFirmware.length > 1, numeric: true, label: `${translations.version}` }
    ];
  }

  getChecked() {
    return this.props.lgEnabled;
  }

  getPopupBox() {
    const { canEdit, lgIndex } = this.props;
    return lgIndex !== -1 && <LgFirmwareDialog canEdit={canEdit} />;
  }

  getTableBody() {
    const { canEdit, lgFirmware, translations } = this.props;
    return <Fragment>
      {!lgFirmware || lgFirmware.length === 0
        ? <TableRow>
          <TableCell colSpan={5}>
            <SonifiLabel error label={translations.lg.errors.noLgFirmware} />
          </TableCell>
        </TableRow >
        : lgFirmware.map((option, index) => (
          <LgFirmwareRow key={index} rowIndex={index} lgFirmwareRow={option} canEdit={canEdit} />
        ))}
    </Fragment>;
  }
}

const mapStateToProps = (state) => ({
  changed: state.termFile.changed,
  lgEnabled: state.termFile.lgEnabled,
  lgFirmware: state.termFile.lgFirmware,
  lgIndex: state.termFile.lgIndex,
  lgLoading: state.termFile.lgLoading,
  samsungEnabled: state.termFile.samsungEnabled,
  sonifiEnabled: state.termFile.sonifiEnabled,
  termOptLoading: state.termFile.termOptLoading,
  translations: state.termFile.translations.grid
});

LgFirmwareGrid.propTypes = {
  canEdit: PropTypes.bool,
  changed: PropTypes.bool,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  lgEnabled: PropTypes.bool,
  lgFirmware: PropTypes.array,
  lgIndex: PropTypes.number,
  lgLoading: PropTypes.bool,
  samsungEnabled: PropTypes.bool,
  samsungLoading: PropTypes.bool,
  sonifiEnabled: PropTypes.bool,
  sonifiLoading: PropTypes.bool,
  tabValue: PropTypes.string,
  termOptLoading: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(LgFirmwareGrid);
