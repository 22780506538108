import * as types from './actionTypes';

export const clearSamsungFirmware = () => ({
  type: types.CLEAR_SAMSUNG_FIRMWARE
});

// export const fetchSamsungFirmwareBegin = () => ({
//   type: types.FETCH_SAMSUNG_FIRMWARE_BEGIN
// });

// export const fetchSamsungFirmwareSuccess = (samsungFirmware, client) => ({
//   type: types.FETCH_SAMSUNG_FIRMWARE_SUCCESS,
//   samsungFirmware,
//   client
// });

// export const fetchSamsungFirmwareFailure = (error) => ({
//   type: types.FETCH_SAMSUNG_FIRMWARE_FAILURE,
//   error
// });

// export function fetchSamsungFirmware() {
//   return (dispatch) => {
//     dispatch(fetchSamsungFirmwareBegin());

//     return getSiteObjectPromise(TERM_MODELS, null, null, { make: SAMSUNG })
//       .then((termFiles) => {
//         dispatch(fetchSamsungFirmwareSuccess(
//           termFiles.models, termFiles.default_site_firmware.samsung_client
//         ));
//         return true;
//       }).catch((error) => {
//         dispatch(fetchSamsungFirmwareFailure(`${error.status}`));
//         return false;
//       });
//   };
// }

export const updateSamsungFirmware = (samsungIndex, editType) => ({
  type: types.UPDATE_SELECTED_SAMSUNG_FIRMWARE,
  samsungIndex,
  editType
});

export const editSamsungFile = (editType) => ({
  type: types.UPDATE_SAMSUNG_FILE,
  editType
});
