import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import { ModalSectionHeader } from '.';

const styles = (theme) => ({
  padAllSides: {
    padding: theme.spacing(2)
  },
  marginRight: {
    marginRight: '10px'
  },
  normalPadding: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingBottom: 0,
    paddingRight: 0,
  },
  section: {
    backgroundColor: '#fff',
    border: 'solid',
    borderWidth: 'thin',
    borderColor: theme.palette.primary.light,
    marginBottom: theme.spacing(2),
  }
});

export class SonifiSection extends Component {
  render() {
    const {
      allPadding, body, classes, marginRight, title
    } = this.props;
    return (<Fragment>
      <ModalSectionHeader label={title} />
      <div className={`${classes.section} ${allPadding ? classes.padAllSides : classes.normalPadding} 
      ${marginRight ? classes.marginRight : ''}`}>
        {body}
      </div>
    </Fragment>);
  }
}

SonifiSection.propTypes = {
  allPadding: PropTypes.bool,
  body: PropTypes.object,
  classes: PropTypes.object.isRequired,
  marginRight: PropTypes.bool,
  title: PropTypes.string.isRequired
};

export default withStyles(SonifiSection, styles, { withTheme: true });
