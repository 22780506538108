export const ADD = 'add';
export const DWB_PRODUCT_CODE = 'CC-21000';
export const EDIT = 'edit';
export const ERROR = 'error';
export const HEALTHCARE = 'Healthcare';
export const INFO = 'info';
export const ITV_PRODUCT_CODE = 'CC-30005';
export const MANUAL = 'MANUAL';
export const MARINA = 'MARINA';
export const READ = 'read';
export const SONIFI = 'SONIFI';
export const SUCCESS = 'success';
export const UPDATE = 'update';
