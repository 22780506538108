import { ADD, SONIFI } from '../../../constants/constants';
import { LG, SAMSUNG } from '../../TermFiles/constants/TermFileConstants';

export const maxSize = 1048576;
export const makes = [
  { label: SONIFI, value: SONIFI },
  { label: LG, value: LG },
  { label: SAMSUNG, value: SAMSUNG }
];

export const newModel = {
  id: ADD, make: SONIFI, isNew: true
};
