import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import StopIcon from '@mui/icons-material/Stop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Grid, TableBody, TableCell, TableRow } from '@mui/material';
import Popover from '@mui/material/Popover';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { withStyles } from 'tss-react/mui';
import { FORM_MODE, FORM_TYPE, getDisplayTypeDisplayName } from '../../../constants/messages';
import { MESSAGEPUBLISH_EDIT } from '../../../constants/roles';
import SonifiConfirm from '../../../containers/SonifiConfirm';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiTooltip from '../../../containers/SonifiTooltip';
import { dateToString } from '../../../utils';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import {
    cancelDialog, clearDialogVariables, clearSelectedPublishItem, getPublishItem,
    setSelectedPublishItem, showMessageDialog, stopPublishItem
} from '../actions/messagingActions';

const DISTANCE_FROM_END = 3; // insert waypoint object 3 items from the end of the list
const styles = (theme) => ({
  hover: {
    // Do not remove
  },
  popOverContainer: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
});

class LiveTableBody extends Component {
  state = {
    open: false,
    popOverElement: null,
    popOverHeight: null,
    popOverId: null,
    stopDialogOpen: false
  };

  // event handler to view
  handleViewDialog() {
    this.props.dispatch(getPublishItem(this.state.popOverId));
    this.props.dispatch(showMessageDialog(FORM_TYPE.PUBLISH, FORM_MODE.VIEW));
  }

  // event handler to copy
  handleCopyDialog() {
    this.props.dispatch(getPublishItem(this.state.popOverId));
    this.props.dispatch(showMessageDialog(FORM_TYPE.PUBLISH, FORM_MODE.COPY));
  }

  // event handler to stop
  handleStopDialog() {
    this.setState({ stopDialogOpen: true });
  }

  // event handler to get more data when waypoint reached
  getMoreData() {
    this.props.onWayPointReached();
  }


  componentDidUpdate(prevProps, prevState) {
    // console.log('COMPONENTDIDUPDATE PUBLISHTABLEBODY');
    // console.log('prevProps', prevProps);
    // console.log('Props', this.props);
    // console.log('prevState', prevState);
    // console.log('State', this.state);

    if ((!prevProps.dialogSaveAttemptComplete && this.props.dialogSaveAttemptComplete) ||
      (!prevProps.dialogCancelSuccess && this.props.dialogCancelSuccess)) {
      this.props.dispatch(clearDialogVariables());
      this.setState({
        open: false,
        stopDialogOpen: false,
        deleteDialogOpen: false
      });
    }
    if (prevState.open !== this.state.open) {
      if (this.state.open) {
        this.props.dispatch(setSelectedPublishItem(this.state.popOverId));
      } else if (this.state.popOverId === null) {
        this.props.dispatch(clearSelectedPublishItem());
      }
    }
  }

  verifyCancelDialog = () => {
    this.setState({ stopDialogOpen: false });
    this.props.dispatch(cancelDialog());
  };

  // handle stop command and pass current time to set end date
  handleStopPublishItem = () => {
    console.log('handleStopPublishItem', this.props.selectedPublishItem);
    this.props.dispatch(stopPublishItem(this.props.selectedPublishItem, moment.utc().tz(this.props.timezone).format()));
  };

  // When popover occurs, set ID so that when dialog is opened, so we know which data to retrieve
  handlePopover = (id) => (event) => {
    const { currentTarget } = event;
    this.setState((state) => ({
      popOverId: id,
      popOverElement: currentTarget,
      popOverHeight: currentTarget.clientHeight,
      open: !state.open
    }));
  };

  handlePopoverClose = (event) => {
    if (event) {
      if (this.state.popOverElement.contains(event.target)) {
        return;
      }
    }

    this.setState({ open: false });
  };

  getToolTip(title, icon, onClick, disabled = false) {
    return (<SonifiTooltip
      onClick={onClick}
      icon={icon}
      title={title}
      disabled={disabled}
    />);
  }

  /* eslint-disable camelcase */
  render() {
    const {
      classes, globalTranslations, live, loading, timezone, translations, userPermissions
    } = this.props;
    const {
      open, popOverElement, popOverHeight, stopDialogOpen,
    } = this.state;

    if (loading && live.length === 0) {
      return (<TableBody className={classes.tableBody}>
        <TableRow>
          <TableCell colSpan={12} >
            <SonifiSpinner />
          </TableCell>
        </TableRow>
      </TableBody>);
    }

    return (<Fragment>
      <SonifiConfirm
        dialogOpen={stopDialogOpen}
        onConfirm={this.handleStopPublishItem}
        onCancel={this.verifyCancelDialog}
        confirmTitle={translations.publishStop}
        confirmText={''}
        buttonCancelText={globalTranslations.cancel}
        buttonConfirmText={globalTranslations.stop}
      />
      <Popover
        open={open}
        anchorEl={popOverElement}
        PaperProps={{
          style: { borderRadius: 0 }
        }}
        onClose={this.handlePopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.popOverContainer}
          style={{ height: { popOverHeight } }}
        >
          {this.getToolTip(globalTranslations.view, <VisibilityIcon fontSize="small" />,
            this.handleViewDialog.bind(this))}
          {checkForSingleUserPermission(MESSAGEPUBLISH_EDIT, userPermissions) &&
            <Fragment>
              {/* cannot Edit or Delete Live Messages, they are disabled */}
              {this.getToolTip(globalTranslations.edit, <EditIcon fontSize="small" />, null, true)}
              {this.getToolTip(globalTranslations.copy, <FileCopyIcon fontSize="small" />,
                this.handleCopyDialog.bind(this))}
              {this.getToolTip(globalTranslations.stop, <StopIcon fontSize="small" />,
                this.handleStopDialog.bind(this))}
              {this.getToolTip(globalTranslations.delete, <DeleteIcon fontSize="small" />, null, true)}
            </Fragment>
          }
        </Grid>
      </Popover>
      <TableBody className={classes.tableBody}>
        {live.length === 0 &&
          <TableRow>
            <TableCell colSpan={7}>
              <SonifiLabel error label={translations.errors.noLive} />
            </TableCell>
          </TableRow>
        }
        {live.map(({
          id, body, user_name, start_timestamp, end_timestamp, requester, organization
        }, i) => (
          <TableRow
            key={id}
            hover={true}
            classes={{ hover: classes.hover }}
            className={classes.tableRow}
            onClick={this.handlePopover(id)}
          >
            <TableCell className={classes.tableCell}>{body.name}
              {i === live.length - DISTANCE_FROM_END && (
                <Waypoint onEnter={() => {
                  // console.log('Waypoint reached: ', i);
                  // console.log('Total Records: ', live.length);

                  // GET NEXT PAGE OF DATA
                  this.getMoreData();
                }}
                />)
              }
            </TableCell>
            <TableCell className={classes.tableCell}>{getDisplayTypeDisplayName(body.display_type)}</TableCell>
            <TableCell className={classes.tableCell}>{requester}</TableCell>
            <TableCell className={classes.tableCell}>{organization}</TableCell>
            <TableCell className={classes.tableCell}>{dateToString(start_timestamp, timezone)}</TableCell>
            <TableCell className={classes.tableCell}>{dateToString(end_timestamp, timezone)}</TableCell>
            <TableCell className={classes.tableCell}>{user_name}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dialogCancelSuccess: state.messaging.dialogCancelSuccess,
  dialogOpen: state.messaging.dialogOpen,
  dialogSaveAttemptComplete: state.messaging.dialogSaveAttemptComplete,
  globalTranslations: state.global.translations.defaults,
  live: state.messaging.live,
  loading: state.messaging.loading,
  selectedPublishItem: state.messaging.selectedPublishItem,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
  translations: state.messaging.translations.messaging,
  userPermissions: state.global.permissions
});

LiveTableBody.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogCancelSuccess: PropTypes.bool,
  dialogOpen: PropTypes.bool,
  dialogSaveAttemptComplete: PropTypes.bool,
  dispatch: PropTypes.func,
  error: PropTypes.bool,
  globalTranslations: PropTypes.object,
  library: PropTypes.array,
  live: PropTypes.array,
  loading: PropTypes.bool,
  onWayPointReached: PropTypes.func,
  selectedLibraryItem: PropTypes.string,
  selectedPublishItem: PropTypes.number,
  timezone: PropTypes.string.isRequired,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(LiveTableBody, styles, { withTheme: true }));

