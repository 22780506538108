import {
  Table, TableBody, TableCell, TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { DISTANCE_FROM_END } from '../../../constants/magic';
import { SOFTWARE_WRITE } from '../../../constants/roles';
import { SonifiLabel, SonifiSpinner, SonifiTableHead } from '../../../containers';
import { buildSortString } from '../../../utils';
import { checkSingleUserPermission } from '../../../utils/rolesUtil';
import { getMoreVersions } from '../actions/deploymentsActions';
import DeploymentRow from './DeploymentRow';

class ParentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 25,
      order: 'desc',
      orderBy: 'id',
      page: 1
    };
    this.getMoreData = this.getMoreData.bind(this);
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }), () => {
        this.props.dispatch(getMoreVersions(this.props.selectedProductParent, buildSortString(this.state)));
      });
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  handleRequestSort = (property) => {
    const { dispatch, gettingNewChannels, selectedProductParent } = this.props;
    if (gettingNewChannels) {
      return;
    }
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    }, () => {
      dispatch(getMoreVersions(selectedProductParent, buildSortString(this.state)));
    });
  };

  getVersions() {
    const availPkgs = JSON.parse(JSON.stringify(this.props.channels));
    availPkgs.sort((a, b) => {
      const va = a.id.split('.'),
        vb = b.id.split('.');
      let i = 0;
      while (i < va.length && i < vb.length && (+va[i]) === (+vb[i])) {
        i++;
      }
      return (+va[i]) - (+vb[i]);
    });
    availPkgs.reverse();

    const maxVersionArray = [];
    const versionArray = [];
    while (availPkgs.length) {
      const tempSplit = availPkgs[0].id.split('.');
      if (!versionArray.includes(`${tempSplit[0]}.${tempSplit[1]}`)) {
        versionArray.push(`${tempSplit[0]}.${tempSplit[1]}`);
        maxVersionArray.push(availPkgs[0].id);
      }
      availPkgs.shift();
    }
    return maxVersionArray;
  }

  render() {
    const { order, orderBy, page } = this.state;
    const {
      channels, gettingNewChannels, maxPages, translations, userPermissions
    } = this.props;
    const canEdit = checkSingleUserPermission(SOFTWARE_WRITE, userPermissions);

    const tableHeader = [
      { id: 'channels', sortable: channels.length > 1, numeric: false, label: `${translations.channel}` },
      { id: 'id', sortable: channels.length > 1, numeric: false, label: `${translations.version}` },
      { id: 'virtos', sortable: channels.length > 1, numeric: false, label: `${translations.virtOS}` },
      { id: 'deployment_date', sortable: channels.length > 1, numeric: false, label: `${translations.deploymentDate}` }
    ];

    const maxVersions = this.getVersions();
    return <div style={{
      border: 'none', flexGrow: 1, height: '100%', overflowX: 'hidden', width: '100%'
    }}>
      <Table stickyHeader={true}>
        <SonifiTableHead
          headColumns={tableHeader}
          order={order}
          orderBy={orderBy}
          onRequestSort={this.handleRequestSort}
        />
        <TableBody>
          {(!channels || channels.length === 0)
            ? <TableRow>
              <TableCell colSpan={4}>
                {gettingNewChannels
                  ? <SonifiSpinner />
                  : <SonifiLabel error label={translations.errors.noDeployments} />}
              </TableCell>
            </TableRow>
            : channels.map((option, index) => (
              <Fragment key={`w_${index}`}>
                <DeploymentRow key={index} rowIndex={index} option={option} canEdit={canEdit}
                  manualUpdate={maxVersions.includes(option.id)} />
                {index === channels.length - DISTANCE_FROM_END && page < maxPages && (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Waypoint onEnter={this.getMoreData} />
                    </TableCell>
                  </TableRow>)
                }
              </Fragment>
            ))}
        </TableBody>
      </Table>
    </div>;
  }
}

const mapStateToProps = (state) => ({
  channels: state.deployments.channels,
  gettingNewChannels: state.deployments.gettingNewChannels,
  globalTranslations: state.global.translations.defaults,
  loading: state.deployments.loading,
  maxPages: state.deployments.maxPages,
  selected: state.deployments.selected,
  selectedProductParent: state.deployments.selectedProductParent,
  snackBarMessage: state.deployments.snackBarMessage,
  snackBarType: state.deployments.snackBarType,
  translations: state.deployments.translations.main,
  userPermissions: state.global.permissions
});

ParentTable.propTypes = {
  channels: PropTypes.array,
  dispatch: PropTypes.func,
  gettingNewChannels: PropTypes.bool,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  maxPages: PropTypes.number,
  selected: PropTypes.number,
  selectedProductParent: PropTypes.string,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(ParentTable);
