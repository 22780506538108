import { Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { SONIFI_ROLES } from '../../../constants/roleGroups';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiLockoutModalSpinner from '../../../containers/SonifiLockoutModalSpinner';
import SonifiSnackBar from '../../../containers/SonifiSnackBar';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiTabs from '../../../containers/SonifiTabs';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { getProductParents } from '../../Deployments/actions/deploymentsActions';
import { getScheduleData, getSystems, updateSnackBar } from '../../SystemManagement/actions/systemManagementActions';
import Software from './Software';
import UpgradeDialog from './UpgradeDialog';
import UpgradeWindow from './UpgradeWindow';

class SoftwareTabs extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getSystems());
    dispatch(getProductParents());
  }

  handleChange(event, newValue) {
    this.props.dispatch(getScheduleData(newValue));
  }

  closeSnackBar() {
    this.props.dispatch(updateSnackBar(''));
  }

  render() {
    const {
      loading, translations, savingWindow, snackBarMessage, snackBarType, systemId, systems, updateRow, userPermissions
    } = this.props;

    if (!checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={savingWindow} />
        <Grid container style={{ alignContent: 'baseline', overflow: 'auto' }}>
          <Grid item xs={12}>
            <UpgradeWindow />
          </Grid>
          {systems === null || loading
            ? <SonifiSpinner />
            : <Grid item xs={12}>
              {(!systems || systems.length === 0 || systemId === null)
                ? <SonifiLabel error boldLabel={translations.errors.noSystems} />
                : <SonifiTabs
                  tabs={systems.map(({ date, id }) => ({
                    id: `${id}`,
                    title: moment(date).format('YYYY-MM-DD'),
                  }))}
                  handleChange={this.handleChange.bind(this)}
                  tabValue={systemId}
                  showingTab={<Software scheduleId={systemId} />}
                />}
            </Grid>}
        </Grid>
        {updateRow && <UpgradeDialog />}
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.systemManagement.loading,
  savingWindow: state.systemManagement.savingWindow,
  snackBarMessage: state.systemManagement.snackBarMessage,
  snackBarType: state.systemManagement.snackBarType,
  systemId: state.systemManagement.systemId,
  systems: state.systemManagement.systems,
  translations: state.systemManagement.translations,
  updateRow: state.systemManagement.updateRow,
  userPermissions: state.global.permissions
});

SoftwareTabs.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  systemId: PropTypes.string,
  systems: PropTypes.array,
  savingWindow: PropTypes.bool,
  translations: PropTypes.object,
  updateRow: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(SoftwareTabs);
