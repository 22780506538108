import { Add } from '@mui/icons-material';
import {
  Grid, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { SonifiIconButton, SonifiSpinner, SonifiSwitch, SonifiTableHead } from '../../../containers';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { buildSortString } from '../../../utils';
import * as actions from '../actions/FirmwareActions';

export default class BaseFirmwareGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      limit: 100,
      order: 'asc',
      orderBy: 'id',
      page: 1
    };

    this.addNewModelToSite = this.addNewModelToSite.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(actions.fetchTermFirmware(this.getFirmwareSortString()));
  }

  debouncedAutoUpdate = debounce(this.autoUpdate, KEYBOARD_DELAY);

  handleCheckChange = () => ({ target: { checked } }) => {
    console.log('handleCheckChange:override in subclass', checked);
  };

  updateMaintWindow(name, checked) {
    this.props.dispatch(actions.updateMaintenanceWindow(name, checked));
    this.debouncedAutoUpdate();
  }

  getMake() {
    return '';
  }

  getFirmwareSortString() {
    return buildSortString({ ...this.state, make: this.getMake() });
  }

  autoUpdate() {
    const {
      dispatch, lgEnabled, samsungEnabled, sonifiEnabled, translations
    } = this.props;
    const saveObj = {};
    saveObj.automatic_updates = {};
    saveObj.automatic_updates.SONIFI = sonifiEnabled;
    saveObj.automatic_updates.LG = lgEnabled;
    saveObj.automatic_updates.SAMSUNG = samsungEnabled;
    dispatch(actions.saveTerminalOptions(saveObj, translations.sonifi));
  }

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      orderBy: property,
      order: isDesc ? 'asc' : 'desc',
      page: 1
    }, () => {
      // this.props.dispatch(fetchTermFirmware(this.getFirmwareSortString()));
      this.props.dispatch(actions.sortTerminalModels(this.getFirmwareSortString()));
    });
  };

  getHeaderInfo() {
    return [];
  }

  getChecked() {
    return false;
  }

  getPopupBox() {
    return null;
  }

  getTableBody() {
    return null;
  }

  addNewModelToSite() {
    this.props.dispatch(actions.addNewModel(this.props.tabValue));
  }

  getPageDetails() {
    const { lgLoading, samsungLoading, sonifiLoading } = this.props;
    const { order, orderBy } = this.state;

    return (
      <Table>
        <SonifiTableHead
          headColumns={this.getHeaderInfo()}
          order={order}
          orderBy={orderBy}
          onRequestSort={this.handleRequestSort}
        />
        {lgLoading || sonifiLoading || samsungLoading
          ? <TableBody>
            <TableRow>
              <TableCell colSpan={4}>
                <SonifiSpinner />
              </TableCell>
            </TableRow >
          </TableBody>
          : <TableBody>
            {this.getTableBody()}
          </TableBody>
        }
      </Table>
    );
  }

  render() {
    const { canEdit, tabValue, termOptLoading, translations } = this.props;
    return (
      <Fragment>
        <SonifiTemplate
          header={translations.title}
          middleExtra={
            <Grid container>
              <Grid item>
                {termOptLoading
                  ? <SonifiSpinner size="sm" />
                  : <SonifiSwitch
                    disabled={!canEdit}
                    label={translations.sonifi.automatic}
                    onChange={this.handleCheckChange()}
                    checked={this.getChecked()}
                  />}
              </Grid>
              {canEdit &&
                <Grid item>
                  <SonifiIconButton disabled={false} onClick={this.addNewModelToSite} icon={<Add />}
                    label={translations.model}
                    toolTipMessage={translations.addModel.replace('#modelType', tabValue)}
                    showToolTip={true}
                  />
                </Grid>}
            </Grid>
          }
          multiple={true}
          paddingBottom={true}
          pageDetails={this.getPageDetails()}
        />
        {this.getPopupBox()}
      </Fragment>
    );
  }
}

BaseFirmwareGrid.propTypes = {
  canEdit: PropTypes.bool,
  changed: PropTypes.bool,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  lgEnabled: PropTypes.bool,
  lgFirmware: PropTypes.array,
  lgIndex: PropTypes.number,
  lgLoading: PropTypes.bool,
  samsungEnabled: PropTypes.bool,
  samsungLoading: PropTypes.bool,
  sonifiEnabled: PropTypes.bool,
  sonifiLoading: PropTypes.bool,
  tabValue: PropTypes.string,
  termOptLoading: PropTypes.bool,
  translations: PropTypes.object
};
