import SearchIcon from '@mui/icons-material/Search';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { updateRole } from '../../actions/globalActions';
import { ROLES_ROLES } from '../../constants/roleGroups';
import SonifiCheckBox from '../../containers/SonifiCheckbox';
import SonifiText from '../../containers/SonifiText';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../utils/rolesUtil';
import { getRoles } from './actions/rolesDevActions';

class RolesDev extends Component {
  state = {
    searchField: '',
    selectAll: false
  };

  componentDidMount() {
    this.props.dispatch(getRoles(this.props.userPermissions[this.props.userPermissions.length - 1]));
  }

  uncheckAll = () => ({ target: { checked } }) => {
    let currentRolesSelected = [...this.props.userPermissions];
    const myCurrentRole = currentRolesSelected.pop();
    if (checked) {
      currentRolesSelected = [...this.props.roles];
    } else {
      currentRolesSelected = [...ROLES_ROLES];
    }

    currentRolesSelected.push(myCurrentRole);
    this.props.dispatch(updateRole({ permissions: currentRolesSelected }));
    this.setState({ selectAll: checked });
  };

  handleRoleSelection = (roleName) => ({ target: { checked } }) => {
    const currentRolesSelected = [...this.props.userPermissions];
    const myCurrentRole = currentRolesSelected.pop();

    if (checked === true) {
      currentRolesSelected.push(roleName);
    } else {
      const roleIndex = this.props.userPermissions.findIndex((role) => role === roleName);
      currentRolesSelected.splice(roleIndex, 1);
    }
    currentRolesSelected.push(myCurrentRole);
    this.props.dispatch(updateRole({ permissions: currentRolesSelected }));
  };

  handleSearchFieldChange = ({ target: { value } }) => {
    this.setState({ searchField: value });
  };

  render() {
    const { roles, userPermissions } = this.props;

    if (!checkForAtLeastOneUserPermission(ROLES_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    return (
      <Fragment>
        <SonifiText
          defaultValue={this.state.searchField}
          change={this.handleSearchFieldChange}
          label="Role Filter"
          icon={<SearchIcon />}
          iconPosition="end"
        />
        <SonifiCheckBox
          onChange={this.uncheckAll()}
        />

        <Grid container style={{ overflow: 'auto' }}>
          {roles.map((role, index) => (
            <Fragment key={index}>
              {role.toLowerCase().includes(this.state.searchField.toLowerCase()) &&
                <Grid item xs={4} >
                  <SonifiCheckBox label={role}
                    value={checkForSingleUserPermission(role, userPermissions)}
                    onChange={this.handleRoleSelection(role)}
                  />

                </Grid>
              }
            </Fragment>
          ))}
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  roles: state.rolesDev.roles,
  userPermissions: state.global.permissions
});

RolesDev.propTypes = {
  change: PropTypes.bool,
  dispatch: PropTypes.func,
  roles: PropTypes.array,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(RolesDev);
