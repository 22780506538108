import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MANUAL, READ, UPDATE, EDIT } from '../../../constants/constants';
import { SonifiLabel } from '../../../containers';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import { getErrorText } from '../../../utils';
import { removePipe } from '../../../utils/textUtil';
import { getDropDown, getLgTextBox } from '../../../utils/ui';
import * as actions from '../actions/deploymentsActions';
import { currentStatus, getStatusOptions } from '../constants/constants';
import ReleaseInfo from '../containers/ReleaseInfo';

export class DeploymentDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      channel: '',
      errors: {},
      version: '',
      virtos: '',
      nextStatus: '',
      releaseState: null,
      templateId: -1
    };
    this.updateManualRelease = this.updateManualRelease.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { channels, selected, subselected } = this.props;

    const status = currentStatus(channels[selected].channels[subselected]);
    this.setState({
      ...channels[selected],
      channel: status,
      nextStatus: status,
    });
  }

  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(actions.updateSelectedDeployment(-1));
    }
  }

  onSubmit() {
    const { channel, nextStatus, releaseState, templateId } = this.state;
    const {
      channels, canEdit, dispatch, editType, selectedProductParent, selected
    } = this.props;
    if (canEdit) {
      if (editType === UPDATE) {
        if (channel === nextStatus) {
          dispatch(actions.updateSelectedDeployment(-1));
        } else {
          dispatch(
            actions.editSelectedDeployment(selectedProductParent, channels[selected].id, [
              ...channels[selected].channels,
              nextStatus.toLowerCase(),
            ], channels[selected].virtos)
          );
        }
      } else if (editType === MANUAL) {
        // https://sonifi.atlassian.net/wiki/spaces/CCA/pages/870711327/APP+API+-+Software+Management#Add-Version
        console.log('Cameron: Save here and get state from ReleaseInfo');

        if (templateId !== -1 && releaseState !== null) {
          dispatch(actions.addManualVersion(templateId, selectedProductParent, releaseState, this.state.id));
        } else {
          dispatch(actions.addManualVersionComplete(true));
        }
      }
    }
  }

  processChange = (name) => ({ target: { value } }) => {
    this.setState({ [name]: value });
  };

  editState = (name) => ({ target: { value } }) => {
    const { canEdit, editType } = this.props;
    if (canEdit) {
      if (editType === UPDATE) {
        this.setState({ [name]: currentStatus(removePipe(value)) });
      } else if (editType === MANUAL) {
        console.log('Cameron: Edit here');
      }
    }
  };

  getDisplay(label, value) {
    return (
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid item>
            <SonifiLabel label={label} />
          </Grid>
          <Grid item>
            <SonifiLabel blue boldLabel={value} halfLeftPadding />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  getHeader() {
    const { translations, editType } = this.props;
    if (editType === READ) {
      return translations.readTitle;
    } else if (editType === MANUAL) {
      return translations.manual;
    }
    return translations.title;
  }

  updateManualRelease(releaseState, templateId) {
    this.setState({ releaseState, templateId });
  }

  render() {
    const { canEdit, globalTranslations, translations, editType } = this.props;
    const {
      channel, nextStatus, errors, id, virtos
    } = this.state;

    const statusOptions = getStatusOptions(channel);
    const readOnly = editType === READ || !canEdit || (statusOptions.length === 1 && editType === EDIT);

    return (
      <Dialog open={true} onClose={this.onCancel} fullWidth maxWidth="md">
        <SonifiModalHeader
          header={this.getHeader()}
          onCancel={this.onCancel}
          onSubmit={this.onSubmit}
          onlyClose={readOnly}
          label={globalTranslations.defaults.save}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <Grid container justifyContent="center">
                <Grid item>
                  {getLgTextBox(
                    translations.version,
                    id,
                    '',
                    this.editState('version'),
                    getErrorText('version', errors),
                    false,
                    'string',
                    'sm'
                  )}
                </Grid>
                <Grid item>
                  {getLgTextBox(
                    translations.virtos,
                    virtos,
                    '',
                    this.editState('virtos'),
                    getErrorText('virtos', errors),
                    false,
                    'string',
                    'sm'
                  )}
                </Grid>
                <Grid item>
                  {getDropDown(
                    editType === UPDATE ? translations.currentStat : translations.promote,
                    nextStatus.toLowerCase(),
                    '',
                    statusOptions,
                    this.editState('nextStatus'),
                    '',
                    editType === UPDATE && canEdit && statusOptions.length > 1,
                    'md'
                  )}
                </Grid>
              </Grid>
            </Grid>
            {editType === UPDATE && this.getDisplay(translations.current, channel)}
            {editType === UPDATE && this.getDisplay(translations.saving, nextStatus)}
            {editType !== UPDATE && <ReleaseInfo updateManualRelease={this.updateManualRelease} />}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  editType: state.deployments.editType,
  globalTranslations: state.global.translations,
  channels: state.deployments.channels,
  selected: state.deployments.selected,
  subselected: state.deployments.subselected,
  translations: state.deployments.translations.editDialog,
  selectedProductParent: state.deployments.selectedProductParent,
});

DeploymentDialog.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  globalTranslations: PropTypes.object,
  channels: PropTypes.array,
  reset: PropTypes.func,
  selected: PropTypes.number,
  subselected: PropTypes.number,
  sort: PropTypes.string,
  translations: PropTypes.object,
  selectedProductParent: PropTypes.string,
};

export default connect(mapStateToProps)(DeploymentDialog);
