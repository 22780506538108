import { Dialog, DialogContent, Grid } from '@mui/material';
import { debounce, map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { SonifiLabel, SonifiLockoutModalSpinner, SonifiModalHeader, SonifiText } from '../../../containers/';
import { alphaNumericLowercaseOnly, removePipe } from '../../../utils/textUtil';
import { addGroup, updateSelectedGroup } from '../actions/groupActions';
import { getErrorText, isError } from '../utils/index';
import { validGroup, validId } from '../utils/validator';
import PreviewSites from './PreviewSites';
import SiteSelector from './SiteSelector';

const styles = (theme) => ({
  editContent: {
    height: '100%',
    overflow: 'hidden'
  },
  siteAssignment: {
    overflowX: 'hidden',
    maxHeight: '88px',
    minHeight: '88px',
    wordBreak: 'break-all',
    border: `1px ${theme.palette.primary.border} solid`,
    marginBottom: '15px',
    paddingLeft: '7px'
  },
  siteSelect: {
    height: 'calc(100% - 220px)',
    overflow: 'auto',
    borderBottom: `1px ${theme.palette.primary.border} solid`,
  },
  siteSelectRead: {
    height: 'calc(100% - 83px)',
  }
});

class EditGroup extends Component {
  state = {
    allowSave: true,
    errors: null,
    groupName: ''
  };

  componentDidMount() {
    this.setState({ groupName: this.props.selectedGroup.description });
  }

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  updateGroupName = ({ target: { value } }) => {
    this.setState({ groupName: removePipe(value) }, () => {
      this.debouncedLoadMoreData();
    });
  };

  filterData() {
    const currSite = { ...this.props.selectedGroup };
    currSite.description = this.state.groupName;
    this.props.dispatch(updateSelectedGroup(currSite, this.props.editType));
  }

  onSave = () => {
    console.log('Save Edit Dialog');

    const { globalTranslations, selectedGroup, translations } = this.props;
    const name = (selectedGroup.isNew
      ? alphaNumericLowercaseOnly(selectedGroup.description)
      : selectedGroup.id);
    const dialogGroup = {
      description: (selectedGroup.description === null ? null : selectedGroup.description.trim()),
      sites: map(selectedGroup.sites, 'id')
    };

    const validationErrors = validGroup(dialogGroup, translations.errors, globalTranslations.errors);

    if (selectedGroup.isNew) {
      validId(name, translations.errors).then((data) => {
        validationErrors.name = data.message;
        this.onSaveContinue(name, dialogGroup, validationErrors);
      }).catch(() => {
        this.onSaveContinue(name, dialogGroup, validationErrors);
      });
    } else {
      this.onSaveContinue(name, dialogGroup, validationErrors);
    }
  };

  onSaveContinue(name, dialogGroup, validationErrors) {
    const { selectedGroup, userState } = this.props;
    if (Object.entries(validationErrors).length === 0) {
      this.setState({ errors: null });
      this.props.dispatch(addGroup(name, dialogGroup, selectedGroup.isNew, userState));
    } else {
      this.setState({ errors: validationErrors });
    }
  }

  getSiteAssignments = () => {
    const { selectedGroup, sites } = this.props;
    if (selectedGroup.sites && selectedGroup.sites.length > 0 && sites) {
      let selectedSites = `${selectedGroup.sites[0].name}`;
      for (let i = 1; i < selectedGroup.sites.length; i++) {
        selectedSites += `, ${selectedGroup.sites[i].name}`;
      }
      return selectedSites;
    }
    return '';
  };

  getTitle() {
    const { editType, selectedGroup, translations } = this.props;
    if (editType === 'read') {
      return translations.previewTitle;
    }
    return (selectedGroup.isNew ? translations.addTitle : translations.editTitle);
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.onCancel();
    }
  }

  render() {
    const {
      addingGroup, canEdit, classes, editType, globalTranslations, selectedGroup, translations
    } = this.props;
    const { allowSave, errors, groupName } = this.state;
    const siteError = isError('sites', errors);

    if (addingGroup) {
      return (<SonifiLockoutModalSpinner show={addingGroup} />);
    }

    return (
      <Dialog open={true}
        onClose={this.onClose.bind(this)}
        fullWidth
        maxWidth="lg" >
        <SonifiModalHeader
          header={this.getTitle()}
          onlyClose={!allowSave || !canEdit || editType === 'read'}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.onSave.bind(this)}
          label={(selectedGroup.isNew ? globalTranslations.defaults.add : globalTranslations.defaults.save)}
        />
        <DialogContent >
          <Grid container className={classes.editContent}>
            <Grid item xs={12}>
              <SonifiText
                size="lg"
                label={translations.groupName}
                defaultValue={groupName}
                change={this.updateGroupName.bind(this)}
                error={isError('name', errors)}
                errorText={getErrorText('name', errors)}
                disabled={!canEdit || editType === 'read'}
              />
            </Grid>
            {editType === 'edit' && <Fragment>
              <Grid item xs={12}>
                <SonifiLabel label={translations.siteAssign} />
              </Grid>
              <Grid item xs={12} className={classes.siteAssignment}>
                {selectedGroup && selectedGroup.sites && selectedGroup.sites.length === 0 && !siteError
                  ? <SonifiLabel disabled label={translations.noSelectedSites} />
                  : <SonifiLabel
                    error={siteError}
                    label={siteError
                      ? getErrorText('sites', errors)
                      : this.getSiteAssignments()} />
                }
              </Grid>
            </Fragment>}
            <Grid item xs={12} className={`${classes.siteSelect} ${(editType === 'read' && classes.siteSelectRead)}`}>
              {editType === 'edit' && <SiteSelector canEdit={canEdit} errors={siteError} />}
              {editType === 'read' && <PreviewSites />}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  addingGroup: state.groups.addingGroup,
  editType: state.groups.editType,
  globalTranslations: state.global.translations,
  selectedGroup: state.groups.selectedGroup,
  sites: state.groups.sites,
  translations: state.groups.translations.dialog,
  userPermissions: state.global.permissions
});

EditGroup.propTypes = {
  addingGroup: PropTypes.bool,
  canEdit: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  globalTranslations: PropTypes.object,
  onCancel: PropTypes.func,
  selectedGroup: PropTypes.object,
  sites: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array,
  userState: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(EditGroup, styles, { withTheme: true }));
