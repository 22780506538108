import { Add, Delete } from '@mui/icons-material';
import { Grid, List, ListItem } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { KEYBOARD_DELAY, KEYCODE_ENTER } from '../../../constants/keyCodes';
import { SonifiIconButton, SonifiLabel, SonifiTextInline } from '../../../containers';
import { editSelectedGroup } from '../actions/groupServicesActions';

class GroupCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      codes: [],
      editSequenceId: -1
    };
    this.keyPressListener = this.keyPressListener.bind(this);
    this.debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);
  }

  componentDidMount() {
    this.setState({ codes: this.props.selectedGroup.codes ?? [] });
  }

  filterData() {
    this.props.dispatch(editSelectedGroup('codes', this.state.codes));
  }

  addGroupCode = () => () => {
    const currentVisible = this.state.codes;
    currentVisible.push(null);

    this.setState({
      codes: currentVisible,
      editSequenceId: currentVisible.length - 1
    });
  };

  editGroupCodeName(termSequenceIndex) {
    this.setState({
      editSequenceId: termSequenceIndex,
    });
  }

  handleGroupCodeChange = (termIndex) => ({ target: { value } }) => {
    const { codes } = this.state;
    const codeCopy = [...codes];
    codeCopy[termIndex] = value;

    this.setState({ codes: codeCopy });
    this.props.dispatch(editSelectedGroup('codes', codeCopy));
  };

  keyPressListener(event) {
    if (event.target.type === 'text') {
      if (event.keyCode === KEYCODE_ENTER) {
        this.setState({
          editSequenceId: -1
        });
      }
    }
  }

  handleGroupCodeDelete(idx) {
    const { codes } = this.state;
    const tmpCodes = codes.filter((code, i) => i !== idx);
    this.setState({ codes: tmpCodes });
    this.props.dispatch(editSelectedGroup('codes', tmpCodes));
  }

  getErrorText(code) {
    const { errorCodes, translations } = this.props;
    if (errorCodes.includes(code)) {
      return translations.errors.failedDuplicateValidation;
      // } else if ((errors || errorCodes.length > 0) && (!code || code.length === 0)) {
      // return translations.errors.needToSelectACode;
    }
    return '';
  }

  render() {
    const {
      canEdit, errors, errorCodes, translations
    } = this.props;
    const { codes } = this.state;

    return (
      <Fragment>
        <Grid container justifyContent="space-between">
          <Grid item style={{ alignItems: 'center', display: 'flex' }}>
            <SonifiLabel
              error={!!(errors && errors.codes) || errorCodes.length > 0} boldLabel={translations.groupCodes} />
          </Grid>
          <Grid item>
            {canEdit && <SonifiIconButton label="Code"
              onClick={this.addGroupCode().bind(this)}
              icon={<Add />} />}
          </Grid>
        </Grid>
        <List style={{ border: '1px solid #d6d6d6', height: '89%', overflow: 'auto' }}>
          {codes.map((code, index) => (
            <ListItem key={index} onClick={this.editGroupCodeName.bind(this, index)}
              style={{ background: index % 2 ? '#FFF' : '#f6f6f6', justifyContent: 'space-between' }}
            >
              {canEdit
                ? <Fragment>
                  <SonifiTextInline
                    change={this.handleGroupCodeChange(index)}
                    defaultValue={code}
                    error={!code || code.length === 0 || errorCodes.includes(code)}
                    errorInline={!code || code.length === 0 || errorCodes.includes(code)}
                    errorText={this.getErrorText(code)}
                    handleKeyDown={this.keyPressListener}
                    highlightText={true}
                    showErrorOutline={errorCodes.includes(code)}
                  />
                  <SonifiIconButton
                    icon={<Delete />}
                    onClick={this.handleGroupCodeDelete.bind(this, index)}
                    showToolTip={true}
                    toolTipMessage={translations.deleteCode}
                  />
                </Fragment>
                : <SonifiLabel label={`${code}`} />}
            </ListItem>
          ))}
        </List>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  errorCodes: state.groupServices.errorCodes,
  selectedGroup: state.groupServices.selectedGroup,
  translations: state.groupServices.translations.editDialog
});

GroupCodes.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  errorCodes: PropTypes.array,
  errorHandler: PropTypes.func,
  errors: PropTypes.object,
  selectedGroup: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(GroupCodes);
