// import { applyMiddleware, createStore } from 'redux';
// import { createLogger } from 'redux-logger';
// import { persistReducer, persistStore } from 'redux-persist';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
// import storage from 'redux-persist/lib/storage';
// import thunk from 'redux-thunk';
// import { GLOBAL, ROLES, TERMINAL_RESOURCE } from './constants/apiEndpoints';
// import reducer from './reducers';

// // console.log(`The site is in ${process.env.NODE_ENV}`);
// window.__DEV__ = process.env.NODE_ENV !== 'production';
// const runningTests = process.env.NODE_ENV === 'test';
// // console.log(`The version is ${process.env.REACT_APP_CCA_VERSION}`);

// const persistConfig = {
//   key: 'root',
//   storage,
//   stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
//   whitelist: [GLOBAL, ROLES, TERMINAL_RESOURCE]
// };

// const pReducer = persistReducer(persistConfig, reducer);

// const createStoreWithMiddleware = applyMiddleware(
//   ...(function *() {
//     yield thunk;
//     if (window.__DEV__ && !runningTests) {
//       yield createLogger({ duration: true });
//     }
//   })()
// )(createStore);

// export const store = createStoreWithMiddleware(
//   pReducer,
//   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
// );

// // unsure if this is anything of use for unit tests, or even needed.
// // export const unitTestStore = createStoreWithMiddleware(pReducer);

// export const persistor = persistStore(store);

// if (window.__DEV__) {
//   window.store = store;
// }


import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import { GLOBAL, ROLES, TERMINAL_RESOURCE } from './constants/apiEndpoints';
import reducer from './reducers';
import logger from 'redux-logger';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  whitelist: [GLOBAL, ROLES, TERMINAL_RESOURCE, 'v2']
};

const persistedReducer = persistReducer(persistConfig, reducer);

console.log(`The site is in ${process.env.NODE_ENV}`);
window.__DEV__ = process.env.NODE_ENV !== 'production';
const runningTests = process.env.NODE_ENV === 'test';

export const store = configureStore({
  reducer: persistedReducer,
  devTools: window.__DEV__,
  middleware: window.__DEV__ && !runningTests ? [thunk, logger] : [thunk],
});

export const persistor = persistStore(store);
