import { remove, uniqBy } from 'lodash';
import { updateProfileUser } from '../../../actions/globalActions';
import { USERS } from '../../../constants/apiEndpoints';
import { SUCCESS } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import {
  deleteSitelessObjectPromise, getObjectPromise, patchSitelessObjectPromise, putSitelessObjectPromise
} from '../../../utils/api';
import { USERS_THAT_EDIT } from '../constants';
import * as types from './actionTypes';


// GET /user-groups
// PUT /user-groups/{group_id}
// DELETE /user-groups/{group_id}

export const sortUsersBegin = () => ({
  type: types.SORT_USERS_BEGIN
});

export const fetchUsersBegin = () => ({
  type: types.FETCH_USERS_BEGIN
});

export const fetchUsersSuccess = (users, paginationToken = null) => ({
  type: types.FETCH_USERS_SUCCESS,
  users,
  paginationToken
});

export const fetchUsersFailure = (allowEditing, error) => ({
  type: types.FETCH_USERS_FAILURE,
  allowEditing,
  error
});

export const deleteUserBegin = () => ({
  type: types.DELETE_USER_BEGIN
});

export const deleteUserSuccess = (users, username) => ({
  type: types.DELETE_USER_SUCCESS,
  users,
  username
});

export const deleteUserFailure = (error) => ({
  type: types.DELETE_USER_FAILURE,
  error
});

export function deleteUser(users, user, userState) {
  return (dispatch) => {
    dispatch(deleteUserBegin());
    deleteSitelessObjectPromise(USERS, user.email)
      .then((data) => {
        if (!httpSuccess(data.status)) {
          data.json().then((err) => dispatch(deleteUserFailure(`${err.detail} (${err.status})`)));
          return;
        }
        dispatch(deleteUserSuccess(users, `${user.given_name} ${user.family_name}`));
        dispatch(clearUsers());
        dispatch(fetchUsers('', userState.filter, true));
      }).catch((error) => {
        error.json().then((err) => dispatch(deleteUserFailure(`${user.email} - ${err.detail} (${err.status})`)));
      });
  };
}

export const updateDeleteRow = (user) => ({
  type: types.DELETE_CHECK,
  user
});

export const clearUsers = () => ({
  type: types.CLEAR_ALL_USERS
});

export const initialDataLoad = (bool) => ({
  type: types.INITIAL_LOAD_TRIGGERED,
  bool
});

export function fetchUsers(group, filterText, sort, paginationToken = null) {
  return (dispatch) => {
    dispatch(fetchUsersBegin());

    // TODO : Remove the sort k/v pair
    const userObj = {};
    if (group) {
      userObj.group = group;
    }

    if (paginationToken) {
      userObj.pagination_token = paginationToken;
    }

    if (filterText && filterText.length > 0) {
      const filteringOptions = ['username', 'email', 'given_name', 'family_name'];
      const doThisPromise = [];
      for (let j = 0; j < filteringOptions.length; j++) {
        userObj.search = `${filteringOptions[j]}:${encodeURIComponent(filterText)}`;
        doThisPromise.push(
          getObjectPromise(USERS, null, userObj)
            .then((obj) => (obj.users)).catch((error) => error)
        );
      }
      Promise.all(doThisPromise).then((data) => {
        remove(data, (n) => n.length === 0);
        const usersToReturn = uniqBy(data, 'username');
        dispatch(fetchUsersSuccess(usersToReturn && usersToReturn.length > 0 ? usersToReturn[0] : []));
      });
    } else {
      return getObjectPromise(USERS, null, userObj)
        .then((obj) => {
          dispatch(fetchUsersSuccess(obj.users, obj.pagination_token));
          return obj;
        }).catch((error) => {
          try {
            error.json().then((err) => {
              dispatch(fetchUsersFailure(sort, `${err.detail} (${err.status})`));
              return err;
            });
          } catch(err) {
            dispatch(fetchUsersFailure(sort, 'Error retrieving users. Try again later.'));
            return err;
          }
        });
    }
  };
}

export function sortUsers(users, sorting) {
  return (dispatch) => {
    dispatch(sortUsersBegin());
    const sortedArray = sortUserArray(users, sorting);
    dispatch(sortUsersSuccess(sortedArray));
  };
}

export const sortUsersSuccess = (users) => ({
  type: types.DISPLAY_SORTED_USERS,
  users
});

export function sortUserArray(users, sorting) {
  const [sort, dir] = sorting.split(':');

  return users.sort((a, b) => {
    if (
      !Date.parse(a[`${sort}`])
      && (a[`${sort}`] !== null &&
        b[`${sort}`] !== null
      )
    ) {
      if (a[`${sort}`].toUpperCase() < b[`${sort}`].toUpperCase()) {
        if (dir === 'asc') {
          return -1;
        } else if (dir === 'desc') {
          return 1;
        }
      }
      if (a[`${sort}`].toUpperCase() > b[`${sort}`].toUpperCase()) {
        if (dir === 'asc') {
          return 1;
        } else if (dir === 'desc') {
          return -1;
        }
      }
    } else {
      if (a[`${sort}`] === null) {
        return 1;
      }
      if (b[`${sort}`] === null) {
        return -1;
      }

      if (a[`${sort}`] < b[`${sort}`]) {
        if (dir === 'asc') {
          return -1;
        } else if (dir === 'desc') {
          return 1;
        }
      }
      if (a[`${sort}`] > b[`${sort}`]) {
        if (dir === 'asc') {
          return 1;
        } else if (dir === 'desc') {
          return -1;
        }
      }
    }

    return 0;
  });
}

export const updateSelectedUser = (user) => ({
  type: types.UPDATE_SELECTED_USER,
  user
});

export const addUserBegin = () => ({
  type: types.ADD_USER_BEGIN
});

export const addUserSuccess = (groups, sendEmail) => ({
  type: types.ADD_USER_SUCCESS,
  groups,
  sendEmail
});

export const addUserFailure = (error, sendEmail) => ({
  type: types.ADD_USER_FAILURE,
  error,
  sendEmail
});

// eslint-disable-next-line max-params
export function addUser(userObj, currGroup, filter, refetch, sendEmail, updateGlobalUser) {
  return (dispatch) => {
    dispatch(addUserBegin());

    const duplicateUser = { ...userObj };
    delete (duplicateUser.username);
    delete (duplicateUser.email);
    delete (duplicateUser.status);
    delete (duplicateUser.last_login);
    delete (duplicateUser.phone_number);
    delete (duplicateUser.country);

    return putSitelessObjectPromise(USERS, userObj.email, duplicateUser)
      .then((groups) => {
        if (!httpSuccess(groups.status)) {
          groups.json().then((err) => dispatch(addUserFailure(`${err.detail} (${err.status})`, sendEmail)));
          return;
        }
        dispatch(addUserSuccess(groups, sendEmail));
        if (updateGlobalUser) {
          dispatch(updateProfileUser(userObj));
        }
        if (!refetch) {
          dispatch(clearUsers());
          dispatch(fetchUsers(currGroup, filter, true));
        }
        return groups;
      }).catch((error) => {
        console.log(error);
        error.json().then((err) => dispatch(addUserFailure(`${err.detail} (${err.status})`, sendEmail)));
        return error;
      });
  };
}

export const getUserSitesBegin = () => ({
  type: types.GET_USER_SITES_BEGIN
});

export const getUserSitesSuccess = (sites) => ({
  type: types.GET_USER_SITES_SUCCESS,
  sites
});

export const getUserSitesFailure = (error) => ({
  type: types.GET_USER_SITES_FAILURE,
  error
});

export function getUserSites(userUserName, userEmail) {
  return (dispatch) => {
    dispatch(getUserSitesBegin());

    getObjectPromise(USERS, userUserName)
      .then((data) => {
        if (USERS_THAT_EDIT.includes(data.role) && data.sites[0] !== '*') {
          dispatch(getUserSitesSuccess(['*']));

          const existingUser = { ...data };
          existingUser.username = userUserName;
          existingUser.email = userEmail;
          existingUser.sites = ['*'];

          dispatch(addUser(existingUser, data.group, true));
          return;
        }

        dispatch(getUserSitesSuccess(data.sites));
      }).catch((error) => {
        dispatch(getUserSitesFailure(error));
      });
  };
}

export const updateSnackBar = (open, messageType = SUCCESS, message = '') => ({
  type: types.UPDATE_SNACK_BAR,
  open,
  messageType,
  message
});

export const resendPasswordBegin = () => ({
  type: types.RESEND_PASSWORD_BEGIN
});

export const resendPasswordSuccess = () => ({
  type: types.RESEND_PASSWORD_SUCCESS
});

export const resendPasswordFailure = (error) => ({
  type: types.RESEND_PASSWORD_FAILURE,
  error
});

export function resendPassword(user) {
  return (dispatch) => {
    dispatch(resendPasswordBegin());
    patchSitelessObjectPromise(USERS, user.email, { action: 'resendCode' })
      .then((data) => {
        console.log('UserActions:data', data);
        dispatch(resendPasswordSuccess());
      }).catch((error) => {
        console.log('Error occurred resendPassword', error);
        error.json().then((err) => dispatch(resendPasswordFailure(`${err.detail} (${err.status})`)));
      });

    // dispatch(getUserSitesBegin());

    // getObjectPromise(USERS, user.username)
    //   .then((data) => {
    //     const existingUser = { ...data };
    //     existingUser.username = user.username;
    //     existingUser.email = user.email;
    //     existingUser.sites = data.sites;

    //     dispatch(addUser(existingUser, data.group, false, true));
    //   }).catch((error) => {
    //     console.log('Error occurred resendPassword', error);
    //     error.json().then((err) => dispatch(addUserFailure(`${err.detail} (${err.status})`, true)));
    //   });
  };
}

export const setFilter = (filter) => ({
  type: types.SET_USER_FILTER,
  filter
});
