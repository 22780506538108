import { DialogTitle, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import CancelSubmit from './CancelSubmit';

const styles = () => ({
  bottomBorder: {
    borderBottomColor: '#f1f1f1',
    borderBottomWidth: '2px',
    borderBottomStyle: 'solid',
    paddingRight: 0
  },
  hdr: {
    fontSize: '16pt',
    height: '100%',
    margin: '8px 4px'
  },
});

export class SonifiModalHeader extends Component {
  render() {
    const { classes, onlyClose, submitToolTip } = this.props;

    return (
      <DialogTitle className={classes.bottomBorder}>
        <Grid container justifyContent="space-between">
          <Grid item xs={6} className={classes.hdr}>
            {this.props.header}
          </Grid>
          <Grid item xs={4}>
            <CancelSubmit
              disabled={this.props.disabled}
              label={this.props.label}
              onCancel={this.props.onCancel}
              onlyClose={onlyClose}
              onSubmit={this.props.onSubmit}
              submitToolTip={submitToolTip}
            />
          </Grid>
        </Grid>
      </DialogTitle>
    );
  }
}

SonifiModalHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  header: PropTypes.string,
  label: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onlyClose: PropTypes.bool,
  onSubmit: PropTypes.func,
  submitToolTip: PropTypes.string
};
export default React.memo(withStyles(SonifiModalHeader, styles));
