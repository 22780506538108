import { CheckCircleOutline, ErrorOutline } from '@mui/icons-material';
import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { SonifiLabel } from '../../../../containers';
import SonifiModalHeader from '../../../../containers/SonifiModalHeader';
import { dateToString } from '../../../../utils';
import { viewLogInfo } from '../actions/purchaseAction';

const styles = (theme) => ({
  goodStatus: {
    color: `${theme.palette.defaults.green} !important`
  },
  badStatus: {
    color: `${theme.palette.defaults.red} !important`
  },
  iconStyle: {
    paddingTop: '8px',
    width: '31px',
    height: '31px'
  },
  siteAssignment: {
    overflowX: 'hidden',
    wordBreak: 'break-all',
    border: `1px ${theme.palette.primary.border} solid`,
    marginBottom: '15px',
    paddingLeft: '7px'
  }
});

class LogDialog extends Component {
  onCancel(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(viewLogInfo(null));
    }
  }

  /* eslint-disable camelcase */
  render() {
    const { classes, selectedLog, timezone, translations } = this.props;

    const {
      checkout_time, reservation_number, purchase_price, purchase_time, room_number, status, transaction_time
    } = selectedLog;
    const statusString = status.charAt(0).toUpperCase() + status.slice(1);

    return (
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={this.onCancel.bind(this)}
        open={true}>
        <SonifiModalHeader
          header={translations.title}
          onCancel={this.onCancel.bind(this)}
          onlyClose={true}
        />
        <DialogContent>
          <Grid container>
            <Grid item xs={12}>
              <SonifiLabel boldLabel={translations.room}
                label={room_number ? room_number : ''} />
            </Grid>
            <Grid item xs={12}>
              <SonifiLabel boldLabel={translations.reservation} label={reservation_number} />
            </Grid>
            <Grid item xs={12}>
              <SonifiLabel boldLabel={translations.checkoutTime}
                label={checkout_time ? dateToString(checkout_time, timezone) : ''} />
            </Grid>
            <Grid item xs={12}>
              <SonifiLabel boldLabel={translations.date} label={dateToString(transaction_time, timezone)} />
            </Grid>
            <Grid item xs={12}><br /></Grid>
            <Grid item xs={12}>
              <Grid container className={classes.siteAssignment}>
                <Grid item xs={3}>
                  <SonifiLabel boldLabel={translations.purchaseTime}
                    label={moment(purchase_time, 'HH:mm').format('h:mm a')} />
                </Grid>
                <Grid item xs={3}>
                  <SonifiLabel boldLabel={translations.price} label={purchase_price} />
                </Grid>
                <Grid item>
                  {status === 'accepted' || status === 'purchased'
                    ? <CheckCircleOutline className={`${classes.iconStyle} ${classes.goodStatus}`} />
                    : <ErrorOutline className={`${classes.iconStyle} ${classes.badStatus}`} />}
                </Grid>
                <Grid item xs={2}>
                  <SonifiLabel boldLabel={translations.status} label={statusString} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLog: state.purchaseLogs.selectedLog,
  translations: state.purchaseLogs.translations.dialog,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago')
});

LogDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  selectedLog: PropTypes.object,
  translations: PropTypes.object,
  timezone: PropTypes.string.isRequired
};

// export default connect(mapStateToProps)(LogDialog);
export default connect(mapStateToProps)(withStyles(LogDialog, styles, { withTheme: true }));
