import SearchIcon from '@mui/icons-material/Search';
import {
  Grid, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { SonifiCheckbox, SonifiLabel, SonifiSpinner, SonifiSubHeader } from '../../../containers';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import SonifiText from '../../../containers/SonifiText';
import { fetchModels, startModels } from '../../TermModels/actions/termModelActions';

class ModelSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allTerms: false,
      filter: '',
      order: 'asc',
      orderBy: 'id',
      page: 1,
      queryObject: {
        page: 1,
        sort: 'id:asc',
        make: `${this.props.make}`,
        search: ''
      }
    };
  }

  tableHeads = () => [
    {
      centerHeader: false,
      checkbox: true,
      checkValue: !!this.props.selectAllChecked || this.state.allTerms,
      disabled: this.state.allTerms || !this.props.canEdit,
      error: this.props.errors,
      id: '',
      label: '',
      noLabel: true,
      numeric: false,
      onChange: this.props.checkboxChange(),
      sortable: false
    },
    {
      centerHeader: false,
      id: 'id',
      label: this.props.translations.model,
      numeric: false,
      sortable: !this.props.uploadErrorMsg
    }
  ];

  componentDidMount() {
    const { selectedModels } = this.props;
    if (selectedModels && selectedModels.length > 0) {
      this.setState({ allTerms: selectedModels[0] === '*' });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selectedModels !== this.props.selectedModels) {
      this.setState({ allTerms: this.props.selectedModels[0] === '*' });
    }

    if (prevState.queryObject !== this.state.queryObject) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        if (this.state.queryObject.search !== '') {
          this.props.dispatch(startModels());
        }

        this.props.dispatch(fetchModels(this.state.queryObject, false));
      }, 1000);
    }
  }

  handlePopover = (model) => () => {
    if (this.props.canEdit && !this.state.allTerms) {
      this.props.removeSelectAll();
      this.updateModels(model);
    }
  };

  handleRequestSort = () => {
    this.props.dispatch(startModels());
    this.setState((prevState) => ({
      order: this.state.order === 'desc' ? 'asc' : 'desc',
      page: 1,
      queryObject: {
        ...prevState.queryObject,
        sort: prevState.order === 'desc'
          ? `${prevState.orderBy}:asc`
          : `${prevState.orderBy}:desc`
      }
    }));
  };

  searchModels = ({ target: { value } }) => {
    this.setState((prevState) => ({
      filter: value,
      page: 1,
      queryObject: {
        ...prevState.queryObject,
        search: value,
        page: 1
      }
    }));
  };

  updateModels(modelObj) {
    const checked = !this.props.isModelSelected(modelObj);
    let tempModels = [...this.props.selectedModels];
    if (checked) {
      tempModels.push(modelObj);
    } else {
      tempModels = tempModels.filter((e) => e !== modelObj);
    }
    this.props.updateModels(tempModels);
  }

  handleCheckChange = ({ target: { checked } }) => {
    this.setState({ allTerms: checked });
    this.props.updateSelectAll(checked);
  };

  getMoreData = () => {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));

      const queryString = {
        page: this.state.page + 1,
        sort: `${this.state.orderBy}:${this.state.order}`,
        make: `${this.props.make}`
      };

      if (this.state.filter !== '') {
        queryString.search = this.state.filter;
      }

      this.props.dispatch(fetchModels(queryString));
    }
  };

  getPageDetails() {
    const {
      canEdit, firmware, globalTranslations, isModelSelected, maxPages,
      loading, make, models, selectedFirmware, translations, uploadErrorMsg
    } = this.props;
    const {
 allTerms, filter, order, orderBy, page
} = this.state;
    const shownModels = (canEdit || allTerms
      ? models
      : map(firmware[selectedFirmware].models, (item) => ({ id: item })));
    return (
      <Fragment>
        <Grid container style={{ justifyContent: 'space-between', paddingBottom: canEdit ? 0 : '10px' }}>
          <SonifiSubHeader
            header={`${make} ${translations.terminals}`}
            showButton={false}
            disabled={true}
            middleExtra={
              <Grid container>
                {allTerms && <Grid item>
                  <SonifiCheckbox
                    noLabel={false}
                    label={translations.allTerms}
                    value={allTerms}
                    disabled={allTerms || !canEdit}
                    onChange={this.handleCheckChange.bind(this)}
                  />
                </Grid>}
                {canEdit && <Grid item>
                  <SonifiText
                    label={globalTranslations.search}
                    defaultValue={filter}
                    change={this.searchModels.bind(this)}
                    icon={<SearchIcon />}
                    iconPosition={'end'}
                    size="mdNoPad"
                    disabled={shownModels.length < 1}
                  />
                </Grid>}
              </Grid>}
          />
        </Grid>
        <Table stickyHeader={true}>
          <SonifiTableHead
            headColumns={this.tableHeads()}
            order={order}
            orderBy={orderBy}
            onRequestSort={this.handleRequestSort}
          />
          <TableBody>
            {loading &&
              filter !== '' &&
              <TableRow>
                <TableCell colSpan={2}>
                  <SonifiSpinner />
                </TableCell>
              </TableRow>
            }
            {!loading && shownModels.length === 0 &&
              <Fragment>
                <TableRow>
                  <TableCell colSpan={2}>
                    <SonifiLabel error={!uploadErrorMsg && !allTerms}
                      label={uploadErrorMsg
                        ? translations.errors.uploadFile
                        : allTerms
                          ? translations.errors.allModels
                          : translations.errors.noModels} />
                  </TableCell>
                </TableRow>
              </Fragment>
            }
            {shownModels.length > 0 &&
              <Fragment>
                {
                  shownModels.map((option, index) => {
                    if (index < shownModels.length - 1) {
                    return (
                      <TableRow
                        key={index}
                        hover={true}
                        onClick={this.handlePopover(option.id)}
                      >
                       <TableCell>
                          <SonifiCheckbox
                            noLabel={true}
                             label=""
                            value={allTerms || isModelSelected(option.id)}
                            disabled={!canEdit || allTerms}
                          />
                        </TableCell>
                        <TableCell>{option.id}</TableCell>
                      </TableRow>
                    );
                  } else if (
                    index >= shownModels.length - 1
                  ) {
                    return (
                      <Fragment
                        key={index}
                      >
                        <TableRow
                          key={index}
                          hover={true}
                          onClick={this.handlePopover(option.id)}
                        >
                          <TableCell>
                            <SonifiCheckbox
                              noLabel={true}
                              label=""
                              value={allTerms || isModelSelected(option.id)}
                              disabled={!canEdit || allTerms}
                            />
                          </TableCell>
                          <TableCell>{option.id}</TableCell>
                        </TableRow>
                        { page < maxPages &&
                          <TableRow>
                            <TableCell colSpan={6}>
                              <Waypoint onEnter={() => { this.getMoreData(); }} />
                            </TableCell>
                          </TableRow>
                        }
                      </Fragment>
                    );
                  }
                })
              }
              </Fragment>
          }
          </TableBody>
        </Table>
      </Fragment>
    );
  }

  render() {
    return (
      <SonifiTemplate
        pageDetails={this.getPageDetails()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  firmware: state.firmware.firmware,
  globalTranslations: state.global.translations.defaults,
  loading: state.termModel.loading,
  models: state.termModel.models,
  maxPages: state.termModel.maxPages,
  selectedFirmware: state.firmware.selectedFirmware,
  translations: state.firmware.translations.editDialog
});

ModelSelector.propTypes = {
  canEdit: PropTypes.bool,
  checkboxChange: PropTypes.func,
  dispatch: PropTypes.func,
  errors: PropTypes.bool,
  firmware: PropTypes.array,
  globalTranslations: PropTypes.object,
  isModelSelected: PropTypes.func,
  loading: PropTypes.bool,
  make: PropTypes.string,
  maxPages: PropTypes.number,
  models: PropTypes.array,
  removeSelectAll: PropTypes.func,
  selectAllChecked: PropTypes.bool,
  selectedFirmware: PropTypes.number,
  selectedModels: PropTypes.array,
  translations: PropTypes.object,
  updateModels: PropTypes.func,
  updateSelectAll: PropTypes.func,
  uploadErrorMsg: PropTypes.bool
};

export default connect(mapStateToProps)(ModelSelector);
