import { createSlice } from '@reduxjs/toolkit';

export const siteSlice = createSlice({
    name: 'site',
    initialState: {},
    reducers: {
      setSiteId: (state, action) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.site.id = action.payload;
      },
      setSiteName: (state, action) => {
        state.site.name = action.payload;
      },
      setSiteDisplayName: (state, action) => {
        state.site.display_name = action.payload;
      },
      clearSite: (state) => {
        state.site = {};
      },
    }
  });
  
  export const { setSiteId, setSiteName, setSiteDisplayName, clearSite } = siteSlice.actions;
  
  export default siteSlice.reducer;
