export const CHANNEL_SET_CHECK = 'CHANNEL_SET_CHECK';
export const DELETE_CHANNEL = 'DELETE_CHANNEL';
export const DELETE_DRAFT_BEGIN = 'DELETE_DRAFT_BEGIN';
export const DELETE_DRAFT_FAILURE = 'DELETE_DRAFT_FAILURE';
export const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS';
export const EDIT_CHANNEL_NUMBER = 'EDIT_CHANNEL_NUMBER';
export const FETCH_CHANNELS_EXPORT_BEGIN = 'FETCH_CHANNELS_EXPORT_BEGIN';
export const FETCH_CHANNELS_FAILURE = 'FETCH_CHANNELS_FAILURE';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const FETCH_CORE_CHANNELS_BEGIN = 'FETCH_CORE_CHANNELS_BEGIN';
export const FETCH_CORE_CHANNELS_FAILURE = 'FETCH_CORE_CHANNELS_FAILURE';
export const FETCH_CORE_CHANNELS_SUCCESS = 'FETCH_CORE_CHANNELS_SUCCESS';
export const MOVE_CHANNEL = 'MOVE_CHANNEL';
export const PUBLISH_DRAFT_BEGIN = 'PUBLISH_DRAFT_BEGIN';
export const PUBLISH_DRAFT_FAILURE = 'PUBLISH_DRAFT_FAILURE';
export const PUBLISH_DRAFT_SUCCESS = 'PUBLISH_DRAFT_SUCCESS';
export const RESORT_CHANNELS = 'RESORT_CHANNELS';
export const SAVE_CHANNEL_LIST_BEGIN = 'SAVE_CHANNEL_LIST_BEGIN';
export const SAVE_CHANNEL_LIST_FAILURE = 'SAVE_CHANNEL_LIST_FAILURE';
export const SAVE_CHANNEL_LIST_SUCCESS = 'SAVE_CHANNEL_LIST_SUCCESS';
export const SET_AVAILABLE_CHANNEL_SETS = 'SET_AVAILABLE_CHANNEL_SETS';
export const SET_CHANNEL_SET = 'SET_CHANNEL_SET';
export const SET_CHANNEL_SNACKBAR = 'SET_CHANNEL_SNACKBAR';
export const SET_CREATING_DRAFT = 'SET_CREATING_DRAFT';
export const SET_DRAGGING_ROW = 'SET_DRAGGING_ROW';
export const SET_DROPPED_ROW = 'SET_DROPPED_ROW';
export const SET_FAILURE_MESSAGE = 'SET_FAILURE_MESSAGE';
export const SET_FIRST_LOAD = 'SET_FIRST_LOAD';
export const SET_LOADING = 'SET_LOADING';
export const UPDATE_CHANNEL_DETAILS = 'UPDATE_CHANNEL_DETAILS';
export const UPDATED_SELECTED_CHANNEL = 'UPDATED_SELECTED_CHANNEL';
