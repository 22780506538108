import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };

const initialState = {
  lineups: [],
  loading: false,
  menusets: [],
  rooms: [],
  roomTerminals: [],
  snackBarMessage: '',
  snackBarType: SUCCESS,
  terminalLocations: [],
  translations: null
};

export default function provisionTermsReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_PROVISION_LINK:
      return { ...state, snackBarMessage: '' };

    case types.UPDATE_PROVISION_LINK:
      return { ...state, snackBarType: action.snackBarType, snackBarMessage: action.snackBarMessage };

    case types.GET_PROVISION_TERM_INFO_FAILURE:
      return { ...state, snackBarType: ERROR, snackBarMessage: action.error, loading: false };

    case types.GET_SITE_ROOMS_BEGIN:
      return { ...state, rooms: [], loading: true };

    case types.GET_SITE_ROOMS_SUCCESS:
      return { ...state, rooms: action.info.rooms, loading: false };

    case types.GET_PROVISION_ROOM_TERMINALS_SUCCESS:
      return {
        ...state,
        lineups: action.siteInfo.lineups,
        loading: false,
        menusets: action.siteInfo.menusets,
        terminalLocations: action.siteInfo.locations
      };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
