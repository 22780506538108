import { Stop } from '@mui/icons-material';
import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { READ } from '../../../constants/constants';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import { dateToString } from '../../../utils';
import { stopJobCheck, viewChannelChange } from '../actions/batchChannelsAction';
import { getIcon } from '../utils';

class BatchRow extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteDialog = this.handleDeleteDialog.bind(this);
    this.handleEditDialog = this.handleEditDialog.bind(this);
  }

  handleEditDialog() {
    const { channels, dispatch, index } = this.props;
    dispatch(viewChannelChange(channels[index], READ));
  }

  handleDeleteDialog = () => {
    const { channels, dispatch, index } = this.props;
    dispatch(stopJobCheck(channels[index].id));
  };

  render() {
    const { globalTranslations, channels, index, timezone } = this.props;
    const { created, status, target } = channels[index];

    return (
      <SonifiPopover
        functionOneFunc={this.handleEditDialog}
        functionOneTitle={globalTranslations.view}
        deleteDisabled={status !== 'In Progress'}
        deleteFunc={this.handleDeleteDialog}
        deleteIcon={<Stop fontSize="small" />}
        deleteTitle={globalTranslations.stop}
        hideEdit={true}
        showFunctionOne={true}
        tableRow={
          <Fragment>
            <TableCell component="th" scope="row">
              {getIcon(status)}
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={dateToString(created, timezone)} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={`${target.number}`} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={target.name} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={target.affiliate} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={target.timezone} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={`${target.source_id}`} />
            </TableCell>
          </Fragment >
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  channels: state.batchChannels.channels,
  globalTranslations: state.global.translations.defaults,
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago'),
});

BatchRow.propTypes = {
  channels: PropTypes.array.isRequired,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  index: PropTypes.number.isRequired,
  timezone: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(BatchRow);
