import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { IconButton, Snackbar, SnackbarContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';
import { ERROR, SUCCESS, INFO } from '../constants/constants';

const styles = (theme) => ({
  success: {
    backgroundColor: 'green'
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: 'amber'
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'pre-line'
  }
});

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export class SonifiSnackbar extends Component {
  handleClose(event, reason) {
    if (reason === 'clickaway') {
      return;
    }

    this.props.onClose();
  }

  render() {
    const { classes, message, open, variant } = this.props;

    const Icon = variantIcon[variant];

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={this.handleClose.bind(this)}
      >
        <SnackbarContent
          className={classes[variant]}
          aria-describedby="client-snackbar"
          message={
            <span id="client-snackbar" className={classes.message}>
              <Icon className={`${classes.icon} ${classes.iconVariant}`} />
              {message}
            </span>
          }
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={this.handleClose.bind(this)}>
              <CloseIcon className={classes.icon} />
            </IconButton>,
          ]}

        />
      </Snackbar>
    );
  }
}

SonifiSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  message: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([ERROR, INFO, SUCCESS, 'warning']).isRequired,
  onClose: PropTypes.func
};

export default withStyles(SonifiSnackbar, styles, { withTheme: true });
