// this component is everythign that is within a Library item modal, header and title.
// Individual message elements are within MessageFormElements.

import DialogContent from '@mui/material/DialogContent';
import { filter, find, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  DEFAULT_SOURCE_TYPE, FORM_MODE, NOT_FOUND
} from '../../../constants/messages';
import ModalSectionHeader from '../../../containers/ModalSectionHeader';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import { getUserNameFromSession } from '../../../utils';
import { addPublishItemBegin, addPublishItemError, getLibraryItem } from '../actions/messagingActions';
import { getFormErrors } from '../utils/validator';
import MessageFormElements from './MessageFormElements';

export class MessageForm extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.props.dispatch(getLibraryItem(this.props.selectedItem));
    this.state = {
      dialogLoading: props.dialogLoading,
      origMessageId: '',
      messageId: '',
      sourceType: DEFAULT_SOURCE_TYPE,
      translations: [],
      user_name: '',
      language: props.defaultLanguage,
      formErrors: [],
    };
  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps) {
    // console.log('COMPONENTDIDUPDATE MESSAGEFORM');
    // console.log('prevProps', prevProps);
    // console.log('Props', this.props);
    // console.log('prevState', prevState);
    // console.log('State', this.state);

    if ((this.props.mode === FORM_MODE.ADD) && (this.state.dialogLoading === true)) {
      this.setState({
        dialogLoading: false,
        availableLanguages: this.props.languages
      });
    } else if ((prevProps.dialogLoading === true) && (this.props.dialogLoading === false)) {
      if ((this.props.libraryItem) && (this.props.dialogError.length === 0)) {
        if (this.props.mode === FORM_MODE.COPY) {
          const { libraryItem } = this.props;
          const messageId = `${libraryItem.id}-copy`;
          this.setState({
            dialogLoading: false,
            ...libraryItem,
            messageId,
            availableLanguages: this.props.languages
          });
        } else if (this.props.mode === FORM_MODE.VIEW) {
          const libraryItem = this.props.libraryItem;
          libraryItem.messageId = libraryItem.id;
          delete libraryItem.id;

          let availableLanguages =
            libraryItem.translations.map(
              (item) => this.props.languages.filter(((e) => e.iso_code_639_1 === item.id))[0]
            );

          if (availableLanguages.length === 0) {
            availableLanguages = this.props.languages;
          }
          availableLanguages = orderBy(availableLanguages, ['sequence']);

          let setLang = this.state.language;
          if (find(availableLanguages, { iso_code_639_1: this.state.language }) === undefined) {
            setLang = availableLanguages[0].iso_code_639_1;
          }
          this.setState({
            dialogLoading: false,
            ...libraryItem,
            availableLanguages,
            language: setLang
          });
        } else {
          const libraryItem = this.props.libraryItem;
          libraryItem.messageId = libraryItem.id;
          delete libraryItem.id;
          this.setState({
            dialogLoading: false,
            ...libraryItem,
            origMessageId: libraryItem.messageId,
            availableLanguages: this.props.languages
          });
        }
      }
    }
  }

  // state is managed in this component, even though the values reside in MessageFormElements
  handleChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  // state is managed in this component, even though the values reside in MessageFormElements
  handleTranslationChange = (name, value) => {
    const { language, translations } = this.state;
    const pos = translations.map((e) => e.id).indexOf(language);

    switch (name) {
      case 'title':
        if (pos === NOT_FOUND) {
          translations.push({
            id: language,
            title: value,
            body: '',
          });
        } else {
          translations[pos].title = value;
        }
        break;
      case 'body':
        if (pos === NOT_FOUND) {
          translations.push({
            id: language,
            title: '',
            body: value,
          });
        } else {
          translations[pos].body = value;
        }
        break;
      default:
        break;
    }

    this.setState({ translations });
  };

  handleSubmit = () => {
    this.props.dispatch(addPublishItemBegin());
    this.setState({ formErrors: [] });
    const removeBlankTranslations =
      filter(this.state.translations, (transValue) => transValue.title !== '' || transValue.body !== '');

    const tempMessageObj = { ...this.state, translations: removeBlankTranslations };
    getFormErrors(tempMessageObj, this.props.globalTranslations.errors, this.props.translation)
      .then((formErrors) => {
        console.log(' LIBRARY FORMERRORS', formErrors);
        if (formErrors.length) {
          this.setState({ formErrors });
          this.props.dispatch(addPublishItemError());
        } else {
          const { sourceType, translations } = this.state;
          if ([FORM_MODE.ADD, FORM_MODE.COPY].includes(this.props.mode)) {
            // onSubmit is passed in as a prop from FormDialog

            this.props.onSubmit({
              ...this.state,
              sourceType,
              user_name: getUserNameFromSession(),
            }, translations);
          } else {
            this.props.onSubmit({
              ...this.state,
              sourceType,
            }, translations);
          }
        }
      }).catch((error) => {
        console.log('handleSubmit failure:', error);
      });
  };

  render() {
    const { globalTranslations, header, mode, translation } = this.props;

    /* eslint-disable camelcase */
    const {
      availableLanguages, formErrors, messageId, translations, language, dialogLoading,
    } = this.state;

    if (!mode) {
      return <Fragment />;
    }

    if (dialogLoading) {
      return <SonifiSpinner />;
    }

    const readOnly = !!((mode === FORM_MODE.VIEW));
    const disableNameEdit = [FORM_MODE.VIEW].includes(mode);

    let title = '',
      body = '';
    const selected = translations.filter(((e) => e.id === language));
    if ((selected !== undefined) && (selected[0])) {
      title = selected[0].title;
      body = selected[0].body;
    }

    return <Fragment>
      <SonifiModalHeader
        header={header}
        onlyClose={readOnly}
        onCancel={this.props.onCancel}
        onSubmit={this.handleSubmit.bind(this)}
        disabled={messageId === ''}
        label={globalTranslations.defaults.save}
      />
      <DialogContent>
        <ModalSectionHeader label={translation.details} />
        <MessageFormElements
          availableLanguages={availableLanguages}
          body={body}
          errors={formErrors}
          language={language}
          messageId={messageId}
          nameEditDisabled={disableNameEdit}
          onChange={this.handleChange}
          onTranslationChange={this.handleTranslationChange}
          readOnly={readOnly}
          title={title}
        />
      </DialogContent>
    </Fragment>;
  }
}

const mapStateToProps = (state) => ({
  defaultLanguage: state.global.defaultLanguage,
  dialogError: state.messaging.dialogError,
  dialogLoading: state.messaging.dialogLoading,
  globalTranslations: state.global.translations,
  languages: state.global.languages,
  libraryItem: state.messaging.libraryItem,
  selectedItem: state.messaging.selectedLibraryItem,
  translation: state.messaging.translations.form
});

MessageForm.propTypes = {
  defaultLanguage: PropTypes.string,
  dialogError: PropTypes.array,
  dialogLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  header: PropTypes.string.isRequired,
  languages: PropTypes.array.isRequired,
  libraryItem: PropTypes.object,
  mode: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  selectedItem: PropTypes.string,
  translation: PropTypes.object
};
export default connect(mapStateToProps)(MessageForm);
