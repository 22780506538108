import { CheckCircleOutline, Warning } from '@mui/icons-material';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiIconButton } from '../../../containers';
import SonifiPopover from '../../../containers/SonifiPopover';
import * as actions from '../actions/tvLineupActions';
import { getChannelsThatCanBeInLineup, validLineup } from '../utils/validator';

class LineupRow extends Component {
  state = {
    msg: ''
  };

  componentDidMount() {
    this.setState({ msg: this.getToolTipMessage() });
  }

  handleEditDialog() {
    const { dispatch, lineup } = this.props;
    dispatch(actions.setSelectedChannelLineups(lineup));
  }

  handleDeleteDialog() {
    const { dispatch, lineup } = this.props;
    dispatch(actions.deleteLineupCheck(lineup));
  }

  getToolTipMessage() {
    const { globalTranslations, lineup, realChannels, translations } = this.props;
    const chans = getChannelsThatCanBeInLineup(lineup, realChannels);
    const valid = validLineup(lineup, chans, translations.errors, globalTranslations);
    let msg = '';
    if (valid.validationErrors && valid.validationErrors.length > 0) {
      for (let i = 0; i < valid.validationErrors.length; i++) {
        msg += `${valid.validationErrors[i]} \n`;
      }
    }
    return msg;
  }

  /* eslint-disable camelcase */
  getLineupRow() {
    const { msg } = this.state;
    const { lineup, translations } = this.props;
    const {
      classification, name, cables, in_use, tuner_type, mpeg_format
    } = lineup;

    return (
      <Fragment>
        <TableCell component="th" scope="row">
          {msg.length > 1
            ? <SonifiIconButton disabled={true} icon={<Warning fontSize="small" style={{ color: 'red' }} />}
              toolTipMessage={msg} showToolTip={true} />
            : in_use && <Tooltip title={translations.inUse}>
              <CheckCircleOutline style={{ color: 'green' }} />
            </Tooltip>}
        </TableCell>
        <TableCell component="th" scope="row">{name}</TableCell>
        <TableCell component="th" scope="row">{classification}</TableCell>
        <TableCell component="th" scope="row">{cables}</TableCell>
        <TableCell component="th" scope="row">{tuner_type}</TableCell>
        <TableCell component="th" scope="row">{mpeg_format}</TableCell>
      </Fragment >
    );
  }

  render() {
    const { canEdit, globalTranslations, lineup } = this.props;
    return (
      canEdit
        ? <SonifiPopover
          editDisabled={!canEdit}
          editFunc={this.handleEditDialog.bind(this)}
          editTitle={globalTranslations.edit}
          deleteDisabled={!canEdit || lineup.in_use}
          deleteFunc={this.handleDeleteDialog.bind(this)}
          deleteTitle={globalTranslations.delete}
          key={lineup.id}
          tableRow={this.getLineupRow()}
        />
        : <TableRow> {this.getLineupRow()} </TableRow>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  realChannels: state.channels.realChannels,
  translations: state.tvLineup.translations.editDialog
});

LineupRow.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  lineup: PropTypes.object,
  realChannels: PropTypes.array,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(LineupRow);
