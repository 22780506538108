import { debounce, Grid } from '@mui/material';
import { noop } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { KEYBOARD_DELAY } from '../../../../constants/keyCodes';
import SonifiDateTimePicker from '../../../../containers/SonifiDateTimePicker';
import SonifiText from '../../../../containers/SonifiText';

/* eslint-disable camelcase*/
class PurchaseFilter extends Component {
  state = {
    search: '',
    start: null,
    nowDate: moment().format('MM/DD/YYYY hh:mm a')
  };

  componentDidMount() {
    this.updateState('start', moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ss'));
  }

  handleDateChange = (name) => (value) => {
    this.updateState(name, value === null ? null : moment(value).format('YYYY-MM-DDTHH:mm:ss'));
  };

  handleOnChange = (name) => ({ target: { value } }) => {
    this.updateState(name, value);
  };

  debouncedLoadMoreData = debounce(this.searchData, KEYBOARD_DELAY);

  updateState = (name, value) => {
    this.setState({ [name]: value }, () => {
      this.debouncedLoadMoreData();
    });
  };

  searchData() {
    const { search, start } = this.state;
    this.props.searchFunc(search, start);
  }

  render() {
    const { globalTranslations, translations } = this.props;
    const { nowDate, search, start } = this.state;

    return (
      <Fragment>
        <Grid container style={{ justifyContent: 'space-between' }}>
          <Grid item xs={3}>
            <SonifiDateTimePicker
              change={noop}
              defaultValue={nowDate}
              disabled={true}
              inputProps={{
                'data-testid': 'endDateTimePicker',
              }}
              label={translations.startDate}
              size="percent"
            />
          </Grid>
          <Grid item xs={3}>
            <SonifiDateTimePicker
              change={this.handleDateChange('start')}
              defaultValue={start}
              disablePast={false}
              inputProps={{
                'data-testid': 'startDatetimePicker',
              }}
              label={translations.endDate}
              size="percent"
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3}>
            <SonifiText
              defaultValue={search}
              label={globalTranslations.search}
              change={this.handleOnChange('search')}
              size="percentNoPad" />
          </Grid>
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  translations: state.purchaseLogs.translations.grid
});

PurchaseFilter.propTypes = {
  searchFunc: PropTypes.func,
  globalTranslations: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(PurchaseFilter);
