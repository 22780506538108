import { Add } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SOFTWARE_WRITE } from '../../../constants/roles';
import { SonifiLabel, SonifiTableHead } from '../../../containers';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import {
  setTemplatePackages, updateSelectedPackageRow, updateSnackBar, updateTemplatePackages
} from '../actions/releaseTemplateActions';
import DialogRow from '../containers/DialogRow';

class DialogGrid extends Component {
  state = {
    availablePackages: [],
    currPackages: []
  };

  componentDidMount() {
    this.filterPackageOptions('');
    this.props.dispatch(setTemplatePackages());
  }

  addPackage() {
    const { dispatch, editType, templatePackages } = this.props;
    const { availablePackages } = this.state;
    const tempPackages = [...templatePackages];
    for (let i = 0; i < availablePackages.length; i++) {
      if (availablePackages[i].visible) {
        tempPackages.push({ name: availablePackages[i].id, version: availablePackages[i].releases[0] });
        break;
      }
    }

    dispatch(updateTemplatePackages(tempPackages));
    dispatch(updateSelectedPackageRow(tempPackages.length - 1, editType));
  }

  updateGridPackages(rowObj) {
    // const { templatePackages } = this.state;
    const { dispatch, selectedPkgRow, templatePackages } = this.props;
    const tempPackages = [...templatePackages];
    tempPackages[selectedPkgRow].name = rowObj.packageName;
    tempPackages[selectedPkgRow].version = rowObj.version;
    dispatch(updateTemplatePackages(tempPackages));
    this.filterPackageOptions(rowObj.packageName);
  }

  deleteRow(rowIndex) {
    const { dispatch, editType, templatePackages } = this.props;
    const tempPackages = [...templatePackages];
    tempPackages.splice(rowIndex, 1);
    dispatch(updateTemplatePackages(tempPackages));
    dispatch(updateSelectedPackageRow(-1, editType));
  }

  filterPackageOptions(currPackage) {
    const { packagesOpt } = this.props;
    const packAvail = [];

    // const packAvail = differenceWith(packagesOpt, filter(map(availablePackages, 'id'), (o) => o !== currPackage));
    for (let i = 0; i < packagesOpt.length; i++) {
      packAvail.push(packagesOpt[i]);
      if (packagesOpt[i].id === currPackage) {
        packAvail[i].visible = false;
      } else {
        packAvail[i].visible = true;
      }
    }
    this.setState({
      availablePackages: packAvail,
    });

    // return packAvail;
  }

  closeSnackBar() {
    this.props.dispatch(updateSnackBar(''));
  }

  getToolTipMessage() {
    const {
      packagesOpt, selected, templatePackages, translations
    } = this.props;

    let addToolTipMsg = '';
    if (!packagesOpt || packagesOpt.length < 1) {
      addToolTipMsg += `${translations.errors.noPackages} \n`;
    }

    if (selected !== -2 && (!templatePackages || templatePackages.length < 1)) {
      addToolTipMsg += `${translations.errors.noTemplatePackages} \n`;
    }

    if (templatePackages.length === packagesOpt.length) {
      addToolTipMsg += `${translations.errors.equal} \n`;
    }

    return addToolTipMsg;
  }

  getPageDetails(canEdit) {
    const {
      packageError, packagesOpt, templatePackages, translations
    } = this.props;
    const packAvail = [];
    for (let i = 0; i < packagesOpt.length; i++) {
      packAvail.push(packagesOpt[i]);
      packAvail[i].visible = true;
      for (let j = 0; j < templatePackages.length; j++) {
        if (packagesOpt[i].id === templatePackages[j].name) {
          packAvail[i].visible = false;
        }
      }
    }

    const tableHeader = [
      { id: 'name', sortable: false, numeric: false, label: `${translations.packageName}` },
      { id: 'version', sortable: false, numeric: false, label: `${translations.version}` }
    ];

    return <Table>
      <SonifiTableHead headColumns={tableHeader} />
      <TableBody>
        {(!templatePackages || templatePackages.length === 0)
          ? <TableRow>
            <TableCell colSpan={3}>
              <SonifiLabel error={packageError} label={translations.errors.noData} />
            </TableCell>
          </TableRow>
          : templatePackages.map((option, index) => (
            <Fragment key={`w_${index}`}>
              <DialogRow
                availablePackages={packAvail}
                canEdit={canEdit}
                changeFunc={this.updateGridPackages.bind(this)}
                deleteEnabled={canEdit}
                gridDeleteFunc={this.deleteRow.bind(this)}
                key={index}
                name={option.name}
                rowIndex={index}
                version={`${option.version}`}
              />
            </Fragment>
          ))}
      </TableBody>
    </Table>;
  }

  render() {
    const {
      editType, globalTranslations, translations, userPermissions
    } = this.props;
    const canEdit = checkForSingleUserPermission(SOFTWARE_WRITE, userPermissions);
    const toolTipMsg = this.getToolTipMessage();

    return (
      <Fragment>
        <SonifiTemplate
          disabled={toolTipMsg !== ''}
          header={translations.header}
          icon={<Add />}
          label={globalTranslations.add}
          onSubmit={this.addPackage.bind(this)}
          pageDetails={this.getPageDetails(canEdit)}
          showButton={editType !== 'read'}
          toolTip={toolTipMsg}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.releaseTemplates.changed,
  editType: state.releaseTemplates.editType,
  globalTranslations: state.global.translations.defaults,
  packagesOpt: state.releaseTemplates.packagesOpt,
  releaseTemplates: state.releaseTemplates.releaseTemplates,
  selected: state.releaseTemplates.selected,
  selectedPkgRow: state.releaseTemplates.selectedPkgRow,
  templatePackages: state.releaseTemplates.templatePackages,
  translations: state.releaseTemplates.translations.editDialog,
  userPermissions: state.global.permissions
});

DialogGrid.propTypes = {
  changed: PropTypes.bool,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  globalTranslations: PropTypes.object,
  packageError: PropTypes.bool,
  packagesOpt: PropTypes.array,
  releaseTemplates: PropTypes.array,
  selected: PropTypes.number,
  selectedPkgRow: PropTypes.number,
  templatePackages: PropTypes.array,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(DialogGrid);
