import { Grid } from '@mui/material';
import React, { Fragment } from 'react';
import { FIAS_IP, FIAS_SERIAL, taxTypes } from '../../constants/constants';
import TieMap from './TieMap';

function isInterfaceFias(interfaceType) {
  return [FIAS_IP, FIAS_SERIAL].indexOf(interfaceType) > -1;
}

/* eslint-disable camelcase */
export default class Tax extends TieMap {
  render() {
    const { editState: { interface: { interface_type } } } = this.props;
    const isFias = isInterfaceFias(interface_type);

    return (
      <Fragment>
        <Grid item xs={12}>
          {/* {this.getDropDown('T0', taxTypes)} */}
          {this.getDropDown('T1', taxTypes)}
          {isFias && this.getDropDown('T2', taxTypes)}
          {isFias && this.getDropDown('T3', taxTypes)}
          {isFias && this.getDropDown('T4', taxTypes)}
          {isFias && this.getDropDown('T5', taxTypes)}
          {isFias && this.getDropDown('T6', taxTypes)}
          {isFias && this.getDropDown('T7', taxTypes)}
          {isFias && this.getDropDown('T8', taxTypes)}
          {isFias && this.getDropDown('T9', taxTypes)}
        </Grid>
      </Fragment>
    );
  }
}
