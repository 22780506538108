import satisfies from 'semver/functions/satisfies';

/**
 * Validate that an upgrade version is a valid semver upgrade.
 *
 * @param {object} translations - An object with translation strings.
 * @param {string} translations.outdatedVersion - An error
 *     string for an outdated version.
 *
 * @param {string} original - The original version.
 * @param {string} selected  - The selected version we want to upgrade to.
 *
 * @returns {object} An empty object if there are no errors. Otherwise,
 *     an object with an `upgrade` attribute set to an error string.
 */
export function validateUpgrade(translations, original, selected) {
  const errors = {};

  if (original !== null && !satisfies(selected, `>=${original}`)) {
    errors.upgrade = translations.outdatedVersion;
  }

  return errors;
}
