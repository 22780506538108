export const pmsTypes = [
  { label: 'All', value: 'all' },
  { label: 'Check In', value: 'checkin' },
  { label: 'Check Out', value: 'checkout' },
  { label: 'Room Move', value: 'move' },
  { label: 'Room Update', value: 'update' },
  { label: 'Room Look', value: 'look' },
  { label: 'Account Purchase', value: 'purchase' },
  { label: 'Account Review', value: 'folio' },
  { label: 'Link Message', value: 'link_message' },
  { label: 'Link Up', value: 'link_connect' },
  { label: 'Link Down', value: 'link_disconnect' },
  { label: 'Link Status', value: 'link_status' },
];
