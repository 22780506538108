import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SONIFI_ROLES } from '../../constants/roleGroups';
import Navigation from '../../containers/Navigation';
import { createLink } from '../../utils/links';
import { checkForAtLeastOneUserPermission } from '../../utils/rolesUtil';
import Downloads from '../IpgDownloads';

class GlobalDownloads extends Component {
  downloads() {
    const { translations, userPermissions } = this.props;
    const title = translations.title;
    const items = [];

    if (checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions)) {
      items.push(createLink(translations.ipgDownloads, 'ipgDownloads', Downloads));
    }

    return { title, items };
  }

  render() {
    return (
      <Navigation links={this.downloads().items} stackedNav={[this.downloads()]} />
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.global.change,
  translations: state.global.translations.downloads,
  userPermissions: state.global.permissions,
});

GlobalDownloads.propTypes = {
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(GlobalDownloads);
