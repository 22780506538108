import { GetApp, Search } from '@mui/icons-material';
import {
  Grid, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { DISTANCE_FROM_END } from '../../../constants/magic';
import { SonifiLabel, SonifiSpinner, SonifiTableHead, SonifiText } from '../../../containers';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import * as actions from './actions/terminalLoggingAction';
import TerminalRow from './components/TerminalRow';
import { terminalTypes } from './constants';

class TerminalReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allowDownload: true,
      filterType: 'all',
      filter: '',
      page: 1,
      limit: 25,
      order: 'asc',
      orderBy: 'site_id'
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.getTerminalLoggingData(this.getFilterObject(), true));
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.orderBy !== this.state.orderBy) || (prevState.order !== this.state.order)) {
      this.props.dispatch(actions.getTerminalLoggingData(this.getFilterObject(), true));
    } else if (prevState.page !== this.state.page) {
      this.props.dispatch(actions.getTerminalLoggingData(this.getFilterObject()));
    } else if (this.state.filter !== prevState.filter) {
      this.debouncedLoadMoreData();
    }
  }

  getFilterObject() {
    const filterObj = { page: this.state.page, limit: this.state.limit };
    filterObj.sort = `${this.state.orderBy}:${this.state.order}`;
    if (this.state.filter !== '') {
      filterObj.search = encodeURIComponent(this.state.filter);
    }

    if (this.state.filterType !== 'all') {
      filterObj.type = encodeURIComponent(this.state.filterType);
    }
    return filterObj;
  }

  debouncedLoadMoreData = debounce(this.loadFilteredData, KEYBOARD_DELAY);

  handleTextFieldChange = ({ target: { value } }) => {
    this.setState({ filter: value }, () => {
      this.debouncedLoadMoreData();
    });
  };

  filterTheType = ({ target: { value } }) => {
    this.setState({ filterType: value }, () => {
      this.loadFilteredData();
    });
  };

  loadFilteredData() {
    this.setState({ page: 1 }, () => {
      this.props.dispatch(actions.getTerminalLoggingData(this.getFilterObject(), true));
    });
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      page: 1,
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    });
  };

  getPageDetails() {
    const {
      filter, filterType, page, order, orderBy
    } = this.state;
    const { loading, logs, maxPages, translations } = this.props;

    const tableHeader = [
      { id: 'site_id', sortable: logs.length > 1, numeric: false, label: `${translations.siteId}` },
      { id: 'site_name', sortable: logs.length > 1, numeric: false, label: `${translations.siteName}` },
      { id: 'model', sortable: logs.length > 1, numeric: false, label: `${translations.model}` },
      { id: 'version_firmware', sortable: logs.length > 1, numeric: false, label: `${translations.firmware}` },
      { id: 'terminal_type', sortable: logs.length > 1, numeric: false, label: `${translations.type}` },
      { id: 'count', sortable: logs.length > 1, numeric: false, label: `${translations.count}` }
    ];

    return (
      <Grid container style={{ alignContent: 'start', width: '100%' }}>
        <Grid item xs={12}>
          <Grid container justifyContent="end">
            <Grid item xs={3}>
              <SonifiText
                change={this.filterTheType.bind(this)}
                defaultValue={filterType}
                items={terminalTypes}
                label={translations.type}
                select={true}
                size="percent"
              />
            </Grid>
            <Grid item xs={3}>
              <SonifiText
                change={this.handleTextFieldChange.bind(this)}
                defaultValue={filter}
                label={translations.search}
                icon={<Search />}
                iconPosition="end"
                size="percentNoPad"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} >
          <Table stickyHeader={true}>
            <SonifiTableHead
              headColumns={tableHeader}
              order={order}
              orderBy={orderBy}
              onRequestSort={this.handleRequestSort}
            />
            {loading
              ? <TableBody>
                <TableRow>
                  <TableCell colSpan={6}>
                    <SonifiSpinner />
                  </TableCell>
                </TableRow>
              </TableBody>
              : <TableBody>
                {(!logs || logs.length === 0 || logs === null)
                  ? <TableRow>
                    <TableCell colSpan={6}>
                      <SonifiLabel error label={translations.errors.noLogs} />
                    </TableCell>
                  </TableRow>
                  : logs.map((option, index) => (
                    <Fragment key={`w_${index}`}>
                      <TerminalRow key={index} rowIndex={index} />

                      {index === logs.length - DISTANCE_FROM_END && page < maxPages && (
                        <TableRow>
                          <TableCell>
                            <Waypoint onEnter={() => { this.getMoreData(); }} />
                          </TableCell>
                        </TableRow>)
                      }
                    </Fragment>
                  ))}
              </TableBody>}
          </Table>
        </Grid>
      </Grid>
    );
  }

  handleRequestDownload() {
    this.setState({ allowDownload: false });
    this.props.dispatch(actions.getTerminalModelReportExport());

    setTimeout(() => {
      this.setState({ allowDownload: true });
    }, 2000);
  }

  render() {
    const { loading, logs, translations } = this.props;
    return (
      <SonifiTemplate
        disabled={!this.state.allowDownload || loading || logs.length === 0}
        header={translations.title}
        icon={<GetApp />}
        label={translations.downloadTermLogs}
        onSubmit={this.handleRequestDownload.bind(this)}
        pageDetails={this.getPageDetails()}
        showButton
      />
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.terminalLogs.loading,
  maxPages: state.terminalLogs.maxPages,
  logs: state.terminalLogs.logs,
  translations: state.terminalLogs.translations.grid,
  userPermissions: state.global.permissions
});

TerminalReport.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  maxPages: PropTypes.number,
  logs: PropTypes.array,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(TerminalReport);
