import { Add } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { KEYBOARD_DELAY } from '../../constants/keyCodes';
import { GROUP_ROLES } from '../../constants/roleGroups';
import { GROUP_EDIT } from '../../constants/roles';
import SonifiSnackBar from '../../containers/SonifiSnackBar';
import SonifiSpinner from '../../containers/SonifiSpinner';
import SonifiTemplate from '../../containers/SonifiTemplate';
import { checkForAtLeastOneUserPermission, checkSingleUserPermission } from '../../utils/rolesUtil';
import {
  fetchGroups, getSites, resetGroups, updateSelectedGroup, updateSnackBar
} from './actions/groupActions';
import GroupFilter from './components/GroupFilter';
import GroupGrid from './components/GroupGrid';

class GroupManagement extends Component {
  state = {
    filter: '',
    order: 'asc',
    orderBy: 'description'
  };

  componentDidMount() {
    this.props.dispatch(resetGroups());
    this.props.dispatch(fetchGroups(this.props.userGroup,
      `${this.state.orderBy}:${this.state.order}`, this.state.filter, false));
    this.props.dispatch(getSites({ limit: 10000 }, true));
  }

  handleRequestSort = (property) => {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property
    }, () => {
      this.props.dispatch(fetchGroups(this.props.userGroup,
        `${this.state.orderBy}:${this.state.order}`, this.state.filter, true));
    });
  };

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  filterGroup = ({ target: { value } }) => {
    this.setState({ filter: value }, () => {
      this.debouncedLoadMoreData();
    });
  };

  filterData() {
    this.props.dispatch(fetchGroups(this.props.userGroup,
      `${this.state.orderBy}:${this.state.order}`, this.state.filter, true));
  }

  closeSnackBar = () => {
    this.props.dispatch(updateSnackBar(false));
  };

  handleButtonClick = () => {
    this.props.dispatch(updateSelectedGroup({ id: null, description: null, sites: [], isNew: true }, 'edit'));
  };

  getPageDetails() {
    const { allowEditing, userPermissions } = this.props;
    const canEdit = checkSingleUserPermission(GROUP_EDIT, userPermissions);

    return (
      <Grid container style={{ alignContent: 'start', width: '100%' }}>
        <Grid item xs={12}>
          {allowEditing &&
            <GroupFilter filterGroup={this.filterGroup.bind(this)} userState={this.state} canEdit={canEdit} />
          }
        </Grid>
        <Grid item xs={12}>
          <GroupGrid userState={this.state} handleRequestSort={this.handleRequestSort.bind(this)} canEdit={canEdit} />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      loading, snackBar, snackbarOpen, snackBarMessage, translations, userPermissions
    } = this.props;

    if (!checkForAtLeastOneUserPermission(GROUP_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    if (loading) {
      return <SonifiSpinner />;
    }

    const canEdit = checkSingleUserPermission(GROUP_EDIT, userPermissions);

    return (
      <Fragment>
        <SonifiTemplate
          header={translations.title}
          pageDetails={this.getPageDetails()}
          icon={<Add />}
          label={translations.group}
          onSubmit={this.handleButtonClick.bind(this)}
          showButton={canEdit}
        />
        <SonifiSnackBar message={snackBarMessage} variant={snackBar}
          open={snackbarOpen} onClose={this.closeSnackBar} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allowEditing: state.groups.allowEditing,
  loading: state.groups.loading,
  snackBar: state.groups.snackBar,
  snackBarMessage: state.groups.snackBarMessage,
  snackbarOpen: state.groups.snackbarOpen,
  translations: state.groups.translations.grid,
  userPermissions: state.global.permissions,
  userGroup: state.global.group,
});

GroupManagement.propTypes = {
  allowEditing: PropTypes.bool,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  snackBar: PropTypes.string,
  snackBarMessage: PropTypes.string,
  snackbarOpen: PropTypes.bool,
  translations: PropTypes.object,
  userGroup: PropTypes.string,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(GroupManagement);
