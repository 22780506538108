import moment from 'moment';
import { displaySnackBar } from '../../../actions/globalActions';
import { CHANNELS, CHANNEL_SETS, CORE_CHANNELS } from '../../../constants/apiEndpoints';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { httpSuccess } from '../../../constants/http';
import { ETAG_MISMATCH } from '../../../constants/magic';
import { getHasCoreChannelsFromSession, getItemFromSession } from '../../../utils';
import {
  deleteObjectPromise, getSiteCsvObjectPromise, getSiteObjectPromise, getSiteObjectPromiseWithETag, putObjectPromise,
  putObjectPromiseWithExtraHeaders
} from '../../../utils/api';
import {
  getAllLineupsForChannelSet, setLineupLoading, updateAllLineupsOnChannelEdit
} from '../../TvLineup/actions/tvLineupActions';
import { formatAllDisplayName } from '../utils/channelMoveAndSort';
import * as types from './actionTypes';

export const setSnackBar = (snackBarMessage = '', snackBarType) => ({
  type: types.SET_CHANNEL_SNACKBAR,
  snackBarMessage,
  snackBarType
});

export const channelSetCheck = (checkType) => ({
  type: types.CHANNEL_SET_CHECK,
  checkType
});

// Special flag for styling the dragged row
export const setDraggingRow = (rowIndex) => ({
  type: types.SET_DRAGGING_ROW,
  rowIndex
});

export const setDroppedRow = (rowIndex) => ({
  type: types.SET_DROPPED_ROW,
  rowIndex
});

export const moveChannel = (oldIndex, newIndex) => ({
  type: types.MOVE_CHANNEL,
  oldIndex,
  newIndex
});

// Special flag for first time coming to the page
export const setFirstLoad = (loading) => ({
  type: types.SET_FIRST_LOAD,
  loading
});

export const setAvailableChannelSets = (channelSets) => ({
  type: types.SET_AVAILABLE_CHANNEL_SETS,
  channelSets
});

export const setChannelSet = (channelSet) => ({
  type: types.SET_CHANNEL_SET,
  channelSet
});

export const deleteChannel = (deleteIndex) => ({
  type: types.DELETE_CHANNEL,
  deleteIndex
});

// The selected channel tell the ui when to show the pop up, aka when not -1
// and it tells which channel to populate the pop up with.
// index - channels position in the array. NOT CHANNEL NUMBER
export const updateSelectedChannel = (channel) => ({
  type: types.UPDATED_SELECTED_CHANNEL,
  channel
});

// export const setFailureMessage = (message) => ({
//   type: types.SET_FAILURE_MESSAGE,
//   message
// });

// Flag for loading spinner
export const setLoading = (loading) => ({
  type: types.SET_LOADING,
  loading
});

export const fetchChannelsSuccess = (products) => ({
  type: types.FETCH_CHANNELS_SUCCESS,
  channels: products.channels
});

export const fetchChannelsFailure = (error) => ({
  type: types.FETCH_CHANNELS_FAILURE,
  error
});

// This is to set a special flag for the messaged spinner
export const setCreatingDraft = (value) => ({
  type: types.SET_CREATING_DRAFT,
  value
});

// This is called immediately after the page mounts in the react life cycle
// It gets all available channel sets and loads the channels for the apropriate set
// If draft exist it will load channels for draft
// If no draft exist it will load channels for Active
// If no channel sets exist - is call a function to create Active and Draft
export function firstMasterChannelLoad() {
  return (dispatch) => {
    dispatch(setFirstLoad(true));

    // API call to get Channel Sets
    return getSiteObjectPromise(CHANNEL_SETS).then((data) => {
      let active = null,
        draft = null;
      const channelSets = [];

      // If no sets returned create them
      if (data.channel_sets.length === 0) {
        return dispatch(createFirstChannelSet());
      }

      // Create key/value list of available channel sets
      for (let i = 0; i < data.channel_sets.length; i++) {
        if (data.channel_sets[i].id === 'Default') {
          channelSets.push({ label: 'Active', value: data.channel_sets[i] });
          active = data.channel_sets[i];
        } else if (data.channel_sets[i].id === 'Draft') {
          channelSets.push({ label: 'Draft', value: data.channel_sets[i] });
          draft = data.channel_sets[i];
        }
      }

      // Send key/value list to redux store
      dispatch(setAvailableChannelSets(channelSets));

      // If draft exist get channels and lineups for draft else get actives
      if (draft !== null) {
        dispatch(selectChannelSet(draft));
        dispatch(getAllLineupsForChannelSet(draft.id));
      } else if (active !== null) {
        dispatch(selectChannelSet(active));
        dispatch(getAllLineupsForChannelSet(active.id));
      } else {
        dispatch(setFirstLoad(false));
      }
      return;
    }).catch((error) => {
      console.log(error);
      dispatch(fetchChannelsFailure({ error }));
    });
  };
}

// This function is called if the site has no channel sets
// It creates the Active set and the draft set
export function createFirstChannelSet() {
  return (dispatch) => {
    // Create the active channel set first
    // set effective_timestamp to now
    const now = new Date();
    putObjectPromise(CHANNEL_SETS, 'Default', {
      description: 'Default Channel Set',
      effective_timestamp: moment.utc(now).format('YYYY-MM-DDTHH:mm:ssZ')
    }).then((response) => {
      if (!httpSuccess(response.status)) {
        printErrorToConsole(response);
        return dispatch(setSnackBar('Unable to create Active Set', ERROR));
      }

      // If active creation is successful create the draft
      putObjectPromise(CHANNEL_SETS, 'Draft', {
        description: 'Editable draft of master channel list',
        effective_timestamp: null
      }).then((response2) => {
        if (!httpSuccess(response2.status)) {
          printErrorToConsole(response2);
          return dispatch(setSnackBar('Unable to create Draft Set', ERROR));
        }

        // Go get the channel sets
        dispatch(getChannelSets());
      }).catch((error) => console.log(error));
    }).catch((error) => console.log(error));
  };
}

// This function is for changing between channel sets
export function selectChannelSet(channelSet) {
  return (dispatch) => {
    // Save the set to the redux store
    dispatch(setChannelSet(channelSet));

    // Get the channels for that set
    return getSiteObjectPromiseWithETag(CHANNEL_SETS, `${encodeURIComponent(channelSet.id)}/channels`)
      .then((channelData) => dispatch(fetchChannelsSuccess(channelData))).catch((error) => {
        console.log(error);
        dispatch(fetchChannelsFailure({ error }));
      });
  };
}

// Get channels sets and call to load channels for a channel set
// If draft exists get draft channel, else get the active
export function getChannelSets(getActiveOnly = false) {
  return (dispatch) => getSiteObjectPromise(CHANNEL_SETS)
    .then((data) => {
      let active = null,
        draft = null;
      const channelSets = [];

      // Create the key/value list
      for (let i = 0; i < data.channel_sets.length; i++) {
        if (data.channel_sets[i].id === 'Default') {
          channelSets.push({ label: 'Active', value: data.channel_sets[i] });
          active = data.channel_sets[i];
        } else if (data.channel_sets[i].id === 'Draft') {
          channelSets.push({ label: 'Draft', value: data.channel_sets[i] });
          draft = data.channel_sets[i];
        }
      }

      // Set the key/value list in the redux store
      dispatch(setAvailableChannelSets(channelSets));

      // Get draft channels if draft exists, else get active
      if (draft !== null && !getActiveOnly) {
        dispatch(selectChannelSet(draft));
      } else if (active !== null) {
        dispatch(selectChannelSet(active));
      }

      // Unset loading flags
      return dispatch(setCreatingDraft(false));
    }).catch((error) => {
      console.log(error);
      return dispatch(setSnackBar('Get channel set error', ERROR));
    });
}

export const saveChannelListBegin = () => ({
  type: types.SAVE_CHANNEL_LIST_BEGIN
});

export const saveChannelListSuccess = (saveInfo) => ({
  type: types.SAVE_CHANNEL_LIST_SUCCESS,
  saveInfo
});

export const saveChannelListFailure = (error) => ({
  type: types.SAVE_CHANNEL_LIST_FAILURE,
  error
});

// This function will save channel list to a set
// currentChannelSet - the channel set the user has loaded
// savingChannelSetId - Id of the channel set you want to save to
// description - description of the saving channel set
// availableChannelSets - key/value list of channel sets that exist for the site
// channelLineups - the lineups for current channel set

// eslint-disable-next-line max-params
export function saveMasterChannelList(currentChannelSet, savingChannelSetId, description, channelListData,
  availableChannelSets, channelLineups, saveInfo) {
  return (dispatch) => {
    // Start loading spinner and clear success/failure flags
    dispatch(saveChannelListBegin());
    channelListData = formatAllDisplayName(channelListData);

    // Check to see if the set you want to save to exists
    const savingChannelSet = availableChannelSets.find((set) => set.value.id === savingChannelSetId);

    if (savingChannelSet === undefined) {
      // No channel set so create a channel set
      dispatch(setCreatingDraft(true));
      return dispatch(createNewChannelSet(savingChannelSetId, description, channelListData, channelLineups));
    } else if (currentChannelSet.id !== savingChannelSetId) {
      dispatch(channelSetCheck('overwrite'));

      // Channel Set exist, but is different from the current channel set
      //   return dispatch(saveToOtherChannelSet(savingChannelSetId, channelListData, channelLineups));
      return;
    }


    // Channel Set exists and is the same as the current channel set
    return dispatch(updateCurrentChannelSet(savingChannelSetId, channelListData, channelLineups, saveInfo));
  };
}

export function updateCurrentChannelSet(channelSetId, channelList, lineups, saveInfo) {
  return (dispatch) => putObjectPromiseWithExtraHeaders(CHANNEL_SETS, `${encodeURIComponent(channelSetId)}/channels`,
    { channels: channelList }, null, { ETag: getItemFromSession('etag') })
    .then((saveResponse) => {
      if (!httpSuccess(saveResponse.status)) {
        // Check for an error with the ETAG
        if (saveResponse.status === ETAG_MISMATCH) {
          printErrorToConsole(saveResponse);
          return dispatch(saveChannelListFailure('Changes have been made by another user. Please reload page.'));
        }
        printErrorToConsole(saveResponse);
        return dispatch(saveChannelListFailure('Unable to save channel set'));
      }
      dispatch(setLineupLoading(true));
      dispatch(getChannelSets())
        .then(() => {
          dispatch(saveChannelListSuccess(saveInfo));
        });
      dispatch(updateAllLineupsOnChannelEdit(lineups, channelList, channelSetId));
      return true;
    })
    .catch((reason) => {
      dispatch(saveChannelListFailure('Unable to save channel set'));
      return reason;
    });
}

// Doesn't do anything.  Nothing in the reducer for this one...
export const fetchChannelsExportBegin = () => ({
  type: types.FETCH_CHANNELS_EXPORT_BEGIN
});

export function getChannelsExport(channelSetType) {
  return (dispatch) => {
    dispatch(fetchChannelsExportBegin());
    const fileUrl = `${CHANNEL_SETS}/${channelSetType}/${CHANNELS}`;
    return getSiteCsvObjectPromise(fileUrl)
      .then((json) => json)
      .catch((error) => {
        console.log('CHRISTINA error is: ', error);

        // dispatch(fetchRoomTerminalsFailure(error));
      });
  };
}

export const deleteDraftBegin = (id) => ({
  type: types.DELETE_DRAFT_BEGIN,
  id
});

export const deleteDraftSuccess = (id) => ({
  type: types.DELETE_DRAFT_SUCCESS,
  id
});
export const deleteDraftFailure = (error) => ({
  type: types.DELETE_DRAFT_FAILURE,
  error
});

export function deleteDraft() {
  return (dispatch) => {
    dispatch(deleteDraftBegin('Draft'));

    return deleteObjectPromise(CHANNEL_SETS, 'Draft')
      .then((json) => {
        console.log('deleteDraftSuccess: successCallback');
        console.log(json);

        // dispatch(setSuccessMessage('Draft successfully deleted.'));
        dispatch(firstMasterChannelLoad())
          .then(() => {
            dispatch(deleteDraftSuccess('Draft'));
          });
        return json;
      }).catch((error) => {
        console.log('deleteDraftFailure: failureCallback');
        console.log(error);
        dispatch(deleteDraftFailure(error));
        return error;
      });
  };
}

// DANIEL REVISIT THIS!!!!!!!!!!!!!!
// This function takes any list of channels and save it to draft
// It will also take the lineups from the active channel set and save them to draft
export function cloneListToDraft(channelList, availableChannelSets) {
  return (dispatch) => {
    // Set loading spinner
    dispatch(setLoading(true));
    channelList = formatAllDisplayName(channelList);

    // Check for a draft
    const draft = availableChannelSets.find((set) => set.value.id === 'Draft');

    // Get the lineups from the active lineup
    return getSiteObjectPromise(CHANNEL_SETS, 'Default/lineups')
      .then((lineups) => {
        const lineupCalls = [];

        // TODO: Refactor this so the TvLineup class does this!
        for (let i = 0; i < lineups.lineups.length; i++) {
          lineupCalls.push(getSiteObjectPromise(CHANNEL_SETS,
            `Default/lineups/${encodeURIComponent(lineups.lineups[i].id)}`));
          lineupCalls.push(getSiteObjectPromise(CHANNEL_SETS,
            `Default/lineups/${encodeURIComponent(lineups.lineups[i].id)}/channels`));
        }

        Promise.all(lineupCalls).then((data) => {
          const lineupsDetailed = [];

          // This works because Promise.all resolves all the promises into an array
          // that is in the same order as the array you gave it.
          for (let i = 0; i < data.length; i += 2) {
            lineupsDetailed.push({ ...data[i], ...data[i + 1] });
          }

          // No channel set so create a channel set
          if (draft === undefined) {
            return dispatch(createNewChannelSet('Draft', 'Draft of upcoming channel list',
              channelList, lineupsDetailed));
          }
          return dispatch(updateCurrentChannelSet('Draft', channelList, lineupsDetailed));
        });
      })
      .catch((error) => console.log(error));
  };
}

// channelSetId - id for the new set
// description - description for the new set
// channelList - list of channels for the new set
// channelLineups - list of lineups for the new channel set
export function createNewChannelSet(channelSetId, description, channelList, channelLineups) {
  return (dispatch) => {
    // Get date time now for effectiv date
    const now = new Date();

    // Create the channel set
    return putObjectPromise(CHANNEL_SETS, channelSetId, {
      description,
      effective_timestamp: channelSetId === 'Draft' ? null : moment.utc(now).format('YYYY-MM-DDTHH:mm:ssZ')
    })
      .then((response) => {
        // Check for returned error
        if (!httpSuccess(response.status)) {
          printErrorToConsole(response);
          dispatch(setSnackBar('Unable to create channel set', ERROR));
          dispatch(setCreatingDraft(false));
          return dispatch(setLoading(false));
        }

        // Pull channels to get the Etag
        getSiteObjectPromiseWithETag(CHANNEL_SETS, `${encodeURIComponent(channelSetId)}/channels`)
          .then(() => {
            // Save the list
            putObjectPromiseWithExtraHeaders(CHANNEL_SETS, `${encodeURIComponent(channelSetId)}/channels`,
              { channels: channelList }, null,
              {
                ETag: getItemFromSession('etag')
              })
              .then((saveResponse) => {
                // Check for failure
                if (!httpSuccess(saveResponse.status)) {
                  printErrorToConsole(saveResponse);
                  dispatch(setSnackBar('Unable to save channel set', ERROR));
                  dispatch(setCreatingDraft(false));
                  return dispatch(setLoading(false));
                }

                dispatch(setLineupLoading(true));

                // Set success message
                dispatch(setSnackBar('Changes saved. To make changes active, click PUBLISH', SUCCESS));

                // Re get the channel sets
                dispatch(getChannelSets());

                // Call to alter lineups to match new channels
                dispatch(updateAllLineupsOnChannelEdit(channelLineups, channelList, channelSetId));
              })
              .catch((reason) => {
                dispatch(setSnackBar('Unable to save channel set', ERROR));
                dispatch(setCreatingDraft(false));
                dispatch(setLoading(false));
                return reason;
              });
          })
          .catch(() => {
            dispatch(setSnackBar('Unable to save changes', ERROR));
            dispatch(setCreatingDraft(false));
            dispatch(setLoading(false));
          });
      })
      .catch(() => {
        dispatch(setSnackBar('Unable to create channel set', ERROR));
        dispatch(setCreatingDraft(false));
        dispatch(setLoading(false));
      });
  };
}


export function saveCheck(channelList, saveInfo) {
  return (dispatch, getState) => {
    dispatch(setLoading(true));


    // Look to see if there is a draft
    const channelsState = getState().channels;

    // const { availableChannelSets } = this.props;
    const draftIndex = channelsState.availableChannelSets.findIndex((set) => set.value.id === 'Draft');

    // Check to see you are not on draft and a draft exists
    if (channelsState.channelSet.id !== 'Draft' && draftIndex !== -1) {

      //   this.setState({
      //     showConfirmDialog: true,
      //     confirmDialogTitle: `${this.props.translations.overwrite} Draft`,
      //     confirmDialogMessage: `${this.props.translations.overwriteDraft}`,
      //     confirmDialogCancelFunc: this.universalCancel.bind(this),
      //     confirmDialogConfirmFunc: this.saveConfirm.bind(this),
      //     channelListToSave: channelList
      //   });
    } else {
      return dispatch(saveMasterChannelList(
        channelsState.channelSet,
        'Draft',
        'Draft of upcoming channel list',
        channelList,
        channelsState.availableChannelSets,
        getState().tvLineup.channelLineups,
        saveInfo
      ));

      // this.saveChannelList(channelList, saveInfo);
    }
  };
}

export const editChannelNum = (index) => ({
  type: types.EDIT_CHANNEL_NUMBER,
  index
});

// Not used as heavily anymore, because most edits happen in pop up now
// Still used on locked status and channel no.
// parentName - if the data you are accessing is in a nested obj, put the object name here, else put null
// rowIndex - which row is being edited
// value - actual value
// valueName - name of the variable within the obj
export const updateChannelDetails = (rowIndex, parentName, valueName, value) => ({
  type: types.UPDATE_CHANNEL_DETAILS,
  parentName,
  rowIndex,
  value,
  valueName
});

// Called when a channel number is manually changed, not after drag.
export const resortChannels = (changedIndex) => ({
  type: types.RESORT_CHANNELS,
  changedIndex
});

export const publishDraftToActiveBegin = () => ({
  type: types.PUBLISH_DRAFT_BEGIN
});

export const publishDraftToActiveSuccess = () => ({
  type: types.PUBLISH_DRAFT_SUCCESS
});

export const publishDraftToActiveFailure = (error) => ({
  type: types.PUBLISH_DRAFT_FAILURE,
  error
});

// This is the function to use to make changes to active
export function publishDraftToActive(channelListData, activeChannelSet, channelLineups) {
  return (dispatch) => {
    dispatch(publishDraftToActiveBegin());


    // Get the channels from the active set to get ETAG
    return getSiteObjectPromiseWithETag(CHANNEL_SETS, `${encodeURIComponent(activeChannelSet.id)}/channels`)
      .then(() => putObjectPromiseWithExtraHeaders(CHANNEL_SETS, `${encodeURIComponent(activeChannelSet.id)}/channels`,
        { channels: channelListData }, null, { ETag: getItemFromSession('etag') })
        .then((response) => {
          if (!httpSuccess(response.status)) {
            printErrorToConsole(response);
            dispatch(publishDraftToActiveFailure('Unable to publish Draft to Active'));

            // dispatch(setFailureMessage('Unable to publish Draft to Active'));
            // dispatch(setLoading(false));
          }

          // Saved to Active, now delete the draft
          return deleteObjectPromise(CHANNEL_SETS, 'Draft')
            .then((deleteResponse) => {
              if (!httpSuccess(deleteResponse.status)) {
                printErrorToConsole(response);
                dispatch(publishDraftToActiveFailure('Unable to delete Draft'));

                // dispatch(setFailureMessage('Unable to delete Draft'));
                // dispatch(setLoading(false));
              }

              const now = new Date();

              // Update the Active channelset to have an effective_timestamp of now
              return putObjectPromise(CHANNEL_SETS, encodeURIComponent(activeChannelSet.id), {
                description: activeChannelSet.description,
                effective_timestamp: moment.utc(now).format('YYYY-MM-DDTHH:mm:ssZ')
              })
                .then((response2) => {
                  if (!httpSuccess(response2.status)) {
                    printErrorToConsole(response2);
                    dispatch(publishDraftToActiveFailure('Unable to publish Draft to Active'));

                    // dispatch(setFailureMessage('Unable to publish Draft to Active'));
                    // dispatch(setLoading(false));
                  }

                  // Set flags, re get the channel set, update lineups
                  dispatch(setLineupLoading(true));

                  // dispatch(setSuccessMessage('Draft published to active.'));
                  dispatch(getChannelSets());
                  dispatch(updateAllLineupsOnChannelEdit(channelLineups, channelListData, activeChannelSet.id));
                  return true;
                }).catch(() => {
                  // dispatch(setFailureMessage('Unable to publish Draft to Active'));
                  // dispatch(setLoading(false));
                  dispatch(publishDraftToActiveFailure('Unable to publish Draft to Active'));
                  return false;
                });
            }).catch((reason) => {
              // dispatch(setFailureMessage('Unable to save channel set'));
              // dispatch(setLoading(false));
              dispatch(publishDraftToActiveFailure('Unable to save channel set'));
              return reason;
            });
        })
        .catch(() => {
          dispatch(publishDraftToActiveFailure('Unable to publish Draft to Active'));

          // dispatch(setFailureMessage('Unable to publish Draft to Active'));
          // dispatch(setLoading(false));
          return false;
        })).catch(() => {
          dispatch(publishDraftToActiveFailure('Unable to publish Draft to Active'));
          return false;

          // dispatch(setFailureMessage('Unable to publish Draft to Active'));
          // dispatch(setLoading(false));
        });
  };
}


// Formatter for printing errors to the console to help with messages
// returned from api.
function printErrorToConsole(errorObj) {
  errorObj.json().then((errorObjDetails) => {
    console.log('Channel Error');
    console.log(`Title: ${errorObjDetails.title}`);
    console.log(`Details: ${errorObjDetails.detail}`);
    console.log(`Request: ${errorObjDetails.request}`);
  });
}

// Created GET 'site/{site_id}/core-channels' route
export const getCoreChannelsBegin = () => ({
  type: types.FETCH_CORE_CHANNELS_BEGIN
});

export const getCoreChannelsSuccess = (channels) => ({
  type: types.FETCH_CORE_CHANNELS_SUCCESS,
  channels
});

// GET 'site/{site_id}/core-channels' route
export function getCoreChannels() {
  return (dispatch) => {
    dispatch(getCoreChannelsBegin());

    if (getHasCoreChannelsFromSession() !== 'true') {
      return new Promise((resolve) => {
        resolve();
        return;
      });
    }

    return getSiteObjectPromise(CORE_CHANNELS)
      .then((json) => {
        dispatch(getCoreChannelsSuccess(json.core_channels));
        return json;
      })
      .catch((error) => {
        try {
          error.json().then((err) => {
            dispatch(displaySnackBar(ERROR, `Error getting core channels - ${err.detail} (${err.status})`));
            return null;
          });
        } catch(err) {
          dispatch(displaySnackBar(ERROR, `Error getting core channels - ${err.detail} (${err.status})`));
          return null;
        }
      });
  };
}
