import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import {
  AppBar, Divider, Drawer, IconButton, List, ListItem, ListItemText,
  Toolbar, Typography
} from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { withStyles } from 'tss-react/mui';

const drawerWidth = 300;

const styles = (theme) => ({
  root: {
    backgroundColor: '#fafafa',
    display: 'flex',
    height: '100%',
    overflow: 'hidden'
  },
  appBar: {
    width: '49px',
    height: '48px',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: theme.palette.defaults.defaultBackground,
    color: theme.palette.primary.main
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: '0',
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    overflow: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 178px)',
    width: drawerWidth,
    top: 'auto',
    backgroundColor: theme.palette.defaults.defaultBackground,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
  },
  drawerClose: {
    backgroundColor: theme.palette.defaults.defaultBackground,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    top: 'auto',
    width: '50px',
    [theme.breakpoints.up('sm')]: {
      width: '50px',
    },
  },
  content: {
    display: 'flex',
    height: 'calc(100vh - 178px)',
    overflow: 'hidden',
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  subheader: {
    color: theme.palette.primary.hoverColor,
    fontSize: '12pt',
    fontWeight: 600
  },
  ul: {
    padding: 0,
    width: '100%',
    marginTop: '10px'
  },
  liText: {
    padding: '0px',
    marginLeft: '20px'
  },
  selectedStyles: {
    'borderBottom': `3px solid ${theme.palette.secondary.main}`,
    'backgroundColor': `${theme.palette.primary.contrastText} !important`,
    '& MuiTypography': {
      color: theme.palette.primary.hoverColor
    }
  },
  regularStyles: {
    'color': theme.palette.primary.main,
    'borderBottom': '3px solid transparent',
    '&:hover': {
      color: theme.palette.primary.contrastText,
      fontWeight: '600'
    }
  },
  selectedFont: {
    color: theme.palette.primary.hoverColor
  }
});

const navigationTabs = [
  '/global/manage$',
  '/global/configure$',
  '/global/reports$',
  '/global/admin$',
  '/global/downloads$',
  '/site/[0-9]{7}/system$',
  '/site/[0-9]{7}/product$',
  '/site/[0-9]{7}/reporting$',
];


export class Navigation extends Component {
  state = {
    open: true,
    selected: ''
  };

  componentDidMount() {
    const { links } = this.props;
    if (links.length > 0) {
      this.setState({
        selected: links[0].value,
      });
    }
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: !this.state.open });
  };

  updateSelected(selectedIndex) {
    this.setState({
      selected: selectedIndex,
    });
  }

  render() {
    const {
      classes, hideNav, links, stackedNav,
    } = this.props;

    if (!links || links.length === 0) {
      return <Navigate to="/site" />;
    }
    if (navigationTabs.some((str) => window.location.pathname.match(str))) {
      const firstLinkRoute = `${window.location.pathname}/${links[0].value}`;
      return <Navigate to={firstLinkRoute} />;
    }

    const newSelectedArr = window.location.pathname.split('/');
    const newSelected = newSelectedArr[newSelectedArr.length - 1];

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar disableGutters={!this.state.open}>
            <IconButton onClick={this.handleDrawerOpen} className={classNames(classes.menuButton, {
              [classes.hide]: this.state.open,
            })}>
              {!this.state.open ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </Toolbar>
        </AppBar>
        {!hideNav && <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div>
            <IconButton onClick={this.handleDrawerClose}>
              {!this.state.open ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </div>
          <Divider />
          <List className={classNames({
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
            [classes.hide]: !this.state.open,
          })} subheader={<li />}>
            <ul className={classes.ul}>
              {stackedNav.map((sectionId) => (
                <li key={`section-${sectionId.title}`}>
                  <ul className={classes.ul} >
                    <ListItem className={classes.subheader} data-testid={`linkSubHeader${sectionId.title}`}>
                      <Typography className={classes.subheader}>
                        {sectionId.title}
                      </Typography>
                    </ListItem>
                    {sectionId.items.map((list) => (
                      <ListItem button key={list.key} value={list.value}
                        component={Link}
                        to={list.value}
                        onClick={() => this.updateSelected(list.value)}
                        selected={newSelected === list.value}
                        className={`${newSelected === list.value ? classes.selectedStyles : classes.regularStyles}`}
                      >
                        <ListItemText
                          primary={
                            <Typography
                              className={newSelected === list.value ? classes.selectedFont : ''}>
                              {list.key}
                            </Typography>}
                          className={classes.liText} />
                      </ListItem>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </List>
        </Drawer>}
        <main className={classes.content}>
          <Routes>
            {links.map((list) => (<Route key={list.key} path={list.value} element={<list.component />} />))}
          </Routes>
        </main>
      </div >
    );
  }
}

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  hideNav: PropTypes.bool,
  links: PropTypes.array,
  stackedNav: PropTypes.array,
};

export default withStyles(Navigation, styles, { withTheme: true });
