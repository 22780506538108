import { compact, intersection } from 'lodash';
import moment from 'moment';
import { textHelper } from '../../../utils/textUtil';
import { getMaxDepartureTime } from './index';

export function isValidGroup(
  group,
  hasGroupCodeSupport,
  translations,
  globalTranslations
) {
  let errors = {
    ...isValidGroupName(group.name, translations, globalTranslations),
    ...isValidGroupTimespan(group.arrival, group.departure, translations),
    ...isValidGroupChannels(group.channels),
  };

  if (hasGroupCodeSupport) {
    errors = {
      ...errors,
      ...isValidGroupCodes(group.codes, translations)
    };
  }

  return Promise.resolve(errors);
}

/**
 * Check whether or not the group's arrival and departure are valid.
 *
 * @param {string} groupArrival - The group's arrival time.
 * @param {string} groupDeparture - The group's departure time.
 * @param {object} translations - An object with translation error message strings.
 *
 * @typedef {Object} GroupTimespanErrors
 * @property {string} [arrival]
 * @property {string} [departure]
 *
 * @returns {GroupTimespanErrors} - An error object with `arrival` and/or
 *     `departure` properties set if there are any errors.
 */
export function isValidGroupTimespan(
  groupArrival,
  groupDeparture,
  translations
) {
  const errors = {};
  const arrival = moment(groupArrival);
  const departure = moment(groupDeparture);
  const maxDepartureTime = getMaxDepartureTime(arrival);

  if (groupArrival === null || typeof groupArrival === 'undefined') {
    errors.arrival = translations.noArrivalTimeSet;
  }

  if (groupDeparture === null || typeof groupDeparture === 'undefined') {
    errors.departure = translations.noDepartureTimeSet;
  }

  if (
    typeof errors.arrival === 'undefined' &&
    typeof errors.departure === 'undefined'
  ) {
    if (departure < arrival) {
      errors.departure = translations.departureBeforeArrival;
    } else if (departure > maxDepartureTime) {
      errors.departure = translations.departurePastMax;
    }
  }

  return errors;
}

/**
 * Check whether or not the group's name is valid.
 *
 * @param {string} name - The name of the group.
 * @param {object} translations - An object of error strings.
 * @param {object} globalTranslations - An object of error strings
 *     with a `symbols` attribute.
 *
 * @typedef {object} GroupNameErrors
 * @property {string} [name]
 * @property {string} [id]
 *
 * @returns {GroupNameErrors} - An object with error strings if there
 *     are validation issues.
 */
export function isValidGroupName(name, translations, globalTranslations) {
  const errors = {};

  if (name.trim().length === 0) {
    errors.name = translations.needGroupName;
  } else if (name.length > 50) {
    errors.name = translations.maxGroupNameLength;
  } else if (textHelper(name)) {
    // TODO: What is this for?
    errors.id = globalTranslations.symbols;
  }

  return errors;
}

/**
 * Check whether or not the group's arrival and departure are valid.
 *
 * @param {object} channels - A list of add and remove channels.
 * @param {string[]} channels.add - IPG source ids for channels to add.
 * @param {string[]} channels.remove - IPG source ids for channels to remove.
 *
 * @typedef {object} GroupChannelErrors
 * @property {string} [channels] - An error string if there are validation issues.
 *
 * @returns {GroupChannelErrors} - An error object with `channels` on it.
 */
export function isValidGroupChannels(channels) {
  const errors = {};
  const addChannels = channels.add;
  const removeChannels = channels.remove;

  if (addChannels.length > 0 && removeChannels.length > 0) {
    const addAndRemove = intersection(addChannels, removeChannels);

    if (addAndRemove.length > 0) {
      errors.channels = addAndRemove;
    }
  }

  return errors;
}

/**
 * Check whether the submitted group codes are valid.
 *
 * @param {string[]} submittedCodes - A list of codes the user is submitting.
 * @param {object} translations - An object of error strings.
 *
 * @typedef {object} GroupCodeErrors
 * @property {string} [codes] - A group code wasn't selected.
 *
 * @returns {GroupCodeErrors} - An error string object.
 */
export function isValidGroupCodes(submittedCodes, translations) {
  const errors = {};
  if (compact(submittedCodes).length < 1) {
    errors.codes = translations.needToSelectACode;
  }
  return errors;
}
