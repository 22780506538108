import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Add, Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DragNDropIcon from '../../../../containers/DragNDrop/DragNDropIcon';
import SonifiConfirm from '../../../../containers/SonifiConfirm';
import SonifiIconButton from '../../../../containers/SonifiIconButton';
import SonifiLabel from '../../../../containers/SonifiLabel';
import SonifiTextInline from '../../../../containers/SonifiTextInline';
import { removePipe } from '../../../../utils/textUtil';
import { updateChangeVariable, updateDeleteRow } from '../../actions/siteManagementActions';
import { getTerminalStyle, getTermLocItemStyle, reorder } from '../../utils';
import Floors from './Floors';

class Locations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      buildings: []
    };

    this.onDragEnd = this.onDragEnd.bind(this);
    this.confirmDialogCancelFunc = this.confirmDialogCancelFunc.bind(this);
  }

  componentDidMount() {
    const { site, siteHierarchy } = this.props;
    if (site && site.length > 0) {
      const locationCopy = [...site];
      for (let i = 0; i < locationCopy.length; i++) {
        locationCopy[i].open = (locationCopy.length < 2);
        locationCopy[i].index = i;
        locationCopy[i].canEdit = (siteHierarchy[i] && siteHierarchy[i].canEdit ? siteHierarchy[i].canEdit : false);
        if (locationCopy[i].floors && locationCopy[i].floors.length > 0) {
          for (let j = 0; j < locationCopy[i].floors.length; j++) {
            locationCopy[i].floors[j].open = false;
            locationCopy[i].floors[j].canEdit = (
              (siteHierarchy[i] && siteHierarchy[i].treeData &&
                siteHierarchy[i].treeData[j] && siteHierarchy[i].treeData[j].canEdit)
                ? siteHierarchy[i].treeData[j].canEdit
                : false);
            if (locationCopy[i].floors[j].units && locationCopy[i].floors[j].units.length > 0) {
              // eslint-disable-next-line max-depth
              for (let k = 0; k < locationCopy[i].floors[j].units.length; k++) {
                locationCopy[i].floors[j].units[k].open = false;
                locationCopy[i].floors[j].units[k].canEdit = siteHierarchy[i].treeData[j].treeData[k].canEdit;
              }
            }
          }
        }
      }

      this.props.onUpdate(locationCopy);
      this.setState({
        buildings: locationCopy
      }, () => {
        this.props.dispatch(updateChangeVariable(true));
      });
    }
  }

  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      // console.log("no-change");
      return;
    }

    let buildings = null;
    if (result.type === 'BUILDING') {
      buildings = reorder(
        this.state.buildings,
        result.source.index,
        result.destination.index
      );
    } else {
      const floors = reorder(
        this.state.buildings[parseInt(result.type, 10)].floors,
        result.source.index,
        result.destination.index
      );

      buildings = JSON.parse(JSON.stringify(this.state.buildings));
      buildings[result.type].floors = floors;
    }

    this.setState({
      buildings
    }, () => {
      // this.props.onUpdate('locations', this.state.buildings);
      this.props.onUpdate(this.state.buildings);
    });
  }

  showHide(ind) {
    const currentVisible = this.state.buildings;
    currentVisible[ind].open = !currentVisible[ind].open;
    this.setState({
      buildings: currentVisible
    }, () => {
      this.props.onUpdate(this.state.buildings, false);
    });
  }

  addBuilding = () => () => {
    const currentVisible = this.state.buildings;

    currentVisible.push({
      index: currentVisible.length,
      name: `Building ${currentVisible.length + 1}`,
      description: null,
      open: true,
      floors: [],
      canEdit: true,
      type: 'building',
      isNew: true,
      error: false
    });

    this.setState({
      buildings: currentVisible
    }, () => {
      this.addFloor(currentVisible.length - 1);
    });
  };

  addFloor(ind) {
    const currentVisible = this.state.buildings;

    if (!currentVisible[ind].floors) {
      currentVisible[ind].floors = [];
    }

    currentVisible[ind].open = true;

    const newObj = {
      name: `Floor ${currentVisible[ind].floors.length + 1}`,
      description: null,
      open: false,
      canEdit: true,
      type: 'floor',
      isNew: true,
      error: false
    };

    if (!currentVisible[ind].floors ||
      currentVisible[ind].floors.length < 1) {
      currentVisible[ind].floors = [newObj];
    } else if (currentVisible[ind].floors[currentVisible[ind].floors.length - 1].name !== 'Floor') {
      currentVisible[ind].floors.push(newObj);
    }

    this.setState({
      buildings: currentVisible
    }, () => {
      this.props.onUpdate(this.state.buildings);
      this.props.dispatch(updateChangeVariable(false));
    });
  }

  deleteBuilding(ind) {
    const currentVisible = [...this.state.buildings];
    if (currentVisible[ind].length <= 1) {
      return;
    }

    currentVisible.splice(ind, 1);
    this.setState({
      buildings: currentVisible
    }, () => {
      this.props.onUpdate(this.state.buildings);
      this.props.dispatch(updateChangeVariable(false));
    });
  }

  handleDeleteDialog(buildingIndex, floorIndex, itemName) {
    const { dispatch } = this.props;
    dispatch(updateDeleteRow('hierarchy', buildingIndex, floorIndex, itemName));
  }

  confirmDialogConfirmFunc() {
    const { dispatch, floorIndex, buildingIndex } = this.props;
    if (floorIndex === null) {
      this.deleteBuilding(buildingIndex);
      dispatch(updateDeleteRow('hierarchy', null, null));
    } else {
      this.deleteFloor(buildingIndex, floorIndex);
      dispatch(updateDeleteRow('hierarchy', null, null));
    }
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateDeleteRow('hierarchy', null, null));
  }

  deleteFloor(b, f) {
    const currentVisible = [...this.state.buildings];

    if (currentVisible[b].floors.length <= 1) {
      return;
    }

    currentVisible[b].floors.splice(f, 1);
    this.setState({
      buildings: currentVisible
    }, () => {
      this.props.onUpdate(this.state.buildings);
      this.props.dispatch(updateChangeVariable(false));
    });
  }

  editName(buildingIndx, floorIndex) {
    const currentVisible = this.state.buildings;
    if (floorIndex !== -1 && !currentVisible[buildingIndx].floors[floorIndex].canEdit) {
      return;
    } else if (floorIndex === -1 && !currentVisible[buildingIndx].canEdit) {
      return;
    }

    for (let i = 0; i < currentVisible.length; i++) {
      currentVisible[i].edit = false;
      if (currentVisible[i].floors && currentVisible[i].floors.length > 0) {
        for (let j = 0; j < currentVisible[i].floors.length; j++) {
          currentVisible[i].floors[j].edit = false;
          if (currentVisible[i].floors[j].units && currentVisible[i].floors[j].units.length > 0) {
            for (let k = 0; k < currentVisible[i].floors[j].units.length; k++) {
              currentVisible[i].floors[j].units[k].edit = false;
            }
          }
        }
      }
    }

    if (floorIndex !== -1) {
      currentVisible[buildingIndx].floors[floorIndex].edit = currentVisible[buildingIndx].floors[floorIndex].canEdit;
    } else {
      currentVisible[buildingIndx].edit = currentVisible[buildingIndx].canEdit;
    }

    this.setState({
      buildings: currentVisible
    }, () => {
      this.props.onUpdate(this.state.buildings, false);
    });
  }

  handleChange = (buildingIndx, floorIndex) => ({ target: { value } }) => {
    if (!value || value === '' || value.length < 1) {
      this.setState({ error: 'Cannot be blank' });
      return;
    }
    this.setState({ error: '' });
    this.change(buildingIndx, floorIndex, value);
  };

  change(buildingIndx, floorIndex, value) {
    const currentVisible = this.state.buildings;
    if (floorIndex !== -1) {
      if (currentVisible[buildingIndx].floors[floorIndex].name === value) {
        return;
      }
    } else if (currentVisible[buildingIndx].name === value) {
      return;
    }

    if (floorIndex !== -1) {
      currentVisible[buildingIndx].floors[floorIndex].name = removePipe(value);
    } else {
      currentVisible[buildingIndx].name = removePipe(value);
    }

    this.setState({
      buildings: currentVisible
    }, () => {
      this.props.onUpdate(this.state.buildings);
      this.props.dispatch(updateChangeVariable(false));
    });
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  /* eslint-disable max-lines-per-function */
  render() {
    const { buildings } = this.state;
    const {
      allowEdit, allowReorder, buildingIndex, deleteType, floorIndex, globalTranslations, itemName, translations
    } = this.props;

    return (
      <Fragment>
        <Grid container justifyContent="space-between">
          <Grid item style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <SonifiLabel boldLabel={translations.title} />
          </Grid>
          <Grid item>
            {allowEdit && <SonifiIconButton label={translations.building}
              onClick={this.addBuilding()}
              icon={<Add />} />}
          </Grid>
        </Grid>
        <DragDropContext onDragEnd={this.onDragEnd} >
          <Droppable droppableId="droppableBuilding" type="BUILDING">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getTerminalStyle(snapshot.isDraggingOver)}
              >
                {buildings.map((location, index) => (
                  <Draggable
                    key={`buildings${index}`}
                    draggableId={`buildings${index}`}
                    isDragDisabled={!allowReorder || buildings.length < 2}
                    index={index}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <div
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                        // {...draggableProvided.dragHandleProps}
                        style={getTermLocItemStyle(
                          draggableSnapshot.isDragging,
                          draggableProvided.draggableProps.style,
                          index
                        )} >
                        <Grid container wrap="nowrap" style={{ padding: '3px' }}>
                          <Grid item xs={1}
                            {...draggableProvided.dragHandleProps}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                            {allowReorder && buildings.length > 1 &&
                              <DragNDropIcon style={{
                                cursor: 'move',
                                filter: `${draggableSnapshot.isDragging
                                  ? 'brightness(0) invert(1)'
                                  : 'brightness(1) invert(0)'}`,
                                verticalAlign: 'middle',
                                width: '20px'
                              }} />}
                          </Grid>
                          <Grid item xs={5} onClick={this.editName.bind(this, index, -1)}>
                            {allowEdit && location.edit
                              ? <SonifiTextInline
                                defaultValue={`${location.name}`}
                                change={this.handleChange(index, -1)}
                                error={(location.hError && location.hError.length > 0)}
                                errorText={location.hError ? `${location.hError}` : ''}
                              />
                              : <SonifiLabel label={location.name} />}
                          </Grid>
                          <Grid item xs={6} /* onClick={this.resetTerm.bind(this)}*/>
                            <Grid container style={{ justifyContent: 'flex-end' }}>
                              <Grid item>
                                {allowEdit && <SonifiIconButton label={translations.floor}
                                  onClick={this.addFloor.bind(this, index)}
                                  icon={<Add />} />}
                              </Grid>
                              <Grid item>
                                {allowEdit && <SonifiIconButton label=""
                                  onClick={this.handleDeleteDialog.bind(this, index, null, location.name)}
                                  icon={<Delete />}
                                  useTertiary={draggableSnapshot.isDragging}
                                  disabled={!location.canEdit || buildings.length < 2}
                                />}
                              </Grid>
                              <Grid item onClick={this.showHide.bind(this, index)}
                                style={{
                                  color: '#1592d9',
                                  paddingTop: '7px',
                                  height: '100%',
                                  paddingRight: '10px'
                                }}>
                                {location.open
                                  ? <ExpandMore style={{ float: 'right' }} />
                                  : <ExpandLess style={{ float: 'right' }} />}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        {location.open &&
                          <Grid container style={{ paddingBottom: `${location.open ? '10px' : '3px'}` }}>
                            <Grid item xs={2}></Grid>
                            <Grid item xs={9}
                              style={{ border: `1px solid ${(index % 2 === 0 ? '#fff' : '#f6f6f6')}` }}>
                              <Floors building={location} buildingIdx={index}
                                deleteFloor={this.handleDeleteDialog.bind(this)}
                                editName={this.editName.bind(this)} change={this.change.bind(this)}
                                allowEdit={allowEdit}
                                allowReorder={allowReorder} />
                            </Grid>
                            <Grid item xs={1}></Grid>
                          </Grid>}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <SonifiConfirm
          dialogOpen={deleteType === 'hierarchy' && buildingIndex !== null && buildingIndex !== undefined}
          onConfirm={this.confirmDialogConfirmFunc.bind(this, buildingIndex, floorIndex)}
          onCancel={this.confirmDialogCancelFunc}
          confirmTitle={translations.deleteTitle}
          confirmText={`${translations.deleteText} ${(itemName
            ? `${itemName}?`
            : '')}`}
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={globalTranslations.delete}
        />
      </Fragment>

    );
  }
}
const mapStateToProps = (state) => ({
  buildingIndex: state.siteManagement.buildingIndex,
  deleteType: state.siteManagement.deleteType,
  floorIndex: state.siteManagement.floorIndex,
  globalTranslations: state.global.translations.defaults,
  itemName: state.siteManagement.itemName,
  savingStatus: state.siteManagement.savingStatus,
  site: state.rooms.buildings,
  translations: state.siteManagement.translations.site
});

Locations.propTypes = {
  allowEdit: PropTypes.bool,
  allowReorder: PropTypes.bool,
  buildingIndex: PropTypes.number,
  deleteType: PropTypes.string,
  dispatch: PropTypes.func,
  floorIndex: PropTypes.number,
  globalTranslations: PropTypes.object,
  itemName: PropTypes.string,
  onUpdate: PropTypes.func,
  savingStatus: PropTypes.number,
  site: PropTypes.array,
  siteHierarchy: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(Locations);
