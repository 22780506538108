import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { loadSiteHierarchy } from '../../../../actions/globalActions';
import { SONIFI } from '../../../../constants/constants';
import { SonifiLabel, SonifiSpinner, SonifiText } from '../../../../containers';
import RcTree from '../../../../containers/RoomSelection/RcTree/RcTree';
import SonifiModalHeader from '../../../../containers/SonifiModalHeader';
import { getErrorText, isError } from '../../../../utils';
import { fetchFirmware, startFirmware } from '../../../Firmware/actions/firmwareActions';
import { updateRoomTerminalFirmware } from '../../../Terminals/actions/terminalActions';
import { saveSiteFirmware } from '../../actions/FirmwareActions';
import { updateSonifiFirmware } from '../../actions/SonifiFirmwareActions';
import { MANUAL } from '../../constants/TermFileConstants';
import { validSONIFIFirmware } from '../../utils/validator';

const styles = () => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden'
  },
  fullHeightRed: {
    height: '69vh'
  },
  fullHeightGreen: {
    height: '92%',
    overflow: 'auto'
  }
});

class EditSonifiFirmware extends Component {
  state = {
    editLoading: false,
    errors: null,
    firmware: null,
    id: null,
    model: null,
    origFirmware: null,
    rooms: []
  };

  componentDidMount() {
    const { dispatch, editType, sonifiFirmware, sonifiIndex } = this.props;
    const { id, model, firmware } = sonifiFirmware[sonifiIndex];

    this.setState({
      id,
      model,
      firmware: (firmware ? firmware[Object.keys(firmware)[0]] : ''),
      origFirmware: (firmware ? firmware[Object.keys(firmware)[0]] : ''),
      editLoading: true
    });

    if (model) {
      dispatch(startFirmware());
      if (editType === MANUAL) {
        dispatch(loadSiteHierarchy(id, false));
      }

      setTimeout(() => {
        dispatch(fetchFirmware({ limit: 100, sort: 'id:asc', model: `${id}` }));
      }, 200);
    }
  }

  updateFirmware() {
    const {
      configuredFirmware, dispatch, editType, sonifiFirmware, sonifiIndex, translations
    } = this.props;
    const { firmware, id, origFirmware } = this.state;

    validSONIFIFirmware(this.state, translations.errors, translations.unconfigure)
      .then((isValidFirmware) => {
        this.setState({ errors: isValidFirmware });

        if (Object.entries(isValidFirmware).length === 0) {
          if (editType === MANUAL) {
            if (this.state.rooms.length === 0) {
              this.onCancel();
            }
            dispatch(updateRoomTerminalFirmware(sonifiFirmware[sonifiIndex], this.state.firmware, this.state.rooms));
            this.onCancel();
            return;
          }

          if (firmware === origFirmware) {
            this.onCancel();
            return;
          }

          const sonifiObj = {};
          sonifiObj.id = id;

          sonifiObj.firmware = {
            'HC-11': null,
            'HC-12': null,
            'SWT-54': null
          };

          if (firmware !== translations.unconfigure) {
            const selected2 = find(configuredFirmware, (o) => o.id === firmware);
            if (selected2 !== null) {
              sonifiObj.firmware[selected2.type] = selected2.id;
            }
          }
          this.props.dispatch(saveSiteFirmware(sonifiObj, SONIFI));
        }
      })
      .catch((error) => {
        console.log('Valid Firmware Error', error);
        this.setState({ errors: { vFirmware: translations.errors.general } });
      });
  }

  onCancel() {
    this.props.dispatch(updateSonifiFirmware(-1));
  }

  handleChange = () => ({ target: { value } }) => {
    this.setState({ firmware: value });
  };

  onChange(checked) {
    this.setState({ rooms: checked });
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.onCancel();
    }
  }

  render() {
    const {
      canEdit, configuredFirmware, classes, editType, globalTranslations, loading,
      loadingSite, siteHierarchy, translations, sonifiFirmware, sonifiIndex
    } = this.props;
    const { errors, firmware, origFirmware } = this.state;

    const tempFirmware = [...configuredFirmware];
    if (!loading && origFirmware !== '') {
      const selected = find(tempFirmware, (o) => o.id === origFirmware);
      if (selected === undefined) {
        tempFirmware.unshift({ id: origFirmware });
      }

      if (editType !== MANUAL) {
        tempFirmware.unshift({ id: `${translations.unconfigure}` });
      }
    }

    const desc = (editType === MANUAL ? translations.manualDescription : translations.description);

    return (
      <Dialog
        open={true}
        maxWidth="md"
        onClose={this.onClose.bind(this)}
        fullWidth
        classes={{ paper: classes.dialogPaper }}
      >
        <SonifiModalHeader
          header={editType === MANUAL ? translations.manualEdit : translations.edit}
          onlyClose={!canEdit || loading || loadingSite}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.updateFirmware.bind(this)}
          label={globalTranslations.save}
        />
        <DialogContent className={classes.fullHeight}>
          {loading || loadingSite
            ? <SonifiSpinner />
            : <Grid container className={classes.fullHeightRed}>
              <Grid item xs={12}>
                <SonifiLabel boldLabel={desc.replace('#model', sonifiFirmware[sonifiIndex].id)} />
              </Grid>
              <Grid item className={classes.fullHeightRed}>
                <SonifiText
                  change={this.handleChange()}
                  defaultValue={firmware}
                  error={isError('vFirmware', errors)}
                  errorText={getErrorText('vFirmware', errors)}
                  items={tempFirmware.map((suggestion) => ({
                    id: suggestion.id,
                    value: suggestion.id,
                    label: suggestion.id,
                  }))}
                  label={translations.vFirmware}
                  select={true}
                  size="lg"
                />
              </Grid>
              {editType === MANUAL &&
                <Grid item className={classes.fullHeightGreen}>
                  <RcTree nodes={siteHierarchy} updateCheckedPos={5}
                    autoExpandParent={true} onChange={this.onChange.bind(this)} />
                </Grid>}
            </Grid>
          }
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  configuredFirmware: state.firmware.firmware,
  editType: state.termFile.editType,
  globalTranslations: state.global.translations.defaults,
  loading: state.firmware.loading,
  loadingSite: state.global.loadingSite,
  siteHierarchy: state.global.buildings,
  sonifiFirmware: state.termFile.sonifiFirmware,
  sonifiIndex: state.termFile.sonifiIndex,
  translations: state.termFile.translations.popOver.sonifi,
});

EditSonifiFirmware.propTypes = {
  canEdit: PropTypes.bool,
  classes: PropTypes.object,
  configuredFirmware: PropTypes.array,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  loadingSite: PropTypes.bool,
  siteHierarchy: PropTypes.array,
  sonifiFirmware: PropTypes.array,
  sonifiIndex: PropTypes.number,
  translations: PropTypes.object,
};

// export default connect(mapStateToProps)(EditSonifiFirmware);
export default connect(mapStateToProps)(withStyles(EditSonifiFirmware, styles, { withTheme: true }));
