import { Search, Timelapse, Update } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { Waypoint } from 'react-waypoint';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { TERM_COMM_READ } from '../../../constants/roles';
import {
  SonifiCheckbox, SonifiIconButton, SonifiLabel, SonifiSpinner, SonifiText
} from '../../../containers';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { buildSortString } from '../../../utils';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import { fetchRoomsOptions } from '../../Rooms/actions/roomActions';
import Selects from '../../Rooms/containers/Selects';
import * as actions from './actions/termCommAction';
import TermCard from './components/TermCard';

class TermComm extends Component {
  constructor(props) {
    super(props);

    this.activateInterval = null;
    this.refreshVal = null;
    this.getNewestValue = null;

    this.state = {
      badComms: false,
      building: this.props.roomTranslations.all,
      filter: '',
      difference: null,
      floor: this.props.roomTranslations.all,
      limit: 100,
      occupied: false,
      page: 1,
      type: this.props.roomTranslations.all,
      minutes: 0,
      seconds: 0,
      refreshEnabled: true,
      unit: this.props.roomTranslations.all
    };

    this.activate = this.activate.bind(this);
    this.getMoreData = this.getMoreData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
    this.myCallback = this.myCallback.bind(this);
    this.refreshActivate = this.refreshActivate.bind(this);
    this.refreshData = this.refreshData.bind(this);
    this.setActivateTimer = this.setActivateTimer.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions.getTermComms(this.filterString()));
    dispatch(fetchRoomsOptions());

    clearInterval(this.activateInterval);
    // clearInterval(this.refreshVal);
    clearInterval(this.getNewestValue);
    this.getNewestValue = setInterval(this.myCallback, 30000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page && this.state.page !== -1) {
      this.props.dispatch(actions.getTermComms(this.filterString()));
    }

    if (this.props.lastPollingTime !== null && prevProps.lastPollingTime !== this.props.lastPollingTime) {
      clearInterval(this.activateInterval);
      const isoStr = new Date(this.props.lastPollingTime);// .getTime() / 1000;
      const isoStr2 = new Date();// .getTime() / 1000;
      const difference = Math.abs(isoStr - isoStr2);
      this.setState({
        difference
      }, () => {
        const minutes = moment.duration(difference).minutes();
        if (minutes >= 15) {
          clearInterval(this.activateInterval);
        } else {
          this.activateInterval = setInterval(this.setActivateTimer, 1000);
        }
      });
    }
  }

  componentWillUnmount() {
    clearInterval(this.activateInterval);
    //   clearInterval(this.refreshVal);
    clearInterval(this.getNewestValue);
  }

  myCallback() {
    if (!this.props.gettingMore) {
      this.props.dispatch(actions.updateTermComms(this.filterString()));
    }
  }

  filterString() {
    const { roomTranslations } = this.props;

    const filterObj = buildSortString(this.state);
    if (this.state.building !== roomTranslations.all && this.state.building !== null) {
      filterObj.building = this.state.building;
    }
    if (this.state.floor !== roomTranslations.all && this.state.floor !== null) {
      filterObj.floor = this.state.floor;
    }
    if (this.state.unit !== roomTranslations.all && this.state.unit !== null) {
      filterObj.unit = this.state.unit;
    }

    if (this.state.filter !== '') {
      filterObj.filter = this.state.filter.trim();
    }

    if (this.state.type !== roomTranslations.all) {
      filterObj.type = this.state.type;
    }

    if (this.state.occupied) {
      filterObj.occupied = this.state.occupied;
    }

    if (this.state.badComms) {
      filterObj.passive_status = 'bad';
    }
    return filterObj;
  }

  debouncedLoadMoreData = debounce(this.loadFilteredData, KEYBOARD_DELAY);

  handleTextFieldChange = ({ target: { value } }) => {
    this.setState({ filter: value }, () => {
      this.debouncedLoadMoreData();
    });
  };

  loadFilteredData() {
    this.handleSelectChange('filter', this.state.filter);
  }

  handleChange = (name) => ({ target: { value } }) => {
    this.handleSelectChange(name, value);
  };

  handleSelectChange(name, value) {
    this.setState({ [name]: value, page: -1 }, () => {
      this.setState({ page: 1 });
    });
  }

  handleCheckboxClick(event) {
    const value = event.target.value;
    if (value) {
      this.handleSelectChange('radioChecked', value);
    }
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  getBFC() {
    const { classifications, roomTranslations } = this.props;
    const { building, floor, type, unit } = this.state;
    return (
      <Grid container>
        <Grid item xs={6}>
          <Selects
            building={building}
            floor={floor}
            includeAll={true}
            onChange={this.handleSelectChange}
            unit={unit}
          />
        </Grid>
        <Grid item>
          <SonifiText
            label={roomTranslations.classification}
            select
            size="sm"
            defaultValue={type}
            items={[roomTranslations.all, ...classifications].map((suggestion) => ({
              id: suggestion,
              value: suggestion,
              label: suggestion,
            }))}
            change={this.handleChange('type')}
          />
        </Grid>
      </Grid>
    );
  }

  changeComms = () => {
    this.handleSelectChange('badComms', !this.state.badComms);
  };

  changeOccupied = () => {
    this.handleSelectChange('occupied', !this.state.occupied);
  };

  getSearch() {
    const { globalTranslations, translations } = this.props;
    const { badComms, filter, occupied } = this.state;

    return (
      <Grid container justifyContent="flex-end">
        <Grid item>
          <SonifiCheckbox
            label={translations.badComms}
            value={badComms}
            onChange={this.changeComms} />
        </Grid>
        <Grid item>
          <SonifiCheckbox
            label={translations.occupied}
            value={occupied}
            onChange={this.changeOccupied} />
        </Grid>
        <Grid item>
          <SonifiText
            change={this.handleTextFieldChange}
            defaultValue={filter}
            icon={<Search />}
            iconPosition="end"
            label={globalTranslations.search}
            size="percentNoPad"
          />
        </Grid>
      </Grid>
    );
  }

  getPageDetails() {
    const {
      loading, gettingMore, maxPages, site, terminals, translations
    } = this.props;
    const { page } = this.state;

    if (!site || site === undefined) {
      return <SonifiSpinner />;
    }

    return (
      <Grid container justifyContent="space-between">
        <Grid item xs={6}>
          {this.getBFC()}
        </Grid>
        <Grid item xs={6}>
          {this.getSearch()}
        </Grid>

        {loading
          ? <SonifiSpinner />
          : <Grid item xs={12}>
            <Grid container item xs={12} spacing={1}>
              {terminals.map((option, index) => (
                <Grid key={`w_${index}`} item xs={2}>
                  <TermCard index={index} />
                  {index === terminals.length - 12 && page < maxPages &&
                    <Waypoint onEnter={this.getMoreData} />
                  }
                </Grid>
              ))}
              {gettingMore && <SonifiSpinner />}
              {terminals.length === 0 &&
                <Grid item>
                  <SonifiLabel error label={translations.errors.noTerms} />
                </Grid>}
            </Grid>
          </Grid>}
      </Grid>
    );
  }

  refreshData() {
    this.props.dispatch(actions.refreshRequests());
    this.refreshVal = setInterval(this.refreshActivate, 60000);
    this.setState({ refreshEnabled: false });
  }

  refreshActivate() {
    clearInterval(this.refreshVal);
    this.setState({ refreshEnabled: true });
  }

  activate() {
    this.props.dispatch(actions.loadTermCommsPage());
  }

  setActivateTimer() {
    const isoStr = new Date(this.props.lastPollingTime);
    const isoStr2 = new Date();
    const difference = Math.abs(isoStr - isoStr2);
    this.setState({ difference });
  }

  getButtons() {
    const { lastPollingTime, loading, translations } = this.props;
    const { difference, refreshEnabled } = this.state;

    const minutes = moment.duration(difference).minutes();
    const seconds = 59 - moment.duration(difference).seconds();
    const activateDisabled = minutes < 15;

    return (
      <Grid container>
        {activateDisabled && lastPollingTime !== null &&
          <Fragment>
            <Grid item>
              <div style={{ display: 'flex', height: '100%', padding: '8px 6px' }}>
                <Timelapse style={{ color: '#1592d9' }} />
              </div>
            </Grid>
            <Grid item>
              <SonifiLabel blue
                label={translations.polling} />
            </Grid>
            <Grid item style={{ paddingLeft: '12px' }}>
              <SonifiLabel blue boldLabel={translations.remaining}
                label={`${14 - moment.duration(difference).minutes()}:${seconds < 10 ? `0${seconds}` : seconds}`} />
            </Grid>
          </Fragment>

        }
        <Grid item>
          <SonifiIconButton onClick={this.activate} icon={<Update />} label={translations.status}
            toolTipMessage={activateDisabled ? translations.activateDisabled : translations.activateEnabled}
            showToolTip disabled={activateDisabled || loading}
          />
        </Grid>
        <Grid item>
          <SonifiIconButton onClick={this.refreshData} icon={<Update />} label={translations.refresh}
            toolTipMessage={refreshEnabled ? translations.resetEnabled : translations.resetDisable}
            showToolTip disabled={!refreshEnabled || loading}
          />
        </Grid>
      </Grid>
    );
  }

  render() {
    const { loading, translations, userPermissions } = this.props;

    if (!checkForSingleUserPermission(TERM_COMM_READ, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    return (
      <SonifiTemplate
        disabled={loading}
        header={translations.title}
        middleExtra={this.getButtons()}
        pageDetails={this.getPageDetails()}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  classifications: state.rooms.classifications,
  gettingMore: state.termComm.gettingMore,
  globalTranslations: state.global.translations.defaults,
  lastPollingTime: state.termComm.lastPollingTime,
  loading: state.termComm.loading,
  maxPages: state.termComm.maxPages,
  roomTranslations: state.rooms.translations.main,
  site: state.rooms.buildings,
  terminals: state.termComm.terminals,
  translations: state.termComm.translations.grid,
  userPermissions: state.global.permissions
});

TermComm.propTypes = {
  classifications: PropTypes.array,
  dispatch: PropTypes.func,
  gettingMore: PropTypes.bool,
  globalTranslations: PropTypes.object,
  lastPollingTime: PropTypes.string,
  loading: PropTypes.bool,
  maxPages: PropTypes.number,
  roomTranslations: PropTypes.object,
  site: PropTypes.array,
  terminals: PropTypes.array,
  translations: PropTypes.object,
  userPermissions: PropTypes.array.isRequired
};

export default connect(mapStateToProps)(TermComm);
