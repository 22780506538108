import SearchIcon from '@mui/icons-material/Search';
import {
    Grid, Paper, Table, TableBody, TableCell,
    TableRow
} from '@mui/material';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../../../constants/keyCodes';
import { SonifiLabel, SonifiTableHead, SonifiText } from '../../../containers/';
import { filterGroupSite } from '../../GroupManagement/actions/groupActions';

const styles = () => ({
  table: {
    minWidth: 700,
    maxHeight: '90%',
  },
});

class PreviewSites extends Component {
  state = {
    filter: '',
  };

  tableHeads = () => [
    {
      centerHeader: false,
      id: 'id',
      label: this.props.translations.id,
      numeric: false,
      sortable: false
    },
    {
      centerHeader: false,
      id: 'name',
      label: this.props.translations.name,
      numeric: false,
      sortable: false
    }
  ];

  debouncedLoadMoreData = debounce(this.filterData, KEYBOARD_DELAY);

  filterSite = ({ target: { value } }) => {
    this.setState({ filter: value }, () => {
      this.debouncedLoadMoreData();
    });
  };

  filterData() {
    this.props.dispatch(filterGroupSite(this.state.filter));
  }

  render() {
    const {
      classes, filteredSitesForUser, filterLoad, gettingGroupSites, globalTranslations, groupId, translations
    } = this.props;

    return (
      <Paper style={{ padding: '10px' }}>
        <Grid container>
          <Grid container style={{ justifyContent: 'space-between' }}>
            <Grid item style={{ lineHeight: 3 }}>
              <SonifiLabel boldLabel={translations.siteAssign} />
            </Grid>
            <Grid item>
              <SonifiText
                label={globalTranslations.search}
                defaultValue={this.state.filter}
                change={this.filterSite}
                icon={<SearchIcon />}
                iconPosition="end"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Table className={classes.table}>
              <SonifiTableHead
                headColumns={this.tableHeads()}
              />
              {filteredSitesForUser.length === 0
                ? <TableBody className={classes.tableBody}>
                  <TableRow>
                    <TableCell colSpan={3}>
                      {!(filterLoad || gettingGroupSites || filteredSitesForUser === null) &&
                        <SonifiLabel error label={((groupId === '' || groupId === null)
                          ? translations.errors.noGroup
                          : translations.errors.noSites)} />}
                    </TableCell>
                  </TableRow>
                </TableBody>
                : <TableBody className={classes.tableBody}>
                  {filteredSitesForUser.map((site, index) => (
                    <TableRow key={index}>
                      <TableCell>{site.id}</TableCell>
                      <TableCell>{site.name}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>}
            </Table>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.groups.changed,
  filteredSitesForUser: state.groups.filteredSitesForUser,
  filterLoad: state.groups.filterLoad,
  gettingGroupSites: state.groups.gettingGroupSites,
  globalTranslations: state.global.translations.defaults,
  translations: state.groups.translations.dialog
});

PreviewSites.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  filteredSitesForUser: PropTypes.array,
  filterLoad: PropTypes.bool,
  gettingGroupSites: PropTypes.bool,
  globalTranslations: PropTypes.object,
  groupId: PropTypes.string,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(PreviewSites, styles, { withTheme: true }));
