import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import PhoneInput from 'react-phone-input-2';

// import 'react-phone-input-2/lib/high-res.css';

export default class InternationalPhoneSignin extends Component {
  state = {
    country: {},
    phone_number: ''
  };

  componentDidMount() {
    if (this.props.phoneNumber) {
      this.setState({ phone_number: this.props.phoneNumber });
    }
  }

  updatePhoneView(value, countryInfo) {
    this.props.updateProfile(value, countryInfo);
  }

  render() {
    const { errors, phoneNumber } = this.props;
    const authDomain = process.env.REACT_APP_COGNITO_DOMAIN_PREFIX;
    if (!authDomain.includes('signin')) {
      return <div style={{ width: '100%', height: '100%', backgroundColor: 'red' }}></div>;
    }

    return (
      <Grid item style={{ height: '80px' }}>
        <PhoneInput
          autoFormat={true}
          country={'us'}
          enableSearch
          dropdownStyle={{
            borderRadius: '0px',
            margin: '4px',
          }}
          searchStyle={{ borderRadius: '0px' }}
          containerStyle={{
            border: 'none',
            left: '0',
            padding: '0',
            background: 'transparent',
            margin: '8px 0px 0.625rem'

          }}
          inputProps={{
            autoFocus: true
          }}
          inputStyle={{
            fontWeight: 400, /* Avenir 55 */
            borderRadius: '0',
            fontFamily: 'Open Sans',
            fontSize: '12pt',
            height: '3.125rem',
            width: '100%'
          }}
          specialLabel={null}
          onChange={this.updatePhoneView.bind(this)}
          value={phoneNumber}
        />
        {errors && errors.phone_number &&
          <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error"
            style={{
              color: '#f44336',
              display: 'block',
              fontFamily: 'Open Sans',
              fontSize: '0.75rem',
              fontWeight: 400, /* Avenir 55 */
              lineHeight: '1.66',
              margin: '3px 14px 0px',
              padding: '0 5px',
              zIndex: '1'
            }}
          >
            {errors.phone_number}
          </p>}
      </Grid>
    );
  }
}

InternationalPhoneSignin.propTypes = {
  errors: PropTypes.object,
  phoneNumber: PropTypes.string,
  signin: PropTypes.bool,
  translations: PropTypes.object,
  updateProfile: PropTypes.func
};
