/* eslint-disable no-magic-numbers*/
const initialState = {
  siteSetup: [{
    value: 'Site 1',
    label: 'Site 1',
    treeData: [
      {
        value: 'Site 1/Building 1',
        label: 'Building 1',
        treeData: [
          {
            value: 'Site 1/Building 1/Floor 1',
            label: 'Floor 1',
            treeData: [
              {
                value: 'Site 1/Building 1/Floor 1/101',
                label: '101'
              }
            ]
          },
          {
            value: 'Site 1/Building 1/Floor 2',
            label: 'Floor 2',
            treeData: [
              {
                value: 'Site 1/Building 1/Floor 2/201',
                label: '201'
              },
              {
                value: 'Site 1/Building 1/Floor 2/203',
                label: '203'
              }
            ]
          }
        ]
      },
      {
        value: 'Site 1/Building 2',
        label: 'Building 2',
        treeData: [
          {
            value: 'Floor 1',
            label: 'Floor 1',
            treeData: [
              {
                value: '101',
                label: '101'
              }
            ]
          },
          {
            value: 'Floor 2',
            label: 'Floor 2',
            treeData: [
              {
                value: '201',
                label: '201'
              },
              {
                value: '203',
                label: '203'
              }
            ]
          }
        ]
      }
    ]
  }],
  treeData: [
    {
      key: 'Site 1',
      title: 'Site 1',
      treeData:
        [
          {
            key: 'Floor 1',
            title: 'Floor 1',
            treeData:
              [
                { key: '101', title: '101' },
              ],
          },
          {
            key: 'Floor 2',
            title: 'Floor 2',
            treeData:
              [
                { key: '201', title: '201' },
                { key: '203', title: '203' },
              ],
          },
        ],
    },
  ]
};

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
