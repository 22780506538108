import {
  Radio, Table, TableBody, TableCell, TableRow
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { LINEUP_EDIT } from '../../../constants/roles';
import SonifiCheckbox from '../../../containers/SonifiCheckbox';
import SonifiError from '../../../containers/SonifiError';
import SonifiTableHead from '../../../containers/SonifiTableHead';
import { checkForSingleUserPermission } from '../../../utils/rolesUtil';
import { checkChannelInfo, getChannelsThatCanBeInLineup } from '../utils/validator';

/* eslint-disable camelcase */
class Channels extends Component {
  state = {
    selectAllChecked: false
  };

  checkboxChange = () => () => {
    console.log(this.state.selectAllChecked);
    if (this.state.selectAllChecked) {
      this.props.handleChannelChange([]);
      this.setState({ selectAllChecked: false });
    } else {
      const channelIds = [];

      for (let i = 0; i < this.props.channels.length; i++) {
        if (checkChannelInfo(this.props.channels[i], this.props.lineupInfo)) {
          channelIds.push(this.props.channels[i].ipg.source_id);
        }
      }
      this.props.handleChannelChange(channelIds);
      this.setState({ selectAllChecked: true });
    }
  };

  tableHeads = (is_power_on_enabled) => (
    [
      {
        centerHeader: false,
        checkbox: true,
        checkValue: this.state.selectAllChecked,
        disabled: this.props.disabled,
        error: (this.props.errors && this.props.errors.channels),
        id: '',
        label: '',
        noLabel: true,
        numeric: false,
        onChange: this.checkboxChange(),
        sortable: false
      },
      {
        centerHeader: true,
        error: (this.props.errors && this.props.errors.power_on_ipg_source_id),
        hide: !is_power_on_enabled,
        width: '12%',
        id: 'power',
        label: this.props.translations.power,
        narrow: true,
        numeric: false,
        sortable: false
      },
      {
        id: 'channel', sortable: true, numeric: true, centerHeader: false, label: this.props.translations.channel
      },
      {
        id: 'tvDisplay', sortable: false, numeric: false, centerHeader: false, label: this.props.translations.tvDisplay
      }
    ]
  );

  isChannelInLineUp = (ipgSourceId) => {
    const { channels } = this.props.lineupInfo;

    if (channels.length === 0) {
      return false;
    }
    return (channels.includes(ipgSourceId));
  };

  onChannelChange = (channelId) => ({ target: { checked } }) => {
    const channels = this.props.lineupInfo.channels;
    const channelIndex = channels.findIndex((channel) => channel === channelId);

    if (checked && channelIndex === -1) {
      channels.push(channelId);
      this.props.handleChannelChange(channels);
    } else if (!checked && channelIndex !== -1) {
      channels.splice(channelIndex, 1);
      this.props.handleChannelChange(channels);
      this.setState({ selectAllChecked: false });
    }
  };

  handleRequestSort = (property) => {
    console.log('Handle sort', property);
  };

  render() {
    const {
      channels, disabled, handleRadioChange, lineupInfo,
      power_on_ipg_source_id, translations, userPermissions
    } = this.props;

    const {
      is_power_on_enabled
    } = lineupInfo;

    if (!channels) {
      return <Fragment />;
    }

    const fullEdit = checkForSingleUserPermission(LINEUP_EDIT, userPermissions);

    const channelsInLineup = getChannelsThatCanBeInLineup(lineupInfo, channels);
    return (
      <div style={{
        border: 'none', flexGrow: 1, height: '100%', overflowX: 'hidden', width: '100%'
      }}>
        <Table stickyHeader={true}>
          <SonifiTableHead
            headColumns={this.tableHeads(is_power_on_enabled)}
            order={'asc'}
            orderBy={'id'}
            onRequestSort={this.handleRequestSort.bind(this)}
            hideSortIcon={true}
          />
          <TableBody>
            {channelsInLineup.length < 1
              ? <TableRow >
                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                  <SonifiError
                    label={translations.noFilterResults} />
                </TableCell>
              </TableRow>
              : channels.map((channel, index) => (
                checkChannelInfo(channel, lineupInfo) && <Fragment key={index}>
                  <TableRow >
                    <TableCell>
                      <SonifiCheckbox
                        noLabel={true}
                        label=""
                        disabled={disabled}
                        value={this.isChannelInLineUp(channel.ipg.source_id)}
                        onChange={this.onChannelChange(channel.ipg.source_id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Radio
                        checked={channel.ipg.source_id === power_on_ipg_source_id}
                        disabled={!fullEdit}
                        style={{ visibility: `${is_power_on_enabled ? 'visible' : 'hidden'}` }}
                        inputProps={{ 'aria-label': `${channel.ipg.source_id}` }}
                        name="powerOnChan"
                        onChange={handleRadioChange('power_on_ipg_source_id', channel.ipg.source_id)}
                        value={channel.ipg.source_id}
                      />
                    </TableCell>
                    <TableCell>{channel.id}</TableCell>
                    <TableCell>{channel.name}</TableCell>
                  </TableRow>
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  channels: state.channels.realChannels,
  translations: state.tvLineup.translations.editDialog,
  userPermissions: state.global.permissions
});

Channels.propTypes = {
  channels: PropTypes.array,
  disabled: PropTypes.bool,
  dispatch: PropTypes.func,
  errors: PropTypes.object,
  handleChannelChange: PropTypes.func,
  handleRadioChange: PropTypes.func,
  lineupInfo: PropTypes.object,
  power_on_ipg_source_id: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(Channels);
