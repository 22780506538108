export const ADD_GROUP_BEGIN = 'ADD_GROUP_BEGIN';
export const ADD_GROUP_FAILURE = 'ADD_GROUP_FAILURE';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const DELETE_CHECK = 'DELETE_CHECK';
export const DELETE_GROUP_BEGIN = 'DELETE_GROUP_BEGIN';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const FETCH_GROUPS_BEGIN = 'FETCH_GROUPS_BEGIN';
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FILTER_SITES_BEGIN = 'FILTER_SITES_BEGIN';
export const FILTER_SITES_FAILURE = 'FILTER_SITES_FAILURE';
export const FILTER_SITES_SUCCESS = 'FILTER_SITES_SUCCESS';
export const GET_GROUP_SITES_BEGIN = 'GET_GROUP_SITES_BEGIN';
export const GET_GROUP_SITES_FAILURE = 'GET_GROUP_SITES_FAILURE';
export const GET_GROUP_SITES_SUCCESS = 'GET_GROUP_SITES_SUCCESS';
export const GET_SITES_BEGIN = 'GET_SITES_BEGIN';
export const GET_SITES_FAILURE = 'GET_SITES_FAILURE';
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
export const RESET_GROUP = 'RESET_GROUP';
export const SORT_GROUPS_BEGIN = 'SORT_GROUPS_BEGIN';
export const UPDATE_SELECTED_GROUP = 'UPDATE_SELECTED_GROUP';
export const UPDATE_SNACK_BAR = 'GROUP_UPDATE_SNACK_BAR';
export const GET_ALL_SITES_FAILURE = 'GET_ALL_SITES_FAILURE';
export const GET_ALL_SITES_SUCCESS = 'GET_ALL_SITES_SUCCESS';
export const FILTER_GROUP_SITES_FOR_USER = 'FILTER_GROUP_SITES_FOR_USER';
