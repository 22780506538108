export const HTTP_NOT_FOUND = 404;

export function httpSuccess(statusCode) {
  // Since a success code is 2xx, I don't think we want
  // the <= here.
  return (statusCode >= 200 && statusCode < 300);
}

export function isClientError(statusCode) {
  return (statusCode >= 400 && statusCode < 500);
}
export function isServerError(statusCode) {
  return (statusCode >= 500 && statusCode < 600);
}
