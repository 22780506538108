import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { SonifiText } from '../../../../containers/';
import { getErrorText, isError } from '../../../../utils/index';

export default class TieMap extends Component {
  changeValue = (name) => ({ target: { value } }) => {
    this.props.updateTypeState(name, value, 'map');
  };

  getDropDown(label, items) {
    const { notEditable, editState } = this.props;
    const { errors } = editState;

    return (
      <SonifiText
        size="lg"
        label={label}
        defaultValue={(editState.map[label] ? editState.map[label] : '')}
        change={this.changeValue(label)}
        error={isError(label, errors)}
        errorText={getErrorText(label, errors)}
        disabled={notEditable}
        select={true}
        items={items}
      />
    );
  }

  render() {
    return (
      <Fragment />
    );
  }
}

TieMap.propTypes = {
  editState: PropTypes.object,
  notEditable: PropTypes.bool,
  updateTypeState: PropTypes.func
};
