import { Search } from '@mui/icons-material';
import { Paper } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';
import { withStyles } from 'tss-react/mui';
import { KEYBOARD_DELAY } from '../constants/keyCodes';
import { getObjectPromise, getSiteObjectPromise } from '../utils/api';

const filterOptions = (inputValue, options) => options.filter((i) => i.label);

const styles = {
  root: {
    display: 'flex',
    width: '100%'
  },
  searchable: {
    float: 'right',
    width: '96%',
    borderRadius: '0',
    borderStyle: 'none'
  },
  icon: {
    color: '#2e2e2e',
    margin: '6px'
  }
};

const searchStyle = () => ({
  'borderRadius': '0',
  'borderStyle': 'none',
  'borderColor': 'transparent',
  'boxShadow': 'none',

  ':hover': {
    borderColor: '#1592d9',
    boxShadow: 'none',
  }
});

const menuStyle = () => ({
  borderRadius: 0,
  zIndex: 1000
});

const optionStyle = () => ({
  ':hover': {
    backgroundColor: '#1592d9',
    color: 'white',
    boxShadow: 'none',
  }
});

const colourStyles = {
  // eslint-disable-next-line no-shadow
  control: (styles) => ({ ...styles, ...searchStyle() }),
  // eslint-disable-next-line no-shadow
  menu: (styles) => ({ ...styles, ...menuStyle() }),
  // eslint-disable-next-line no-shadow
  option: (styles) => ({ ...styles, ...optionStyle() }),
};

export class SonifiFilterAsync extends Component {
  state = {
    inputValue: '',
    options: []
  };

  timeout = null;

  componentDidMount() {
    // this.getFilteredData(this.props.defaultValue ? this.props.defaultValue.trim() : '');
  }

  getFilteredData(filter) {
    return new Promise((resolve, reject) => {
      if (this.props.siteObj) {
        getSiteObjectPromise(this.props.resource,
          this.props.info,
          this.props.siteId,
          { filter, limit: 20 }).then((data) => {
            const options = data[this.props.resource].map((d) => ({
              value: d,
              type: this.props.type,
              label: `${d.id} ${(d.name ? `: ${d.name}` : '')}`,
            }));
            resolve(options);
          }).catch((error) => {
            console.log('Error in GetObject: ', error);
            reject(error);
          });
      } else {
        getObjectPromise(this.props.resource, this.props.info, { filter, limit: 20 }).then((data) => {
          const options = data[this.props.resource].map((d) => ({
            value: d,
            type: this.props.type,
            label: `${d.id} ${(d.name ? `: ${d.name}` : '')}`,
          }));
          resolve(options);
        }).catch((error) => {
          console.log('Error in GetObject: ', error);
          reject(error);
        });
      }
    });
  }


  // TODO
  // Remove the following 'callback' methods
  // successCallback = (data) => {
  //   console.log(`Filter successCall back for ${this.props.resource}`);
  //   console.log(data);

  //   const options = data[this.props.resource].map((d) => ({
  //     value: d,
  //     type: this.props.type,
  //     label: `${d.id} ${(d.name ? `: ${d.name}` : '')}`,
  //   }));
  //   this.setState({ options });

  //   //
  //   if(this.props.defaultValue) {
  //     this.setState({
  //       currentValue: {
  //         value: data[this.props.resource][0],
  //         type: this.props.type,
  //         label: `${data[this.props.resource][0].id} ${(data[this.props.resource][0].name
  //           ? `: ${data[this.props.resource][0].name}`
  //           : '')}`,
  //       }
  //     });
  //   }
  // }

  // failureCallback = (data) => {
  //   console.log('Filter failure');
  //   console.log(data);
  // }

  handleInputChange = (newValue) => {
    if (newValue.length > 0) {
      this.setState({ newValue });

      // this.getFilteredData(newValue);
      // return newValue;
    }
  };

  handleChange = (newValue) => {
    this.setState({ currentValue: newValue });
    this.props.onSelect(newValue);
  };

  loadOptions = (inputValue, callback) => {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.getFilteredData(inputValue).then((options) => {
        callback(filterOptions(inputValue, options));
      });
    }, KEYBOARD_DELAY);
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.root}>
        <Search className={classes.icon} />
        <AsyncSelect
          className={classes.searchable}
          styles={colourStyles}
          cacheOptions
          loadOptions={this.loadOptions}
          defaultOptions
          onInputChange={this.handleInputChange}
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          value={this.state.currentValue}
        />
      </Paper>
    );
  }
}

SonifiFilterAsync.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  resource: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  siteObj: PropTypes.bool,
  info: PropTypes.string,
  siteId: PropTypes.string,
  defaultValue: PropTypes.string
};

export default withStyles(SonifiFilterAsync, styles);
