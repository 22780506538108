import { Add } from '@mui/icons-material';
import { Dialog, DialogContent, Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { ERROR } from '../../../../constants/constants';
import { MAX_SIZE } from '../../../../constants/magic';
import { SonifiButton, SonifiLabel, SonifiModalHeader, SonifiText } from '../../../../containers';
import SonifiDropZone from '../../../../containers/SonifiDropZone/SonifiDropZone';
import {
  editTLXFile, importTLXFile, importTLXFileBegin, importTLXFileFailure, resetSnackBar
} from '../../actions/tlxFileActions';
import { allowUpload, validTLX } from '../../utils/validator';

const styles = (theme) => ({
  dropFile: {
    height: '95%',
    width: '45%',
    backgroundColor: theme.palette.secondary.main,
  },
  centered: {
    fontWeight: 900, /* Avenir 85 */
    color: theme.palette.primary.contrastText,
    fontFamily: 'Open Sans',
    fontSize: '12pt',
    left: '50%',
    outline: 'none',
    position: 'relative',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  noPadding: {
    padding: 0,
  },
  textCenter: {
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  inputBoxFormatting: {
    alignItems: 'start',
    justifyContent: 'space-around',
    height: 'auto'
  },
  hoverCursor: {
    cursor: 'cell',
  },
  gridHeight: {
    height: '100%',
  },
  gridHeightTall: {
    height: '67%',
  },
  gridHeightShort: {
    height: '22%',
    width: '100%',
  },
  dropzonePadding: {
    padding: '5% 2.5% 2.5%'
  }
});

class ImportDialogTlx extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allowSave: false,
      changed: false,
      filename: null,
      importError: null,
      md5: null,
      tlxFile: null,
      uploaded: null
    };

    this.getIntroStuff = this.getIntroStuff.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.onAcceptedDrop = this.onAcceptedDrop.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    const { tlxFile } = this.props;
    if (tlxFile && tlxFile.length > 0 && tlxFile[0].filename) {
      this.setState({
        tlxFile: { ...tlxFile[0] },
        md5: tlxFile[0].md5,
        filename: tlxFile[0].filename,
        oldFilename: tlxFile[0].filename,
        uploaded: tlxFile[0].uploaded,
      });
    }
  }

  onAcceptedDrop(acceptedFiles) {
    const { dispatch, translations } = this.props;

    if (allowUpload(acceptedFiles[0].name)) {
      this.setState({
        allowSave: true,
        importError: null,
        tlxFile: acceptedFiles[0],
        filename: acceptedFiles[0].name,
        uploaded: null,
        md5: null,
        changed: true,
      });
      return;
    }
    this.setState({ importError: translations.errors.fileType });
    dispatch(resetSnackBar(ERROR, translations.errors.fileType));
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(editTLXFile(false));
    }
  }

  onSave() {
    if (!this.state.changed) {
      this.props.dispatch(editTLXFile(false));
      return;
    }
    const { dispatch } = this.props;

    dispatch(importTLXFileBegin());
    const data = validTLX(this.state.tlxFile, this.props.translations.errors);
    this.setState({ errors: data });

    if (Object.entries(data).length === 0) {
      dispatch(importTLXFile(this.state));
    } else {
      dispatch(importTLXFileFailure('Filename already in use'));
    }
  }

  handleButtonClick() {
    window.open('http://cct.procentric.tv/');
  }

  getIntroStuff() {
    const { classes, globalTranslations } = this.props;
    return (
      <Grid container className={`${classes.textCenter}`}>
        <Grid item>
          <div className={classes.textCenter}>
            <Add />
            <SonifiLabel
              additionalClasses={classes.noPadding}
              label={globalTranslations.browseFile}
            />
            <SonifiLabel
              additionalClasses={classes.noPadding}
              label={globalTranslations.addDrop}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  getGridText(label, value) {
    return (
      <Grid item>
        <SonifiText label={label} size="md" defaultValue={value} disabled={true} />
      </Grid>
    );
  }

  render() {
    const { classes, globalTranslations, translations } = this.props;
    const {
      allowSave, tlxFile, md5, filename, uploaded, importError
    } = this.state;

    return (
      <Fragment>
        <Dialog
          open={true}
          onClose={this.onClose}
          fullWidth
          maxWidth="lg">
          <SonifiModalHeader
            header={!tlxFile || tlxFile.length === 0 ? translations.addFile : translations.editFile}
            onlyClose={!allowSave}
            onCancel={this.onClose}
            onSubmit={this.onSave}
            label={globalTranslations.import}
          />
          <DialogContent>
            <Grid container className={`${classes.gridHeight} ${classes.textCenter}`}>
              <Grid container className={`${classes.inputBoxFormatting}`}>
                {filename && this.getGridText(translations.filename, filename)}
                {md5 && this.getGridText(translations.identifier, md5)}
                {uploaded && this.getGridText(translations.uploaded, moment(uploaded).format('MM/DD/YYYY h:mm a'))}
                {!tlxFile && <div style={{ height: '80px', width: '100%' }} />}
                {importError !== null && <SonifiLabel error label={importError} />}
              </Grid>
              <Grid item xs={6} className={`${classes.gridHeightTall} ${classes.dropzonePadding}`}>
                <SonifiDropZone
                  acceptedFiles={['.tlx']}
                  allowMultiple={false}
                  maxSize={MAX_SIZE}
                  onAcceptedDropHandler={this.onAcceptedDrop}
                  displayDropZoneContent={this.getIntroStuff}
                />
              </Grid>
              <Grid item xs={12} className={`${classes.gridHeightShort} ${classes.textCenter}`}>
                <SonifiLabel label={this.props.globalTranslations.tlxInstruct} />
                <SonifiButton caption={globalTranslations.createTlx} onClick={this.handleButtonClick} />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.termFile.changed,
  editTLX: state.termFile.editTLX,
  globalTranslations: state.global.translations.importDialog,
  loading: state.termFile.loading,
  tlxFile: state.termFile.tlxFile,
  translations: state.termFile.translations.grid.tlx
});

ImportDialogTlx.propTypes = {
  canEdit: PropTypes.bool,
  changed: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  editTLX: PropTypes.bool,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  termFileState: PropTypes.object,
  tlxFile: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(ImportDialogTlx, styles));
