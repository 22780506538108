export const ADD_MANUAL_VERSION_BEGIN = 'ADD_MANUAL_VERSION_BEGIN';
export const ADD_MANUAL_VERSION_COMPLETE = 'ADD_MANUAL_VERSION_COMPLETE';
export const DELETE_CHECK = 'DELETE_DEPLOYMENT_CHECK';
export const EDIT_SELECTED_DEPLOYMENT_BEGIN = 'EDIT_SELECTED_DEPLOYMENT_BEGIN';
export const EDIT_SELECTED_DEPLOYMENT_FAILURE = 'EDIT_SELECTED_DEPLOYMENT_FAILURE';
export const EDIT_SELECTED_DEPLOYMENT_SUCCESS = 'EDIT_SELECTED_DEPLOYMENT_SUCCESS';
export const GET_MORE_PP_VERSIONS_BEGIN = 'GET_MORE_PP_VERSIONS_BEGIN';
export const GET_MORE_PP_VERSIONS_FAILURE = 'GET_MORE_PP_VERSIONS_FAILURE';
export const GET_MORE_PP_VERSIONS_SUCCESS = 'GET_MORE_PP_VERSIONS_SUCCESS';
export const GET_PRODUCT_PARENTS_BEGIN = 'GET_PRODUCT_PARENTS_BEGIN';
export const GET_PRODUCT_PARENTS_FAILURE = 'GET_PRODUCT_PARENTS_FAILURE';
export const GET_PRODUCT_PARENTS_SUCCESS = 'GET_PRODUCT_PARENTS_SUCCESS';
export const GET_RELEASE_VERSIONS_BEGIN = 'GET_RELEASE_VERSIONS_BEGIN';
export const GET_RELEASE_VERSIONS_FAILURE = 'GET_RELEASE_VERSIONS_FAILURE';
export const GET_RELEASE_VERSIONS_SUCCESS = 'GET_RELEASE_VERSIONS_SUCCESS';
export const GET_VIRTOS_PKGS_BEGIN = 'GET_VIRTOS_PKGS_BEGIN';
export const GET_VIRTOS_PKGS_FAILURE = 'GET_VIRTOS_PKGS_FAILURE';
export const GET_VIRTOS_PKGS_SUCCESS = 'GET_VIRTOS_PKGS_SUCCESS';
export const SORT_RELEASE_VERSIONS = 'SORT_RELEASE_VERSIONS';
export const UPDATE_DEPLOYMENTS_BAR = 'UPDATE_DEPLOYMENTS_BAR';
export const UPDATE_PRODUCT_PARENTS_BEGIN = 'UPDATE_PRODUCT_PARENTS_BEGIN';
export const UPDATE_PRODUCT_PARENTS_FAILURE = 'UPDATE_PRODUCT_PARENTS_FAILURE';
export const UPDATE_PRODUCT_PARENTS_SUCCESS = 'UPDATE_PRODUCT_PARENTS_SUCCESS';
export const UPDATE_SELECTED_DEPLOYMENT = 'UPDATE_SELECTED_DEPLOYMENT';
