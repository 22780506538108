/* eslint-disable max-lines */
import { httpSuccess } from '../../../constants/http';
import {
  EMPTY_LIBRARY_ITEM, GENERIC_ERROR, getNow, hasMessagePublished, isMessageExpired,
  LIBRARY_RESOURCE, NO_LIBRARY_ITEM, NO_PUBLISH_ITEM, PUBLISH_RESOURCE
} from '../../../constants/messages';
import { getUserNameFromSession } from '../../../utils';
import {
  deleteObjectPromise, getSiteObjectPromise, patchObjectPromise, postObjectPromise, putObjectPromise,
  readResponseErrorStream
} from '../../../utils/api';
import * as types from './actionTypes';

// GLOBAL ACTIONS
export const updateSaveAttempt = (saveAttemptComplete) => ({
  type: types.UPDATE_SAVE_ATTEMPT,
  saveAttemptComplete
});

export const updateDeleteAttempt = (deleteAttemptComplete) => ({
  type: types.UPDATE_DELETE_ATTEMPT,
  deleteAttemptComplete
});

export const cancelDialog = () => ({
  type: types.CANCEL_DIALOG
});

export const clearDialogVariables = () => ({
  type: types.CLEAR_DIALOG_VARIABLES
});


// LIBRARY ACTIONS
export const clearLibrary = () => ({
  type: types.CLEAR_LIBRARY
});

export const showMessageDialog = (dialogFormType, dialogMode) => ({
  type: types.SHOW_MESSAGE_DIALOG,
  payload: {
    dialogMode,
    dialogFormType
  }
});

export const closeMessageDialog = () => ({
  type: types.CLOSE_MESSAGE_DIALOG
});

export const fetchLibrarySuccess = (library) => ({
  type: types.FETCH_LIBRARY_SUCCESS,
  payload: { ...library }
});

export const fetchLibraryAllSuccess = (library) => ({
  type: types.FETCH_LIBRARY_ALL_SUCCESS,
  library
});

export const fetchLibraryFailure = (error) => ({
  type: types.FETCH_LIBRARY_FAILURE,
  error
});

export const addLibraryItemBegin = () => ({
  type: types.ADD_LIBRARY_ITEM_BEGIN
});

export const addLibraryItemSuccess = (libraryItem) => ({
  type: types.ADD_LIBRARY_ITEM_SUCCESS,
  libraryItem
});

export const editLibraryItemComplete = (libraryItem, dialogError) => ({
  type: types.EDIT_LIBRARY_ITEM_COMPLETE,
  payload: {
    libraryItem,
    dialogError
  }
});

export const deleteLibraryItemBegin = () => ({
  type: types.DELETE_LIBRARY_ITEM_BEGIN
});

export const deleteLibraryItemSuccess = (id) => ({
  type: types.DELETE_LIBRARY_ITEM_SUCCESS,
  id
});

export const deleteLibraryItemFailure = (error) => ({
  type: types.DELETE_LIBRARY_ITEM_FAILURE,
  error
});

export function deleteLibraryItem(id) {
  return (dispatch) => {
    dispatch(deleteLibraryItemBegin());

    return deleteObjectPromise(LIBRARY_RESOURCE, encodeURIComponent(id))
      .then((json) => {
        console.log('deleteLibraryItem: successCallback');
        console.log(json);

        if (!httpSuccess(json.status)) {
          dispatch(deleteLibraryItemFailure(`${id} (${json.status})`));
        } else {
          dispatch(deleteLibraryItemSuccess(id));
        }
        return json;
      }).catch((error) => {
        console.log('deleteLibraryItem: failureCallback');
        console.log(error);
        dispatch(deleteLibraryItemFailure(`${error.status}`));
        return error;
      });
  };
}

export const fetchLibraryBegin = () => ({
  type: types.FETCH_LIBRARY_BEGIN
});

// recursive function to get entire list of library records
export function getAllLibraryRecords() {
  return new Promise((resolveOuter, rejectOuter) => {
    // const currentPage = 1;
    // const pageCount = 1;
    const allData = [];

    (function loop(currentPage, pageCount) {
      if (currentPage <= pageCount) {
        new Promise((resolveInner, rejectInner) => {
          const options = {
            page: currentPage,
            sort: 'id:asc'
          };

          getSiteObjectPromise(LIBRARY_RESOURCE, null, null, options).then((data) => {
            pageCount = data.page_count;
            allData.push(...data.messages);
            resolveInner(pageCount);
          }).catch((error) => {
            console.log('Error retrieving library records', error);
            rejectInner(error);
            rejectOuter(error);
          });
        }).then((pgCount) => {
          loop(currentPage + 1, pgCount);
        });
      } else {
        resolveOuter(allData);
      }
    })(1, 1);
  });
}

// get entire library
export function fetchLibraryAll() {
  return (dispatch) => {
    dispatch(fetchLibraryBegin());
    dispatch(clearLibrary());
    getAllLibraryRecords().then((libraryItems) => {
      dispatch(fetchLibraryAllSuccess(libraryItems));
    }).catch((error) => {
      console.log('Error occurred fetching entire library.', error);
      dispatch(fetchLibraryFailure('Error occurred fetching entire library.'));
    });
  };
}

export function fetchLibrary(options) {
  return (dispatch) => {
    dispatch(fetchLibraryBegin());
    return getSiteObjectPromise(LIBRARY_RESOURCE, null, null, options)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchLibrarySuccess(json));
        return json;
      })
      .catch((error) => {
        readResponseErrorStream(error).then((errorDetail) => {
          console.log('readResponseErrorStream:', errorDetail.detail, errorDetail);
          if (errorDetail.detail === 'No Messages Found') {
            dispatch(fetchLibrarySuccess(
              {
                messages: [],
                page_count: 0,
                page_size: 0,
                total_items: 0,
              }
            ));
          } else {
            dispatch(fetchLibraryFailure(errorDetail));
          }
        });
      });
  };
}

export const getLibraryItemBegin = () => ({
  type: types.GET_LIBRARY_ITEM_BEGIN
});

export const getLibraryItemSuccess = (libraryItem) => ({
  type: types.GET_LIBRARY_ITEM_SUCCESS,
  libraryItem
});

export const getLibraryItemFailure = () => ({
  type: types.GET_LIBRARY_ITEM_FAILURE
});

// return specific library item
export function getLibraryItem(id) {
  return (dispatch) => {
    if (id.toString() !== NO_LIBRARY_ITEM.toString()) {
      dispatch(getLibraryItemBegin());
      return getSiteObjectPromise(LIBRARY_RESOURCE, encodeURIComponent(id))
        .then(handleErrors)
        .then((json) => {
          dispatch(getLibraryItemSuccess(json));
          return json;
        })
        .catch((error) => {
          readResponseErrorStream(error).then(() => {
            dispatch(getLibraryItemFailure());
            return error;
          });
        });
    } else {
      console.log('No Selected Object, returning empty library item');
      dispatch(getLibraryItemBegin());
      dispatch(getLibraryItemSuccess(EMPTY_LIBRARY_ITEM));
      return;
    }
  };
}

export function addLibraryItem(libraryItem, translations) {
  return (dispatch) => {
    dispatch(addLibraryItemBegin());
    const { id } = libraryItem;
    delete libraryItem.id;
    delete libraryItem.translations;
    if (id) {
      const obj = {
        display_type: libraryItem.display_type,
        message_type: libraryItem.message_type,
        source_id: libraryItem.source_id,
        source_type: libraryItem.source_type || libraryItem.sourceType,
        takeover_type: libraryItem.takeover_type,
        user_name: libraryItem.user_name

        //        asdf: 'asdf' //just an item in the object to force an error

      };

      return putObjectPromise(LIBRARY_RESOURCE, encodeURIComponent(id), obj) // libraryItem)
        .then((json) => {
          if (!json.ok) {
            readResponseErrorStream(json).then((errorDetail) => {
              console.log('readResponseErrorStream:', errorDetail);
              dispatch(addDialogError(errorDetail));
            });
            return json;
          }
          dispatch(addLibraryItemSuccess({ id, ...obj }));

          // if translations exist, insert those as well
          if (translations && translations.length) {
            processTranslations(dispatch, id, translations);
          }
          dispatch(closeMessageDialog());

          return json;
        })
        .catch((error) => {
          dispatch(addDialogError(error));
        });
    } else {
      dispatch(addDialogError({ status: GENERIC_ERROR, detail: 'Missing ID' }));
    }
  };
}

function deleteTranslation(dispatch, apiPath) {
  deleteObjectPromise(LIBRARY_RESOURCE, apiPath)
    .then((json) => json).catch((error) => {
      dispatch(addDialogError(error));
      return error;
    });
}

// api call to save translations for library item
function saveTranslation(dispatch, id, translation) {
  putObjectPromise(LIBRARY_RESOURCE, id, translation) // libraryItem)
    .then((json) => {
      if (!json.ok) {
        readResponseErrorStream(json).then((errorDetail) => {
          console.log('readResponseErrorStream:', errorDetail);
          dispatch(addDialogError(errorDetail));
        });
        return json;
      }
      return json;
    })
    .catch((error) => {
      dispatch(addDialogError(error));
    });
}

export function processTranslations(dispatch, messageId, translations) {
  return new Promise((resolve) => {
    if (translations && translations.length) {
      // no translations to update, resolve and continue
      resolve();
    }
    const userName = getUserNameFromSession();
    translations.forEach((translationItem) => {
      const lang = translationItem.id;
      const apiPath = `${encodeURIComponent(messageId)}/translations/${lang}`;

      // each translation must have both a title and body in order to be saved
      if ((translationItem.title === '') || (translationItem.body === '')) {
        deleteTranslation(dispatch, apiPath);
      } else {
        if (!translationItem.user_name) {
          translationItem.user_name = userName;
        }
        delete translationItem.id;
        saveTranslation(dispatch, apiPath, translationItem);
      }
    });

    // all translations updated, resolve and continue
    resolve();
  });
}

// recursive function to retrieve all publish records
export function getAllPublishRecords(id) {
  return new Promise((resolveOuter, rejectOuter) => {
    // const currentPage = 1;
    // const pageCount = 1;
    const allData = [];

    (function loop(currentPage, pageCount) {
      if (currentPage <= pageCount) {
        new Promise((resolveInner, rejectInner) => {
          const options = {
            page: currentPage,
            name: id
          };

          getSiteObjectPromise(PUBLISH_RESOURCE, null, null, options).then((data) => {
            pageCount = data.page_count;
            allData.push(...data.published_messages);
            resolveInner(pageCount);
          }).catch((error) => {
            console.log('Error retrieving publish records', error);
            rejectInner(error);
            rejectOuter(error);
          });
        }).then((pgCount) => {
          loop(currentPage + 1, pgCount);
        });
      } else if (allData.length === 0) {
        rejectOuter(new Error('NO DATA'));
      } else {
        resolveOuter(allData);
      }
    })(1, 1);
  });
}

// find all publish records using the library item id, and republish
export function loopAndRepublish(id, publishItems, timezone) {
  return new Promise((resolveOuter) => {
    // const currentIndex = 0;
    const totalItems = publishItems.length;
    let errorList = [];
    const now = getNow(timezone);

    (function loop(currentIndex) {
      if (currentIndex < totalItems) {
        new Promise((resolveInner) => {
          const item = publishItems[currentIndex];
          const { body } = item;
          const newPublishItem = {
            start_timestamp: item.start_timestamp,
            end_timestamp: item.end_timestamp,
            name: id,
            organization: item.organization,
            requester: item.requester,
            user_name: getUserNameFromSession(),
            body: {
              message_type: body.message_type,
              display_type: body.display_type,
              takeover_type: body.takeover_type,
              user_name: getUserNameFromSession()
            }
          };

          if (!isMessageExpired(item, timezone)) {
            if (hasMessagePublished(item, timezone)) {
              // if publish record is currently active, we must stop that publish
              // record and create a new one
              console.log('PUBLISH STOP/ADD', item, body);

              stopAndRepublishItem(item.id, now, newPublishItem).then(() => {
                console.log('ITEM STOPPED AND REPUBLISHED SUCCESS');
                resolveInner();
              }).catch((error) => {
                errorList = [...errorList, ...error];
                console.log('ITEM STOPPED AND REPUBLISHED ERROR', errorList);
                resolveInner();
              });
            } else {
              // if message has not yet been published, delete old one
              // and create new.  We are unable to edit anything in the publish
              // record besides start_timestamp and end_timestamp,
              // so it must be done that way
              deleteAndRepublishItem(item.id, newPublishItem).then(() => {
                console.log('ITEM DELETED AND REPUBLISHED SUCCESS');
                resolveInner();
              }).catch((error) => {
                errorList = [...errorList, ...error];
                console.log('ITEM DELETED AND REPUBLISHED ERROR', errorList);
                resolveInner();
              });
            }
          } else {
            // if publish record has expired, do nothing
            resolveInner();
          }
        }).then(() => {
          loop(currentIndex + 1);
        });
      } else {
        console.log('ERROR LIST', errorList);
        resolveOuter(errorList);
      }
    })(0);
  });
}

export function modifyExistingPublishRecords(id, timezone) {
  return new Promise((resolve) => {
    let errorList = [];

    // current id will return the proper records, even if library has just been renamed
    getAllPublishRecords(id)
      .then((publishItems) => {
        loopAndRepublish(id, publishItems, timezone)
          .then((error) => {
            errorList = [...errorList, ...error];
            resolve(errorList);
          });
      })
      .catch(() => {
        // 404, no records found to update, resolve promise
        console.log('modifyExistingPublishRecords - No Publish records to update');
        resolve(errorList);
      });
  });
}

// a library item cannot be renamed, so we must do a patch
function patchLibraryItem(id, origMessageId) {
  return new Promise((resolve, reject) => {
    // if name has not changed, continue
    if (id === origMessageId) {
      resolve();
    }

    patchObjectPromise(LIBRARY_RESOURCE, encodeURIComponent(origMessageId), { name: id })
      .then(() => {
        resolve();
      })
      .catch((error) => {
        console.log('patchObject: PROMISE failureCallback');
        console.log(error);
        reject(error);
      });
  });
}


// TODO
// WRAP EACH INDIVIDUAL CALL IN A PROMISE TO ENSURE EVERYTHING COMPLETES
// READ THIS LINK AGAIN TOO.
// https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b

// - patch library item first, it will resolve even if the id/name has not changed
// - then put the object to update the other fields
// - then update translations for the library item
// - then update and republish any publish records that are using this library item
export function editLibraryItem(libraryItem, translations, timezone) {
  return (dispatch) => {
    // const { id, translations } = libraryItem;
    const { id, origMessageId } = libraryItem;
    console.log('EDIT LIBRARY SAVE', libraryItem, translations);

    delete libraryItem.id;
    delete libraryItem.translations;
    if (id) {
      const obj = {
        source_type: libraryItem.source_type,
        source_id: libraryItem.source_id,

        // message_type: libraryItem.message_type,
        // display_type: libraryItem.display_type,
        // takeover_type: libraryItem.takeover_type,
        user_name: libraryItem.user_name,

        // asdf: 'asdf' // just an item in the object to force an error
      };

      return patchLibraryItem(id, origMessageId)
        .then(() => {
          putObjectPromise(LIBRARY_RESOURCE, encodeURIComponent(id), obj) // libraryItem)
            .then((libraryEditResponse) => {
              if (!libraryEditResponse.ok) {
                readResponseErrorStream(libraryEditResponse).then((errorDetail) => {
                  console.log('readResponseErrorStream:', errorDetail);
                  dispatch(addDialogError(errorDetail));
                });
                return libraryEditResponse;
              }

              // the errorlist is passed back with the resolve, as there are a number
              // of items that could have passed and failed, this gives us a log
              // of problems that occurred.
              // At this point, the library item has already been saved, but it is the
              // secondary items such as the translations and publish records that are
              // being updated as well.
              let errorList = [];
              processTranslations(dispatch, id, translations)
                .then(() => {
                  modifyExistingPublishRecords(id, timezone)
                    .then((error) => {
                      // library record has been saved and all publish records
                      // adjusted accordingly.  Set flag to denote process is complete.
                      errorList = [...errorList, ...error];
                      console.log('editLibraryItem ERRORLIST', errorList);
                      dispatch(closeMessageDialog());
                      dispatch(editLibraryItemComplete({ id, origMessageId, ...obj }, errorList));
                    });
                  return libraryEditResponse;
                });
            })
            .catch(() => {
              dispatch(addDialogError('Error saving library item.'));
            });
        });
    } else {
      dispatch(addDialogError({ status: GENERIC_ERROR, detail: 'Missing ID' }));
    }
  };
}

export const setSelectedLibraryItem = (id) => ({
  type: types.SET_SELECTED_LIBRARY_ITEM,
  id
});

export const clearSelectedLibraryItem = () => ({
  type: types.CLEAR_SELECTED_LIBRARY_ITEM
});

// PUBLISH ACTIONS
export const clearPublish = () => ({
  type: types.CLEAR_PUBLISH
});

export const clearLive = () => ({
  type: types.CLEAR_LIVE
});

export const fetchPublishBegin = () => ({
  type: types.FETCH_PUBLISH_BEGIN
});

export const fetchPublishSuccess = (publish, timezone) => ({
  type: types.FETCH_PUBLISH_SUCCESS,
  payload: { ...publish, timezone }
});

export const fetchPublishFailure = (error) => ({
  type: types.FETCH_PUBLISH_FAILURE,
  error
});

export function fetchPublishedMessages(options, timezone) {
  return (dispatch) => {
    dispatch(fetchPublishBegin());

    // options = {};
    return getSiteObjectPromise(PUBLISH_RESOURCE, null, null, options)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchPublishSuccess(json, timezone));
        return json;
      })
      .catch((error) => {
        console.log('fetchPublishedMessages FAIL', error);
        readResponseErrorStream(error)
          .then((errorDetail) => {
            console.log('readResponseErrorStream:', errorDetail);

            if (errorDetail.detail === 'No Messages Found') {
              dispatch(fetchPublishSuccess({
                published_messages: [],
                page_count: 0,
                page_size: 0,
                total_items: 0,
              }, timezone));
            } else {
              dispatch(fetchPublishFailure(errorDetail));
            }
          });
      });
  };
}

export const fetchLiveBegin = () => ({
  type: types.FETCH_LIVE_BEGIN
});

export const fetchLiveSuccess = (live) => ({
  type: types.FETCH_LIVE_SUCCESS,
  payload: { ...live }
});

export const fetchLiveFailure = (error) => ({
  type: types.FETCH_LIVE_FAILURE,
  error
});

export function fetchLiveMessages(options) {
  return (dispatch) => {
    dispatch(fetchLiveBegin());

    // options = {};
    return getSiteObjectPromise(PUBLISH_RESOURCE, null, null, options)
      .then(handleErrors)
      .then((json) => {
        dispatch(fetchLiveSuccess(json));
        return json;
      })
      .catch((error) => {
        console.log('fetchLiveMessages FAIL', error);
        readResponseErrorStream(error).then((errorDetail) => {
          console.log('readResponseErrorStream:', errorDetail);
          if (errorDetail.detail === 'No Messages Found') {
            dispatch(fetchLiveSuccess({
              published_messages: [],
              page_count: 0,
              page_size: 0,
              total_items: 0,
            }));
          } else {
            dispatch(fetchLiveFailure(errorDetail));
          }
        });
      });
  };
}

export const setSelectedPublishItem = (id) => ({
  type: types.SET_SELECTED_PUBLISH_ITEM,
  id
});

export const clearSelectedPublishItem = () => ({
  type: types.CLEAR_SELECTED_PUBLISH_ITEM
});

export const getPublishItemBegin = () => ({
  type: types.GET_PUBLISH_ITEM_BEGIN
});

export const getPublishItemSuccess = (publishItem) => ({
  type: types.GET_PUBLISH_ITEM_SUCCESS,
  publishItem
});

export const getPublishItemFailure = (error) => ({
  type: types.GET_PUBLISH_ITEM_FAILURE,
  error
});

export function getPublishItem(id) {
  return (dispatch) => {
    if (id !== NO_PUBLISH_ITEM) {
      dispatch(getPublishItemBegin());
      return getSiteObjectPromise(PUBLISH_RESOURCE, id)
        .then(handleErrors)
        .then((json) => {
          dispatch(getPublishItemSuccess(json));
          return json;
        })
        .catch((error) => {
          readResponseErrorStream(error).then((errorDetail) => {
            console.log('readResponseErrorStream:', errorDetail);
            dispatch(getPublishItemFailure(errorDetail));
          });
        });
    } else {
      console.log('No Selected Object');
    }
  };
}

export const addPublishItemBegin = () => ({
  type: types.ADD_PUBLISH_ITEM_BEGIN
});

export const addPublishItemSuccess = (publishItem, timezone) => ({
  type: types.ADD_PUBLISH_ITEM_SUCCESS,
  publishItem,
  timezone
});

export const addPublishItemError = () => ({
  type: types.ADD_PUBLISH_ITEM_ERROR
});

export const addDialogError = (error) => ({
  type: types.ADD_DIALOG_ERROR,
  error
});

// if publish item has not been published, we are able to delete and republish
// the api doesn't allow us to save and adjustments
export function deleteAndRepublishItem(deleteItemId, newPublishItem) {
  return new Promise((resolve, reject) => {
    const errorList = [];

    return deleteObjectPromise(PUBLISH_RESOURCE, deleteItemId)
      .then((json) => {
        postObjectPromise(PUBLISH_RESOURCE, newPublishItem)
          .then((newItemId) => {
            console.log('ITEM REPUBLISHED', newItemId, newPublishItem);
            resolve();
            return newItemId;
          })
          .catch((error) => {
            console.log('REPUBLISH ITEM FAILURE', error);
            errorList.push(error);
            reject(errorList);
          });
        return json;
      }).catch((error) => {
        errorList.push(error);
        reject(errorList);
        return error;
      });
  });
}

// if publish item has been published, we are not able to delete
// we need to stop the current publish record and create a new copy with the new data
export function stopAndRepublishItem(stopId, timestamp, newPublishItem) {
  return new Promise((resolve, reject) => {
    const errorList = [];
    if (stopId !== NO_PUBLISH_ITEM) {
      return getSiteObjectPromise(PUBLISH_RESOURCE, stopId)
        .then(handleErrors)
        .then((oldPublishItem) => {
          const roomsInBody = oldPublishItem?.body?.rooms && oldPublishItem.body.rooms.length > 0
            ? JSON.parse(JSON.stringify(oldPublishItem.body.rooms))// [...oldPublishItem.body.rooms]
            : [];
          delete oldPublishItem.body;
          delete oldPublishItem.published;
          oldPublishItem.end_timestamp = timestamp;
          const obj = {
            start_timestamp: oldPublishItem.start_timestamp,
            end_timestamp: timestamp
          };

          // stop publish item by setting end_timestamp to now
          return putObjectPromise(PUBLISH_RESOURCE, stopId, obj)
            .then((json) => {
              if (!json.ok) {
                readResponseErrorStream(json).then((error) => {
                  console.log('readResponseErrorStream:', error);
                  errorList.push(error);
                  reject(errorList);
                });
                return json;
              }

              newPublishItem.start_timestamp = timestamp;
              newPublishItem.rooms = roomsInBody;
              postObjectPromise(PUBLISH_RESOURCE, newPublishItem)
                .then((newItemId) => {
                  console.log('ITEM REPUBLISHED', newItemId, newPublishItem);
                  resolve();
                  return newItemId;
                })
                .catch((error) => {
                  console.log('REPUBLISH ITEM FAILURE', error);
                  errorList.push(error);
                  reject(errorList);
                });

              return json;
            })
            .catch((error) => {
              errorList.push(error);
              reject(errorList);
            });
        })
        .catch((error) => {
          readResponseErrorStream(error).then((errorDetail) => {
            console.log('readResponseErrorStream:', errorDetail);
            errorList.push(errorDetail);
            reject(errorList);
          });
        });
    } else {
      console.log('No Item to Republish.');
      errorList.push('No Item to Republish.');
      reject(errorList);
    }
  });
}

export function addPublishItem(publishItem, timezone) {
  return (dispatch) => {
    dispatch(addPublishItemBegin());
    return postObjectPromise(PUBLISH_RESOURCE, publishItem)
      .then((newItemId) => {
        getSiteObjectPromise(PUBLISH_RESOURCE, newItemId)
          .then(handleErrors)
          .then((item) => {
            dispatch(closeMessageDialog());
            dispatch(addPublishItemSuccess({
              id: Number(newItemId),
              ...item
            }, timezone));
            return item;
          })
          .catch((error) => {
            readResponseErrorStream(error).then((errorDetail) => {
              console.log('readResponseErrorStream:', errorDetail);
              dispatch(addDialogError(errorDetail));
            });
          });
        return newItemId;
      })
      .catch((error) => {
        dispatch(addDialogError(error));
      });
  };
}

export const editPublishItemSuccess = (publishItem) => ({
  type: types.EDIT_PUBLISH_ITEM_SUCCESS,
  publishItem
});

// only allowed to modify start/end datetimes
export function editPublishItem(publishItem) {
  console.log('EDIT PUBLISH ITEM', publishItem);
  return (dispatch) => {
    const { id } = publishItem;

    if (id) {
      const obj = {
        start_timestamp: publishItem.start_timestamp,
        end_timestamp: publishItem.end_timestamp,

        //        asdf: 'asdf' //just an item in the object to force an error
      };

      return putObjectPromise(PUBLISH_RESOURCE, id, obj) // libraryItem)
        .then((json) => {
          if (!json.ok) {
            readResponseErrorStream(json).then((errorDetail) => {
              console.log('readResponseErrorStream:', errorDetail);
              dispatch(addDialogError(errorDetail));
            });
            return json;
          }
          dispatch(editPublishItemSuccess({ id, ...obj }));
          return json;
        })
        .catch((error) => {
          dispatch(addDialogError(error));
        });
    } else {
      dispatch(addDialogError({ status: GENERIC_ERROR, detail: 'Missing ID' }));
    }
  };
}

export const stopPublishItemBegin = () => ({
  type: types.STOP_PUBLISH_ITEM_BEGIN
});

export const stopPublishItemSuccess = (publishItem) => ({
  type: types.STOP_PUBLISH_ITEM_SUCCESS,
  publishItem
});

export function stopPublishItem(id, timestamp) {
  console.log('STOP PUBLISH ITEM', id, timestamp);
  return (dispatch) => {
    dispatch(stopPublishItemBegin());
    if (id !== NO_PUBLISH_ITEM) {
      return getSiteObjectPromise(PUBLISH_RESOURCE, id)
        .then(handleErrors)
        .then((publishItem) => {
          console.log('PUBLISH ITEM', publishItem);
          delete publishItem.body;
          delete publishItem.published;

          // publishItem.id = id;
          publishItem.end_timestamp = timestamp;

          const obj = {
            start_timestamp: publishItem.start_timestamp,
            end_timestamp: timestamp
          };

          return putObjectPromise(PUBLISH_RESOURCE, id, obj)
            .then((json) => {
              if (!json.ok) {
                readResponseErrorStream(json).then((errorDetail) => {
                  console.log('readResponseErrorStream:', errorDetail);
                  dispatch(addDialogError(errorDetail));
                });
                return json;
              }
              dispatch(stopPublishItemSuccess({ id, ...publishItem }));
              return json;
            })
            .catch((error) => {
              dispatch(addDialogError(error));
            });
        })
        .catch((error) => {
          readResponseErrorStream(error).then((errorDetail) => {
            console.log('readResponseErrorStream:', errorDetail);
            dispatch(addDialogError(errorDetail));
          });
        });
    } else {
      console.log('No Selected Object');
    }
  };
}

export const deletePublishItemBegin = () => ({
  type: types.DELETE_PUBLISH_ITEM_BEGIN
});

export const deletePublishItemSuccess = (id) => ({
  type: types.DELETE_PUBLISH_ITEM_SUCCESS,
  id
});

export const deletePublishItemFailure = (error) => ({
  type: types.DELETE_PUBLISH_ITEM_FAILURE,
  error
});

// only allowed to delete if it was never active
export function deletePublishItem(id) {
  return (dispatch) => {
    dispatch(deletePublishItemBegin());

    return deleteObjectPromise(PUBLISH_RESOURCE, id)
      .then((json) => {
        console.log('deletePublishItem: successCallback');
        console.log(json);
        dispatch(deletePublishItemSuccess(id));
        return json;
      }).catch((error) => {
        console.log('deletePublishItem: failureCallback');
        console.log(error);
        dispatch(deletePublishItemFailure());
        return error;
      });
  };
}


// ----------------------------------------------------------------------
// Error Handling
// ----------------------------------------------------------------------
// Handle HTTP errors since fetch won't.
function handleErrors(response) {
  console.log('handleErrors response', response);

  // if (!response.ok) {
  //   throw Error(response.statusText);
  // }
  return response;
}
