import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SONIFI_ROLES } from '../../../../constants/roleGroups';
import { SITE_EDIT } from '../../../../constants/roles';
import SonifiLabel from '../../../../containers/SonifiLabel';
import SonifiText from '../../../../containers/SonifiText';
import { checkForAtLeastOneUserPermission, checkSingleUserPermission } from '../../../../utils/rolesUtil';
import { updateChromecastPort } from '../../../Rooms/actions/terminalActions';
import { updateChangeVariable } from '../../actions/siteManagementActions';
import { LOCATIONS } from '../../constants/SiteContants';

class ChannelDelivery extends Component {
  handleChange = () => ({ target: { value } }) => {
    this.props.dispatch(updateChromecastPort(value));
    this.props.dispatch(updateChangeVariable(false));
  };

  render() {
    const { translations, chromecastPort, userPermissions } = this.props;

    return (
      <Grid container>
        <Grid item xs={12}>
          <SonifiLabel boldLabel={translations.chromecastPort} />
        </Grid>
        <Grid item>
          <SonifiText
            defaultValue={chromecastPort}
            change={this.handleChange()}
            select={true}
            label={translations.chromecastPort}
            size="lg"
            items={LOCATIONS}
            disabled={!(checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions) &&
              checkSingleUserPermission(SITE_EDIT, userPermissions))} />
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  chromecastPort: state.terminals.chromecast_port,
  translations: state.siteManagement.translations.general,
  userPermissions: state.global.permissions
});

ChannelDelivery.propTypes = {
  chromecastPort: PropTypes.string,
  dispatch: PropTypes.func,
  userPermissions: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(ChannelDelivery);
