import { Check } from '@mui/icons-material';
import { Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { loadSiteHierarchy } from '../../../actions/globalActions';
import { ERROR } from '../../../constants/constants';
import { SITE_ROLES } from '../../../constants/roleGroups';
import { SITE_EDIT, SITE_LIMITED } from '../../../constants/roles';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiSubHeader from '../../../containers/SonifiSubHeader';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { fetchRoomsOptions } from '../../Rooms/actions/roomActions';
import { fetchTerminalOptions } from '../../Rooms/actions/terminalActions';
import {
  closeSnackBar, resetHierarchy, saveAvailableLocations, updateChangeVariable, updateSnackBar, updateTerminalLocations
} from '../actions/siteManagementActions';
import TerminalLocations from '../containers/TerminalLocations/TerminalLocations';
import { isValidHierarchy } from '../utils/validator';
import Hierarchy from './Hierarchy';


class Locations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buildings: null,
      terminalLocations: [],
      hasTerminalLocationsError: false,
      loading: false
    };

    this.onSave = this.onSave.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchTerminalOptions({ fields: 'locations:count' }));
    dispatch(fetchRoomsOptions());
    dispatch(loadSiteHierarchy());
    dispatch(resetHierarchy());
    dispatch(closeSnackBar());
    dispatch(updateChangeVariable(true));
  }

  componentDidUpdate(prevProps) {
    if (this.state.loading && prevProps.loadingSite) {
      setTimeout(() => {
        this.props.dispatch(updateChangeVariable(true));
        this.setState({ loading: false });
      }, 500);
    }

    if (!this.state.loading) {
      this.setState({ loading: true });
    }
  }

  onSave = () => {
    const activeLocations = this.state.terminalLocations.filter(
      (location) => location.is_active
    );
    const valid = isValidHierarchy(
      this.state.buildings,
      activeLocations,
      this.props.globalTranslations.errors
    );

    if (valid.errors.length === 0) {
      this.saveLocations();
      this.props.dispatch(updateTerminalLocations(activeLocations));
    } else {
      this.props.dispatch(updateSnackBar(ERROR, valid.errors));
    }
  };

  saveLocations = () => {
    const locationCopy = JSON.parse(JSON.stringify(this.state.buildings));
    for (let i = 0; i < locationCopy.length; i++) {
      locationCopy[i].name = locationCopy[i].name.trim();
      delete (locationCopy[i].open);
      delete (locationCopy[i].edit);
      delete (locationCopy[i].index);
      delete (locationCopy[i].canEdit);
      delete (locationCopy[i].isNew);
      delete (locationCopy[i].type);
      delete (locationCopy[i].error);
      if (locationCopy[i].floors && locationCopy[i].floors.length > 0) {
        for (let j = 0; j < locationCopy[i].floors.length; j++) {
          locationCopy[i].floors[j].name = locationCopy[i].floors[j].name.trim();
          delete (locationCopy[i].floors[j].open);
          delete (locationCopy[i].floors[j].edit);
          delete (locationCopy[i].floors[j].canEdit);
          delete (locationCopy[i].floors[j].isNew);
          delete (locationCopy[i].floors[j].type);
          delete (locationCopy[i].floors[j].error);
          if (locationCopy[i].floors[j].units && locationCopy[i].floors[j].units.length > 0) {
            for (let k = 0; k < locationCopy[i].floors[j].units.length; k++) {
              delete (locationCopy[i].floors[j].units[k].open);
              delete (locationCopy[i].floors[j].units[k].edit);
              delete (locationCopy[i].floors[j].units[k].canEdit);
              delete (locationCopy[i].floors[j].units[k].isNew);
              delete (locationCopy[i].floors[j].units[k].type);
              delete (locationCopy[i].floors[j].units[k].error);
            }
          }
        }
      }
    }

    this.props.dispatch(saveAvailableLocations(
      {
        buildings: locationCopy,
        classifications: this.props.classifications,
      }
    ));
    this.props.dispatch(updateChangeVariable(true));
  };

  onUpdateTerminalLocations = (terminalLocations, hasTerminalLocationsError, updateChange = true) => {
    if (updateChange) {
      this.props.dispatch(updateChangeVariable(false));
    }

    this.setState({ terminalLocations, hasTerminalLocationsError });
  };

  onHeirarchyUpdate = (buildings, updateChangeVar = true) => {
    if (updateChangeVar && checkForAtLeastOneUserPermission([SITE_EDIT, SITE_LIMITED], this.props.userPermissions)) {
      this.props.dispatch(updateChangeVariable(false));
    }
    this.setState({
      buildings
    });
  };

  render() {
    const {
      globalTranslations, loadingSite, terminalOptionsLoading, userPermissions
    } = this.props;

    if (!checkForAtLeastOneUserPermission(SITE_ROLES, userPermissions)) {
      return <Navigate replace to="/" />;
    }

    if (terminalOptionsLoading || loadingSite) {
      return <SonifiSpinner />;
    }

    const { hasTerminalLocationsError } = this.state;
    const canEdit = checkForAtLeastOneUserPermission([SITE_EDIT, SITE_LIMITED], userPermissions);

    return (
      <Grid container style={{ height: '100%', overflow: 'hidden' }}>
        <Grid container justifyContent="flex-end">
          {canEdit && <SonifiSubHeader
            header=""
            onSubmit={this.onSave}
            label={globalTranslations.defaults.save}
            icon={<Check />}
            showButton={canEdit}
            disabled={hasTerminalLocationsError}
          />}
        </Grid>
        <Divider style={{ marginTop: '5px', marginBottom: '5px' }} />
        <Grid item xs={12} style={{ height: 'calc(100% - 20px)' }}>
          <Grid container justifyContent="center" spacing={2} style={{ height: '100%', maxHeight: '100%' }}>
            <Grid item xs={6} style={{ height: '100%', maxHeight: '100%' }}>
              <Hierarchy onSiteUpdate={this.onHeirarchyUpdate} />
            </Grid>
            <Grid item xs={6} style={{ height: '100%', maxHeight: '100%' }}>
              <TerminalLocations
                setTerminalLocations={this.onUpdateTerminalLocations}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.siteManagement.changed,
  classifications: state.rooms.classifications,
  globalTranslations: state.global.translations,
  loading: state.siteManagement.loading,
  loadingSite: state.global.loadingSite,
  terminalOptionsLoading: state.terminals.terminalOptionsLoading,
  userPermissions: state.global.permissions,
});

Locations.propTypes = {
  classifications: PropTypes.array,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  loadingSite: PropTypes.bool,
  terminalOptionsLoading: PropTypes.bool,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(Locations);
