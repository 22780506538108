import { Replay, ReportProblem } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { TERMINAL_DETAIL_ROLES } from '../../../constants/roleGroups';
import { ROOM_DETAIL_EDIT, ROOM_DETAIL_EDIT_LIMITED } from '../../../constants/roles';
import { SonifiIconButton, SonifiLabel, SonifiSpinner } from '../../../containers';
import SonifiCheckbox from '../../../containers/SonifiCheckbox';
import SonifiDivider from '../../../containers/SonifiDivider';
import SonifiError from '../../../containers/SonifiError';
import SonifiText from '../../../containers/SonifiText';
import { findArrayIdByKey, hasGroupServiceFeat } from '../../../utils/index';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../../utils/rolesUtil';
import { checkForDuplicateRoom, editSelectedRoom, resetContentPin } from '../actions/editRoomActions';
import { updateTerminal } from '../actions/terminalActions';
import GuestStayInfo from '../containers/GuestStayInfo';
import Selects from '../containers/Selects';
import RoomTabs from './RoomTabs';

class EditForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      language: '',
      selectedGroup: undefined
    };

    this.handleResetPin = this.handleResetPin.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleGroupChange = this.handleGroupChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleTerminalChange = this.handleTerminalChange.bind(this);
  }

  componentDidMount() {
    const { selectedRoom } = this.props;
    const selectedGroup = (selectedRoom.groups !== undefined &&
      selectedRoom.groups !== null) &&
      selectedRoom.groups.length === 1
      ? selectedRoom.groups[0]
      : undefined;

    const tempGroups = this.getSiteGroups();
    this.setState({ groups: tempGroups, selectedGroup });
  }

  getSiteGroups() {
    if (hasGroupServiceFeat() !== 'true') {
      return [];
    }

    const tempGroups = [{ name: 'None' }];
    if (this.props.siteGroups) {
      tempGroups.push(...this.props.siteGroups);
    }

    if (this.props.selectedRoom.groups) {
      tempGroups.push(...this.props.selectedRoom.groups);
    }
    return uniqBy(tempGroups, 'id');
  }

  handleChange = (name) => ({ target: { value } }) => {
    this.props.dispatch(editSelectedRoom(name, value));

    if (name === 'editRoomNum') {
      this.props.dispatch(checkForDuplicateRoom(value));
    }
  };

  handleTerminalChange = (name) => ({ target: { value } }) => {
    this.setState({ [name]: value });

    for (let i = 0; i < this.props.terminals.length; i++) {
      this.props.dispatch(updateTerminal(i, 'edited', true));
      this.props.dispatch(updateTerminal(i, name, value));
    }
  };

  handleCheckboxChange = (name) => ({ target: { checked } }) => {
    this.props.dispatch(editSelectedRoom(name, checked));
  };

  handleSelectChange(name, value) {
    this.props.dispatch(editSelectedRoom(name, value));
    if (name === 'building') {
      const { site } = this.props;
      const buildingId = findArrayIdByKey(site, 'name', value);
      if (site[buildingId].floors.length === 1) {
        this.props.dispatch(editSelectedRoom('floor', site[buildingId].floors[0].name));
      }
    }
  }

  handleGroupChange(event) {
    const groupId = event.target.value.id,
      groupName = event.target.value.name;

    if (groupId === undefined) {
      this.setState({ selectedGroup: undefined });
      this.props.dispatch(editSelectedRoom('groups', undefined));
    } else {
      this.setState({ selectedGroup: { id: groupId, name: groupName } });
      this.props.dispatch(editSelectedRoom('groups', [{ id: groupId, name: groupName }]));
    }
  }

  handleResetPin() {
    console.log('EditForm:handleResetPin');
    const { dispatch, selectedRoom } = this.props;
    dispatch(resetContentPin(selectedRoom.editRoomNum));
  }

  getRoomInfo(roleAllowed) {
    const {
      errors, selectedRoom, translations
    } = this.props;

    return (
      <Grid container>
        <Grid item>
          <SonifiText
            label={translations.main.roomNum}
            size="sm"
            error={errors.id && errors.id.length > 0}
            errorText={errors.id}
            defaultValue={selectedRoom.editRoomNum}
            change={this.handleChange('editRoomNum')}
            disabled={roleAllowed}
          />
        </Grid>
        <Grid item>
          <Selects
            onChange={this.handleSelectChange}
            building={selectedRoom.building}
            unit={selectedRoom.unit}
            floor={selectedRoom.floor}
            includeAll={false}
            disabled={roleAllowed}
            errors={errors}
          />
        </Grid>
        <Grid item>
          <SonifiCheckbox
            float="right"
            label={translations.editDialog.testRoom}
            onChange={this.handleCheckboxChange('is_test')}
            value={selectedRoom.is_test || false}
            disabled={roleAllowed}
          />
        </Grid>
      </Grid>
    );
  }

  getContentRestriction() {
    const {
      globalTranslations, translations, userPermissions
    } = this.props;

    const roleAllowed = !checkForSingleUserPermission(ROOM_DETAIL_EDIT_LIMITED, userPermissions);

    return (
      <Grid item style={{ border: '1px solid #d6d6d6', padding: '10px', marginBottom: '10px' }}>
        <Grid container>
          <Grid item>
            <SonifiLabel blue label={translations.main.contentRestriction.desc} />
          </Grid>
          <Grid item>&nbsp; &nbsp;</Grid>
          <SonifiIconButton
            icon={<Replay style={{ transform: 'scaleX(-1)' }} />}
            label={globalTranslations.reset}
            onClick={this.handleResetPin}
            testLabel="delete-unassinged-terminals-button"
            toolTipIcon={<ReportProblem />}
            toolTipHeaderText="ALERT"
            disabled={roleAllowed}
            showToolTip
            toolTipMessage={translations.main.contentRestriction.tooltip}
          />
        </Grid>
      </Grid>
    );
  }

  getMoreInfo(roleAllowed) {
    const {
      classifications, configurePmsRoomTypes, errors, hasPms, roomTypes, selectedRoom, translations
    } = this.props;
    const { groups, selectedGroup } = this.state;

    const ableToSetRoomType = !true; // TODO: need to read if room_type is in Integrations.userTypes

    return (
      <Grid container>
        <Grid item>
          <SonifiText
            label={translations.main.classification}
            select
            size="sm"
            defaultValue={selectedRoom.classification}
            items={classifications.map((suggestion) => ({
              id: suggestion,
              value: suggestion,
              label: suggestion,
            }))}
            change={this.handleChange('classification')}
            disabled={roleAllowed}
          />
        </Grid>
        <Grid item>
          <SonifiText
            label={translations.editDialog.pmsId}
            size="sm"
            defaultValue={selectedRoom.pms_id || ''}
            change={this.handleChange('pms_id')}
            error={errors.pms_id && errors.pms_id.length > 0}
            errorText={errors.pms_id}
            disabled={roleAllowed}
          />
        </Grid>
        {roomTypes.length > 0 && <Grid item>
          <SonifiText
            label={translations.editDialog.roomType}
            select
            size="sm"
            defaultValue={selectedRoom.room_type || 'None'}
            items={[{ name: 'None', sequence: -1 }, ...roomTypes]
              .map(({ name }) => ({ id: name, value: name, label: name }))}
            change={this.handleChange('room_type')}
            disabled={roleAllowed || ableToSetRoomType || configurePmsRoomTypes}
          />
        </Grid>
        }
        {hasGroupServiceFeat() === 'true' && groups.length > 0 &&
          <Grid item>
            <SonifiText
              label={translations.editDialog.groupType}
              select
              size="sm"
              defaultValue={groups !== undefined && selectedGroup
                ? groups.find((group) => group.id === selectedGroup.id)
                : { name: 'None' }
              }
              items={groups
                .map((group) => ({
                  id: group.name,
                  label: (group.arrival || group.name === 'None' ? group.name : `${group.name} (expired)`),
                  value: { id: group.id, name: group.name }
                }))}
              change={this.handleGroupChange}
              disabled={(
                !roleAllowed && hasPms
                  ? true
                  : roleAllowed) || groups.length === 1}
            />
          </Grid>
        }
      </Grid>
    );
  }

  render() {
    const { dialogError, loading, selectedRoom, userPermissions } = this.props;
    const { language } = this.state;
    const errorStr = (dialogError) ? `Error! ${dialogError}` : '';

    if (loading) {
      return <SonifiSpinner />;
    }

    const roleAllowed = !checkForSingleUserPermission(ROOM_DETAIL_EDIT, userPermissions);

    return (
      <Grid container>
        <Grid item xs={12}>
          <SonifiError label={errorStr} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between">
            <Grid item>
              {this.getRoomInfo(roleAllowed)}
            </Grid>
            {selectedRoom.content_restriction_pin !== null &&
              <Grid item>
                {this.getContentRestriction()}
              </Grid>
            }
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {this.getMoreInfo(roleAllowed)}
        </Grid>
        <Grid item xs={12}>
          <SonifiDivider />
        </Grid>
        <Grid item xs={12}>
          <GuestStayInfo
            language={language} onChange={this.handleTerminalChange}
            checkboxChange={this.handleCheckboxChange} isOccupied={selectedRoom.is_occupied}
            stayIdProp={(selectedRoom.stay && selectedRoom.stay.property ? selectedRoom.stay.property : '')} />
        </Grid>
        {checkForAtLeastOneUserPermission(TERMINAL_DETAIL_ROLES, userPermissions) &&
          <Fragment>
            <Grid item xs={12}>
              <SonifiDivider />
            </Grid>
            <Grid item xs={12}>
              <RoomTabs />
            </Grid>
          </Fragment>
        }
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  classifications: state.rooms.classifications,
  configurePmsRoomTypes: state.integrations.configurePmsRoomTypes,
  dialogError: state.rooms.dialogError,
  globalTranslations: state.global.translations.defaults,
  hasPms: state.integrations.hasPms,
  loading: state.integrations.loading,
  roomTypes: state.lateCheckout.roomTypes,
  selectedRoom: state.rooms.selectedRoom,
  site: state.rooms.buildings,
  siteGroups: state.groupServices.groups,
  terminals: state.terminals.terminals,
  translations: state.rooms.translations,
  userPermissions: state.global.permissions
});

EditForm.propTypes = {
  classifications: PropTypes.array.isRequired,
  configurePmsRoomTypes: PropTypes.bool,
  dialogError: PropTypes.string,
  dispatch: PropTypes.func,
  errors: PropTypes.object,
  globalTranslations: PropTypes.object,
  hasPms: PropTypes.bool,
  loading: PropTypes.bool,
  roomTypes: PropTypes.array,
  selectedRoom: PropTypes.object.isRequired,
  site: PropTypes.array,
  siteGroups: PropTypes.array,
  terminals: PropTypes.array.isRequired,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(EditForm);
