import { uniqBy } from 'lodash';
import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import { newModel } from '../constants';
import en from '../i18n/en';

const langs = { en };
const initialState = {
  deleteTermModel: null,
  editType: '',
  loading: false,
  maxPages: 0,
  models: [],
  pageSize: 0,
  popupLoading: false,
  saving: false,
  selectedTermModel: -1,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  sorting: false,
  termModelError: false,
  totalItems: 0,
  translations: null
};

export default function termModelReducer(state = initialState, action) {
  const updatedTermModels = [...state.models];
  let miscObj = null;

  switch (action.type) {
    case types.START_MODELS:
      return { ...state, loading: true, models: [] };

    case types.ADD_NEW_TERM_MODEL:
      updatedTermModels.push({ ...newModel });
      return {
        ...state, models: updatedTermModels, selectedTermModel: state.models.length, snackBarMessage: ''
      };

    case types.CLEAR_MODELS:
      return {
        ...state, models: [], maxPages: 0, pageSize: 0, totalItems: 0, sorting: true, snackBarMessage: ''
      };

    case types.FETCH_ALL_MODELS_BEGIN:
      return {
        ...state, selectedTermModel: -1, termModelError: false, snackBarMessage: ''
      };

    case types.FETCH_ALL_MODELS_SUCCESS:
      miscObj = [...state.models, ...action.models.models];
      return {
        ...state,
        models: uniqBy(miscObj, (e) => e.id),
        loading: false,
        maxPages: action.models.page_count,
        pageSize: action.models.page_size,
        totalItems: action.models.total_items,
        sorting: false
      };

    case types.FETCH_ALL_MODELS_FAILURE:
      return {
        ...state, models: [], loading: false, termModelError: true, sorting: false
      };

    case types.UPDATE_SELECTED_TERM_MODEL:
      if (action.index === -1) {
        miscObj = updatedTermModels.filter((word) => word.id !== 'add');
      } else {
        miscObj = updatedTermModels;
      }
      return {
        ...state, selectedTermModel: action.index, models: miscObj
      };

    case types.DELETE_CHECK:
      return { ...state, deleteTermModel: action.termModel };

    case types.DELETE_TERM_MODEL_BEGIN:
      return { ...state, popupLoading: true };

    case types.DELETE_TERM_MODEL_SUCCESS:
      return {
        ...state,
        popupLoading: false,
        selectedTermModel: -1,
        deleteTermModel: null,
        snackBarMessage: `${state.translations.snackbar.deleteSuccess} ${action.id}`,
        snackBarType: SUCCESS
      };

    case types.DELETE_TERM_MODEL_FAILURE:
      return {
        ...state,
        popupLoading: false,
        selectedTermModel: -1,
        deleteTermModel: null,
        snackBarMessage: `${state.translations.snackbar.deleteError} ${action.error}`,
        snackBarType: ERROR
      };

    case types.SAVE_TERM_MODEL_BEGIN:
      return { ...state, saving: true };

    case types.SAVE_TERM_MODEL_RESET:
      return { ...state, saving: false };

    case types.SAVE_TERM_MODEL_SUCCESS:
      return {
        ...state,
        saving: false,
        selectedTermModel: -1,
        snackBarType: SUCCESS,
        snackBarMessage: `${state.translations.snackbar.saveSuccess} ${(action.termId)}`
      };

    case types.SAVE_TERM_MODEL_FAILURE:
      return {
        ...state,
        saving: false,
        snackBarType: ERROR,
        snackBarMessage: `${state.translations.snackbar.saveError} - ${action.error}`
      };

    case types.RESET_SNACKBAR:
      return { ...state, snackBarMessage: '' };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };

    default:
      return state;
  }
}
