import { Dialog, DialogContent } from '@mui/material';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import {
  ROOM_DETAIL_EDIT, ROOM_DETAIL_EDIT_LIMITED, TERMINAL_DETAIL_EDIT,
  TERMINAL_DETAIL_EDIT_LIMITED
} from '../../../constants/roles';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { submitRoomInfo } from '../actions/editRoomActions';
import { filterRooms } from '../actions/filterActions';
import { closeDialog } from '../actions/roomActions';
import { updateSelectedTerminal } from '../actions/terminalActions';
import { isFormValid } from '../utils/index';
import EditForm from './EditForm';

const styles = () => ({
  dialogPaper: {
    minHeight: '90%',
    maxHeight: '90%',
  },
});

class EditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: {}
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.setState({ errors: {} });
      if (!isEmpty(this.props.filterObj)) {
        this.props.dispatch(filterRooms(this.props.filterObj));
      }
      this.props.dispatch(closeDialog());
    }
  }

  onSubmit() {
    this.props.dispatch(updateSelectedTerminal(-1));
    const { integrations, selectedRoom, siteHierarchy, translations } = this.props;

    const validForm = isFormValid(selectedRoom, translations.addDialog.validation, null, siteHierarchy);

    let pmsSite = false;
    if (integrations[0] !== undefined) {
      pmsSite = !(integrations[0].type === 'generator');
    }

    if (Object.keys(validForm).length === 0) {
      this.setState({ errors: {} });

      const submittedRoom = { ...this.props.selectedRoom };
      if (this.props.selectedRoom.room_type === 'None') {
        submittedRoom.room_type = null;
      }

      // Api fails with tags || groups key || content_restriction_pin
      delete submittedRoom.tags;
      delete submittedRoom.content_restriction_pin;

      let newGroupId = null;
      if (submittedRoom.groups !== undefined && submittedRoom.groups !== null) {
        newGroupId = submittedRoom.groups[0].id;
      }
      delete submittedRoom.groups;

      let groupObj = null;
      if (newGroupId) {
        groupObj = {
          group_id: newGroupId,
          integration_type: pmsSite ? 'pms' : 'generator'
        };
        submittedRoom.group = groupObj;
      }

      this.props.dispatch(submitRoomInfo(
        submittedRoom,
        [...this.props.terminals]
      ));

      return;
    } else {
      this.setState({ errors: validForm });
    }
  }

  render() {
    const {
      classes, dialogLoading, globalTranslations, loading,
      open, selectedRoom, translations, userPermissions
    } = this.props;

    return (
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        fullWidth
        maxWidth="xl"
        onClose={this.onClose}
        open={open}
      >
        <SonifiModalHeader
          disabled={dialogLoading}
          header={`${translations.editDialog.editRoom} ${selectedRoom.id || ''}`}
          onCancel={this.onClose}
          onSubmit={this.onSubmit}
          label={globalTranslations.save}
          onlyClose={!checkForAtLeastOneUserPermission(
            [ROOM_DETAIL_EDIT, ROOM_DETAIL_EDIT_LIMITED, TERMINAL_DETAIL_EDIT, TERMINAL_DETAIL_EDIT_LIMITED],
            userPermissions
          ) && !loading && !dialogLoading}
        />
        <DialogContent>
          {dialogLoading
            ? <SonifiSpinner />
            : <EditForm errors={this.state.errors} />
          }
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  dialogLoading: state.rooms.dialogLoading,
  filterObj: state.rooms.filterObj,
  globalTranslations: state.global.translations.defaults,
  integrations: state.integrations.integrations,
  loading: state.integrations.loading,
  selectedRoom: state.rooms.selectedRoom,
  siteHierarchy: state.rooms.buildings,
  terminals: state.terminals.terminals,
  translations: state.rooms.translations,
  userPermissions: state.global.permissions
});

EditDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dialogLoading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func,
  filterObj: PropTypes.object,
  globalTranslations: PropTypes.object,
  integrations: PropTypes.array,
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  selectedRoom: PropTypes.object.isRequired,
  siteHierarchy: PropTypes.array,
  terminals: PropTypes.array,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(EditDialog, styles, { withTheme: true }));
