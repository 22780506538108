import React from 'react';
import { Check, Remove, RotateLeft } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import * as XLSX from 'xlsx';
import { padSiteNumber } from '../../../utils';

export function getIcon(status) {
  let tempIcon = <Remove />;
  if (status === 'In Progress') {
    tempIcon = <RotateLeft />;
  } else if (status === 'Complete') {
    tempIcon = <Check />;
  }
  return <Tooltip title={`${status}`}>
    {tempIcon}
  </Tooltip>;
}

export function parseSites(csvInfo) {
  let XLRowObject = null;
  csvInfo.SheetNames.forEach((sheetName) => {
    XLRowObject = XLSX.utils.sheet_to_row_object_array(csvInfo.Sheets[sheetName]);
  });

  const arr2 = XLRowObject.map((v) => (
    { id: padSiteNumber(v['Salesforce Account']), name: v['Customer Full Name'], found: false, platforms: [] }
  ));
  return arr2;
}
