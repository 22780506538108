import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import SonifiLabel from '../../../containers/SonifiLabel';

export default class TableHeader extends Component {
  render() {
    const { translations } = this.props;
    return <Grid container>
      <Grid item xs={1}>
        <Grid container>
          <Grid item xs={6} />
          <Grid item xs={6} />
        </Grid>
      </Grid>
      <Grid item xs={1}>
        <SonifiLabel boldLabel={translations.channel} />
      </Grid>
      <Grid item xs={2}>
        <SonifiLabel boldLabel={translations.type} />
      </Grid>
      <Grid item xs={3}>
        <SonifiLabel boldLabel={translations.displayName} />
      </Grid>
      <Grid item xs={3}>
        <SonifiLabel boldLabel={translations.source} />
      </Grid>
      <Grid item xs={1}>
        <SonifiLabel boldLabel={translations.status} />
      </Grid>
      <Grid item xs={1} />
    </Grid>;
  }
}

TableHeader.propTypes = {
  translations: PropTypes.object
};
