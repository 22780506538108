import { debounce, Grid } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { KEYBOARD_DELAY } from '../../../../constants/keyCodes';
import SonifiDateTimePicker from '../../../../containers/SonifiDateTimePicker';
import SonifiText from '../../../../containers/SonifiText';
import { pmsTypes } from '../constants';


const DEFAULT_TIMEZONE = 'America/Chicago';

/**
 * PmsFilter component used for the filter options on the PMS logs page.
 */
class PmsFilter extends Component {
  DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

  constructor(props) {
    super(props);
    this.state = {
      pmsType: '',
      roomNum: '',
      start: null,
      end: null,
    };
  }

  componentDidMount() {
    this.setState({ pmsType: pmsTypes.length > 0 ? pmsTypes[0]?.value : 'all' });
    this.debouncedLoadMoreData = debounce(this.filterPmsLogs, KEYBOARD_DELAY);
  }

  /**
   * Filter the current PMS log data based on the this component'
   * state and passed in filterFunc prop.
   *
   * @returns {void}
   */
  filterPmsLogs() {
    const { filterFunc } = this.props;

    filterFunc(this._createFilterPayload());
  }

  /**
   * @typedef {Object} FilterPayload
   *
   * @property {string} [start] - An ISO8601 datetime string. Retrieve PMS logs from
   *     start to now or to end if present.
   * @property {string} [end] - An ISO8601 datetime string. Retrieve all PMS logs up
   *     to end or between start and end if start is present.
   * @property {string} [event_type] - The PMS log type to filter for.
   * @property {string} [room] - Display PMS logs for only this room.
   */

  /**
   * Create a filter object payload.
   *
   * @returns {FilterPayload} A FilterPayload object to be used
   *     with the filterFunc prop.
   */
  _createFilterPayload() {
    const { timezone } = this.props;
    const { pmsType, roomNum, start, end } = this.state;
    const filterObj = {};

    if (start !== null && typeof start !== 'undefined' && start !== '') {
      filterObj.start = moment.tz(start, timezone).format();
    }

    if (end !== null && typeof end !== 'undefined' && end !== '') {
      filterObj.end = moment.tz(end, timezone).format();
    }

    if (
      pmsType !== null &&
      typeof pmsType !== 'undefined' &&
      pmsType !== 'all' &&
      pmsType !== ''
    ) {
      filterObj.event_type = pmsType;
    }

    if (roomNum !== null && typeof roomNum !== 'undefined' && roomNum !== '') {
      filterObj.room = roomNum;
    }

    return filterObj;
  }

  render() {
    const { loading, translations } = this.props;
    const { roomNum, pmsType, start, end } = this.state;

    return (
      <Grid container style={{ justifyContent: 'flex-end' }}>
        <Grid item xs={3}>
          <SonifiDateTimePicker
            change={this.handleDatetimeChange('start')}
            defaultValue={start}
            disabled={loading}
            disablePast={false}
            inputProps={{
              'data-testid': 'startDatetimePicker',
            }}
            label={translations.startTime}
            size="percent"
          />
        </Grid>
        <Grid item xs={3}>
          <SonifiDateTimePicker
            change={this.handleDatetimeChange('end')}
            defaultValue={end}
            disabled={loading}
            disablePast={false}
            inputProps={{
              'data-testid': 'endDatetimePicker',
            }}
            label={translations.endTime}
            size="percent"
          />
        </Grid>
        <Grid item xs={3}>
          <SonifiText
            change={this.handleTextFieldChange('pmsType')}
            defaultValue={pmsType}
            disabled={loading}
            inputProps={{
              'data-testid': 'pmsTypeTextField',
            }}
            items={pmsTypes}
            label={translations.type}
            select={true}
            size="percent"
          />
        </Grid>
        <Grid item xs={3}>
          <SonifiText
            defaultValue={roomNum}
            inputProps={{
              'data-testid': 'roomNumTextField',
            }}
            label={translations.room}
            change={this.handleTextFieldChange('roomNum')}
            size="percent"
          />
        </Grid>
      </Grid>
    );
  }

  /**
   * Given a datetime state variable name, handle updating that value
   * as the datetime picker value for it changes.
   *
   * @param {string} name - The name of the state variable containing the datetime string.
   * @returns {function} A function to execute to update the internal state.
   */
  handleDatetimeChange(name) {
    return (value) => {
      const datetime = moment(value, true);

      this.updateState(
        name,
        datetime.isValid() ? datetime.format(this.DATETIME_FORMAT) : null
      );
    };
  }

  /**
   * Given a text state variable name, handle updating that value
   * as the text field value for it changes.
   *
   * @param {string} name - The name of the state variable containing the text data.
   * @returns {function} A function to execute to update the internal state.
   */
  handleTextFieldChange(name) {
    return ({ target: { value } }) => {
      this.updateState(name, value);
    };
  }

  /**
   * Update this component's state and then load more pms logs.
   *
   * @param {string} name - The name of the state key.
   * @param {any} value - The value to set the state to.
   *
   * @returns {void}
   */
  updateState(name, value) {
    this.setState({ [name]: value }, this.debouncedLoadMoreData);
  }
}

const mapStateToProps = (state) => ({
  loading: state.pmsLogging.loading,
  timezone: state?.global?.siteInfo?.location?.timezone ?? DEFAULT_TIMEZONE,
  translations: state.pmsLogging.translations.grid,
});

PmsFilter.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  filterFunc: PropTypes.func,
  timezone: PropTypes.string.isRequired,
  translations: PropTypes.object,
};

export { PmsFilter, mapStateToProps, DEFAULT_TIMEZONE };
export default connect(mapStateToProps)(PmsFilter);
