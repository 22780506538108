import { LOAD_LANGUAGE_LITERALS } from '../../../actions/globalActionTypes';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';

const langs = { en };
const initialState = {
  auditInfo: [],
  loading: false,
  auditDialogIndex: -1,
  maxPages: 1,
  snackbarMessage: '',
  snackbarType: SUCCESS,
  translations: null
};

export default function auditReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_AUDIT_INFO_BEGIN:
      return {
        ...state,
        auditInfo: action.resetAuditInfo ? [] : [...state.auditInfo],
        loading: action.resetAuditInfo,
        snackbarMessage: ''
      };

    case types.FETCH_AUDIT_INFO_SUCCESS:
      return {
        ...state,
        auditInfo: state.auditInfo.concat(action.info),
        maxPages: action.maxPages,
        loading: false
      };

    case types.FETCH_AUDIT_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        snackbarMessage: `${state.translations.snackbar.fetchError} - (${action.error})`,
        snackbarType: ERROR
      };

    case types.SHOW_AUDIT_INFO:
      return {
        ...state,
        auditDialogIndex: action.index
      };

    case types.UPDATE_AUDIT_SNACK_BAR:
      return {
        ...state,
        snackbarMessage: action.snackbarMessage,
        snackbarType: action.snackbarType ? action.snackbarType : state.snackbarType
      };

    case LOAD_LANGUAGE_LITERALS:
      return { ...state, translations: getTranslationObject(action.lang, langs) };
    default:
      return state;
  }
}
