import { Divider, Menu, MenuItem } from '@mui/material';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { updateDevProd } from '../../actions/globalActions';
import { USER_PROFILE_ROLES, USER_TOKEN_ROLES } from '../../constants/roleGroups';
import { TERMINAL_DETAIL_EDIT } from '../../constants/roles';
import { getTokenExpiration, getTokenFromSession, logOut, refreshCognitoTokens } from '../../utils';
import { isTesting } from '../../utils/api';
import { checkForAtLeastOneUserPermission, checkSingleUserPermission } from '../../utils/rolesUtil';
import ProfileSettings from '../Profile/components/ProfileSettings';

class TopMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayModal: false,
      rememberedDevice: false,
      nonSSO: false
    };

    this.handleProfileOpen = this.handleProfileOpen.bind(this);
    this.handleProfileClose = this.handleProfileClose.bind(this);
  }

  componentDidMount() {
    const authDomain = process.env.REACT_APP_COGNITO_DOMAIN_PREFIX;
    if (authDomain.includes('signin')) {
      this.getCurrentAuthUser();
      this.setState({ nonSSO: true });
    }
  }

  async getCurrentAuthUser() {
    try {
      const currUser = await Auth.currentAuthenticatedUser();
      currUser.getCachedDeviceKeyAndPassword();
      this.setState({
        rememberedDevice: currUser.deviceKey && currUser.deviceKey.length > 2
      });
    } catch(err) {
      console.log('TopMenu:Error', err);
    }
  }

  isProdFlipped = () => {
    this.props.dispatch(updateDevProd());
    this.props.close();
  };

  handleShowToken = () => {
    const token = getTokenFromSession();
    const exp = getTokenExpiration();

    console.log(token);
    console.log(exp);

    const expDate = new Date(0);
    expDate.setUTCSeconds(exp);

    // eslint-disable-next-line no-alert
    prompt(`Token Expires:\n${expDate.toString()}`, token);
    this.props.close();
  };

  handleShowVersion = () => {
    // For local debugging, this value is set in the .env file,
    // otherwise it is packaged and exported within the build process in .gitlab-ci.yml
    // eslint-disable-next-line no-alert
    alert(`Version: ${process.env.REACT_APP_CCA_VERSION}`);
    this.props.close();
  };

  handleLogout = () => {
    this.props.dispatch({ type: 'USER_LOGGED_OUT' });
    logOut();
  };

  handleRefresh = () => {
    refreshCognitoTokens().then((data) => {
      console.log('REFRESH SUCCESS: ', data);
    }).catch((reason) => {
      console.log('REFRESH FAILED: ', reason);
    });
  };

  handleProfileOpen() {
    this.setState({ displayModal: true });
    this.props.close();
  }

  handleProfileClose(event, reason) {
    if (reason === undefined) {
      this.setState({ displayModal: false });
    }
  }

  handleForgetDevice = () => {
    this.props.close();
    Auth.forgetDevice()
      .then((cogUser) => {
        console.log('Device forgot', cogUser);
        this.setState({ rememberedDevice: false });
      });
  };

  render() {
    const topMenu = this.props.translations.topMenu;
    const links = [];
    const { isProd, userPermissions } = this.props;
    const { displayModal } = this.state;

    if (this.state.nonSSO && checkForAtLeastOneUserPermission(USER_PROFILE_ROLES, userPermissions)) {
      // links.push(createLink('Profile', 'profile', Profile));
      links.push(
        <MenuItem key="myAccount" onClick={this.handleProfileOpen}>
          {topMenu.myAccount}
        </MenuItem>
      );
    }

    if (!isTesting(false)) {
      links.push(
        <MenuItem key="isProduction" onClick={this.isProdFlipped}>
          {isProd ? topMenu.dev : topMenu.prod}
        </MenuItem>
      );
    }

    // if (!isProduction(this.props.isProd) && checkForAtLeastOneUserPermission(USER_PROFILE_ROLES, userPermissions)) {
    //   links.push(<MenuItem key="myAccount" onClick={this.props.close}>{topMenu.myAccount}</MenuItem>);
    // }

    if (checkForAtLeastOneUserPermission(USER_TOKEN_ROLES, userPermissions)) {
      links.push(<MenuItem key="token" onClick={this.handleShowToken}>{topMenu.token}</MenuItem>);
      links.push(<MenuItem key="refreshToken" onClick={this.handleRefresh}>{topMenu.refreshToken}</MenuItem>);
    }

    if (checkSingleUserPermission(TERMINAL_DETAIL_EDIT, userPermissions)) {
      links.push(<MenuItem key="ProvisionTerms" onClick={this.props.close}
        component={Link} to="/device/provision">{topMenu.provision}</MenuItem>);
    }

    if (this.state.nonSSO && this.state.rememberedDevice) {
      links.push(<Divider key="d1" />);
      links.push(<MenuItem key="forget-device" onClick={this.handleForgetDevice}>{topMenu.forgetDevice}</MenuItem>);
    }
    links.push(<Divider key="d2" />);
    links.push(<MenuItem key="version" onClick={this.handleShowVersion}>{topMenu.version}</MenuItem>);
    links.push(<Divider key="d3" />);
    links.push(<MenuItem key="logout" onClick={this.handleLogout}>{topMenu.logout}</MenuItem>);
    return (
      <Fragment>
        <Menu
          id="simple-menu"
          anchorEl={this.props.anchorEl}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.props.open}
          onClose={this.props.close}
        >
          {links}
        </Menu>
        {displayModal && <ProfileSettings
          display={displayModal}
          closeHandler={this.handleProfileClose}
        />
        }
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => ({
  change: state.global.change,
  currentLanguage: state.global.currentLanguage,
  isProd: state.global.isProd,
  translations: state.global.translations,
  userPermissions: state.global.permissions
});

TopMenu.propTypes = {
  anchorEl: PropTypes.object,
  change: PropTypes.bool,
  close: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string,
  dispatch: PropTypes.func,
  isProd: PropTypes.bool,
  open: PropTypes.bool,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(TopMenu);
