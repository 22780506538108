import { Divider } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = () => ({
  divide: {
    margin: '10px 0'
  },

});

export class SonifiDivider extends Component {
  render() {
    const { classes } = this.props;

    return (
      <Divider className={classes.divide} />);
  }
}
SonifiDivider.propTypes = {
  classes: PropTypes.object.isRequired
};
export default withStyles(SonifiDivider, styles);
