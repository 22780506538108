import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel } from '../../../../containers';
import { BEM, CLONE, SAMSUNG } from '../../constants/TermFileConstants';
import BaseFirmwareGrid from '../BaseFirmwareGrid';
import EditSamsung from './EditSamsung';
import SamsungRow from './SamsungRow';

class SamsungFirmwareGrid extends BaseFirmwareGrid {
  getMake() {
    return SAMSUNG;
  }

  handleCheckChange = () => ({ target: { checked } }) => {
    this.updateMaintWindow('samsungEnabled', checked);
  };

  getHeaderInfo() {
    const { samsungFirmware, translations } = this.props;
    return [
      { id: 'id', sortable: samsungFirmware.length > 1, numeric: false, label: `${translations.id}` },
      {
        id: BEM,
        sortable: samsungFirmware.length > 1,
        numeric: false,
        label: `${translations.sonifi.firmware}`
      },
      { id: CLONE, sortable: samsungFirmware.length > 1, numeric: false, label: `${translations.samsung.clone}` },
      { id: 'version', sortable: samsungFirmware.length > 1, numeric: true, label: `${translations.version}` }
    ];
  }

  getChecked() {
    return this.props.samsungEnabled;
  }

  getPopupBox() {
    const { canEdit, samsungIndex } = this.props;
    return samsungIndex !== -1 && <EditSamsung canEdit={canEdit} />;
  }

  getTableBody() {
    const { canEdit, samsungFirmware, translations } = this.props;
    return <Fragment>
      {!samsungFirmware || samsungFirmware.length === 0
        ? <TableRow>
          <TableCell colSpan={3}>
            <SonifiLabel error label={translations.samsung.errors.noTerminals} />
          </TableCell>
        </TableRow >
        : samsungFirmware.map((option, i) => (
          <SamsungRow key={i} rowIndex={i} canEdit={canEdit} />
        ))}
    </Fragment>;
  }
}

const mapStateToProps = (state) => ({
  changed: state.termFile.changed,
  lgEnabled: state.termFile.lgEnabled,
  samsungEnabled: state.termFile.samsungEnabled,
  samsungFirmware: state.termFile.samsungFirmware,
  samsungIndex: state.termFile.samsungIndex,
  samsungLoading: state.termFile.samsungLoading,
  sonifiEnabled: state.termFile.sonifiEnabled,
  termOptLoading: state.termFile.termOptLoading,
  translations: state.termFile.translations.grid
});

SamsungFirmwareGrid.propTypes = {
  canEdit: PropTypes.bool,
  changed: PropTypes.bool,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  lgEnabled: PropTypes.bool,
  lgFirmware: PropTypes.array,
  lgIndex: PropTypes.number,
  lgLoading: PropTypes.bool,
  samsungEnabled: PropTypes.bool,
  samsungLoading: PropTypes.bool,
  sonifiEnabled: PropTypes.bool,
  sonifiLoading: PropTypes.bool,
  tabValue: PropTypes.string,
  termOptLoading: PropTypes.bool,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(SamsungFirmwareGrid);
