import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiPopover from '../../../containers/SonifiPopover';
import { getGroupSites, updateDeleteRow, updateSelectedGroup } from '../actions/groupActions';

class GroupRow extends Component {
  handleEditDialog() {
    const { dispatch, group } = this.props;
    dispatch(updateSelectedGroup(group, 'edit'));
    dispatch(getGroupSites(group.id));
  }

  handleDeleteDialog() {
    const { dispatch, group } = this.props;
    dispatch(updateDeleteRow(group));
  }

  handleReadDialog() {
    const { dispatch, group } = this.props;
    dispatch(updateSelectedGroup(group, 'read'));
    dispatch(getGroupSites(group.id));
  }

  render() {
    const { canEdit, group, key, globalTranslations } = this.props;

    if (!group.description) {
      return <Fragment />;
    }

    return (
      <SonifiPopover
        deleteDisabled={group.user_count > 0}
        deleteFunc={this.handleDeleteDialog.bind(this)}
        deleteTitle={globalTranslations.delete}
        editFunc={this.handleEditDialog.bind(this)}
        editTitle={globalTranslations.edit}
        functionOneFunc={this.handleReadDialog.bind(this)}
        functionOneTitle={globalTranslations.view}
        hideDelete={!canEdit}
        hideEdit={!canEdit}
        key={key}
        showFunctionOne={true}
        tableRow={
          <TableCell>{group.description}</TableCell>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults
});

GroupRow.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  group: PropTypes.object,
  key: PropTypes.number,
  globalTranslations: PropTypes.object
};

export default connect(mapStateToProps)(GroupRow);
