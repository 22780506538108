import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import { cloudStreamDialog } from '../actions/cloudStreamAction';

class CloudStreamRow extends Component {
  handleEditDialog(type) {
    this.props.dispatch(cloudStreamDialog(this.props.index, type));
  }

  /* eslint-disable camelcase */
  render() {
    const { globalTranslations, cloudChans, index } = this.props;
    const { description, entry, id, name } = cloudChans[index];

    return (
      <SonifiPopover
        // editFunc={this.handleEditDialog.bind(this, 'edit')}
        // editTitle={globalTranslations.edit}
        functionOneFunc={this.handleEditDialog.bind(this, 'read')}
        functionOneTitle={globalTranslations.view}
        hideDelete={true}
        hideEdit={true}
        showFunctionOne={true}
        tableRow={
          <Fragment>
            <TableCell component="th" scope="row">
              <SonifiLabel label={id} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={name} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={entry} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={description} />
            </TableCell>
          </Fragment >
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  cloudChans: state.cloudStream.cloudChans,
  globalTranslations: state.global.translations.defaults
});

CloudStreamRow.propTypes = {
  cloudChans: PropTypes.array.isRequired,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  index: PropTypes.number.isRequired
};

export default connect(mapStateToProps)(CloudStreamRow);
