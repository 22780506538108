import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

class LocationCard extends Component {
  render() {
    const { helperClass, getItem, translations, selectedTerm } = this.props;
    return (
      <Grid container justifyContent="space-between" className={helperClass.card} >
        {getItem(translations.terminalInfo.room, selectedTerm.room_id || '')}
        {getItem(translations.terminalInfo.unit, selectedTerm.unit)}
        {getItem(translations.terminalInfo.building, selectedTerm.building)}
        {getItem(translations.terminalInfo.floor, selectedTerm.floor)}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.termGrid.translations,
  changed: state.termGrid.changed,
  selectedTerm: state.termGrid.selectedTerm
});

LocationCard.propTypes = {
  helperClass: PropTypes.object,
  getItem: PropTypes.func,
  selectedTerm: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(LocationCard);
