import { ROOM_RESOURCE } from '../../../constants/apiEndpoints';
import { getSiteObjectPromise } from '../../../utils/api';
import { findArrayIdByKey } from '../../../utils/index';
import { textHelper } from '../../../utils/textUtil';

export function isFormValid(room, validation, range, siteHierarchy) {
  const errors = checkRoom(room, validation, range);

  if (room.classification.length < 1) {
    errors.classification = validation.classification;
  }

  if (!room.building) {
    errors.building = validation.building;
  } else if (!room.floor) {
    errors.floor = validation.floor;
  } else {
    const buildingId = findArrayIdByKey(siteHierarchy, 'name', room.building);
    if (buildingId === null || buildingId === undefined) {
      errors.hierarchy = validation.buildingFloor;
    } else {
      const floorId = findArrayIdByKey(siteHierarchy[buildingId].floors, 'name', `${room.floor}`);
      if (floorId === null || floorId === undefined) {
        errors.floor = validation.floor;
      }
    }
  }

  return errors;
}

export function checkRoom(room, validation, range) {
  const errors = {};
  if (room.id.length < 1) {
    errors.id = validation.roomNumber;
  } else if (textHelper(room.id)) {
    errors.id = validation.poundPercentage;
  }

  if (!room.pms_id && room.building && room.floor) {
    errors.pms_id = validation.pms_id;
  }

  if (range && room.rangeEnd.length < 1) {
    errors.end = validation.rangeEnd;
  } else if (
    range && (textHelper(room.rangeEnd))
  ) {
    errors.end = validation.poundPercentage;
  } else if (
    range && (parseInt(room.id, 10) > parseInt(room.rangeEnd, 10))) {
    errors.end = validation.rangeEndStart;
  }

  if (room.editRoomNum !== undefined) {
    if (room.editRoomNum.length < 1) {
      errors.id = validation.roomNumber;
    } else if (textHelper(room.editRoomNum)) {
      errors.id = validation.poundPercentage;
    }
  }
  return errors;
}

export function checkForDuplicateRooms(roomsToParse) {
  const duplicateRoom = [];

  // const roomsToParse = (bRange ? range(room.id, room.rangeEnd) : [room.id]);

  console.log('checkForDuplicateRooms:roomsToParse', roomsToParse);

  for (let i = 0; i < roomsToParse.length; i++) {
    console.log('roomid', roomsToParse[i]);
    duplicateRoom.push(
      getSiteObjectPromise(ROOM_RESOURCE, `${roomsToParse[i]}`)
        .then(() => ({
          id: roomsToParse[i],
          duplicate: true
        })).catch(() => ({
          id: roomsToParse[i],
          duplicate: false
        }))
    );
  }
  return duplicateRoom;
}
