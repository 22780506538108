import { find, map, orderBy } from 'lodash';
import { padSiteNumber } from '../../../utils';

export function exampleCsv(productParents) {
  let csvText = 'Product Parent ids and names \r\n';
  const arr = map(productParents, (element) => `${element.id} : ${element.name}`);
  csvText += arr.join('\r\n');
  csvText += '\r\nautoUpdate should be either true (automatic) or false (manual)';
  csvText += '\r\nsite,virtOS,autoUpdate,softwareChannel,system,productParent,version';
  return csvText;
}

export function getTableHeaders(translations) {
  return [
    { id: '', sortable: false, numeric: false, label: '' },
    { id: 'site', sortable: false, numeric: false, label: translations.site },
    // { id: 'virtOS', sortable: false, numeric: false, label: translations.virtOS },
    { id: 'autoUpdate', sortable: false, numeric: false, label: translations.autoUpdate },
    { id: 'softwareChannel', sortable: false, numeric: false, label: translations.softwareChannel },
    { id: 'systemId', sortable: false, numeric: false, label: translations.system },
    { id: 'productParent', sortable: false, numeric: false, label: translations.productParent },
    { id: 'version', sortable: false, numeric: false, label: translations.version }
  ];
}

export function getColumnNames() {
  return [
    { id: 'id', type: 'label' },
    // { id: 'virtos_version', type: 'label' },
    { id: 'automatic_updates', type: 'label' },
    { id: 'software_channel', type: 'label' },
    { id: 'system_id', type: 'label' },
    { id: 'product_code', type: 'label' },
    { id: 'version', type: 'label' }
  ];
}

export function findProductParentId(rowProductParent, productParents) {
  let ppId = find(productParents, { id: rowProductParent.trim() });
  if (ppId === undefined) {
    ppId = find(productParents, { name: rowProductParent.trim() });
  }

  if (ppId === undefined) {
    ppId = find(productParents, { title: rowProductParent.trim() });
  }
  return ppId === undefined ? { name: rowProductParent } : ppId;
}

export function parseSoftware(rInfo, translations) {
  const roomInfo = getRoomArray(rInfo);
  const sites = [];
  const titles = [];

  if (roomInfo.length < 1) {
    return { sites, sitesError: true };
  }

  for (let i = 0; i < roomInfo[0].length; i++) {
    titles.push(roomInfo[0][i].trim().toUpperCase());
  }

  const siteNum = getPlaceInLine(titles, 'siteId', 'site');
  const systemId = getPlaceInLine(titles, 'systemId', 'system');
  // const virtOS = getPlaceInLine(titles, 'virtOS', 'virt');
  const pp = getPlaceInLine(titles, 'productParent', 'productCode');
  const release = getPlaceInLine(titles, 'releaseVersion', 'version');
  const auto = getPlaceInLine(titles, 'autoUpdate', 'auto');
  const softwareChannel = getPlaceInLine(titles, 'softwareChannel', 'channel');
  const host = getPlaceInLine(titles, 'host', 'vmcHost');

  for (let i = 1; i < roomInfo.length; i++) {
    let siteId = getInfo(siteNum, roomInfo[i]);
    if (siteId && siteId !== '' && siteId !== -1) {
      siteId = padSiteNumber(siteId);
    }

    // If file has any extra lines, ignore them
    if (siteId === '') {
      continue;
    }

    const autoUpdate = getInfo(auto, roomInfo[i]).toLowerCase();
    const canAutoUpdate = autoUpdate === 'true' || autoUpdate === 'automatic';

    const site = {
      automatic_updates: (autoUpdate === '' || autoUpdate === null) ? '' : canAutoUpdate ? 'automatic' : 'manual',
      allowSave: true,
      id: siteId,
      product_code: getInfo(pp, roomInfo[i]),
      version: canAutoUpdate ? null : getInfo(release, roomInfo[i]),
      software_channel: getInfo(softwareChannel, roomInfo[i]),
      host: getInfo(host, roomInfo[i]),
      system_id: getInfo(systemId, roomInfo[i]),
      // virtos_version: getInfo(virtOS, roomInfo[i])
    };

    sites.push(site);
  }

  return {
    sites: orderBy(sites, ['id']),
    tableHeader: getTableHeaders(translations),
    columnNames: getColumnNames(),
  };
}

function getInfo(roomNumber, roomInfo) {
  if (roomNumber >= roomInfo.length || roomNumber === -1) {
    return '';
  }

  if (roomInfo[roomNumber].trim().length > 0) {
    return roomInfo[roomNumber].trim();
  }

  return '';
}

function getRoomArray(roomInfo) {
  const channelInfo = [...roomInfo];
  let findStartOfChannelInfo = true;

  while (findStartOfChannelInfo && channelInfo.length > 0) {
    const chanRow = channelInfo[0].map((i) => i.trim().toLowerCase());
    if (
      (chanRow.includes('sitenumber') || chanRow.includes('siteid') || chanRow.includes('site')) &&
      (chanRow.includes('productparent') || chanRow.includes('productcode') || chanRow.includes('systemid') ||
        chanRow.includes('system') || chanRow.includes('version') || chanRow.includes('releaseversion') ||
        chanRow.includes('autoupdate') || chanRow.includes('auto') || chanRow.includes('softwarechannel') ||
        chanRow.includes('channel'))
    ) {
      findStartOfChannelInfo = false;
    } else {
      channelInfo.shift();
    }
  }
  return channelInfo;
}

function getPlaceInLine(a, first, second) {
  let idx = a.indexOf(first.trim().toUpperCase());
  if (second && idx === -1) {
    idx = a.indexOf(second.trim().toUpperCase());
  }
  return idx;
}
