import { TableCell } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { EDIT, READ } from '../../../constants/constants';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiPopover from '../../../containers/SonifiPopover';
import { getNameFromObjectArray } from '../../../utils';
import { deleteCheckGroup, updateSelectedGroup } from '../actions/groupServicesActions';

class GroupRow extends Component {
  handleEditDialog(type) {
    this.props.dispatch(updateSelectedGroup(this.props.group, type));
  }

  handleDeleteDialog() {
    const { dispatch, group } = this.props;
    dispatch(deleteCheckGroup(group));
  }

  render() {
    const {
      canEdit, globalTranslations, group, key, menusets
    } = this.props;
    const {
      arrival, departure, menuset, name, type
    } = group;

    return (
      <SonifiPopover

        // TODO: Enable delete tooltip once functionality is in place
        // deleteDisabled={!canEdit}
        deleteDisabled={true}
        deleteFunc={this.handleDeleteDialog.bind(this)}
        deleteTitle={globalTranslations.delete}
        editDisabled={!canEdit}
        editFunc={this.handleEditDialog.bind(this, EDIT)}
        editTitle={globalTranslations.edit}
        functionOneFunc={this.handleEditDialog.bind(this, READ)}
        functionOneTitle={globalTranslations.view}
        hideDelete={!canEdit}
        hideEdit={!canEdit}
        key={key}
        showFunctionOne={true}
        tableRow={
          <Fragment>
            <TableCell component="th" scope="row" style={{ maxWidth: '100px' }}>
              <SonifiLabel label={`${name}`} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={moment(arrival).format('MM/DD/YYYY h:mm a')} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={moment(departure).format('MM/DD/YYYY h:mm a')} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={`${getNameFromObjectArray(menusets, menuset === null ? 0 : menuset)}`} />
            </TableCell>
            <TableCell component="th" scope="row">
              <SonifiLabel label={type.charAt(0).toUpperCase() + type.slice(1)} />
            </TableCell>
          </Fragment >
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  globalTranslations: state.global.translations.defaults,
  menusets: state.termGrid.menusets
});

GroupRow.propTypes = {
  canEdit: PropTypes.bool,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  group: PropTypes.object,
  key: PropTypes.number,
  menusets: PropTypes.array.isRequired
};

export default connect(mapStateToProps)(GroupRow);

