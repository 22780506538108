import { Button, Divider, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import { getProvisionSiteObjectPromise } from '../../actions/globalActions';
import {
  HIGH_HTTP, LOW_HTTP, ROOM_RESOURCE, TERMINAL_OPTIONS, TERMINAL_RESOURCE
} from '../../constants/apiEndpoints';
import SonifiError from '../../containers/SonifiError';
import SonifiLabel from '../../containers/SonifiLabel';
import SonifiSpinnerWithMessage from '../../containers/SonifiSpinnerWithMessage';
import SonifiText from '../../containers/SonifiText';
import SonifiTextAsync from '../../containers/SonifiTextAsync';
import { findObjectByKey, getValueFromSession, removeSessionItem } from '../../utils/';
import { getObjectPromise, getSiteObjectPromise, putObjectPromise } from '../../utils/api';

const styles = (theme) => ({
  padding: {
    padding: '1rem'
  },
  button: {
    marginRight: theme.spacing(),
    color: theme.palette.primary.cancelBtn
  },
  backButton: {
    'marginRight': theme.spacing(),
    'backgroundColor': theme.palette.primary.main,
    'color': theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.primary.hoverColor,
    }
  },
  instructions: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
});

function getStepContent(step, state, handleChange, createFilterObj, createDisplayObj) {
  switch (step) {
    case 0:
      return (
        <SonifiTextAsync
          dataName="rooms"
          defaultValue={state.room}
          displayFunc={createDisplayObj}
          filterFunc={createFilterObj}
          onSelect={handleChange('room')}
          placeholder={(state.room) ? state.room : 'Please select a room'}
          resource="rooms"
          siteId={state.site}
          siteObj={true}
          type="room"
        />
      );
    case 1:
      return (
        <Grid container>
          <Grid item xs={12}>
            <SonifiText
              label="Location"
              select
              items={state.roomTermLocations.map((suggestion) => ({
                id: suggestion.id,
                value: suggestion.id,
                label: suggestion.name,
              }))}
              defaultValue={`${state.termLocation}`}
              change={handleChange('termLocation')}
            />
          </Grid>
          <Grid item xs={12}><br /></Grid>
          <Grid item xs={12}>
            <SonifiText
              label="Channel Lineup"
              select
              items={state.siteOptions.lineups.map((suggestion) => ({
                id: suggestion.id,
                value: suggestion.id,
                label: suggestion.name,
              }))}
              defaultValue={`${state.termLineup}`}
              change={handleChange('termLineup')}
            />
          </Grid>
          <Grid item xs={12}><br /></Grid>
          <Grid item xs={12}>
            <SonifiText
              label="Menuset"
              select
              items={state.siteOptions.menusets.map((suggestion) => ({
                id: suggestion.id,
                value: suggestion.id,
                label: suggestion.name,
              }))}
              defaultValue={`${state.termMenuSet}`}
              change={handleChange('termMenuSet')}
            />
          </Grid>
        </Grid >
      );
    case 2:
      return (
        <Fragment>
          <Grid item>
            <SonifiLabel boldLabel="Location:"
              label={`${findObjectByKey(state.roomTermLocations, 'id', state.termLocation).name}`} />
          </Grid>
          <Grid item>
            <SonifiLabel boldLabel="Menuset:"
              label={`${findObjectByKey(state.siteOptions.menusets, 'id', state.termMenuSet).name}`} />
          </Grid>
          <Grid item>
            <SonifiLabel boldLabel="Channel Lineup:"
              label={`${findObjectByKey(state.siteOptions.lineups, 'id', state.termLineup).name}`} />
          </Grid>
        </Fragment>
      );
    default:
      return 'Unknown step';
  }
}

class Mobile extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      activeStep: 0,
      id: null,
      room: null,
      rooms: null,
      site: null,
      siteLocation: null,
      siteName: null,
      siteOptions: null,
      spinner: true,
      termAdded: null,
      termLineup: null,
      termLocation: null,
      termMenuSet: null
    };
  }

  // function for filter async
  createDisplayObj = (site) => ({
    value: site,
    label: `${site.id} ${(site.name ? `: ${site.name}` : '')}`,
  });

  // function for filter async
  createFilterObj = (inputData) => ({
    filter: inputData,
    limit: 20
  });

  handleNext = () => {
    const { activeStep } = this.state;

    if (activeStep === 0) {
      this.setState({ spinner: true });
      this.getRoomTerminals();
    }

    if (activeStep === 1) {
      this.setState({ spinner: true });
      this.addTerminalToRoom();
    }

    this.setState({
      activeStep: activeStep + 1,
    });
  };

  handleBack = () => {
    // if(this.state.activeStep === 1) {
    //   this.setState({ roomTerm: null });
    // }
    this.setState((state) => ({
      activeStep: state.activeStep - 1,
    }));
  };

  buttonEnabled = () => {
    if (this.state.activeStep === 0) {
      return (this.state.room === null || this.state.room === '');
    } else if (this.state.activeStep === 1) {
      return (
        this.state.termLocation === null ||
        this.state.termLineup === null ||
        this.state.termMenuSet === null
      );
    }
    return false;
  };

  getQueryParam(param) {
    const result = getValueFromSession('Mobile').match(
      new RegExp(`(\\?|&)${param}(\\[\\])?=([^&]*)`)
    );

    return result ? result[3] : false;
  }

  getRoomTerminals() {
    const room = this.state.room.trim();
    getSiteObjectPromise(ROOM_RESOURCE, `${room}/terminals`, this.state.site).then((data) => {
      this.roomTermCallback(data);
    }).catch(() => {
      this.roomTermFailure();
    });
  }

  addTerminalToRoom() {
    const putSomething = { ...this.state.termInfo };
    delete putSomething.id;
    delete putSomething.site_id;
    putSomething.room_id = this.state.room.trim();
    putSomething.location = findObjectByKey(this.state.roomTermLocations, 'id', this.state.termLocation).name;
    putSomething.menuset = this.state.termMenuSet;
    putSomething.lineup_id = this.state.termLineup;
    putSomething.language = (this.state.termInfo.language || 'en');
    putSomething.censor_level = this.state.termInfo.censor_level || 1;

    putObjectPromise(TERMINAL_RESOURCE, this.state.id, putSomething, this.state.site).then((data) => {
      this.addTerminalSuccess(data);
    }).catch(() => {
      this.addTerminalFailure();
    });
  }

  roomTermCallback = (data) => {
    const roomTermLocations = [];
    for (let i = 0; i < data.terminals.length; i++) {
      roomTermLocations.push(data.terminals[i].location);
    }

    const terminalLocations = [];
    for (let i = 0; i < this.state.siteOptions.locations.length; i++) {
      if (!roomTermLocations.includes(this.state.siteOptions.locations[i].name)) {
        terminalLocations.push(this.state.siteOptions.locations[i]);
      }
    }

    this.setState({
      termLocation: terminalLocations[0].id,
      termLineup: this.state.siteOptions.lineups[0].id,
      termMenuSet: this.state.siteOptions.menusets[0].id,
      roomTermLocations: terminalLocations,
      spinner: false
    });
  };

  roomTermFailure = () => {
    this.setState({
      termLocation: this.state.siteOptions.locations[0].id,
      termLineup: this.state.siteOptions.lineups[0].id,
      termMenuSet: this.state.siteOptions.menusets[0].id,
      roomTermLocations: this.state.siteOptions.locations,
      spinner: false
    });
  };

  addTerminalSuccess = (data) => {
    if (data.status < LOW_HTTP || data.status >= HIGH_HTTP) {
      this.setState({
        error: true,
        spinner: false,
        errorText: `${data.status}:${data.title}:${data.detail}`
      });
    } else {
      this.setState({ termAdded: true, spinner: false });
    }

    removeSessionItem('Mobile');
  };

  addTerminalFailure = () => {
    this.setState({ termAdded: false, spinner: false });
  };

  componentDidMount() {
    this.getMobileTerminal(0).then(() => console.log('done'));
  }

  getMobileTerminal = (index) => getObjectPromise(TERMINAL_RESOURCE, this.getQueryParam('terminal'))
    .then((data) => {
      this.mobileSuccess(data);
    }).catch(() => {
      if (index >= 30) {
        this.termFailure();
        this.setState({
          error: true,
          spinner: false,
          errorText: `Error grabbing terminal ${this.getQueryParam('terminal')}`
        });
      } else {
        setTimeout(() => {
          this.getMobileTerminal(index + 1);
        }, 1000);
      }
    });

  mobileSuccess = (data) => {
    if (data.room_id) {
      this.setState({
        id: data.id,
        site: data.site_id,
        room: data.room_id,
        error: true,
        spinner: false,
        errorText: `Terminal ${data.id} already assigned for room ${data.room_id} at site ${data.site_id}`
      });
    } else {
      this.setState({ id: data.id, site: data.site_id, room: data.room_id });
      getSiteObjectPromise(TERMINAL_RESOURCE, data.id, data.site_id).then((dataOne) => {
        this.terminalCallback(dataOne);
      }).catch((error) => {
        this.failure(`Unable to get terminal ${data.id} info (${error.status})`);
      });
    }
  };

  termFailure = () => {
    this.setState({
      error: true,
      spinner: false,
      errorText: `Error grabbing terminal ${this.getQueryParam('terminal')}`
    });
  };

  terminalCallback = (data) => {
    this.setState({ termInfo: data });
    getProvisionSiteObjectPromise('', null, data.site_id)
      .then((dataOne) => {
        this.successCallback(dataOne);
      }).catch((error) => {
        this.failure(`Terminal error ${error.status}`);
      });
  };

  successCallback = (data) => {
    this.setState({ siteName: data.name, siteLocation: data.location });
    getSiteObjectPromise(TERMINAL_OPTIONS, null, this.state.site)
      .then((data2) => {
        this.siteCallback(data2);
      }).catch((error) => {
        this.failure(`Error grabbing terminal options (${error.status})`);
      });
  };

  siteCallback = (data2) => {
    this.setState({ siteOptions: data2 });
    getSiteObjectPromise(ROOM_RESOURCE, null, this.state.site, { limit: 500000 }).then((data) => {
      this.successRooms(data);
    }).catch((error) => {
      this.failure(`Error getting room information (${error.status})`);
    });
  };

  successRooms = (data) => {
    this.setState({ rooms: data.rooms, spinner: false });
  };

  failure = (errorText) => {
    console.log('Mobile:Error', errorText);
    this.setState({ error: true, spinner: false, errorText: `${errorText}` });
  };

  handleRoomChange = (name) => (value) => {
    this.setState({
      [name]: (value instanceof Event ? value.target.value : value.label)
    });
  };

  checkErrorStatus() {
    const errors = [];
    if (!this.state.siteOptions.locations || this.state.siteOptions.locations.length < 1) {
      errors.push('Locations');
    }

    if (!this.state.siteOptions.cables || this.state.siteOptions.cables.length < 1) {
      errors.push('Cables');
    }

    if (!this.state.siteOptions.lineups || this.state.siteOptions.lineups.length < 1) {
      errors.push('Lineups');
    }

    if (!this.state.siteOptions.menusets || this.state.siteOptions.menusets.length < 1) {
      errors.push('Menusets');
    }

    if (!this.state.siteOptions.languages || this.state.siteOptions.languages.length < 1) {
      errors.push('Languages');
    }

    if (errors.length > 1) {
      return `No ${errors.toString()} Configured`;
    }
    return '';
  }

  render() {
    if (this.state.spinner) {
      return (
        <SonifiSpinnerWithMessage show
          message="Getting terminal information.  This could take up to 30 seconds." />
      );
    }

    const { activeStep } = this.state;
    const { classes } = this.props;

    if (this.state.error) {
      return (
        <div className={classes.padding}>
          <SonifiError label="An error occurred." />
          <SonifiError label={this.state.errorText || ''} />
        </div>
      );
    }

    if (activeStep === 1 && this.state.siteOptions) {
      const errors = this.checkErrorStatus();
      if (errors.length > 1) {
        return <SonifiError boldLabel="Error" label={errors} />;
      }
    }

    return (
      <div className={classes.padding}>
        {activeStep === 2 && (
          <>
            {this.state.termAdded
              ? <SonifiLabel label="Terminal Added" />
              : <SonifiError label="Error Adding Terminal" />
            }
            <Divider />
          </>
        )}

        <SonifiLabel label={`${this.state.site}, ${this.state.siteName}, ${this.state.siteLocation.city}`} />
        {activeStep > 0 && (
          <SonifiLabel label={`Room: ${this.state.room}`} />
        )}
        <SonifiLabel label={`Terminal: ${this.state.id}`} />

        <div className={classes.instructions}>
          {getStepContent(activeStep, this.state, this.handleRoomChange.bind(this),
            this.createFilterObj.bind(this), this.createDisplayObj.bind(this))}
        </div>
        {activeStep < 2 && (<div>
          <Button
            disabled={activeStep === 0}
            onClick={this.handleBack}
            variant="contained"
            sx={{
              'marginRight': '12px',
              'backgroundColor': '#2e2e2e',
              'color': '#fff',
              'min-width': '100px',
              '&:hover': {
                backgroundColor: '#0e6393',
              }
            }}
          >
            Back
          </Button>
          <Button
            disabled={this.buttonEnabled()}
            variant="contained"
            onClick={this.handleNext}
            sx={{
              'marginRight': '12px',
              'backgroundColor': '#1592d9',
              'color': '#fff',
              'min-width': '100px',
              '&:hover': {
                backgroundColor: '#0e6393',
              }

            }}
          >
            {activeStep === 0 ? 'Next' : 'Done'}
          </Button>

        </div>)}
      </div>
    );
  }
}

Mobile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(Mobile, styles);
