import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { EDIT } from '../../../constants/constants';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import SonifiText from '../../../containers/SonifiText';
import { editIcp, editSelectedIcp } from '../actions/icpActions';
import { MAX, MIN } from '../constants/constants';

class IcpForm extends Component {
  constructor(props) {
    super(props);

    this.handleAutoRegistrationChange = this.handleAutoRegistrationChange.bind(this);
    this.state = {
      autoRegistrationMode: 'detect'
    };
  }

  handleChange = (name) => ({ target: { value } }) => {
    if (value < MAX && value > MIN) {
      this.props.dispatch(editSelectedIcp(name, value));
    }
  };

  handleAutoRegistrationChange = (name) => ({ target: { value } }) => {
    this.props.dispatch(editSelectedIcp(name, value));
    this.setState({ autoRegistrationMode: value });
  };

  componentDidMount() {
    if (this.props.selectedIcp > MIN && !this.props.icps[this.props.selectedIcp].firmware) {
      this.props.dispatch(editIcp(this.props.icps[this.props.selectedIcp].id));
    }
    this.setState({ autoRegistrationMode: this.props.icps[this.props.selectedIcp].auto_registration_mode });
  }

  icpTextField = function(uObj) {
    const defaultObject = { type: 'string', ksrReadOnly: true, isSelect: false, items: [] };
    const obj = { ...defaultObject, ...uObj };
    return (
      <Grid item xs={4}>
        { obj.isSelect && <SonifiText
            change={obj.change}
            defaultValue={obj.value}
            disabled={obj.ksrReadOnly}
            label={obj.label}
            size="percent"
            type={obj.type}
            items={obj.items}
            select
          />
        }
        { !obj.isSelect && <SonifiText
            change={obj.change}
            defaultValue={obj.value}
            disabled={obj.ksrReadOnly}
            label={obj.label}
            size="percent"
            type={obj.type}
          />
        }
      </Grid>
    );
  };

  /* eslint-disable camelcase */
  render() {
    const { editType, icps, selectedIcp, translations } = this.props;

    if (selectedIcp === -1) {
      return <Fragment />;
    }

    const {
      firmware, hardware, heart_rate, mac_address, number_terminals, number_subslots
    } = icps[selectedIcp];

    const { autoRegistrationMode } = this.state;

    if (!firmware) {
      return <SonifiSpinner />;
    }

    return (
      <Fragment>
        <Grid container>
          {this.icpTextField({ label: translations.macAddress, value: mac_address })}
          {this.icpTextField({ label: translations.firmwareVersion, value: firmware.version })}
          {this.icpTextField({ label: translations.hardwareVersion, value: hardware.version })}
          {this.icpTextField({ label: translations.firmwareChecksum, value: firmware.checksum })}
          {this.icpTextField({ label: translations.hardwareType, value: hardware.type })}
          {this.icpTextField({ label: translations.heartRate, value: heart_rate })}
          {this.icpTextField({
            label: translations.numTerms,
            value: number_terminals,
            type: 'number',
            ksrReadOnly: editType !== EDIT,
            change: this.handleChange('number_terminals')
          })}
          {this.icpTextField({ label: translations.numSubSlots, value: number_subslots })}
          {this.icpTextField({
            label: translations.autoRegistrationMode,
            value: autoRegistrationMode,
            type: 'string',
            ksrReadOnly: editType !== EDIT,
            change: this.handleAutoRegistrationChange('auto_registration_mode'),
            isSelect: true,
            items: [translations.autoRegistrationValues.detect, translations.autoRegistrationValues.harvest]
          })}
        </Grid>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  editType: state.icps.editType,
  icps: state.icps.icps,
  selectedIcp: state.icps.selectedIcp,
  translations: state.icps.translations.form
});

IcpForm.propTypes = {
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  icps: PropTypes.array.isRequired,
  selectedIcp: PropTypes.number.isRequired,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(IcpForm);
