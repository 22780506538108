import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { withStyles } from 'tss-react/mui';
import SonifiError from './SonifiError';

const styles = () => ({
  root: {
    color: 'red',
  },
});

export class SonifiErrorList extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.state = {
      errorList: props.errorList,
    };
  }

  componentDidUpdate() {
    if (this.props.errorList !== this.state.errorList) {
      this.setState({
        errorList: this.props.errorList
      });
    }
  }

  render() {
    const { errorList } = this.state;

    // const errorList = [
    //   'MIKE ERROR 1',
    //   {status: 201, detail: 'MIKE ERROR 2'},
    //   'MIKE ERROR 3',
    // ];

    if ((errorList === undefined) || (errorList.length === 0)) {
      return <Fragment></Fragment>;
    }
    return <Fragment>
      <ul>
        {errorList.map((error, index) => {
          // eslint-disable-next-line no-prototype-builtins
          if (error.hasOwnProperty('status') && error.hasOwnProperty('title')) {
            return <li key={index}><SonifiError label={`${error.status} : ${error.title}`} /></li>;
          } else {
            return <li key={index}><SonifiError label={error} /></li>;
          }
        })
        }
      </ul>
    </Fragment>;
  }
}

SonifiErrorList.propTypes = {
  errorList: PropTypes.array.isRequired
};


export default React.memo(withStyles(SonifiErrorList, styles, { withTheme: true }));
