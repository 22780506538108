import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { SonifiText } from '../../../../containers/';
import { getErrorText, isError } from '../../../../utils/index';
import { parity, serialPorts } from '../../constants/constants';
import { isValidBaud, isValidBits, isValidCommand } from '../../validator/validSerial';

const styles = () => ({
  table: {
    minWidth: 700,
    maxHeight: '90%',
  }
});

class Interface extends Component {
  changeStateNumber = (name) => ({ target: { value } }) => {
    this.props.updateTypeState(name, value.replace(/([^0-9])/g, 'port'));
  };

  changeValue = (name) => ({ target: { value } }) => {
    if (name === 'baud' && !isValidBaud(value)) {
      return;
    }

    if (['data_bits', 'stop_bits', 'send_attempts', 'send_timeout'].indexOf(name) > -1 && !isValidBits(value)) {
      return;
    }

    if (name === 'command_timeout' && !isValidCommand(value)) {
      return;
    }

    this.props.updateTypeState(name, value, 'port');
  };

  handleCheckChange = (name) => ({ target }) => {
    this.props.updateTypeState(name, target.checked, 'port');
  };

  getDropDown(label, name, defaultVal, items) {
    const { editState, notEditable } = this.props;
    const { errors } = editState;

    return (
      <SonifiText
        size="lg"
        label={label}
        defaultValue={defaultVal}
        change={this.changeValue(name)}
        error={isError(name, errors)}
        errorText={getErrorText(name, errors)}
        disabled={notEditable}
        select={true}
        items={items}
      />
    );
  }

  getTextBox(label, defaultValue, name, type = 'string') {
    const { editState, notEditable } = this.props;
    return (
      <SonifiText
        size="lg"
        label={label}
        disabled={notEditable}
        change={this.changeValue(name)}
        defaultValue={defaultValue}
        error={isError(name, editState.errors)}
        errorText={getErrorText(name, editState.errors)}
        type={type}
      />
    );
  }


  render() {
    const { editState, translations } = this.props;
    return (
      <Grid item xs={12}>
        {this.getTextBox(translations.baud, editState.port.baud, 'baud', 'number')}
        {this.getDropDown(translations.parity, 'parity', editState.port.parity, parity)}
        {this.getTextBox(translations.data_bits, editState.port.data_bits, 'data_bits', 'number')}
        {this.getTextBox(translations.stop_bits, editState.port.stop_bits, 'stop_bits', 'number')}
        {this.getDropDown(translations.port, 'port', editState.port.port, serialPorts)}
        {this.getTextBox(translations.device_name, editState.port.device_name, 'device_name')}
        {this.getTextBox(translations.hardware, editState.port.hardware, 'hardware')}
        {this.getTextBox(translations.send_attempts, editState.port.send_attempts, 'send_attempts', 'number')}
        {this.getTextBox(translations.send_timeout, editState.port.send_timeout, 'send_timeout', 'number')}
        {this.getTextBox(translations.command_timeout, editState.port.command_timeout, 'command_timeout', 'number')}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.integrations.translations.pmsd.serial
});

Interface.propTypes = {
  dispatch: PropTypes.func,
  editState: PropTypes.object,
  notEditable: PropTypes.bool,
  updateTypeState: PropTypes.func,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(Interface, styles, { withTheme: true }));
