import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateDevProd } from '../../../actions/globalActions';
import SonifiCheckbox from '../../../containers/SonifiCheckbox';
import SonifiText from '../../../containers/SonifiText';
import { isProduction } from '../../../utils/api';
import { editLanguageSetting } from '../actions/profileActions';

class ProfileSettings extends Component {
  handleLanguageChange = (name) => ({ target: { value } }) => {
    this.props.dispatch(editLanguageSetting(name, value));
    this.setState({ currentLanguage: value });
  };

  checkUncheck() {
    this.props.dispatch(updateDevProd());
  }

  render() {
    const { isProd, languages } = this.props;
    return (
      <Grid item>
        <Grid container>
          <Grid item>
            <SonifiText
              label="Default Language"
              select
              items={languages.map((suggestion) => ({
                id: suggestion.id,
                value: suggestion.id,
                label: suggestion.name,
              }))}
              defaultValue={this.props.currentLanguage}
              change={this.handleLanguageChange('language').bind(this)}
              size="md"
            />
          </Grid>
          {!isProduction(false) && <Grid item>
            <SonifiCheckbox
              value={isProd}
              onChange={this.checkUncheck.bind(this)}
              label="Is Production"
              removeTopPadding={true} />
          </Grid>}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLanguage: state.global.currentLanguage,
  isProd: state.global.isProd,
  language: state.profile.language,
  languages: state.profile.languages
});

ProfileSettings.propTypes = {
  currentLanguage: PropTypes.string,
  dispatch: PropTypes.func,
  isProd: PropTypes.bool,
  language: PropTypes.string,
  languages: PropTypes.array,
};

export default connect(mapStateToProps)(ProfileSettings);
