import { Add } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { EDIT } from '../../../constants/constants';
import { SONIFI_ROLES } from '../../../constants/roleGroups';
import { SONIFI_ADMIN } from '../../../constants/roles';
import {
  SonifiConfirm, SonifiLabel, SonifiLockoutModalSpinner, SonifiSnackBar, SonifiSpinner, SonifiTableHead
} from '../../../containers';
import SonifiTemplate from '../../../containers/SonifiTemplate';
import { buildSortString } from '../../../utils';
import { checkForAtLeastOneUserPermission, checkForSingleUserPermission } from '../../../utils/rolesUtil';
import { updateSelectedReleaseTemplate } from '../../ReleaseTemplates/actions/releaseTemplateActions';
import {
  clearSitesTable,
  getTieApiClients, updateActiveRow, updateActiveStatus, updateSelectedClient, updateSnackBar
} from '../actions/tieApiClientActions';
import ClientRow from '../containers/ClientRow';
import TieClientDialog from './TieClientDialog';

// import TieClientsDialog from './TieClientsDialog';

export class TieApiClientsGrid extends Component {
  state = {
    order: 'asc',
    orderBy: 'id',
    page: 1,
  };

  componentDidMount() {
    this.props.dispatch(getTieApiClients(buildSortString(this.state)));
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.orderBy !== this.state.orderBy || prevState.order !== this.state.order ||
      prevState.page !== this.state.page) {
      this.props.dispatch(getTieApiClients(buildSortString(this.state)));
    }
  }

  closeDialog() {
    this.props.dispatch(updateSelectedClient(-1, ''));
  }

  handleRequestSort = (property) => {
    const { dispatch } = this.props;
    this.props.dispatch(updateSelectedReleaseTemplate(-1, EDIT));
    dispatch(clearSitesTable());
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({
      order: isDesc ? 'asc' : 'desc',
      orderBy: property,
      page: 1
    });
  };

  confirmDialogConfirmFunc() {
    const { activeClient, dispatch } = this.props;
    dispatch(updateActiveStatus(activeClient));
  }

  confirmDialogCancelFunc() {
    this.props.dispatch(updateActiveRow(null));
  }

  getMoreData() {
    if (this.state.page < this.props.maxPages) {
      this.setState((prevState) => ({ page: prevState.page + 1 }));
    } else {
      console.log('WAYPOINT MAXPAGES REACHED!', this.props.maxPages);
    }
  }

  closeSnackBar() {
    this.props.dispatch(updateSnackBar(''));
  }

  handleButtonClick = () => {
    this.props.dispatch(updateSelectedClient(-2, EDIT));
  };

  getPageDetails(canEdit) {
    const { order, orderBy } = this.state;
    const {
      loading, clients, translations,
    } = this.props;

    const tableHeader = [
      { id: 'active', sortable: clients.length > 1, numeric: false, label: `${translations.active}` },
      { id: 'id', sortable: clients.length > 1, numeric: false, label: `${translations.id}` },
      { id: 'brand', sortable: clients.length > 1, numeric: false, label: `${translations.brand}` },
      { id: 'ip', sortable: false, numeric: false, label: `${translations.ipAddress}` },
      { id: 'type', sortable: clients.length > 1, numeric: false, label: `${translations.type}` },
      { id: 'client_type', sortable: clients.length > 1, numeric: false, label: `${translations.client_type}` },
      { id: 'scope', sortable: false, numeric: false, label: `${translations.scope}` }
    ];

    return <Table stickyHeader={true}>
      <SonifiTableHead
        headColumns={tableHeader}
        order={order}
        orderBy={orderBy}
        onRequestSort={this.handleRequestSort}
      />
      {loading && clients.length === 0
        ? <TableBody>
          <TableRow>
            <TableCell colSpan={7}>
              <SonifiSpinner />
            </TableCell>
          </TableRow>
        </TableBody>
        : <TableBody>
          {(!clients || clients.length === 0)
            ? <TableRow>
              <TableCell colSpan={7}>
                <SonifiLabel error label={translations.errors.noData} />
              </TableCell>
            </TableRow>
            : clients.map((option, index) => (
              <Fragment key={`w_${index}`}>
                <ClientRow key={index} rowIndex={index} canEdit={canEdit && !loading} />

                {/* index === clients.length - DISTANCE_FROM_END && page < maxPages && (
                <TableRow>
                  <TableCell>
                    <Waypoint onEnter={() => { this.getMoreData(); }} />
                  </TableCell>
                </TableRow>)
              */}
              </Fragment>
            ))}
        </TableBody>}
    </Table>;
  }

  isDialogOpen() {
    /* const { deleteTemplate, immutable, popupLoading, saving } = this.props;
    return ((deleteTemplate !== null && deleteTemplate !== undefined) || immutable !== -1) &&
      !popupLoading && !saving;*/
    const { activeClient, saving } = this.props;
    return activeClient !== null && !saving;
  }

  getDialogText() {
    const { activeClient, translations } = this.props;

    if (activeClient !== null) {
      const clientName = decodeURIComponent(activeClient.id);
      return `${activeClient.active ? translations.deactivateDesc : translations.activateDesc} ${clientName}?`;
    }
    return '';
  }

  getDialogTitle() {
    const { activeClient, translations } = this.props;

    if (activeClient !== null) {
      return activeClient.active ? translations.deactivateTitle : translations.activateTitle;
    }
    return '';
  }

  getButtonText() {
    const { activeClient, globalTranslations } = this.props;
    if (activeClient !== null) {
      return activeClient.active ? globalTranslations.deactivate : globalTranslations.activate;
    }
    return '';
  }

  render() {
    const {
      globalTranslations, loading, saving, selectedClient, snackBarMessage,
      snackBarType, translations, userPermissions
    } = this.props;

    if (!(checkForAtLeastOneUserPermission(SONIFI_ROLES, userPermissions))) {
      return <Navigate replace to="/" />;
    }

    const canEdit = checkForSingleUserPermission(SONIFI_ADMIN, userPermissions);

    return (
      <Fragment>
        <SonifiLockoutModalSpinner show={saving} />
        <SonifiConfirm
          buttonCancelText={globalTranslations.cancel}
          buttonConfirmText={this.getButtonText()}
          confirmText={this.getDialogText()}
          confirmTitle={this.getDialogTitle()}
          dialogOpen={this.isDialogOpen()}
          onCancel={this.confirmDialogCancelFunc.bind(this)}
          onConfirm={this.confirmDialogConfirmFunc.bind(this)}
        />
        <SonifiTemplate
          header={translations.header}
          icon={<Add />}
          label={globalTranslations.add}
          onSubmit={this.handleButtonClick.bind(this)}
          pageDetails={this.getPageDetails(canEdit)}
          showButton={canEdit}
          disabled={loading || saving}
        />
        <SonifiSnackBar message={snackBarMessage} variant={snackBarType}
          open={snackBarMessage !== ''} onClose={this.closeSnackBar.bind(this)} />

        {selectedClient !== -1 && <TieClientDialog onCancel={this.closeDialog.bind(this)} canEdit={canEdit} />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  activeClient: state.tieClients.activeClient,
  activeIndex: state.tieClients.activeIndex,
  clients: state.tieClients.clients,
  globalTranslations: state.global.translations.defaults,
  loading: state.tieClients.loading,
  saving: state.tieClients.saving,
  maxPages: state.tieClients.maxPages,
  selectedClient: state.tieClients.selectedClient,
  snackBarMessage: state.tieClients.snackBarMessage,
  snackBarType: state.tieClients.snackBarType,
  translations: state.tieClients.translations.grid,
  userPermissions: state.global.permissions
});

TieApiClientsGrid.propTypes = {
  activeClient: PropTypes.object,
  activeIndex: PropTypes.number,
  clients: PropTypes.array,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  maxPages: PropTypes.number,
  selectedClient: PropTypes.number,
  snackBarMessage: PropTypes.string,
  snackBarType: PropTypes.string,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(TieApiClientsGrid);
