import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { closeSnackBar, getSystemSchedule } from '../../../actions/globalActions';
import SonifiError from '../../../containers/SonifiError';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import { getSiteNumFromURI } from '../../../utils';
import { fetchAllRoomTypes } from '../../LateCheckout/actions/lateCheckoutActions';
import { getTerminalOptions } from '../../Terminals/actions/terminalActions';
import { fetchRooms } from '../actions/roomActions';
import { fetchFreeTerminals, fetchTerminalOptions } from '../actions/terminalActions';
import EditCard from './EditCard';
import EditDialog from './EditDialog';


class RoomsGrid extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(closeSnackBar());
    dispatch(fetchRooms());
    dispatch(fetchTerminalOptions());
    dispatch(getTerminalOptions(getSiteNumFromURI()));
    dispatch(fetchFreeTerminals());
    dispatch(getSystemSchedule());
    dispatch(fetchAllRoomTypes());
  }

  componentWillUnmount() {
    this.props.dispatch(closeSnackBar());
  }

  /* eslint-disable camelcase*/
  render() {
    const {
      error, loading, loadingGroups, open, rooms, site
    } = this.props;

    if (error) {
      return <SonifiError label={`Error! ${error.message}`} />;
    }

    if (loading || loadingGroups) {
      return <SonifiSpinner />;
    }

    if (!site || site.length < 1) {
      return <div></div>;
    }

    if (!rooms || rooms.length === 0) {
      return (<SonifiError label="No Rooms" />);
    }

    return (
      <Fragment>
        <Grid container spacing={1}>
          {rooms.map(({ id, is_occupied }, index) => (
            <Grid item key={id} sm={4} md={3} lg={2} xl={1}>
              <EditCard
                roomID={id}
                index={index}
                occupied={is_occupied} />
            </Grid>
          ))}
        </Grid>
        <EditDialog open={open} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  change: state.rooms.change,
  error: state.rooms.error,
  loading: state.rooms.loading,
  loadingGroups: state.groupServices.loadingGroups,
  open: state.rooms.open,
  pageCount: state.rooms.page_count,
  pageSize: state.rooms.page_size,
  rooms: state.rooms.rooms,
  site: state.rooms.buildings,
  totalItems: state.rooms.total_items
});

RoomsGrid.propTypes = {
  dispatch: PropTypes.func,
  error: PropTypes.object,
  loading: PropTypes.bool,
  loadingGroups: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  rooms: PropTypes.array,
  site: PropTypes.array
};

export default connect(mapStateToProps)(RoomsGrid);
