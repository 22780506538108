import { Dialog, DialogContent } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { LINEUP_EDIT, LINEUP_LIMITED } from '../../../constants/roles';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiSnackBar from '../../../containers/SonifiSnackBar';
import SonifiSpinner from '../../../containers/SonifiSpinner';
import { checkForAtLeastOneUserPermission } from '../../../utils/rolesUtil';
import { removePipe } from '../../../utils/textUtil';
import * as actions from '../actions/tvLineupActions';
import ChannelLineupForm from '../containers/ChannelLineupForm';
import { checkChannelInfo, getChannelsThatCanBeInLineup, validLineup } from '../utils/validator';

const styles = () => ({
  dialogPaper: {
    minHeight: '90%',
    maxHeight: '90%',
  },
  overflow: {
    overflow: 'hidden'
  }
});

export class LineupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editableLineup: this.props.selectedChannelLineup,
      validationErrors: {}
    };
  }

  componentDidMount() {
    this.setState({ editableLineup: JSON.parse(JSON.stringify(this.props.selectedChannelLineup)) });
    this.props.dispatch(actions.canDetailsBeEdited(this.props.selectedChannelLineup.id));
  }

  handleChange = (name) => ({ target: { value } }) => {
    if (name === 'bat_warmup_seconds') {
      if (isNaN(value) || value < 0 || value > 100 || value === '') {
        return;
      }
    }

    if (name === 'name' && (value.length > 32 || value.length < 1)) {
        this.setState({
          validationErrors: {
            ...this.state.validationErrors,
            name: this.props.translations.errors.nameCharRestriction
          }
        });
    } else {
      const currentErrors = this.state.validationErrors;
      delete currentErrors.name;
      this.setState({
        validationErrors: {
          ...currentErrors
        }
      });
    }
    this.setState({
      editableLineup: { ...this.state.editableLineup, [name]: removePipe(value) }
    });
  };

  onCheckboxChange = (name) => ({ target: { checked } }) => {
    this.setState((prevState) => ({
      editableLineup: { ...prevState.editableLineup, [name]: checked }
    }));
  };

  handleRadioChange = (name, value) => () => {
    this.setState((prevState) => ({
      editableLineup: { ...prevState.editableLineup, [name]: value }
    }));
  };

  handleChannelChange = (newChannelArray) => {
    this.setState((prevState) => ({
      editableLineup: { ...prevState.editableLineup, channels: newChannelArray }
    }));
  };

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.props.dispatch(actions.setSelectedChannelLineups(null));
    }
  }

  onSubmit() {
    const { editableLineup } = this.state;
    const { globalTranslations, translations } = this.props;
    const validLineupChannels = [];

    const channelsInLineup = getChannelsThatCanBeInLineup(editableLineup, this.props.channels);
    const validationErrors = validLineup(editableLineup, channelsInLineup, translations.errors,
      globalTranslations.errors);
    this.setState({ validationErrors });

    if (Object.entries(validationErrors).length === 0) {
      for (let i = 0; i < editableLineup.channels.length; i++) {
        const index = this.props.channels.findIndex((channel) => channel.ipg.source_id === editableLineup.channels[i]);
        if (index !== -1 && checkChannelInfo(this.props.channels[index], editableLineup)) {
          validLineupChannels.push(editableLineup.channels[i]);
        }
      }

      editableLineup.channels = validLineupChannels;
      this.props.dispatch(actions.saveSingleExistingLineup(this.props.selectedChannelSet.id, editableLineup));
    }
  }

  closeSnackBar = () => {
    const validErrors = { ...this.state.validationErrors };
    delete (validErrors.validationErrors);
    this.setState({ validationErrors: validErrors });
  };

  closeApiError = () => {
    this.props.dispatch(actions.closeLineupSaveError());
  };

  render() {
    const {
      classes, lineupError, lineupErrorMessage, loading, globalTranslations,
      selectedChannelLineup, translations, userPermissions
    } = this.props;

    const { validationErrors } = this.state;
    let snackBarErrors = '';
    if (validationErrors.validationErrors) {
      for (let i = 0; i < validationErrors.validationErrors.length; i++) {
        snackBarErrors += `${validationErrors.validationErrors[i]}\n`;
      }
    }

    return (
      <Fragment>
        <Dialog
          classes={{ paper: classes.dialogPaper }}
          fullWidth
          maxWidth="lg"
          onClose={this.onClose.bind(this)}
          open={this.state.editableLineup !== null}>
          <SonifiModalHeader
            header={`${selectedChannelLineup.isNew ? translations.newLineup : translations.editLineup}: 
            ${(this.props.selectedChannelSet.id === 'Default' ? 'Active' : this.props.selectedChannelSet.id)}`}
            onCancel={this.onClose.bind(this)}
            onSubmit={this.onSubmit.bind(this)}
            label={globalTranslations.defaults.save}
            onlyClose={!checkForAtLeastOneUserPermission([LINEUP_EDIT, LINEUP_LIMITED], userPermissions) || loading}
          />
          <DialogContent className={classes.overflow}>
            {loading
              ? <SonifiSpinner />
              : <ChannelLineupForm
                lineupInfo={this.state.editableLineup}
                handleChange={this.handleChange.bind(this)}
                onCheckboxChange={this.onCheckboxChange.bind(this)}
                handleRadioChange={this.handleRadioChange.bind(this)}
                handleChannelChange={this.handleChannelChange}
                newLineup={!!selectedChannelLineup.isNew}
                userPermissions={userPermissions}
                errors={validationErrors}
              />}
          </DialogContent>
        </Dialog>

        <SonifiSnackBar message={snackBarErrors} variant="error"
          open={snackBarErrors.length > 0} onClose={this.closeSnackBar} />

        <SonifiSnackBar message={lineupErrorMessage || ''} variant="error"
          open={lineupError} onClose={this.closeApiError} />

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  availableChannelSets: state.channels.availableChannelSets,
  canEditDetails: state.tvLineup.canEditDetails,
  changed: state.tvLineup.changed,
  channels: state.channels.realChannels,
  globalTranslations: state.global.translations,
  lineupError: state.tvLineup.lineupError,
  lineupErrorMessage: state.tvLineup.lineupErrorMessage,
  loading: state.tvLineup.loading,
  selectedChannelLineup: state.tvLineup.selectedChannelLineup,
  selectedChannelSet: state.channels.channelSet,
  translations: state.tvLineup.translations.editDialog,
  userPermissions: state.global.permissions
});

LineupDialog.propTypes = {
  availableChannelSets: PropTypes.array,
  canEditDetails: PropTypes.bool,
  channels: PropTypes.array,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  lineupError: PropTypes.bool,
  lineupErrorMessage: PropTypes.string,
  loading: PropTypes.bool,
  newLineup: PropTypes.bool,
  selectedChannelSet: PropTypes.object,
  selectedChannelLineup: PropTypes.object,
  translations: PropTypes.object,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(withStyles(LineupDialog, styles, { withTheme: true }));
