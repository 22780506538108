import { Add } from '@mui/icons-material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel, SonifiSpinner } from '../../../../containers';
import SonifiTableHead from '../../../../containers/SonifiTableHead';
import SonifiTemplate from '../../../../containers/SonifiTemplate';
import { editTLXFile, fetchTLXFile } from '../../actions/tlxFileActions';
import ImportDialogTlx from './ImportDialogTlx';
import TLXFileRow from './TLXFileRow';

class TLXFileGrid extends Component {
  constructor(props) {
    super(props);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(fetchTLXFile());
  }

  handleButtonClick = () => {
    this.props.dispatch(editTLXFile(true));
  };

  getPageDetails() {
    const {
      canEdit, loading, tlxFile, translations
    } = this.props;
    const tableHeader = [
      { id: 'filename', sortable: false, numeric: false, label: `${translations.filename}` },
      { id: 'md5', sortable: false, numeric: false, label: `${translations.identifier}` },
      { id: 'uploaded', sortable: false, numeric: false, label: `${translations.uploaded}` }
    ];
    return (
      <Table>
        <SonifiTableHead headColumns={tableHeader} />
        {loading
          ? <TableBody>
            <TableRow>
              <TableCell colSpan={3}>
                <SonifiSpinner />
              </TableCell>
            </TableRow>
          </TableBody>
          : <TableBody>
            {!tlxFile || tlxFile.length === 0
              ? <TableRow>
                <TableCell colSpan={3}>
                  <SonifiLabel error label={translations.noTLX} />
                </TableCell>
              </TableRow >
              : tlxFile.map((option, index) => (
                <TLXFileRow key={index} rowIndex={index} tlxFile={option} canEdit={canEdit} />
              ))}
          </TableBody>}
      </Table>
    );
  }

  render() {
    const {
      canEdit, tlxFile, translations, editTLX, globalTranslations, loading
    } = this.props;

    return (
      <Fragment>
        <SonifiTemplate
          header={translations.tlxTitle}
          icon={<Add />}
          label={globalTranslations.defaults.add}
          multiple={true}
          onSubmit={this.handleButtonClick}
          paddingBottom={true}
          pageDetails={this.getPageDetails()}
          showButton={canEdit && tlxFile && tlxFile.length === 0 && !loading} />
        {editTLX && <ImportDialogTlx />}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.termFile.changed,
  editTLX: state.termFile.editTLX,
  globalTranslations: state.global.translations,
  loading: state.termFile.loading,
  tlxFile: state.termFile.tlxFile,
  translations: state.termFile.translations.grid.tlx
});

TLXFileGrid.propTypes = {
  canEdit: PropTypes.bool,
  changed: PropTypes.bool,
  dispatch: PropTypes.func,
  editTLX: PropTypes.bool,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  termFileState: PropTypes.object,
  tlxFile: PropTypes.array,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(TLXFileGrid);
