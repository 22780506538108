export const getOptionStyle = (isDraggingOver) => ({
  background: isDraggingOver ? '#dfe0e2' : '#f6f6f6',
  maxHeight: '93%',
  overflow: 'auto',
  padding: 1,
  width: '100%'
});

export const getItemStyle = (isDragging, draggableStyle, index) => ({
  background: isDragging ? '#1592d9' : index % 2 === 0 ? '#f6f6f6' : '#fff',
  padding: 1,
  userSelect: 'none',
  ...draggableStyle
});

export function getPriceToSend(value) {
  const origPrice = typeof value === 'string' ? parseFloat(value.replace(',', '')).toFixed(2) : value;
  const modPrice = origPrice * 100;
  const stringSplit = modPrice.toString().split('.');
  if (stringSplit.length === 1) {
    return modPrice;
  }
  let returnPrice = modPrice;
  if (parseInt(stringSplit[1], 10) > 9) {
    returnPrice = parseInt(stringSplit[0], 10) + 1;
  }
  return returnPrice;
}
