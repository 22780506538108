export const ADD_NEW_TERM_MODEL = 'ADD_NEW_TERM_MODEL';
export const CLEAR_MODELS = 'CLEAR_MODELS';
export const DELETE_CHECK = 'DELETE_CHECK';
export const DELETE_TERM_MODEL_BEGIN = 'DELETE_TERM_MODEL_BEGIN';
export const DELETE_TERM_MODEL_FAILURE = 'DELETE_TERM_MODEL_FAILURE';
export const DELETE_TERM_MODEL_SUCCESS = 'DELETE_TERM_MODEL_SUCCESS';
export const FETCH_ALL_MODELS_BEGIN = 'FETCH_ALL_MODELS_BEGIN';
export const FETCH_ALL_MODELS_FAILURE = 'FETCH_MODELS_FAILURE';
export const FETCH_ALL_MODELS_SUCCESS = 'FETCH_ALL_MODELS_SUCCESS';
export const FETCH_SITE_FIRMWARE_BEGIN = 'FETCH_SITE_FIRMWARE_BEGIN';
export const FETCH_SITE_FIRMWARE_FAILURE = 'FETCH_SITE_FIRMWARE_FAILURE';
export const FETCH_SITE_FIRMWARE_SUCCESS = 'FETCH_SITE_FIRMWARE_SUCCESS';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';
export const SAVE_TERM_MODEL_BEGIN = 'SAVE_TERM_MODEL_BEGIN';
export const SAVE_TERM_MODEL_FAILURE = 'SAVE_TERM_MODEL_FAILURE';
export const SAVE_TERM_MODEL_RESET = 'SAVE_TERM_MODEL_RESET';
export const SAVE_TERM_MODEL_SUCCESS = 'SAVE_TERM_MODEL_SUCCESS';
export const START_MODELS = 'START_MODELS';
export const UPDATE_SELECTED_TERM_MODEL = 'UPDATE_SELECTED_TERM_MODEL';
