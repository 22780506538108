/* eslint-disable max-len */
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel } from '../../../containers';
import { BATTERY_KEY_THRESHOLD, RF_COMM_THRESHOLD, RF_THROUGHPUT_THRESHOLD, RSSI_SIGNAL_THRESHOLD } from '../constants/Constants';

class StatusCard extends Component {
  getRFTermInfo() {
    const { getStatusItem, translations, selectedTerm } = this.props;
    return (
      <Fragment>
        {getStatusItem(translations.rfRssi,
          selectedTerm.rf_rssi,
          selectedTerm.rf_rssi > RSSI_SIGNAL_THRESHOLD,
          selectedTerm.rf_rssi,
          null,
          translations.statusInfo.rfRssi)}
        {getStatusItem(translations.rfPercent,
          `${selectedTerm.rf_comms_percentage}%`,
          selectedTerm.rf_comms_percentage > RF_COMM_THRESHOLD,
          selectedTerm.rf_comms_percentage,
          null,
          translations.statusInfo.rfPercent)}
        {getStatusItem(translations.rfThroughput,
          selectedTerm.rf_comms_throughput,
          selectedTerm.rf_comms_throughput > RF_THROUGHPUT_THRESHOLD,
          selectedTerm.rf_comms_throughput,
          true,
          translations.statusInfo.rfThroughput)}
      </Fragment>
    );
  }

  getNonRFTermInfo() {
    const { getStatusItem, translations, selectedTerm } = this.props;
    return (
      <Fragment>
        {getStatusItem(translations.tvMode,
          selectedTerm.tv_mode,
          selectedTerm.tv_mode,
          selectedTerm.tv_mode,
          null,
          translations.statusInfo.tvMode)}
        {getStatusItem(translations.inputSource,
          selectedTerm.input_source,
          selectedTerm.input_source,
          selectedTerm.input_source,
          null,
          translations.statusInfo.inputSource)}
        {getStatusItem(translations.hostConnected,
          selectedTerm.host_connected,
          selectedTerm.host_connected,
          selectedTerm.host_connected,
          true,
          translations.statusInfo.hostConnected)}
      </Fragment>

    );
  }

  render() {
    const { helperClass, getStatusItem, translations, selectedTerm } = this.props;
    if ((selectedTerm.type !== 'rf' && !selectedTerm.host_connected) ||
      (selectedTerm.type === 'rf' && selectedTerm.rf_comms_percentage === 0)) {
      return (
        <Grid container spacing={0} className={helperClass.statusCard} >
          <Grid item padding="16px">
            <SonifiLabel error boldLabel={translations.noComms} />
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container spacing={0} className={helperClass.statusCard} >
        {getStatusItem(translations.scaKey,
          selectedTerm.sca_key_is_valid ? translations.good : translations.bad,
          selectedTerm.sca_key_is_valid, selectedTerm.sca_key_is_valid,
          null,
          translations.statusInfo.scaKey)}
        {getStatusItem(translations.tvComms,
          selectedTerm.tv_communication ? translations.good : translations.bad,
          selectedTerm.tv_communication,
          selectedTerm.tv_communication,
          null,
          translations.statusInfo.tvComms)}
        {getStatusItem(translations.lowBattery,
          selectedTerm.lowBatteryKey >= BATTERY_KEY_THRESHOLD ? translations.bad : translations.good,
          selectedTerm.lowBatteryKey < BATTERY_KEY_THRESHOLD,
          selectedTerm.lowBatteryKey,
          null,
          translations.statusInfo.lowBattery)}
        {selectedTerm.type === 'rf' ? this.getRFTermInfo() : this.getNonRFTermInfo()}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  changed: state.termGrid.changed,
  selectedTerm: state.termGrid.selectedTerm,
  translations: state.termGrid.translations.terminalInfo
});

StatusCard.propTypes = {
  getStatusItem: PropTypes.func,
  helperClass: PropTypes.object,
  selectedTerm: PropTypes.object,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(StatusCard);
