import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SonifiText from '../../../../../containers/SonifiText';
import { ANALOG, analogTuningValues, analogTypes, INTL_ANALOG } from '../../../constants/constants';
import IntlAnalogForm from './IntlAnalogForm';

export default class ChannelTableAnalogColumn extends Component {
  constructor(props) {
    super(props);
    this.props = props;

    if (this.props.intlAnalogObj.frequency_khz !== '') {
      this.state = {
        type: INTL_ANALOG
      };
      this.props.editNonEventFunction(INTL_ANALOG, 'channelType', 'string', INTL_ANALOG);
    } else {
      this.state = {
        type: ANALOG
      };
      this.props.editNonEventFunction(ANALOG, 'channelType', 'string', ANALOG);
    }
  }

  typeEdit = ({ target: { value } }) => {
    this.setState({ type: value });
    this.props.editNonEventFunction(ANALOG, 'channelType', 'string', value);
  };

  render() {
    const {
      analogObj, intlAnalogObj, editFunction, errors, readOnly, translations
    } = this.props;

    return (
      <Grid container>
        <Grid item>
          <SonifiText
            defaultValue={this.state.type}
            change={this.typeEdit}
            select={true}
            label={translations.type}
            size="md"
            items={analogTypes}
          />
        </Grid>
        {this.state.type === ANALOG
          ? <Fragment>
            <Grid item>
              <SonifiText
                defaultValue={analogObj.modulator}
                label={translations.analogMod}
                change={editFunction(ANALOG, 'modulator', 'number')}
                size="md"
                error={!!errors.analogMod}
                errorText={(errors.analogMod ? errors.analogMod : '')}
                disabled={readOnly} />
              <SonifiText
                defaultValue={analogObj.tuning}
                change={editFunction(ANALOG, 'tuning')}
                select={true}
                label={translations.tuning}
                size="md"
                items={analogTuningValues}
                error={!!errors.analogTuning}
                errorText={(errors.analogTuning ? errors.analogTuning : '')}
                disabled={readOnly} />
            </Grid>
          </Fragment>
          : <IntlAnalogForm
            readOnly={readOnly}
            editFunction={this.props.editFunction}
            intlAnalogObj={intlAnalogObj}
            errors={errors}
            translations={translations} />}
      </Grid>
    );
  }
}

ChannelTableAnalogColumn.propTypes = {
  analogObj: PropTypes.object,
  intlAnalogObj: PropTypes.object,
  editFunction: PropTypes.func.isRequired,
  editNonEventFunction: PropTypes.func.isRequired,
  errors: PropTypes.object,
  readOnly: PropTypes.bool,
  translations: PropTypes.object
};
