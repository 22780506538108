export const BATCH_TERMINAL_RANGE_BEGIN = 'BATCH_TERMINAL_RANGE_BEGIN';
export const BATCH_TERMINAL_RANGE_FAILURE = 'BATCH_TERMINAL_RANGE_FAILURE';
export const BATCH_TERMINAL_RANGE_SUCCESS = 'BATCH_TERMINAL_RANGE_SUCCESS';
export const CLEAR_KEY_BEGIN = 'CLEAR_KEY_BEGIN';
export const CLEAR_KEY_EMPTY = 'CLEAR_KEY_EMPTY';
export const CLEAR_KEY_FAILURE = 'CLEAR_KEY_FAILURE';
export const CLEAR_KEY_SUCCESS = 'CLEAR_KEY_SUCCESS';
export const CLEAR_TABLE = 'CLEAR_TABLE';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';
export const CLOSE_SNACK = 'CLOSE_SNACK';
export const DELETE_TERM_BEGIN = 'DELETE_TERM_BEGIN';
export const DELETE_TERM_FAILURE = 'DELETE_TERM_FAILURE';
export const DELETE_TERM_SUCCESS = 'DELETE_TERM_SUCCESS';
export const FETCH_RANGE_TERMINALS_BEGIN = 'FETCH_RANGE_TERMINALS_BEGIN';
export const FETCH_RANGE_TERMINALS_FAILURE = 'FETCH_RANGE_TERMINALS_FAILURE';
export const FETCH_RANGE_TERMINALS_SUCCESS = 'FETCH_RANGE_TERMINALS_SUCCESS';
export const FETCH_ROOM_BEGIN = 'FETCH_ROOM_BEGIN';
export const FETCH_ROOM_FAILURE = 'FETCH_ROOM_FAILURE';
export const FETCH_ROOM_SUCCESS = 'FETCH_ROOM_SUCCESS';
export const FETCH_ROOM_TERMINALS_BEGIN = 'FETCH_ROOM_TERMINALS_BEGIN';
export const FETCH_ROOM_TERMINALS_FAILURE = 'FETCH_ROOM_TERMINALS_FAILURE';
export const FETCH_ROOM_TERMINALS_SUCCESS = 'FETCH_ROOM_TERMINALS_SUCCESS';
export const FETCH_TERMINAL_BEGIN = 'FETCH_TERMINAL_BEGIN';
export const FETCH_TERMINAL_EXPORT_BEGIN = 'FETCH_TERMINAL_EXPORT_BEGIN';
export const FETCH_TERMINAL_FAILURE = 'FETCH_TERMINAL_FAILURE';
export const FETCH_TERMINAL_OPT_BEGIN = 'FETCH_TERMINAL_OPT_BEGIN';
export const FETCH_TERMINAL_OPT_FAILURE = 'FETCH_TERMINAL_OPT_FAILURE';
export const FETCH_TERMINAL_OPT_SUCCESS = 'FETCH_TERMINAL_OPT_SUCCESS';
export const FETCH_TERMINAL_STATUS_BEGIN = 'FETCH_TERMINAL_STATUS_BEGIN';
export const FETCH_TERMINAL_STATUS_FAILURE = 'FETCH_TERMINAL_STATUS_FAILURE';
export const FETCH_TERMINAL_STATUS_SUCCESS = 'FETCH_TERMINAL_STATUS_SUCCESS';
export const FETCH_TERMINAL_STATUSES_BEGIN = 'FETCH_TERMINAL_STATUSES_BEGIN';
export const FETCH_TERMINAL_STATUSES_FAILURE = 'FETCH_TERMINAL_STATUSES_FAILURE';
export const FETCH_TERMINAL_STATUSES_SUCCESS = 'FETCH_TERMINAL_STATUSES_SUCCESS';
export const FETCH_TERMINAL_SUCCESS = 'FETCH_TERMINAL_SUCCESS';
export const FETCH_TERMINALS_BEGIN = 'FETCH_TERMINALS_BEGIN';
export const FETCH_TERMINALS_FAILURE = 'FETCH_TERMINALS_FAILURE';
export const FETCH_TERMINALS_SUCCESS = 'FETCH_TERMINALS_SUCCESS';
export const PATCH_TERMINAL_OPT_BEGIN = 'PATCH_TERMINAL_OPT_BEGIN';
export const PATCH_TERMINAL_OPT_FAILURE = 'PATCH_TERMINAL_OPT_FAILURE';
export const PATCH_TERMINAL_OPT_SUCCESS = 'PATCH_TERMINAL_OPT_SUCCESS';
export const PUT_TERMINAL_BEGIN = 'PUT_TERMINAL_BEGIN';
export const PUT_TERMINAL_FAILURE = 'PUT_TERMINAL_FAILURE';
export const PUT_TERMINAL_SUCCESS = 'PUT_TERMINAL_SUCCESS';
export const PUT_TERMINAL_SUCCESS_FAILURE = 'PUT_TERMINAL_SUCCESS_FAILURE';
export const REBOOT_TERM_BEGIN = 'REBOOT_TERM_BEGIN';
export const REBOOT_TERM_FAILURE = 'REBOOT_TERM_FAILURE';
export const REBOOT_TERM_SUCCESS = 'REBOOT_TERM_SUCCESS';
export const RESET_RANGE = 'RESET_RANGE';
export const RESET_TERM_GRID = 'RESET_TERM_GRID';
export const SEARCH_TERMINALS_BEGIN = 'SEARCH_TERMINALS_BEGIN';
export const SEARCH_TERMINALS_SUCCESS = 'SEARCH_TERMINALS_SUCCESS';
export const SET_SELECTED_TERM_ID = 'SET_SELECTED_TERM_ID';
export const UNCLAIM_TERMINAL = 'UNCLAIM_TERMINAL';
export const UPDATE_LOG_BEGIN = 'UPDATE_LOG_BEGIN';
export const UPDATE_LOG_FAILURE = 'UPDATE_LOG_FAILURE';
export const UPDATE_LOG_SUCCESS = 'UPDATE_LOG_SUCCESS';
export const UPDATE_ROOM_TERM_FIRM_BEGIN = 'UPDATE_ROOM_TERM_FIRM_BEGIN';
export const UPDATE_ROOM_TERM_FIRM_FAILURE = 'UPDATE_ROOM_TERM_FIRM_FAILURE';
export const UPDATE_ROOM_TERM_FIRM_SUCCESS = 'UPDATE_ROOM_TERM_FIRM_SUCCESS';
export const UPDATE_SELECTED_RANGE = 'UPDATE_SELECTED_RANGE';
export const UPDATE_SELECTED_TERMINAL2 = 'UPDATE_SELECTED_TERMINAL2';
export const UPDATE_TERM_FIRM_BEGIN = 'UPDATE_TERM_FIRM_BEGIN';
export const UPDATE_TERM_FIRM_FAILURE = 'UPDATE_TERM_FIRM_FAILURE';
export const UPDATE_TERM_FIRM_SUCCESS = 'UPDATE_TERM_FIRM_SUCCESS';
export const UPDATE_TERMINAL_BEGIN = 'UPDATE_TERMINAL_BEGIN';
export const UPDATE_TERMINAL_FAILURE = 'UPDATE_TERMINAL_FAILURE';
export const UPDATE_TERMINAL_FIELD = 'UPDATE_TERMINAL_FIELD';
export const UPDATE_TERMINAL_RANGE = 'UPDATE_TERMINAL_RANGE';
export const UPDATE_TERMINAL_SUCCESS = 'UPDATE_TERMINAL_SUCCESS';
