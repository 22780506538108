import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadSiteHierarchy } from '../../actions/globalActions';
import RcTree from '../../containers/RoomSelection/RcTree/RcTree'; // './RcTree/RcTree';
import SonifiSpinner from '../../containers/SonifiSpinner';

class Site extends Component {
  componentDidMount() {
    // this.props.dispatch(loadSiteHierarchy('HC-11B', false));
    this.props.dispatch(loadSiteHierarchy());
  }

  onChange(checked) {
    console.log('Site checked', checked);

    // if(this.props.onChange) {
    // this.props.onChange(checked);
    // }
  }

  render() {
    const { autoExpandKeys, loadingSite, selectedRooms, siteHierarchy } = this.props;
    const expandedKeys = (!autoExpandKeys || autoExpandKeys.length === 0) ? ['Whole Site'] : autoExpandKeys;

    return (
      <div style={{ width: '100%', height: '90%', overflow: 'auto' }}>
        {loadingSite
          ? <SonifiSpinner />
          : <RcTree nodes={siteHierarchy} keys={selectedRooms ? [selectedRooms] : []}
            autoExpandKeys={expandedKeys} onChange={this.onChange} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loadingSite: state.global.loadingSite,
  siteHierarchy: state.global.buildings,
});

Site.propTypes = {
  dispatch: PropTypes.func,
  loadingSite: PropTypes.bool,
  siteHierarchy: PropTypes.array,
  autoExpandKeys: PropTypes.array,
  onChange: PropTypes.func,
  selectedRooms: PropTypes.array
};

export default connect(mapStateToProps)(Site);
