import { TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  md: {
    marginRight: theme.spacing(2),
    width: '320px',
    height: theme.spacing(10),
    marginBottom: 0
  },
  percent: {
    marginRight: 0,
    width: '95%',
    height: theme.spacing(10),
    marginBottom: 0
  },
});

export class SonifiDatePicker extends Component {
  dateTimeChange = (value) => {
    const changeDate = new Date(value);
    if (value === null || changeDate === null) {
      return;
    }
    this.props.change(changeDate);
  };

  render() {
    const {
      classes, defaultValue, error, errorText, disabled, disablePast, label, size
    } = this.props;
    const showError = ((typeof error === 'undefined') || (error === null)) ? false : error;

    let classSize = '';
    if (size === 'md') {
      classSize = classes.md;
    } else if (size === 'percent') {
      classSize = classes.percent;
    }

    return (
      <DatePicker
        disabled={disabled}
        disableFuture={!disablePast}
        disablePast={disablePast}
        label={label}
        onChange={this.dateTimeChange.bind(this)}
        renderInput={(params) => <TextField
          {...params} variant="filled" margin="normal" size={size}
          error={showError} helperText={errorText ? errorText : ' '} className={classSize}
        />}
        value={defaultValue}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  timezone: (state?.global?.site?.location?.timezone ?? 'America/Chicago')
});

SonifiDatePicker.defaultProps = {
  disablePast: true,
};

SonifiDatePicker.propTypes = {
  change: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  disablePast: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string.isRequired,
  size: PropTypes.string,
  timezone: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(withStyles(SonifiDatePicker, styles, { withTheme: true }));
