import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  sectionTitle: {
    marginTop: '4px',

    // marginBottom: '4px',
    marginBottom: theme.spacing(1),
    fontSize: '12pt',
    color: theme.palette.secondary.main
  },
});

export class ModalSectionHeader extends Component {
  render() {
    const { classes, label } = this.props;

    return (
      <Typography className={classes.sectionTitle} noWrap>
        {label}
      </Typography>
    );
  }
}

ModalSectionHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

export default withStyles(ModalSectionHeader, styles, { withTheme: true });
