import { Check, FileCopy, GetApp, ImportExport } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { trim } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { CHANNEL_CLONE } from '../../../../constants/roles';
import { SonifiIconButton } from '../../../../containers';
import ImportDialog from '../../../../containers/Dialog/ImportDialog';
import { getSiteNumFromURI } from '../../../../utils';
import { checkSingleUserPermission } from '../../../../utils/rolesUtil';
import * as actions from '../../actions/channelsActions';
import { PROPERTY } from '../../constants/constants';
import { exampleCsv, parseChannels } from '../../utils/channelImport';
import { deepCopyJson } from '../../utils/channelMoveAndSort';
import CloneAnotherSiteDialog from '../CloneAnotherSiteDialog';

class ActiveButtons extends Component {
  state = {
    allowDownload: true,
    showType: null
  };

  checkType(type) {
    this.setState({ showType: type });
  }

  handleRequestDownload() {
    this.setState({ allowDownload: false });
    this.props.dispatch(actions.getChannelsExport(this.props.channelSet.id));
    setTimeout(() => {
      this.setState({ allowDownload: true });
    }, 2000);
  }

  parseImportFile(result) {
    const { channelErrors, channelTypes, channelHeaders, translations } = this.props;
    const miscObj = JSON.parse(JSON.stringify(result));
    const results = miscObj.map((item) => item.map((valueString) => trim(valueString.replaceAll('"', ''))));
    const pChannels = parseChannels(getSiteNumFromURI(), channelTypes, channelHeaders, channelErrors, results);

    if (pChannels.channels.length === 0) {
      return {
        errors: translations.columnHeaderError
      };
    }

    return {
      tableList: pChannels.channels,
      allowSave: pChannels.channelNumberError || pChannels.configErrors,
      headers: pChannels.tableHeader,
      columnNames: pChannels.columnNames
    };
  }

  importList(channelData) {
    this.importCloneList(channelData, true);
  }

  saveCopyMasterChannelList = (channelData) => () => {
    this.importCloneList(channelData, false);
  };

  // This is called for the clone functionality
  // It goes throught the channelData and updates all of the sourceIds as needed
  importCloneList(channelData, keepSourceIds) {
    const channelCopy = deepCopyJson(channelData);

    // Seconds since epoch for source id generation
    const now = new Date();
    let nowSeconds = Math.round(now.getTime() / 1000);

    // Loop through and change all of the property channels source ids
    if (!keepSourceIds) {
      for (let i = 0; i < channelCopy.length; i++) {
        if (channelCopy[i].type === PROPERTY) {
          channelCopy[i].ipg.source_id = `SONIFI-${getSiteNumFromURI()}-${nowSeconds}`;
          nowSeconds += 1;
        }
      }
    }

    for (let i = 0; i < channelCopy.length; i++) {
      if (channelCopy[i].analog) {
        delete (channelCopy[i].analog.toolTip);
      }

      if (channelCopy[i].digital) {
        delete (channelCopy[i].digital.toolTip);
        delete (channelCopy[i].digital.aux);
      }

      if (channelCopy[i].ip) {
        delete (channelCopy[i].ip.toolTip);
      }
      if (channelCopy[i].intl_analog) {
        delete (channelCopy[i].intl_analog.toolTip);
      }
      if (channelCopy[i].intl_dvb_c) {
        delete (channelCopy[i].intl_dvb_c.toolTip);
        delete (channelCopy[i].intl_dvb_c.intlType);
      }
      if (channelCopy[i].intl_dvb_s) {
        delete (channelCopy[i].intl_dvb_s.toolTip);
        delete (channelCopy[i].intl_dvb_s.intlType);
      }
      if (channelCopy[i].intl_dvb_t) {
        delete (channelCopy[i].intl_dvb_t.toolTip);
        delete (channelCopy[i].intl_dvb_t.intlType);
      }
    }

    // Call clone
    this.props.dispatch(actions.cloneListToDraft(channelCopy, this.props.availableChannelSets))
      .then(() => {
        this.setState({ showType: null });
      });
  }

  getSaveToolTip() {
    const { realChannels, translations, undoStack } = this.props;
    let publishButtonToolTip = '';
    if (undoStack.length < 1) {
      publishButtonToolTip = translations.allSaved;
    }

    if (realChannels.length === 0) {
      if (publishButtonToolTip.length > 0) {
        publishButtonToolTip += '\n';
      }
      publishButtonToolTip = translations.oneChannel;
    }
    return publishButtonToolTip;
  }

  saveCheck() {
    this.props.dispatch(actions.saveCheck(this.props.realChannels, { type: 'save', chan: '' }));
  }

  render() {
    const {
      channelHeaders, cloneTranslations, draftExists, firstLoad, globalTranslations, lineupsLoading, loading,
      realChannels, translations, undoStack, userPermissions
    } = this.props;
    const { allowDownload, showType } = this.state;
    const updateInProgress = (lineupsLoading || loading || firstLoad);
    const canClone = checkSingleUserPermission(CHANNEL_CLONE, userPermissions);

    if (!canClone) {
      return <Fragment />;
    }

    return (<Fragment>
      {showType === null &&
        <Grid container justifyContent="flex-end">
          <Fragment>
            <Grid item>
              <SonifiIconButton label={translations.clone}
                onClick={this.checkType.bind(this, 'clone')}
                disabled={updateInProgress || draftExists}
                showToolTip={draftExists}
                toolTipMessage="Draft exists"
                icon={<FileCopy />} />
            </Grid>
            <Grid item>
              <SonifiIconButton label={globalTranslations.defaults.download}
                onClick={this.handleRequestDownload.bind(this)}
                toolTipMessage={translations.downloadActive}
                showToolTip={true}
                disabled={updateInProgress || !allowDownload}
                icon={<GetApp />} />
            </Grid>
            <Grid item>
              <SonifiIconButton label={translations.import}
                onClick={this.checkType.bind(this, 'import')}
                disabled={updateInProgress || draftExists}
                showToolTip={draftExists}
                toolTipMessage="Draft exists"
                icon={<ImportExport />} />
            </Grid>
          </Fragment>
          <Grid item>
            <SonifiIconButton label={globalTranslations.defaults.save}
              onClick={this.saveCheck.bind(this)}
              icon={<Check />}
              toolTipMessage={this.getSaveToolTip()}
              showToolTip={undoStack.length < 1 || realChannels.length === 0}
              disabled={undoStack.length < 1 || realChannels.length === 0 || updateInProgress ||
                draftExists} />
          </Grid>
        </Grid>}

      {showType === 'import' &&
        <ImportDialog
          csvText={exampleCsv()}
          globalTranslations={globalTranslations.importDialog}
          onCancel={this.checkType.bind(this, null)}
          parseImportFile={this.parseImportFile.bind(this)}
          saveListFunc={this.importList.bind(this)}
          title={translations.importChannels}
        />}

      {showType === 'clone' &&
        <CloneAnotherSiteDialog
          channelHeaders={channelHeaders}
          closeFunc={this.checkType.bind(this, null)}
          globalTranslations={globalTranslations.defaults}
          saveListFunc={this.saveCopyMasterChannelList.bind(this)}
          translations={cloneTranslations}
        />
      }
    </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  availableChannelSets: state.channels.availableChannelSets,
  channelErrors: state.channels.translations.channelErrors,
  channelHeaders: state.channels.translations.channelHeaders,
  channelSet: state.channels.channelSet,
  channelTypes: state.siteManagement.channelDelivery,
  cloneTranslations: state.channels.translations.cloneDialog,
  firstLoad: state.channels.firstLoad,
  globalTranslations: state.global.translations,
  lineupErrors: state.tvLineup.translations.editDialog.errors,
  lineups: state.tvLineup.channelLineups,
  lineupsLoading: state.tvLineup.lineupsLoading,
  loading: state.channels.loading,
  realChannels: state.channels.realChannels,
  translations: state.channels.translations.buttonPanel,
  undoStack: state.channels.undoStack,
  userPermissions: state.global.permissions
});

ActiveButtons.propTypes = {
  availableChannelSets: PropTypes.array,
  channelErrors: PropTypes.object,
  channelHeaders: PropTypes.object,
  channelSet: PropTypes.object,
  channelTypes: PropTypes.array.isRequired,
  checkType: PropTypes.func,
  cloneTranslations: PropTypes.object,
  dispatch: PropTypes.func,
  draftExists: PropTypes.bool,
  firstLoad: PropTypes.bool,
  globalTranslations: PropTypes.object,
  lineupErrors: PropTypes.object,
  lineups: PropTypes.array,
  lineupsLoading: PropTypes.bool,
  loading: PropTypes.bool,
  realChannels: PropTypes.array,
  translations: PropTypes.object,
  undoStack: PropTypes.array,
  userPermissions: PropTypes.array
};

export default connect(mapStateToProps)(ActiveButtons);
