import { findObjectByKey } from '../../../utils/index';
import { addedRange, addRoomInfo, startAddedRange } from '../actions/editRoomActions';
import { fetchRooms } from '../actions/roomActions';
import { checkForDuplicateRooms } from '../utils/index';

export function addRangeOfRooms(rooms, dispatch) {
  dispatch(startAddedRange());
  const roomsToParse = [];
  for (let i = 0; i < rooms.length; i++) {
    roomsToParse.push(rooms[i].id);
  }

  const duplicateCheck = checkForDuplicateRooms(roomsToParse);

  Promise.all(duplicateCheck).then((data) => {
    const roomsToAdd = [];
    const duplicateRooms = [];

    for (let i = 0; i < data.length; i++) {
      const room = findObjectByKey(rooms, 'id', data[i].id);
      if (!data[i].duplicate) {
        roomsToAdd.push(room);
      } else {
        duplicateRooms.push(room);
      }
    }
    console.log('duplicateRooms', duplicateRooms);
    console.log('roomsToAdd', roomsToAdd);

    const addingAllRoomsInArr = [];

    for (let i = 0; i < roomsToAdd.length; i++) {
      addingAllRoomsInArr.push(dispatch(addRoomInfo({ ...roomsToAdd[i] }, null)));
    }

    Promise.all(addingAllRoomsInArr).then((data2) => {
      console.log('1addingAllRoomsInArr', addingAllRoomsInArr);
      console.log('1data2', data2);
      dispatch(addedRange(data2, duplicateRooms));
      dispatch(fetchRooms());
    });
  });
}
