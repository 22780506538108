/* eslint-disable max-lines-per-function */
/* eslint-disable camelcase */
import { Warning } from '@mui/icons-material';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { generate } from 'generate-password-browser';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { READ } from '../../../constants/constants';
import SonifiLabel from '../../../containers/SonifiLabel';
import SonifiLockoutModalSpinner from '../../../containers/SonifiLockoutModalSpinner';
import SonifiModalHeader from '../../../containers/SonifiModalHeader';
import SonifiText from '../../../containers/SonifiText';
import { getErrorText, isError } from '../../../utils/index';
import { removePipe } from '../../../utils/textUtil';
import { addClient, addClientBegin, addClientValidationFailure } from '../actions/tieApiClientActions';
import {
  AUTH_OPTIONS,
  BRAND_OPTIONS,
  CLIENT_CREDENTIALS,
  CLIENT_TYPE_3RD_PARTY,
  CLIENT_TYPE_OPTIONS
} from '../constants/TieApiClientConstants';
import ScopeTable from '../containers/ScopeTable';
import SiteSelector from '../containers/SiteSelector';
import { getSubmitObject } from '../utils';
import { validClient, validId } from '../utils/validator';

class TieClientDialog extends Component {
  state = {
    allowSave: true,
    config: {},
    errors: null,
    id: '',
    isNew: false,
    type: '',
    updated: false,
    client_type: ''
  };

  componentDidMount() {
    const { clients, selectedClient } = this.props;
    if (selectedClient === -2) {
      this.setState({
        config: {
          brand: BRAND_OPTIONS[0].value,
          ip: '',
          scopes: [],
          sites: []
        },
        password: this.generatePassword(),
        type: AUTH_OPTIONS[1],
        updated: true,
        client_type: CLIENT_TYPE_OPTIONS[0]
      });
    } else {
      this.setState({ ...JSON.parse(JSON.stringify(clients[selectedClient])) }, () => {
        if (Array.isArray(this.state.config.ip)) {
          const configField = { ...this.state.config };
          const ipString = configField.ip.toString();
          this.setState({ config: { ...configField, ip: ipString.split(',').join('\n') } });
        }
      });
    }
  }

  updateField = (name) => ({ target: { value } }) => {
    this.setState({ [name]: removePipe(value), updated: true });
  };

  updateConfigField = (name) => ({ target: { value } }) => {
    this.configUpdate(name, value);
  };

  configUpdate(name, value) {
    const configField = { ...this.state.config };
    configField[name] = value;
    this.setState({ config: configField, updated: true });
  }

  updateBrand = (value) => {
    this.configUpdate('brand', value);
  };

  generatePassword() {
    return generate({
      length: 16,
      numbers: true,
      uppercase: true,
      lowercase: true,
      symbols: '@#$%',

      // symbols: true,
      excludeSimilarCharacters: true,

      // exclude: '!,^,*,(,),{,},[,],<,>,?,/,~,-,_,+,=,.'
    });
  }

  updateSelectedScope(selected) {
    this.configUpdate('scopes', selected);
  }

  updateSites(sites) {
    this.configUpdate('sites', sites);
  }

  onSave = () => {
    const { globalTranslations, selectedClient, translations } = this.props;

    if (!this.state.updated) {
      this.props.onCancel();
      return;
    }

    this.props.dispatch(addClientBegin());

    const currClient = getSubmitObject(this.state);
    const validationErrors = validClient(currClient, translations.errors, globalTranslations.errors);
    this.setState({ errors: validationErrors });
    if (selectedClient === -2 && !validationErrors.id) {
      validId(currClient.id, translations.errors)
        .then((data) => {
          validationErrors.id = data.message;
          this.onSaveContinue(currClient, validationErrors);
        }).catch(() => {
          this.onSaveContinue(currClient, validationErrors);
        });
    } else {
      this.onSaveContinue(currClient, validationErrors);
    }
  };

  onSaveContinue(currClient, validationErrors) {
    if (Object.entries(validationErrors).length === 0) {
      this.setState({ errors: null });
      this.props.dispatch(addClient(currClient, this.props.selectedClient === -2 ? 'add' : 'edit'));
    } else {
      this.setState({ errors: validationErrors });
      this.props.dispatch(addClientValidationFailure());
    }
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.setState({
        type: AUTH_OPTIONS[0]
      }, () => {
        this.props.onCancel();
      });
    }
  }

  getClientTextBox(fieldName, disabled) {
    const { canEdit, translations } = this.props;
    const { errors } = this.state;

    return (
      <SonifiText
        size="percent"
        label={translations[fieldName]}
        defaultValue={`${decodeURIComponent(this.state[fieldName])}`}
        change={this.updateField(fieldName)}
        error={isError(fieldName, errors)}
        errorText={getErrorText(fieldName, errors)}
        disabled={disabled || !canEdit}
      />
    );
  }

  getTitle() {
    const { editType, selectedClient, translations } = this.props;
    if (editType === READ) {
      return translations.readTitle;
    } else if (selectedClient === -2) {
      return translations.addTitle;
    }
    return translations.editTitle;
  }

  getPassword() {
    const { translations } = this.props;
    const { password } = this.state;
    return (
      <Grid container style={{ border: '1px solid #d6d6d6', padding: '10px', marginBottom: '10px' }}>
        <Grid item>
          <SonifiLabel boldLabel={`${translations.password}: ${password}`} blue />
        </Grid>
        <Grid item xs={1} />
        <Grid item>
          <Warning style={{ color: 'red', height: '100%' }} />
        </Grid>
        <Grid item>
          <SonifiLabel label={translations.passwordText} error />
        </Grid>
      </Grid>
    );
  }

  render() {
    const {
      addingClient, canEdit, editType, globalTranslations, selectedClient, translations
    } = this.props;
    const {
      config, errors, type, client_type
    } = this.state;

    const readOnly = editType === READ || !canEdit;
    if (addingClient) {
      return (<SonifiLockoutModalSpinner show={addingClient} />);
    }

    const calcNum = type === CLIENT_CREDENTIALS && selectedClient === -2 ? '310' : '240';

    return (
      <Dialog open={true}
        onClose={this.onClose.bind(this)}
        fullWidth
        maxWidth="lg" >
        <SonifiModalHeader
          header={this.getTitle()}
          onlyClose={readOnly}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.onSave.bind(this)}
          label={globalTranslations.defaults.save}
        />
        <DialogContent >
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={3}>
                {this.getClientTextBox('id', (readOnly || selectedClient !== -2))}
                <SonifiText
                  label={translations.type}
                  select
                  items={(AUTH_OPTIONS).map((suggestion) => ({
                    id: suggestion,
                    value: suggestion,
                    label: suggestion,
                  }))}
                  size="percent"
                  change={this.updateField('type')}
                  defaultValue={type}
                  error={isError('role', errors)}
                  errorText={getErrorText('role', errors)}
                  disabled={(readOnly || selectedClient !== -2)}
                />
                <SonifiText
                  label={translations.client_type}
                  select
                  items={(CLIENT_TYPE_OPTIONS).map((suggestion) => ({
                    id: suggestion,
                    value: suggestion,
                    label: suggestion,
                  }))}
                  size="percent"
                  change={this.updateField('client_type')}
                  defaultValue={client_type}
                  error={isError('role', errors)}
                  errorText={getErrorText('role', errors)}
                  disabled={(readOnly || selectedClient !== -2)}
                />
              </Grid>
            <Grid item xs={4}>
              {type === CLIENT_CREDENTIALS && <SonifiText
                change={this.updateConfigField('ip')}
                defaultValue={this.state.config.ip}
                disabled={readOnly}
                error={isError('ip', errors)}
                errorText={getErrorText('ip', errors)}
                id="ip"
                label={translations.ip}
                multiline={true}
                helperText={translations.ipHelper}
                rows={4.5}
                size="percent"
              />}
            </Grid>
            <Grid item xs={4}>
              {type === CLIENT_CREDENTIALS &&
                <ScopeTable
                  canEdit={canEdit}
                  error={isError('scopes', errors)}
                  updateSelectedScope={this.updateSelectedScope.bind(this)}
                  selectedScope={config.scopes} />}
            </Grid>
            {type === CLIENT_CREDENTIALS && selectedClient === -2 &&
              <Grid item xs={12}>
                {this.getPassword()}
              </Grid>}
            <Grid item xs={12} style={{ height: `calc(100% - ${calcNum}px)` }}>
              {type === CLIENT_CREDENTIALS && client_type === CLIENT_TYPE_3RD_PARTY &&
                <SiteSelector canEdit={!readOnly}
                  clientSites={config.sites}
                  brand={config.brand}
                  updateBrand={this.updateBrand.bind(this)}
                  updateSites={this.updateSites.bind(this)}
                  errors={isError('sites', errors)} />
              }
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  addingClient: state.tieClients.addingClient,
  clients: state.tieClients.clients,
  editType: state.tieClients.editType,
  gettingClientSites: state.tieClients.gettingClientSites,
  globalTranslations: state.global.translations,
  selectedClient: state.tieClients.selectedClient,
  translations: state.tieClients.translations.dialog
});

TieClientDialog.propTypes = {
  addingClient: PropTypes.bool,
  canEdit: PropTypes.bool,
  clients: PropTypes.array,
  dispatch: PropTypes.func,
  editType: PropTypes.string,
  gettingClientSites: PropTypes.bool,
  globalTranslations: PropTypes.object,
  onCancel: PropTypes.func,
  selectedClient: PropTypes.number,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(TieClientDialog);
