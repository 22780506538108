import {
  DEV_UPDATE_PERMISSION_LIST, DEV_UPDATE_ROLES, DEV_UPDATE_ROLE_PERMISSION, DEV_UPDATE_SELECTED_PERMISSION,
  DEV_UPDATE_SELECTED_ROLE, DEV_UPDATE_SELECTED_ROLE_ID, GET_ROLES_SUCCESS
} from '../actions/rolesDevActionTypes';

const initialState = {
  permissions: [],
  selectedPermission: {},
  roles: [],
  selectedRoleId: '',
  selectedRole: {}
};

export default function rolesReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.roles.roles
      };

    case DEV_UPDATE_PERMISSION_LIST:
      return {
        ...state,
        permissions: [...action.payload.permissions],
      };
    case DEV_UPDATE_SELECTED_PERMISSION:
      return {
        ...state,
        selectedPermission: action.payload.permission,
      };
    case DEV_UPDATE_ROLES: {
      return {
        ...state,
        roles: [...action.payload.roles],
      };
    }
    case DEV_UPDATE_SELECTED_ROLE_ID: {
      console.log(action.payload.roleId);
      return {
        ...state,
        selectedRoleId: action.payload.roleId,
      };
    }
    case DEV_UPDATE_SELECTED_ROLE: {
      return {
        ...state,
        selectedRole: action.payload.role,
      };
    }
    case DEV_UPDATE_ROLE_PERMISSION: {
      return {
        ...state,
        rolePermissions: action.payload.permissions,
      };
    }
    default:
      return state;
  }
}
