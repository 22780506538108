import { GROUP_SERVICES } from '../../../constants/apiEndpoints';
import { httpSuccess } from '../../../constants/http';
import { convertToSiteTimezone, getSiteNumFromURI, getUserNameFromSession } from '../../../utils';
import { getSiteObjectPromise, postObjectPromise, putObjectPromise } from '../../../utils/api';
import { addLibraryItem, addPublishItem, getLibraryItem } from '../../Messaging/actions/messagingActions';
import * as types from './actionTypes';

export const resetGroupDialog = () => ({
  type: types.RESET_GROUP_DIALOG
});

export const updateSelectedGroup = (group, editType) => ({
  type: types.UPDATE_SELECTED_GROUP,
  editType,
  group
});

export const deleteGroupServiceBegin = () => ({
  type: types.DELETE_GROUP_SERVICE_BEGIN
});

export const deleteGroupServiceSuccess = (groupId) => ({
  type: types.DELETE_GROUP_SERVICE_SUCCESS,
  groupId
});

export const deleteGroupServiceFailure = (groupId, error) => ({
  type: types.DELETE_GROUP_SERVICE_FAILURE,
  groupId,
  error
});

export function deleteGroup(groupId) {
  return (dispatch) => {
    dispatch(deleteGroupServiceBegin());

    setTimeout(() => {
      dispatch(deleteGroupServiceSuccess(groupId));
    }, 1500);
  };
}

export const deleteCheckGroup = (group) => ({
  type: types.DELETE_CHECK_SELECTED_GROUP,
  group
});

export const editSelectedGroup = (name, value) => ({
  type: types.EDIT_SELECTED_GROUP,
  name,
  value
});

export const setSnackBar = (msg = '', sbType) => ({
  type: types.SET_GROUP_SERVICES_SNACKBAR,
  msg,
  sbType
});

export const fetchGroupServicesBegin = (resetGroups) => ({
  type: types.FETCH_GROUP_SERVICES_BEGIN,
  resetGroups
});

export const fetchGroupServicesSuccess = (groups, maxPages) => ({
  type: types.FETCH_GROUP_SERVICES_SUCCESS,
  groups,
  maxPages
});

export const fetchGroupServicesFailure = (error) => ({
  type: types.FETCH_GROUP_SERVICES_FAILURE,
  error
});

// GET /sites/{site_id}/groups
export function fetchGroupServices(filter, resetGroups = false) {
  return (dispatch) => {
    dispatch(fetchGroupServicesBegin(resetGroups));
    const siteNum = getSiteNumFromURI();
    return getSiteObjectPromise(GROUP_SERVICES, null, siteNum, filter)
      .then((json) => {
        const maxPages = json.total_items / json.page_size;
        dispatch(fetchGroupServicesSuccess(json.groups, Math.ceil(maxPages)));
        return json;
      })
      .catch((error) => {
        console.log(error);
        fetchGroupServicesFailure(error);
        return error;
      });
  };
}

export const getGroupRoomsBegin = () => ({
  type: types.GET_GROUP_ROOMS_BEGIN
});

export const getGroupRoomsSuccess = (rooms) => ({
  type: types.GET_GROUP_ROOMS_SUCCESS,
  rooms
});

export const getGroupRoomsFailure = (error) => ({
  type: types.GET_GROUP_ROOMS_FAILURE,
  error
});

export function getGroupRooms(groupId) {
  return (dispatch) => {
    dispatch(getGroupRoomsBegin());
    const siteNum = getSiteNumFromURI();
    return getSiteObjectPromise(GROUP_SERVICES, `${groupId}/rooms`, siteNum, { limit: 1000 })
      .then((json) => {
        dispatch(getGroupRoomsSuccess(json.rooms));
        return json;
      })
      .catch((error) => {
        dispatch(getGroupRoomsFailure(error.status));
        return error;
      });
  };
}

export const sortSelectedGroupRooms = (column, direction) => ({
  type: types.ORDER_GROUP_ROOMS,
  column,
  direction
});

export const saveGroupBegin = () => ({
  type: types.SAVE_GROUP_BEGIN
});

export const saveGroupSuccess = (group, groupId = null) => ({
  type: types.SAVE_GROUP_SUCCESS,
  group,
  groupId
});

export const saveGroupFailure = (error, errorCodes, errorMsg) => ({
  type: types.SAVE_GROUP_FAILURE,
  error,
  errorCodes,
  errorMsg
});

export function saveGroup(group) {
  return (dispatch) => {
    if (!group || group === null) {
      dispatch(saveGroupSuccess(null));
      return group;
    }

    dispatch(saveGroupBegin());
    const siteNum = getSiteNumFromURI();

    if (group.menuset === 0) {
      group.menuset = null;
    }
    group.codes = group.codes.filter((e) => e).sort();
    group.codes = [...new Set(group.codes)];

    const sendGroup = JSON.parse(JSON.stringify(group));
    delete sendGroup.isNew;

    return postObjectPromise(GROUP_SERVICES, sendGroup, siteNum)
      .then((json) => {
        if (json.status && !httpSuccess(json.status)) {
          dispatch(saveGroupFailure(json.status, json.codes));
          return json;
        }
        dispatch(fetchGroupServices());
        dispatch(saveGroupSuccess(group, json));
        return json;
      })
      .catch((error) => {
        dispatch(saveGroupFailure(error));
        return error;
      });
  };
}

export function updateGroup(group) {
  return (dispatch) => {
    if (!group || group === null) {
      return;
    }

    dispatch(saveGroupBegin());
    const siteNum = getSiteNumFromURI(),
      groupId = group.id;

    if (group.menuset === 0) {
      group.menuset = null;
    }
    if (group.codes) {
      group.codes = group.codes.filter((e) => e).sort();
      group.codes = [...new Set(group.codes)];
    }
    return putObjectPromise(GROUP_SERVICES, groupId, group, siteNum, true)
      .then((json) => {
        if (json.status && !httpSuccess(json.status)) {
          dispatch(saveGroupFailure(json.status, json.codes, json?.detail));
          return json;
        }

        dispatch(fetchGroupServices());
        dispatch(saveGroupSuccess(group));
        return json;
      })
      .catch((error) => {
        dispatch(saveGroupFailure(error));
        return error;
      });
  };
}

export const getGroupMessagesBegin = () => ({
  type: types.GET_GROUP_MESSAGES_BEGIN
});

export const getGroupMessagesSuccess = (messages) => ({
  type: types.GET_GROUP_MESSAGES_SUCCESS,
  messages
});

export const getGroupMessagesFailure = (error) => ({
  type: types.GET_GROUP_MESSAGES_FAILURE,
  error
});

export function getGroupMessages(groupId) {
  return (dispatch) => {
    dispatch(getGroupMessagesBegin());
    const siteNum = getSiteNumFromURI();
    return getSiteObjectPromise(GROUP_SERVICES, `${groupId}/messages`, siteNum, { limit: 1000 })
      .then((json) => {
        dispatch(getGroupMessagesSuccess(json.messages));
        return json;
      })
      .catch((error) => {
        console.log(error);
        getGroupMessagesFailure(error.status);
        return error;
      });
  };
}

export const resetSendGroupMessage = (errors = null) => ({
  type: types.RESET_SEND_GROUP_MESSAGE,
  errors
});

export const sendMessageBegin = () => ({
  type: types.SEND_GROUP_MESSAGE_BEGIN
});

export const sendMessageSuccess = (messageTitle) => ({
  type: types.SEND_GROUP_MESSAGE_SUCCESS,
  messageTitle
});

export const sendMessageFailure = (error) => ({
  type: types.GET_GROUP_MESSAGES_FAILURE,
  error
});

export function sendMessage(groupId, publishItem, timezone) {
  return (dispatch) => {
    dispatch(sendMessageBegin());
    const userName = getUserNameFromSession();
    const libraryObj = {
      ...publishItem,
      source_type: 'group-services',
      source_id: groupId,
      user_name: userName
    };

    return dispatch(addLibraryItem(libraryObj, publishItem.translations))
      .then(() => setTimeout(() => dispatch(getLibraryItem(publishItem.id))
        .then(() => {
          dispatch(addPublishItem({
            body: {
              display_type: 'inbox',
              message_type: 'room',
              takeover_type: 'none',
              user_name: userName,
            },
            end_timestamp: convertToSiteTimezone(publishItem.end_timestamp, timezone),
            name: publishItem.id,
            organization: null,
            requester: userName,
            start_timestamp: convertToSiteTimezone(publishItem.start_timestamp, timezone),
            user_name: userName
          }, timezone))
            .then((successful) => dispatch(getGroupMessages(groupId))
              .then(() => {
                dispatch(sendMessageSuccess(publishItem.id));
                return successful;
              }))
            .catch((e) => dispatch(sendMessageFailure(e.status)));
        })
        .catch((e) => dispatch(sendMessageFailure(e.status))), 1000))
      .catch((e) => dispatch(sendMessageFailure(e.status)));
  };
}
