import { Dialog, Grid } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { SonifiLabel, SonifiSpinner, SonifiText } from '../../../../containers';
import SonifiModalHeader from '../../../../containers/SonifiModalHeader';
import { getErrorText, isError } from '../../../../utils';
import { fetchFirmware, startFirmware } from '../../../Firmware/actions/firmwareActions';
import { saveSiteFirmware } from '../../actions/FirmwareActions';
import { updateSamsungFirmware } from '../../actions/SamsungFirmwareActions';
import { BEM, CLONE, SAMSUNG } from '../../constants/TermFileConstants';
import { validSamsungFirmware } from '../../utils/validator';

class EditSamsung extends Component {
  state = {
    editLoading: false,
    errors: null,
    id: null,
    model: null,
    origBem: null,
    origClone: null,
    rooms: [],
    selectedBem: null,
    selectedClone: null,
    type: null
  };

  componentDidMount() {
    const { dispatch, samsungFirmware, samsungIndex } = this.props;
    const { id, model, firmware } = samsungFirmware[samsungIndex];

    console.log('samsungFirmware[samsungIndex]', samsungFirmware[samsungIndex]);

    this.setState({
      editLoading: true,
      id,
      model,
      origBem: (firmware ? firmware[BEM] : ''),
      origClone: (firmware ? firmware[CLONE] : ''),
      selectedBem: (firmware ? firmware[BEM] : ''),
      selectedClone: (firmware ? firmware[CLONE] : '')
    });

    if (model) {
      dispatch(startFirmware());
      setTimeout(() => {
        dispatch(fetchFirmware({ limit: 100, sort: 'id:asc', model: `${id}` }))
          .then((json) => {
            const termType = (json.firmware && json.firmware.length > 0 ? json.firmware[0].type : null);
            this.setState({ editLoading: false, type: termType });
          });
      }, 200);
    }
  }

  updateFirmware() {
    const { samsungFirmware, samsungIndex, translations } = this.props;
    const { origBem, origClone, selectedBem, selectedClone } = this.state;

    if (selectedBem === origBem && selectedClone === origClone) {
      this.onCancel();
      return;
    }

    const samsungObj = {};
    samsungObj.id = samsungFirmware[samsungIndex].id;
    samsungObj.firmware = {
      [BEM]: selectedBem === translations.unconfigureBem ? null : selectedBem,
      [CLONE]: selectedClone === translations.unconfigureClone ? null : selectedClone,
    };

    validSamsungFirmware(samsungObj, translations.errors)
      .then((isValidFirmware) => {
        this.setState({ errors: isValidFirmware });

        if (Object.entries(isValidFirmware).length === 0) {
          this.props.dispatch(saveSiteFirmware(samsungObj, SAMSUNG));
        }
      })
      .catch((error) => {
        console.log('Valid Firmware Error', error);
        this.setState({ errors: { firmware: translations.errors.general } });
      });
  }

  onClose(event, reason) {
    if (reason !== 'backdropClick') {
      this.onCancel();
    }
  }

  onCancel() {
    this.props.dispatch(updateSamsungFirmware(-1));
  }

  handleChange = (name) => ({ target: { value } }) => {
    this.setState({ [name]: value });
  };

  getBrowserOptions(name, type) {
    let clientOptions = [];
    const { configuredFirmware, loading, translations } = this.props;

    if (!loading) {
      clientOptions = [...configuredFirmware];
      if (this.state[`orig${name}`] !== '' && this.state[`orig${name}`] !== null &&
        this.state[`orig${name}`] !== undefined) {
        const selected = find(clientOptions, (o) => o.id === this.state[`orig${name}`]);
        if (selected === undefined) {
          clientOptions.unshift({ id: this.state[`orig${name}`], type });
        }
        clientOptions.unshift({
          id: translations[`unconfigure${name}`],
          type
        });
      }
    }
    return clientOptions;
  }

  render() {
    const {
      canEdit, configuredFirmware, globalTranslations, loading,
      translations, samsungFirmware, samsungIndex
    } = this.props;
    const { errors, selectedBem, origBem, selectedClone } = this.state;

    const tempFirmware = [...configuredFirmware];
    if (!loading && origBem !== '') {
      const selected = find(tempFirmware, (o) => o.id === origBem);
      if (selected === undefined) {
        tempFirmware.unshift({ id: origBem });
      }
    }

    return (
      <Dialog
        open={true}
        maxWidth="md"
        onClose={this.onClose.bind(this)}
        fullWidth >
        <SonifiModalHeader
          header={translations.edit}
          onlyClose={!canEdit || loading}
          onCancel={this.onClose.bind(this)}
          onSubmit={this.updateFirmware.bind(this)}
          label={globalTranslations.save}
        />
        <DialogContent>
          {loading
            ? <SonifiSpinner />
            : <Grid container>
              <Grid item xs={12}>
                <SonifiLabel boldLabel={translations.description.replace('#model', samsungFirmware[samsungIndex].id)} />
              </Grid>
              <Grid item xs={4}>
                <SonifiText
                  change={this.handleChange('selectedBem')}
                  defaultValue={selectedBem}
                  error={isError('selectedBem', errors)}
                  errorText={getErrorText('selectedBem', errors)}
                  items={this.getBrowserOptions('Bem', BEM).filter(
                    (suggestion) => (suggestion.type === BEM && !suggestion.corrupted)
                  ).map((suggestion) => ({
                    id: suggestion.id,
                    value: suggestion.id,
                    label: suggestion.id,
                  }))}
                  label={translations.vFirmware}
                  select={true}
                  size="percent"
                />
              </Grid>
              <Grid item xs={4}>
                <SonifiText
                  change={this.handleChange('selectedClone')}
                  defaultValue={selectedClone}
                  error={isError(CLONE, errors)}
                  errorText={getErrorText(CLONE, errors)}
                  items={this.getBrowserOptions('Clone', CLONE).filter(
                    (suggestion) => (suggestion.type === CLONE && !suggestion.corrupted)
                  ).map((suggestion) => ({
                    id: suggestion.id,
                    value: suggestion.id,
                    label: suggestion.id,
                  }))}
                  label={translations.clone}
                  select={true}
                  size="percent"
                />
              </Grid>
            </Grid>
          }
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  configuredFirmware: state.firmware.firmware,
  globalTranslations: state.global.translations.defaults,
  loading: state.firmware.loading,
  samsungFirmware: state.termFile.samsungFirmware,
  samsungIndex: state.termFile.samsungIndex,
  translations: state.termFile.translations.popOver.samsung,
});

EditSamsung.propTypes = {
  canEdit: PropTypes.bool,
  classes: PropTypes.object,
  configuredFirmware: PropTypes.array,
  dispatch: PropTypes.func,
  globalTranslations: PropTypes.object,
  loading: PropTypes.bool,
  samsungFirmware: PropTypes.array,
  samsungIndex: PropTypes.number,
  translations: PropTypes.object,
};

export default connect(mapStateToProps)(EditSamsung);
