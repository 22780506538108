/* eslint-disable max-len */
/* eslint-disable max-params */
/* eslint-disable max-lines */
import { MAX_IP_PORT } from '../../../constants/magic';
import { compareValues, findObjectByKey } from '../../../utils/index';
import {
  analogStandardValues, analogTuningValues, analogTypeValues, ANALOG_RF, AUX, AUX_CHANNEL,
  bandwidthValues, digitalAuxiliary, digitalSource, digitalTuningValues, digitalTypeValues,
  DIGITAL_RF, dvbcAndsModulationValues, dvbtModulationValues, encryptionValues, IPTV, MAX_NUM_CHANNELS,
  mpegFormatValues, polarizationValues, PROPERTY
} from '../constants/constants';
import { changeBinaryToDecimal, changeDecimalToBinary } from './binaryHelper';
import { parseDownloadFileChannels } from './downloadFileImport';

export function exampleCsv() {
  let csv = 'Here is where we can put some instructional text.\n';
  csv += 'Channel number column is optional.  If this column doesn\'t exist -> channel numbers will be 1-n. \n';
  csv += 'Channel name must exist. \n';
  csv += 'Description is optional. \n';
  csv += 'Analog:\n,Mod is needed.\n,Analog Tuning is optional (default \'std\').\n';
  csv += 'Digital:\n,Digital mod and stream is needed (mod-stream is the format).\n,Digital Tuning is optional (default \'atsc\').\n';
  csv += ',Digital mpeg format is optional (default is \'mpeg4\').\n,Digital encryption is optional (default \'proidiom\').\n';
  csv += 'IP:\n,IP Address is needed.\n,IP Stream is needed.\n,IP Port is needed.\n';
  csv += ',IP MPEG format is optional (default \'mpeg4\').\n,IP Encryption is optional (default is \'proidiom\').\n';
  csv += 'International Analog:\n,Intl Type is needed (analog_air/analog_cable).\n';
  csv += ',Frequency Khz is needed (40000 - 1000000).\n';
  csv += ',Standard is needed (NTSC/PAL_BG/PAL_DK/PAL_I/PAL_M/PAL_N/SECAM_BG/SECAM_DK/SECAM_L).\n';
  csv += 'DVB-C:\n,Frequency Khz is needed (40000 - 1000000).  Stream is needed.\n';
  csv += ',Mpeg Format is optional (default is \'mpeg4\').\n,Encryption is optional (default \'none\').\n';
  csv += ',Modulation is needed (QPSK/QAM/QAM4/QAM8/QAM16/QAM32/QAM64/QAM128/QAM256).\n';
  csv += ',Bandwidth is needed (1.7/5/6/7/8/9/10 MHz)\n';
  csv += ',Symbol Rate is needed.\n';
  csv += 'DVB-S:\n,Frequency Mhz is needed (1000 - 100000).  Stream is needed.\n';
  csv += ',Mpeg Format is optional (default is \'mpeg4\').\n,Encryption is optional (default \'none\').\n';
  csv += ',Modulation is needed (QPSK/QAM/QAM4/QAM8/QAM16/QAM32/QAM64/QAM128/QAM256).\n';
  csv += ',Bandwidth is needed (1.7/5/6/7/8/9/10 MHz).\n';
  csv += ',Symbol Rate is needed.\n';
  csv += ',Polarization is needed (vertical/horizontal/left/right).\n';
  csv += ',Type is needed (digital_satellite/digital_satellite2).\n';
  csv += 'DVB-T:\n,Frequency Khz is needed (40000 - 1000000).  Stream is needed.\n';
  csv += ',Mpeg Format is optional (default is \'mpeg4\').\n,Encryption is optional (default \'none\').\n';
  csv += ',Modulation is needed (OFDM/OFDM_T2/QPSK/QAM16/QAM64/QAM256).\n';
  csv += ',Bandwidth is needed (1.7/5/6/7/8/9/10 MHz).\n';
  csv += ',PLP ID is needed.\n';


  // csv += 'Channel Number, Channel Name, Description, Digital mod and stream,Digital Tuning,Digital mpeg format,Digital encryption,Digital MBPS,IP Address,IP stream,Ip port,IP MPEG format,IP encryption,IP MBPS,Analog Modulator,Analog Tuning\n';
  csv += 'Channel Number, Channel Name, Description,';
  csv += 'Digital mod and stream,Digital Tuning,Digital mpeg format,Digital encryption,';
  csv += 'IP Address,IP stream,Ip port,IP MPEG format,IP encryption,';
  csv += 'Analog Modulator,Analog Tuning,Intl Analog Type,Intl Analog Frequency Khz,Intl Analog Standard,';
  csv += 'DVB-C Frequency Khz,DVB-C Stream,DVB-C Mpeg Format,DVB-C Encryption,DVB-C Modulation,DVB-C Bandwidth,DVB-C Symbol Rate,';
  csv += 'DVB-S Frequency Mhz,DVB-S Stream,DVB-S Mpeg Format,DVB-S Encryption,DVB-S Modulation,DVB-S Bandwidth,DVB-S Symbol Rate,DVB-S Satellite ID, DVB-S Polarization, DVB-S Type,';
  csv += 'DVB-T Frequency Khz,DVB-T Stream,DVB-T Mpeg Format,DVB-T Encryption,DVB-T Modulation,DVB-T Bandwidth,DVB-T PLP ID,\n';

  return csv;
}

export function getTableHeaders(translations, hasAnalog, hasIntlAnalog, hasDigital, hasIP, hasDvbC, hasDvbS, hasDvbT) {
  const tableHeader = [
    { id: '', sortable: false, numeric: false, label: '' },
    { id: 'id', sortable: false, numeric: false, label: translations.channel },
    { id: 'type', sortable: false, numeric: false, label: translations.type },
    { id: 'name', sortable: false, numeric: false, label: translations.displayName }
  ];

  if (hasAnalog) {
    tableHeader.push({ id: 'analog', sortable: false, numeric: false, label: translations.analog });
  }

  if (hasIntlAnalog) {
    tableHeader.push({ id: 'intl_analog', sortable: false, numeric: false, label: translations.intlAnalog });
  }

  if (hasDigital) {
    tableHeader.push({ id: 'digital', sortable: false, numeric: false, label: translations.digital });
  }

  if (hasIP) {
    tableHeader.push({ id: 'ip', sortable: false, numeric: false, label: translations.ip });
  }

  if (hasDvbC) {
    tableHeader.push({ id: 'digital', sortable: false, numeric: false, label: translations.dvbC });
  }

  if (hasDvbS) {
    tableHeader.push({ id: 'digital', sortable: false, numeric: false, label: translations.dvbS });
  }

  if (hasDvbT) {
    tableHeader.push({ id: 'digital', sortable: false, numeric: false, label: translations.dvbT });
  }

  return tableHeader;
}

export function getColumnNames(hasAnalog, hasIntlAnalog, hasDigital, hasIP, hasDvbC, hasDvbS, hasDvbT) {
  const cNames = [
    { id: 'id', type: 'label' },
    { id: 'type', type: 'label' },
    { id: 'name', type: 'label' }
  ];

  if (hasAnalog) {
    cNames.push({ id: 'analog', type: 'check' });
  }

  if (hasIntlAnalog) {
    cNames.push({ id: 'intl_analog', type: 'check' });
  }

  if (hasDigital) {
    cNames.push({ id: 'digital', type: 'check' });
  }

  if (hasIP) {
    cNames.push({ id: 'ip', type: 'check' });
  }

  if (hasDvbC) {
    cNames.push({ id: 'intl_dvb_c', type: 'check' });
  }

  if (hasDvbS) {
    cNames.push({ id: 'intl_dvb_s', type: 'check' });
  }

  if (hasDvbT) {
    cNames.push({ id: 'intl_dvb_t', type: 'check' });
  }

  return cNames;
}

/* eslint-disable max-lines-per-function */
export function parseChannels(siteNum, channelTypes, channelHeaders, translations, chanInfo) {
  const channelInfo = getChannelArray(chanInfo);
  const channels = [];
  const channelNumbers = [];
  const aAnalogMod = [];
  const aDigital = [];
  const aIp = [];
  const titles = [];
  let areThereErrors = false,
    channelConfigErrors = false,
    hasAuxChan = false,
    hasAnalog = false,
    hasIntlAnalog = false,
    hasDigital = false,
    hasDvbC = false,
    hasDvbS = false,
    hasDvbT = false,
    hasIP = false;

  if (!channelInfo || channelInfo.length < 1) {
    return { channels, channelNumberError: true, configErrors: true };
  }

  for (let i = 0; i < channelInfo[0].length; i++) {
    titles.push(channelInfo[0][i].toUpperCase());
  }

  const channelNumber = getPlaceInLine(titles, 'Channel', 'Channel Number');
  const downloadNumber = getPlaceInLine(titles, '"Display"', 'Display');
  if (channelNumber === -1 && downloadNumber !== -1) {
    return parseDownloadFileChannels(channelInfo, siteNum, channelTypes, channelHeaders, translations);
  }

  const now = new Date();
  let curdate = Math.round(now.getTime() / 1000);
  for (let i = 0; i < channelTypes.length; i++) {
    if (channelTypes[i].checked) {
      if (channelTypes[i].name === ANALOG_RF) {
        hasAnalog = true;
      } else if (channelTypes[i].name === DIGITAL_RF) {
        hasDigital = true;
      } else if (channelTypes[i].name === IPTV) {
        hasIP = true;
      }
    }
  }

  const channelName = getPlaceInLine(titles, 'Name', 'Channel Name');
  const channelDesc = getPlaceInLine(titles, 'Description', 'Desc');
  const analogMod = getPlaceInLine(titles, 'Analog Modulator', 'Analog');
  const analogTuning = getPlaceInLine(titles, 'Analog Tuning', 'aTuning');
  const digitalModStream = getPlaceInLine(titles, 'Digital mod and stream', 'Digital');
  const digitalTuning = getPlaceInLine(titles, 'Digital Tuning', 'dTuning');
  const digitalFormat = getPlaceInLine(titles, 'Digital mpeg format', 'dFormat');
  const digitalEncryption = getPlaceInLine(titles, 'Digital Encryption', 'Encryption');
  const ipAddress = getPlaceInLine(titles, 'IP Address', 'IP');
  const ipPort = getPlaceInLine(titles, 'Ip port', 'Port');
  const ipStream = getPlaceInLine(titles, 'IP stream', 'Stream');
  const ipFormat = getPlaceInLine(titles, 'IP MPEG format', 'IP Format');
  const ipEncryption = getPlaceInLine(titles, 'IP Encryption', 'Encryption');

  const intlAnaType = getPlaceInLine(titles, 'Intl Analog Type', 'Intl Type');
  const intlAnaFreq = getPlaceInLine(titles, 'Intl Analog Frequency Khz', 'Intl Freq Khz');
  const intlAnaStd = getPlaceInLine(titles, 'Intl Analog Standard', 'Intl Standard');

  const dvbcFreq = getPlaceInLine(titles, 'DVB-C Frequency Khz', 'DVB-C Freq');
  const dvbcStream = getPlaceInLine(titles, 'DVB-C Stream', 'DVB-C Stream');
  const dvbcFormat = getPlaceInLine(titles, 'DVB-C Mpeg Format', 'DVB-C Format');
  const dvbcEncryption = getPlaceInLine(titles, 'DVB-C Encryption', 'DVB-C Encrypt');
  const dvbcMod = getPlaceInLine(titles, 'DVB-C Modulation', 'DVB-C Mod');
  const dvbcBand = getPlaceInLine(titles, 'DVB-C Bandwidth', 'DVB-C Band');
  const dvbcSymbol = getPlaceInLine(titles, 'DVB-C Symbol Rate', 'DVB-C Symbol');

  const dvbsFreq = getPlaceInLine(titles, 'DVB-S Frequency Mhz', 'DVB-S Freq');
  const dvbsStream = getPlaceInLine(titles, 'DVB-S Stream', 'DVB-S Stream');
  const dvbsFormat = getPlaceInLine(titles, 'DVB-S Mpeg Format', 'DVB-S Format');
  const dvbsEncryption = getPlaceInLine(titles, 'DVB-S Encryption', 'DVB-S Encrypt');
  const dvbsMod = getPlaceInLine(titles, 'DVB-S Modulation, DVB-S Mod');
  const dvbsBand = getPlaceInLine(titles, 'DVB-S Bandwidth', 'DVB-S Band');
  const dvbsSymbol = getPlaceInLine(titles, 'DVB-S Symbol Rate', 'DVB-S Symbol');
  const dvbsSatID = getPlaceInLine(titles, 'DVB-S Satellite ID', 'DVB-S SatID');
  const dvbsPolar = getPlaceInLine(titles, 'DVB-S Polarization', 'DVB-S Polar');
  const dvbsType = getPlaceInLine(titles, 'DVB-S Type', 'DVB-S Type');

  const dvbtFreq = getPlaceInLine(titles, 'DVB-T Frequency Khz', 'DVB-T Freq');
  const dvbtStream = getPlaceInLine(titles, 'DVB-T Stream', 'DVB-T Stream');
  const dvbtFormat = getPlaceInLine(titles, 'DVB-T Mpeg Format', 'DVB-T Format');
  const dvbtEncryption = getPlaceInLine(titles, 'DVB-T Encryption', 'DVB-T Encrypt');
  const dvbtMod = getPlaceInLine(titles, 'DVB-T Modulation', 'DVB-T Mod');
  const dvbtBand = getPlaceInLine(titles, 'DVB-T Bandwidth', 'DVB-T Band');
  const dvbtPlpID = getPlaceInLine(titles, 'DVB-T PLP ID', 'DVB-T PLP ID');

  let channelIssue = false;
  const channelsThatHaveIssues = [];

  for (let i = 1; i < channelInfo.length; i++) {
    const completelyEmpty = channelInfo[i].join('');
    if (!completelyEmpty || completelyEmpty.length === 0) {
      continue;
    }

    if (channels.length >= MAX_NUM_CHANNELS) {
      break;
    }

    channelIssue = false;
    curdate++;
    const channel = {
      id: (channelNumber !== -1 ? ((!isNaN(channelInfo[i][channelNumber]) && channelInfo[i][channelNumber].length > 0) ? parseInt(channelInfo[i][channelNumber], 10) : -1) : i),
      name: (channelName === -1 ? '' : channelInfo[i][channelName]),
      type: PROPERTY,
      locked: false,
      enabled: true,
      ipg: {
        source_id: `SONIFI-${siteNum}-${curdate}`,
        affiliate: '',
        call_letters: '',
        entry: (channelName === -1 ? '' : channelInfo[i][channelName]),
        description: (channelDesc === -1 ? ' ' : `${channelInfo[i][channelDesc]} `),
        duration: 'PT1H'
      },
      error: {},
      toolTip: ''
    };


    if (channelName === -1 || channel.name === '') {
      areThereErrors = true;
      channel.error.name = `${translations.channelName} \n`;
      channel.toolTip += `${translations.channelName} \n`;
    }

    if (channel.id !== -1 && !channelNumbers.includes(channel.id)) {
      channelNumbers.push(channel.id);
    } else {
      let channelNumberError = channel.id === -1 ? `${translations.channelName} \n` : `${channel.id} ${translations.duplicate} \n`;
      if (isNaN(channelInfo[i][channelNumber])) {
        channelNumberError = `${translations.channelNumberIssue} \n`;
      }
      channel.error.id = channelNumberError;
      channel.toolTip += channelNumberError;
      areThereErrors = true;
      channelIssue = true;
    }

    if (hasAnalog && analogMod !== -1 && channelInfo[i][analogMod] !== '') {
      const anaTune = (analogTuning !== -1 ? channelInfo[i][analogTuning] : 'std');

      const aChan = checkAnalogChannel(channelInfo[i][analogMod], anaTune, translations);
      if (!aAnalogMod.includes(`${aChan.modulator}`)) {
        aAnalogMod.push(`${aChan.modulator}`);
      } else {
        aChan.errorToolTip += `${aChan.modulator} ${translations.duplicate} \n`;
      }

      if (aChan.errorToolTip.length === 0) {
        delete (aChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        channel.error.analog = true;
        aChan.error = true;
        aChan.toolTip += `\n${aChan.errorToolTip}`;
        channel.toolTip += aChan.errorToolTip;
      }

      channel.analog = aChan;
    }
    if (hasAnalog && intlAnaFreq !== -1 && channelInfo[i][intlAnaFreq] !== '') {
      const anaType = (intlAnaType !== -1 ? channelInfo[i][intlAnaType] : 'analog_air');
      const anaStd = (intlAnaStd !== -1 ? channelInfo[i][intlAnaStd] : 'PAL_BG');
      hasIntlAnalog = true;
      const intlAnaChan = checkIntlAnalogChannel(anaType, channelInfo[i][intlAnaFreq], anaStd, translations);
      if (intlAnaChan.errorToolTip.length === 0) {
        delete (intlAnaChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        channel.error.intl_analog = true;
        intlAnaChan.error = true;
        intlAnaChan.toolTip += `\n${intlAnaChan.errorToolTip}`;
        channel.toolTip += intlAnaChan.errorToolTip;
      }
      channel.intl_analog = intlAnaChan;
    }

    if (digitalModStream !== -1 && channelInfo[i][digitalModStream] !== '') {
      const digiMod = channelInfo[i][digitalModStream];
      const digiTune = (digitalTuning !== -1 ? channelInfo[i][digitalTuning] : 'atsc');
      const digiForm = (digitalFormat !== -1 ? channelInfo[i][digitalFormat] : 'mpeg4');
      const digiEncrypt = (digitalEncryption !== -1 ? channelInfo[i][digitalEncryption].toLowerCase() : 'proidiom');

      const dChan = checkDigitalChannel(digiMod, digiTune, digiForm, digiEncrypt, translations);

      if (!aDigital.includes(`${dChan.modulator}-${dChan.stream}`)) {
        aDigital.push(`${dChan.modulator}-${dChan.stream}`);
      } else {
        dChan.errorToolTip += `${dChan.modulator}-${dChan.stream} ${translations.duplicate} \n`;
      }

      if (dChan.errorToolTip.length === 0) {
        delete (dChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        dChan.error = true;
        dChan.toolTip += `\n${dChan.errorToolTip}`;
        channel.error.digital = true;
        channel.toolTip += dChan.errorToolTip;
      }

      if (hasDigital || (`${dChan.modulator}` === AUX_CHANNEL)) {
        channel.digital = dChan;
      }

      if (channel.digital && `${channel.digital.modulator}` === AUX_CHANNEL) {
        channel.type = AUX;
        hasAuxChan = true;
      }
    }

    if (dvbcFreq !== -1 && channelInfo[i][dvbcFreq] !== '') {
      const freq = channelInfo[i][dvbcFreq];
      const stream = channelInfo[i][dvbcStream];
      const format = (dvbcFormat !== -1 ? channelInfo[i][dvbcFormat] : 'mpeg4');
      const encrypt = (dvbcEncryption !== -1 ? channelInfo[i][dvbcEncryption].toLowerCase() : 'proidiom');
      const mod = (dvbcMod !== -1 ? channelInfo[i][dvbcMod] : 'QAM256');
      const bandwidth = String((dvbcBand !== -1 ? channelInfo[i][dvbcBand] : '8'));
      const symbol = channelInfo[i][dvbcSymbol];
      const dvbcObj = {
        errorToolTip: '',
        toolTip: '',
        intlType: 'dvbc',
        carrier: 'digital_cable',
        frequency_khz: freq,
        stream,
        video_format: format,
        encryption: encrypt,
        modulation: mod,
        symbol_rate: symbol,
        bandwidth
      };
      hasDvbC = true;
      const dvbcChan = checkIntlDigitalChannel(dvbcObj, translations);
      if (dvbcChan.errorToolTip.length === 0) {
        delete (dvbcChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        dvbcChan.error = true;
        dvbcChan.toolTip += `\n${dvbcChan.errorToolTip}`;
        channel.error.digital = true;
        channel.toolTip += dvbcChan.errorToolTip;
      }
      channel.intl_dvb_c = dvbcChan;
    }

    if (dvbsFreq !== -1 && channelInfo[i][dvbsFreq] !== '') {
      const freq = channelInfo[i][dvbsFreq];
      const stream = channelInfo[i][dvbsStream];
      const format = (dvbsFormat !== -1 ? channelInfo[i][dvbsFormat] : 'mpeg4');
      const encrypt = (dvbsEncryption !== -1 ? channelInfo[i][dvbsEncryption].toLowerCase() : 'proidiom');
      const mod = (dvbsMod !== -1 ? channelInfo[i][dvbsMod] : 'QAM256');
      const bandwidth = (dvbsBand !== -1 ? channelInfo[i][dvbsBand] : '8');
      const symbol = channelInfo[i][dvbsSymbol];
      const satID = channelInfo[i][dvbsSatID];
      const polar = (dvbsPolar !== -1 ? channelInfo[i][dvbsPolar] : 'left');
      const type = (dvbsType !== -1 ? channelInfo[i][dvbsType] : 'digital_satellite');
      const dvbsObj = {
        errorToolTip: '',
        toolTip: '',
        intlType: 'dvbs',
        carrier: type,
        frequency_mhz: freq,
        stream,
        video_format: format,
        encryption: encrypt,
        modulation: mod,
        symbol_rate: symbol,
        bandwidth,
        satellite_id: satID,
        polarization: polar,
      };
      hasDvbS = true;
      const dvbsChan = checkIntlDigitalChannel(dvbsObj, translations);
      if (dvbsChan.errorToolTip.length === 0) {
        delete (dvbsChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        dvbsChan.error = true;
        dvbsChan.toolTip += `\n${dvbsChan.errorToolTip}`;
        channel.error.digital = true;
        channel.toolTip += dvbsChan.errorToolTip;
      }
      channel.intl_dvb_s = dvbsChan;
    }
    if (dvbtFreq !== -1 && channelInfo[i][dvbtFreq] !== '') {
      const freq = channelInfo[i][dvbtFreq];
      const stream = channelInfo[i][dvbtStream];
      const format = (dvbtFormat !== -1 ? channelInfo[i][dvbtFormat] : 'mpeg4');
      const encrypt = (dvbtEncryption !== -1 ? channelInfo[i][dvbtEncryption].toLowerCase() : 'proidiom');
      const mod = (dvbtMod !== -1 ? channelInfo[i][dvbtMod] : 'QAM64');
      const bandwidth = (dvbtBand !== -1 ? channelInfo[i][dvbtBand] : '8');
      const plpID = channelInfo[i][dvbtPlpID];
      const dvbtObj = {
        errorToolTip: '',
        toolTip: '',
        intlType: 'dvbt',
        carrier: 'digital_air',
        frequency_khz: freq,
        stream,
        video_format: format,
        encryption: encrypt,
        modulation: mod,
        bandwidth,
        plp_id: plpID
      };
      hasDvbT = true;
      const dvbtChan = checkIntlDigitalChannel(dvbtObj, translations);
      if (dvbtChan.errorToolTip.length === 0) {
        delete (dvbtChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        dvbtChan.error = true;
        dvbtChan.toolTip += `\n${dvbtChan.errorToolTip}`;
        channel.error.digital = true;
        channel.toolTip += dvbtChan.errorToolTip;
      }
      channel.intl_dvb_t = dvbtChan;
    }

    if (hasIP && ipAddress !== -1 && ipPort !== -1 && ipStream !== -1 &&
      channelInfo[i][ipAddress] !== '' && channelInfo[i][ipPort] !== '' && channelInfo[i][ipStream] !== ''
    ) {
      const iAddr = channelInfo[i][ipAddress];
      const iPort = parseInt(channelInfo[i][ipPort], 10);
      const iStream = parseInt(channelInfo[i][ipStream], 10);
      const iFormat = (ipFormat === -1 ? 'mpeg4' : channelInfo[i][ipFormat]);
      const iEncryption = (ipEncryption === -1 ? 'proidiom' : channelInfo[i][ipEncryption].toLowerCase());
      const ipChan = checkIpChannel(iAddr, iPort, iStream, iFormat, iEncryption, translations);

      const ipAddStream = `${ipChan.address}:${ipChan.port}`;
      if (!aIp.includes(ipAddStream)) {
        aIp.push(ipAddStream);
      } else {
        ipChan.errorToolTip += `${ipAddStream} ${translations.duplicate} \n`;
      }

      if (ipChan.errorToolTip.length === 0) {
        delete (ipChan.errorToolTip);
      } else {
        channelConfigErrors = true;
        channelIssue = true;
        ipChan.error = true;
        ipChan.toolTip += `\n${ipChan.errorToolTip}`;
        channel.error.ip = true;
        channel.toolTip += ipChan.errorToolTip;
      }
      channel.ip = ipChan;
    }

    if (channel.digital) {
      if (channel.digital && channel.digital.modulator === parseInt(AUX_CHANNEL, 10) && (channel.analog || channel.ip)) {
        channelIssue = true;
        if (channel.analog) {
          // if (!channel.analog.error) {
          channel.analog.error = true;
          channel.error.analog = true;
          channelConfigErrors = true;
          channelIssue = true;

          // }
          channel.toolTip += `\n ${translations.auxAnalog} \n`;
          channel.analog.toolTip += `\n ${translations.auxAnalog} \n`;
        }
        if (channel.ip) {
          // if (!channel.ip.error) {
          channel.ip.error = true;
          channel.error.ip = true;
          channelConfigErrors = true;
          channelIssue = true;

          // }
          channel.toolTip += `\n ${translations.auxIP} \n`;
          channel.ip.toolTip += `\n ${translations.auxIP} \n`;
        }
      }
    }

    if (channelIssue) {
      channelsThatHaveIssues.push(channel.id);
    }

    if (Object.keys(channel.error).length === 0) {
      delete (channel.error);
      delete channel.toolTip;
    }
    channels.push(channel);
  }

  if (channelNumbers.length === 0) {
    for (let i = 0; i < channels.length; i++) {
      channels[i].id = (i + 1);
      if (channels[i].error.length > 0) {
        channels[i].error.shift();
        if (channels[i].error.length === 0) {
          delete (channels[i].error);
        }
      }
    }
    areThereErrors = false;
  } else if (!areThereErrors) {
    channels.sort(compareValues('id'));
  }

  return {
    channels,
    channelNumberError: areThereErrors,
    configErrors: channelConfigErrors,
    tableHeader: getTableHeaders(channelHeaders, hasAnalog, hasIntlAnalog, (hasAuxChan || hasDigital), hasIP, hasDvbC, hasDvbS, hasDvbT),
    columnNames: getColumnNames(hasAnalog, hasIntlAnalog, (hasAuxChan || hasDigital), hasIP, hasDvbC, hasDvbS, hasDvbT),
  };
}

function getChannelArray(chanInfo) {
  const channelInfo = [...chanInfo];
  let findStartOfChannelInfo = true;
  while (findStartOfChannelInfo && channelInfo.length > 0) {
    const chanRow = channelInfo[0].map((i) => i.toLowerCase().replace(new RegExp('"', 'g'), ''));
    if ((chanRow.includes('channel name') || chanRow.includes('name')) &&
      (
        (chanRow.includes('channel') || chanRow.includes('channel number') || chanRow.includes('display')) ||
        (
          chanRow.includes('analog modulator') || chanRow.includes('analog') || chanRow.includes('analog_modulator')
        ) ||
        (
          chanRow.includes('digital mod and stream') || chanRow.includes('digital') ||
          chanRow.includes('digital_modulator')
        ) ||
        (
          (chanRow.includes('ip Address') || chanRow.includes('ip') || chanRow.includes('ip_address')) &&
          (chanRow.includes('ip stream') || chanRow.includes('stream') || chanRow.includes('ip_stream')) &&
          (chanRow.includes('ip port') || chanRow.includes('port') || chanRow.includes('ip_port'))
        ) ||
        (
          chanRow.includes('intl analog frequency khz') || chanRow.includes('intl_analog_frequency_khz') ||
          chanRow.includes('dvb-c frequency khz') || chanRow.includes('intl_dvb_c_frequency_khz') ||
          chanRow.includes('dvb-s frequency mhz') || chanRow.includes('intl_dvb_s_frequency_mhz') ||
          chanRow.includes('dvb-t frequency khz') || chanRow.includes('intl_dvb_t_frequency_khz')
        )
      )
    ) {
      findStartOfChannelInfo = false;
    } else {
      channelInfo.shift();
    }
  }

  return channelInfo;
}

export function getPlaceInLine(a, first, second) {
  let idx = a.indexOf(first.toUpperCase());
  if (second && idx === -1) {
    idx = a.indexOf(second.toUpperCase());
  }
  return idx;
}

export function checkAnalogChannel(aChannel, aTuning, translations) {
  const anaObject = {
    modulator: parseInt(aChannel, 10),
    tuning: (!aTuning || aTuning.length === 0 ? 'std' : aTuning),
    errorToolTip: ''
  };

  anaObject.toolTip = `${translations.modulator}: ${anaObject.modulator} \n ${translations.tuning}: ${anaObject.tuning} \n`;
  if (isNaN(anaObject.modulator)) {
    anaObject.errorToolTip += `${translations.analogNumber} \n`;
  }

  if (anaObject.tuning === '' || !findObjectByKey(analogTuningValues, 'label', anaObject.tuning)) {
    anaObject.errorToolTip += `${translations.analogTuning} hrc, irc, jpn, or std \n`;
  }

  return anaObject;
}

export function checkIntlAnalogChannel(intlAnaType, intlAnaFreq, intlAnaStd, translations) {
  const intlAnaObject = {
    frequency_khz: parseInt(intlAnaFreq, 10),
    standard: (!intlAnaStd || intlAnaStd.length === 0 ? 'PAL_BG' : intlAnaStd),
    carrier: (!intlAnaType || intlAnaType.length === 0 ? 'analog_air' : intlAnaType),
    errorToolTip: '',
    toolTip: ''
  };
  intlAnaObject.toolTip = `${translations.frequencyKhz}: ${intlAnaObject.frequency_khz} \n ${translations.type}: ${intlAnaObject.carrier} \n ${translations.standard}: ${intlAnaObject.standard} \n`;
  if (isNaN(intlAnaObject.frequency_khz)) {
    intlAnaObject.errorToolTip += `${translations.freqKhzNum} \n`;
  }

  if (intlAnaObject.standard === '' || !findObjectByKey(analogStandardValues, 'label', intlAnaObject.standard)) {
    intlAnaObject.errorToolTip += `${translations.intlAnaStd} NTSC, PAL_BG, PAL_DK, PAL_I, PAL_M, SECAM_BG, SECAM_DK, SECAM_L \n`;
  }

  if (intlAnaObject.carrier === '' || !findObjectByKey(analogTypeValues, 'label', intlAnaObject.carrier)) {
    intlAnaObject.errorToolTip += `${translations.intlAnaType} analog_air, analog_cable \n`;
  }
  return intlAnaObject;
}

export function checkDigitalChannel(dChannel, dTuning, dFormat, dEncryption, translations) {
  let splitItUp = dChannel.split('-'),
    digitalMod = null,
    digitalStream = null;

  if (splitItUp.length === 1 && !isNaN(splitItUp[0])) {
    splitItUp = dChannel.split('.');
  }

  if (splitItUp.length === 1 || isNaN(splitItUp[0])) {
    splitItUp = dChannel.split(' ');

    const first = findObjectByKey(digitalAuxiliary, 'label', splitItUp[0]);
    if (first) {
      const second = findObjectByKey(digitalSource, 'label', splitItUp[1]);

      if (second) {
        digitalMod = AUX_CHANNEL;
        digitalStream = changeBinaryToDecimal(`${second.value}${first.value}`);
      }
    }
  } else {
    digitalMod = (splitItUp[0] ? parseInt(splitItUp[0], 10) : -1);
    digitalStream = (splitItUp[1] ? parseInt(splitItUp[1], 10) : -1);
  }

  const digitalObj = {
    modulator: digitalMod,
    stream: digitalStream,
    tuning: (!dTuning || dTuning.length === 0 ? 'atsc' : dTuning),
    mbps: '0.000000',
    video_format: (!dFormat || dFormat.length === 0 ? 'mpeg4' : dFormat),
    encryption: (!dEncryption || dEncryption.length === 0 ? 'proidiom' : dEncryption),
    errorToolTip: ''
  };

  if (digitalObj.modulator === -1 || isNaN(digitalObj.modulator) || digitalObj.modulator === null) {
    digitalObj.errorToolTip += `${translations.digitalModNumber} \n`;
  }

  if (digitalObj.stream === -1 || isNaN(digitalObj.stream) || digitalObj.stream === null) {
    digitalObj.errorToolTip += `${translations.digitalStreamNumber} \n`;
  }

  if (`${digitalObj.modulator}` === AUX_CHANNEL) {
    const bin = changeDecimalToBinary(digitalObj.stream);
    const axuls = findObjectByKey(digitalAuxiliary, 'value', bin.substr(3));
    const srcs = findObjectByKey(digitalSource, 'value', bin.substr(0, 3));

    if (!axuls || !srcs) {
      digitalObj.errorToolTip += `${translations.auxChannel} \n`;
    } else {
      digitalObj.aux = `(${axuls.label} ${srcs.label})`;
    }
  } else if (digitalObj.modulator > parseInt(AUX_CHANNEL, 10)) {
    digitalObj.errorToolTip += `${translations.digitalExceed} ${AUX_CHANNEL} \n`;
  }

  digitalObj.toolTip = `${translations.modulator}: ${digitalObj.modulator}-${digitalObj.stream} ${(digitalObj.aux ? digitalObj.aux : '')}\n`;
  digitalObj.toolTip += `${translations.tuning}: ${digitalObj.tuning} \n ${translations.format}: ${digitalObj.video_format} \n`;
  digitalObj.toolTip += `${translations.encryption}: ${digitalObj.encryption} \n`;


  if (digitalObj.tuning === '' || !findObjectByKey(digitalTuningValues, 'label', digitalObj.tuning)) {
    digitalObj.errorToolTip += `${translations.digitalTuning} atsc, dvb-c, dvb-s, dvb-t \n`;
  }

  if (digitalObj.video_format === '' || !findObjectByKey(mpegFormatValues, 'label', digitalObj.video_format)) {
    digitalObj.errorToolTip += `${translations.digitalFormat} mpeg2 or mpeg4 \n`;
  }

  if (digitalObj.encryption === '' || !findObjectByKey([...encryptionValues, 'none'], 'label', digitalObj.encryption)) {
    digitalObj.errorToolTip += `${translations.digitalEncryption} none or proidiom \n`;
  }

  return digitalObj;
}

export function checkIntlDigitalChannel(intlChanObj, translations) {
  if (intlChanObj.intlType === 'dvbs') {
    if (intlChanObj.frequency_mhz === -1 || isNaN(intlChanObj.frequency_mhz) || intlChanObj.frequency_mhz === null) {
      intlChanObj.errorToolTip += `${translations.freqMhzNum} \n`;
    }
    intlChanObj.toolTip = `${translations.frequencyMhz}: ${intlChanObj.frequency_mhz}\n`;
  } else {
    if (intlChanObj.frequency_khz === -1 || isNaN(intlChanObj.frequency_khz) || intlChanObj.frequency_khz === null) {
      intlChanObj.errorToolTip += `${translations.freqKhzNum} \n`;
    }
    intlChanObj.toolTip = `${translations.frequencyKhz}: ${intlChanObj.frequency_khz}\n`;
  }
  if (intlChanObj.stream === -1 || isNaN(intlChanObj.stream) || intlChanObj.stream === null) {
    intlChanObj.errorToolTip += `${translations.intlStreamNumber} \n`;
  }
  intlChanObj.toolTip += `${translations.stream}: ${intlChanObj.stream} \n`;

  if (intlChanObj.video_format === '' || !findObjectByKey(mpegFormatValues, 'label', intlChanObj.video_format)) {
    intlChanObj.errorToolTip += `${translations.digitalFormat} mpeg2 or mpeg4 \n`;
  }
  intlChanObj.toolTip += `${translations.format}: ${intlChanObj.video_format} \n`;

  if (intlChanObj.encryption === '' || !findObjectByKey([...encryptionValues, 'none'], 'label', intlChanObj.encryption)) {
    intlChanObj.errorToolTip += `${translations.digitalEncryption} none or proidiom \n`;
  }
  intlChanObj.toolTip += `${translations.encryption}: ${intlChanObj.encryption} \n`;

  if (intlChanObj.intlType === 'dvbt') {
    if (intlChanObj.modulation === '' || !findObjectByKey(dvbtModulationValues, 'label', intlChanObj.modulation)) {
      intlChanObj.errorToolTip += `${translations.modulationErr} OFDM, OFDM_T2, QPSK, QAM16, QAM64, QAM256 \n`;
    }
  } else if (intlChanObj.modulation === '' || !findObjectByKey(dvbcAndsModulationValues, 'label', intlChanObj.modulation)) {
    intlChanObj.errorToolTip += `${translations.modulationErr} QPSK, QAM, QAM4, QAM8, QAM16, QAM32, QAM64, QAM128, QAM256 \n`;
  }
  intlChanObj.toolTip += `${translations.modulation}: ${intlChanObj.modulation} \n`;

  if (intlChanObj.bandwidth === '' || !findObjectByKey(bandwidthValues, 'value', intlChanObj.bandwidth)) {
    intlChanObj.errorToolTip += `${translations.bandwidthErr} 1.7, 5, 6, 7, 8, 9, 10 \n`;
  }
  intlChanObj.toolTip += `${translations.bandwidth}: ${intlChanObj.bandwidth} \n`;

  if (intlChanObj.intlType === 'dvbs') {
    if (intlChanObj.polarization === '' || !findObjectByKey(polarizationValues, 'label', intlChanObj.polarization)) {
      intlChanObj.errorToolTip += `${translations.polarizationErr} vertical, horizontal, right, left \n`;
    }
    if (intlChanObj.satellite_id === -1 || isNaN(intlChanObj.satellite_id) || intlChanObj.satellite_id === null) {
      intlChanObj.errorToolTip += `${translations.satNumber} \n`;
    }
    if (intlChanObj.carrier === '' || !findObjectByKey(digitalTypeValues, 'label', intlChanObj.carrier)) {
      intlChanObj.errorToolTip += `${translations.carrierErr} digital_satellite, digital_satellite2 \n`;
    }
    intlChanObj.toolTip += `${translations.polar}: ${intlChanObj.polarization} \n`;
    intlChanObj.toolTip += `${translations.satID}: ${intlChanObj.satellite_id} \n`;
    intlChanObj.toolTip += `${translations.type}: ${intlChanObj.carrier} \n`;
  }
  if (intlChanObj.intlType === 'dvbt') {
    if (intlChanObj.plp_id === -1 || isNaN(intlChanObj.plp_id) || intlChanObj.plp_id === null) {
      intlChanObj.errorToolTip += `${translations.plpNumber} \n`;
    }
    intlChanObj.toolTip += `${translations.plpID}: ${intlChanObj.plp_id} \n`;
  } else {
    if (intlChanObj.symbol_rate === -1 || isNaN(intlChanObj.symbol_rate) || intlChanObj.symbol_rate === null) {
      intlChanObj.errorToolTip += `${translations.symbolRateNum} \n`;
    }
    intlChanObj.toolTip += `${translations.symbolRate}: ${intlChanObj.symbol_rate} \n`;
  }
  return intlChanObj;
}

export function checkIpChannel(iAddr, iPort, iStream, iFormat, iEncryption, translations) {
  const ipObj = {
    address: iAddr,
    port: iPort,
    stream: iStream,
    mbps: '0.000000',
    video_format: (!iFormat || iFormat.length === 0 ? 'mpeg4' : iFormat),

    // encryption: (PROIDIOM.includes(iEncryption.toUpperCase()) ? 'proidiom' : 'none'),
    encryption: (!iEncryption || iEncryption.length === 0 ? 'proidiom' : iEncryption),
    errorToolTip: ''
  };

  ipObj.toolTip = `${translations.ipAddress}: ${ipObj.address} \n ${translations.port}: ${ipObj.port} \n ${translations.stream}: ${ipObj.stream} \n`;
  ipObj.toolTip += `${translations.format}: ${ipObj.video_format} \n ${translations.encryption}: ${ipObj.encryption} \n`;

  if (!(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/).test(iAddr)) {
    ipObj.errorToolTip += `${translations.validIpRange} 0.0.0.0 to 255.255.255.255 \n`;
  }

  if (isNaN(iPort)) {
    ipObj.errorToolTip += `${translations.validIpPort} \n`;
  }
  if (iPort < 1 || iPort > MAX_IP_PORT) {
    ipObj.errorToolTip += `${translations.ipPortRange} 1 and 65535 \n`;
  }

  if (ipObj.video_format === '' || !findObjectByKey(mpegFormatValues, 'label', ipObj.video_format)) {
    ipObj.errorToolTip += `${translations.ipFormat} mpeg2 or mpeg4 \n`;
  }

  if (ipObj.encryption === '' || !findObjectByKey(encryptionValues, 'label', ipObj.encryption)) {
    ipObj.errorToolTip += `${translations.ipTuning} none or proidiom \n`;
  }

  return ipObj;
}

export function isValidForm(channelList) {
  const errors = [];
  for (let i = 0; i < channelList.length; i++) {
    if (!channelList[i].id || !channelList[i].name) {
      errors.push(channelList[i].id);
    }
  }
  return errors;
}
