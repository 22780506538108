import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = (theme) => ({
  root: {
    fontWeight: 400, /* Avenir 55 */
    color: 'red',
    fontFamily: 'Open Sans',
    padding: theme.spacing()
  },
});

export class SonifiError extends Component {
  render() {
    const { boldLabel, classes, label } = this.props;

    return (
      <div className={classes.root}>
        <b>{boldLabel}</b> {label}
      </div>
    );
  }
}

SonifiError.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  boldLabel: PropTypes.string,
};

export default withStyles(SonifiError, styles, { withTheme: true });
