import { USER_LOGS } from '../../../constants/apiEndpoints';
import { getSitelessObjectPromise } from '../../../utils/api';
import * as types from './actionTypes';

export const fetchAuditInfoBegin = (resetAuditInfo) => ({
  type: types.FETCH_AUDIT_INFO_BEGIN,
  resetAuditInfo
});

export const fetchAuditInfoSuccess = (info, maxPages) => ({
  type: types.FETCH_AUDIT_INFO_SUCCESS,
  info,
  maxPages
});

export const fetchAuditInfoFailure = (error) => ({
  type: types.FETCH_AUDIT_INFO_FAILURE,
  error
});

export function fetchAuditInfo(filter, resetAuditInfo = false) {
  return (dispatch) => {
    dispatch(fetchAuditInfoBegin(resetAuditInfo));
    return getSitelessObjectPromise(USER_LOGS, '', filter)
      .then((json) => {
        dispatch(fetchAuditInfoSuccess(json.logs, json.page_count));
        return json;
      })
      .catch((error) => dispatch(fetchAuditInfoFailure(error)));
  };
}

export const updateAuditSnackbar = (snackbarMessage = '', snackbarType) => ({
  type: types.UPDATE_AUDIT_SNACK_BAR,
  snackbarMessage,
  snackbarType
});

export const showAuditInfo = (index = -1) => ({
  type: types.SHOW_AUDIT_INFO,
  index
});
