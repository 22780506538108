import { orderBy } from 'lodash';
import { compareValues, findArrayIdByKey } from '../../../utils/index';
import * as types from '../actions/actionTypes';

const initialState = {
  canAddTerminal: true,
  dialogLoading: false,
  error: null,
  freeTerminals: [],
  loading: false,
  locations: [],
  selectedTerminal: -1,
  terminalOptionsLoading: false,
  terminals: []
};

/* eslint-disable max-lines-per-function */
export default function terminalsReducer(state = initialState, action) {
  const updatedTerminals = [...state.terminals];
  let terminalIndex = 0,
    terminalId = '',
    assignedLocations = [],
    assignedFreeTerminals = [],
    newTerminal = null,
    addFreeTerminal = null;

  switch (action.type) {
    case types.CLOSE_ROOM_INFO:
      assignedFreeTerminals = removeAllFreeTerminalAssignments([...state.freeTerminals]);
      return {
        ...state,
        locations: getAssignedLocations(state, []),
        selectedTerminal: -1,
        canAddTerminal: true
      };

    case types.GET_TERMINALS_BEGIN:
      return {
        ...state,
        dialogLoading: true
      };

    case types.GET_TERMINALS_SUCCESS:
      return {
        ...state,
        terminals: (action.terminals.terminals ? [...action.terminals.terminals] : []),
        locations: getAssignedLocations(state, action.terminals.terminals),
        dialogLoading: false
      };

    case types.GET_TERMINALS_FAILURE:
      return {
        ...state,
        locations: getAssignedLocations(state, []),
        terminals: [],
        dialogLoading: false
      };

    case types.FETCH_TERMINAL_OPTIONS_BEGIN:
      return {
        ...state,
        terminalOptionsLoading: true
      };

    case types.FETCH_TERMINAL_OPTIONS_SUCCESS:
      return {
        ...state,
        locations: [],
        ...action.terminalOptions,
        terminalOptionsLoading: false
      };

    case types.FETCH_TERMINAL_OPTIONS_FAILURE:
      return {
        ...state,
        terminalOptionsLoading: false
      };

    case types.FETCH_TERMINAL_INFO_SUCCESS:
      terminalIndex = findArrayIdByKey(updatedTerminals, 'id', action.terminal.id);
      updatedTerminals[terminalIndex] = action.terminal;

      return {
        ...state,
        terminals: updatedTerminals
      };

    case types.UPDATE_TERMINAL:
      if (action.name === 'swapId' &&
        updatedTerminals[action.terminalIndex].id === 'Add' &&
        action.value === '') {
        action.value = 'Add';
      }

      updatedTerminals[action.terminalIndex] = {
        ...updatedTerminals[action.terminalIndex],
        [action.name]: action.value
      };
      assignedLocations = [...state.locations];
      assignedFreeTerminals = [...state.freeTerminals];
      if (action.name === 'location') {
        terminalId = (updatedTerminals[action.terminalIndex].isNew ? 'Add' : updatedTerminals[action.terminalIndex].id);
        for (let j = 0; j < assignedLocations.length; j++) {
          if (terminalId === assignedLocations[j].terminalId) {
            delete assignedLocations[j].terminalId;
            break;
          }
        }
      }

      if (action.name === 'swapId') {
        for (let j = 0; j < assignedFreeTerminals.length; j++) {
          if (updatedTerminals[action.terminalIndex].id === assignedFreeTerminals[j].terminalId) {
            delete assignedFreeTerminals[j].terminalId;
            break;
          }
        }
      }

      return {
        ...state,
        terminals: updatedTerminals,
        locations: getAssignedLocations(state, updatedTerminals),
        freeTerminals: getFreeTerminals(state, updatedTerminals)
      };

    case types.FETCH_FREE_TERMINALS_SUCCESS:
      assignedFreeTerminals = orderBy(
        action.freeTerminals,
        [(a) => a.id],
        ['asc']
      );
      return {
        ...state,
        freeTerminals: [
          ...assignedFreeTerminals
        ]
      };

    case types.FETCH_FREE_TERMINAL_INFO_SUCCESS:
      assignedFreeTerminals = [...state.freeTerminals];
      terminalIndex = findArrayIdByKey(state.freeTerminals, 'id', action.freeTerminalInfo.id);
      assignedFreeTerminals[terminalIndex] = action.freeTerminalInfo;
      return {
        ...state,
        freeTerminals: assignedFreeTerminals
      };

    case types.ADD_TERMINAL:
      addFreeTerminal = canAddTerminal(state);
      if (addFreeTerminal.add) {
        newTerminal = createNewTerminal(state, action.menusets);
        assignedLocations = getAssignedLocations(state, [...updatedTerminals, newTerminal]);
        return {
          ...state,
          terminals: [...updatedTerminals, newTerminal],
          locations: assignedLocations,
          canAddTerminal: addFreeTerminal.canAdd
        };
      }
      return { ...state, canAddTerminal: false };

    case types.REMOVE_NEW_TERMINAL:
      updatedTerminals.splice(action.terminalIndex, 1);
      assignedLocations = getAssignedLocations(state, [...updatedTerminals] || []);
      return {
        ...state,
        terminals: [...updatedTerminals] || [],
        locations: assignedLocations,
        canAddTerminal: true
      };

    case types.FETCH_FREE_TERMINALS_FAILURE:
      return {
        ...state,
        freeTerminals: [],
        dialogLoading: false
      };

    case types.UPDATE_SELECTED_TERMINAL:
      return {
        ...state,
        selectedTerminal: action.index
      };

    case types.SORT_TERMINALS:
      updatedTerminals.sort(compareValues(action.state.orderBy, action.state.order));

      return {
        ...state,
        terminals: updatedTerminals
      };

    case types.UPDATE_CHROMECAST_PORT_GENERAL:
      return {
        ...state,
        chromecast_port: action.chromecastPort
      };

    default:
      return state;
  }
}

export function getAssignedLocations(state, terminals) {
  const assignedLocations = (state.locations ? [...state.locations] : []);
  for (let j = 0; j < assignedLocations.length; j++) {
    if (assignedLocations[j].terminalId) {
      delete assignedLocations[j].terminalId;
    }
  }

  for (let i = 0; i < terminals.length; i++) {
    for (let j = 0; j < assignedLocations.length; j++) {
      if (terminals[i].location === assignedLocations[j].name) {
        assignedLocations[j].terminalId = terminals[i].id;
        break;
      }
    }
  }
  return assignedLocations;
}

function canAddTerminal(state) {
  let addedTerminalsCount = 0;
  for (let i = 0; i < state.terminals.length; i++) {
    if (state.terminals[i].room_id === 'Add') {
      addedTerminalsCount++;
    }
  }
  return {
    add: (state.terminals.length < state.locations.length && addedTerminalsCount < state.freeTerminals.length),
    canAdd: (state.terminals.length < state.locations.length && addedTerminalsCount < state.freeTerminals.length - 1)
  };
}

function getFreeTerminals(state, terminals) {
  const freeTerms = [...state.freeTerminals];
  for (let i = 0; i < terminals.length; i++) {
    for (let j = 0; j < freeTerms.length; j++) {
      if ((terminals[i].isNew && terminals[i].id === freeTerms[j].id) ||
        (terminals[i].swapId === freeTerms[j].id)) {
        freeTerms[j].terminalId = terminals[i].id;
        break;
      }
    }
  }
  return freeTerms;
}

function createNewTerminal(state, menusets) {
  return {
    id: 'Add',
    room_id: 'Add',
    lineup_id: state.lineups[0].id,
    location: getUnassignedTermLocation(state),
    menuset: menusets[0].id,
    language: 'en', // state.languages[0].id,
    censor_level: 1,
    isNew: true,
    swapId: 'Add'
  };
}

function removeAllFreeTerminalAssignments(assignedFreeTerminals) {
  for (let j = 0; j < assignedFreeTerminals.length; j++) {
    if (assignedFreeTerminals[j].terminalId) {
      delete assignedFreeTerminals[j].terminalId;
    }
  }
}

function getUnassignedTermLocation(state) {
  for (let i = 0; i < state.locations.length; i++) {
    if (!state.locations[i].terminalId) {
      return state.locations[i].name;
    }
  }
}
