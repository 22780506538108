import { createSlice } from '@reduxjs/toolkit';

import { siteSlice } from './siteSlice';

const rootSlice = createSlice({
    name: 'v2',
    initialState: {
        site: {}
    },
    reducers: {
    },
    // This is temporary should be removed once other state has been removed
    // i.e. Only v2 remains in state
    extraReducers: {
        // SITE STATE
        [siteSlice.actions.setSiteId]: (state, action) => {
            state.site.id = action.payload;
          },
        [siteSlice.actions.setSiteName]: (state, action) => {
            state.site.name = action.payload;
          },
        [siteSlice.actions.setSiteDisplayName]: (state, action) => {
            state.site.display_name = action.payload;
          },
        [siteSlice.actions.clearSite]: (state) => {
            state.site = {};
          },
        // OTHER STATE
    },
  });


export default rootSlice.reducer;
