import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'tss-react/mui';
import { SonifiText } from '../../../../containers/';
import { getErrorText, isError } from '../../../../utils/index';

const styles = () => ({
  table: {
    minWidth: 700,
    maxHeight: '90%',
  }
});

class Ip extends Component {
  changeValue = (name) => ({ target: { value } }) => {
    this.props.updateTypeState(name, value, 'ip');
  };

  getTextBox(label, defaultValue, name, disabled) {
    const { editState } = this.props;
    return (
      <SonifiText
        size="lg"
        label={label}
        change={this.changeValue(name)}
        defaultValue={defaultValue}
        error={isError(name, editState.errors)}
        errorText={getErrorText(name, editState.errors)}
        disabled={disabled}
      />
    );
  }

  render() {
    const { editState, translations, notEditable } = this.props;
    return (
      <Grid item xs={12}>
        {this.getTextBox(translations.address, editState.ip.address, 'address', notEditable)}
        {this.getTextBox(translations.port, editState.ip.port, 'port', notEditable)}
        {this.getTextBox(translations.command_timeout, editState.ip.command_timeout, 'command_timeout', notEditable)}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  translations: state.integrations.translations.pmsd.ip
});

Ip.propTypes = {
  editState: PropTypes.object,
  notEditable: PropTypes.bool,
  updateTypeState: PropTypes.func,
  translations: PropTypes.object
};

export default connect(mapStateToProps)(withStyles(Ip, styles, { withTheme: true }));
