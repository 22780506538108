import { TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from 'tss-react/mui';

const styles = () => ({
  head: {
    backgroundColor: '#fff',
    color: '#000',
    fontSize: '14pt',
    fontWeight: 900, /* Avenir 85 */
    position: 'sticky',
    top: 0,
    zIndex: 10
  }
});

export class TableHeader extends Component {
  render() {
    const {
      classes, columns
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {columns.map((item, i) => (
            <TableCell key={i} className={classes.head}>{item}</TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

TableHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired
};

export default withStyles(TableHeader, styles, { withTheme: true });

