import maxSatisfying from 'semver/ranges/max-satisfying';
import { ERROR, SUCCESS } from '../../../constants/constants';
import { getTranslationObject } from '../../../utils/index';
import * as types from '../actions/actionTypes';
import en from '../i18n/en';
import es from '../i18n/es';

const langs = { en, es };

const initialState = {
  error: null,
  latestVersions: null,
  loading: false,
  gettingProducts: false,
  productVersions: null,
  savingWindow: false,
  schedule: null,
  snackBarMessage: '',
  snackBarType: SUCCESS,
  systemId: null,
  systems: null,
  updateRow: null,
  upgradeWindow: null,
  upgradeWindowLoading: true
};

/* eslint-disable max-lines-per-function */
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_LANGUAGE_LITERALS: {
      return {
        ...state,
        translations: getTranslationObject(action.lang, langs),
      };
    }

    case types.GET_SYSTEMS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null,
        snackBarMessage: '',
        systemId: null,
        systems: null
      };
    }

    case types.GET_SYSTEMS_SUCCESS: {
      return {
        ...state,
        loading: false,
        systems: action.systems,
        systemId: (action.systemId ? `${action.systemId}` : null),
      };
    }
    case types.GET_SYSTEMS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.error,
        systemId: null,
        systems: []
      };
    }

    case types.GET_SCHEDULE_BEGIN: {
      return {
        ...state,
        error: null,
        schedule: null,
        snackBarMessage: ''
      };
    }

    case types.GET_SCHEDULE_SUCCESS: {
      return {
        ...state,
        schedule: action.schedule,
        systemId: (action.systemId ? `${action.systemId}` : null),
      };
    }
    case types.GET_SCHEDULE_ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case types.GET_PRODUCTS_BEGIN: {
      return {
        ...state,
        productVersions: {},
        latestVersions: {},
        error: null,
        gettingProducts: true
      };
    }

    case types.GET_PRODUCTS_SUCCESS: {
      const uniqueVirtosVersions = [...new Set(action.versions.map(({ virtos }) => virtos))];
      const maxVersionPerVirtosVerion = {};
      uniqueVirtosVersions.forEach((virtosVersion) => {
        maxVersionPerVirtosVerion[virtosVersion] = maxSatisfying(
          action.versions
            .filter(({ virtos }) => virtos === virtosVersion)
            .map(({ id }) => id),
          '*'
        );
      });

      return {
        ...state,
        productVersions: {
          ...state.productVersions,
          [action.productId]: action.versions,
        },
        latestVersions: {
          ...state.latestVersions,
          [action.productId]: maxVersionPerVirtosVerion,
        },
        error: null,
        gettingProducts: false
      };
    }

    case types.GET_PRODUCTS_ERROR: {
      return {
        ...state,
        error: action.error,
        latestVersions: {},
        productVersions: {},
        gettingProducts: false
      };
    }

    case types.UPDATE_SNACK_BAR: {
      return {
        ...state,
        snackBarMessage: action.snackBarMessage,
        snackBarType: (action.snackBarType ? action.snackBarType : state.snackBarType)
      };
    }

    // case types.UPGRADE_SYSTEM_BEGIN: {
    //   return {
    //     ...state,
    //     snackBarMessage: '',
    //   };
    // }

    // case types.UPGRADE_SYSTEM_SUCCESS: {
    //   return {
    //     ...state,
    //     snackBarMessage: state.translations.main.requestSuccess,
    //     snackBarType: SUCCESS,
    //   };
    // }

    // case types.UPGRADE_SYSTEM_ERROR: {
    //   return {
    //     ...state,
    //     snackBarMessage: state.translations.main.requestFailure,
    //     snackBarType: ERROR,
    //   };
    // }

    case types.GET_UPGRADE_WINDOW_BEGIN:
      return { ...state, upgradeWindowLoading: true };

    case types.GET_UPGRADE_WINDOW_SUCCESS:
      return {
        ...state,
        upgradeWindow: action.upgrade,
        upgradeWindowLoading: false
      };

    case types.GET_UPGRADE_WINDOW_FAILURE:
      return {
        ...state,
        upgradeWindow: null,
        upgradeWindowLoading: false
      };

    case types.SET_UPGRADE_WINDOW_BEGIN:
      return { ...state, savingWindow: true, snackBarMessage: '' };

    case types.SET_UPGRADE_WINDOW_SUCCESS:
      return {
        ...state,
        upgradeWindow: action.upgradeObject,
        savingWindow: false,
        snackBarMessage: 'Successfully saved upgrade window',
        snackBarType: SUCCESS
      };

    case types.SET_UPGRADE_WINDOW_FAILURE:
      return {
        ...state,
        savingWindow: false,
        snackBarMessage: `Failure to save upgrade window - (${action.status})`,
        snackBarType: ERROR
      };

    case types.UPDATE_HOST_BEGIN:
      return { ...state, savingWindow: true };

    case types.UPDATE_HOST_FAILURE:
      return {
        ...state,
        savingWindow: false,
        snackBarMessage: `Failure to save host upgrade - ${action.error}`,
        snackBarType: ERROR
      };

    case types.UPDATE_HOST_SUCCESS:
      return {
        ...state,
        savingWindow: false,
        snackBarMessage: `Successfully to saved ${action.msg}`,
        snackBarType: SUCCESS,
        updateRow: null
      };

    case types.UPDATE_HOST_ROW:
      return { ...state, snackBarMessage: '', updateRow: action.hostRow };

    default:
      return state;
  }
}
